import CrudLedgerAccountTabsDataContents from 'components/crudLedgerAccounts/CrudLedgerAccountTabsDataContents'
import CrudSearchLedgerAccountForm from 'components/crudLedgerAccounts/SearchForm/CrudSearchLedgerAccountForm'
import { CRUD_LEDGER_ACCOUNTS_DATA } from 'utils/RoutePath'

export const ledgerAccountsConfig = {
	id: 'ledgerAccountsCrud',
	key: 'nicodcta',
	descrip: 'descrip',
	searchMethod: 'get',
	category: 'A',
	translation: 'ledger_accounts_data',
	dataUrl: CRUD_LEDGER_ACCOUNTS_DATA,
	urlApi: 'abm_cont_cuentas',
	component: CrudLedgerAccountTabsDataContents,
	searchComponent: CrudSearchLedgerAccountForm
}
