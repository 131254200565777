import Axios from 'axios'
import {
	IConfirmTreasuryAttributesParams,
	IGetAttributesTreasuryHeaderParams,
	IGetTreasuryCurrenciesParams
} from 'models/TreasuryHeader'

const urlApi = '/tes_cab_compr'

export const getAttributesTreasuryHeaderApi = async (
	params: IGetAttributesTreasuryHeaderParams
) => {
	const response = await Axios.get(urlApi, { params })
	return response.data
}

export const confirmTreasuryAttributesApi = async (
	params: Partial<IConfirmTreasuryAttributesParams>
) => {
	const response = await Axios.post(`${urlApi}/confirmar`, params)
	return response.data
}

export const getTreasuryCurrenciesApi = async (
	params: IGetTreasuryCurrenciesParams
) => {
	const response = await Axios.get('/tablas/monedas', { params })
	return response.data
}
