import { getDetailVoucherTaxes } from 'actions'
import CommonLabelCol from 'components/common/CommonLabelCol'
import CommonTable from 'components/common/commonTable'
import { getDefaultColumns } from 'lib/FormUtils'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import InputDropdown from '../form/inputDropdown'

class SearchVoucherTaxesTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showDetailModal: true,
			taxesDataTable: [],
			taxeInfo: {}
		}
		this.primaryKey = 'nro'
	}

	componentDidMount = () => {
		const { id, authUser } = this.props
		if (id) {
			this.props.getDetailVoucherTaxes({
				id,
				page_size: 10,
				page_number: 1,
				idEmpresa: authUser.userCompany
			})
		}
	}

	handleChangeTable = () => {}

	/**
	 * use when change select item
	 */
	handleChangeSelect = (itemId) => {
		const { taxesData } = this.props
		const taxe = _.findWhere(taxesData.items, { nro: itemId })
		this.setState({ taxesDataTable: taxe.impuestos, taxeInfo: taxe })
	}

	render() {
		const { taxesData, fields, fieldsTable, authUser } = this.props
		const { taxesDataTable, itemId, taxeInfo } = this.state

		const propsTableTaxes = {
			remote: true,
			keyField: this.primaryKey,
			columns: !_.isEmpty(fieldsTable)
				? getDefaultColumns(fieldsTable, { authUser })
				: [],
			data: taxesDataTable || [],
			paginationOptions: {},
			onTableChange: this.handleChangeTable
		}

		return (
			<Fragment>
				<Row>
					<Col sm={6}>
						<InputDropdown
							inputFormCol={{ sm: 12 }}
							fields={fields}
							label="Items"
							inputId="filtro_it"
							name="filtro_it"
							placeholder=""
							colLabel="col-sm-2"
							colInput="col-sm-10"
							disable={false}
							onBlur={() => {}}
							value={itemId}
							options={
								_.isEmpty(taxesData)
									? []
									: taxesData.items.map((taxe) => ({
											id: taxe.nro,
											label: `${taxe.nro} - ${taxe.detalle}`
									  }))
							}
							onChange={(data) => {
								const itemId = parseInt(data.target.value)
								this.handleChangeSelect(itemId)
								this.setState({ itemId })
							}}
						/>
					</Col>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 2 }}
						fields={fields}
						labelId="it"
						value={taxeInfo.it ? `${taxeInfo.it}` : ''}
					/>
					<CommonLabelCol
						colLabel={{ sm: 2 }}
						fields={fields}
						labelId="des_prod"
						value={taxeInfo.des_prod ? `${taxeInfo.des_prod}` : ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						fields={fields}
						labelId="cant"
						value={taxeInfo.cant ? `${taxeInfo.cant}` : ''}
					/>
					<CommonLabelCol
						fields={fields}
						labelId="precio"
						value={taxeInfo.precio ? `${taxeInfo.precio}` : ''}
					/>
					<CommonLabelCol
						fields={fields}
						labelId="um"
						value={taxeInfo.um ? `${taxeInfo.um}` : ''}
					/>
					<CommonLabelCol
						fields={fields}
						labelId="cod_prod"
						value={taxeInfo.cod_prod ? `${taxeInfo.cod_prod}` : ''}
					/>
					<CommonLabelCol
						fields={fields}
						labelId="neto"
						value={taxeInfo.neto ? `${taxeInfo.neto}` : ''}
					/>
					<Col></Col>
				</Row>
				<Row>
					<Col sm={12}>
						{!_.isEmpty(fieldsTable) && <CommonTable {...propsTableTaxes} />}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ configCrud, searchVoucher, auth }) => {
	const { fieldsConfig } = configCrud
	const { taxesData } = searchVoucher
	const { authUser } = auth
	return { fieldsConfig, taxesData, authUser }
}

const connectForm = connect(mapStateToProps, { getDetailVoucherTaxes })(
	SearchVoucherTaxesTable
)

export default withTranslation()(connectForm)
