import {
	clearSearchVoucherParams,
	getConfigFieldsCrud,
	searchVouchers
} from 'actions'
import withMenu from 'components/common/withMenu'
import CrudsContainer from 'components/layout/CrudsContainer'
import SearchVoucherTable from 'components/searchVouchers/SearchVoucherTable'
import VoucherSearchForm from 'components/searchVouchers/VoucherSearchForm'
import { P_SEARCH_VOUCHER } from 'constants/ConfigProcessNames'
import { BASIC_TAB } from 'constants/InputsNames'
import { getExcludeFiltersData, getFiltersData } from 'lib/Utils'
import { ISearchVoucherParams } from 'models/SearchVoucher'
import React, { Component } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'

type SearchProps = {
	getConfigFieldsCrud: any
	fieldsConfig: any
	t: any
	searchVouchers: any
	authUser: any
	match?: any
	clearSearchVoucherParams: () => void
	searchParams?: ISearchVoucherParams
	location: any
}

type SearchState = {
	filtersForms: any
}

class SearchVoucher extends Component<SearchProps, SearchState> {
	constructor(props: SearchProps) {
		super(props)
		this.state = {
			filtersForms: null
		}
	}

	componentDidMount = () => {
		const { params } = this.props.match
		const { searchParams, authUser } = this.props
		const queryParams = new URLSearchParams(this.props.location.search)
		const filters: any = {}

		queryParams.forEach((value, key) => {
			filters[key] = value
		})

		if (!_.isEmpty(searchParams)) {
			this.props.searchVouchers({
				idEmpresa: authUser.userCompany,
				...searchParams,
				page_number: 1,
				page_size: 10
			})
			this.setState({ filtersForms: filters })
		}

		if (!params.loadStore || params.loadStore !== 'filters') {
			this.props.clearSearchVoucherParams()
		}

		if (!_.isEmpty(searchParams)) {
			this.setState({ filtersForms: searchParams })
		}

		this.props.getConfigFieldsCrud({ cod_abm: P_SEARCH_VOUCHER })
	}

	componentDidUpdate(prevProps: SearchProps) {
		const { searchParams } = this.props
		const { searchParams: prevSearchParams } = prevProps

		if (searchParams !== prevSearchParams) {
			this.setState({ filtersForms: searchParams })
		}
	}

	handleChangeTable = (type: string, pagination: any) => {
		const { authUser } = this.props
		const { filtersForms } = this.state
		this.props.searchVouchers({
			idEmpresa: authUser.userCompany,
			...filtersForms,
			page_number: pagination.page,
			page_size: pagination.sizePerPage
		})
	}

	/**
	 * to save in the state all filter data
	 * @param filtersForms
	 */
	handleSetFilterForms = (filtersForms: object) => {
		this.setState({ filtersForms })
	}

	render() {
		const { t, fieldsConfig } = this.props
		const { filtersForms } = this.state

		const fieldsTable = fieldsConfig
			? getFiltersData(fieldsConfig.campos, { agrupador: 'grilla' })
			: []

		const fieldsForms = fieldsConfig
			? getExcludeFiltersData(fieldsConfig.campos, { agrupador: 'grilla' })
			: []

		return (
			<CrudsContainer>
				<Row className={'pt-4 pb-2'}>
					<Col>
						<Card.Title>{t('search_voucher.title')}</Card.Title>
					</Col>
				</Row>
				{fieldsConfig && (
					<Row>
						<VoucherSearchForm
							t={t}
							fields={fieldsForms}
							selectedTab={BASIC_TAB}
							setFilters={this.handleSetFilterForms}
							searchParams={filtersForms}
						/>
					</Row>
				)}
				<div className="mt-3">
					<SearchVoucherTable
						fields={fieldsTable}
						handleChangeTable={this.handleChangeTable}
					/>
				</div>
			</CrudsContainer>
		)
	}
}
const mapStateToProps = ({ configCrud, auth, searchVoucher }: any) => {
	const { fieldsConfig } = configCrud
	const { authUser } = auth
	const { searchParams } = searchVoucher
	return { fieldsConfig, authUser, searchParams }
}

const connectForm = connect(mapStateToProps, {
	getConfigFieldsCrud,
	searchVouchers,
	clearSearchVoucherParams
})(SearchVoucher)

export default withTranslation()(withMenu(withRouter(connectForm)))
