import {
	crudGetCustomerCodeConfig,
	crudValidateCustomerCode,
	getConfigFieldsCrud,
	getTableBranchOffices,
	getTableConceptsBackgrounds,
	getTableCountry,
	getTableCouriers,
	getTableCredit,
	getTableCustomerCategory,
	getTableDocumentType,
	getTableForeignCurrencies,
	getTableGenerationType,
	getTableIsCUITRepeated,
	getTableIsDNIRepeated,
	getTableIsPostalCodeValid,
	getTableIsTaxesIdValid,
	getTablePriceList,
	getTableProvince,
	getTableResponsibleType,
	getTableSellers,
	getTableTermsOfSales,
	getTableVoucherTypeAdmin
} from 'actions'
import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import NotificationError from 'components/common/notificationsErrors'
import { FormRefsContext } from 'context/FormRefsContext'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { ICrudCustomerField } from 'models/CrudCustomerInterface'
import {
	IGetTableBranchOfficesResArray,
	IGetTableConceptsBackgroundResArray,
	IGetTableCountryResArray,
	IGetTableCouriersResArray,
	IGetTableCredit,
	IGetTableCustomerCategoryResArray,
	IGetTableDocumentTypeResArray,
	IGetTableForeignCurrenciesResArray,
	IGetTableGenerationTypeResArray,
	IGetTablePriceListRes,
	IGetTablePriceListResArray,
	IGetTableProvinceResArray,
	IGetTableResponsibleTypeResArray,
	IGetTableSellersResArray,
	IGetTableTermOfSalesResArray,
	IGetTableVoucherTypeResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import * as Yup from 'yup'
import CrudCustomerDataField from './CrudCustomerDataField'
import { ISubGroup } from './CrudCustomerTabsDataContents'

interface CrudCustomerDataProps extends WithTranslation {
	getConfigFieldsCrud: any
	getTableCustomerCategory: any
	getTableResponsibleType: any
	getTableDocumentType: any
	getTableProvince: any
	getTableIsPostalCodeValid: any
	getTableCountry: any
	getTableCouriers: any
	getTableTermsOfSales: any
	getTableSellers: any
	getTablePriceList: any
	getTableCredit: any
	getTableForeignCurrencies: any
	getTableConceptsBackgrounds: any
	getTableBranchOffices: any
	getTableGenerationType: any
	getTableVoucherTypeAdmin: any
	getTableIsTaxesIdValid: any
	getTableIsDNIRepeated: any
	getTableIsCUITRepeated: any
	crudGetCustomerCodeConfig: any
	crudValidateCustomerCode: any
	customerCategory: IGetTableCustomerCategoryResArray
	responsibleType: IGetTableResponsibleTypeResArray
	documentType: IGetTableDocumentTypeResArray
	province: IGetTableProvinceResArray
	country: IGetTableCountryResArray
	couriers: IGetTableCouriersResArray
	termsOfSales: IGetTableTermOfSalesResArray
	sellers: IGetTableSellersResArray
	priceList: IGetTablePriceListResArray
	credit: IGetTableCredit
	foreignCurrencies: IGetTableForeignCurrenciesResArray
	conceptsBackgrounds: IGetTableConceptsBackgroundResArray
	branchOffices: IGetTableBranchOfficesResArray
	generationType: IGetTableGenerationTypeResArray
	voucherType: IGetTableVoucherTypeResArray
	customerCodeConfig: any
	customerCodeValidated: any
	isPostalCodeValid: number
	isTaxesIdValid: boolean
	disableForm: boolean
	fields: Array<ICrudCustomerField>
	showMessage: any
	t: any
	handleSubmit: any
	itemInfo?: any
	handleCloseModal: any
	method: string
	handleWarning: any
	showWarning: boolean
	setFormChanged: any
	subGroups: Array<ISubGroup>
}

type CrudCustomerDataState = {
	showCodePopup: boolean
	values?: any
	priceList2: IGetTablePriceListResArray
}

class CrudCustomerData extends Component<
	CrudCustomerDataProps,
	CrudCustomerDataState
> {
	constructor(props: CrudCustomerDataProps) {
		super(props)
		this.state = {
			priceList2: {
				lista: [],
				resultado: {
					Resultado: false,
					Tipo_error: '',
					Mens_error: '',
					Errores: []
				}
			},
			showCodePopup: false
		}
	}

	// Initial dropdown setup
	componentDidMount = () => {
		this.props.getTableCustomerCategory()
		this.props.getTableResponsibleType({ por_defecto: 'I' })
		this.props.getTableDocumentType()
		this.props.getTableProvince()
		this.props.getTableCountry({ por_defecto: 'ARG' })
		this.props.getTableCouriers()
		this.props.getTableTermsOfSales()
		this.props.getTableSellers()
		this.props.getTablePriceList()
		this.props.getTableForeignCurrencies()
		this.props.getTableConceptsBackgrounds({ por_defecto: 'IIVT' })
		this.props.getTableBranchOffices()
		this.props.getTableGenerationType()
		this.props.getTableVoucherTypeAdmin()
	}

	componentDidUpdate = (prevProps: CrudCustomerDataProps) => {
		const { customerCodeValidated } = this.props
		const { customerCodeValidated: prevCustomerCodeValidated } = prevProps
		if (
			customerCodeValidated &&
			customerCodeValidated !== prevCustomerCodeValidated
		) {
			this.setState({ showCodePopup: false })
		}
	}

	// Handle price list 2 values, to hide the selected one in price list 1
	handlePriceList2 = (selectedPrice: any) => {
		const { priceList } = this.props
		const priceList2: Array<IGetTablePriceListRes> = []
		if (!_.isEmpty(selectedPrice) && priceList) {
			priceList.lista.forEach((price: any) => {
				if (selectedPrice !== price.codigo) {
					priceList2.push(price)
				}
			})
			this.setState({
				priceList2: {
					lista: priceList2,
					resultado: priceList.resultado
				}
			})
		}
	}

	handleCodePopup = (showCodePopup: boolean) => {
		const { customerCategory } = this.props
		customerCategory && this.setState({ showCodePopup })
	}

	render() {
		const {
			fields,
			t,
			handleSubmit,
			itemInfo,
			disableForm,
			handleWarning,
			showWarning,
			setFormChanged,
			isPostalCodeValid,
			isTaxesIdValid,
			method,
			subGroups
		} = this.props

		const { showCodePopup } = this.state

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})

		const columnsCancel = disableForm ? 12 : 6
		const validationSchema = fields.length
			? getValidationSchema(
					fields,
					t,
					[],
					[
						{
							id: 'cuit',
							validation: Yup.string()
								.when(['tipo_resp'], {
									is: (tipo_resp) => tipo_resp !== 'CF',
									then: Yup.string().test(
										'ValidCuit',
										t('validation-invalid', { field: 'CUIT' }),
										(cuit: any) => {
											return isTaxesIdValid === true
										}
									)
								})
								.nullable()
						},
						{
							id: 'cpos',
							validation: Yup.string()
								.test(
									'ValidCpos',
									t('validation-invalid', { field: 'Cod.Postal' }),
									(cpos: any) => {
										return isPostalCodeValid === 1
									}
								)
								.nullable()
						}
					]
			  )
			: {}

		const allFields = _.map(subGroups, (subGroup) => {
			const id = subGroup.groupId
			const filterFields = fields.filter((field: ICrudCustomerField) => {
				return field.agrupador === subGroup.groupId
			})
			return { name: id, fields: filterFields, rights: subGroup.accessRight }
		})

		return (
			<FormRefsContext.Consumer>
				{({ formRef, cancelButtonRef }) => (
					<Fragment>
						<Formik
							ref={formRef}
							initialValues={initialValues}
							onSubmit={(values, actions) => {
								handleSubmit(values)
							}}
							enableReinitialize={true}
							validationSchema={validationSchema}
						>
							{(props: FormikProps<any>) => (
								<Fragment>
									<Col sm={12}>
										<NotificationError
											errors={props.errors}
											touched={props.touched}
										/>
									</Col>
									<Form
										onChange={(e) => {
											setFormChanged(true) // used by confirm modal to check changed status
										}}
									>
										{(method === 'add' ||
											((method === 'edit' || method === 'view') && itemInfo)) &&
											_.map(
												allFields,
												(category: {
													name: string
													fields: Array<ICrudCustomerField>
													rights: string
												}) => {
													return (
														<Fragment key={category.name}>
															{category.rights !== 'N' && (
																<Alert variant={'dark'}>
																	<b>
																		{t(
																			'CRUDS.general_use.' +
																				category.name.replace('.', '')
																		)}
																	</b>
																</Alert>
															)}
															{_.map(
																category.fields,
																(field: ICrudCustomerField) => {
																	return (
																		<CrudCustomerDataField
																			key={field.idCampo}
																			formikProps={props}
																			field={field}
																			showCodePopup={showCodePopup}
																			handleCodePopup={this.handleCodePopup}
																			crudProps={{
																				...this.props,
																				priceList2: this.state.priceList2,
																				handlePriceList2: this.handlePriceList2
																			}}
																			initialValue={
																				initialValues[
																					field.idCampo as keyof typeof initialValues
																				]
																			}
																			accessRights={category.rights}
																		/>
																	)
																}
															)}
														</Fragment>
													)
												}
											)}
										<Row className={'pt-3'}>
											{!disableForm && (
												<Col sm={6} className={'text-center mt-2'}>
													<Button style={{ minWidth: '145px' }} type={'submit'}>
														{t('Collection.form.buttons.accept')}
													</Button>
												</Col>
											)}
											<Col sm={columnsCancel} className={'text-center mt-2'}>
												<Button
													style={{ minWidth: '145px' }}
													type={'button'}
													onClick={this.props.handleCloseModal}
													ref={cancelButtonRef}
												>
													{t('Collection.form.buttons.cancel')}
												</Button>
											</Col>
										</Row>
										<ConfirmModalNoButton
											modalTitle={t('CRUDS.modal.title_warning')}
											messageBody={t('CRUDS.modal.message_changes')}
											showModal={showWarning}
											handleCloseModal={() => handleWarning(false, 'close')}
											handleCancelButton={() => {
												handleWarning(false, 'cancel')
											}}
											handleSubmitButton={(e: any) => {
												props.handleSubmit(e as any)
												handleWarning(false, 'submit')
											}}
										/>
									</Form>
								</Fragment>
							)}
						</Formik>
					</Fragment>
				)}
			</FormRefsContext.Consumer>
		)
	}
}

const mapStateToProps = ({
	tableCalls,
	validationResponses,
	customerCode
}: any) => {
	const {
		customerCategory,
		responsibleType,
		documentType,
		province,
		country,
		couriers,
		termsOfSales,
		sellers,
		priceList,
		credit,
		foreignCurrencies,
		conceptsBackgrounds,
		branchOffices,
		generationType,
		voucherType
	} = tableCalls
	const { isPostalCodeValid, isTaxesIdValid } = validationResponses
	const { customerCodeConfig, customerCodeValidated } = customerCode
	return {
		customerCategory,
		responsibleType,
		documentType,
		province,
		country,
		couriers,
		termsOfSales,
		sellers,
		priceList,
		credit,
		foreignCurrencies,
		conceptsBackgrounds,
		branchOffices,
		generationType,
		voucherType,
		isPostalCodeValid,
		isTaxesIdValid,
		customerCodeConfig,
		customerCodeValidated
	}
}

const connectForm = connect(mapStateToProps, {
	getConfigFieldsCrud,
	getTableCustomerCategory,
	getTableResponsibleType,
	getTableDocumentType,
	getTableProvince,
	getTableIsPostalCodeValid,
	getTableCountry,
	getTableCouriers,
	getTableTermsOfSales,
	getTableSellers,
	getTablePriceList,
	getTableCredit,
	getTableForeignCurrencies,
	getTableConceptsBackgrounds,
	getTableBranchOffices,
	getTableGenerationType,
	getTableVoucherTypeAdmin,
	getTableIsTaxesIdValid,
	getTableIsDNIRepeated,
	getTableIsCUITRepeated,
	crudGetCustomerCodeConfig,
	crudValidateCustomerCode
})(CrudCustomerData)

export default withTranslation()(withRouter(connectForm))
