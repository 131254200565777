import { TreasuryHeaderActions } from 'constants/ActionsTypesTs'
import { ICommonInitialState } from 'models/CommonInterface'
import {
	IGetAttributesTreasuryHeaderResponse,
	IGetTreasuryCurrenciesResponse
} from 'models/TreasuryHeader'
import { AnyAction } from 'redux'

export interface IFormAttributesStore extends ICommonInitialState {
	data: IGetAttributesTreasuryHeaderResponse | null
}

export interface ICurrencyListStore extends ICommonInitialState {
	data: IGetTreasuryCurrenciesResponse | null
}

export interface ITreasuryHeaderState {
	formAttributes: IFormAttributesStore
	currencyList: ICurrencyListStore
}

const initialState: ITreasuryHeaderState = {
	formAttributes: {
		data: null,
		status: 'idle',
		error: null
	},
	currencyList: {
		data: null,
		status: 'idle',
		error: null
	}
}

function TreasuryHeaderReducer(
	state: ITreasuryHeaderState = initialState,
	action: AnyAction
): ITreasuryHeaderState {
	switch (action.type) {
		case TreasuryHeaderActions.GET_ATTRIBUTES_TREASURY_HEADER:
			return {
				...state,
				formAttributes: {
					status: 'loading',
					data: null,
					error: null
				}
			}
		case TreasuryHeaderActions.GET_ATTRIBUTES_TREASURY_HEADER_SUCCESS:
			return {
				...state,
				formAttributes: {
					status: 'succeeded',
					data: action.payload,
					error: null
				}
			}
		case TreasuryHeaderActions.GET_ATTRIBUTES_TREASURY_HEADER_ERROR:
			return {
				...state,
				formAttributes: {
					status: 'failed',
					error: action.payload,
					data: null
				}
			}
		case TreasuryHeaderActions.GET_TREASURY_CURRENCIES:
			return {
				...state,
				currencyList: {
					status: 'loading',
					error: action.payload,
					data: null
				}
			}
		case TreasuryHeaderActions.GET_TREASURY_CURRENCIES_SUCCESS:
			return {
				...state,
				currencyList: {
					status: 'succeeded',
					data: action.payload,
					error: null
				}
			}
		case TreasuryHeaderActions.GET_TREASURY_CURRENCIES_ERROR:
			return {
				...state,
				currencyList: {
					status: 'failed',
					error: action.payload,
					data: null
				}
			}

		default:
			return { ...state }
	}
}

export default TreasuryHeaderReducer
