import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment, PureComponent } from 'react'
import { Alert } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import _ from 'underscore'
import styles from './notificationMessage.module.css'
class NotificationMessage extends PureComponent {
	render() {
		const {
			showError,
			handleCloseError,
			errorTitle,
			errorMessage,
			type,
			theme
		} = this.props
		const typeNotification = type || 'success'
		const fontIcon =
			typeNotification === 'success' ? faCheckCircle : faTimesCircle
		return (
			<Alert
				show={showError}
				className={theme.alertContainer}
				variant={typeNotification}
				onClose={handleCloseError}
				dismissible
			>
				<Alert.Heading>{errorTitle}</Alert.Heading>

				{_.isArray(errorMessage) ? (
					errorMessage.map((error, index) => {
						return (
							<div key={index} className={'d-flex align-items-center'}>
								<FontAwesomeIcon
									style={{ width: '25px', height: '25px' }}
									icon={fontIcon}
									className={'highlight pr-1'}
								/>
								<span className={'p-1'}>{error}</span>
							</div>
						)
					})
				) : (
					<Fragment>
						<div className={'d-flex align-items-center'}>
							<FontAwesomeIcon
								style={{ width: '25px', height: '25px' }}
								icon={fontIcon}
								className={'highlight pr-1'}
							/>
							<span className={'p-1'}>{errorMessage}</span>
						</div>
					</Fragment>
				)}
			</Alert>
		)
	}
}
export default themr('NotificationMessageStyle', styles)(NotificationMessage)
