export enum UploadFilesActions {
	UPLOAD_FILES = 'UPLOAD_FILES',
	UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS',
	UPLOAD_FILES_ERROR = 'UPLOAD_FILES_ERROR',
	GET_TEMP_DOCUMENT_LIST = 'GET_TEMP_DOCUMENT_LIST',
	GET_TEMP_DOCUMENT_LIST_SUCCESS = 'GET_TEMP_DOCUMENT_LIST_SUCCESS',
	GET_TEMP_DOCUMENT_LIST_ERROR = 'GET_TEMP_DOCUMENT_LIST_ERROR',
	REMOVE_TEMP_DOCUMENT = 'REMOVE_TEMP_DOCUMENT',
	REMOVE_TEMP_DOCUMENT_SUCCESS = 'REMOVE_TEMP_DOCUMENT_SUCCESS',
	REMOVE_DOCUMENT = 'REMOVE_DOCUMENT',
	REMOVE_DOCUMENT_SUCCESS = 'REMOVE_DOCUMENT_SUCCESS',
	GET_DOCUMENT_LIST = 'GET_DOCUMENT_LIST',
	GET_DOCUMENT_LIST_SUCCESS = 'GET_DOCUMENT_LIST_SUCCESS',
	GET_DOCUMENT_LIST_ERROR = 'GET_DOCUMENT_LIST_ERROR',
	DOWNLOAD_FILE = 'DOWNLOAD_FILE',
	DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS',
	DOWNLOAD_FILE_ERROR = 'DOWNLOAD_FILE_ERROR'
}

export enum HeaderDataValidationActions {
	GET_VALIDATIONS = 'GET_VALIDATIONS',
	GET_VALIDATIONS_SUCCESS = 'GET_VALIDATIONS_SUCCESS',
	CONFIRM_VALIDATIONS = 'CONFIRM_VALIDATIONS',
	CONFIRM_VALIDATIONS_SUCCESS = 'CONFIRM_VALIDATIONS_SUCCESS'
}

export enum CalculationBonusesActions {
	GET_BONUSES = 'GET_BONUSES',
	GET_BONUSES_SUCCESS = 'GET_BONUSES_SUCCESS',
	CONFIRM_BONUSES = 'CONFIRM_BONUSES',
	CONFIRM_BONUSES_SUCCESS = 'CONFIRM_BONUSES_SUCCESS'
}

export enum HeaderEntryActions {
	GET_FORM_ATTRIBUTES = 'GET_FORM_ATTRIBUTES',
	GET_FORM_ATTRIBUTES_SUCCESS = 'GET_FORM_ATTRIBUTES_SUCCESS',
	CONFIRM_ATTRIBUTES = 'CONFIRM_HEADER_ATTRIBUTES',
	CONFIRM_ATTRIBUTES_SUCCESS = 'CONFIRM_HEADER_ATTRIBUTES_SUCCESS'
}

export enum InventoryPickStockActions {
	INVENTORY_PICK_BAR_CODE_SEARCH = 'INVENTORY_PICK_BAR_CODE_SEARCH',
	INVENTORY_PICK_BAR_CODE_SEARCH_SUCCESS = 'INVENTORY_PICK_BAR_CODE_SEARCH_SUCCESS',
	INVENTORY_PICK_BAR_CODE_SEARCH_ERROR = 'INVENTORY_PICK_BAR_CODE_SEARCH_ERROR',
	GET_INVENTORY_PICK_STOCK = 'GET_INVENTORY_PICK_STOCK',
	GET_INVENTORY_PICK_STOCK_SUCCESS = 'GET_INVENTORY_PICK_STOCK_SUCCESS',
	GET_CUF_PICK_STOCK = 'GET_CUF_PICK_STOCK',
	GET_CUF_PICK_STOCK_SUCCESS = 'GET_CUF_PICK_STOCK_SUCCESS',
	GET_DEPOSITS_PICK_STOCK = 'GET_DEPOSITS_PICK_STOCK',
	GET_DEPOSITS_PICK_STOCK_SUCCESS = 'GET_DEPOSITS_PICK_STOCK_SUCCESS',
	CONFIRM_INVENTORY_PICK_STOCK = 'CONFIRM_INVENTORY_PICK_STOCK',
	CONFORM_INVENTORY_PICK_STOCK_SUCCESS = 'CONFORM_INVENTORY_PICK_STOCK_SUCCESS'
}

export enum InventoryExpensesStockActions {
	INVENTORY_PICK_BAR_CODE_SEARCH = 'INVENTORY_PICK_BAR_CODE_SEARCH',
	INVENTORY_PICK_BAR_CODE_SEARCH_SUCCESS = 'INVENTORY_PICK_BAR_CODE_SEARCH_SUCCESS',
	INVENTORY_PICK_BAR_CODE_SEARCH_ERROR = 'INVENTORY_PICK_BAR_CODE_SEARCH_ERROR',
	GET_INVENTORY_PICK_STOCK = 'GET_INVENTORY_PICK_STOCK',
	GET_INVENTORY_PICK_STOCK_SUCCESS = 'GET_INVENTORY_PICK_STOCK_SUCCESS',
	GET_CUF_PICK_EXPENSES = 'GET_CUF_PICK_EXPENSES',
	GET_CUF_PICK_EXPENSES_SUCCESS = 'GET_CUF_PICK_EXPENSES_SUCCESS',
	GET_DEPOSITS_PICK_EXPENSES = 'GET_DEPOSITS_PICK_EXPENSES',
	GET_DEPOSITS_PICK_EXPENSES_SUCCESS = 'GET_DEPOSITS_PICK_EXPENSES_SUCCESS',
	CONFIRM_INVENTORY_PICK_STOCK = 'CONFIRM_EXPENSES_INVENTORY_PICK_STOCK',
	CONFIRM_INVENTORY_PICK_STOCK_SUCCESS = 'CONFIRM_EXPENSES_INVENTORY_PICK_STOCK_SUCCESS'
}

export enum LoadPackageActions {
	GET_PACKAGES = 'GET_PACKAGES',
	GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS',
	GET_PACKAGES_ERROR = 'GET_PACKAGES_ERROR',
	CONFIRM_PACKAGES = 'CONFIRM_PACKAGES',
	CONFIRM_PACKAGES_SUCCESS = 'CONFIRM_PACKAGES_SUCCESS',
	CONFIRM_PACKAGES_ERROR = 'CONFIRM_PACKAGES_ERROR'
}

export enum LoadInventoryItemsActions {
	LOAD_ITEM_INVENTORY = 'LOAD_ITEM_INVENTORY',
	LOAD_ITEM_INVENTORY_SUCCESS = 'LOAD_ITEM_INVENTORY_SUCCESS',
	LOAD_ITEM_INVENTORY_ERROR = 'LOAD_ITEM_INVENTORY_ERROR',
	LOAD_ITEM_INCOME_INVENTORY = 'LOAD_ITEM_INCOME_INVENTORY',
	LOAD_ITEM_INCOME_INVENTORY_SUCCESS = 'LOAD_ITEM_INCOME_INVENTORY_SUCCESS',
	LOAD_ITEM_INCOME_INVENTORY_ERROR = 'LOAD_ITEM_INCOME_INVENTORY_ERROR',
	LOAD_ITEM_INVENTORY_CONFIRM = 'LOAD_ITEM_INVENTORY_CONFIRM',
	LOAD_ITEM_INVENTORY_CONFIRM_SUCCESS = 'LOAD_ITEM_INVENTORY_CONFIRM_SUCCESS',
	LOAD_ITEM_INVENTORY_CONFIRM_ERROR = 'LOAD_ITEM_INVENTORY_CONFIRM_ERROR',
	FORM_LOAD_ITEM_INVENTORY = 'FORM_LOAD_ITEM_INVENTORY',
	FORM_LOAD_INCOME_ITEM_INVENTORY = 'FORM_LOAD_INCOME_ITEM_INVENTORY',
	FORM_LOAD_ITEM_FORM = 'LOAD_ITEM',
	FORM_LOAD_ITEM_PROVIDER = 'FORM_LOAD_ITEM_PROVIDER'
}

export enum TreasuryHeaderActions {
	GET_ATTRIBUTES_TREASURY_HEADER = 'GET_ATTRIBUTES_TREASURY_HEADER',
	GET_ATTRIBUTES_TREASURY_HEADER_SUCCESS = 'GET_ATTRIBUTES_TREASURY_HEADER_SUCCESS',
	GET_ATTRIBUTES_TREASURY_HEADER_ERROR = 'GET_ATTRIBUTES_TREASURY_HEADER_ERROR',
	CONFIRM_ATTRIBUTES_TREASURY_HEADER = 'CONFIRM_ATTRIBUTES_TREASURY_HEADER',
	CONFIRM_ATTRIBUTES_TREASURY_HEADER_SUCCESS = 'CONFIRM_ATTRIBUTES_TREASURY_HEADER_SUCCESS',
	CONFIRM_ATTRIBUTES_TREASURY_HEADER_ERROR = 'CONFIRM_ATTRIBUTES_TREASURY_HEADER_ERROR',
	GET_TREASURY_CURRENCIES = 'GET_TREASURY_CURRENCIES',
	GET_TREASURY_CURRENCIES_SUCCESS = 'GET_TREASURY_CURRENCIES_SUCCESS',
	GET_TREASURY_CURRENCIES_ERROR = 'GET_TREASURY_CURRENCIES_ERROR'
}
