import { ICrudGenericFields } from 'models/CrudInterface'

export const customerAttrFields: Array<ICrudGenericFields> = [
	{
		id: 'catributo',
		type: 'combo',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'catributoValues',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'des_valor',
		type: 'textArea',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: 'listState',
			values: [
				{
					idValue: true,
					disabled: true
				}
			]
		}
	},
	{
		id: 'cod_valor',
		type: 'combo',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'catributoListValues',
		dependsOn: {
			id: 'listState',
			values: [
				{
					idValue: false,
					disabled: true
				}
			]
		}
	}
]
