import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	getCufsPickExpenses,
	getCufsPickStock,
	getDepositsPickExpenses,
	getDepositsPickStock,
	searchProducts
} from 'actions'
import InputCheckBox from 'components/form/InputCheckBox'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { Formik } from 'formik'
import React, { Fragment, PureComponent } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
class SearchBox extends PureComponent {
	searchBoxFormRef = React.createRef()

	constructor(props) {
		super(props)
		this.state = {
			isLoading: false
		}
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.getDepositsPickExpenses({ idOperacion })
		this.props.getDepositsPickStock({ idOperacion })
	}

	componentDidUpdate = (prevProps) => {
		const { search, searchParameters } = this.props
		if (search !== prevProps.search && search && search.productos) {
			this.setState({ isLoading: false })
		}

		if (searchParameters !== prevProps.searchParameters && searchParameters) {
			this.setState({ cod_bonif: searchParameters.cod_bonif })
		}
	}

	handleSearch = (values) => {
		const { desc_prod, cod_prod, cod_bonif, stock } = values
		const { idOperacion, currentCuf } = this.props
		this.setState({ isLoading: true })
		const paramsApi = {
			idOperacion,
			desc_prod,
			cod_prod,
			page_number: 1,
			page_size: 10,
			con_stock: stock,
			cod_bonif
		}

		if (!_.isEmpty(values.cuf_dest)) {
			paramsApi.cuf_dest = values.cuf_dest
		}

		if (values[currentCuf]) {
			paramsApi.cuf = values[currentCuf]
		}

		this.props.searchProducts(paramsApi)

		if (this.props.handleSetCuf) {
			this.props.handleSetCuf(values[currentCuf]) // set cuf selected
		}
	}

	handleOnChangeCheckbox = (event) => {
		this.setState({ stock: event.target.checked })
	}

	handleSelect = (selected) => {
		// console.log(selected, 'cuando seleccion')
	}

	getOptionsDeal = () => {
		const { bonuses } = this.props
		return _.isEmpty(bonuses)
			? []
			: bonuses.map((bonus) => {
					return { id: bonus.cod_bonif, label: bonus.desc_bon }
			  })
	}

	handleChangeBonus = () => {
		setTimeout(() => {
			this.searchBoxFormRef.current.executeSubmit()
		}, 500)
	}

	/**
	 * handle change deposit of origin
	 * @param {*} event
	 */
	handleChangeDepDestination = (event) => {
		const { idOperacion } = this.props
		const { value } = event.target

		this.props.getCufsPickStock({
			cod_dep: value,
			idOperacion
		})
	}

	/**
	 * handle change deposit of origin
	 * @param {*} event
	 */
	handleChangeDepOrigin = (event) => {
		const { idOperacion } = this.props
		const { value } = event.target

		this.props.getCufsPickExpenses({
			cod_dep: value,
			idOperacion
		})
	}

	render() {
		const {
			hideStock,
			t,
			inputRef,
			fields,
			depositList,
			cufList,
			pickStockListDeposits,
			pickStockListCufs
		} = this.props
		const { isLoading } = this.state

		const depOptionsDest = !_.isEmpty(pickStockListDeposits)
			? pickStockListDeposits.lista_dep.map((dept) => ({
					id: dept.codigo,
					label: dept.descrip
			  }))
			: []

		const cufOptionsDest = !_.isEmpty(pickStockListCufs)
			? pickStockListCufs.lista.map((cuf) => ({
					id: cuf.codigo,
					label: cuf.descrip
			  }))
			: []

		const depOptionsOrigin = !_.isEmpty(depositList)
			? depositList.lista_dep.map((dept) => ({
					id: dept.codigo,
					label: dept.descrip
			  }))
			: []

		const cufOptionsOrigin = !_.isEmpty(cufList)
			? cufList.lista.map((cuf) => ({
					id: cuf.codigo,
					label: cuf.descrip
			  }))
			: []

		const initialValues = {
			desc_prod: '',
			cod_prod: '',
			stock: false,
			cod_bonif: '',
			depo_dest: '',
			cuf_dest: '',
			depo_ori: '',
			cuf_ori: '',
			deals: ''
		}

		const inputConfig = [
			{
				idCampo: 'desc_prod',
				label: false,
				visible: 1,
				requerido: 0,
				editable: 1
			},
			{
				idCampo: 'cod_prod',
				label: false,
				visible: 1,
				requerido: 0,
				editable: 1
			},
			{
				idCampo: 'stock',
				label: false,
				visible: 1,
				requerido: 0,
				editable: 1
			},
			{
				idCampo: 'deals',
				label: false,
				visible: 1,
				requerido: 0,
				editable: 1
			}
		]

		return (
			<Fragment>
				<Formik
					ref={this.searchBoxFormRef}
					onSubmit={(values, actions) => {
						this.handleSearch(values)
					}}
					initialValues={{ ...initialValues }}
					enableReinitialize={true}
					render={({
						values,
						handleBlur,
						handleChange,
						handleSubmit,
						setFieldValue
					}) => (
						<Form onSubmit={handleSubmit} className="voucher-info-form col-12">
							<Row>
								<InputDropdown
									inputFormCol={{ sm: 6 }}
									fields={fields}
									label={'deposit'}
									inputId={'depo_ori'}
									name={'depo_ori'}
									styles={{ width: '100%' }}
									colLabel={'col-sm-3'}
									colInput={'col-sm-9'}
									options={depOptionsOrigin}
									handleBlur={handleBlur}
									value={values.depo_ori}
									tooltip
									onChange={(event) => {
										setFieldValue('depo_ori', event.target.value)
										this.handleChangeDepOrigin(event)
									}}
								/>
								<InputDropdown
									inputFormCol={{ sm: 6 }}
									fields={fields}
									label={'location'}
									inputId={'cuf_ori'}
									name={'cuf_ori'}
									styles={{ width: '100%' }}
									colLabel={'col-sm-3'}
									colInput={'col-sm-8'}
									options={cufOptionsOrigin}
									value={values.cuf_ori}
									tooltip
									onChange={(event) => {
										setFieldValue('cuf_ori', event.target.value)
									}}
								/>
							</Row>
							<Row>
								<InputDropdown
									inputFormCol={{ sm: 6 }}
									fields={fields}
									label={'deposit'}
									inputId={'depo_dest'}
									name={'depo_dest'}
									styles={{ width: '100%' }}
									colLabel={'col-sm-3'}
									colInput={'col-sm-9'}
									options={depOptionsDest}
									handleBlur={handleBlur}
									value={values.depo_dest}
									tooltip
									onChange={(event) => {
										setFieldValue('depo_dest', event.target.value)
										this.handleChangeDepDestination(event)
									}}
								/>
								<InputDropdown
									inputFormCol={{ sm: 6 }}
									fields={fields}
									label={'location'}
									inputId={'cuf_dest'}
									name={'cuf_dest'}
									styles={{ width: '100%' }}
									colLabel={'col-sm-3'}
									colInput={'col-sm-8'}
									options={cufOptionsDest}
									value={values.cuf_dest}
									tooltip
									onChange={(event) => {
										setFieldValue('cuf_dest', event.target.value)
									}}
								/>
							</Row>
							<Row>
								<InputText
									inputFormCol={{ sm: 5 }}
									label={false}
									fwRef={inputRef}
									fields={inputConfig}
									inputId={'desc_prod'}
									name={'desc_prod'}
									styles={{ width: '100%' }}
									placeholder={t('searchBox.form.insert_product')}
									colLabel={'col-sm-2'}
									colInput={'col-sm-10'}
									disable={false}
									value={values.desc_prod}
									allowClear
									isLoading={isLoading}
									onChange={(data) => {
										setFieldValue('desc_prod', data)
										handleChange()
									}}
								/>
								<InputText
									inputFormCol={{ sm: 3 }}
									label={false}
									fields={inputConfig}
									placeholder={t('searchBox.form.insert_code')}
									inputId={'cod_prod'}
									name={'cod_prod'}
									styles={{ width: '100%' }}
									colLabel={'col-sm-2'}
									colInput={'col-sm-10'}
									disable={false}
									value={values.cod_prod}
									allowClear
									isLoading={isLoading}
									onChange={(data) => {
										setFieldValue('cod_prod', data)
										handleChange()
									}}
								/>
								{!hideStock && (
									<InputCheckBox
										inputFormCol={{ sm: 'auto' }}
										type={'checkbox'}
										fields={inputConfig}
										label={'Con stock'}
										inputId={'stock'}
										name={'stock'}
										colLabel={'col-sm-9'}
										colInput={'col-sm-2'}
										checked={values.stock}
										onChange={(checked) => {
											setFieldValue('stock', checked)
										}}
									/>
								)}
								<Col sm={'auto'} className={'text-left'}>
									<Button
										style={{
											color: '#000',
											backgroundColor: '#fff',
											borderColor: '#fff',
											position: 'absolute',
											top: '-3px'
										}}
										type={'submit'}
									>
										<FontAwesomeIcon icon={faSearch} />
									</Button>
								</Col>
								<Col sm={2}>
									<InputDropdown
										inputFormCol={{ sm: 12 }}
										noInitialExecute
										fields={inputConfig}
										label={false}
										inputId={'deals'}
										name={'deals'}
										value={values.cod_bonif}
										styles={{ width: '100%' }}
										styleLabel={{ textAlign: 'right' }}
										colLabel={'col-sm-3'}
										colInput={'col-sm-9'}
										options={this.getOptionsDeal()}
										onBlur={() => {}}
										onChange={(event) => {
											const { value } = event.target
											setFieldValue('cod_bonif', value)
											this.handleChangeBonus()
										}}
									/>
								</Col>
							</Row>
						</Form>
					)}
				/>
			</Fragment>
		)
	}
}

const mapStateToProps = ({
	product,
	inventoryPickExpenses,
	inventoryPickStock
}) => {
	const { search, searchParameters } = product
	const { depositList, cufList } = inventoryPickExpenses
	const { pickStockListDeposits, pickStockListCufs } = inventoryPickStock

	return {
		search,
		searchParameters,
		depositList,
		cufList,
		pickStockListDeposits,
		pickStockListCufs
	}
}

export default connect(mapStateToProps, {
	searchProducts,
	getDepositsPickExpenses,
	getCufsPickExpenses,
	getDepositsPickStock,
	getCufsPickStock
})(withTranslation()(SearchBox))
