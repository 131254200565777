import Axios from 'axios'
import { ICrudApplyLedgerAccountsPointers } from 'models/CrudLedgerAccountsInterface'
const urlCRUD = '/abm_cont_cuentas'

export const crudGetLedgerAccountsPointers = async () => {
	const response = await Axios.get(`${urlCRUD}/global/sel`)
	return response.data
}

export const crudApplyLedgerAccountsPointers = async (
	params: ICrudApplyLedgerAccountsPointers
) => {
	const response = await Axios.post(`${urlCRUD}/global/confirmar`, params)
	return response.data
}
