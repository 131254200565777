import {
	CRUD_ACCEPT_PRODUCT_CODE,
	CRUD_ACCEPT_PRODUCT_CODE_SUCCESS,
	CRUD_CLEAR_PRODUCT_CODE_STORE,
	CRUD_GET_PRODUCT_CODE,
	CRUD_GET_PRODUCT_CODE_CONFIG,
	CRUD_GET_PRODUCT_CODE_CONFIG_SUCCESS,
	CRUD_GET_PRODUCT_CODE_INFO,
	CRUD_GET_PRODUCT_CODE_INFO_SUCCESS,
	CRUD_GET_PRODUCT_CODE_SUCCESS,
	CRUD_VALIDATE_PRODUCT_CODE,
	CRUD_VALIDATE_PRODUCT_CODE_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	productCodeInfo: any
	productCodeAccepted: any
	productCodeConfig: any
	productCodeValidated: any
	productCodeLevelInfo: any
}

const initialState = {
	productCodeInfo: null,
	productCodeAccepted: null,
	productCodeConfig: null,
	productCodeValidated: null,
	productCodeLevelInfo: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_PRODUCT_CODE:
			return { ...state, productCodeInfo: null }
		case CRUD_GET_PRODUCT_CODE_SUCCESS:
			return { ...state, productCodeInfo: action.payload }
		case CRUD_GET_PRODUCT_CODE_INFO:
			return { ...state, productCodeLevelInfo: null }
		case CRUD_GET_PRODUCT_CODE_INFO_SUCCESS:
			return { ...state, productCodeLevelInfo: action.payload }
		case CRUD_GET_PRODUCT_CODE_CONFIG:
			return { ...state, productCodeConfig: null }
		case CRUD_GET_PRODUCT_CODE_CONFIG_SUCCESS:
			return { ...state, productCodeConfig: action.payload }
		case CRUD_VALIDATE_PRODUCT_CODE:
			return { ...state, productCodeValidated: null }
		case CRUD_VALIDATE_PRODUCT_CODE_SUCCESS:
			return { ...state, productCodeValidated: action.payload }
		case CRUD_ACCEPT_PRODUCT_CODE:
			return { ...state, productCodeAccepted: null }
		case CRUD_ACCEPT_PRODUCT_CODE_SUCCESS:
			return { ...state, productCodeAccepted: action.payload }
		case CRUD_CLEAR_PRODUCT_CODE_STORE:
			return {
				...state,
				productCodeConfig: null,
				productCodeInfo: null,
				productCodeValidated: null,
				productCodeCreated: null
			}
		default:
			return state
	}
}

export default rootReducer
