import Axios from 'axios'
import { IConfirmPackageParams, IGetPackagesParams } from 'models/LoadPackages'
const urlBase = 'bultos'

export const getPackagesAttributesApi = async (params: IGetPackagesParams) => {
	const response = await Axios.get(`${urlBase}`, { params })
	return response.data
}

export const confirmPackagesAttributesApi = async (
	params: IConfirmPackageParams
) => {
	const response = await Axios.post(`${urlBase}/confirmar`, params)
	return response.data
}
