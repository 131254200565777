import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { FormikProps } from 'formik'
import { handleSetValueInput } from 'lib/FormUtils'
import { IGetFormAttributesResponse } from 'models/HeaderEntry'
import moment from 'moment'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'
interface IHeaderEntryFormInputsProps {
	t: any
	fields: Array<IFieldStructure>
	formikProps: FormikProps<any>
	headerFormAttributes: IGetFormAttributesResponse
}

interface IHeaderEntryFormInputsState {}

class HeaderEntryFormInputs extends Component<
	IHeaderEntryFormInputsProps,
	IHeaderEntryFormInputsState
> {
	componentDidUpdate(prevProps: Readonly<IHeaderEntryFormInputsProps>): void {
		const { headerFormAttributes, formikProps } = this.props
		if (
			!_.isEqual(prevProps.headerFormAttributes, headerFormAttributes) &&
			!_.isEmpty(headerFormAttributes)
		) {
			const date = headerFormAttributes?.fecha
				? moment(new Date(`${headerFormAttributes.fecha}T00:00:00`))
				: new Date()
			formikProps.setFieldValue('cod_comps', headerFormAttributes.cod_comps)
			formikProps.setFieldValue('fecha', date)
			formikProps.setFieldValue('descrip', headerFormAttributes.descrip)
		}
	}

	render() {
		const { fields, formikProps, headerFormAttributes } = this.props
		const { values, handleBlur } = formikProps

		const propsVoucher = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'Nro. Interno',
			inputId: 'cod_comps',
			name: 'cod_comps',
			placeholder: '',
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			onBlur: handleBlur,
			value: values.cod_comps,
			onChange: (data: any) =>
				handleSetValueInput(data, 'cod_comps', formikProps)
		}

		const propsNumberVoucher = {
			inputFormCol: { sm: 9 },
			fields,
			label: 'Descripcion',
			inputId: 'descrip',
			name: 'descrip',
			placeholder: '',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			onBlur: handleBlur,
			value: values.descrip,
			onChange: (data: any) =>
				handleSetValueInput(data, 'descrip', formikProps),
			autoComplete: 'Off'
		}

		const propsDate = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'Fecha',
			inputId: 'fecha',
			name: 'fecha',
			placeholder: '',
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			onBlur: handleBlur,
			value: values.fecha,
			onChange: (data: any) =>
				handleSetValueInput(data, 'fecha', formikProps, 'date')
		}

		const sourceDepositsProps = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Depósitos',
			inputId: 'dep_orig',
			name: 'dep_orig',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.dep_orig,
			options: headerFormAttributes
				? headerFormAttributes.dep_orig.map((depOrg) => {
						return { id: depOrg.cod_dep, label: depOrg.descrip }
				  })
				: [],
			onChange: (data: any) => {
				handleSetValueInput(data, 'dep_orig', formikProps)
			}
		}

		const destinationDepositsProps = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Depósitos',
			inputId: 'dep_dest',
			name: 'dep_dest',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.dep_dest,
			options: headerFormAttributes
				? headerFormAttributes.dep_dest.map((depDest) => {
						return { id: depDest.cod_dep, label: depDest.descrip }
				  })
				: [],
			onChange: (data: any) =>
				handleSetValueInput(data, 'dep_dest', formikProps)
		}

		return (
			<Row>
				<Row className={`col-12`}>
					<InputText {...propsVoucher} />
					<InputText {...propsNumberVoucher} />
				</Row>
				<Row className={`col-12`}>
					<InputText {...propsDate} />
				</Row>
				<Row className={`col-12`}>
					<InputDropdown {...sourceDepositsProps} />
				</Row>
				<Row className={`col-12`}>
					<InputDropdown {...destinationDepositsProps} />
				</Row>
			</Row>
		)
	}
}

const mapStateToProps = ({ headerEntry }: any) => {
	const { headerFormAttributes } = headerEntry
	return { headerFormAttributes }
}

const mapDispatchToProps = {}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HeaderEntryFormInputs)
