import {
	CANCEL_ATTRIBUTES,
	CANCEL_ATTRIBUTES_SUCCESS,
	CONFIRM_ATTRIBUTES,
	CONFIRM_ATTRIBUTES_SUCCESS,
	GET_ATTRIBUTES,
	GET_ATTRIBUTES_SUCCESS
} from 'constants/ActionsTypes'
import {
	ICancelAttributesAction,
	ICancelAttributesParams,
	IConfirmAttributeAction,
	IConfirmAttributesParams,
	IGetAttributesAction,
	IGetAttributesParams
} from 'models/Attributes'

export const getAttributes = (
	payload: IGetAttributesParams
): IGetAttributesAction => ({
	type: GET_ATTRIBUTES,
	payload
})

export const getAttributesSuccess = (payload: any) => ({
	type: GET_ATTRIBUTES_SUCCESS,
	payload
})

export const confirmAttributes = (
	payload: IConfirmAttributesParams
): IConfirmAttributeAction => ({
	payload,
	type: CONFIRM_ATTRIBUTES
})

export const confirmAttributesSuccess = (payload: any) => ({
	payload,
	type: CONFIRM_ATTRIBUTES_SUCCESS
})

export const cancelAttributes = (
	payload: ICancelAttributesParams
): ICancelAttributesAction => ({
	type: CANCEL_ATTRIBUTES,
	payload
})

export const cancelAttributesSuccess = (payload: any) => ({
	type: CANCEL_ATTRIBUTES_SUCCESS,
	payload
})
