import InputText from 'components/form/inputText'
import { FormikProps } from 'formik'
import React, { Component, Fragment } from 'react'
import { Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import fields from './FieldsPassword.json'

interface IChangePasswordFormProps {
	formikProps: FormikProps<any>
}

class ChangePasswordForm extends Component<IChangePasswordFormProps> {
	render() {
		const { values, setFieldValue, handleBlur } = this.props.formikProps

		const oldPasswordProps = {
			inputFormCol: { sm: 12 },
			type: 'password',
			fields,
			label: 'Fecha',
			inputId: 'old_password',
			name: 'old_password',
			placeholder: 'Password',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.old_password,
			autoComplete: 'off',
			onChange: (data: any) => setFieldValue('old_password', data)
		}

		const newPasswordProps = {
			inputFormCol: { sm: 12 },
			type: 'password',
			fields,
			label: 'Fecha',
			inputId: 'new_password',
			name: 'new_password',
			placeholder: 'Password',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.new_password,
			autoComplete: 'off',
			onChange: (data: any) => setFieldValue('new_password', data)
		}

		const repeatPasswordProps = {
			inputFormCol: { sm: 12 },
			type: 'password',
			fields,
			label: 'Fecha',
			inputId: 'repeat_password',
			name: 'repeat_password',
			placeholder: 'Password',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.repeat_password,
			autoComplete: 'off',
			onChange: (data: any) => setFieldValue('repeat_password', data)
		}

		return (
			<Fragment>
				<Row className={`col-12`}>
					<InputText {...oldPasswordProps} />
					<InputText {...newPasswordProps} />
					<InputText {...repeatPasswordProps} />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm)
