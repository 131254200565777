import { faPalette } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { SketchPicker } from 'react-color'

export default class colorPick extends Component {
	constructor(props) {
		super(props)
		this.state = {
			displayColorPicker: false,
			color: {
				r: '241',
				g: '112',
				b: '19',
				a: '1'
			}
		}
	}

	componentDidUpdate = (prevProps) => {
		const { closePick } = this.props
		if (prevProps.closePick !== closePick && !closePick) {
			this.setState({ displayColorPicker: false })
		}
	}

	/**
	 * to open and close the color picker
	 */
	handleClick = () => {
		const { displayColorPicker } = this.state

		if (!displayColorPicker) {
			this.props.closeAllPickers()
		}

		this.setState((state) => ({
			displayColorPicker: !state.displayColorPicker
		}))
	}

	render() {
		const { displayColorPicker } = this.state

		const popover = {
			position: 'absolute',
			zIndex: '2',
			left: '-25px'
		}
		return (
			<div>
				<div
					style={{
						textAlign: 'right',
						position: 'absolute',
						bottom: 15,
						right: 20,
						cursor: 'pointer',
						color: this.props.iconColor(this.props.color)
					}}
				>
					<FontAwesomeIcon
						className="fa-lg"
						icon={faPalette}
						onClick={this.handleClick}
					/>
				</div>
				{displayColorPicker ? (
					<div style={popover}>
						<SketchPicker
							color={this.props.color}
							onChange={this.props.handleChange}
						/>
					</div>
				) : null}
			</div>
		)
	}
}
