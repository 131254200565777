import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import AccountingSeat from 'containers/AccountingSeat'
import AccountingVoucher from 'containers/AccountingVoucher'
import Admin from 'containers/Admin'
import AffectationAmounts from 'containers/AffectationAmounts'
import AutoGenerateContainer from 'containers/AutoGenerate'
import AutomaticPurchaseOrder from 'containers/AutomaticPurchaseOrder'
import BudgetContainer from 'containers/Budget'
import CalculationBonuses from 'containers/CalculationBonuses'
import Collection from 'containers/Collection/'
import CrudDirectCCAssignData from 'containers/CrudDirectCCAssign/CrudDirectCCAssignData'
import CrudDirectLAAssignData from 'containers/CrudDirectLAAssign/CrudDirectLAAssignData'
import CrudsData from 'containers/CrudsData/CrudsData'
import CrudsDataEdit from 'containers/CrudsData/CrudsDataEdit'
import EquivalentProducts from 'containers/EquivalentProducts/EquivalentProducts'
import FinalConsumerContainer from 'containers/FinalConsumer'
import Generate from 'containers/Generate'
import HeaderBoard from 'containers/Headboard'
import HeaderDataValidation from 'containers/HeaderDataValidation'
import HeaderEntry from 'containers/HeaderEntry'
import HeaderSelection from 'containers/HeaderSelection'
import IndividualLoaditems from 'containers/IndividualLoaditems'
import InventoryExpensesStock from 'containers/InventoryExpensesStock'
import InventoryPickStock from 'containers/InventoryPickStock'
import Landing from 'containers/Landing'
import LoadInventoryItem from 'containers/LoadInventoryItems/LoadInventoryItem'
import Loaditems from 'containers/Loaditems'
import LoadItemsIncomeInventoryPage from 'containers/LoadItemsIncomeInventoryPage/LoadItemsIncomeInventoryPage'
import LoadPackages from 'containers/LoadPackages'
import Login from 'containers/Login'
import PickStock from 'containers/PickStock'
import Provider from 'containers/Provider/'
import ProviderAffecting from 'containers/ProviderAffecting'
import ProviderAffectState from 'containers/ProviderAffectState'
import ProviderHeader from 'containers/ProviderHeader/'
import ProviderIndLoadItems from 'containers/ProviderIndividualLoaditems/'
import ProviderLoadItems from 'containers/ProviderLoaditems/'
import ProviderPickStock from 'containers/ProviderPickStock'
import SearchProducts from 'containers/SearchProducts'
import SearchedProductDetails from 'containers/SearchProducts/SearchedProductDetails'
import SearchVoucher from 'containers/SearchVoucher'
import SearchVoucherDetail from 'containers/SearchVoucher/SearchVoucherDetail'
import SelectType from 'containers/SelectType'
import SugestedProducts from 'containers/SugestedProducts/SugestedProducts'
import TreasuryHeader from 'containers/TreasuryHeader'
import Voucher from 'containers/Voucher'
import VoucherAffecting from 'containers/VoucherAffecting'
import VoucherInvolvement from 'containers/VoucherInvolvement'
import VoucherState from 'containers/VoucherState'
import { createBrowserHistory } from 'history'
import React from 'react'
import { connect } from 'react-redux'
import { Router, Switch } from 'react-router-dom'
import {
	PrivateRouteWithSubRoutes,
	RouteWithSubRoutes
} from 'utils/RouterUtils'
import {
	ACCOUNTINGSEAT,
	ACCOUNTINGVOUCHER,
	ADMIN,
	AFFECTATION_AMOUNTS,
	AUTOMATIC_PURCHASE_ORDER,
	AUTO_GENERATE,
	BUDGET,
	CALCULATION_BONUSES,
	COLLECTION,
	CRUD,
	CRUD_CC_ASSIGNATIONS,
	CRUD_EDIT,
	CRUD_LA_ASSIGNATIONS,
	EQUIVALENT_PROD_CONF,
	FINAL_CONSUMER,
	GENERATE,
	HEADERBOARD,
	HEADER_DATA_VALIDATION_PROCESS,
	HEADER_ENTRY,
	HEADER_SELECTION,
	HOME,
	INDLOADITEMS,
	INVENTORY_EXPENSE_STOCK,
	INVENTORY_PICK_STOCK,
	LANDING,
	LOADITEMS,
	LOAD_ITEMS_INCOME_INV,
	LOAD_ITEMS_INV,
	LOAD_PACKAGES_DISPATCH,
	LOGIN,
	PICK_STOCK,
	PROVIDER,
	PROVIDERAFFECTING,
	PROVIDERHEADER,
	PROVIDERINDLOADITEMS,
	PROVIDERLOADITEMS,
	PROVIDER_AFFECT_STATE,
	PROVIDER_PICK_STOCK,
	SEARCHED_PRODUCT_DETAILS,
	SEARCH_PRODUCTS,
	SEARCH_VOUCHER,
	SEARCH_VOUCHER_DETAIL,
	SELECTTYPE,
	SUGESTED_PROD_CONF,
	TREASURY_HEADER,
	VOUCHER,
	VOUCHERAFFECTING,
	VOUCHERINVOLVEMENT,
	VOUCHERSTATE
} from './utils/RoutePath'

const publicRoutes = [
	{ path: HOME, component: Login, exact: true },
	{ path: LOGIN, component: Login }
]

const privateRoutes = [
	{ path: `${LANDING}/:idCompany`, component: Landing },
	{ path: SELECTTYPE, component: SelectType },
	{ path: `${VOUCHER}/:idOperacion`, component: Voucher },
	{ path: `${HEADERBOARD}/:idOperacion?`, component: HeaderBoard },
	{ path: `${GENERATE}/:idOperacion?`, component: Generate },
	{ path: `${LOADITEMS}/:idOperacion?`, component: Loaditems },
	{ path: `${INDLOADITEMS}/:idOperacion?`, component: IndividualLoaditems },
	{
		path: `${VOUCHERINVOLVEMENT}/:idOperacion?`,
		component: VoucherInvolvement
	},
	{ path: `${VOUCHERAFFECTING}/:idOperacion?`, component: VoucherAffecting },
	{ path: `${VOUCHERSTATE}/:idOperacion?`, component: VoucherState },
	{ path: `${ACCOUNTINGSEAT}/:idOperacion?`, component: AccountingSeat },
	{ path: `${ACCOUNTINGVOUCHER}/:idOperacion?`, component: AccountingVoucher },
	{ path: `${COLLECTION}/:idOperacion?`, component: Collection },
	{ path: `${PROVIDER}/:idOperacion?`, component: Provider },
	{ path: `${PROVIDERHEADER}/:idOperacion?`, component: ProviderHeader },
	{ path: `${PROVIDERLOADITEMS}/:idOperacion?`, component: ProviderLoadItems },
	{
		path: `${PROVIDERINDLOADITEMS}/:idOperacion?`,
		component: ProviderIndLoadItems
	},
	{ path: `${PROVIDERAFFECTING}/:idOperacion?`, component: ProviderAffecting },
	{
		path: `${AFFECTATION_AMOUNTS}/:idOperacion?`,
		component: AffectationAmounts
	},
	{
		path: `${PROVIDER_AFFECT_STATE}/:idOperacion?`,
		component: ProviderAffectState
	},
	{
		path: `${PROVIDER_PICK_STOCK}/:idOperacion?`,
		component: ProviderPickStock
	},
	{ path: `${LOGIN}`, component: Login },
	{ path: `${ADMIN}/:mode`, component: Admin },
	{ path: `${PICK_STOCK}/:idOperacion?`, component: PickStock },
	{ path: `${SEARCH_VOUCHER}/:loadStore?`, component: SearchVoucher },
	{
		path: `${SEARCH_VOUCHER_DETAIL}/:id/:op`,
		component: SearchVoucherDetail
	},
	{ path: `${SEARCH_PRODUCTS}/:loadStore?`, component: SearchProducts },
	{
		path: `${SEARCHED_PRODUCT_DETAILS}/:id`,
		component: SearchedProductDetails
	},
	{ path: `${BUDGET}/:idOperacion?`, component: BudgetContainer },
	{ path: `${SUGESTED_PROD_CONF}/:idOperacion?`, component: SugestedProducts },
	{
		path: `${EQUIVALENT_PROD_CONF}/:idOperacion?`,
		component: EquivalentProducts
	},
	{
		path: `${CRUD}/${CRUD_LA_ASSIGNATIONS}`,
		component: CrudDirectLAAssignData
	},
	{
		path: `${CRUD}/${CRUD_CC_ASSIGNATIONS}`,
		component: CrudDirectCCAssignData
	},
	{
		path: `${CRUD}/:abmKey`,
		component: CrudsData
	},
	{
		path: `${CRUD_EDIT}/:abmKey/:action/:id`,
		component: CrudsDataEdit
	},
	{ path: `${BUDGET}/:idOperacion?`, component: BudgetContainer },
	{ path: `${AUTO_GENERATE}/:idOperacion?`, component: AutoGenerateContainer },
	{
		path: `${FINAL_CONSUMER}/:idOperacion?`,
		component: FinalConsumerContainer
	},
	{ path: `${HEADER_SELECTION}/:idOperacion?`, component: HeaderSelection },
	{
		path: `${AUTOMATIC_PURCHASE_ORDER}/:idOperacion?`,
		component: AutomaticPurchaseOrder
	},
	{
		path: `${HEADER_DATA_VALIDATION_PROCESS}/:idOperacion?`,
		component: HeaderDataValidation
	},
	{
		path: `${CALCULATION_BONUSES}/:idOperacion?`,
		component: CalculationBonuses
	},
	{
		path: `${INVENTORY_PICK_STOCK}/:idOperacion?`,
		component: InventoryPickStock
	},
	{
		path: `${HEADER_ENTRY}/:idOperacion?`,
		component: HeaderEntry
	},
	{
		path: `${LOAD_PACKAGES_DISPATCH}/:idOperacion?`,
		component: LoadPackages
	},
	{
		path: `${INVENTORY_EXPENSE_STOCK}/:idOperacion`,
		component: InventoryExpensesStock
	},
	{ path: `${LOAD_ITEMS_INV}/:idOperacion?`, component: LoadInventoryItem },
	{
		path: `${LOAD_ITEMS_INCOME_INV}/:idOperacion?`,
		component: LoadItemsIncomeInventoryPage
	},
	{
		path: `${TREASURY_HEADER}/:idOperacion?`,
		component: TreasuryHeader
	}
]

const history = createBrowserHistory()

if (
	process.env.REACT_APP_NODE_ENV !== 'localhost' &&
	process.env.REACT_APP_NODE_ENV !== 'alpha'
) {
	Sentry.init({
		integrations: [
			new Integrations.BrowserTracing({
				// Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
				routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
			})
		],
		environment: process.env.REACT_APP_NODE_ENV,
		dsn: process.env.REACT_APP_DEBUG_URL,
		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 0.001
	})
}

const AppRouter = (props) => (
	<Router basename={process.env.PUBLIC_URL} history={history}>
		<Switch>
			{publicRoutes.map((route, index) => (
				<RouteWithSubRoutes key={index} {...route} />
			))}
			{privateRoutes.map((route, index) => (
				<PrivateRouteWithSubRoutes key={index} {...route} {...props} />
			))}
		</Switch>
	</Router>
)

const mapStateToProps = ({ auth }) => {
	return {
		auth
	}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter)
