import Axios from 'axios'
import { downloadFileType } from 'lib/Utils'
import {
	IAutomaticPurchaseChangeLineParams,
	IAutomaticPurchaseConfirmParams,
	IAutomaticPurchaseFilterParams,
	IAutomaticPurchaseUpdateParams,
	IGetDepositsParams,
	IGetLaboratoriesParams,
	IGetListDrugsParams,
	IGetListProvidersParams,
	IGetPaymentTermsFilterListParams
} from 'models/AutomaticPurchase'

export const getDepositsApi = async (params: IGetDepositsParams) => {
	const response = await Axios.get('tablas/FiltroDepositos', { params })
	return response.data
}

export const getListCalculationFormulasApi = async () => {
	const response = await Axios.get('tablas/FiltroFormaCalc')
	return response.data
}

export const getListOrderOCMultiApi = async () => {
	const response = await Axios.get('tablas/OrdenOCMulti')
	return response.data
}

export const getListCostTypesApi = async () => {
	const response = await Axios.get('tablas/FiltroTipoCosto')
	return response.data
}

export const getLisLaboratoriesApi = async (params: IGetLaboratoriesParams) => {
	const response = await Axios.get('OCmulti/fabricante', { params })
	return response.data
}

export const getLisDrugsApi = async (params: IGetListDrugsParams) => {
	const response = await Axios.get('OCmulti/espec', { params })
	return response.data
}

export const getLisProvidersApi = async (params: IGetListProvidersParams) => {
	const response = await Axios.get('OCmulti/proveedor', { params })
	return response.data
}

export const automaticPurchaseFilterApi = async (
	params: Partial<IAutomaticPurchaseFilterParams>
) => {
	const response = await Axios.post('OCmulti/aplicar', params)
	return response.data
}

export const getPaymentTermsFilterListApi = async (
	params: IGetPaymentTermsFilterListParams
) => {
	const response = await Axios.get('tablas/FiltroCondPago', { params })
	return response.data
}

export const automaticPurchaseChangeLineApi = async (
	params: IAutomaticPurchaseChangeLineParams
) => {
	const response = await Axios.post('OCmulti/cambia_linea', params)
	return response.data
}

export const automaticPurchaseConfirmApi = async (
	params: IAutomaticPurchaseConfirmParams
) => {
	const response = await Axios.post('OCmulti/confirmar', params)
	return response.data
}

export const automaticPurchaseUpdateApi = async (
	params: IAutomaticPurchaseUpdateParams
) => {
	const response = await Axios.post('OCmulti/actu_prec', params)
	return response.data
}

export const automaticPurchaseExportApi = async (params: any) => {
	const response = await Axios.get('OCmulti/exportar', {
		params,
		responseType: 'blob'
	})

	downloadFileType(response, 'OC Automatica.csv') // Pendiente nombre del archivo

	return response.data
}
