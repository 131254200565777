import Axios from 'axios'
import { IGetBonusesParams } from 'models/CalculationBonuses'
import { IBaseParamsApi } from 'models/CommonInterface'
const urlBase = 'bonif'

export const getBonusesApi = async (params: IGetBonusesParams) => {
	const response = await Axios.get(`${urlBase}/consultar`, { params })
	return response.data
}

export const confirmBonusesApi = async (params: IBaseParamsApi) => {
	const response = await Axios.post(`${urlBase}/confirmar`, params)
	return response.data
}
