import {
	crudGetCustomerReferredSuccess,
	crudUpdateCustomerReferredSuccess
} from 'actions'
import {} from 'api/CustomerObservationApi'
import {
	crudGetCustomerReferred,
	crudUpdateCustomerReferred
} from 'api/CustomerReferApi'
import {
	CRUD_GET_CUSTOMER_REFER,
	CRUD_UPDATE_CUSTOMER_REFER
} from 'constants/ActionsTypes'
import {
	ICrudGetCustomerReferSaga,
	ICrudUpdateCustomerReferSaga
} from 'models/CrudCustomerInterface'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* crudGetCustomerReferredRequest({
	payload
}: ICrudGetCustomerReferSaga): any {
	try {
		const values: any = yield call(crudGetCustomerReferred, payload)
		yield put(crudGetCustomerReferredSuccess(values))
	} catch (error) {}
}

function* crudUpdateCustomerReferredRequest({
	payload
}: ICrudUpdateCustomerReferSaga): any {
	try {
		const values: any = yield call(crudUpdateCustomerReferred, payload)
		yield put(crudUpdateCustomerReferredSuccess(values))
	} catch (error) {}
}

export function* crudGetCustomerReferredSaga() {
	yield takeEvery(CRUD_GET_CUSTOMER_REFER, crudGetCustomerReferredRequest)
}

export function* crudUpdateCustomerReferredSaga() {
	yield takeEvery(CRUD_UPDATE_CUSTOMER_REFER, crudUpdateCustomerReferredRequest)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetCustomerReferredSaga),
		fork(crudUpdateCustomerReferredSaga)
	])
}
