import CollapseButton from 'components/common/collapseButton'
import NotificationMessage from 'components/common/notificationMessage'
import GenericInputForm from 'components/form/genericInputForm'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Collapse } from 'reactstrap'
import {
	voucherHeadAuto,
	voucherHeadCheckDate,
	voucherHeadConfirm,
	voucherHeadValidatekey
} from '../../actions'

class HeadBoardFormInput extends Component {
	constructor(props) {
		super(props)
		this.state = {
			collapse: true,
			showError: false,
			errorMessage: '',
			optionsCurrency: props.headSale.moneda
				? props.headSale.moneda.map((opt) => {
						return { id: opt.cod_moneda, label: opt.desc_moneda }
				  })
				: []
		}
	}

	componentDidMount = () => {}

	componentDidUpdate = (prevProps) => {
		const { fields, setFieldValue, checkDate, checkKey, headSale } = this.props

		if (!prevProps.checkKey && checkKey) {
			if (checkKey) {
				fields.forEach((field) => {
					if (field.idCampo.trim() === 'cotiz_comp_vta') {
						field.editable = 1
						setFieldValue(
							'cotiz_comp_vta',
							headSale.moneda ? `${headSale.moneda[0].cotiz}` : '0'
						)
					}
				})
			} else {
				this.setError(checkKey)
			}
		}

		if (!prevProps.checkDate && checkDate) {
			if (checkDate.codigo && checkDate.codigo !== 200) {
				setFieldValue('date', new Date())
				this.setError(checkDate)
			} else {
				setFieldValue('cotiz_comp_vta', checkDate.cotizacion)
			}
		}
	}

	setError = (error) => {
		this.setState({
			showError: true,
			errorMessage: error.mensaje,
			errorTitle: error.descripcion
		})
	}

	toggle() {
		this.setState((state) => ({ collapse: !state.collapse }))
	}

	companyChange = (select) => {
		// console.log(select.target.value)
	}

	handleChangeCurrency = (select) => {
		const { headSale, setFieldValue } = this.props
		const code = select.target.value
		headSale.moneda.forEach((currency) => {
			if (currency.cod_moneda === code) {
				setFieldValue('cotiz_comp_vta', currency.cotiz)
				setFieldValue('mon_comp_vta', code)
			}
		})
	}

	handleValidateInput = (data) => {
		this.props.voucherHeadValidatekey({ idProceso: 'CC_003', clave: data })
	}

	handleChangeDate = (date) => {
		const { idOperacion, handleChange } = this.props
		const { setFieldValue } = this.props
		const dateFormated = moment(date).format('DD/MM/YYYY')
		this.props.voucherHeadCheckDate({ idOperacion, fecha: dateFormated })
		const valueDate = date || ''
		setFieldValue('fecha_comp_vta', valueDate)
		handleChange()
	}

	handleChangeGeneric = (data) => {
		const { values, setFieldValue } = this.props
		const { atrib_comp_vta_field } = values

		const generic = atrib_comp_vta_field || []
		let ban = true

		generic.forEach((field, index) => {
			if (field.cod_atributo === data.cod_atributo) {
				generic[index] = data // Editando
				ban = false
			}
		})

		if (ban) {
			generic.push(data) // La primera vez
		}

		setFieldValue('atrib_comp_vta_field', generic)
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	handleSearchAuto = (params) => {
		this.props.voucherHeadAuto(params)
	}

	renderCarrier = () => {
		const {
			readOnly,
			t,
			fields,
			headSale,
			setFieldValue,
			handleBlur,
			genericOptions
		} = this.props
		const result = []
		let config = {}

		fields.forEach((field) => {
			const idField = field.idCampo.trim()
			if (idField === 'atrib_comp_vta') {
				config = field
			}
		})

		const optionsTrans =
			headSale && headSale.transporte
				? headSale.transporte.map((opt) => {
						return { id: opt.cod_transp, label: opt.nom_transp }
				  })
				: []

		const genericFields = readOnly
			? genericOptions.atrib_comp_vta
			: headSale
			? headSale.atrib_comp_vta
			: []

		result.push(
			<Row key={1} className={'col-11'} style={{ paddingRight: '0px' }}>
				<InputDropdown
					inputFormCol={{ sm: 6 }}
					fields={fields}
					label={t('headboard.form.carrier')}
					inputId={'transp_comp_vta'}
					name={'transp_comp_vta'}
					placeholder={t('headboard.form.insert_carrier')}
					colLabel={'col-sm-4'}
					colInput={'col-sm-8'}
					divStyle={{ paddingLeft: '17px' }}
					options={optionsTrans}
					disable={readOnly}
					onBlur={handleBlur}
					onChange={(data) => {
						setFieldValue('transp_comp_vta', data.target.value)
					}}
				/>
			</Row>
		)

		genericFields.forEach((element) => {
			result.push(
				<GenericInputForm
					style={{ marginLeft: -5 }}
					config={{ ...config, ...element }}
					key={element.cod_atrib}
					handleChange={this.handleChangeGeneric}
					handleSearchAuto={this.handleSearchAuto}
					autodata={this.props.autodata}
				/>
			)
		})

		return result
	}

	render() {
		const {
			t,
			values,
			handleChange,
			handleBlur,
			setFieldValue,
			headSale,
			readOnly,
			collapse,
			fields
		} = this.props

		const { optionsCurrency } = this.state

		const optionsCompany =
			headSale && headSale.suc_empresa
				? headSale.suc_empresa.map((opt) => {
						return { id: opt.Cod_Suc, label: opt.Nom_suc }
				  })
				: []

		const optionsBranches =
			headSale && headSale.suc_clie
				? headSale.suc_clie.map((opt) => {
						return { id: opt.suc_nro, label: opt.suc_nombre }
				  })
				: []

		const optionsSellers =
			headSale && headSale.vendedor
				? headSale.vendedor.map((opt) => {
						return { id: opt.cod_vend, label: opt.nom_vend }
				  })
				: []

		const optionsConditions =
			headSale && headSale.cond_comp_vta
				? headSale.cond_comp_vta.map((opt) => {
						return { id: opt.cod_cond_vta, label: opt.desc_cond_vta }
				  })
				: []

		if (fields && values) {
			return (
				<Row>
					<NotificationMessage
						{...this.state}
						handleCloseError={this.handleCloseError}
						type={'danger'}
					/>
					<InputDropdown
						inputFormCol={{ sm: 11 }}
						fields={fields}
						label={t('headboard.form.company_branch')}
						inputId={'suc_clie'}
						name={'suc_clie'}
						placeholder={t('voucher.headboard.form.insert_company_branch')}
						styles={{ width: '100%' }}
						colLabel={'col-sm-2'}
						colInput={'col-sm-10'}
						options={optionsBranches}
						disable={readOnly}
						value={values ? values.suc_clie : null}
						onBlur={handleBlur}
						onChange={(data) => {
							setFieldValue('suc_clie', data.target.value)
							handleChange()
						}}
					/>
					<InputDropdown
						inputFormCol={{ sm: 11 }}
						fields={fields}
						label={t('headboard.form.company_branch')}
						inputId={'Suc_empresa_vta'}
						name={'Suc_empresa_vta'}
						placeholder={t('voucher.headboard.form.insert_company_branch')}
						styles={{ width: '100%' }}
						colLabel={'col-sm-2'}
						colInput={'col-sm-10'}
						options={optionsCompany}
						disable={readOnly}
						value={values ? values.Suc_empresa_vta : null}
						onBlur={handleBlur}
						onChange={(data) => {
							setFieldValue('Suc_empresa_vta', data.target.value)
							handleChange()
						}}
					/>
					<Row className={'col-11'} style={{ paddingRight: '0px' }}>
						<InputText
							inputFormCol={{ sm: 6 }}
							fields={fields}
							label={t('headboard.form.voucher')}
							inputId={'Titulo_comp_vta'}
							name={'Titulo_comp_vta'}
							placeholder={t('headboard.form.insert_voucher')}
							colLabel={'col-sm-4'}
							colInput={'col-sm-8'}
							divStyle={{ paddingLeft: '17px' }}
							disable={readOnly}
							value={values.Titulo_comp_vta}
							onBlur={handleBlur}
							onChange={(value) => {
								setFieldValue('Titulo_comp_vta', value)
								handleChange()
							}}
						/>
						<InputText
							inputFormCol={{ sm: 5 }}
							fields={fields}
							label={t('headboard.form.date')}
							inputId={'fecha_comp_vta'}
							name={'fecha_comp_vta'}
							placeholder={t('headboard.form.insert_date')}
							colLabel={'col-sm-4'}
							colInput={'col-sm-8'}
							styleLabel={{ textAlign: 'right' }}
							disable={readOnly}
							value={values.fecha_comp_vta}
							onChange={this.handleChangeDate}
							onBlur={handleBlur}
						/>
					</Row>
					<Row className={'col-11'} style={{ paddingRight: '0px' }}>
						<InputDropdown
							inputFormCol={{ sm: 6 }}
							fields={fields}
							label={t('headboard.form.currency')}
							inputId={'mon_comp_vta'}
							name={'mon_comp_vta'}
							placeholder={t('headboard.form.insert_currency')}
							colLabel={'col-sm-4'}
							colInput={'col-sm-8'}
							divStyle={{ paddingLeft: '17px' }}
							disable={readOnly}
							value={values.mon_comp_vta}
							options={optionsCurrency}
							onChange={this.handleChangeCurrency}
							onBlur={handleBlur}
						/>
						<InputText
							inputFormCol={{ sm: 5 }}
							lock
							fields={fields}
							handleSubmit={this.handleValidateInput}
							label={t('headboard.form.quotation')}
							inputId={'cotiz_comp_vta'}
							name={'cotiz_comp_vta'}
							placeholder={t('headboard.form.insert_quotation')}
							colLabel={'col-sm-4'}
							colInput={'col-sm-8'}
							styleLabel={{ textAlign: 'right' }}
							disable={readOnly}
							value={values.cotiz_comp_vta}
							// divStyle={{ paddingLeft: '14px' }}
							onBlur={handleBlur}
							onChange={(data) => {
								setFieldValue('cotiz_comp_vta', data)
								handleChange()
							}}
						/>
					</Row>
					<Row className={'col-11'} style={{ paddingRight: '0px' }}>
						<InputDropdown
							inputFormCol={{ sm: 6 }}
							fields={fields}
							label={t('headboard.form.saler')}
							inputId={'vend_comp_vta'}
							name={'vend_comp_vta'}
							placeholder={t('headboard.form.insert_saler')}
							colLabel={'col-sm-4'}
							colInput={'col-sm-8'}
							divStyle={{ paddingLeft: '17px' }}
							disable={readOnly}
							options={optionsSellers}
							value={values.vend_comp_vta}
							onBlur={handleBlur}
							onChange={(data) => {
								setFieldValue('vend_comp_vta', data.target.value)
								handleChange()
							}}
						/>

						<InputDropdown
							inputFormCol={{ sm: 5 }}
							fields={fields}
							label={t('headboard.form.condSale')}
							inputId={'cond_comp_vta'}
							name={'cond_comp_vta'}
							placeholder={t('headboard.form.insert_condition_sale')}
							colLabel={'col-sm-4'}
							colInput={'col-sm-8'}
							styleLabel={{ textAlign: 'right' }}
							divStyle={{ paddingLeft: '15px' }}
							disable={readOnly}
							options={optionsConditions}
							value={values.cond_comp_vta}
							onChange={(data) => {
								setFieldValue('cond_comp_vta', data.target.value)
								handleChange()
							}}
							onBlur={handleBlur}
						/>
					</Row>
					{collapse && (
						<>
							<Row className={'col-12'}>
								<Col sm={1}>
									<CollapseButton
										onPress={() => this.toggle()}
										status={this.state.collapse}
									/>
								</Col>
								<Col sm={11}>
									<div className="dropdown-divider col-11 p-2" />
								</Col>
							</Row>

							<Collapse
								style={{ width: '100%' }}
								isOpen={this.state.collapse && collapse}
							>
								{values && this.renderCarrier()}
							</Collapse>
						</>
					)}
					{!collapse && this.renderCarrier()}
				</Row>
			)
		} else {
			return null
		}
	}
}

const mapStateToProps = ({ voucher, vouchertype }) => {
	const { checkKey, checkDate, autodata } = voucher
	const { voucherTypeCancel, voucherType } = vouchertype
	return { checkKey, checkDate, voucherTypeCancel, voucherType, autodata }
}

export default connect(mapStateToProps, {
	voucherHeadValidatekey,
	voucherHeadCheckDate,
	voucherHeadConfirm,
	voucherHeadAuto
})(withTranslation()(HeadBoardFormInput))
