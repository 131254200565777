import {
	faCheck,
	faPencilAlt,
	faTimes,
	faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CollapseButton from 'components/common/collapseButton'
import CommonTable from 'components/common/commonTable'
import NotificationMessage from 'components/common/notificationMessage'
import InputText from 'components/form/inputText'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getConfigVoucher, taxesConfirm, taxesValidateRow } from '../../actions'
import { validateForm } from '../../lib/FieldValidations'
import { getValueMask } from '../../lib/MaskValues'
import styles from './AccountingVoucherTable.module.scss'

class TaxesTable extends Component {
	constructor(props) {
		super(props)
		this.inputRefs = {}
		this.state = {
			editRow: null,
			editing: false,
			accountDetail: null,
			itemsTable: this.props.taxes ? this.props.taxes.Impuestos : [],
			showError: false,
			errorTitle: '',
			errorMessage: '',
			typeNotification: 'danger'
		}

		this.primarykey = 'id'
		this.rowErrors = []
		this.excludeFields = ['tasa', 'alicuota', 'base_calc', 'impuesto']
		this.countVisible = 0

		this.columnsProduct = [
			{
				dataField: 'nitem_af',
				text: '#',
				align: 'center',
				headerAlign: 'center'
			},
			{
				dataField: 'desc_item',
				text: 'Detalle Item',
				align: 'center',
				headerAlign: 'center'
			},
			{
				dataField: 'neto_item',
				text: 'Neto',
				align: 'right',
				headerAlign: 'center'
			},
			{
				dataField: 'impuesto_item',
				text: 'Impuesto',
				align: 'right',
				headerAlign: 'center'
			}
		]
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.formConfirmation(this.handleConfirmation)

		if (idOperacion) {
			this.handleConfirmation = this.handleConfirmation.bind(this)
		}
	}

	componentDidUpdate = (prevProps) => {
		if (
			prevProps.accountsUpdate !== this.props.accountsUpdate &&
			this.props.accountsUpdate
		) {
			this.setState({ itemsTable: this.props.accountsUpdate })
		}

		if (
			prevProps.accountDetail !== this.props.accountDetail &&
			this.props.accountDetail
		) {
			this.setState({ accountDetail: this.props.accountDetail })
		}
	}

	/**
	 * to save and call next page
	 * @param callBackReturn
	 */
	handleConfirmation = () => {
		const { idOperacion, config, t, callBackReturn } = this.props
		const { itemsTable } = this.state
		const result = []
		const errorMessage = []
		let validError = false

		itemsTable.forEach((taxe) => {
			result.push(validateForm(config, taxe, t))
		})

		result.forEach((rest) => {
			if (!rest.success) {
				validError = true
				rest.message.forEach((mess) => {
					errorMessage.push(mess)
				})
			}
		})

		if (validError) {
			this.createError(errorMessage)
		} else {
			this.props.taxesConfirm({ idOperacion, callBackReturn })
			this.setState({ showError: false })
		}
	}

	/**
	 * to call to edit cell.
	 * @param {*} row
	 */
	handleValidateCell = (row) => {
		const { idOperacion, config, t } = this.props
		const validations = validateForm(config, row, t)
		if (validations.success) {
			// Falta campos validar
			const Item = {
				cod_imp: row.cod_imp,
				tasa: row.tasa,
				impuesto: row.impuesto,
				base_calc: row.base_calc,
				nro_certif: row.nro_certif ? row.nro_certif : ''
			}
			this.props.taxesValidateRow({ ...Item, idoperacion: idOperacion })
			this.setState({ editing: false, showError: false })
		} else {
			this.createError(validations.message)
		}
	}

	/**
	 * to cancel editing
	 */
	handleCancelCell = () => {
		this.setState({ editing: false })
	}

	/**
	 * To remove product.
	 * @param {*} row
	 */
	handleRemoveCell = (row) => {
		// console.log(row)
	}

	/**
	 * to create error message
	 * @param {string} errorMessage
	 */
	createError = (errorMessage) => {
		const { t } = this.props
		this.setState({
			showError: true,
			typeNotification: 'danger',
			errorMessage,
			errorTitle: t('global.input_require')
		})
	}

	/**
	 * To get columns for table.
	 * @returns dataTable Columns
	 */
	getColumns = () => {
		const { config } = this.props
		const { editing, rowEdit, accountDetail, ccUpdateValue } = this.state
		let count = 0
		const rows = config.campos.map((field) => {
			const campoId = field.idCampo.trim()

			if (field.visible) {
				count++
			}

			return {
				dataField: campoId,
				text: field.label ? (campoId === 'cuenta' ? '' : field.label) : '',
				align: this.excludeFields.includes(campoId) ? 'right' : 'center',
				headerAlign: 'center',
				headerStyle: this.getStyleColumn(field),
				hidden: !field.visible,
				formatter: (cell, row, rowIndex, extraData) =>
					this.renderFormat(field, cell, row, extraData),
				formatExtraData: { editing, rowEdit, accountDetail, ccUpdateValue }
			}
		})

		rows.push({
			dataField: 'error',
			text: '',
			align: 'center',
			headerAlign: 'center',
			headerStyle: { width: '3%', textAlign: 'center' },
			formatExtraData: { editing, rowEdit },
			formatter: (cell, row, rowIndex, extraData) => {
				if (row.linea_edit) {
					if (rowEdit === row[this.primarykey] && editing) {
						return (
							<Row>
								<Col sm={6} className={'text-right pr-0'}>
									<FontAwesomeIcon
										style={{ cursor: 'pointer' }}
										icon={faCheck}
										onClick={() => this.handleValidateCell(row)}
									/>
								</Col>
								<Col sm={6}>
									<FontAwesomeIcon
										style={{ cursor: 'pointer' }}
										icon={faTimes}
										onClick={() => this.handleCancelCell(row)}
									/>
								</Col>
							</Row>
						)
					} else if (!editing) {
						const actions = []

						actions.push(
							<Col key={1} sm={6} className={'text-right pr-0'}>
								<FontAwesomeIcon
									style={{ cursor: 'pointer' }}
									icon={faPencilAlt}
									onClick={() => this.handleEditCell(row)}
								/>
							</Col>
						)

						if (row.linea_edit === 2) {
							actions.push(
								<Col key={2} sm={6}>
									<FontAwesomeIcon
										style={{ cursor: 'pointer' }}
										icon={faTrashAlt}
										onClick={() => this.handleRemoveCell(row)}
									/>
								</Col>
							)
						}
						return <Row>{actions}</Row>
					} else {
						return null
					}
				} else {
					return null
				}
			},
			editable: false
		})
		this.countVisible = count
		return rows
	}

	/**
	 * to render field in the table, editing.
	 * @param {strin} field
	 * @param {string} value
	 * @param {object} row
	 * @param {array} editProperties
	 * @returns
	 */

	renderFormat = (field, value, row, editProperties) => {
		const { rowEdit, editing, ccUpdateValue } = editProperties
		const campoId = field.idCampo.trim()
		const inputError = value === 'error_input'
		const customValue =
			value === 'error_input'
				? ''
				: !Array.isArray(value)
				? value
				: value[0].cod_estado
		const inputStyle =
			campoId === 'impuesto' ||
			campoId === 'tasa' ||
			campoId === 'alicuota' ||
			campoId === 'base_calc'
				? { textAlign: 'right' }
				: {}
		let result = null

		const optionsInput = {
			inputFormCol: { sm: 12 },
			fields: [{ ...field, label: false }],
			label: false,
			inputId: `${campoId}`,
			id: `${campoId}_${row[this.primarykey]}`,
			name: `${campoId}_${row[this.primarykey]}`,
			colLabel: 'col-sm-4',
			colInput: 'col-sm-12',
			divStyle: { paddingLeft: '17px' },
			disable: false,
			value: ccUpdateValue || customValue,
			showError: inputError,
			styles: inputStyle,
			rowStyle: { marginBottom: '5px' },
			onChange: (value) => {
				this.handleUpdateItems(row[this.primarykey], campoId, value)
			}
		}

		if (editing && row[this.primarykey] === rowEdit) {
			if (field.editable) {
				result = <InputText {...optionsInput} />
			} else {
				result = <span> {customValue} </span>
			}
		} else {
			result = (
				<span>
					{' '}
					{field.mascara
						? getValueMask(customValue, field.mascara, this.props)
						: customValue}{' '}
				</span>
			)
		}

		return result
	}

	/**
	 * to change editing state
	 * @param {object} row
	 */
	handleEditCell = (row) => {
		this.setState({ editing: true, rowEdit: row[this.primarykey] })
	}

	/**
	 * to editiing
	 * @param {string} rowId
	 * @param {string} field
	 * @param {string} value
	 */
	handleUpdateItems = (rowId, field, value) => {
		const { itemsTable } = this.state
		itemsTable.forEach((row) => {
			if (row[this.primarykey] === rowId) {
				row[field] = value
			}
		})
	}

	/**
	 * to close error message
	 */
	handleCloseError = () => {
		this.setState({ showError: false })
	}

	/**
	 * to get column styles
	 * @param {string} field
	 * @returns
	 */
	getStyleColumn = (field) => {
		const idField = field.idCampo.trim()

		let style = {}
		if (this.countVisible > 9) {
			switch (idField) {
				case 'Items':
				case 'tasa':
				case 'alicuota':
					style = { width: '5%' }
					break
				case 'nro_certif':
				case 'impuesto':
					style = { width: '8%' }
					break
				default:
					style = { width: '10%' }
					break
			}
		}

		if (field.requerido === '1') {
			style.color = 'red'
		}

		return style
	}

	/**
	 * to show expand icon
	 * @param row
	 */
	getExpandRow = (row) => {
		return {
			renderer: (row) => this.renderExpandRow(row),
			showExpandColumn: true,
			expandByColumnOnly: true,
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				return <CollapseButton status={isAnyExpands} />
			},
			expandColumnRenderer: ({ expanded, rowKey }) => {
				return <CollapseButton status={expanded} />
			}
		}
	}

	/**
	 * to get options to expand row
	 * @param row
	 */

	renderExpandRow = (row) => {
		const { theme } = this.props
		return (
			<CommonTable
				columns={this.columnsProduct}
				data={row.Items}
				keyField={'nitem_af'}
				rowClasses={theme.tableRow}
				headerClasses={theme.tableHeader}
				pagination={false}
			/>
		)
	}

	/**
	 * to get all pagination's options
	 */
	getPaginationOptions = () => {
		const { taxes } = this.props
		return {
			pageStartIndex: 1,
			sizePerPage: taxes.page_size,
			page: taxes.page_number,
			totalSize: taxes.total_count,
			onPageChange: (page, sizePerPage) => {}
		}
	}

	render() {
		const { theme, config } = this.props
		const { itemsTable, typeNotification } = this.state
		const tableColumns = config ? this.getColumns() : []
		return (
			<Fragment>
				<Col sm={12} className={'mb-1'}>
					<NotificationMessage
						{...this.state}
						handleCloseError={this.handleCloseError}
						type={typeNotification}
					/>
				</Col>
				<Col className={`col-12 pl-0 pr-0`}>
					{tableColumns.length > 0 && (
						<CommonTable
							remote
							refTable={this.tableRef}
							columns={tableColumns}
							keyField={this.primarykey}
							data={itemsTable}
							rowClasses={theme.tableRow}
							headerClasses={theme.tableHeader}
							expandRow={this.getExpandRow()}
							paginationOptions={this.getPaginationOptions()}
							onTableChange={this.props.handleChangeTable}
						/>
					)}
				</Col>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ voucher, accountingSeats, auth, vouchertype }) => {
	const { authUser } = auth
	const { productsUpdate, accountDetail, accountsUpdate } = accountingSeats
	const { voucherTypeCancel } = vouchertype
	return {
		productsUpdate,
		authUser,
		accountDetail,
		accountsUpdate,
		voucherTypeCancel
	}
}

const connectForm = connect(mapStateToProps, {
	getConfigVoucher,
	taxesValidateRow,
	taxesConfirm
})(TaxesTable)

export default themr('StateTableStyles', styles)(withTranslation()(connectForm))
