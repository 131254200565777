import { confirmPackagesAttributes, getPackagesAttributes } from 'actions'
import GenericInputForm from 'components/form/genericInputForm'
import { Form, Formik, FormikProps } from 'formik'
import {
	ICommonGenericItemApi,
	ICommonGenericItemValue
} from 'models/CommonInterface'
import {
	IConfirmPackagePayload,
	IGetPackagesParams,
	IGetPackageSuccessResponse
} from 'models/LoadPackages'
import React, { Component, forwardRef, Fragment, Ref } from 'react'
import { Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'
interface ILoadPackagesProps {
	loadPackageFormRef: any
	callBackReturn?: () => void
	idOperacion?: string
	getPackagesAttributes: (payload: IGetPackagesParams) => void
	packagesAttributes: IGetPackageSuccessResponse | null
	confirmPackagesAttributes: (payload: IConfirmPackagePayload) => void
}

type State = {}

interface IFormValues {
	atrib_comp_vta: Array<ICommonGenericItemValue>
}

class LoadPackagesComponent extends Component<ILoadPackagesProps, State> {
	componentDidMount = (): void => {
		const { idOperacion, getPackagesAttributes } = this.props
		getPackagesAttributes({ idOperacion: idOperacion || '' })
	}

	/**
	 * handle submit and confirm the load packages
	 * @param values
	 */
	handleSubmitForm = (values: IFormValues): void => {
		const { atrib_comp_vta } = values
		const { idOperacion, callBackReturn } = this.props
		this.props.confirmPackagesAttributes({
			params: {
				idOperacion: idOperacion || '',
				atrib_comp_vta
			},
			callBackReturn
		})
	}

	handleSearchAuto = () => {}

	/**
	 * function called when the values are changed
	 */
	handleChangeGeneric = (data: any, formikProps: FormikProps<IFormValues>) => {
		const { atrib_comp_vta } = formikProps.values
		const generic = atrib_comp_vta || []
		const indexField = _.findIndex(generic, { cod_atributo: data.cod_atributo })

		if (indexField === -1) {
			generic.push(data) // La primera vez
		} else {
			generic[indexField] = data
		}
		formikProps.setFieldValue('atrib_comp_vta', generic)
	}

	renderFields = (formikProps: FormikProps<IFormValues>) => {
		const result: any = []
		const { packagesAttributes } = this.props
		packagesAttributes?.atrib_comp_vta.forEach((element: any) => {
			result.push(
				<GenericInputForm
					style={{ marginLeft: -5 }}
					config={{ visible: 1, editable: 1, ...element }}
					key={element.cod_atrib}
					handleChange={(data: any) =>
						this.handleChangeGeneric(data, formikProps)
					}
					handleSearchAuto={this.handleSearchAuto}
					autodata={[]}
				/>
			)
		})

		return result
	}

	getInitialFormValues = () => {
		const { packagesAttributes } = this.props
		return packagesAttributes?.atrib_comp_vta.map(
			(attr: ICommonGenericItemApi) => {
				return { cod_atributo: attr.cod_atrib, desc_dato: '' }
			}
		)
	}

	render() {
		const { loadPackageFormRef, packagesAttributes } = this.props
		return (
			!_.isEmpty(packagesAttributes) && (
				<Formik
					ref={loadPackageFormRef}
					initialValues={{
						atrib_comp_vta:
							this.getInitialFormValues() as Array<ICommonGenericItemValue>
					}}
					onSubmit={(values) => {
						this.handleSubmitForm(values)
					}}
					validationSchema={{}}
					enableReinitialize={true}
					render={(props: FormikProps<IFormValues>) => {
						return (
							<Fragment>
								<Col sm={12}>
									<Form>{this.renderFields(props)}</Form>
								</Col>
							</Fragment>
						)
					}}
				/>
			)
		)
	}
}

const mapStateToProps = ({ loadPackages }: any) => {
	const { packagesAttributes } = loadPackages
	return { packagesAttributes }
}

const mapDispatchToProps = { getPackagesAttributes, confirmPackagesAttributes }

const LoadPackagesComponentWithRef = forwardRef(
	function InventoryPickStockFormWrapper(
		props: ILoadPackagesProps,
		ref: Ref<LoadPackagesComponent>
	) {
		return <LoadPackagesComponent {...props} ref={ref} />
	}
)

export default connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
})(LoadPackagesComponentWithRef)
