import {
	purchasesAffectedState,
	purchasesAffectedStateConfirm,
	purchasesAffectedStateValidate
} from 'actions'
import FiltersTable from 'components/common/filtersTable'
import { P_AFEC_STATE_CPA } from 'constants/ConfigProcessNames'
import { getFiltersTable } from 'lib/Utils'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import VoucherAffectingTotal from '../voucherAffecting/VoucherAffectingTotal'
import styles from './providerStateTable.module.css'
import SalesStateTable from './SalesStateTable'

class ProviderStateTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			total_item: 0,
			total_cant: 0,
			total_importe: 0,
			ComprobAvencer: 0,
			OpcionMuestra: '0'
		}

		this.filtersKey = {
			fec_emis: 'filtro_fec_emis',
			fec_vto: 'filtro_fec_vto',
			fec_real: 'filtro_fec_real',
			comprob_desc: 'filtro_comprob_desc',
			comprob_nro: 'filtro_comprob_nro',
			desc_cond_cpa: 'filtro_desc_cond_cpa',
			desc_item: 'filtro_desc_item',
			cod_mone: 'filtro_cod_mone',
			cotiz: 'filtro_cotiz',
			Cod_prod: 'filtro_cod_prod',
			Cod_unid: 'filtro_cod_unid',
			cant_saldo: 'filtro_cant_pend',
			imp_pend: 'filtro_imp_pend',
			cod_estado_orig: 'filtro_cod_estado_orig',
			estado_orig: 'filtro_estado_orig',
			sucursal: 'filtro_sucursal'
		}
	}

	componentDidMount = () => {
		const { ComprobAvencer, OpcionMuestra } = this.state
		const { idOperacion } = this.props
		this.props.purchasesAffectedState({
			ComprobAvencer,
			OpcionMuestra,
			idOperacion,
			page_size: 10,
			page_number: 1
		})
	}

	componentDidUpdate = (prevProps) => {
		const { stateValidate, salesconfirm, productsState } = this.props

		if (prevProps.productsState !== productsState && productsState) {
			this.setState({
				total_item: productsState.total_item,
				total_cant: productsState.total_cant,
				total_importe: productsState.total_importe
			})
		}

		if (prevProps.stateValidate !== stateValidate && stateValidate) {
			this.setState({
				total_item: stateValidate.total_item,
				total_cant: stateValidate.total_afec,
				total_importe: stateValidate.total_importe
			})
		}

		if (
			prevProps.salesconfirm !== salesconfirm &&
			!prevProps.salesconfirm &&
			salesconfirm
		) {
			this.setState({
				total_item: salesconfirm.total_item,
				total_cant: salesconfirm.total_cant,
				total_importe: salesconfirm.total_importe
			})
		}
	}

	onChangeTable = (type, pagination) => {
		const { ComprobAvencer, OpcionMuestra } = this.state
		const { idOperacion } = this.props
		const filters = type === 'filter' ? getFiltersTable(pagination.filters) : {}
		this.props.purchasesAffectedState({
			ComprobAvencer,
			OpcionMuestra,
			idOperacion,
			page_number: pagination.page,
			page_size: pagination.sizePerPage,
			...filters
		})
	}

	handleGetCant = (e) => {
		const { idOperacion } = this.props
		const comprobante = e.target.checked ? 1 : 0
		const { OpcionMuestra } = this.state
		this.setState({ ComprobAvencer: comprobante })
		this.props.purchasesAffectedState({
			ComprobAvencer: comprobante,
			OpcionMuestra,
			idOperacion,
			page_size: 10,
			page_number: 1
		})
	}

	handleChangeSelect = (value) => {
		const { ComprobAvencer } = this.state
		const { idOperacion } = this.props
		this.setState({ OpcionMuestra: value })
		this.props.purchasesAffectedState({
			ComprobAvencer,
			OpcionMuestra: value,
			idOperacion,
			page_size: 10,
			page_number: 1
		})
	}

	render() {
		const {
			t,
			productsState,
			readOnly,
			theme,
			config,
			productsUpdate,
			callBackReturn
		} = this.props
		const inputConfig = [
			{
				idCampo: 'checkComprobAvencer',
				label: t('voucherInvolvement.form.sample'),
				visible: 1,
				requerido: 0,
				editable: 1
			}
		]
		const customCol = readOnly ? 4 : { span: 5, offset: 7 }

		return (
			<Row className={theme.containerTable}>
				{!readOnly && (
					<>
						<FiltersTable
							handleGetCant={this.handleGetCant}
							t={t}
							inputConfig={inputConfig}
							handleChangeSelect={(value) => this.handleChangeSelect(value)}
						/>
						<Col lg={{ span: 3, offset: 0 }} md={{ span: 5, offset: 5 }}>
							{productsState && (
								<VoucherAffectingTotal
									classDiv={'pl-5'}
									formatCol={{ span: 10, offset: 3 }}
									data={this.state}
								/>
							)}
						</Col>
					</>
				)}
				{readOnly && (
					<Col sm={12}>
						<VoucherAffectingTotal formatCol={{ span: 4 }} data={this.state} />
					</Col>
				)}
				<Col sm={12} className={`pb-2 pr-0 ${theme.scrollContainer}`}>
					{productsState && config && (
						<SalesStateTable
							products={productsState}
							productsUpdate={productsUpdate}
							filtersKey={this.filtersKey}
							pageKeys={{ key: 'nimovpro', field: 'nimovpro' }}
							handleStateValidate={this.props.purchasesAffectedStateValidate}
							handleStateConfirm={this.props.purchasesAffectedStateConfirm}
							config={config}
							readOnly={readOnly}
							idOperacion={this.props.idOperacion}
							handleChangeTable={this.onChangeTable}
							formConfirmation={this.props.formConfirmation}
							callBackReturn={callBackReturn}
						/>
					)}
					{productsState && (
						<VoucherAffectingTotal
							classDiv={'pl-0'}
							formatCol={customCol}
							data={this.state}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = ({ vouchertype, purchasesAffected, voucher }) => {
	const config = voucher.config ? voucher.config[P_AFEC_STATE_CPA] : null
	const { voucherType } = vouchertype
	const {
		productsState,
		stateValidate,
		subCalculations,
		salesconfirm,
		productsUpdate
	} = purchasesAffected
	return {
		voucherType,
		productsState,
		stateValidate,
		subCalculations,
		salesconfirm,
		config,
		productsUpdate
	}
}

const connectForm = connect(mapStateToProps, {
	purchasesAffectedState,
	purchasesAffectedStateValidate,
	purchasesAffectedStateConfirm
})(ProviderStateTable)

export default themr('StateTableStyles', styles)(withTranslation()(connectForm))
