import {
	CANCEL_VOUCHER,
	CANCEL_VOUCHER_SUCCESS,
	CLEAR_SEARCH_VOUCHER_PARAMS,
	CLEAR_VOUCHER_SEARCH_STORE,
	GET_DETAIL_INVENTORY_SUMMARY,
	GET_DETAIL_INVENTORY_SUMMARY_SUCCESS,
	GET_DETAIL_TAX_SUMMARY,
	GET_DETAIL_TAX_SUMMARY_SUCCESS,
	GET_DETAIL_TREASURY_SUMMARY,
	GET_DETAIL_TREASURY_SUMMARY_SUCCESS,
	GET_DETAIL_VOUCHER_AFFECTATIONS,
	GET_DETAIL_VOUCHER_AFFECTATIONS_SUCCESS,
	GET_DETAIL_VOUCHER_HEADBOARD,
	GET_DETAIL_VOUCHER_HEADBOARD_SUCCESS,
	GET_DETAIL_VOUCHER_ITEMS,
	GET_DETAIL_VOUCHER_ITEMS_SUCCESS,
	GET_DETAIL_VOUCHER_SEAT,
	GET_DETAIL_VOUCHER_SEAT_SUCCESS,
	GET_DETAIL_VOUCHER_TAXES,
	GET_DETAIL_VOUCHER_TAXES_SUCCESS,
	GET_FILTER_ATTRIBUTES,
	GET_FILTER_ATTRIBUTES_SUCCESS,
	GET_FILTER_BRANCHES,
	GET_FILTER_BRANCHES_SUCCESS,
	GET_FILTER_CURRENCY,
	GET_FILTER_CURRENCY_SUCCESS,
	GET_FILTER_DEPOSITS,
	GET_FILTER_DEPOSITS_SUCCESS,
	GET_FILTER_FUND_ACCOUNT_TYPES,
	GET_FILTER_FUND_ACCOUNT_TYPES_SUCCESS,
	GET_FILTER_MODULES,
	GET_FILTER_MODULES_SUCCESS,
	GET_FILTER_PAYMENT_CONDITIONS,
	GET_FILTER_PAYMENT_CONDITIONS_SUCCESS,
	GET_FILTER_SELLERS,
	GET_FILTER_SELLERS_SUCCESS,
	GET_FILTER_TYPE_ACCOUNTING,
	GET_FILTER_TYPE_ACCOUNTING_SUCCESS,
	GET_FILTER_USERS,
	GET_FILTER_USERS_SUCCESS,
	GET_FILTER_VOUCHERS,
	GET_FILTER_VOUCHERS_SUCCESS,
	GET_FILTER_VOUCHER_CATEGORY,
	GET_FILTER_VOUCHER_CATEGORY_SUCCESS,
	GET_FILTER_VOUCHER_NAMES,
	GET_FILTER_VOUCHER_NAMES_SUCCESS,
	GET_INVENTORY_SERIE_DATA,
	GET_INVENTORY_SERIE_DATA_SUCCESS,
	GET_ITEM_CATEGORIES,
	GET_ITEM_CATEGORIES_SUCCESS,
	GET_ORDER_DIR_OPTIONS,
	GET_ORDER_DIR_OPTIONS_SUCCESS,
	GET_ORDER_OPTIONS,
	GET_ORDER_OPTIONS_SUCCESS,
	REMOVE_VOUCHER,
	REMOVE_VOUCHER_SUCCESS,
	SEARCH_VOUCHER,
	SEARCH_VOUCHER_SUCCESS,
	SET_VOUCHER_ACTIVE_TAB
} from 'constants/ActionsTypes'
import {
	IGetDetailVoucherHeadboardResponse,
	IGetDetailVoucherItemsResponse,
	ISearchVoucherParams
} from 'models/SearchVoucher'

export interface valueState {
	searchedVouchers: any
	headboardData: Partial<IGetDetailVoucherHeadboardResponse>
	itemsData: Partial<IGetDetailVoucherItemsResponse>
	taxesData: any
	affectationsData: any
	seatData: any
	taxSummary: any
	filterModules: any
	filterVouchers: any
	filterAttributes: any
	filterCurrencies: any
	filterBranches: any
	filterSellers: any
	filterTypeAccount: any
	filterPayments: any
	filterUsers: any
	filterAccountingEntry: any
	filterDeposits: any
	sortOptions: any
	sortDirOptions: any
	filterItemCategory: any
	searchParams: Partial<ISearchVoucherParams>
	activeSearchVoucherTab: string
	canceledVoucher: any
	removedVoucher: any
	treasuryData: any
	inventoryData: any
	inventorySerie: any
	voucherCategory?: any
	voucherNames?: any
}

const initialState = {
	searchedVouchers: null,
	headboardData: {},
	itemsData: {},
	taxesData: {},
	affectationsData: {},
	seatData: {},
	taxSummary: {},
	filterModules: {},
	removedVoucher: {},
	canceledVoucher: {},
	filterVouchers: [],
	filterAttributes: [],
	filterCurrencies: [],
	filterBranches: [],
	filterSellers: [],
	filterTypeAccount: [],
	filterPayments: [],
	filterUsers: [],
	filterAccountingEntry: [],
	filterDeposits: [],
	filterItemCategory: [],
	sortOptions: [],
	sortDirOptions: [],
	searchParams: {},
	activeSearchVoucherTab: '',
	treasuryData: {},
	inventoryData: {},
	inventorySerie: {},
	voucherCategory: {},
	voucherNames: {}
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case SEARCH_VOUCHER:
			return { ...state, searchedVouchers: null, searchParams: action.payload }
		case SEARCH_VOUCHER_SUCCESS:
			return { ...state, searchedVouchers: action.payload }
		case GET_DETAIL_VOUCHER_HEADBOARD:
			return { ...state, headboardData: {} }
		case GET_DETAIL_VOUCHER_HEADBOARD_SUCCESS:
			return { ...state, headboardData: action.payload }
		case GET_DETAIL_VOUCHER_ITEMS:
			return { ...state, itemsData: {} }
		case GET_DETAIL_VOUCHER_ITEMS_SUCCESS:
			return { ...state, itemsData: action.payload }
		case GET_DETAIL_VOUCHER_TAXES:
			return { ...state, taxesData: {} }
		case GET_DETAIL_VOUCHER_TAXES_SUCCESS:
			return { ...state, taxesData: action.payload }
		case GET_DETAIL_VOUCHER_AFFECTATIONS:
			return { ...state, affectationsData: {} }
		case GET_DETAIL_VOUCHER_AFFECTATIONS_SUCCESS:
			return { ...state, affectationsData: action.payload }
		case GET_DETAIL_VOUCHER_SEAT:
			return { ...state, seatData: {} }
		case GET_DETAIL_VOUCHER_SEAT_SUCCESS:
			return { ...state, seatData: action.payload }
		case GET_DETAIL_TAX_SUMMARY:
			return { ...state, taxSummary: {} }
		case GET_DETAIL_TAX_SUMMARY_SUCCESS:
			return { ...state, taxSummary: action.payload }
		case GET_FILTER_MODULES:
			return { ...state, filterModules: {} }
		case GET_FILTER_MODULES_SUCCESS:
			return { ...state, filterModules: action.payload.lista }
		case GET_FILTER_VOUCHERS:
			return { ...state, filterVouchers: [] }
		case GET_FILTER_VOUCHERS_SUCCESS:
			return { ...state, filterVouchers: action.payload.lista }
		case GET_FILTER_BRANCHES:
			return { ...state, filterBranches: [] }
		case GET_FILTER_BRANCHES_SUCCESS:
			return { ...state, filterBranches: action.payload.lista }
		case GET_FILTER_ATTRIBUTES:
			return { ...state, filterAttributes: [] }
		case GET_FILTER_ATTRIBUTES_SUCCESS:
			return { ...state, filterAttributes: action.payload.lista }
		case GET_FILTER_PAYMENT_CONDITIONS:
			return { ...state, filterPayments: [] }
		case GET_FILTER_PAYMENT_CONDITIONS_SUCCESS:
			return { ...state, filterPayments: action.payload.lista }
		case GET_FILTER_SELLERS:
			return { ...state, filterSellers: [] }
		case GET_FILTER_SELLERS_SUCCESS:
			return { ...state, filterSellers: action.payload.lista }
		case GET_FILTER_CURRENCY:
			return { ...state, filterCurrencies: [] }
		case GET_FILTER_CURRENCY_SUCCESS:
			return { ...state, filterCurrencies: action.payload.lista }
		case GET_FILTER_FUND_ACCOUNT_TYPES:
			return { ...state, filterTypeAccount: [] }
		case GET_FILTER_FUND_ACCOUNT_TYPES_SUCCESS:
			return { ...state, filterTypeAccount: action.payload.lista }
		case GET_FILTER_USERS:
			return { ...state, filterUsers: [] }
		case GET_FILTER_USERS_SUCCESS:
			return { ...state, filterUsers: action.payload.lista }
		case GET_FILTER_TYPE_ACCOUNTING:
			return { ...state, filterAccountingEntry: [] }
		case GET_FILTER_TYPE_ACCOUNTING_SUCCESS:
			return { ...state, filterAccountingEntry: action.payload.lista }
		case GET_FILTER_DEPOSITS:
			return { ...state, filterDeposits: [] }
		case GET_FILTER_DEPOSITS_SUCCESS:
			return { ...state, filterDeposits: action.payload.lista }
		case GET_ORDER_OPTIONS:
			return { ...state, sortOptions: [] }
		case GET_ORDER_OPTIONS_SUCCESS:
			return { ...state, sortOptions: action.payload.lista }
		case GET_ORDER_DIR_OPTIONS:
			return { ...state, sortDirOptions: [] }
		case GET_ORDER_DIR_OPTIONS_SUCCESS:
			return { ...state, sortDirOptions: action.payload.lista }
		case GET_ITEM_CATEGORIES:
			return { ...state, filterItemCategory: [] }
		case GET_ITEM_CATEGORIES_SUCCESS:
			return { ...state, filterItemCategory: action.payload.lista }
		case REMOVE_VOUCHER:
			return { ...state, removedVoucher: {} }
		case REMOVE_VOUCHER_SUCCESS:
			return { ...state, removedVoucher: action.payload }
		case CANCEL_VOUCHER:
			return { ...state, canceledVoucher: {} }
		case CANCEL_VOUCHER_SUCCESS:
			return { ...state, canceledVoucher: action.payload }
		case CLEAR_VOUCHER_SEARCH_STORE:
			return {
				...state,
				headboardData: {},
				itemsData: {},
				taxesData: {},
				affectationsData: {},
				seatData: {},
				taxSummary: {}
			}
		case CLEAR_SEARCH_VOUCHER_PARAMS:
			return {
				...state,
				searchParams: {},
				activeSearchVoucherTab: '',
				searchedVouchers: null
			}
		case SET_VOUCHER_ACTIVE_TAB:
			return { ...state, activeSearchVoucherTab: action.payload }
		case GET_DETAIL_TREASURY_SUMMARY:
			return { ...state, treasuryData: {} }
		case GET_DETAIL_TREASURY_SUMMARY_SUCCESS:
			return { ...state, treasuryData: action.payload }
		case GET_DETAIL_INVENTORY_SUMMARY:
			return { ...state, inventoryData: {} }
		case GET_DETAIL_INVENTORY_SUMMARY_SUCCESS:
			return { ...state, inventoryData: action.payload }
		case GET_INVENTORY_SERIE_DATA:
			return { ...state, inventorySerie: {} }
		case GET_INVENTORY_SERIE_DATA_SUCCESS:
			return { ...state, inventorySerie: action.payload }
		case GET_FILTER_VOUCHER_CATEGORY:
			return { ...state, voucherCategory: null }
		case GET_FILTER_VOUCHER_CATEGORY_SUCCESS:
			return { ...state, voucherCategory: action.payload.lista }
		case GET_FILTER_VOUCHER_NAMES:
			return { ...state, voucherNames: null }
		case GET_FILTER_VOUCHER_NAMES_SUCCESS:
			return { ...state, voucherNames: action.payload }

		default:
			return state
	}
}

export default rootReducer
