import crudPricesListTabsDataContent from 'components/crudPricesList/CrudPricesListTabsDataContents'
import { CRUD_PRICES_LIST } from 'utils/RoutePath'

export const pricesListConfig = {
	id: 'pricesListCrud',
	key: 'cod_preclis',
	descrip: 'descrip',
	searchMethod: 'get',
	category: 'S',
	translation: 'prices_list',
	dataUrl: CRUD_PRICES_LIST,
	urlApi: 'abm_inv_lprec',
	component: crudPricesListTabsDataContent
}
