import ValueTermsOfSaleContent from 'components/termsOfSale/ValueTermsOfSaleContent'
import { CRUD_TERMS_OF_SALE_DATA } from 'utils/RoutePath'

export const termsOfSaleConfig = {
	id: 'termsOfSaleCrud',
	key: 'cod_cvta',
	descrip: 'descrip',
	searchMethod: 'get',
	category: 'A',
	translation: 'terms_of_sale_data',
	dataUrl: CRUD_TERMS_OF_SALE_DATA,
	urlApi: 'abm_vta_cond',
	component: ValueTermsOfSaleContent
}
