import withMenu from 'components/common/withMenu'
import GlobalContainer from 'components/layout/globalContainer'
import PickStockForm from 'components/pickStock/PickStockForm'
import { P_PICK_STOCK_VTA } from 'constants/ConfigProcessNames'
import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'

class PickStock extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			isChange: false,
			enableCant: true
		}
	}

	getShortcuts = () => {
		const shortcuts = [
			{
				hotkey: { charCode: 'F2', modifiers: ['f2'] },
				action: this.changeInput,
				name: 'Change input search',
				description: 'Change input search'
			},
			{
				hotkey: { charCode: 'F4', modifiers: ['f4'] },
				action: this.toggleCant,
				name: 'change between enable and disable',
				description: 'change between enable and disable'
			},

			{
				hotkey: { charCode: 'F5', modifiers: ['f5'] },
				action: this.showFilters,
				name: 'to show and hide filters',
				description: 'to show and hide filters'
			}
		]

		return shortcuts
	}

	handleCallBackButton = () => {
		this.pickStockConfirmation()
	}

	changeInput = () => {
		this.setState((prevState) => ({ isChange: !prevState.isChange }))
	}

	toggleCant = () => {
		this.handleToggleCant()
	}

	showFilters = () => {
		this.handleShowFilters()
	}

	render() {
		const { isChange, enableCant } = this.state
		return (
			<Row>
				<GlobalContainer
					codeProcess={P_PICK_STOCK_VTA}
					shortcuts={this.getShortcuts()}
					callBackButton={this.handleCallBackButton}
					nextPage
				>
					<PickStockForm
						searchInput={isChange}
						enableCant={enableCant}
						propShowFilters={(click) => (this.handleShowFilters = click)}
						formConfirmation={(click) => (this.pickStockConfirmation = click)}
						handleToggleCant={(click) => (this.handleToggleCant = click)}
					/>
				</GlobalContainer>
			</Row>
		)
	}
}

export default withMenu(PickStock)
