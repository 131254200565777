import Axios from 'axios'
import {
	IConfirmInventoryPickStockParams,
	IGetCufPickStockParams,
	IGetDepositsPickStockParams,
	IGetInventoryPickStockParams,
	InventorySearchBarcodeParams
} from 'models/InventoryPickStock'
const urlBase = '/stock/ingr_pickeo'

export const inventoryPickStockSearchBarCodeApi = async (
	params: InventorySearchBarcodeParams
) => {
	const response = await Axios.post(`${urlBase}/cod_barra`, params)

	return response.data
}

export const getInventoryPickStockApi = async (
	params: IGetInventoryPickStockParams
) => {
	const response = await Axios.get(urlBase, { params })
	return response.data
}

export const getDepositsPickStockApi = async (
	params: IGetDepositsPickStockParams
) => {
	const response = await Axios.get(`/inv/ingr_pickeo/depositos`, { params })
	return response.data
}

export const getCufsPickStockApi = async (params: IGetCufPickStockParams) => {
	const response = await Axios.get(`/inv/ingr_pickeo/cuf`, { params })
	return response.data
}

export const confirmInventoryPickStockApi = async (
	params: IConfirmInventoryPickStockParams
) => {
	const response = await Axios.post(`/inv/ingr_pickeo/confirmar`, params)
	return response.data
}
