import InputButton from 'components/form/inputButton'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'
interface IVoucherNumberFormProps {
	fields: Array<IFieldStructure>
	readOnly: boolean
	submitForm: any
	voucherNumber: any
}

interface IVoucherNumberFormState {
	numberValue: string
	numberDescValue: string
}

class VoucherNumberForm extends Component<
	IVoucherNumberFormProps,
	IVoucherNumberFormState
> {
	constructor(props: IVoucherNumberFormProps) {
		super(props)
		this.state = {
			numberValue: '',
			numberDescValue: ''
		}
	}

	componentDidMount(): void {
		const { voucherNumber } = this.props

		if (!_.isEmpty(voucherNumber)) {
			this.setNumberStateValues(voucherNumber)
		}
	}

	componentDidUpdate(prevProps: Readonly<IVoucherNumberFormProps>): void {
		const { voucherNumber } = this.props
		if (
			voucherNumber !== prevProps.voucherNumber &&
			!_.isEmpty(voucherNumber)
		) {
			this.setNumberStateValues(voucherNumber)
		}
	}

	/**
	 * to save api values in state
	 * @param voucherNumber
	 */
	setNumberStateValues = (voucherNumber: any) => {
		this.setState({
			numberValue: voucherNumber.numero,
			numberDescValue: voucherNumber.descrip_numerador
		})
	}

	render() {
		const { readOnly, fields } = this.props

		const { numberValue, numberDescValue } = this.state

		const numberProps = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'Numero',
			inputId: 'numero',
			name: 'numero',
			placeholder: 'Numeros',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: () => {},
			value: numberValue,
			autoComplete: 'off',
			disable: !readOnly,
			disabledInput: !readOnly,
			onChange: (numberValue: any) => this.setState({ numberValue })
		}

		const descProps = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'Descripcion',
			inputId: 'descrip_numerador',
			name: 'descrip_numerador',
			placeholder: 'Numeros',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: () => {},
			value: numberDescValue,
			autoComplete: 'off',
			disable: true,
			disabledInput: true,
			onChange: (data: any) => {}
		}

		return (
			<Fragment>
				<Row className={`col-12`}>
					<InputText {...numberProps} />
					<InputText {...descProps} />
				</Row>
				<Row>
					<Col sm={{ span: 6, offset: 3 }} className={'text-center'}>
						<InputButton
							valueButton={'Aceptar'}
							onClick={() => this.props.submitForm(numberValue)}
							type={'primary'}
							customStyle={{ minWidth: '145px' }}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ vouchertype }: any) => {
	const { voucherNumber } = vouchertype
	return { voucherNumber }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherNumberForm)
