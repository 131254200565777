import { getConfigShoppingCartSuccess } from 'actions'
import { getConfigShoppingCartApi } from 'api/ShoppingCart'
import { GET_CONFIG_SHOPPING_CART } from 'constants/ActionsTypes'
import { IShoppingCartAction } from 'models/ShoppingCart'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

function* getConfigShoppingCartRequest({ payload }: IShoppingCartAction): any {
	try {
		const config: any = yield call(getConfigShoppingCartApi, payload)
		yield put(getConfigShoppingCartSuccess(config))
	} catch (error) {}
}

export function* getConfigShoppingCartSaga() {
	yield takeLatest(GET_CONFIG_SHOPPING_CART, getConfigShoppingCartRequest)
}

export default function* rootSaga() {
	yield all([fork(getConfigShoppingCartSaga)])
}
