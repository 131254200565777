import {
	clearSearchVoucherStore,
	getConfigFieldsCrud,
	showMessage
} from 'actions'
import CommonTabs from 'components/abmUtils/CommonTabs'
import withMenu from 'components/common/withMenu'
import CrudsContainer from 'components/layout/CrudsContainer'
import DetailHeader from 'components/searchVouchers/DetailHeader'
import SearchVoucherAffectationsTable from 'components/searchVouchers/SearchVoucherAffectationsTable'
import SearchVoucherHeaderTable from 'components/searchVouchers/SearchVoucherHeaderTable'
import SearchVoucherItemsTable from 'components/searchVouchers/SearchVoucherItemsTable'
import SearchVoucherResumenTable from 'components/searchVouchers/SearchVoucherResumenTable'
import SearchVoucherSeatTable from 'components/searchVouchers/SearchVoucherSeatTable'
import SearchVouchersInventory from 'components/searchVouchers/SearchVouchersInventory'
import SearchVouchersTreasuryTable from 'components/searchVouchers/SearchVouchersTreasuryTable'
import SearchVoucherTaxesTable from 'components/searchVouchers/SearchVoucherTaxesTable'
import {
	P_CONST_SEARCH_VOUCHER_DETAIL,
	P_REMOVE_SEARCH_VOUCHER_DETAIL
} from 'constants/ConfigProcessNames'
import { IAbmStructureResponse } from 'constants/valuesInterfaces/interfaces'
import { getFiltersData } from 'lib/Utils'
import {
	IGetDetailVoucherHeadboardParams,
	IGetDetailVoucherHeadboardResponse,
	IGetDetailVoucherItemsResponse
} from 'models/SearchVoucher'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { SEARCH_VOUCHER } from 'utils/RoutePath'

interface SearchVoucherDetailProps extends WithTranslation {
	t?: any
	match?: any
	getDetailVoucherHeadboard: ({ id }: IGetDetailVoucherHeadboardParams) => void
	headboardData: IGetDetailVoucherHeadboardResponse
	itemsData: IGetDetailVoucherItemsResponse
	taxesData: any
	affectationsData: any
	seatData: any
	taxSummary: any
	getConfigFieldsCrud: any
	fieldsConfig: IAbmStructureResponse
	clearSearchVoucherStore: () => void
	removedVoucher?: any
	canceledVoucher?: any
	history?: any
	showMessage?: any
}

interface SearchVoucherDetailState {
	voucherInfo: Partial<IGetDetailVoucherHeadboardResponse>
	id: string
	activeTab: string
	actionOp: string
}

const groupers = [
	{
		key: 'head',
		filterValue: 'form_cabecera',
		filterTable: 'form_cab_tot'
	},
	{
		key: 'item',
		filterValue: 'form_items',
		filterTable: 'form_items_grilla'
	},
	{
		key: 'taxes',
		filterValue: 'form_impu',
		filterTable: 'form_impu_grilla'
	},
	{
		key: 'affectations',
		filterValue: 'form_afec',
		filterTable: 'form_afec_grilla'
	},
	{
		key: 'seat',
		filterValue: 'form_asiento',
		filterTable: 'form_asiento_grilla'
	},
	{
		key: 'resumen',
		filterValue: 'form_res_impu',
		filterTable: 'form_res_impu_grilla'
	},
	{
		key: 'treasury',
		filterValue: 'form_tesoreria',
		filterTable: 'form_tesoreria_gr'
	},
	{
		key: 'inventory',
		filterValue: 'form_invent',
		filterTable: 'form_invent_gr'
	}
]
class SearchVoucherDetail extends Component<
	SearchVoucherDetailProps,
	SearchVoucherDetailState
> {
	constructor(props: SearchVoucherDetailProps) {
		super(props)

		this.state = {
			id: '',
			voucherInfo: {},
			activeTab: 'head',
			actionOp: ''
		}
	}

	componentDidMount() {
		const { match } = this.props
		if (match.params) {
			const { id, op } = match.params
			this.setState({ id, actionOp: op })
			this.props.getConfigFieldsCrud({
				cod_abm:
					op === 'consult'
						? P_CONST_SEARCH_VOUCHER_DETAIL
						: P_REMOVE_SEARCH_VOUCHER_DETAIL
			})
		}
	}

	componentWillUnmount = () => {
		this.props.clearSearchVoucherStore()
	}

	componentDidUpdate = (prevProps: SearchVoucherDetailProps) => {
		const { headboardData, removedVoucher, showMessage, canceledVoucher } =
			this.props

		if (prevProps.headboardData !== headboardData && headboardData) {
			this.setState({ voucherInfo: headboardData })
		}

		if (
			prevProps.removedVoucher !== removedVoucher &&
			!_.isEmpty(removedVoucher)
		) {
			this.props.history.push(`${SEARCH_VOUCHER}/filters`)
			showMessage({
				showError: true,
				type: removedVoucher.Resultado ? 'success' : 'error',
				message: 'Comprobante Eliminado',
				description: removedVoucher.Mens_error
			})
		}
		if (
			prevProps.canceledVoucher !== canceledVoucher &&
			canceledVoucher.Resultado
		) {
			this.props.history.push(`${SEARCH_VOUCHER}/filters`)
			showMessage({
				showError: true,
				type: 'success',
				message: 'Comprobante Anulado',
				description: canceledVoucher.Mens_error
			})
		}
	}

	handleActiveKey = (activeTab: string) => {
		this.setState({ activeTab })
	}

	render() {
		const { fieldsConfig, t } = this.props

		const fieldsHeadBoard = fieldsConfig
			? getFiltersData(fieldsConfig.campos, { agrupador: 'cabecera' })
			: []

		const { id, activeTab, voucherInfo } = this.state
		const gr = _.findWhere(groupers, { key: activeTab })

		const fieldsTreasuryModal = fieldsConfig
			? getFiltersData(fieldsConfig.campos, { agrupador: 'modal_tesoreria' })
			: []

		const fieldsSourceModal = fieldsConfig
			? getFiltersData(fieldsConfig.campos, { agrupador: 'form_inv_origen' })
			: []

		const fieldsSerieModal = fieldsConfig
			? getFiltersData(fieldsConfig.campos, { agrupador: 'form_inv_series' })
			: []

		const extraInventoryFields = {
			fieldsSourceModal,
			fieldsSerieModal
		}

		const extraFields = {
			fieldsTreasuryModal
		}
		const paramsForm: any = {
			id,
			fields: fieldsConfig
				? getFiltersData(fieldsConfig.campos, {
						agrupador: gr ? gr.filterValue : ''
				  })
				: [],
			fieldsTable: fieldsConfig
				? getFiltersData(fieldsConfig.campos, {
						agrupador: gr ? gr.filterTable : ''
				  })
				: []
		}

		const propsTables: any = {
			fieldsTableAttr: fieldsConfig
				? getFiltersData(fieldsConfig.campos, { agrupador: 'form_cab_atr' })
				: []
		}

		const customTabs = [
			{
				title: t('search_voucher.detail.tabs.headboard'),
				key: 'head',
				content:
					activeTab === 'head' ? (
						<SearchVoucherHeaderTable {...paramsForm} {...propsTables} />
					) : (
						<div />
					)
			},
			{
				title: t('search_voucher.detail.tabs.items'),
				key: 'item',
				content:
					activeTab === 'item' ? (
						<SearchVoucherItemsTable voucherId={id} {...paramsForm} />
					) : (
						<div />
					)
			},
			{
				title: t('search_voucher.tabs.inventory'),
				key: 'inventory',
				content:
					activeTab === 'inventory' ? (
						<SearchVouchersInventory
							{...paramsForm}
							{...extraInventoryFields}
						/>
					) : (
						<div />
					)
			},
			{
				title: t('search_voucher.detail.tabs.taxes'),
				key: 'taxes',
				content:
					activeTab === 'taxes' ? (
						<SearchVoucherTaxesTable {...paramsForm} />
					) : (
						<div />
					)
			},
			{
				title: t('search_voucher.detail.tabs.affectations'),
				key: 'affectations',
				content:
					activeTab === 'affectations' ? (
						<SearchVoucherAffectationsTable {...paramsForm} />
					) : (
						<div />
					)
			},
			{
				title: t('search_voucher.detail.tabs.seat'),
				key: 'seat',
				content:
					activeTab === 'seat' ? (
						<SearchVoucherSeatTable {...paramsForm} />
					) : (
						<div />
					)
			},
			{
				title: t('search_voucher.detail.tabs.resumen'),
				key: 'resumen',
				content:
					activeTab === 'resumen' ? (
						<SearchVoucherResumenTable {...paramsForm} />
					) : (
						<div />
					)
			},
			{
				title: t('search_voucher.tabs.treasury'),
				key: 'treasury',
				content:
					activeTab === 'treasury' ? (
						<SearchVouchersTreasuryTable {...paramsForm} {...extraFields} />
					) : (
						<div />
					)
			}
		]

		const containerProps = {
			disableArrows: true
		}

		return (
			<CrudsContainer {...containerProps}>
				<Row className="mt-4">
					<Col sm={12}>
						<DetailHeader
							voucherInfo={voucherInfo}
							fields={fieldsHeadBoard}
							voucherId={id}
						/>
					</Col>
					<Col sm={12} className={'mt-0 pt-0'}>
						{id && (
							<CommonTabs
								customTabs={customTabs}
								defaultActiveKey={activeTab}
								getActiveKey={this.handleActiveKey}
							/>
						)}
					</Col>
				</Row>
			</CrudsContainer>
		)
	}
}

const mapStateToProps = ({ configCrud, searchVoucher }: any) => {
	const { fieldsConfig } = configCrud
	const {
		headboardData,
		itemsData,
		taxesData,
		affectationsData,
		seatData,
		taxSummary,
		removedVoucher,
		canceledVoucher
	} = searchVoucher
	return {
		fieldsConfig,
		headboardData,
		itemsData,
		taxesData,
		affectationsData,
		seatData,
		taxSummary,
		removedVoucher,
		canceledVoucher
	}
}

const connectForm = connect(mapStateToProps, {
	getConfigFieldsCrud,
	clearSearchVoucherStore,
	showMessage
})(SearchVoucherDetail)

export default withTranslation()(withMenu(withRouter(connectForm)))
