import {
	crudDelSellerCommissionSuccess,
	crudGetSellerCommissionsSuccess,
	crudGetSellerCommissionSuccess,
	crudUpdateSellerCommissionSuccess
} from 'actions'
import {
	crudDelSellerCommission,
	crudGetSellerCommission,
	crudGetSellerCommissions,
	crudUpdateSellerCommission
} from 'api/SellerCommissionsApi'
import {
	CRUD_DEL_SELLER_COMMISSION,
	CRUD_GET_SELLER_COMMISSION,
	CRUD_GET_SELLER_COMMISSIONS,
	CRUD_UPDATE_SELLER_COMMISSION
} from 'constants/ActionsTypes'
import {
	ICrudGetSellerCommissionSaga,
	ICrudGetSellerCommissionsSaga,
	ICrudUpdateSellerCommissionSaga
} from 'models/CrudSellerInterface'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

function* crudGetSellerCommissionsRequest({
	payload
}: ICrudGetSellerCommissionsSaga): any {
	try {
		const values: any = yield call(crudGetSellerCommissions, payload)
		yield put(crudGetSellerCommissionsSuccess(values))
	} catch (error) {}
}

function* crudGetSellerCommissionRequest({
	payload
}: ICrudGetSellerCommissionSaga): any {
	try {
		const values: any = yield call(crudGetSellerCommission, payload)
		yield put(crudGetSellerCommissionSuccess(values))
	} catch (error) {}
}

function* crudUpdateSellerCommissionRequest({
	payload
}: ICrudUpdateSellerCommissionSaga): any {
	try {
		const values: any = yield call(crudUpdateSellerCommission, payload)
		yield put(crudUpdateSellerCommissionSuccess(values))
	} catch (error) {}
}

function* crudDelSellerCommissionRequest({
	payload
}: ICrudGetSellerCommissionSaga): any {
	try {
		const values: any = yield call(crudDelSellerCommission, payload)
		yield put(crudDelSellerCommissionSuccess(values))
	} catch (error) {}
}

export function* crudGetSellerCommissionsSaga() {
	yield takeLatest(CRUD_GET_SELLER_COMMISSIONS, crudGetSellerCommissionsRequest)
}

export function* crudGetSellerCommissionSaga() {
	yield takeLatest(CRUD_GET_SELLER_COMMISSION, crudGetSellerCommissionRequest)
}

export function* crudUpdateSellerCommissionSaga() {
	yield takeLatest(
		CRUD_UPDATE_SELLER_COMMISSION,
		crudUpdateSellerCommissionRequest
	)
}

export function* crudDelSellerCommissionSaga() {
	yield takeLatest(CRUD_DEL_SELLER_COMMISSION, crudDelSellerCommissionRequest)
}

export default function* rootSata() {
	yield all([
		fork(crudGetSellerCommissionsSaga),
		fork(crudGetSellerCommissionSaga),
		fork(crudUpdateSellerCommissionSaga),
		fork(crudDelSellerCommissionSaga)
	])
}
