import CommonLabelCol from 'components/common/CommonLabelCol'
import InputButton from 'components/form/inputButton'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'

interface SearchVoucherTreasuryModalProps {
	closeModal: () => void
	fields: Array<IFieldStructure>
	treasuryInfo: any
}

export default class SearchVoucherTreasuryModal extends Component<SearchVoucherTreasuryModalProps> {
	render() {
		const { fields, treasuryInfo } = this.props

		return (
			<Fragment>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 12 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="tipo"
						value={`${treasuryInfo.tipo}` || ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 12 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="cuenta"
						value={`${treasuryInfo.cuenta}` || ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 12 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="cat_item"
						value={treasuryInfo.cat_item || ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="fec_emis"
						value={`${treasuryInfo.fec_emis}` || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="fec_cobro"
						value={`${treasuryInfo.fec_cobro}` || ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="banco"
						value={treasuryInfo.banco || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="chq_suc"
						value={`${treasuryInfo.chq_suc}` || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="chq_cpos"
						value={`${treasuryInfo.chq_cpos}` || ''}
					/>
				</Row>

				<Row>
					<CommonLabelCol
						formCol={{ sm: 12 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="nro_cta"
						value={treasuryInfo.nro_cta || ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 12 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="nrochq"
						value={treasuryInfo.nrochq || ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="cod_mone"
						value={treasuryInfo.cod_mone || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="importe"
						value={treasuryInfo.importe || ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 12 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="titular"
						value={treasuryInfo.titular || ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 12 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="cuit"
						value={treasuryInfo.cuit || ''}
					/>
				</Row>
				<Row>
					<Col className="text-center">
						<InputButton
							valueButton={'Cancelar'}
							onClick={() => this.props.closeModal()}
							type={'primary'}
							customStyle={{ minWidth: '125px' }}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}
