import { ICrudGenericFields } from 'models/CrudInterface'

export const searchFields: Array<ICrudGenericFields> = [
	{
		id: 'lk_nom',
		type: 'text',
		labelWidth: 'col-sm-2',
		width: 'col-sm-9',
		height: 'col-sm-5',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'lk_calle',
		type: 'text',
		labelWidth: 'col-sm-2',
		width: 'col-sm-9',
		height: 'col-sm-4',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'lk_localidad',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-3',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'lk_val',
		type: 'combo',
		labelWidth: 'col-sm-1',
		width: 'col-sm-3',
		height: 'col-sm-12',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'customerCategory',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'lk_movim_desde',
		type: 'text',
		labelWidth: 'col-sm-6',
		width: 'col-sm-5',
		height: 'col-sm-3',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'lk_movim_hasta',
		type: 'text',
		labelWidth: 'col-sm-4',
		width: 'col-sm-5',
		height: 'col-sm-3',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
