import {
	CLEAR_ITEM_STORE,
	CONFIRM_IND_LOAD_ITEMS,
	CONFIRM_IND_LOAD_ITEMS_SUCCESS,
	CONFIRM_LOAD_ITEMS,
	CONFIRM_LOAD_ITEMS_SUCCESS,
	CONFIRM_PROVIDER_IND_LOAD_ITEMS,
	CONFIRM_PROVIDER_IND_LOAD_ITEMS_SUCCESS,
	CONFIRM_PROVIDER_LOAD_ITEMS,
	CONFIRM_PROVIDER_LOAD_ITEMS_SUCCESS,
	CONFIRM_TABLE_ITEMS,
	CONFIRM_TABLE_ITEMS_SUCCESS,
	GET_CART_ITEM_INFO,
	GET_CART_ITEM_INFO_SUCCESS,
	GET_LOAD_ITEMS,
	GET_LOAD_ITEMS_SUCCESS,
	GET_PROVIDER_CART_ITEM_INFO,
	GET_PROVIDER_CART_ITEM_INFO_SUCCESS,
	ITEM_PROVIDER_ADD_TO_CART,
	ITEM_PROVIDER_ADD_TO_CART_SUCCESS,
	UPDATE_CART_ITEM,
	UPDATE_CART_ITEM_SUCCESS,
	UPDATE_PROVIDER_CART_ITEM,
	UPDATE_PROVIDER_CART_ITEM_SUCCESS
} from 'constants/ActionsTypes'

export const getLoadItems = (params) => {
	return {
		type: GET_LOAD_ITEMS,
		payload: params
	}
}

export const getLoadItemsSuccess = (response) => {
	return {
		type: GET_LOAD_ITEMS_SUCCESS,
		payload: response
	}
}

export const confirmLoadItems = (params) => {
	return {
		type: CONFIRM_LOAD_ITEMS,
		payload: params
	}
}

export const confirmLoadItemsSuccess = (response) => {
	return {
		type: CONFIRM_LOAD_ITEMS_SUCCESS,
		payload: response
	}
}

export const confirmTableItems = (payload) => {
	return {
		type: CONFIRM_TABLE_ITEMS,
		payload
	}
}

export const confirmTableItemsSuccess = (response) => {
	return {
		type: CONFIRM_TABLE_ITEMS_SUCCESS,
		payload: response
	}
}

export const itemProviderAddToCart = (params) => {
	return {
		type: ITEM_PROVIDER_ADD_TO_CART,
		payload: params
	}
}

export const itemProviderAddToCartSuccess = (response) => {
	return {
		type: ITEM_PROVIDER_ADD_TO_CART_SUCCESS,
		payload: response
	}
}

export const getCartItemInfo = (params) => {
	return {
		type: GET_CART_ITEM_INFO,
		payload: params
	}
}

export const getCartItemInfoSuccess = (response) => {
	return {
		type: GET_CART_ITEM_INFO_SUCCESS,
		payload: response
	}
}

export const updateCartItem = (params) => {
	return {
		type: UPDATE_CART_ITEM,
		payload: params
	}
}

export const updateCartItemSuccess = (response) => {
	return {
		type: UPDATE_CART_ITEM_SUCCESS,
		payload: response
	}
}

export const confirmIndLoadItems = (params) => {
	return {
		type: CONFIRM_IND_LOAD_ITEMS,
		payload: params
	}
}

export const confirmIndLoadItemsSuccess = (response) => {
	return {
		type: CONFIRM_IND_LOAD_ITEMS_SUCCESS,
		payload: response
	}
}

export const clearItemStore = () => {
	return {
		type: CLEAR_ITEM_STORE
	}
}

export const getProviderCartItemInfo = (params) => {
	return {
		type: GET_PROVIDER_CART_ITEM_INFO,
		payload: params
	}
}

export const getProviderCartItemInfoSuccess = (response) => {
	return {
		type: GET_PROVIDER_CART_ITEM_INFO_SUCCESS,
		payload: response
	}
}

export const updateProviderCartItem = (params) => {
	return {
		type: UPDATE_PROVIDER_CART_ITEM,
		payload: params
	}
}

export const updateProviderCartItemSuccess = (response) => {
	return {
		type: UPDATE_PROVIDER_CART_ITEM_SUCCESS,
		payload: response
	}
}

export const confirmProvIndLoadItems = (params) => {
	return {
		type: CONFIRM_PROVIDER_IND_LOAD_ITEMS,
		payload: params
	}
}

export const confirmProvIndLoadItemsSuccess = (response) => {
	return {
		type: CONFIRM_PROVIDER_IND_LOAD_ITEMS_SUCCESS,
		payload: response
	}
}

export const confirmProvLoadItems = (params) => {
	return {
		type: CONFIRM_PROVIDER_LOAD_ITEMS,
		payload: params
	}
}

export const confirmProvLoadItemsSuccess = (response) => {
	return {
		type: CONFIRM_PROVIDER_LOAD_ITEMS_SUCCESS,
		payload: response
	}
}
