import {
	ICrudCreateCustomerCode,
	ICrudCreateCustomerCodeRes,
	ICrudGetCustomerCode,
	ICrudGetCustomerCodeConfig,
	ICrudGetCustomerCodeConfigRes,
	ICrudGetCustomerCodeRes
} from 'models/CrudCustomerInterface'
import {
	CRUD_CLEAR_CUSTOMER_CODE_STORE,
	CRUD_CREATE_CUSTOMER_CODE,
	CRUD_CREATE_CUSTOMER_CODE_SUCCESS,
	CRUD_GET_CUSTOMER_CODE,
	CRUD_GET_CUSTOMER_CODE_CONFIG,
	CRUD_GET_CUSTOMER_CODE_CONFIG_SUCCESS,
	CRUD_GET_CUSTOMER_CODE_SUCCESS,
	CRUD_VALIDATE_CUSTOMER_CODE,
	CRUD_VALIDATE_CUSTOMER_CODE_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetCustomerCode = (payload: ICrudGetCustomerCode) => {
	return {
		type: CRUD_GET_CUSTOMER_CODE,
		payload
	}
}

export const crudGetCustomerCodeSuccess = (
	payload: ICrudGetCustomerCodeRes
) => {
	return {
		type: CRUD_GET_CUSTOMER_CODE_SUCCESS,
		payload
	}
}

export const crudGetCustomerCodeConfig = (
	payload: ICrudGetCustomerCodeConfig
) => {
	return {
		type: CRUD_GET_CUSTOMER_CODE_CONFIG,
		payload
	}
}

export const crudGetCustomerCodeConfigSuccess = (
	payload: ICrudGetCustomerCodeConfigRes
) => {
	return {
		type: CRUD_GET_CUSTOMER_CODE_CONFIG_SUCCESS,
		payload
	}
}

export const crudCreateCustomerCode = (payload: ICrudCreateCustomerCode) => {
	return {
		type: CRUD_CREATE_CUSTOMER_CODE,
		payload
	}
}

export const crudCreateCustomerCodeSuccess = (
	payload: ICrudCreateCustomerCodeRes
) => {
	return {
		type: CRUD_CREATE_CUSTOMER_CODE_SUCCESS,
		payload
	}
}

export const crudValidateCustomerCode = (payload: ICrudCreateCustomerCode) => {
	return {
		type: CRUD_VALIDATE_CUSTOMER_CODE,
		payload
	}
}

export const crudValidateCustomerCodeSuccess = (
	payload: ICrudCreateCustomerCodeRes
) => {
	return {
		type: CRUD_VALIDATE_CUSTOMER_CODE_SUCCESS,
		payload
	}
}

export const crudClearCustomerCodeStore = () => ({
	type: CRUD_CLEAR_CUSTOMER_CODE_STORE
})
