import {
	CRUD_CLEAR_DIRECT_LA_ASSIGNATIONS_STORE,
	CRUD_GET_DIRECT_LA_ASSIGNATIONS,
	CRUD_GET_DIRECT_LA_ASSIGNATIONS_SUCCESS,
	CRUD_UPDATE_DIRECT_LA_ASSIGNATIONS,
	CRUD_UPDATE_DIRECT_LA_ASSIGNATIONS_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	LAAssignationsSearched: any
	LAAssignationsUpdated: any
}

const initialState = {
	LAAssignationsSearched: null,
	LAAssignationsUpdated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_DIRECT_LA_ASSIGNATIONS:
			return {
				...state,
				LAAssignationsSearched: null,
				paramsSearch: action.payload
			}
		case CRUD_GET_DIRECT_LA_ASSIGNATIONS_SUCCESS:
			return { ...state, LAAssignationsSearched: action.payload }
		case CRUD_UPDATE_DIRECT_LA_ASSIGNATIONS:
			return { ...state, LAAssignationsUpdated: null }
		case CRUD_UPDATE_DIRECT_LA_ASSIGNATIONS_SUCCESS:
			return { ...state, LAAssignationsUpdated: action.payload }
		case CRUD_CLEAR_DIRECT_LA_ASSIGNATIONS_STORE:
			return {
				...state,
				LAAssignationsSearched: null,
				LAAssignationsUpdated: null
			}
		default:
			return state
	}
}

export default rootReducer
