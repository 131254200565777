import GlobalContainer from 'components/layout/globalContainer'
import VoucherAffectingTable from 'components/voucherAffecting/voucherAffectingTable'
import { P_AFEC_IMPO_VTA } from 'constants/ConfigProcessNames'
import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getVoucherType } from '../../actions'
import withMenu from '../../components/common/withMenu'

class VoucherInvolvement extends PureComponent {
	getShortcuts = () => {
		return [
			{
				hotkey: {
					charCode: '40',
					modifiers: ['arrowDown', 'arrowUp' /*, "arrowLeft", "arrowRight", */]
				},
				action: this.moveArrow,
				name: 'Arrow move',
				description: 'Arrow move'
			}
		]
	}

	getRowParams = (event) => {
		const params = event.target.id.split('-')
		const rowId = params[1]
		const field = params[0]
		return { rowId, field }
	}

	moveArrow = async (e) => {
		if (e.target.id) {
			const { rowId, field } = this.getRowParams(e)
			this.handleMoveArrow({ rowId, field, key: e.code })
		}
	}

	handleCallBackButton = (urlNext) => {
		this.affectingConfirmation()
	}

	render() {
		return (
			<Row>
				<GlobalContainer
					shortcuts={this.getShortcuts()}
					codeProcess={P_AFEC_IMPO_VTA}
					nextPage
					callBackButton={this.handleCallBackButton}
				>
					<VoucherAffectingTable
						formConfirmation={(click) => (this.affectingConfirmation = click)}
						handleMoveArrow={(click) => (this.handleMoveArrow = click)}
					/>
				</GlobalContainer>
			</Row>
		)
	}
}

const mapStateToProps = ({ vouchertype }) => {
	const { voucherType } = vouchertype
	return { voucherType }
}

export default connect(mapStateToProps, { getVoucherType })(
	withTranslation()(withRouter(withMenu(VoucherInvolvement)))
)
