import { getConfigFieldsCrud } from 'actions'
import CommonTabs from 'components/abmUtils/CommonTabs'
import withMenu from 'components/common/withMenu'
import DetailEquivalentData from 'components/SearchProducts/DetailEquivalentData'
import DetailMainData from 'components/SearchProducts/DetailMainData'
import DetailSalesData from 'components/SearchProducts/DetailSalesData'
import DetailShoppingData from 'components/SearchProducts/DetailShoppingData'
import DetailStockData from 'components/SearchProducts/DetailStockData'
import DetailSuggestedData from 'components/SearchProducts/DetailSuggestedData'
import { P_SEARCH_PRODUCT_DETAIL } from 'constants/ConfigProcessNames'
import { IAbmStructureResponse } from 'constants/valuesInterfaces/interfaces'
import { getFiltersData } from 'lib/Utils'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import ProductDetailHeader from './ProductDetailHeader'

const groupers = [
	{
		key: 'main_data',
		filterValue: 'principal',
		filterTable: 'ppal_atributos'
	},
	{
		key: 'stock',
		filterValue: 'form.stock',
		filterTable: 'form.stock.grilla'
	},
	{
		key: 'sales',
		filterValue: 'form.ventas',
		filterTable: 'form.ventas.gr.lis'
	},
	{
		key: 'shopping',
		filterValue: 'form.cpas',
		filterTable: 'form.cpas.gr.lis'
	},
	{
		key: 'suggested',
		filterValue: 'form_asiento',
		filterTable: 'sugeridos.gr'
	},
	{
		key: 'equivalents',
		filterValue: 'form_res_impu',
		filterTable: 'equivalentes.gr'
	}
]

interface SearchedProductDetailProps extends WithTranslation {
	t?: any
	match?: any
	getConfigFieldsCrud: (payload: { cod_abm: string }) => void
	fieldsConfig: IAbmStructureResponse
	detailMainData?: any
}

interface SearchedProductDetailState {
	id: string
	activeTab: string
}

class SearchedProductDetails extends Component<
	SearchedProductDetailProps,
	SearchedProductDetailState
> {
	constructor(props: SearchedProductDetailProps) {
		super(props)
		this.state = {
			id: '',
			activeTab: 'main_data'
		}
	}

	componentDidMount = () => {
		const { match } = this.props
		if (match.params) {
			const { id } = match.params
			this.setState({ id })
			this.props.getConfigFieldsCrud({ cod_abm: P_SEARCH_PRODUCT_DETAIL })
		}
	}

	/**
	 * to set tab name
	 * @param activeTab
	 */
	handleActiveKey = (activeTab: string) => {
		this.setState({ activeTab })
	}

	render() {
		const { activeTab, id } = this.state
		const { t, fieldsConfig, detailMainData } = this.props
		const gr = _.findWhere(groupers, { key: activeTab })

		const fieldsHeader = fieldsConfig
			? getFiltersData(fieldsConfig.campos, {
					agrupador: 'cabecera'
			  })
			: []

		const paramsForm = {
			productId: id,
			fields: fieldsConfig
				? getFiltersData(fieldsConfig.campos, {
						agrupador: gr ? gr.filterValue : ''
				  })
				: [],
			fieldsTable: fieldsConfig
				? getFiltersData(fieldsConfig.campos, {
						agrupador: gr ? gr.filterTable : ''
				  })
				: []
		}

		const extraFields = {
			fieldsTableStatic: fieldsConfig
				? getFiltersData(fieldsConfig.campos, {
						agrupador: 'form.ventas.gr.est'
				  })
				: []
		}

		const extraFieldsShopping = {
			fieldsTableStatic: fieldsConfig
				? getFiltersData(fieldsConfig.campos, {
						agrupador: 'form.cpas.gr.est'
				  })
				: []
		}

		const customTabs = [
			{
				title: t('search_product_detail.tabs.main_data'),
				key: 'main_data',
				content:
					activeTab === 'main_data' ? (
						<DetailMainData {...paramsForm} />
					) : (
						<div />
					)
			},
			{
				title: t('search_product_detail.tabs.stock'),
				key: 'stock',
				content:
					activeTab === 'stock' ? <DetailStockData {...paramsForm} /> : <div />
			},
			{
				title: t('search_product_detail.tabs.sales'),
				key: 'sales',
				content:
					activeTab === 'sales' ? (
						<DetailSalesData {...paramsForm} {...extraFields} />
					) : (
						<div />
					)
			},
			{
				title: t('search_product_detail.tabs.shopping'),
				key: 'shopping',
				content:
					activeTab === 'shopping' ? (
						<DetailShoppingData {...paramsForm} {...extraFieldsShopping} />
					) : (
						<div />
					)
			},
			{
				title: t('search_product_detail.tabs.suggested'),
				key: 'suggested',
				content:
					activeTab === 'suggested' ? (
						<DetailSuggestedData {...paramsForm} />
					) : (
						<div />
					)
			},
			{
				title: t('search_product_detail.tabs.equivalents'),
				key: 'equivalents',
				content:
					activeTab === 'equivalents' ? (
						<DetailEquivalentData {...paramsForm} />
					) : (
						<div />
					)
			}
		]
		return (
			<Fragment>
				<Row className="mt-1">
					<Col sm={{ span: 12 }}>
						<ProductDetailHeader
							productInfo={detailMainData}
							fields={fieldsHeader}
						/>
					</Col>
					<Col sm={12} className={'mt-0 pt-0'}>
						{id && (
							<CommonTabs
								customTabs={customTabs}
								defaultActiveKey={activeTab}
								getActiveKey={this.handleActiveKey}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ configCrud, searchProducts }: any) => {
	const { fieldsConfig } = configCrud
	const { detailMainData } = searchProducts

	return {
		fieldsConfig,
		detailMainData
	}
}

const connectForm = connect(mapStateToProps, {
	getConfigFieldsCrud
	// clearSearchVoucherStore
})(SearchedProductDetails)

export default withTranslation()(withMenu(connectForm))
