import _ from 'underscore'

/**
 * To set focus in the field.
 * @params input
 * @params rowId
 */
export const handleSetFocus = (input, rowId, inputRefs) => {
	const nextRef = inputRefs[input] ? inputRefs[input][rowId] : null
	// console.log(nextRef, rowId, '::::focus<>input::', input)
	if (nextRef) {
		if (input === 'fec_entrega' && nextRef.current) {
			nextRef.current.setFocus()
		} else if (input === 'addToCart') {
			nextRef.current.focus()
		} else if (nextRef.current && nextRef.current.element) {
			nextRef.current.element.focus()
		} else {
			nextRef.current.focus()
		}
	}
}

/**
 * callback used when table is render to set
 * focus in the first editable field
 * @params firstRefs
 */

export const handleIsRendeTableFinish = (firstRefs) => {
	if (firstRefs && firstRefs.current) {
		if (firstRefs.current.element) {
			firstRefs.current.element.focus()
		} else {
			firstRefs.current.focus()
		}
	}
}

export const isInputEnabled = (input, rowId, inputRefs) => {
	const nextRef = inputRefs[input] ? inputRefs[input][rowId] : null
	if (nextRef) {
		return nextRef.current.props ? !nextRef.current.props.disabled : null
	}
	return null
}

/**
 * To validate if the field values changed
 * @params id row
 * @params fieldId
 * @params value
 * @params idSearch
 * @params rows
 */
export const isValueChange = (
	rowId,
	fieldId,
	value,
	idSearch = 'niprod',
	rows
) => {
	if (value) {
		const searchParams = {}
		searchParams[idSearch] = rowId
		const updateIndex = _.findIndex(rows, searchParams)
		if (updateIndex < 0) {
			return false
		}

		const updateValue =
			!_.isNull(rows[updateIndex][fieldId]) ||
			!_.isUndefined(rows[updateIndex][fieldId])
				? rows[updateIndex][fieldId]
				: null

		if (updateValue === null) {
			return true
		} else if (String(updateValue) === String(value)) {
			return false
		} else {
			return true
		}
	}

	return false
}
