import {
	getEgrStockSuccess,
	pickeoEgrSearchBarcodeError,
	pickeoEgrSearchBarcodeSuccess
} from 'actions'
import {
	getEgrStock,
	pickeoEgrSearchBarcode,
	pickeoEgrStockConfirm
} from 'api/PickeoEgrStock'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import _ from 'underscore'
import {
	GET_EGR_STOCK,
	PICKEO_EGR_SEARCH_BARCODE,
	PICKEO_EGR_STOCK_CONFIRM
} from '../constants/ActionsTypes'

function* getEgrStockRequest({ payload }) {
	try {
		const stock = yield call(getEgrStock, payload)
		yield put(getEgrStockSuccess(stock))
	} catch (error) {}
}

function* pickeoEgrSearchBarcodeRequest({ payload }) {
	try {
		const stock = yield call(pickeoEgrSearchBarcode, payload)
		yield put(pickeoEgrSearchBarcodeSuccess(stock))
	} catch (error) {
		if (error.response) {
			const { data } = error.response
			if (_.has(data, 'Resultado')) {
				const { Resultado } = data
				const [message, typeError] = !Resultado.Resultado
					? [Resultado.Mens_error, Resultado.Tipo_error]
					: ['', '']

				yield put(
					pickeoEgrSearchBarcodeError({
						message,
						typeError,
						requireFields: {
							ind_lote: data.ind_lote,
							ind_vto: data.ind_vto,
							ind_nserie: data.ind_nserie
						},
						dataFields: {
							lote: data.lote,
							fec_vto: data.fec_vto,
							nserie: data.nserie,
							nitem: data.nitem
						}
					})
				)
			}
		}
	}
}

function* pickeoEgrStockConfirmRequest({ payload }) {
	const { idOperacion, callBackReturn } = payload
	try {
		const items = yield call(pickeoEgrStockConfirm, { idOperacion })
		callBackReturn()
		yield put(pickeoEgrSearchBarcodeSuccess(items))
	} catch (error) {}
}

export function* getEgrStockSaga() {
	yield takeEvery(GET_EGR_STOCK, getEgrStockRequest)
}

export function* pickeoEgrSearchBarcodeSaga() {
	yield takeEvery(PICKEO_EGR_SEARCH_BARCODE, pickeoEgrSearchBarcodeRequest)
}

export function* pickeoEgrStockConfirmSaga() {
	yield takeEvery(PICKEO_EGR_STOCK_CONFIRM, pickeoEgrStockConfirmRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getEgrStockSaga),
		fork(pickeoEgrSearchBarcodeSaga),
		fork(pickeoEgrStockConfirmSaga)
	])
}
