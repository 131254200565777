import CommonTable from 'components/common/commonTable'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getDefaultColumns } from 'lib/FormUtils'
import React, { Component, Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'

interface IPendingVouchersProps extends WithTranslation {
	authUser: any
	fields?: Array<IFieldStructure>
	t?: any
	generatedStatus: any
}

class AutoGenerateTable extends Component<IPendingVouchersProps> {
	onChangeTable = (type: string, pagination: any) => {
		return true
		// this.props.getVoucherAccounting({ ComprobAvencer, OpcionMuestra, idOperacion, page_number: pagination.page, page_size: pagination.sizePerPage });
	}

	render() {
		const { authUser, fields, t, generatedStatus } = this.props

		const paginationOptions = generatedStatus
			? {
					page: generatedStatus.page_number,
					sizePerPage: generatedStatus.page_size,
					totalSize: generatedStatus.total_count
			  }
			: {}

		const propsTable = {
			remote: false,
			columns: !_.isEmpty(fields)
				? getDefaultColumns(fields, { authUser })
				: [],
			data: generatedStatus ? generatedStatus.items : [],
			rowClasses: 'theme.tableRow',
			headerClasses: 'theme.tableHeader',
			paginationOptions,
			handleChangeTable: this.onChangeTable
		}

		return (
			<Fragment>
				<Col className="p-2 font-weight-bold">{t('auto_generate.errors')}</Col>
				<div
					className="dropdown-divider col-12"
					style={{ backgroundColor: '#2e00ff', padding: '1px' }}
				/>
				{!_.isEmpty(fields) && <CommonTable {...propsTable} />}
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth, autoGenerate }: any) => {
	const { generatedStatus } = autoGenerate
	const { authUser } = auth

	return { authUser, generatedStatus }
}

const connectForm = connect(mapStateToProps, {})(AutoGenerateTable)

export default withTranslation()(connectForm)
