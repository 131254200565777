import { ICrudGenericFields } from 'models/CrudInterface'

export const productBonifFields: Array<ICrudGenericFields> = [
	{
		id: 'cod_bonif',
		type: 'combo',
		labelWidth: 'col-sm-4',
		width: 'col-sm-5',
		height: 'col-sm-12',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'bonifications',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'bonpre_d1',
		type: 'text',
		labelWidth: 'col-sm-6',
		width: 'col-sm-6',
		height: 'col-sm-6',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: 'bonpre_vig',
			values: [
				{
					idValue: 0,
					disabled: true
				}
			]
		}
	},
	{
		id: 'bonpre_d2',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-5',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: 'bonpre_vig',
			values: [
				{
					idValue: 0,
					disabled: true
				}
			]
		}
	},
	{
		id: 'bonpre_vig',
		type: 'check',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-5',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'bonif_c1',
		type: 'text',
		labelWidth: 'col-sm-8',
		width: 'col-sm-4',
		height: 'col-sm-4',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'bonif_c2',
		type: 'text',
		labelWidth: 'col-sm-4',
		width: 'col-sm-4',
		height: 'col-sm-4',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'bonif_d1',
		type: 'text',
		labelWidth: 'col-sm-6',
		width: 'col-sm-6',
		height: 'col-sm-6',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'bonif_d2',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-5',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'bonif_act',
		type: 'check',
		labelWidth: 'col-sm-2',
		width: 'col-sm-3',
		height: 'col-sm-12',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'lim_inf',
		type: 'text',
		labelWidth: 'col-sm-2',
		width: 'col-sm-3',
		height: 'col-sm-12',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'porc',
		type: 'text',
		labelWidth: 'col-sm-2',
		width: 'col-sm-3',
		height: 'col-sm-12',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
