import GlobalContainer from 'components/layout/globalContainer'
import VoucherClientForm from 'components/voucher/voucherClientForm'
import { P_SELCLI } from 'constants/ConfigProcessNames'
import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getVoucherType } from '../../actions'
import withMenu from '../../components/common/withMenu'

class Voucher extends PureComponent {
	constructor(props) {
		super(props)
		this.formRef = React.createRef()
	}

	callBackButton = () => {
		if (this.formRef.current) {
			this.formRef.current.handleSubmit()
		}
	}

	render() {
		return (
			<Row>
				<GlobalContainer
					codeProcess={P_SELCLI}
					callBackButton={this.callBackButton}
					breadCrumbButtonType={'primary'}
					nextPage
				>
					<VoucherClientForm current={P_SELCLI} formRef={this.formRef} />
				</GlobalContainer>
			</Row>
		)
	}
}

const mapStateToProps = ({ vouchertype }) => {
	const { voucherType } = vouchertype
	return { voucherType }
}

export default connect(mapStateToProps, { getVoucherType })(
	withTranslation()(withRouter(withMenu(Voucher)))
)
