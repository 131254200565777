import Axios from 'axios'
import {
	ICrudGetCustomerObservation,
	ICrudUpdateCustomerObservation
} from 'models/CrudCustomerInterface'

const urlBase = '/abm_vta_clientes_obs'

export const crudGetCustomerObservation = async (
	params: ICrudGetCustomerObservation
) => {
	const response = await Axios.get(`${urlBase}/consulta`, { params })
	return response.data
}

export const crudUpdateCustomerObservation = async (
	params: ICrudUpdateCustomerObservation
) => {
	const response = await Axios.post(`${urlBase}/confirmar`, params)
	return response.data
}
