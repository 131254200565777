import { ICrudGenericFields } from 'models/CrudInterface'

export const principalFields: Array<ICrudGenericFields> = [
	{
		id: 'cod_preclis',
		type: 'text',
		width: 'col-sm-4',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'descrip',
		type: 'text',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'forma_calc',
		type: 'combo',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		comboData: 'estimationType',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'solo_prodlis',
		type: 'check',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'act_autom',
		type: 'check',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: 'solo_prodlis',
			values: [
				{
					idValue: 1,
					disabled: true
				}
			]
		}
	},
	{
		id: 'porc_dto',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		disabled: true,
		dependsOn: {
			id: 'act_autom',
			values: [
				{
					idValue: 1,
					disabled: false
				}
			]
		}
	}
]
