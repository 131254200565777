import {
	salesAffectedImport,
	salesAffectedImportConfirm,
	salesAffectedImportValidate
} from 'actions'
import FiltersTable from 'components/common/filtersTable'
import { P_AFEC_IMPO_VTA } from 'constants/ConfigProcessNames'
import { getFiltersTable } from 'lib/Utils'
import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import VoucherAffectingTotal from './VoucherAffectingTotal'
import VoucherImportTable from './voucherImportTable'
import styles from './voucherImportTable.module.scss'

class VoucherAffectingTable extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			total_item: 0,
			total_cant: 0,
			total_importe: 0,
			ComprobAvencer: 0,
			OpcionMuestra: 0
		}

		this.filtersKey = {
			cant_saldo: 'filtro_cant_pend',
			Cod_prod: 'filtro_cod_prod',
			Cod_unid: 'filtro_cod_unid',
			Cod_barra: 'filtro_codbarra',
			comprob_desc: 'filtro_comprob_desc',
			comprob_nro: 'filtro_comprob_nro',
			Desc_prod: 'filtro_desc_prod',
			fec_emis: 'filtro_fec_emis',
			fec_vto: 'filtro_fec_vto',
			nimovpro: 'filtro_niprod',
			pcio_unit: 'filtro_precio_unit',
			Presentacion: 'filtro_presentacion',
			sucursal: 'filtro_sucursal',
			desc_cond_cpa: 'filtro_desc_cond_cpa',
			desc_cond_vta: 'filtro_desc_cond_vta',
			desc_item: 'filtro_desc_item',
			imp_pend: 'filtro_imp_pend',
			cotiz: 'filtro_cotiz'
		}
	}

	componentDidMount = () => {
		const { ComprobAvencer, OpcionMuestra } = this.state
		const { idOperacion } = this.props
		if (idOperacion) {
			this.props.salesAffectedImport({
				ComprobAvencer,
				OpcionMuestra,
				idOperacion,
				page_size: 10,
				page_number: 1
			})
		}
	}

	componentDidUpdate = (prevProps) => {
		const { subCalculations, cantValidate, salesconfirm, productsImport } =
			this.props

		if (prevProps.productsImport !== productsImport && productsImport) {
			this.setState({
				total_item: productsImport.total_item,
				total_cant: productsImport.total_afec,
				total_importe: productsImport.total_importe
			})
		}

		if (
			prevProps.subCalculations !== subCalculations &&
			!prevProps.subCalculations
		) {
			this.setState({
				total_item: subCalculations.total_item,
				total_cant: subCalculations.total_afec,
				total_importe: subCalculations.total_importe
			})
		}

		if (prevProps.cantValidate !== cantValidate && !prevProps.cantValidate) {
			this.setState({
				total_item: cantValidate.total_item,
				total_cant: cantValidate.total_afec,
				total_importe: cantValidate.total_importe
			})
		}

		if (
			prevProps.salesconfirm !== salesconfirm &&
			!prevProps.salesconfirm &&
			salesconfirm
		) {
			this.setState({
				total_item: salesconfirm.total_item,
				total_cant: salesconfirm.total_afec,
				total_importe: salesconfirm.total_importe
			})
		}
	}

	onChangeTable = (type, pagination) => {
		const filters = type === 'filter' ? getFiltersTable(pagination.filters) : {}
		const { ComprobAvencer, OpcionMuestra } = this.state
		const { idOperacion } = this.props
		this.props.salesAffectedImport({
			ComprobAvencer,
			OpcionMuestra,
			idOperacion,
			page_number: pagination.page,
			page_size: pagination.sizePerPage,
			...filters
		})
	}

	handleGetFilters = (filters) => {
		const result = {}
		Object.keys(filters).forEach((filter) => {
			result[`filtro_${filter}`] = filters[filter].filterVal
		})

		return result
	}

	handleGetCant = (e) => {
		const { idOperacion } = this.props
		const comprobante = e.target.checked ? 1 : 0
		const { OpcionMuestra } = this.state
		this.setState({ ComprobAvencer: comprobante })
		this.props.salesAffectedImport({
			ComprobAvencer: comprobante,
			OpcionMuestra,
			idOperacion,
			page_size: 10,
			page_number: 1
		})
	}

	handleChangeSelect = (value) => {
		const { ComprobAvencer } = this.state
		const { idOperacion } = this.props
		this.setState({ OpcionMuestra: value })
		this.props.salesAffectedImport({
			ComprobAvencer,
			OpcionMuestra: value,
			idOperacion,
			page_size: 10,
			page_number: 1
		})
	}

	render() {
		const {
			t,
			productsImport,
			readOnly,
			theme,
			config,
			productsUpdate,
			salesAffectedImportValidate,
			callBackReturn
		} = this.props
		const inputConfig = [
			{
				idCampo: 'checkComprobAvencer',
				label: t('voucherInvolvement.form.sample'),
				visible: 1,
				requerido: 0,
				editable: 1
			}
		]
		const customCol = readOnly ? 4 : { span: 5, offset: 7 }
		return (
			<Row className={theme.containerTable}>
				{!readOnly && (
					<>
						<FiltersTable
							handleGetCant={this.handleGetCant}
							t={t}
							inputConfig={inputConfig}
							handleChangeSelect={(value) => this.handleChangeSelect(value)}
						/>
						<Col xs={12} sm={12} md={6} lg={3}>
							{productsImport && (
								<VoucherAffectingTotal
									classDiv={'pl-5'}
									formatCol={{ span: 12 }}
									data={this.state}
								/>
							)}
						</Col>
					</>
				)}
				{readOnly && (
					<Col sm={12}>
						<VoucherAffectingTotal formatCol={{ span: 4 }} data={this.state} />
					</Col>
				)}
				<Col sm={12} className={`pb-2 pl-0 ${theme.scrollContainer}`}>
					<VoucherImportTable
						config={config}
						keysValidate={{ field: 'nimovcli', label: 'Nimovcli' }}
						filtersKey={this.filtersKey}
						products={productsImport}
						productsUpdate={productsUpdate}
						readOnly={readOnly}
						idOperacion={this.props.idOperacion}
						handleChangeTable={this.onChangeTable}
						formConfirmation={this.props.formConfirmation}
						handleValidateImp={salesAffectedImportValidate}
						handleConfirmImp={this.props.salesAffectedImportConfirm}
						handleMoveArrow={this.props.handleMoveArrow}
						callBackReturn={callBackReturn}
					/>
					{productsImport && (
						<VoucherAffectingTotal
							classDiv={'pl-0'}
							formatCol={customCol}
							data={this.state}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = ({ vouchertype, salesAffected, voucher }) => {
	const { voucherType } = vouchertype
	const config = voucher.config ? voucher.config[P_AFEC_IMPO_VTA] : null

	const {
		productsImport,
		cantValidate,
		subCalculations,
		salesconfirm,
		productsUpdate
	} = salesAffected
	return {
		voucherType,
		productsImport,
		cantValidate,
		subCalculations,
		salesconfirm,
		config,
		productsUpdate
	}
}

const connectForm = connect(mapStateToProps, {
	salesAffectedImport,
	salesAffectedImportValidate,
	salesAffectedImportConfirm
})(VoucherAffectingTable)

export default themr(
	'AffectingTableStyles',
	styles
)(withTranslation()(connectForm))
