import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons'
import {
	crudClearValuesStore,
	crudCreateValue,
	crudDeleteValue,
	crudGetValue,
	crudGetValuesList
} from 'actions'
import ComponentUpdateSegment from 'components/abmUtils/ComponentUpdateSegment'
import CrudTableAddButton from 'components/abmUtils/CrudTableAddButton'
import CrudTableButtons from 'components/abmUtils/CrudTableButtons'
import CommonModal from 'components/common/commonModal'
import CommonTable from 'components/common/commonTable'
import NotificationMessage from 'components/common/notificationMessage'
import { ICrudPageOptions, ICrudsApi } from 'models/CrudInterface'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const excludeFields = ['modif', 'baja']

interface BranchesOfficesTableProps extends WithTranslation {
	catributo: string | number
	niclieRef: number
	cod_val: string
	urlApi: string
	crudId: string
	fieldsAlta: any
	fields: any
	tabId: string
	valueId: string
	Component: any
	ReferComponent: any
	valueRemoved: any
	valueUpdated: any
	valuesList: any
	valueSelected: any
	crudGetValuesList: (params: ICrudsApi) => void
	crudCreateValue: (params: ICrudsApi) => void
	crudDeleteValue: (params: ICrudsApi) => void
	crudGetValue: (params: ICrudsApi) => void
	crudClearValuesStore: () => void
	disableForm: boolean
	t: any
}

interface BranchesOfficesTableState {
	showEditPopup: boolean
	showReferPopup: boolean
	disablePopup: boolean
	alta: number
	errorTitle: string
	errorMessage: string
	typeNotification: string
	showError: boolean
	selectRow?: any
	titleModal: string
}

class BranchesOfficesTable extends Component<
	BranchesOfficesTableProps,
	BranchesOfficesTableState
> {
	constructor(props: BranchesOfficesTableProps) {
		super(props)
		this.state = {
			showEditPopup: false,
			showReferPopup: false,
			disablePopup: true,
			alta: 0,
			errorTitle: '',
			errorMessage: '',
			typeNotification: 'success',
			titleModal: '',
			showError: false
		}
	}

	componentDidMount = () => {
		const { catributo, crudGetValuesList, urlApi, crudId } = this.props
		crudGetValuesList({
			crudName: crudId,
			urlApi: urlApi,
			action: 'buscar',
			params: {
				page_number: 1,
				page_size: 10,
				busqueda: catributo
			}
		})
	}

	componentDidUpdate = (prevProps: BranchesOfficesTableProps) => {
		const {
			valueUpdated,
			catributo,
			valueRemoved,
			urlApi,
			crudGetValuesList,
			crudId,
			t
		} = this.props
		const { alta } = this.state

		if (prevProps.valueUpdated !== valueUpdated && valueUpdated) {
			crudGetValuesList({
				crudName: crudId,
				urlApi: urlApi,
				action: 'buscar',
				params: {
					page_number: 1,
					page_size: 10,
					busqueda: catributo
				}
			})
			this.handleCloseEditPopup()
			this.setState({
				errorMessage: alta
					? t('values.success_create')
					: t('values.success_update'),
				showError: true
			})
		}

		if (prevProps.valueRemoved !== valueRemoved && valueRemoved) {
			this.setState({
				errorMessage: t('values.success_remove'),
				showError: true
			})
			crudGetValuesList({
				crudName: crudId,
				urlApi: urlApi,
				action: 'buscar',
				params: {
					page_number: 1,
					page_size: 10,
					busqueda: catributo
				}
			})
		}
	}

	/**
	 * to get columns table.
	 * @returns object
	 */
	getColumns = () => {
		const { fields, t, disableForm } = this.props
		const rows = fields.map((field: any) => {
			const fieldId = field.idCampo.trim()
			return {
				dataField: fieldId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '10%' },
				hidden: !field.visible || excludeFields.includes(fieldId)
			}
		})

		rows.push({
			dataField: 'actions',
			text: '',
			align: 'center',
			headerAlign: 'center',
			headerStyle: { width: '5%' },
			hidden: false,
			formatter: (value: any, row: any) => {
				let Component
				if (row?.niclie_entr !== 0) {
					Component = <></>
				} else {
					Component = (
						<CrudTableButtons
							view={this.handleViewValue}
							edit={this.handleEditValue}
							delete={this.handleRemoveValue}
							row={row}
							deleteMessage={t('CRUDS.generic.delete.body')}
							deleteAction={this.handleRemoveValue}
							deleteTitle={
								t('CRUDS.generic.delete.title') || t('global.delete')
							}
							disableForm={disableForm}
						/>
					)
				}
				return Component
			}
		})

		return rows
	}

	/**
	 * to close popup
	 */
	handleCloseEditPopup = () => {
		this.setState({ showEditPopup: false })
	}

	handleCloseReferPopup = () => {
		const { catributo, urlApi, crudGetValuesList, crudId } = this.props
		this.setState({ showReferPopup: false })
		crudGetValuesList({
			crudName: crudId,
			urlApi: urlApi,
			action: 'buscar',
			params: {
				page_number: 1,
				page_size: 10,
				busqueda: catributo
			}
		})
	}

	/**
	 * to save or update data modal
	 */
	handleSubmitPopup = (values: any) => {
		const { alta } = this.state
		const { crudCreateValue, urlApi, tabId, catributo, crudId } = this.props

		crudCreateValue({
			crudName: crudId,
			urlApi: urlApi,
			action: 'confirmar',
			params: {
				...values,
				[tabId]: catributo,
				alta: alta
			}
		})
	}

	handleRemoveValue = (selectedValue: any) => {
		const { suc_clie: cod_valor } = selectedValue
		const { catributo, crudDeleteValue, urlApi, tabId, valueId, crudId } =
			this.props
		crudDeleteValue({
			crudName: crudId,
			urlApi: urlApi,
			action: 'eliminar',
			params: {
				id: tabId,
				param: catributo,
				value: valueId,
				valueParam: cod_valor
			}
		})
	}

	/**
	 * to show value data info.
	 * @param {*} row
	 */
	handleViewValue = (row: any) => {
		const { urlApi, crudGetValue, tabId, valueId, crudId } = this.props
		crudGetValue({
			crudName: crudId,
			urlApi: urlApi,
			action: 'consulta',
			params: {
				[tabId]: row[tabId],
				[valueId]: row[valueId]
			}
		})

		this.setState({
			showEditPopup: true,
			disablePopup: true,
			selectRow: row,
			titleModal: 'Consultando'
		})
	}

	/**
	 * to update value
	 * @param {object} row
	 */
	handleEditValue = (row: any) => {
		const { urlApi, crudGetValue, tabId, valueId, crudId } = this.props
		crudGetValue({
			crudName: crudId,
			urlApi: urlApi,
			action: 'consulta',
			params: {
				[tabId]: row[tabId],
				[valueId]: row[valueId]
			}
		})
		this.setState({
			showEditPopup: true,
			disablePopup: false,
			selectRow: row,
			titleModal: 'Editando',
			alta: 0
		})
	}

	handleAddValue = () => {
		this.props.crudClearValuesStore()
		this.setState({
			showEditPopup: true,
			disablePopup: false,
			selectRow: null,
			titleModal: 'Creando',
			alta: 1
		})
	}

	handleRefer = () => {
		this.setState({
			showReferPopup: true,
			disablePopup: false,
			selectRow: null,
			titleModal: 'Modificando Referidos',
			alta: 1
		})
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	/**
	 * to handle change table
	 */
	handleChangeTable = (type: string, pagination: ICrudPageOptions) => {
		const { catributo, crudGetValuesList, urlApi, crudId } = this.props
		if (type === 'pagination') {
			crudGetValuesList({
				crudName: crudId,
				urlApi: urlApi,
				action: 'buscar',
				params: {
					page_number: pagination.page,
					page_size: pagination.sizePerPage,
					busqueda: catributo
				}
			})
		}
	}

	render() {
		const {
			fields,
			fieldsAlta,
			valuesList,
			valueSelected,
			disableForm,
			Component,
			ReferComponent,
			catributo,
			tabId,
			t,
			niclieRef,
			cod_val
		} = this.props
		const {
			showEditPopup,
			showReferPopup,
			disablePopup,
			selectRow,
			titleModal,
			typeNotification,
			errorTitle,
			errorMessage,
			showError,
			alta
		} = this.state

		const tableColumns = fields ? this.getColumns() : []

		const referredBranchesFields = fieldsAlta
			? fieldsAlta.filter((field: any) => {
					return field.agrupador.includes('grupo.suc.ref')
			  })
			: []
		const searchBranchesFields = fieldsAlta
			? fieldsAlta.filter((field: any) => {
					return field.agrupador.includes('grupo.suc.lk')
			  })
			: []
		const branchesFields = fieldsAlta
			? fieldsAlta.filter((field: any) => {
					return (
						field.agrupador.includes('grupo.suc.com') ||
						field.agrupador.includes('grupo.suc.prin')
					)
			  })
			: []

		const propsTable = {
			remote: true,
			columns: tableColumns,
			keyField: 'nro',
			data: valuesList ? valuesList.lista : [],
			rowStyle: { height: '70px' },
			paginationOptions: valuesList
				? {
						pageStartIndex: 1,
						sizePerPage: valuesList.page_size,
						page: valuesList.page_number,
						totalSize: valuesList.total_count
				  }
				: {},
			onTableChange: this.handleChangeTable
		}

		const propsEdit = {
			[tabId]: catributo,
			alta: alta,
			fields: branchesFields,
			itemInfo: valueSelected || selectRow,
			disableForm: disablePopup,
			handleSubmit: this.handleSubmitPopup,
			handleCloseModal: this.handleCloseEditPopup
		}

		const propsRefer = {
			alta: alta,
			niclieRef: niclieRef,
			niclie: catributo,
			fields: referredBranchesFields,
			searchFields: searchBranchesFields,
			disableForm: disablePopup,
			handleCloseModal: this.handleCloseReferPopup
		}

		const propsEditModal = {
			showModal: showEditPopup,
			handleCloseModal: this.handleCloseEditPopup,
			modalTitle: titleModal,
			modalBody: Component ? (
				<Component {...propsEdit} />
			) : (
				<ComponentUpdateSegment {...propsEdit} />
			),
			buttons: false,
			handleSubmit: this.handleSubmitPopup,
			sizeModal: 'xl'
		}

		const propsReferModal = {
			showModal: showReferPopup,
			handleCloseModal: this.handleCloseReferPopup,
			modalTitle: titleModal,
			modalBody: ReferComponent ? <ReferComponent {...propsRefer} /> : <div />,
			buttons: false,
			handleSubmit: this.handleSubmitPopup,
			sizeModal: 'xl'
		}

		return (
			<Fragment>
				<Col sm={12} className={'mb-1'}>
					<NotificationMessage
						errorTitle={errorTitle}
						errorMessage={errorMessage}
						showError={showError}
						handleCloseError={this.handleCloseError}
						type={typeNotification}
					/>
				</Col>
				{!disableForm && (
					<Row>
						<Col className="col-11" />
						{cod_val === 'REFER' && (
							<Col>
								<CrudTableAddButton
									customIcon={faArrowsAltH}
									clickAction={this.handleRefer}
									title={t('CRUDS.general_use.referred_tab')}
								/>
							</Col>
						)}
						<Col>
							<CrudTableAddButton
								clickAction={this.handleAddValue}
								title={t('CRUDS.general_use.values_tab')}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<CommonTable {...propsTable} />
					<CommonModal {...propsEditModal} />
					<CommonModal {...propsReferModal} />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ crudValues }: any) => {
	const { valuesList, valueSelected, valueUpdated, valueRemoved } = crudValues
	return { valuesList, valueSelected, valueUpdated, valueRemoved }
}

const connectForm = connect(mapStateToProps, {
	crudGetValuesList,
	crudGetValue,
	crudDeleteValue,
	crudCreateValue,
	crudClearValuesStore
})(BranchesOfficesTable)

export default withTranslation()(withRouter(connectForm))
