import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import styles from './inputDropdown.module.css'

class InputDropdown extends PureComponent {
	componentDidMount = () => {
		const { options, onChange, noInitialExecute, value } = this.props
		if (value && !noInitialExecute) {
			onChange({ target: { value } }) // Se hace el llamado al cargar las opciones desde el inicio
		} else if (options[0] && onChange && !noInitialExecute) {
			onChange({ target: { value: options[0].id } }) // Se hace el llamado al cargar las opciones desde el inicio
		}
	}

	componentDidUpdate = (prevProps) => {
		const { options, onChange, noInitialExecute } = this.props

		if (
			prevProps.options !== options &&
			prevProps.options.length !== options.length &&
			options.length > 0
		) {
			if (options[0] && onChange && !noInitialExecute) {
				onChange({ target: { value: options[0].id } })
			}
		}
	}

	renderOptions = () => {
		const { options, tooltip } = this.props
		const result = []
		options.forEach((option) => {
			const titleOption = tooltip ? option.label : null
			result.push(
				<option
					data-toggle="tooltip"
					title={titleOption}
					value={option.id}
					key={option.id}
				>
					{option.label}
				</option>
			)
		})

		return result
	}

	getConfigField = (id) => {
		const { fields } = this.props
		let result = {}
		fields.forEach((field) => {
			if (field.idCampo.trim() === id) {
				result = field
			}
		})

		return result
	}

	getCustomValue = (options) => {
		let result = ''
		options.forEach((opt) => {
			result = opt.label
		})
		return result
	}

	renderField = () => {
		const {
			label,
			placeholder,
			name,
			styles,
			inputId,
			id,
			colInput,
			colLabel,
			styleLabel,
			divStyle,
			options,
			disable,
			theme,
			inputFormCol,
			rowStyle,
			value,
			fwRef,
			disabledInput,
			onBlur
		} = this.props

		const classInput = label ? colInput : 'col-sm-12'
		const classLabel = label ? colLabel : ''
		const config = this.getConfigField(inputId)
		const customStyleLabel = config.requerido
			? { ...styleLabel, color: 'red' }
			: styleLabel
		const classText = disable ? theme.inputDisabled : ''
		const customValue = value || this.getCustomValue(options)

		if (config.visible) {
			return (
				<Col {...inputFormCol}>
					<Row className={'form-group'} style={rowStyle}>
						<label
							className={`${classLabel} ${theme.inputLabel}`}
							style={customStyleLabel}
						>
							{config.label ? config.label : label}
						</label>
						{!disable && (
							<Col className={classInput} style={{ ...divStyle }}>
								<select
									id={id || inputId}
									ref={config.fwRef ? config.fwRef : fwRef}
									name={name}
									style={{ styles }}
									placeholder={placeholder}
									disabled={!config.editable || disabledInput}
									className={`${theme.inputDropdown} ${classText} custom-select`}
									onChange={(value) => this.props.onChange(value)}
									onBlur={(value) => {
										if (onBlur) {
											onBlur(value)
										}
									}}
									value={value}
								>
									{options && this.renderOptions()}
								</select>
							</Col>
						)}

						{disable && (
							<Col className={theme.valueReadOnly}>{customValue}</Col>
						)}
					</Row>
				</Col>
			)
		} else {
			return null
		}
	}

	render() {
		const { fields } = this.props
		if (fields) {
			return this.renderField()
		} else {
			return null
		}
	}
}

export default themr('InputDropdownStyle', styles)(InputDropdown)
