import { getDetailVoucherSeat } from 'actions'
import CommonTable from 'components/common/commonTable'
import { getDefaultColumns } from 'lib/FormUtils'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
class SearchVoucherSeatTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showDetailModal: true
		}
		this.primaryKey = 'nro'
	}

	componentDidMount = () => {
		const { id, authUser } = this.props
		if (id) {
			this.props.getDetailVoucherSeat({
				id,
				page_size: 10,
				page_number: 1,
				idEmpresa: authUser.userCompany
			})
		}
	}

	handleChangeTable = () => {}

	render() {
		const { seatData, fieldsTable, authUser } = this.props
		const propsTable = {
			remote: true,
			columns: _.isEmpty(fieldsTable)
				? []
				: getDefaultColumns(fieldsTable, { authUser }),
			keyField: this.primaryKey,
			data: _.isEmpty(seatData) ? [] : seatData.items,
			rowClasses: 'theme.tableRow',
			headerClasses: 'theme.tableHeader',
			paginationOptions: {},
			onTableChange: this.handleChangeTable
		}

		return (
			<Row>
				<Col className={'col-12'}>
					{!_.isEmpty(fieldsTable) && <CommonTable {...propsTable} />}{' '}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = ({ configCrud, searchVoucher, auth }) => {
	const { fieldsConfig } = configCrud
	const { seatData } = searchVoucher
	const { authUser } = auth
	return { fieldsConfig, seatData, authUser }
}

const connectForm = connect(mapStateToProps, { getDetailVoucherSeat })(
	SearchVoucherSeatTable
)

export default withTranslation()(connectForm)
