import {
	deleteEquivalentProductGroupSuccess,
	getEquivalentProductGroupSuccess,
	getEquivalentProductsSuccess,
	setEquivalentProductGroupSuccess,
	setEquivalentProductSelectionSuccess
} from 'actions'
import {
	deleteEquivalentProductGroup,
	getEquivalentProductGroup,
	getEquivalentProducts,
	setEquivalentProductGroup,
	setEquivalentProductSelection
} from 'api/EquivalentProductsApi'
import {
	DELETE_EQUIVALENT_PRODUCT_GROUP,
	GET_EQUIVALENT_PRODUCTS,
	GET_EQUIVALENT_PRODUCT_GROUP,
	SET_EQUIVALENT_PRODUCT_GROUP,
	SET_EQUIVALENT_PRODUCT_SELECTION
} from 'constants/ActionsTypes'
import {
	IDeleteEquivalentProductGroupSaga,
	IGetEquivalentProductGroupSaga,
	IGetEquivalentProductsSaga,
	ISetEquivalentProductGroupSaga,
	ISetEquivalentProductSelectionSaga
} from 'models/EquivalentProducts'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

function* getEquivalentProductsRequest({
	payload
}: IGetEquivalentProductsSaga): any {
	try {
		const values: any = yield call(getEquivalentProducts, payload)
		yield put(getEquivalentProductsSuccess(values))
	} catch (error) {}
}

function* setEquivalentProductSelectionRequest({
	payload
}: ISetEquivalentProductSelectionSaga): any {
	try {
		const values: any = yield call(setEquivalentProductSelection, payload)
		yield put(setEquivalentProductSelectionSuccess(values))
	} catch (error) {}
}

function* setEquivalentProductGroupRequest({
	payload
}: ISetEquivalentProductGroupSaga): any {
	try {
		const values: any = yield call(setEquivalentProductGroup, payload)
		yield put(setEquivalentProductGroupSuccess(values))
	} catch (error) {}
}

function* deleteEquivalentProductGroupRequest({
	payload
}: IDeleteEquivalentProductGroupSaga): any {
	try {
		const values: any = yield call(deleteEquivalentProductGroup, payload)
		yield put(deleteEquivalentProductGroupSuccess(values))
	} catch (error) {}
}

function* getEquivalentProductGroupRequest({
	payload
}: IGetEquivalentProductGroupSaga): any {
	try {
		const values: any = yield call(getEquivalentProductGroup, payload)
		yield put(getEquivalentProductGroupSuccess(values))
	} catch (error) {}
}

export function* getEquivalentProductsSaga() {
	yield takeLatest(GET_EQUIVALENT_PRODUCTS, getEquivalentProductsRequest)
}

export function* setEquivalentProductSelectionSaga() {
	yield takeLatest(
		SET_EQUIVALENT_PRODUCT_SELECTION,
		setEquivalentProductSelectionRequest
	)
}

export function* setEquivalentProductGroupSaga() {
	yield takeLatest(
		SET_EQUIVALENT_PRODUCT_GROUP,
		setEquivalentProductGroupRequest
	)
}

export function* deleteEquivalentProductGroupSaga() {
	yield takeLatest(
		DELETE_EQUIVALENT_PRODUCT_GROUP,
		deleteEquivalentProductGroupRequest
	)
}

export function* getEquivalentProductGroupSaga() {
	yield takeLatest(
		GET_EQUIVALENT_PRODUCT_GROUP,
		getEquivalentProductGroupRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(getEquivalentProductsSaga),
		fork(setEquivalentProductSelectionSaga),
		fork(setEquivalentProductGroupSaga),
		fork(deleteEquivalentProductGroupSaga),
		fork(getEquivalentProductGroupSaga)
	])
}
