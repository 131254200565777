import Axios from 'axios'

const baseUrl = '/Stock/sel_partida/ingresos/'

export const getProductStockProvider = async (params) => {
	const response = await Axios.get(baseUrl, { params })
	return response.data
}

export const validateProductStockProvider = async (params) => {
	const response = await Axios.post(`${baseUrl}validar`, params)
	return response.data
}

export const confirmProductStockProvider = async (params) => {
	const response = await Axios.post(`${baseUrl}confirmar`, params)
	return response.data
}

export const cancelProductStockProvider = async (params) => {
	const response = await Axios.post(`${baseUrl}cancelar`, params)
	return response.data
}

export const searchBarcodeProvider = async (params) => {
	const response = await Axios.post(`${baseUrl}busq_nserie`, params)
	return response.data
}

export const applyProductStockProviderApi = async (params) => {
	const response = await Axios.post(`${baseUrl}aplicar`, params)
	return response.data
}

export const loadProductStockProvider = async (params) => {
	const response = await Axios.post(`${baseUrl}carga`, params)
	return response.data
}
