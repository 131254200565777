import {
	confirmExpensesPickSuccess,
	getCufsPickExpensesSuccess,
	getDepositsPickExpensesSuccess
} from 'actions/InventoryExpensesStock'
import {
	confirmExpensesPickApi,
	getCufsPickExpensesApi,
	getDepositsPickExpensesApi
} from 'api/InventoryExpensesStock'
import { InventoryExpensesStockActions } from 'constants/ActionsTypesTs'
import {
	IConfirmExpensesPickAction,
	IGetCufPickExpensesAction,
	IGetDepositsPickExpensesAction
} from 'models/InventoryExpensesStock'
import {
	IGetCufPickStockResponse,
	IGetDepositsPickStockResponse
} from 'models/InventoryPickStock'
import { IResponseResult } from 'models/ResultsInterface'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* getDepositsPickExpensesRequest({
	payload
}: IGetDepositsPickExpensesAction): any {
	try {
		const items: IGetDepositsPickStockResponse = yield call(
			getDepositsPickExpensesApi,
			payload
		)
		yield put(getDepositsPickExpensesSuccess(items))
	} catch (error: any) {}
}

function* getCufsPickExpensesRequest({
	payload
}: IGetCufPickExpensesAction): any {
	try {
		const items: IGetCufPickStockResponse = yield call(
			getCufsPickExpensesApi,
			payload
		)
		yield put(getCufsPickExpensesSuccess(items))
	} catch (error: any) {}
}

function* confirmExpensesPickRequest({
	payload
}: IConfirmExpensesPickAction): any {
	const { params, callBackReturn } = payload
	try {
		const items: IResponseResult = yield call(confirmExpensesPickApi, params)
		yield put(confirmExpensesPickSuccess(items))
		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error: any) {}
}

export function* getDepositsPickExpensesSaga() {
	yield takeEvery(
		InventoryExpensesStockActions.GET_DEPOSITS_PICK_EXPENSES,
		getDepositsPickExpensesRequest
	)
}

export function* getCufsPickExpensesSaga() {
	yield takeEvery(
		InventoryExpensesStockActions.GET_CUF_PICK_EXPENSES,
		getCufsPickExpensesRequest
	)
}

export function* confirmExpensesPickSaga() {
	yield takeEvery(
		InventoryExpensesStockActions.CONFIRM_INVENTORY_PICK_STOCK,
		confirmExpensesPickRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(getDepositsPickExpensesSaga),
		fork(getCufsPickExpensesSaga),
		fork(confirmExpensesPickSaga)
	])
}
