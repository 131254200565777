import Axios from 'axios'
import {
	IConfirmSalesHeadersValues,
	IGetSalesHeadersParams,
	IValidateBarCodeParams
} from 'models/Budget'
const urlBase = 'vta_cab_selcompr'

export const getSalesHeadersApi = async (params: IGetSalesHeadersParams) => {
	const response = await Axios.get(urlBase, { params })
	return response.data
}

export const confirmSalesHeadersApi = async (
	params: IConfirmSalesHeadersValues
) => {
	const response = await Axios.post(`${urlBase}/confirmar`, params)
	return response.data
}

export const validateBarCodeSalesApi = async (
	params: IValidateBarCodeParams
) => {
	const response = await Axios.post(`${urlBase}/codbar`, params)
	return response.data
}
