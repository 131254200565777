import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
	purchasesAffectedConfirmSuccess,
	purchasesAffectedImportConfirmSuccess,
	purchasesAffectedImportSuccess,
	purchasesAffectedImpValidateSuccess,
	purchasesAffectedStateConfirmSuccess,
	purchasesAffectedStateSuccess,
	purchasesAffectedStateValidateSuccess,
	purchasesAffectedSubCalculationSuccess,
	purchasesAffectedValidateSuccess,
	purchasesQuantityAffectedSuccess,
	purchasesValidateDateSuccess
} from '../actions'
import {
	purchasesAffectedConfirm,
	purchasesAffectedImport,
	purchasesAffectedImportConfirm,
	purchasesAffectedImpValidate,
	purchasesAffectedState,
	purchasesAffectedStateConfirm,
	purchasesAffectedStateValidate,
	purchasesAffectedSubCalculation,
	purchasesAffectedValidate,
	purchasesQuantityAffected,
	purchasesValidateDate
} from '../api/PurchasesAffected'
import {
	PURCHASES_AFFECTED_CONFIRM,
	PURCHASES_AFFECTED_IMPORT,
	PURCHASES_AFFECTED_IMPORT_CONFIRM,
	PURCHASES_AFFECTED_IMPORT_VALIDATE,
	PURCHASES_AFFECTED_QUANTITY,
	PURCHASES_AFFECTED_STATE,
	PURCHASES_AFFECTED_STATE_CONFIRM,
	PURCHASES_AFFECTED_STATE_VALIDATE,
	PURCHASES_AFFECTED_SUB_CALCULATION,
	PURCHASES_AFFECTED_VALIDATE,
	PURCHASES_VALIDATE_DATE
} from '../constants/ActionsTypes'

function* purchasesQuantityAffectedRequest({ payload }) {
	try {
		const products = yield call(purchasesQuantityAffected, payload)
		yield put(purchasesQuantityAffectedSuccess(products))
	} catch (error) {}
}

function* purchasesAffectedValidateRequest({ payload }) {
	try {
		const products = yield call(purchasesAffectedValidate, payload)
		yield put(purchasesAffectedValidateSuccess(products))
	} catch (error) {}
}

function* purchasesAffectedSubCalculationRequest({ payload }) {
	try {
		const subCals = yield call(purchasesAffectedSubCalculation, payload)
		yield put(purchasesAffectedSubCalculationSuccess(subCals))
	} catch (error) {}
}

function* purchasesAffectedConfirmRequest({ payload }) {
	const { items, callBackReturn } = payload
	try {
		const purchasesconfirm = yield call(purchasesAffectedConfirm, items)
		yield put(purchasesAffectedConfirmSuccess(purchasesconfirm))
		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error) {}
}

function* purchasesAffectedImportConfirmRequest({ payload }) {
	const { items, callBackReturn } = payload
	try {
		const purchasesconfirm = yield call(purchasesAffectedImportConfirm, items)
		yield put(purchasesAffectedImportConfirmSuccess(purchasesconfirm))
		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error) {}
}

function* purchasesAffectedImportRequest({ payload }) {
	try {
		const imports = yield call(purchasesAffectedImport, payload)
		yield put(purchasesAffectedImportSuccess(imports))
	} catch (error) {}
}

function* purchasesAffectedImpValidateRequest({ payload }) {
	try {
		const validateItems = yield call(purchasesAffectedImpValidate, payload)
		yield put(purchasesAffectedImpValidateSuccess(validateItems))
	} catch (error) {}
}

function* purchasesValidateDateRequest({ payload }) {
	try {
		const validateItems = yield call(purchasesValidateDate, payload)
		yield put(purchasesValidateDateSuccess(validateItems))
	} catch (error) {}
}

function* purchasesAffectedStateRequest({ payload }) {
	try {
		const products = yield call(purchasesAffectedState, payload)
		yield put(purchasesAffectedStateSuccess(products))
	} catch (error) {}
}

function* purchasesAffectedStateValidateRequest({ payload }) {
	try {
		const products = yield call(purchasesAffectedStateValidate, payload)
		yield put(purchasesAffectedStateValidateSuccess(products))
	} catch (error) {}
}

function* purchasesAffectedStateConfirmRequest({ payload }) {
	const { states, callBackReturn } = payload
	try {
		const purchasesconfirm = yield call(purchasesAffectedStateConfirm, states)
		yield put(purchasesAffectedStateConfirmSuccess(purchasesconfirm))
		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error) {}
}

export function* purchasesQuantityAffectedSaga() {
	yield takeLatest(
		PURCHASES_AFFECTED_QUANTITY,
		purchasesQuantityAffectedRequest
	)
}

export function* purchasesAffectedValidateSaga() {
	yield takeLatest(
		PURCHASES_AFFECTED_VALIDATE,
		purchasesAffectedValidateRequest
	)
}

export function* purchasesAffectedSubCalculationSaga() {
	yield takeLatest(
		PURCHASES_AFFECTED_SUB_CALCULATION,
		purchasesAffectedSubCalculationRequest
	)
}

export function* purchasesAffectedConfirmSaga() {
	yield takeLatest(PURCHASES_AFFECTED_CONFIRM, purchasesAffectedConfirmRequest)
}

export function* purchasesAffectedImportSaga() {
	yield takeLatest(PURCHASES_AFFECTED_IMPORT, purchasesAffectedImportRequest)
}

export function* purchasesAffectedImpValidateSaga() {
	yield takeLatest(
		PURCHASES_AFFECTED_IMPORT_VALIDATE,
		purchasesAffectedImpValidateRequest
	)
}

export function* purchasesValidateDateSaga() {
	yield takeLatest(PURCHASES_VALIDATE_DATE, purchasesValidateDateRequest)
}

export function* purchasesAffectedImportConfirmSaga() {
	yield takeLatest(
		PURCHASES_AFFECTED_IMPORT_CONFIRM,
		purchasesAffectedImportConfirmRequest
	)
}

export function* purchasesAffectedStateSaga() {
	yield takeLatest(PURCHASES_AFFECTED_STATE, purchasesAffectedStateRequest)
}

export function* purchasesAffectedStateValidateSaga() {
	yield takeLatest(
		PURCHASES_AFFECTED_STATE_VALIDATE,
		purchasesAffectedStateValidateRequest
	)
}

export function* purchasesAffectedStateConfirmSaga() {
	yield takeLatest(
		PURCHASES_AFFECTED_STATE_CONFIRM,
		purchasesAffectedStateConfirmRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(purchasesQuantityAffectedSaga),
		fork(purchasesAffectedValidateSaga),
		fork(purchasesAffectedSubCalculationSaga),
		fork(purchasesAffectedConfirmSaga),
		fork(purchasesAffectedImportSaga),
		fork(purchasesAffectedImpValidateSaga),
		fork(purchasesValidateDateSaga),
		fork(purchasesAffectedImportConfirmSaga),
		fork(purchasesAffectedStateSaga),
		fork(purchasesAffectedStateValidateSaga),
		fork(purchasesAffectedStateConfirmSaga)
	])
}
