import {
	AUTOMATIC_PURCHASE_CHANGE_LINE,
	AUTOMATIC_PURCHASE_CHANGE_LINE_SUCCESS,
	AUTOMATIC_PURCHASE_CONFIRM,
	AUTOMATIC_PURCHASE_CONFIRM_SUCCESS,
	AUTOMATIC_PURCHASE_EXPORT,
	AUTOMATIC_PURCHASE_EXPORT_SUCCESS,
	AUTOMATIC_PURCHASE_FILTER,
	AUTOMATIC_PURCHASE_FILTER_SUCCESS,
	AUTOMATIC_PURCHASE_UPDATE,
	AUTOMATIC_PURCHASE_UPDATE_SUCCESS,
	GET_DEPOSITS,
	GET_DEPOSITS_SUCCESS,
	GET_LIST_CALCULATION_FORMULAS,
	GET_LIST_CALCULATION_FORMULAS_SUCCESS,
	GET_LIST_COST_TYPE,
	GET_LIST_COST_TYPE_SUCCESS,
	GET_LIST_DRUGS,
	GET_LIST_DRUGS_SUCCESS,
	GET_LIST_LABORATORIES,
	GET_LIST_LABORATORIES_SUCCESS,
	GET_LIST_ORDER_OC_MULTI,
	GET_LIST_ORDER_OC_MULTI_SUCCESS,
	GET_LIST_PROVIDERS,
	GET_LIST_PROVIDERS_SUCCESS,
	GET_PAYMENT_TERMS_FILTER_LIST,
	GET_PAYMENT_TERMS_FILTER_LIST_SUCCESS
} from 'constants/ActionsTypes'
import {
	IAutomaticPurchaseChangeLineAction,
	IAutomaticPurchaseChangeLineParams,
	IAutomaticPurchaseChangeLineResponse,
	IAutomaticPurchaseChangeLineSuccessAction,
	IAutomaticPurchaseConfirm,
	IAutomaticPurchaseConfirmAction,
	IAutomaticPurchaseConfirmResponse,
	IAutomaticPurchaseConfirmSuccessAction,
	IAutomaticPurchaseFilterAction,
	IAutomaticPurchaseFilterParams,
	IAutomaticPurchaseFilterSuccessAction,
	IAutomaticPurchaseUpdateAction,
	IAutomaticPurchaseUpdateParams,
	IAutomaticPurchaseUpdateResponse,
	IAutomaticPurchaseUpdateSuccessAction,
	IGetCostTypeSuccessAction,
	IGetCostTypeSuccessResponse,
	IGetDepositsAction,
	IGetDepositsParams,
	IGetLaboratoriesAction,
	IGetLaboratoriesParams,
	IGetListCalculationFormulasAction,
	IGetListCalculationFormulasSuccessAction,
	IGetListCalculationFormulasSuccessResponse,
	IGetListCostTypeAction,
	IGetListDrugsAction,
	IGetListDrugsParams,
	IGetListDrugsSuccessAction,
	IGetListDrugsSuccessResponse,
	IGetListLaboratoriesSuccessAction,
	IGetListLaboratoriesSuccessResponse,
	IGetListOrderOCMultiAction,
	IGetListOrderOCMultiSuccessAction,
	IGetListOrderOCMultiSuccessResponse,
	IGetListProvidersAction,
	IGetListProvidersParams,
	IGetListProvidersResponse,
	IGetPaymentTermsFilterListParams,
	IGetPaymentTermsFiltersListAction,
	IGetPaymentTermsFiltersListSuccessAction,
	IGetProvidersSuccessAction
} from 'models/AutomaticPurchase'

export const getDeposits = (
	payload: IGetDepositsParams
): IGetDepositsAction => ({
	type: GET_DEPOSITS,
	payload
})

export const getDepositsSuccess = (payload: any) => ({
	type: GET_DEPOSITS_SUCCESS,
	payload
})

export const getListCalculationFormulas =
	(): IGetListCalculationFormulasAction => ({
		type: GET_LIST_CALCULATION_FORMULAS
	})

export const getListCalculationFormulasSuccess = (
	payload: IGetListCalculationFormulasSuccessResponse
): IGetListCalculationFormulasSuccessAction => ({
	type: GET_LIST_CALCULATION_FORMULAS_SUCCESS,
	payload
})

export const getListOrderOCMulti = (): IGetListOrderOCMultiAction => ({
	type: GET_LIST_ORDER_OC_MULTI
})

export const getListOrderOCMultiSuccess = (
	payload: IGetListOrderOCMultiSuccessResponse
): IGetListOrderOCMultiSuccessAction => ({
	type: GET_LIST_ORDER_OC_MULTI_SUCCESS,
	payload
})

export const getListLaboratories = (
	payload: IGetLaboratoriesParams
): IGetLaboratoriesAction => ({
	type: GET_LIST_LABORATORIES,
	payload
})

export const getListLaboratoriesSuccess = (
	payload: IGetListLaboratoriesSuccessResponse
): IGetListLaboratoriesSuccessAction => ({
	type: GET_LIST_LABORATORIES_SUCCESS,
	payload
})

export const getListDrugs = (
	payload: IGetListDrugsParams
): IGetListDrugsAction => ({
	type: GET_LIST_DRUGS,
	payload
})

export const getListDrugsSuccess = (
	payload: IGetListDrugsSuccessResponse
): IGetListDrugsSuccessAction => ({
	type: GET_LIST_DRUGS_SUCCESS,
	payload
})

export const getListCostTypes = (): IGetListCostTypeAction => ({
	type: GET_LIST_COST_TYPE
})

export const getListCostTypesSuccess = (
	payload: IGetCostTypeSuccessResponse
): IGetCostTypeSuccessAction => ({
	type: GET_LIST_COST_TYPE_SUCCESS,
	payload
})

export const getListProviders = (
	payload: IGetListProvidersParams
): IGetListProvidersAction => ({
	type: GET_LIST_PROVIDERS,
	payload
})

export const getListProvidersSuccess = (
	payload: IGetListProvidersResponse
): IGetProvidersSuccessAction => ({
	type: GET_LIST_PROVIDERS_SUCCESS,
	payload
})

export const getPaymentTermsFilterList = (
	payload: IGetPaymentTermsFilterListParams
): IGetPaymentTermsFiltersListAction => ({
	type: GET_PAYMENT_TERMS_FILTER_LIST,
	payload
})

export const getPaymentTermsFilterListSuccess = (
	payload: any
): IGetPaymentTermsFiltersListSuccessAction => ({
	type: GET_PAYMENT_TERMS_FILTER_LIST_SUCCESS,
	payload
})

export const automaticPurchaseFilter = (
	payload: IAutomaticPurchaseFilterParams
): IAutomaticPurchaseFilterAction => ({
	type: AUTOMATIC_PURCHASE_FILTER,
	payload
})

export const automaticPurchaseFilterSuccess = (
	payload: any
): IAutomaticPurchaseFilterSuccessAction => ({
	type: AUTOMATIC_PURCHASE_FILTER_SUCCESS,
	payload
})

export const automaticPurchaseChangeLine = (
	payload: IAutomaticPurchaseChangeLineParams
): IAutomaticPurchaseChangeLineAction => ({
	type: AUTOMATIC_PURCHASE_CHANGE_LINE,
	payload
})

export const automaticPurchaseChangeLineSuccess = (
	payload: IAutomaticPurchaseChangeLineResponse
): IAutomaticPurchaseChangeLineSuccessAction => ({
	type: AUTOMATIC_PURCHASE_CHANGE_LINE_SUCCESS,
	payload
})

export const automaticPurchaseConfirm = (
	payload: IAutomaticPurchaseConfirm
): IAutomaticPurchaseConfirmAction => ({
	type: AUTOMATIC_PURCHASE_CONFIRM,
	payload
})

export const automaticPurchaseConfirmSuccess = (
	payload: IAutomaticPurchaseConfirmResponse
): IAutomaticPurchaseConfirmSuccessAction => ({
	type: AUTOMATIC_PURCHASE_CONFIRM_SUCCESS,
	payload
})

export const automaticPurchaseUpdate = (
	payload: IAutomaticPurchaseUpdateParams
): IAutomaticPurchaseUpdateAction => ({
	type: AUTOMATIC_PURCHASE_UPDATE,
	payload
})

export const automaticPurchaseUpdateSuccess = (
	payload: IAutomaticPurchaseUpdateResponse
): IAutomaticPurchaseUpdateSuccessAction => ({
	type: AUTOMATIC_PURCHASE_UPDATE_SUCCESS,
	payload
})

export const automaticPurchaseExport = (payload: any): any => ({
	type: AUTOMATIC_PURCHASE_EXPORT,
	payload
})

export const automaticPurchaseExportSuccess = (payload: any): any => ({
	type: AUTOMATIC_PURCHASE_EXPORT_SUCCESS,
	payload
})
