import { getDetailVoucherHeadboard } from 'actions'
import CommonLabelCol from 'components/common/CommonLabelCol'
import CommonTable from 'components/common/commonTable'
import { getDefaultColumns } from 'lib/FormUtils'
import { getValueMaskObject } from 'lib/MaskValues'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'

class SearchVoucherHeaderTable extends Component {
	constructor(props) {
		super(props)
		this.primaryKey = 'descrip'
	}

	componentDidMount() {
		const { id, authUser } = this.props

		if (id) {
			this.props.getDetailVoucherHeadboard({
				id,
				page_size: 10,
				page_number: 1,
				idEmpresa: authUser.userCompany // Company ID
			})
		}
	}

	handleChangeTable = () => {}

	render() {
		const { headboardData, fields, fieldsTable, fieldsTableAttr, t, authUser } =
			this.props

		const headboardInfo = headboardData || {}

		const maskedData = {
			imp_total: headboardInfo.imp_total,
			saldo: headboardInfo.saldo,
			imp_total_ext: headboardInfo.imp_total_ext,
			saldo_mone: headboardInfo.saldo_mone
		}

		const valuesMasked = getValueMaskObject(maskedData, fields, authUser)

		const propsTable = {
			remote: true,
			columns:
				fieldsTable.length > 0
					? getDefaultColumns(fieldsTable, { authUser })
					: [],
			keyField: this.primaryKey,
			data: !_.isEmpty(headboardData) ? headboardData.totales : [],
			rowClasses: 'theme.tableRow',
			headerClasses: 'theme.tableHeader',
			paginationOptions: {},
			onTableChange: this.handleChangeTable
		}

		const propsTableAttr = {
			remote: true,
			columns:
				fieldsTableAttr.length > 0
					? getDefaultColumns(fieldsTableAttr, { authUser })
					: [],
			keyField: this.primaryKey,
			data: _.isEmpty(headboardData) ? [] : headboardData.atributos,
			rowClasses: 'theme.tableRow',
			headerClasses: 'theme.tableHeader',
			paginationOptions: {},
			onTableChange: this.handleChangeTable
		}

		return (
			<Fragment>
				<Row>
					<CommonLabelCol
						colLabel={{ sm: 4 }}
						fields={fields}
						labelId="cond_ope"
						value={`${headboardInfo.cond_ope}` || ''}
					/>
					<CommonLabelCol
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="sucursal"
						value={`${headboardInfo.sucursal}` || ''}
					/>
					<CommonLabelCol
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="vendedor"
						value={headboardInfo.vendedor || ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: { span: 4, offset: 4 }, style: { top: '0px' } }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="usuario"
						value={`${headboardInfo.usuario}` || ''}
					/>
					<CommonLabelCol
						colLabel={{ sm: 4 }}
						fields={fields}
						labelId="fmovim"
						value={headboardInfo.fmovim || ''}
					/>
					<CommonLabelCol
						colLabel={{ sm: 4 }}
						fields={fields}
						labelId="hmovim"
						value={headboardInfo.hmovim || ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{
							sm: 4,
							style: {
								border: '1px solid #ccc',
								borderRight: '0px',
								backgroundColor: '#f4f4f4'
							}
						}}
						colLabel={{ sm: 4 }}
						fields={fields}
						labelId="imp_total"
						value={
							!_.isEmpty(headboardInfo.mon_usu) ? (
								<Fragment>
									<div>{`${headboardInfo.mon_usu} ${valuesMasked.imp_total}`}</div>
									<div>{`${headboardInfo.c_mone_ext} ${valuesMasked.imp_total_ext}`}</div>
								</Fragment>
							) : (
								''
							)
						}
					/>
					<CommonLabelCol
						formCol={{
							sm: 4,
							style: {
								border: '1px solid #ccc',
								borderLeft: '0px',
								backgroundColor: '#f4f4f4'
							}
						}}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="saldo"
						value={
							headboardInfo.mon_usu ? (
								<Fragment>
									<div>{`${headboardInfo.mon_usu} ${valuesMasked.saldo}`}</div>
									<div>{`${headboardInfo.c_mone_ext} ${valuesMasked.saldo_mone}`}</div>
								</Fragment>
							) : (
								''
							)
						}
					/>
					<Col sm={4}>
						<Row style={{ height: '0px' }} className="mb-2">
							<CommonLabelCol
								formCol={{ sm: { span: 12, offset: 0 }, style: { top: '0px' } }}
								colLabel={{ sm: 3 }}
								fields={fields}
								labelId="cotizacion"
								value={headboardInfo.cotizacion || ''}
							/>
							<CommonLabelCol
								formCol={{ sm: { span: 12, offset: 0 }, style: { top: '0px' } }}
								colLabel={{ sm: 3 }}
								fields={fields}
								labelId="mone_comp"
								value={headboardInfo.mone_comp || ''}
							/>
						</Row>
					</Col>
				</Row>

				<Row className={'pt-3'}>
					<Col className="font-weight-bold text-center" lg={6} md={12} sm={12}>
						{t('search_voucher.totals')}
					</Col>
					<Col
						className="font-weight-bold text-center"
						lg={{ span: 4, offset: 1 }}
						md={12}
						sm={12}
					>
						{t('search_voucher.attributes')}
					</Col>
					<Col lg={6} md={12} sm={12}>
						{!_.isEmpty(fieldsTable) && <CommonTable {...propsTable} />}
					</Col>

					<Col
						lg={{ span: 4, offset: 1 }}
						md={12}
						sm={12}
						className={'pl-0 pr-0'}
					>
						{!_.isEmpty(fieldsTableAttr) && <CommonTable {...propsTableAttr} />}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ configCrud, searchVoucher, auth }) => {
	const { fieldsConfig } = configCrud
	const { headboardData } = searchVoucher
	const { authUser } = auth
	return { fieldsConfig, headboardData, authUser }
}

const connectForm = connect(mapStateToProps, { getDetailVoucherHeadboard })(
	SearchVoucherHeaderTable
)

export default withTranslation()(connectForm)
