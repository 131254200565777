import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getPendingVouchers } from 'actions'
import CommonModal from 'components/common/commonModal'
import CommonTable from 'components/common/commonTable'
import AffectationVoucher from 'components/selectType/AffectationVoucher'
import { getDefaultColumns } from 'lib/FormUtils'
import React, { Component, Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'

interface IPendingVouchersProps extends WithTranslation {
	getPendingVouchers: any
	authUser: any
	pendingVouchers: any
	t?: any
}

interface IPendingVouchersState {
	showModal: boolean
	nioperacion: number
}

class PendingVouchersTable extends Component<
	IPendingVouchersProps,
	IPendingVouchersState
> {
	constructor(props: IPendingVouchersProps) {
		super(props)
		this.state = {
			showModal: false,
			nioperacion: 0
		}
	}

	componentDidMount = () => {
		const { authUser } = this.props

		this.props.getPendingVouchers({ idEmpresa: authUser.userCompany })
	}

	toggleVoucherModal = () => {
		this.setState((prevState) => ({ showModal: !prevState.showModal }))
	}

	handleAffectation = (row: any) => {
		const { nioperacion } = row
		this.setState({ nioperacion })
		this.toggleVoucherModal()
	}

	render() {
		const { authUser, pendingVouchers, t } = this.props
		const { showModal, nioperacion } = this.state

		const additionalColumns = [
			{
				dataField: 'actions',
				text: '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '5%' },
				formatter: (cell: any, row: any, rowindex: any) => {
					return (
						<FontAwesomeIcon
							className={'mr-3'}
							icon={faChevronCircleRight}
							style={{ cursor: 'pointer' }}
							color={'#007bff'}
							onClick={() => this.handleAffectation(row)}
						/>
					)
				}
			}
		]

		const propsTable = {
			remote: false,
			columns: getDefaultColumns(
				[
					{ idCampo: 'modulo', label: 'Modulo', visible: true },
					{ idCampo: 'descrip_comprob', label: 'Comprobante', visible: true },
					{ idCampo: 'cant', label: 'Cantidad', visible: true }
				],
				{ authUser },
				additionalColumns
			),
			keyField: 'nioperacion',
			data: _.isEmpty(pendingVouchers) ? [] : pendingVouchers.pendientes,
			rowClasses: 'theme.tableRow',
			headerClasses: 'theme.tableHeader'
		}

		const propsModal = {
			showModal,
			handleCloseModal: this.toggleVoucherModal,
			modalTitle: t('pending_voucher.title_modal'),
			sizeModal: 'lg',
			modalBody: (
				<AffectationVoucher
					nioperacion={nioperacion}
					handleCloseModal={this.toggleVoucherModal}
				/>
			)
		}

		return (
			<Fragment>
				<CommonModal {...propsModal} />
				{pendingVouchers && !_.isEmpty(pendingVouchers.pendientes) && (
					<CommonTable {...propsTable} />
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = ({ landingItems, auth }: any) => {
	const { pendingVouchers } = landingItems
	const { authUser } = auth

	return { pendingVouchers, authUser }
}

export default withTranslation()(
	connect(mapStateToProps, {
		getPendingVouchers
	})(PendingVouchersTable)
)
