import Axios from 'axios'
import {
	ICrudGetProfileTabs,
	ICrudUpdateProfileTabs
} from 'models/CrudProfilesInterface'

const urlCRUD = '/abm_perfiles/tab'

export const crudGetProfileTabs = async (params: ICrudGetProfileTabs) => {
	const response = await Axios.get(`${urlCRUD}/buscar`, { params })
	return response.data
}

export const crudUpdateProfileTabs = async (params: ICrudUpdateProfileTabs) => {
	const response = await Axios.post(`${urlCRUD}/confirmar`, params)
	return response.data
}
