import Axios from 'axios'
import {
	ICrudGetPurchaseConditionsDues,
	ICrudUpdatePurchaseConditionsDues
} from 'models/CrudPurchaseConditionsInterface'

const urlBase = '/abm_cpa_cond'

export const crudGetPurchaseConditionsDues = async (
	params: ICrudGetPurchaseConditionsDues
) => {
	const response = await Axios.get(`${urlBase}/cuotas/sel`, { params })
	return response.data
}

export const crudUpdatePurchaseConditionsDues = async (
	params: ICrudUpdatePurchaseConditionsDues
) => {
	const response = await Axios.post(`${urlBase}/cuotas/confirmar`, params)
	return response.data
}
