import {
	crudsCloneSuccess,
	crudsCreateSuccess,
	crudsDeleteSuccess,
	crudsGetListSuccess,
	crudsGetSingleSuccess,
	getAllCrudsSuccess,
	getAmbCodeSuccess,
	getConfigFieldsCrudAltaSuccess,
	getConfigFieldsCrudSuccess
} from 'actions'
import {
	CRUD_CLONE,
	CRUD_CREATE,
	CRUD_DELETE,
	CRUD_GET_LIST,
	CRUD_GET_SINGLE,
	GET_ABM_CODE,
	GET_ALL_CRUDS,
	GET_CONFIG_FIELDS_CRUD,
	GET_CONFIG_FIELDS_CRUD_ALTA
} from 'constants/ActionsTypes'
import {
	IGetAbmCodeSaga,
	IGetAllCrudsSaga,
	IGetConfigFieldsCrudSaga
} from 'models/CrudInterface'
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
	crudsClone,
	crudsCreate,
	crudsDelete,
	crudsGetList,
	crudsGetSingle,
	getAbmCodeApi,
	getAllCruds,
	getConfigFieldsCrud
} from '../api/Cruds'

function* getConfigFieldsCrudRequest({
	payload
}: IGetConfigFieldsCrudSaga): any {
	try {
		const configFields = yield call(getConfigFieldsCrud, payload)
		yield put(getConfigFieldsCrudSuccess(configFields))
	} catch (error) {}
}

function* getConfigFieldsCrudAltaRequest({
	payload
}: IGetConfigFieldsCrudSaga): any {
	try {
		const configFields = yield call(getConfigFieldsCrud, payload)
		yield put(getConfigFieldsCrudAltaSuccess(configFields))
	} catch (error) {}
}

function* getAllCrudsRequest({ payload }: IGetAllCrudsSaga): any {
	try {
		const cruds = yield call(getAllCruds, payload)
		yield put(getAllCrudsSuccess(cruds))
	} catch (error) {}
}

function* getAbmCodeRequest({ payload }: IGetAbmCodeSaga): any {
	try {
		const code = yield call(getAbmCodeApi, payload)
		yield put(getAmbCodeSuccess(code))
	} catch (error) {}
}

function* crudGetListRequest({ payload }: any): any {
	try {
		const values = yield call(crudsGetList, payload)
		yield put(crudsGetListSuccess(values))
	} catch (error) {}
}

function* crudDeleteRequest({ payload }: any): any {
	try {
		const values = yield call(crudsDelete, payload)
		yield put(crudsDeleteSuccess(values))
	} catch (error) {}
}

function* crudGetSingleRequest({ payload }: any): any {
	try {
		const values = yield call(crudsGetSingle, payload)
		yield put(crudsGetSingleSuccess(values))
	} catch (error) {}
}

function* crudCreateRequest({ payload }: any): any {
	try {
		const values = yield call(crudsCreate, payload)
		yield put(crudsCreateSuccess(values))
	} catch (error) {}
}

function* crudCloneRequest({ payload }: any): any {
	try {
		const values = yield call(crudsClone, payload)
		yield put(crudsCloneSuccess(values))
	} catch (error) {}
}

export function* getConfigFieldsCrudSaga() {
	yield takeLatest(GET_CONFIG_FIELDS_CRUD, getConfigFieldsCrudRequest)
}

export function* getConfigFieldsCrudAltaSaga() {
	yield takeLatest(GET_CONFIG_FIELDS_CRUD_ALTA, getConfigFieldsCrudAltaRequest)
}

export function* getAllCrudsSaga() {
	yield takeLatest(GET_ALL_CRUDS, getAllCrudsRequest)
}

export function* getAbmCodeSaga() {
	yield takeLatest(GET_ABM_CODE, getAbmCodeRequest)
}

export function* crudGetListSaga() {
	yield takeLatest(CRUD_GET_LIST, crudGetListRequest)
}

export function* crudDeleteSaga() {
	yield takeLatest(CRUD_DELETE, crudDeleteRequest)
}

export function* crudGetSingleSaga() {
	yield takeEvery(CRUD_GET_SINGLE, crudGetSingleRequest)
}

export function* crudCreateSaga() {
	yield takeLatest(CRUD_CREATE, crudCreateRequest)
}

export function* crudCloneSaga() {
	yield takeLatest(CRUD_CLONE, crudCloneRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getConfigFieldsCrudSaga),
		fork(getConfigFieldsCrudAltaSaga),
		fork(getAllCrudsSaga),
		fork(getAbmCodeSaga),
		fork(crudGetListSaga),
		fork(crudDeleteSaga),
		fork(crudGetSingleSaga),
		fork(crudCreateSaga),
		fork(crudCloneSaga)
	])
}
