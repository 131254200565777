import InputDropdown from 'components/form/inputDropdown'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import styles from './collectionForm.module.css'

const config = {
	idCampo: 'orden_dato',
	descrip: '',
	label: false,
	mascara: '',
	editable: 1,
	visible: 1,
	requerido: 0,
	valid: null
}

const configType = {
	idCampo: 'orden_tipo',
	descrip: '',
	label: false,
	mascara: '',
	editable: 1,
	visible: 1,
	requerido: 0,
	valid: null
}

class CollectionOrderForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			orderCollection: this.props.filterOrder
				? this.props.filterOrder[0].id
				: 'I',
			sentOrderCollection: this.props.filterOrderType
				? this.props.filterOrderType[0].id
				: 'ASC'
		}
		// I: Item/ T: Tipo cuenta / C: Cuenta/ V: Importe Por defecto se considera por item descendente
		this.optionsOrder = [
			{
				id: 'I',
				label: 'Item'
			},
			{
				id: 'T',
				label: 'Tipo Cuenta'
			},
			{
				id: 'C',
				label: 'Cuenta'
			},
			{
				id: 'V',
				label: 'Importe'
			}
		]

		this.sendoptionsOrder = [
			{
				id: 'ASC',
				label: '\u25B2'
			},
			{
				id: 'DESC',
				// label: () => { return <FontAwesomeIcon icon={faLongArrowAltDown} /> },
				// label: '&#8657',
				label: '\u25BC'
			}
		]
	}

	renderOptions = (options) => {
		const result = []
		options.forEach((option) => {
			result.push(
				<option value={option.id} key={option.id}>{`${option.label}`}</option>
			)
		})

		return result
	}

	render() {
		const { theme, filterOrder, filterOrderType } = this.props
		return (
			<Row>
				<label style={{ paddingTop: '4px' }} className={theme.inputLabel}>
					Orden
				</label>

				<InputDropdown
					inputFormCol={{ sm: 5 }}
					fields={[config]}
					label={false}
					inputId={'orden_dato'}
					name={'orden_dato'}
					styles={{ width: '100%' }}
					colLabel={'col-sm-2'}
					colInput={'col-sm-10'}
					options={filterOrder || this.optionsOrder}
					noInitialExecute
					onChange={(e) => {
						this.setState({ orderCollection: e.target.value })
						this.props.handleChangeData({
							orden_dato: e.target.value,
							orden_tipo: this.state.sentOrderCollection
						})
					}}
				/>

				<InputDropdown
					inputFormCol={{ sm: 3 }}
					fields={[configType]}
					label={false}
					inputId={'orden_tipo'}
					name={'orden_tipo'}
					styles={{ width: '100%' }}
					colLabel={'col-sm-2'}
					colInput={'col-sm-10'}
					options={filterOrderType || this.sendoptionsOrder}
					noInitialExecute
					onChange={(e) => {
						this.setState({ sentOrderCollection: e.target.value })
						this.props.handleChangeData({
							orden_tipo: e.target.value,
							orden_dato: this.state.orderCollection
						})
					}}
				/>
			</Row>
		)
	}
}

export default themr('collectionOrderStyle', styles)(CollectionOrderForm)
