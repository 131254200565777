import {
	CRUD_CLEAR_PROFILE_USERS_STORE,
	CRUD_GET_PROFILE_USERS,
	CRUD_GET_PROFILE_USERS_SUCCESS,
	CRUD_UPDATE_PROFILE_USERS,
	CRUD_UPDATE_PROFILE_USERS_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	profileUsersSearched: any
	profileUsersUpdated: any
}

const initialState = {
	profileUsersSearched: null,
	profileUsersUpdated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_PROFILE_USERS:
			return { ...state, profileUsersSearched: null }
		case CRUD_GET_PROFILE_USERS_SUCCESS:
			return { ...state, profileUsersSearched: action.payload }
		case CRUD_UPDATE_PROFILE_USERS:
			return { ...state, profileUsersUpdated: null }
		case CRUD_UPDATE_PROFILE_USERS_SUCCESS:
			return { ...state, profileUsersUpdated: action.payload }
		case CRUD_CLEAR_PROFILE_USERS_STORE:
			return {
				...state,
				profileUsersSearched: null,
				profileUsersUpdated: null
			}
		default:
			return state
	}
}

export default rootReducer
