import InputText from 'components/form/inputText'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import _ from 'underscore'
import InputDropdown from '../form/inputDropdown'

class ClientFormInput extends Component {
	handleChange = (select) => {
		const { cliente_Sucursales } = this.props.values
		const val = select.target.value
		let result = null

		cliente_Sucursales.forEach((opt) => {
			if (opt.suc_nro === parseInt(val)) {
				result = opt
			}
		})

		this.setValues(result)
	}

	setValues = (data) => {
		const { setFieldValue, inputsForm } = this.props
		if (data) {
			setFieldValue(
				inputsForm.email,
				data.suc_email !== null ? data.suc_email : ''
			)
			setFieldValue(
				inputsForm.phone,
				data.suc_telef !== null ? data.suc_telef : ''
			)

			setFieldValue('client_branch', data.suc_nro)
			setFieldValue(inputsForm.address, data.address)
			setFieldValue(inputsForm.location, data.suc_local)
			setFieldValue(inputsForm.province, data.suc_nom_prov)
			setFieldValue(inputsForm.codPos, data.suc_cpos)
		} else {
			setFieldValue(inputsForm.email, '')
			setFieldValue(inputsForm.phone, '')
			setFieldValue(inputsForm.address, '')
			setFieldValue(inputsForm.location, '')
			setFieldValue(inputsForm.province, '')
			setFieldValue(inputsForm.codPos, '')
		}
	}

	render() {
		const {
			t,
			values,
			handleChange,
			handleBlur,
			setFieldValue,
			readOnly,
			fields,
			inputsForm
		} = this.props

		const branch = _.find(
			values[inputsForm.branches],
			(branch) => branch[inputsForm.branchId] === values.client_branch
		)

		const optionsBranch = values.cliente_Sucursales
			? values.cliente_Sucursales.map((opt) => {
					return { id: opt.suc_nro, label: opt.suc_nombre }
			  })
			: []

		return (
			<Row>
				<InputDropdown
					inputFormCol={{ sm: 11 }}
					fields={fields}
					label={t('client.form.client_branch')}
					inputId={'cliente_Sucursales'}
					name={'cliente_Sucursales'}
					placeholder={t('client.form.insert_client_branch')}
					styles={{ width: '100%' }}
					colLabel={'col-sm-2'}
					colInput={'col-sm-10'}
					options={optionsBranch}
					onBlur={handleBlur}
					onChange={this.handleChange}
				/>

				<InputText
					inputFormCol={{ sm: 11 }}
					fields={fields}
					label={t('client.form.contact')}
					inputId={inputsForm.contact}
					name={inputsForm.contact}
					placeholder={t('client.form.insert_contact')}
					styles={{ width: '100%' }}
					colLabel={'col-sm-2'}
					colInput={'col-sm-10'}
					disable={readOnly}
					value={values[inputsForm.contact]}
					onBlur={handleBlur}
					onChange={(data) => {
						setFieldValue(inputsForm.contact, data)
						handleChange()
					}}
				/>
				<Row className={'col-11'} style={{ paddingRight: '0px' }}>
					<InputText
						inputFormCol={{ sm: 6 }}
						fields={fields}
						label={t('client.form.phone')}
						inputId={inputsForm.phone}
						name={inputsForm.phone}
						placeholder={t('client.form.insert_phone')}
						colLabel={'col-sm-4'}
						colInput={'col-sm-8'}
						disable={readOnly}
						divStyle={{ paddingLeft: '17px' }}
						value={values[inputsForm.phone]}
						onBlur={handleBlur}
						onChange={(data) => {
							setFieldValue(inputsForm.phone, data)
							handleChange()
						}}
					/>
					<InputText
						inputFormCol={{ sm: 6, style: { paddingRight: '0px' } }}
						fields={fields}
						label={t('client.form.email')}
						inputId={inputsForm.email}
						name={inputsForm.email}
						placeholder={t('client.form.insert_email')}
						colLabel={'col-sm-4'}
						colInput={'col-sm-8'}
						divStyle={{ paddingLeft: '10px' }}
						styleLabel={{ textAlign: 'right' }}
						disable={readOnly}
						onBlur={handleBlur}
						value={values[inputsForm.email]}
						onChange={(data) => {
							setFieldValue(inputsForm.email, data)
							handleChange()
						}}
					/>
				</Row>
				<InputText
					inputFormCol={{ sm: 11 }}
					fields={fields}
					label={t('client.form.address')}
					inputId={inputsForm.address}
					name={inputsForm.address}
					placeholder={t('client.form.insert_address')}
					styles={{ width: '100%' }}
					colLabel={'col-sm-2'}
					colInput={'col-sm-10'}
					disable={readOnly}
					onBlur={handleBlur}
					value={branch ? branch[inputsForm.branchAddress] : ''}
					onChange={(data) => {
						setFieldValue(inputsForm.address, data)
						handleChange()
					}}
				/>
			</Row>
		)
	}
}

export default withTranslation()(ClientFormInput)
