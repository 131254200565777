import { crudGetProviderObservation } from 'actions'
import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import NotificationError from 'components/common/notificationsErrors'
import { Formik } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { renderFormInputs } from 'lib/FormUtils'
import React, { Component, Fragment } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const noRenderInputs = ['cancelar', 'aceptar']

type ProviderObservationsTabProps = {
	niprov: number
	crudGetProviderObservation: any
	disableForm: boolean
	disableFields: boolean
	fields: any
	t: any
	handleSubmit: any
	itemInfo: any
	handleObservationDisable: any
	handleWarning: any
	showWarning: boolean
	setFormChanged: any
	op: string
}

class ProviderObservationsTab extends Component<ProviderObservationsTabProps> {
	constructor(props: ProviderObservationsTabProps) {
		super(props)
		this.state = {}
	}

	componentDidMount = () => {
		const { niprov, crudGetProviderObservation } = this.props
		crudGetProviderObservation({ niprov })
	}

	/**
	 * to add callbacks to fields.
	 * @param {object} fields
	 * @param {object} props
	 * @returns html inputs
	 */

	/**
	 * to show the form fields from the api configuration     *
	 */

	renderInputs = (fields: any, formikProps: any) => {
		const { disableFields } = this.props

		fields.forEach((field: any) => {
			const idField = field.idCampo.trim()

			if (idField === 'obs_vtas') {
				field.tipo = 'textArea'
			} else if (idField === 'obs_desp') {
				field.tipo = 'textArea'
			} else if (idField === 'obs_cc') {
				field.tipo = 'textArea'
			}
		})

		return renderFormInputs(formikProps, fields, noRenderInputs, disableFields)
	}

	/**
	 * to get data of sellers
	 */

	render() {
		const {
			fields,
			t,
			handleSubmit,
			itemInfo,
			disableForm,
			disableFields,
			handleWarning,
			setFormChanged,
			showWarning,
			handleObservationDisable,
			op
		} = this.props
		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})
		const columnsCancel = disableFields ? 12 : 6
		const validationSchema = fields.length
			? getValidationSchema(fields, t, [])
			: {}

		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values, actions) => {
					handleSubmit(values)
				}}
				validationSchema={validationSchema}
				enableReinitialize={true}
				render={(props) => (
					<Fragment>
						<Col sm={12}>
							<NotificationError
								errors={props.errors}
								touched={props.touched}
							/>
						</Col>
						<Form
							onSubmit={(e) => props.handleSubmit(e as any)}
							className="voucher-info-form"
							onChange={(e) => {
								setFormChanged(true)
							}}
						>
							{fields && this.renderInputs(fields, props)}
							<Row className={'pt-3'}>
								{!disableFields && (
									<>
										<Col sm={6} className={'text-center mt-2'}>
											<Button style={{ minWidth: '145px' }} type={'submit'}>
												{t('Collection.form.buttons.accept')}
											</Button>
										</Col>
										<Col sm={6} className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={handleObservationDisable}
											>
												{t('Collection.form.buttons.cancel')}
											</Button>
										</Col>
									</>
								)}

								{!disableForm && (
									<Col
										sm={columnsCancel}
										className={'text-center mt-2 d-flex justify-content-around'}
									>
										{disableFields && op !== 'view' && (
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={handleObservationDisable}
											>
												{t('Editar')}
											</Button>
										)}
									</Col>
								)}
							</Row>
							<ConfirmModalNoButton
								modalTitle={t('CRUDS.modal.title_warning')}
								messageBody={t('CRUDS.modal.message_changes')}
								showModal={showWarning}
								handleCloseModal={() => handleWarning(false, 'close')}
								handleCancelButton={() => {
									handleWarning(false, 'cancel')
								}}
								handleSubmitButton={(e: any) => {
									props.handleSubmit(e as any)
									handleWarning(false, 'submit')
								}}
							/>
						</Form>
					</Fragment>
				)}
			/>
		)
	}
}

const mapStateToProps = ({ providerObservation }: any) => {
	const { observationInfo } = providerObservation
	return {
		observationInfo
	}
}

const connectForm = connect(mapStateToProps, {
	crudGetProviderObservation
})(ProviderObservationsTab)

export default withTranslation()(withRouter(connectForm))
