import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
	cancelSelDocumentsSuccess,
	collectionImportDocSuccess,
	confirImpDocumentsSuccess,
	confirmPaymentSuccess,
	confirmSelDocumentsSuccess,
	getCollectionsSuccess,
	getImpDocumentsError,
	getImpDocumentsSuccess,
	getSelDocumentsSuccess,
	removePaymentSuccess,
	searchBankSuccess,
	validatePaymentSuccess,
	validateSelDocumentsSuccess
} from '../actions/Collections'
import {
	cancelSelDocuments,
	collectionImportDoc,
	confirmImpDocuments,
	confirmPayment,
	confirmSelDocuments,
	getCollections,
	getImpDocuments,
	getSelDocuments,
	removePayment,
	searchBank,
	validatePayment,
	validateSelDocuments
} from '../api/Collection'
import {
	CANCEL_SEL_DOCUMENTS,
	COLLECTION_IMPORT_DOC,
	CONFIRM_IMP_DOCUMENTS,
	CONFIRM_PAYMENT,
	CONFIRM_SEL_DOCUMENTS,
	ERROR_GET_IMPDOCS,
	GET_COLLECTIONS,
	GET_IMP_DOCUMENTS,
	GET_SEL_DOCUMENTS,
	REMOVE_PAYMENT,
	SEARCH_BANK,
	VALIDATE_PAYMENT,
	VALIDATE_SEL_DOCUMENTS
} from '../constants/ActionsTypes'

function* getCollectionsRequest({ payload }) {
	try {
		const collections = yield call(getCollections, payload)
		yield put(getCollectionsSuccess(collections))
	} catch (error) {}
}

function* validatePaymentRequest({ payload }) {
	try {
		const payment = yield call(validatePayment, payload)
		yield put(validatePaymentSuccess(payment))
	} catch (error) {}
}

function* confirmPaymentRequest({ payload }) {
	const { idOperacion, callBackReturn } = payload
	try {
		const paymentConfirm = yield call(confirmPayment, { idOperacion })
		yield put(confirmPaymentSuccess(paymentConfirm))
		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error) {}
}

function* getSelDocumentsRequest({ payload }) {
	try {
		const documents = yield call(getSelDocuments, payload)
		yield put(getSelDocumentsSuccess(documents))
	} catch (error) {}
}

function* getImpDocumentsRequest({ payload }) {
	try {
		const documents = yield call(getImpDocuments, payload)
		yield put(getImpDocumentsSuccess(documents))
	} catch (error) {
		const { Resultado } = error.response.data
		const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
		yield put(getImpDocumentsError({ error: ERROR_GET_IMPDOCS, errorMessage }))
	}
}

function* confirmSelDocumentsRequest({ payload }) {
	try {
		const confirmSel = yield call(confirmSelDocuments, payload)
		yield put(confirmSelDocumentsSuccess(confirmSel))
	} catch (error) {}
}

function* validateSelDocumentsRequest({ payload }) {
	try {
		const validateSel = yield call(validateSelDocuments, payload)
		yield put(validateSelDocumentsSuccess(validateSel))
	} catch (error) {}
}

function* removePaymentRequest({ payload }) {
	try {
		const remove = yield call(removePayment, payload)
		yield put(removePaymentSuccess(remove))
	} catch (error) {}
}

function* searchBankRequest({ payload }) {
	try {
		const banks = yield call(searchBank, payload)
		yield put(searchBankSuccess(banks))
	} catch (error) {}
}

function* collectionImportDocRequest({ payload }) {
	const formData = new FormData()
	formData.append('archivo', payload.archivo)
	formData.append('idOperacion', payload.idOperacion)
	formData.append('nicfondo', payload.nicfondo)
	formData.append('cat_item', payload.cat_item)

	try {
		const docs = yield call(collectionImportDoc, formData)
		yield put(collectionImportDocSuccess(docs))
	} catch (error) {}
}

function* confirmImpDocumentsRequest({ payload }) {
	try {
		const confirm = yield call(confirmImpDocuments, payload)
		yield put(confirImpDocumentsSuccess(confirm))
	} catch (error) {}
}

function* cancelSelDocumentsRequest({ payload }) {
	try {
		const cancel = yield call(cancelSelDocuments, payload)
		yield put(cancelSelDocumentsSuccess(cancel))
	} catch (error) {}
}

export function* getCollectionSaga() {
	yield takeLatest(GET_COLLECTIONS, getCollectionsRequest)
}

export function* validatePaymentSaga() {
	yield takeLatest(VALIDATE_PAYMENT, validatePaymentRequest)
}

export function* confirmPaymentSaga() {
	yield takeLatest(CONFIRM_PAYMENT, confirmPaymentRequest)
}

export function* getSelDocumentsSaga() {
	yield takeLatest(GET_SEL_DOCUMENTS, getSelDocumentsRequest)
}

export function* getImpDocumentsSaga() {
	yield takeLatest(GET_IMP_DOCUMENTS, getImpDocumentsRequest)
}

export function* confirmSelDocumentsSaga() {
	yield takeLatest(CONFIRM_SEL_DOCUMENTS, confirmSelDocumentsRequest)
}

export function* confirmImpDocumentsSaga() {
	yield takeLatest(CONFIRM_IMP_DOCUMENTS, confirmImpDocumentsRequest)
}

export function* validateSelDocumentsSaga() {
	yield takeLatest(VALIDATE_SEL_DOCUMENTS, validateSelDocumentsRequest)
}

export function* removePaymentSaga() {
	yield takeLatest(REMOVE_PAYMENT, removePaymentRequest)
}

export function* searchBankSaga() {
	yield takeLatest(SEARCH_BANK, searchBankRequest)
}

export function* collectionImportDocSaga() {
	yield takeLatest(COLLECTION_IMPORT_DOC, collectionImportDocRequest)
}

export function* cancelSelDocumentsSaga() {
	yield takeLatest(CANCEL_SEL_DOCUMENTS, cancelSelDocumentsRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getCollectionSaga),
		fork(validatePaymentSaga),
		fork(confirmPaymentSaga),
		fork(getSelDocumentsSaga),
		fork(confirmSelDocumentsSaga),
		fork(validateSelDocumentsSaga),
		fork(removePaymentSaga),
		fork(searchBankSaga),
		fork(getImpDocumentsSaga),
		fork(collectionImportDocSaga),
		fork(confirmImpDocumentsSaga),
		fork(cancelSelDocumentsSaga)
	])
}
