import {
	crudGetProfileTabsSuccess,
	crudUpdateProfileTabsSuccess
} from 'actions/ProfileTabsActions'
import { crudGetProfileTabs, crudUpdateProfileTabs } from 'api/ProfileTabsApi'
import {
	CRUD_GET_PROFILE_TABS,
	CRUD_UPDATE_PROFILE_TABS
} from 'constants/ActionsTypes'
import {
	ICrudGetProfileTabsSaga,
	ICrudUpdateProfileTabsSaga
} from 'models/CrudProfilesInterface'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

function* crudGetProfileTabsReq({ payload }: ICrudGetProfileTabsSaga): any {
	try {
		const abms: any = yield call(crudGetProfileTabs, payload)
		yield put(crudGetProfileTabsSuccess(abms))
	} catch (error) {}
}

function* crudUpdateProfileTabsReq({
	payload
}: ICrudUpdateProfileTabsSaga): any {
	try {
		const abms: any = yield call(crudUpdateProfileTabs, payload)
		yield put(crudUpdateProfileTabsSuccess(abms))
	} catch (error) {}
}

export function* crudGetProfileTabsSaga() {
	yield takeLatest(CRUD_GET_PROFILE_TABS, crudGetProfileTabsReq)
}

export function* crudUpdateProfileTabsSaga() {
	yield takeLatest(CRUD_UPDATE_PROFILE_TABS, crudUpdateProfileTabsReq)
}

export default function* rootSaga() {
	yield all([fork(crudGetProfileTabsSaga), fork(crudUpdateProfileTabsSaga)])
}
