import React, { PureComponent } from 'react'
import { Modal } from 'react-bootstrap'
import CollectionImportForm from './collectionImportForm'
import CollectionPaymentForm from './collectionPaymentForm'
import CollectionSearchForm from './collectionSearchForm.js'

export default class ModalComponent extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			showSearch: false,
			showImp: false,
			nicfondo: null,
			cat_item: null
		}
	}

	componentDidMount = () => {
		const { initialValues, accounts } = this.props
		const nicfondo = initialValues ? initialValues.nicfondo : accounts[0].id
		const cat_item = initialValues
			? initialValues.cat_item
			: accounts[0].tipo_mov[0].id
		this.setState({ nicfondo, cat_item })
	}

	handleChangeAccount = ({ nicfondo, cat_item }) => {
		this.setState({ nicfondo, cat_item })
	}

	handleChangeModal = (comp) => {
		let showImp = false
		let showSearch = false

		if (comp === 'impt') {
			showImp = true
		}

		if (comp === 'search') {
			showSearch = true
		}

		this.setState({ showSearch, showImp })
	}

	render() {
		const {
			modalTitle,
			idOperacion,
			configFields,
			showModal,
			handleCloseModal,
			t,
			initialValues,
			accounts,
			handleMoveArrow
		} = this.props
		const { showSearch, showImp, nicfondo, cat_item } = this.state
		let customModalTitle = showSearch
			? t('Collection.form.select_document_title')
			: modalTitle
		customModalTitle = showImp
			? t('Collection.modal.title_import', { payment: modalTitle })
			: modalTitle

		return (
			<Modal
				show={showModal}
				onHide={handleCloseModal}
				aria-labelledby={'ModalHeader'}
				size="xl"
			>
				<Modal.Header closeButton>
					{customModalTitle && <Modal.Title id={'ModalHeader'}></Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					{!showSearch && !showImp && (
						<CollectionPaymentForm
							idOperacion={idOperacion}
							closeWindow={handleCloseModal}
							configFields={configFields}
							accounts={accounts}
							moneyOptions={this.props.moneyOptions}
							initialValues={initialValues}
							changeModal={this.handleChangeModal}
							page={this.props.page}
							page_size={this.props.page_size}
							paymentTotals={this.props.paymentTotals}
							handleChangeAccount={this.handleChangeAccount}
						/>
					)}
					{showSearch && (
						<CollectionSearchForm
							handleMoveArrow={handleMoveArrow}
							idOperacion={idOperacion}
							closeWindow={handleCloseModal}
							changeModal={this.handleChangeModal}
						/>
					)}
					{showImp && (
						<CollectionImportForm
							idOperacion={idOperacion}
							closeWindow={handleCloseModal}
							changeModal={this.handleChangeModal}
							customModalTitle={customModalTitle}
							nicfondo={nicfondo}
							cat_item={cat_item}
						/>
					)}
				</Modal.Body>
			</Modal>
		)
	}
}
