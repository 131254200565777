import {
	crudGetProductBonificationSuccess,
	crudUpdateProductBonificationSuccess
} from 'actions'
import {
	crudGetProductBonification,
	crudUpdateProductBonification
} from 'api/ProductBonificationApi'
import {
	CRUD_GET_PRODUCT_BONIFICATION,
	CRUD_UPDATE_PRODUCT_BONIFICATION
} from 'constants/ActionsTypes'
import {
	ICrudGetProductBonifSaga,
	ICrudUpdateProductBonifSaga
} from 'models/CrudProductsInterface'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* crudGetProductBonificationRequest({
	payload
}: ICrudGetProductBonifSaga): any {
	try {
		const values: any = yield call(crudGetProductBonification, payload)
		yield put(crudGetProductBonificationSuccess(values))
	} catch (error) {}
}

function* crudUpdateProductBonificationRequest({
	payload
}: ICrudUpdateProductBonifSaga): any {
	try {
		const values: any = yield call(crudUpdateProductBonification, payload)
		yield put(crudUpdateProductBonificationSuccess(values))
	} catch (error) {}
}

export function* crudGetProductBonificationSaga() {
	yield takeEvery(
		CRUD_GET_PRODUCT_BONIFICATION,
		crudGetProductBonificationRequest
	)
}

export function* crudUpdateProductBonificationSaga() {
	yield takeEvery(
		CRUD_UPDATE_PRODUCT_BONIFICATION,
		crudUpdateProductBonificationRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetProductBonificationSaga),
		fork(crudUpdateProductBonificationSaga)
	])
}
