import InputDropdown from 'components/form/inputDropdown'
import React, { Component, Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import styles from './filtersTable.module.css'

class FiltersTable extends Component {
	constructor(props) {
		super(props)
		this.optionsInput = [
			{
				id: 0,
				label: 'Todos'
			},
			{
				id: 1,
				label: 'Solo comprob. afectados'
			},
			{
				id: 2,
				label: 'Solo comprob. sin afectar'
			}
		]
	}

	render() {
		const { t, handleGetCant, inputConfig, handleChangeSelect } = this.props

		return (
			<Fragment>
				<Col xs={12} sm={12} md={6} lg={3}>
					<input
						type="checkbox"
						className={'form-check-input ml-4 mt-2'}
						onChange={handleGetCant}
						value="1"
					/>

					<label
						className={'form-check-label ml-5'}
						style={{ fontSize: 14, fontFamily: 'inherit' }}
					>
						{t('voucherInvolvement.form.checkbox')}
					</label>
				</Col>
				<Col xs={12} sm={12} md={6} lg={3} style={{ textAlign: 'center' }}>
					<InputDropdown
						inputFormCol={{ sm: 12 }}
						fields={inputConfig}
						label={t('voucherInvolvement.form.sample')}
						inputId={'checkComprobAvencer'}
						name={'checkComprobAvencer'}
						placeholder={t('client.form.select_sample')}
						styles={{ width: '100%' }}
						styleLabel={{ textAlign: 'right' }}
						colLabel={'col-sm-3'}
						colInput={'col-sm-9'}
						options={this.optionsInput}
						tooltip
						noInitialExecute
						onChange={(data) => {
							handleChangeSelect(data.target.value)
						}}
					/>
				</Col>
			</Fragment>
		)
	}
}

export default themr('FilterTableStyle', styles)(FiltersTable)
