import React, { Component, Fragment } from 'react'
import { Container } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { connect } from 'react-redux'
import Drawer from './drawer'
import MainBar from './navbar'
import styles from './withMenu.module.css'

function withMenu(WrappedComponent) {
	class WithMenu extends Component {
		state = {
			drawer: {
				orientation: 'left',
				state: false
			}
		}

		changeDrawerState = (state) => {
			// const drawer = { ...this.state.drawer }
			const { drawer } = this.state
			drawer.state = state
			this.setState({ drawer })
		}

		render() {
			const { drawer } = this.state
			const { authUser } = this.props
			return (
				<Fragment>
					<Drawer
						orientation={drawer.orientation}
						state={drawer.state}
						drawerState={this.changeDrawerState}
						userCompany={authUser ? authUser.userCompany : 1}
						authUser={authUser}
					/>
					<MainBar openDrawer={this.changeDrawerState} />
					<Container fluid>
						<WrappedComponent {...this.props} />
					</Container>
				</Fragment>
			)
		}
	}
	const mapStateToProps = ({ auth }) => {
		const { authUser } = auth

		return { authUser }
	}

	return connect(mapStateToProps, {})(themr('WithMenuTheme', styles)(WithMenu))
}

export default withMenu
