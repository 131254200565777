import {
	GET_ING_STOCK,
	GET_ING_STOCK_SUCCESS,
	PICKEO_SEARCH_BARCODE,
	PICKEO_SEARCH_BARCODE_ERROR,
	PICKEO_SEARCH_BARCODE_SUCCESS,
	PICK_STOCK_ANMAT,
	PICK_STOCK_ANMAT_CANCEL,
	PICK_STOCK_ANMAT_CANCEL_SUCCESS,
	PICK_STOCK_ANMAT_CONFIRM,
	PICK_STOCK_ANMAT_CONFIRM_SUCCESS,
	PICK_STOCK_ANMAT_SUCCESS,
	PICK_STOCK_CONFIRM,
	PICK_STOCK_CONFIRM_SUCCESS,
	PICK_STOCK_IMPORT,
	PICK_STOCK_IMPORT_CANCEL,
	PICK_STOCK_IMPORT_CANCEL_SUCCESS,
	PICK_STOCK_IMPORT_CONFIRM,
	PICK_STOCK_IMPORT_CONFIRM_ERROR,
	PICK_STOCK_IMPORT_CONFIRM_SUCCESS,
	PICK_STOCK_IMPORT_ERROR,
	PICK_STOCK_IMPORT_SUCCESS,
	PICK_STOCK_TABLE,
	PICK_STOCK_TABLE_SUCCESS
} from 'constants/ActionsTypes'

const initialState = {
	ingrPickeo: null,
	barCodeSearched: null,
	barCodeError: null,
	pickStockImportData: null,
	importConfirm: null,
	importCancel: null,
	anmatConfirm: null,
	anmatCancel: null,
	barCodeParams: null,
	anmatImportData: null,
	pickStockImportError: null,
	importConfirmError: null
}

function rootReducer(state = initialState, action) {
	switch (action.type) {
		case GET_ING_STOCK:
			return { ...state, ingrPickeo: null }
		case GET_ING_STOCK_SUCCESS:
		case PICK_STOCK_IMPORT_CONFIRM_SUCCESS:
		case PICK_STOCK_ANMAT_CONFIRM_SUCCESS:
			return { ...state, ingrPickeo: action.payload }
		case PICKEO_SEARCH_BARCODE:
			return {
				...state,
				barCodeSearched: null,
				barCodeError: null,
				barCodeParams: action.payload
			}
		case PICKEO_SEARCH_BARCODE_SUCCESS:
			return { ...state, barCodeSearched: action.payload }
		case PICKEO_SEARCH_BARCODE_ERROR:
			return { ...state, barCodeError: action.payload }
		case PICK_STOCK_CONFIRM:
			return { ...state, confirmStock: null }
		case PICK_STOCK_CONFIRM_SUCCESS:
			return { ...state, confirmStock: action.payload }
		case PICK_STOCK_IMPORT:
		case PICK_STOCK_TABLE:
			return { ...state, pickStockImportData: null, pickStockImportError: null }
		case PICK_STOCK_TABLE_SUCCESS:
		case PICK_STOCK_IMPORT_SUCCESS:
			return { ...state, pickStockImportData: action.payload }
		case PICK_STOCK_IMPORT_ERROR:
			return { ...state, pickStockImportError: action.payload }
		case PICK_STOCK_IMPORT_CONFIRM:
			return { ...state, importConfirm: null, importConfirmError: null }
		case PICK_STOCK_IMPORT_CONFIRM_ERROR:
			return { ...state, importConfirmError: action.payload }
		case PICK_STOCK_IMPORT_CANCEL:
			return { ...state, importCancel: null }
		case PICK_STOCK_IMPORT_CANCEL_SUCCESS:
			return { ...state, importCancel: action.payload }
		case PICK_STOCK_ANMAT:
			return { ...state, anmatImportData: null }
		case PICK_STOCK_ANMAT_SUCCESS:
			return { ...state, anmatImportData: action.payload }
		case PICK_STOCK_ANMAT_CONFIRM:
			return { ...state, anmatConfirm: null }
		case PICK_STOCK_ANMAT_CANCEL:
			return { ...state, anmatCancel: null }
		case PICK_STOCK_ANMAT_CANCEL_SUCCESS:
			return { ...state, anmatCancel: action.payload }

		default:
			return state
	}
}

export default rootReducer
