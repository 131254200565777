import Steps from 'components/common/steps'
import { getBackNextButtons } from 'lib/BreadCrumbsUtils'
import React, { PureComponent } from 'react'

export default class VoucherBreadCrumbs extends PureComponent {
	getCurrentProccess = () => {
		const { crumbs, current } = this.props
		let result = {}
		crumbs.forEach((crumb) => {
			if (crumb.cod_proceso === current) {
				result = crumb
			}
		})

		return result
	}

	render() {
		const {
			crumbs,
			current,
			completed,
			idOperacion,
			showBreadCrumbs,
			handlePreviousPage
		} = this.props
		let ban = true
		const steps = crumbs.map((crumb, index) => {
			const tmpmain = current === crumb.cod_proceso
			ban = tmpmain ? false : ban
			return {
				...crumb,
				before: ban,
				position: index,
				label: crumb.desc_proceso,
				main: tmpmain
			}
		})

		const [back, next] = getBackNextButtons(crumbs, current, idOperacion)

		if (steps.length) {
			return (
				<Steps
					steps={showBreadCrumbs ? steps : []}
					backButton={back}
					nextButton={next}
					completed={completed}
					callBackButton={this.props.callBackButton}
					buttonsType={this.props.buttonsType}
					showBreadCrumbs={showBreadCrumbs}
					handlePreviousPage={handlePreviousPage}
				/>
			)
		} else {
			return null
		}
	}
}
