import NotificationError from 'components/common/notificationsErrors'
import InputText from 'components/form/inputText'
import { Formik } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { setInputFocus } from 'lib/Utils'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import _ from 'underscore'

export default class BarCodeErrorForm extends Component {
	constructor(props) {
		super(props)
		const { requireFields } = props

		this.loteInputRef = React.createRef()
		this.dateInputRef = React.createRef()
		this.buttonSubmitRef = React.createRef()
		this.serieInputRef = React.createRef()

		this.inputsOrderForm = [
			{
				order: 1,
				inputRef: this.loteInputRef
			},
			{
				order: 2,
				inputRef: this.serieInputRef
			},
			{
				order: 3,
				inputRef: this.dateInputRef
			}
		]

		this.state = {
			configFields: [
				{
					idCampo: 'lote',
					fwRef: this.loteInputRef,
					descripcion: 'Lote',
					label: 'Lote',
					editable: 1,
					visible:
						requireFields && requireFields.ind_lote
							? requireFields.ind_lote
							: 0,
					mascara: '',
					requerido:
						requireFields && requireFields.ind_lote ? requireFields.ind_lote : 0
				},
				{
					idCampo: 'nserie',
					fwRef: this.serieInputRef,
					descripcion: 'Serie',
					label: 'Serie',
					editable: 1,
					visible:
						requireFields && requireFields.ind_nserie
							? requireFields.ind_nserie
							: 0,
					mascara: '',
					requerido:
						requireFields && requireFields.ind_nserie
							? requireFields.ind_nserie
							: 0
				},
				{
					idCampo: 'fec_vto',
					descripcion: 'Fecha Vencimiento',
					label: 'Fecha Vencimiento',
					editable: 1,
					visible:
						requireFields && requireFields.ind_vto ? requireFields.ind_vto : 0,
					valid: 'FEC>=H',
					mascara: 'FechaCorta',
					requerido:
						requireFields && requireFields.ind_vto ? requireFields.ind_vto : 0
				}
			]
		}
	}

	componentDidMount() {
		setTimeout(() => {
			// Timer to set focus in the first field.
			const firstInput = this.getNextInputRef(0)
			setInputFocus(firstInput.inputRef)
		}, 100)
	}

	/**
	 * to get the next input ref
	 * @param {number} position
	 * @returns ref
	 */
	getNextInputRef = (position) => {
		if (position < 3) {
			let nexInputRef
			let nextPosition = position + 1
			do {
				nexInputRef = _.findWhere(this.inputsOrderForm, {
					order: nextPosition
				})
				nextPosition++
			} while (!nexInputRef.inputRef.current)

			return nexInputRef
		}
		return null
	}

	/**
	 * to handle the enter key in the text inputs
	 * @param {*} e
	 * @param {*} position
	 * @returns
	 */
	handleInputEnterKey = (e, position) => {
		const nextInput = this.getNextInputRef(position)
		e.preventDefault()
		setInputFocus(nextInput.inputRef)
		return true
	}

	render() {
		const { t, dataFields } = this.props
		const { configFields } = this.state
		const validationSchema = getValidationSchema(configFields, t, [])
		const initialValues =
			{
				...dataFields,
				fec_vto: dataFields.fec_vto ? moment(dataFields.fec_vto) : ''
			} || getInitValues(configFields)

		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values) => {
					this.props.handleSubmitForm(values)
				}}
				validationSchema={validationSchema}
				enableReinitialize={true}
				render={(props) => {
					const {
						handleSubmit,
						errors,
						touched,
						handleBlur,
						handleChange,
						values,
						setFieldValue
					} = props
					return (
						<Fragment>
							<Col sm={12}>
								<NotificationError errors={errors} touched={touched} />
							</Col>
							<Form onSubmit={handleSubmit} className="voucher-info-form">
								<Row>
									<InputText
										fwRef={this.loteInputRef}
										inputFormCol={{ sm: 12 }}
										fields={configFields}
										label={true}
										inputId={'lote'}
										name={'lote'}
										placeholder={''}
										styles={{ width: '100%', border: '1px solid #ccc' }}
										colLabel={'col-sm-4'}
										colInput={'col-sm-8'}
										value={values.lote}
										onBlur={handleBlur}
										onChange={(data) => {
											setFieldValue('lote', data)
											handleChange()
										}}
										handleEnterKey={(e, value) =>
											this.handleInputEnterKey(e, 1)
										}
									/>
									<InputText
										fwRef={this.serieInputRef}
										inputFormCol={{ sm: 12 }}
										fields={configFields}
										label={true}
										inputId={'nserie'}
										name={'nserie'}
										placeholder={''}
										styles={{ width: '100%', border: '1px solid #ccc' }}
										colLabel={'col-sm-4'}
										colInput={'col-sm-8'}
										value={values.nserie}
										onBlur={handleBlur}
										onChange={(data) => {
											setFieldValue('nserie', data)
											handleChange()
										}}
										handleEnterKey={(e, value) =>
											this.handleInputEnterKey(e, 2)
										}
									/>
									<InputText
										fwRef={this.dateInputRef}
										inputFormCol={{ sm: 12 }}
										fields={configFields}
										label={true}
										inputId={'fec_vto'}
										name={'fec_vto'}
										placeholder={''}
										styles={{ width: '100%', border: '1px solid #ccc' }}
										colLabel={'col-sm-4'}
										colInput={'col-sm-8'}
										value={values.fec_vto}
										onBlur={handleBlur}
										handleEnterKey={(e, value) => {
											e.preventDefault()
											setFieldValue('fec_vto', value || '')
											setInputFocus(this.buttonSubmitRef)
											return true
										}}
										onChange={(data, event) => {
											if (
												event !== undefined &&
												event.nativeEvent.type === 'click'
											) {
												// To validate event click of calendar.
												setFieldValue('fec_vto', data || '')
												setInputFocus(this.buttonSubmitRef)
												handleChange()
											}
										}}
									/>
								</Row>
								<Row className={'pt-3'}>
									<Col sm={6} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px' }}
											type={'submit'}
											ref={this.buttonSubmitRef}
										>
											{t('Collection.form.buttons.accept')}
										</Button>
									</Col>
									<Col sm={6} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px' }}
											type={'button'}
											onClick={this.props.handleCloseModal}
										>
											{t('Collection.form.buttons.cancel')}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					)
				}}
			/>
		)
	}
}
