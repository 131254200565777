import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { salesAffectedSubCalculation, setTableDataInvolvement } from 'actions'
import CollapseButton from 'components/common/collapseButton'
import CommonTable from 'components/common/commonTable'
import NotificationMessage from 'components/common/notificationMessage'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { getSelectFilter, getValueMask } from 'lib/MaskValues'
import { handleIsRendeTableFinish } from 'lib/TableUtils'
import { getFiltersData } from 'lib/Utils'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import ExpandStateForm from './ExpandStateForm'
import styles from './voucherStateTable.module.scss'
class StateTable extends Component {
	constructor(props) {
		super(props)
		this.inputRefs = {}

		this.state = {
			showError: false,
			globalStateAfect: null,
			changedStates: [],
			tableColumns: this.getColumns()
		}

		this.rowErrors = []
		this.firstRefs = null
	}

	componentDidMount = () => {
		this.props.formConfirmation(this.handleConfirmation)
		this.handleConfirmation = this.handleConfirmation.bind(this)
	}

	/**
	 * to call confirmation api and navigate to next page
	 */
	handleConfirmation = () => {
		const { idOperacion, callBackReturn } = this.props
		this.props.handleStateConfirm({
			states: { idOperacion, Items: this.state.changedStates },
			callBackReturn
		})
	}

	/**
	 * to render column table
	 * @param config
	 * @param products
	 */
	getColumns = () => {
		const { config, filtersKey, products, theme } = this.props
		const fieldsTable = getFiltersData(config.campos, {
			agrupador: 'grilla',
			adicional: 0
		})

		const rows = fieldsTable.map((field) => {
			const fieldId = field.idCampo.trim()
			return {
				dataField: fieldId,
				text:
					filtersKey[fieldId] &&
					products[filtersKey[fieldId]].length &&
					products
						? ''
						: field.label === null
						? ''
						: field.label,
				align: 'center',
				headerAlign: 'center',
				// headerStyle: this.getStyleColumn(field),
				hidden: !field.visible,
				filter: getSelectFilter(
					field,
					products,
					filtersKey,
					this.props,
					theme.inputFilter
				),
				formatter:
					field.editable || field.mascara
						? (cell, row, rowIndex) => {
								return this.renderFormat(field, cell, row)
						  }
						: null
			}
		})

		return rows
	}

	/**
	 * to get style for header table
	 * @param field
	 */

	getStyleColumn = (field) => {
		const idField = field.idCampo.trim()

		let style = {}

		switch (idField) {
			case 'cod_unid':
				style = { width: '3%' }
				break
			case 'fec_emis':
			case 'fec_vto':
			case 'cant_pend':
				style = { width: '5%' }
				break
			case 'cod_mone':
			case 'comprob_nro':
				style = { width: '7%' }
				break
			case 'imp_pend':
				style = { width: '9%' }
				break
			case 'estado_afec':
				style = { width: '12%' }
				break
			default:
				style = { width: '10%' }
				break
		}

		if (field.requerido === '1') {
			style.color = 'red'
		}

		return style
	}

	/**
	 * to close error modal
	 */
	handleCloseError = () => {
		this.setState({ showError: false })
	}

	/**
	 * table input dropdown onchange event
	 * @param data
	 * @param row
	 */

	handleChangeSelect = (data, row) => {
		const states = this.state.changedStates
		const selected = data.target.value
		const { idOperacion, pageKeys } = this.props
		const Item = { id: row.id, nitem: row.nitem, estado_afec: selected }
		Item[pageKeys.key] = row[pageKeys.field]
		const Items = [Item] // A array must be sent to the api
		let updateState = false

		states.forEach((state) => {
			if (state.id === row.id) {
				state.estado_afec = selected
				updateState = true // update
			}
		})

		if (!updateState) {
			states.push(Item) // new Item
		}
		this.setState({ changedStates: states })
		this.props.handleStateValidate({ idOperacion, Items })
	}

	/**
	 * to get expand options
	 * @param {*} row
	 * @returns
	 */
	getExpandRow = () => {
		const { hay_adicionales } = this.props.config

		return {
			renderer: (row) => this.renderExpandRow(row),
			showExpandColumn: !!hay_adicionales,
			expandByColumnOnly: true,
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				return <CollapseButton status={isAnyExpands} />
			},
			expandColumnRenderer: ({ expanded, rowKey }) => {
				return <CollapseButton status={expanded} />
			}
		}
	}

	/**
	 * to render html in the table
	 * @param {*} row
	 * @returns
	 */
	renderExpandRow = (row) => {
		const { config } = this.props
		const fields = getFiltersData(config.campos, {
			agrupador: 'grilla',
			adicional: 1
		})
		return !_.isEmpty(fields) ? (
			<ExpandStateForm row={row} fields={fields} />
		) : (
			<div />
		)
	}

	/**
	 * to render select input in the table
	 * @param field
	 * @param value
	 * @param row
	 */
	renderFormat = (field, value, row) => {
		const campoId = field.idCampo.trim()
		let result = null
		const inputError = value === 'error_input'
		const customValue =
			value === 'error_input'
				? ''
				: !Array.isArray(value)
				? value
				: value[0].cod_estado
		const inputStyle = {}

		if (field.editable && !this.inputRefs[campoId]) {
			this.inputRefs[campoId] = {}
		}

		if (field.editable && !this.inputRefs[campoId][row.id]) {
			const customRef = React.createRef()
			this.inputRefs[campoId][row.id] = customRef
			if (this.firstRefs === null) {
				this.firstRefs = customRef
			}
		}

		const optionsInput = {
			fwRef: field.editable ? this.inputRefs[campoId][row.id] : null,
			inputFormCol: { sm: 12 },
			fields: [{ ...field, label: false }],
			label: false,
			inputId: `${campoId}`,
			id: `${campoId}_${row.id}`,
			name: `${campoId}_${row.id}`,
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			divStyle: { paddingLeft: '0px', paddingRight: '0px' },
			disable: false,
			value: customValue,
			showError: inputError,
			styles: inputStyle,
			rowStyle: { marginBottom: '5px' },
			onChange: () => {},
			onBlur: () => {}
		}

		if (campoId === 'estado_afec') {
			const optionsState = Array.isArray(value)
				? value.map((state) => {
						return { id: state.cod_estado, label: state.descrip_estado }
				  })
				: []
			result = (
				<InputDropdown
					{...optionsInput}
					toltip
					options={optionsState}
					value={row.state_afec_selected}
					onChange={(obj) => this.handleChangeSelect(obj, row)}
					noInitialExecute
				/>
			)
		} else if (field.editable) {
			result = <InputText {...optionsInput} />
		} else if (field.mascara) {
			result = getValueMask(value, field.mascara, this.props)
		}

		return result
	}

	handleSetStatus = () => {
		const { products, idOperacion, pageKeys } = this.props
		const Items = Array.isArray(products.Items)
			? products.Items.map((row) => {
					const params = {
						nitem: row.nitem,
						estado_afec: this.state.globalStateAfect
							? this.state.globalStateAfect
							: ''
					}
					params[pageKeys.key] = row[pageKeys.field]
					return params
			  })
			: []
		this.setState({ changedStates: Items })
		this.props.handleStateValidate({ idOperacion, Items })
	}

	/**
	 * rende global input select to change all options.
	 */
	renderGlobalSelect = () => {
		const { t, products } = this.props

		const options = Array.isArray(products.estado_destino)
			? products.estado_destino.map((opt) => {
					return { id: opt.cod_estado, label: opt.descrip_estado }
			  })
			: []
		const inputConfig = [
			{
				idCampo: 'cambiar_masivamente',
				label: false,
				visible: 1,
				requerido: 0,
				editable: 1
			}
		]

		const optionsInput = {
			inputFormCol: { md: 12 },
			fields: inputConfig,
			label: false,
			inputId: 'cambiar_masivamente',
			name: 'cambiar_masivamente',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			divStyle: { paddingLeft: '17px' },
			disable: false,
			rowStyle: { marginBottom: '5px' },
			options: options,
			onChange: (obj) => {
				this.setState({ globalStateAfect: obj.target.value })
			}
		}

		return (
			<Row className={'mt-2'}>
				<Col lg={{ span: 2, offset: 6 }} md={5} className={'text-right pt-1'}>
					{t('voucherState.change_global')}
				</Col>
				<Col lg={3} md={5}>
					<InputDropdown {...optionsInput} />
				</Col>
				<Col>
					<Button
						type={'primary'}
						className={'btn btn-primary'}
						style={{ fontSize: '9pt' }}
						onClick={this.handleSetStatus}
					>
						<FontAwesomeIcon icon={faCheck} />
					</Button>
				</Col>
			</Row>
		)
	}

	/**
	 * to get all pagination options
	 */
	getPaginationOptions = () => {
		const { products, idOperacion } = this.props
		return products
			? {
					pageStartIndex: 1,
					sizePerPage: products.page_size,
					page: products.page_number,
					totalSize: products.total_count,
					onPageChange: (page, sizePerPage) => {
						this.props.handleStateConfirm({
							states: { idOperacion, Items: this.state.changedStates }
						})
					}
			  }
			: {}
	}

	/**
	 * when call pagination event.
	 * @param {string} type
	 * @param {object} pagination
	 */
	handleOnChangeTable = (type, pagination) => {
		this.firstRefs = null
		this.inputRefs = {}
		this.props.handleChangeTable(type, pagination)
	}

	render() {
		const { products, theme, config, productsUpdate } = this.props
		const { tableColumns } = this.state

		const defaultSorted = [
			{
				dataField: 'fec_entrega',
				order: 'desc'
			}
		]

		const rowData = products
			? products.Items.map((prod) => {
					let result = {}
					if (productsUpdate) {
						productsUpdate.forEach((update) => {
							if (update.id === prod.id) {
								result = {
									...update
								}
							} else {
								result = {
									...prod
								}
							}
						})
					} else {
						result = {
							...prod
						}
					}

					return result
			  })
			: null

		return (
			<Fragment>
				<Col sm={12} className={'mb-1'}>
					<NotificationMessage
						{...this.state}
						handleCloseError={this.handleCloseError}
						type={'danger'}
					/>
				</Col>
				<Col sm={12}>{products && this.renderGlobalSelect()}</Col>
				<Col className={`col-12 pr-0 ${theme.divContainer}`}>
					{config && (
						<CommonTable
							remote
							columns={tableColumns}
							data={rowData}
							defaultSorted={defaultSorted}
							rowClasses={theme.tableRow}
							headerClasses={theme.tableHeader}
							paginationOptions={this.getPaginationOptions()}
							onTableChange={this.handleOnChangeTable}
							isRendeTableFinish={() =>
								handleIsRendeTableFinish(this.firstRefs)
							}
							expandRow={this.getExpandRow()}
						/>
					)}
				</Col>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth }) => {
	const { authUser } = auth

	return { authUser }
}

const connectForm = connect(mapStateToProps, {
	setTableDataInvolvement,
	salesAffectedSubCalculation
})(StateTable)

export default themr('StateTableStyles', styles)(withTranslation()(connectForm))
