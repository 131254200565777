import {
	CONFIRMATION_CLIENT,
	CONFIRMATION_CLIENT_SUCCESS,
	GET_CLIENT,
	GET_CLIENT_SUCCESS,
	GET_VOUCHER_TYPE_SUCCESS,
	SEARCH_CLIENTS,
	SEARCH_CLIENTS_SUCCESS,
	VOUCHER_CANCEL_SUCCESS
} from 'constants/ActionsTypes'

const initialState = {
	client: null,
	search: null,
	confirmation: null
}

function rootReducer(state = initialState, action) {
	switch (action.type) {
		case GET_CLIENT:
			return { ...state, client: null }
		case GET_CLIENT_SUCCESS:
			return { ...state, client: action.payload }
		case SEARCH_CLIENTS:
			return { ...state, search: null }
		case SEARCH_CLIENTS_SUCCESS:
			return { ...state, search: action.payload }
		case CONFIRMATION_CLIENT:
			return { ...state, confirmation: null }
		case CONFIRMATION_CLIENT_SUCCESS:
			return { ...state, confirmation: action.payload }
		case GET_VOUCHER_TYPE_SUCCESS:
		case VOUCHER_CANCEL_SUCCESS:
			return { ...initialState }
		default:
			return state
	}
}

export default rootReducer
