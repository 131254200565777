import { automaticPurchaseConfirm } from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { API_FORMAT_DATE } from 'constants/ApiFormats'
import { P_AUTOMATIC_PURCHASE_ORDER } from 'constants/ConfigProcessNames'
import { Formik } from 'formik'
import { getFiltersData } from 'lib/Utils'
import {
	IAutomaticPurchaseConfirm,
	IAutomaticPurchaseConfirmParams
} from 'models/AutomaticPurchase'
import { IConfigFieldsForm } from 'models/Budget'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Col, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import AutomaticPurchaseProviderInputs from './AutomaticPurchaseProviderInputs'

interface IAutomaticPurchaseFiltersProps {
	config: IConfigFieldsForm
	handleFilters: (payload: any) => void
	idOperacion: string
	providerTableRef: any
	automaticPurchaseConfirm: (payload: IAutomaticPurchaseConfirm) => void
	callBackReturn: () => void
}

class AutomaticPurchaseProviders extends Component<IAutomaticPurchaseFiltersProps> {
	handleSubmit = (values: any) => {
		const { idOperacion, callBackReturn } = this.props

		const params: IAutomaticPurchaseConfirmParams = {
			idOperacion,
			cond_pago: values.cond_pago,
			niprov: values.proveedor,
			fec_entrega: values.fec_entrega
				? moment(values.fec_entrega).format(API_FORMAT_DATE)
				: '',
			observ: values.observ
		}
		this.props.automaticPurchaseConfirm({ params, callBackReturn })
	}

	render() {
		const { config, idOperacion, providerTableRef } = this.props
		const initialValues = {}
		const validationSchema = {}
		return (
			<Formik
				ref={providerTableRef}
				initialValues={initialValues}
				onSubmit={(values) => {
					this.handleSubmit(values)
				}}
				validationSchema={validationSchema}
				enableReinitialize={true}
				render={(props) => {
					const {
						values,
						handleChange,
						handleBlur,
						setFieldValue,
						setFieldTouched
					} = props

					return (
						<Fragment>
							<Col sm={12}>
								<NotificationError
									errors={props.errors}
									touched={props.touched}
								/>
								<Form
									onSubmit={(e) => props.handleSubmit(e as any)}
									className="voucher-info-form"
								>
									<AutomaticPurchaseProviderInputs
										fields={getFiltersData(config.campos, {
											agrupador: 'cabecera'
										})}
										{...{
											values,
											handleChange,
											handleBlur,
											setFieldValue,
											setFieldTouched,
											idOperacion
										}}
									/>
								</Form>
							</Col>
						</Fragment>
					)
				}}
			/>
		)
	}
}

const mapStateToProps = ({ voucher, autoGenerate }: any) => {
	const config = voucher.config
		? voucher.config[P_AUTOMATIC_PURCHASE_ORDER]
		: null
	const { generatedStatus, autoConfirm } = autoGenerate

	return { config, generatedStatus, autoConfirm }
}

const mapDispatchToProps = { automaticPurchaseConfirm }

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AutomaticPurchaseProviders)
