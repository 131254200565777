import {
	cancelVoucherNumber,
	changeCollectionTable,
	changeTableAffect,
	changeTableItems,
	exitPrint,
	finishGenerate,
	getClientHeadboard,
	getConfigVoucher,
	getVoucherNumber,
	getVoucherType,
	newVoucherType,
	showMessage,
	voucherCancel,
	voucherCancelSuccess,
	voucherSaveAndNew
} from 'actions'
import CommonModal from 'components/common/commonModal'
import ConfirmModal from 'components/common/ConfirmModal'
import NotificationMessage from 'components/common/notificationMessage'
import InputButton from 'components/form/inputButton'
import LoadItemsTableReadOnly from 'components/loadItems/loadItemsTableReadOnly'
import { P_FINCOMPROB } from 'constants/ConfigProcessNames'
import { getFiltersData } from 'lib/Utils'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { LANDING } from 'utils/RoutePath'
import AffectedVoucher from './affectedVouchers'
import ClienteReadOnly from './clienteReadOnly'
import ComponentCardTable from './componentCardTable'
import styles from './generateForm.module.scss'
import ObservationsForm from './ObservationsForm'
import PrintModal from './printModal'
import UploadFileForm from './UploadFileForm'
import VoucherNumberForm from './VoucherNumberForm'
class GenerateForm extends Component {
	constructor(props) {
		super(props)
		const { t } = props
		this.state = {
			collapseItemTable: false,
			collapse: false,
			generated: false,
			collapseVoucherTable: false,
			collapseVoucherAffectingTable: false,
			collapseVoucherStateTable: false,
			messageNotification: '',
			showPrintModal: false,
			observationsText: '',
			newVoucher: false,
			showNumberModal: false,
			editNumberModal: false
		}

		this.fieldsSeat = [
			{
				dataField: 'cod_cta',
				text: 'Cuenta',
				align: 'center',
				headerAlign: 'center'
			},
			{
				dataField: 'descrip',
				text: 'Descripción',
				align: 'center',
				headerAlign: 'center'
			},
			{
				dataField: 'debe',
				text: 'Debe',
				align: 'center',
				headerAlign: 'center'
			},
			{
				dataField: 'haber',
				text: 'Haber',
				align: 'center',
				headerAlign: 'center'
			}
		]

		this.fieldsImp = [
			{
				dataField: 'cod_imp',
				text: 'Codigo',
				align: 'center',
				headerAlign: 'center'
			},
			{
				dataField: 'descrip',
				text: 'Impuesto',
				align: 'center',
				headerAlign: 'center'
			},
			{
				dataField: 'alicuota',
				text: 'Alicouta',
				align: 'center',
				headerAlign: 'center'
			},
			{
				dataField: 'importe',
				text: 'Importe',
				align: 'center',
				headerAlign: 'center'
			},
			{
				dataField: 'base_calc',
				text: 'Base Cal',
				align: 'center',
				headerAlign: 'center'
			},
			{
				dataField: 'nro_certif',
				text: 'Certificado',
				align: 'center',
				headerAlign: 'center'
			}
		]

		this.fieldsCollections = [
			{
				dataField: 'cuenta',
				text: t('Collection.fields.account'),
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '10%' }
			},
			{
				dataField: 'categ_cuenta',
				text: t('Collection.fields.category'),
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '10%' }
			},
			{
				dataField: 'cat_item',
				text: t('Collection.fields.subcategory'),
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '10%' }
			},
			{
				dataField: 'nro_movim',
				text: t('Collection.fields.nroMove'),
				align: 'center',
				headerStyle: { width: '8%' },
				headerAlign: 'center'
			},
			{
				dataField: 'fecha_emis',
				text: t('Collection.fields.emis_date'),
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '10%' },
				formatter: (cell, row, rowIndex) => {
					return cell ? moment(cell).format('DD/MM/YYYY') : ''
				}
			},
			{
				dataField: 'fecha_cobro',
				text: t('Collection.fields.payment_date'),
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '10%' },
				formatter: (cell, row, rowIndex) => {
					return cell ? moment(cell).format('DD/MM/YYYY') : ''
				}
			},
			{
				dataField: 'chq_bco',
				text: t('Collection.fields.bank'),
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '8%' }
			},
			{
				dataField: 'cod_mone',
				text: t('Collection.fields.currency_code'),
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '5%' }
			},
			{
				dataField: 'importe',
				text: t('Collection.fields.import'),
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '10%' }
			}
		]
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.getClientHeadboard({ idOperacion, page_size: 10 })
	}

	componentDidUpdate = (prevProps) => {
		const {
			voucherSaveParameter,
			idOperacion,
			voucherConfirmation,
			t,
			clientHeadboard,
			voucherNumber
		} = this.props

		this.validateVoucherNumber(prevProps.voucherNumber, voucherNumber)

		if (prevProps.clientHeadboard !== clientHeadboard && clientHeadboard) {
			if (!_.isEmpty(clientHeadboard.observaciones)) {
				const { texto } = clientHeadboard.observaciones
				this.setState({ observationsText: texto })
			}
		}

		if (
			prevProps.generateVoucher !== this.props.generateVoucher &&
			this.props.generateVoucher
		) {
			this.setState({ generated: true })
		}

		if (
			prevProps.voucherConfirmation !== this.props.voucherConfirmation &&
			voucherConfirmation &&
			voucherSaveParameter
		) {
			this.handleCloseNumberModal()
			if (voucherConfirmation.NiMovimiento) {
				this.props.getConfigVoucher({ idOperacion })
				this.setState({
					generated: true,
					messageNotification: t('voucherConfirmation', {
						voucher: voucherConfirmation.NiMovimiento
					})
				})
			}
		}
	}

	/**
	 * to validate response of the api number
	 */
	validateVoucherNumber = (prevVoucherNumber, voucherNumber) => {
		if (
			!_.isEqual(prevVoucherNumber, voucherNumber) &&
			!_.isEmpty(voucherNumber)
		) {
			this.setState({
				voucherNumber: voucherNumber.numero,
				voucherNumberDesc: voucherNumber.descrip_numerador
			})
			if (voucherNumber.carga_manual === 0) {
				this.handleVoucherConfirm()
			} else {
				let editNumberModal = false
				if (voucherNumber.carga_manual === 2) {
					editNumberModal = true
				}
				this.setState({ showNumberModal: true, editNumberModal })
			}
		}
	}

	/**
	 * to close number modal
	 */
	handleCloseNumberModal = () => {
		const { idOperacion } = this.props
		this.props.cancelVoucherNumber({ idOperacion })
		this.setState({ showNumberModal: false })
	}

	/**
	 * to close notification banner
	 */
	handleCloseNotification = () => {
		const { config } = this.props
		if (config.fin_comprobante) {
			this.handleFinishAndRedirect()
		} else {
			this.handleTogglePrintModal()
		}
		this.setState({ generated: false })
	}

	/**
	 * to cancel voucher
	 */
	handleVoucherCancel = () => {
		const { idOperacion } = this.props
		this.props.voucherCancel({ idOperacion })
	}

	/**
	 * click button save and exit, save and continue
	 * @param {*} ban
	 */
	handleSaveVoucher = (ban) => {
		const { idOperacion } = this.props
		this.setState({ newVoucher: ban })
		this.props.getVoucherNumber({ idOperacion })
	}

	/**
	 * to call api confirm
	 */
	handleVoucherConfirm = (params = {}) => {
		const { idOperacion } = this.props
		const { observationsText, newVoucher } = this.state
		this.props.voucherSaveAndNew({
			idOperacion,
			nuevoComprobante: newVoucher,
			observaciones: observationsText,
			...params
		})
	}

	renderGenerateButtons = () => {
		const { t } = this.props
		return (
			<div className="d-flex flex-row-reverse bd-highlight">
				<div className="p-2 bd-highlight">
					<ConfirmModal
						messageBody={t('form.modal.confirmationMessage')}
						onSubmitModal={this.handleVoucherCancel}
						labelButton={t('form.button.cancel')}
						modalTitle={t('form.modal.confirmationTitle')}
					/>
				</div>
				<div className="p-2 bd-highlight">
					<InputButton
						onClick={() => this.handleSaveVoucher(true)}
						valueButton={t('form.button.save_continue')}
					/>
				</div>
				<div className="p-2 bd-highlight">
					<InputButton
						onClick={() => this.handleSaveVoucher(false)}
						valueButton={t('form.button.save_exit')}
					/>
				</div>
			</div>
		)
	}

	handleTogglePrintModal = () => {
		const { idOperacion } = this.props
		if (this.state.showPrintModal) {
			this.props.exitPrint({ idOperacion })
			this.handleFinishAndRedirect()
		}

		this.setState((state) => ({ showPrintModal: !state.showPrintModal }))
	}

	handleFinishAndRedirect = () => {
		const { voucherSaveParameter, config } = this.props
		const urlSubmit = !voucherSaveParameter.nuevoComprobante
			? `${LANDING}/${config.idEmpresa}`
			: null

		if (!urlSubmit) {
			this.props.newVoucherType({
				idComprobante: config.idComprobante,
				idEmpresa: config.idEmpresa,
				history: this.props.history
			})
		} else {
			this.props.history.push(urlSubmit)
		}

		this.props.voucherCancelSuccess()
	}

	handleChangeTableItem = (params) => {
		const { idOperacion } = this.props
		this.props.changeTableItems({ ...params, idOperacion })
	}

	handleChangeTableAffect = (params) => {
		const { idOperacion } = this.props
		this.props.changeTableAffect({ ...params, idOperacion })
	}

	handleChangeCollectionTable = (type, pagination) => {
		const { idOperacion } = this.props
		if (type === 'pagination') {
			this.props.changeCollectionTable({
				idOperacion,
				page_number: pagination.page,
				page_size: pagination.sizePerPage
			})
		}
	}

	/**
	 * to save observation in a state
	 */
	handleUpdateObservations = (text) => {
		this.setState({ observationsText: text })
	}

	/**
	 * submit number form
	 */
	submitNumberForm = (newNumber) => {
		const { editNumberModal } = this.state
		let params = {}
		if (editNumberModal) {
			params = { numero: newNumber }
		}
		this.handleVoucherConfirm(params)
	}

	render() {
		const {
			t,
			config,
			clientHeadboard,
			affecItemsTable,
			collectionItemsTable,
			idOperacion
		} = this.props

		const { observationsText, showNumberModal, editNumberModal } = this.state

		const fieldsNumber = !_.isEmpty(config)
			? getFiltersData(config.campos, {
					agrupador: 'numerador'
			  })
			: []

		return (
			<Col sm={12}>
				<CommonModal
					showModal={showNumberModal}
					handleCloseModal={this.handleCloseNumberModal}
					modalTitle={t('voucher_number.title')}
					sizeModal={'lg'}
					modalBody={
						<VoucherNumberForm
							fields={fieldsNumber}
							readOnly={editNumberModal}
							submitForm={this.submitNumberForm}
						/>
					}
				/>

				<PrintModal
					idOperacion={idOperacion}
					showModal={this.state.showPrintModal}
					toggleModal={this.handleTogglePrintModal}
				/>
				{!this.state.generated && this.renderGenerateButtons()}
				{this.state.generated && config && (
					<Row>
						<Col sm={6}>
							<Modal
								show={this.state.generated}
								onHide={this.handleCloseNotification}
								aria-labelledby={'ModalHeader'}
							>
								<Modal.Body>
									<NotificationMessage
										showError={this.state.generated}
										handleCloseError={this.handleCloseNotification}
										errorMessage={this.state.messageNotification}
									/>
								</Modal.Body>
							</Modal>
						</Col>
					</Row>
				)}
				<ObservationsForm
					clientHeadboard={clientHeadboard || null}
					updateObservations={this.handleUpdateObservations}
					observationValue={observationsText}
				/>
				<ClienteReadOnly
					{...this.props}
					defaultValues={clientHeadboard || null}
				/>

				<LoadItemsTableReadOnly
					{...this.props}
					itemsTable={clientHeadboard ? clientHeadboard.Items : null}
					totalTable={clientHeadboard ? clientHeadboard.Totales : []}
					handleChangeTable={this.handleChangeTableItem}
				/>

				<AffectedVoucher
					{...this.props}
					itemsTable={clientHeadboard ? clientHeadboard.Afectaciones : null}
					handleChangeTable={this.handleChangeTableAffect}
					affecItemsTable={affecItemsTable}
				/>

				<ComponentCardTable
					{...this.props}
					title={t('AccountingSeat.title')}
					dataTable={clientHeadboard ? clientHeadboard.Asiento : null}
					fieldTable={this.fieldsSeat}
					keyField={'nitem'}
					handleOnTableChange={() => {}}
				/>

				<ComponentCardTable
					{...this.props}
					title={t('Taxes.title')}
					dataTable={clientHeadboard ? clientHeadboard.Impuestos : null}
					fieldTable={this.fieldsImp}
					keyField={'nitem'}
					handleOnTableChange={() => {}}
				/>

				<ComponentCardTable
					{...this.props}
					title={t('Collection.title')}
					dataTable={clientHeadboard ? clientHeadboard.Cobranzas : null}
					fieldTable={this.fieldsCollections}
					fieldItems={'ItemsCobranzas'}
					keyField={'nitem'}
					handleOnTableChange={this.handleChangeCollectionTable}
					paginationDataTable={
						collectionItemsTable ? collectionItemsTable.Cobranzas : null
					}
				/>
				<UploadFileForm idOperacion={idOperacion} />

				{!this.state.generated && this.renderGenerateButtons()}
			</Col>
		)
	}
}

const mapStateToProps = ({ generateForm, vouchertype, voucher }) => {
	const {
		clientHeadboard,
		generateVoucher,
		affecItemsTable,
		collectionItemsTable
	} = generateForm
	const config = voucher.config ? voucher.config[P_FINCOMPROB] : null

	const {
		voucherConfirmation,
		voucherSaveParameter,
		newVoucher,
		voucherType,
		voucherNumber
	} = vouchertype
	return {
		clientHeadboard,
		generateVoucher,
		affecItemsTable,
		voucherConfirmation,
		voucherSaveParameter,
		config,
		collectionItemsTable,
		newVoucher,
		voucherType,
		voucherNumber
	}
}

const connectForm = connect(mapStateToProps, {
	getClientHeadboard,
	finishGenerate,
	voucherCancel,
	voucherSaveAndNew,
	showMessage,
	getConfigVoucher,
	changeTableItems,
	changeTableAffect,
	voucherCancelSuccess,
	changeCollectionTable,
	getVoucherType,
	newVoucherType,
	exitPrint,
	getVoucherNumber,
	cancelVoucherNumber
})(withRouter(GenerateForm))

export default themr(
	'GenerateFormStyle',
	styles
)(withTranslation()(connectForm))
