import {
	crudGetFundAccountsCodeConfig,
	crudValidateFundAccountsCode,
	getTableForeignCurrencies,
	getTableFundsAccountsCat,
	getTableIsTaxesIdValid,
	getTableProvince
} from 'actions'
import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import NotificationError from 'components/common/notificationsErrors'
import { FormRefsContext } from 'context/FormRefsContext'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import {
	ICrudCreateFundAccountsCode,
	ICrudCreateFundAccountsCodeRes,
	ICrudFundAccountField,
	ICrudGetFundAccountsCodeConfig,
	ICrudGetFundAccountsCodeConfigRes
} from 'models/CrudFundsAccountsInterface'
import {
	IGetTableForeignCurrenciesResArray,
	IGetTableFundsAccountsCatResArray,
	IGetTableProvinceResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import * as Yup from 'yup'
import CrudFundAccountDataField from './CrudFundAccountDataField'

interface CrudFundAccountDataProps extends WithTranslation {
	crudGetFundAccountsCodeConfig: (
		params: ICrudGetFundAccountsCodeConfig
	) => void
	crudValidateFundAccountsCode: (params: ICrudCreateFundAccountsCode) => void
	getTableProvince: any
	getTableForeignCurrencies: any
	getTableFundsAccountsCat: any
	getTableIsTaxesIdValid: any
	province: IGetTableProvinceResArray
	foreignCurrencies: IGetTableForeignCurrenciesResArray
	fundsAccountsCategory: IGetTableFundsAccountsCatResArray
	isTaxesIdValid: boolean
	disableForm: boolean
	fields: Array<ICrudFundAccountField>
	showMessage: any
	t: any
	handleSubmit: any
	itemInfo?: any
	handleCloseModal: any
	method: string
	showWarning: boolean
	handleWarning: any
	setFormChanged: any
	fundAccountsCodeConfig: ICrudGetFundAccountsCodeConfigRes
	fundAccountsCodeValidated: ICrudCreateFundAccountsCodeRes
}

type CrudFundAccountDataState = {
	showCodePopup: boolean
	values?: any
}

class CrudFundAccountData extends Component<
	CrudFundAccountDataProps,
	CrudFundAccountDataState
> {
	constructor(props: CrudFundAccountDataProps) {
		super(props)
		this.state = {
			showCodePopup: false
		}
	}

	// Initial dropdown setup
	componentDidMount = () => {
		this.props.getTableProvince()
		this.props.getTableForeignCurrencies()
		this.props.getTableFundsAccountsCat()
	}

	componentDidUpdate = (prevProps: CrudFundAccountDataProps) => {
		const { fundAccountsCodeValidated } = this.props
		const { fundAccountsCodeValidated: prevFundAccountsCodeValidated } =
			prevProps
		if (
			fundAccountsCodeValidated &&
			fundAccountsCodeValidated !== prevFundAccountsCodeValidated
		) {
			this.setState({ showCodePopup: false })
		}
	}

	handleCodePopup = (showCodePopup: boolean) => {
		const { fundsAccountsCategory } = this.props

		fundsAccountsCategory && this.setState({ showCodePopup })
	}

	render() {
		const {
			fields,
			t,
			handleSubmit,
			itemInfo,
			disableForm,
			isTaxesIdValid,
			method
		} = this.props

		const { showCodePopup } = this.state

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})

		const columnsCancel = disableForm ? 12 : 6
		const validationSchema = fields.length
			? getValidationSchema(
					fields,
					t,
					[],
					[
						{
							id: 'cuit',
							validation: Yup.string().when(['tipo_resp'], {
								is: (tipo_resp) => tipo_resp !== 'CF',
								then: Yup.string().test(
									'ValidCuit',
									t('validation-invalid', { field: 'CUIT' }),
									(cuit: any) => {
										return isTaxesIdValid === true
									}
								)
							})
						}
					]
			  )
			: {}
		const subCategories: Array<string> = []

		_.each(
			fields,
			(
				field: ICrudFundAccountField,
				index: number,
				array: Array<ICrudFundAccountField>
			) => {
				if (array[index - 1]) {
					if (field.agrupador !== array[index - 1].agrupador) {
						subCategories.push(field.agrupador)
					}
				} else {
					subCategories.push(field.agrupador)
				}
			}
		)

		const allFields = _.map(subCategories, (category: string) => {
			const id = category
			const filterFields = fields.filter((field: ICrudFundAccountField) => {
				return field.agrupador === category
			})
			return { name: id, fields: filterFields }
		})

		return (
			<FormRefsContext.Consumer>
				{({ formRef, cancelButtonRef }) => (
					<Fragment>
						<Formik
							ref={formRef}
							initialValues={initialValues}
							onSubmit={(values, actions) => {
								handleSubmit(values)
							}}
							enableReinitialize={true}
							validationSchema={validationSchema}
						>
							{(props: FormikProps<any>) => (
								<Fragment>
									<Col sm={12}>
										<NotificationError
											errors={props.errors}
											touched={props.touched}
										/>
									</Col>
									<Form
										onChange={(e) => {
											this.props.setFormChanged(true)
										}}
									>
										{_.map(
											allFields,
											(category: {
												name: string
												fields: Array<ICrudFundAccountField>
											}) => {
												return (
													<Fragment key={category.name}>
														<Alert variant={'dark'}>
															<b>
																{t(
																	'CRUDS.general_use.' +
																		category.name.replace('.', '')
																)}
															</b>
														</Alert>
														{(method === 'add' ||
															((method === 'edit' || method === 'view') &&
																itemInfo)) &&
															_.map(
																category.fields,
																(field: ICrudFundAccountField) => {
																	return (
																		<CrudFundAccountDataField
																			key={field.idCampo}
																			formikProps={props}
																			field={field}
																			showCodePopup={showCodePopup}
																			handleCodePopup={this.handleCodePopup}
																			crudProps={this.props}
																			initialValue={
																				initialValues[
																					field.idCampo as keyof typeof initialValues
																				]
																			}
																		/>
																	)
																}
															)}
													</Fragment>
												)
											}
										)}
										<Row className={'pt-3'}>
											{!disableForm && (
												<Col sm={6} className={'text-center mt-2'}>
													<Button style={{ minWidth: '145px' }} type={'submit'}>
														{t('Collection.form.buttons.accept')}
													</Button>
												</Col>
											)}
											<Col sm={columnsCancel} className={'text-center mt-2'}>
												<Button
													style={{ minWidth: '145px' }}
													type={'button'}
													onClick={this.props.handleCloseModal}
													ref={cancelButtonRef}
												>
													{t('Collection.form.buttons.cancel')}
												</Button>
											</Col>
										</Row>
										<ConfirmModalNoButton
											modalTitle={t('CRUDS.modal.title_warning')}
											messageBody={t('CRUDS.modal.message_changes')}
											showModal={this.props.showWarning}
											handleCloseModal={() =>
												this.props.handleWarning(false, 'close')
											}
											handleCancelButton={() => {
												this.props.handleWarning(false, 'cancel')
											}}
											handleSubmitButton={(e: any) => {
												props.handleSubmit(e as any)
												this.props.handleWarning(false, 'submit')
											}}
										/>
									</Form>
								</Fragment>
							)}
						</Formik>
					</Fragment>
				)}
			</FormRefsContext.Consumer>
		)
	}
}

const mapStateToProps = ({
	tableCalls,
	validationResponses,
	fundAccountsCode
}: any) => {
	const { province, foreignCurrencies, fundsAccountsCategory } = tableCalls
	const { isTaxesIdValid } = validationResponses
	const { fundAccountsCodeConfig, fundAccountsCodeValidated } = fundAccountsCode
	return {
		fundAccountsCodeConfig,
		fundAccountsCodeValidated,
		province,
		foreignCurrencies,
		fundsAccountsCategory,
		isTaxesIdValid
	}
}

const connectForm = connect(mapStateToProps, {
	getTableFundsAccountsCat,
	getTableForeignCurrencies,
	getTableProvince,
	getTableIsTaxesIdValid,
	crudGetFundAccountsCodeConfig,
	crudValidateFundAccountsCode
})(CrudFundAccountData)

export default withTranslation()(withRouter(connectForm))
