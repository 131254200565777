import {
	clearTableValidations,
	crudsClearStore,
	crudsCreate,
	crudsGetSingle,
	showMessage
} from 'actions'
import CommonTabs from 'components/abmUtils/CommonTabs'
import withMenu from 'components/common/withMenu'
import CrudsContainer from 'components/layout/CrudsContainer'
import {
	IAbmStructureResponse,
	IFieldStructure
} from 'constants/valuesInterfaces/interfaces'
import { ICrudsApi, ICrudsTypeList } from 'models/CrudInterface'
import {
	ICrudGetPricesListRes,
	ICrudUpdatePricesListsRes
} from 'models/CrudPricesListInterfase'
import { Ihistory } from 'models/NavigationInterface'
import React, { Component } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { CRUD, CRUD_EDIT, CRUD_PRICES_LIST } from 'utils/RoutePath'
import CrudPricesList from './CrudPricesList'

export interface ISubGroup {
	groupId: string
	accessRight: string
}

export interface ICrudPricesListTabsDataContentProps extends WithTranslation {
	crudCode: number
	op: string
	history: Ihistory
	selectedAbm: ICrudsTypeList
	valueDataSelected: ICrudGetPricesListRes
	valueDataUpdated: ICrudUpdatePricesListsRes
	crudsGetSingle: (params: ICrudsApi) => void
	crudsCreate: (params: ICrudsApi) => void
	crudsClearStore: () => void
	fieldsAltaConfig: IAbmStructureResponse
	fieldsConfig: IAbmStructureResponse
	disableForm: boolean
	showMessage: any
	t: any
}

export interface ICrudPricesListState {
	info: boolean
	tabActive: string
	showWarningPopup: boolean
	formChanged: boolean
	nextTab: any
	nextAction: string
}

const pricesTabs = [
	{
		key: 'basic',
		label: 'CRUDS.general_use.basic_tab',
		type: 'basic'
	},
	{
		key: 'prices',
		label: 'CRUDS.general_use.prices_tab',
		type: 'prices'
	}
]

class crudPricesListTabsDataContent extends Component<
	ICrudPricesListTabsDataContentProps,
	ICrudPricesListState
> {
	constructor(props: ICrudPricesListTabsDataContentProps) {
		super(props)
		this.state = {
			info: false,
			tabActive: 'basic',
			showWarningPopup: false,
			formChanged: false,
			nextTab: 'basic',
			nextAction: ''
		}
	}

	componentDidUpdate = (prevProps: ICrudPricesListTabsDataContentProps) => {
		const {
			crudCode,
			crudsGetSingle,
			selectedAbm,
			valueDataUpdated,
			valueDataSelected,
			op
		} = this.props
		const {
			valueDataUpdated: prevValueDataUpdated,
			valueDataSelected: prevValueDataSelected
		} = prevProps

		if (prevValueDataSelected !== valueDataSelected && valueDataSelected) {
			let info = false
			if (op === 'view' || op === 'edit') {
				info = true
			} else if (op === 'add') {
				info = false
			}

			this.setState({ info })
		}

		if (valueDataUpdated !== prevValueDataUpdated && valueDataUpdated) {
			crudsGetSingle({
				crudName: selectedAbm.id,
				urlApi: selectedAbm.urlApi,
				action: 'consulta',
				params: { [selectedAbm.key]: crudCode }
			})
			this.setState({ formChanged: false })
		}
	}

	// Cancel button function
	handleRedirectHome = () => {
		this.props.history.push(`${CRUD}/${CRUD_PRICES_LIST}`)
	}

	/**
	 * to call api to create new abm
	 * @param {object} value
	 */

	handleNewCrud = (values: any) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 1 }
		})
	}

	/**
	 * to call api to edit abm
	 * @param {object} value
	 */

	handleEditCrud = (values: any) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 0 }
		})
	}

	// Set's current tab in state
	handleActiveKey = (tabActive: string) => {
		this.setState({ tabActive })
	}

	// Handles the Crud change arrows
	handleCrudChange = (param: any) => {
		const { history } = this.props
		history.go(`${CRUD_EDIT}/${CRUD_PRICES_LIST}/${param.cod_preclis}`)
	}

	// Handles the changes warning state
	handleWarningPopup = (value: boolean, action: string) => {
		const { tabActive } = this.state
		this.setState({ showWarningPopup: value })
		action === 'close' && this.setState({ nextTab: tabActive, nextAction: '' })
		action === 'cancel' && this.setState({ formChanged: false })
	}

	// Handles the changes state, used in detecting changes
	handleSetFormChanged = (formChanged: boolean) => {
		this.setState({ formChanged })
	}

	// Stores the value of the clicked tab in the component state
	handleSetNextTab = (nextTab: string) => {
		this.setState({ nextTab })
	}

	// Stores the value of the clicked arrow in the component state
	handleSetNextAction = (nextAction: string) => {
		this.setState({ nextAction })
	}

	render() {
		const {
			t,
			fieldsAltaConfig,
			valueDataSelected,
			disableForm,
			op,
			crudCode,
			fieldsConfig,
			selectedAbm
		} = this.props

		const { tabActive, showWarningPopup, formChanged, nextTab, nextAction } =
			this.state

		const subGroups: Array<ISubGroup> = []

		const fields = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('principal')
			  })
			: []

		_.each(
			fields,
			(
				field: IFieldStructure,
				index: number,
				array: Array<IFieldStructure>
			) => {
				if (array[index - 1]) {
					if (field.agrupador !== array[index - 1].agrupador) {
						const groupId = field.agrupador
						const accessRight = fieldsConfig.tabs.find(
							(tab) => tab.cod_tab === groupId
						)?.tipo_perm
						subGroups.push({
							groupId: groupId,
							accessRight: accessRight || 'E'
						})
					}
				} else {
					subGroups.push({ groupId: field.agrupador, accessRight: 'E' })
				}
			}
		)
		const observationsFields = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.observ')
			  })
			: []

		const tabProps = {
			basic: {
				disableForm: disableForm,
				itemInfo: valueDataSelected,
				fields: fields,
				showMessage: this.props.showMessage,
				handleSubmit: op === 'add' ? this.handleNewCrud : this.handleEditCrud,
				method: op,
				handleCloseModal: this.handleRedirectHome,
				showWarning: showWarningPopup,
				handleWarning: this.handleWarningPopup,
				setFormChanged: this.handleSetFormChanged,
				subGroups: subGroups
			},
			obs: {
				niclie: crudCode,
				fields: observationsFields,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'observ')
						?.tipo_perm === 'L' ||
					false,
				handleWarning: this.handleWarningPopup,
				showWarning: showWarningPopup,
				setFormChanged: this.handleSetFormChanged
			}
		}
		const propsCrudContainer = {
			urlApi: 'abm_inv_lprec',
			getNewData: this.handleCrudChange,
			keyApi: 'cod_preclis',
			title: t('global.price_list'),
			handleExitAbm: this.handleRedirectHome,
			showWarning: formChanged,
			handleWarning: this.handleWarningPopup,
			nextAction: nextAction,
			setNextAction: this.handleSetNextAction,
			op: op
		}

		const componentTabs = Array.from(pricesTabs).map((tab) => {
			const selectedTab: any =
				tab.type === 'basic'
					? {
							key: tab.key,
							title: t(tab.label),
							content:
								tabActive === tab.key ? (
									<CrudPricesList {...tabProps.basic} />
								) : (
									<div />
								)
					  }
					: tab.type === 'obs' && {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content: tabActive === tab.key ? <div /> : <div />
					  }
			return selectedTab
		})

		return (
			<CrudsContainer {...propsCrudContainer}>
				<Row className={'pt-4'}>
					<Col>
						<Card.Title>
							{disableForm
								? t('prices_list_data.title_view')
								: op === 'edit'
								? t('prices_list_data.title_edit')
								: t('prices_list_data.title_new')}
						</Card.Title>
					</Col>
				</Row>
				{valueDataSelected && (
					<>
						<Row>
							<Col sm={2}>
								<strong>{t('global.internal_code')}</strong>
							</Col>
							<Col className={'text-left'}>
								{valueDataSelected && valueDataSelected.cod_preclis}
							</Col>
						</Row>
						<Row className={'pb-2'}>
							<Col sm={2}>
								<strong>{t('global.name')}</strong>
							</Col>
							<Col className={'text-left'}>
								{valueDataSelected && valueDataSelected.descrip}
							</Col>
						</Row>
					</>
				)}
				<CommonTabs
					customTabs={componentTabs}
					defaultActiveKey={tabActive}
					getActiveKey={this.handleActiveKey}
					showWarning={formChanged}
					handleWarning={this.handleWarningPopup}
					nextTab={nextTab}
					setNextTab={this.handleSetNextTab}
				/>
			</CrudsContainer>
		)
	}
}

const mapStateToProps = ({ configCrud }: any) => {
	const {
		fieldsConfig,
		fieldsAltaConfig,
		valueDataSelected,
		valueDataUpdated
	} = configCrud
	return {
		fieldsConfig,
		fieldsAltaConfig,
		valueDataSelected,
		valueDataUpdated
	}
}

const connectForm = connect(mapStateToProps, {
	crudsGetSingle,
	crudsCreate,
	crudsClearStore,
	showMessage,
	clearTableValidations
})(crudPricesListTabsDataContent)

export default withTranslation()(withMenu(withRouter(connectForm)))
