import withMenu from 'components/common/withMenu'
import GlobalContainer from 'components/layout/globalContainer'
import LoadPackagesComponent from 'components/LoadPackages/LoadPackagesComponent'
import { P_LOAD_PACKAGES_DISPATCH } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'

type Props = {}

type State = {}

class LoadPackages extends Component<Props, State> {
	myRef: any = React.createRef()
	refPackageComponent: any = React.createRef()
	handleCallBackButton = () => {
		if (this.myRef.submitForm) {
			const { values } = this.myRef.state
			this.refPackageComponent.current.handleSubmitForm(values)
			// this.myRef.handleSubmit()
		}
	}

	render() {
		const containerProps = {
			codeProcess: P_LOAD_PACKAGES_DISPATCH,
			callBackButton: this.handleCallBackButton,
			nextPage: true
		}

		const propsComponent = {
			loadPackageFormRef: (ref: any) => (this.myRef = ref),
			ref: this.refPackageComponent
		}

		return (
			<Row>
				<GlobalContainer {...containerProps}>
					<LoadPackagesComponent {...propsComponent} />
				</GlobalContainer>
			</Row>
		)
	}
}

export default withMenu(LoadPackages)
