import { finalConsumerConfirmSuccess } from 'actions'
import { finalConsumerConfirmApi } from 'api/FinalConsumer'
import { FINAL_CONSUMER_CONFIRM } from 'constants/ActionsTypes'
import { IFinalConsumerConfirmAction } from 'models/FinalConsumer'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* finalConsumerConfirmRequest({
	payload
}: IFinalConsumerConfirmAction): any {
	const { values, callBackReturn } = payload
	try {
		const response: any = yield call(finalConsumerConfirmApi, values)

		if (callBackReturn) {
			callBackReturn()
		}
		yield put(finalConsumerConfirmSuccess(response))
	} catch (error: any) {}
}

export function* finalConsumerConfirmSaga() {
	yield takeEvery(FINAL_CONSUMER_CONFIRM, finalConsumerConfirmRequest)
}

export default function* rootSaga() {
	yield all([fork(finalConsumerConfirmSaga)])
}
