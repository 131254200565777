import {
	salesAffectedCant,
	salesAffectedConfirm,
	salesAffectedSubCalculation,
	salesAffectedValidate,
	validateSalesAffectedPrice
} from 'actions'
import FiltersTable from 'components/common/filtersTable'
import StockPopup from 'components/Stock/stockPopup'
import { P_AFEC_CANT_VTA } from 'constants/ConfigProcessNames'
import React, { Fragment, PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import InvolvementTable from './involvementTable'
import styles from './involvementTable.module.scss'
import InvolvementTotalResume from './involvementTotalResume'

class VoucherInvolvementTable extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			total_item: 0,
			total_cant: 0,
			total_importe: 0,
			ComprobAvencer: 0,
			OpcionMuestra: 0,
			showStock: false,
			referenceItem: null,
			params: {},
			queryFilters: {}
		}

		this.filtersKey = {
			cant_saldo: 'filtro_cant_pend',
			Cod_prod: 'filtro_cod_prod',
			Cod_unid: 'filtro_cod_unid',
			Cod_barra: 'filtro_codbarra',
			Comprob_desc: 'filtro_comprob_desc',
			Comprob_nro: 'filtro_comprob_nro',
			Desc_prod: 'filtro_desc_prod',
			Fec_emis: 'filtro_fec_emis',
			Fec_vto: 'filtro_fec_vto',
			nimovpro: 'filtro_niprod',
			pcio_unit: 'filtro_precio_unit',
			Presentacion: 'filtro_presentacion'
		}
	}

	componentDidMount = () => {
		const { ComprobAvencer, OpcionMuestra } = this.state
		const { idOperacion } = this.props

		if (idOperacion) {
			this.props.salesAffectedCant({
				ComprobAvencer,
				OpcionMuestra,
				idOperacion,
				page_number: 1,
				page_size: 10
			})
		}
	}

	componentDidUpdate = (prevProps) => {
		const {
			salesDiscount,
			cantValidate,
			salesconfirm,
			productsInvol,
			paramsValidate,
			canceledStock,
			salesPrice
		} = this.props

		/** when change price */
		this.handleSetTotals(prevProps.salesPrice, salesPrice)

		/** when change data table init and pagination */
		this.handleSetTotals(prevProps.productsInvol, productsInvol)

		/** when change cant */
		this.handleSetTotals(prevProps.cantValidate, cantValidate)

		/** when use confirm */
		this.handleSetTotals(prevProps.salesconfirm, salesconfirm)

		/** when apply discount */
		this.handleSetTotals(prevProps.salesDiscount, salesDiscount)

		if (cantValidate !== prevProps.cantValidate && cantValidate) {
			if (cantValidate.Items[0].ind_stock === 1) {
				this.setState({
					showStock: true,
					referenceItem: cantValidate.Items[0].it,
					params: {
						Niprod: paramsValidate.items[0].niprod,
						cantidad: cantValidate.Items[0].Cant_afec
					}
				})
			}
		}

		if (canceledStock !== prevProps.canceledStock && canceledStock) {
			const { ComprobAvencer, OpcionMuestra, queryFilters } = this.state
			const { idOperacion } = this.props
			this.props.salesAffectedCant({
				ComprobAvencer,
				OpcionMuestra,
				idOperacion,
				page_number: 1,
				page_size: 10,
				...queryFilters
			})
		}
	}

	handleSetTotals = (prevTotals, totals) => {
		if (!_.isEqual(prevTotals, totals) && !_.isEmpty(totals)) {
			this.setState({
				total_item: totals.total_item,
				total_cant: totals.total_cant,
				total_importe: totals.total_importe
			})
		}
	}

	onChangeTable = (type, pagination) => {
		const { idOperacion } = this.props
		const queryFilters = {}
		if (type === 'filter') {
			const filters = _.keys(pagination.filters)
			filters.forEach((filter) => {
				queryFilters[this.filtersKey[filter]] =
					pagination.filters[filter].filterVal
			})
			this.setState({ queryFilters })
		}

		this.props.salesAffectedCant({
			idOperacion,
			page_number: pagination.page,
			page_size: pagination.sizePerPage,
			...queryFilters
		})
	}

	handleGetCant = (e) => {
		const comprobante = e.target.checked ? 1 : 0
		const { OpcionMuestra } = this.state
		const { idOperacion } = this.props

		this.setState({ ComprobAvencer: comprobante })
		this.props.salesAffectedCant({
			ComprobAvencer: comprobante,
			OpcionMuestra,
			idOperacion,
			page_number: 1,
			page_size: 10
		})
	}

	handleChangeSelect = (value) => {
		const { ComprobAvencer } = this.state
		const { idOperacion } = this.props

		this.setState({ OpcionMuestra: value })
		this.props.salesAffectedCant({
			ComprobAvencer,
			OpcionMuestra: value,
			idOperacion,
			page_number: 1,
			page_size: 10
		})
	}

	handleSubCalculation = ({ row, value }) => {
		const { idOperacion } = this.props
		const params = {
			idOperacion,
			Nimovcli: row.nimovcli,
			Nitem: row.nitem,
			Cant_afec: row.Cant_afec,
			Neto: row.neto,
			precio_unit: value
		}
		this.props.salesAffectedSubCalculation({ idOperacion, ...params })
	}

	/**
	 * to calc new values after edit price
	 * @param {*} row
	 * @param {*} field
	 * @param {*} value
	 */
	validateFieldPrice = (row, field, value) => {
		const { idOperacion } = this.props

		this.props.validateSalesAffectedPrice({
			idOperacion,
			nimovcli: row.nimovcli,
			nitem: row.nitem,
			precio: value,
			cant_afec: row.Cant_afec
		})
	}

	/**
	 * to close stock popup
	 */
	handleCloseStock = () => {
		this.setState({ showStock: false })
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	render() {
		const {
			t,
			productsInvol,
			readOnly,
			config,
			productsUpdate,
			cantValidate,
			paramsValidate,
			idOperacion,
			canceledStock,
			callBackReturn
		} = this.props
		const { params, showStock, referenceItem } = this.state

		const inputConfig = [
			{
				idCampo: 'checkComprobAvencer',
				label: t('voucherInvolvement.form.sample'),
				visible: 1,
				requerido: 0,
				editable: 1
			}
		]
		const customCol = readOnly ? 4 : { span: 5, offset: 7 }

		return (
			<Fragment>
				<Row style={{ marginLeft: '0px' }}>
					{!readOnly && (
						<>
							<FiltersTable
								handleGetCant={this.handleGetCant}
								t={t}
								inputConfig={inputConfig}
								handleChangeSelect={(value) => this.handleChangeSelect(value)}
							/>
							<Col lg={3} md={6} className={'mb-2'}>
								{productsInvol && (
									<InvolvementTotalResume
										classDiv={'pl-5'}
										formatCol={{ span: 10, offset: 3 }}
										data={this.state}
									/>
								)}
							</Col>
						</>
					)}
					{readOnly && (
						<Col sm={12}>
							<InvolvementTotalResume
								formatCol={{ span: 4 }}
								data={this.state}
							/>
						</Col>
					)}
				</Row>
				<Col lg={12} md={10} className={'mb-1'}>
					<StockPopup
						params={params}
						showStock={showStock}
						idOperacion={idOperacion}
						referenceItem={referenceItem}
						handleClose={this.handleCloseStock}
						handleCloseCartMessage={this.handleCloseError}
						handleMoveArrow={this.props.handleMoveArrow}
					/>
				</Col>
				<Col sm={12} className={`pb-2 pl-0`}>
					{!_.isEmpty(config) && (
						<InvolvementTable
							filtersKey={this.filtersKey}
							keysValidate={{ label: 'Nimovcli', field: 'nimovcli' }}
							productsUpdate={productsUpdate}
							cantValidate={cantValidate}
							paramsValidate={paramsValidate}
							config={config}
							products={productsInvol}
							readOnly={readOnly}
							idOperacion={idOperacion}
							handleChangeTable={this.onChangeTable}
							handleMoveArrow={this.props.handleMoveArrow}
							formConfirmation={this.props.formConfirmation}
							handleCantValidate={this.props.salesAffectedValidate}
							handleAffectedConfirm={this.props.salesAffectedConfirm}
							handleAffectedSubCalculation={
								this.props.salesAffectedSubCalculation
							}
							validateFieldPrice={this.validateFieldPrice}
							canceledStock={canceledStock}
							callBackReturn={callBackReturn}
						/>
					)}

					{productsInvol && (
						<InvolvementTotalResume
							classDiv={'pl-0'}
							formatCol={customCol}
							data={this.state}
						/>
					)}
				</Col>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ vouchertype, salesAffected, voucher, stock }) => {
	const { voucherType } = vouchertype
	const config = voucher.config ? voucher.config[P_AFEC_CANT_VTA] : null
	const { canceledStock } = stock
	const {
		productsInvol,
		cantValidate,
		subCalculations,
		salesconfirm,
		paramsProductsInvol,
		paramsValidate,
		productsUpdate,
		salesPrice,
		priceValidate,
		salesDiscount
	} = salesAffected

	return {
		voucherType,
		productsInvol,
		cantValidate,
		subCalculations,
		salesconfirm,
		paramsProductsInvol,
		paramsValidate,
		productsUpdate,
		config,
		canceledStock,
		salesPrice,
		priceValidate,
		salesDiscount
	}
}

const formConnect = connect(mapStateToProps, {
	salesAffectedCant,
	salesAffectedSubCalculation,
	salesAffectedValidate,
	salesAffectedConfirm,
	validateSalesAffectedPrice
})(withTranslation()(VoucherInvolvementTable))

export default themr('VoucherInvolvementTableStyles', styles)(formConnect)
