import {
	CHANGE_PASSWORD,
	CHANGE_PASSWORD_ERROR,
	CHANGE_PASSWORD_SUCCESS,
	GET_PERMISSIONS_SUCCESS,
	GET_USER_SUCCESS,
	SELECT_COMPANY,
	SELECT_COMPANY_SUCCESS,
	SET_COMPANY_ERROR,
	SIGNIN_USER_SUCCESS,
	SIGNOUT_USER_SUCCESS
} from 'constants/ActionsTypes'

const initialState = {
	authUser:
		typeof window !== 'undefined'
			? window.localStorage.getItem('user')
				? JSON.parse(window.localStorage.getItem('user'))
				: undefined
			: undefined,
	companyChanged: false,
	showCompanyModal: false,
	changePasswordError: null,
	changePasswordSuccess: null
}

function rootReducer(state = initialState, action) {
	let authUser
	switch (action.type) {
		case SIGNIN_USER_SUCCESS:
			return { ...state, authUser: action.payload }
		case SIGNOUT_USER_SUCCESS:
			return { state: undefined }
		case GET_USER_SUCCESS:
			return { ...state, authUser: action.payload }
		case GET_PERMISSIONS_SUCCESS:
			return { ...state, permissions: action.payload }
		case SELECT_COMPANY:
			return { ...state, companyChanged: false }
		case SELECT_COMPANY_SUCCESS:
			authUser = action.payload.authUser
			return { ...state, authUser, companyChanged: true }
		case SET_COMPANY_ERROR:
			return { ...state, showCompanyModal: action.payload }
		case CHANGE_PASSWORD:
			return {
				...state,
				changePasswordSuccess: null,
				changePasswordError: null
			}
		case CHANGE_PASSWORD_SUCCESS:
			return { ...state, changePasswordSuccess: action.payload }
		case CHANGE_PASSWORD_ERROR:
			return { ...state, changePasswordError: action.payload }
		default:
			return state
	}
}

export default rootReducer
