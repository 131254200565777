import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { accountConfirm, accountValidate, removePayment } from 'actions'
import CommonTable from 'components/common/commonTable'
import ConfirmModal from 'components/common/ConfirmModal'
import { P_LOAD_COLLECTION } from 'constants/ConfigProcessNames'
import { getValueMask } from 'lib/MaskValues'
import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import styles from './collectionsTable.module.scss'
import ModalComponent from './modalComponent'

class CollectionsTable extends PureComponent {
	constructor(props) {
		super(props)
		this.inputRefs = {}
		this.state = {
			itemsTable: this.props.dataTable ? this.props.dataTable.items_cobro : [],
			tableColumns: null
		}
		this.rowErrors = []
		this.primaryKey = 'nitem'
		this.paymentMethods = []
		this.paymentMethods.Otros = '_OTROS'
		this.paymentMethods.Caja = '_CAJA'
		this.paymentMethods.Cheques = '_DOCT'
		this.paymentMethods.Bancos = '_BCO'
	}

	componentDidMount = () => {
		const { config } = this.props
		if (config) {
			const tableColumns = this.getColumns() // Create columns table
			this.setState({ tableColumns })
		}
	}

	componentDidUpdate = (prevProps) => {
		const { config } = this.props

		if (config !== prevProps.config && config) {
			const tableColumns = this.getColumns() // Create columns table
			this.setState({ tableColumns })
		}
	}

	/**
	 * to update data from table
	 */

	static getDerivedStateFromProps(props, state) {
		const { dataTable } = props
		if (dataTable.items_cobro) {
			return {
				itemsTable: dataTable.items_cobro
			}
		}

		return null
	}

	renderFormat = (field, value) => {
		let result = value

		if (field.mascara && value) {
			result = getValueMask(value, field.mascara, this.props)
		}

		return result
	}

	getColumns = () => {
		const { config, t } = this.props
		const rows = config.campos.map((field) => {
			const campoId = field.idCampo.trim()
			return {
				dataField: campoId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: this.getStyleColumn(field),
				hidden:
					!field.visible || campoId === 'editar' || campoId === 'eliminar',
				formatter: (cell, row, rowIndex) => {
					return this.renderFormat(field, cell)
				}
			}
		})

		rows.push({
			dataField: 'error',
			text: '',
			align: 'center',
			headerAlign: 'center',
			headerStyle: { width: '5%', textAlign: 'center' },
			formatter: (cell, row) => {
				return (
					<Row>
						{row.reg_edit === 1 && (
							<Col sm={6}>
								<FontAwesomeIcon
									style={{
										marginTop: '6px',
										cursor: 'pointer',
										color: '007bff'
									}}
									icon={faPencilAlt}
									onClick={() => this.handleEditCell(row)}
								/>
							</Col>
						)}
						<Col sm={6} className={'pl-0'}>
							<ConfirmModal
								messageBody={t('Collection.table.remove_message')}
								onSubmitModal={() => this.handleRemoveCell(row)}
								labelButton={<FontAwesomeIcon icon={faTrashAlt} />}
								modalTitle={t('Collection.table.remove_title')}
								typeButton={'light'}
								buttonStyle={{ marginTop: '-6px', fontSize: '12pt' }}
							/>
						</Col>
					</Row>
				)
			}
		})

		return rows
	}

	handleEditCell = (row) => {
		const { botones } = this.props
		const codCatfon = this.paymentMethods[row.categ_cuenta]
		const fields = this._getConfigFields(codCatfon)
		const title = _.find(botones, function (descrp) {
			return descrp.cod_catfon === codCatfon
		})

		this.setState({
			modalTitle: `Tipo cuenta ${title.descripcion}`,
			configFields: fields,
			showEditModal: true,
			accounts: this.props.accounts[codCatfon],
			initialValues: { ...row, cuenta: row.nicfondo }
		})
	}

	/**
	 * to open remove modal
	 * @params row
	 */
	handleRemoveCell = (row) => {
		const { idOperacion, page, page_size } = this.props
		this.props.removePayment({
			idOperacion,
			page_size,
			page_number: page,
			eliminar: [{ nitem: row.nitem }]
		})
	}

	/**
	 * to close modal
	 */
	handleCloseModal = () => {
		this.setState({ showEditModal: false })
	}

	_getConfigFields = (idField) => {
		const { configCampos } = this.props
		const result = _.where(configCampos, { cod_catfon: idField })
		return result
	}

	getStyleColumn = (field) => {
		const idField = field.idCampo.trim()
		let style = {}

		switch (idField) {
			default:
				style = { width: '10%' }
				break
		}

		if (field.requerido === '1') {
			style.color = 'red'
		}

		return style
	}

	render() {
		const { dataTable, theme, t } = this.props
		const { tableColumns, showEditModal, itemsTable } = this.state
		const options = {
			pageStartIndex: 1,
			sizePerPage: dataTable.page_size,
			page: dataTable.page_number,
			totalSize: dataTable.total_count
		}

		return (
			<div className={theme.scrollContainer}>
				<div className={`${theme.divContainer}`}>
					<Col className={`col-12 pl-0 pr-0`}>
						{tableColumns && itemsTable && (
							<CommonTable
								remote
								refTable={this.tableRef}
								columns={tableColumns}
								keyField={this.primaryKey}
								data={itemsTable}
								rowClasses={theme.tableRow}
								headerClasses={theme.tableHeader}
								paginationOptions={options}
								onTableChange={this.props.handleChangeTable}
								wrapperClasses={theme.collectionsTable}
							/>
						)}
						{showEditModal && (
							<ModalComponent
								t={t}
								showModal={showEditModal}
								handleCloseModal={this.handleCloseModal}
								idOperacion={this.props.idOperacion}
								configFields={this.state.configFields}
								accounts={this.state.accounts}
								moneyOptions={this.props.moneyOptions}
								modalTitle={this.state.modalTitle}
								initialValues={this.state.initialValues}
								page={this.props.page}
								page_size={this.props.page_size}
								paymentTotals={this.props.dataTable}
							/>
						)}
					</Col>
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ voucher, auth }) => {
	const config = voucher.config ? voucher.config[P_LOAD_COLLECTION] : null
	const { authUser } = auth
	return { config, authUser }
}

const connectForm = connect(mapStateToProps, {
	accountValidate,
	accountConfirm,
	removePayment
})(CollectionsTable)

export default themr('StateTableStyles', styles)(withTranslation()(connectForm))
