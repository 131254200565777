import {
	confirmHeaderAttributesSuccess,
	getFormAttributesSuccess
} from 'actions'
import {
	confirmHeaderAttributesApi,
	getFormAttributesApi
} from 'api/HeaderEntry'
import { HeaderEntryActions } from 'constants/ActionsTypesTs'
import {
	IConfirmHeaderAttributesAction,
	IGetFormAttributesAction,
	IGetFormAttributesResponse
} from 'models/HeaderEntry'
import { IResponseResult } from 'models/ResultsInterface'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* getFormAttributesRequest({ payload }: IGetFormAttributesAction): any {
	try {
		const attributes: IGetFormAttributesResponse = yield call(
			getFormAttributesApi,
			payload
		)
		yield put(getFormAttributesSuccess(attributes))
	} catch (error: any) {
		// const { Resultado } = error.response.data
		// const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
	}
}

function* confirmHeaderAttributesRequest({
	payload
}: IConfirmHeaderAttributesAction): any {
	try {
		const { callBackReturn, params } = payload
		const attributes: IResponseResult = yield call(
			confirmHeaderAttributesApi,
			params
		)
		yield put(confirmHeaderAttributesSuccess(attributes))

		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error: any) {
		// const { Resultado } = error.response.data
		// const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
	}
}

export function* getFormAttributesSaga() {
	yield takeEvery(
		HeaderEntryActions.GET_FORM_ATTRIBUTES,
		getFormAttributesRequest
	)
}

export function* confirmHeaderAttributesSaga() {
	yield takeEvery(
		HeaderEntryActions.CONFIRM_ATTRIBUTES,
		confirmHeaderAttributesRequest
	)
}

export default function* rootSaga() {
	yield all([fork(getFormAttributesSaga), fork(confirmHeaderAttributesSaga)])
}
