import {
	CRUD_CLEAR_CUSTOMER_CODE_STORE,
	CRUD_CREATE_CUSTOMER_CODE,
	CRUD_CREATE_CUSTOMER_CODE_SUCCESS,
	CRUD_GET_CUSTOMER_CODE,
	CRUD_GET_CUSTOMER_CODE_CONFIG,
	CRUD_GET_CUSTOMER_CODE_CONFIG_SUCCESS,
	CRUD_GET_CUSTOMER_CODE_SUCCESS,
	CRUD_VALIDATE_CUSTOMER_CODE,
	CRUD_VALIDATE_CUSTOMER_CODE_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	customerCodeInfo: any
	customerCodeCreated: any
	customerCodeConfig: any
	customerCodeValidated: any
}

const initialState = {
	customerCodeInfo: null,
	customerCodeCreated: null,
	customerCodeConfig: null,
	customerCodeValidated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_CUSTOMER_CODE:
			return { ...state, customerCodeInfo: null }
		case CRUD_GET_CUSTOMER_CODE_SUCCESS:
			return { ...state, customerCodeInfo: action.payload }
		case CRUD_GET_CUSTOMER_CODE_CONFIG:
			return { ...state, customerCodeConfig: null }
		case CRUD_GET_CUSTOMER_CODE_CONFIG_SUCCESS:
			return { ...state, customerCodeConfig: action.payload }
		case CRUD_CREATE_CUSTOMER_CODE:
			return { ...state, customerCodeCreated: null }
		case CRUD_CREATE_CUSTOMER_CODE_SUCCESS:
			return { ...state, customerCodeCreated: action.payload }
		case CRUD_VALIDATE_CUSTOMER_CODE:
			return { ...state, customerCodeValidated: null }
		case CRUD_VALIDATE_CUSTOMER_CODE_SUCCESS:
			return { ...state, customerCodeValidated: action.payload }
		case CRUD_CLEAR_CUSTOMER_CODE_STORE:
			return {
				...state,
				customerCodeConfig: null,
				customerCodeInfo: null,
				customerCodeValidated: null,
				customerCodeCreated: null
			}
		default:
			return state
	}
}

export default rootReducer
