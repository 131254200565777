import { getInventoryPickStock } from 'actions'
import CommonTable from 'components/common/commonTable'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getDefaultColumns } from 'lib/FormUtils'
import {
	IGetInventoryPickStockParams,
	IGetInventoryPickStockResponse,
	InventorySearchBarcodeResponse,
	IPickStockItemTable
} from 'models/InventoryPickStock'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'underscore'

interface InventoryPickStockTableProps {
	fields: Array<IFieldStructure>
	authUser: any
	inventoryPickStockData: IGetInventoryPickStockResponse
	inventoryBarCodeItems: InventorySearchBarcodeResponse
	idOperacion: string
	getInventoryPickStock: (payload: IGetInventoryPickStockParams) => void
}

interface IPaginationOptions {
	page: number
	sizePerPage: number
	totalSize: number
}

interface InventoryPickStockTableState {
	tableItems: Array<IPickStockItemTable> | string[]
	paginationOptions: IPaginationOptions
}

class InventoryPickStockTable extends Component<
	InventoryPickStockTableProps,
	InventoryPickStockTableState
> {
	constructor(props: InventoryPickStockTableProps) {
		super(props)
		this.state = {
			tableItems: [],
			paginationOptions: {
				page: 1,
				sizePerPage: 10,
				totalSize: 1
			}
		}
	}

	componentDidUpdate(prevProps: Readonly<InventoryPickStockTableProps>): void {
		const { inventoryPickStockData, inventoryBarCodeItems } = this.props
		/** *************** Consult of items ******************/
		this.handleGetItemsTable(
			prevProps.inventoryPickStockData,
			inventoryPickStockData
		)
		/** *************** Consult of items ******************/

		/** ****************** search bar code update **********************/
		this.handleGetItemsTable(
			prevProps.inventoryBarCodeItems,
			inventoryBarCodeItems
		)
		/** ****************** search bar code update **********************/
	}

	/**
	 * to get the items table
	 * @param prevStockData
	 * @param stockData
	 */
	handleGetItemsTable = (
		prevStockData: IGetInventoryPickStockResponse,
		stockData: IGetInventoryPickStockResponse
	) => {
		if (!_.isEqual(prevStockData, stockData) && !_.isEmpty(stockData)) {
			const paginationOptions: IPaginationOptions = {
				page: stockData.page_number,
				sizePerPage: stockData.page_size,
				totalSize: stockData.total_count
			}
			this.setState({ tableItems: stockData.Items, paginationOptions })
		}
	}

	onChangeTable = (type: string, pagination: any) => {
		const { idOperacion } = this.props
		if (type === 'pagination') {
			this.props.getInventoryPickStock({
				idOperacion: idOperacion,
				page_size: pagination.sizePerPage,
				ind_filtro_fecha: 0,
				filtro_fecha: 0,
				filtro_afectados: 0,
				page_number: pagination.page
			})
		}
	}

	render() {
		const { fields, authUser } = this.props
		const { paginationOptions, tableItems } = this.state

		const propsTable = {
			remote: true,
			columns: !_.isEmpty(fields)
				? getDefaultColumns(fields, { authUser })
				: [],
			data: tableItems,
			paginationOptions,
			onTableChange: this.onChangeTable
		}

		return !_.isEmpty(fields) && <CommonTable {...propsTable} />
	}
}

const mapStateToProps = ({ auth, inventoryPickStock }: any) => {
	const { authUser } = auth
	const { inventoryPickStockData, inventoryBarCodeItems } = inventoryPickStock
	return { authUser, inventoryPickStockData, inventoryBarCodeItems }
}

const mapDispatchToProps = {
	getInventoryPickStock
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InventoryPickStockTable)
