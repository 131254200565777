import Axios from 'axios'
import {
	IConfirmAttributesData,
	IGetFormAttributesParams
} from 'models/HeaderEntry'
const urlBase = 'inv_cab_compr'

export const getFormAttributesApi = async (
	params: IGetFormAttributesParams
) => {
	const response = await Axios.get(urlBase, { params })

	return response.data
}

export const confirmHeaderAttributesApi = async (
	params: Partial<IConfirmAttributesData>
) => {
	const response = await Axios.post(`${urlBase}/confirmar`, params)

	return response.data
}
