import {
	CRUD_CLEAR_DIRECT_CC_ASSIGNATIONS_STORE,
	CRUD_GET_DIRECT_CC_ASSIGNATIONS,
	CRUD_GET_DIRECT_CC_ASSIGNATIONS_SUCCESS,
	CRUD_UPDATE_DIRECT_CC_ASSIGNATIONS,
	CRUD_UPDATE_DIRECT_CC_ASSIGNATIONS_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	CCAssignationsSearched: any
	CCAssignationsUpdated: any
}

const initialState = {
	CCAssignationsSearched: null,
	CCAssignationsUpdated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_DIRECT_CC_ASSIGNATIONS:
			return {
				...state,
				CCAssignationsSearched: null,
				paramsSearch: action.payload
			}
		case CRUD_GET_DIRECT_CC_ASSIGNATIONS_SUCCESS:
			return { ...state, CCAssignationsSearched: action.payload }
		case CRUD_UPDATE_DIRECT_CC_ASSIGNATIONS:
			return { ...state, CCAssignationsUpdated: null }
		case CRUD_UPDATE_DIRECT_CC_ASSIGNATIONS_SUCCESS:
			return { ...state, CCAssignationsUpdated: action.payload }
		case CRUD_CLEAR_DIRECT_CC_ASSIGNATIONS_STORE:
			return {
				...state,
				CCAssignationsSearched: null,
				CCAssignationsUpdated: null
			}
		default:
			return state
	}
}

export default rootReducer
