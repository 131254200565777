import Axios from 'axios'
import {
	IConfirmHeaderSelectionValues,
	IGetHeaderSelectionParams,
	IValidateBarCodeParams
} from 'models/HeaderSelection'

const urlBase = 'cpa_cab_selcompr'

export const getHeaderSelectionApi = async (
	params: IGetHeaderSelectionParams
) => {
	const response = await Axios.get(urlBase, { params })
	return response.data
}

export const confirmHeaderSelectionApi = async (
	params: IConfirmHeaderSelectionValues
) => {
	const response = await Axios.post(`${urlBase}/confirmar`, params)
	return response.data
}

export const validatePurchaseBarCodeApi = async (
	params: IValidateBarCodeParams
) => {
	const response = await Axios.post(`${urlBase}/codbar`, params)
	return response.data
}
