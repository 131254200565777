import { getFilterFundAccountTypes } from 'actions'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getOptionsDropDown, handleSetValueInput } from 'lib/FormUtils'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'
import styles from './SearchVoucher.module.scss'

type SearchFormProps = {
	t: any
	values: any
	handleChange: any
	handleBlur: any
	setFieldValue: any
	readOnly: boolean
	fields: Array<IFieldStructure>
	setFieldTouched: any
	filterTypeAccount: any
	getFilterFundAccountTypes: () => void
}

type TreasuryStateProps = {
	payDateFrom: string
	payDateUntil: string
}

class TreasurySearchFormInput extends Component<
	SearchFormProps,
	TreasuryStateProps
> {
	constructor(props: SearchFormProps) {
		super(props)
		this.state = {
			payDateFrom: '',
			payDateUntil: ''
		}
	}

	componentDidMount = () => {
		const { filterTypeAccount, values } = this.props
		if (_.isEmpty(filterTypeAccount)) {
			this.props.getFilterFundAccountTypes()
		}

		const payDateFrom = values && values.fpago_desde ? values.fpago_desde : ''
		const payDateUntil = values && values.fpago_hasta ? values.fpago_hasta : ''

		this.setState({
			payDateFrom,
			payDateUntil
		})
	}

	componentDidUpdate = (prevProps: any) => {
		const { values } = this.props
		if (
			!_.isEqual(prevProps.values, values) &&
			values.fpago_desde === '' &&
			values.fpago_hasta === ''
		) {
			this.setState({ payDateFrom: '', payDateUntil: '' })
		}
	}

	render() {
		const {
			values,
			handleBlur,
			readOnly,
			fields,
			t,
			setFieldTouched,
			setFieldValue,
			filterTypeAccount
		} = this.props
		const { payDateFrom, payDateUntil } = this.state
		const formikProps = { setFieldTouched, setFieldValue }

		const propsDescFondo = {
			inputFormCol: { sm: 12 },
			fields,
			label: t('search_voucher.module'),
			inputId: 'desc_fondo',
			name: 'desc_fondo',
			placeholder: '',
			colLabel: 'col-sm-1',
			colInput: 'col-sm-11',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.desc_fondo,
			divStyle: { paddingLeft: '5.4%' },
			onChange: (data: any) =>
				handleSetValueInput(data, 'desc_fondo', formikProps)
		}
		const propsNroDoc = {
			inputFormCol: { sm: 8 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'nro_doc',
			name: 'nro_doc',
			placeholder: '',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.nro_doc,
			divStyle: { paddingLeft: '4%', paddingRight: '11px' },
			onChange: (data: any) => handleSetValueInput(data, 'nro_doc', formikProps)
		}

		const propsTypeCtaFondo = {
			inputFormCol: { sm: 8 },
			fields,
			label: t('search_voucher.module'),
			inputId: 'tipo_cfondo',
			name: 'tipo_cfondo',
			placeholder: '',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.tipo_cfondo,
			options: getOptionsDropDown(filterTypeAccount),
			noInitialExecute: true,
			divStyle: { paddingLeft: '4%', paddingRight: '11px' },
			onChange: (data: any) =>
				handleSetValueInput(data, 'tipo_cfondo', formikProps)
		}

		const propsDateFrom = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Importe',
			inputId: 'fpago_desde',
			name: 'fpago_desde',
			placeholder: t('global.from'),
			colLabel: 'col-sm-5',
			colInput: 'col-sm-6',
			disable: readOnly,
			onBlur: handleBlur,
			value: payDateFrom,
			divStyle: { paddingLeft: '0px' },
			autoComplete: 'off',
			onChange: (data: any) => {
				const date = data || ''
				this.setState({ payDateFrom: date })
				handleSetValueInput(date, 'fpago_desde', formikProps)
			}
		}

		const propsDateUntil = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'fecha',
			inputId: 'fpago_hasta',
			name: 'fpago_hasta',
			placeholder: t('global.until'),
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: handleBlur,
			value: payDateUntil,
			divStyle: { paddingRight: '0px' },
			autoComplete: 'off',
			onChange: (data: any) => {
				const date = data || ''
				this.setState({ payDateUntil: date })
				handleSetValueInput(date, 'fpago_hasta', formikProps)
			}
		}

		const propsImpFrom = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Importe',
			inputId: 'impdoc_desde',
			name: 'impdoc_desde',
			placeholder: t('global.from'),
			colLabel: 'col-sm-5',
			colInput: 'col-sm-7',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.impdoc_desde,
			divStyle: { paddingLeft: '0px' },
			onChange: (data: any) =>
				handleSetValueInput(data, 'impdoc_desde', formikProps)
		}

		const propsImpUntil = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Importe',
			inputId: 'impdoc_hasta',
			name: 'impdoc_hasta',
			placeholder: t('global.until'),
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.impdoc_hasta,
			divStyle: { paddingRight: '0px' },
			onChange: (data: any) =>
				handleSetValueInput(data, 'impdoc_hasta', formikProps)
		}

		return (
			<Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsDescFondo} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputDropdown {...propsTypeCtaFondo} />
				</Row>
				<Row className={`col-8 ${styles.module_search_row}`}>
					<InputText {...propsDateFrom} />
					<InputText {...propsDateUntil} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsNroDoc} />
				</Row>
				<Row className={`col-8 ${styles.module_search_row}`}>
					<InputText {...propsImpFrom} />
					<InputText {...propsImpUntil} />
				</Row>
			</Row>
		)
	}
}

const mapStateToProps = ({ searchVoucher }: any) => {
	const { filterTypeAccount } = searchVoucher
	return { filterTypeAccount }
}

export default connect(mapStateToProps, { getFilterFundAccountTypes })(
	TreasurySearchFormInput
)
