import { ICrudGenericFields } from 'models/CrudInterface'

export const voucherConfigFields: Array<ICrudGenericFields> = [
	{
		id: 'nioperacion',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'modulo',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-4',
		height: '',
		default: null,
		maxChars: 30,
		autoComplete: 'new-off',
		comboData: 'voucherModules',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'nombre',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'measurementUnit',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_tipcomp',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-1',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'd_compcc',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'AVSales',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'd_compcp',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'AVPurchases',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'd_compst',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'AVStock',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'd_compfon',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'AVFunds',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'd_compcnt',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'AVAccounting',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'habilitado',
		type: 'check',
		labelWidth: 'col-sm-3',
		width: 'col-sm-1',
		height: '',
		default: 1,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
