import {
	CRUD_CLEAR_SELLER_COMMISSIONS_STORE,
	CRUD_DEL_SELLER_COMMISSION,
	CRUD_DEL_SELLER_COMMISSION_SUCCESS,
	CRUD_GET_SELLER_COMMISSION,
	CRUD_GET_SELLER_COMMISSIONS,
	CRUD_GET_SELLER_COMMISSIONS_SUCCESS,
	CRUD_GET_SELLER_COMMISSION_SUCCESS,
	CRUD_UPDATE_SELLER_COMMISSION,
	CRUD_UPDATE_SELLER_COMMISSION_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	commissionsList: any
	commissionValue: any
	commissionUpdated: any
	commissionRemoved: any
}

const initialState = {
	commissionsList: null,
	commissionValue: null,
	commissionUpdated: null,
	commissionRemoved: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_SELLER_COMMISSIONS:
			return { ...state, commissionsList: null, commissionsUpdated: null }
		case CRUD_GET_SELLER_COMMISSIONS_SUCCESS:
			return { ...state, commissionsList: action.payload }
		case CRUD_GET_SELLER_COMMISSION:
			return { ...state, commissionValue: null }
		case CRUD_GET_SELLER_COMMISSION_SUCCESS:
			return { ...state, commissionValue: action.payload }
		case CRUD_UPDATE_SELLER_COMMISSION:
			return { ...state, commissionUpdated: null }
		case CRUD_UPDATE_SELLER_COMMISSION_SUCCESS:
			return { ...state, commissionUpdated: action.payload }
		case CRUD_DEL_SELLER_COMMISSION:
			return { ...state, commissionRemoved: null }
		case CRUD_DEL_SELLER_COMMISSION_SUCCESS:
			return { ...state, commissionRemoved: action.payload }
		case CRUD_CLEAR_SELLER_COMMISSIONS_STORE:
			return {
				...state,
				commissionValue: null,
				commissionUpdated: null
			}
		default:
			return state
	}
}

export default rootReducer
