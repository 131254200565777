import {
	CRUD_GET_PROVIDER_OBSERVATION,
	CRUD_GET_PROVIDER_OBSERVATION_SUCCESS,
	CRUD_UPDATE_PROVIDER_OBSERVATION,
	CRUD_UPDATE_PROVIDER_OBSERVATION_SUCCESS
} from '../constants/ActionsTypes'
import {
	getValueInfoParamsInterface,
	updateValueParamsInterface
} from '../constants/valuesInterfaces/interfaces'

export const crudGetProviderObservation = (
	payload: getValueInfoParamsInterface
) => ({
	type: CRUD_GET_PROVIDER_OBSERVATION,
	payload
})

export const crudGetProviderObservationSuccess = (payload: any) => ({
	type: CRUD_GET_PROVIDER_OBSERVATION_SUCCESS,
	payload
})

export const crudUpdateProviderObservation = (
	payload: updateValueParamsInterface
) => ({
	type: CRUD_UPDATE_PROVIDER_OBSERVATION,
	payload
})

export const crudUpdateProviderObservationSuccess = (payload: any) => ({
	type: CRUD_UPDATE_PROVIDER_OBSERVATION_SUCCESS,
	payload
})
