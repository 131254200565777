import { getFilterDeposits } from 'actions'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getOptionsDropDown, handleSetValueInput } from 'lib/FormUtils'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'
import styles from './SearchVoucher.module.scss'

type SearchFormProps = {
	t: any
	values: any
	handleChange: any
	handleBlur: any
	setFieldValue: any
	readOnly: boolean
	fields: Array<IFieldStructure>
	setFieldTouched: any
	getFilterDeposits: () => void
	filterDeposits: any
}

type InventoryState = {
	fec_vto_value: string
}

class InventorySearchFormInput extends Component<
	SearchFormProps,
	InventoryState
> {
	constructor(props: SearchFormProps) {
		super(props)
		this.state = { fec_vto_value: '' }
	}

	componentDidMount = () => {
		const { filterDeposits, values } = this.props
		if (_.isEmpty(filterDeposits)) {
			this.props.getFilterDeposits()
			const fec_vto_value = values && values.fec_vto ? values.fec_vto : ''

			this.setState({
				fec_vto_value
			})
		}
	}

	componentDidUpdate = (prevProps: any) => {
		const { values } = this.props
		if (!_.isEqual(prevProps.values, values) && values.fec_vto === '') {
			this.setState({ fec_vto_value: '' })
		}
	}

	render() {
		const {
			values,
			handleBlur,
			readOnly,
			fields,
			t,
			setFieldTouched,
			setFieldValue,
			filterDeposits
		} = this.props

		const { fec_vto_value } = this.state
		const formikProps = { setFieldTouched, setFieldValue }

		const propsDescProd = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'desc_prod',
			name: 'desc_prod',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.desc_prod,
			onChange: (data: any) =>
				handleSetValueInput(data, 'desc_prod', formikProps)
		}

		const propsCodProduct = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'cod_prod',
			name: 'cod_prod',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.cod_prod,
			onChange: (data: any) =>
				handleSetValueInput(data, 'cod_prod', formikProps)
		}

		const propsBarCode = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'codbarra',
			name: 'codbarra',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.codbarra,
			onChange: (data: any) =>
				handleSetValueInput(data, 'codbarra', formikProps)
		}

		const propsCUF = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'cuf',
			name: 'cuf',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.cuf,
			onChange: (data: any) => handleSetValueInput(data, 'cuf', formikProps)
		}

		const propsDeposit = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.module'),
			inputId: 'deposito',
			name: 'deposito',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.deposito,
			options: getOptionsDropDown(filterDeposits),
			noInitialExecute: true,
			onChange: (data: any) =>
				handleSetValueInput(data, 'deposito', formikProps)
		}

		const propsDeparture = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'partida',
			name: 'partida',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.partida,
			onChange: (data: any) => handleSetValueInput(data, 'partida', formikProps)
		}

		const propsDateFrom = {
			inputFormCol: { sm: 8 },
			fields,
			label: 'Importe',
			inputId: 'fec_vto',
			name: 'fec_vto',
			placeholder: t('global.date'),
			colLabel: 'col-sm-5',
			colInput: 'col-sm-6',
			disable: readOnly,
			onBlur: handleBlur,
			value: fec_vto_value,
			divStyle: { paddingLeft: '0px' },
			autoComplete: 'off',
			onChange: (data: any) => {
				const date = data || ''
				this.setState({ fec_vto_value: date })
				handleSetValueInput(date, 'fec_vto', formikProps)
			}
		}

		const propsDateUntil = {
			inputFormCol: { sm: 4 },
			fields,
			label: '',
			inputId: 'fec_vto_until',
			name: 'fec_vto_until',
			placeholder: t('global.until'),
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.fec_vto_until,
			divStyle: { paddingRight: '0px' },
			autoComplete: 'off',
			onChange: (data: any) =>
				handleSetValueInput(data, 'fec_vto_until', formikProps)
		}

		const propsNserie = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'nserie',
			name: 'nserie',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.nserie,
			onChange: (data: any) => handleSetValueInput(data, 'nserie', formikProps)
		}

		return (
			<Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsDescProd} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsCodProduct} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsBarCode} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputDropdown {...propsDeposit} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsCUF} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsDeparture} />
				</Row>
				<Row className={`col-6 ${styles.module_search_row}`}>
					<InputText {...propsDateFrom} />
					<InputText {...propsDateUntil} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsNserie} />
				</Row>
			</Row>
		)
	}
}

const mapStateToProps = ({ searchVoucher }: any) => {
	const { filterDeposits } = searchVoucher
	return { filterDeposits }
}

export default connect(mapStateToProps, { getFilterDeposits })(
	InventorySearchFormInput
)
