import InputButton from 'components/form/inputButton'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import moment from 'moment'
import React, { Component } from 'react'
import { Col } from 'react-bootstrap'

class AffectationTableFilter extends Component {
	constructor(props) {
		super(props)
		this.state = {
			desde_fecha: moment(),
			hasta_fecha: moment(),
			disableField: true,
			tipofecha: ''
		}

		this.optionsShowInput = [
			{
				id: 'T',
				label: 'Todo'
			},
			{
				id: 'A',
				label: 'Afectado'
			},
			{
				id: 'P',
				label: 'Pendiente'
			}
		]

		this.optionsInput = [
			{
				id: 'T',
				label: 'Todos'
			},
			{
				id: 'F',
				label: 'Facturas'
			},
			{
				id: 'D',
				label: 'Débitos'
			},
			{
				id: 'C',
				label: 'Créditos'
			}
		]

		this.optionsFilter = [
			{
				id: 'C',
				label: 'Contable'
			},
			{
				id: 'R',
				label: 'Real'
			},
			{
				id: 'V',
				label: 'Vencimiento'
			}
		]
	}

	handleValidateDate = ({ afectar }) => {
		const { tipofecha, desde_fecha, hasta_fecha } = this.state
		const formatDate = 'DD/MM/YYYY'
		const dateFrom = moment(desde_fecha).format(formatDate)
		const dateUntil = moment(hasta_fecha).format(formatDate)
		this.props.validateImpDate({
			tipofecha,
			desde_fecha: dateFrom,
			hasta_fecha: dateUntil,
			afectar
		})
	}

	render() {
		const { t, handleGetCant, handleChangeSelect } = this.props
		const { desde_fecha, hasta_fecha, disableField } = this.state

		const inputsConfig = [
			{
				idCampo: 'tipofecha',
				label: false,
				visible: 1,
				requerido: 0,
				editable: !disableField
			},
			{
				idCampo: 'checkComprobAvencer',
				label: t('affectationAmounts.table.show'),
				visible: 1,
				requerido: 0,
				editable: 1
			},
			{
				idCampo: 'desde_fecha',
				label: t('affectationAmounts.filter.from'),
				visible: 1,
				requerido: 0,
				editable: !disableField,
				valid: 'FEC>=H',
				mascara: 'FechaLarga'
			},
			{
				idCampo: 'hasta_fecha',
				label: t('affectationAmounts.filter.until'),
				visible: 1,
				requerido: 0,
				editable: !disableField,
				valid: 'FEC>=H',
				mascara: 'FechaLarga'
			},
			{
				idCampo: 'voucherType',
				label: t('affectationAmounts.filter.voucherType'),
				visible: 1,
				requerido: 0,
				editable: 1
			}
		]

		return (
			<Col lg={12}>
				<div
					className={'row pt-3 ml-1 mb-2'}
					style={{ border: '1px solid black' }}
				>
					<Col lg={2}>
						<input
							type="checkbox"
							className={'form-check-input ml-4 mt-2'}
							onChange={() =>
								this.setState((state) => ({
									disableField: !state.disableField
								}))
							}
							value="1"
						/>

						<label
							className={'form-check-label ml-5'}
							style={{ fontSize: 14, fontFamily: 'inherit' }}
						>
							{t('affectationAmounts.table.dates')}
						</label>
					</Col>
					<Col xs={12} sm={12} md={6} lg={2} style={{ textAlign: 'center' }}>
						<InputDropdown
							inputFormCol={{ sm: 12 }}
							fields={inputsConfig}
							label={false}
							inputId={'tipofecha'}
							name={'tipofecha'}
							placeholder={t('client.form.select_sample')}
							styles={{ width: '100%' }}
							styleLabel={{ textAlign: 'right' }}
							colLabel={'col-sm-3'}
							colInput={'col-sm-9'}
							options={this.optionsFilter}
							onChange={(data) => {
								this.setState({ tipofecha: data.target.value })
							}}
						/>
					</Col>
					<InputText
						inputFormCol={{ sm: 2, lg: 2 }}
						fields={inputsConfig}
						label={t('affectationAmounts.filter.from')}
						inputId={'desde_fecha'}
						name={'desde_fecha'}
						placeholder={t('headboard.form.insert_date')}
						colLabel={'col-sm-3'}
						colInput={'col-sm-9'}
						value={desde_fecha}
						handleEnterKey={(e, date) => {
							this.setState({ desde_fecha: date })
						}}
						onChange={(date, evt) => {
							if (
								date === null ||
								evt.target.value === undefined ||
								(evt.keyCode === 13 && date !== null)
							) {
								this.setState({ desde_fecha: date })
							}
						}}
					/>
					<InputText
						inputFormCol={{ sm: 2, lg: 2 }}
						fields={inputsConfig}
						label={t('affectationAmounts.filter.until')}
						inputId={'hasta_fecha'}
						name={'hasta_fecha'}
						placeholder={t('headboard.form.insert_date')}
						colLabel={'col-sm-3'}
						colInput={'col-sm-9'}
						value={hasta_fecha}
						handleEnterKey={(e, date) => {
							this.setState({ hasta_fecha: date })
						}}
						onChange={(date, evt) => {
							if (
								date === null ||
								evt.target.value === undefined ||
								(evt.keyCode === 13 && date !== null)
							) {
								this.setState({ hasta_fecha: date })
							}
						}}
					/>
					<Col xs={12} sm={12} md={6} lg={4} style={{ textAlign: 'center' }}>
						<InputButton
							valueButton={t('affectationAmounts.filter.buttons.affect')}
							type={'primary'}
							onClick={() => this.handleValidateDate({ afectar: 1 })}
							customStyle={{ minWidth: '100px', marginLeft: '15px' }}
							disabled={disableField}
						/>

						<InputButton
							valueButton={t('affectationAmounts.filter.buttons.disaffect')}
							type={'primary'}
							onClick={() => this.handleValidateDate({ afectar: 0 })}
							customStyle={{ minWidth: '100px', marginLeft: '15px' }}
							disabled={disableField}
						/>
					</Col>
				</div>
				<div className={'row'}>
					<Col lg={3}>
						<input
							type="checkbox"
							className={'form-check-input ml-4 mt-2'}
							onChange={handleGetCant}
							value="1"
						/>
						<label
							className={'form-check-label ml-5'}
							style={{ fontSize: 14, fontFamily: 'inherit' }}
						>
							{t('voucherInvolvement.form.checkbox')}
						</label>
					</Col>

					<Col xs={12} sm={12} md={6} lg={3} style={{ textAlign: 'center' }}>
						<InputDropdown
							inputFormCol={{ sm: 12 }}
							fields={inputsConfig}
							label={t('affectationAmounts.table.show')}
							inputId={'checkComprobAvencer'}
							name={'checkComprobAvencer'}
							placeholder={t('client.form.select_sample')}
							styles={{ width: '100%' }}
							styleLabel={{ textAlign: 'right' }}
							colLabel={'col-sm-3'}
							colInput={'col-sm-9'}
							options={this.optionsShowInput}
							tooltip
							noInitialExecute
							onChange={(data) => {
								handleChangeSelect(data.target.value)
							}}
						/>
					</Col>
					<Col lg={2}>
						<input
							type="checkbox"
							className={'form-check-input ml-4 mt-2'}
							onChange={handleGetCant}
							value="1"
						/>
						<label
							className={'form-check-label ml-5'}
							style={{ fontSize: 14, fontFamily: 'inherit' }}
						>
							{t('affectationAmounts.filter.allbranch')}
						</label>
					</Col>
					<Col xs={12} sm={12} md={6} lg={4} style={{ textAlign: 'left' }}>
						<InputDropdown
							inputFormCol={{ sm: 12 }}
							fields={inputsConfig}
							label={t('affectationAmounts.table.show')}
							inputId={'voucherType'}
							name={'voucherType'}
							placeholder={t('client.form.select_sample')}
							styles={{ width: '100%' }}
							styleLabel={{ textAlign: 'left' }}
							colLabel={'col-sm-5'}
							colInput={'col-sm-7'}
							options={this.optionsInput}
							tooltip
							noInitialExecute
							onChange={(data) => {
								handleChangeSelect(data.target.value)
							}}
						/>
					</Col>
				</div>
			</Col>
		)
	}
}

export default AffectationTableFilter
