import {
	downloadFilesSuccess,
	getDocumentListSuccess,
	getTempDocumentListSuccess,
	removeDocumentSuccess,
	removeTempDocumentSuccess,
	uploadFilesSuccess
} from 'actions'
import {
	downloadFileApi,
	getDocumentListApi,
	getTempDocumentListApi,
	removeDocumentApi,
	removeTempDocumentApi,
	uploadFilesApi
} from 'api/FileManager'
import { UploadFilesActions } from 'constants/ActionsTypesTs'
import logger from 'lib/logger'
import {
	IDownloadFileAction,
	IGetDocumentsAction,
	IGetTempDocumentsAction,
	IRemoveDocumentAction,
	IRemoveTempDocumentAction,
	IUploadFilesAction
} from 'models/FileManager'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* uploadFileRequest({ payload }: IUploadFilesAction): any {
	try {
		const response: any = yield call(uploadFilesApi, payload)
		yield put(uploadFilesSuccess(response))
	} catch (error: any) {
		logger({
			module: 'SAGA',
			data: error,
			type: 'error',
			msg: 'Hay un error'
		})
	}
}

function* getTempDocumentListRequest({
	payload
}: IGetTempDocumentsAction): any {
	try {
		const documentList: any = yield call(getTempDocumentListApi, payload)
		yield put(getTempDocumentListSuccess(documentList))
	} catch (error: any) {
		logger({
			module: 'SAGA',
			data: error,
			type: 'error',
			msg: 'Hay un error'
		})
	}
}

function* removeTempDocumentRequest({
	payload
}: IRemoveTempDocumentAction): any {
	try {
		const document: any = yield call(removeTempDocumentApi, payload)
		yield put(removeTempDocumentSuccess(document))
	} catch (error: any) {
		logger({
			module: 'SAGA',
			data: error,
			type: 'error',
			msg: 'Hay un error'
		})
	}
}

function* getDocumentListRequest({ payload }: IGetDocumentsAction): any {
	try {
		const documentList: any = yield call(getDocumentListApi, payload)
		yield put(getDocumentListSuccess(documentList))
	} catch (error: any) {
		logger({
			module: 'SAGA',
			data: error,
			type: 'error',
			msg: 'Hay un error'
		})
	}
}

function* removeDocumentRequest({ payload }: IRemoveDocumentAction): any {
	try {
		const document: any = yield call(removeDocumentApi, payload)
		yield put(removeDocumentSuccess(document))
	} catch (error: any) {
		logger({
			module: 'SAGA',
			data: error,
			type: 'error',
			msg: 'Hay un error'
		})
	}
}

function* downloadFileRequest({ payload }: IDownloadFileAction): any {
	try {
		const document: any = yield call(downloadFileApi, payload)
		yield put(downloadFilesSuccess(document))
	} catch (error: any) {
		logger({
			module: 'SAGA',
			data: error,
			type: 'error',
			msg: 'Hay un error'
		})
	}
}

export function* uploadFileSaga() {
	yield takeEvery(UploadFilesActions.UPLOAD_FILES, uploadFileRequest)
}

export function* getTempDocumentListSaga() {
	yield takeEvery(
		UploadFilesActions.GET_TEMP_DOCUMENT_LIST,
		getTempDocumentListRequest
	)
}

export function* removeTempDocumentSaga() {
	yield takeEvery(
		UploadFilesActions.REMOVE_TEMP_DOCUMENT,
		removeTempDocumentRequest
	)
}

export function* getDocumentListSaga() {
	yield takeEvery(UploadFilesActions.GET_DOCUMENT_LIST, getDocumentListRequest)
}

export function* removeDocumentSaga() {
	yield takeEvery(UploadFilesActions.REMOVE_DOCUMENT, removeDocumentRequest)
}

export function* downloadFileSaga() {
	yield takeEvery(UploadFilesActions.DOWNLOAD_FILE, downloadFileRequest)
}

export default function* rootSaga() {
	yield all([
		fork(uploadFileSaga),
		fork(getTempDocumentListSaga),
		fork(removeTempDocumentSaga),
		fork(getDocumentListSaga),
		fork(removeDocumentSaga),
		fork(downloadFileSaga)
	])
}
