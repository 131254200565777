import { getConfigVoucher, getImpDocuments, pickStockImport } from 'actions'
import CommonTable from 'components/common/commonTable'
import { P_IMPO_STOCK_ING } from 'constants/ConfigProcessNames'
import { getValueMask } from 'lib/MaskValues'
import { getFiltersData } from 'lib/Utils'
import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { connect } from 'react-redux'
import styles from './PickStockImportTable.module.scss'

class PickStockImportTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			itemsTable: []
		}
		this.primaryKey = 'niprod'
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.getConfigVoucher({ cod_proceso: P_IMPO_STOCK_ING, idOperacion })
	}

	componentDidUpdate = (prevProps) => {
		const { pickStockData } = this.props
		if (prevProps.pickStockData !== pickStockData && pickStockData) {
			this.setState({ itemsTable: pickStockData.items })
		}
	}

	/**
	 * to get all columns table
	 * @returns object with de columns
	 */
	getColumns = () => {
		const { config } = this.props
		const fieldsTable = getFiltersData(config.campos, { agrupador: 'grilla' })

		const rows = fieldsTable.map((field) => {
			const campoId = field.idCampo.trim()
			return {
				dataField: campoId,
				text: field.label,
				align: 'center',
				headerAlign: 'center',
				headerStyle: this.getStyleColumn(field),
				hidden: !field.visible,
				formatter: (cell, row, rowIndex) => {
					return this.renderFormat(field, cell, row)
				}
			}
		})

		return rows
	}

	/**
	 * to add background color to table
	 * @param {object} row
	 * @returns styles object
	 */
	handleRowStyle = (row) => {
		const style = {}
		if (row.ind_error) {
			style.backgroundColor = '#FFCC99'
		} else if (!row.ind_error) {
			style.backgroundColor = '#33CCFF'
		}
		return style
	}

	/**
	 * to print formant in the cell tables.
	 * @param {string} field
	 * @param {string} value
	 * @param {object} row
	 * @returns object with the format data.
	 */
	renderFormat = (field, value, row) => {
		const idField = field.idCampo.trim()
		let result = value

		if (field.mascara) {
			result = getValueMask(value, field.mascara, this.props)
		}

		if (idField === 'mens_error' && value.length > 30) {
			result = (
				<div data-toggle="tooltip" data-placement="left" title={value}>
					{`${value.substring(0, 30)}...`}
				</div>
			)
		}
		return result
	}

	/**
	 * to add columns style
	 * @param {string} field
	 * @returns  object with the styles
	 */
	getStyleColumn = (field) => {
		const idField = field.idCampo.trim()
		let style = {}
		switch (idField) {
			default:
				style = { width: '10%' }
				break
		}

		if (field.requerido === '1' || field.requerido) {
			style.color = 'red'
		}

		return style
	}

	render() {
		const { pickStockData, theme, config, primaryKey } = this.props
		const { itemsTable } = this.state
		const tableColumns = config ? this.getColumns() : []

		const options = pickStockData
			? {
					pageStartIndex: 1,
					sizePerPage: pickStockData.page_size,
					page: pickStockData.page_number,
					totalSize: pickStockData.total_count
			  }
			: {}

		return (
			<Col sm={12}>
				{tableColumns.length > 0 && (
					<CommonTable
						remote
						columns={tableColumns}
						keyField={primaryKey || this.primaryKey}
						data={itemsTable}
						rowClasses={theme.tableRow}
						headerClasses={theme.tableHeader}
						paginationOptions={options}
						onTableChange={this.props.handleChangeTable}
						rowStyle={this.handleRowStyle}
					/>
				)}
			</Col>
		)
	}
}

const mapStateToProps = ({ collections, auth, voucher }) => {
	const config = voucher.config ? voucher.config[P_IMPO_STOCK_ING] : null

	const { impDocuments, docsImported } = collections
	const { authUser } = auth

	return { impDocuments, authUser, docsImported, config }
}

const connectForm = connect(mapStateToProps, {
	getImpDocuments,
	getConfigVoucher,
	pickStockImport
})(PickStockImportTable)

export default themr('PickStockImportTableStyles', styles)(connectForm)
