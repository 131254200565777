import { LoadInventoryItemsActions } from 'constants/ActionsTypesTs'
import { IResponseErrorApi } from 'models/CommonInterface'
import {
	ILoadItemIncomeInventoryAction,
	ILoadItemIncomeInventoryErrorAction,
	ILoadItemIncomeInventoryProps,
	ILoadItemIncomeInventorySuccessAction,
	ILoadItemInventoryAction,
	ILoadItemInventoryConfirmAction,
	ILoadItemInventoryConfirmErrorAction,
	ILoadItemInventoryConfirmPayload,
	ILoadItemInventoryConfirmSuccessAction,
	ILoadItemInventoryErrorAction,
	ILoadItemInventoryProps,
	ILoadItemInventoryResponse,
	ILoadItemInventorySuccessAction
} from 'models/LoadInventoryItems'
import { IResponseResult } from 'models/ResultsInterface'

export const loadItemInventory = (
	payload: ILoadItemInventoryProps
): ILoadItemInventoryAction => ({
	type: LoadInventoryItemsActions.LOAD_ITEM_INVENTORY,
	payload
})

export const loadItemInventorySuccess = (
	payload: ILoadItemInventoryResponse
): ILoadItemInventorySuccessAction => ({
	type: LoadInventoryItemsActions.LOAD_ITEM_INVENTORY_SUCCESS,
	payload
})

export const loadItemInventoryError = (
	payload: IResponseErrorApi
): ILoadItemInventoryErrorAction => ({
	type: LoadInventoryItemsActions.LOAD_ITEM_INVENTORY_ERROR,
	payload
})

export const loadItemInventoryConfirm = (
	payload: ILoadItemInventoryConfirmPayload
): ILoadItemInventoryConfirmAction => ({
	payload,
	type: LoadInventoryItemsActions.LOAD_ITEM_INVENTORY_CONFIRM
})

export const loadItemInventoryConfirmSuccess = (
	payload: IResponseResult
): ILoadItemInventoryConfirmSuccessAction => ({
	payload,
	type: LoadInventoryItemsActions.LOAD_ITEM_INVENTORY_CONFIRM_SUCCESS
})

export const loadItemInventoryConfirmError = (
	payload: IResponseErrorApi
): ILoadItemInventoryConfirmErrorAction => ({
	type: LoadInventoryItemsActions.LOAD_ITEM_INVENTORY_CONFIRM_ERROR,
	payload
})

export const loadItemIncomeInventory = (
	payload: ILoadItemIncomeInventoryProps
): ILoadItemIncomeInventoryAction => ({
	type: LoadInventoryItemsActions.LOAD_ITEM_INCOME_INVENTORY,
	payload
})

export const loadItemIncomeInventorySuccess = (
	payload: ILoadItemInventoryResponse
): ILoadItemIncomeInventorySuccessAction => ({
	type: LoadInventoryItemsActions.LOAD_ITEM_INCOME_INVENTORY_SUCCESS,
	payload
})

export const loadItemIncomeInventoryError = (
	payload: IResponseErrorApi
): ILoadItemIncomeInventoryErrorAction => ({
	type: LoadInventoryItemsActions.LOAD_ITEM_INCOME_INVENTORY_ERROR,
	payload
})
