import {
	clearSugestedProductsStore,
	deleteSearchedProducts,
	getConfigFieldsCrud,
	getConfigVoucher,
	getSugestedProducts,
	selectSearchedProducts,
	setSugestedProductPriority,
	setSugestedProductSelection
} from 'actions'
import CommonModal from 'components/common/commonModal'
import SearchProductsForm from 'components/SearchProducts/SearchProductsForm'
import SearchProductsTable from 'components/SearchProducts/SearchProductsTable'
import {
	P_CONF_PROD_SUG,
	P_SEARCH_PRODUCTS
} from 'constants/ConfigProcessNames'
import { IAbmStructureResponse } from 'constants/valuesInterfaces/interfaces'
import { getFiltersData } from 'lib/Utils'
import { IDeleteSearchedProductsParams } from 'models/SearchProduct'
import {
	IConfigFieldsForm,
	IGetSugestedProducts,
	IGetSugestedProductsResponse,
	IGetSugestedProductsResponseArray,
	ISetSugestedProductPriority,
	ISetSugestedProductSelection
} from 'models/SugestedProducts'
import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import SugestedProductsForm from './SugestedProductsForm'
import SugestedProductsTable from './SugestedProductsTable'

interface ISugestedProductsProps extends WithTranslation {
	config?: IConfigFieldsForm
	t?: any
	products: IGetSugestedProductsResponseArray
	getSugestedProducts: (payload: IGetSugestedProducts) => void
	clearSugestedProductsStore: () => void
	setSugestedProductPriority: (payload: ISetSugestedProductPriority) => void
	setSugestedProductSelection: (payload: ISetSugestedProductSelection) => void
	getConfigFieldsCrud: (payload: { cod_abm: string }) => void
	selectSearchedProducts: (payload: any) => void
	deleteSearchedProducts: (payload: IDeleteSearchedProductsParams) => void
	fieldsConfig: IAbmStructureResponse
	idOperacion?: any
	productSelection: any
	productPriority: any
}

interface ISugestedProductsState {
	searchParams: IGetSugestedProducts
	showSearchModal: boolean
	searchProduct: string
	selectedRows: Array<number>
}
class SugestedProducts extends Component<
	ISugestedProductsProps,
	ISugestedProductsState
> {
	constructor(props: ISugestedProductsProps) {
		super(props)
		this.state = {
			searchParams: {
				idOperacion: props.idOperacion,
				niprod: 0,
				page_number: 0,
				page_size: 0
			},
			showSearchModal: false,
			searchProduct: '',
			selectedRows: []
		}
	}

	componentDidMount = () => {
		this.props.clearSugestedProductsStore()
		this.props.getConfigFieldsCrud({ cod_abm: P_SEARCH_PRODUCTS })
	}

	componentDidUpdate = (
		prevProps: ISugestedProductsProps,
		prevState: ISugestedProductsState
	) => {
		const { getSugestedProducts, products, deleteSearchedProducts } = this.props
		const { searchParams, selectedRows } = this.state

		if (products && products !== prevProps.products) {
			const newSelection: Array<number> = []

			_.forEach(products.productos, (producto) => {
				producto.select === 1 && newSelection.push(producto.nisuger)
			})

			this.setState({ selectedRows: newSelection })
			deleteSearchedProducts({ idOperacion: searchParams.idOperacion })
		}

		if (searchParams !== prevState.searchParams && searchParams.niprod) {
			getSugestedProducts(searchParams)
		}

		if (selectedRows !== prevState.selectedRows) {
			this.handleSelection()
		}
	}

	handleSearchPagination = (page_number: number, page_size: number) => {
		const { searchParams } = this.state

		this.setState({
			searchParams: {
				...searchParams,
				page_number: page_number,
				page_size: page_size
			}
		})
	}

	handleSearchProduct = (niprod: number) => {
		const { searchParams } = this.state

		this.setState({
			searchParams: {
				...searchParams,
				page_number: 0,
				page_size: 0,
				mascara: '',
				descrip: '',
				niprod: niprod
			}
		})
	}

	handleSearchFilter = (values: any) => {
		const { searchParams } = this.state

		this.setState({
			searchParams: {
				...searchParams,
				page_number: 0,
				page_size: 0,
				mascara: values?.mascara,
				descrip: values?.desc_filtro,
				sel_primero: values?.check_selected
			}
		})
	}

	handleChange = (type: string, row: number, value: number) => {
		const { setSugestedProductPriority, idOperacion } = this.props
		const { searchParams } = this.state

		if (type === 'priority') {
			setSugestedProductPriority({
				idoperacion: idOperacion,
				niprod: searchParams.niprod,
				nisuger: row,
				prioridad: value
			})
		}
	}

	handleSelection = () => {
		const { products, setSugestedProductSelection } = this.props
		const { selectedRows, searchParams } = this.state

		const newSelection: Array<{ nisuger: number; seleccion: number }> = []

		_.forEach(products.productos, (product: IGetSugestedProductsResponse) => {
			_.some(
				selectedRows,
				(selectedProduct) => product.nisuger === selectedProduct
			)
				? newSelection.push({ nisuger: product.nisuger, seleccion: 1 })
				: newSelection.push({ nisuger: product.nisuger, seleccion: 0 })
		})

		setSugestedProductSelection({
			idoperacion: searchParams.idOperacion,
			niprod: searchParams.niprod,
			select: newSelection
		})
	}

	handleAddProduct = (selectedRow: number) => {
		const { selectedRows } = this.state

		this.setState(() => ({
			selectedRows: [...(selectedRows || []), selectedRow]
		}))
	}

	handleDeleteProduct = (selectedRow: number) => {
		const { selectedRows } = this.state

		this.setState(() => ({
			selectedRows: selectedRows?.filter((row) => row !== selectedRow)
		}))
	}

	handleSelectAll = (action: string) => {
		const { products } = this.props
		const { selectedRows } = this.state
		if (action === 'add') {
			const selectAll: Array<any> = []
			products &&
				_.forEach(products.productos, (item) => {
					selectAll.push(item.nisuger)
				})
			this.setState(() => ({
				selectedRows: selectAll
			}))
		} else if (action === 'delete') {
			this.setState(() => ({
				selectedRows: selectedRows?.filter((id: any) => false)
			}))
		}
	}

	renderSearchProducts = () => {
		const { fieldsConfig } = this.props
		return (
			<>
				<SearchProductsForm
					fields={getFiltersData(fieldsConfig.campos, {
						agrupador: 'formulario'
					})}
					isSearchProducts={true}
					handleSendSearch={this.handleSendSearch}
				/>
				<SearchProductsTable
					fields={getFiltersData(fieldsConfig.campos, {
						agrupador: 'grilla'
					})}
				/>
			</>
		)
	}

	/**
	 * to close search products modal
	 */
	handleCloseSearchModal = () => {
		this.setState({ showSearchModal: false })
	}

	/**
	 * to open and close search modal
	 */

	handleToggleSearchModal = () => {
		this.setState((state) => ({
			showSearchModal: !state.showSearchModal
		}))
	}

	handleSendSearch = (values: any) => {
		const { idOperacion } = this.props
		this.setState({ showSearchModal: false })
		this.props.selectSearchedProducts({ ...values, idOperacion })
	}

	render() {
		const { config, t, idOperacion, products, productSelection } = this.props
		const { showSearchModal, selectedRows } = this.state

		const fieldsFilter = config
			? getFiltersData(config.campos, { agrupador: 'formulario' })
			: []
		const allFields = [
			...fieldsFilter,
			...(config ? getFiltersData(config.campos, { agrupador: 'filtros' }) : [])
		]
		const tableFields = config
			? getFiltersData(config.campos, { agrupador: 'grilla' })
			: []

		const formProps = {
			fields: allFields,
			t: t,
			idOperacion: idOperacion,
			handleFilterChange: this.handleSearchFilter,
			handleProductChange: this.handleSearchProduct,
			handleOpenModal: this.handleToggleSearchModal
		}

		const tableProps = {
			products: products,
			tableFields: tableFields,
			disableVoucher: false,
			handlePaginationChange: this.handleSearchPagination,
			handleChange: this.handleChange,
			handleProductSelect: this.handleSelection,
			handleAddProduct: this.handleAddProduct,
			handleDeleteProduct: this.handleDeleteProduct,
			handleSelectAll: this.handleSelectAll,
			selectedRows: selectedRows
		}

		const propsSearchModal = {
			showModal: showSearchModal,
			handleCloseModal: this.handleToggleSearchModal,
			modalTitle: 'Busqueda de productos',
			modalBody: showSearchModal ? this.renderSearchProducts() : <></>,
			buttons: false,
			sizeModal: 'xl'
		}

		return (
			<Col className={`col-12`}>
				<SugestedProductsForm {...formProps} />
				{products && productSelection && (
					<SugestedProductsTable {...tableProps} />
				)}
				{showSearchModal && <CommonModal {...propsSearchModal} />}
			</Col>
		)
	}
}

const mapStateToProps = ({
	voucher,
	auth,
	sugestedProducts,
	configCrud
}: any) => {
	const config = voucher.config ? voucher.config[P_CONF_PROD_SUG] : null
	const { products, productSelection, productPriority } = sugestedProducts
	const { fieldsConfig } = configCrud
	const { authUser } = auth
	return {
		productSelection,
		productPriority,
		config,
		authUser,
		fieldsConfig,
		products
	}
}

const connectForm = connect(mapStateToProps, {
	getSugestedProducts,
	getConfigVoucher,
	setSugestedProductPriority,
	setSugestedProductSelection,
	clearSugestedProductsStore,
	getConfigFieldsCrud,
	selectSearchedProducts,
	deleteSearchedProducts
})(SugestedProducts)

export default withTranslation()(withRouter(connectForm))
