import TableWithSelection from 'components/abmUtils/TableWithSelection'
import { ICrudCompleteFields } from 'models/CrudInterface'
import { IGetEquivalentProductsResponseArray } from 'models/EquivalentProducts'
import React, { Component } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import _ from 'underscore'

interface IEquivalentProductsProps extends WithTranslation {
	tableFields: Array<ICrudCompleteFields>
	products: IGetEquivalentProductsResponseArray
	disableVoucher: boolean
	handlePaginationChange: (page_number: number, page_size: number) => void
	handleProductSelect: (selection: Array<number>) => void
	disabledProducts: Array<number>
}

interface IEquivalentProductsState {
	selectedRows?: Array<number>
}

class EquivalentProductsTable extends Component<
	IEquivalentProductsProps,
	IEquivalentProductsState
> {
	constructor(props: IEquivalentProductsProps) {
		super(props)
		this.state = {
			selectedRows: _.map(
				props.products.productos.filter((product) => product.select === 1),
				(selection) => selection.niprod
			)
		}
	}

	componentDidUpdate = (
		prevProps: IEquivalentProductsProps,
		prevState: IEquivalentProductsState
	) => {
		const { products, handleProductSelect } = this.props
		const { selectedRows } = this.state

		if (products && prevProps.products !== products && !_.isEmpty(products)) {
			const selectArray: Array<number> = []
			_.forEach(products.productos, (producto) => {
				producto.select === 1 && selectArray.push(producto.niprod)
			})
			this.setState({ selectedRows: selectArray })
		}

		if (selectedRows && prevState.selectedRows !== selectedRows) {
			handleProductSelect(selectedRows)
		}
	}

	handleChangeTable = (type: String, pagination: any, value: any) => {
		const { disableVoucher, handlePaginationChange } = this.props
		if (type === 'pagination') {
			if (!disableVoucher) {
				handlePaginationChange(pagination.page, pagination.sizePerPage)
				this.setState({
					selectedRows: undefined
				})
			}
		}
	}

	handleSelection = (selectedRow: number, table: string, action: string) => {
		const { selectedRows } = this.state

		if (action === 'add') {
			this.setState(() => ({
				selectedRows: [...(selectedRows || []), selectedRow]
			}))
		} else if (action === 'delete') {
			this.setState(() => ({
				selectedRows: selectedRows?.filter((row) => row !== selectedRow)
			}))
		}
	}

	render() {
		const { products, tableFields, disabledProducts } = this.props
		const { selectedRows } = this.state

		const propsTable = {
			fields: products && products.productos,
			tableFields: tableFields,
			disableForm: false,
			keyfield: 'niprod',
			sustainsClick: false,
			doDoubleClick: false,
			disableClick: true,
			hideScrollX: true,
			hideScrollY: true,
			selected: selectedRows || [],
			setSelection: this.handleSelection,
			paginationOption: products || null,
			handleChangeTable: this.handleChangeTable,
			label: 'EquivalentPTable',
			checkBox: true,
			height: '100%',
			nonSelectableRows: disabledProducts
		}

		return (
			<>
				<TableWithSelection {...propsTable} />
			</>
		)
	}
}

export default withTranslation()(EquivalentProductsTable)
