import { confirmationProvider, getProvider, searchProviders } from 'actions'
import { P_SELPROV } from 'constants/ConfigProcessNames'
import { Formik } from 'formik'
import { getValidationSchema } from 'lib/FieldValidations'
import React, { Component, Fragment } from 'react'
import { Col, Form } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import NotificationsErrors from '../common/notificationsErrors'
import AccountFormInput from '../voucher/accountFormInput'
import ClientFormInput from '../voucher/clientFormInput'
import LocationFormInput from '../voucher/locationFormInput'
import VoucherFormInput from '../voucher/voucherFormInput'
import ProviderGenericInputs from './providerGenericInputs'

class ProviderForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			showError: false,
			errorMessage: '',
			urlForm: '',
			providerParams: ''
		}

		this.firtsRefs = React.createRef()
		this.inputsForm = {
			searchInpt: 'proveedor_criterio',
			razonSocial: 'proveedor_razon_social',
			codigo: 'proveedor_codigo',
			tipoResp: 'proveedor_tipo_resp',
			cuit: 'proveedor_identificador',
			contact: 'proveedor_contacto',
			phone: 'proveedor_telefono',
			email: 'proveedor_email',
			address: 'proveedor_domocilio',
			location: 'proveedor_localidad',
			province: 'proveedor_provincia',
			codPos: 'proveedor_cpos',
			obsCC: 'proveedor_obs_cc',
			obsTrans: 'proveedor_obs_cpa',
			balance: 'proveedor_saldo'
		}
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		// this.props.formConfirmation(this.handleConfirmation); //No es necesario en esta pantalla

		if (idOperacion) {
			this.handleConfirmation = this.handleConfirmation.bind(this)
		}
	}

	componentDidUpdate = (prevProps) => {
		if (
			this.props.listProviders !== prevProps.listProviders &&
			this.props.listProviders !== null
		) {
			this.setState({ loading: false })
		}

		if (this.props.config !== prevProps.config) {
			if (this.firtsRefs.current) {
				this.firtsRefs.current.focus()
			}
		}
	}

	handleConfirmation = () => {
		const { provider, idOperacion, callBackReturn } = this.props
		if (provider) {
			this.props.confirmationProvider({
				provider: { idOperacion, idProveedor: provider.idProveedor },
				callBackReturn
			})
		}
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	handleSearch = (value) => {
		const { idOperacion } = this.props
		this.props.searchProviders({ criterio_proveedor: value, idOperacion })
		this.setState({ loading: true })
	}

	handleSelect = (prov) => {
		const { idOperacion } = this.props
		const selected = prov[0]

		if (selected) {
			this.props.getProvider({ IdProveedor: selected.id, idOperacion })
			this.setState({ providerParams: selected.label })
			this.setState({ cliente_criterio: selected.label })
			this.setState({ loading: false })
		}
	}

	render() {
		const { listProviders, provider, config, t } = this.props

		const defaultInitial = {
			proveedor_razon_social: '',
			proveedor_codigo: '',
			proveedor_tipo_resp: '',
			proveedor_identificador: '',
			proveedor_contacto: '',
			proveedor_obs_cc: '',
			proveedor_obs_vta: '',
			proveedor_saldo: '',
			proveedor_email: '',
			proveedor_telefono: '',
			proveedor_domicilio: '',
			proveedor_localidad: '',
			proveedor_provincia: '',
			proveedor_cpos: '',
			proveedor_criterio: this.state.providerParams
		}

		const initial = provider
			? { ...provider, proveedor_criterio: this.state.providerParams }
			: defaultInitial
		const optionsSync = listProviders
			? listProviders.proveedores.map((opt) => {
					return { id: opt.idProveedor, label: opt.rsocial }
			  })
			: []

		const validationSchema = config ? getValidationSchema(config.campos, t) : {}

		if (config) {
			return (
				<Col sm={12} className={'mb-1'}>
					<Formik
						ref={this.props.formRef}
						initialValues={{ ...initial }}
						onSubmit={(values, actions) => this.handleConfirmation()}
						validationSchema={validationSchema}
						enableReinitialize={true}
						render={({
							values,
							handleBlur,
							setErrors,
							handleChange,
							errors,
							touched,
							isSubmitting,
							handleSubmit,
							setFieldValue,
							setFieldTouched
						}) => (
							<Fragment>
								<Col sm={11}>
									<NotificationsErrors
										errors={errors}
										setErrors={setErrors}
										touched={touched}
									/>
								</Col>
								<Form onSubmit={handleSubmit} className="voucher-info-form">
									<Col>
										<VoucherFormInput
											inputsForm={this.inputsForm}
											handleSearch={this.handleSearch}
											auoptions={optionsSync}
											handleLoading={this.state.loading}
											handleSelect={this.handleSelect}
											firtsRefs={this.firtsRefs}
											fields={config ? config.campos : null}
											{...{
												values,
												handleBlur,
												handleChange,
												errors,
												touched,
												isSubmitting,
												handleSubmit,
												setFieldValue,
												setFieldTouched
											}}
										/>
									</Col>
									<div className="dropdown-divider col-11 p-1" />
									<Col>
										<ClientFormInput
											inputsForm={this.inputsForm}
											fields={config ? config.campos : null}
											{...{
												values,
												handleBlur,
												handleChange,
												errors,
												touched,
												isSubmitting,
												handleSubmit,
												setFieldValue,
												setFieldTouched
											}}
										/>
										<LocationFormInput
											inputsForm={this.inputsForm}
											fields={config ? config.campos : null}
											{...{
												values,
												handleBlur,
												handleChange,
												errors,
												touched,
												isSubmitting,
												handleSubmit,
												setFieldValue,
												setFieldTouched
											}}
										/>
									</Col>
									<div className="dropdown-divider col-11 p-1" />
									<Col>
										<AccountFormInput
											inputsForm={this.inputsForm}
											fields={config ? config.campos : null}
											{...{
												values,
												handleBlur,
												handleChange,
												errors,
												touched,
												isSubmitting,
												handleSubmit,
												setFieldValue,
												setFieldTouched
											}}
										/>
									</Col>
									<div className="dropdown-divider col-11 p-1" />

									{config && (
										<ProviderGenericInputs
											config={config}
											provider={provider}
										/>
									)}
								</Form>
							</Fragment>
						)}
					/>
				</Col>
			)
		} else {
			return null
		}
	}
}

const mapStateToProps = ({ voucher, vouchertype, providers }) => {
	const { listProviders, provider } = providers
	const config = voucher.config ? voucher.config[P_SELPROV] : null
	const { voucherTypeCancel } = vouchertype
	return { config, voucherTypeCancel, listProviders, provider }
}

export default connect(mapStateToProps, {
	searchProviders,
	getProvider,
	confirmationProvider
})(withTranslation()(withRouter(ProviderForm)))
