import { faBuilding, faLock, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NotificationMessage from 'components/common/notificationMessage'
import NotificationsErrors from 'components/common/notificationsErrors'
import InputGroupText from 'components/form/inputGroupText'
import { Formik } from 'formik'
import { isLoggedIn } from 'lib/AuthUtils'
import React, { Component, Fragment } from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as Yup from 'yup'
import { selectCompany, userSignIn } from '../../actions'
import { LANDING } from '../../utils/RoutePath'

class LoginForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showError: false,
			errorMessage: '',
			type: 'danger',
			showCompanies: false,
			company: null
		}
	}

	componentDidMount = () => {
		const { auth } = this.props
		if (isLoggedIn(auth) && auth.authUser.userCompany) {
			const { userCompany } = auth.authUser
			this.props.history.push(`${LANDING}/${userCompany}`) // Accion para cuando se carga el form y se encuentra logeado
		}
	}

	componentDidUpdate = (prevProps) => {
		const { auth, common, t } = this.props

		if (
			auth &&
			isLoggedIn(auth) &&
			prevProps.auth.authUser !== auth.authUser &&
			auth.authUser.userCompany
		) {
			const { userCompany } = auth.authUser
			this.props.history.push(`${LANDING}/${userCompany}`) // Accion para cuando se carga el form y se encuentra logeado
		}

		if (prevProps.common.error !== common.error && common.error) {
			this.setState({ showError: true, errorMessage: t(common.error.trim()) })
		}
	}

	handleSelectCompany = () => {
		const { company } = this.state
		this.props.selectCompany({ company })
	}

	render() {
		const { t, auth } = this.props
		const { authUser } = auth

		const initial = {
			user: '',
			pass: ''
		}
		const companies =
			authUser && authUser.empresas
				? authUser.empresas.map((company) => {
						return { id: company.id, label: company.razonSocial }
				  })
				: []

		return (
			<Col sm={12} className={'mb-1'} style={{ margin: '0px auto' }}>
				<Formik
					initialValues={{ ...initial }}
					onSubmit={(values, actions) => {
						this.props.userSignIn(values)
						actions.setSubmitting(false)
					}}
					validationSchema={Yup.object().shape({
						user: Yup.string().required(
							t('validation-required', { field: t('login.form.username') })
						),
						pass: Yup.string().required(
							t('validation-required', { field: t('login.form.password') })
						)
					})}
					render={({
						values,
						errors,
						touched,
						handleSubmit,
						setFieldValue,
						setErrors
					}) => (
						<Form onSubmit={handleSubmit} className="voucher-info-form">
							<Col sm={12}>
								<NotificationMessage
									{...this.state}
									handleCloseError={this.handleCloseNotification}
								/>
							</Col>
							<Col sm={12}>
								<NotificationsErrors
									errors={errors}
									touched={touched}
									setErrors={setErrors}
								/>
							</Col>
							{!this.state.showCompanies ? (
								<Fragment>
									<Col sm={12}>
										<InputGroupText
											label={t('login.form.username')}
											inputId={'user'}
											name={'user'}
											placeholder={t('login.form.insert_username')}
											colLabel={'col-3 offset-3'}
											colInput={'col-6'}
											beforeInput={<FontAwesomeIcon icon={faUser} />}
											styles={{
												width: '70%',
												backgroundColor: '#E8F0FD',
												border: '#E8F0FD',
												color: '#000'
											}}
											stylesGroup={{
												backgroundColor: '#E8F0FD',
												border: '#E8F0FD'
											}}
											value={values.user}
											onChange={(data) => {
												setFieldValue('user', data.target.value)
											}}
										/>
									</Col>
									<Col sm={12}>
										<InputGroupText
											label={t('login.form.password')}
											inputId={'pass'}
											name={'pass'}
											type={'password'}
											placeholder={t('login.form.insert_password')}
											colLabel={'col-3 offset-3'}
											colInput={'col-6'}
											beforeInput={<FontAwesomeIcon icon={faLock} />}
											styles={{
												width: '70%',
												backgroundColor: '#E8F0FD',
												border: '#E8F0FD'
											}}
											stylesGroup={{
												backgroundColor: '#E8F0FD',
												border: '#E8F0FD'
											}}
											value={values.pass}
											onChange={(data) => {
												setFieldValue('pass', data.target.value)
											}}
										/>
									</Col>
									<Col
										style={{ textAlign: 'left', paddingLeft: '15px' }}
										className={'mt-2 col-6 offset-3'}
									>
										<Button type={'submit'}>{t('form.button.submit')}</Button>
									</Col>
								</Fragment>
							) : (
								<Fragment>
									<Col sm={12}>
										<InputGroupText
											label={t('login.form.companies')}
											inputId={'company'}
											name={'company'}
											type={'dropdown'}
											selectOptions={companies}
											placeholder={t('login.form.select_company')}
											colLabel={'col-3'}
											colInput={'col-6'}
											beforeInput={<FontAwesomeIcon icon={faBuilding} />}
											styles={{
												width: '70%',
												backgroundColor: '#E8F0FD',
												border: '#E8F0FD'
											}}
											stylesGroup={{
												backgroundColor: '#E8F0FD',
												border: '#E8F0FD'
											}}
											value={values.company}
											onChange={(data) => {
												setFieldValue('company', data.target.value)
												this.setState({ company: data.target.value })
											}}
										/>
									</Col>
									<Col
										style={{ textAlign: 'left', paddingLeft: '15px' }}
										className={'mt-2 col-6 offset-3'}
									>
										<Button type={'button'} onClick={this.handleSelectCompany}>
											{t('form.button.select')}
										</Button>
									</Col>
								</Fragment>
							)}
						</Form>
					)}
				/>
			</Col>
		)
	}
}

const mapStateToProps = ({ auth, common }) => {
	return { auth, common }
}

export default connect(mapStateToProps, { userSignIn, selectCompany })(
	withTranslation()(withRouter(LoginForm))
)
