import NotificationError from 'components/common/notificationsErrors'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { Formik } from 'formik'
import { getInitValues } from 'lib/FieldValidations'
import { renderFormInputs } from 'lib/FormUtils'
import React, { Component, Fragment } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'

const noRenderInputs = ['cancelar', 'aceptar']

type ComponentProps = {
	fields: Array<IFieldStructure>
	keyField?: string
	handleSubmit: any
	itemInfo?: any
	disableForm?: boolean
	handleCloseModal: any
}

type ComponentState = {
	collapseTable: boolean
	showEditPopup: boolean
}

class ComponentUpdate extends Component<ComponentProps, ComponentState> {
	/**
	 * to add callbacks to fields.
	 * @param {object} fields
	 * @param {object} props
	 * @returns html inputs
	 */

	/**
	 * to show the form fields from the api configuration     *
	 */
	renderInputs = (fields: Array<IFieldStructure>, props: any) => {
		const { disableForm, itemInfo, keyField } = this.props

		fields.forEach((field: any): any => {
			if (field.idCampo === keyField && itemInfo) {
				field.disabled = true
			} else {
				field.disabled = false
			}
		})

		return renderFormInputs(props, fields, noRenderInputs, disableForm || false)
	}

	/**
	 * to get data of sellers
	 */

	render() {
		const { fields, handleSubmit, itemInfo, disableForm } = this.props
		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})
		const columnsCancel = disableForm ? 12 : 6
		// const validationSchema = fields.length ? getValidationSchema(fields, t, []) : {};
		const validationSchema = {}
		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values, actions) => {
					values.habilitado === true
						? (values.habilitado = 1)
						: (values.habilitado = 0)
					handleSubmit(values)
				}}
				validationSchema={validationSchema}
				enableReinitialize={true}
				render={(props) => (
					<Fragment>
						<Col sm={12}>
							<NotificationError
								errors={props.errors}
								touched={props.touched}
							/>
						</Col>
						<Form
							onSubmit={(e) => props.handleSubmit(e as any)}
							className="voucher-info-form"
						>
							{fields && this.renderInputs(fields, props)}

							<Row className={'pt-3'}>
								{!disableForm && (
									<Col sm={6} className={'text-center mt-2'}>
										<Button style={{ minWidth: '145px' }} type={'submit'}>
											{'Aceptar'}
										</Button>
									</Col>
								)}
								<Col sm={columnsCancel} className={'text-center mt-2'}>
									<Button
										style={{ minWidth: '145px' }}
										type={'button'}
										onClick={this.props.handleCloseModal}
									>
										{'Cancelar'}
									</Button>
								</Col>
							</Row>
						</Form>
					</Fragment>
				)}
			/>
		)
	}
}

export default ComponentUpdate
