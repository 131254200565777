import Axios from 'axios'

export const purchasesQuantityAffected = async (params) => {
	const response = await Axios.get('/AfectaCompras/Cantidad', { params })
	return response.data
}

export const purchasesAffectedValidate = async (params) => {
	const response = await Axios.post('/Afectacompras/cantidad/validar', params)
	return response.data
}

export const purchasesAffectedSubCalculation = async (params) => {
	const response = await Axios.get(
		'/AfectaCompras/Cantidad/CalculoSubtotales',
		{ params }
	)
	return response.data
}

export const purchasesAffectedConfirm = async (params) => {
	const response = await Axios.post('/Afectacompras/cantidad/confirmar', params)
	return response.data
}

export const purchasesAffectedImport = async (params) => {
	const response = await Axios.get('/AfectaCompras/Importe', { params })
	return response.data
}

export const purchasesAffectedImpValidate = async (params) => {
	const response = await Axios.post('/AfectaCompras/Importe/Validar', params)
	return response.data
}

export const purchasesValidateDate = async (params) => {
	const response = await Axios.post(
		'/AfectaCompras/importe/Validar/Fechas',
		params
	)
	return response.data
}

export const purchasesAffectedImportConfirm = async (params) => {
	const response = await Axios.post('/AfectaCompras/importe/Confirmar', params)
	return response.data
}

export const purchasesAffectedState = async (params) => {
	const response = await Axios.get('/AfectaCompras/Estado', { params })
	return response.data
}

export const purchasesAffectedStateValidate = async (params) => {
	const response = await Axios.post('/AfectaCompras/Estado/Validar', params)
	return response.data
}

export const purchasesAffectedStateConfirm = async (params) => {
	const response = await Axios.post('/AfectaCompras/Estado/Confirmar', params)
	return response.data
}
