import InputTextArea from 'components/form/InputTextArea'
import React, { Component, Fragment } from 'react'
import { Card } from 'react-bootstrap'
import styles from './generateForm.module.scss'
interface IObservationsForm {
	clientHeadboard: any
	updateObservations: (data: string) => void
	observationValue: string
}

class ObservationsForm extends Component<IObservationsForm> {
	render() {
		const { clientHeadboard, observationValue } = this.props

		const fields: any = [
			{
				idCampo: 'texto',
				descripcion: 'Fecha de cobro',
				label: null,
				editable: 1,
				visible: 1,
				requerido: '0',
				valid: null,
				mascara: null
			}
		]

		const textAreaProps = {
			inputFormCol: { sm: 11 },
			fields,
			label: '',
			inputId: 'texto',
			name: 'texto',
			placeholder: '',
			styles: { width: '100%', paddingRight: '0px' },
			colLabel: 'col-sm-0',
			colInput: 'col-sm-12',
			cols: 3,
			rows: 3,
			value: observationValue,
			onChange: (data: string) => this.props.updateObservations(data)
		}

		return (
			clientHeadboard &&
			clientHeadboard.observaciones && (
				<Fragment>
					<Card className={`pb-3 pt-3 mb-3 ${styles.containerCard}`}>
						<div className="p-2 font-weight-bold">{'Observaciones'}</div>
						<InputTextArea {...textAreaProps} />
					</Card>
				</Fragment>
			)
		)
	}
}

export default ObservationsForm
