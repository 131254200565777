import {
	CRUD_GET_FUND_ACCOUNT_BANK_RECOINCILIATION,
	CRUD_GET_FUND_ACCOUNT_BANK_RECOINCILIATION_SUCCESS,
	CRUD_UPDATE_FUND_ACCOUNT_BANK_RECOINCILIATION,
	CRUD_UPDATE_FUND_ACCOUNT_BANK_RECOINCILIATION_SUCCESS
} from '../constants/ActionsTypes'
import {
	getValueInfoParamsInterface,
	updateValueParamsInterface
} from '../constants/valuesInterfaces/interfaces'

export const crudGetFundAccountBankReconciliation = (
	payload: getValueInfoParamsInterface
) => ({
	type: CRUD_GET_FUND_ACCOUNT_BANK_RECOINCILIATION,
	payload
})

export const crudGetFundAccountBankReconciliationSuccess = (payload: any) => ({
	type: CRUD_GET_FUND_ACCOUNT_BANK_RECOINCILIATION_SUCCESS,
	payload
})

export const crudUpdateFundAccountBankReconciliation = (
	payload: updateValueParamsInterface
) => ({
	type: CRUD_UPDATE_FUND_ACCOUNT_BANK_RECOINCILIATION,
	payload
})

export const crudUpdateFundAccountBankReconciliationSuccess = (
	payload: any
) => ({
	type: CRUD_UPDATE_FUND_ACCOUNT_BANK_RECOINCILIATION_SUCCESS,
	payload
})
