import {
	loadItemIncomeInventorySuccess,
	loadItemInventoryConfirmSuccess,
	loadItemInventorySuccess
} from 'actions'
import {
	loadItemIncomeInventoryApi,
	loadItemInventoryApi,
	loadItemInventoryConfirmApi
} from 'api/LoadInventoryItems'
import { LoadInventoryItemsActions } from 'constants/ActionsTypesTs'
import {
	ILoadItemIncomeInventoryAction,
	ILoadItemInventoryAction,
	ILoadItemInventoryConfirmAction
} from 'models/LoadInventoryItems'
import { IResponseResult } from 'models/ResultsInterface'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

function* loadItemInventoryRequest({ payload }: ILoadItemInventoryAction): any {
	try {
		const ledgerAccounts = yield call(loadItemInventoryApi, payload)
		yield put(loadItemInventorySuccess(ledgerAccounts))
	} catch (error) {}
}

function* loadItemIncomeInventoryRequest({
	payload
}: ILoadItemIncomeInventoryAction): any {
	try {
		const ledgerAccounts = yield call(loadItemIncomeInventoryApi, payload)
		yield put(loadItemIncomeInventorySuccess(ledgerAccounts))
	} catch (error) {}
}

function* loadItemInventoryConfirmRequest({
	payload
}: ILoadItemInventoryConfirmAction): any {
	try {
		const { params, callBackReturn } = payload
		const resp: IResponseResult = yield call(
			loadItemInventoryConfirmApi,
			params
		)
		yield put(loadItemInventoryConfirmSuccess(resp))

		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error: any) {}
}

export function* loadItemInventorySaga() {
	yield takeLatest(
		LoadInventoryItemsActions.LOAD_ITEM_INVENTORY,
		loadItemInventoryRequest
	)
}

export function* loadItemInventoryConfirmSaga() {
	yield takeLatest(
		LoadInventoryItemsActions.LOAD_ITEM_INVENTORY_CONFIRM,
		loadItemInventoryConfirmRequest
	)
}

export function* loadItemIncomeInventorySaga() {
	yield takeLatest(
		LoadInventoryItemsActions.LOAD_ITEM_INCOME_INVENTORY,
		loadItemIncomeInventoryRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(loadItemInventorySaga),
		fork(loadItemInventoryConfirmSaga),
		fork(loadItemIncomeInventorySaga)
	])
}
