import {
	faEye,
	faPaperclip,
	faShareAlt,
	faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { showMessage } from 'actions'
import CommonModal from 'components/common/commonModal'
import CommonTable from 'components/common/commonTable'
import NotificationMessage from 'components/common/notificationMessage'
import React, { Component, Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'underscore'
import { SEARCH_VOUCHER_DETAIL } from 'utils/RoutePath'
import AttachModal from './AttachModal'

class SearchVoucherTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showError: false,
			errorMessage: '',
			typeError: 'danger',
			showAttachModal: false,
			voucherSelected: null
		}
		this.primaryKey = ''
	}

	componentDidUpdate = (prevProps) => {
		const { searchedVouchers, documentDelete } = this.props

		if (prevProps.searchedVouchers !== searchedVouchers && searchedVouchers) {
			let typeError = ''
			if (_.isEmpty(searchedVouchers.Items)) {
				typeError = 'danger'
			} else {
				typeError = 'success'
			}
			this.setState({
				showError: true,
				errorMessage: searchedVouchers.Resultado.Mens_error,
				typeError
			})
		}

		if (
			!_.isEqual(documentDelete, prevProps.documentDelete) &&
			!_.isEmpty(documentDelete) &&
			documentDelete.status === 'succeeded'
		) {
			this.props.showMessage({
				type: 'success',
				description: 'Se elimino el documento correctamente',
				showError: true
			})
		}
	}

	getColumns = () => {
		const { fields } = this.props

		const rows = fields.map((field) => {
			const idField = field.idCampo.trim()
			const hide = field.visible
			return {
				dataField: idField,
				text: field.label,
				align: 'center',
				headerAlign: 'center',

				hidden: !hide
			}
		})

		rows.push({
			dataField: 'actions',
			text: '',
			align: 'center',
			headerAlign: 'center',
			headerStyle: { width: '10%' },
			formatter: (cell, row) => {
				return (
					<Fragment>
						<Link to={`${SEARCH_VOUCHER_DETAIL}/${row.id}/consult`}>
							<FontAwesomeIcon
								className={'mr-2'}
								icon={faEye}
								style={{ cursor: 'pointer' }}
								color={'#007bff'}
							/>
						</Link>
						<Link to={`${SEARCH_VOUCHER_DETAIL}/${row.id}/remove`}>
							<FontAwesomeIcon
								className={'mr-2'}
								icon={faTrashAlt}
								style={{ cursor: 'pointer' }}
								color={'#007bff'}
							/>
						</Link>
						<FontAwesomeIcon
							className={'mr-2'}
							icon={faShareAlt}
							style={{ cursor: 'pointer' }}
							color={'#007bff'}
						/>
						<FontAwesomeIcon
							className={'mr-2'}
							icon={faPaperclip}
							style={{ cursor: 'pointer' }}
							color={'#007bff'}
							onClick={() => this.handleToggleModal(row)}
						/>
					</Fragment>
				)
			}
		})

		return rows
	}

	/**
	 * to close error  banner
	 */
	handleCloseError = () => {
		this.setState({ showError: false })
	}

	handleToggleModal = (row) => {
		this.setState((state) => ({
			showAttachModal: !state.showAttachModal,
			voucherSelected: row ?? null
		}))
	}

	render() {
		const { fields, searchedVouchers, t } = this.props
		const {
			showError,
			errorMessage,
			typeError,
			showAttachModal,
			voucherSelected
		} = this.state
		const paginationOptions = searchedVouchers
			? {
					pageStartIndex: 1,
					sizePerPage: searchedVouchers.page_size,
					page: searchedVouchers.page_number,
					totalSize: searchedVouchers.total_count
			  }
			: {}
		const propsTable = {
			remote: true,
			columns: this.getColumns(),
			keyField: this.primaryKey,
			data: searchedVouchers ? searchedVouchers.Items : [],
			rowClasses: 'theme.tableRow',
			headerClasses: 'theme.tableHeader',
			paginationOptions,
			onTableChange: this.props.handleChangeTable,
			pagination: !_.isEmpty(searchedVouchers)
		}

		return (
			<Fragment>
				<NotificationMessage
					showError={showError}
					errorMessage={errorMessage}
					handleCloseError={this.handleCloseError}
					type={typeError}
				/>
				<Col className={`col-12 pl-0 pr-0`}>
					{fields.length > 0 && <CommonTable {...propsTable} />}
				</Col>
				{showAttachModal && (
					<Col className={`col-12 pl-0 pr-0`}>
						<CommonModal
							showModal={showAttachModal}
							handleCloseModal={this.handleToggleModal}
							modalTitle={t('search_voucher.attach_modal.title')}
							modalBody={<AttachModal voucherSelected={voucherSelected} />}
							sizeModal="lg"
						/>
					</Col>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = ({ searchVoucher, filesManager }) => {
	const { searchedVouchers } = searchVoucher
	const { documentDelete } = filesManager

	return { searchedVouchers, documentDelete }
}

export default connect(mapStateToProps, { showMessage })(
	withTranslation()(SearchVoucherTable)
)
