import {
	confirmInventoryPickStock,
	getDepositsPickStock,
	getInventoryPickStock,
	inventoryPickStockSearchBarCode
} from 'actions'
import CommonAudio from 'components/common/CommonAudio/CommonAudio'
import CommonModal from 'components/common/commonModal'
import NotificationMessage from 'components/common/notificationMessage'
import BarCodeErrorForm from 'components/providerPickStock/BarCodeErrorForm'
import { API_FORMAT_DATE } from 'constants/ApiFormats'
import { P_INV_ING_ITEM_PIC } from 'constants/ConfigProcessNames'
import { Form, Formik, FormikProps } from 'formik'
import { addPrevValidations } from 'lib/AxiosInterceptors'
import { getValidationSchema } from 'lib/FieldValidations'
import { getFiltersData } from 'lib/Utils'
import { IGetDepositsParams } from 'models/AutomaticPurchase'
import { IConfigFieldsForm } from 'models/Budget'
import { ICommonComponent } from 'models/CommonInterface'
import {
	IConfirmInventoryPickStockPayload,
	IGetInventoryPickStockParams,
	IGetInventoryPickStockResponse,
	InventoryForm,
	InventorySearchBarcodeParams,
	ISearchBarcodeErrorResponse
} from 'models/InventoryPickStock'
import moment from 'moment'
import React, { Component, forwardRef, Fragment, Ref } from 'react'
import { Col } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import InventoryPickStockBarCode from './InventoryPickStockBarCode/InventoryPickStockBarCode'
import InventoryPickStockTable from './InventoryPickStockTable'

interface InventoryPickStockFormProps
	extends WithTranslation,
		Partial<ICommonComponent> {
	t?: any
	config: IConfigFieldsForm
	inventoryFormRef: any
	getInventoryPickStock: (payload: IGetInventoryPickStockParams) => void
	isVisibleBarCode: boolean
	inventoryPickStockSearchBarCode: (
		payload: InventorySearchBarcodeParams
	) => void
	inventoryBarCodeItems: IGetInventoryPickStockResponse
	inventoryBarCodeError: ISearchBarcodeErrorResponse
	inventoryBarCodeParams: InventorySearchBarcodeParams
	getDepositsPickStock: (payload: IGetDepositsParams) => void
	confirmInventoryPickStock: (
		payload: IConfirmInventoryPickStockPayload
	) => void
}

interface InventoryPickStockFormState {
	showConfirmationPopup: boolean
	errorMessage: any
	titleBarCodeError: string
	buttonsConfirmation: boolean
	showError: boolean
	typeError: string
}

class InventoryPickStockForm extends Component<
	InventoryPickStockFormProps,
	InventoryPickStockFormState
> {
	inventoryPickStockBarCodeRef: any
	audioComponentRef: any
	constructor(props: InventoryPickStockFormProps) {
		super(props)
		this.inventoryPickStockBarCodeRef = React.createRef()
		this.audioComponentRef = React.createRef()
		this.state = {
			buttonsConfirmation: false,
			errorMessage: null,
			showConfirmationPopup: false,
			showError: false,
			titleBarCodeError: '',
			typeError: ''
		}

		addPrevValidations((e: any) => {
			return e.request.responseURL.includes('/stock/ingr_pickeo/cod_barra')
		})
	}

	componentDidMount(): void {
		const { idOperacion } = this.props

		this.props.getDepositsPickStock({ idOperacion: idOperacion || '' })

		this.props.getInventoryPickStock({
			idOperacion: idOperacion || '',
			page_size: 10,
			ind_filtro_fecha: 0,
			filtro_fecha: 0,
			filtro_afectados: 0,
			page_number: 1
		})
	}

	componentDidUpdate(prevProps: Readonly<InventoryPickStockFormProps>): void {
		const { inventoryBarCodeItems, inventoryBarCodeError } = this.props
		if (
			inventoryBarCodeItems !== prevProps.inventoryBarCodeItems &&
			inventoryBarCodeItems
		) {
			this.handleSoundSuccess()
		} else {
			this.handleBarCodeError(
				prevProps.inventoryBarCodeError,
				inventoryBarCodeError
			)
		}
	}

	handleBarCodeError = (
		prevBarCodeError: ISearchBarcodeErrorResponse,
		barCodeError: ISearchBarcodeErrorResponse
	) => {
		if (
			!_.isEqual(barCodeError, prevBarCodeError) &&
			!_.isEmpty(barCodeError)
		) {
			const { t } = this.props
			this.handleSoundError()
			let showError = true

			if (barCodeError.typeError === 'C') {
				showError = false
				this.setState({
					showConfirmationPopup: true,
					errorMessage: `${barCodeError.message}`,
					titleBarCodeError: t('global.confirm'),
					buttonsConfirmation: true,
					showError
				})
			} else if (barCodeError.typeError === 'S') {
				showError = false
				const { requireFields, dataFields } = barCodeError
				const errorMessage = (
					<BarCodeErrorForm
						requireFields={requireFields}
						dataFields={dataFields}
						t={t}
						handleSubmitForm={this.handleConfirmationPopup}
						handleCloseModal={this.handleCloseErrorPopup}
					/>
				)
				this.setState({
					showConfirmationPopup: true,
					errorMessage,
					titleBarCodeError: t('global.mandatory_fields'),
					buttonsConfirmation: false,
					showError: false
				})
			} else if (showError) {
				this.setState({
					showError,
					buttonsConfirmation: true,
					errorMessage: `${barCodeError.message}`,
					typeError: 'danger'
				})
			}
		}
	}

	/**
	 * to resend barcode request
	 */
	handleConfirmationPopup = (valuesForm: {
		fec_vto: string
		lote: string
		nserie: string
	}) => {
		const { inventoryBarCodeParams } = this.props

		const fec_vto = valuesForm.fec_vto
			? moment(valuesForm.fec_vto).format(API_FORMAT_DATE)
			: ''

		const params = !_.isEmpty(valuesForm)
			? { ...inventoryBarCodeParams, ...valuesForm, fec_vto }
			: { ...inventoryBarCodeParams, confirmacion: 1 }

		this.props.inventoryPickStockSearchBarCode({
			...params,
			page: 1,
			page_size: 10
		})
		this.handleCloseErrorPopup()
	}

	/**
	 * to close error popup
	 */
	handleCloseErrorPopup = () => {
		this.setState({ showConfirmationPopup: false })
	}

	/**
	 * call child function of success sound
	 */
	handleSoundSuccess = () => {
		this.audioComponentRef.current.handleSuccess()
	}

	/**
	 * call child function of success error
	 */
	handleSoundError = () => {
		this.audioComponentRef.current.handleError()
	}

	/**
	 * handle submit form and call confirm action
	 * @param values
	 */
	handleSubmitForm = (values: Partial<InventoryForm>) => {
		const { idOperacion, callBackReturn } = this.props

		this.props.confirmInventoryPickStock({
			params: { idOperacion: idOperacion || '' },
			callBackReturn
		})
	}

	toggleLastCuf = () => {
		this.inventoryPickStockBarCodeRef.current.toggleLastCuf()
	}

	toggleDisableCant = () => {
		this.inventoryPickStockBarCodeRef.current.toggleDisableCant()
	}

	handleChangeSearchInput = () => {}

	handleCloseError = () => this.setState({ showError: false })

	render() {
		const { t, config, inventoryFormRef, idOperacion, isVisibleBarCode } =
			this.props

		const {
			showConfirmationPopup,
			titleBarCodeError,
			errorMessage,
			buttonsConfirmation,
			typeError,
			showError
		} = this.state

		const fields = config
			? getFiltersData(config.campos, { agrupador: 'formulario' })
			: []

		const fieldsTable = config
			? getFiltersData(config.campos, { agrupador: 'grilla' })
			: []

		const initialValues = { cant_ns: '1', pick_cod_barra: '', ult_cuf: 0 }
		const validationSchema = fields.length ? getValidationSchema(fields, t) : {}

		return (
			<Fragment>
				<Col sm={12} className={'mb-1'}>
					<NotificationMessage
						errorMessage={errorMessage}
						showError={showError}
						handleCloseError={this.handleCloseError}
						type={typeError}
					/>
				</Col>
				<Formik
					ref={inventoryFormRef}
					initialValues={initialValues}
					onSubmit={(values) => {
						this.handleSubmitForm(values)
					}}
					validationSchema={validationSchema}
					enableReinitialize={true}
					render={(props: FormikProps<Partial<InventoryForm>>) => {
						return (
							<Fragment>
								<CommonAudio ref={this.audioComponentRef} />
								<CommonModal
									showModal={showConfirmationPopup}
									handleCloseModal={this.handleCloseErrorPopup}
									modalTitle={titleBarCodeError}
									modalBody={errorMessage}
									buttons={buttonsConfirmation}
									handleSubmit={this.handleConfirmationPopup}
								/>
								<Col sm={12}>
									<Form>
										<InventoryPickStockBarCode
											ref={this.inventoryPickStockBarCodeRef}
											formikProps={props}
											fields={fields}
											idOperacion={idOperacion || ''}
											isVisibleBarCode={isVisibleBarCode}
										/>
									</Form>
								</Col>
								<Col sm={12}>
									<InventoryPickStockTable
										fields={fieldsTable}
										idOperacion={idOperacion || ''}
									/>
								</Col>
							</Fragment>
						)
					}}
				/>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ voucher, inventoryPickStock }: any) => {
	const config = voucher.config ? voucher.config[P_INV_ING_ITEM_PIC] : null
	const {
		inventoryBarCodeItems,
		inventoryBarCodeError,
		inventoryBarCodeParams
	} = inventoryPickStock

	return {
		config,
		inventoryBarCodeItems,
		inventoryBarCodeError,
		inventoryBarCodeParams
	}
}

const InventoryPickStockFormWithRef = forwardRef(
	function InventoryPickStockFormWrapper(
		props: InventoryPickStockFormProps,
		ref: Ref<InventoryPickStockForm>
	) {
		return <InventoryPickStockForm {...props} ref={ref} />
	}
)

const mapDispatchToProps = {
	getInventoryPickStock,
	inventoryPickStockSearchBarCode,
	getDepositsPickStock,
	confirmInventoryPickStock
}

export default withTranslation('', { withRef: true })(
	connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
		InventoryPickStockFormWithRef
	)
)
