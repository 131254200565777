import {
	advancedProductSearchSuccess,
	checkItemByProductSuccess,
	finishProductCartSuccess,
	getPriceByProductSuccess,
	getProductCostSuccess,
	getProductImageError,
	getProductImageSuccess,
	getProductPriceListSuccess,
	getProductsCartSuccess,
	getProductsSuccess,
	removeProductCartSuccess,
	searchAutoProductsSuccess,
	searchProductsSuccess,
	setInputFocusSuccess,
	validateProductCostNetSuccess,
	validateProductCostPCSuccess,
	validateProductCostSuccess,
	validateProductDiscountSuccess,
	validateProductNetSuccess,
	validateProductPriceSuccess
} from 'actions'
import {
	advancedProductSearchApi,
	checkItemByProduct,
	finishProductCart,
	getPriceByProduct,
	getProductCost,
	getProductImage,
	getProductPriceListApi,
	getProducts,
	getProductsCart,
	removeProductCart,
	searchAutoProduct,
	searchProducts,
	validateProductCostApi,
	validateProductCostNetApi,
	validateProductCostPCApi,
	validateProductDiscountApi,
	validateProductNetApi,
	validateProductPriceApi
} from 'api/Product'
import {
	ADVANCED_PRODUCT_SEARCH,
	CHECK_ITEM_BY_PRODUCT,
	ERROR_IMAGE,
	FINISH_PRODUCT_CART,
	GET_PRICE_BY_PRODUCT,
	GET_PRODUCTS,
	GET_PRODUCTS_CART,
	GET_PRODUCT_COST,
	GET_PRODUCT_IMAGE,
	GET_PRODUCT_PRICE_LIST,
	REMOVE_PRODUCT_CART,
	SEARCH_AUTO_PRODUCTS,
	SEARCH_PRODUCTS,
	SET_INPUT_FOCUS,
	VALIDATE_PRODUCT_COST,
	VALIDATE_PRODUCT_COST_NET,
	VALIDATE_PRODUCT_COST_PC,
	VALIDATE_PRODUCT_DISCOUNT,
	VALIDATE_PRODUCT_NET,
	VALIDATE_PRODUCT_PRICE
} from 'constants/ActionsTypes'
import { all, call, fork, put, takeLatest, throttle } from 'redux-saga/effects'

function* getProductsRequest({ payload }) {
	try {
		const products = yield call(getProducts, payload)
		yield put(getProductsSuccess(products))
	} catch (error) {}
}

function* searchProductsRequest({ payload }) {
	try {
		const search = yield call(searchProducts, payload)
		yield put(searchProductsSuccess(search))
	} catch (error) {}
}

function* getPriceByProductRequest({ payload }) {
	try {
		const price = yield call(getPriceByProduct, payload)
		yield put(getPriceByProductSuccess(price))
	} catch (error) {}
}

function* checkItemByProductRequest({ payload }) {
	try {
		const itemResponse = yield call(checkItemByProduct, payload)
		yield put(checkItemByProductSuccess(itemResponse))
	} catch (error) {}
}

function* getProductsCartRequest({ payload }) {
	try {
		const products = yield call(getProductsCart, payload)
		yield put(getProductsCartSuccess(products))
	} catch (error) {}
}

function* getProductCostRequest({ payload }) {
	try {
		const products = yield call(getProductCost, payload)
		yield put(getProductCostSuccess(products))
	} catch (error) {}
}

function* setInputFocusRequest({ payload }) {
	try {
		yield put(setInputFocusSuccess(payload))
	} catch (error) {}
}

function* removeProductCartRequest({ payload }) {
	try {
		const products = yield call(removeProductCart, payload)
		yield put(removeProductCartSuccess(products))
	} catch (error) {}
}

function* finishProductCartRequest({ payload }) {
	try {
		const proccess = yield call(finishProductCart, payload)
		yield put(finishProductCartSuccess(proccess))
	} catch (error) {}
}

function* getProductImageRequest({ payload }) {
	try {
		const image = yield call(getProductImage, payload)
		yield put(getProductImageSuccess(image))
	} catch (error) {
		yield put(getProductImageError(ERROR_IMAGE))
	}
}

function* searchAutoProductRequest({ payload }) {
	try {
		const products = yield call(searchAutoProduct, payload)
		yield put(searchAutoProductsSuccess(products))
	} catch (error) {}
}

function* advancedProductSearchRequest({ payload }) {
	try {
		const products = yield call(advancedProductSearchApi, payload)
		yield put(advancedProductSearchSuccess(products))
	} catch (error) {}
}

function* validateProductDiscountRequest({ payload }) {
	try {
		const products = yield call(validateProductDiscountApi, payload)
		yield put(validateProductDiscountSuccess(products))
	} catch (error) {}
}

function* validateProductPriceRequest({ payload }) {
	try {
		const products = yield call(validateProductPriceApi, payload)
		yield put(validateProductPriceSuccess(products))
	} catch (error) {}
}

function* getProductPriceListRequest({ payload }) {
	try {
		const priceList = yield call(getProductPriceListApi, payload)
		yield put(getProductPriceListSuccess(priceList))
	} catch (error) {}
}

function* validateProductCostRequest({ payload }) {
	try {
		const cost = yield call(validateProductCostApi, payload)
		yield put(validateProductCostSuccess(cost))
	} catch (error) {}
}

function* validateProductCostPCRequest({ payload }) {
	try {
		const cost = yield call(validateProductCostPCApi, payload)
		yield put(validateProductCostPCSuccess(cost))
	} catch (error) {}
}

function* validateProductNetRequest({ payload }) {
	try {
		const net = yield call(validateProductNetApi, payload)
		yield put(validateProductNetSuccess(net))
	} catch (error) {}
}

function* validateProductCostNetRequest({ payload }) {
	try {
		const net = yield call(validateProductCostNetApi, payload)
		yield put(validateProductCostNetSuccess(net))
	} catch (error) {}
}

export function* getProductsSaga() {
	yield takeLatest(GET_PRODUCTS, getProductsRequest)
}

export function* searchProductsSaga() {
	yield takeLatest(SEARCH_PRODUCTS, searchProductsRequest)
}

export function* getPriceByProductSaga() {
	yield takeLatest(GET_PRICE_BY_PRODUCT, getPriceByProductRequest)
}

export function* checkItemByProductSaga() {
	yield takeLatest(CHECK_ITEM_BY_PRODUCT, checkItemByProductRequest)
}

export function* getProductsCartSaga() {
	yield takeLatest(GET_PRODUCTS_CART, getProductsCartRequest)
}

export function* setFocusInputSaga() {
	yield throttle(10000, SET_INPUT_FOCUS, setInputFocusRequest)
}

export function* removeProductCartSaga() {
	yield takeLatest(REMOVE_PRODUCT_CART, removeProductCartRequest)
}

export function* finishProductCartSaga() {
	yield takeLatest(FINISH_PRODUCT_CART, finishProductCartRequest)
}

export function* getProductImageSaga() {
	yield takeLatest(GET_PRODUCT_IMAGE, getProductImageRequest)
}

export function* getProductCostSaga() {
	yield takeLatest(GET_PRODUCT_COST, getProductCostRequest)
}

export function* searchAutoProductSaga() {
	yield takeLatest(SEARCH_AUTO_PRODUCTS, searchAutoProductRequest)
}

export function* advancedProductSearchSaga() {
	yield takeLatest(ADVANCED_PRODUCT_SEARCH, advancedProductSearchRequest)
}

export function* validateProductDiscountSaga() {
	yield takeLatest(VALIDATE_PRODUCT_DISCOUNT, validateProductDiscountRequest)
}

export function* validateProductPriceSaga() {
	yield takeLatest(VALIDATE_PRODUCT_PRICE, validateProductPriceRequest)
}

export function* getProductPriceListSaga() {
	yield takeLatest(GET_PRODUCT_PRICE_LIST, getProductPriceListRequest)
}

export function* validateProductCostSaga() {
	yield takeLatest(VALIDATE_PRODUCT_COST, validateProductCostRequest)
}

export function* validateProductCostPCSaga() {
	yield takeLatest(VALIDATE_PRODUCT_COST_PC, validateProductCostPCRequest)
}

export function* validateProductNetSaga() {
	yield takeLatest(VALIDATE_PRODUCT_NET, validateProductNetRequest)
}

export function* validateProductCostNetSaga() {
	yield takeLatest(VALIDATE_PRODUCT_COST_NET, validateProductCostNetRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getProductsSaga),
		fork(searchProductsSaga),
		fork(getPriceByProductSaga),
		fork(getProductCostSaga),
		fork(checkItemByProductSaga),
		fork(getProductsCartSaga),
		fork(setFocusInputSaga),
		fork(removeProductCartSaga),
		fork(getProductImageSaga),
		fork(finishProductCartSaga),
		fork(searchAutoProductSaga),
		fork(advancedProductSearchSaga),
		fork(validateProductDiscountSaga),
		fork(validateProductPriceSaga),
		fork(getProductPriceListSaga),
		fork(validateProductCostSaga),
		fork(validateProductCostPCSaga),
		fork(validateProductNetSaga),
		fork(validateProductCostNetSaga)
	])
}
