import { range } from 'lodash'
import React, { Component } from 'react'
import { Motion, spring } from 'react-motion'
import _ from 'underscore'
import DocumentsItems from './documentItems'

const springSetting1 = { stiffness: 180, damping: 10 }
const springSetting2 = { stiffness: 120, damping: 17 }

const reinsert = (arr, from, to) => {
	const _arr = arr.slice(0)
	const val = _arr[from]
	_arr.splice(from, 1)
	_arr.splice(to, 0, val)
	return _arr
}

const clamp = (n, min, max) => Math.max(Math.min(n, max), min)

const amountOfItemsInOneRow = (widthLayout, width) =>
	Math.floor((widthLayout - 36) / width)

const initLayout = (count, itemPerRow, width, height) => {
	return range(count).map((n) => {
		const row = Math.floor(n / itemPerRow)
		const col = n % itemPerRow
		return [width * col, height * row]
	})
}

const [width, height] = [210, 216]
let itemPerRow
const ignoreKeys = [70, 71, 80]

export default class DocumentsType extends Component {
	constructor(props) {
		super(props)

		const count = props.documents.length
		const [heightLayout, widthLayout] = [window.innerHeight, window.innerWidth]
		itemPerRow = amountOfItemsInOneRow(widthLayout, width)

		this.state = {
			mouseXY: [0, 0],
			mouseCircleDelta: [0, 0], // difference between mouse and circle pos for x + y coords, for dragging
			lastPress: null, // key of the last pressed component
			isPressed: false,
			width,
			height,
			count,
			order: range(count), // index: visual position. value: component key/id
			defaultOrder: range(count),
			itemPerRow,
			layout: initLayout(count, itemPerRow, width, height),
			isMoving: false,
			heightLayout,
			widthLayout,
			items: props.documents ? props.documents : [],
			openColorPickId: null
		}
	}

	componentDidMount() {
		// this.props.getVoucherTypeByUser();
		window.addEventListener('resize', this.handleResize)
		window.addEventListener('keydown', this.handleKeyDown)
		window.addEventListener('keyup', this.handleKeyUp)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
		window.removeEventListener('keydown', this.handleKeyDown)
		window.removeEventListener('keyup', this.handleKeyUp)
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { documents } = this.props
		const { count, width, height, widthLayout } = this.state
		itemPerRow = amountOfItemsInOneRow(widthLayout, width)

		if (documents !== prevProps.documents && documents) {
			const count = documents.length
			const layout = initLayout(count, itemPerRow, width, height)

			this.setState({
				items: documents,
				order: range(count), // index: visual position. value: component key/id
				defaultOrder: range(count),
				layout
			})
		}

		if (prevState.widthLayout !== widthLayout) {
			itemPerRow = amountOfItemsInOneRow(widthLayout, width)
			const newLayout = initLayout(count, itemPerRow, width, height)
			this.setState({ layout: newLayout })
		}
	}

	handleResize = () =>
		this.setState({
			heightLayout: window.innerHeight,
			widthLayout: window.innerWidth
		})

	handleTouchStart = (key, pressLocation, e) => {
		this.handleMouseDown(key, pressLocation, e.touches[0])
	}

	handleTouchMove = (e) => {
		e.preventDefault()
		this.handleMouseMove(e.touches[0])
	}

	handleMouseMove = ({ pageX, pageY }) => {
		const {
			order,
			lastPress,
			isPressed,
			mouseCircleDelta: [dx, dy],
			width,
			height,
			count
		} = this.state
		if (isPressed) {
			const mouseXY = [pageX - dx, pageY - dy]
			const col = clamp(Math.floor(mouseXY[0] / width), 0, itemPerRow - 1)
			const row = clamp(
				Math.floor(mouseXY[1] / height),
				0,
				Math.floor(count / itemPerRow)
			)
			const index = row * itemPerRow + col
			const newOrder = reinsert(order, order.indexOf(lastPress), index)
			this.setState({ mouseXY, order: newOrder })
		}
	}

	handleMouseDown = (key, [pressX, pressY], { pageX, pageY }) => {
		if (this.state.isMoving) {
			this.setState({
				lastPress: key,
				isPressed: true,
				mouseCircleDelta: [pageX - pressX, pageY - pressY],
				mouseXY: [pressX, pressY]
			})
		}
	}

	handleMouseUp = () => {
		// TODO hector metodo para cuando se tenga la api de configuracion de los estilos y posiciones
		/* const { order } = this.state;
        const newItems = [];
        for (let i = 0; i < order.length; i++) {
            newItems.push(order[i]);
        } */
		// this.saveVouchersStyleConfigByUser(<object>)

		// no actualizar la variable items items del state
		this.setState({ isPressed: false, mouseCircleDelta: [0, 0] })

		const { order, items, defaultOrder } = this.state
		if (JSON.stringify(order) !== JSON.stringify(defaultOrder)) {
			this.extractStyleFroItems(order, items)
		}
		this.setState({
			isPressed: false,
			mouseCircleDelta: [0, 0],
			defaultOrder: order
		})
	}

	handleKeyDown = (e) => {
		if (e.keyCode === 17) {
			this.setState({ isMoving: true })
		} else if (ignoreKeys.includes(e.keyCode) && e.ctrlKey) {
			this.setState({ isMoving: false }) // Searching...
		}
	}

	handleKeyUp = (e) => {
		if (e.keyCode === 17) {
			this.setState({ isMoving: false })
		}
	}

	handleColorChange = (nioperacion, color) => {
		const { order, items } = this.state
		const newItems = items.map((item) => {
			if (item.nioperacion === nioperacion) {
				item.color = color.hex
			}
			return item
		})
		this.setState({ items: newItems })
		this.extractStyleFroItems(order, newItems)
	}

	extractStyleFroItems = (order, items) => {
		const newItems = []
		for (let i = 0; i < order.length; i++) {
			const { nioperacion, color } = items[order[i]]
			newItems.push({ nioperacion, color })
		}
		this.props.saveVouchersStyleConfigByUser({ botoneraComprobantes: newItems })
	}

	/**
	 * managed all picker color items
	 * @param {*} currentItem
	 */
	closeAllPickers = (currentItem) => {
		this.setState({ openColorPickId: currentItem })
	}

	/**
	 * Render all items.
	 * @param {*} items
	 * @returns
	 */
	renderItems = (items) => {
		const {
			lastPress,
			isPressed,
			mouseXY,
			order,
			height,
			isMoving,
			openColorPickId,
			layout
		} = this.state

		const { saveVouchersStyleConfigByUser, idCompany } = this.props

		return _.map(items, (voucher, key) => {
			let style
			let x = 0
			let y = 0

			const visualPosition = order.indexOf(key)

			if (key === lastPress && isPressed) {
				;[x, y] = mouseXY
				style = {
					translateX: x,
					translateY: y,
					scale: spring(1.2, springSetting1)
				}
			} else {
				if (layout[visualPosition]) {
					;[x, y] = layout[visualPosition]
				}
				style = {
					translateX: spring(x, springSetting2),
					translateY: spring(y, springSetting2),
					scale: spring(1, springSetting1)
				}
			}

			return (
				<Motion key={key} style={style}>
					{({ translateX, translateY, scale, boxShadow }) => (
						<div
							role={'presentation'}
							onMouseDown={this.handleMouseDown.bind(null, key, [x, y])}
							onTouchStart={this.handleTouchStart.bind(null, key, [x, y])}
							style={{
								position: 'absolute',
								width: width,
								height: height,
								WebkitTransform: `translate3d(${translateX}px, ${translateY}px, 0) scale(${scale})`,
								transform: `translate3d(${translateX}px, ${translateY}px, 0) scale(${scale})`,
								zIndex: key === lastPress ? 99 : items.length + 1 - key,
								boxShadow: `${boxShadow}px 5px 5px rgba(0,0,0,0.5)`
							}}
						>
							<DocumentsItems
								key={voucher.nioperacion}
								closePick={openColorPickId === voucher.nioperacion}
								voucher={voucher}
								isMoving={isMoving}
								onColorChange={this.handleColorChange}
								saveVouchersStyleConfigByUser={saveVouchersStyleConfigByUser}
								idCompany={idCompany}
								handleCrearVoucherType={this.props.handleCrearVoucherType}
								closeAllPickers={this.closeAllPickers}
							/>
						</div>
					)}
				</Motion>
			)
		})
	}

	render() {
		const { items, height } = this.state

		return (
			<div
				role={'presentation'}
				style={{ height: Math.ceil(items.length / itemPerRow) * height }}
				onTouchMove={this.handleTouchMove}
				onTouchEnd={this.handleMouseUp}
				onMouseMove={this.handleMouseMove}
				onMouseUp={this.handleMouseUp}
			>
				{!_.isEmpty(items) ? this.renderItems(items) : <div />}
			</div>
		)
	}
}
