import {
	getCufsPickExpenses,
	getCufsPickStock,
	pickeoEgrSearchBarcode,
	searchAutoProducts
} from 'actions'
import BarcodeInputText from 'components/form/barcodeInputText'
import InputAutocomplete from 'components/form/inputAutocomplete'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { IProductsAuOptions } from 'components/InventoryPickStock/InventoryPickStockBarCode/inventoryPickStockBarCodeType'
import { handleSetValueInput } from 'lib/FormUtils'
import { setInputFocus } from 'lib/Utils'
import {
	InventorySearchBarcodeParams,
	InventorySearchBarcodeResponse
} from 'models/InventoryPickStock'
import React, { Component, forwardRef, Ref } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import InventoryExpensesStockResume from '../InventoryExpensesStockResume'
import {
	InventoryExpensesStockBarCodeProps,
	InventoryPickStockBarCodeState
} from './inventoryExpensesStockBarCodeType'

class InventoryExpensesStockBarCode extends Component<
	InventoryExpensesStockBarCodeProps,
	InventoryPickStockBarCodeState
> {
	cantInputRef: any
	firstInputRef: any
	searchInputRef: any

	constructor(props: InventoryExpensesStockBarCodeProps) {
		super(props)
		this.state = {
			prodOptions: [],
			isLoading: false,
			isCantDisabled: true,
			firstFocus: true,
			searchBarCode: ''
		}
		this.cantInputRef = React.createRef()
		this.firstInputRef = React.createRef()
		this.searchInputRef = React.createRef()
	}

	componentDidMount(): void {
		this.setFocusFirstInput()
	}

	componentDidUpdate(
		prevProps: Readonly<InventoryExpensesStockBarCodeProps>
	): void {
		const { searchAutoOptions, isVisibleBarCode, barCodeSearched } = this.props

		this.setFocusFirstInput()

		this.handleSetProductOptions(prevProps.searchAutoOptions, searchAutoOptions)

		if (prevProps.isVisibleBarCode !== isVisibleBarCode) {
			this.handleSetSearchFocus()
		}

		this.handleValidateBarCode(prevProps.barCodeSearched, barCodeSearched)
	}

	handleValidateBarCode = (
		prevBarcodeResponse: InventorySearchBarcodeResponse,
		barcodeResponse: InventorySearchBarcodeResponse
	) => {
		if (
			!_.isEqual(prevBarcodeResponse, barcodeResponse) &&
			!_.isEmpty(barcodeResponse)
		) {
			this.handleResetForm()
		}
	}

	handleSetProductOptions = (
		prevOptions: IProductsAuOptions,
		options: IProductsAuOptions
	) => {
		if (!_.isEqual(prevOptions, options) && !_.isEmpty(options)) {
			const prodOptions = options.productos.map((product: any) => ({
				id: product.niprod,
				label: product.desc_prod
			}))
			this.setState({ prodOptions })
		}
	}

	/**
	 * to set focus in the search bar after load the form
	 */
	setFocusFirstInput = () => {
		const { firstFocus } = this.state
		if (this.firstInputRef.current && firstFocus) {
			setInputFocus(this.firstInputRef)
			this.setState({ firstFocus: false })
		}
	}

	/**
	 * search codeBar
	 */
	handleSearchBarcode = () => {
		const { idOperacion, formikProps } = this.props
		const { values } = formikProps

		const params: InventorySearchBarcodeParams = {
			idOperacion,
			cod_barra: values.pick_cod_barra || '',
			ind_ultimo_cuf: values.ult_cuf ? 1 : 0,
			cant: values.cant_ns,
			cuf: values.cuf_ori,
			cuf_dest: values.cuf_dest,
			page: 1,
			page_size: 10
		}

		this.props.pickeoEgrSearchBarcode(params)
		this.setState({ isLoading: true })
	}

	/**
	 * to format formik form to initial values
	 */
	handleResetForm = () => {
		const { formikProps } = this.props

		formikProps.setFieldValue('cant_ns', '1')
		formikProps.setFieldValue('pick_cod_barra', '')

		if (this.searchInputRef.current) {
			this.searchInputRef.current.clear()
		}
	}

	/**
	 * to call search api
	 * @param {string} search
	 */
	handleSearch = (search: string) => {
		const { idOperacion } = this.props
		this.props.searchAutoProducts({
			idOperacion,
			desc_prod: search
		})

		this.setState({ isLoading: true, searchBarCode: search })
	}

	/**
	 * to select a product from the search
	 * @param {string} productSelected
	 */
	handleSelect = (productSelected: any) => {
		const { formikProps, idOperacion } = this.props

		if (!_.isEmpty(productSelected)) {
			const params: InventorySearchBarcodeParams = {
				idOperacion,
				niprod: productSelected[0].id,
				ind_ultimo_cuf: formikProps.values.ult_cuf,
				cant: formikProps.values.cant_ns,
				cuf: formikProps.values.cuf_ori,
				cuf_dest: formikProps.values.cuf_dest,
				page: 1,
				page_size: 10
			}

			this.props.pickeoEgrSearchBarcode(params)
			this.setState({
				isLoading: false,
				searchBarCode: productSelected[0].label
			})
		}
	}

	/**
	 * to  enable and disable the quantity field
	 */
	toggleDisableCant = () => {
		const { isCantDisabled } = this.state
		const { formikProps } = this.props

		formikProps.setFieldValue('cant_ns', isCantDisabled ? '' : 1)
		this.setState({ isCantDisabled: !isCantDisabled })
		if (isCantDisabled) {
			setInputFocus(this.cantInputRef)
		} else {
			this.handleSetSearchFocus()
		}
	}

	/**
	 * to set focus in the search input tha is enabled
	 */
	handleSetSearchFocus = () => {
		const { isVisibleBarCode } = this.props
		if (!isVisibleBarCode) {
			setInputFocus(this.searchInputRef)
		} else {
			setInputFocus(this.firstInputRef)
		}
	}

	handleChangeDeptOrigen = (data: any) => {
		const { formikProps, idOperacion } = this.props
		this.props.getCufsPickExpenses({ cod_dep: data.target.value, idOperacion })
		formikProps.setFieldValue('depo_ori', data.target.value)
	}

	handleChangeDeptDestination = (data: any) => {
		const { formikProps, idOperacion } = this.props
		this.props.getCufsPickStock({ cod_dep: data.target.value, idOperacion })
		formikProps.setFieldValue('depo_dest', data.target.value)
	}

	render() {
		const {
			fields,
			formikProps,
			t,
			isVisibleBarCode,
			egrPickeo,
			depositList,
			cufList,
			pickStockListDeposits,
			pickStockListCufs
		} = this.props
		const { values, handleBlur } = formikProps
		const { prodOptions, isLoading, isCantDisabled, searchBarCode } = this.state

		const depOptionsDest = !_.isEmpty(pickStockListDeposits)
			? pickStockListDeposits.lista_dep.map((dept) => ({
					id: dept.codigo,
					label: dept.descrip
			  }))
			: []

		const cufOptionsDest = !_.isEmpty(pickStockListCufs)
			? pickStockListCufs.lista.map((cuf) => ({
					id: cuf.codigo,
					label: cuf.descrip
			  }))
			: []

		const depOptions = !_.isEmpty(depositList)
			? depositList.lista_dep.map((dept) => ({
					id: dept.codigo,
					label: dept.descrip
			  }))
			: []

		const cufOptions = !_.isEmpty(cufList)
			? cufList.lista.map((cuf) => ({
					id: cuf.codigo,
					label: cuf.descrip
			  }))
			: []

		const depositProps = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'l',
			inputId: 'depo_ori',
			name: 'depo_ori',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.depo_ori,
			divStyle: { paddingRight: '0px' },
			options: depOptions,
			noInitialExecute: false,
			onChange: (data: any) => this.handleChangeDeptOrigen(data)
		}

		const locationProps = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'l',
			inputId: 'cuf_ori',
			name: 'cuf_ori',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.cuf_ori,
			divStyle: { paddingRight: '0px' },
			disabledInput: values.ult_cuf,
			options: cufOptions,
			noInitialExecute: false,
			onChange: (data: any) => handleSetValueInput(data, 'cuf_ori', formikProps)
		}

		const destinationDepositProps = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'l',
			inputId: 'depo_dest',
			name: 'depo_dest',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.depo_dest,
			divStyle: { paddingRight: '0px' },
			options: depOptionsDest,
			noInitialExecute: false,
			onChange: (data: any) => this.handleChangeDeptDestination(data)
		}

		const destinationLocationProps = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'l',
			inputId: 'cuf_dest',
			name: 'cuf_dest',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.cuf_dest,
			divStyle: { paddingRight: '0px' },
			disabledInput: values.ult_cuf,
			options: cufOptionsDest,
			noInitialExecute: false,
			onChange: (data: any) =>
				handleSetValueInput(data, 'cuf_dest', formikProps)
		}

		const barCodeProps = {
			fwRef: this.firstInputRef,
			inputFormCol: { sm: 6 },
			fields,
			label: 'label',
			inputId: 'pick_cod_barra',
			name: 'pick_cod_barra',
			placeholder: 'Código de barra',
			styles: { width: '100%' },
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			handleEnterKey: () => this.handleSearchBarcode(),
			value: values.pick_cod_barra,
			onChange: (data: string) =>
				formikProps.setFieldValue('pick_cod_barra', data),
			activeListeners: true
		}

		const cantProps = {
			fwRef: this.cantInputRef,
			inputFormCol: { sm: 6 },
			disabledInput: isCantDisabled,
			fields,
			label: 'Cantidad',
			inputId: 'cant_ns',
			name: 'cant_ns',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-6',
			placeholder: 'Cantidad',
			onBlur: handleBlur,
			autoComplete: 'off',
			value: values.cant_ns,
			divStyle: { paddingRight: '0px' },
			handleEnterKey: () => this.handleSetSearchFocus(),
			onChange: (data: any) => handleSetValueInput(data, 'cant_ns', formikProps)
		}

		const searchProdProps = {
			refs: this.searchInputRef,
			inputFormCol: { sm: 6 },
			label: 'Buscar Productos (F2)',
			inputId: 'search_product',
			name: 'search_product',
			placeholder: t('searchBox.form.insert_product'),
			fields,
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			handleSearch: this.handleSearch,
			auoptions: prodOptions,
			handleLoading: isLoading,
			handleSelect: this.handleSelect,
			onChange: () => {},
			defaultValue: searchBarCode,
			labelKey: 'label',
			extraCol: true
		}
		return (
			<Row>
				<Row className={`col-12`}>
					<InputDropdown {...depositProps} />
					<InputDropdown {...locationProps} />
				</Row>
				<Row className={`col-12`}>
					<InputDropdown {...destinationDepositProps} />
					<InputDropdown {...destinationLocationProps} />
				</Row>
				<Row className={`col-12`}>
					{isVisibleBarCode ? (
						<BarcodeInputText {...barCodeProps} />
					) : (
						<InputAutocomplete {...searchProdProps} />
					)}
					<InputText {...cantProps} />
				</Row>
				<Row className={`col-12`}>
					<InventoryExpensesStockResume fields={fields} data={egrPickeo} />
				</Row>
			</Row>
		)
	}
}

const mapStateToProps = ({
	product,
	pickeoEgrStock,
	inventoryPickExpenses,
	inventoryPickStock
}: any) => {
	const { barCodeSearched, egrPickeo } = pickeoEgrStock
	const { depositList, cufList } = inventoryPickExpenses
	const { pickStockListDeposits, pickStockListCufs } = inventoryPickStock

	const { searchAutoOptions } = product
	return {
		searchAutoOptions,
		barCodeSearched,
		egrPickeo,
		depositList,
		cufList,
		pickStockListDeposits,
		pickStockListCufs
	}
}

const mapDispatchToProps = {
	getCufsPickExpenses,
	searchAutoProducts,
	pickeoEgrSearchBarcode,
	getCufsPickStock
}

const InventoryPickStockBarCodeWithRef = forwardRef(
	function InventoryPickStockFormWrapper(
		props: InventoryExpensesStockBarCodeProps,
		ref: Ref<InventoryExpensesStockBarCode>
	) {
		return <InventoryExpensesStockBarCode {...props} ref={ref} />
	}
)

export default withTranslation('', { withRef: true })(
	connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
		InventoryPickStockBarCodeWithRef
	)
)
