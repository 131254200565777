import {
	autoGenerateCancelSuccess,
	autoGenerateConfirmSuccess,
	autoGenerateStatusSuccess
} from 'actions'
import {
	autoGenerateCancelApi,
	autoGenerateConfirmApi,
	autoGenerateStatusApi
} from 'api/AutoGenerate'
import {
	AUTO_GENERATE_CANCEL,
	AUTO_GENERATE_CONFIRM,
	AUTO_GENERATE_STATUS
} from 'constants/ActionsTypes'
import {
	IAutoGenerateCancelAction,
	IAutoGenerateConfirmAction,
	IAutoGenerateStatusAction
} from 'models/AutoGenerate'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { autoGenerateStatus } from './../actions/AutoGenerate'

function* autoGenerateConfirmRequest({
	payload
}: IAutoGenerateConfirmAction): any {
	try {
		const values: any = yield call(autoGenerateConfirmApi, payload)
		yield put(autoGenerateConfirmSuccess(values))
		yield put(
			autoGenerateStatus({
				idOperacion: payload.idOperacion,
				page_number: 1,
				page_size: 10
			})
		)
	} catch (error: any) {}
}

function* autoGenerateStatusRequest({
	payload
}: IAutoGenerateStatusAction): any {
	try {
		const values: any = yield call(autoGenerateStatusApi, payload)
		yield put(autoGenerateStatusSuccess(values))
	} catch (error: any) {}
}

function* autoGenerateCancelRequest({
	payload
}: IAutoGenerateCancelAction): any {
	try {
		const values: any = yield call(autoGenerateCancelApi, payload)
		yield put(autoGenerateCancelSuccess(values))
	} catch (error: any) {}
}

export function* autoGenerateConfirmSaga() {
	yield takeEvery(AUTO_GENERATE_CONFIRM, autoGenerateConfirmRequest)
}
export function* autoGenerateCancelSaga() {
	yield takeEvery(AUTO_GENERATE_CANCEL, autoGenerateCancelRequest)
}
export function* autoGenerateStatusSaga() {
	yield takeEvery(AUTO_GENERATE_STATUS, autoGenerateStatusRequest)
}

export default function* rootSaga() {
	yield all([
		fork(autoGenerateConfirmSaga),
		fork(autoGenerateStatusSaga),
		fork(autoGenerateCancelSaga)
	])
}
