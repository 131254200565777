import {
	automaticPurchaseFilter,
	getConfigFieldsCrud,
	getDeposits,
	getListCalculationFormulas,
	getListOrderOCMulti,
	getTableBranchOffices,
	selectSearchedProducts
} from 'actions'
import CommonModal from 'components/common/commonModal'
import NotificationError from 'components/common/notificationsErrors'
import SearchProductsForm from 'components/SearchProducts/SearchProductsForm'
import SearchProductsTable from 'components/SearchProducts/SearchProductsTable'
import { API_FORMAT_DATE } from 'constants/ApiFormats'
import {
	P_AUTOMATIC_PURCHASE_ORDER,
	P_SEARCH_PRODUCTS
} from 'constants/ConfigProcessNames'
import { IAbmStructureResponse } from 'constants/valuesInterfaces/interfaces'
import { Formik } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { getFiltersData } from 'lib/Utils'
import { IGetDepositsParams } from 'models/AutomaticPurchase'
import { IConfigFieldsForm } from 'models/Budget'
import { IGetTableBranchOfficesParams } from 'models/TableCalls/TableCallsInterface'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Col, Form } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import AutomaticPurchaseFiltersInputs from './AutomaticPurchaseFiltersInputs'

interface IAutomaticPurchaseFiltersProps extends WithTranslation {
	config: IConfigFieldsForm
	handleFilters: (payload: any) => void
	getDeposits: (payload: IGetDepositsParams) => void
	getListCalculationFormulas: () => void
	getListOrderOCMulti: () => void
	idOperacion: string
	automaticPurchaseFilter: (payload: any) => void
	fieldsConfig: IAbmStructureResponse
	getConfigFieldsCrud: (payload: { cod_abm: string }) => void
	selectSearchedProducts: (payload: any) => void
	selectedProducts: any
	getTableBranchOffices: (payload: IGetTableBranchOfficesParams) => void
	toggleColumns: (payload: { showColumns: boolean }) => void
	t?: any
}

interface IAutomaticPurchaseFilterState {
	showSearchModal: boolean
	searchProduct: string
}

class AutomaticPurchaseFilters extends Component<
	IAutomaticPurchaseFiltersProps,
	IAutomaticPurchaseFilterState
> {
	constructor(props: IAutomaticPurchaseFiltersProps) {
		super(props)
		this.state = {
			showSearchModal: false,
			searchProduct: ''
		}
	}

	componentDidMount = (): void => {
		const { idOperacion } = this.props
		this.props.getDeposits({ descrip: '', idOperacion })
		this.props.getListCalculationFormulas()
		this.props.getListOrderOCMulti()
		this.props.getConfigFieldsCrud({ cod_abm: P_SEARCH_PRODUCTS })
		this.props.getTableBranchOffices({ requerido: 1 })
	}

	componentDidUpdate(
		prevProps: Readonly<IAutomaticPurchaseFiltersProps>
	): void {
		const { selectedProducts } = this.props
		if (
			!_.isEqual(selectedProducts, prevProps.selectedProducts) &&
			!_.isEmpty(selectedProducts)
		) {
			this.handleToggleSearchModal()
		}
	}

	/**
	 * to close search products modal
	 */
	handleCloseSearchModal = () => {
		this.setState({ showSearchModal: false })
	}

	/**
	 * to open and close search modal
	 */

	handleToggleSearchModal = () => {
		this.setState((state) => ({
			showSearchModal: !state.showSearchModal
		}))
	}

	/**
	 * to submit of search modal
	 */

	handleSendSearch = (values: any) => {
		const { idOperacion } = this.props
		this.props.selectSearchedProducts({ ...values, idOperacion })
	}

	renderSearchProducts = () => {
		const { fieldsConfig } = this.props
		return (
			<>
				<SearchProductsForm
					fields={getFiltersData(fieldsConfig.campos, {
						agrupador: 'formulario'
					})}
					isSearchProducts={true}
					handleSendSearch={this.handleSendSearch}
				/>
				<SearchProductsTable
					fields={getFiltersData(fieldsConfig.campos, {
						agrupador: 'grilla'
					})}
				/>
			</>
		)
	}

	handleSubmitForm = (values: any) => {
		const { fec_des, fec_has } = values
		const { idOperacion, handleFilters } = this.props
		const fechaFrom = fec_des ? moment(fec_des).format(API_FORMAT_DATE) : ''
		const fechaTo = fec_has ? moment(fec_has).format(API_FORMAT_DATE) : ''

		handleFilters(values)

		this.props.automaticPurchaseFilter({
			...values,
			fec_des: fechaFrom,
			fec_has: fechaTo,
			idOperacion,
			page_size: 10,
			page_number: 0
		})
	}

	render() {
		const { config, idOperacion, t } = this.props
		const { showSearchModal } = this.state
		const fieldsFilters = !_.isEmpty(config)
			? getFiltersData(config.campos, {
					agrupador: 'formulario'
			  })
			: {}

		const initialValues = config
			? getInitValues(fieldsFilters, { ver_comprar: true })
			: {}

		const validationSchema = config
			? getValidationSchema(fieldsFilters, t, [])
			: {}

		const propsSearchModal = {
			showModal: showSearchModal,
			handleCloseModal: this.handleToggleSearchModal,
			modalTitle: 'Búsqueda de productos',
			modalBody: showSearchModal ? this.renderSearchProducts() : <></>,
			buttons: false,
			sizeModal: 'xl'
		}

		return (
			<>
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						this.handleSubmitForm(values)
					}}
					validationSchema={validationSchema}
					enableReinitialize={true}
					render={(props) => {
						const {
							values,
							handleChange,
							handleBlur,
							setFieldValue,
							setFieldTouched
						} = props

						return (
							<Fragment>
								<Col sm={12}>
									<NotificationError
										errors={props.errors}
										touched={props.touched}
									/>
									<Form
										onSubmit={(e) => props.handleSubmit(e as any)}
										className="voucher-info-form"
									>
										<AutomaticPurchaseFiltersInputs
											fields={
												!_.isEmpty(config)
													? getFiltersData(config.campos, {
															agrupador: 'formulario'
													  })
													: []
											}
											handleOpenModal={this.handleToggleSearchModal}
											toggleColumns={this.props.toggleColumns}
											{...{
												values,
												handleChange,
												handleBlur,
												setFieldValue,
												setFieldTouched,
												idOperacion
											}}
										/>
									</Form>
								</Col>
							</Fragment>
						)
					}}
				/>
				{showSearchModal && <CommonModal {...propsSearchModal} />}
			</>
		)
	}
}

const mapStateToProps = ({
	voucher,
	autoGenerate,
	configCrud,
	searchProducts
}: any) => {
	const config = voucher.config
		? voucher.config[P_AUTOMATIC_PURCHASE_ORDER]
		: null
	const { generatedStatus, autoConfirm } = autoGenerate
	const { fieldsConfig } = configCrud
	const { selectedProducts } = searchProducts
	return {
		config,
		generatedStatus,
		autoConfirm,
		fieldsConfig,
		selectedProducts
	}
}

const mapDispatchToProps = {
	getDeposits,
	getListCalculationFormulas,
	getListOrderOCMulti,
	automaticPurchaseFilter,
	getConfigFieldsCrud,
	selectSearchedProducts,
	getTableBranchOffices
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(AutomaticPurchaseFilters))
