import CrudAdditionalAccountsPayableData from 'components/additionalAccountsPayableData/CrudAdditionalAccountsPayableData'
import ListCrudTabContent from 'components/genericCrud/ListCrudTabContent'
import ValuesCrudTable from 'components/genericCrud/ValuesCrudTable'
import { CRUD_ATTR_ACCOUNT_PAYABLE_DATA } from 'utils/RoutePath'

export const attr_accounts_payable_dataConfig = {
	id: 'attrAccountPayableCrud',
	key: 'catributo',
	descrip: 'datributo',
	searchMethod: 'get',
	category: 'A',
	translation: 'attr_accounts_payable_data',
	dataUrl: CRUD_ATTR_ACCOUNT_PAYABLE_DATA,
	urlApi: 'abm_cpa_atribcomp',
	component: ListCrudTabContent,
	tabsList: [
		{
			key: 'basic',
			title: 'CRUDS.general_use.basic_tab',
			tabComponent: CrudAdditionalAccountsPayableData
		},
		{
			key: 'atrib',
			title: 'CRUDS.general_use.additional_tab',
			urlApi: 'abm_cpa_atribcomp_valores',
			tabId: 'catributo',
			valueId: 'cod_valor',
			tabComponent: ValuesCrudTable
		}
	],
	tabsGroups: ['grilla', 'grupo.valores'],
	tabsGroupsAlta: ['grilla', 'grupo.valores']
}
