import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import styles from './inputText.module.css'

class InputCheckBox extends PureComponent {
	constructor(props) {
		super(props)

		this.state = {
			showLockModal: false,
			inputValue: this.props.value,
			configInput: this.getConfigField(props.inputId),
			requireError: this.props.showError ? this.props.showError : false,
			trueValue: null
		}
	}

	componentDidUpdate = (prevProps) => {
		const { fields, inputId } = this.props
		if (prevProps.fields !== fields && fields) {
			this.setState({ configInput: this.getConfigField(inputId) })
		}
	}

	getConfigField = (id) => {
		const { fields } = this.props
		let result = {}
		if (fields) {
			fields.forEach((field) => {
				if (field.idCampo.trim() === id) {
					result = field
				}
			})
		}

		return result
	}

	renderField = () => {
		const {
			label,
			placeholder,
			name,
			styles,
			inputId,
			id,
			colInput,
			colLabel,
			styleLabel,
			disable,
			theme,
			inputFormCol,
			rowStyle,
			disabledInput,
			checked,
			defaultChecked,
			inputType
		} = this.props

		const classInput = label ? colInput : 'col-sm-12'
		const classLabel = label ? colLabel : ''
		const classText = disable ? theme.inputDisabled : ''
		const customType = inputType || 'checkbox'
		const config = this.state.configInput
		const customStyleLabel = config.requerido
			? { ...styleLabel, color: 'red' }
			: { ...styleLabel }
		const inputStyles = this.state.requireError
			? { ...styles, border: '1px solid red' }
			: styles

		if (config.visible) {
			const optionsInput = {
				id: id || inputId,
				name: name,
				type: customType,
				style: inputStyles,
				placeholder: placeholder,
				disabled: !config.editable || disabledInput,
				className: `${classText}`,
				checked: checked,
				defaultChecked: defaultChecked,
				onChange: (event) => this.props.onChange(event.target.checked)
			}

			return (
				<Col {...inputFormCol}>
					<Row style={rowStyle}>
						<Col className={`${classLabel}`}>
							<label
								className={`${theme.inputLabelCheckBox}`}
								style={customStyleLabel}
							>
								{config.label || label}
							</label>
						</Col>
						<Col className={`${classInput}`}>{<input {...optionsInput} />}</Col>
					</Row>
				</Col>
			)
		} else {
			return null
		}
	}

	render() {
		const { fields } = this.props
		if (fields) {
			return this.renderField()
		} else {
			return null
		}
	}
}

const mapStateToProps = ({ auth }) => {
	const { authUser } = auth
	return { authUser }
}

export default connect(mapStateToProps)(
	themr('InputCheckBoxStyle', styles)(InputCheckBox)
)
