import React, { Component } from 'react'
import Helmet from 'react-helmet'

export default class TitleComponent extends Component {
	render() {
		const { title } = this.props
		const defaultTitle = 'CEOGestion'
		return (
			<Helmet>
				<title>{title || defaultTitle}</title>
			</Helmet>
		)
	}
}
