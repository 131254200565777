import { ICrudGenericFields } from 'models/CrudInterface'

export const searchFields: Array<ICrudGenericFields> = [
	{
		id: 'lk_nom',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-9',
		height: 'col-sm-8',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
