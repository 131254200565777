import {
	CRUD_GET_TERMS_OF_SALES_DUES,
	CRUD_GET_TERMS_OF_SALES_DUES_SUCCESS,
	CRUD_UPDATE_TERMS_OF_SALES_DUES,
	CRUD_UPDATE_TERMS_OF_SALES_DUES_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	duesSearched: any
	duesUpdated: any
}

const initialState = {
	duesSearched: null,
	duesUpdated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_TERMS_OF_SALES_DUES:
			return { ...state, duesSearched: null }
		case CRUD_GET_TERMS_OF_SALES_DUES_SUCCESS:
			return { ...state, duesSearched: action.payload }
		case CRUD_UPDATE_TERMS_OF_SALES_DUES:
			return { ...state, duesUpdated: null }
		case CRUD_UPDATE_TERMS_OF_SALES_DUES_SUCCESS:
			return { ...state, duesUpdated: action.payload }
		default:
			return state
	}
}

export default rootReducer
