import { getTableEstimationType } from 'actions'
import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import NotificationError from 'components/common/notificationsErrors'
import { FormRefsContext } from 'context/FormRefsContext'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { ICrudCompleteFields } from 'models/CrudInterface'
import { ICrudUpdatePricesList } from 'models/CrudPricesListInterfase'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import CrudPricesListDataField from './CrudPricesListDataField'

export interface ICrudPricesListProps extends WithTranslation {
	disableForm: boolean
	fields: Array<ICrudCompleteFields>
	showMessage: any
	t: any
	handleSubmit: (param: ICrudUpdatePricesList) => void
	method: string
	handleWarning: (value: any, action: string) => void
	showWarning: boolean
	setFormChanged: (formChanged: boolean) => void
	getTableEstimationType: (payload: any) => void
	handleCloseModal: () => void
	itemInfo?: any
}

class crudPricesList extends Component<ICrudPricesListProps> {
	// Initial dropdown setup
	componentDidMount = () => {
		this.props.getTableEstimationType(null)
	}

	componentDidUpdate = (prevProps: ICrudPricesListProps) => {}

	render() {
		const {
			fields,
			itemInfo,
			handleSubmit,
			t,
			setFormChanged,
			method,
			disableForm,
			handleCloseModal,
			handleWarning,
			showWarning
		} = this.props

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})

		const columnsCancel = disableForm ? 12 : 6

		const validationSchema = fields.length
			? getValidationSchema(fields, t, [], [])
			: {}

		return (
			<FormRefsContext.Consumer>
				{({ formRef, cancelButtonRef }) => (
					<Fragment>
						<Formik
							ref={formRef}
							initialValues={initialValues}
							onSubmit={(values, actions) => {
								handleSubmit(values)
							}}
							enableReinitialize={true}
							validationSchema={validationSchema}
						>
							{(props: FormikProps<any>) => (
								<Fragment>
									<Col sm={12}>
										<NotificationError
											errors={props.errors}
											touched={props.touched}
										/>
									</Col>
									<Form
										onChange={(e) => {
											setFormChanged(true)
										}}
									>
										{(method === 'add' ||
											((method === 'edit' || method === 'view') && itemInfo)) &&
											_.map(fields, (field) => {
												return (
													<CrudPricesListDataField
														key={field.idCampo}
														formikProps={props}
														field={field}
														crudProps={this.props}
														initialValue={initialValues[field.idCampo]}
													/>
												)
											})}
										<Row className={'pt-3'}>
											{!disableForm && (
												<Col sm={6} className={'text-center mt-2'}>
													<Button style={{ minWidth: '145px' }} type={'submit'}>
														{t('Collection.form.buttons.accept')}
													</Button>
												</Col>
											)}
											<Col sm={columnsCancel} className={'text-center mt-2'}>
												<Button
													style={{ minWidth: '145px' }}
													type={'button'}
													onClick={handleCloseModal}
													ref={cancelButtonRef}
												>
													{t('Collection.form.buttons.cancel')}
												</Button>
											</Col>
										</Row>
										<ConfirmModalNoButton
											modalTitle={t('CRUDS.modal.title_warning')}
											messageBody={t('CRUDS.modal.message_changes')}
											showModal={showWarning}
											handleCloseModal={() => handleWarning(false, 'close')}
											handleCancelButton={() => {
												handleWarning(false, 'cancel')
											}}
											handleSubmitButton={(e: any) => {
												props.handleSubmit(e)
												handleWarning(false, 'submit')
											}}
										/>
									</Form>
								</Fragment>
							)}
						</Formik>
					</Fragment>
				)}
			</FormRefsContext.Consumer>
		)
	}
}

const mapStateToProps = ({ tableCalls }: any) => {
	const { estimationType } = tableCalls
	return {
		estimationType
	}
}

const connectForm = connect(mapStateToProps, {
	getTableEstimationType
})(crudPricesList)

export default withTranslation()(withRouter(connectForm))
