import { userChangePassword } from 'actions'
import NotificationMessage from 'components/common/notificationMessage'
import NotificationError from 'components/common/notificationsErrors'
import { Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import React, { Component, Fragment } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import * as Yup from 'yup'
import ChangePasswordForm from './ChangePasswordForm'
import fields from './FieldsPassword.json'

interface IChangePasswordProps extends WithTranslation {
	t: any
	handleCloseModal: () => void
	userChangePassword: any
	changePasswordError: any
	changePasswordSuccess: any
}

interface IChangePasswordState {
	showError: boolean
	message: string
}

class ChangePassword extends Component<
	IChangePasswordProps,
	IChangePasswordState
> {
	constructor(props: IChangePasswordProps) {
		super(props)
		this.state = {
			showError: false,
			message: ''
		}
	}

	componentDidUpdate(prevProps: Readonly<IChangePasswordProps>): void {
		const { changePasswordError, changePasswordSuccess } = this.props

		this.validatePasswordError(
			prevProps.changePasswordError,
			changePasswordError
		)

		this.validatePasswordSuccess(
			prevProps.changePasswordSuccess,
			changePasswordSuccess
		)
	}

	/**
	 * to validate and show error when change password
	 * @param prevPasswordError
	 * @param passwordError
	 */
	validatePasswordError = (prevPasswordError: any, passwordError: any) => {
		if (
			!_.isEqual(prevPasswordError, passwordError) &&
			!_.isEmpty(passwordError)
		) {
			this.setState({
				showError: true,
				message: passwordError.errorMessage
			})
		}
	}

	/**
	 * to validate correct change password and close popup
	 * @param prevPassword
	 * @param password
	 */
	validatePasswordSuccess = (prevPassword: any, password: any) => {
		if (!_.isEqual(prevPassword, password) && !_.isEmpty(password)) {
			this.props.handleCloseModal()
		}
	}

	/**
	 * send data to api
	 * @param values s
	 */
	handleSubmit = (values: any) => {
		this.props.userChangePassword({
			clave_actual: values.old_password,
			clave_nueva: values.new_password,
			clave_repetida: values.repeat_password
		})
	}

	/**
	 * to close error message
	 */
	handleCloseError = () => {
		this.setState({ showError: false })
	}

	render() {
		const { t } = this.props
		const { showError, message } = this.state
		const initialValues = getInitValues(fields)
		const validationSchema = getValidationSchema(
			fields,
			t,
			[],
			[
				{
					id: 'repeat_password',
					validation: Yup.string()
						.required(t('validation-required', { field: 'Repetir Clave' }))
						.oneOf(
							[Yup.ref('new_password'), 'null'],
							'La nueva clave no coincide'
						)
				}
			]
		)

		return (
			<Fragment>
				<NotificationMessage
					showError={showError}
					errorMessage={message}
					handleCloseError={this.handleCloseError}
					type={'danger'}
				/>
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						this.handleSubmit(values)
					}}
					enableReinitialize={true}
					validationSchema={validationSchema}
				>
					{(props: FormikProps<any>) => {
						const { handleSubmit } = props
						return (
							<Fragment>
								<Col sm={12}>
									<NotificationError
										errors={props.errors}
										touched={props.touched}
									/>
								</Col>
								<Form>
									<ChangePasswordForm formikProps={props} />
									<Row className={'pt-3'}>
										<Col sm={6} className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={() => handleSubmit()}
											>
												{t('Collection.form.buttons.accept')}
											</Button>
										</Col>

										<Col sm={6} className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={this.props.handleCloseModal}
											>
												{t('Collection.form.buttons.cancel')}
											</Button>
										</Col>
									</Row>
								</Form>
							</Fragment>
						)
					}}
				</Formik>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth }: any) => {
	const { changePasswordError, changePasswordSuccess } = auth
	return { changePasswordError, changePasswordSuccess }
}

const mapDispatchToProps = { userChangePassword }

const connectForm = connect(mapStateToProps, mapDispatchToProps)(ChangePassword)

export default withTranslation()(withRouter(connectForm))
