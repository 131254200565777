import {
	SALES_AFFECTED_CONFIRM,
	SALES_AFFECTED_CONFIRM_SUCCESS,
	SALES_AFFECTED_IMPORT,
	SALES_AFFECTED_IMPORT_CONFIRM,
	SALES_AFFECTED_IMPORT_CONFIRM_SUCCESS,
	SALES_AFFECTED_IMPORT_SUCCESS,
	SALES_AFFECTED_IMPORT_VALIDATE,
	SALES_AFFECTED_IMPORT_VALIDATE_SUCCESS,
	SALES_AFFECTED_QUANTITY,
	SALES_AFFECTED_QUANTITY_SUCCESS,
	SALES_AFFECTED_STATE,
	SALES_AFFECTED_STATE_CONFIRM,
	SALES_AFFECTED_STATE_CONFIRM_SUCCESS,
	SALES_AFFECTED_STATE_SUCCESS,
	SALES_AFFECTED_STATE_VALIDATE,
	SALES_AFFECTED_STATE_VALIDATE_SUCCESS,
	SALES_AFFECTED_SUB_CALCULATION,
	SALES_AFFECTED_SUB_CALCULATION_SUCCESS,
	SALES_AFFECTED_VALIDATE,
	SALES_AFFECTED_VALIDATE_SUCCESS,
	SET_TABLE_DATA_INVOLVEMENT,
	VALIDATE_SALES_AFFECTED_DISCOUNT,
	VALIDATE_SALES_AFFECTED_DISCOUNT_SUCCESS,
	VALIDATE_SALES_AFFECTED_PRICE,
	VALIDATE_SALES_AFFECTED_PRICE_SUCCESS
} from 'constants/ActionsTypes'

export const salesAffectedValidate = (params) => {
	return {
		type: SALES_AFFECTED_VALIDATE,
		payload: params
	}
}

export const salesAffectedValidateSuccess = (response) => {
	return {
		type: SALES_AFFECTED_VALIDATE_SUCCESS,
		payload: response
	}
}

export const salesAffectedImportValidate = (params) => {
	return {
		type: SALES_AFFECTED_IMPORT_VALIDATE,
		payload: params
	}
}

export const salesAffectedImportValidateSuccess = (response) => {
	return {
		type: SALES_AFFECTED_IMPORT_VALIDATE_SUCCESS,
		payload: response
	}
}

export const salesAffectedCant = (params) => {
	return {
		type: SALES_AFFECTED_QUANTITY,
		payload: params
	}
}

export const salesAffectedCantSuccess = (response) => {
	return {
		type: SALES_AFFECTED_QUANTITY_SUCCESS,
		payload: response
	}
}

export const salesAffectedImport = (params) => {
	return {
		type: SALES_AFFECTED_IMPORT,
		payload: params
	}
}

export const salesAffectedImportSuccess = (response) => {
	return {
		type: SALES_AFFECTED_IMPORT_SUCCESS,
		payload: response
	}
}

export const salesAffectedSubCalculation = (params) => {
	return {
		type: SALES_AFFECTED_SUB_CALCULATION,
		payload: params
	}
}

export const salesAffectedSubCalculationSuccess = (params) => {
	return {
		type: SALES_AFFECTED_SUB_CALCULATION_SUCCESS,
		payload: params
	}
}

export const salesAffectedConfirm = (params) => {
	return {
		type: SALES_AFFECTED_CONFIRM,
		payload: params
	}
}

export const salesAffectedConfirmSuccess = (response) => {
	return {
		type: SALES_AFFECTED_CONFIRM_SUCCESS,
		payload: response
	}
}

export const salesAffectedImportConfirm = (params) => {
	return {
		type: SALES_AFFECTED_IMPORT_CONFIRM,
		payload: params
	}
}

export const salesAffectedImportConfirmSuccess = (response) => {
	return {
		type: SALES_AFFECTED_IMPORT_CONFIRM_SUCCESS,
		payload: response
	}
}

export const setTableDataInvolvement = (params) => {
	return {
		type: SET_TABLE_DATA_INVOLVEMENT,
		payload: params
	}
}

export const salesAffectedState = (params) => {
	return {
		type: SALES_AFFECTED_STATE,
		payload: params
	}
}

export const salesAffectedStateSuccess = (response) => {
	return {
		type: SALES_AFFECTED_STATE_SUCCESS,
		payload: response
	}
}

export const salesAffectedStateValidate = (params) => {
	return {
		type: SALES_AFFECTED_STATE_VALIDATE,
		payload: params
	}
}

export const salesAffectedStateValidateSuccess = (response) => {
	return {
		type: SALES_AFFECTED_STATE_VALIDATE_SUCCESS,
		payload: response
	}
}

export const salesAffectedStateConfirm = (params) => {
	return {
		type: SALES_AFFECTED_STATE_CONFIRM,
		payload: params
	}
}

export const salesAffectedStateConfirmSuccess = (response) => {
	return {
		type: SALES_AFFECTED_STATE_CONFIRM_SUCCESS,
		payload: response
	}
}

export const validateSalesAffectedDiscount = (params) => {
	return {
		type: VALIDATE_SALES_AFFECTED_DISCOUNT,
		payload: params
	}
}

export const validateSalesAffectedDiscountSuccess = (response) => {
	return {
		type: VALIDATE_SALES_AFFECTED_DISCOUNT_SUCCESS,
		payload: response
	}
}

export const validateSalesAffectedPrice = (params) => {
	return {
		type: VALIDATE_SALES_AFFECTED_PRICE,
		payload: params
	}
}

export const validateSalesAffectedPriceSuccess = (response) => {
	return {
		type: VALIDATE_SALES_AFFECTED_PRICE_SUCCESS,
		payload: response
	}
}
