import { DELAY_TIME_API } from 'constants/ConfigProcessNames'
import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects'
import {
	confirmationProviderSuccess,
	confirmProviderTableSuccess,
	getProviderHeaderSuccess,
	getProviderSuccess,
	providerHeaderAutoSuccess,
	providerHeaderCheckDateSuccess,
	providerHeaderConfirmSuccess,
	searchProvidersSuccess
} from '../actions'
import {
	confirmationProvider,
	confirmProviderTable,
	getProvider,
	getProviderHeader,
	providerHeaderAuto,
	providerHeaderCheckDate,
	providerHeaderConfirm,
	searchProviders
} from '../api/Provider'
import {
	CONFIRMATION_PROVIDER,
	CONFIRM_PROVIDER_TABLE,
	GET_PROVIDER,
	GET_PROVIDER_HEADER,
	PROVIDER_HEADER_AUTO,
	PROVIDER_HEADER_CHECK_DATE,
	PROVIDER_HEADER_CONFIRMATION,
	SEARCH_PROVIDERS
} from '../constants/ActionsTypes'

function* getProviderRequest({ payload }) {
	try {
		const provider = yield call(getProvider, payload)
		yield put(getProviderSuccess(provider))
	} catch (error) {}
}

function* getProviderHeaderRequest({ payload }) {
	try {
		const header = yield call(getProviderHeader, payload)
		yield put(getProviderHeaderSuccess(header))
	} catch (error) {}
}

function* searchProvidersRequest({ payload }) {
	delay(DELAY_TIME_API)
	try {
		const providers = yield call(searchProviders, payload)
		yield put(searchProvidersSuccess(providers))
	} catch (error) {}
}

function* confirmationProviderRequest({ payload }) {
	const { provider, callBackReturn } = payload

	try {
		const confirmation = yield call(confirmationProvider, provider)
		callBackReturn()
		yield put(confirmationProviderSuccess(confirmation))
	} catch (error) {}
}

function* providerHeaderConfirmRequest({ payload }) {
	const { headerInfo, callBackReturn } = payload
	try {
		const confirmation = yield call(providerHeaderConfirm, headerInfo)
		callBackReturn()
		yield put(providerHeaderConfirmSuccess(confirmation))
	} catch (error) {}
}

function* providerHeaderCheckDateRequest({ payload }) {
	try {
		const checkdate = yield call(providerHeaderCheckDate, payload)
		yield put(providerHeaderCheckDateSuccess(checkdate))
	} catch (error) {}
}

function* providerHeaderAutoRequest({ payload }) {
	delay(DELAY_TIME_API)
	try {
		const options = yield call(providerHeaderAuto, payload)
		yield put(providerHeaderAutoSuccess(options))
	} catch (error) {}
}

function* confirmProviderTableRequest({ payload }) {
	const { idOperacion, callBackReturn } = payload

	try {
		const confirmation = yield call(confirmProviderTable, { idOperacion })
		yield put(confirmProviderTableSuccess(confirmation))
		callBackReturn()
	} catch (error) {}
}
export function* getProviderSaga() {
	yield takeLatest(GET_PROVIDER, getProviderRequest)
}

export function* searchProvidersSaga() {
	yield takeLatest(SEARCH_PROVIDERS, searchProvidersRequest)
}

export function* confirmationProviderSaga() {
	yield takeLatest(CONFIRMATION_PROVIDER, confirmationProviderRequest)
}

export function* getProviderHeaderSaga() {
	yield takeLatest(GET_PROVIDER_HEADER, getProviderHeaderRequest)
}

export function* providerHeaderCheckDateSaga() {
	yield takeLatest(PROVIDER_HEADER_CHECK_DATE, providerHeaderCheckDateRequest)
}

export function* providerHeaderConfirmSaga() {
	yield takeLatest(PROVIDER_HEADER_CONFIRMATION, providerHeaderConfirmRequest)
}

export function* providerHeaderAutoSaga() {
	yield takeLatest(PROVIDER_HEADER_AUTO, providerHeaderAutoRequest)
}

export function* confirmProviderTableSaga() {
	yield takeLatest(CONFIRM_PROVIDER_TABLE, confirmProviderTableRequest)
}
export default function* rootSaga() {
	yield all([
		fork(getProviderSaga),
		fork(searchProvidersSaga),
		fork(confirmationProviderSaga),
		fork(getProviderHeaderSaga),
		fork(providerHeaderCheckDateSaga),
		fork(providerHeaderConfirmSaga),
		fork(providerHeaderAutoSaga),
		fork(confirmProviderTableSaga)
	])
}
