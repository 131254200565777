import { confirmBonusesSuccess, getBonusesSuccess } from 'actions'
import { confirmBonusesApi, getBonusesApi } from 'api/CalculationBonusesApi'
import { CalculationBonusesActions } from 'constants/ActionsTypesTs'
import {
	IConfirmBonusesAction,
	IGetBonusesAction,
	IGetBonusesResponse
} from 'models/CalculationBonuses'
import { IResponseResult } from 'models/ResultsInterface'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* getBonusesRequest({ payload }: IGetBonusesAction): any {
	try {
		const values: IGetBonusesResponse = yield call(getBonusesApi, payload)
		yield put(getBonusesSuccess(values))
	} catch (error: any) {}
}

function* confirmBonusesRequest({ payload }: IConfirmBonusesAction): any {
	try {
		const { params, callBackReturn } = payload
		const resp: IResponseResult = yield call(confirmBonusesApi, params)
		yield put(confirmBonusesSuccess(resp))

		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error: any) {}
}

export function* getBonusesSaga() {
	yield takeEvery(CalculationBonusesActions.GET_BONUSES, getBonusesRequest)
}

export function* confirmBonusesSaga() {
	yield takeEvery(
		CalculationBonusesActions.CONFIRM_BONUSES,
		confirmBonusesRequest
	)
}

export default function* rootSaga() {
	yield all([fork(getBonusesSaga), fork(confirmBonusesSaga)])
}
