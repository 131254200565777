import {
	crudGetPurchaseConditionsDuesSuccess,
	crudUpdatePurchaseConditionsDuesSuccess
} from 'actions'
import {
	crudGetPurchaseConditionsDues,
	crudUpdatePurchaseConditionsDues
} from 'api/PurchaseConditionsDuesApi'
import {
	CRUD_GET_PURCHASE_CONDITIONS_DUES,
	CRUD_UPDATE_PURCHASE_CONDITIONS_DUES
} from 'constants/ActionsTypes'
import {
	ICrudGetPurchaseConditionsDuesSaga,
	ICrudUpdatePurchaseConditionsDuesSaga
} from 'models/CrudPurchaseConditionsInterface'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

function* crudGetPurchaseConditionsDuesRequest({
	payload
}: ICrudGetPurchaseConditionsDuesSaga): any {
	try {
		const values: any = yield call(crudGetPurchaseConditionsDues, payload)
		yield put(crudGetPurchaseConditionsDuesSuccess(values))
	} catch (error) {}
}

function* crudUpdatePurchaseConditionsDuesRequest({
	payload
}: ICrudUpdatePurchaseConditionsDuesSaga): any {
	try {
		const values: any = yield call(crudUpdatePurchaseConditionsDues, payload)
		yield put(crudUpdatePurchaseConditionsDuesSuccess(values))
	} catch (error) {}
}

export function* crudGetPurchaseConditionsDuesSaga() {
	yield takeLatest(
		CRUD_GET_PURCHASE_CONDITIONS_DUES,
		crudGetPurchaseConditionsDuesRequest
	)
}

export function* crudUpdatePurchaseConditionsDuesSaga() {
	yield takeLatest(
		CRUD_UPDATE_PURCHASE_CONDITIONS_DUES,
		crudUpdatePurchaseConditionsDuesRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetPurchaseConditionsDuesSaga),
		fork(crudUpdatePurchaseConditionsDuesSaga)
	])
}
