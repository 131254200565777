import {
	CHANGE_PASSWORD,
	CHANGE_PASSWORD_ERROR,
	CHANGE_PASSWORD_SUCCESS,
	GET_PERMISSIONS,
	GET_PERMISSIONS_SUCCESS,
	GET_USER,
	GET_USER_SUCCESS,
	SELECT_COMPANY,
	SELECT_COMPANY_SUCCESS,
	SET_COMPANY_ERROR,
	SET_COMPANY_ERROR_SUCCESS,
	SIGNIN_USER,
	SIGNIN_USER_SUCCESS,
	SIGNOUT_USER,
	SIGNOUT_USER_SUCCESS
} from 'constants/ActionsTypes'

export const userSignIn = (credentials) => {
	return {
		type: SIGNIN_USER,
		payload: credentials
	}
}

export const userSignInSuccess = (authUser) => {
	return {
		type: SIGNIN_USER_SUCCESS,
		payload: authUser
	}
}

export const userSignOut = () => {
	return {
		type: SIGNOUT_USER
	}
}

export const userSignOutSuccess = () => {
	return {
		type: SIGNOUT_USER_SUCCESS
	}
}

export const getUser = () => {
	return {
		type: GET_USER
	}
}

export const getUserSuccess = (user) => {
	return {
		type: GET_USER_SUCCESS,
		payload: user
	}
}

export const getPermissions = () => {
	return {
		type: GET_PERMISSIONS
	}
}

export const getPermissionsSuccess = (permissions) => {
	return {
		type: GET_PERMISSIONS_SUCCESS,
		payload: permissions
	}
}

export const selectCompany = (company) => {
	return {
		type: SELECT_COMPANY,
		payload: company
	}
}

export const selectCompanySuccess = (success) => {
	return {
		type: SELECT_COMPANY_SUCCESS,
		payload: success
	}
}

export const setCompanyError = (error) => {
	return {
		type: SET_COMPANY_ERROR,
		payload: error
	}
}

export const setCompanyErrorSuccess = (success) => {
	return {
		type: SET_COMPANY_ERROR_SUCCESS,
		payload: success
	}
}

export const userChangePassword = (error) => {
	return {
		type: CHANGE_PASSWORD,
		payload: error
	}
}

export const userChangePasswordSuccess = (success) => {
	return {
		type: CHANGE_PASSWORD_SUCCESS,
		payload: success
	}
}

export const userChangePasswordError = (payload) => {
	return {
		type: CHANGE_PASSWORD_ERROR,
		payload
	}
}
