import { renderInput } from 'lib/FormUtils'
import { ICrudFundAccountField } from 'models/CrudFundsAccountsInterface'
import { ICrudGenericFields } from 'models/CrudInterface'
import {
	IGetTableGenericRes,
	IGetTableGenericResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'
import { principalFields } from '../../constants/crudsConfig/crudFundsAccountsConstants/CrudFundsAccountsFormFields'
import CrudFundAccountsCodeForm from './CrudFundAccountsCodeForm'

type CrudFundAccountDataFieldProps = {
	field: ICrudFundAccountField
	formikProps: any
	crudProps: any
	initialValue: any
	showCodePopup?: boolean
	handleCodePopup?: any
}

type CrudFundAccountDataFieldState = {
	dependsOn: any
	fieldConfig: ICrudGenericFields
	defaultValue?: string
	idField?: any
	value?: any
}

class CrudFundAccountDataField extends Component<
	CrudFundAccountDataFieldProps,
	CrudFundAccountDataFieldState
> {
	constructor(props: CrudFundAccountDataFieldProps) {
		super(props)
		this.state = {
			fieldConfig: {
				id: '',
				type: '',
				width: '',
				autoComplete: '',
				comboData: '',
				maxChars: 0,
				dependsOn: {
					id: '',
					values: [
						{
							idValue: '',
							disabled: false
						}
					]
				}
			},
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field, crudProps, initialValue } = this.props
		const idField = field.idCampo.trim()
		const addData = _.find(principalFields, (field: ICrudGenericFields) => {
			return field.id === idField
		})
		addData && this.setState({ dependsOn: addData.dependsOn })
		addData && this.setState({ fieldConfig: addData })
		addData && this.setState({ idField: idField })
		addData && this.setState({ defaultValue: addData.default })

		idField === 'cuit' && crudProps.getTableIsTaxesIdValid(initialValue)
	}

	// Update check for CPOS validation, and default state of dropdowns
	componentDidUpdate = (prevProps: CrudFundAccountDataFieldProps) => {
		const { crudProps: prevCrudProps, formikProps: prevFormikProps } = prevProps
		const { formikProps, crudProps } = this.props

		const { idField, defaultValue, fieldConfig } = this.state

		if (
			idField === 'cod_catfon' &&
			formikProps.values[idField] !== formikProps.initialValues[idField] &&
			formikProps.values[idField] !== prevFormikProps.values[idField]
		) {
			formikProps.setFieldValue('cod_fond', '')
		} else if (
			idField === 'cod_catfon' &&
			formikProps.values[idField] === formikProps.initialValues[idField] &&
			formikProps.values[idField] !== prevFormikProps.values[idField]
		) {
			formikProps.setFieldValue('cod_fond', formikProps.initialValues.cod_fond)
		}

		if (
			fieldConfig.type !== 'combo' &&
			!formikProps.values[idField] &&
			formikProps.values[idField] !== 0 &&
			defaultValue
		) {
			formikProps.setFieldValue(idField, defaultValue)
		}

		if (
			idField === 'cuit' &&
			prevCrudProps.isTaxesIdValid !== crudProps.isTaxesIdValid
		) {
			formikProps.setFieldValue(idField, formikProps.values[idField])
		}

		if (
			idField === 'cod_catfon' &&
			formikProps.values[idField] &&
			prevFormikProps.values[idField] !== formikProps.values[idField]
		) {
			crudProps.crudGetFundAccountsCodeConfig(formikProps.values.cod_catfon)
		}
		if (
			idField === 'cod_fond' &&
			crudProps.fundAccountsCodeValidated &&
			crudProps.fundAccountsCodeValidated !==
				prevCrudProps.fundAccountsCodeValidated
		) {
			formikProps.setFieldValue(
				idField,
				crudProps.fundAccountsCodeValidated.codigo_completo
			)
		}
	}

	// Setup combo field if it's empty
	comboDataField = (data: IGetTableGenericResArray) => {
		const values: { cod_valor: string; desc_valor: string }[] = []
		data &&
			_.each(data.lista, (opt: IGetTableGenericRes) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	handleBlur = async () => {
		const { formikProps, crudProps } = this.props
		const { idField } = this.state
		formikProps.setTouched({
			...formikProps.touched,
			[idField]: true
		})
		idField === 'cuit' &&
			crudProps.getTableIsTaxesIdValid(formikProps.values[idField])
	}

	handleSubmitPopup = (data: any) => {
		const { crudProps } = this.props
		crudProps.crudValidateFundAccountsCode({
			alta: 1,
			cod_val: data.cod_val,
			codigo_actual: data.cod_fond,
			codigo_completo: data.values.preview
		})
	}

	renderInputs = (
		field: ICrudFundAccountField,
		formikProps: any,
		disableForm: boolean,
		fieldConfig: ICrudGenericFields
	) => {
		const { crudProps, showCodePopup, handleCodePopup } = this.props
		const { values, setFieldValue } = formikProps
		const { dependsOn, idField } = this.state
		const addData = fieldConfig

		addData &&
			(field = {
				...field,
				tipo: addData.type,
				colInput: addData.width,
				autoComplete: addData.autoComplete,
				onBlur: this.handleBlur
			})

		const codeProps = {
			disableForm: crudProps.disableForm,
			itemInfo: crudProps.itemInfo,
			method: crudProps.method,
			cod_val: values.cod_catfon,
			cod_fond: values.cod_fond
		}

		if (field.tipo === 'combo') {
			addData &&
				(field.valores = crudProps[addData.comboData as keyof typeof crudProps]
					? this.comboDataField(
							crudProps[addData.comboData as keyof typeof crudProps]
					  )
					: [])
			!formikProps.values[idField] &&
				(field.onChange = (event: any) =>
					setFieldValue(field.idCampo, event.target.value))
		} else if (field.tipo === 'check') {
			field.customProps = {
				checked: Number(values[field.idCampo])
			}
		} else if (field.tipo === 'withModal') {
			field.customProps = {
				modalSubmit: this.handleSubmitPopup,
				modalProps: codeProps,
				ModalBody: CrudFundAccountsCodeForm,
				showEditPopup: showCodePopup,
				handleEditPopup: handleCodePopup
			}
		}

		dependsOn.id !== '' &&
			_.each(dependsOn.values, (dependency) => {
				values[dependsOn.id] === dependency.idValue &&
					(field.disabled = dependency.disabled)
			})

		if (!field.editable) field.disabled = true
		if (field.visible) {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { field, formikProps, crudProps } = this.props
		const { fieldConfig } = this.state
		return (
			<Fragment>
				{this.renderInputs(
					field,
					formikProps,
					crudProps.disableForm,
					fieldConfig
				)}
			</Fragment>
		)
	}
}

export default CrudFundAccountDataField
