import {
	faArrowAltCircleDown,
	faArrowAltCircleUp,
	faHouseUser,
	faServer
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getDetailInventorySummary } from 'actions'
import CommonModal from 'components/common/commonModal'
import CommonTable from 'components/common/commonTable'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getValueMask } from 'lib/MaskValues'
import { IGetDetailVoucherInventoryParams } from 'models/SearchVoucher'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import SearchVouchersInventoryResumen from './SearchVouchersInventoryResumen'
import SearchVouchersInventorySerieModal from './SearchVouchersInventorySerieModal'
import SearchVouchersInventorySourceModal from './SearchVouchersInventorySourceModal'

interface SearchVouchersInventoryProps extends WithTranslation {
	id: string
	fields: Array<IFieldStructure>
	fieldsTable: Array<IFieldStructure>
	getDetailInventorySummary: (payload: IGetDetailVoucherInventoryParams) => void
	authUser: any
	inventoryData?: any
	t?: any
	fieldsSourceModal?: any
	fieldsSerieModal?: any
}

interface SearchVouchersInventoryState {
	newId: string
	showModal: boolean
	modalBody: any
	titleModal: string
}

class SearchVouchersInventory extends Component<
	SearchVouchersInventoryProps,
	SearchVouchersInventoryState
> {
	constructor(props: SearchVouchersInventoryProps) {
		super(props)
		this.state = {
			newId: '',
			showModal: false,
			modalBody: null,
			titleModal: ''
		}
	}

	componentDidMount = () => {
		this.getInventorySummary()
	}

	/**
	 * to save id in state
	 */
	handleGetInventorySummary = (filter: { id: string }) => {
		const { id } = filter
		this.setState({ newId: id })
		this.getInventorySummary(filter)
	}

	/**
	 * to get all data of inventory
	 * @param filters
	 */
	getInventorySummary = (filters: any = {}) => {
		const { authUser, id } = this.props
		const { newId } = this.state
		this.props.getDetailInventorySummary({
			idEmpresa: authUser.userCompany,
			page_number: 1,
			page_size: 10,
			id: newId || id,
			...filters
		})
	}

	/**
	 * to get all pagination options
	 * @returns object
	 */
	getPaginationOptions = () => {
		const { inventoryData } = this.props
		return {
			pageStartIndex: 1,
			sizePerPage: inventoryData.page_size,
			page: inventoryData.page_number,
			totalSize: inventoryData.total_count,
			onPageChange: this.handleChangeTable
		}
	}

	/**
	 * to handle pagination
	 */
	handleChangeTable = (type: string, pagination: any) => {
		if (type === 'pagination') {
			this.getInventorySummary({
				page_size: pagination.sizePerPage,
				page_number: pagination.page
			})
		}
	}

	/**
	 * To get columns for table.
	 * @returns dataTable Columns
	 */
	getColumns = () => {
		const { fieldsTable } = this.props

		const rows = fieldsTable.map((field) => {
			const fieldId = field.idCampo.trim()

			return {
				dataField: fieldId,
				text: field.label ? (fieldId === 'cuenta' ? '' : field.label) : '',
				align: 'center',
				headerAlign: 'center',
				hidden: !field.visible,
				formatter: (cell: string, row: any) =>
					this.renderFormat(field, cell, row)
			}
		})

		rows.push({
			dataField: 'actions',
			text: '',
			align: 'center',
			headerAlign: 'center',
			hidden: false,
			formatter: (cell: string, row: any) => {
				return (
					<Fragment>
						<FontAwesomeIcon
							className={'mr-3'}
							icon={faHouseUser}
							style={{ cursor: 'pointer' }}
							color={'#007bff'}
							onClick={() => this.handleDetailModalContent(row, 'source')}
						/>
						<FontAwesomeIcon
							className={'mr-3'}
							icon={faServer}
							style={{ cursor: 'pointer' }}
							color={'#007bff'}
							onClick={() => this.handleDetailModalContent(row, 'serie')}
						/>
					</Fragment>
				)
			}
		})

		return rows
	}

	/**
	 * select modal data
	 * @param row
	 * @param type
	 */
	handleDetailModalContent = (row: any, type: string) => {
		const { fieldsSourceModal, fieldsSerieModal, t, inventoryData } = this.props

		const titleModal =
			type === 'source'
				? t('search_voucher.detail.source')
				: t('search_voucher.detail.serie')
		const modalBody =
			type === 'source' ? (
				<SearchVouchersInventorySourceModal
					fields={fieldsSourceModal}
					data={row}
					handleClose={this.toggleDetailModal}
				/>
			) : (
				<SearchVouchersInventorySerieModal
					fields={fieldsSerieModal}
					it={row.nitem}
					id={inventoryData.nro_int}
					handleClose={this.toggleDetailModal}
				/>
			)
		this.setState({ modalBody, titleModal })
		this.toggleDetailModal()
	}

	/**
	 * to change show/hide modalTitle
	 */
	toggleDetailModal = () => {
		this.setState((prevState) => ({ showModal: !prevState.showModal }))
	}

	/**
	 * to render column data with icons and format
	 * @param field
	 * @param cell
	 * @param row
	 * @returns columns data
	 */
	renderFormat = (field: IFieldStructure, cell: string, row: any) => {
		const { authUser } = this.props

		const fieldId = field.idCampo.trim()
		let result: any = cell
		if (fieldId === 'id_ascendente' || fieldId === 'id_dependiente') {
			const iconAction =
				fieldId === 'id_ascendente' ? faArrowAltCircleUp : faArrowAltCircleDown
			result = !_.isEmpty(cell) ? (
				<FontAwesomeIcon
					icon={iconAction}
					style={{ cursor: 'pointer' }}
					color={'#007bff'}
					onClick={() => this.handleGetInventorySummary({ id: cell })}
				/>
			) : (
				cell
			)
		}

		if (field.mascara) {
			result = getValueMask(cell, field.mascara, { authUser })
		}

		return result
	}

	render() {
		const { fields, fieldsTable, inventoryData } = this.props
		const { showModal, modalBody, titleModal } = this.state

		const propsTable = {
			remote: true,
			columns: _.isEmpty(fieldsTable) ? [] : this.getColumns(),
			keyField: 'nro',
			data: _.isEmpty(inventoryData) ? [] : inventoryData.items,
			rowClasses: 'theme.tableRow',
			headerClasses: 'theme.tableHeader',
			paginationOptions: this.getPaginationOptions,
			onTableChange: this.handleChangeTable
		}

		return (
			<Fragment>
				<CommonModal
					sizeModal="lg"
					showModal={showModal}
					modalTitle={titleModal}
					handleCloseModal={this.toggleDetailModal}
					buttons={false}
					modalBody={modalBody}
				/>
				<Row>
					<Col>
						<SearchVouchersInventoryResumen
							fields={fields}
							inventoryData={inventoryData}
							handleChangeOptions={this.handleGetInventorySummary}
						/>
					</Col>
				</Row>
				<Row>{!_.isEmpty(fieldsTable) && <CommonTable {...propsTable} />}</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth, searchVoucher }: any) => {
	const { authUser } = auth
	const { inventoryData } = searchVoucher
	return { authUser, inventoryData }
}

const connectForm = connect(mapStateToProps, { getDetailInventorySummary })(
	SearchVouchersInventory
)

export default withTranslation()(connectForm)
