import {
	AUTO_GENERATE_CANCEL,
	AUTO_GENERATE_CANCEL_SUCCESS,
	AUTO_GENERATE_CLEAN_STORE,
	AUTO_GENERATE_CONFIRM,
	AUTO_GENERATE_CONFIRM_SUCCESS,
	AUTO_GENERATE_STATUS,
	AUTO_GENERATE_STATUS_SUCCESS
} from 'constants/ActionsTypes'
import {
	IAutoGenerateCancelAction,
	IAutoGenerateCancelParams,
	IAutoGenerateConfirmAction,
	IAutoGenerateConfirmParams,
	IAutoGenerateStatusAction,
	IAutoGenerateStatusParams
} from 'models/AutoGenerate'

export const autoGenerateConfirm = (
	payload: IAutoGenerateConfirmParams
): IAutoGenerateConfirmAction => ({
	type: AUTO_GENERATE_CONFIRM,
	payload
})

export const autoGenerateConfirmSuccess = (payload: any) => ({
	type: AUTO_GENERATE_CONFIRM_SUCCESS,
	payload
})

export const autoGenerateStatus = (
	payload: IAutoGenerateStatusParams
): IAutoGenerateStatusAction => ({
	type: AUTO_GENERATE_STATUS,
	payload
})

export const autoGenerateStatusSuccess = (payload: any) => ({
	type: AUTO_GENERATE_STATUS_SUCCESS,
	payload
})

export const autoGenerateCancel = (
	payload: IAutoGenerateCancelParams
): IAutoGenerateCancelAction => ({
	type: AUTO_GENERATE_CANCEL,
	payload
})

export const autoGenerateCancelSuccess = (payload: any) => ({
	type: AUTO_GENERATE_CANCEL_SUCCESS,
	payload
})

export const autoGenerateCleanStore = () => ({
	type: AUTO_GENERATE_CLEAN_STORE
})
