import DisplayAmount from 'components/common/displayAmount'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'

export default class CollectionTotals extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tableOrder: null
		}
	}

	render() {
		const { t, totals } = this.props
		const {
			pend_cobro_usual,
			pend_cobro_ext,
			cobro_usual,
			cobro_ext,
			// cod_mone_usu,
			cod_mone_ext
		} = totals

		return (
			<Row
				className={'alert'}
				style={{ backgroundColor: 'rgba(111, 105, 105, 0.11)' }}
				role={'alert'}
			>
				<Col md={6}>
					<Row>
						<Col sm={6} className={'text-right font-weight-bold'}>
							{t('Collection.table.pendingCollection')}
						</Col>
						<Col className={'text-left'}>
							<DisplayAmount
								amount={pend_cobro_usual > 0 ? pend_cobro_usual : 0}
							/>
							<br />
							<DisplayAmount
								amount={pend_cobro_ext > 0 ? pend_cobro_ext : 0}
								currency={'USD'}
								prefix={cod_mone_ext}
							/>
						</Col>
					</Row>
				</Col>
				<Col md={6}>
					<Row>
						<Col sm={6} className={'text-right font-weight-bold'}>
							{t('Collection.table.totalCharged')}
						</Col>
						<Col className={'text-left'}>
							<DisplayAmount amount={cobro_usual} />
							<br />
							<DisplayAmount
								amount={cobro_ext}
								currency={'USD'}
								prefix={cod_mone_ext}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		)
	}
}
