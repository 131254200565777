import { productDepotsFields } from 'constants/crudsConfig/crudProductsConstants/CrudProductDepotsFormFields'
import { renderInput } from 'lib/FormUtils'
import { ICrudGenericFields } from 'models/CrudInterface'
import {
	IGetTableGenericRes,
	IGetTableGenericResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'

type CrudProductDepotsFieldProps = {
	field: any
	formikProps: any
	crudProps: any
	initialValue: any
}

type CrudProductDepotsFieldState = {
	dependsOn: any
	fieldConfig: ICrudGenericFields
	defaultValue?: string
	idField?: any
	value?: any
}

class CrudProductDepotsField extends Component<
	CrudProductDepotsFieldProps,
	CrudProductDepotsFieldState
> {
	constructor(props: CrudProductDepotsFieldProps) {
		super(props)
		this.state = {
			fieldConfig: {
				id: '',
				type: '',
				width: '',
				autoComplete: '',
				comboData: '',
				maxChars: 0,
				dependsOn: {
					id: '',
					values: [
						{
							idValue: '',
							disabled: false
						}
					]
				}
			},
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field } = this.props
		const idField = field.idCampo.trim()
		const addData = _.find(productDepotsFields, (field: ICrudGenericFields) => {
			return field.id === idField
		})
		addData && this.setState({ dependsOn: addData.dependsOn })
		addData && this.setState({ fieldConfig: addData })
		addData && this.setState({ idField: idField })
		addData && this.setState({ defaultValue: addData.default })
	}

	// Update check for CPOS validation, and default state of dropdowns
	componentDidUpdate = (prevProps: CrudProductDepotsFieldProps) => {
		const { formikProps } = this.props

		const { idField, defaultValue, fieldConfig } = this.state

		if (
			fieldConfig.type !== 'combo' &&
			!formikProps.values[idField] &&
			formikProps.values[idField] !== 0 &&
			defaultValue
		) {
			formikProps.setFieldValue(idField, defaultValue)
		}
	}

	handleBlur = async () => {
		const { formikProps } = this.props
		const { idField } = this.state
		formikProps.setTouched({
			...formikProps.touched,
			[idField]: true
		})
	}

	// Setup depot field if it's empty
	comboDataField = (data: IGetTableGenericResArray) => {
		const values: { cod_valor: string; desc_valor: string }[] = []
		data &&
			_.each(data.lista, (opt: IGetTableGenericRes) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	renderInputs = (
		field: any,
		formikProps: any,
		disableForm: boolean,
		fieldConfig: ICrudGenericFields
	) => {
		const { crudProps } = this.props
		const { values, setFieldValue } = formikProps
		const { idField } = this.state
		const addData = fieldConfig

		addData &&
			(field = {
				...field,
				tipo: addData.type,
				colInput: addData.width,
				autoComplete: addData.autoComplete,
				onBlur: this.handleBlur,
				disabled: addData.disabled,
				customProps: {
					maxLength: addData.maxChars
				}
			})

		if (field.tipo === 'combo') {
			addData &&
				(field.valores = crudProps[addData.comboData as keyof typeof crudProps]
					? this.comboDataField(
							crudProps[addData.comboData as keyof typeof crudProps]
					  )
					: [])
			!formikProps.values[idField] &&
				(field.onChange = (event: any) =>
					setFieldValue(field.idCampo, event.target.value))
		} else if (field.tipo === 'check') {
			field.customProps = {
				...field.customProps,
				checked: Number(values[field.idCampo])
			}
		}

		if (field.idCampo === 'cod_dep' && crudProps.alta === 0) {
			field.disabled = true
		}

		if (!field.editable) field.disabled = true
		if (field.visible) {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { field, formikProps, crudProps } = this.props
		const { fieldConfig } = this.state
		return (
			<Fragment>
				{this.renderInputs(
					field,
					formikProps,
					crudProps.disableForm,
					fieldConfig
				)}
			</Fragment>
		)
	}
}

export default CrudProductDepotsField
