import {
	ADVANCED_PRODUCT_SEARCH,
	ADVANCED_PRODUCT_SEARCH_SUCCESS,
	CHECK_ITEM_BY_PRODUCT,
	CHECK_ITEM_BY_PRODUCT_SUCCESS,
	FINISH_PRODUCT_CART,
	FINISH_PRODUCT_CART_SUCCESS,
	GET_PRICE_ADD_CART,
	GET_PRICE_BY_PRODUCT,
	GET_PRICE_BY_PRODUCT_SUCCESS,
	GET_PRODUCTS,
	GET_PRODUCTS_CART,
	GET_PRODUCTS_CART_SUCCESS,
	GET_PRODUCTS_SUCCESS,
	GET_PRODUCT_COST,
	GET_PRODUCT_COST_SUCCESS,
	GET_PRODUCT_IMAGE,
	GET_PRODUCT_IMAGE_ERROR,
	GET_PRODUCT_IMAGE_SUCCESS,
	GET_PRODUCT_PRICE_LIST,
	GET_PRODUCT_PRICE_LIST_SUCCESS,
	REMOVE_PRODUCT_CART,
	REMOVE_PRODUCT_CART_SUCCESS,
	SEARCH_AUTO_PRODUCTS,
	SEARCH_AUTO_PRODUCTS_SUCCESS,
	SEARCH_PRODUCTS,
	SEARCH_PRODUCTS_SUCCESS,
	SET_INPUT_FOCUS,
	SET_INPUT_FOCUS_SUCCESS,
	SET_TABLE_DATA_PRODUCTS,
	VALIDATE_PRODUCT_COST,
	VALIDATE_PRODUCT_COST_NET,
	VALIDATE_PRODUCT_COST_NET_SUCCESS,
	VALIDATE_PRODUCT_COST_PC,
	VALIDATE_PRODUCT_COST_PC_SUCCESS,
	VALIDATE_PRODUCT_COST_SUCCESS,
	VALIDATE_PRODUCT_DISCOUNT,
	VALIDATE_PRODUCT_DISCOUNT_SUCCESS,
	VALIDATE_PRODUCT_NET,
	VALIDATE_PRODUCT_NET_SUCCESS,
	VALIDATE_PRODUCT_PRICE,
	VALIDATE_PRODUCT_PRICE_SUCCESS
} from 'constants/ActionsTypes'

export const getProducts = (params) => {
	return {
		type: GET_PRODUCTS,
		payload: params
	}
}

export const getProductsSuccess = (response) => {
	return {
		type: GET_PRODUCTS_SUCCESS,
		payload: response
	}
}

export const searchProducts = (params) => {
	return {
		type: SEARCH_PRODUCTS,
		payload: params
	}
}

export const searchProductsSuccess = (response) => {
	return {
		type: SEARCH_PRODUCTS_SUCCESS,
		payload: response
	}
}

export const advancedProductSearch = (params) => {
	return {
		type: ADVANCED_PRODUCT_SEARCH,
		payload: params
	}
}

export const advancedProductSearchSuccess = (response) => {
	return {
		type: ADVANCED_PRODUCT_SEARCH_SUCCESS,
		payload: response
	}
}

export const searchAutoProducts = (params) => {
	return {
		type: SEARCH_AUTO_PRODUCTS,
		payload: params
	}
}

export const searchAutoProductsSuccess = (response) => {
	return {
		type: SEARCH_AUTO_PRODUCTS_SUCCESS,
		payload: response
	}
}

export const getPriceByProduct = (params) => {
	return {
		type: GET_PRICE_BY_PRODUCT,
		payload: params
	}
}

export const getPriceByProductSuccess = (response) => {
	return {
		type: GET_PRICE_BY_PRODUCT_SUCCESS,
		payload: response
	}
}

export const getProductCost = (params) => {
	return {
		type: GET_PRODUCT_COST,
		payload: params
	}
}

export const getProductCostSuccess = (response) => {
	return {
		type: GET_PRODUCT_COST_SUCCESS,
		payload: response
	}
}

export const checkItemByProduct = (params) => {
	return {
		type: CHECK_ITEM_BY_PRODUCT,
		payload: params
	}
}

export const checkItemByProductSuccess = (response) => {
	return {
		type: CHECK_ITEM_BY_PRODUCT_SUCCESS,
		payload: response
	}
}

export const setTableDataProducts = (params) => {
	return {
		type: SET_TABLE_DATA_PRODUCTS,
		payload: params
	}
}

export const getProductsCart = (params) => {
	return {
		type: GET_PRODUCTS_CART,
		payload: params
	}
}

export const getProductsCartSuccess = (response) => {
	return {
		type: GET_PRODUCTS_CART_SUCCESS,
		payload: response
	}
}

export const removeProductCart = (params) => {
	return {
		type: REMOVE_PRODUCT_CART,
		payload: params
	}
}

export const removeProductCartSuccess = (response) => {
	return {
		type: REMOVE_PRODUCT_CART_SUCCESS,
		payload: response
	}
}

export const setInputFocus = (field) => {
	return {
		type: SET_INPUT_FOCUS,
		payload: field
	}
}

export const setInputFocusSuccess = (response) => {
	return {
		type: SET_INPUT_FOCUS_SUCCESS,
		payload: response
	}
}

export const getProductImage = (params) => {
	return {
		type: GET_PRODUCT_IMAGE,
		payload: params
	}
}

export const getProductImageSuccess = (response) => {
	return {
		type: GET_PRODUCT_IMAGE_SUCCESS,
		payload: response
	}
}

export const getProductImageError = (response) => {
	return {
		type: GET_PRODUCT_IMAGE_ERROR,
		payload: response
	}
}

export const finishProductCart = (params) => {
	return {
		type: FINISH_PRODUCT_CART,
		payload: params
	}
}

export const finishProductCartSuccess = (response) => {
	return {
		type: FINISH_PRODUCT_CART_SUCCESS,
		payload: response
	}
}

export const getPriceAddCart = (params) => {
	return {
		type: GET_PRICE_ADD_CART,
		payload: params
	}
}

export const validateProductDiscount = (payload) => {
	return {
		type: VALIDATE_PRODUCT_DISCOUNT,
		payload
	}
}

export const validateProductDiscountSuccess = (payload) => {
	return {
		type: VALIDATE_PRODUCT_DISCOUNT_SUCCESS,
		payload
	}
}

export const validateProductPrice = (payload) => {
	return {
		type: VALIDATE_PRODUCT_PRICE,
		payload
	}
}

export const validateProductPriceSuccess = (payload) => {
	return {
		type: VALIDATE_PRODUCT_PRICE_SUCCESS,
		payload
	}
}

export const getProductPriceList = (payload) => {
	return {
		type: GET_PRODUCT_PRICE_LIST,
		payload
	}
}

export const getProductPriceListSuccess = (payload) => {
	return {
		type: GET_PRODUCT_PRICE_LIST_SUCCESS,
		payload
	}
}

export const validateProductCost = (payload) => {
	return {
		type: VALIDATE_PRODUCT_COST,
		payload
	}
}

export const validateProductCostSuccess = (payload) => {
	return {
		type: VALIDATE_PRODUCT_COST_SUCCESS,
		payload
	}
}

export const validateProductNet = (payload) => {
	return {
		type: VALIDATE_PRODUCT_NET,
		payload
	}
}

export const validateProductNetSuccess = (payload) => {
	return {
		type: VALIDATE_PRODUCT_NET_SUCCESS,
		payload
	}
}

export const validateProductCostPC = (payload) => {
	return {
		type: VALIDATE_PRODUCT_COST_PC,
		payload
	}
}

export const validateProductCostPCSuccess = (payload) => {
	return {
		type: VALIDATE_PRODUCT_COST_PC_SUCCESS,
		payload
	}
}

export const validateProductCostNet = (payload) => {
	return {
		type: VALIDATE_PRODUCT_COST_NET,
		payload
	}
}

export const validateProductCostNetSuccess = (payload) => {
	return {
		type: VALIDATE_PRODUCT_COST_NET_SUCCESS,
		payload
	}
}
