import { getFilterTypeAccounting } from 'actions'
import InputDropdown from 'components/form/inputDropdown'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getOptionsDropDown, handleSetValueInput } from 'lib/FormUtils'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'
import InputText from '../form/inputText'
import styles from './SearchVoucher.module.scss'

type SearchFormProps = {
	t: any
	values: any
	handleChange: any
	handleBlur: any
	setFieldValue: any
	readOnly: boolean
	fields: Array<IFieldStructure>
	setFieldTouched: any
	getFilterTypeAccounting: () => void
	filterAccountingEntry: any
}

class AccountantSearchFormInput extends Component<SearchFormProps> {
	componentDidMount = () => {
		const { filterAccountingEntry } = this.props
		if (_.isEmpty(filterAccountingEntry)) {
			this.props.getFilterTypeAccounting()
		}
	}

	render() {
		const {
			values,
			handleBlur,
			readOnly,
			fields,
			t,
			setFieldTouched,
			setFieldValue,
			filterAccountingEntry
		} = this.props

		const formikProps = { setFieldTouched, setFieldValue }

		const propsTypes = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.module'),
			inputId: 'tipo_asto',
			name: 'tipo_asto',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.tipo_asto,
			options: getOptionsDropDown(filterAccountingEntry),
			noInitialExecute: true,
			onChange: (data: any) =>
				handleSetValueInput(data, 'tipo_asto', formikProps)
		}

		const propsCtaCont = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.module'),
			inputId: 'cta_cont',
			name: 'cta_cont',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.cta_cont,
			noInitialExecute: true,
			onChange: (data: any) =>
				handleSetValueInput(data, 'cta_cont', formikProps)
		}

		const propsDescAsto = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.module'),
			inputId: 'desc_asto',
			name: 'desc_asto',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.desc_asto,
			noInitialExecute: true,
			onChange: (data: any) =>
				handleSetValueInput(data, 'desc_asto', formikProps)
		}

		return (
			<Row>
				<Col className={styles.module_search_row} sm={12}>
					<InputDropdown {...propsTypes} />
				</Col>
				<Col className={styles.module_search_row} sm={12}>
					<InputText {...propsCtaCont} />
				</Col>
				<Col className={styles.module_search_row} sm={12}>
					<InputText {...propsDescAsto} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = ({ searchVoucher }: any) => {
	const { filterAccountingEntry } = searchVoucher
	return { filterAccountingEntry }
}

export default connect(mapStateToProps, { getFilterTypeAccounting })(
	AccountantSearchFormInput
)
