import {
	CANCEL_ATTRIBUTES,
	CANCEL_ATTRIBUTES_SUCCESS,
	CONFIRM_ATTRIBUTES,
	CONFIRM_ATTRIBUTES_SUCCESS,
	GET_ATTRIBUTES,
	GET_ATTRIBUTES_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	attributeList: any
	confirmedAttributes: any
	canceledAttributes: any
}

const initialState = {
	attributeList: null,
	confirmedAttributes: null,
	canceledAttributes: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case GET_ATTRIBUTES:
			return { ...state, attributeList: null }
		case GET_ATTRIBUTES_SUCCESS:
			return {
				...state,
				attributeList: action.payload
			}
		case CONFIRM_ATTRIBUTES:
			return { ...state, confirmedAttributes: null }
		case CONFIRM_ATTRIBUTES_SUCCESS:
			return { ...state, confirmedAttributes: action.payload }
		case CANCEL_ATTRIBUTES:
			return { ...state, canceledAttributes: null }
		case CANCEL_ATTRIBUTES_SUCCESS:
			return { ...state, canceledAttributes: action.payload }

		default:
			return state
	}
}

export default rootReducer
