import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalValidate from 'components/headboard/modalValidate'
import { validateField } from 'lib/FieldValidations'
import { debugMessage } from 'lib/Utils'
import moment from 'moment'
import React, { Fragment, PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { IMaskInput } from 'react-imask'
import { connect } from 'react-redux'
import _ from 'underscore'
import { getValueUnmask } from '../../lib/MaskValues'
import styles from './inputText.module.css'
class InputText extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			showLockModal: false,
			inputValue: props.value,
			configInput: this.getConfigField(props.inputId),
			requireError: props.showError ? props.showError : false,
			trueValue: null
		}
	}

	componentDidUpdate = (prevProps) => {
		const { fields, value } = this.props

		if (!_.isEqual(prevProps.fields, fields) && !_.isEmpty(fields)) {
			this.setState({ configInput: this.getConfigField(this.props.inputId) })
		}
		if (prevProps.value !== value && value !== undefined && value !== null) {
			this.setState({ inputValue: value, trueValue: value }) // Valor inicial
		}
	}

	_handleKeyDown = (e) => {
		const { activeListeners, tabKeyPress, handleEnterKey } = this.props
		const { trueValue } = this.state
		switch (e.keyCode) {
			case 13: {
				// ENTER
				if (handleEnterKey) {
					handleEnterKey(e, trueValue)
					e.preventDefault()
				}
				break
			}
			default:
				break
		}

		// TAB
		if (tabKeyPress && !e.shiftKey && e.keyCode === 9) {
			if (this.props.handleEnterKey) {
				// this.props.handleEnterKey(e, this.state.trueValue);
			}
		}

		if (activeListeners) {
			debugMessage({
				stacktrace: e.type,
				message: `keyCode: ${e.keyCode} value:${
					e.target.value
				} ASCII:${e.target.value.charCodeAt(0)}`
			})
		}
		return false
	}

	_handleKeyUp = (e) => {
		debugMessage({
			stacktrace: e.type,
			message: `keyCode: ${e.keyCode} value:${e.target.value}`
		})
	}

	_handleKeyPress = (e) => {
		debugMessage({
			stacktrace: e.type,
			message: `keyCode: ${e.keyCode} value:${e.target.value}`
		})
		// debugMessage({ stacktrace: e.type, message: `keyCode: ${e.keyCode} value:${e.target.value}` })
	}

	handleShowModal = () => {
		this.setState({ showLockModal: true })
	}

	handleCancelModal = () => {
		this.setState({ showLockModal: false })
	}

	handleClearValue = () => {
		const { onBlur, onChange } = this.props
		this.setState({ inputValue: '' })
		if (onBlur) {
			onBlur('')
		}
		if (onChange) {
			onChange('')
		}
	}

	handleSubmit = (data) => {
		this.handleCancelModal()
		this.props.handleSubmit(data)
	}

	handleChange = (data) => {
		const { onChange } = this.props
		const { mascara } = this.state.configInput
		const value = data && data.target ? data.target.value : data
		const trueValue = getValueUnmask(value, mascara, this.props)

		this.setState({ inputValue: value, trueValue })
		if (onChange) {
			onChange(trueValue)
		}
	}

	handleOnblur = () => {
		const { onBlur } = this.props
		const { configInput, inputValue } = this.state
		let ban = true

		if (configInput.valid && !validateField(inputValue, configInput.valid)) {
			ban = false
		}

		if (onBlur && ban) {
			onBlur(this.state.inputValue)
		} else if (onBlur) {
			onBlur()
		}

		if (configInput.requerido && !this.state.inputValue) {
			this.setState({ requireError: true })
		} else if (this.state.inputValue) {
			this.setState({ requireError: false })
		}
	}

	handleNumberOnblur = (data) => {
		const { onBlur } = this.props
		const { inputValue, configInput } = this.state
		const value = data.target.value
		const trueValue =
			inputValue === ''
				? inputValue
				: getValueUnmask(value, configInput.mascara, this.props)
		this.setState({ inputValue: value, trueValue })

		if (configInput.requerido && !inputValue) {
			this.setState({ requireError: true })
		}
		if (onBlur) {
			onBlur(trueValue)
		}
	}

	getConfigField = (id) => {
		const { fields } = this.props
		let result = {}
		if (fields) {
			fields.forEach((field) => {
				if (field.idCampo.trim() === id) {
					result = field
				}
			})
		}

		return result
	}

	getMask = (config) => {
		const { authUser } = this.props
		const maskInput = authUser.configApp.mascaras[config.mascara]
			? authUser.configApp.mascaras[config.mascara]
			: null
		return maskInput || false
	}

	renderInput = (options, config) => {
		const { trueValue } = this.state
		let response
		if (config.mascara) {
			const mask = this.getMask(config) // Se obtiene las posibles opciones de mascara.. aca se agregan validaciones.
			if (mask.tipo === 'fecha') {
				const formatDate = mask.valor
					? mask.valor.replace(/D/g, 'd').replace(/Y/g, 'y')
					: 'MM-dd-yyyy'
				const customDate = options.value ? moment(options.value) : null // Para dar una fecha por defecto.
				const date = customDate ? customDate.format(mask.valor) : customDate

				const valueDate =
					new Date(date) === 'Invalid Date'
						? new Date(options.value)
						: new Date(options.value)

				const maskInput = formatDate.replace(/[A-Za-z\w]/g, '0')
				// options.value = trueValue

				response = (
					<DatePicker
						{...options}
						onChange={(date, evt) => {
							this.props.onChange(date, evt)
							this.setState({ trueValue: date })
						}}
						value={trueValue}
						placeholderText={options.placeholder}
						selected={valueDate.getTime() ? valueDate : trueValue}
						dateFormat={formatDate}
						isClearable={!options.disabled && !options.disableClear}
						onCalendarClose={() => {
							this.props.onBlur(trueValue)
						}}
						customInput={
							<IMaskInput
								mask={maskInput}
								{...options}
								placeholder={formatDate}
							/>
						}
						showYearDropdown
					/>
				)
			}
		}

		return response
	}

	renderField = () => {
		const {
			label,
			placeholder,
			name,
			styles,
			inputId,
			id,
			colInput,
			colLabel,
			styleLabel,
			divStyle,
			disable,
			theme,
			type,
			inputFormCol,
			lock,
			rowStyle,
			autoFocus,
			fwRef,
			activeListeners,
			disabledInput,
			autoComplete,
			disableClear,
			maxLength
		} = this.props

		const classInput = label ? colInput : 'col-sm-12'
		const classLabel = label ? colLabel : ''
		const classText = disable ? theme.inputDisabled : ''
		const customType = type || 'text'
		const config = this.state.configInput
		const customStyleLabel = config.requerido
			? { ...styleLabel, color: 'red' }
			: { ...styleLabel }
		const inputStyles = this.state.requireError
			? { ...styles, border: '1px solid red' }
			: styles

		if (config.visible) {
			const optionsInput = {
				id: id || inputId,
				name: name,
				type: customType,
				style: inputStyles,
				placeholder: placeholder,
				autoFocus: autoFocus,
				disabled: !config.editable || disabledInput,
				className: `${theme.inputText} ${classText}`,
				disableClear: disableClear,
				value: this.state.inputValue,
				ref: config.fwRef ? config.fwRef : fwRef,
				autoComplete: autoComplete || '',
				onChange: (v) => this.handleChange(v),
				onBlur: (v) => this.handleOnblur(v),
				onKeyDown: this._handleKeyDown,
				maxLength: maxLength || Infinity
			}

			if (activeListeners) {
				optionsInput.onKeyPress = this._handleKeyPress
				optionsInput.onKeyUp = this._handleKeyUp
			}

			return (
				<Fragment>
					<Col {...inputFormCol}>
						<Row className={'form-group'} style={rowStyle}>
							<label
								className={`${theme.inputLabel}  ${classLabel}`}
								style={customStyleLabel}
							>
								{config.label ? config.label : label}
							</label>
							<Col
								className={`${classInput} ${theme.divDataPicker}`}
								style={{ ...divStyle }}
							>
								{this.renderInput(optionsInput, config)}
							</Col>
						</Row>
					</Col>
					{lock && (
						<Col className={theme.lock} sm={1}>
							<FontAwesomeIcon
								icon={faLock}
								onClick={this.handleShowModal}
								style={{ cursor: 'pointer' }}
							/>
							<ModalValidate
								showModal={this.state.showLockModal}
								handleClose={this.handleCancelModal}
								handleSubmit={this.handleSubmit}
							/>
						</Col>
					)}
				</Fragment>
			)
		} else {
			return null
		}
	}

	render() {
		const { fields } = this.props
		if (fields) {
			return this.renderField()
		} else {
			return null
		}
	}
}

const mapStateToProps = ({ auth }) => {
	const { authUser } = auth
	return { authUser }
}

export default connect(mapStateToProps)(
	themr('InputDateStyle', styles)(InputText)
)
