import { ICrudGenericFields } from 'models/CrudInterface'

export const searchFields: Array<ICrudGenericFields> = [
	{
		id: 'lk_nom',
		type: 'text',
		labelWidth: 'col-sm-2',
		width: 'col-sm-9',
		height: 'col-sm-6',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'lk_niabm',
		type: 'text',
		labelWidth: 'col-sm-4',
		width: 'col-sm-6',
		height: 'col-sm-6',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'lk_modulo',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-5',
		height: 'col-sm-7',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'voucherModules',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'lk_tipoperm',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-5',
		height: 'col-sm-7',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'permissionType',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
