import {
	CRUD_CLEAR_SEARCH,
	CRUD_CLEAR_STORE,
	CRUD_CLONE,
	CRUD_CLONE_SUCCESS,
	CRUD_CREATE,
	CRUD_CREATE_SUCCESS,
	CRUD_DELETE,
	CRUD_DELETE_SUCCESS,
	CRUD_GET_LIST,
	CRUD_GET_LIST_SUCCESS,
	CRUD_GET_SINGLE,
	CRUD_GET_SINGLE_SUCCESS,
	GET_ABM_CODE,
	GET_ABM_CODE_SUCCESS,
	GET_ALL_CRUDS,
	GET_ALL_CRUDS_SUCCESS,
	GET_CONFIG_FIELDS_CRUD,
	GET_CONFIG_FIELDS_CRUD_ALTA,
	GET_CONFIG_FIELDS_CRUD_ALTA_SUCCESS,
	GET_CONFIG_FIELDS_CRUD_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	fieldsConfig: any
	fieldsAltaConfig: any
	fieldsCodeConfig: any
	newAbmCode: any
	listCruds: any
	valuesDataList: any
	valueDataSelected: any
	valueDataUpdated: any
	valueDataRemoved: any
	valueDataCloned: any
}

const initialState = {
	fieldsConfig: null,
	fieldsAltaConfig: null,
	fieldsCodeConfig: null,
	newAbmCode: null,
	listCruds: null,
	valuesDataList: null,
	valueDataSelected: null,
	valueDataUpdated: null,
	valueDataRemoved: null,
	valueDataCloned: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case GET_CONFIG_FIELDS_CRUD:
			return { ...state, fieldsConfig: null }
		case GET_CONFIG_FIELDS_CRUD_SUCCESS:
			return { ...state, fieldsConfig: action.payload }
		case GET_CONFIG_FIELDS_CRUD_ALTA:
			return { ...state, fieldsAltaConfig: null }
		case GET_CONFIG_FIELDS_CRUD_ALTA_SUCCESS:
			return { ...state, fieldsAltaConfig: action.payload }
		case GET_ALL_CRUDS:
			return { ...state, listCruds: null }
		case GET_ALL_CRUDS_SUCCESS:
			return { ...state, listCruds: action.payload }
		case GET_ABM_CODE:
			return { ...state, newAbmCode: null }
		case GET_ABM_CODE_SUCCESS:
			return { ...state, newAbmCode: action.payload }
		case CRUD_GET_LIST:
			return {
				...state,
				valuesDataList: null,
				valueDataRemoved: null,
				paramsSearch: action.payload
			}
		case CRUD_GET_LIST_SUCCESS:
			return { ...state, valuesDataList: action.payload }
		case CRUD_CREATE:
			return { ...state, valueDataUpdated: null }
		case CRUD_CREATE_SUCCESS:
			return { ...state, valueDataUpdated: action.payload }
		case CRUD_DELETE:
			return { ...state, valueDataRemoved: null }
		case CRUD_DELETE_SUCCESS:
			return { ...state, valueDataRemoved: action.payload }
		case CRUD_GET_SINGLE:
			return { ...state, valueDataSelected: null }
		case CRUD_GET_SINGLE_SUCCESS:
			return { ...state, valueDataSelected: action.payload }
		case CRUD_CLEAR_SEARCH:
			return { ...state, valuesDataList: null, paramsSearch: null }
		case CRUD_CLEAR_STORE:
			return {
				...state,
				valueDataSelected: null,
				valueDataUpdated: null
			}
		case CRUD_CLONE:
			return { ...state, valueDataCloned: null }
		case CRUD_CLONE_SUCCESS:
			return { ...state, valueDataCloned: action.payload }
		default:
			return state
	}
}

export default rootReducer
