import { confirmPayment, getCollections } from 'actions'
import React, { Fragment, PureComponent } from 'react'
import { Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import CollectionPaymentMethods from './collectionPaymentMethods'
import CollectionsTable from './collectionsTable'
import CollectionTotals from './collectionTotals'

class CollectionForm extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			dataTable: [],
			page: 1,
			page_size: 10
		}
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.formConfirmation(this.handleConfirmation)

		if (idOperacion) {
			this.props.getCollections({ idOperacion, page_size: 10, page_number: 1 })
			this.handleConfirmation = this.handleConfirmation.bind(this)
		}
	}

	componentDidUpdate = (prevProps) => {
		const { collectionList, payment, paymentRemoved } = this.props

		if (prevProps.collectionList !== collectionList && collectionList) {
			this.setState({ dataTable: collectionList })
		}

		if (prevProps.payment !== payment && payment) {
			this.setState({ dataTable: payment })
		}

		if (prevProps.paymentRemoved !== paymentRemoved && paymentRemoved) {
			this.setState({ dataTable: paymentRemoved })
		}
	}

	onChangeTable = (type, pagination) => {
		const { idOperacion } = this.props
		if (type === 'pagination') {
			this.props.getCollections({
				idOperacion,
				page_size: pagination.sizePerPage,
				page_number: pagination.page
			})
		}
		this.setState({ page: pagination.page, page_size: pagination.sizePerPage })
	}

	handleChangeFilter = ({ orden_dato, orden_tipo }) => {
		const { idOperacion } = this.props
		const { page, page_size } = this.state
		this.props.getCollections({
			idOperacion,
			page_size,
			page_number: page,
			orden: orden_dato,
			orden_sentido: orden_tipo
		})
	}

	handleConfirmation = () => {
		const { idOperacion, callBackReturn } = this.props
		this.props.confirmPayment({ idOperacion, callBackReturn })
	}

	_getOptionsAccount = (account) => {
		const tipoMov = account.mc_tipo_mov.map((tipo) => {
			return { id: tipo.cat_item, label: tipo.desc_item }
		})

		const currency = account.mc_cod_mone.map((curr) => {
			return { id: curr.cod_mone, label: curr.nom_mone }
		})

		return {
			id: account.nicfondo,
			label: account.descrip,
			tipo_mov: tipoMov,
			currency: currency
		}
	}

	render() {
		const { t, collectionList, handleMoveArrow, idOperacion } = this.props
		const { dataTable, page, page_size } = this.state

		const accounts = {}
		const botones = collectionList ? collectionList.botones : null

		const moneyOptions =
			collectionList && collectionList.cto_fondos.length
				? collectionList.cto_fondos.map((fondo) => {
						return { id: fondo.cto_fondo, label: fondo.desc_fondo }
				  })
				: []

		if (collectionList && collectionList.mc_cuenta) {
			collectionList.mc_cuenta.forEach((account) => {
				if (!accounts[account.cod_catfon]) {
					accounts[account.cod_catfon] = []
				}
				accounts[account.cod_catfon].push(this._getOptionsAccount(account))
			})
		}

		return (
			<Fragment>
				{collectionList && (
					<Col sm={12}>
						<CollectionTotals totals={dataTable} t={t} />
						<CollectionPaymentMethods
							t={t}
							idOperacion={idOperacion}
							paymentData={collectionList}
							paymentTotals={dataTable}
							moneyOptions={moneyOptions}
							accounts={accounts}
							page={page}
							page_size={page_size}
							handleChangeFilter={this.handleChangeFilter}
							handleMoveArrow={handleMoveArrow}
						/>
					</Col>
				)}
				<Col sm={12} className={'pb-2 pl-0 mt-3'}>
					{dataTable && collectionList && (
						<CollectionsTable
							dataTable={dataTable}
							idOperacion={idOperacion}
							handleChangeTable={this.onChangeTable}
							moneyOptions={moneyOptions}
							accounts={accounts}
							botones={botones}
							configCampos={collectionList ? collectionList.configCampos : []}
							page={page}
							page_size={page_size}
						/>
					)}
				</Col>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ collections }) => {
	const { collectionList, payment, paymentRemoved } = collections
	return { collectionList, payment, paymentRemoved }
}

export default connect(mapStateToProps, { getCollections, confirmPayment })(
	withTranslation()(CollectionForm)
)
