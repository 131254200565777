import ConfirmModal from 'components/common/ConfirmModal'
import NotificationError from 'components/common/notificationsErrors'
import { Form, Formik, FormikProps } from 'formik'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import CrudLedgerAccountGlobalField from './CrudLedgerAccountGlobalField'

interface ComponentProps extends WithTranslation {
	fields: any
	pointers: any
	handleCloseModal: any
	handleSubmit: any
	t: any
}

type ComponentState = {}

class AdditionalDataForm extends Component<ComponentProps, ComponentState> {
	constructor(props: ComponentProps) {
		super(props)
		this.state = {
			allFields: [{}]
		}
	}

	render() {
		const { t, fields, pointers } = this.props

		const pointerFields = _.map(pointers.lista, (pointer) => {
			const fullPointer = {
				...fields[2],
				idCampo: 'lista.' + (pointer.cod_cta - 1),
				label: pointer.descrip,
				descrip: pointer.descrip,
				visible: 1
			}

			return fullPointer
		})

		const allFields = [...pointerFields, ...fields]

		return (
			<Fragment>
				{allFields ? (
					<Formik
						initialValues={{}}
						onSubmit={(values: any) => {
							const listaValues = () => {
								const lista = values.lista.map((value: any, index: any) => {
									return value === 1 ? { cod_cta: index + 1 } : {}
								})
								const filteredLista = lista.filter((value: {}) => {
									return Object.keys(value).length > 0
								})
								return filteredLista
							}
							const submitValues = { ...values, lista: listaValues() }
							this.props.handleSubmit(submitValues)
						}}
						enableReinitialize={true}
					>
						{(props: FormikProps<any>) => (
							<Fragment>
								<Col sm={12}>
									<NotificationError
										errors={props.errors}
										touched={props.touched}
									/>
								</Col>
								<Form>
									{_.map(pointerFields, (field: any) => {
										return (
											<CrudLedgerAccountGlobalField
												key={field.idCampo}
												formikProps={props}
												field={field}
												crudProps={this.props}
											/>
										)
									})}
									<hr />
									{_.map(fields, (field: any) => {
										return (
											<CrudLedgerAccountGlobalField
												key={field.idCampo}
												formikProps={props}
												field={field}
												crudProps={this.props}
											/>
										)
									})}
									<Row className={'pt-3'}>
										<Col className={'text-center mt-2'}>
											<ConfirmModal
												messageBody={
													<div>{t('CRUDS.generic.confirm.body')}</div>
												}
												onSubmitModal={props.handleSubmit}
												typeButton="button"
												modalTitle={t('CRUDS.generic.confirm.title')}
												labelButton={t('Collection.form.buttons.accept')}
												buttonStyle={{
													minWidth: '145px'
												}}
											/>
										</Col>
										<Col className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={this.props.handleCloseModal}
											>
												{t('Collection.form.buttons.cancel')}
											</Button>
										</Col>
									</Row>
								</Form>
							</Fragment>
						)}
					</Formik>
				) : (
					<div>Loading...</div>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = ({ tableCalls }: any) => {
	const { catributoValues, catributoListValues } = tableCalls
	return { catributoValues, catributoListValues }
}

const connectForm = connect(mapStateToProps, {})(AdditionalDataForm)

export default withTranslation()(withRouter(connectForm))
