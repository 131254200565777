import CollapseButton from 'components/common/collapseButton'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import ExpandLoadItemsForm from 'containers/ProviderLoaditems/ExpandLoadItemsForm'
import { IProductItem } from 'models/Products'
import React, { Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import _ from 'underscore'
import {
	IDynamicObject,
	IGetExpandRowProps,
	IGetNextProductIdProps,
	IGetOptionsProps,
	IGetRowByIdProps,
	IGetStyleColumnProps,
	IGetValueUpdateTableProps,
	IHandleUpdateDataTableProps,
	InputOptionsProps,
	IRenderExpandRow
} from './LoadInventoryItemsTableType'

export const getInputOptions = (props: IGetOptionsProps): InputOptionsProps => {
	const { field, onEnterKeyPress, row, value, onChangeInput, inputRefs } = props
	const fieldId = field.idCampo.trim()
	const rightFields = ['cantidad', 'pcio_unit', 'neto']
	const inputStyle = rightFields.includes(fieldId) ? { textAlign: 'right' } : {}

	return {
		fwRef: field.editable ? inputRefs[fieldId][row.niprod] : null,
		inputFormCol: { sm: 12 },
		fields: [{ ...field, label: false }],
		label: false,
		inputId: `${fieldId}`,
		id: `${fieldId}-${row.niprod}`,
		name: `${fieldId}-${row.niprod}`,
		colLabel: 'col-sm-4',
		colInput: 'col-sm-8',
		divStyle: { paddingLeft: '10px', paddingRight: '10px' },
		disable: false,
		value,
		styles: inputStyle,
		rowStyle: { marginBottom: '5px' },
		onBlur: () => {},
		popperPlacement: 'bottom-end',
		autoComplete: 'Off',
		handleEnterKey: (
			event: React.ChangeEvent<HTMLInputElement>,
			value: string
		) => onEnterKeyPress({ event, value, fieldId, row }),
		onChange: (value: string, event: React.ChangeEvent<HTMLInputElement>) =>
			onChangeInput({ event, value, fieldId, row })
	}
}

/**
 * to get the next editable field.
 * @param {*} field
 * @returns
 */
export const getNextNameField = (
	field: string,
	fieldsTable: Array<IFieldStructure>,
	excludeFields: Array<string>
) => {
	let result = null
	let nextIndex = 0
	let restart = false
	let indexField = _.findIndex(fieldsTable, { idCampo: field })

	do {
		nextIndex = indexField + 1
		indexField = fieldsTable[nextIndex] ? nextIndex : 0
		result = fieldsTable[indexField].idCampo.trim()
		if (!fieldsTable[nextIndex]) {
			restart = true
		}
	} while (
		!fieldsTable[indexField].editable ||
		!fieldsTable[indexField].visible ||
		excludeFields.includes(result)
	)

	return restart ? 'addToCart' : result
}

export const excludeFields: Array<string> = [
	'sig_proceso',
	'fin_item',
	'modif_pcio',
	'ind_stock',
	'avisos'
]

/**
 * to get count of columns visible
 * @param {*} fieldsTable
 */
export const getCountVisibleColumns = (fieldsTable: Array<IFieldStructure>) => {
	return _.countBy(fieldsTable, (field) => {
		const idField = field.idCampo.trim()
		return field.visible && !_.contains(excludeFields, idField)
			? 'visible'
			: 'noVisible'
	})
}

/**
 * to get with style to each column
 * @param {*} field
 * @returns
 */
export const getStyleColumn = ({
	field,
	countColumns
}: IGetStyleColumnProps) => {
	const idField = field.idCampo.trim()

	let style: { width?: string; color?: string } = {}
	if (countColumns.visible >= 10) {
		switch (idField) {
			case 'desc_prod':
				style = { width: '25%' }
				break
			case 'ind_stock':
			case 'pc_util':
				style = { width: '2%' }
				break
			case 'fec_entrega':
				style = { width: '10%' }
				break
			case 'pcio_unit':
			case 'neto':
				style = { width: '11%' }
				break
			case 'modif_pcio':
				style = { width: '1%' }
				break
			case 'cantidad':
			case 'cod_prod':
			case 'cod_unid':
			case 'pc_lista':
			case 'costo':
				style = { width: '8%' }
				break

			default:
				style = { width: '10%' }
				break
		}
	} else if (countColumns.visible < 7) {
		switch (idField) {
			case 'desc_prod':
				style = { width: '30%' }
				break
			default:
				break
		}
	}

	if (field.requerido) {
		style.color = 'red'
	}

	return style
}

/**
 * To save and update data table to validate changes..
 * @params id row
 * @params fieldId
 * @params value
 */
export const handleUpdateDataTable = (props: IHandleUpdateDataTableProps) => {
	const { rowId, fieldId, value, updateDataTable } = props
	const updateIndex = _.findIndex(updateDataTable, { rowId })
	if (updateIndex === -1) {
		const params: IDynamicObject = { rowId }
		params[fieldId] = value
		updateDataTable.push(params)
	} else {
		updateDataTable[updateIndex][fieldId] = value
	}
	return updateDataTable
}

/**
 * get data from table row
 */
export const getRowById = ({ rowId, productTable }: IGetRowByIdProps) => {
	const result = _.find(productTable, { niprod: rowId })

	return result
}

/**
 * To get data from update table
 * @params rowId
 * @params field
 */
export const getValueUpdateTable = ({
	rowId,
	field,
	updateDataTable
}: IGetValueUpdateTableProps) => {
	const findData = _.findWhere(updateDataTable, { rowId })
	return findData ? findData[field] : null
}

/**
 * to get id of the next row
 * @params idProduct
 * @params revers >> to validate if down or up
 */
export const getNextProductId = ({
	idProduct,
	reverse = false,
	products
}: IGetNextProductIdProps) => {
	let result = 0
	if (idProduct) {
		products.forEach((prd, index) => {
			if (prd.niprod === idProduct) {
				result = !reverse ? index + 1 : index - 1 // Back o next row
			}
		})
		if (products[result]) {
			return products[result].niprod ? products[result].niprod : null
		} else {
			return null
		}
	}
	return null
}

/**
 * to clear data from editable field on the row
 * @params {NiProd} row
 */
export const setInitRow = (params: {
	niprod: number
	setTableDataProducts: (payload: Partial<IProductItem>) => void
}) => {
	const { niprod, setTableDataProducts } = params
	const initRow = {
		niprod,
		cantidad: 0,
		neto: 0,
		pcio_unit: 0,
		fec_entrega: '',
		pc_dto: 0
	}

	setTableDataProducts(initRow)
}

export const getExpandRow = (props: IGetExpandRowProps) => {
	const { products, fieldsExpandRow, idOperacion } = props
	const noExpand: Array<string> = getNoExpandRows(products)
	return {
		renderer: (row: any) =>
			renderExpandRow({ row, fieldsExpandRow, idOperacion }),
		showExpandColumn: true,
		nonExpandable: noExpand,
		expandByColumnOnly: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }: any) => {
			return <CollapseButton status={isAnyExpands} />
		},
		expandColumnRenderer: ({ expanded, rowKey }: any) => {
			if (!noExpand.includes(rowKey)) {
				return <CollapseButton status={expanded} />
			}
		}
	}
}

const getNoExpandRows = (products: Array<IProductItem>) => {
	const result: any = []
	if (!_.isEmpty(products)) {
		products.forEach((prd) => {
			if (!prd.atributos || prd.atributos.length === 0) {
				result.push(prd.niprod)
			}
		})
	}

	return result
}

const renderExpandRow = (props: IRenderExpandRow) => {
	const { idOperacion, fieldsExpandRow, row } = props
	const cols: any = []
	let result
	if (row && row.atributos && row.atributos.length) {
		row.atributos.forEach((atrb: any, index: number) => {
			cols.push(
				<Col key={index} className={'col-6 p-2'}>
					<b>{`${atrb.desc_atrib}:`}</b> {atrb.desc_dato}
				</Col>
			)
		})
		result = (
			<Fragment>
				<ExpandLoadItemsForm
					row={row}
					fields={fieldsExpandRow}
					idOperacion={idOperacion}
				/>
				<Row
					className={'row ml-1 mr-1'}
					style={{ borderTop: '1px solid black' }}
				/>
				<Row className={'container mt-2'}>{cols}</Row>
			</Fragment>
		)
	}

	return result
}
