import {
	crudGetProductCode,
	crudGetProductCodeConfig,
	crudGetProductCodeInfo,
	crudValidateProductCode
} from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import {
	ICrudCreateProductCode,
	ICrudGetProductCode,
	ICrudGetProductCodeConfig,
	ICrudGetProductCodeInfo
} from 'models/CrudProductsInterface'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import CrudProductCodeField from './CrudProductCodeField'

interface CrudProductCodeFormProps extends WithTranslation {
	crudGetProductCode: (params: ICrudGetProductCode) => void
	crudValidateProductCode: (payload: ICrudCreateProductCode) => void
	crudGetProductCodeInfo: (payload: ICrudGetProductCodeInfo) => void
	crudGetProductCodeConfig: (payload: ICrudGetProductCodeConfig) => void
	t: any
	itemInfo?: any
	method: string
	disableForm: boolean
	handleSubmitModal: any
	handleCloseModal: any
	productCodeConfig: any
	productCodeInfo: any
	productCodeValidated: any
	productCodeLevelInfo: any
	cod_catprod: string
	cod_prod: string
}

type CrudProductCodeFormState = {
	disableForm: boolean
	changedIndex: number
}

class CrudProductCodeForm extends Component<
	CrudProductCodeFormProps,
	CrudProductCodeFormState
> {
	constructor(props: CrudProductCodeFormProps) {
		super(props)
		props.crudGetProductCodeConfig({
			cod_catprod: props.cod_catprod
		})
		this.state = {
			disableForm: false,
			changedIndex: 0
		}
	}

	// Gets initial values and dropdowns
	componentDidMount = () => {
		const {
			cod_catprod,
			cod_prod,
			crudGetProductCode,
			crudGetProductCodeInfo
		} = this.props
		crudGetProductCode({ cod_catprod, cod_prod })
		crudGetProductCodeInfo({
			cod_catprod: cod_catprod,
			datosNiveles: [
				{
					nivel: 1,
					cod_dato_ref: ''
				}
			]
		})
	}

	// If info exists setups default values
	componentDidUpdate = () => {}

	handleIndex = (index: number) => {
		this.setState({ changedIndex: index })
	}

	getLevelsData = (values: any) => {
		const levels: Array<any> = []

		for (const key in values) {
			const level = parseInt(key.replace('nivel', ''))
			level &&
				levels.push({
					nivel: level,
					cod_dato: values[key]
				})
		}

		return levels
	}

	render() {
		const {
			t,
			disableForm,
			method,
			handleSubmitModal,
			handleCloseModal,
			productCodeConfig,
			productCodeInfo,
			cod_catprod,
			cod_prod
		} = this.props

		const { changedIndex } = this.state

		if (productCodeConfig) {
			const fields = productCodeConfig.campos
			const fieldValues: any = {}
			productCodeInfo &&
				_.forEach(productCodeInfo.lista, (value) => {
					fieldValues['nivel' + value.nivel] = value.cod_dato
				})

			const initialValues = !_.isEmpty(fieldValues)
				? fieldValues
				: fields.length
				? getInitValues(fields)
				: {}

			const columnsCancel = disableForm ? 12 : 6
			const validationSchema = fields.length
				? getValidationSchema(fields, t, [], [])
				: {}

			return (
				<Fragment>
					<Formik
						initialValues={initialValues}
						onSubmit={(values: any, actions) => {
							handleSubmitModal({
								cod_catprod: cod_catprod,
								cod_prod: cod_prod,
								preview: values.preview,
								lista: this.getLevelsData(values)
							})
						}}
						enableReinitialize={true}
						validationSchema={validationSchema}
					>
						{(props: FormikProps<any>) => (
							<Fragment>
								<Col sm={12}>
									<NotificationError
										errors={props.errors}
										touched={props.touched}
									/>
								</Col>
								<Alert variant={'dark'}>
									<b>{t('CRUDS.general_use.formulariocodigo')}</b>
								</Alert>
								<Form>
									{(method === 'add' ||
										((method === 'edit' || method === 'view') &&
											productCodeInfo)) &&
										_.map(fields, (field: any) => {
											return (
												<CrudProductCodeField
													key={field.idCampo}
													formikProps={props}
													changedIndex={changedIndex}
													handleIndex={this.handleIndex}
													field={field}
													getLevelsData={this.getLevelsData}
													crudProps={{
														...this.props
													}}
													initialValue={
														initialValues[
															field.idCampo as keyof typeof initialValues
														]
													}
												/>
											)
										})}
									<Row className={'pt-3'}>
										{!disableForm && (
											<Col sm={6} className={'text-center mt-2'}>
												<Button
													style={{ minWidth: '145px' }}
													type={'button'}
													onClick={() => props.handleSubmit()}
												>
													{t('Collection.form.buttons.accept')}
												</Button>
											</Col>
										)}
										<Col sm={columnsCancel} className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={handleCloseModal}
											>
												{t('Collection.form.buttons.cancel')}
											</Button>
										</Col>
									</Row>
								</Form>
							</Fragment>
						)}
					</Formik>
				</Fragment>
			)
		} else {
			return <div>Cargando...</div>
		}
	}
}

const mapStateToProps = ({ productCode }: any) => {
	const {
		productCodeConfig,
		productCodeInfo,
		productCodeValidated,
		productCodeLevelInfo
	} = productCode
	return {
		productCodeConfig,
		productCodeInfo,
		productCodeValidated,
		productCodeLevelInfo
	}
}

const connectForm = connect(mapStateToProps, {
	crudGetProductCodeConfig,
	crudGetProductCode,
	crudGetProductCodeInfo,
	crudValidateProductCode
})(CrudProductCodeForm)

export default withTranslation()(withRouter(connectForm))
