import { combineReducers } from 'redux'
import AccountingPeriodsReducer from './AccountingPeriodsReducer'
import AccountingReducer from './AccountingSeat'
import AccountingVoucherReducer from './AccountingVoucher'
import AttributesReducer from './Attributes'
import Auth from './Auth'
import AutoGenerateRedux from './AutoGenerate'
import AutomaticPurchaseReducer from './AutomaticPurchase'
import BudgetReducer from './Budget'
import CalculationBonusesReducer from './CalculationBonuses'
import Client from './Client'
import CollectionReducer from './Collection'
import Common from './Common'
import CostCentersLedgerAccountsReducer from './CostCenterLedgerAccountsReducer'
import CrudReducer from './Cruds'
import CrudValuesReducer from './CrudValuesReducer'
import CustomerCrudCodeReducer from './CustomerCrudCodeReducer'
import CustomerObservationReducer from './CustomerObservationReducer'
import CustomerReferReducer from './CustomerReferReducer'
import CCAssignationsReducer from './DirectCCAssignationReducer'
import LAAssignationsReducer from './DirectLAAssignationReducer'
import EquivalentProductsReducer from './EquivalentProductsReducer'
import UploadFilesReducer from './FileManager'
import FundAccountBankReconciliationReducer from './FundAccountBankReconciliationReducer'
import FundAccountsCrudCodeReducer from './FundAccountsCrudCodeReducer'
import GenerateReducer from './Generate'
import HeaderDataValidationReducer from './HeaderDataValidation'
import HeaderEntryReducer from './HeaderEntry'
import HeaderSelectionReducer from './HeaderSelection'
import InventoryPickExpensesReducer from './InventoryExpensesStock'
import InventoryPickStockReducer from './InventoryPickStock'
import LandingReducer from './Landing'
import LedgerAccountsCostCentersReducer from './LedgerAccountsCostCentersReducer'
import LedgerAccountsCrudCodeReducer from './LedgerAccountsCrudCodeReducer'
import LedgerAccountsPointersReducer from './LedgerAccountsPointersReducer'
import LoadInventoryReducer from './LoadInventoryItems'
import LoadItemsReducer from './LoadItems'
import LoadPackagesReducer from './LoadPackages'
import PickeoEgrStockReducer from './PickeoEgrStock'
import PickeoIngStockReducer from './PickeoIngStock'
import ProductReducer from './Product'
import ProductBonificationReducer from './ProductBonificationReducer'
import ProductCrudCodeReducer from './ProductCrudCodeReducer'
import ProfileAbmsReducer from './ProfileAbmsReducer'
import ProfileTabsReducer from './ProfileTabsReducer'
import ProfileUsersReducer from './ProfileUsersReducer'
import ProviderReducer from './Provider'
import ProviderCodeReducer from './ProviderCrudCodeReducer'
import ProviderObservationReducer from './ProviderObservationReducer'
import PurchaseConditionsDuesReducer from './PurchaseConditionsDuesReducer'
import PurchasesAffectedReducer from './PurchasesAffected'
import SalesAffectedReducer from './SalesAffected'
import SearchProductsReducer from './SearchProducts'
import SearchVoucherReducer from './SearchVouchers'
import SellerCommissionsReducer from './SellerCommissionsReducer'
import ShoppingCartReducer from './ShoppingCart'
import StockReducer from './Stock'
import StockProviderReducer from './StockProvider'
import SugestedProductsReducer from './SugestedProductsReducer'
import TableCallsReducer from './TableCalls'
import TermsOfSalesDuesReducer from './TermsOfSalesDuesReducer'
import TreasuryHeaderReducer from './TreasuryHeader'
import UserProfilesReducer from './UserProfilesReducer'
import UtilsFiltersReducer from './UtilsFilters'
import ValidationReducer from './ValidationReducer'
import Voucher from './Voucher'
import VoucherType from './VoucherType'

const reducers = combineReducers({
	accountingPeriodsCrud: AccountingPeriodsReducer,
	accountingSeats: AccountingReducer,
	attributes: AttributesReducer,
	auth: Auth,
	autoGenerate: AutoGenerateRedux,
	automaticPurchase: AutomaticPurchaseReducer,
	budgetItems: BudgetReducer,
	calculationBonuses: CalculationBonusesReducer,
	clients: Client,
	collections: CollectionReducer,
	common: Common,
	configCrud: CrudReducer,
	costCentersAccounts: CostCentersLedgerAccountsReducer,
	crudValues: CrudValuesReducer,
	customerCode: CustomerCrudCodeReducer,
	customerObservation: CustomerObservationReducer,
	customerReferred: CustomerReferReducer,
	directCCAssignations: CCAssignationsReducer,
	directLAAssignations: LAAssignationsReducer,
	equivalentProducts: EquivalentProductsReducer,
	filesManager: UploadFilesReducer,
	fundAccountBankReconciliation: FundAccountBankReconciliationReducer,
	fundAccountsCode: FundAccountsCrudCodeReducer,
	generateForm: GenerateReducer,
	headerDataValidation: HeaderDataValidationReducer,
	headerEntry: HeaderEntryReducer,
	headerSelection: HeaderSelectionReducer,
	inventoryPickStock: InventoryPickStockReducer,
	inventoryPickExpenses: InventoryPickExpensesReducer,
	landingItems: LandingReducer,
	ledgerAccountCostCenters: LedgerAccountsCostCentersReducer,
	ledgerAccountsCode: LedgerAccountsCrudCodeReducer,
	ledgerAccountsPointers: LedgerAccountsPointersReducer,
	loadInventoryItems: LoadInventoryReducer,
	loadItems: LoadItemsReducer,
	loadPackages: LoadPackagesReducer,
	pickeoEgrStock: PickeoEgrStockReducer,
	pickeoIngStock: PickeoIngStockReducer,
	product: ProductReducer,
	productBonification: ProductBonificationReducer,
	productCode: ProductCrudCodeReducer,
	profileAbms: ProfileAbmsReducer,
	profileTabs: ProfileTabsReducer,
	profileUsers: ProfileUsersReducer,
	providerCode: ProviderCodeReducer,
	providerObservation: ProviderObservationReducer,
	providers: ProviderReducer,
	purchaseConditionsDues: PurchaseConditionsDuesReducer,
	purchasesAffected: PurchasesAffectedReducer,
	salesAffected: SalesAffectedReducer,
	searchProducts: SearchProductsReducer,
	searchVoucher: SearchVoucherReducer,
	sellerCommissions: SellerCommissionsReducer,
	shoppingCart: ShoppingCartReducer,
	stock: StockReducer,
	stockProvider: StockProviderReducer,
	sugestedProducts: SugestedProductsReducer,
	tableCalls: TableCallsReducer,
	termsOfSalesDues: TermsOfSalesDuesReducer,
	treasuryHeader: TreasuryHeaderReducer,
	userProfiles: UserProfilesReducer,
	utilsFilters: UtilsFiltersReducer,
	validationResponses: ValidationReducer,
	voucher: Voucher,
	voucherTaxes: AccountingVoucherReducer,
	vouchertype: VoucherType
})

const rootReducer = (state, action) => {
	return reducers(state, action)
}

export default rootReducer
