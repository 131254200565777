import CrudFundConcepts from 'components/crudFundsConcepts/CrudFundConcepts'
import SingleTabCrudContent from 'components/genericCrud/SingleTabCrudContent'
import { CRUD_FUNDS_CONCEPTS_DATA } from 'utils/RoutePath'

export const fundsConceptsConfig = {
	id: 'fundsConceptsCrud',
	key: 'cto_fon',
	descrip: 'descrip',
	searchMethod: 'get',
	category: 'A',
	translation: 'funds_concepts_data',
	dataUrl: CRUD_FUNDS_CONCEPTS_DATA,
	urlApi: 'abm_fon_conceptos',
	component: SingleTabCrudContent,
	tabsList: [
		{
			key: 'basic',
			title: 'CRUDS.general_use.basic_tab',
			tabComponent: CrudFundConcepts
		}
	],
	tabsGroupsAlta: ['grilla']
}
