import { CRUD_LA_ASSIGNATIONS } from 'utils/RoutePath'

export const directLAAssignConfig = {
	id: 'ledgerAccountAssignCrud',
	key: 'nicodcta',
	descrip: 'descrip',
	searchMethod: 'get',
	category: 'A',
	dataUrl: CRUD_LA_ASSIGNATIONS,
	urlApi: 'abm_cont_asig_cta'
}
