import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Card, CardTitle, Collapse } from 'reactstrap'
import CollapseButton from './collapseButton'
import styles from './CommonCard.module.scss'

interface ICommonCardProps {
	isOpen: boolean
	content: any
	toggleState: () => void
	title: string
}

class CommonCard extends Component<ICommonCardProps> {
	render() {
		const { isOpen, content, title } = this.props

		return (
			<Card className={`pb-3 mt-3 pt-3 mb-4 ${styles.containerCard} col-12`}>
				<CardTitle tag="h5">
					<Row className={''}>
						<Col sm={11}>{title}</Col>
						<Col sm={1}>
							<CollapseButton
								onPress={() => this.props.toggleState()}
								status={isOpen}
							/>
						</Col>
					</Row>
				</CardTitle>
				<Collapse isOpen={isOpen}>{content}</Collapse>
			</Card>
		)
	}
}

export default CommonCard
