import { getConfigFieldsCrud } from 'actions'
import { IAbmStructureResponse } from 'constants/valuesInterfaces/interfaces'
import { ICrudUpdateProfileUsers } from 'models/CrudProfilesInterface'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import ProfileUsersAssignCenterDynamicTable from './ProfileUsersAssignCenterDynamicTable'

type ValuesProps = {
	getConfigFieldsCrud: any
	handleSubmit: (params: ICrudUpdateProfileUsers) => void
	setFormChanged: (formChanged: boolean) => void
	fieldsConfig: IAbmStructureResponse
	cod_grupo: string
	values?: any
	disableForm: boolean
	showWarning: boolean
	handleWarning: (value: any, action: string) => void
}

type ValuesState = {
	activeTab: string
}

class LedgerAccountAssignCenterTab extends Component<ValuesProps, ValuesState> {
	constructor(props: ValuesProps) {
		super(props)
		this.state = {
			activeTab: 'assignCenter'
		}
	}

	render() {
		const {
			fieldsConfig,
			disableForm,
			values,
			cod_grupo,
			handleSubmit,
			setFormChanged,
			showWarning,
			handleWarning
		} = this.props

		const propsTable = {
			fieldsConfig: fieldsConfig,
			disableForm: disableForm,
			values: values,
			cod_grupo: cod_grupo,
			handleSubmit: handleSubmit,
			setFormChanged: setFormChanged,
			showWarning: showWarning,
			handleWarning: handleWarning
		}
		return (
			<Fragment>
				{fieldsConfig && (
					<ProfileUsersAssignCenterDynamicTable {...propsTable} />
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = ({ configCrud }: any) => {
	const { fieldsConfig } = configCrud
	return { fieldsConfig }
}

const connectForm = connect(mapStateToProps, {
	getConfigFieldsCrud
})(LedgerAccountAssignCenterTab)

export default connectForm
