import {
	P_AFEC_CANT_CPA,
	P_AFEC_CANT_VTA,
	P_AFEC_IMPO_CPA,
	P_AFEC_IMPO_VTA,
	P_AFEC_STADO_VTA,
	P_AFEC_STATE_CPA,
	P_ASIEN_CONT,
	P_AUTOMATIC_PURCHASE_ORDER,
	P_AUTO_GENERATE,
	P_CALCULATION_BONUSES,
	P_CARGAINDIV_CPA,
	P_CARGAINDIV_VTA,
	P_CARGAITEMVTA,
	P_CARGAITEM_CPA,
	P_CONF_PROD_EQ,
	P_CONF_PROD_SUG,
	P_CPACAB,
	P_FINAL_CONSUMER,
	P_FINCOMPROB,
	P_HEADER_DATA_VALIDATION,
	P_HEADER_ENTRY,
	P_IMP_COMPROB,
	P_INVETORY_EXPENSE,
	P_INV_ING_ITEM_PIC,
	P_LOAD_COLLECTION,
	P_LOAD_ITEM_INCOME_INV,
	P_LOAD_ITEM_INV,
	P_LOAD_PACKAGES_DISPATCH,
	P_PICK_STOCK_CPA,
	P_PICK_STOCK_VTA,
	P_PRINT_VOUCHER,
	P_SELCAB_CPA,
	P_SELCLI,
	P_SELPRE,
	P_SELPROV,
	P_TREASURY_HEADER,
	P_VTACAB
} from 'constants/ConfigProcessNames'
import _ from 'underscore'
import {
	ACCOUNTINGSEAT,
	ACCOUNTINGVOUCHER,
	AFFECTATION_AMOUNTS,
	AUTOMATIC_PURCHASE_ORDER,
	AUTO_GENERATE,
	BUDGET,
	CALCULATION_BONUSES,
	COLLECTION,
	EQUIVALENT_PROD_CONF,
	FINAL_CONSUMER,
	GENERATE,
	HEADERBOARD,
	HEADER_DATA_VALIDATION_PROCESS,
	HEADER_ENTRY,
	HEADER_SELECTION,
	INDLOADITEMS,
	INVENTORY_EXPENSE_STOCK,
	INVENTORY_PICK_STOCK,
	LOADITEMS,
	LOAD_ITEMS_INCOME_INV,
	LOAD_ITEMS_INV,
	LOAD_PACKAGES_DISPATCH,
	PICK_STOCK,
	PROVIDER,
	PROVIDERAFFECTING,
	PROVIDERHEADER,
	PROVIDERINDLOADITEMS,
	PROVIDERLOADITEMS,
	PROVIDER_AFFECT_STATE,
	PROVIDER_PICK_STOCK,
	SUGESTED_PROD_CONF,
	TREASURY_HEADER,
	VOUCHER,
	VOUCHERAFFECTING,
	VOUCHERINVOLVEMENT,
	VOUCHERSTATE
} from '../utils/RoutePath'

export function findPreviousProcess(processList, currentProcess) {
	const processIndex = _.findIndex(
		processList,
		(process) => process.cod_proceso === currentProcess.cod_proceso
	)

	return processList[processIndex - 1]?.cod_proceso
}

export function getBackButton(previous, urlParameter) {
	const previousButton = previous
		? { url: getUrl(previous, urlParameter) }
		: undefined
	return previousButton
}

export function getBackNextButtons(crumbs, current, urlParameter) {
	let ban = true

	const steps = crumbs.map((crumb, index) => {
		const tmpmain = current === crumb.cod_proceso
		ban = tmpmain ? false : ban
		return {
			...crumb,
			before: ban,
			position: index,
			label: crumb.desc_proceso,
			main: tmpmain
		}
	})

	return getButtons(steps, urlParameter)
}

const getButtons = (crumbs, urlParameter) => {
	let nextButton
	let backButton = false
	let mainIndex = null

	crumbs.forEach((crumb, index) => {
		if (crumb.main) {
			mainIndex = index
		}
	})

	const next =
		crumbs[mainIndex + 1] &&
		crumbs[mainIndex + 1].cod_proceso !== P_PRINT_VOUCHER
			? crumbs[mainIndex + 1]
			: false
	const back = crumbs[mainIndex - 1] ? crumbs[mainIndex - 1] : false

	if (next) {
		nextButton = {
			url: getUrl(next.cod_proceso, urlParameter)
		}
	}

	if (back) {
		backButton = {
			url: getUrl(back.cod_proceso, urlParameter)
		}
	}

	return [backButton, nextButton]
}

export function getUrl(process, urlParameter) {
	const urls = {}
	urls[P_VTACAB] = HEADERBOARD
	urls[P_SELCLI] = VOUCHER
	urls[P_CARGAITEMVTA] = LOADITEMS
	urls[P_AFEC_CANT_VTA] = VOUCHERINVOLVEMENT
	urls[P_FINCOMPROB] = GENERATE
	urls[P_AFEC_IMPO_VTA] = VOUCHERAFFECTING
	urls[P_AFEC_STADO_VTA] = VOUCHERSTATE
	urls[P_ASIEN_CONT] = ACCOUNTINGSEAT
	urls[P_IMP_COMPROB] = ACCOUNTINGVOUCHER
	urls[P_LOAD_COLLECTION] = COLLECTION
	urls[P_SELPROV] = PROVIDER
	urls[P_CPACAB] = PROVIDERHEADER
	urls[P_CARGAITEM_CPA] = PROVIDERLOADITEMS
	urls[P_AFEC_CANT_CPA] = PROVIDERAFFECTING
	urls[P_AFEC_IMPO_CPA] = AFFECTATION_AMOUNTS
	urls[P_AFEC_STATE_CPA] = PROVIDER_AFFECT_STATE
	urls[P_CARGAINDIV_VTA] = INDLOADITEMS
	urls[P_CARGAINDIV_CPA] = PROVIDERINDLOADITEMS
	urls[P_PICK_STOCK_CPA] = PROVIDER_PICK_STOCK
	urls[P_PICK_STOCK_VTA] = PICK_STOCK
	urls[P_SELPRE] = BUDGET
	urls[P_AUTO_GENERATE] = AUTO_GENERATE
	urls[P_FINAL_CONSUMER] = FINAL_CONSUMER
	urls[P_SELCAB_CPA] = HEADER_SELECTION
	urls[P_CONF_PROD_SUG] = SUGESTED_PROD_CONF
	urls[P_AUTOMATIC_PURCHASE_ORDER] = AUTOMATIC_PURCHASE_ORDER
	urls[P_CONF_PROD_EQ] = EQUIVALENT_PROD_CONF
	urls[P_HEADER_DATA_VALIDATION] = HEADER_DATA_VALIDATION_PROCESS
	urls[P_CALCULATION_BONUSES] = CALCULATION_BONUSES
	urls[P_LOAD_PACKAGES_DISPATCH] = LOAD_PACKAGES_DISPATCH
	urls[P_HEADER_ENTRY] = HEADER_ENTRY
	urls[P_INV_ING_ITEM_PIC] = INVENTORY_PICK_STOCK
	urls[P_INVETORY_EXPENSE] = INVENTORY_EXPENSE_STOCK
	urls[P_LOAD_ITEM_INV] = LOAD_ITEMS_INV
	urls[P_LOAD_ITEM_INCOME_INV] = LOAD_ITEMS_INCOME_INV
	urls[P_TREASURY_HEADER] = TREASURY_HEADER

	return urlParameter ? `${urls[process]}/${urlParameter}` : urls[process]
}
