import {
	faFile,
	faFileExcel,
	faFilePdf,
	faFileWord,
	faImage
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useStyles } from './CommonFileIconStyles'
import { ICommonFileIconProps } from './CommonFileIconTypes'

const CommonFileIcon = (props: ICommonFileIconProps) => {
	const { typeFile } = props
	const classes = useStyles()

	const renderIcon = () => {
		switch (typeFile) {
			case 'application/pdf':
			case 'pdf':
				return (
					<FontAwesomeIcon
						style={{ width: '40px' }}
						className={classes.fileIcon}
						icon={faFilePdf}
					/>
				)
			case 'image/png':
			case 'png':
			case 'image/jpeg':
			case 'jpg':
				return (
					<FontAwesomeIcon
						style={{ width: '40px' }}
						className={classes.fileIcon}
						icon={faImage}
					/>
				)
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			case 'docx':
			case 'text/plain':
			case 'txt':
				return (
					<FontAwesomeIcon
						style={{ width: '40px' }}
						className={classes.fileIcon}
						icon={faFileWord}
					/>
				)

			case 'application/vnd.ms-excel.sheet.macroEnabled.12':
			case 'text/csv':
			case 'xlsm':
			case 'csv':
			case 'xls':
			case 'xlsx':
				return (
					<FontAwesomeIcon
						style={{ width: '40px' }}
						className={classes.fileIcon}
						icon={faFileExcel}
					/>
				)
			default:
				return (
					<FontAwesomeIcon
						style={{ width: '40px' }}
						className={classes.fileIcon}
						icon={faFile}
					/>
				)
		}
	}

	return renderIcon()
}

export default CommonFileIcon
