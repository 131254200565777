import {
	getFilterCCAssignTypeSuccess,
	getFilterConditionSaleSuccess,
	getFilterLAAssignOrderSuccess,
	getFilterLAAssignStatusSuccess,
	getFilterLAAssignTypeSuccess,
	getFilterProductSuccess,
	getFilterTransportSuccess,
	getOptionsFilterAffectedSuccess,
	getOrdenCbtCabSuccess,
	getTableAbmTabsSuccess,
	getTableAlignmentTypeSuccess,
	getTableAVAccountingSuccess,
	getTableAVFundsSuccess,
	getTableAVPurchasesSuccess,
	getTableAVSalesSuccess,
	getTableAVStockSuccess,
	getTableBarcodeFormatSuccess,
	getTableBonificationsSuccess,
	getTableBonifRatesSuccess,
	getTableBranchOfficesSuccess,
	getTableCCImputationTypeSuccess,
	getTableCommissionTypeSuccess,
	getTableConceptsBackgroundsSuccess,
	getTableCostAccountingTypeSuccess,
	getTableCostCentersCategorySuccess,
	getTableCostCentersSuccess,
	getTableCountrySuccess,
	getTableCouriersSuccess,
	getTableCreditSuccess,
	getTableCufDataSuccess,
	getTableCUFSuccess,
	getTableCurrenciesSuccess,
	getTableCustomerAttrListSuccess,
	getTableCustomerAttrSuccess,
	getTableCustomerBranchesSuccess,
	getTableCustomerCategorySuccess,
	getTableDataTypeSuccess,
	getTableDepotsSuccess,
	getTableDocumentTypeSuccess,
	getTableEstimationTypeSuccess,
	getTableForeignCurrenciesSuccess,
	getTableFundAccountAttrListSuccess,
	getTableFundAccountAttrSuccess,
	getTableFundsAccountsCatSuccess,
	getTableFundsConceptsTypeSuccess,
	getTableGenerationTypeSuccess,
	getTableImputationTypeSuccess,
	getTableItemCatSuccess,
	getTableLedgerAccountsCategorySuccess,
	getTableLedgerAccountsSuccess,
	getTableMasksSuccess,
	getTableMeasurementUnitSuccess,
	getTableMessageSuccess,
	getTableNumeratorsSuccess,
	getTableParentCostCenterSuccess,
	getTableParentLedgerAccountSuccess,
	getTablePaymentConditionsSuccess,
	getTablePermissionTypeSuccess,
	getTablePriceListSuccess,
	getTablePricesSuccess,
	getTableProcessSuccess,
	getTableProductAttrListSuccess,
	getTableProductAttrSuccess,
	getTableProductCategorySuccess,
	getTableProductsSuccess,
	getTableProviderAttrListSuccess,
	getTableProviderAttrSuccess,
	getTableProviderCatSuccess,
	getTableProvinceSuccess,
	getTableResponsibleTypeSuccess,
	getTableSellersSuccess,
	getTableSymbolsSuccess,
	getTableTableSuccess,
	getTableTableTypeSuccess,
	getTableTaxesRateSuccess,
	getTableTaxesSuccess,
	getTableTermsOfSalesSuccess,
	getTableTesStatesSuccess,
	getTableTitlesSuccess,
	getTableUsualCoinSuccess,
	getTableValidationsSuccess,
	getTableValidationTypeSuccess,
	getTableValidationTypeTSuccess,
	getTableValidationValuesSuccess,
	getTableVoucherFieldsSuccess,
	getTableVoucherModulesSuccess,
	getTableVoucherProcessSuccess,
	getTableVoucherTypeAdminSuccess
} from 'actions'
import {
	getFilterCCAssignType,
	getFilterConditionSaleApi,
	getFilterLAAssignOrder,
	getFilterLAAssignStatus,
	getFilterLAAssignType,
	getFilterProduct,
	getFilterTransportApi,
	getOptionsFilterAffectedApi,
	getOrdenCbtCabApi,
	getTableAbmTabs,
	getTableAlignmentType,
	getTableAVAccounting,
	getTableAVFunds,
	getTableAVPurchases,
	getTableAVSales,
	getTableAVStock,
	getTableBarcodeFormat,
	getTableBonifications,
	getTableBonifRates,
	getTableBranchOffices,
	getTableCCImputationType,
	getTableCommisionType,
	getTableConceptsBackgrounds,
	getTableCostAccountingType,
	getTableCostCenters,
	getTableCostCentersCategory,
	getTableCountry,
	getTableCouriers,
	getTableCredit,
	getTableCUF,
	getTableCufData,
	getTableCurrencies,
	getTableCustomerAttr,
	getTableCustomerAttrList,
	getTableCustomerBranches,
	getTableCustomerCategory,
	getTableDataType,
	getTableDepots,
	getTableDocumentType,
	getTableEstimationType,
	getTableForeignCurrencies,
	getTableFundAccountAttr,
	getTableFundAccountAttrList,
	getTableFundsAccountsCat,
	getTableFundsConceptsType,
	getTableGenerationType,
	getTableImputationType,
	getTableItemCat,
	getTableLedgerAccounts,
	getTableLedgerAccountsCategory,
	getTableMasks,
	getTableMeasurementUnit,
	getTableMessage,
	getTableNumerators,
	getTableParentCostCenter,
	getTableParentLedgerAccount,
	getTablePaymentConditions,
	getTablePermissionType,
	getTablePriceList,
	getTablePrices,
	getTableProcess,
	getTableProductAttr,
	getTableProductAttrList,
	getTableProductCategory,
	getTableProducts,
	getTableProviderAttr,
	getTableProviderAttrList,
	getTableProviderCat,
	getTableProvince,
	getTableResponsibleType,
	getTableSellers,
	getTableSymbols,
	getTableTable,
	getTableTableType,
	getTableTaxes,
	getTableTaxesRate,
	getTableTermsOfSales,
	getTableTesStates,
	getTableTitles,
	getTableUsualCoin,
	getTableValidations,
	getTableValidationType,
	getTableValidationTypeT,
	getTableValidationValues,
	getTableVoucherFields,
	getTableVoucherModules,
	getTableVoucherProcess,
	getTableVoucherType
} from 'api/TableCalls'
import {
	GET_FILTER_CC_ASSIGN_TYPE,
	GET_FILTER_CONDITION_SALE,
	GET_FILTER_LA_ASSIGN_ORDER,
	GET_FILTER_LA_ASSIGN_STATUS,
	GET_FILTER_LA_ASSIGN_TYPE,
	GET_FILTER_PRODUCT,
	GET_FILTER_TRANSPORT,
	GET_OPTIONS_FILTER_AFFECTED,
	GET_ORDER_CBT_CAB,
	GET_TABLE_ABM_TABS,
	GET_TABLE_ALIGNMENT_TYPE,
	GET_TABLE_AV_ACCOUNTING,
	GET_TABLE_AV_FUNDS,
	GET_TABLE_AV_PURCHASES,
	GET_TABLE_AV_SALES,
	GET_TABLE_AV_STOCK,
	GET_TABLE_BARCODE_FORMAT,
	GET_TABLE_BONIFICATIONS,
	GET_TABLE_BONIF_RATES,
	GET_TABLE_BRANCH_OFFICES,
	GET_TABLE_CC_IMPUTATION_TYPE,
	GET_TABLE_COMMISSION_TYPE,
	GET_TABLE_CONCEPTS_BACKGROUNDS,
	GET_TABLE_COST_ACCOUNTING_TYPE,
	GET_TABLE_COST_CENTERS,
	GET_TABLE_COST_CENTERS_CATEGORY,
	GET_TABLE_COUNTRY,
	GET_TABLE_COURIERS,
	GET_TABLE_CREDIT,
	GET_TABLE_CUF,
	GET_TABLE_CUF_DATA,
	GET_TABLE_CURRENCIES,
	GET_TABLE_CUSTOMER_ATTR,
	GET_TABLE_CUSTOMER_ATTR_LIST,
	GET_TABLE_CUSTOMER_BRANCHES,
	GET_TABLE_CUSTOMER_CATEGORY,
	GET_TABLE_DATA_TYPE,
	GET_TABLE_DEPOTS,
	GET_TABLE_DOCUMENT_TYPE,
	GET_TABLE_ESTIMATION_TYPE,
	GET_TABLE_FOREIGN_CURRENCIES,
	GET_TABLE_FUNDS_ACCOUNTS_CAT,
	GET_TABLE_FUNDS_CONCEPTS_TYPE,
	GET_TABLE_FUND_ACCOUNT_ATTR,
	GET_TABLE_FUND_ACCOUNT_ATTR_LIST,
	GET_TABLE_GENERATION_TYPE,
	GET_TABLE_IMPUTATION_TYPE,
	GET_TABLE_ITEM_CAT,
	GET_TABLE_LEDGER_ACCOUNTS,
	GET_TABLE_LEDGER_ACCOUNTS_CATEGORY,
	GET_TABLE_MASKS,
	GET_TABLE_MEASUREMENT_UNIT,
	GET_TABLE_MESSAGE,
	GET_TABLE_NUMERATORS,
	GET_TABLE_PARENT_COST_CENTER,
	GET_TABLE_PARENT_LEDGER_ACCOUNT,
	GET_TABLE_PAYMENT_CONDITIONS,
	GET_TABLE_PERMISSION_TYPE,
	GET_TABLE_PRICES,
	GET_TABLE_PRICE_LIST,
	GET_TABLE_PROCESS,
	GET_TABLE_PRODUCTS,
	GET_TABLE_PRODUCT_ATTR,
	GET_TABLE_PRODUCT_ATTR_LIST,
	GET_TABLE_PRODUCT_CAT,
	GET_TABLE_PROVIDER_ATTR,
	GET_TABLE_PROVIDER_ATTR_LIST,
	GET_TABLE_PROVIDER_CAT,
	GET_TABLE_PROVINCE,
	GET_TABLE_RESPONSIBLE_TYPE,
	GET_TABLE_SELLERS,
	GET_TABLE_SYMBOLS,
	GET_TABLE_TABLE,
	GET_TABLE_TABLE_TYPE,
	GET_TABLE_TAXES,
	GET_TABLE_TAXES_RATE,
	GET_TABLE_TERMSOFSALES,
	GET_TABLE_TES_STATES,
	GET_TABLE_TITLES,
	GET_TABLE_USUAL_COIN,
	GET_TABLE_VALIDATIONS,
	GET_TABLE_VALIDATION_TYPE,
	GET_TABLE_VALIDATION_TYPE_T,
	GET_TABLE_VALIDATION_VALUES,
	GET_TABLE_VOUCHER_FIELDS,
	GET_TABLE_VOUCHER_MODULE,
	GET_TABLE_VOUCHER_PROCESS,
	GET_TABLE_VOUCHER_TYPE_ABM
} from 'constants/ActionsTypes'
import {
	IGetTableBarcodeFormatSaga,
	IGetTableBonifRatesSaga,
	IGetTableCostCentersSaga,
	IGetTableCreditSaga,
	IGetTableCufDataSaga,
	IGetTableCUFSaga,
	IGetTableCustomerAttrListSaga,
	IGetTableCustomerBranchesSaga,
	IGetTableFundAccountAttrListSaga,
	IGetTableGenericRequestSaga,
	IGetTableLedgerAccountsSaga,
	IGetTableNumeratorsSaga,
	IGetTableParentCostCenterSaga,
	IGetTableParentLedgerAccountSaga,
	IGetTableProductAttrListSaga,
	IGetTableProductsSaga,
	IGetTableProviderAttrListSaga,
	IGetTableTaxesRateSaga,
	IGetTableTaxesSaga,
	IGetTableTermOfSalesSaga,
	IGetTableVoucherFieldsSaga,
	IGetTableVoucherProcessSaga
} from 'models/TableCalls/TableCallsInterface'
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'

function* getOrdenCbtCabRequest(): any {
	try {
		const tableData = yield call(getOrdenCbtCabApi)
		yield put(getOrdenCbtCabSuccess(tableData))
	} catch (error) {}
}

function* getFilterConditionSaleRequest(): any {
	try {
		const tableData = yield call(getFilterConditionSaleApi)
		yield put(getFilterConditionSaleSuccess(tableData))
	} catch (error) {}
}

function* getFilterTransportRequest(): any {
	try {
		const tableData = yield call(getFilterTransportApi)
		yield put(getFilterTransportSuccess(tableData))
	} catch (error) {}
}

function* getTableCommissionTypeRequest(): any {
	try {
		const tableData = yield call(getTableCommisionType)
		yield put(getTableCommissionTypeSuccess(tableData))
	} catch (error) {}
}

function* getTableMessageRequest(): any {
	try {
		const tableData = yield call(getTableMessage)
		yield put(getTableMessageSuccess(tableData))
	} catch (error) {}
}

function* getTableTableRequest(): any {
	try {
		const tableData = yield call(getTableTable)
		yield put(getTableTableSuccess(tableData))
	} catch (error) {}
}

function* getTableTableTypeRequest(): any {
	try {
		const tableData = yield call(getTableTableType)
		yield put(getTableTableTypeSuccess(tableData))
	} catch (error) {}
}

function* getTableMeasurementUnitRequest({ payload }: any): any {
	try {
		const tableData = yield call(getTableMeasurementUnit, payload)
		yield put(getTableMeasurementUnitSuccess(tableData))
	} catch (error) {}
}

function* getTableAlignmentTypeRequest(): any {
	try {
		const tableData = yield call(getTableAlignmentType)
		yield put(getTableAlignmentTypeSuccess(tableData))
	} catch (error) {}
}

function* getTableDataTypeRequest(): any {
	try {
		const tableData = yield call(getTableDataType)
		yield put(getTableDataTypeSuccess(tableData))
	} catch (error) {}
}

function* getTableValidationTypeRequest(): any {
	try {
		const tableData = yield call(getTableValidationType)
		yield put(getTableValidationTypeSuccess(tableData))
	} catch (error) {}
}

function* getTableValidationTypeTRequest(): any {
	try {
		const tableData = yield call(getTableValidationTypeT)
		yield put(getTableValidationTypeTSuccess(tableData))
	} catch (error) {}
}

function* getTableResponsibleTypeRequest({ payload }: any): any {
	try {
		const tableData = yield call(getTableResponsibleType, payload)
		yield put(getTableResponsibleTypeSuccess(tableData))
	} catch (error) {}
}

function* getTableDocumentTypeRequest(): any {
	try {
		const tableData = yield call(getTableDocumentType)
		yield put(getTableDocumentTypeSuccess(tableData))
	} catch (error) {}
}

function* getTableProvinceRequest(): any {
	try {
		const tableData = yield call(getTableProvince)
		yield put(getTableProvinceSuccess(tableData))
	} catch (error) {}
}

function* getTableCountryRequest({ payload }: any): any {
	try {
		const tableData = yield call(getTableCountry, payload)
		yield put(getTableCountrySuccess(tableData))
	} catch (error) {}
}

function* getTableCouriersRequest(): any {
	try {
		const tableData = yield call(getTableCouriers)
		yield put(getTableCouriersSuccess(tableData))
	} catch (error) {}
}

function* getTableTermsOfSalesRequest({
	payload
}: IGetTableTermOfSalesSaga): any {
	try {
		const tableData = yield call(getTableTermsOfSales, payload)
		yield put(getTableTermsOfSalesSuccess(tableData))
	} catch (error) {}
}

function* getTableSellersRequest(): any {
	try {
		const tableData = yield call(getTableSellers)
		yield put(getTableSellersSuccess(tableData))
	} catch (error) {}
}

function* getTablePriceListRequest(): any {
	try {
		const tableData = yield call(getTablePriceList)
		yield put(getTablePriceListSuccess(tableData))
	} catch (error) {}
}

function* getTableForeignCurrenciesRequest(): any {
	try {
		const tableData = yield call(getTableForeignCurrencies)
		yield put(getTableForeignCurrenciesSuccess(tableData))
	} catch (error) {}
}

function* getTableCurrenciesRequest({ payload }: any): any {
	try {
		const tableData = yield call(getTableCurrencies, payload)
		yield put(getTableCurrenciesSuccess(tableData))
	} catch (error) {}
}

function* getTableConceptsBackgroundsRequest({ payload }: any): any {
	try {
		const tableData = yield call(getTableConceptsBackgrounds, payload)
		yield put(getTableConceptsBackgroundsSuccess(tableData))
	} catch (error) {}
}

function* getTableBranchOfficesRequest({ payload }: any): any {
	try {
		const tableData: any = yield call(getTableBranchOffices, payload)
		yield put(getTableBranchOfficesSuccess(tableData))
	} catch (error) {}
}

function* getTableGenerationTypeRequest(): any {
	try {
		const tableData = yield call(getTableGenerationType)
		yield put(getTableGenerationTypeSuccess(tableData))
	} catch (error) {}
}

function* getTableVoucherTypeRequest(): any {
	try {
		const tableData = yield call(getTableVoucherType)
		yield put(getTableVoucherTypeAdminSuccess(tableData))
	} catch (error) {}
}

function* getTableCustomerCategoryRequest({ payload }: any): any {
	try {
		const tableData = yield call(getTableCustomerCategory, payload)
		yield put(getTableCustomerCategorySuccess(tableData))
	} catch (error) {}
}

function* getTablePaymentConditionsRequest(): any {
	try {
		const tableData = yield call(getTablePaymentConditions)
		yield put(getTablePaymentConditionsSuccess(tableData))
	} catch (error) {}
}

function* getTableProviderCatRequest(): any {
	try {
		const tableData = yield call(getTableProviderCat)
		yield put(getTableProviderCatSuccess(tableData))
	} catch (error) {}
}

function* getTableProviderAttrRequest(): any {
	try {
		const values: any = yield call(getTableProviderAttr)
		yield put(getTableProviderAttrSuccess(values))
	} catch (error) {}
}

function* getTableProviderAttrListRequest({
	payload
}: IGetTableProviderAttrListSaga): any {
	try {
		const values: any = yield call(getTableProviderAttrList, payload)
		yield put(getTableProviderAttrListSuccess(values))
	} catch (error) {}
}

function* getTableCreditRequest({ payload }: IGetTableCreditSaga): any {
	try {
		const tableData = yield call(getTableCredit, payload)
		yield put(getTableCreditSuccess(tableData))
	} catch (error) {}
}

function* getTableTaxesRequest({ payload }: IGetTableTaxesSaga): any {
	try {
		const tableData = yield call(getTableTaxes, payload)
		yield put(getTableTaxesSuccess(tableData))
	} catch (error) {}
}

function* getTableTaxesRateRequest({ payload }: IGetTableTaxesRateSaga): any {
	try {
		const tableData = yield call(getTableTaxesRate, payload)
		yield put(getTableTaxesRateSuccess(tableData))
	} catch (error) {}
}

function* getTableCustomerAttrRequest({ payload }: any): any {
	try {
		const values: any = yield call(getTableCustomerAttr, payload)
		yield put(getTableCustomerAttrSuccess(values))
	} catch (error) {}
}

function* getTableCustomerAttrListRequest({
	payload
}: IGetTableCustomerAttrListSaga): any {
	try {
		const values: any = yield call(getTableCustomerAttrList, payload)
		yield put(getTableCustomerAttrListSuccess(values))
	} catch (error) {}
}

function* getTableCustomerBranchesRequest({
	payload
}: IGetTableCustomerBranchesSaga): any {
	try {
		const tableData = yield call(getTableCustomerBranches, payload)
		yield put(getTableCustomerBranchesSuccess(tableData))
	} catch (error) {}
}

function* getTableFundsAccountsCategoryRequest(): any {
	try {
		const tableData = yield call(getTableFundsAccountsCat)
		yield put(getTableFundsAccountsCatSuccess(tableData))
	} catch (error) {}
}

function* getTableItemCategoryRequest({ payload }: any): any {
	try {
		const tableData = yield call(getTableItemCat, payload)
		yield put(getTableItemCatSuccess(tableData))
	} catch (error) {}
}

function* getTableNumeratorsRequest({ payload }: IGetTableNumeratorsSaga): any {
	try {
		const tableData = yield call(getTableNumerators, payload)
		yield put(getTableNumeratorsSuccess(tableData))
	} catch (error) {}
}

function* getTableSymbolsRequest(): any {
	try {
		const tableData = yield call(getTableSymbols)
		yield put(getTableSymbolsSuccess(tableData))
	} catch (error) {}
}

function* getTableTesStatesRequest(): any {
	try {
		const tableData = yield call(getTableTesStates)
		yield put(getTableTesStatesSuccess(tableData))
	} catch (error) {}
}

function* getTableFundAccountAttrRequest(): any {
	try {
		const values: any = yield call(getTableFundAccountAttr)
		yield put(getTableFundAccountAttrSuccess(values))
	} catch (error) {}
}

function* getTableFundAccountAttrListRequest({
	payload
}: IGetTableFundAccountAttrListSaga): any {
	try {
		const values: any = yield call(getTableFundAccountAttrList, payload)
		yield put(getTableFundAccountAttrListSuccess(values))
	} catch (error) {}
}

function* getTableFundsConceptsTypeRequest(): any {
	try {
		const values: any = yield call(getTableFundsConceptsType)
		yield put(getTableFundsConceptsTypeSuccess(values))
	} catch (error) {}
}

function* getTableImputationTypeRequest(): any {
	try {
		const values: any = yield call(getTableImputationType)
		yield put(getTableImputationTypeSuccess(values))
	} catch (error) {}
}

function* getTableLedgerAccountsCategoryRequest(): any {
	try {
		const values: any = yield call(getTableLedgerAccountsCategory)
		yield put(getTableLedgerAccountsCategorySuccess(values))
	} catch (error) {}
}

function* getTableParentLedgerAccountRequest({
	payload
}: IGetTableParentLedgerAccountSaga): any {
	try {
		const values: any = yield call(getTableParentLedgerAccount, payload)
		yield put(getTableParentLedgerAccountSuccess(values))
	} catch (error) {}
}

function* getTableCostCentersCategoryRequest(): any {
	try {
		const values: any = yield call(getTableCostCentersCategory)
		yield put(getTableCostCentersCategorySuccess(values))
	} catch (error) {}
}

function* getTableCCImputationTypeRequest(): any {
	try {
		const values: any = yield call(getTableCCImputationType)
		yield put(getTableCCImputationTypeSuccess(values))
	} catch (error) {}
}

function* getTableParentCostCenterRequest({
	payload
}: IGetTableParentCostCenterSaga): any {
	try {
		const values: any = yield call(getTableParentCostCenter, payload)
		yield put(getTableParentCostCenterSuccess(values))
	} catch (error) {}
}

function* getFilterLAAssignTypeRequest(): any {
	try {
		const values: any = yield call(getFilterLAAssignType)
		yield put(getFilterLAAssignTypeSuccess(values))
	} catch (error) {}
}

function* getFilterLAAssignStatusRequest(): any {
	try {
		const values: any = yield call(getFilterLAAssignStatus)
		yield put(getFilterLAAssignStatusSuccess(values))
	} catch (error) {}
}

function* getFilterLAAssignOrderRequest(): any {
	try {
		const values: any = yield call(getFilterLAAssignOrder)
		yield put(getFilterLAAssignOrderSuccess(values))
	} catch (error) {}
}

function* getTableLedgerAccountsRequest({
	payload
}: IGetTableLedgerAccountsSaga): any {
	try {
		const values: any = yield call(getTableLedgerAccounts, payload)
		yield put(getTableLedgerAccountsSuccess(values))
	} catch (error) {}
}

function* getTableCostCentersRequest({
	payload
}: IGetTableCostCentersSaga): any {
	try {
		const values: any = yield call(getTableCostCenters, payload)
		yield put(getTableCostCentersSuccess(values))
	} catch (error) {}
}

function* getFilterCCAssignTypeRequest(): any {
	try {
		const values: any = yield call(getFilterCCAssignType)
		yield put(getFilterCCAssignTypeSuccess(values))
	} catch (error) {}
}

function* getTableProductCategoryRequest(): any {
	try {
		const values: any = yield call(getTableProductCategory)
		yield put(getTableProductCategorySuccess(values))
	} catch (error) {}
}

function* getTableBarcodeFormatRequest({
	payload
}: IGetTableBarcodeFormatSaga): any {
	try {
		const values: any = yield call(getTableBarcodeFormat, payload)
		yield put(getTableBarcodeFormatSuccess(values))
	} catch (error) {}
}

function* getTableCostAccountingTypeRequest(): any {
	try {
		const values: any = yield call(getTableCostAccountingType)
		yield put(getTableCostAccountingTypeSuccess(values))
	} catch (error) {}
}

function* getTableCUFRequest({ payload }: IGetTableCUFSaga): any {
	try {
		const values: any = yield call(getTableCUF, payload)
		yield put(getTableCUFSuccess(values))
	} catch (error) {}
}

function* getTableProductAttrRequest(): any {
	try {
		const values: any = yield call(getTableProductAttr)
		yield put(getTableProductAttrSuccess(values))
	} catch (error) {}
}

function* getTableProductAttrListRequest({
	payload
}: IGetTableProductAttrListSaga): any {
	try {
		const values: any = yield call(getTableProductAttrList, payload)
		yield put(getTableProductAttrListSuccess(values))
	} catch (error) {}
}

function* getTablePRoductsRequest({ payload }: IGetTableProductsSaga): any {
	try {
		const values: any = yield call(getTableProducts, payload)
		yield put(getTableProductsSuccess(values))
	} catch (error) {}
}

function* getOptionsFilterAffectedRequest(): any {
	try {
		const options: any = yield call(getOptionsFilterAffectedApi)
		yield put(getOptionsFilterAffectedSuccess(options))
	} catch (error) {}
}

function* getTablePricesRequest(): any {
	try {
		const tableData = yield call(getTablePrices)
		yield put(getTablePricesSuccess(tableData))
	} catch (error) {}
}

function* getTableDepotsRequest(): any {
	try {
		const values: any = yield call(getTableDepots)
		yield put(getTableDepotsSuccess(values))
	} catch (error) {}
}

function* getTableAVPurchasesRequest({
	payload
}: IGetTableGenericRequestSaga): any {
	try {
		const values: any = yield call(getTableAVPurchases, payload)
		yield put(getTableAVPurchasesSuccess(values))
	} catch (error) {}
}

function* getTableAVSalesRequest({
	payload
}: IGetTableGenericRequestSaga): any {
	try {
		const values: any = yield call(getTableAVSales, payload)
		yield put(getTableAVSalesSuccess(values))
	} catch (error) {}
}

function* getTableAVFundsRequest({
	payload
}: IGetTableGenericRequestSaga): any {
	try {
		const values: any = yield call(getTableAVFunds, payload)
		yield put(getTableAVFundsSuccess(values))
	} catch (error) {}
}

function* getTableAVStockRequest({
	payload
}: IGetTableGenericRequestSaga): any {
	try {
		const values: any = yield call(getTableAVStock, payload)
		yield put(getTableAVStockSuccess(values))
	} catch (error) {}
}

function* getTableAVAccountingRequest({
	payload
}: IGetTableGenericRequestSaga): any {
	try {
		const values: any = yield call(getTableAVAccounting, payload)
		yield put(getTableAVAccountingSuccess(values))
	} catch (error) {}
}

function* getTableVoucherModulesRequest({
	payload
}: IGetTableGenericRequestSaga): any {
	try {
		const values: any = yield call(getTableVoucherModules, payload)
		yield put(getTableVoucherModulesSuccess(values))
	} catch (error) {}
}

function* getTableProcessRequest({
	payload
}: IGetTableGenericRequestSaga): any {
	try {
		const values: any = yield call(getTableProcess, payload)
		yield put(getTableProcessSuccess(values))
	} catch (error) {}
}

function* getTableVoucherProcessRequest({
	payload
}: IGetTableVoucherProcessSaga): any {
	try {
		const values: any = yield call(getTableVoucherProcess, payload)
		yield put(getTableVoucherProcessSuccess(values))
	} catch (error) {}
}

function* getTableVoucherFieldsRequest({
	payload
}: IGetTableVoucherFieldsSaga): any {
	try {
		const values: any = yield call(getTableVoucherFields, payload)
		yield put(getTableVoucherFieldsSuccess(values))
	} catch (error) {}
}

function* getTableTitlesRequest({ payload }: IGetTableGenericRequestSaga): any {
	try {
		const values: any = yield call(getTableTitles, payload)
		yield put(getTableTitlesSuccess(values))
	} catch (error) {}
}

function* getTableMasksRequest({ payload }: IGetTableGenericRequestSaga): any {
	try {
		const values: any = yield call(getTableMasks, payload)
		yield put(getTableMasksSuccess(values))
	} catch (error) {}
}

function* getTableValidationsRequest({
	payload
}: IGetTableGenericRequestSaga): any {
	try {
		const values: any = yield call(getTableValidations, payload)
		yield put(getTableValidationsSuccess(values))
	} catch (error) {}
}

function* getTableValidationValuesRequest({
	payload
}: IGetTableGenericRequestSaga): any {
	try {
		const values: any = yield call(getTableValidationValues, payload)
		yield put(getTableValidationValuesSuccess(values))
	} catch (error) {}
}

function* getTableCufDataRequest({ payload }: IGetTableCufDataSaga): any {
	try {
		const values: any = yield call(getTableCufData, payload)
		yield put(getTableCufDataSuccess(values))
	} catch (error) {}
}

function* getTableBonificationsRequest(): any {
	try {
		const values: any = yield call(getTableBonifications)
		yield put(getTableBonificationsSuccess(values))
	} catch (error) {}
}

function* getTableBonifRatesRequest({ payload }: IGetTableBonifRatesSaga): any {
	try {
		const values: any = yield call(getTableBonifRates, payload)
		yield put(getTableBonifRatesSuccess(values))
	} catch (error) {}
}

function* getTableUsualCoinRequest(): any {
	try {
		const values: any = yield call(getTableUsualCoin)
		yield put(getTableUsualCoinSuccess(values))
	} catch (error) {}
}

function* getTablePermissionTypeRequest(): any {
	try {
		const values: any = yield call(getTablePermissionType)
		yield put(getTablePermissionTypeSuccess(values))
	} catch (error) {}
}

function* getTableAbmTabsRequest({
	payload
}: IGetTableGenericRequestSaga): any {
	try {
		const values: any = yield call(getTableAbmTabs, payload)
		yield put(getTableAbmTabsSuccess(values))
	} catch (error) {}
}

function* getFilterProductRequest({ payload }: any): any {
	try {
		const values: any = yield call(getFilterProduct, payload)
		yield put(getFilterProductSuccess(values))
	} catch (error) {}
}

function* getTableEstimationTypeRequest({ payload }: any): any {
	try {
		const values: any = yield call(getTableEstimationType, payload)
		yield put(getTableEstimationTypeSuccess(values))
	} catch (error) {}
}

export function* getTableCommissionTypeSaga() {
	yield takeEvery(GET_TABLE_COMMISSION_TYPE, getTableCommissionTypeRequest)
}

export function* getTableMessageSaga() {
	yield takeEvery(GET_TABLE_MESSAGE, getTableMessageRequest)
}

export function* getTableTableSaga() {
	yield takeEvery(GET_TABLE_TABLE, getTableTableRequest)
}

export function* getTableTableTypeSaga() {
	yield takeEvery(GET_TABLE_TABLE_TYPE, getTableTableTypeRequest)
}

export function* getTableMeasurementUnitSaga() {
	yield takeEvery(GET_TABLE_MEASUREMENT_UNIT, getTableMeasurementUnitRequest)
}

export function* getTableAlignmentTypeSaga() {
	yield takeEvery(GET_TABLE_ALIGNMENT_TYPE, getTableAlignmentTypeRequest)
}

export function* getTableDataTypeSaga() {
	yield takeEvery(GET_TABLE_DATA_TYPE, getTableDataTypeRequest)
}

export function* getTableValidationTypeSaga() {
	yield takeEvery(GET_TABLE_VALIDATION_TYPE, getTableValidationTypeRequest)
}

export function* getTableValidationTypeTSaga() {
	yield takeEvery(GET_TABLE_VALIDATION_TYPE_T, getTableValidationTypeTRequest)
}

export function* getTableResponsibleTypeSaga() {
	yield takeEvery(GET_TABLE_RESPONSIBLE_TYPE, getTableResponsibleTypeRequest)
}

export function* getTableDocumentTypeSaga() {
	yield takeEvery(GET_TABLE_DOCUMENT_TYPE, getTableDocumentTypeRequest)
}

export function* getTableProvinceSaga() {
	yield takeEvery(GET_TABLE_PROVINCE, getTableProvinceRequest)
}

export function* getTableCountrySaga() {
	yield takeEvery(GET_TABLE_COUNTRY, getTableCountryRequest)
}

export function* getTableCouriersSaga() {
	yield takeEvery(GET_TABLE_COURIERS, getTableCouriersRequest)
}

export function* getTableTermsOfSalesSaga() {
	yield takeEvery(GET_TABLE_TERMSOFSALES, getTableTermsOfSalesRequest)
}

export function* getTableSellersSaga() {
	yield takeEvery(GET_TABLE_SELLERS, getTableSellersRequest)
}

export function* getTablePriceListSaga() {
	yield takeEvery(GET_TABLE_PRICE_LIST, getTablePriceListRequest)
}

export function* getTableForeignCurrenciesSaga() {
	yield takeEvery(
		GET_TABLE_FOREIGN_CURRENCIES,
		getTableForeignCurrenciesRequest
	)
}

export function* getTableCurrenciesSaga() {
	yield takeEvery(GET_TABLE_CURRENCIES, getTableCurrenciesRequest)
}

export function* getTableConceptsBackgroundsSaga() {
	yield takeEvery(
		GET_TABLE_CONCEPTS_BACKGROUNDS,
		getTableConceptsBackgroundsRequest
	)
}

export function* getTableBranchOfficesSaga() {
	yield takeEvery(GET_TABLE_BRANCH_OFFICES, getTableBranchOfficesRequest)
}

export function* getTableGenerationTypeSaga() {
	yield takeEvery(GET_TABLE_GENERATION_TYPE, getTableGenerationTypeRequest)
}

export function* getTableVoucherTypeSaga() {
	yield takeEvery(GET_TABLE_VOUCHER_TYPE_ABM, getTableVoucherTypeRequest)
}

export function* getTableCreditSaga() {
	yield takeEvery(GET_TABLE_CREDIT, getTableCreditRequest)
}

export function* getTableCustomerCategorySaga() {
	yield takeEvery(GET_TABLE_CUSTOMER_CATEGORY, getTableCustomerCategoryRequest)
}

export function* getTablePaymentConditionsSaga() {
	yield takeEvery(
		GET_TABLE_PAYMENT_CONDITIONS,
		getTablePaymentConditionsRequest
	)
}

export function* getTableProviderCatSaga() {
	yield takeEvery(GET_TABLE_PROVIDER_CAT, getTableProviderCatRequest)
}

export function* getTableProviderAttrSaga() {
	yield takeEvery(GET_TABLE_PROVIDER_ATTR, getTableProviderAttrRequest)
}

export function* getTableProviderAttrListSaga() {
	yield takeEvery(GET_TABLE_PROVIDER_ATTR_LIST, getTableProviderAttrListRequest)
}

export function* getTableTaxesSaga() {
	yield takeEvery(GET_TABLE_TAXES, getTableTaxesRequest)
}

export function* getTableTaxesRateSaga() {
	yield takeEvery(GET_TABLE_TAXES_RATE, getTableTaxesRateRequest)
}

export function* getTableCustomerAttrSaga() {
	yield takeEvery(GET_TABLE_CUSTOMER_ATTR, getTableCustomerAttrRequest)
}

export function* getTableCustomerAttrListSaga() {
	yield takeEvery(GET_TABLE_CUSTOMER_ATTR_LIST, getTableCustomerAttrListRequest)
}

export function* getTableCustomerBranchesSaga() {
	yield takeEvery(GET_TABLE_CUSTOMER_BRANCHES, getTableCustomerBranchesRequest)
}

export function* getTableFundsAccountsCategorySaga() {
	yield takeEvery(
		GET_TABLE_FUNDS_ACCOUNTS_CAT,
		getTableFundsAccountsCategoryRequest
	)
}

export function* getTableItemCategorySaga() {
	yield takeEvery(GET_TABLE_ITEM_CAT, getTableItemCategoryRequest)
}

export function* getTableNumeratorsSaga() {
	yield takeEvery(GET_TABLE_NUMERATORS, getTableNumeratorsRequest)
}

export function* getTableSymbolsSaga() {
	yield takeEvery(GET_TABLE_SYMBOLS, getTableSymbolsRequest)
}

export function* getTableTesStatesSaga() {
	yield takeEvery(GET_TABLE_TES_STATES, getTableTesStatesRequest)
}

export function* getTableFundAccountAttrSaga() {
	yield takeEvery(GET_TABLE_FUND_ACCOUNT_ATTR, getTableFundAccountAttrRequest)
}

export function* getTableFundAccountAttrListSaga() {
	yield takeEvery(
		GET_TABLE_FUND_ACCOUNT_ATTR_LIST,
		getTableFundAccountAttrListRequest
	)
}

export function* getTableFundsConceptsTypeSaga() {
	yield takeEvery(
		GET_TABLE_FUNDS_CONCEPTS_TYPE,
		getTableFundsConceptsTypeRequest
	)
}

export function* getTableImputationTypeSaga() {
	yield takeEvery(GET_TABLE_IMPUTATION_TYPE, getTableImputationTypeRequest)
}

export function* getTableLedgerAccountsCategorySaga() {
	yield takeEvery(
		GET_TABLE_LEDGER_ACCOUNTS_CATEGORY,
		getTableLedgerAccountsCategoryRequest
	)
}

export function* getTableParentLedgerAccountSaga() {
	yield takeLatest(
		GET_TABLE_PARENT_LEDGER_ACCOUNT,
		getTableParentLedgerAccountRequest
	)
}

export function* getTableCostCentersCategorySaga() {
	yield takeLatest(
		GET_TABLE_COST_CENTERS_CATEGORY,
		getTableCostCentersCategoryRequest
	)
}

export function* getTableCCImputationTypeSaga() {
	yield takeLatest(
		GET_TABLE_CC_IMPUTATION_TYPE,
		getTableCCImputationTypeRequest
	)
}

export function* getTableParentCostCenterSaga() {
	yield takeLatest(
		GET_TABLE_PARENT_COST_CENTER,
		getTableParentCostCenterRequest
	)
}

export function* getFilterLAAssignTypeSaga() {
	yield takeLatest(GET_FILTER_LA_ASSIGN_TYPE, getFilterLAAssignTypeRequest)
}

export function* getFilterLAAssignStatusSaga() {
	yield takeLatest(GET_FILTER_LA_ASSIGN_STATUS, getFilterLAAssignStatusRequest)
}

export function* getFilterLAAssignOrderSaga() {
	yield takeLatest(GET_FILTER_LA_ASSIGN_ORDER, getFilterLAAssignOrderRequest)
}

export function* getTableLedgerAccountsSaga() {
	yield takeLatest(GET_TABLE_LEDGER_ACCOUNTS, getTableLedgerAccountsRequest)
}

export function* getTableCostCentersSaga() {
	yield takeLatest(GET_TABLE_COST_CENTERS, getTableCostCentersRequest)
}

export function* getFilterCCAssignTypeSaga() {
	yield takeLatest(GET_FILTER_CC_ASSIGN_TYPE, getFilterCCAssignTypeRequest)
}

export function* getTableProductCategorySaga() {
	yield takeLatest(GET_TABLE_PRODUCT_CAT, getTableProductCategoryRequest)
}

export function* getTableBarcodeFormatSaga() {
	yield takeLatest(GET_TABLE_BARCODE_FORMAT, getTableBarcodeFormatRequest)
}

export function* getTableCostAccountingTypeSaga() {
	yield takeLatest(
		GET_TABLE_COST_ACCOUNTING_TYPE,
		getTableCostAccountingTypeRequest
	)
}

export function* getTableCUFSaga() {
	yield takeLatest(GET_TABLE_CUF, getTableCUFRequest)
}

export function* getTableProductAttrSaga() {
	yield takeEvery(GET_TABLE_PRODUCT_ATTR, getTableProductAttrRequest)
}

export function* getTableProductAttrListSaga() {
	yield takeEvery(GET_TABLE_PRODUCT_ATTR_LIST, getTableProductAttrListRequest)
}

export function* getTableProductsSaga() {
	yield takeEvery(GET_TABLE_PRODUCTS, getTablePRoductsRequest)
}

export function* getFilterTransportSaga() {
	yield takeLatest(GET_FILTER_TRANSPORT, getFilterTransportRequest)
}

export function* getFilterConditionSaleSaga() {
	yield takeLatest(GET_FILTER_CONDITION_SALE, getFilterConditionSaleRequest)
}

export function* getOrdenCbtCabSaga() {
	yield takeLatest(GET_ORDER_CBT_CAB, getOrdenCbtCabRequest)
}

export function* getOptionsFilterAffectedSaga() {
	yield takeLatest(GET_OPTIONS_FILTER_AFFECTED, getOptionsFilterAffectedRequest)
}

export function* getTablePricesSaga() {
	yield takeEvery(GET_TABLE_PRICES, getTablePricesRequest)
}

export function* getTableDepotsSaga() {
	yield takeEvery(GET_TABLE_DEPOTS, getTableDepotsRequest)
}

export function* getTableAVPurchasesSaga() {
	yield takeEvery(GET_TABLE_AV_PURCHASES, getTableAVPurchasesRequest)
}

export function* getTableAVSalesSaga() {
	yield takeEvery(GET_TABLE_AV_SALES, getTableAVSalesRequest)
}

export function* getTableAVStockSaga() {
	yield takeEvery(GET_TABLE_AV_STOCK, getTableAVStockRequest)
}

export function* getTableAVFundsSaga() {
	yield takeEvery(GET_TABLE_AV_FUNDS, getTableAVFundsRequest)
}

export function* getTableAVAccountingSaga() {
	yield takeEvery(GET_TABLE_AV_ACCOUNTING, getTableAVAccountingRequest)
}

export function* getTableVoucherModulesSaga() {
	yield takeEvery(GET_TABLE_VOUCHER_MODULE, getTableVoucherModulesRequest)
}

export function* getTableProcessSaga() {
	yield takeEvery(GET_TABLE_PROCESS, getTableProcessRequest)
}

export function* getTableVoucherProcessSaga() {
	yield takeEvery(GET_TABLE_VOUCHER_PROCESS, getTableVoucherProcessRequest)
}

export function* getTableVoucherFieldsSaga() {
	yield takeEvery(GET_TABLE_VOUCHER_FIELDS, getTableVoucherFieldsRequest)
}

export function* getTableTitlesSaga() {
	yield takeEvery(GET_TABLE_TITLES, getTableTitlesRequest)
}

export function* getTableMasksSaga() {
	yield takeEvery(GET_TABLE_MASKS, getTableMasksRequest)
}

export function* getTableValidationsSaga() {
	yield takeEvery(GET_TABLE_VALIDATIONS, getTableValidationsRequest)
}

export function* getTableValidationValuesSaga() {
	yield takeEvery(GET_TABLE_VALIDATION_VALUES, getTableValidationValuesRequest)
}

export function* getTableCufDataSaga() {
	yield takeLatest(GET_TABLE_CUF_DATA, getTableCufDataRequest)
}

export function* getTableBonificationsSaga() {
	yield takeLatest(GET_TABLE_BONIFICATIONS, getTableBonificationsRequest)
}

export function* getTableBonifRatesSaga() {
	yield takeLatest(GET_TABLE_BONIF_RATES, getTableBonifRatesRequest)
}

export function* getTableUsualCoinSaga() {
	yield takeLatest(GET_TABLE_USUAL_COIN, getTableUsualCoinRequest)
}

export function* getTablePermissionTypeSaga() {
	yield takeLatest(GET_TABLE_PERMISSION_TYPE, getTablePermissionTypeRequest)
}

export function* getTableAbmTabsSaga() {
	yield takeLatest(GET_TABLE_ABM_TABS, getTableAbmTabsRequest)
}

export function* getFilterProductSaga() {
	yield takeLatest(GET_FILTER_PRODUCT, getFilterProductRequest)
}

export function* getTableEstimationTypeSaga() {
	yield takeLatest(GET_TABLE_ESTIMATION_TYPE, getTableEstimationTypeRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getTableCommissionTypeSaga),
		fork(getTableMessageSaga),
		fork(getTableResponsibleTypeSaga),
		fork(getTableDocumentTypeSaga),
		fork(getTableProvinceSaga),
		fork(getTableCountrySaga),
		fork(getTableCouriersSaga),
		fork(getTableTermsOfSalesSaga),
		fork(getTableSellersSaga),
		fork(getTablePriceListSaga),
		fork(getTableForeignCurrenciesSaga),
		fork(getTableConceptsBackgroundsSaga),
		fork(getTableBranchOfficesSaga),
		fork(getTableGenerationTypeSaga),
		fork(getTableVoucherTypeSaga),
		fork(getTableCustomerCategorySaga),
		fork(getTablePaymentConditionsSaga),
		fork(getTableProviderCatSaga),
		fork(getTableProviderAttrSaga),
		fork(getTableProviderAttrListSaga),
		fork(getTableTaxesSaga),
		fork(getTableTaxesRateSaga),
		fork(getTableCreditSaga),
		fork(getTableCustomerAttrSaga),
		fork(getTableCustomerAttrListSaga),
		fork(getTableCustomerBranchesSaga),
		fork(getTableFundsAccountsCategorySaga),
		fork(getTableItemCategorySaga),
		fork(getTableNumeratorsSaga),
		fork(getTableSymbolsSaga),
		fork(getTableTesStatesSaga),
		fork(getTableFundAccountAttrSaga),
		fork(getTableFundAccountAttrListSaga),
		fork(getTableAlignmentTypeSaga),
		fork(getTableDataTypeSaga),
		fork(getTableValidationTypeSaga),
		fork(getTableMeasurementUnitSaga),
		fork(getTableTableSaga),
		fork(getTableTableTypeSaga),
		fork(getTableValidationTypeTSaga),
		fork(getTableFundsConceptsTypeSaga),
		fork(getTableImputationTypeSaga),
		fork(getTableLedgerAccountsCategorySaga),
		fork(getTableParentLedgerAccountSaga),
		fork(getTableCostCentersCategorySaga),
		fork(getTableCCImputationTypeSaga),
		fork(getTableParentCostCenterSaga),
		fork(getFilterLAAssignTypeSaga),
		fork(getFilterLAAssignStatusSaga),
		fork(getFilterLAAssignOrderSaga),
		fork(getTableLedgerAccountsSaga),
		fork(getFilterCCAssignTypeSaga),
		fork(getTableCostCentersSaga),
		fork(getTableProductCategorySaga),
		fork(getTableBarcodeFormatSaga),
		fork(getTableCostAccountingTypeSaga),
		fork(getTableCUFSaga),
		fork(getTableProductAttrSaga),
		fork(getTableProductAttrListSaga),
		fork(getTableProductsSaga),
		fork(getFilterTransportSaga),
		fork(getFilterConditionSaleSaga),
		fork(getOrdenCbtCabSaga),
		fork(getOptionsFilterAffectedSaga),
		fork(getTablePricesSaga),
		fork(getTableDepotsSaga),
		fork(getTableAVPurchasesSaga),
		fork(getTableAVSalesSaga),
		fork(getTableAVFundsSaga),
		fork(getTableAVStockSaga),
		fork(getTableAVAccountingSaga),
		fork(getTableVoucherModulesSaga),
		fork(getTableProcessSaga),
		fork(getTableVoucherProcessSaga),
		fork(getTableVoucherFieldsSaga),
		fork(getTableTitlesSaga),
		fork(getTableMasksSaga),
		fork(getTableValidationsSaga),
		fork(getTableValidationValuesSaga),
		fork(getTableCufDataSaga),
		fork(getTableBonificationsSaga),
		fork(getTableBonifRatesSaga),
		fork(getTableUsualCoinSaga),
		fork(getTableCurrenciesSaga),
		fork(getTablePermissionTypeSaga),
		fork(getTableAbmTabsSaga),
		fork(getFilterProductSaga),
		fork(getTableEstimationTypeSaga)
	])
}
