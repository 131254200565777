import {
	getFilterAttributes,
	getFilterBranches,
	getFilterModules,
	getFilterPaymentConditions,
	getFilterVoucherCategory,
	getFilterVoucherNames,
	getFilterVouchers,
	getOrderDirOptions,
	getOrderOptions
} from 'actions'
import InputAutoCompleteFormik from 'components/form/InputAutoCompleteFormik'
import {
	ATTRIB_DESC,
	ATTRIB_VALUE,
	BRANCH,
	CATEGORY_VOUCHER,
	CLIENT,
	DATE_FROM,
	DATE_UNTIL,
	DESCRIPTION,
	IMP_FROM,
	IMP_UNTIL,
	INPUT_ORDER,
	INTER_NRO,
	MODULE,
	NRO_VOUCHER,
	PENDING,
	SORT,
	TYPE_VOUCHER,
	VOUCHER_DESC
} from 'constants/InputsNames'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getOptionsDropDown, handleSetValueInput } from 'lib/FormUtils'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'
import InputCheckBox from '../form/InputCheckBox'
import InputDropdown from '../form/inputDropdown'
import InputText from '../form/inputText'
import styles from './SearchVoucher.module.scss'

type SearchProps = {
	t: any
	values: any
	handleChange: any
	handleBlur: any
	setFieldValue: any
	readOnly: boolean
	fields: Array<IFieldStructure>
	setFieldTouched: any
	filterModules: any
	filterVouchers: any
	filterBranches: any
	filterAttributes: any
	filterPayments: any
	voucherCategory: any
	voucherNames: any
	sortOptions: any
	sortDirOptions: any
	getFilterVoucherCategory: (params: any) => void
	getFilterVoucherNames: (params: any) => void
	getFilterModules: () => void
	getFilterVouchers: ({ module }?: any) => void
	getFilterBranches: () => void
	getFilterAttributes: ({ module }?: any) => void
	getFilterPaymentConditions: ({ module }?: any) => void
	getOrderOptions: () => void
	getOrderDirOptions: () => void
}

type SearchState = {
	dateFromValue: string
	dateUntilValue: string
	isSearching: boolean
}

class BasicSearchFormInputs extends Component<SearchProps, SearchState> {
	constructor(props: SearchProps) {
		super(props)
		this.state = {
			dateFromValue: '',
			dateUntilValue: '',
			isSearching: false
		}
	}

	componentDidMount = () => {
		const {
			filterModules,
			filterBranches,
			sortOptions,
			sortDirOptions,
			filterVouchers,
			filterAttributes,
			filterPayments,
			values,
			voucherCategory
		} = this.props

		if (_.isEmpty(filterModules)) {
			this.props.getFilterModules()
		}

		if (_.isEmpty(filterBranches)) {
			this.props.getFilterBranches()
		}

		if (_.isEmpty(sortOptions)) {
			this.props.getOrderOptions()
		}
		if (_.isEmpty(sortDirOptions)) {
			this.props.getOrderDirOptions()
		}

		if (_.isEmpty(filterVouchers)) {
			this.props.getFilterVouchers({ module: '' })
		}

		if (_.isEmpty(filterAttributes)) {
			this.props.getFilterAttributes({ module: '' })
		}

		if (_.isEmpty(filterPayments)) {
			this.props.getFilterPaymentConditions({ module: '' })
		}

		if (_.isEmpty(voucherCategory)) {
			this.props.getFilterVoucherCategory({ module: '', optionAll: 1 })
		}

		const dateFromValue = values && values.fec_desde ? values.fec_desde : ''
		const dateUntilValue = values && values.fec_hasta ? values.fec_hasta : ''

		this.setState({
			dateFromValue,
			dateUntilValue
		})
	}

	componentDidUpdate = (prevProps: any) => {
		const { values } = this.props
		if (
			!_.isEqual(prevProps.values, values) &&
			values.fec_desde === '' &&
			values.fec_hasta === ''
		) {
			this.setState({ dateFromValue: '', dateUntilValue: '' })
		}
	}

	/**
	 * to call api voucher after change module
	 * @param module
	 */
	handleChangeModule = (module: string) => {
		this.props.getFilterVouchers({ module })
		this.props.getFilterAttributes({ module })
		this.props.getFilterPaymentConditions({ module })
		this.props.getFilterVoucherCategory({ module, optionAll: 1 })
	}

	handleSearchVoucher = (value: string) => {
		const { getFilterVoucherNames } = this.props
		getFilterVoucherNames({ comprobante: value })
		this.handleIsSearching(true)
	}

	handleIsSearching = (isSearching: boolean) => {
		this.setState({ isSearching })
	}

	render() {
		const {
			values,
			handleBlur,
			readOnly,
			fields,
			t,
			setFieldTouched,
			setFieldValue,
			filterModules,
			filterVouchers,
			filterBranches,
			filterAttributes,
			voucherNames,
			voucherCategory,
			sortOptions,
			sortDirOptions
		} = this.props
		const { dateFromValue, dateUntilValue, isSearching } = this.state
		const formikProps = { setFieldTouched, setFieldValue }

		const voucherList: any =
			voucherNames && voucherNames.lista
				? voucherNames.lista.map((opt: any) => {
						return { id: opt.codigo, label: opt.codigo }
				  })
				: []

		const propsTypeVoucher = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: TYPE_VOUCHER,
			name: TYPE_VOUCHER,
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[TYPE_VOUCHER],
			options: getOptionsDropDown(filterVouchers),
			noInitialExecute: true,
			onChange: (data: any) =>
				handleSetValueInput(data, TYPE_VOUCHER, formikProps)
		}

		const propsCategory = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.cat_voucher'),
			inputId: CATEGORY_VOUCHER,
			name: CATEGORY_VOUCHER,
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[CATEGORY_VOUCHER],
			options: getOptionsDropDown(voucherCategory),
			noInitialExecute: true,
			onChange: (data: any) =>
				handleSetValueInput(data, CATEGORY_VOUCHER, formikProps)
		}

		const propsNoVoucher = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'numero',
			inputId: NRO_VOUCHER,
			name: NRO_VOUCHER,
			placeholder: '',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[NRO_VOUCHER],
			onChange: (data: any) =>
				handleSetValueInput(data, NRO_VOUCHER, formikProps)
		}

		const propsInterNumber = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Nro. Interno',
			inputId: INTER_NRO,
			name: INTER_NRO,
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[INTER_NRO],
			onChange: (data: any) => handleSetValueInput(data, INTER_NRO, formikProps)
		}

		const propsModule = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.module'),
			inputId: MODULE,
			name: MODULE,
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[MODULE],
			options: getOptionsDropDown(filterModules),
			noInitialExecute: true,
			onChange: (data: any) => {
				const { value } = data.target
				this.handleChangeModule(value)
				handleSetValueInput(data, MODULE, formikProps)
			}
		}

		const dateFrom = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'Fecha',
			inputId: DATE_FROM,
			name: DATE_FROM,
			placeholder: t('global.from'),
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: () => {},
			value: dateFromValue,
			autoComplete: 'off',
			onChange: (data: any) => {
				const date = data || ''
				this.setState({ dateFromValue: date })
				handleSetValueInput(date, DATE_FROM, formikProps)
			}
		}

		const dateUntil = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'Fecha',
			inputId: DATE_UNTIL,
			name: DATE_UNTIL,
			placeholder: t('global.until'),
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: dateUntilValue,
			autoComplete: 'off',
			onChange: (data: any) => {
				const date = data || ''
				this.setState({ dateUntilValue: date })
				handleSetValueInput(date, DATE_UNTIL, formikProps)
			}
		}

		const propsDescription = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'Descripción',
			inputId: DESCRIPTION,
			name: DESCRIPTION,
			placeholder: '',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[DESCRIPTION],
			onChange: (data: any) =>
				handleSetValueInput(data, DESCRIPTION, formikProps)
		}

		const propsClient = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'Cliente',
			inputId: CLIENT,
			name: CLIENT,
			placeholder: '',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[CLIENT],
			onChange: (data: any) => handleSetValueInput(data, CLIENT, formikProps)
		}

		const propsAttrib = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'Atributo',
			inputId: ATTRIB_DESC,
			name: ATTRIB_DESC,
			placeholder: '',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[ATTRIB_DESC],
			handleLoading: false,
			options: getOptionsDropDown(filterAttributes),
			noInitialExecute: true,
			onChange: (data: any) =>
				handleSetValueInput(data, ATTRIB_DESC, formikProps)
		}

		const propsPending = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Pendientes',
			inputId: PENDING,
			name: PENDING,
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[PENDING],
			checked: values[PENDING],
			onChange: (data: any) =>
				handleSetValueInput(data ? 1 : 0, PENDING, formikProps)
		}

		const propsSort = {
			inputFormCol: { sm: 4 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: SORT,
			name: SORT,
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[SORT],
			options: getOptionsDropDown(sortOptions),
			onChange: (data: any) => handleSetValueInput(data, SORT, formikProps)
		}

		const propsOrder = {
			inputFormCol: { sm: 2 },
			fields,
			label: 'L',
			inputId: INPUT_ORDER,
			name: INPUT_ORDER,
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[INPUT_ORDER],
			options: getOptionsDropDown(sortDirOptions),
			onChange: (data: any) =>
				handleSetValueInput(data, INPUT_ORDER, formikProps)
		}

		const propsBranch = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.branch'),
			inputId: BRANCH,
			name: BRANCH,
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[BRANCH],
			options: getOptionsDropDown(filterBranches),
			noInitialExecute: true,
			onChange: (data: any) => handleSetValueInput(data, BRANCH, formikProps)
		}

		const propsVoucher = {
			inputFormCol: { sm: 12 },
			fields,
			minLength: 3,
			handleSearch: this.handleSearchVoucher,
			handleLoading: isSearching,
			auoptions: voucherList,
			label: 'Comprobante',
			handleSelect: (voucher: { id: string; label: string }) => {
				formikProps.setFieldValue('comprobante', voucher.id)
				this.handleIsSearching(false)
			},
			divStyle: { paddingLeft: '15px', paddingRight: '15px' },
			styleLabel: {},
			disabledInput: readOnly,
			defaultValue: values[VOUCHER_DESC],
			inputId: VOUCHER_DESC,
			name: VOUCHER_DESC,
			placeholder: '',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			disable: readOnly,
			disableValid: true,
			clearButton: false,
			onBlur: () => this.handleIsSearching(false),
			handleClear: () => formikProps.setFieldValue('comprobante', ''),
			value: values[VOUCHER_DESC],
			t: t,
			handlePartialChange: (data: any) =>
				handleSetValueInput(data, VOUCHER_DESC, formikProps)
		}

		const propsImpFrom = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Importe',
			inputId: IMP_FROM,
			name: IMP_FROM,
			placeholder: t('global.from'),
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[IMP_FROM],
			onChange: (data: any) => handleSetValueInput(data, IMP_FROM, formikProps)
		}

		const propsImpUntil = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Importe',
			inputId: IMP_UNTIL,
			name: IMP_UNTIL,
			placeholder: t('global.until'),
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[IMP_UNTIL],
			onChange: (data: any) => handleSetValueInput(data, IMP_UNTIL, formikProps)
		}

		const propsAttribValue = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.branch'),
			inputId: ATTRIB_VALUE,
			name: ATTRIB_VALUE,
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: handleBlur,
			value: values[ATTRIB_VALUE],
			onChange: (data: any) =>
				handleSetValueInput(data, ATTRIB_VALUE, formikProps)
		}

		return (
			<Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputDropdown {...propsModule} />
					<InputDropdown {...propsCategory} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputDropdown {...propsTypeVoucher} />
					<InputText {...propsNoVoucher} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsInterNumber} />
					<InputText {...dateFrom} />
					<InputText {...dateUntil} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsDescription} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsClient} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputDropdown {...propsBranch} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputAutoCompleteFormik {...propsVoucher} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsImpFrom} />
					<InputText {...propsImpUntil} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputDropdown {...propsAttrib} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsAttribValue} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputCheckBox {...propsPending} />
					<InputDropdown {...propsSort} />
					<InputDropdown {...propsOrder} />
				</Row>
			</Row>
		)
	}
}

const mapStateToProps = ({ searchVoucher }: any) => {
	const {
		filterModules,
		filterVouchers,
		filterBranches,
		filterAttributes,
		filterPayments,
		voucherNames,
		voucherCategory,
		sortOptions,
		sortDirOptions
	} = searchVoucher
	return {
		filterModules,
		filterVouchers,
		filterBranches,
		filterAttributes,
		sortOptions,
		sortDirOptions,
		filterPayments,
		voucherNames,
		voucherCategory
	}
}

export default connect(mapStateToProps, {
	getFilterModules,
	getFilterVouchers,
	getFilterBranches,
	getFilterAttributes,
	getFilterVoucherCategory,
	getFilterVoucherNames,
	getOrderOptions,
	getOrderDirOptions,
	getFilterPaymentConditions
})(BasicSearchFormInputs)
