import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import InputAutocomplete from './inputAutocomplete'
import InputDate from './inputDate'
import InputDropdown from './inputDropdown'
import InputText from './inputText'
class GenericInputForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loadingSearch: false
		}
	}

	componentDidMount = () => {
		const { config } = this.props

		if (!_.isEmpty(config.valor)) {
			this.props.handleChange({
				cod_atributo: config.cod_atrib,
				desc_dato: config.valor
			}) // TODO: set initial value
		}
	}

	componentDidUpdate = (prevProps) => {
		if (
			this.props.autodata !== prevProps.autodata &&
			this.props.autodata.length
		) {
			this.setState({ loadingSearch: false })
		}
	}

	handleSearch = (value) => {
		const { idOperacion, config } = this.props
		this.props.handleSearchAuto({
			dato: value,
			idOperacion,
			cod_atributo: config.cod_atrib
		})
		this.setState({ loadingSearch: true })
	}

	handleSelect = (selected) => {
		const { config } = this.props
		const value = selected[0]
		if (value) {
			this.props.handleChange({
				cod_atributo: config.cod_atrib,
				desc_dato: value.id.trim()
				// desc_dato: value.label
			})
		}
	}

	handleDateChange = (data) => {
		const { config } = this.props
		this.setState({ [config.cod_atrib]: data })
		this.props.handleChange({
			cod_atributo: config.cod_atrib,
			desc_dato: data
		})
	}

	render() {
		const { config, autodata } = this.props

		const optionsSync = autodata
			? autodata.map((opt) => {
					return { id: opt.cod_dato, label: opt.desc_dato.trim() }
			  })
			: []

		const inputConfig = [
			{
				idCampo: config.cod_atrib ? config.cod_atrib.trim() : '',
				label: config.descripcion,
				visible: config.visible,
				requerido: 0,
				editable: config.editable,
				mascara: config.mascara,
				placeholder: config.placeholder || config.descripcion
			}
		]

		const properties = {
			inputFormCol: { sm: 11 },
			fields: inputConfig,
			inputId: config.cod_atrib,
			name: config.cod_atrib,
			label: config.descripcion,
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			divStyle: { paddingLeft: '15px' },
			maxLength: config.largo
		}

		if (config.tipo === 'autocomp') {
			return (
				<InputAutocomplete
					{...properties}
					disable={!config.editable}
					styles={{ width: '100%' }}
					handleSearch={this.handleSearch}
					auoptions={optionsSync}
					handleLoading={this.state.loadingSearch}
					handleSelect={this.handleSelect}
					onChange={() => {}}
					labelKey={'label'}
				/>
			)
		} else if (config.valores && config.valores.length) {
			const optionsConditions = config.valores.map((opt) => {
				return { id: opt.cod_valor, label: opt.desc_valor }
			})

			return (
				<InputDropdown
					noInitialExecute={false}
					options={optionsConditions}
					onChange={(data) => {
						let text = ''

						optionsConditions.forEach((field) => {
							if (field.id === data.target.value) {
								text = field.label
							}
						})

						this.props.handleChange({
							cod_atributo: config.cod_atrib,
							cod_dato: data.target.value,
							desc_dato: text
						})
					}}
					{...properties}
				/>
			)
		} else if (config.tipo === 'fec') {
			return (
				<InputDate
					{...properties}
					value={
						this.state[config.cod_atrib]
							? this.state[config.cod_atrib]
							: config.valor
							? config.valor
							: ''
					}
					disableClear={true}
					onBlur={() => {}}
					autoComplete="new-off"
					onChange={(data) => {
						this.handleDateChange(data)
					}}
				/>
			)
		} else {
			return (
				<InputText
					{...properties}
					value={config.valor ? config.valor : ''}
					onChange={(data) => {
						this.props.handleChange({
							cod_atributo: config.cod_atrib,
							desc_dato: data
						})
					}}
				/>
			)
		}
	}
}

const mapStateToProps = ({ vouchertype }) => {
	const { idOperacion } = vouchertype.voucherType
		? vouchertype.voucherType
		: { idOperacion: null }
	return { idOperacion }
}

export default connect(mapStateToProps, {})(withTranslation()(GenericInputForm))
