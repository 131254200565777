import {
	CRUD_GET_LAST_ACCOUNTING_DATE,
	CRUD_GET_LAST_ACCOUNTING_DATE_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	lastAccountingPeriod: any
}

const initialState = {
	lastAccountingPeriod: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_LAST_ACCOUNTING_DATE:
			return { ...state, lastAccountingPeriod: null }
		case CRUD_GET_LAST_ACCOUNTING_DATE_SUCCESS:
			return { ...state, lastAccountingPeriod: action.payload }
		default:
			return state
	}
}

export default rootReducer
