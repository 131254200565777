import CrudProviderTabsDataContents from 'components/crudProvider/CrudProviderTabsDataContents'
import { CRUD_PROVIDER_DATA } from 'utils/RoutePath'

export const providerConfig = {
	id: 'providerCrud',
	key: 'niprov',
	descrip: 'descrip',
	searchMethod: 'get',
	category: 'A',
	translation: 'provider_data',
	dataUrl: CRUD_PROVIDER_DATA,
	urlApi: 'abm_cpa_proveedores',
	component: CrudProviderTabsDataContents
}
