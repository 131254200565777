import {
	crudCreateProductCode,
	crudGetProductCode,
	crudGetProductCodeConfig,
	crudGetProductCodeInfo
} from 'api/ProductCrudCodeApi'
import {
	ICrudCreateProductCodeSaga,
	ICrudGetProductCodeConfigSaga,
	ICrudGetProductCodeInfoSaga,
	ICrudGetProductCodeSaga
} from 'models/CrudProductsInterface'
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
	crudAcceptProductCodeSuccess,
	crudGetProductCodeConfigSuccess,
	crudGetProductCodeInfoSuccess,
	crudGetProductCodeSuccess,
	crudValidateProductCodeSuccess
} from '../actions'
import {
	CRUD_ACCEPT_PRODUCT_CODE,
	CRUD_GET_PRODUCT_CODE,
	CRUD_GET_PRODUCT_CODE_CONFIG,
	CRUD_GET_PRODUCT_CODE_INFO,
	CRUD_VALIDATE_PRODUCT_CODE
} from '../constants/ActionsTypes'

function* crudGetProductCodeReq({ payload }: ICrudGetProductCodeSaga): any {
	try {
		const product: any = yield call(crudGetProductCode, payload)
		yield put(crudGetProductCodeSuccess(product))
	} catch (error) {}
}

function* crudGetProductCodeConfigReq({
	payload
}: ICrudGetProductCodeConfigSaga): any {
	try {
		const product: any = yield call(crudGetProductCodeConfig, payload)
		yield put(crudGetProductCodeConfigSuccess(product))
	} catch (error) {}
}

function* crudValidateProductCodeReq({
	payload
}: ICrudCreateProductCodeSaga): any {
	try {
		const product = yield call(crudCreateProductCode, payload)
		yield put(crudValidateProductCodeSuccess(product))
	} catch (error) {}
}

function* crudGetProductCodeInfoReq({
	payload
}: ICrudGetProductCodeInfoSaga): any {
	try {
		const product = yield call(crudGetProductCodeInfo, payload)
		yield put(crudGetProductCodeInfoSuccess(product))
	} catch (error) {}
}

function* crudAcceptProductCodeReq({
	payload
}: ICrudCreateProductCodeSaga): any {
	try {
		const product = yield call(crudCreateProductCode, payload)
		yield put(crudAcceptProductCodeSuccess(product))
	} catch (error) {}
}

export function* crudGetProductCodeSaga() {
	yield takeLatest(CRUD_GET_PRODUCT_CODE, crudGetProductCodeReq)
}

export function* crudGetProductCodeConfigSaga() {
	yield takeLatest(CRUD_GET_PRODUCT_CODE_CONFIG, crudGetProductCodeConfigReq)
}

export function* crudValidateProductCodeSaga() {
	yield takeEvery(CRUD_VALIDATE_PRODUCT_CODE, crudValidateProductCodeReq)
}

export function* crudGetProductCodeInfoSaga() {
	yield takeEvery(CRUD_GET_PRODUCT_CODE_INFO, crudGetProductCodeInfoReq)
}

export function* crudAcceptProductCodeSaga() {
	yield takeEvery(CRUD_ACCEPT_PRODUCT_CODE, crudAcceptProductCodeReq)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetProductCodeSaga),
		fork(crudGetProductCodeConfigSaga),
		fork(crudValidateProductCodeSaga),
		fork(crudGetProductCodeInfoSaga),
		fork(crudAcceptProductCodeSaga)
	])
}
