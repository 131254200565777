import Axios from 'axios'
import {
	IDeleteEquivalentProductGroup,
	IGetEquivalentProducts,
	ISetEquivalentProductGroup,
	ISetEquivalentProductSelection
} from 'models/EquivalentProducts'

export const getEquivalentProducts = async (params: IGetEquivalentProducts) => {
	const response = await Axios.get(`/conf_prod_eq/buscar`, { params })
	return response.data
}

export const setEquivalentProductSelection = async (
	params: ISetEquivalentProductSelection
) => {
	const response = await Axios.post(`/conf_prod_eq/seleccionar`, params)
	return response.data
}

export const setEquivalentProductGroup = async (
	params: ISetEquivalentProductGroup
) => {
	const response = await Axios.post(`/conf_prod_eq/agrupador/confirmar`, params)
	return response.data
}

export const deleteEquivalentProductGroup = async (
	params: IDeleteEquivalentProductGroup
) => {
	const response = await Axios.post(
		`/conf_prod_eq/agrupador/eliminar?niref=${params.niref}`
	)
	return response.data
}

export const getEquivalentProductGroup = async (niprod: number) => {
	const response = await Axios.get(`/conf_prod_eq/agrupador?niprod=${niprod}`)
	return response.data
}
