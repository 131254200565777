import { clearMessage, getConfigVoucher, getVoucherType } from 'actions'
import HeadCartResume from 'components/loadItems/HeadCartResume'
import VoucherBreadCrumbs from 'components/voucher/voucherBreadCrumbs'
import {
	findPreviousProcess,
	getBackNextButtons,
	getUrl
} from 'lib/BreadCrumbsUtils'
import logger from 'lib/logger'
import React, { Fragment, PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { LANDING } from 'utils/RoutePath'
import GlobalNotifications from '../common/globalNotifications'
import ErrorBoundary from './ErrorBoundary'
import GlobalShortcut from './globalShortcut'

class GlobalContainer extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			showMessage: false,
			message: '',
			typeMessage: 'error',
			idOperacion: null
		}
	}

	componentDidMount = () => {
		const { voucherType, config, codeProcess, currentProcess } = this.props
		const idOperacion = this.getOperationId()

		if (
			!_.isEmpty(currentProcess) &&
			codeProcess !== currentProcess.cod_proceso
		) {
			this.props.getConfigVoucher({ idOperacion, cod_proceso: codeProcess }) // move back
		}

		if (voucherType === null && this.props.match) {
			this.props.getVoucherType({ idOperacion }) // voucher already exits.
		}

		if (_.isEmpty(config)) {
			this.props.getConfigVoucher({ idOperacion }) // Initial Config
			this.setState({ idOperacion })
		}
	}

	componentDidUpdate = (prevProps) => {
		const { message, authUser, currentProcess, voucherTypeCancel } = this.props

		// to handle all error messages
		this.handleErrorMessage(prevProps.message, message)

		// redirect to the next process
		this.handleRedirectNextProcess(prevProps.currentProcess, currentProcess)

		// redirect to landing when the voucher is canceled
		if (
			voucherTypeCancel !== prevProps.voucherTypeCancel &&
			voucherTypeCancel
		) {
			this.props.history.push(`${LANDING}/${authUser.userCompany}`)
		}
	}

	/**
	 * to handle el redirect to the next process
	 * @param {*} prevProcess
	 * @param {*} currentProcess
	 */
	handleRedirectNextProcess = (prevProcess, currentProcess) => {
		const { codeProcess } = this.props
		if (currentProcess !== prevProcess && !prevProcess) {
			if (codeProcess !== currentProcess.cod_proceso) {
				const operationId = this.getOperationId()
				// se valida que el proceso envié una pagina diferente a la actual.
				const newUrlSubmit = getUrl(currentProcess.cod_proceso, operationId)

				if (!newUrlSubmit.includes('undefined')) {
					this.props.history.push(newUrlSubmit) // redirect to next page
				} else {
					logger({
						module: 'App:: GlobalContainer',
						type: 'error',
						msg: `Comprobante no encontrado. ${newUrlSubmit} `
					})
				}
			}
		}
	}

	/**
	 * to handle all error messages
	 * @param {*} prevMessage
	 * @param {*} message
	 */
	handleErrorMessage = (prevMessage, message) => {
		const { clearMessage, t } = this.props

		if (prevMessage !== message && message) {
			if (message.showError) {
				let errorMessage =
					typeof message.description === 'object'
						? typeof message.description.resultado === 'object'
							? message.description.resultado
							: typeof message.description.Resultado === 'object'
							? message.description.Resultado
							: message.description
						: message.description
				errorMessage = errorMessage.type ? t('error_image') : errorMessage // update error message type blob

				this.setState({
					showMessage: true,
					message: errorMessage,
					typeMessage: message.type || 'error'
				})
			}
			clearMessage()
		}
	}

	/**
	 * to return the operationId
	 * @returns
	 */

	getOperationId = () => {
		const { idOperacion } = this.props.match ? this.props.match.params : null
		return idOperacion
	}

	/**
	 * function to call after confirmation of all forms
	 */
	handleCallBackReturn = () => {
		const idOperacion = this.getOperationId()
		this.props.getConfigVoucher({ idOperacion })
	}

	getShortcuts = () => {
		const { shortcuts } = this.props
		const rtShort = shortcuts || []
		rtShort.push({
			hotkey: { charCode: '123', modifiers: ['f12'] },
			action: this.handleNextPage,
			name: 'NextPage',
			description: 'to call Next Page'
		})

		return rtShort
	}

	handleNextPage = () => {
		const { voucherType, codeProcess, callBackButton } = this.props

		const crumbs = voucherType ? voucherType.procesos : []
		if (voucherType) {
			const [, nextButton] = getBackNextButtons(
				crumbs,
				codeProcess,
				voucherType.idOperacion
			)

			if (callBackButton) {
				const url = nextButton ? nextButton.url : null
				callBackButton(url)
			} else if (nextButton) {
				this.props.history.push(nextButton.url)
			}
		}
	}

	handleSetShow = (state) => {
		this.setState({ showMessage: state })
	}

	handlePreviousPage = () => {
		const { currentProcess, voucherType } = this.props

		const idOperacion = this.getOperationId()

		this.props.getConfigVoucher({
			idOperacion: idOperacion,
			cod_proceso: findPreviousProcess(voucherType.procesos, currentProcess)
		})
	}

	render() {
		const {
			voucherType,
			codeProcess,
			callBackButton,
			breadCrumbButtonType,
			shortcuts,
			nextPage,
			shoppingCartProcess,
			children,
			configTitles
		} = this.props
		const { idOperacion } = voucherType || {}
		const customShortcuts = nextPage ? this.getShortcuts() : shortcuts
		return (
			<GlobalShortcut shortcuts={customShortcuts}>
				<GlobalNotifications
					{...this.state}
					setShow={(s) => this.handleSetShow(s)}
					voucherType={voucherType}
				/>
				{voucherType && (
					<Fragment>
						<ErrorBoundary>
							<HeadCartResume
								idOperacion={idOperacion}
								shoppingCartProcess={shoppingCartProcess}
								codeProcess={codeProcess}
								configTitles={configTitles}
							/>
							<VoucherBreadCrumbs
								crumbs={voucherType ? voucherType.procesos : []}
								current={codeProcess}
								idOperacion={idOperacion}
								callBackButton={callBackButton}
								buttonsType={breadCrumbButtonType}
								showBreadCrumbs={true}
								handlePreviousPage={this.handlePreviousPage}
							/>
						</ErrorBoundary>
						<ErrorBoundary>
							{idOperacion &&
								React.cloneElement(children, {
									idOperacion,
									callBackReturn: this.handleCallBackReturn
								})}
						</ErrorBoundary>
						<VoucherBreadCrumbs
							crumbs={voucherType ? voucherType.procesos : []}
							current={codeProcess}
							idOperacion={idOperacion}
							callBackButton={callBackButton}
							buttonsType={breadCrumbButtonType}
							showBreadCrumbs={false}
							handlePreviousPage={this.handlePreviousPage}
						/>
					</Fragment>
				)}
			</GlobalShortcut>
		)
	}
}

const mapStateToProps = ({ common, vouchertype, auth, voucher }) => {
	const { message } = common
	const { voucherTypeCancel, voucherType } = vouchertype
	const { authUser } = auth
	const { currentProcess, config } = voucher

	return {
		message,
		voucherTypeCancel,
		authUser,
		voucherType,
		currentProcess,
		config
	}
}

export default connect(mapStateToProps, {
	clearMessage,
	getVoucherType,
	getConfigVoucher
})(withTranslation()(withRouter(GlobalContainer)))
