import {
	crudGetPurchaseConditionsDues,
	crudUpdatePurchaseConditionsDues
} from 'actions'
import React, { Component, Fragment } from 'react'
import { Alert } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PurchaseConditionsDuesTable from './PurchaseConditionsDuesTable'

interface PurchaseConditionsDuesProps extends WithTranslation {
	crudGetPurchaseConditionsDues: any
	crudUpdatePurchaseConditionsDues: any
	duesSearched: any
	duesUpdated: any
	cod_cpag: number
	fields: any
	disableForm: boolean
	handleDuesDisable: any
	disabledDuesFields: boolean
	handleWarning: any
	showWarning: boolean
	setFormChanged: any
	t: any
}

class PurchaseConditionsDues extends Component<PurchaseConditionsDuesProps> {
	componentDidMount = () => {
		const { crudGetPurchaseConditionsDues, cod_cpag } = this.props

		crudGetPurchaseConditionsDues({ cod_cpag })
	}

	/**
	 * To submit table data
	 * @param {object} fieldsData
	 */

	handleTableSubmit = (fieldsData: any) => {
		const { cod_cpag, crudUpdatePurchaseConditionsDues } = this.props
		const submitValues = {
			cod_cpag: cod_cpag,
			cuotas: fieldsData
		}
		crudUpdatePurchaseConditionsDues(submitValues)
	}

	render() {
		const {
			fields,
			disableForm,
			duesSearched,
			t,
			disabledDuesFields,
			handleDuesDisable,
			showWarning,
			handleWarning,
			setFormChanged
		} = this.props

		const tableProps = {
			fields: fields,
			disableForm: disableForm,
			duesSearched: duesSearched,
			disabledDuesFields: disabledDuesFields,
			handleDuesDisable: handleDuesDisable,
			handleTableSubmit: this.handleTableSubmit,
			handleWarning: handleWarning,
			showWarning: showWarning,
			setFormChanged: setFormChanged,
			t: t
		}

		return (
			<Fragment>
				<Alert variant={'dark'}>
					<b>{t('CRUDS.general_use.dues')}</b>
				</Alert>
				<PurchaseConditionsDuesTable {...tableProps} />
			</Fragment>
		)
	}
}

const mapStateToProps = ({ purchaseConditionsDues }: any) => {
	const { duesSearched, duesUpdated } = purchaseConditionsDues
	return { duesSearched, duesUpdated }
}

const connectForm = connect(mapStateToProps, {
	crudGetPurchaseConditionsDues,
	crudUpdatePurchaseConditionsDues
})(PurchaseConditionsDues)

export default withTranslation()(connectForm)
