import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CollapseButton from 'components/common/collapseButton'
import CommonTable from 'components/common/commonTable'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Card, Collapse } from 'reactstrap'
import _ from 'underscore'
import { getConfigVoucher, getProductsCart } from '../../actions/'
import { structColumns } from './ItemsTableReadOnlyColumns'
import styles from './shoppingCart.module.css'
class LoadItemsTableReadOnly extends Component {
	dynamicColumns = ['cuf', 'fec_vto', 'partida']

	constructor(props) {
		super(props)

		this.state = {
			collapseItemTable: false,
			columns: []
		}
	}

	componentDidUpdate = (prevProps) => {
		const { itemsTable } = this.props
		this.validateColumnsByData(prevProps.itemsTable, itemsTable)
	}

	validateColumnsByData = (prevItemsTable, itemsTable) => {
		if (
			!_.isEqual(itemsTable, prevItemsTable) &&
			!_.isEmpty(itemsTable?.Items)
		) {
			const structKeys = structColumns.map((column) => column.dataField)
			const missingKeys = structKeys.filter(
				(key) => !Object.keys(itemsTable.Items[0]).includes(key)
			)

			const itemWithValue = {}

			const columnsWithoutValue = _.reduce(
				itemsTable.Items,
				(result, item) => {
					_.each(item, (value, key) => {
						if (
							(value === null || value === undefined) &&
							!itemWithValue[key]
						) {
							if (
								!_.contains(result, key) &&
								_.contains(this.dynamicColumns, key)
							) {
								result.push(key)
							}
						} else if (!itemWithValue[key]) {
							const removeIndex = result.findIndex((res) => res === key)

							if (removeIndex > -1) {
								result.splice(removeIndex, 1)
							}

							itemWithValue[key] = true
						}
					})
					return result
				},
				[]
			)

			const setColumns = new Set([...columnsWithoutValue, ...missingKeys])

			const columns = _.map(structColumns, (column) => {
				if (setColumns.has(column.dataField)) {
					column.hidden = true
				}
				return column
			})

			this.setState({ columns })
		}
	}

	toggleTableItem() {
		this.setState((state) => ({ collapseItemTable: !state.collapseItemTable }))
	}

	renderTotales = () => {
		const { totalTable } = this.props
		const result = []

		totalTable.forEach((row, index) => {
			result.push(
				<Fragment key={index}>
					<Col sm={3}>
						<strong>{row.descrip}</strong>
					</Col>
					<Col sm={3}>{row.importe}</Col>
				</Fragment>
			)
		})

		return result
	}

	handleChangeTable = (type, pagination) => {
		this.props.handleChangeTable({
			page_size: pagination.sizePerPage,
			page_number: pagination.page
		})
	}

	render() {
		const { theme, t, itemsTable, generateItemsTable } = this.props

		const { collapseItemTable, columns } = this.state
		const dataTable = generateItemsTable || itemsTable
		const options = dataTable
			? {
					page: dataTable.page_number,
					sizePerPage: dataTable.page_size,
					totalSize: dataTable.total_count
			  }
			: null

		return (
			<Card className={`pb-3 mt-3 pt-3 mb-4 ${theme.containerCard}`}>
				<Row className={'mb-3'}>
					<Col sm={6} className={`${theme.title} pb-2`}>
						{t('shoppingCart.generate_title')}
					</Col>
					<Col sm={3} className={theme.title} />

					<Col sm={{ span: 2 }} className={'text-right'}>
						<FontAwesomeIcon icon={faPencilAlt} />
					</Col>
					<Fragment>
						<Col className={'pt-2'} sm={3}>
							<strong>{t('shoppingCart.total')}</strong>{' '}
						</Col>
						<Col className={'pt-2'} sm={3}>
							{dataTable ? dataTable.total_count : null}
						</Col>
					</Fragment>
				</Row>
				<Row>{this.renderTotales()}</Row>
				<Row className={'mt-2'}>
					<Col sm={1}>
						<CollapseButton
							onPress={() => this.toggleTableItem()}
							status={collapseItemTable}
						/>
					</Col>
					<Col sm={11}>
						<div className="dropdown-divider col-11 p-1" />
					</Col>
				</Row>

				<Collapse isOpen={collapseItemTable}>
					<Row style={{ width: '98%' }} className={theme.containerTable}>
						{!_.isEmpty(dataTable) && !_.isEmpty(columns) && (
							<CommonTable
								remote
								columns={columns}
								keyField={'nitem'}
								data={dataTable ? dataTable.Items : []}
								rowClasses={theme.tableRow}
								headerClasses={theme.tableHeader}
								paginationOptions={options}
								onTableChange={this.handleChangeTable}
							/>
						)}
					</Row>
				</Collapse>
			</Card>
		)
	}
}

const mapStateToProps = ({ generateForm }) => {
	const { generateItemsTable } = generateForm

	return { generateItemsTable }
}

const connectForm = connect(mapStateToProps, {
	getConfigVoucher,
	getProductsCart
})(LoadItemsTableReadOnly)
export default themr(
	'LoadItemsTableStyles',
	styles
)(withTranslation()(connectForm))
