import CrudProfileTabsDataContents from 'components/crudProfiles/CrudProfileTabsDataContents'
import { CRUD_PROFILES_DATA } from 'utils/RoutePath'

export const profilesConfig = {
	id: 'profilesCrud',
	key: 'cod_grupo',
	descrip: 'desc_grupo',
	searchMethod: 'get',
	category: 'C',
	translation: 'profiles_data',
	dataUrl: CRUD_PROFILES_DATA,
	urlApi: 'abm_perfiles',
	component: CrudProfileTabsDataContents
}
