import InputButton from 'components/form/inputButton'
import React, { Component, Fragment } from 'react'
import { Button, Modal } from 'react-bootstrap'
import './ConfirmModal.scss'

export default class ConfirmModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showModal: false
		}
	}

	handleOpenModal = () => {
		this.setState({ showModal: true })
	}

	handleCloseModal = () => {
		this.setState({ showModal: false })
	}

	handleSubmit = () => {
		const { onSubmitModal } = this.props
		onSubmitModal()
		this.handleCloseModal()
	}

	render() {
		const {
			labelButton,
			labelCancelButtonModal,
			labelSubmitButton,
			modalTitle,
			messageBody,
			buttonStyle,
			typeButton,
			backButton,
			iconTitle,
			disabled
		} = this.props

		const { showModal } = this.state
		const closeButton = labelCancelButtonModal || 'Cancelar'
		const submitButton = labelSubmitButton || 'Aceptar'

		return (
			<Fragment>
				<InputButton
					backButton={backButton}
					valueButton={labelButton}
					type={typeButton || 'primary'}
					onClick={this.handleOpenModal}
					customStyle={buttonStyle || {}}
					disabled={disabled}
				/>
				<Modal
					show={showModal}
					onHide={this.handleCloseModal}
					aria-labelledby={'ModalHeader'}
				>
					<Modal.Header closeButton>
						{modalTitle && (
							<Modal.Title id={'ModalHeader'}>
								<Fragment>
									{modalTitle}
									{iconTitle}
								</Fragment>
							</Modal.Title>
						)}
					</Modal.Header>
					<Modal.Body>{messageBody}</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleCloseModal}>
							{closeButton}
						</Button>
						<Button variant="primary" onClick={this.handleSubmit}>
							{submitButton}
						</Button>
					</Modal.Footer>
				</Modal>
			</Fragment>
		)
	}
}
