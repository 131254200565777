import { all } from 'redux-saga/effects'
import accountingSeatSaga from './AccontingSeat'
import accountingPeriodsSaga from './AccountingPeriodsSaga'
import accountingVoucherSaga from './AccountingVoucher'
import attributeSaga from './Attributes'
import authSagas from './Auth'
import autoGeneraSaga from './AutoGenerate'
import AutomaticPurchaseSaga from './AutomaticPurchase'
import BudgetSaga from './Budget'
import CalculationBonusesSaga from './CalculationBonuses'
import clientSagas from './Client'
import collectionsSaga from './Collection'
import costCentersAccountsSaga from './CostCentersLedgerAccountsSaga'
import crudSaga from './Cruds'
import crudValuesSaga from './CrudValuesSaga'
import customerCodeSaga from './CustomerCrudCodeSaga'
import customerObservationSaga from './CustomerObservationSaga'
import customerReferredSaga from './CustomerReferSaga'
import directCCAssignationsSaga from './DirectCCAssignationSaga'
import directLAAssignationsSaga from './DirectLAAssignationSaga'
import equivalentProductsSaga from './EquivalentProductsSaga'
import uploadFilesSaga from './FileManager'
import FinalConsumerSaga from './FinalConsumer'
import fundAccountBankReconciliationSaga from './FundAccountBankReconciliationSaga'
import fundAccountsCrudCodeSaga from './FundAccountsCrudCodeSaga'
import generateSaga from './Generate'
import HeaderDataValidationSaga from './HeaderDataValidation'
import HeaderEntrySaga from './HeaderEntry'
import HeaderSelectionSaga from './HeaderSelection'
import InventoryExpensesStock from './InventoryExpensesStock'
import InventoryPickStockSaga from './InventoryPickStock'
import LandingSaga from './Landing'
import ledgerAccountsCostCentersSaga from './LedgerAccountsCostCentersSaga'
import ledgerAccountsCrudCodeSaga from './LedgerAccountsCrudCodeSaga'
import ledgerAccountsPointersSaga from './LedgerAccountsPointersSaga'
import LoadInventoryItemSaga from './LoadInventoryItems'
import loadItemsSaga from './LoadItems'
import LoadPackageSaga from './LoadPackages'
import pickeoEgrStockSaga from './PickeoEgrStock'
import pickeoIngStock from './PickeoIngStock'
import productSaga from './Product'
import productBonificationSaga from './ProductBonificationSaga'
import productCodeSaga from './ProductCrudCodeSaga'
import profileAbmsSaga from './ProfileAbmsSaga'
import profileTabsSaga from './ProfileTabsSaga'
import profileUsersSaga from './ProfileUsersSaga'
import providerSaga from './Provider'
import providerCodeSaga from './ProviderCrudCodeSaga'
import providerObservationSaga from './ProviderObservationSaga'
import purchaseConditionsDuesSaga from './PurchaseConditionsDuesSaga'
import purchasesAffectedSaga from './PurchasesAffected'
import salesAffectedSaga from './SalesAffected'
import SearchProductSaga from './SearchProducts'
import searchVoucherSaga from './SearchVouchers'
import sellerCommissionSaga from './SellerCommissionsSaga'
import shoppingCartSaga from './ShoppingCart'
import stockSaga from './Stock'
import stockProviderSaga from './StockProvider'
import sugestedProductsSaga from './SugestedProductsSaga'
import tableCallsSaga from './TableCalls'
import termsOfSalesDuesSaga from './TermsOfSalesDuesSaga'
import TreasuryHeaderSaga from './TreasuryHeader'
import userProfilesSaga from './UserProfilesSaga'
import UtilsFiltersSaga from './UtilsFilters'
import validationSaga from './ValidationSaga'
import voucherSaga from './Voucher'
import voucherTypeSaga from './VoucherType'

export default function* rootSaga(getState) {
	yield all([
		authSagas(),
		clientSagas(),
		voucherTypeSaga(),
		voucherSaga(),
		productSaga(),
		loadItemsSaga(),
		salesAffectedSaga(),
		generateSaga(),
		accountingSeatSaga(),
		accountingVoucherSaga(),
		collectionsSaga(),
		stockSaga(),
		providerSaga(),
		purchasesAffectedSaga(),
		stockProviderSaga(),
		pickeoIngStock(),
		crudSaga(),
		crudValuesSaga(),
		pickeoEgrStockSaga(),
		validationSaga(),
		searchVoucherSaga(),
		SearchProductSaga(),
		customerObservationSaga(),
		customerCodeSaga(),
		providerObservationSaga(),
		providerCodeSaga(),
		fundAccountBankReconciliationSaga(),
		ledgerAccountsPointersSaga(),
		ledgerAccountsCostCentersSaga(),
		costCentersAccountsSaga(),
		directCCAssignationsSaga(),
		directLAAssignationsSaga(),
		LandingSaga(),
		sellerCommissionSaga(),
		termsOfSalesDuesSaga(),
		purchaseConditionsDuesSaga(),
		tableCallsSaga(),
		accountingPeriodsSaga(),
		BudgetSaga(),
		customerReferredSaga(),
		shoppingCartSaga(),
		FinalConsumerSaga(),
		UtilsFiltersSaga(),
		autoGeneraSaga(),
		productBonificationSaga(),
		productCodeSaga(),
		ledgerAccountsCrudCodeSaga(),
		fundAccountsCrudCodeSaga(),
		profileUsersSaga(),
		profileAbmsSaga(),
		profileTabsSaga(),
		userProfilesSaga(),
		HeaderSelectionSaga(),
		attributeSaga(),
		sugestedProductsSaga(),
		equivalentProductsSaga(),
		uploadFilesSaga(),
		AutomaticPurchaseSaga(),
		HeaderDataValidationSaga(),
		CalculationBonusesSaga(),
		HeaderEntrySaga(),
		InventoryPickStockSaga(),
		LoadPackageSaga(),
		InventoryExpensesStock(),
		LoadInventoryItemSaga(),
		TreasuryHeaderSaga()
	])
}
