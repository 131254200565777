import GenericInputForm from 'components/form/genericInputForm'
import React, { Component } from 'react'

export default class providerGenericInputs extends Component {
	handleChangeGeneric = (data) => {
		// console.log(data);
	}

	render() {
		const { provider } = this.props
		return (
			<div>
				{provider && provider.Atributos
					? provider.Atributos.map((element, index) => {
							return (
								<GenericInputForm
									style={{ marginLeft: -5 }}
									config={{ ...element, visible: 1, editable: 1 }}
									key={index}
									handleChange={this.handleChangeGeneric}
								/>
							)
					  })
					: null}
			</div>
		)
	}
}
