import React, { Component } from 'react'
import NotificationMessage from './notificationMessage'

export default class NotificationError extends Component {
	getErrors = () => {
		const { errors, touched } = this.props
		const result = []
		for (const error in errors) {
			if (touched[error]) {
				result.push(errors[error])
			}
		}

		return result
	}

	handleCloseError = () => {
		const { setErrors } = this.props

		if (setErrors) {
			setErrors({})
		}
	}

	render() {
		const errors = this.getErrors()
		const showError = errors.length > 0
		return (
			<NotificationMessage
				showError={showError}
				errorMessage={errors}
				handleCloseError={this.handleCloseError}
				type={'danger'}
			/>
		)
	}
}
