import { ICrudGenericFields } from 'models/CrudInterface'

export const productCufFields: Array<ICrudGenericFields> = [
	{
		id: 'cuf',
		type: 'text',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		disabled: true,
		dependsOn: {
			id: 'alta',
			values: [
				{
					idValue: 1,
					disabled: true
				}
			]
		}
	},
	{
		id: 'cod_dep',
		type: 'combo',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'depots',
		dependsOn: {
			id: 'alta',
			values: [
				{
					idValue: 0,
					disabled: true
				}
			]
		}
	},
	{
		id: 'cod_cuf1',
		type: 'combo',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: 'alta',
			values: [
				{
					idValue: 0,
					disabled: true
				}
			]
		}
	},
	{
		id: 'cod_cuf2',
		type: 'combo',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: 'alta',
			values: [
				{
					idValue: 0,
					disabled: true
				}
			]
		}
	},
	{
		id: 'cod_cuf3',
		type: 'combo',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: 'alta',
			values: [
				{
					idValue: 0,
					disabled: true
				}
			]
		}
	},
	{
		id: 'cod_cuf4',
		type: 'combo',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: 'alta',
			values: [
				{
					idValue: 0,
					disabled: true
				}
			]
		}
	},
	{
		id: 'cod_cuf5',
		type: 'combo',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: 'alta',
			values: [
				{
					idValue: 0,
					disabled: true
				}
			]
		}
	},
	{
		id: 'cod_cuf6',
		type: 'combo',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: 'alta',
			values: [
				{
					idValue: 0,
					disabled: true
				}
			]
		}
	},
	{
		id: 'cod_cuf7',
		type: 'combo',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: 'alta',
			values: [
				{
					idValue: 0,
					disabled: true
				}
			]
		}
	},
	{
		id: 'cod_cuf8',
		type: 'combo',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: 'alta',
			values: [
				{
					idValue: 0,
					disabled: true
				}
			]
		}
	},
	{
		id: 'cod_cuf9',
		type: 'combo',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: 'alta',
			values: [
				{
					idValue: 0,
					disabled: true
				}
			]
		}
	},
	{
		id: 'habilitado',
		type: 'check',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
