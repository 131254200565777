import {
	confirmLoadItems,
	confirmProvLoadItems,
	getVoucherType,
	itemProviderAddToCart,
	voucherCancelSuccess
} from 'actions'
import withMenu from 'components/common/withMenu'
import GlobalContainer from 'components/layout/globalContainer'
import { P_CARGAITEM_CPA } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ItemsTable from './ItemsTable'

class ProviderLoadItems extends Component {
	getShortcuts = () => {
		const shortcuts = [
			{
				hotkey: { charCode: '65', modifiers: ['insert'] },
				action: this.addToCart,
				name: 'Save',
				description: 'Save a file'
			},
			{
				hotkey: { charCode: '65', modifiers: ['home'] },
				action: this.homeFocus,
				name: 'Focus',
				description: 'Focus init'
			},
			{
				hotkey: {
					charCode: '40',
					modifiers: ['arrowDown', 'arrowUp' /*, "arrowLeft", "arrowRight", */]
				},
				action: this.moveArrow,
				name: 'Arrow move',
				description: 'Arrow move'
			}
		]

		return shortcuts
	}

	homeFocus = async () => {
		this.handleFocus()
	}

	addToCart = async (e) => {
		if (e.target.id) {
			const { rowId, field } = this.getRowParams(e)
			this.preventAddToCart(rowId, field)
		}
	}

	moveArrow = async (e) => {
		if (e.target.id) {
			const { rowId, field } = this.getRowParams(e)
			this.handleMoveArrow({ rowId, field, key: e.code })
		}
	}

	getRowParams = (event) => {
		const params = event.target.id.split('-')
		const rowId = parseInt(params[1])
		const field = params[0]

		return { rowId, field }
	}

	handleCallBackButton = (urlNext) => {
		this.loadItemsConfirmation(() => this.handleConfirmationForm(urlNext))
	}

	handleConfirmationForm = (url) => {
		this.props.history.push(url)
	}

	render() {
		const {
			itemsCartProvider,
			parameterConfirm,
			itemProviderAddToCart,
			confirmProvLoadItems
		} = this.props
		return (
			<Row>
				<GlobalContainer
					codeProcess={P_CARGAITEM_CPA}
					shortcuts={this.getShortcuts()}
					callBackButton={this.handleCallBackButton}
					shoppingCartProcess={P_CARGAITEM_CPA}
					nextPage
				>
					<ItemsTable
						showSearchBox
						divClass={'mt-1'}
						preventAddToCart={(click) => (this.preventAddToCart = click)}
						homeFocus={(click) => (this.handleFocus = click)}
						formConfirmation={(click) => (this.loadItemsConfirmation = click)}
						handleMoveArrow={(click) => (this.handleMoveArrow = click)}
						handleItemAddToCart={itemProviderAddToCart}
						isProvider={true}
						itemsCart={itemsCartProvider}
						parameterConfirm={parameterConfirm}
						apiConfirm={confirmProvLoadItems}
					/>
				</GlobalContainer>
			</Row>
		)
	}
}

const mapStateToProps = ({ vouchertype, product, providers }) => {
	const { voucherType } = vouchertype
	const { productsUpdate, search } = product
	const { itemsCartProvider, parameterConfirm } = providers
	return {
		voucherType,
		productsUpdate,
		search,
		itemsCartProvider,
		parameterConfirm
	}
}

export default connect(mapStateToProps, {
	confirmLoadItems,
	voucherCancelSuccess,
	itemProviderAddToCart,
	getVoucherType,
	confirmProvLoadItems
})(withRouter(withMenu(ProviderLoadItems)))
