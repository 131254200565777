import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ERROR_IMAGE } from 'constants/ActionsTypes'
import React, { Component } from 'react'
import { Image, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { getProductImage } from '../../actions'

class PopupImage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showModal: false
		}
	}

	componentDidUpdate = (nextProps) => {
		if (
			nextProps.productImage !== this.props.productImage &&
			this.props.productImage === ERROR_IMAGE
		) {
			this.setState({ showModal: false })
		}
	}

	handleClose = () => {
		this.setState({ showModal: false })
	}

	handleOpen = () => {
		const { product } = this.props
		this.props.getProductImage({ id: product.niprod })
		this.setState({ showModal: true })
	}

	render() {
		const { productImage } = this.props
		const urlImage = productImage || '/assets/images/Loading.gif'

		return (
			<div style={{ cursor: 'pointer' }}>
				<FontAwesomeIcon icon={faCamera} onClick={this.handleOpen} />
				<Modal show={this.state.showModal} onHide={this.handleClose}>
					<Modal.Header closeButton />
					<Modal.Body>
						<div className={'w-100 text-center'}>
							<Image className={'text-center'} src={urlImage} />
						</div>
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = ({ product, common }) => {
	const { productImage } = product
	const { message } = common
	return { productImage, message }
}

export default connect(mapStateToProps, { getProductImage })(PopupImage)
