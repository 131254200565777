import {
	crudGetPurchaseConditionsDues,
	crudsClearStore,
	crudsCreate,
	crudsGetSingle,
	showMessage
} from 'actions'
import CommonTabs from 'components/abmUtils/CommonTabs'
import withMenu from 'components/common/withMenu'
import CrudsContainer from 'components/layout/CrudsContainer'
import CrudPurchaseConditions from 'components/purchaseConditions/CrudPurchaseConditions'
import { getFiltersData } from 'lib/Utils'
import React, { Component } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { CRUD, CRUD_EDIT, CRUD_PURCHASE_CONDITIONS_DATA } from 'utils/RoutePath'
import PurchaseConditionsDues from './AdditionalTabs/PurchaseConditionsDuesComponent'

const refcalculo = {
	lista: [
		{
			codigo: 'F',
			descrip: 'fecha fact'
		},
		{
			codigo: 'S',
			descrip: 'semana fact'
		},
		{
			codigo: 'Q',
			descrip: 'quincena fact'
		},
		{
			codigo: 'M',
			descrip: 'mes fact'
		}
	]
}

const purchaseConditionsTabs = [
	{
		key: 'home',
		label: 'CRUDS.general_use.basic_tab',
		type: 'home'
	},
	{
		key: 'dues',
		label: 'CRUDS.general_use.dues',
		type: 'dues'
	}
]

class SupplierDataContent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			crudCode: this.props.crudCode,
			tabActive: 'home',
			showWarningPopup: false,
			formChanged: false,
			nextAction: '',
			nextTab: 'home'
		}
	}

	componentDidMount() {
		const { crudCode, op, crudsClearStore } = this.props

		if (crudCode && (op === 'edit' || op === 'view')) {
			crudGetPurchaseConditionsDues({ cod_cpag: crudCode })
		}
		if (op === 'add') {
			crudsClearStore()
		}
	}

	componentDidUpdate = (prevProps, prevState) => {
		const {
			valueDataSelected,
			crudCode,
			crudsGetSingle,
			valueDataUpdated,
			selectedAbm,
			duesUpdated,
			crudGetPurchaseConditionsDues,
			op
		} = this.props

		const {
			valueDataUpdated: prevValueDataUpdated,
			valueDataSelected: prevValueDataSelected,
			duesUpdated: prevDuesUpdated
		} = prevProps

		const { tabActive } = this.state

		const { tabActive: prevTabActive } = prevState

		if (prevValueDataSelected !== valueDataSelected && valueDataSelected) {
			let info = false
			if (op === 'view' || op === 'edit') {
				info = true
			} else if (op === 'add') {
				info = false
			}

			this.setState({ info })
		}

		if (duesUpdated !== prevDuesUpdated && duesUpdated) {
			showMessage({
				type: 'success',
				description: 'Se actualizo correctamente',
				showError: true
			})
			crudGetPurchaseConditionsDues({ cod_cpag: crudCode })
			this.handleDuesDisable()
			this.setState({ formChanged: false })
		}

		if (valueDataUpdated !== prevValueDataUpdated && valueDataUpdated) {
			crudsGetSingle({
				crudName: selectedAbm.id,
				urlApi: selectedAbm.urlApi,
				action: 'consulta',
				params: { [selectedAbm.key]: crudCode }
			})
			this.setState({ formChanged: false })
		}

		if (tabActive !== prevTabActive) {
			this.setState({ disabledDuesFields: true })
		}
	}

	// Cancel button function
	handleRedirectHome = () => {
		this.props.history.push(`${CRUD}/${CRUD_PURCHASE_CONDITIONS_DATA}`)
	}

	/**
	 * To disable Dues
	 */

	handleDuesDisable = () => {
		if (this.state.disabledDuesFields) {
			this.setState({ disabledDuesFields: false })
		} else {
			this.setState({ disabledDuesFields: true })
		}
	}

	/**
	 * to call api to create new abm
	 * @param {object} value
	 */

	handleNewCrud = (values) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 1 }
		})
	}

	/**
	 * to call api to edit abm
	 * @param {object} value
	 */

	handleEditCrud = (values) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 0 }
		})
	}

	// Get's the active tab
	handleActiveKey = (tabActive) => {
		this.setState({ tabActive })
	}

	// Handles the Crud change arrows
	handleCrudChange = (param) => {
		const { history, op } = this.props
		history.go(
			`${CRUD_EDIT}/${CRUD_PURCHASE_CONDITIONS_DATA}/${op}/${param.cod_cpag}`
		)
	}

	// Handles the changes warning state
	handleWarningPopup = (value, action) => {
		const { tabActive } = this.state
		this.setState({ showWarningPopup: value })
		action === 'close' && this.setState({ nextTab: tabActive, nextAction: '' })
		action === 'cancel' && this.setState({ formChanged: false })
	}

	// Handles the changes state, used in detecting changes
	handleSetFormChanged = (formChanged) => {
		this.setState({ formChanged })
	}

	// Stores the value of the clicked tab in the component state
	handleSetNextTab = (nextTab) => {
		this.setState({ nextTab })
	}

	// Stores the value of the clicked arrow in the component state
	handleSetNextAction = (nextAction) => {
		this.setState({ nextAction })
	}

	render() {
		const {
			t,
			fieldsConfig,
			fieldsAltaConfig,
			valueDataSelected,
			disableForm,
			op,
			crudCode
		} = this.props

		const {
			tabActive,
			showWarningPopup,
			formChanged,
			nextAction,
			nextTab,
			disabledDuesFields
		} = this.state

		const fields = fieldsAltaConfig
			? getFiltersData(fieldsAltaConfig.campos, { agrupador: 'form_alta' })
			: []
		const duesFields = fieldsAltaConfig
			? getFiltersData(fieldsAltaConfig.campos, {
					agrupador: 'form_alta.cuotas'
			  })
			: []

		const componentTabs = []

		_.forEach(purchaseConditionsTabs, (tab) => {
			const newTab =
				tab.type === 'home'
					? {
							key: tab.key,
							title: t(tab.label),
							content:
								tabActive === tab.key ? (
									<CrudPurchaseConditions
										disableForm={disableForm || false}
										fields={fields}
										itemInfo={valueDataSelected}
										handleSubmit={
											op === 'add' ? this.handleNewCrud : this.handleEditCrud
										}
										method={op}
										handleCloseModal={this.handleRedirectHome}
										showWarning={showWarningPopup}
										handleWarning={this.handleWarningPopup}
										setFormChanged={this.handleSetFormChanged}
										expireData={refcalculo}
									/>
								) : (
									<div></div>
								)
					  }
					: tab.type === 'dues' &&
					  crudCode !== 'new' && {
							key: 'dues',
							title: 'Cuotas',
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<PurchaseConditionsDues
										disableForm={
											disableForm ||
											fieldsConfig.tabs.find(
												(tabResult) => tabResult.cod_tab === tab.key
											)?.tipo_perm === 'L' ||
											false
										}
										fields={duesFields}
										itemInfo={valueDataSelected}
										cod_cpag={crudCode}
										handleSubmit={
											op === 'add' ? this.handleNewCrud : this.handleEditCrud
										}
										method={op}
										handleCloseModal={this.handleRedirectHome}
										showWarning={showWarningPopup}
										handleWarning={this.handleWarningPopup}
										setFormChanged={this.handleSetFormChanged}
										handleDuesDisable={this.handleDuesDisable}
										disabledDuesFields={disabledDuesFields}
									/>
								) : (
									<div></div>
								)
					  }
			!_.isEmpty(newTab) && componentTabs.push(newTab)
		})

		return (
			<CrudsContainer
				urlApi="abm_cpa_cond"
				getNewData={this.handleCrudChange}
				keyApi="cod_cpag"
				handleExitAbm={this.handleRedirectHome}
				showWarning={formChanged}
				handleWarning={this.handleWarningPopup}
				nextAction={nextAction}
				setNextAction={this.handleSetNextAction}
			>
				<Row className={'pt-4'}>
					<Col>
						<Card.Title>
							{disableForm
								? t('purchase_conditions_data.title_view')
								: op === 'edit'
								? t('purchase_conditions_data.title_edit')
								: t('purchase_conditions_data.title_new')}
						</Card.Title>
					</Col>
				</Row>
				{valueDataSelected && (
					<>
						<Row>
							<Col sm={1}>
								<strong>{t('global.code')}</strong>
							</Col>
							<Col className={'text-left'}>
								{valueDataSelected && valueDataSelected.cod_cpag}
							</Col>
						</Row>
						<Row className={'pb-2'}>
							<Col sm={1}>
								<strong>{t('global.name')}</strong>
							</Col>
							<Col className={'text-left'}>
								{valueDataSelected && valueDataSelected.datributo}
							</Col>
						</Row>
					</>
				)}
				<CommonTabs
					customTabs={componentTabs}
					defaultActiveKey={tabActive}
					getActiveKey={this.handleActiveKey}
					showWarning={formChanged}
					handleWarning={this.handleWarningPopup}
					nextTab={nextTab}
					setNextTab={this.handleSetNextTab}
				/>
			</CrudsContainer>
		)
	}
}

const mapStateToProps = ({ configCrud, purchaseConditionsDues }) => {
	const {
		fieldsConfig,
		fieldsAltaConfig,
		valueDataSelected,
		valueDataUpdated
	} = configCrud
	const { duesUpdated } = purchaseConditionsDues
	return {
		fieldsConfig,
		fieldsAltaConfig,
		valueDataSelected,
		valueDataUpdated,
		duesUpdated
	}
}

const connectForm = connect(mapStateToProps, {
	crudsGetSingle,
	crudsCreate,
	crudsClearStore,
	crudGetPurchaseConditionsDues,
	showMessage
})(SupplierDataContent)

export default withTranslation()(withMenu(withRouter(connectForm)))
