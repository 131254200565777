import {
	getPermissionsSuccess,
	getUserSuccess,
	selectCompanySuccess,
	showError,
	userChangePasswordError,
	userChangePasswordSuccess,
	userSignInSuccess,
	userSignOutSuccess
} from 'actions'
import {
	changePasswordApi,
	getPermissions,
	getUser,
	signInUser,
	signOutUser
} from 'api/Auth'
import { getVoucherTypeByUser } from 'api/VoucherType'
import Axios from 'axios'
import {
	CHANGE_PASSWORD,
	GET_PERMISSIONS,
	GET_USER,
	SELECT_COMPANY,
	SIGNIN_USER,
	SIGNOUT_USER
} from 'constants/ActionsTypes'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

function* signInUserRequest({ payload }) {
	try {
		const user = yield call(signInUser, payload)
		const company = user.empresas[0] // Se toma la primera por defecto
		user.configApp = company.configApp
		user.empresa = {
			id: company.id,
			nombre: company.razonSocial,
			colorFondo: company.configApp.colorFondo,
			logo: company.configApp.logo
		}
		user.userCompany = company.id
		localStorage.setItem(
			'user',
			JSON.stringify({ ...user, user: payload.user })
		)
		localStorage.setItem('token', user.token)
		Axios.defaults.headers.common.Authorization = `Bearer ${user.token}`
		const voucherType = yield call(getVoucherTypeByUser)
		localStorage.setItem('userVoucherType', JSON.stringify(voucherType))
		yield put(userSignInSuccess(user))
		// yield put(getVoucherTypeByUserSuccess(voucherType));
	} catch (error) {
		// console.log(error, error)
		if (error.response && error.response.status === 401) {
			yield put(showError('Invalid username or password'))
		} else if (!error.response && error.request && error.request.status === 0) {
			yield put(showError('Network error'))
		} else {
			yield put(showError('Error communicating with server'))
		}
	}
}

function* signOutUserRequest() {
	if (
		localStorage.getItem('token') ||
		Axios.defaults.headers.common.Authorization
	) {
		try {
			window.loggingOut = true // flag to signal the intention, in case request fails
			yield call(signOutUser)
		} catch (error) {}
	}
	localStorage.clear()
	delete Axios.defaults.headers.common.Authorization
	yield put(userSignOutSuccess()) // necessary for redirect login
}

function* getUserRequest() {
	try {
		const user = yield call(getUser)
		yield put(getUserSuccess(user))
	} catch (error) {}
}

function* getPermissionsRequest() {
	try {
		const permissions = yield call(getPermissions)
		yield put(getPermissionsSuccess(permissions))
	} catch (error) {}
}

function* selectCompanyRequest({ payload }) {
	const { company } = payload
	const userLocal = JSON.parse(localStorage.getItem('user'))

	userLocal.empresas.forEach((comp) => {
		if (parseInt(comp.id) === parseInt(company)) {
			userLocal.configApp = comp.configApp
			userLocal.empresa = {
				id: comp.id,
				nombre: comp.razonSocial,
				colorFondo: comp.configApp.colorFondo,
				logo: comp.configApp.logo
			}
			userLocal.userCompany = company
			localStorage.setItem('user', JSON.stringify(userLocal))
		}
	})

	yield put(selectCompanySuccess({ authUser: userLocal }))
}

function* changePasswordRequest({ payload }) {
	try {
		const password = yield call(changePasswordApi, payload)
		yield put(userChangePasswordSuccess(password))
	} catch (error) {
		const { Mens_error } = error.response.data
		yield put(userChangePasswordError({ errorMessage: Mens_error }))
	}
}

export function* signInUserSaga() {
	yield takeLatest(SIGNIN_USER, signInUserRequest)
}

export function* signOutUserSaga() {
	yield takeLatest(SIGNOUT_USER, signOutUserRequest)
}

export function* getUserSaga() {
	yield takeLatest(GET_USER, getUserRequest)
}

export function* getPermissionsSaga() {
	yield takeLatest(GET_PERMISSIONS, getPermissionsRequest)
}

export function* selectCompanySaga() {
	yield takeLatest(SELECT_COMPANY, selectCompanyRequest)
}

export function* changePasswordSaga() {
	yield takeLatest(CHANGE_PASSWORD, changePasswordRequest)
}

export default function* rootSaga() {
	yield all([
		fork(signInUserSaga),
		fork(signOutUserSaga),
		fork(getUserSaga),
		fork(getPermissionsSaga),
		fork(selectCompanySaga),
		fork(changePasswordSaga)
	])
}
