import {
	getSugestedProductsSuccess,
	setSugestedProductPrioritySuccess,
	setSugestedProductSelectionSuccess
} from 'actions'
import {
	getSugestedProducts,
	setSugestedProductPriority,
	setSugestedProductSelection
} from 'api/SugestedProductsApi'
import {
	GET_SUGESTED_PRODUCTS,
	SET_SUGESTED_PRODUCT_PRIORITY,
	SET_SUGESTED_PRODUCT_SELECTION
} from 'constants/ActionsTypes'
import {
	IGetSugestedProductsSaga,
	ISetSugestedProductPrioritySaga,
	ISetSugestedProductSelectionSaga
} from 'models/SugestedProducts'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

function* getSugestedProductsRequest({
	payload
}: IGetSugestedProductsSaga): any {
	try {
		const values: any = yield call(getSugestedProducts, payload)
		yield put(getSugestedProductsSuccess(values))
	} catch (error) {}
}

function* setSugestedProductSelectionRequest({
	payload
}: ISetSugestedProductSelectionSaga): any {
	try {
		const values: any = yield call(setSugestedProductSelection, payload)
		yield put(setSugestedProductSelectionSuccess(values))
	} catch (error) {}
}

function* setSugestedProductPriorityRequest({
	payload
}: ISetSugestedProductPrioritySaga): any {
	try {
		const values: any = yield call(setSugestedProductPriority, payload)
		yield put(setSugestedProductPrioritySuccess(values))
	} catch (error) {}
}

export function* getSugestedProductsSaga() {
	yield takeLatest(GET_SUGESTED_PRODUCTS, getSugestedProductsRequest)
}

export function* setSugestedProductSelectionSaga() {
	yield takeLatest(
		SET_SUGESTED_PRODUCT_SELECTION,
		setSugestedProductSelectionRequest
	)
}

export function* setSugestedProductPrioritySaga() {
	yield takeLatest(
		SET_SUGESTED_PRODUCT_PRIORITY,
		setSugestedProductPriorityRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(getSugestedProductsSaga),
		fork(setSugestedProductSelectionSaga),
		fork(setSugestedProductPrioritySaga)
	])
}
