import CommonLabelCol from 'components/common/CommonLabelCol'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { IGetEgrStockResponse } from 'models/InventoryExpensesStock'
import React, { Component, Fragment } from 'react'
import { Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'

interface InventoryExpensesStockResumeProps extends WithTranslation {
	t?: any
	fields: Array<IFieldStructure>
	data: IGetEgrStockResponse
}

interface InventoryExpensesStockResumeState {}

class InventoryExpensesStockResume extends Component<
	InventoryExpensesStockResumeProps,
	InventoryExpensesStockResumeState
> {
	render() {
		const { fields, data } = this.props

		return (
			<Fragment>
				<Row className="col-sm-12">
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 5 }}
						fields={fields}
						labelId="tot_cant_ingr"
						value={data ? data.cant_ingr : ''}
					/>
				</Row>
			</Fragment>
		)
	}
}

export default withTranslation()(InventoryExpensesStockResume)
