interface LoggerModule {
	module: String
	type?: 'alert' | 'info' | 'error'
	data?: any
	msg: String
}

function logger({ module, type, msg, data }: LoggerModule) {
	const handleMsgType = () => {
		switch (type) {
			case 'info':
				// eslint-disable-next-line no-console
				return console.log(
					`%c ♻️ ${module} ---> `,
					'color: #07BEB8',
					msg,
					data || ''
				)

			case 'error':
				// eslint-disable-next-line no-console
				return console.log(
					`%c ❌ ${module} ---> `,
					'color: #f70a0a',
					msg,
					data || ''
				)

			default:
				// eslint-disable-next-line no-console
				return console.log(
					`%c ⚠️ ${module} ---> `,
					'color: #fff',
					msg,
					data || ''
				)
		}
	}

	if (process.env.REACT_APP_NODE_ENV !== 'production') {
		handleMsgType()
	}
}

export default logger
