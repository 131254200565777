import {
	CRUD_APPLY_LEDGER_ACCOUNTS_POINTERS,
	CRUD_APPLY_LEDGER_ACCOUNTS_POINTERS_SUCCESS,
	CRUD_GET_LEDGER_ACCOUNTS_POINTERS,
	CRUD_GET_LEDGER_ACCOUNTS_POINTERS_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	ledgerAccountsPointersSearched: any
	ledgerAccountsPointersModified: any
}

const initialState = {
	ledgerAccountsPointersSearched: null,
	ledgerAccountsPointersModified: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_LEDGER_ACCOUNTS_POINTERS:
			return { ...state, ledgerAccountsPointersSearched: null }
		case CRUD_GET_LEDGER_ACCOUNTS_POINTERS_SUCCESS:
			return { ...state, ledgerAccountsPointersSearched: action.payload }
		case CRUD_APPLY_LEDGER_ACCOUNTS_POINTERS:
			return { ...state, ledgerAccountsPointersModified: null }
		case CRUD_APPLY_LEDGER_ACCOUNTS_POINTERS_SUCCESS:
			return { ...state, ledgerAccountsPointersModified: action.payload }
		default:
			return state
	}
}

export default rootReducer
