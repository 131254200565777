import GlobalContainer from 'components/layout/globalContainer'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import withMenu from '../../components/common/withMenu'
import ProviderAffectationTable from '../../components/providerAffectation/providerAffectationTable'
import {
	P_AFEC_CANT_CPA,
	P_CARGAITEM_CPA
} from '../../constants/ConfigProcessNames'

class ProviderAffecting extends Component {
	handleCallBackButton = (urlNext) => {
		this.voucherConfirmation()
	}

	getShortcuts = () => {
		return [
			{
				hotkey: {
					charCode: '40',
					modifiers: ['arrowDown', 'arrowUp' /*, "arrowLeft", "arrowRight", */]
				},
				action: this.moveArrow,
				name: 'Arrow move',
				description: 'Arrow move'
			}
		]
	}

	getRowParams = (event) => {
		const params = event.target.id.split('-')
		const rowId = params[1]
		const field = params[0]

		return { rowId, field }
	}

	moveArrow = async (e) => {
		if (e.target.id) {
			const { rowId, field } = this.getRowParams(e)
			this.handleMoveArrow({ rowId, field, key: e.code })
		}
	}

	render() {
		return (
			<Col>
				<Row>
					<GlobalContainer
						shortcuts={this.getShortcuts()}
						codeProcess={P_AFEC_CANT_CPA}
						nextPage
						callBackButton={this.handleCallBackButton}
						shoppingCartProccess={P_CARGAITEM_CPA}
					>
						<ProviderAffectationTable
							formConfirmation={(click) => (this.voucherConfirmation = click)}
							handleMoveArrow={(click) => (this.handleMoveArrow = click)}
						/>
					</GlobalContainer>
				</Row>
			</Col>
		)
	}
}

export default withTranslation()(withRouter(withMenu(ProviderAffecting)))
