import Axios from 'axios'
import {
	ICrudGetTermsOfSalesDues,
	ICrudUpdateTermsOfSalesDues
} from 'models/CrudTermsOfSalesInterface'

const urlBase = '/abm_vta_cond'

export const crudGetTermsOfSalesDues = async (
	params: ICrudGetTermsOfSalesDues
) => {
	const response = await Axios.get(`${urlBase}/cuotas/sel`, { params })
	return response.data
}

export const crudUpdateTermsOfSalesDues = async (
	params: ICrudUpdateTermsOfSalesDues
) => {
	const response = await Axios.post(`${urlBase}/cuotas/confirmar`, params)
	return response.data
}
