import AutoGenerateComponent from 'components/AutoGenerate/AutoGenerateComponent'
import withMenu from 'components/common/withMenu'
import GlobalContainer from 'components/layout/globalContainer'
import { P_AUTO_GENERATE } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'

class AutoGenerate extends Component {
	handleCallBackButton = (urlNextPage: string) => {
		// console.log(urlNextPage)
	}

	render() {
		const containerProps = {
			codeProcess: P_AUTO_GENERATE,
			callBackButton: this.handleCallBackButton,
			nextPage: true
		}

		return (
			<Row>
				<GlobalContainer {...containerProps}>
					<AutoGenerateComponent />
				</GlobalContainer>
			</Row>
		)
	}
}

export default withMenu(AutoGenerate)
