import {
	getTableItemCat,
	getTableNumerators,
	getTableSymbols,
	getTableTesStates
} from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { ICrudFundAccountField } from 'models/CrudFundsAccountsInterface'
import {
	IGetTableItemCatResArray,
	IGetTableNumeratorsResArray,
	IGetTableSymbolsResArray,
	IGetTableTesStatesResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import FundAccountMovementsDataField from './FundAccountMovementsDataField'

type ComponentProps = {
	fields: Array<ICrudFundAccountField>
	handleSubmit: any
	itemInfo?: any
	disableForm?: boolean
	handleCloseModal: any
	getTableItemCat: any
	getTableNumerators: any
	getTableSymbols: any
	getTableTesStates: any
	itemCategory: IGetTableItemCatResArray
	numerators: IGetTableNumeratorsResArray
	symbols: IGetTableSymbolsResArray
	tesStates: IGetTableTesStatesResArray
	renderFormInputs: any
	nicfondo: number
	alta: number
	t: any
	method: any
}

type ComponentState = {
	rate: any
}

class ComponentUpdate extends Component<ComponentProps, ComponentState> {
	constructor(props: ComponentProps) {
		super(props)
		this.state = {
			rate: []
		}
	}

	/**
	 * to add callbacks to fields.
	 * @param {object} fields
	 * @param {object} props
	 * @returns html inputs
	 */

	componentDidMount = () => {
		this.props.getTableItemCat()
		this.props.getTableNumerators()
		this.props.getTableSymbols()
		this.props.getTableTesStates()
	}

	/**
	 * to get data of sellers
	 */

	render() {
		const { fields, t, handleSubmit, itemInfo, disableForm, alta } = this.props

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})

		const columnsCancel = disableForm ? 12 : 6
		const validationSchema = fields.length
			? getValidationSchema(fields, t, [])
			: {}

		const subCategories: Array<string> = []

		_.each(
			fields,
			(
				field: ICrudFundAccountField,
				index: number,
				array: Array<ICrudFundAccountField>
			) => {
				if (array[index - 1]) {
					if (field.agrupador !== array[index - 1].agrupador) {
						subCategories.push(field.agrupador)
					}
				} else {
					subCategories.push(field.agrupador)
				}
			}
		)

		const allFields = _.map(subCategories, (category: string) => {
			const id = category
			const filterFields = fields.filter((field: ICrudFundAccountField) => {
				return field.agrupador === category
			})
			return { name: id, fields: filterFields }
		})
		return (
			<>
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						handleSubmit(values)
					}}
					validationSchema={validationSchema}
					enableReinitialize={true}
				>
					{(props: FormikProps<any>) => (
						<Fragment>
							<Col sm={12}>
								<NotificationError
									errors={props.errors}
									touched={props.touched}
								/>
							</Col>
							<Form>
								{(alta === 1 || (alta === 0 && itemInfo)) &&
									_.map(
										allFields,
										(category: {
											name: string
											fields: Array<ICrudFundAccountField>
										}) => {
											return (
												<Fragment key={category.name}>
													<Alert variant={'dark'}>
														<b>
															{t(
																'CRUDS.general_use.' +
																	category.name.replace('.', '')
															)}
														</b>
													</Alert>
													{_.map(
														category.fields,
														(field: ICrudFundAccountField) => {
															return (
																<FundAccountMovementsDataField
																	key={field.idCampo}
																	formikProps={props}
																	field={
																		field.idCampo === 'suc_clie' && alta === 0
																			? { ...field, disabled: true }
																			: field
																	}
																	crudProps={this.props}
																	initialValue={
																		initialValues[
																			field.idCampo as keyof typeof initialValues
																		]
																	}
																/>
															)
														}
													)}
												</Fragment>
											)
										}
									)}
								<Row className={'pt-3'}>
									{!disableForm && (
										<Col sm={6} className={'text-center mt-2'}>
											<Button style={{ minWidth: '145px' }} type={'submit'}>
												{t('Collection.form.buttons.accept')}
											</Button>
										</Col>
									)}
									<Col sm={columnsCancel} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px' }}
											type={'button'}
											onClick={this.props.handleCloseModal}
										>
											{t('Collection.form.buttons.cancel')}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					)}
				</Formik>
			</>
		)
	}
}

const mapStateToProps = ({ tableCalls }: any) => {
	const { itemCategory, numerators, symbols, tesStates } = tableCalls
	return {
		itemCategory,
		numerators,
		symbols,
		tesStates
	}
}

export default withTranslation()<any>(
	withRouter(
		connect(mapStateToProps, {
			getTableItemCat,
			getTableNumerators,
			getTableSymbols,
			getTableTesStates
		})<any>(ComponentUpdate)
	)
)
