import React, { Component } from "react";
import { ThemeProvider } from "react-css-themr";
import { Provider } from "react-redux";
import AppRouter from "./AppRouter";
import configureStore from "./store";
import contextTheme from "./themes";
import { translate, setLocale } from "translations/index";

const store = configureStore();

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<ThemeProvider theme={contextTheme}>
					<AppRouter />
				</ThemeProvider>
			</Provider>
		);
	}
}

export default App;
