import { confirmBonuses, getBonuses } from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { P_CALCULATION_BONUSES } from 'constants/ConfigProcessNames'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { IConfigFieldsForm } from 'models/Budget'
import {
	ICalculationFormValues,
	IConfirmBonusesParams,
	IGetBonusesParams,
	IGetBonusesResponse
} from 'models/CalculationBonuses'
import React, { Component } from 'react'
import { Col, Container } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import CalculationBonusesForm from './CalculationBonusesForm'

interface ICalculationBonusesComponentProps extends WithTranslation {
	calculationFormRef: any
	idOperacion?: string
	config: IConfigFieldsForm
	callBackReturn?: () => void
	t?: any
	getBonuses: (payload: IGetBonusesParams) => void
	bonusesData: IGetBonusesResponse
	confirmBonuses: (payload: IConfirmBonusesParams) => void
}

interface ICalculationBonusesComponentState {}

export class CalculationBonusesComponent extends Component<
	ICalculationBonusesComponentProps,
	ICalculationBonusesComponentState
> {
	componentDidMount = (): void => {
		const { idOperacion } = this.props
		this.props.getBonuses({ idOperacion: idOperacion || '' })
	}

	handleSubmitForm = (values: Partial<ICalculationFormValues>) => {
		const { callBackReturn, idOperacion } = this.props
		this.props.confirmBonuses({
			callBackReturn,
			params: { ...values, idOperacion: idOperacion || '' }
		})
	}

	render() {
		const { config, calculationFormRef, t, bonusesData } = this.props
		const { Resultado, ...dataForm } = bonusesData || { Resultado: null }
		const initialValues: Partial<ICalculationFormValues> = !_.isEmpty(config)
			? (getInitValues(config.campos) as Partial<ICalculationFormValues>)
			: {}

		const validationSchema = !_.isEmpty(config)
			? getValidationSchema(config.campos, t, [])
			: {}

		return (
			<Formik
				ref={calculationFormRef}
				initialValues={!_.isEmpty(dataForm) ? dataForm : initialValues}
				onSubmit={(values) => {
					this.handleSubmitForm(values)
				}}
				validationSchema={validationSchema}
				enableReinitialize={true}
				render={(props: FormikProps<Partial<ICalculationFormValues>>) => {
					return (
						<Container>
							<Col sm={12}>
								<NotificationError
									errors={props.errors}
									touched={props.touched}
								/>
							</Col>
							<Col sm={12}>
								<Form>
									<CalculationBonusesForm
										fields={config ? config.campos : []}
										formikProps={props}
									/>
								</Form>
							</Col>
						</Container>
					)
				}}
			/>
		)
	}
}

const mapStateToProps = ({ voucher, calculationBonuses }: any) => {
	const { bonusesData } = calculationBonuses
	const config = voucher.config ? voucher.config[P_CALCULATION_BONUSES] : null
	return { config, bonusesData }
}

const mapDispatchToProps = { getBonuses, confirmBonuses }

export default withTranslation()(
	connect(mapStateToProps, mapDispatchToProps)(CalculationBonusesComponent)
)
