import { getDetailMainData } from 'actions'
import CommonContainer from 'components/common/CommonContainer/CommonContainer'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import React, { Component, Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import MainDataAttributes from './MainDataAttributes'
import MainDataFields from './MainDataFields'
import MainDataIndicators from './MainDataIndicators'

interface IMainDataProps extends WithTranslation {
	authUser: any
	productId: string
	detailMainData?: any
	fields: IFieldStructure[]
	fieldsTable: IFieldStructure[]
	getDetailMainData?: any
	t?: any
}

class DetailMainData extends Component<IMainDataProps> {
	constructor(props: IMainDataProps) {
		super(props)
		this.state = {}
	}

	componentDidMount = () => {
		const { authUser, productId } = this.props
		this.props.getDetailMainData({
			idEmpresa: authUser.userCompany,
			ID: productId
		})
	}

	render() {
		const { fields, detailMainData, t, fieldsTable } = this.props
		return (
			<Fragment>
				<MainDataFields fields={fields} detailMainData={detailMainData} />
				<CommonContainer>
					<div className="py-1 pl-2 font-weight-bold">
						{t('search_product_detail.indicators')}
					</div>
				</CommonContainer>
				<MainDataIndicators fields={fields} detailMainData={detailMainData} />
				<CommonContainer>
					<div className="py-1 pl-2 font-weight-bold">
						{t('search_voucher.attributes')}
					</div>
				</CommonContainer>
				{!_.isEmpty(detailMainData) && (
					<MainDataAttributes
						fields={fieldsTable}
						items={detailMainData ? detailMainData.atributos : []}
					/>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth, searchProducts }: any) => {
	const { authUser } = auth
	const { detailMainData } = searchProducts
	return {
		authUser,
		detailMainData
	}
}
const connectForm = connect(mapStateToProps, { getDetailMainData })(
	DetailMainData
)

export default withTranslation()(connectForm)
