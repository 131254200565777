import { createStyles } from '@material-ui/core'

export const styles = (theme: any) =>
	createStyles({
		info_content: {
			textAlign: 'center',
			fontFamily: 'Roboto',
			'& h2': {
				fontSize: '18px',
				margin: 0
			},
			'& p, div': {
				fontSize: '14px'
			},
			'& span': {
				fontWeight: 'bold'
			}
		},
		icon_success: {
			color: '#00c880',
			fontSize: '3.5em',
			width: '100%'
		},
		icon_error: {
			color: '#e34162',
			fontSize: '3.5em'
		},
		text_message_short: {
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden'
		}
	})
