import { getAllCruds, saveVouchersStyleConfigByUser } from 'actions'
import DocumentsType from 'components/landing/documentsType'
import { getAbmUrl } from 'lib/AbmUtils'
import { renderCheckBasicModules } from 'lib/FormUtils'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import withMenu from '../../components/common/withMenu'
import styles from './Admin.module.scss'
class Admin extends Component {
	constructor(props) {
		super(props)
		this.state = {
			docsByUser: [],
			elements: [],
			idCompany: null,
			modules: []
		}
	}

	componentDidMount = () => {
		const { match } = this.props

		if (match.params.mode) {
			const { mode } = match.params
			this.props.getAllCruds({ modo: mode })
		}
	}

	componentDidUpdate = (prevProps) => {
		const { match, listCruds } = this.props
		if (prevProps.match !== match && match) {
			if (match.params.mode) {
				const { mode } = match.params
				this.props.getAllCruds({ modo: mode })
			}
		}

		// create modules
		this.handleNewModules(prevProps.listCruds, listCruds)
	}

	/**
	 * to get list of modules
	 * @param {*} prevListCruds
	 * @param {*} listCruds
	 */
	handleNewModules = (prevListCruds, listCruds) => {
		if (!_.isEqual(prevListCruds, listCruds) && !_.isEmpty(listCruds)) {
			const modules = _.allKeys(_.countBy(listCruds, 'modulo')).map(
				(module) => {
					return { label: module, check: true }
				}
			)

			this.setState({ modules })
		}
	}

	/**
	 * Link to form to create Abm's
	 * @param {*} params
	 */
	handleNewABM = (params) => {
		const { history, listCruds } = this.props
		const currentUrl = _.find(listCruds, { niabm: params.idComprobante })
		history.push(`/crud/${getAbmUrl(currentUrl.cod_abm)}`)
	}

	/**
	 * to save button colors
	 * @param {*} params
	 */
	handleSaveStyle = (params) => {
		const { botoneraComprobantes } = params
		const botoneraABM = botoneraComprobantes.map((button) => ({
			niabm: button.nioperacion,
			color: button.color
		}))
		this.props.saveVouchersStyleConfigByUser({ botoneraABM })
	}

	/**
	 * to manage the action of checkbox
	 * @param nextCheck
	 * @param module
	 */
	handleChangeCheck = (nextCheck, module) => {
		const { modules } = this.state
		modules.forEach((mod) => {
			if (mod.label === module.label) {
				mod.check = nextCheck
			}
		})

		this.setState({ modules })
	}

	/**
	 * render all ABM
	 * @returns
	 */
	renderABMs = () => {
		const { modules } = this.state
		const { listCruds, authUser } = this.props
		const result = []

		if (!_.isEmpty(listCruds) && !_.isEmpty(modules)) {
			listCruds.forEach((voucher) => {
				const filter = _.filter(modules, { label: voucher.modulo })
				if (!_.isEmpty(filter) && filter[0].check) {
					result.push(voucher)
				}
			})
		}

		if (!_.isEmpty(result)) {
			const typeProps = {
				documents: result.map((crud) => {
					return {
						cod_comprob: crud.cod_abm,
						color: crud.color,
						descrip_comprob: crud.descrip,
						descrip_tipocomp: crud.modulo,
						modulo: crud.modulo,
						nioperacion: crud.niabm
					}
				}),
				handleCrearVoucherType: this.handleNewABM,
				idCompany: authUser.userCompany,
				saveVouchersStyleConfigByUser: this.handleSaveStyle
			}
			return <DocumentsType {...typeProps} />
		}

		return <div />
	}

	render() {
		const { listCruds, t } = this.props
		const { modules } = this.state
		return (
			<Fragment>
				<Row>
					<Col className="p-2 ml-2 font-weight-bold"> {t('CRUDS.title')} </Col>
				</Row>
				<Row>{renderCheckBasicModules(modules, this.handleChangeCheck)} </Row>
				{!_.isEmpty(listCruds) ? <Row>{this.renderABMs()}</Row> : <div />}
			</Fragment>
		)
	}
}

const mapStateToProps = ({ configCrud, auth }) => {
	const { listCruds } = configCrud
	const { authUser } = auth

	return { listCruds, authUser }
}

const connectForm = connect(mapStateToProps, {
	getAllCruds,
	saveVouchersStyleConfigByUser
})(Admin)

export default themr(
	'AdminPageStyles',
	styles
)(withTranslation()(withMenu(withRouter(connectForm))))
