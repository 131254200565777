import { renderInput } from 'lib/FormUtils'
import { ICrudCompleteFields, ICrudGenericFields } from 'models/CrudInterface'
import {
	IGetTableGenericRes,
	IGetTableGenericResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'
import { principalFields } from '../../constants/crudsConfig/crudProductsConstants/CrudProductFormFields'
import CrudProductCodeForm from './CrudProductCodeForm'

type CrudProductDataFieldProps = {
	field: ICrudCompleteFields
	formikProps: any
	crudProps: any
	initialValue: any
	showCodePopup: boolean
	handleCodePopup?: any
	searchCUF: (value: string) => void
	isSearching: boolean
	handleIsSearching: (isSearching: boolean) => void
	accessRights?: string
}

type CrudProductDataFieldState = {
	dependsOn: any
	fieldConfig: ICrudGenericFields
	defaultValue?: string
	idField?: any
	value?: any
}

class CrudProductDataField extends Component<
	CrudProductDataFieldProps,
	CrudProductDataFieldState
> {
	constructor(props: CrudProductDataFieldProps) {
		super(props)
		this.state = {
			fieldConfig: {
				id: '',
				type: '',
				width: '',
				autoComplete: '',
				comboData: '',
				maxChars: 0,
				dependsOn: {
					id: '',
					values: [
						{
							idValue: '',
							disabled: false
						}
					]
				}
			},
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field } = this.props
		const idField = field.idCampo.trim()
		const addData = _.find(principalFields, (field: ICrudGenericFields) => {
			return field.id === idField
		})

		addData && this.setState({ dependsOn: addData.dependsOn })
		addData && this.setState({ fieldConfig: addData })
		addData && this.setState({ idField: idField })
		addData && this.setState({ defaultValue: addData.default })
	}

	// Update check for CPOS and CUIT validation, and default state of dropdowns
	componentDidUpdate = (prevProps: CrudProductDataFieldProps) => {
		const { formikProps: prevFormikProps, crudProps: prevCrudProps } = prevProps
		const { formikProps, crudProps } = this.props

		const { idField, defaultValue, fieldConfig } = this.state

		if (
			idField === 'cod_catprod' &&
			formikProps.values[idField] !== formikProps.initialValues[idField] &&
			formikProps.values[idField] !== prevFormikProps.values[idField]
		) {
			formikProps.setFieldValue('cod_prod', '')
		} else if (
			idField === 'cod_catprod' &&
			formikProps.values[idField] === formikProps.initialValues[idField] &&
			formikProps.values[idField] !== prevFormikProps.values[idField]
		) {
			formikProps.setFieldValue('cod_prod', formikProps.initialValues.cod_prod)
		}

		if (
			fieldConfig.type !== 'combo' &&
			!formikProps.values[idField] &&
			formikProps.values[idField] !== 0 &&
			defaultValue
		) {
			formikProps.setFieldValue(idField, defaultValue)
		}

		if (
			idField === 'mon_v' &&
			!formikProps.initialValues.cod_mone &&
			formikProps.values[idField] !== prevFormikProps.values[idField] &&
			!formikProps.touched.cod_mone
		) {
			formikProps.setFieldValue('cod_mone', formikProps.values[idField])
		}

		if (
			idField === 'unid_c' &&
			!formikProps.initialValues.unid_alt &&
			formikProps.values[idField] !== prevFormikProps.values[idField] &&
			!formikProps.touched.unid_alt
		) {
			formikProps.setFieldValue('unid_alt', formikProps.values[idField])
		}

		if (
			idField === 'rel_c_v' &&
			formikProps.values[idField] &&
			formikProps.values[idField] !== prevFormikProps.values[idField]
		) {
			formikProps.setFieldValue('rel_v_c', 0)
		}
		if (
			idField === 'rel_v_c' &&
			formikProps.values[idField] &&
			formikProps.values[idField] !== prevFormikProps.values[idField]
		) {
			formikProps.setFieldValue('rel_c_v', 0)
		}
		if (
			idField === 'rel_c_a' &&
			formikProps.values[idField] &&
			formikProps.values[idField] !== prevFormikProps.values[idField]
		) {
			formikProps.setFieldValue('rel_a_c', 0)
		}
		if (
			idField === 'rel_a_c' &&
			formikProps.values[idField] &&
			formikProps.values[idField] !== prevFormikProps.values[idField]
		) {
			formikProps.setFieldValue('rel_c_a', 0)
		}
		if (
			(idField === 'largo' || idField === 'ancho' || idField === 'alto') &&
			formikProps.values.largo &&
			formikProps.values.ancho &&
			formikProps.values.alto &&
			formikProps.values[idField] !== prevFormikProps.values[idField]
		) {
			const volumen =
				(parseFloat(formikProps.values.largo) *
					parseFloat(formikProps.values.ancho) *
					parseFloat(formikProps.values.alto)) /
				1000
			formikProps.setFieldValue('volumen', volumen)
		}

		if (
			idField === 'cod_prod' &&
			crudProps.productCodeAccepted &&
			crudProps.productCodeAccepted !== prevCrudProps.productCodeAccepted
		) {
			formikProps.setFieldValue(
				idField,
				crudProps.productCodeAccepted.codigo_completo
			)
		}

		if (
			idField === 'cod_catprod' &&
			!formikProps.initialValues.cat_item &&
			formikProps.values[idField] !== prevFormikProps.values[idField] &&
			!formikProps.touched.cat_item
		) {
			if (formikProps.values[idField] === 'PT')
				formikProps.setFieldValue('cat_item', 'ITGEV')
			else formikProps.setFieldValue('cat_item', 'ITG')
		}
	}

	shouldComponentUpdate(
		nextProps: CrudProductDataFieldProps,
		nextState: CrudProductDataFieldState
	) {
		const { formikProps, field, crudProps, initialValue, showCodePopup } =
			this.props
		const {
			formikProps: nextFormikProps,
			crudProps: nextCrudProps,
			initialValue: nextInitialValue,
			showCodePopup: nextShowCodePopup
		} = nextProps
		// Id of the component
		const idCampo = field.idCampo
		// Dependency from other component
		const dependKey = this.state.dependsOn.id

		// Formik props to check
		const currentFormikValue = formikProps.values[idCampo]
		const nextFormikValue = nextFormikProps.values[idCampo]

		// Props of dependency to check
		const currentDependencyValue = formikProps.values[dependKey]
		const nextDependencyValue = nextFormikProps.values[dependKey]

		// Return value, default is true
		let updateBool = true

		// Initialize checking if the field value changed, if not, return false
		currentFormikValue === nextFormikValue && (updateBool = false)

		// From here do all exceptions
		// If crudprops change, re-render
		crudProps !== nextCrudProps && (updateBool = true)
		// If dependency change, re-render
		if (dependKey !== '') {
			currentDependencyValue !== nextDependencyValue && (updateBool = true)
		}

		initialValue !== nextInitialValue && (updateBool = true)

		showCodePopup !== nextShowCodePopup && (updateBool = true)

		nextState !== this.state && (updateBool = true)

		return updateBool
	}

	// Setup combo field if it's empty
	comboDataField = (data: IGetTableGenericResArray) => {
		const values: { cod_valor: string; desc_valor: string }[] = []
		data &&
			_.each(data.lista, (opt: IGetTableGenericRes) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	// Handles blur to setup touched and validation of cpos/cuit fields
	handleBlur = async (value: any) => {
		const { formikProps, handleIsSearching } = this.props
		const { idField } = this.state
		formikProps.setTouched({
			...formikProps.touched,
			[idField]: true
		})
		idField === 'cuf_rep' && handleIsSearching(false)
	}

	findValueDescrip = (value: any, prop: any) => {
		return (
			prop &&
			prop.lista &&
			prop.lista.find((item: any) => item.codigo === value).descrip
		)
	}

	handleSubmitPopup = (data: any) => {
		const { crudProps } = this.props
		crudProps.crudAcceptProductCode({
			alta: 1,
			cod_catprod: data.cod_catprod,
			codigo_actual: data.cod_prod,
			codigo_completo: data.preview,
			lista: data.lista
		})
	}

	/**
	 * to render form inputs
	 * @param field
	 * @param formikProps
	 * @param disableForm
	 * @param fieldConfig
	 * @returns
	 */
	renderInputs = (
		field: ICrudCompleteFields,
		formikProps: any,
		disableForm: boolean,
		fieldConfig: ICrudGenericFields
	) => {
		const {
			crudProps,
			showCodePopup,
			handleCodePopup,
			searchCUF,
			isSearching,
			handleIsSearching,
			initialValue,
			accessRights
		} = this.props
		const { CUF } = crudProps
		const { values, setFieldValue } = formikProps
		const { dependsOn, idField } = this.state
		const addData = fieldConfig

		const cufList: any =
			CUF && CUF.lista
				? CUF.lista.map((opt: any) => {
						return { id: opt.codigo, label: opt.codigo }
				  })
				: []

		addData &&
			(field = {
				...field,
				tipo: addData.type,
				colLabel: addData.labelWidth,
				colInput: addData.width,
				autoComplete: addData.autoComplete,
				disabled: addData.disabled,
				onBlur: this.handleBlur
			})

		const codeProps = {
			disableForm: crudProps.disableForm,
			itemInfo: crudProps.itemInfo,
			method: crudProps.method,
			cod_catprod: values.cod_catprod,
			cod_prod: values.cod_prod
		}

		if (field.tipo === 'combo') {
			addData &&
				(field.valores = crudProps[addData.comboData as keyof typeof crudProps]
					? this.comboDataField(
							crudProps[addData.comboData as keyof typeof crudProps]
					  )
					: [])

			!formikProps.values[idField] &&
				(field.onChange = (event: any) => {
					setFieldValue(field.idCampo, event.target.value)
				})
		} else if (field.tipo === 'check') {
			field.customProps = {
				checked: Number(values[field.idCampo])
			}
		} else if (field.tipo === 'withModal') {
			field.customProps = {
				modalSubmit: this.handleSubmitPopup,
				modalProps: codeProps,
				ModalBody: CrudProductCodeForm,
				showEditPopup: showCodePopup,
				handleEditPopup: handleCodePopup
			}
		}

		if (field.idCampo.includes('rel')) {
			switch (field.idCampo) {
				case 'rel_c_v':
					field.prefix = formikProps.values.unid_c
						? '1 ' +
						  this.findValueDescrip(
								formikProps.values.unid_c,
								crudProps.measurementUnit
						  ) +
						  ':'
						: 'sin seleccionar'
					field.suffix = formikProps.values.unid_v
						? this.findValueDescrip(
								formikProps.values.unid_v,
								crudProps.measurementUnit
						  )
						: 'sin seleccionar'
					break
				case 'rel_v_c':
					field.prefix = formikProps.values.unid_v
						? '1 ' +
						  this.findValueDescrip(
								formikProps.values.unid_v,
								crudProps.measurementUnit
						  ) +
						  ':'
						: 'sin seleccionar'
					field.suffix = formikProps.values.unid_c
						? this.findValueDescrip(
								formikProps.values.unid_c,
								crudProps.measurementUnit
						  )
						: 'sin seleccionar'
					break
				case 'rel_c_a':
					field.prefix = formikProps.values.unid_c
						? '1 ' +
						  this.findValueDescrip(
								formikProps.values.unid_c,
								crudProps.measurementUnit
						  ) +
						  ':'
						: 'sin seleccionar'
					field.suffix = formikProps.values.unid_alt
						? this.findValueDescrip(
								formikProps.values.unid_alt,
								crudProps.measurementUnit
						  )
						: 'sin seleccionar'
					break
				case 'rel_a_c':
					field.prefix = formikProps.values.unid_alt
						? '1 ' +
						  this.findValueDescrip(
								formikProps.values.unid_alt,
								crudProps.measurementUnit
						  ) +
						  ':'
						: 'sin seleccionar'
					field.suffix = formikProps.values.unid_c
						? this.findValueDescrip(
								formikProps.values.unid_c,
								crudProps.measurementUnit
						  )
						: 'sin seleccionar'
					break
				default:
					field.prefix = ''
					field.suffix = ''
					break
			}

			if (
				field.prefix === 'sin seleccionar' ||
				field.suffix === 'sin seleccionar' ||
				field.prefix.replace(/[1 :]/g, '') === field.suffix
			) {
				field.disabled = true
			} else {
				field.disabled = false
			}
		}

		if (field.idCampo === 'cuf_rep') {
			field.customProps = {
				minLength: 3,
				handleSearch: searchCUF,
				handleLoading: isSearching,
				auoptions: cufList,
				handleSelect: (cuf: { id: string; label: string }) => {
					formikProps.setFieldValue('cuf_rep', cuf.id)
					handleIsSearching(false)
				},
				defaultValue: initialValue,
				disable: disableForm
			}
		}

		dependsOn.id !== '' &&
			_.each(dependsOn.values, (dependency) => {
				values[dependsOn.id] === dependency.idValue &&
					(field.disabled = dependency.disabled)
			})

		if (!field.editable || accessRights === 'L') field.disabled = true
		if (field.visible && accessRights !== 'N') {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { field, formikProps, crudProps } = this.props
		const { fieldConfig } = this.state
		return (
			<Fragment>
				{this.renderInputs(
					field,
					formikProps,
					crudProps.disableForm,
					fieldConfig
				)}
			</Fragment>
		)
	}
}

export default CrudProductDataField
