import { getDetailVoucherItems, getItemCategories } from 'actions'
import CollapseButton from 'components/common/collapseButton'
import CommonTable from 'components/common/commonTable'
import {
	getCurrencyOptions,
	getDefaultColumns,
	getOptionsDropDown
} from 'lib/FormUtils'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import InputDropdown from '../form/inputDropdown'

class SearchVoucherItemsTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			currencyFilter: '',
			category: ''
		}
		this.primaryKey = 'nro'
	}

	componentDidMount = () => {
		const { voucherId, authUser } = this.props
		this.handleGetItems()
		this.props.getItemCategories({
			id: voucherId,
			idEmpresa: authUser.userCompany
		})
	}

	handleGetItems = (filters = {}) => {
		const { id, authUser } = this.props
		if (id) {
			this.props.getDetailVoucherItems({
				id,
				page_size: 10,
				page_number: 1,
				idEmpresa: authUser.userCompany,
				...filters
			})
		}
	}

	handleChangeTable = (type, pagination) => {
		if (type === 'pagination') {
			const filters = {
				page_number: pagination.page,
				page_size: pagination.sizePerPage
			}
			this.handleGetItems(filters)
		}
	}

	/**
	 * to expand row with new items
	 * @param {*} row
	 * @returns
	 */
	getExpandRow = () => {
		return {
			renderer: (row) => this.renderExpandRow(row),
			showExpandColumn: true,
			expandByColumnOnly: true,
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				return <CollapseButton status={isAnyExpands} />
			},
			expandColumnRenderer: ({ expanded, rowKey }) => {
				return <CollapseButton status={expanded} />
			}
		}
	}

	/**
	 * to render Html in row
	 * @param {*} row
	 * @returns
	 */
	renderExpandRow = (row) => {
		const cols = []
		let result
		if (row && !_.isEmpty(row.it_atrib)) {
			row.it_atrib.forEach((atrb, index) => {
				cols.push(
					<Col key={index} className={'p-2'}>
						<b>{`${atrb.descrip}:`}</b> {atrb.des_valor}
					</Col>
				)
			})
			result = <Row className={'container mt-2'}>{cols}</Row>
		}

		return result
	}

	render() {
		const { itemsData, fields, fieldsTable, filterItemCategory, authUser } =
			this.props
		const { currencyFilter, category } = this.state
		const propsTable = {
			remote: true,
			columns: getDefaultColumns(fieldsTable, { authUser }),
			keyField: this.primaryKey,
			data: !_.isEmpty(itemsData) ? itemsData.items : [],
			rowClasses: 'theme.tableRow',
			headerClasses: 'theme.tableHeader',
			paginationOptions: {},
			onTableChange: this.handleChangeTable,
			expandRow: this.getExpandRow()
		}

		return (
			<Fragment>
				<Row>
					<InputDropdown
						inputFormCol={{ sm: 6 }}
						fields={fields}
						label="C"
						inputId="filtro_moneda"
						name="filtro_moneda"
						placeholder=""
						colLabel="col-sm-3"
						colInput="col-sm-9"
						disable={false}
						onBlur={() => {}}
						value={currencyFilter}
						noInitialExecute
						options={getCurrencyOptions(itemsData)}
						onChange={(data) => {
							const { value } = data.target
							this.setState({ currencyFilter: value })
							this.handleGetItems({ filtro_moneda: value, cat_item: category })
						}}
					/>
					<InputDropdown
						inputFormCol={{ sm: 6 }}
						fields={fields}
						label="C"
						inputId="cat_item"
						name="cat_item"
						placeholder=""
						colLabel="col-sm-3"
						colInput="col-sm-9"
						disable={false}
						onBlur={() => {}}
						value={category}
						noInitialExecute
						options={getOptionsDropDown(filterItemCategory)}
						onChange={(data) => {
							const { value } = data.target
							this.setState({ category: value })
							this.handleGetItems({
								cat_item: value,
								filtro_moneda: currencyFilter
							})
						}}
					/>
					<Col className={'col-12'}>
						{fieldsTable.length > 0 && <CommonTable {...propsTable} />}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ configCrud, searchVoucher, auth }) => {
	const { fieldsConfig } = configCrud
	const { itemsData, filterItemCategory } = searchVoucher
	const { authUser } = auth
	return { fieldsConfig, itemsData, authUser, filterItemCategory }
}

const connectForm = connect(mapStateToProps, {
	getDetailVoucherItems,
	getItemCategories
})(SearchVoucherItemsTable)

export default withTranslation()(connectForm)
