import { ISetActiveTabAction, ISetActiveTabParams } from 'models/SearchProduct'
import {
	IGetDetailVoucherHeadboardAction,
	IGetDetailVoucherHeadboardParams,
	IGetDetailVoucherHeadboardResponse,
	IGetDetailVoucherHeadboardSuccessAction,
	IGetDetailVoucherInventoryAction,
	IGetDetailVoucherInventoryParams,
	IGetDetailVoucherItemsAction,
	IGetDetailVoucherItemsParams,
	IGetDetailVoucherItemsResponse,
	IGetDetailVoucherItemsSuccessAction,
	IGetDetailVoucherTreasuryAction,
	IGetDetailVoucherTreasuryParams,
	IGetInventorySerieDataAction,
	IGetInventorySerieDataParams,
	IGetItemCategoriesAction,
	IGetItemCategoriesParams,
	IParamsFilters,
	ISearchVoucherAction,
	ISearchVoucherParams,
	ISearchVoucherResponse,
	ISearchVoucherSuccessAction
} from 'models/SearchVoucher'
import {
	CANCEL_VOUCHER,
	CANCEL_VOUCHER_SUCCESS,
	CLEAR_SEARCH_VOUCHER_PARAMS,
	CLEAR_VOUCHER_SEARCH_STORE,
	GET_DETAIL_INVENTORY_SUMMARY,
	GET_DETAIL_INVENTORY_SUMMARY_SUCCESS,
	GET_DETAIL_TAX_SUMMARY,
	GET_DETAIL_TAX_SUMMARY_SUCCESS,
	GET_DETAIL_TREASURY_SUMMARY,
	GET_DETAIL_TREASURY_SUMMARY_SUCCESS,
	GET_DETAIL_VOUCHER_AFFECTATIONS,
	GET_DETAIL_VOUCHER_AFFECTATIONS_SUCCESS,
	GET_DETAIL_VOUCHER_HEADBOARD,
	GET_DETAIL_VOUCHER_HEADBOARD_SUCCESS,
	GET_DETAIL_VOUCHER_ITEMS,
	GET_DETAIL_VOUCHER_ITEMS_SUCCESS,
	GET_DETAIL_VOUCHER_SEAT,
	GET_DETAIL_VOUCHER_SEAT_SUCCESS,
	GET_DETAIL_VOUCHER_TAXES,
	GET_DETAIL_VOUCHER_TAXES_SUCCESS,
	GET_FILTER_ATTRIBUTES,
	GET_FILTER_ATTRIBUTES_SUCCESS,
	GET_FILTER_BRANCHES,
	GET_FILTER_BRANCHES_SUCCESS,
	GET_FILTER_CURRENCY,
	GET_FILTER_CURRENCY_SUCCESS,
	GET_FILTER_DEPOSITS,
	GET_FILTER_DEPOSITS_SUCCESS,
	GET_FILTER_FUND_ACCOUNT_TYPES,
	GET_FILTER_FUND_ACCOUNT_TYPES_SUCCESS,
	GET_FILTER_MODULES,
	GET_FILTER_MODULES_SUCCESS,
	GET_FILTER_PAYMENT_CONDITIONS,
	GET_FILTER_PAYMENT_CONDITIONS_SUCCESS,
	GET_FILTER_SELLERS,
	GET_FILTER_SELLERS_SUCCESS,
	GET_FILTER_TYPE_ACCOUNTING,
	GET_FILTER_TYPE_ACCOUNTING_SUCCESS,
	GET_FILTER_USERS,
	GET_FILTER_USERS_SUCCESS,
	GET_FILTER_VOUCHERS,
	GET_FILTER_VOUCHERS_SUCCESS,
	GET_FILTER_VOUCHER_CATEGORY,
	GET_FILTER_VOUCHER_CATEGORY_SUCCESS,
	GET_FILTER_VOUCHER_NAMES,
	GET_FILTER_VOUCHER_NAMES_SUCCESS,
	GET_INVENTORY_SERIE_DATA,
	GET_INVENTORY_SERIE_DATA_SUCCESS,
	GET_ITEM_CATEGORIES,
	GET_ITEM_CATEGORIES_SUCCESS,
	GET_ORDER_DIR_OPTIONS,
	GET_ORDER_DIR_OPTIONS_SUCCESS,
	GET_ORDER_OPTIONS,
	GET_ORDER_OPTIONS_SUCCESS,
	PRINT_VOUCHER_DETAIL,
	PRINT_VOUCHER_DETAIL_SUCCESS,
	REMOVE_VOUCHER,
	REMOVE_VOUCHER_SUCCESS,
	SEARCH_VOUCHER,
	SEARCH_VOUCHER_SUCCESS,
	SET_VOUCHER_ACTIVE_TAB
} from '../constants/ActionsTypes'

export const searchVouchers = (
	payload: ISearchVoucherParams
): ISearchVoucherAction => ({
	type: SEARCH_VOUCHER,
	payload
})

export const searchVouchersSuccess = (
	payload: ISearchVoucherResponse
): ISearchVoucherSuccessAction => ({
	type: SEARCH_VOUCHER_SUCCESS,
	payload
})

export const getDetailVoucherHeadboard = (
	payload: IGetDetailVoucherHeadboardParams
): IGetDetailVoucherHeadboardAction => {
	return {
		type: GET_DETAIL_VOUCHER_HEADBOARD,
		payload
	}
}

export const getDetailVoucherHeadboardSuccess = (
	payload: IGetDetailVoucherHeadboardResponse
): IGetDetailVoucherHeadboardSuccessAction => ({
	type: GET_DETAIL_VOUCHER_HEADBOARD_SUCCESS,
	payload
})

export const getDetailVoucherItems = (
	payload: IGetDetailVoucherItemsParams
): IGetDetailVoucherItemsAction => {
	return {
		type: GET_DETAIL_VOUCHER_ITEMS,
		payload
	}
}

export const getDetailVoucherItemsSuccess = (
	payload: IGetDetailVoucherItemsResponse
): IGetDetailVoucherItemsSuccessAction => ({
	type: GET_DETAIL_VOUCHER_ITEMS_SUCCESS,
	payload
})

export const getDetailVoucherTaxes = (payload: any): any => {
	return {
		type: GET_DETAIL_VOUCHER_TAXES,
		payload
	}
}

export const getDetailVoucherTaxesSuccess = (payload: any): any => ({
	type: GET_DETAIL_VOUCHER_TAXES_SUCCESS,
	payload
})

export const getDetailVoucherAffectations = (payload: any): any => {
	return {
		type: GET_DETAIL_VOUCHER_AFFECTATIONS,
		payload
	}
}

export const getDetailVoucherAffectationsSuccess = (payload: any): any => ({
	type: GET_DETAIL_VOUCHER_AFFECTATIONS_SUCCESS,
	payload
})

export const getDetailVoucherSeat = (payload: any): any => {
	return {
		type: GET_DETAIL_VOUCHER_SEAT,
		payload
	}
}

export const getDetailVoucherSeatSuccess = (payload: any): any => ({
	type: GET_DETAIL_VOUCHER_SEAT_SUCCESS,
	payload
})

export const getDetailTaxSummary = (payload: any): any => {
	return {
		type: GET_DETAIL_TAX_SUMMARY,
		payload
	}
}

export const getDetailTaxSummarySuccess = (payload: any): any => ({
	type: GET_DETAIL_TAX_SUMMARY_SUCCESS,
	payload
})

export const getFilterModules = (): any => {
	return {
		type: GET_FILTER_MODULES
	}
}

export const getFilterModulesSuccess = (payload: any): any => ({
	type: GET_FILTER_MODULES_SUCCESS,
	payload
})

export const getFilterVouchers = (payload: any): any => {
	return {
		type: GET_FILTER_VOUCHERS,
		payload
	}
}

export const getFilterVouchersSuccess = (payload: any): any => ({
	type: GET_FILTER_VOUCHERS_SUCCESS,
	payload
})

export const getFilterBranches = (payload?: any): any => {
	return {
		type: GET_FILTER_BRANCHES,
		payload
	}
}

export const getFilterBranchesSuccess = (payload: any): any => ({
	type: GET_FILTER_BRANCHES_SUCCESS,
	payload
})

export const getFilterAttributes = (payload: IParamsFilters): any => {
	return {
		type: GET_FILTER_ATTRIBUTES,
		payload
	}
}

export const getFilterAttributesSuccess = (payload: any): any => ({
	type: GET_FILTER_ATTRIBUTES_SUCCESS,
	payload
})

export const getFilterPaymentConditions = (payload: IParamsFilters): any => {
	return {
		type: GET_FILTER_PAYMENT_CONDITIONS,
		payload
	}
}

export const getFilterPaymentConditionsSuccess = (payload: any): any => ({
	type: GET_FILTER_PAYMENT_CONDITIONS_SUCCESS,
	payload
})

export const getFilterSellers = (): any => {
	return {
		type: GET_FILTER_SELLERS
	}
}

export const getFilterSellersSuccess = (payload: any): any => ({
	type: GET_FILTER_SELLERS_SUCCESS,
	payload
})

export const getFilterCurrency = (): any => {
	return {
		type: GET_FILTER_CURRENCY
	}
}

export const getFilterCurrencySuccess = (payload: any): any => ({
	type: GET_FILTER_CURRENCY_SUCCESS,
	payload
})

export const getFilterFundAccountTypes = (): any => {
	return {
		type: GET_FILTER_FUND_ACCOUNT_TYPES
	}
}

export const getFilterFundAccountTypesSuccess = (payload: any): any => ({
	type: GET_FILTER_FUND_ACCOUNT_TYPES_SUCCESS,
	payload
})

export const getFilterUsers = (): any => {
	return {
		type: GET_FILTER_USERS
	}
}

export const getFilterUsersSuccess = (payload: any): any => ({
	type: GET_FILTER_USERS_SUCCESS,
	payload
})

export const getFilterTypeAccounting = (): any => {
	return {
		type: GET_FILTER_TYPE_ACCOUNTING
	}
}

export const getFilterTypeAccountingSuccess = (payload: any): any => ({
	type: GET_FILTER_TYPE_ACCOUNTING_SUCCESS,
	payload
})

export const getFilterDeposits = (): any => {
	return {
		type: GET_FILTER_DEPOSITS
	}
}

export const getFilterDepositsSuccess = (payload: any): any => ({
	type: GET_FILTER_DEPOSITS_SUCCESS,
	payload
})

export const getOrderOptions = (): any => {
	return {
		type: GET_ORDER_OPTIONS
	}
}

export const getOrderOptionsSuccess = (payload: any): any => ({
	type: GET_ORDER_OPTIONS_SUCCESS,
	payload
})

export const getOrderDirOptions = (): any => {
	return {
		type: GET_ORDER_DIR_OPTIONS
	}
}

export const getOrderDirOptionsSuccess = (payload: any): any => ({
	type: GET_ORDER_DIR_OPTIONS_SUCCESS,
	payload
})

export const clearSearchVoucherStore = (): any => {
	return {
		type: CLEAR_VOUCHER_SEARCH_STORE
	}
}

export const getItemCategories = (
	payload: IGetItemCategoriesParams
): IGetItemCategoriesAction => ({
	type: GET_ITEM_CATEGORIES,
	payload
})

export const getItemCategoriesSuccess = (payload: any): any => ({
	type: GET_ITEM_CATEGORIES_SUCCESS,
	payload
})

export const setVoucherActiveTab = (
	payload: ISetActiveTabParams
): ISetActiveTabAction => ({
	type: SET_VOUCHER_ACTIVE_TAB,
	payload
})

export const clearSearchVoucherParams = (): any => {
	return {
		type: CLEAR_SEARCH_VOUCHER_PARAMS
	}
}
export const cancelVoucher = (
	payload: IGetItemCategoriesParams
): IGetItemCategoriesAction => ({
	type: CANCEL_VOUCHER,
	payload
})

export const cancelVoucherSuccess = (payload: any): any => ({
	type: CANCEL_VOUCHER_SUCCESS,
	payload
})

export const removeVoucher = (
	payload: IGetItemCategoriesParams
): IGetItemCategoriesAction => ({
	type: REMOVE_VOUCHER,
	payload
})

export const removeVoucherSuccess = (payload: any): any => ({
	type: REMOVE_VOUCHER_SUCCESS,
	payload
})

export const getDetailTreasurySummary = (
	payload: IGetDetailVoucherTreasuryParams
): IGetDetailVoucherTreasuryAction => ({
	type: GET_DETAIL_TREASURY_SUMMARY,
	payload
})

export const getDetailTreasurySummarySuccess = (payload: any): any => ({
	type: GET_DETAIL_TREASURY_SUMMARY_SUCCESS,
	payload
})

export const printVoucherDetail = (
	payload: IGetItemCategoriesParams
): IGetItemCategoriesAction => ({
	type: PRINT_VOUCHER_DETAIL,
	payload
})

export const printVoucherDetailSuccess = (payload: any): any => ({
	type: PRINT_VOUCHER_DETAIL_SUCCESS,
	payload
})

export const getDetailInventorySummary = (
	payload: IGetDetailVoucherInventoryParams
): IGetDetailVoucherInventoryAction => ({
	type: GET_DETAIL_INVENTORY_SUMMARY,
	payload
})

export const getDetailInventorySummarySuccess = (payload: any): any => ({
	type: GET_DETAIL_INVENTORY_SUMMARY_SUCCESS,
	payload
})

export const getInventorySerieData = (
	payload: IGetInventorySerieDataParams
): IGetInventorySerieDataAction => ({
	type: GET_INVENTORY_SERIE_DATA,
	payload
})

export const getInventorySerieDataSuccess = (payload: any): any => ({
	type: GET_INVENTORY_SERIE_DATA_SUCCESS,
	payload
})

export const getFilterVoucherCategory = (payload: any) => {
	return {
		type: GET_FILTER_VOUCHER_CATEGORY,
		payload
	}
}

export const getFilterVoucherCategorySuccess = (payload: any) => {
	return {
		type: GET_FILTER_VOUCHER_CATEGORY_SUCCESS,
		payload
	}
}

export const getFilterVoucherNames = (payload: any) => {
	return {
		type: GET_FILTER_VOUCHER_NAMES,
		payload
	}
}

export const getFilterVoucherNamesSuccess = (payload: any) => {
	return {
		type: GET_FILTER_VOUCHER_NAMES_SUCCESS,
		payload
	}
}
