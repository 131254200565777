import {
	GET_CONFIG_SHOPPING_CART,
	GET_CONFIG_SHOPPING_CART_SUCCESS
} from 'constants/ActionsTypes'

const initialState = {
	configCart: {}
}

export interface valueState {
	configCart: any
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case GET_CONFIG_SHOPPING_CART:
			return { ...state, configCart: {} }
		case GET_CONFIG_SHOPPING_CART_SUCCESS:
			return { ...state, configCart: action.payload }

		default:
			return state
	}
}

export default rootReducer
