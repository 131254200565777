import {
	salesAffectedCantSuccess,
	salesAffectedConfirmSuccess,
	salesAffectedImportConfirmSuccess,
	salesAffectedImportSuccess,
	salesAffectedImportValidateSuccess,
	salesAffectedStateConfirmSuccess,
	salesAffectedStateSuccess,
	salesAffectedStateValidateSuccess,
	salesAffectedSubCalculationSuccess,
	salesAffectedValidateSuccess,
	validateSalesAffectedDiscountSuccess,
	validateSalesAffectedPriceSuccess
} from 'actions'
import {
	salesAffectedCant,
	salesAffectedConfirm,
	salesAffectedImport,
	salesAffectedImportConfirm,
	salesAffectedImportValidate,
	salesAffectedState,
	salesAffectedStateConfirm,
	salesAffectedStateValidate,
	salesAffectedSubCalculation,
	salesAffectedValidate,
	validateSalesAffectedDiscountApi,
	validateSalesAffectedPriceApi
} from 'api/SalesAffected'
import {
	SALES_AFFECTED_CONFIRM,
	SALES_AFFECTED_IMPORT,
	SALES_AFFECTED_IMPORT_CONFIRM,
	SALES_AFFECTED_IMPORT_VALIDATE,
	SALES_AFFECTED_QUANTITY,
	SALES_AFFECTED_STATE,
	SALES_AFFECTED_STATE_CONFIRM,
	SALES_AFFECTED_STATE_VALIDATE,
	SALES_AFFECTED_SUB_CALCULATION,
	SALES_AFFECTED_VALIDATE,
	VALIDATE_SALES_AFFECTED_DISCOUNT,
	VALIDATE_SALES_AFFECTED_PRICE
} from 'constants/ActionsTypes'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

function* salesAffectedValidateRequest({ payload }) {
	try {
		const items = yield call(salesAffectedValidate, payload)
		yield put(salesAffectedValidateSuccess(items))
	} catch (error) {}
}

function* salesAffectedImportValidateRequest({ payload }) {
	try {
		const items = yield call(salesAffectedImportValidate, payload)
		yield put(salesAffectedImportValidateSuccess(items))
	} catch (error) {}
}

function* salesAffectedCantRequest({ payload }) {
	try {
		const products = yield call(salesAffectedCant, payload)
		yield put(salesAffectedCantSuccess(products))
	} catch (error) {}
}

function* salesAffectedSubCalculationRequest({ payload }) {
	try {
		const subCalculations = yield call(salesAffectedSubCalculation, payload)
		yield put(salesAffectedSubCalculationSuccess(subCalculations))
	} catch (error) {}
}

function* salesAffectedConfirmRequest({ payload }) {
	const { items, callBackReturn } = payload
	try {
		const salesconfirm = yield call(salesAffectedConfirm, items)
		yield put(salesAffectedConfirmSuccess(salesconfirm))
		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error) {}
}

function* salesAffectedImportConfirmRequest({ payload }) {
	const { items, callBackReturn } = payload

	try {
		const salesconfirm = yield call(salesAffectedImportConfirm, items)
		yield put(salesAffectedImportConfirmSuccess(salesconfirm))
		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error) {}
}

function* salesAffectedImportRequest({ payload }) {
	try {
		const items = yield call(salesAffectedImport, payload)
		yield put(salesAffectedImportSuccess(items))
	} catch (error) {}
}

function* salesAffectedStateRequest({ payload }) {
	try {
		const items = yield call(salesAffectedState, payload)
		yield put(salesAffectedStateSuccess(items))
	} catch (error) {}
}

function* salesAffectedStateValidateRequest({ payload }) {
	try {
		const itemsValidate = yield call(salesAffectedStateValidate, payload)
		yield put(salesAffectedStateValidateSuccess(itemsValidate))
	} catch (error) {}
}

function* salesAffectedStateConfirmRequest({ payload }) {
	const { states, callBackReturn } = payload

	try {
		const confirmState = yield call(salesAffectedStateConfirm, states)
		yield put(salesAffectedStateConfirmSuccess(confirmState))

		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error) {}
}

function* validateSalesAffectedDiscountRequest({ payload }) {
	try {
		const itemsValidate = yield call(validateSalesAffectedDiscountApi, payload)
		yield put(validateSalesAffectedDiscountSuccess(itemsValidate))
	} catch (error) {}
}

function* validateSalesAffectedPriceRequest({ payload }) {
	try {
		const itemsValidate = yield call(validateSalesAffectedPriceApi, payload)
		yield put(validateSalesAffectedPriceSuccess(itemsValidate))
	} catch (error) {}
}

export function* salesAffectedValidateSaga() {
	yield takeLatest(SALES_AFFECTED_VALIDATE, salesAffectedValidateRequest)
}

export function* salesAffectedImportValidateSaga() {
	yield takeLatest(
		SALES_AFFECTED_IMPORT_VALIDATE,
		salesAffectedImportValidateRequest
	)
}

export function* salesAffectedCantSaga() {
	yield takeLatest(SALES_AFFECTED_QUANTITY, salesAffectedCantRequest)
}

export function* salesAffectedSubCalculationSaga() {
	yield takeLatest(
		SALES_AFFECTED_SUB_CALCULATION,
		salesAffectedSubCalculationRequest
	)
}

export function* salesAffectedConfirmSaga() {
	yield takeLatest(SALES_AFFECTED_CONFIRM, salesAffectedConfirmRequest)
}

export function* salesAffectedImportConfirmSaga() {
	yield takeLatest(
		SALES_AFFECTED_IMPORT_CONFIRM,
		salesAffectedImportConfirmRequest
	)
}

export function* salesAffectedImportSaga() {
	yield takeLatest(SALES_AFFECTED_IMPORT, salesAffectedImportRequest)
}

export function* salesAffectedStateSaga() {
	yield takeLatest(SALES_AFFECTED_STATE, salesAffectedStateRequest)
}

export function* salesAffectedStateValidateSaga() {
	yield takeLatest(
		SALES_AFFECTED_STATE_VALIDATE,
		salesAffectedStateValidateRequest
	)
}
export function* salesAffectedStateConfirmSaga() {
	yield takeLatest(
		SALES_AFFECTED_STATE_CONFIRM,
		salesAffectedStateConfirmRequest
	)
}

export function* validateSalesAffectedDiscountSaga() {
	yield takeLatest(
		VALIDATE_SALES_AFFECTED_DISCOUNT,
		validateSalesAffectedDiscountRequest
	)
}

export function* validateSalesAffectedPriceSaga() {
	yield takeLatest(
		VALIDATE_SALES_AFFECTED_PRICE,
		validateSalesAffectedPriceRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(salesAffectedValidateSaga),
		fork(salesAffectedCantSaga),
		fork(salesAffectedSubCalculationSaga),
		fork(salesAffectedConfirmSaga),
		fork(salesAffectedImportSaga),
		fork(salesAffectedImportValidateSaga),
		fork(salesAffectedImportConfirmSaga),
		fork(salesAffectedStateSaga),
		fork(salesAffectedStateValidateSaga),
		fork(salesAffectedStateConfirmSaga),
		fork(validateSalesAffectedDiscountSaga),
		fork(validateSalesAffectedPriceSaga)
	])
}
