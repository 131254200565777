import AccountingVoucherTable from 'components/accountingVoucher/AccountingVoucherTable'
import withMenu from 'components/common/withMenu'
import GlobalContainer from 'components/layout/globalContainer'
import { P_IMP_COMPROB } from 'constants/ConfigProcessNames'
import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

class AccountingSeat extends PureComponent {
	handleCallBackButton = () => {
		this.accountingConfirmation()
	}

	render() {
		return (
			<Row>
				<GlobalContainer
					codeProcess={P_IMP_COMPROB}
					nextPage
					callBackButton={this.handleCallBackButton}
				>
					<AccountingVoucherTable
						formConfirmation={(click) => (this.accountingConfirmation = click)}
					/>
				</GlobalContainer>
			</Row>
		)
	}
}

export default withTranslation()(withRouter(withMenu(AccountingSeat)))
