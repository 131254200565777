import {
	APPLY_PRODUCT_STOCK_PROVIDER,
	APPLY_PRODUCT_STOCK_PROVIDER_ERROR,
	APPLY_PRODUCT_STOCK_PROVIDER_SUCCESS,
	CANCEL_PRODUCT_STOCK_PROVIDER,
	CANCEL_PRODUCT_STOCK_PROVIDER_ERROR,
	CANCEL_PRODUCT_STOCK_PROVIDER_SUCCESS,
	CLEAR_STORE_STOCK_PROVIDER,
	CONFIRM_PRODUCT_STOCK_PROVIDER,
	CONFIRM_PRODUCT_STOCK_PROVIDER_ERROR,
	CONFIRM_PRODUCT_STOCK_PROVIDER_SUCCESS,
	GET_PRODUCT_STOCK_PROVIDER,
	GET_PRODUCT_STOCK_PROVIDER_SUCCESS,
	LOAD_PRODUCT_STOCK_PROVIDER,
	LOAD_PRODUCT_STOCK_PROVIDER_ERROR,
	LOAD_PRODUCT_STOCK_PROVIDER_SUCCESS,
	SEARCH_BARCODE_PROVIDER,
	SEARCH_BARCODE_PROVIDER_ERROR,
	SEARCH_BARCODE_PROVIDER_SUCCESS,
	VALIDATE_PRODUCT_STOCK_PROVIDER,
	VALIDATE_PRODUCT_STOCK_PROVIDER_ERROR,
	VALIDATE_PRODUCT_STOCK_PROVIDER_SUCCESS
} from '../constants/ActionsTypes'

export const getProductStockProvider = (params) => {
	return {
		type: GET_PRODUCT_STOCK_PROVIDER,
		payload: params
	}
}

export const getProductStockProviderSuccess = (response) => {
	return {
		type: GET_PRODUCT_STOCK_PROVIDER_SUCCESS,
		payload: response
	}
}

export const validateProductStockProvider = (params) => {
	return {
		type: VALIDATE_PRODUCT_STOCK_PROVIDER,
		payload: params
	}
}

export const validateProductStockProviderSuccess = (response) => {
	return {
		type: VALIDATE_PRODUCT_STOCK_PROVIDER_SUCCESS,
		payload: response
	}
}

export const validateProductStockProviderError = (error) => {
	return {
		type: VALIDATE_PRODUCT_STOCK_PROVIDER_ERROR,
		payload: error
	}
}

export const searchBarcodeProvider = (params) => {
	return {
		type: SEARCH_BARCODE_PROVIDER,
		payload: params
	}
}

export const searchBarcodeProviderSuccess = (response) => {
	return {
		type: SEARCH_BARCODE_PROVIDER_SUCCESS,
		payload: response
	}
}

export const searchBarcodeProviderError = (error) => {
	return {
		type: SEARCH_BARCODE_PROVIDER_ERROR,
		payload: error
	}
}

export const confirmProductStockProvider = (params) => {
	return {
		type: CONFIRM_PRODUCT_STOCK_PROVIDER,
		payload: params
	}
}

export const confirmProductStockProviderSuccess = (response) => {
	return {
		type: CONFIRM_PRODUCT_STOCK_PROVIDER_SUCCESS,
		payload: response
	}
}

export const confirmProductStockProviderError = (error) => {
	return {
		type: CONFIRM_PRODUCT_STOCK_PROVIDER_ERROR,
		payload: error
	}
}

export const applyProductStockProvider = (params) => {
	return {
		type: APPLY_PRODUCT_STOCK_PROVIDER,
		payload: params
	}
}

export const applyProductStockProviderSuccess = (response) => {
	return {
		type: APPLY_PRODUCT_STOCK_PROVIDER_SUCCESS,
		payload: response
	}
}

export const applyProductStockProviderError = (response) => {
	return {
		type: APPLY_PRODUCT_STOCK_PROVIDER_ERROR,
		payload: response
	}
}

export const loadProductStockProvider = (params) => {
	return {
		type: LOAD_PRODUCT_STOCK_PROVIDER,
		payload: params
	}
}

export const loadProductStockProviderSuccess = (response) => {
	return {
		type: LOAD_PRODUCT_STOCK_PROVIDER_SUCCESS,
		payload: response
	}
}

export const loadProductStockProviderError = (response) => {
	return {
		type: LOAD_PRODUCT_STOCK_PROVIDER_ERROR,
		payload: response
	}
}

export const cancelProductStockProvider = (params) => {
	return {
		type: CANCEL_PRODUCT_STOCK_PROVIDER,
		payload: params
	}
}

export const cancelProductStockProviderSuccess = (response) => {
	return {
		type: CANCEL_PRODUCT_STOCK_PROVIDER_SUCCESS,
		payload: response
	}
}

export const cancelProductStockProviderError = (error) => {
	return {
		type: CANCEL_PRODUCT_STOCK_PROVIDER_ERROR,
		payload: error
	}
}

export const clearStoreStockProvider = () => {
	return {
		type: CLEAR_STORE_STOCK_PROVIDER
	}
}
