import CommonLabelCol from 'components/common/CommonLabelCol'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import React, { Component, Fragment } from 'react'
import { Row } from 'react-bootstrap'

interface IMainDataFieldsProps {
	fields: IFieldStructure[]
	detailMainData?: any
}

class MainDataFields extends Component<IMainDataFieldsProps> {
	render() {
		const { fields, detailMainData } = this.props

		return (
			<Fragment>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="descrip1"
						value={`${detailMainData.descrip1}` || ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="descrip2"
						value={detailMainData.descrip2 ? `${detailMainData.descrip2}` : ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="categ_prod"
						value={
							detailMainData.categ_prod ? `${detailMainData.categ_prod}` : ''
						}
					/>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="codbarra"
						value={detailMainData.codbarra ? `${detailMainData.codbarra}` : ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="cod_prod"
						value={detailMainData.cod_prod ? `${detailMainData.cod_prod}` : ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="cod_abrev"
						value={
							detailMainData.cod_abrev ? `${detailMainData.cod_abrev}` : ''
						}
					/>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="abrev"
						value={detailMainData.abrev ? `${detailMainData.abrev}` : ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="stock_disp_suc"
						value={`${detailMainData.stock_disp_suc}` || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="stock_disp_tot"
						value={`${detailMainData.stock_disp_tot}` || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="unid_c"
						value={detailMainData.unid_c ? `${detailMainData.unid_c}` : ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="precio"
						value={`${detailMainData.precio}` || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="mon_v"
						value={`${detailMainData.mon_v}` || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="base_v"
						value={detailMainData.base_v ? `${detailMainData.base_v}` : ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="unid_v"
						value={detailMainData.unid_v ? `${detailMainData.unid_v}` : ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="costo"
						value={`${detailMainData.costo}` || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="mon_c"
						value={`${detailMainData.mon_c}` || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="base_c"
						value={detailMainData.base_c ? `${detailMainData.base_c}` : ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="unid_c"
						value={detailMainData.unid_c ? `${detailMainData.unid_c}` : ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="costo_uc"
						value={`${detailMainData.costo_uc}` || ''}
					/>
				</Row>
			</Fragment>
		)
	}
}

export default MainDataFields
