import { getDetailTaxSummary } from 'actions'
import CommonTable from 'components/common/commonTable'
import { getDefaultColumns } from 'lib/FormUtils'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import InputDropdown from '../form/inputDropdown'
class SearchVoucherResumenTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			taxId: '',
			taxSummaryTable: []
		}
		this.primaryKey = 'it'
	}

	componentDidMount = () => {
		const { id, authUser } = this.props
		if (id) {
			this.props.getDetailTaxSummary({
				id,
				page_size: 10,
				page_number: 1,
				idEmpresa: authUser.userCompany
			})
		}
	}

	handleChangeTable = () => {}

	/**
	 * use when change select item
	 */
	handleChangeSelect = (taxId) => {
		const { taxSummary } = this.props
		const tax = _.findWhere(taxSummary.impuestos, { nro: taxId })
		this.setState({
			taxSummaryTable: tax.items,
			taxInfo: tax
		})
	}

	render() {
		const { taxSummary, fields, fieldsTable, authUser } = this.props
		const { taxId, taxSummaryTable } = this.state
		const propsTable = {
			remote: true,
			columns: _.isEmpty(fieldsTable)
				? []
				: getDefaultColumns(fieldsTable, { authUser }),
			keyField: this.primaryKey,
			data: taxSummaryTable,
			rowClasses: 'theme.tableRow',
			headerClasses: 'theme.tableHeader',
			paginationOptions: {},
			onTableChange: this.handleChangeTable
		}

		return (
			<Fragment>
				<Row>
					<Col sm={12}>
						<Row>
							<Col sm={6}>
								<InputDropdown
									inputFormCol={{ sm: 12 }}
									fields={fields}
									label="fil"
									inputId="filtro_imp"
									name="filtro_imp"
									placeholder=""
									colLabel="col-sm-3"
									colInput="col-sm-9"
									disable={false}
									onBlur={() => {}}
									value={taxId}
									options={
										taxSummary && _.isEmpty(taxSummary.impuestos)
											? []
											: taxSummary.impuestos.map((tax) => ({
													id: tax.nro,
													label: `${tax.nro} - ${tax.descrip_impu}`
											  }))
									}
									onChange={(data) => {
										const taxId = parseInt(data.target.value)
										this.handleChangeSelect(taxId)
										this.setState({ taxId })
									}}
								/>
							</Col>
						</Row>
					</Col>
					<Col className={'col-12'}>
						{!_.isEmpty(fieldsTable) && <CommonTable {...propsTable} />}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ configCrud, searchVoucher, auth }) => {
	const { fieldsConfig } = configCrud
	const { seatData, taxSummary } = searchVoucher
	const { authUser } = auth
	return { fieldsConfig, seatData, taxSummary, authUser }
}

const connectForm = connect(mapStateToProps, { getDetailTaxSummary })(
	SearchVoucherResumenTable
)

export default withTranslation()(connectForm)
