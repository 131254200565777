import { getVoucherTaxes, taxesConfirm } from 'actions'
import { P_IMP_COMPROB } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styles from './AccountingVoucherTable.module.scss'
import TaxesTable from './TaxesTable'

class AccountingSeatTable extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount = () => {
		const { idOperacion } = this.props

		if (idOperacion) {
			this.props.getVoucherTaxes({ idOperacion, page_size: 10, page_number: 1 })
		}
	}

	onChangeTable = (type, pagination) => {
		return true
		// this.props.getVoucherTaxes({ ComprobAvencer, OpcionMuestra, idOperacion, page_number: pagination.page, page_size: pagination.sizePerPage });
	}

	componentDidUpdate = (prevProps) => {
		const { stateValidate, salesconfirm } = this.props

		if (prevProps.stateValidate !== stateValidate && stateValidate) {
			this.setState({
				total_item: stateValidate.total_item,
				total_cant: stateValidate.total_cant,
				total_importe: stateValidate.total_importe
			})
		}

		if (
			prevProps.salesconfirm !== salesconfirm &&
			!prevProps.salesconfirm &&
			salesconfirm
		) {
			this.setState({
				total_item: salesconfirm.total_item,
				total_cant: salesconfirm.total_cant,
				total_importe: salesconfirm.total_importe
			})
		}
	}

	render() {
		const {
			search,
			readOnly,
			config,
			formConfirmation,
			theme,
			callBackReturn
		} = this.props
		return (
			<Row className={theme.containerTable}>
				<Col sm={12} className={'pb-2 pl-0'}>
					{search && (
						<TaxesTable
							taxes={search}
							readOnly={readOnly}
							config={config}
							formConfirmation={formConfirmation}
							idOperacion={this.props.idOperacion}
							handleChangeTable={this.onChangeTable}
							callBackReturn={callBackReturn}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = ({ vouchertype, voucherTaxes, voucher }) => {
	const config = voucher.config ? voucher.config[P_IMP_COMPROB] : null
	const { voucherType } = vouchertype
	const { search } = voucherTaxes
	return { voucherType, search, config }
}

const connectForm = connect(mapStateToProps, {
	getVoucherTaxes,
	taxesConfirm
})(AccountingSeatTable)

export default themr('TaxesTableStyles', styles)(withTranslation()(connectForm))
