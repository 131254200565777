import React, { PureComponent } from 'react'

function inputInitFocus(CurrentComponent) {
	class inputInitFocus extends PureComponent {
		constructor(props) {
			super(props)
			const _this = this

			CurrentComponent.prototype.getRefFirstInput = function () {
				return _this.getRefFirstInput()
			}

			CurrentComponent.prototype.setRefFirstInput = function (ref) {
				return _this.setRefFirstInput(ref)
			}

			this.state = {
				firstRef: null,
				onlyset: true
			}
		}

		componentDidUpdate = (prevProps) => {
			const { config } = this.props
			const { firstRef, onlyset } = this.state
			if (config && firstRef && onlyset) {
				if (firstRef.current && firstRef.current.element) {
					firstRef.current.element.focus()
				} else if (firstRef.current) {
					firstRef.current.focus()
				}
				this.setState({ onlyset: false })
			}
		}

		getRefFirstInput() {
			const firstRef = React.createRef()
			this.setState({ firstRef })
			return firstRef
		}

		setRefFirstInput(ref) {
			this.setState({ firstRef: ref })
		}

		render() {
			return <CurrentComponent {...this.props} />
		}
	}

	return inputInitFocus
}

export default inputInitFocus
