import AudioComponent from 'components/common/audioComponent'
import BarcodeInputText from 'components/form/barcodeInputText'
import InputButton from 'components/form/inputButton'
import InputText from 'components/form/inputText'
import { SEARCH_BARCODE_ERROR } from 'constants/ActionsTypes'
import { addPrevValidations } from 'lib/AxiosInterceptors'
import { debugMessage } from 'lib/Utils'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './stockPopup.scss'

const fields = [
	{
		idCampo: 'barCode',
		descrip: '',
		label: 'Código barra',
		mascara: '',
		editable: 1,
		visible: 1,
		requerido: 0,
		valid: null
	},
	{
		idCampo: 'number_from',
		descrip: '',
		label: 'Desde',
		mascara: '',
		editable: 1,
		visible: 1,
		requerido: 0,
		valid: null
	},
	{
		idCampo: 'number_quantity',
		descrip: '',
		label: 'Cantidad',
		mascara: '',
		editable: 1,
		visible: 1,
		requerido: 0,
		valid: null
	}
]
export default class StockInputs extends Component {
	constructor(props) {
		super(props)
		this.state = {
			serieFrom: '',
			serieCant: 0,
			barCode: ''
		}
		this.firstRefInput = React.createRef()
		addPrevValidations((e) => {
			debugMessage(
				e.request.responseURL.includes('busq_nserie'),
				'validacion del error'
			)
			if (e.request.responseURL.includes('busq_nserie')) {
				return true
			}
			return false
		})
	}

	handleValidate = () => {
		const { idOperacion, referenceItem } = this.props
		const { serieFrom, serieCant } = this.state
		this.props.validateProductStock({
			idOperacion,
			it: referenceItem,
			serie_desde: serieFrom,
			serie_cant: serieCant
		})
	}

	handleSearchBarcode = () => {
		const { idOperacion, params, referenceItem } = this.props
		const { barCode } = this.state

		this.props.searchBarcode({
			idOperacion,
			it: referenceItem,
			niprod: params.Niprod,
			codbarra: barCode
		})
	}

	componentDidMount = () => {
		if (this.firstRefInput.current) {
			this.firstRefInput.current.focus()
		}
	}

	componentDidUpdate = (prevProps) => {
		const { searchItems } = this.props

		if (prevProps.searchItems !== searchItems && searchItems) {
			let params = {}
			if (searchItems.error === SEARCH_BARCODE_ERROR) {
				params = {
					showError: true,
					errorMessage: `${searchItems.errorMessage}  Código: ${this.state.barCode}`,
					type: 'danger'
				}
				this.handleSoundError()
			} else {
				params = {
					showError: false,
					type: 'success'
				}
				this.handleSoundSuccess()
			}
			this.setState({ barCode: '' })
			this.props.handleSetErrorBarCode(params)
		}
	}

	handleSoundSuccess = async () => {
		this.soundSuccess()
	}

	handleSoundError = async () => {
		this.soundError()
	}

	render() {
		const { t } = this.props
		return (
			<Container className={` mt-2 containerDisplay`}>
				<Row>
					<AudioComponent
						handleSuccess={(click) => (this.soundSuccess = click)}
						handleError={(click) => (this.soundError = click)}
					/>
					<BarcodeInputText
						fwRef={this.firstRefInput}
						inputFormCol={{ sm: 9 }}
						fields={fields}
						label={'label'}
						inputId={'barCode'}
						name={'barCode'}
						placeholder={''}
						styles={{ width: '100%' }}
						colLabel={'col-sm-2'}
						colInput={'col-sm-10'}
						onBlur={() => {}}
						handleEnterKey={() => this.handleSearchBarcode()}
						value={this.state.barCode}
						onChange={(data) => this.setState({ barCode: data })}
						activeListeners
					/>
					<Col sm={11} className={'contentDisplay'}>
						<Row>
							<Col sm={12}>
								<label className={'subtitle'}>
									{t('Stock.continuous_number')}
								</label>
							</Col>
							<InputText
								inputFormCol={{ sm: 6 }}
								fields={fields}
								label={'label'}
								inputId={'number_from'}
								name={'number_from'}
								placeholder={''}
								styles={{ width: '100%' }}
								colLabel={'col-sm-2'}
								colInput={'col-sm-10'}
								onBlur={() => {}}
								disable={true}
								value={this.state.serieFrom}
								onChange={(data) => {
									this.setState({ serieFrom: data })
								}}
								divStyle={{}}
							/>
							<InputText
								inputFormCol={{ sm: 4 }}
								fields={fields}
								label={'label'}
								inputId={'number_quantity'}
								name={'number_quantity'}
								placeholder={''}
								styles={{ width: '100%' }}
								colLabel={'col-sm-3'}
								colInput={'col-sm-9'}
								disable={true}
								onBlur={() => {}}
								value={this.state.serieCant}
								onChange={(data) => {
									this.setState({ serieCant: data })
								}}
								handleEnterKey={() => this.handleValidate()}
								divStyle={{}}
							/>
							<Col sm={2} className={'text-right mb-1'}>
								<InputButton
									valueButton={t('Stock.buttons.apply')}
									type={'primary'}
									onClick={() => this.handleValidate()}
									customStyle={{ minWidth: '125px' }}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		)
	}
}
