import NotificationError from 'components/common/notificationsErrors'
import InputText from 'components/form/inputText'
import { Formik } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { setInputFocus } from 'lib/Utils'
import React, { Component, Fragment } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'

export default class BarCodeErrorForm extends Component {
	constructor(props) {
		super(props)
		const { requireFields } = props

		this.loteInputRef = React.createRef()
		this.buttonSubmitRef = React.createRef()
		this.serieInputRef = React.createRef()
		this.state = {
			configFields: [
				{
					idCampo: 'fec_vto',
					descripcion: 'Fecha Vencimiento',
					label: 'Fecha Vencimiento',
					editable: 1,
					visible: 1,
					valid: 'FEC>=H',
					mascara: 'FechaLarga',
					requerido:
						requireFields && requireFields.ind_lote ? requireFields.ind_lote : 0
				},
				{
					idCampo: 'lote',
					fwRef: this.loteInputRef,
					descripcion: 'Lote',
					label: 'Lote',
					editable: 1,
					visible: 1,
					mascara: '',
					requerido:
						requireFields && requireFields.ind_vto ? requireFields.ind_vto : 0
				},
				{
					idCampo: 'nserie',
					fwRef: this.serieInputRef,
					descripcion: 'Serie',
					label: 'Serie',
					editable: 1,
					visible: 1,
					mascara: '',
					requerido:
						requireFields && requireFields.ind_nserie
							? requireFields.ind_nserie
							: 0
				}
			]
		}
	}

	componentDidMount() {
		if (this.loteInputRef.current) {
			setTimeout(() => {
				// Timer to set focus in the first field.
				setInputFocus(this.loteInputRef)
			}, 1)
		}
	}

	render() {
		const { t } = this.props
		const { configFields } = this.state
		const validationSchema = getValidationSchema(configFields, t, [])
		const initialValues = getInitValues(configFields)

		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values, actions) => {
					this.props.handleSubmitForm(values)
				}}
				validationSchema={validationSchema}
				enableReinitialize={true}
				render={(props) => {
					const {
						handleSubmit,
						errors,
						touched,
						handleBlur,
						handleChange,
						values,
						setFieldValue
					} = props
					return (
						<Fragment>
							<Col sm={12}>
								<NotificationError errors={errors} touched={touched} />
							</Col>
							<Form onSubmit={handleSubmit} className="voucher-info-form">
								<Row>
									<InputText
										fwRef={this.loteInputRef}
										inputFormCol={{ sm: 12 }}
										fields={configFields}
										label={true}
										inputId={'lote'}
										name={'lote'}
										placeholder={''}
										styles={{ width: '100%', border: '1px solid #ccc' }}
										colLabel={'col-sm-4'}
										colInput={'col-sm-8'}
										value={values.lote}
										onBlur={handleBlur}
										onChange={(data) => {
											setFieldValue('lote', data)
											handleChange()
										}}
									/>
									<InputText
										fwRef={this.serieInputRef}
										inputFormCol={{ sm: 12 }}
										fields={configFields}
										label={true}
										inputId={'nserie'}
										name={'nserie'}
										placeholder={''}
										styles={{ width: '100%', border: '1px solid #ccc' }}
										colLabel={'col-sm-4'}
										colInput={'col-sm-8'}
										value={values.nserie}
										onBlur={handleBlur}
										onChange={(data) => {
											setFieldValue('nserie', data)
											handleChange()
										}}
									/>
									<InputText
										inputFormCol={{ sm: 12 }}
										fields={configFields}
										label={true}
										inputId={'fec_vto'}
										name={'fec_vto'}
										placeholder={''}
										styles={{ width: '100%', border: '1px solid #ccc' }}
										colLabel={'col-sm-4'}
										colInput={'col-sm-8'}
										value={values.fec_vto}
										onBlur={handleBlur}
										handleEnterKey={(e, value) => {
											e.preventDefault()
											setFieldValue('fec_vto', value || '')
											setInputFocus(this.buttonSubmitRef)
											return true
										}}
										onChange={(data, event) => {
											if (
												event !== undefined &&
												event.nativeEvent.type === 'click'
											) {
												// To validate event click of calendar.
												setFieldValue('fec_vto', data || '')
												setInputFocus(this.buttonSubmitRef)
												handleChange()
											}
										}}
									/>
								</Row>
								<Row className={'pt-3'}>
									<Col sm={6} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px' }}
											type={'submit'}
											ref={this.buttonSubmitRef}
										>
											{t('Collection.form.buttons.accept')}
										</Button>
									</Col>
									<Col sm={6} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px' }}
											type={'button'}
											onClick={this.props.handleCloseModal}
										>
											{t('Collection.form.buttons.cancel')}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					)
				}}
			/>
		)
	}
}
