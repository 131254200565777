import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { advancedProductSearch } from 'actions'
import CommonTable from 'components/common/commonTable'
import NotificationMessage from 'components/common/notificationMessage'
import React, { Component, Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'underscore'
import { SEARCHED_PRODUCT_DETAILS } from 'utils/RoutePath'

class SearchProductsTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showDetailModal: false,
			showError: false,
			errorMessage: '',
			typeError: 'success'
		}
		this.primaryKey = 'nro'
	}

	componentDidUpdate = (prevProps) => {
		const { productList } = this.props
		if (prevProps.productList !== productList && productList) {
			let typeError = ''
			if (_.isEmpty(productList.productos)) {
				typeError = 'danger'
			} else {
				typeError = 'success'
			}
			this.setState({
				showError: true,
				errorMessage: productList.Resultado.Mens_error,
				typeError
			})
		}
	}

	getColumns = () => {
		const { fields } = this.props

		const rows = fields.map((field) => {
			const idField = field.idCampo.trim()
			const hide = field.visible
			return {
				dataField: idField,
				text: field.label,
				align: 'center',
				headerAlign: 'center',
				hidden: !hide
			}
		})

		rows.push({
			dataField: 'actions',
			text: '',
			align: 'center',
			headerAlign: 'center',
			headerStyle: { width: '5%' },
			formatter: (cell, row) => {
				return (
					<Link to={`${SEARCHED_PRODUCT_DETAILS}/${row.niprod}`}>
						<FontAwesomeIcon
							className={'mr-3'}
							icon={faEye}
							style={{ cursor: 'pointer' }}
							color={'#007bff'}
						/>
					</Link>
				)
			}
		})

		return rows
	}

	/**
	 * to handle pagination
	 * @param {*} type
	 * @param {*} pagination
	 */
	handleChangeTable = (type, pagination) => {
		const { paramsSearch } = this.props

		if (type === 'pagination') {
			this.props.advancedProductSearch({
				...paramsSearch,
				page_number: pagination.page,
				page_size: pagination.sizePerPage
			})
		}
	}

	/**
	 * to close alert message
	 */
	handleCloseError = () => {
		this.setState({ showError: false })
	}

	render() {
		const { productList, fields } = this.props
		const { showError, errorMessage, typeError } = this.state
		const paginationOptions = productList
			? {
					pageStartIndex: 1,
					sizePerPage: productList.page_size,
					page: productList.page_number,
					totalSize: productList.total_count
			  }
			: {}

		const propsTable = {
			remote: true,
			columns: fields ? this.getColumns() : [],
			keyField: this.primaryKey,
			data: productList ? productList.productos : [],
			rowClasses: 'theme.tableRow',
			headerClasses: 'theme.tableHeader',
			paginationOptions,
			onTableChange: this.handleChangeTable,
			pagination: !_.isEmpty(productList)
		}

		return (
			<Fragment>
				<div className="pt-2">
					<NotificationMessage
						showError={showError}
						errorMessage={errorMessage}
						handleCloseError={this.handleCloseError}
						type={typeError}
					/>
				</div>
				<Col className={`col-12 pl-0 pr-0 pt-3`}>
					{fields && <CommonTable {...propsTable} />}
				</Col>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ product }) => {
	const { productList, paramsSearch } = product

	return { productList, paramsSearch }
}

export default connect(mapStateToProps, { advancedProductSearch })(
	SearchProductsTable
)
