import { getOrderDirOptions, getPeriodFilter, getSuggestedData } from 'actions'
import CommonTable from 'components/common/commonTable'
import { getDefaultColumns } from 'lib/FormUtils'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'

class DetailSuggestedData extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount = () => {
		const { productId, authUser } = this.props
		this.props.getSuggestedData({
			page_number: 1,
			page_size: 10,
			idEmpresa: authUser.userCompany,
			id: productId
		})
	}

	render() {
		const { fieldsTable, detailSuggested, authUser } = this.props

		return (
			<Row>
				<Col sm={12}>
					{!_.isEmpty(fieldsTable) && (
						<CommonTable
							pagination={false}
							keyField="nro"
							columns={getDefaultColumns(fieldsTable, { authUser })}
							data={
								!_.isEmpty(detailSuggested) && detailSuggested.Items
									? detailSuggested.Items
									: []
							}
							paginationOptions={{}}
							onTableChange={this.handleChangeTable}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = ({ auth, searchProducts }) => {
	const { authUser } = auth
	const { detailSuggested } = searchProducts
	return {
		authUser,
		detailSuggested
	}
}
const connectForm = connect(mapStateToProps, {
	getSuggestedData,
	getOrderDirOptions,
	getPeriodFilter
})(DetailSuggestedData)

export default withTranslation()(connectForm)
