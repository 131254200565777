import {
	crudClearProfileTabsStore,
	crudClearProfileUsersStore,
	crudGetProfileTabs,
	crudGetProfileUsers,
	crudsClearStore,
	crudsCreate,
	crudsGetSingle,
	crudUpdateProfileTabs,
	crudUpdateProfileUsers,
	showMessage
} from 'actions'
import {
	crudClearProfileAbmsStore,
	crudGetProfileAbms,
	crudUpdateProfileAbms
} from 'actions/ProfileAbmsActions'
import CommonTabs from 'components/abmUtils/CommonTabs'
import withMenu from 'components/common/withMenu'
import CrudsContainer from 'components/layout/CrudsContainer'
import { IAbmStructureResponse } from 'constants/valuesInterfaces/interfaces'
import { ICrudsApi, ICrudsTypeList } from 'models/CrudInterface'
import {
	ICrudGetProfileAbms,
	ICrudGetProfileAbmsResArray,
	ICrudGetProfileRes,
	ICrudGetProfileTabs,
	ICrudGetProfileTabsResArray,
	ICrudGetProfileUsers,
	ICrudGetProfileUsersRes,
	ICrudUpdateProfile,
	ICrudUpdateProfileAbms,
	ICrudUpdateProfileTabs,
	ICrudUpdateProfileUsers,
	ICrudUpdateProfileUsersRes
} from 'models/CrudProfilesInterface'
import { Ihistory } from 'models/NavigationInterface'
import { IResponseResult } from 'models/ResultsInterface'
import React, { Component } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CRUD, CRUD_EDIT, CRUD_PROFILES_DATA } from 'utils/RoutePath'
import ProfileAbmsTable from './AdditionalTabs/ProfileAbmsTable'
import ProfileTabsTable from './AdditionalTabs/ProfileTabsTable'
import ProfileUsersAssignCenterTab from './AdditionalTabs/ProfileUsersAssignCenterTab'
import CrudProfileData from './CrudProfileData'

interface ProfileDataContentProps {
	crudCode: string
	op: string
	selectedAbm: ICrudsTypeList
	crudsGetSingle: (params: ICrudsApi) => void
	crudsCreate: (params: ICrudsApi) => void
	crudsClearStore: () => void
	crudUpdateProfileUsers: (params: ICrudUpdateProfileUsers) => void
	crudGetProfileUsers: (params: ICrudGetProfileUsers) => void
	crudClearProfileUsersStore: () => void
	crudGetProfileAbms: (params: ICrudGetProfileAbms) => void
	crudUpdateProfileAbms: (params: ICrudUpdateProfileAbms) => void
	crudClearProfileAbmsStore: () => void
	crudGetProfileTabs: (params: ICrudGetProfileTabs) => void
	crudUpdateProfileTabs: (params: ICrudUpdateProfileTabs) => void
	crudClearProfileTabsStore: () => void
	valueDataSelected: ICrudGetProfileRes
	valueDataUpdated: ICrudUpdateProfile
	profileUsersSearched: ICrudGetProfileUsersRes
	profileUsersUpdated: ICrudUpdateProfileUsersRes
	profileAbmsSearched: ICrudGetProfileAbmsResArray
	profileAbmsUpdated: IResponseResult
	profileTabsSearched: ICrudGetProfileTabsResArray
	profileTabsUpdated: IResponseResult
	showMessage: any
	history: Ihistory
	fieldsAltaConfig: IAbmStructureResponse
	fieldsConfig: IAbmStructureResponse
	disableForm: boolean
	t: any
}

interface ProfileDataContentState {
	crudCode: number | string | null
	info: boolean
	tabActive: string
	showWarningPopup: boolean
	formChanged: boolean
	nextTab: any
	nextAction: string
}

const profileTabs = [
	{
		key: 'basic',
		label: 'CRUDS.general_use.basic_tab',
		type: 'basic'
	},
	{
		key: 'usuarios',
		label: 'CRUDS.general_use.profile_users',
		type: 'assignCenter'
	},
	{
		key: 'abm',
		label: 'CRUDS.general_use.profile_abms',
		type: 'abmsTable'
	},
	{
		key: 'tab',
		label: 'CRUDS.general_use.profile_tabs',
		type: 'tabsTable'
	}
]

class ProfileDataContent extends Component<
	ProfileDataContentProps,
	ProfileDataContentState
> {
	constructor(props: ProfileDataContentProps) {
		super(props)
		this.state = {
			crudCode: this.props.crudCode,
			info: false,
			tabActive: 'basic',
			showWarningPopup: false,
			formChanged: false,
			nextTab: 'basic',
			nextAction: ''
		}
	}

	componentDidMount() {
		const {
			op,
			crudsClearStore,
			crudCode,
			crudClearProfileUsersStore,
			crudClearProfileAbmsStore,
			crudClearProfileTabsStore,
			crudGetProfileUsers,
			crudGetProfileAbms,
			crudGetProfileTabs
		} = this.props

		if (crudCode && (op === 'edit' || op === 'view')) {
			crudGetProfileUsers({ cod_grupo: crudCode })
			crudGetProfileAbms({ cod_grupo: crudCode })
			crudGetProfileTabs({ cod_grupo: crudCode })
		} else if (op === 'add') {
			crudsClearStore()
			crudClearProfileUsersStore()
			crudClearProfileAbmsStore()
			crudClearProfileTabsStore()
		}
	}

	componentDidUpdate = (prevProps: ProfileDataContentProps) => {
		const {
			valueDataSelected,
			valueDataUpdated,
			op,
			selectedAbm,
			crudCode,
			crudsGetSingle,
			profileUsersUpdated,
			crudGetProfileUsers,
			crudGetProfileAbms,
			crudGetProfileTabs,
			showMessage
		} = this.props

		const {
			valueDataSelected: prevValueDataSelected,
			valueDataUpdated: prevValueDataUpdated,
			profileUsersUpdated: prevProfileUsersUpdated
		} = prevProps

		if (prevValueDataSelected !== valueDataSelected && valueDataSelected) {
			let info = false
			if (op === 'view' || op === 'edit') {
				info = true
			} else if (op === 'add') {
				info = false
			}

			this.setState({ info })
		}

		if (
			(valueDataUpdated !== prevValueDataUpdated && valueDataUpdated) ||
			(profileUsersUpdated !== prevProfileUsersUpdated && profileUsersUpdated)
		) {
			crudsGetSingle({
				crudName: selectedAbm.id,
				urlApi: selectedAbm.urlApi,
				action: 'consulta',
				params: { [selectedAbm.key]: crudCode }
			})
			crudGetProfileUsers({ cod_grupo: crudCode })
			crudGetProfileAbms({ cod_grupo: crudCode })
			crudGetProfileTabs({ cod_grupo: crudCode })
			showMessage({
				type: 'success',
				description: 'Se actualizo correctamente',
				showError: true
			})
			this.setState({ formChanged: false })
		}
	}

	// Cancel button function
	handleRedirectHome = () => {
		this.props.history.push(`${CRUD}/${CRUD_PROFILES_DATA}`)
	}

	/**
	 * to call api to create new abm
	 * @param {object} value
	 */

	handleNewCrud = (values: any) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 1 }
		})
	}

	/**
	 * to call api to edit abm
	 * @param {object} value
	 */

	handleEditCrud = (values: any) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 0 }
		})
	}

	// Set's current tab in state
	handleActiveKey = (tabActive: string) => {
		this.setState({ tabActive })
	}

	// Handles the Crud change arrows
	handleCrudChange = (param: any) => {
		const { history } = this.props
		history.go(`${CRUD_EDIT}/${CRUD_PROFILES_DATA}/${param.cod_grupo}`)
	}

	// Handles the changes warning state
	handleWarningPopup = (value: boolean, action: string) => {
		const { tabActive } = this.state
		this.setState({ showWarningPopup: value })
		action === 'close' && this.setState({ nextTab: tabActive, nextAction: '' })
		action === 'cancel' && this.setState({ formChanged: false })
	}

	// Handles the changes state, used in detecting changes
	handleSetFormChanged = (formChanged: boolean) => {
		this.setState({ formChanged })
	}

	// Stores the value of the clicked tab in the component state
	handleSetNextTab = (nextTab: string) => {
		this.setState({ nextTab })
	}

	// Stores the value of the clicked arrow in the component state
	handleSetNextAction = (nextAction: string) => {
		this.setState({ nextAction })
	}

	handleUpdateProfileUsers = (values: ICrudUpdateProfileUsers) => {
		this.props.crudUpdateProfileUsers(values)
	}

	render() {
		const {
			fieldsAltaConfig,
			fieldsConfig,
			op,
			disableForm,
			valueDataSelected,
			showMessage,
			crudCode,
			profileUsersSearched,
			profileAbmsSearched,
			profileTabsSearched,
			t
		} = this.props

		const { showWarningPopup, formChanged, nextAction, tabActive, nextTab } =
			this.state

		const fields = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('principal')
			  })
			: []
		const abmFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grilla.abm')
			  })
			: []
		const abmFieldsSearch = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('formulario.abm')
			  })
			: []
		const tabFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grilla.tab')
			  })
			: []
		const tabFieldsSearch = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('formulario.tab')
			  })
			: []

		const tabProps = {
			basic: {
				disableForm: disableForm,
				itemInfo: valueDataSelected,
				fields: fields,
				showMessage: showMessage,
				handleSubmit: op === 'add' ? this.handleNewCrud : this.handleEditCrud,
				method: op,
				handleCloseModal: this.handleRedirectHome,
				showWarning: showWarningPopup,
				handleWarning: this.handleWarningPopup,
				setFormChanged: this.handleSetFormChanged
			},
			assign_center: {
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'usuarios')
						?.tipo_perm === 'L' ||
					false,
				values: profileUsersSearched,
				cod_grupo: crudCode,
				handleSubmit: this.handleUpdateProfileUsers,
				setFormChanged: this.handleSetFormChanged,
				showWarning: showWarningPopup,
				handleWarning: this.handleWarningPopup
			},
			abms_table: {
				cod_grupo: crudCode,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'abm')?.tipo_perm ===
						'L' ||
					false,
				fields: abmFields,
				fieldsSearch: abmFieldsSearch
			},
			tabs_table: {
				cod_grupo: crudCode,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'tab')?.tipo_perm ===
						'L' ||
					false,
				fields: tabFields,
				fieldsSearch: tabFieldsSearch
			}
		}

		const propsCrudContainer = {
			urlApi: 'abm_perfiles',
			getNewData: this.handleCrudChange,
			keyApi: 'cod_grupo',
			title: t('global.profiles'),
			handleExitAbm: this.handleRedirectHome,
			showWarning: formChanged,
			handleWarning: this.handleWarningPopup,
			nextAction: nextAction,
			setNextAction: this.handleSetNextAction,
			op: op
		}

		const componentTabs = profileTabs.map((tab) => {
			const selectedTab: any =
				tab.type === 'basic'
					? {
							key: tab.key,
							title: t(tab.label),
							content:
								tabActive === tab.key ? (
									<CrudProfileData {...tabProps.basic} />
								) : (
									<div />
								)
					  }
					: tab.type === 'assignCenter'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled: !(
								!(
									op === 'add' ||
									fieldsConfig.tabs.find(
										(tabResult) => tabResult.cod_tab === tab.key
									)?.tipo_perm === 'N'
								) &&
								profileUsersSearched &&
								(profileUsersSearched.lista1.length > 0 ||
									profileUsersSearched.lista2.length > 0)
							),
							content:
								tabActive === tab.key ? (
									<ProfileUsersAssignCenterTab {...tabProps.assign_center} />
								) : (
									<div />
								)
					  }
					: tab.type === 'abmsTable'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled: !(
								!(
									op === 'add' ||
									fieldsConfig.tabs.find(
										(tabResult) => tabResult.cod_tab === tab.key
									)?.tipo_perm === 'N'
								) &&
								profileAbmsSearched &&
								profileAbmsSearched.lista.length > 0
							),
							content:
								tabActive === tab.key ? (
									<ProfileAbmsTable {...tabProps.abms_table} />
								) : (
									<div />
								)
					  }
					: tab.type === 'tabsTable' && {
							key: tab.key,
							title: t(tab.label),
							disabled: !(
								!(
									op === 'add' ||
									fieldsConfig.tabs.find(
										(tabResult) => tabResult.cod_tab === tab.key
									)?.tipo_perm === 'N'
								) &&
								profileTabsSearched &&
								profileTabsSearched.lista.length > 0
							),
							content:
								tabActive === tab.key ? (
									<ProfileTabsTable {...tabProps.tabs_table} />
								) : (
									<div />
								)
					  }

			return selectedTab
		})

		return (
			<CrudsContainer {...propsCrudContainer}>
				<Row className={'pt-4'}>
					<Col>
						<Card.Title>
							{disableForm
								? t('profiles_data.title_view')
								: op === 'edit'
								? t('profiles_data.title_edit')
								: t('profiles_data.title_new')}
						</Card.Title>
					</Col>
				</Row>
				{valueDataSelected && (
					<>
						<Row>
							<Col sm={2}>
								<strong>{t('global.internal_code')}</strong>
							</Col>
							<Col className={'text-left'}>
								{valueDataSelected && valueDataSelected.cod_grupo}
							</Col>
						</Row>
						<Row className={'pb-2'}>
							<Col sm={2}>
								<strong>{t('global.name')}</strong>
							</Col>
							<Col className={'text-left'}>
								{valueDataSelected && valueDataSelected.desc_grupo}
							</Col>
						</Row>
					</>
				)}
				<CommonTabs
					customTabs={componentTabs}
					defaultActiveKey={tabActive}
					getActiveKey={this.handleActiveKey}
					showWarning={formChanged}
					handleWarning={this.handleWarningPopup}
					nextTab={nextTab}
					setNextTab={this.handleSetNextTab}
				/>
			</CrudsContainer>
		)
	}
}

const mapStateToProps = ({
	configCrud,
	profileUsers,
	profileAbms,
	profileTabs
}: any) => {
	const {
		fieldsConfig,
		fieldsAltaConfig,
		valueDataSelected,
		valueDataUpdated
	} = configCrud
	const { profileUsersSearched, profileUsersUpdated } = profileUsers
	const { profileAbmsSearched, profileAbmsUpdated } = profileAbms
	const { profileTabsSearched, profileTabsUpdated } = profileTabs
	return {
		fieldsConfig,
		fieldsAltaConfig,
		valueDataSelected,
		valueDataUpdated,
		profileUsersSearched,
		profileUsersUpdated,
		profileAbmsSearched,
		profileAbmsUpdated,
		profileTabsSearched,
		profileTabsUpdated
	}
}

const connectForm = connect(mapStateToProps, {
	crudsGetSingle,
	crudsCreate,
	crudsClearStore,
	crudUpdateProfileUsers,
	crudGetProfileUsers,
	crudClearProfileUsersStore,
	crudGetProfileAbms,
	crudUpdateProfileAbms,
	crudClearProfileAbmsStore,
	crudGetProfileTabs,
	crudUpdateProfileTabs,
	crudClearProfileTabsStore,
	showMessage
})(ProfileDataContent)

export default withTranslation()(withMenu(withRouter(connectForm)))
