import { getTableCufData, getTableDepots } from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues } from 'lib/FieldValidations'
import {
	ICrudCufFormData,
	ICrudUpdateCufData
} from 'models/CrudProductsInterface'
import {
	IGetTableCufData,
	IGetTableCufDataRes
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import CufFormField from './CufFormField'

export interface CufProps {
	fields: Array<IFieldStructure>
	handleSubmit: (values: any) => void
	itemInfo?: any
	disableForm: boolean
	handleCloseModal: () => void
	niprod: number
	alta: number
	getTableDepots: () => void
	getTableCufData: (params: IGetTableCufData) => void
	cufData: IGetTableCufDataRes
	t: any
}

type CufState = {
	changedIndex: number
}
class CufForm extends Component<CufProps, CufState> {
	constructor(props: CufProps) {
		super(props)
		this.state = {
			changedIndex: 0
		}
	}

	componentDidMount = () => {
		this.props.getTableDepots()
	}

	handleIndex = (index: number) => {
		this.setState({ changedIndex: index })
	}

	parseCufData = (formData: ICrudCufFormData) => {
		const { niprod, alta } = this.props

		const parsedData: ICrudUpdateCufData = {
			niprod: niprod,
			cuf: !alta ? formData.cuf : formData.cod_dep.padEnd(5, ' '),
			habilitado: formData.habilitado,
			alta: alta
		}

		if (alta) {
			for (const key in formData) {
				key.includes('cod_cuf') &&
					(parsedData.cuf =
						parsedData.cuf + formData[key as keyof typeof formData])
			}
		}

		return parsedData
	}

	render() {
		const { fields, handleSubmit, itemInfo, disableForm, t, alta } = this.props
		const { changedIndex } = this.state

		for (const key in itemInfo) {
			itemInfo[key] =
				typeof itemInfo[key] === 'string' ? itemInfo[key].trim() : itemInfo[key]
		}

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})
		const columnsCancel = disableForm ? 12 : 6

		return (
			<>
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						handleSubmit(this.parseCufData(values))
					}}
					enableReinitialize={true}
				>
					{(props: FormikProps<any>) => (
						<Fragment>
							<Col sm={12}>
								<NotificationError
									errors={props.errors}
									touched={props.touched}
								/>
							</Col>
							<Form>
								{(alta === 1 || (alta === 0 && itemInfo)) &&
									_.map(fields, (field: any) => {
										return (
											<CufFormField
												key={field.idCampo}
												formikProps={props}
												field={field}
												alta={alta}
												crudProps={this.props}
												changedIndex={changedIndex}
												handleIndex={this.handleIndex}
												initialValue={
													initialValues[
														field.idCampo as keyof typeof initialValues
													]
												}
											/>
										)
									})}
								<Row className={'pt-3'}>
									{!disableForm && (
										<Col sm={6} className={'text-center mt-2'}>
											<Button style={{ minWidth: '145px' }} type={'submit'}>
												{t('Collection.form.buttons.accept')}
											</Button>
										</Col>
									)}
									<Col sm={columnsCancel} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px' }}
											type={'button'}
											onClick={this.props.handleCloseModal}
										>
											{t('Collection.form.buttons.cancel')}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					)}
				</Formik>
			</>
		)
	}
}

const mapStateToProps = ({ tableCalls }: any) => {
	const { depots, cufData } = tableCalls
	return { depots, cufData }
}

const connectForm = connect(mapStateToProps, {
	getTableDepots,
	getTableCufData
})(CufForm)

export default withTranslation()(withRouter(connectForm))
