import {
	getVoucherHead,
	voucherHeadCheckDate,
	voucherHeadConfirm
} from 'actions'
import inputInitFocus from 'components/common/inputInitFocus'
import NotificationError from 'components/common/notificationsErrors'
import { P_VTACAB } from 'constants/ConfigProcessNames'
import { Formik } from 'formik'
import { getValidationSchema } from 'lib/FieldValidations'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Col, Form } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import HeadboardFormInput from './headboardFormInput'

class HeadboardForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			urlSubmitForm: '',
			timeSet: '',
			fieldsConfig: null
		}
		this.firtsRefs = null
	}

	componentDidMount = () => {
		const { idOperacion, config } = this.props
		if (idOperacion) {
			this.props.getVoucherHead({ idOperacion })
		}
		if (config) {
			const fields = config ? this.getRefsField(config.campos) : {}
			this.setState({ fieldsConfig: fields })
		}
	}

	componentDidUpdate = (prevProps) => {
		const { config } = this.props
		if (config !== prevProps.config && config) {
			const fields = config ? this.getRefsField(config.campos) : {}
			this.setState({ fieldsConfig: fields })
		}
	}

	getRefsField = (fields) => {
		fields.forEach((field) => {
			if (field.editable) {
				const fieldRefs = React.createRef()
				if (!this.firtsRefs) {
					this.setRefFirstInput(fieldRefs)
					this.firtsRefs = fieldRefs // Save the firts ref
				}
				field.fwRef = fieldRefs
			}
		})

		return fields
	}

	/**
	 * confirm and redirect
	 * @param {*} values
	 */
	handleSubmitForm = (values) => {
		const { idOperacion, callBackReturn } = this.props

		if (values) {
			const requestData = {
				Transp_comp_vta: values.transp_comp_vta,
				Suc_empresa_vta: values.Suc_empresa_vta,
				cond_comp_vta: values.cond_comp_vta,
				cotiz_comp_vta: values.cotiz_comp_vta,
				fecha_comp_vta: values.fecha_comp_vta,
				mon_comp_vta: values.mon_comp_vta,
				titulo_comp_vta: values.titulo_comp_vta,
				vend_comp_vta: values.vend_comp_vta,
				atrib_comp_vta: values.atrib_comp_vta_field,
				suc_clie: values.suc_clie
			}

			if (idOperacion) {
				this.props.voucherHeadConfirm({
					headInfo: { ...requestData, idOperacion },
					callBackReturn
				})
			}
		}
	}

	render() {
		const { fieldsConfig } = this.state
		const { config, headSale, t } = this.props

		const defaultInitial = headSale
			? {
					Suc_empresa_vta: headSale.suc_empresa[0].Cod_Suc,
					mon_comp_vta: headSale.moneda[0].cod_moneda,
					cond_comp_vta: headSale.cond_comp_vta[0].cod_cond_vta,
					vend_comp_vta: headSale.vendedor[0].cod_vend,
					fecha_comp_vta: moment(headSale.fecha_comp_vta),
					Titulo_comp_vta: '',
					cotiz_comp_vta: headSale.moneda[0].cotiz | 0,
					suc_clie: headSale.suc_clie[0].suc_nro
			  }
			: {}

		const validationSchema = config ? getValidationSchema(config.campos, t) : {}

		if (fieldsConfig && headSale) {
			return (
				<Col sm={12}>
					<Formik
						ref={this.props.formRef}
						initialValues={{ ...defaultInitial }}
						onSubmit={(values, actions) => this.handleSubmitForm(values)}
						validationSchema={validationSchema}
						render={({
							values,
							handleBlur,
							handleChange,
							errors,
							touched,
							isSubmitting,
							handleSubmit,
							setFieldValue,
							setFieldTouched,
							setErrors
						}) => (
							<Fragment>
								<Col sm={11}>
									<NotificationError
										errors={errors}
										setErrors={setErrors}
										touched={touched}
									/>
								</Col>
								<Form onSubmit={handleSubmit} className={'voucher-info-form'}>
									<Col>
										<HeadboardFormInput
											fields={fieldsConfig}
											idOperacion={this.props.idOperacion}
											idProcess={P_VTACAB}
											headSale={headSale}
											collapse
											{...{
												values,
												handleBlur,
												handleChange,
												errors,
												touched,
												isSubmitting,
												handleSubmit,
												setFieldValue,
												setFieldTouched
											}}
										/>
									</Col>
									<div className="dropdown-divider col-11 p-1" />
								</Form>
							</Fragment>
						)}
					/>
				</Col>
			)
		} else {
			return null
		}
	}
}

const mapStateToProps = ({ voucher }) => {
	const config = voucher.config ? voucher.config[P_VTACAB] : null
	const { headSale } = voucher
	return { config, headSale }
}

export default connect(mapStateToProps, {
	getVoucherHead,
	voucherHeadCheckDate,
	voucherHeadConfirm
})(withTranslation()(withRouter(inputInitFocus(HeadboardForm))))
