import { ICrudGenericFields } from 'models/CrudInterface'

export const additionalTreasuryAccountsFields: Array<ICrudGenericFields> = [
	{
		id: 'catributo',
		type: 'text',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'datributo',
		type: 'text',
		width: 'col-sm-4',
		height: '',
		default: null,
		maxChars: 30,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_unid',
		type: 'combo',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'measurementUnit',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cant_dig',
		type: 'text',
		width: 'col-sm-1',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'tipo',
		type: 'combo',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'dataType',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'tipo_alinea',
		type: 'combo',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'alignmentType',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'tipo_valid',
		type: 'combo',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'validationType',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'desde',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: 'tipo_valid',
			values: [
				{
					idValue: 'R',
					disabled: false
				},
				{
					idValue: 'L',
					disabled: true
				},
				{
					idValue: 'N',
					disabled: true
				}
			]
		}
	},
	{
		id: 'hasta',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: 'tipo_valid',
			values: [
				{
					idValue: 'R',
					disabled: false
				},
				{
					idValue: 'L',
					disabled: true
				},
				{
					idValue: 'N',
					disabled: true
				}
			]
		}
	},
	{
		id: 'relleno',
		type: 'text',
		width: 'col-sm-1',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
