import {
	ADVANCED_PRODUCT_SEARCH,
	ADVANCED_PRODUCT_SEARCH_SUCCESS,
	CHECK_ITEM_BY_PRODUCT,
	CHECK_ITEM_BY_PRODUCT_SUCCESS,
	CLEAR_PRODUCT_STORE,
	FINISH_PRODUCT_CART,
	FINISH_PRODUCT_CART_SUCCESS,
	GET_PRICE_BY_PRODUCT,
	GET_PRICE_BY_PRODUCT_SUCCESS,
	GET_PRODUCTS_CART,
	GET_PRODUCTS_CART_SUCCESS,
	GET_PRODUCTS_INVOLVEMENT,
	GET_PRODUCTS_INVOLVEMENT_SUCCESS,
	GET_PRODUCT_COST,
	GET_PRODUCT_COST_SUCCESS,
	GET_PRODUCT_IMAGE,
	GET_PRODUCT_IMAGE_ERROR,
	GET_PRODUCT_IMAGE_SUCCESS,
	GET_PRODUCT_PRICE_LIST,
	GET_PRODUCT_PRICE_LIST_SUCCESS,
	REMOVE_PRODUCT_CART,
	REMOVE_PRODUCT_CART_SUCCESS,
	SEARCH_AUTO_PRODUCTS,
	SEARCH_AUTO_PRODUCTS_SUCCESS,
	SEARCH_PRODUCTS,
	SEARCH_PRODUCTS_SUCCESS,
	SET_INPUT_FOCUS,
	SET_INPUT_FOCUS_SUCCESS,
	SET_TABLE_DATA_PRODUCTS,
	VALIDATE_PRODUCT_COST,
	VALIDATE_PRODUCT_COST_NET,
	VALIDATE_PRODUCT_COST_NET_SUCCESS,
	VALIDATE_PRODUCT_COST_PC,
	VALIDATE_PRODUCT_COST_PC_SUCCESS,
	VALIDATE_PRODUCT_COST_SUCCESS,
	VALIDATE_PRODUCT_DISCOUNT,
	VALIDATE_PRODUCT_DISCOUNT_SUCCESS,
	VALIDATE_PRODUCT_NET,
	VALIDATE_PRODUCT_NET_SUCCESS,
	VALIDATE_PRODUCT_PRICE,
	VALIDATE_PRODUCT_PRICE_SUCCESS,
	VOUCHER_CANCEL_SUCCESS
} from 'constants/ActionsTypes'
import _ from 'underscore'

const initialState = {
	search: [],
	price: null,
	productsUpdate: null,
	paramsPrice: null,
	productsCart: null,
	focusInput: null,
	updateCant: false,
	productsInvol: null,
	searchParameters: null,
	removeProducts: null,
	productImage: null,
	finishCart: null,
	paramsCost: null,
	updateRecord: null,
	removeItemCart: true,
	productList: null,
	searchAutoOptions: null,
	productPriceList: null,
	paramsSaleDto: null
}

function rootReducer(state = initialState, action) {
	const { paramsCost, paramsPrice, paramsSaleDto } = state
	let updateCostProduct
	let updateState
	let price
	let productCost
	let updateRecord
	let salesProduct

	switch (action.type) {
		case SEARCH_PRODUCTS:
		case SEARCH_AUTO_PRODUCTS:
			return {
				...state,
				searchParameters: action.payload,
				search: [],
				productsUpdate: null
			}
		case SEARCH_PRODUCTS_SUCCESS:
			return { ...state, search: action.payload }
		case SEARCH_AUTO_PRODUCTS_SUCCESS:
			return { ...state, searchAutoOptions: action.payload }
		case GET_PRODUCT_COST:
			return {
				...state,
				productCost: null,
				paramsCost: action.payload,
				updateCant: false
			}
		case GET_PRODUCT_COST_SUCCESS:
			productCost = action.payload.productCost
			updateCostProduct = {
				...state,
				productsUpdate: [...state.search.productos],
				productCost,
				updateCant: true
			}

			return updateListProducts(
				updateCostProduct,
				paramsCost,
				productCost.prod_costo
			)

		case GET_PRICE_BY_PRODUCT:
			return {
				...state,
				price: null,
				paramsPrice: action.payload,
				updateCant: false,
				productsUpdate: null
			}
		case GET_PRICE_BY_PRODUCT_SUCCESS:
			price = action.payload
			updateState = {
				...state,
				productsUpdate: {
					..._.findWhere(state.search.productos, {
						niprod: paramsPrice.Idproducto
					}),
					cod_unid: paramsPrice.unid_vta
				},
				price,
				updateCant: true
			}
			return updateListProducts(updateState, paramsPrice, price)

		case CHECK_ITEM_BY_PRODUCT:
			return { ...state, itemTable: null }
		case CHECK_ITEM_BY_PRODUCT_SUCCESS:
			return { ...state, itemTable: action.payload.data }
		case SET_TABLE_DATA_PRODUCTS:
			updateRecord = action.payload
			updateState = {
				...state,
				productsUpdate: _.findWhere(state.search.productos, {
					niprod: updateRecord.niprod
				})
			}

			return updateProductRow(updateState, updateRecord)

		case GET_PRODUCTS_CART:
			return { ...state, productsCart: null }
		case GET_PRODUCTS_CART_SUCCESS:
			return { ...state, productsCart: action.payload }
		case GET_PRODUCTS_INVOLVEMENT:
			return { ...state, productsInvol: null }
		case GET_PRODUCTS_INVOLVEMENT_SUCCESS:
			return { ...state, productsInvol: action.payload.data }
		case SET_INPUT_FOCUS:
			return { ...state, focusInput: null }
		case SET_INPUT_FOCUS_SUCCESS:
			return { ...state, focusInput: action.payload }
		case VOUCHER_CANCEL_SUCCESS:
			return { ...initialState }
		case REMOVE_PRODUCT_CART:
			return { ...state, removeProducts: action.payload, removeItemCart: false }
		case REMOVE_PRODUCT_CART_SUCCESS:
			return { ...state, productsCart: action.payload, removeItemCart: true }
		case GET_PRODUCT_IMAGE:
			return { ...state, productImage: null }
		case GET_PRODUCT_IMAGE_SUCCESS:
			return { ...state, productImage: action.payload }
		case GET_PRODUCT_IMAGE_ERROR:
			return { ...state, productImage: action.payload }
		case FINISH_PRODUCT_CART:
			return { ...state, finishCart: null }
		case FINISH_PRODUCT_CART_SUCCESS:
			return { ...state, finishCart: action.payload }
		case ADVANCED_PRODUCT_SEARCH:
			return { ...state, productList: null, paramsSearch: action.payload }
		case ADVANCED_PRODUCT_SEARCH_SUCCESS:
			return { ...state, productList: action.payload }
		case CLEAR_PRODUCT_STORE:
			return { ...state, productList: null, paramsSearch: {} }
		case VALIDATE_PRODUCT_DISCOUNT:
		case VALIDATE_PRODUCT_NET:
		case VALIDATE_PRODUCT_PRICE:
			return { ...state, paramsPrice: action.payload, productsUpdate: null }
		case VALIDATE_PRODUCT_DISCOUNT_SUCCESS:
		case VALIDATE_PRODUCT_PRICE_SUCCESS:
		case VALIDATE_PRODUCT_NET_SUCCESS:
			price = action.payload
			updateState = {
				...state,
				productsUpdate: {
					..._.findWhere(state.search.productos, {
						niprod: paramsPrice.idProducto
					}),
					cod_unid: paramsPrice.unid_vta
				},
				price
			}
			return updateListProducts(updateState, paramsPrice, price)

		case GET_PRODUCT_PRICE_LIST:
			return { ...state, productPriceList: null }
		case GET_PRODUCT_PRICE_LIST_SUCCESS:
			return { ...state, productPriceList: action.payload }
		case VALIDATE_PRODUCT_COST_PC:
		case VALIDATE_PRODUCT_COST:
		case VALIDATE_PRODUCT_COST_NET:
			return { ...state, paramsSaleDto: action.payload }
		case VALIDATE_PRODUCT_COST_PC_SUCCESS:
		case VALIDATE_PRODUCT_COST_SUCCESS:
		case VALIDATE_PRODUCT_COST_NET_SUCCESS:
			price = action.payload

			salesProduct = _.findWhere(state.search.productos, {
				niprod: paramsSaleDto.idProducto
			})
			updateState = {
				...state,
				productsUpdate: {
					..._.findWhere(state.search.productos, {
						niprod: paramsSaleDto.idProducto
					}),
					cod_unid: paramsPrice.unid_vta
				},
				paramsSaleDto: null,
				paramsPrice: { ...paramsSaleDto }
			}

			return updateListProducts(updateState, paramsSaleDto, price)

		default:
			return state
	}
}

// /**
//  * to change a array to a object
//  * @param {*} records
//  * @returns
//  */
// function getRecordByArray(records) {
// 	const result = { niprod: records[0].niprod }
// 	records.forEach((record) => {
// 		result[record.idCampo] = record.value
// 	})
// 	return result
// }

/**
 * update prices and neto
 * @param {*} updateState
 * @param {*} price
 * @returns
 */
function updateProductRow(updateState, newData) {
	if (!_.isEmpty(updateState.productsUpdate)) {
		updateState.productsUpdate = { ...updateState.productsUpdate, ...newData }
	}

	return updateState
}

function updateListProducts(updateState, params, apiPrice) {
	if (!_.isEmpty(updateState.productsUpdate)) {
		if (_.isArray(updateState.productsUpdate)) {
			updateState.productsUpdate.forEach((prd) => {
				if (prd.niprod === params.Idproducto) {
					prd.pcio_unit = apiPrice.prod_pcio_vta
					prd.neto = apiPrice.neto
					prd.cantidad = parseFloat(params.cantidad)
					prd.pc_lista = apiPrice.pc_lista
					prd.pc_dto = apiPrice.pc_lista
					prd.pc_util = apiPrice.pc_util
				}
			})
		} else {
			updateState.productsUpdate.pcio_unit =
				apiPrice.prod_pcio_vta || apiPrice.pcio_unit
			updateState.productsUpdate.neto = apiPrice.neto
			updateState.productsUpdate.cantidad = parseFloat(params.cantidad)
			updateState.productsUpdate.pc_lista = apiPrice.pc_lista
			updateState.productsUpdate.pc_dto = apiPrice.pc_lista || apiPrice.pc_dto
			updateState.productsUpdate.pc_util = apiPrice.pc_util
		}
	}

	return updateState
}

export default rootReducer
