import {
	getTableIsCUITRepeatedSuccess,
	getTableIsDNIRepeatedSuccess,
	getTableIsPostalCodeValidSuccess,
	getTableIsTaxesIdValidSuccess
} from 'actions'
import {
	getTableIsCUITRepeated,
	getTableIsDNIRepeated,
	getTableIsPostalCodeValid,
	getTableIsTaxesIdValid
} from 'api/ValidationApi'
import {
	GET_TABLE_IS_CUIT_REPEATED,
	GET_TABLE_IS_DNI_REPEATED,
	GET_TABLE_IS_POSTAL_CODE_VALID,
	GET_TABLE_IS_TAXES_ID_VALID
} from 'constants/ActionsTypes'
import {
	IGetTableIsPostalCodeValidSaga,
	IGetTableIsTaxesIdValidSaga
} from 'models/TableCalls/DynamicValidationsInterface'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* getTableIsPostalCodeValidRequest({
	payload
}: IGetTableIsPostalCodeValidSaga): any {
	try {
		const tableData = yield call(getTableIsPostalCodeValid, payload)
		yield put(getTableIsPostalCodeValidSuccess(tableData))
	} catch (error) {}
}

function* getTableIsTaxesIdValidRequest({
	payload
}: IGetTableIsTaxesIdValidSaga): any {
	try {
		const tableData = yield call(getTableIsTaxesIdValid, payload)
		yield put(getTableIsTaxesIdValidSuccess(tableData))
	} catch (error) {}
}

function* getTableIsDNIRepeatedRequest({ payload }: any): any {
	try {
		const tableData = yield call(getTableIsDNIRepeated, payload)
		yield put(getTableIsDNIRepeatedSuccess(tableData))
	} catch (error) {}
}

function* getTableIsCUITRepeatedRequest({ payload }: any): any {
	try {
		const tableData = yield call(getTableIsCUITRepeated, payload)
		yield put(getTableIsCUITRepeatedSuccess(tableData))
	} catch (error) {}
}

export function* getTableIsPostalCodeValidSaga() {
	yield takeEvery(
		GET_TABLE_IS_POSTAL_CODE_VALID,
		getTableIsPostalCodeValidRequest
	)
}

export function* getTableIsTaxesIdValidSaga() {
	yield takeEvery(GET_TABLE_IS_TAXES_ID_VALID, getTableIsTaxesIdValidRequest)
}

export function* getTableIsDNIRepeatedSaga() {
	yield takeEvery(GET_TABLE_IS_DNI_REPEATED, getTableIsDNIRepeatedRequest)
}

export function* getTableIsCUITRepeatedSaga() {
	yield takeEvery(GET_TABLE_IS_CUIT_REPEATED, getTableIsCUITRepeatedRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getTableIsPostalCodeValidSaga),
		fork(getTableIsTaxesIdValidSaga),
		fork(getTableIsDNIRepeatedSaga),
		fork(getTableIsCUITRepeatedSaga)
	])
}
