import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	getPriceAddCart,
	getPriceByProduct,
	searchProducts,
	setTableDataProducts,
	validateProductDiscount,
	validateProductNet,
	validateProductPrice
} from 'actions'
import CollapseButton from 'components/common/collapseButton'
import CommonModal from 'components/common/commonModal'
import CommonTable from 'components/common/commonTable'
import DisplayLight from 'components/common/displayLight'
import NotificationMessage from 'components/common/notificationMessage'
import PopupImage from 'components/common/popupImage'
import SearchBox from 'components/common/searchBox'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { LoadInventoryItemsActions } from 'constants/ActionsTypesTs'
import { validateField } from 'lib/FieldValidations'
import { getValueMask } from 'lib/MaskValues'
import { handleIsRendeTableFinish, handleSetFocus } from 'lib/TableUtils'
import { getFiltersData } from 'lib/Utils'
import moment from 'moment'
import React, { Fragment, PureComponent } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import StockPopup from '../Stock/stockPopup'
import AttributesModal from './AttributesModal'
import InputPriceUnit from './inputPriceUnit'
import styles from './itemsTable.module.scss'

class LoadItemsTable extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			filterVal: null,
			inputsError: [],
			showError: false,
			errorTitle: '',
			errorMessage: '',
			typeNotification: 'danger',
			showStock: false,
			updateDataTable: [],
			isUpdateRecord: null,
			tableColumns: [],
			productTable: [],
			stockProviderModal: false,
			referenceItem: null,
			updatedRow: null,
			showAttributesModal: false
		}

		this.inputRefs = {}
		this.firstRefs = null
		this.refsBeforeSearch = React.createRef()
		this.primaryKey = 'niprod'
		this.excludeFields = [
			'sig_proceso',
			'fin_item',
			'modif_pcio',
			'ind_stock',
			'avisos'
		]
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.preventAddToCart(this.preventAddToCart)
		this.props.homeFocus(this.setInitFocus)
		this.props.formConfirmation(this.handleConfirmation)
		this.props.handleMoveArrow(this.handleMoveArrow)
		this.preventAddToCart = this.preventAddToCart.bind(this)

		if (idOperacion) {
			this.handleAddToCart = this.handleAddToCart.bind(this)
			this.setInitFocus = this.setInitFocus.bind(this)
			this.handleConfirmation = this.handleConfirmation.bind(this)
			this.handleMoveArrow = this.handleMoveArrow.bind(this)
		}

		this.setInitFocus()
	}

	componentDidUpdate = (prevProps, prevState) => {
		const {
			search,
			itemsCart,
			confirmItem,
			productsUpdate,
			config,
			confirmedStock,
			confirmedAttributes,
			canceledAttributes
		} = this.props
		const { showStock } = this.state

		this.validateAttribs(prevProps.confirmedStock, confirmedStock)

		this.validateConfirmAttribs(
			prevProps.confirmedAttributes,
			confirmedAttributes
		)

		this.validateCancelAttributeModal(
			prevProps.canceledAttributes,
			canceledAttributes
		)

		this.validateUpdateSetFocus(prevProps.productsUpdate, productsUpdate)

		if (showStock !== prevState.showStock && prevState.showStock) {
			this.props.handleMoveArrow(this.handleMoveArrow)
			this.handleMoveArrow = this.handleMoveArrow.bind(this)
		}

		if (prevProps.itemsCart !== itemsCart && !prevProps.itemsCart) {
			this.setState({ isUpdateRecord: null })
			// first time
			this.setFocusNextRow()
		} else if (
			prevProps.itemsCart &&
			itemsCart &&
			itemsCart.total_importe !== prevProps.itemsCart.total_importe
		) {
			this.setFocusNextRow()
		}

		/**
		 * restart firstRefs and inputRefs
		 */

		if (!search.Resultado && this.firstRefs) {
			this.firstRefs = null
			this.inputRefs = {}
		}

		// set focus in the first editable field on table.
		if (search !== prevProps.search && search && search.productos) {
			const fieldsPriceEstTable = getFiltersData(config.campos, {
				agrupador: 'vprec.est'
			})

			const fieldsPriceTable = getFiltersData(config.campos, {
				agrupador: 'vprec.lprec'
			})

			const fieldsPriceForm = getFiltersData(config.campos, {
				agrupador: 'vprec.cab'
			})

			const fieldsTable = getFiltersData(config.campos, { agrupador: 'grilla' })
			const tableColumns = this.getColumns()

			this.setState({
				showError: false,
				productTable: search.productos,
				tableColumns,
				fieldsPriceEstTable,
				fieldsPriceTable,
				fieldsPriceForm,
				fieldsTable
			})
		}

		if (confirmItem !== prevProps.confirmItem && confirmItem) {
			if (confirmItem.solic_stock === 1) {
				this.setState({ showStock: true, referenceItem: confirmItem.it })
			} else if (confirmItem.solic_atributos === 1) {
				this.toggleAttributesModal()
				this.setState({ referenceItem: confirmItem.it })
			}
		}
	}

	/**
	 * to validate api and show attributes modal
	 * @param {*} prevAttribs
	 * @param {*} attribs
	 */
	validateAttribs = (prevAttribs, attribs) => {
		if (
			!_.isEqual(prevAttribs, attribs) &&
			!_.isEmpty(attribs) &&
			attribs.solic_atributos === 1
		) {
			this.toggleAttributesModal()
		}
	}

	/**
	 * to validate api and show attributes modal
	 * @param {*} prevAttribs
	 * @param {*} attribs
	 */
	validateConfirmAttribs = (prevAttribs, attribs) => {
		if (!_.isEqual(prevAttribs, attribs) && !_.isEmpty(attribs)) {
			this.toggleAttributesModal()
		}
	}

	/**
	 * valida cancel attribute and show message
	 * @param {*} prevCancelAttr
	 * @param {*} cancelAttr
	 */
	validateCancelAttributeModal = (prevCancelAttr, cancelAttr) => {
		const { t } = this.props
		if (!_.isEqual(prevCancelAttr, cancelAttr) && !_.isEmpty(cancelAttr)) {
			this.setState({
				showError: true,
				errorMessage: t('attribute.delete_cart_product'),
				typeNotification: 'danger'
			})
			this.toggleAttributesModal()
		}
	}

	/**
	 * to update data from table
	 */

	static getDerivedStateFromProps(props, state) {
		const { productsUpdate } = props
		const { productTable, updatedRow } = state

		if (!_.isEqual(productsUpdate, updatedRow) && !_.isEmpty(productsUpdate)) {
			if (!_.isArray(productsUpdate)) {
				const rowData = productTable.map((prod) => {
					let result = {}
					if (productsUpdate.niprod === prod.niprod) {
						result = {
							...productsUpdate,
							id: productsUpdate.niprod
						}
					} else {
						result = {
							...prod,
							id: prod.niprod
						}
					}
					return result
				})
				return {
					productTable: rowData,
					updatedRow: productsUpdate
				}
			} else {
				return {
					productTable: productsUpdate.map((prd) => prd)
				}
			}
		}
		return null
	}

	/**
	 * To set focus in the next editable field after change %Desc and pcio_unit
	 * @param {*} prevProductsUpdate
	 * @param {*} productsUpdate
	 */
	validateUpdateSetFocus = (prevProductsUpdate, productsUpdate) => {
		const { paramsPrice } = this.props
		const { isUpdateRecord } = this.state
		if (
			!_.isEqual(prevProductsUpdate, productsUpdate) &&
			!_.isEmpty(productsUpdate) &&
			!_.isEmpty(paramsPrice)
		) {
			if (isUpdateRecord && isUpdateRecord !== 'cantidad') {
				const nextField = this.getNextEditField(isUpdateRecord)
				handleSetFocus(nextField, paramsPrice.idProducto, this.inputRefs)
			}
		}
	}

	/**
	 * To move focus to other rows.
	 * @params {rowId, field, key}
	 */
	handleMoveArrow = (params) => {
		const { rowId, field, key } = params
		const reverse = key === 'ArrowUp'
		const nextRow = this.getNextProductId(rowId, reverse)
		handleSetFocus(field, nextRow, this.inputRefs)
	}

	/**
	 * to clear data from editable field on the row
	 * console.log(field, nextRow, '??')
	 * @params {NiProd} row
	 */
	setInitRow = (params) => {
		const initRow = {
			niprod: params.Niprod,
			cantidad: '',
			neto: '',
			pcio_unit: '',
			fec_entrega: '',
			pc_lista: ''
		}

		this.props.setTableDataProducts(initRow)
	}

	handleConfirmation = () => {
		const { idOperacion, callBackReturn } = this.props
		this.props.apiConfirm({ idOperacion, callBackReturn })
	}

	/**
	 * to set foco in the search input
	 */
	setInitFocus = () => {
		this.refsBeforeSearch.current.focus() // Focus in the search table
	}

	/**
	 * to get id of the next row
	 * @params idProduct
	 * @params revers >> to validate if down or up
	 */
	getNextProductId = (idProduct, reverse = false) => {
		const { search } = this.props
		let result = 0
		if (idProduct) {
			search.productos.forEach((prd, index) => {
				if (prd.niprod === idProduct) {
					result = !reverse ? index + 1 : index - 1 // Back o next row
				}
			})
			if (search.productos[result]) {
				return search.productos[result].niprod
					? search.productos[result].niprod
					: null
			} else {
				return null
			}
		}
		return null
	}

	/**
	 * To set focus in next row in the first editable field
	 * depends on the product that has just been entered into the cart
	 */
	setFocusNextRow = () => {
		const { parameterConfirm, t, config } = this.props
		const fieldsTable = getFiltersData(config.campos, { agrupador: 'grilla' })
		const addRow = this.getRowById(parameterConfirm.Niprod)
		const configNeto = this.getConfigById('neto')
		const message = t('itemAdd-Cart', {
			cantidad: parameterConfirm.cantidad,
			unidad: parameterConfirm.cod_unid,
			producto: addRow.desc_prod,
			neto: configNeto.mascara
				? getValueMask(parameterConfirm.neto, configNeto.mascara, this.props)
				: parameterConfirm.neto
		})

		this.setState({
			showError: true,
			errorMessage: message,
			errorTitle: '',
			typeNotification: 'success'
		})
		const nextRow = this.getNextProductId(parameterConfirm.Niprod)
		if (nextRow) {
			const firstField = fieldsTable[0]
			const field = firstField.editable
				? firstField.idCampo
				: this.getNextEditField(firstField.idCampo) // first field

			// Set focus
			handleSetFocus(field, nextRow, this.inputRefs)
			this.setInitRow(parameterConfirm) // Clear last table row
		}
	}

	getConfigById = (field) => {
		const { config } = this.props
		let result = null
		config.campos.forEach((campo) => {
			const campoId = campo.idCampo.trim()
			if (campoId === field) {
				result = campo
			}
		})

		return result
	}

	/**
	 * to get the next editable field.
	 * @param {*} field
	 * @returns
	 */
	getNextEditField = (field) => {
		const { config } = this.props
		const fieldsTable = getFiltersData(config.campos, { agrupador: 'grilla' })

		let result = null
		let nextIndex = 0
		let restart = false
		let indexField = _.findIndex(fieldsTable, { idCampo: field })

		do {
			nextIndex = indexField + 1
			indexField = fieldsTable[nextIndex] ? nextIndex : 0
			result = fieldsTable[indexField].idCampo.trim()
			if (!fieldsTable[nextIndex]) {
				restart = true
			}
		} while (
			!fieldsTable[indexField].editable ||
			!fieldsTable[indexField].visible ||
			this.excludeFields.includes(result)
		)

		return restart ? 'addToCart' : result
	}

	/**
	 * get data from table row
	 */
	getRowById = (rowId) => {
		const { productTable } = this.state // use update data from table.
		const result = _.find(productTable, { niprod: rowId })

		return result
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	/**
	 * To add products to shopping cart
	 * @params row
	 */
	handleAddToCart = (row) => {
		const {
			config,
			t,
			idOperacion,
			setTableDataProducts,
			handleItemAddToCart
		} = this.props

		const { updateDataTable } = this.state
		const updateDesc = _.findWhere(updateDataTable, { rowId: row.niprod })

		const message = []
		let flag = true

		const params = {
			idOperacion,
			Niprod: row.niprod,
			cod_unid: row.cod_unid,
			cantidad: row.cantidad,
			pcio_unit: row.pcio_unit,
			neto: row.neto,
			desc_prod:
				!_.isEmpty(updateDesc) && updateDesc.desc_prod
					? updateDesc.desc_prod
					: row.desc_prod
		}

		if (row.fec_entrega) {
			params.fec_entrega = moment(row.fec_entrega).format('YYYY-MM-DD')
		}
		const fieldsTable = getFiltersData(config.campos, { agrupador: 'grilla' })
		fieldsTable.forEach((field) => {
			const fieldId = field.idCampo.trim()
			if (parseInt(field.requerido) && !this.excludeFields.includes(fieldId)) {
				if (!validateField(row[fieldId], field.valid)) {
					const params = { niprod: row.niprod }
					params[fieldId] = 'error_input'
					setTableDataProducts(params)
					message.push(
						'El campo ' + t('validation-required', { field: field.label })
					)
					flag = false
				}
			}
		})

		if (flag) {
			this.setState({
				showError: false,
				isUpdateRecord: null,
				updateRecord: null
			})
			handleItemAddToCart(params)
		} else {
			this.setState({
				showError: true,
				updateRecord: null,
				typeNotification: 'danger',
				errorMessage: message,
				errorTitle: t('global.input_require')
			})
		}
	}

	/**
	 * to get with style to each column
	 * @param {*} field
	 * @returns
	 */
	getStyleColumn = (field, countColumns) => {
		const idField = field.idCampo.trim()

		let style = {}
		if (countColumns.visible >= 10) {
			switch (idField) {
				case 'desc_prod':
					style = { width: '25%' }
					break
				case 'ind_stock':
				case 'pc_util':
					style = { width: '2%' }
					break
				case 'fec_entrega':
					style = { width: '10%' }
					break
				case 'pcio_unit':
				case 'neto':
					style = { width: '11%' }
					break
				case 'modif_pcio':
					style = { width: '1%' }
					break
				case 'cantidad':
				case 'cod_prod':
				case 'cod_unid':
				case 'pc_lista':
				case 'costo':
					style = { width: '8%' }
					break

				default:
					style = { width: '10%' }
					break
			}
		} else if (countColumns.visible < 7) {
			switch (idField) {
				case 'desc_prod':
					style = { width: '30%' }
					break
				default:
					break
			}
		}

		if (field.requerido) {
			style.color = 'red'
		}

		return style
	}

	/**
	 * to get count of columns visible
	 * @param {*} fieldsTable
	 */
	getCountVisibleColumns = (fieldsTable) => {
		return _.countBy(fieldsTable, (field) => {
			const idField = field.idCampo.trim()
			return field.visible && !_.contains(this.excludeFields, idField)
				? 'visible'
				: 'noVisible'
		})
	}

	/**
	 * to print columns table
	 * @returns
	 */

	getColumns = () => {
		const { config, theme, isProvider } = this.props
		const fieldsTable = getFiltersData(config.campos, { agrupador: 'grilla' })
		const countVisibleColumns = this.getCountVisibleColumns(fieldsTable)
		const rows = fieldsTable.map((field) => {
			const fieldId = field.idCampo.trim()
			return {
				dataField: fieldId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: isProvider
					? {}
					: this.getStyleColumn(field, countVisibleColumns),
				hidden:
					!field.visible || fieldId === 'fin_item' || fieldId === 'sig_proceso',
				formatter: (cell, row, rowIndex) => {
					return this.renderFormat(field, cell, row)
				}
			}
		})

		rows.push({
			dataField: 'actions',
			text: '',
			align: 'center',
			headerAlign: 'center',
			headerStyle: { width: '2%' },
			formatter: (cell, row, rowIndex) => {
				const customRef = React.createRef()
				if (!this.inputRefs.addToCart) {
					this.inputRefs.addToCart = {}
				}
				this.inputRefs.addToCart[row.niprod] = customRef
				return (
					<Button
						id={`bottomAdd-${row.niprod}`}
						className={`btn btn-light  ${theme.buttonAddToCart}`}
						ref={customRef}
						onClick={() => this.handlePreventClickAddCart(row)}
					>
						<FontAwesomeIcon icon={faShoppingCart} />
					</Button>
				)
			}
		})

		return [
			{
				dataField: 'id_imagen',
				text: '',
				align: 'right',
				headerStyle: { width: '2%' },
				formatter: (cell, row, rowIndex) => {
					return (
						<Fragment>
							<PopupImage product={row} />
						</Fragment>
					)
				}
			}
		].concat(rows)
	}

	/**
	 * To update data in reducer in the onblur event
	 * @params value
	 * @params fieldId
	 * @params row
	 */

	handleOnblurInput = async (value, fieldId, row) => {
		const { idOperacion } = this.props

		if (this.isValueChange(row[this.primaryKey], fieldId, value)) {
			this.setState({ isUpdateRecord: fieldId, updatedRow: null })
			if (fieldId === 'pc_lista') {
				this.props.validateProductDiscount({
					idOperacion,
					idProducto: row.niprod,
					cantidad: row.cantidad,
					pc_lista: value,
					unid_vta: row.cod_unid
				})
			} else if (fieldId === 'cantidad') {
				this.props.getPriceByProduct({
					idOperacion: this.props.idOperacion,
					Idproducto: row.niprod,
					cantidad: value,
					unid_vta: row.cod_unid,
					pcio_unit: row.pcio_unit
				})
			} else if (fieldId === 'pcio_unit') {
				this.props.validateProductPrice({
					idOperacion,
					idProducto: row.niprod,
					cantidad: row.cantidad,
					unid_vta: row.cod_unid,
					precio: value
				})
			} else if (fieldId === 'neto') {
				this.props.validateProductNet({
					idOperacion,
					idProducto: row.niprod,
					cantidad: row.cantidad,
					unid_vta: row.cod_unid,
					neto: value
				})
			}
		}
	}

	/**
	 * To validate if the field values changed
	 * @params id row
	 * @params fieldId
	 * @params value
	 */

	isValueChange = (rowId, fieldId, value) => {
		const { productTable } = this.state

		if (value) {
			const updateIndex = _.findIndex(productTable, { niprod: rowId })
			if (updateIndex >= 0) {
				const updateValue = productTable[updateIndex][fieldId]
					? productTable[updateIndex][fieldId]
					: null
				if (updateValue === null) {
					return true
				} else if (String(updateValue) === String(value)) {
					return false
				}
				return true
			}
			return true
		}

		return false
	}

	/**
	 * To save and update data table to validate changes..
	 * @params id row
	 * @params fieldId
	 * @params value
	 */
	handleUpdateDataTable = (rowId, fieldId, value) => {
		const { updateDataTable } = this.state
		const updateIndex = _.findIndex(updateDataTable, { rowId })
		if (updateIndex === -1) {
			const params = { rowId }
			params[fieldId] = value
			updateDataTable.push(params)
		} else {
			updateDataTable[updateIndex][fieldId] = value
		}
		this.setState({ updateDataTable })
	}

	handleUpdateProductTable = (rowId, fieldId, value) => {
		const { productTable } = this.state
		productTable.forEach((product) => {
			if (product.niprod === rowId) {
				product[fieldId] = value
			}
		})

		this.setState({ productTable: [...productTable] })
	}

	/**
	 * To get data from update table
	 * @params rowId
	 * @params field
	 */
	getValueUpdateTable = (rowId, field) => {
		const { updateDataTable } = this.state
		const findData = _.findWhere(updateDataTable, { rowId })
		return findData ? findData[field] : null
	}

	/**
	 * To add products to cart when used insert key
	 * @params rowId
	 * @params field
	 */
	preventAddToCart = async (rowId, field) => {
		const rowData = this.getRowById(rowId)
		const stateValue = this.getValueUpdateTable(rowId, field)
		const fieldValue = stateValue || rowData[field]

		if (fieldValue && fieldValue !== 'error_input') {
			if (field === 'cantidad') {
				const { updateDataTable } = this.state
				const updateDesc = _.findWhere(updateDataTable, {
					rowId: rowData.niprod
				})

				// Call api to return price
				const params = {
					idOperacion: this.props.idOperacion,
					Idproducto: rowId,
					cantidad: fieldValue,
					unid_vta: rowData.cod_unid,
					desc_prod:
						!_.isEmpty(updateDesc) && updateDesc.desc_prod
							? updateDesc.desc_prod
							: rowData.desc_prod
				}
				if (rowData.fec_entrega) {
					params.fec_entrega = moment(rowData.fec_entrega).format('YYYY-MM-DD')
				}
				this.props.getPriceAddCart({
					params,
					apiByForm: LoadInventoryItemsActions.FORM_LOAD_ITEM_FORM
				})
			} else {
				await this.handleOnblurInput(fieldValue, field, rowData)
				this.handleAddToCart(rowData)
			}
			this.setInitRow({ Niprod: rowId })
		} else {
			this.handleAddToCart(rowData)
		}
	}

	/**
	 * to validate click event and add to cart.
	 * @param row from product
	 */
	handlePreventClickAddCart = (row) => {
		const { isUpdateRecord } = this.state
		// Set focus field neto
		handleSetFocus('neto', row[this.primaryKey], this.inputRefs)

		this.preventAddToCart(row[this.primaryKey], isUpdateRecord)
	}

	/**
	 * call api to validate price
	 * @param {*} row
	 * @param {*} newPrice
	 */
	handleSubmitPrice = (row, precio) => {
		const { idOperacion } = this.props

		this.props.validateProductPrice({
			idOperacion,
			idProducto: row.niprod,
			cantidad: row.cantidad || 0,
			unid_vta: row.cod_unid,
			precio
		})

		this.setState({ updatedRow: null, isUpdateRecord: 'modif_pcio' })
	}

	/**
	 *To render table rows
	 * @param {string} field
	 * @param {string} value
	 * @param {object} row
	 * @returns input render field
	 */
	renderFormat = (field, value, row) => {
		const {
			fieldsPriceEstTable,
			fieldsPriceTable,
			fieldsPriceForm,
			fieldsTable
		} = this.state

		const fieldId = field.idCampo.trim()
		const configPrice = _.findWhere(fieldsTable, { idCampo: 'modif_pcio' })

		let result = null
		const inputError = value === 'error_input'
		const customValue = value === 'error_input' ? '' : value
		const inputStyle =
			fieldId === 'cantidad' || fieldId === 'pcio_unit' || fieldId === 'neto'
				? { textAlign: 'right' }
				: {}

		if (field.editable && !this.inputRefs[fieldId]) {
			this.inputRefs[fieldId] = {}
		}

		if (field.editable && !this.inputRefs[fieldId][row.niprod]) {
			const customRef = React.createRef()
			this.inputRefs[fieldId][row.niprod] = customRef
			if (this.firstRefs === null) {
				this.firstRefs = customRef
			}
		}

		const optionsInput = {
			fwRef: field.editable ? this.inputRefs[fieldId][row.niprod] : null,
			inputFormCol: { sm: 12 },
			fields: [{ ...field, label: false }],
			label: false,
			inputId: `${fieldId}`,
			id: `${fieldId}-${row.niprod}`,
			name: `${fieldId}-${row.niprod}`,
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			divStyle: { paddingLeft: '10px', paddingRight: '10px' },
			disable: false,
			value: customValue,
			showError: inputError,
			styles: inputStyle,
			rowStyle: { marginBottom: '5px' },
			onBlur: () => {},
			popperPlacement: 'bottom-end',
			autoComplete: 'Off',
			handleEnterKey: (e, value) => {
				const nextField = this.getNextEditField(fieldId)

				if (fieldId === 'fec_entrega') {
					this.handleOnblurInput(value, fieldId, row)
				}
				handleSetFocus(nextField, row.niprod, this.inputRefs)
				e.preventDefault()
			},
			onChange: (value, event) => {
				this.handleUpdateDataTable(row[this.primaryKey], fieldId, value)
				if (
					fieldId === 'fec_entrega' &&
					event !== undefined &&
					event.nativeEvent.type === 'click'
				) {
					// To validate event click of calendar.
					this.handleOnblurInput(value, fieldId, row)
					const nextField = this.getNextEditField(fieldId)
					handleSetFocus(nextField, row.niprod, this.inputRefs)
				}
			}
		}

		if (fieldId === 'cod_unid') {
			const selectOptions = Array.from(
				new Set(row.presentaciones.map((s) => s.cod_pres))
			).map((cod_pres) => {
				return {
					id: cod_pres,
					label: row.presentaciones.find((s) => s.cod_pres === cod_pres)
						.cod_pres
				}
			})

			result = (
				<span
					className="d-inline-block"
					data-bs-toggle="tooltip"
					title={row[fieldId]}
				>
					<InputDropdown
						{...optionsInput}
						options={selectOptions}
						tooltip
						noInitialExecute
						onChange={(data) => {
							const { value } = data.target
							this.props.getPriceByProduct({
								idOperacion: this.props.idOperacion,
								Idproducto: row.niprod,
								cantidad: row.cantidad || 0,
								unid_vta: value,
								pcio_unit: row.pcio_unit
							})
						}}
					/>
				</span>
			)
		} else if (fieldId === 'ind_stock') {
			result = <DisplayLight title={row.stock_disp} semaforo={value} />
		} else if (fieldId === 'modif_pcio') {
			result = (
				<InputPriceUnit
					idOperacion={this.props.idOperacion}
					handleSubmitPrice={this.handleSubmitPrice}
					row={row}
					showPriceIcon={
						row.cantidad > 0 &&
						!_.isEmpty(configPrice) &&
						configPrice.visible === 1
					}
					fieldsPriceEstTable={fieldsPriceEstTable}
					fieldsPriceTable={fieldsPriceTable}
					fieldsPriceForm={fieldsPriceForm}
				/>
			)
		} else if (field.editable) {
			result = (
				<InputText
					{...optionsInput}
					autoComplete="off"
					onBlur={(value) => {
						this.handleOnblurInput(value, fieldId, row)
					}}
				/>
			)
		} else if (field.mascara) {
			result = getValueMask(value, field.mascara, this.props)
		} else {
			result = value
		}

		return result
	}

	renderExpandRow = (row) => {
		const cols = []
		let result
		if (row && row.atributos && row.atributos.length) {
			row.atributos.forEach((atrb, index) => {
				cols.push(
					<Col key={index} className={'col-6 p-2'}>
						<b>{`${atrb.desc_atrib}:`}</b> {atrb.desc_dato}
					</Col>
				)
			})
			result = <Row className={'container mt-2'}>{cols}</Row>
		}

		return result
	}

	getNoExpandRows = () => {
		const { search } = this.props
		const result = []
		if (search && search.productos) {
			search.productos.forEach((prd) => {
				if (!prd.atributos || prd.atributos.length === 0) {
					result.push(parseInt(prd.niprod))
				}
			})
		}

		return result
	}

	validateKey = (key, rows) => {
		let result = false
		rows.forEach((row) => {
			if (row === key) {
				result = true
			}
		})
		return result
	}

	getExpandRow = (row) => {
		const noExpand = this.getNoExpandRows()
		return {
			renderer: (row) => this.renderExpandRow(row),
			showExpandColumn: true,
			nonExpandable: noExpand,
			expandByColumnOnly: true,
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				return <CollapseButton status={isAnyExpands} />
			},
			expandColumnRenderer: ({ expanded, rowKey }) => {
				if (!this.validateKey(rowKey, noExpand)) {
					return <CollapseButton status={expanded} />
				}
			}
		}
	}

	/**
	 * callback used when change of pagination
	 * @param type
	 * @param pagination
	 */

	handleOnTableChange = (type, pagination) => {
		this.inputRefs = {}
		const { searchParameters } = this.props
		const { page, sizePerPage } = pagination
		this.props.searchProducts({
			...searchParameters,
			page_number: page,
			page_size: sizePerPage
		})
	}

	/**
	 * to close stock window.
	 */
	handleCloseStock = () => {
		this.setState({ showStock: false })
	}

	/**
	 * to close stock provider modal
	 */
	handleCloseStockProvider = () => {
		this.setState({ stockProviderModal: false })
	}

	/**
	 * to show and hide attributes modal
	 */
	toggleAttributesModal = () => {
		this.setState((state) => ({
			showAttributesModal: !state.showAttributesModal
		}))
	}

	render() {
		const {
			theme,
			showSearchBox,
			divClass,
			search,
			parameterConfirm,
			idOperacion,
			t
		} = this.props
		const {
			tableColumns,
			productTable,
			showStock,
			showError,
			errorMessage,
			errorTitle,
			showAttributesModal,
			referenceItem
		} = this.state

		const options = search.productos
			? {
					page: search.page_number,
					sizePerPage: search.page_size,
					totalSize: search.total_count
			  }
			: {}
		return (
			<Col sm={12}>
				<Row className={divClass}>
					<Col lg={12} md={10} className={'mb-1'}>
						<NotificationMessage
							showError={showError}
							errorMessage={errorMessage}
							errorTitle={errorTitle}
							handleCloseError={this.handleCloseError}
							type={this.state.typeNotification}
						/>
						<CommonModal
							showModal={showAttributesModal}
							handleCloseModal={this.toggleAttributesModal}
							modalTitle={t('loadItem.attributes')}
							sizeModal={'lg'}
							modalBody={
								<AttributesModal it={referenceItem} idOperacion={idOperacion} />
							}
						/>
						<StockPopup
							params={parameterConfirm}
							showStock={showStock}
							idOperacion={idOperacion}
							referenceItem={this.state.referenceItem}
							handleClose={this.handleCloseStock}
							handleCloseCartMessage={this.handleCloseError}
							handleMoveArrow={showStock ? this.props.handleMoveArrow : null}
						/>
					</Col>
					{showSearchBox && (
						<SearchBox
							idOperacion={this.props.idOperacion}
							inputRef={this.refsBeforeSearch}
							bonuses={search.bonificaciones}
						/>
					)}
					{productTable.length > 0 && (
						<Col className={`${divClass} col-12 ${theme.divContainer}`}>
							<CommonTable
								remote
								columns={tableColumns}
								data={productTable}
								keyField={'niprod'}
								rowClasses={theme.tableRow}
								headerClasses={theme.tableHeader}
								expandRow={this.getExpandRow()}
								paginationOptions={options}
								onTableChange={this.handleOnTableChange}
								wrapperClasses={theme.tableWrapper}
								isRendeTableFinish={() =>
									handleIsRendeTableFinish(this.firstRefs)
								}
							/>
						</Col>
					)}
				</Row>
			</Col>
		)
	}
}

const mapStateToProps = ({
	providers,
	product,
	loadItems,
	vouchertype,
	auth,
	stock,
	attributes
}) => {
	const { confirmedAttributes, canceledAttributes } = attributes
	const { confirmItem } = loadItems
	const {
		search,
		searchParameters,
		productsUpdate,
		focusInput,
		updateCant,
		paramsPrice,
		updateRecord
	} = product
	const { voucherTypeCancel } = vouchertype
	const { itemsCartProvider } = providers
	const { confirmedStock } = stock
	const { authUser } = auth
	return {
		search,
		searchParameters,
		productsUpdate,
		focusInput,
		updateCant,
		paramsPrice,
		voucherTypeCancel,
		authUser,
		confirmItem,
		updateRecord,
		itemsCartProvider,
		confirmedStock,
		confirmedAttributes,
		canceledAttributes
	}
}

const connectForm = connect(mapStateToProps, {
	setTableDataProducts,
	getPriceByProduct,
	searchProducts,
	getPriceAddCart,
	validateProductDiscount,
	validateProductPrice,
	validateProductNet
})(LoadItemsTable)

export default themr(
	'LoadItemsTableStyles',
	styles
)(withTranslation()(connectForm))
