import {
	crudGetCostCenterAccounts,
	crudUpdateCostCenterAccounts
} from 'api/CostCenterLedgerAccountsApi'
import {
	ICrudGetCostCenterAccountsSaga,
	ICrudUpdateCostCenterAccountsSaga
} from 'models/CrudCostCentersInterface'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
	crudGetCostCenterAccountsSuccess,
	crudUpdateCostCenterAccountsSuccess
} from '../actions'
import {
	CRUD_GET_COST_CENTER_LEDGER_ACCOUNTS,
	CRUD_UPDATE_COST_CENTER_LEDGER_ACCOUNTS
} from '../constants/ActionsTypes'

function* crudGetCostCenterLedgerAccountsReq({
	payload
}: ICrudGetCostCenterAccountsSaga): any {
	try {
		const costCenter = yield call(crudGetCostCenterAccounts, payload)
		yield put(crudGetCostCenterAccountsSuccess(costCenter))
	} catch (error) {}
}

function* crudUpdateCostCenterLedgerAccountsReq({
	payload
}: ICrudUpdateCostCenterAccountsSaga): any {
	try {
		const costCenter = yield call(crudUpdateCostCenterAccounts, payload)
		yield put(crudUpdateCostCenterAccountsSuccess(costCenter))
	} catch (error) {}
}

export function* crudGetCostCenterLedgerAccountsSaga() {
	yield takeLatest(
		CRUD_GET_COST_CENTER_LEDGER_ACCOUNTS,
		crudGetCostCenterLedgerAccountsReq
	)
}

export function* crudUpdateCostCenterLedgerAccountsSaga() {
	yield takeLatest(
		CRUD_UPDATE_COST_CENTER_LEDGER_ACCOUNTS,
		crudUpdateCostCenterLedgerAccountsReq
	)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetCostCenterLedgerAccountsSaga),
		fork(crudUpdateCostCenterLedgerAccountsSaga)
	])
}
