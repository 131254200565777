import {
	confirmInventoryPickStockSuccess,
	getCufsPickStockSuccess,
	getDepositsPickStockSuccess,
	getInventoryPickStockSuccess,
	inventoryPickStockSearchBarCodeError,
	inventoryPickStockSearchBarCodeSuccess
} from 'actions'
import {
	confirmInventoryPickStockApi,
	getCufsPickStockApi,
	getDepositsPickStockApi,
	getInventoryPickStockApi,
	inventoryPickStockSearchBarCodeApi
} from 'api/InventoryPickStock'
import { InventoryPickStockActions } from 'constants/ActionsTypesTs'
import {
	IConfirmInventoryPickStockAction,
	IConfirmInventoryPickStockResponse,
	IGetCufPickStockAction,
	IGetCufPickStockResponse,
	IGetDepositsPickStockAction,
	IGetDepositsPickStockResponse,
	IGetInventoryPickStockAction,
	IGetInventoryPickStockResponse,
	InventorySearchBarcodeAction,
	InventorySearchBarcodeResponse,
	ISearchBarcodeErrorResponse
} from 'models/InventoryPickStock'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* inventoryPickStockSearchBarCodeRequest({
	payload
}: InventorySearchBarcodeAction): any {
	try {
		const products: InventorySearchBarcodeResponse = yield call(
			inventoryPickStockSearchBarCodeApi,
			payload
		)
		yield put(inventoryPickStockSearchBarCodeSuccess(products))
	} catch (error: any) {
		const { data } = error.response
		const { Resultado } = data
		const [message, typeError] = !Resultado.Resultado
			? [Resultado.Mens_error, Resultado.Tipo_error]
			: ['', '']

		const errorParams: ISearchBarcodeErrorResponse = {
			message,
			typeError,
			requireFields: {
				ind_lote: data.ind_lote,
				ind_vto: data.ind_vto,
				ind_nserie: data.ind_nserie
			},
			dataFields: {
				lote: data.lote,
				fec_vto: data.fec_vto,
				nserie: data.nserie,
				nitem: ''
			}
		}
		yield put(inventoryPickStockSearchBarCodeError(errorParams))
	}
}

function* getInventoryPickStockRequest({
	payload
}: IGetInventoryPickStockAction): any {
	try {
		const items: IGetInventoryPickStockResponse = yield call(
			getInventoryPickStockApi,
			payload
		)
		yield put(getInventoryPickStockSuccess(items))
	} catch (error: any) {}
}

function* getDepositsPickStockRequest({
	payload
}: IGetDepositsPickStockAction): any {
	try {
		const items: IGetDepositsPickStockResponse = yield call(
			getDepositsPickStockApi,
			payload
		)
		yield put(getDepositsPickStockSuccess(items))
	} catch (error: any) {}
}

function* getCufPickStockRequest({ payload }: IGetCufPickStockAction): any {
	try {
		const items: IGetCufPickStockResponse = yield call(
			getCufsPickStockApi,
			payload
		)
		yield put(getCufsPickStockSuccess(items))
	} catch (error: any) {}
}

function* confirmInventoryPickStockRequest({
	payload
}: IConfirmInventoryPickStockAction): any {
	const { params, callBackReturn } = payload
	try {
		const items: IConfirmInventoryPickStockResponse = yield call(
			confirmInventoryPickStockApi,
			params
		)
		yield put(confirmInventoryPickStockSuccess(items))
		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error: any) {}
}

export function* inventoryPickStockSearchBarCodeSaga() {
	yield takeEvery(
		InventoryPickStockActions.INVENTORY_PICK_BAR_CODE_SEARCH,
		inventoryPickStockSearchBarCodeRequest
	)
}

export function* getInventoryPickStockSaga() {
	yield takeEvery(
		InventoryPickStockActions.GET_INVENTORY_PICK_STOCK,
		getInventoryPickStockRequest
	)
}

export function* getDepositsPickStockSaga() {
	yield takeEvery(
		InventoryPickStockActions.GET_DEPOSITS_PICK_STOCK,
		getDepositsPickStockRequest
	)
}

export function* getCufsPickStockSaga() {
	yield takeEvery(
		InventoryPickStockActions.GET_CUF_PICK_STOCK,
		getCufPickStockRequest
	)
}

export function* confirmInventoryPickStockSaga() {
	yield takeEvery(
		InventoryPickStockActions.CONFIRM_INVENTORY_PICK_STOCK,
		confirmInventoryPickStockRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(inventoryPickStockSearchBarCodeSaga),
		fork(getInventoryPickStockSaga),
		fork(getDepositsPickStockSaga),
		fork(getCufsPickStockSaga),
		fork(confirmInventoryPickStockSaga)
	])
}
