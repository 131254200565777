import NotificationError from 'components/common/notificationsErrors'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues } from 'lib/FieldValidations'
import { ICrudFieldStructure } from 'models/CrudInterface'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import DirectLAAssignationsFormField from './DirectLAAssignationsFormField'

type ComponentProps = {
	fields: Array<ICrudFieldStructure>
	handleSubmit: any
	itemInfo?: any
	disableForm?: boolean
	handleCloseModal: any
	renderFormInputs: any
	t: any
}

type ComponentState = {
	listState: boolean
}

class LAADeleteModal extends Component<ComponentProps, ComponentState> {
	constructor(props: ComponentProps) {
		super(props)
		this.state = {
			listState: false
		}
	}

	render() {
		const { fields, handleSubmit, itemInfo, disableForm, t } = this.props

		for (const key in itemInfo) {
			itemInfo[key] =
				typeof itemInfo[key] === 'string' ? itemInfo[key].trim() : itemInfo[key]
		}

		const subCategories: Array<string> = []

		_.each(
			fields,
			(field: ICrudFieldStructure, index: number, array: Array<any>) => {
				if (array[index - 1]) {
					if (field.agrupador !== array[index - 1].agrupador) {
						subCategories.push(field.agrupador)
					}
				} else {
					subCategories.push(field.agrupador)
				}
			}
		)

		const allFields = _.map(subCategories, (category: string) => {
			const id = category
			const filterFields = fields.filter((field: ICrudFieldStructure) => {
				return field.agrupador === category
			})
			return { name: id, fields: filterFields }
		})

		const firstValue =
			itemInfo && itemInfo.length > 1
				? {
						cod_cta: '*VARIOS*',
						codigo: '*VARIOS*',
						desc_cta: '*VARIOS*',
						descrip: '*VARIOS*'
				  }
				: itemInfo[0]

		const initialValues =
			firstValue || (fields.length ? getInitValues(fields) : {})
		const columnsCancel = disableForm ? 12 : 6

		return (
			<>
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						handleSubmit(values)
					}}
					enableReinitialize={true}
				>
					{(props: FormikProps<any>) => (
						<Fragment>
							<Col sm={12}>
								<NotificationError
									errors={props.errors}
									touched={props.touched}
								/>
							</Col>
							<Form>
								{_.map(allFields[0].fields, (field: ICrudFieldStructure) => {
									return (
										<DirectLAAssignationsFormField
											key={field.idCampo}
											formikProps={props}
											field={field}
											crudProps={{
												...this.props
											}}
											initialValue={
												initialValues[
													field.idCampo as keyof typeof initialValues
												]
											}
										/>
									)
								})}
								<Row className={'pt-3'}>
									{!disableForm && (
										<Col sm={6} className={'text-center mt-2'}>
											<Button style={{ minWidth: '145px' }} type={'submit'}>
												{t('Collection.form.buttons.accept')}
											</Button>
										</Col>
									)}
									<Col sm={columnsCancel} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px' }}
											type={'button'}
											onClick={this.props.handleCloseModal}
										>
											{t('Collection.form.buttons.cancel')}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					)}
				</Formik>
			</>
		)
	}
}

export default withTranslation()(withRouter(LAADeleteModal))
