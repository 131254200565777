import moment from 'moment'

export const structColumns = [
	{
		dataField: 'fecha',
		text: 'Fecha',
		align: 'center',
		headerAlign: 'center',
		headerStyle: { width: '10%' },
		formatter: (cell, row, rowIndex) => {
			return moment(cell).format('DD/MM/YYYY')
		},
		hidden: false
	},
	{
		dataField: 'cod_prod',
		text: 'Código',
		align: 'center',
		headerAlign: 'center',
		headerStyle: { width: '10%' },
		hidden: false
	},
	{
		dataField: 'desc_item',
		text: 'Descripción',
		align: 'center',
		headerAlign: 'center',
		hidden: false
	},
	{
		dataField: 'cod_unid',
		text: 'Unidad',
		align: 'center',
		headerAlign: 'center',
		headerStyle: { width: '8%' },
		hidden: false
	},
	{
		dataField: 'cant',
		text: 'Cantidad',
		align: 'center',
		headerAlign: 'center',
		headerStyle: { width: '10%' },
		hidden: false
	},
	{
		dataField: 'precio',
		text: 'Precio Unitario',
		align: 'center',
		headerAlign: 'center',
		headerStyle: { width: '10%' },
		hidden: false
	},
	{
		dataField: 'neto',
		text: 'Importe Neto',
		align: 'center',
		headerAlign: 'center',
		headerStyle: { width: '10%' },
		hidden: false
	},
	{
		dataField: 'estado',
		text: 'Estado',
		align: 'center',
		headerAlign: 'center',
		headerStyle: { width: '8%' },
		hidden: false
	},
	{
		dataField: 'cuf',
		text: 'CUF',
		align: 'center',
		headerAlign: 'center',
		headerStyle: { width: '8%' },
		hidden: false
	},
	{
		dataField: 'fec_vto',
		text: 'Fecha Vencimiento',
		align: 'center',
		headerAlign: 'center',
		headerStyle: { width: '10%' },
		hidden: false
	},
	{
		dataField: 'partida',
		text: 'Partida',
		align: 'center',
		headerAlign: 'center',
		headerStyle: { width: '10%' },
		hidden: false
	}
]
