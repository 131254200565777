import {
	ICrudGetProfileAbms,
	ICrudGetProfileAbmsResArray,
	ICrudUpdateProfileAbms
} from 'models/CrudProfilesInterface'
import { IResponseResult } from 'models/ResultsInterface'
import {
	CRUD_CLEAR_PROFILE_ABMS_STORE,
	CRUD_GET_PROFILE_ABMS,
	CRUD_GET_PROFILE_ABMS_SUCCESS,
	CRUD_UPDATE_PROFILE_ABMS,
	CRUD_UPDATE_PROFILE_ABMS_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetProfileAbms = (payload: ICrudGetProfileAbms) => {
	return {
		type: CRUD_GET_PROFILE_ABMS,
		payload
	}
}

export const crudGetProfileAbmsSuccess = (
	payload: ICrudGetProfileAbmsResArray
) => {
	return {
		type: CRUD_GET_PROFILE_ABMS_SUCCESS,
		payload
	}
}

export const crudUpdateProfileAbms = (payload: ICrudUpdateProfileAbms) => {
	return {
		type: CRUD_UPDATE_PROFILE_ABMS,
		payload
	}
}

export const crudUpdateProfileAbmsSuccess = (payload: IResponseResult) => {
	return {
		type: CRUD_UPDATE_PROFILE_ABMS_SUCCESS,
		payload
	}
}

export const crudClearProfileAbmsStore = () => ({
	type: CRUD_CLEAR_PROFILE_ABMS_STORE
})
