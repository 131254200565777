import {
	ADD_CONFIG_PENDING_VOUCHERS,
	ADD_CONFIG_PENDING_VOUCHERS_SUCCESS,
	GET_PENDING_VOUCHERS,
	GET_PENDING_VOUCHERS_SUCCESS,
	REMOVE_CONFIG_PENDING_VOUCHERS,
	REMOVE_CONFIG_PENDING_VOUCHERS_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	pendingVouchers: any
	filterAdd: any
	filterRemove: any
}

const initialState = {
	pendingVouchers: null,
	filterAdd: null,
	filterRemove: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case GET_PENDING_VOUCHERS:
			return { ...state, pendingVouchers: null }
		case GET_PENDING_VOUCHERS_SUCCESS:
			return { ...state, pendingVouchers: action.payload }
		case ADD_CONFIG_PENDING_VOUCHERS:
			return { ...state, filterAdd: null }
		case ADD_CONFIG_PENDING_VOUCHERS_SUCCESS:
			return { ...state, filterAdd: action.payload }
		case REMOVE_CONFIG_PENDING_VOUCHERS:
			return { ...state, filterRemove: null }
		case REMOVE_CONFIG_PENDING_VOUCHERS_SUCCESS:
			return { ...state, filterRemove: action.payload }
		default:
			return state
	}
}

export default rootReducer
