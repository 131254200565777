import Axios from 'axios'
import { downloadFileType } from 'lib/Utils'

export const getClientHeadboard = async (params) => {
	const response = await Axios.get('/Comprobante/vistafinal', { params })
	return response.data
}

export const changeTableItems = async (params) => {
	const response = await Axios.get('/Comprobante/vistafinal/items', { params })
	return response.data
}

export const changeTableAffect = async (params) => {
	const response = await Axios.get('/Comprobante/vistafinal/afec', { params })
	return response.data
}

export const changeCollectionTable = async (params) => {
	const response = await Axios.get('/Comprobante/vistafinal/cobranzas', {
		params
	})
	return response.data
}

export const getGenerateItems = async (params) => {
	const response = await Axios.get('/vta_generar_compr/ítems_agregados')
	return response.data
}

export const finishGenerate = async (params) => {
	const response = await Axios.post('/Comprobante/confirmar', params)
	return response.data
}

export const printGenerateFile = async (request) => {
	const { idOperacion, nameFile } = request

	const response = await Axios.get(request.url, {
		params: {
			idOperacion
		},
		responseType: 'blob'
	})

	downloadFileType(response, nameFile)
	return response.data
}

export const printGenerate = async (request) => {
	const { params } = request
	const response = await Axios.get(request.url, { params })
	return response.data
}

export const getGeneratesalesAffected = async (params) => {
	const response = await Axios.get('/vta_generar_compr/comprob_afectados')
	return response.data
}

export const printVoucher = async (params) => {
	const response = await Axios.get('/ventas/comprobante/imprimir', { params })
	return response.data
}

export const exitPrint = async (params) => {
	const response = await Axios.get('/ventas/comprobante/imprimir/salir', {
		params
	})
	return response.data
}
