import { accountingPeriodCrudConfig } from 'constants/crudsConfig/crudAccountingPeriodsConstants/crudAccountingPeriodsConfig'
import { attr_accounts_payable_dataConfig } from 'constants/crudsConfig/crudAdditionalAccountsPayableData/crudAdditionalAccountsPayableDataConfig'
import { attr_accounts_receivable_dataConfig } from 'constants/crudsConfig/crudAdditionalAccountsReceivableData/crudAdditionalAccountsReceivableDataConfig'
import { attrCustomerDataConfig } from 'constants/crudsConfig/crudAdditionalCustomerData/crudAdditionalCustomerDataConfig'
import { attrInventoryVouchersConfig } from 'constants/crudsConfig/crudAdditionalDataOfInventoryVouchers/crudAdditionalInventoryVouchersConfig'
import { attrProductDataConfig } from 'constants/crudsConfig/crudAdditionalProductData/crudAdditionalProductDataConfig'
import { attrSupplierDataConfig } from 'constants/crudsConfig/crudAdditionalSupplierData/crudAdditionalSupplierDataConfig'
import { attr_treasury_accounts_dataConfig } from 'constants/crudsConfig/crudAdditionalTreasuryAccountsData/crudAdditionalTreasuryAccountsDataConfig'
import { attr_treasury_receipts_dataConfig } from 'constants/crudsConfig/crudAdditionalTreasuryReceiptsData/crudAdditionalTreasuryAccountsDataConfig'
import { costCentersConfig } from 'constants/crudsConfig/crudCostCentersConstants/crudCostCentersConfig'
import { courierConfig } from 'constants/crudsConfig/crudCourierConstants/crudCourierConfig'
import { customerConfig } from 'constants/crudsConfig/crudCustomerConstants/crudCustomerConfig'
import { directCCAssignConfig } from 'constants/crudsConfig/crudDirectCCAssign/crudDirectCCAssignConfig'
import { directLAAssignConfig } from 'constants/crudsConfig/crudDirectLAAssign/crudDirectLAAssignConfig'
import { errorConfig } from 'constants/crudsConfig/crudError/crudErrorConfig'
import { fundsAccountsConfig } from 'constants/crudsConfig/crudFundsAccountsConstants/crudFundsAccountsConfig'
import { fundsConceptsConfig } from 'constants/crudsConfig/crudFundsConceptsConstants/crudFundsConceptsConfig'
import { ledgerAccountsConfig } from 'constants/crudsConfig/crudLedgerAccountsConstants/crudLedgerAccountsConfig'
import { messageConfig } from 'constants/crudsConfig/crudMessage/crudMessageConfig'
import { pricesListConfig } from 'constants/crudsConfig/crudPricesListConstants/crudPricesListConfig'
import { productsConfig } from 'constants/crudsConfig/crudProductsConstants/crudProductsConfig'
import { profilesConfig } from 'constants/crudsConfig/crudProfilesConstants/crudProfilesConfig'
import { providerConfig } from 'constants/crudsConfig/crudProviderConstants/crudProviderConfig'
import { purchaseConditionsConfig } from 'constants/crudsConfig/crudPurchaseConditions/crudPurchaseConditionsConfig'
import { sellerConfig } from 'constants/crudsConfig/crudSeller/crudSellerConfig'
import { termsOfSaleConfig } from 'constants/crudsConfig/crudTermsOfSale/crudTermsOfSaleConfig'
import { userConfig } from 'constants/crudsConfig/crudUserConstants/crudUserConfig'
import { voucherConfig } from 'constants/crudsConfig/crudVoucherConfigConstants/crudVoucherConfig'
import { ICrudsTypeList } from 'models/CrudInterface'

export const CrudsList: Array<ICrudsTypeList> = [
	errorConfig,

	attr_accounts_payable_dataConfig,

	attr_accounts_receivable_dataConfig,

	attrInventoryVouchersConfig,

	attrProductDataConfig,

	attrSupplierDataConfig,

	attrCustomerDataConfig,

	attr_treasury_accounts_dataConfig,

	attr_treasury_receipts_dataConfig,

	courierConfig,

	accountingPeriodCrudConfig,

	fundsConceptsConfig,

	customerConfig,

	sellerConfig,

	termsOfSaleConfig,

	purchaseConditionsConfig,

	ledgerAccountsConfig,

	costCentersConfig,

	directCCAssignConfig,

	productsConfig,

	messageConfig,

	fundsAccountsConfig,

	directLAAssignConfig,

	providerConfig,

	voucherConfig,

	profilesConfig,

	userConfig,

	pricesListConfig
]
