import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import NotificationError from 'components/common/notificationsErrors'
import { FormRefsContext } from 'context/FormRefsContext'
import { Form, Formik } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { getTableMessage, getTablePriceList } from '../../actions'
import CrudTermsOfSaleField from './CrudTermsOfSaleField'

class CrudNew extends Component {
	componentDidMount = () => {
		this.props.getTableMessage()
		this.props.getTablePriceList()
	}

	render() {
		const {
			fields,
			t,
			handleSubmit,
			itemInfo,
			disableForm,
			showWarning,
			handleWarning,
			setFormChanged,
			method
		} = this.props

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})

		const columnsCancel = disableForm ? 12 : 6
		const validationSchema = fields.length
			? getValidationSchema(fields, t, [])
			: {}

		return (
			<FormRefsContext.Consumer>
				{({ formRef, cancelButtonRef }) => (
					<Formik
						ref={formRef}
						initialValues={initialValues}
						onSubmit={(values, actions) => {
							actions.setErrors({ cuit: '' })
							handleSubmit(values)
						}}
						enableReinitialize={true}
						validationSchema={validationSchema}
					>
						{(props) => (
							<Fragment>
								<Col sm={12}>
									<NotificationError
										errors={props.errors}
										touched={props.touched}
									/>
								</Col>
								<Form
									onChange={(e) => {
										setFormChanged(true)
									}}
								>
									<Alert variant={'dark'}>
										<b>{t('CRUDS.general_use.principal')}</b>
									</Alert>
									{(method === 'add' ||
										((method === 'edit' || method === 'view') && itemInfo)) &&
										_.map(fields, (field) => {
											return (
												<CrudTermsOfSaleField
													key={field.idCampo}
													formikProps={props}
													field={field}
													crudProps={this.props}
													initialValue={initialValues[field.idCampo]}
												/>
											)
										})}
									<Row className={'pt-3'}>
										{!disableForm && (
											<Col sm={6} className={'text-center mt-2'}>
												<Button style={{ minWidth: '145px' }} type={'submit'}>
													{t('Collection.form.buttons.accept')}
												</Button>
											</Col>
										)}
										<Col sm={columnsCancel} className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={this.props.handleCloseModal}
												ref={cancelButtonRef}
											>
												{t('Collection.form.buttons.cancel')}
											</Button>
										</Col>
									</Row>
									<ConfirmModalNoButton
										modalTitle={t('CRUDS.modal.title_warning')}
										messageBody={t('CRUDS.modal.message_changes')}
										showModal={showWarning}
										handleCloseModal={() => handleWarning(false, 'close')}
										handleCancelButton={() => {
											handleWarning(false, 'cancel')
										}}
										handleSubmitButton={(e) => {
											props.handleSubmit(e)
											handleWarning(false, 'submit')
										}}
									/>
								</Form>
							</Fragment>
						)}
					</Formik>
				)}
			</FormRefsContext.Consumer>
		)
	}
}
const mapStateToProps = ({ tableCalls }) => {
	const { messageList, priceList } = tableCalls
	return { messageList, priceList }
}

const connectForm = connect(mapStateToProps, {
	getTableMessage,
	getTablePriceList
})(CrudNew)

export default withTranslation()(withRouter(connectForm))
