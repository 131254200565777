import {
	CHANGE_COLLECTION_TABLE,
	CHANGE_COLLECTION_TABLE_SUCCESS,
	CHANGE_TABLE_AFFECT,
	CHANGE_TABLE_AFFECT_SUCCESS,
	CHANGE_TABLE_ITEMS,
	CHANGE_TABLE_ITEMS_SUCCESS,
	EXIT_PRINT_VOUCHER,
	EXIT_PRINT_VOUCHER_SUCCESS,
	FINISH_GENERATE,
	FINISH_GENERATE_SUCCESS,
	GET_CLIENT_HEADBOARD,
	GET_CLIENT_HEADBOARD_SUCCESS,
	GET_GENERATE_ITEMS,
	GET_GENERATE_ITEMS_SUCCESS,
	GET_GENERATE_SALES_AFFECTED,
	GET_GENERATE_SALES_AFFECTED_SUCCESS,
	PRINT_GENERATE,
	PRINT_GENERATE_SUCCESS,
	PRINT_VOUCHER,
	PRINT_VOUCHER_SUCCESS
} from '../constants/ActionsTypes'

export const getClientHeadboard = (params) => {
	return {
		type: GET_CLIENT_HEADBOARD,
		payload: params
	}
}

export const getClientHeadboardSuccess = (response) => {
	return {
		type: GET_CLIENT_HEADBOARD_SUCCESS,
		payload: response
	}
}

export const changeTableItems = (params) => {
	return {
		type: CHANGE_TABLE_ITEMS,
		payload: params
	}
}

export const changeTableItemsSuccess = (response) => {
	return {
		type: CHANGE_TABLE_ITEMS_SUCCESS,
		payload: response
	}
}

export const changeTableAffect = (params) => {
	return {
		type: CHANGE_TABLE_AFFECT,
		payload: params
	}
}

export const changeTableAffectSuccess = (response) => {
	return {
		type: CHANGE_TABLE_AFFECT_SUCCESS,
		payload: response
	}
}

export const getGenerateItems = (params) => {
	return {
		type: GET_GENERATE_ITEMS,
		payload: params
	}
}

export const getGenerateItemsSuccess = (response) => {
	return {
		type: GET_GENERATE_ITEMS_SUCCESS,
		payload: response
	}
}

export const getGeneratesalesAffected = (params) => {
	return {
		type: GET_GENERATE_SALES_AFFECTED,
		payload: params
	}
}

export const getGeneratesalesAffectedSuccess = (response) => {
	return {
		type: GET_GENERATE_SALES_AFFECTED_SUCCESS,
		payload: response
	}
}

export const finishGenerate = (params) => {
	return {
		type: FINISH_GENERATE,
		payload: params
	}
}

export const finishGenerateSuccess = (response) => {
	return {
		type: FINISH_GENERATE_SUCCESS,
		payload: response
	}
}

export const printGenerate = (params) => {
	return {
		type: PRINT_GENERATE,
		payload: params
	}
}

export const printGenerateSuccess = (response) => {
	return {
		type: PRINT_GENERATE_SUCCESS,
		payload: response
	}
}

export const changeCollectionTable = (params) => {
	return {
		type: CHANGE_COLLECTION_TABLE,
		payload: params
	}
}

export const changeCollectionTableSuccess = (response) => {
	return {
		type: CHANGE_COLLECTION_TABLE_SUCCESS,
		payload: response
	}
}

export const printVoucher = (params) => {
	return {
		type: PRINT_VOUCHER,
		payload: params
	}
}

export const printVoucherSuccess = (response) => {
	return {
		type: PRINT_VOUCHER_SUCCESS,
		payload: response
	}
}

export const exitPrint = (params) => {
	return {
		type: EXIT_PRINT_VOUCHER,
		payload: params
	}
}

export const exitPrintSuccess = (response) => {
	return {
		type: EXIT_PRINT_VOUCHER_SUCCESS,
		payload: response
	}
}
