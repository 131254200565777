import Axios from 'axios'
import {
	IConfirmInventoryPickStockParams,
	IGetCufPickStockParams,
	IGetDepositsPickStockParams,
	IGetInventoryPickStockParams,
	InventorySearchBarcodeParams
} from 'models/InventoryPickStock'

const urlBase = '/stock/ingr_pickeo'
const urlExpense = '/inv/egr_pickeo/'
export const inventoryPickStockSearchBarCodeApi = async (
	params: InventorySearchBarcodeParams
) => {
	const response = await Axios.post(`${urlBase}/cod_barra`, params)

	return response.data
}

export const getInventoryPickStockApi = async (
	params: IGetInventoryPickStockParams
) => {
	const response = await Axios.get(urlBase, { params })
	return response.data
}

export const getDepositsPickExpensesApi = async (
	params: IGetDepositsPickStockParams
) => {
	const response = await Axios.get(`${urlExpense}depositos`, { params })
	return response.data
}

export const getCufsPickExpensesApi = async (
	params: IGetCufPickStockParams
) => {
	const response = await Axios.get(`${urlExpense}cuf`, { params })
	return response.data
}

export const confirmExpensesPickApi = async (
	params: IConfirmInventoryPickStockParams
) => {
	const response = await Axios.post(`${urlExpense}confirmar`, params)
	return response.data
}
