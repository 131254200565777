import {
	ICrudGetPurchaseConditionsDues,
	ICrudGetPurchaseConditionsDuesRes,
	ICrudUpdatePurchaseConditionsDues,
	ICrudUpdatePurchaseConditionsDuesRes
} from 'models/CrudPurchaseConditionsInterface'
import {
	CRUD_GET_PURCHASE_CONDITIONS_DUES,
	CRUD_GET_PURCHASE_CONDITIONS_DUES_SUCCESS,
	CRUD_UPDATE_PURCHASE_CONDITIONS_DUES,
	CRUD_UPDATE_PURCHASE_CONDITIONS_DUES_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetPurchaseConditionsDues = (
	payload: ICrudGetPurchaseConditionsDues
) => ({
	type: CRUD_GET_PURCHASE_CONDITIONS_DUES,
	payload
})

export const crudGetPurchaseConditionsDuesSuccess = (
	payload: ICrudGetPurchaseConditionsDuesRes
) => ({
	type: CRUD_GET_PURCHASE_CONDITIONS_DUES_SUCCESS,
	payload
})

export const crudUpdatePurchaseConditionsDues = (
	payload: ICrudUpdatePurchaseConditionsDues
) => ({
	type: CRUD_UPDATE_PURCHASE_CONDITIONS_DUES,
	payload
})

export const crudUpdatePurchaseConditionsDuesSuccess = (
	payload: ICrudUpdatePurchaseConditionsDuesRes
) => ({
	type: CRUD_UPDATE_PURCHASE_CONDITIONS_DUES_SUCCESS,
	payload
})
