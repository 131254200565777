import 'bootstrap/dist/css/bootstrap.css'
import logger from 'lib/logger'
import 'nprogress/nprogress.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'

const loadApp = async () => {
	if (process.env.REACT_APP_MOCK_ENABLED === 'true') {
		await import('__mocks__')
	}

	logger({
		module: 'App',
		type: 'info',
		msg: `environment: ${process.env.REACT_APP_NODE_ENV}`
	})

	ReactDOM.render(<App />, document.getElementById('root'))

	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: http://bit.ly/CRA-PWA
	serviceWorker.unregister()
}

loadApp()
