import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sugestedProductsFields } from 'constants/voucherConfig/SugestedProductsConfig/SugestedProductsFields'
import { renderInput } from 'lib/FormUtils'
import { ICrudCompleteFields, ICrudGenericFields } from 'models/CrudInterface'
import {
	IGetTableGenericRes,
	IGetTableGenericResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'

type SugestedProductsFieldsProps = {
	field: ICrudCompleteFields
	formikProps: any
	crudProps: any
	searchProduct: any
	isSearching: boolean
	handleIsSearching: any
	handleOpenModal?: () => void
	selectedProducts?: any
}

type SugestedProductsFieldsState = {
	dependsOn: any
	fieldConfig: ICrudGenericFields
	defaultValue?: string
	idField?: any
	value?: any
	isSearching: boolean
}

class SugestedProductsFields extends Component<
	SugestedProductsFieldsProps,
	SugestedProductsFieldsState
> {
	constructor(props: SugestedProductsFieldsProps) {
		super(props)
		this.state = {
			fieldConfig: {
				id: '',
				type: '',
				width: '',
				autoComplete: '',
				comboData: '',
				maxChars: 0,
				dependsOn: {
					id: '',
					values: [
						{
							idValue: '',
							disabled: false
						}
					]
				}
			},
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			},
			isSearching: false
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field } = this.props
		const idField = field.idCampo.trim()
		const addData = _.find(
			sugestedProductsFields,
			(field: ICrudGenericFields) => {
				return field.id === idField
			}
		)
		addData && this.setState({ dependsOn: addData.dependsOn })
		addData && this.setState({ fieldConfig: addData })
		addData && this.setState({ idField: idField })
		addData && this.setState({ defaultValue: addData.default })
	}

	// Update check for CPOS validation, and default state of dropdowns
	componentDidUpdate = (prevProps: SugestedProductsFieldsProps) => {
		const { formikProps, selectedProducts } = this.props
		const { idField, defaultValue, fieldConfig } = this.state

		if (
			fieldConfig.type !== 'combo' &&
			!formikProps.values[idField] &&
			formikProps.values[idField] !== 0 &&
			defaultValue
		) {
			formikProps.setFieldValue(idField, defaultValue)
		}

		if (
			idField === 'product_select' &&
			selectedProducts &&
			prevProps.selectedProducts !== selectedProducts
		) {
			formikProps.setFieldValue(idField, selectedProducts.mensaje)
		}
	}

	// Setup combo field if it's empty
	comboDataField = (data: IGetTableGenericResArray) => {
		const values: { cod_valor: string; desc_valor: string }[] = []
		data &&
			_.each(data.lista, (opt: IGetTableGenericRes) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	handleBlur = async () => {
		const { formikProps, handleIsSearching } = this.props
		const { idField } = this.state
		formikProps.setTouched({
			...formikProps.touched,
			[idField]: true
		})
		idField === 'desc_prod_ref' && handleIsSearching(false)
	}

	renderInputs = (
		field: ICrudCompleteFields,
		formikProps: any,
		disableForm: boolean,
		fieldConfig: ICrudGenericFields
	) => {
		const {
			crudProps,
			searchProduct,
			isSearching,
			handleIsSearching,
			handleOpenModal
		} = this.props
		const { values, setFieldValue } = formikProps
		const { dependsOn, idField } = this.state
		const { product, handleProductChange } = crudProps
		const addData = fieldConfig

		const listProducts = !_.isEmpty(product)
			? product.lista.map((opt: any) => {
					return { id: opt.codigo, label: opt.descrip }
			  })
			: []

		addData &&
			(field = {
				...field,
				tipo: addData.type,
				colInput: addData.width,
				autoComplete: addData.autoComplete,
				disabled: addData.disabled,
				onBlur: this.handleBlur
			})

		if (field.tipo === 'combo') {
			addData &&
				(field.valores = crudProps[addData.comboData as keyof typeof crudProps]
					? this.comboDataField(
							crudProps[addData.comboData as keyof typeof crudProps]
					  )
					: [])
			!formikProps.values[idField] &&
				(field.onChange = (event: any) =>
					setFieldValue(field.idCampo, event.target.value))
		} else if (field.tipo === 'check') {
			field.customProps = {
				checked: Number(values[field.idCampo]) || 0
			}
		}

		if (field.idCampo === 'desc_prod_ref') {
			field.customProps = {
				minLength: 3,
				handleSearch: searchProduct,
				handleLoading: isSearching,
				auoptions: listProducts,
				handleSelect: (product: { id: string; label: string }) => {
					formikProps.setFieldValue('desc_prod_ref', product.id)
					formikProps.setFieldValue('mascara', '')
					formikProps.setFieldValue('desc_filtro', '')
					handleIsSearching(false)
					handleProductChange(product.id)
				},
				defaultValue: '',
				disable: disableForm
			}
			field.requerido = 1
		}

		dependsOn.id !== '' &&
			_.each(dependsOn.values, (dependency) => {
				values[dependsOn.id] === dependency.idValue &&
					(field.disabled = dependency.disabled)
			})

		if (field.idCampo === 'product_select') {
			field.suffix = (
				<FontAwesomeIcon
					icon={faSearch}
					style={{ cursor: 'pointer', width: '20px', height: '20px' }}
					color={'#007bff'}
					title={'Buscar Productos'}
					onClick={() => handleOpenModal && handleOpenModal()}
				/>
			)
		}

		if (!field.editable) field.disabled = true
		if (field.visible) {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { field, formikProps, crudProps } = this.props
		const { fieldConfig } = this.state
		return (
			<Fragment>
				{this.renderInputs(
					field,
					formikProps,
					crudProps.disableForm,
					fieldConfig
				)}
			</Fragment>
		)
	}
}

export default SugestedProductsFields
