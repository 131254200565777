import { ICrudGenericFields } from 'models/CrudInterface'

export const additionalDataOfInventoryVouchersFields: Array<ICrudGenericFields> =
	[
		{
			id: 'catributo',
			type: 'text',
			width: 'col-sm-2',
			height: '',
			default: '',
			maxChars: 10,
			autoComplete: 'new-off',
			disabled: true,
			comboData: '',
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		},
		{
			id: 'datributo',
			type: 'text',
			width: 'col-sm-4',
			height: '',
			default: '',
			maxChars: 30,
			autoComplete: 'new-off',
			comboData: '',
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		},
		{
			id: 'tipo_tabla',
			type: 'combo',
			width: 'col-sm-1',
			height: '',
			default: null,
			maxChars: 99,
			autoComplete: 'new-off',
			comboData: 'tableType',
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		},
		{
			id: 'cant_dig',
			type: 'text',
			width: 'col-sm-1',
			height: '',
			default: '',
			maxChars: 99,
			autoComplete: 'new-off',
			comboData: '',
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		},
		{
			id: 'tipo',
			type: 'combo',
			width: 'col-sm-3',
			height: '',
			default: null,
			maxChars: 99,
			autoComplete: 'new-off',
			comboData: 'dataType',
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		},
		{
			id: 'tipo_alinea',
			type: 'combo',
			width: 'col-sm-3',
			height: '',
			default: null,
			maxChars: 99,
			autoComplete: 'new-off',
			comboData: 'alignmentType',
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		},
		{
			id: 'tipo_valid',
			type: 'combo',
			width: 'col-sm-3',
			height: '',
			default: null,
			maxChars: 99,
			autoComplete: 'new-off',
			comboData: 'validationTypeT',
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		},
		{
			id: 'desde',
			type: 'text',
			width: 'col-sm-3',
			height: '',
			default: '',
			maxChars: 99,
			autoComplete: 'new-off',
			comboData: '',
			dependsOn: {
				id: 'tipo_valid',
				values: [
					{
						idValue: 'R',
						disabled: false
					},
					{
						idValue: 'L',
						disabled: true
					},
					{
						idValue: 'N',
						disabled: true
					}
				]
			}
		},
		{
			id: 'hasta',
			type: 'text',
			width: 'col-sm-3',
			height: '',
			default: '',
			maxChars: 99,
			autoComplete: 'new-off',
			comboData: '',
			dependsOn: {
				id: 'tipo_valid',
				values: [
					{
						idValue: 'R',
						disabled: false
					},
					{
						idValue: 'L',
						disabled: true
					},
					{
						idValue: 'N',
						disabled: true
					}
				]
			}
		},
		{
			id: 'relleno',
			type: 'text',
			width: 'col-sm-1',
			height: '',
			default: '',
			maxChars: 99,
			autoComplete: 'new-off',
			comboData: '',
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		},
		{
			id: 'tabla',
			type: 'combo',
			width: 'col-sm-3',
			height: '',
			default: null,
			maxChars: 99,
			autoComplete: 'new-off',
			comboData: 'table',
			dependsOn: {
				id: 'tipo_valid',
				values: [
					{
						idValue: 'R',
						disabled: true
					},
					{
						idValue: 'L',
						disabled: true
					},
					{
						idValue: 'N',
						disabled: true
					},
					{
						idValue: 'T',
						disabled: false
					}
				]
			}
		},
		{
			id: 't_condic',
			type: 'text',
			width: 'col-sm-4',
			height: '',
			default: '',
			maxChars: 99,
			autoComplete: 'new-off',
			comboData: '',
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		}
	]
