import {
	CLEAR_PROVIDER_HEADER,
	CONFIRMATION_PROVIDER,
	CONFIRMATION_PROVIDER_SUCCESS,
	CONFIRM_PROVIDER_TABLE,
	CONFIRM_PROVIDER_TABLE_SUCCESS,
	GET_PROVIDER,
	GET_PROVIDER_HEADER,
	GET_PROVIDER_HEADER_SUCCESS,
	GET_PROVIDER_SUCCESS,
	ITEM_PROVIDER_ADD_TO_CART,
	ITEM_PROVIDER_ADD_TO_CART_SUCCESS,
	PROVIDER_HEADER_AUTO,
	PROVIDER_HEADER_AUTO_SUCCESS,
	PROVIDER_HEADER_CHECK_DATE,
	PROVIDER_HEADER_CHECK_DATE_SUCCESS,
	SEARCH_PROVIDERS,
	SEARCH_PROVIDERS_SUCCESS,
	VOUCHER_CANCEL_SUCCESS
} from 'constants/ActionsTypes'

const initialState = {
	provider: null,
	listProviders: null,
	confirmation: null,
	providerHeader: null,
	checkDate: null,
	itemsCartProvider: null,
	confirmTable: null
}

function rootReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PROVIDER:
			return { ...state, provider: null }
		case GET_PROVIDER_SUCCESS:
			return { ...state, provider: action.payload }
		case SEARCH_PROVIDERS:
			return { ...state, listProviders: null }
		case SEARCH_PROVIDERS_SUCCESS:
			return { ...state, listProviders: action.payload }
		case CONFIRMATION_PROVIDER:
			return { ...state, confirmation: null }
		case CONFIRMATION_PROVIDER_SUCCESS:
			return { ...state, confirmation: action.payload }
		case GET_PROVIDER_HEADER:
			return { ...state, providerHeader: null }
		case GET_PROVIDER_HEADER_SUCCESS:
			return { ...state, providerHeader: action.payload }
		case PROVIDER_HEADER_CHECK_DATE:
			return { ...state, checkDate: null }
		case PROVIDER_HEADER_CHECK_DATE_SUCCESS:
			return { ...state, checkDate: action.payload }
		case PROVIDER_HEADER_AUTO:
			return { ...state, autodata: [] }
		case PROVIDER_HEADER_AUTO_SUCCESS:
			return { ...state, autodata: action.payload }
		case ITEM_PROVIDER_ADD_TO_CART:
			return {
				...state,
				itemsCartProvider: null,
				parameterConfirm: action.payload
			}
		case ITEM_PROVIDER_ADD_TO_CART_SUCCESS:
			return { ...state, itemsCartProvider: action.payload }
		case CONFIRM_PROVIDER_TABLE:
			return { ...state, confirmTable: null }
		case CONFIRM_PROVIDER_TABLE_SUCCESS:
			return { ...state, confirmTable: action.payload }
		case CLEAR_PROVIDER_HEADER:
			return { ...initialState }
		case VOUCHER_CANCEL_SUCCESS:
			return { ...initialState }
		default:
			return state
	}
}

export default rootReducer
