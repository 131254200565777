import {
	crudClearSellerCommissionsStore,
	crudDelSellerCommission,
	crudGetSellerCommission,
	crudGetSellerCommissions,
	crudUpdateSellerCommission
} from 'actions'
import ComponentUpdateSegment from 'components/abmUtils/ComponentUpdateSegment'
import CrudTableAddButton from 'components/abmUtils/CrudTableAddButton'
import CommonModal from 'components/common/commonModal'
import CommonTable from 'components/common/commonTable'
import NotificationMessage from 'components/common/notificationMessage'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CrudTableButtons from '../abmUtils/CrudTableButtons'

const excludeFields = ['modif', 'baja']

interface ValuesTableProps extends WithTranslation {
	nivend: number
	fieldsConfig: any
	crudGetSellerCommissions: any
	crudGetSellerCommission: any
	crudUpdateSellerCommission: any
	crudDelSellerCommission: any
	crudClearSellerCommissionsStore: any
	commissionUpdated: any
	commissionRemoved: any
	t: any
	fields: any
	fieldsAlta: any
	disableForm: boolean
	commissionsList: any
	commissionValue: any
}

type ValuesTableState = {
	showEditPopup: any
	disablePopup: any
	alta: any
	errorTitle: any
	errorMessage: any
	typeNotification: any
	showError: any
	selectRow: any
	titleModal: any
}

class ValuesTable extends Component<ValuesTableProps, ValuesTableState> {
	constructor(props: ValuesTableProps) {
		super(props)
		this.state = {
			showEditPopup: false,
			disablePopup: true,
			alta: 0,
			errorTitle: '',
			errorMessage: '',
			typeNotification: 'success',
			showError: false,
			selectRow: 0,
			titleModal: ''
		}
	}

	componentDidMount = () => {
		const { nivend } = this.props
		this.props.crudGetSellerCommissions({
			page_number: 1,
			page_size: 10,
			busqueda: nivend
		})
	}

	componentDidUpdate = (prevProps: any) => {
		const { commissionUpdated, nivend, commissionRemoved, t } = this.props
		const { alta } = this.state
		if (
			prevProps.commissionUpdated !== commissionUpdated &&
			commissionUpdated
		) {
			this.props.crudGetSellerCommissions({
				page_number: 1,
				page_size: 10,
				busqueda: nivend
			})
			this.handleCloseEditPopup()
			this.setState({
				errorMessage: alta
					? t('values.success_create')
					: t('values.success_update'),
				showError: true
			})
		}

		if (
			prevProps.commissionRemoved !== commissionRemoved &&
			commissionRemoved
		) {
			this.setState({
				errorMessage: t('values.success_remove'),
				showError: true
			})
			this.props.crudGetSellerCommissions({
				page_number: 1,
				page_size: 10,
				busqueda: nivend
			})
		}
	}

	/**
	 * to get columns table.
	 * @returns object
	 */
	getColumns = () => {
		const { fields, t, disableForm } = this.props

		const rows = fields.map((field: any) => {
			const fieldId = field.idCampo.trim()
			return {
				dataField: fieldId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '10%' },
				hidden: !field.visible || excludeFields.includes(fieldId)
			}
		})

		rows.push({
			dataField: 'actions',
			text: '',
			align: 'center',
			headerAlign: 'center',
			headerStyle: { width: '5%' },
			hidden: false,
			formatter: (value: any, row: any) => (
				<CrudTableButtons
					view={this.handleViewValue}
					edit={this.handleEditValue}
					delete={this.handleRemoveValue}
					row={row}
					deleteMessage={t('CRUDS.generic.delete.body')}
					deleteAction={this.handleRemoveValue}
					deleteTitle={t('CRUDS.generic.delete.title') || t('global.delete')}
					disableForm={disableForm}
				/>
			)
		})

		return rows
	}

	/**
	 * to close popup
	 */
	handleCloseEditPopup = () => {
		this.setState({ showEditPopup: false })
	}

	/**
	 * to save or update data modal
	 */
	handleSubmitPopup = (values: any) => {
		const { alta } = this.state
		const { nivend } = this.props
		this.props.crudUpdateSellerCommission({
			alta,
			nivend,
			cod_prod: values.cod_prod,
			p_com: values.p_com
		})
	}

	handleRemoveValue = (selectedValue: any) => {
		const { cod_prod } = selectedValue
		const { nivend } = this.props
		this.props.crudDelSellerCommission({ nivend, cod_prod })
	}

	/**
	 * to show value data info.
	 * @param {*} row
	 */
	handleViewValue = (row: any) => {
		this.props.crudClearSellerCommissionsStore()

		this.setState({
			showEditPopup: true,
			disablePopup: true,
			selectRow: row,
			titleModal: 'Consultando'
		})
	}

	/**
	 * to update value
	 * @param {object} row
	 */
	handleEditValue = (row: any) => {
		const { cod_prod, nivend } = row
		this.props.crudGetSellerCommission({ cod_prod, nivend })
		this.setState({
			showEditPopup: true,
			disablePopup: false,
			selectRow: row,
			titleModal: 'Editando',
			alta: 0
		})
	}

	handleAddValue = () => {
		this.props.crudClearSellerCommissionsStore()
		this.setState({
			showEditPopup: true,
			disablePopup: false,
			selectRow: null,
			titleModal: 'Creando',
			alta: 1
		})
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	/**
	 * to handle change table
	 */
	handleChangeTable = (type: any, pagination: any) => {
		const { nivend } = this.props
		if (type === 'pagination') {
			this.props.crudGetSellerCommissions({
				page_number: pagination.page,
				page_size: pagination.sizePerPage,
				busqueda: nivend
			})
		}
	}

	render() {
		const {
			fields,
			commissionsList,
			commissionValue,
			disableForm,
			nivend,
			t,
			fieldsAlta
		} = this.props
		const {
			showEditPopup,
			disablePopup,
			selectRow,
			titleModal,
			typeNotification,
			errorTitle,
			errorMessage,
			showError
		} = this.state

		const tableColumns = fields ? this.getColumns() : []
		const propsTable = {
			remote: true,
			columns: tableColumns,
			keyField: 'nro',
			data: commissionsList ? commissionsList.lista : [],
			paginationOptions: commissionsList
				? {
						pageStartIndex: 1,
						sizePerPage: commissionsList.page_size,
						page: commissionsList.page_number,
						totalSize: commissionsList.total_count
				  }
				: {},
			onTableChange: this.handleChangeTable
		}

		const propsEdit = {
			fields: fieldsAlta,
			keyField: 'cod_prod',
			alta: this.state.alta,
			niclie: nivend,
			itemInfo: commissionValue || selectRow,
			disableForm: disablePopup,
			handleSubmit: this.handleSubmitPopup,
			handleCloseModal: this.handleCloseEditPopup
		}

		const propsEditModal = {
			showModal: showEditPopup,
			handleCloseModal: this.handleCloseEditPopup,
			modalTitle: titleModal,
			modalBody: <ComponentUpdateSegment {...propsEdit} />,
			buttons: false,
			handleSubmit: this.handleSubmitPopup,
			sizeModal: 'xl'
		}

		return (
			<Fragment>
				<Col sm={12} className={'mb-1'}>
					<NotificationMessage
						errorTitle={errorTitle}
						errorMessage={errorMessage}
						showError={showError}
						handleCloseError={this.handleCloseError}
						type={typeNotification}
					/>
				</Col>
				{!disableForm && (
					<CrudTableAddButton
						clickAction={this.handleAddValue}
						title={t('CRUDS.general_use.additional_tab')}
					/>
				)}
				<Row>
					<CommonTable {...propsTable} />
					<CommonModal {...propsEditModal} />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ sellerCommissions }: any) => {
	const {
		commissionsList,
		commissionValue,
		commissionUpdated,
		commissionRemoved
	} = sellerCommissions

	return {
		commissionsList,
		commissionValue,
		commissionUpdated,
		commissionRemoved
	}
}

const connectForm = connect(mapStateToProps, {
	crudClearSellerCommissionsStore,
	crudGetSellerCommission,
	crudGetSellerCommissions,
	crudDelSellerCommission,
	crudUpdateSellerCommission
})(ValuesTable)
export default withTranslation()(withRouter(connectForm))
