import { HeaderEntryActions } from 'constants/ActionsTypesTs'
import {
	IConfirmAttributesPayload,
	IConfirmHeaderAttributesAction,
	IConfirmHeaderAttributesSuccessAction,
	IGetFormAttributesAction,
	IGetFormAttributesParams,
	IGetFormAttributesResponse,
	IGetFormAttributesSuccessAction
} from 'models/HeaderEntry'
import { IResponseResult } from 'models/ResultsInterface'

export const getFormAttributes = (
	payload: IGetFormAttributesParams
): IGetFormAttributesAction => ({
	type: HeaderEntryActions.GET_FORM_ATTRIBUTES,
	payload
})

export const getFormAttributesSuccess = (
	payload: IGetFormAttributesResponse
): IGetFormAttributesSuccessAction => ({
	type: HeaderEntryActions.GET_FORM_ATTRIBUTES_SUCCESS,
	payload
})

export const confirmHeaderAttributes = (
	payload: IConfirmAttributesPayload
): IConfirmHeaderAttributesAction => ({
	type: HeaderEntryActions.CONFIRM_ATTRIBUTES,
	payload
})

export const confirmHeaderAttributesSuccess = (
	payload: IResponseResult
): IConfirmHeaderAttributesSuccessAction => ({
	type: HeaderEntryActions.CONFIRM_ATTRIBUTES_SUCCESS,
	payload
})
