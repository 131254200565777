import {
	crudAcceptProductCode,
	crudGetProductCodeConfig,
	crudValidateProductCode,
	getConfigFieldsCrud,
	getTableBarcodeFormat,
	getTableCostAccountingType,
	getTableCUF,
	getTableCurrencies,
	getTableForeignCurrencies,
	getTableItemCat,
	getTableMeasurementUnit,
	getTableNumerators,
	getTableProductCategory,
	getTableTermsOfSales
} from 'actions'
import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import NotificationError from 'components/common/notificationsErrors'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { ICrudCompleteFields } from 'models/CrudInterface'
import {
	ICrudCreateProductCode,
	ICrudGetProductCodeConfig
} from 'models/CrudProductsInterface'
import {
	IGetTableBarcodeFormatResArray,
	IGetTableCostAccountingTypeResArray,
	IGetTableCUF,
	IGetTableCUFResArray,
	IGetTableForeignCurrenciesResArray,
	IGetTableItemCatResArray,
	IGetTableMeasurementUnitResArray,
	IGetTableNumeratorsResArray,
	IGetTableProductCategoryResArray,
	IGetTableTermOfSalesResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { principalFields } from '../../constants/crudsConfig/crudProductsConstants/CrudProductFormFields'
import CrudProductDataField from './CrudProductDataField'
import { ISubGroup } from './CrudProductTabsDataContents'

interface CrudProductDataProps extends WithTranslation {
	getConfigFieldsCrud: any
	getTableNumerators: (payload: any) => { type: string; payload: any }
	getTableTermsOfSales: (payload: any) => { type: string; payload: any }
	getTableProductCategory: (payload: any) => { type: string; payload: any }
	getTableItemCat: (payload: any) => { type: string; payload: any }
	getTableMeasurementUnit: (payload: any) => { type: string; payload: any }
	getTableBarcodeFormat: (payload: any) => { type: string; payload: any }
	getTableCostAccountingType: (payload: any) => { type: string; payload: any }
	getTableForeignCurrencies: (payload: any) => { type: string; payload: any }
	getTableCurrencies: (payload: any) => { type: string; payload: any }
	getTableCUF: (payload: IGetTableCUF) => { type: string; payload: any }
	crudGetProductCodeConfig: (payload: ICrudGetProductCodeConfig) => void
	crudValidateProductCode: (payload: ICrudCreateProductCode) => void
	crudAcceptProductCode: (payload: ICrudCreateProductCode) => void
	CUF: IGetTableCUFResArray
	numerators: IGetTableNumeratorsResArray
	termsOfSales: IGetTableTermOfSalesResArray
	productCategory: IGetTableProductCategoryResArray
	barcodeFormat: IGetTableBarcodeFormatResArray
	costAccountingType: IGetTableCostAccountingTypeResArray
	itemCategory: IGetTableItemCatResArray
	measurementUnit: IGetTableMeasurementUnitResArray
	foreignCurrencies: IGetTableForeignCurrenciesResArray
	currencies: IGetTableForeignCurrenciesResArray
	disableForm: boolean
	fields: Array<ICrudCompleteFields>
	showMessage: any
	t: any
	handleSubmit: any
	itemInfo?: any
	handleCloseModal: any
	method: string
	handleWarning: any
	showWarning: boolean
	setFormChanged: any
	productCodeAccepted: any
	subGroups: Array<ISubGroup>
}

type CrudProductDataState = {
	showCodePopup: boolean
	values?: any
	isSearching: boolean
}

class CrudProductData extends Component<
	CrudProductDataProps,
	CrudProductDataState
> {
	constructor(props: CrudProductDataProps) {
		super(props)
		this.state = {
			showCodePopup: false,
			isSearching: false
		}
	}

	// Initial dropdown setup
	componentDidMount = () => {
		this.props.getTableTermsOfSales({ incluye_ninguna: 1 })
		this.props.getTableBarcodeFormat({ incluye_ninguno: 1 })
		this.props.getTableCostAccountingType(null)
		this.props.getTableItemCat({ por_defecto: 'ITG' })
		this.props.getTableMeasurementUnit({ por_defecto: 'UN' })
		this.props.getTableNumerators({ incluye_ninguno: 1 })
		this.props.getTableProductCategory(null)
		this.props.getTableForeignCurrencies(null)
		this.props.getTableCurrencies({ por_defecto: 'usual' })
	}

	componentDidUpdate = (prevProps: CrudProductDataProps) => {
		const { productCodeAccepted } = this.props
		const { productCodeAccepted: prevProductCodeAccepted } = prevProps
		if (
			productCodeAccepted &&
			productCodeAccepted !== prevProductCodeAccepted
		) {
			this.setState({ showCodePopup: false })
		}
	}

	handleCodePopup = (showCodePopup: boolean) => {
		const { productCategory } = this.props
		productCategory && this.setState({ showCodePopup })
	}

	handleSearchCUF = (value: string) => {
		const { getTableCUF } = this.props
		getTableCUF({ criterio_cuf: value })
		this.handleIsSearching(true)
	}

	handleIsSearching = (isSearching: boolean) => {
		this.setState({ isSearching })
	}

	render() {
		const {
			fields,
			t,
			handleSubmit,
			itemInfo,
			disableForm,
			handleWarning,
			showWarning,
			setFormChanged,
			method,
			subGroups
		} = this.props

		const { showCodePopup, isSearching } = this.state

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})

		const columnsCancel = disableForm ? 12 : 6
		const validationSchema = fields.length
			? getValidationSchema(fields, t, [], [])
			: {}

		const allFields = _.map(subGroups, (subGroup) => {
			const id = subGroup.groupId
			const filterFields = fields.filter((field: ICrudCompleteFields) => {
				return field.agrupador === subGroup.groupId
			})
			return { name: id, fields: filterFields, rights: subGroup.accessRight }
		})

		return (
			<Fragment>
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						handleSubmit(values)
					}}
					enableReinitialize={true}
					validationSchema={validationSchema}
				>
					{(props: FormikProps<any>) => (
						<Fragment>
							<Col sm={12}>
								<NotificationError
									errors={props.errors}
									touched={props.touched}
								/>
							</Col>
							<Form
								onChange={(e) => {
									setFormChanged(true) // used by confirm modal to check changed status
								}}
							>
								{(method === 'add' ||
									((method === 'edit' || method === 'view') && itemInfo)) &&
									_.map(
										allFields,
										(category: {
											name: string
											fields: Array<ICrudCompleteFields>
											rights: string
										}) => {
											return (
												<Fragment key={category.name}>
													{category.rights !== 'N' && (
														<Alert variant={'dark'}>
															<b>
																{t(
																	'CRUDS.general_use.' +
																		category.name.replace('.', '')
																)}
															</b>
														</Alert>
													)}
													<Row>
														{_.map(
															category.fields,
															(field: ICrudCompleteFields) => {
																const selectedFieldConfig =
																	principalFields.find((fieldConfig) => {
																		return fieldConfig.id === field.idCampo
																	})
																return (
																	<Col
																		className={
																			(selectedFieldConfig &&
																				selectedFieldConfig.height) ||
																			'col-sm-12'
																		}
																	>
																		<CrudProductDataField
																			key={field.idCampo}
																			formikProps={props}
																			field={field}
																			showCodePopup={showCodePopup}
																			handleCodePopup={this.handleCodePopup}
																			isSearching={isSearching}
																			searchCUF={this.handleSearchCUF}
																			handleIsSearching={this.handleIsSearching}
																			crudProps={{
																				...this.props
																			}}
																			initialValue={
																				initialValues[
																					field.idCampo as keyof typeof initialValues
																				]
																			}
																			accessRights={category.rights}
																		/>
																	</Col>
																)
															}
														)}
													</Row>
												</Fragment>
											)
										}
									)}
								<Row className={'pt-3'}>
									{!disableForm && (
										<Col sm={6} className={'text-center mt-2'}>
											<Button style={{ minWidth: '145px' }} type={'submit'}>
												{t('Collection.form.buttons.accept')}
											</Button>
										</Col>
									)}
									<Col sm={columnsCancel} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px' }}
											type={'button'}
											onClick={this.props.handleCloseModal}
										>
											{t('Collection.form.buttons.cancel')}
										</Button>
									</Col>
								</Row>
								<ConfirmModalNoButton
									modalTitle={t('CRUDS.modal.title_warning')}
									messageBody={t('CRUDS.modal.message_changes')}
									showModal={showWarning}
									handleCloseModal={() => handleWarning(false, 'close')}
									handleCancelButton={() => {
										handleWarning(false, 'cancel')
									}}
									handleSubmitButton={(e: any) => {
										props.handleSubmit(e as any)
										handleWarning(false, 'submit')
									}}
								/>
							</Form>
						</Fragment>
					)}
				</Formik>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ tableCalls, productCode }: any) => {
	const {
		numerators,
		itemCategory,
		costAccountingType,
		barcodeFormat,
		productCategory,
		termsOfSales,
		measurementUnit,
		foreignCurrencies,
		currencies,
		CUF
	} = tableCalls
	const { productCodeAccepted } = productCode
	return {
		numerators,
		itemCategory,
		costAccountingType,
		barcodeFormat,
		productCategory,
		termsOfSales,
		measurementUnit,
		foreignCurrencies,
		currencies,
		CUF,
		productCodeAccepted
	}
}

const connectForm = connect(mapStateToProps, {
	getConfigFieldsCrud,
	getTableNumerators,
	getTableTermsOfSales,
	getTableProductCategory,
	getTableItemCat,
	getTableMeasurementUnit,
	getTableBarcodeFormat,
	getTableCostAccountingType,
	getTableForeignCurrencies,
	getTableCurrencies,
	getTableCUF,
	crudGetProductCodeConfig,
	crudValidateProductCode,
	crudAcceptProductCode
})(CrudProductData)

export default withTranslation()(withRouter(connectForm))
