import {
	CLEAR_PRODUCT_STORE,
	DELETE_SEARCHED_PRODUCTS,
	GET_DETAIL_MAIN_DATA,
	GET_DETAIL_MAIN_DATA_SUCCESS,
	GET_DETAIL_SALES_DATA,
	GET_DETAIL_SALES_DATA_SUCCESS,
	GET_DETAIL_SHOPPING_DATA,
	GET_DETAIL_SHOPPING_DATA_SUCCESS,
	GET_DETAIL_STOCK,
	GET_DETAIL_STOCK_SUCCESS,
	GET_EQUIVALENT_DATA,
	GET_EQUIVALENT_DATA_SUCCESS,
	GET_FILTER_PRODUCT_ATTRIBUTES,
	GET_FILTER_PRODUCT_ATTRIBUTES_SUCCESS,
	GET_PERIOD_FILTER,
	GET_PERIOD_FILTER_SUCCESS,
	GET_PRODUCT_ORDER_OPTIONS,
	GET_PRODUCT_ORDER_OPTIONS_SUCCESS,
	GET_STOCK_ORDER_OPTIONS,
	GET_STOCK_ORDER_OPTIONS_SUCCESS,
	GET_SUGGESTED_DATA,
	GET_SUGGESTED_DATA_SUCCESS,
	PRODUCT_CATEGORY_FILTER,
	PRODUCT_CATEGORY_FILTER_SUCCESS,
	SELECT_SEARCHED_PRODUCTS,
	SELECT_SEARCHED_PRODUCTS_SUCCESS
} from 'constants/ActionsTypes'
import {
	IDeleteSearchedProductsParams,
	IGetMainDataAction,
	IGetMainDataParams,
	IGetSalesDataAction,
	IGetSalesDataParams,
	IGetStockAction,
	IGetStockParams,
	IGetSuggestAction,
	IGetSuggestParams,
	IResponseApiOptions,
	ISelectSearchedProductAction,
	ISelectSearchedProductParams,
	ISelectSearchedProductResponse,
	ISelectSearchedProductSuccessAction
} from 'models/SearchProduct'

export const getProductsOrderOptions = () => {
	return {
		type: GET_PRODUCT_ORDER_OPTIONS
	}
}

export const getProductsOrderOptionsSuccess = (
	payload: IResponseApiOptions
) => ({
	type: GET_PRODUCT_ORDER_OPTIONS_SUCCESS,
	payload
})

export const productCategoryFilter = () => {
	return {
		type: PRODUCT_CATEGORY_FILTER
	}
}

export const productCategoryFilterSuccess = (payload: IResponseApiOptions) => ({
	type: PRODUCT_CATEGORY_FILTER_SUCCESS,
	payload
})

export const getFilterProductAttributes = () => {
	return {
		type: GET_FILTER_PRODUCT_ATTRIBUTES
	}
}

export const getFilterProductAttributesSuccess = (
	payload: IResponseApiOptions
) => ({
	type: GET_FILTER_PRODUCT_ATTRIBUTES_SUCCESS,
	payload
})

export const getDetailMainData = (
	payload: IGetMainDataParams
): IGetMainDataAction => {
	return {
		type: GET_DETAIL_MAIN_DATA,
		payload
	}
}

export const getDetailMainDataSuccess = (payload: any) => ({
	type: GET_DETAIL_MAIN_DATA_SUCCESS,
	payload
})

export const getDetailSales = (
	payload: IGetSalesDataParams
): IGetSalesDataAction => {
	return {
		type: GET_DETAIL_SALES_DATA,
		payload
	}
}

export const getDetailSalesSuccess = (payload: any) => ({
	type: GET_DETAIL_SALES_DATA_SUCCESS,
	payload
})

export const getDetailShopping = (
	payload: IGetSalesDataParams
): IGetSalesDataAction => {
	return {
		type: GET_DETAIL_SHOPPING_DATA,
		payload
	}
}

export const getDetailShoppingSuccess = (payload: any) => ({
	type: GET_DETAIL_SHOPPING_DATA_SUCCESS,
	payload
})

export const getDetailStock = (payload: IGetStockParams): IGetStockAction => {
	return {
		type: GET_DETAIL_STOCK,
		payload
	}
}

export const getDetailStockSuccess = (payload: any) => ({
	type: GET_DETAIL_STOCK_SUCCESS,
	payload
})

export const getStockOrderOptions = () => {
	return {
		type: GET_STOCK_ORDER_OPTIONS
	}
}

export const getStockOrderOptionsSuccess = (payload: IResponseApiOptions) => ({
	type: GET_STOCK_ORDER_OPTIONS_SUCCESS,
	payload
})

export const getPeriodFilter = () => {
	return {
		type: GET_PERIOD_FILTER
	}
}

export const getPeriodFilterSuccess = (payload: IResponseApiOptions) => ({
	type: GET_PERIOD_FILTER_SUCCESS,
	payload
})

export const getSuggestedData = (
	payload: IGetSuggestParams
): IGetSuggestAction => {
	return {
		type: GET_SUGGESTED_DATA,
		payload
	}
}

export const getSuggestedDataSuccess = (payload: any) => ({
	type: GET_SUGGESTED_DATA_SUCCESS,
	payload
})

export const getEquivalentData = (
	payload: IGetSuggestParams
): IGetSuggestAction => {
	return {
		type: GET_EQUIVALENT_DATA,
		payload
	}
}

export const getEquivalentDataSuccess = (payload: any) => ({
	type: GET_EQUIVALENT_DATA_SUCCESS,
	payload
})

export const clearProductStore = () => ({
	type: CLEAR_PRODUCT_STORE
})

export const selectSearchedProducts = (
	payload: ISelectSearchedProductParams
): ISelectSearchedProductAction => ({
	type: SELECT_SEARCHED_PRODUCTS,
	payload
})

export const selectSearchedProductsSuccess = (
	payload: ISelectSearchedProductResponse
): ISelectSearchedProductSuccessAction => ({
	type: SELECT_SEARCHED_PRODUCTS_SUCCESS,
	payload
})

export const deleteSearchedProducts = (
	payload: IDeleteSearchedProductsParams
) => ({
	type: DELETE_SEARCHED_PRODUCTS,
	payload
})
