import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			width: 145,
			height: 32,
			borderRadius: 8,
			fontSize: 14,
			textTransform: 'initial'
		},
		input: {
			display: 'none'
		}
	})
)
