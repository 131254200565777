import Axios from 'axios'

export const getCollections = async (params) => {
	const response = await Axios.get('/Ventas/Cobranzas', { params })
	return response.data
}

export const validatePayment = async (params) => {
	const response = await Axios.post('/Fondos/Cobranza/Validar', params)
	return response.data
}

export const confirmPayment = async (params) => {
	const response = await Axios.post('/Ventas/Cobranzas/Confirmar', params)
	return response.data
}

export const removePayment = async (params) => {
	const response = await Axios.post('/Fondos/Cobranzas/Eliminar', params)
	return response.data
}

export const getSelDocuments = async (params) => {
	const response = await Axios.get('/Fondos/Cobranzas/SelDoc', { params })
	return response.data
}

export const getImpDocuments = async (params) => {
	const response = await Axios.get('/Fondos/Cobranzas/impo_doc', { params })
	return response.data
}

export const confirmSelDocuments = async (params) => {
	const response = await Axios.post(
		'/Fondos/Cobranzas/SelDoc/confirmar',
		params
	)
	return response.data
}

export const validateSelDocuments = async (params) => {
	const response = await Axios.post('/Fondos/Cobranzas/SelDoc/validar', params)
	return response.data
}

export const searchBank = async (params) => {
	const response = await Axios.get('/Bancos/Seleccion', { params })
	return response.data
}

export const collectionImportDoc = async (params) => {
	const response = await Axios.post(
		'/Fondos/cobranzas/impo_doc/importar',
		params
	)
	return response.data
}

export const confirmImpDocuments = async (params) => {
	const response = await Axios.post(
		'/Fondos/cobranzas/impo_doc/confirmar',
		params
	)
	return response.data
}

export const cancelSelDocuments = async (params) => {
	const response = await Axios.post('/Fondos/Cobranzas/SelDoc/cancelar', params)
	return response.data
}
