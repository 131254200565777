import {
	crudGetProviderObservationSuccess,
	crudUpdateProviderObservationSuccess
} from 'actions'
import {
	crudGetProviderObservation,
	crudUpdateProviderObservation
} from 'api/ProviderObservationApi'
import {
	CRUD_GET_PROVIDER_OBSERVATION,
	CRUD_UPDATE_PROVIDER_OBSERVATION
} from 'constants/ActionsTypes'
import {
	ICrudGetProviderObservationSaga,
	ICrudUpdateProviderObservationSaga
} from 'models/CrudProviderInterface'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* crudGetProviderObservationRequest({
	payload
}: ICrudGetProviderObservationSaga): any {
	try {
		const values: any = yield call(crudGetProviderObservation, payload)
		yield put(crudGetProviderObservationSuccess(values))
	} catch (error) {}
}

function* crudUpdateProviderObservationRequest({
	payload
}: ICrudUpdateProviderObservationSaga): any {
	try {
		const values: any = yield call(crudUpdateProviderObservation, payload)
		yield put(crudUpdateProviderObservationSuccess(values))
	} catch (error) {}
}

export function* crudGetProviderObservationSaga() {
	yield takeEvery(
		CRUD_GET_PROVIDER_OBSERVATION,
		crudGetProviderObservationRequest
	)
}

export function* crudUpdateProviderObservationSaga() {
	yield takeEvery(
		CRUD_UPDATE_PROVIDER_OBSERVATION,
		crudUpdateProviderObservationRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetProviderObservationSaga),
		fork(crudUpdateProviderObservationSaga)
	])
}
