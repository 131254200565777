import CrudCustomerTabsDataContents from 'components/crudCustomerData/CrudCustomerTabsDataContents'
import CrudSearchCustomerForm from 'components/crudCustomerData/SearchForm/CrudSearchCustomerForm'
import { CRUD_CUSTOMER_DATA } from 'utils/RoutePath'

export const customerConfig = {
	id: 'customerCrud',
	key: 'niclie',
	descrip: 'rsoc',
	searchMethod: 'post',
	category: 'A',
	translation: 'customer_data',
	dataUrl: CRUD_CUSTOMER_DATA,
	urlApi: 'abm_vta_clientes',
	component: CrudCustomerTabsDataContents,
	searchComponent: CrudSearchCustomerForm
}
