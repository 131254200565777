import {
	getVoucherTaxesSuccess,
	taxesConfirmSuccess,
	taxesValidateRowSuccess
} from 'actions'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
	getVoucherTaxes,
	taxesConfirm,
	taxesValidateRow
} from '../api/AccountingVoucher'
import {
	GET_VOUCHER_TAXES,
	TAXES_CONFIRM,
	TAXES_VALIDATE_ROW
} from '../constants/ActionsTypes'

function* getVoucherTaxesRequest({ payload }) {
	try {
		const taxes = yield call(getVoucherTaxes, payload)
		yield put(getVoucherTaxesSuccess(taxes))
	} catch (error) {}
}

function* taxesValidateRowRequest({ payload }) {
	try {
		const validate = yield call(taxesValidateRow, payload)
		yield put(taxesValidateRowSuccess(validate))
	} catch (error) {}
}

function* taxesConfirmRequest({ payload }) {
	const { idOperacion, callBackReturn } = payload
	try {
		const confirm = yield call(taxesConfirm, { idOperacion })
		callBackReturn()
		yield put(taxesConfirmSuccess(confirm))
	} catch (error) {}
}

export function* getVoucherTaxesSaga() {
	yield takeLatest(GET_VOUCHER_TAXES, getVoucherTaxesRequest)
}

export function* taxesValidateRowSaga() {
	yield takeLatest(TAXES_VALIDATE_ROW, taxesValidateRowRequest)
}

export function* taxesConfirmSaga() {
	yield takeLatest(TAXES_CONFIRM, taxesConfirmRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getVoucherTaxesSaga),
		fork(taxesValidateRowSaga),
		fork(taxesConfirmSaga)
	])
}
