import Axios from 'axios'

export const getProductStock = async (params) => {
	const response = await Axios.get('/Stock/sel_partida/egresos', { params })
	return response.data
}

export const validateProductStock = async (params) => {
	const response = await Axios.post(
		'/Stock/sel_partida/egresos/validar',
		params
	)
	return response.data
}

export const confirmProductStock = async (params) => {
	const response = await Axios.post(
		'/Stock/sel_partida/egresos/confirmar',
		params
	)
	return response.data
}

export const cancelProductStock = async (params) => {
	const response = await Axios.post(
		'/Stock/sel_partida/egresos/cancelar',
		params
	)
	return response.data
}

export const searchBarcode = async (params) => {
	const response = await Axios.post(
		'/Stock/sel_partida/egresos/busq_nserie',
		params
	)
	return response.data
}
