import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'

interface ButtonProps extends WithTranslation {
	clickAction: any
	title?: any
	t?: any
	customIcon?: any
}

class CrudTableAddButton extends Component<ButtonProps> {
	constructor(props: ButtonProps) {
		super(props)
		this.state = {}
	}

	render() {
		const { title, t, customIcon } = this.props
		return (
			<Row>
				<Col className="text-right">
					<FontAwesomeIcon
						icon={customIcon || faPlusCircle}
						style={{ cursor: 'pointer' }}
						color={'#007bff'}
						title={
							title
								? t('CRUDS.general_use.new_with_title', { title: title })
								: t('CRUDS.general_use.new')
						}
						onClick={this.props.clickAction}
					/>
				</Col>
			</Row>
		)
	}
}

export default withTranslation()(CrudTableAddButton)
