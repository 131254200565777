import {
	pickStockImport,
	pickStockImportConfirm,
	pickStockTable
} from 'actions'
import bsCustomFileInput from 'bs-custom-file-input'
import CommonModal from 'components/common/commonModal'
import NotificationMessage from 'components/common/notificationMessage'
import InputButton from 'components/form/inputButton'
import {
	PICK_STOCK_IMPORT_CONFIRM_ERROR,
	PICK_STOCK_IMPORT_ERROR
} from 'constants/ActionsTypes'
import { addPrevValidations } from 'lib/AxiosInterceptors'
import React, { Component, Fragment } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PickStockImportTable from './PickStockImportTable'
import styles from './ProviderPickStockImport.module.scss'
import ProviderPickStockTotals from './ProviderPickStockTotals'

class ProviderPickStockImport extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showImportModal: false,
			fileUpload: null,
			nameFile: null,
			showError: false,
			errorMessage: '',
			typeError: 'danger',
			pickStockImportData: null
		}

		addPrevValidations((error) =>
			error.request.responseURL.includes('stock/ingresos/impo_mov/importar')
		)
		addPrevValidations((error) =>
			error.request.responseURL.includes('stock/ingresos/impo_mov/confirmar')
		)
	}

	componentDidMount = () => {
		bsCustomFileInput.init()
	}

	componentDidUpdate = (prevProps) => {
		const { pickStockImportError, importConfirmError, pickStockImportData } =
			this.props

		if (
			pickStockImportError !== prevProps.pickStockImportError &&
			pickStockImportError &&
			pickStockImportError.error === PICK_STOCK_IMPORT_ERROR
		) {
			this.setState({
				showError: true,
				errorMessage: pickStockImportError.messageError
			})
		}

		if (
			importConfirmError !== prevProps.importConfirmError &&
			importConfirmError &&
			importConfirmError.error === PICK_STOCK_IMPORT_CONFIRM_ERROR
		) {
			this.setState({
				showError: true,
				errorMessage: importConfirmError.messageError
			})
		}

		if (
			prevProps.pickStockImportData !== pickStockImportData &&
			pickStockImportData
		) {
			this.setState({ pickStockImportData })
		}
	}

	/**
	 * to close import modal and reset totals value
	 */
	handleCloseModal = () => {
		this.setState({
			showImportModal: false,
			fileUpload: null,
			nameFile: '',
			pickStockImportData: null,
			showError: false
		})
	}

	/**
	 * when the input file change
	 * @param {event} event
	 */
	handleFileChange = (event) => {
		const fileUpload = event.target.files[0]
		this.setState({ fileUpload, nameFile: fileUpload.name })
	}

	/**
	 * to call api from import pick stock file.
	 */
	handleImportFile = () => {
		const { idOperacion } = this.props
		const { fileUpload } = this.state
		this.props.pickStockImport({ idOperacion, archivo: fileUpload })
	}

	/**
	 * to call api when use pagination options of table.
	 * @param {string} type
	 * @param {object} pagination
	 */
	handleChangeTable = (type, pagination) => {
		const { idOperacion, nicfondo, cat_item } = this.props
		if (type === 'pagination') {
			this.props.pickStockTable({
				idOperacion,
				page_size: pagination.sizePerPage,
				page_number: pagination.page,
				mostrar: 'T',
				nicfondo,
				cat_item
			})
		}
	}

	/**
	 * call api to confirm import
	 */
	handleConfirmForm = () => {
		const { idOperacion } = this.props
		this.props.pickStockImportConfirm({ idOperacion })
		this.handleCloseModal()
	}

	/**
	 * to close notification error.
	 */
	handleCloseErrorNotification = () => {
		this.setState({ showError: false })
	}

	/**
	 * call api to cal
	 */
	handleCancelForm = () => {}

	/**
	 * to open modal
	 */
	handleOpenModal = () => {
		// const { idOperacion } = this.props
		this.setState({ showImportModal: true })
		// this.props.pickStockTable({ idOperacion, page_size: 10, page_number: 1 });
	}

	/**
	 * to render import form
	 */
	renderForm = () => {
		const { t, idOperacion } = this.props
		const {
			nameFile,
			showError,
			errorMessage,
			typeError,
			pickStockImportData
		} = this.state

		return (
			<Container>
				<Row>
					<Col sm={12}>
						<NotificationMessage
							showError={showError}
							errorMessage={errorMessage}
							type={typeError}
							handleCloseError={this.handleCloseErrorNotification}
						/>
					</Col>

					<Col sm={8}>
						<Row className={'d-flex justify-content-between'}>
							<Col>
								<div className="custom-file">
									<input
										id="inputGroupFile01"
										ref={this.rfsFile}
										accept=".csv"
										type="file"
										className="custom-file-input"
										lang="es"
										onChange={this.handleFileChange}
										filename={'nameFile'}
									/>
									<label
										className="custom-file-label"
										data-browse={t('Collection.form.buttons.select')}
										htmlFor="inputGroupFile01"
									>
										{nameFile || t('Collection.import.choose_file')}
									</label>
								</div>
							</Col>
							<Col sm={4}>
								<InputButton
									valueButton={t('Collection.form.buttons.import')}
									type={'primary'}
									onClick={this.handleImportFile}
									customStyle={{ minWidth: '145px' }}
								/>
							</Col>
						</Row>
					</Col>
					<Col sm={4}>
						<Row className="justify-content-md-center">
							<Col>
								<InputButton
									valueButton={t('Collection.form.buttons.accept')}
									type={'primary'}
									onClick={this.handleConfirmForm}
									customStyle={{ minWidth: '145px' }}
								/>
							</Col>
							<Col className={'pr-0'}>
								<InputButton
									valueButton={t('Collection.form.buttons.cancel')}
									type={'primary'}
									onClick={this.handleCloseModal}
									customStyle={{ minWidth: '145px' }}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<ProviderPickStockTotals
							totals={pickStockImportData}
							handleChangeFilter={(data) => {
								this.props.pickStockTable({
									...data,
									idOperacion,
									page_size: 10,
									page_number: 1
								})
							}}
						/>
					</Col>
				</Row>
				<Row>
					<PickStockImportTable
						pickStockData={pickStockImportData}
						idOperacion={idOperacion}
						handleChangeTable={this.handleChangeTable}
					/>
				</Row>
			</Container>
		)
	}

	render() {
		const { t, theme } = this.props
		const { showImportModal } = this.state
		return (
			<Fragment>
				<Button
					style={{ minWidth: '125px' }}
					type={'button'}
					onClick={this.handleOpenModal}
				>
					{t('Collection.form.buttons.import')}
				</Button>
				{showImportModal && (
					<CommonModal
						sizeModal={'xl'}
						customClass={theme.size_modal}
						showModal={showImportModal}
						handleCloseModal={this.handleCloseModal}
						modalTitle={t('Collection.form.buttons.import')}
						modalBody={this.renderForm()}
					/>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = ({ pickeoIngStock }) => {
	const { pickStockImportData, pickStockImportError, importConfirmError } =
		pickeoIngStock
	return { pickStockImportData, pickStockImportError, importConfirmError }
}

const connectForm = connect(mapStateToProps, {
	pickStockImport,
	pickStockTable,
	pickStockImportConfirm
})(withTranslation()(ProviderPickStockImport))
export default themr('ProviderPickStockImportStyles', styles)(connectForm)
