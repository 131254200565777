import { faFileImport, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputAutocomplete from 'components/form/inputAutocomplete'
import InputText from 'components/form/inputText'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import _ from 'underscore'
import InputDropdown from '../form/inputDropdown'

class PaymentFormInput extends Component {
	constructor(props) {
		super(props)
		this.state = {
			optionsTipoMov: this.props.optionsAccount[0].tipo_mov, // set valores iniciales
			optionsCurrency: this.props.optionsAccount[0].currency, // set valores iniciales
			showSearchModal: false
		}
	}

	componentDidMount = () => {
		/* if (this.props.firtsRef.current.setFocus) {
            this.props.firtsRef.current.setFocus(); //Cuando es un dataPicker
        } else {
            this.props.firtsRef.current.focus();
        } */
	}

	handleChangeAccount = (evt) => {
		const { setFieldValue, optionsAccount, values } = this.props
		const accId = parseFloat(evt.target.value)

		optionsAccount.forEach((account) => {
			if (account.id === accId) {
				this.setState({
					optionsTipoMov: account.tipo_mov,
					optionsCurrency: account.currency
				})
				this.props.handleChangeAccount({
					nicfondo: accId,
					cat_item: account.tipo_mov[0].id
				})
			}
		})

		values.cuenta = accId // se usa para asignar los valores cuando carga el form.
		setFieldValue('cuenta', accId)
	}

	handleSelectChange = (evt, field) => {
		const { setFieldValue, values } = this.props
		const data = evt.target.value
		values[field] = data // se usa para asignar los valores cuando carga el form.
		setFieldValue(field, data)

		if (field === 'cat_item') {
			this.props.handleChangeAccount({
				nicfondo: values.cuenta,
				cat_item: data
			})
		}
	}

	handleChangeCurrency = (envt) => {
		const { setFieldValue, optionsImp, values } = this.props
		const { value } = envt.target
		values.cod_mone = value // se usa para asignar los valores cuando carga el form.

		setFieldValue('cod_mone', value)
		setFieldValue('importe', optionsImp[value])
	}

	handleSelect = (selected) => {
		const { setFieldValue } = this.props
		const value = selected[0]
		if (value) {
			setFieldValue('chq_bco', value.id) // Se adiciona al formik el banco.
		}
	}

	render() {
		const {
			t,
			values,
			handleChange,
			handleBlur,
			setFieldValue,
			readOnly,
			fields,
			handleSearch,
			handleLoading,
			bankoptions
		} = this.props
		const showSearch = _.findWhere(fields, { idCampo: 'mc_buscar' })
		const showImport = _.findWhere(fields, { idCampo: 'mc_importar' })
		// xs={12} sm={12} md={6} lg={3}
		return (
			<Row>
				<InputDropdown
					inputFormCol={{ sm: 11 }}
					fields={fields}
					label={'label'}
					inputId={'cuenta'}
					name={'cuenta'}
					placeholder={'placeHolder'}
					styles={{ width: '100%' }}
					colLabel={'col-md-2 col-lg-2'}
					colInput={'col-md-10 col-lg-10'}
					options={this.props.optionsAccount}
					onBlur={handleBlur}
					onChange={this.handleChangeAccount}
					value={values.cuenta}
				/>
				<InputDropdown
					inputFormCol={{ sm: 11 }}
					fields={fields}
					label={'label'}
					inputId={'cat_item'}
					name={'cat_item'}
					placeholder={'placeHolder'}
					styles={{ width: '100%' }}
					colLabel={'col-md-2 col-lg-2'}
					colInput={'col-md-10 col-lg-10'}
					options={this.state.optionsTipoMov}
					onBlur={handleBlur}
					onChange={(e) => this.handleSelectChange(e, 'cat_item')}
					value={values.cat_item}
				/>
				<InputText
					fwRef={this.props.movNo}
					inputFormCol={{ sm: 11 }}
					fields={fields}
					label={'label'}
					inputId={'nro_movim'}
					name={'nro_movim'}
					placeholder={t('Collection.form.insert_nro_mov')}
					styles={{ width: '100%' }}
					colLabel={'col-md-2 col-lg-2'}
					colInput={'col-md-10 col-lg-10'}
					disable={readOnly}
					onBlur={handleBlur}
					value={values.nro_movim}
					onChange={(data) => {
						setFieldValue('nro_movim', data)
						handleChange()
					}}
				/>

				<Row className={'col-11'} style={{ paddingRight: '0px' }}>
					<InputText
						inputFormCol={{ md: 12, lg: 6 }}
						fields={fields}
						label={'label'}
						inputId={'fecha_emis'}
						name={'fecha_emis'}
						placeholder={t('Collection.form.insert_date_emis')}
						colLabel={'col-md-2 col-lg-4'}
						colInput={'col-md-10 col-lg-8'}
						disable={readOnly}
						styleLabel={{ paddingRight: '0px' }}
						divStyle={{ paddingLeft: '1.1rem' }}
						onBlur={handleBlur}
						value={values.fecha_emis}
						onChange={(data) => {
							setFieldValue('fecha_emis', data)
							handleChange()
						}}
					/>
					<InputText
						inputFormCol={{ md: 12, lg: 6, style: { paddingRight: '0px' } }}
						fwRef={this.props.dateRef}
						fields={fields}
						label={'label'}
						inputId={'fecha_cobro'}
						name={'fecha_cobro'}
						placeholder={t('Collection.form.insert_date_payment')}
						colLabel={'col-lg-2 col-md-2'}
						colInput={'col-lg-10 col-md-10'}
						disable={readOnly}
						styleLabel={{ textAlign: 'right' }}
						value={values.fecha_cobro}
						onBlur={handleBlur}
						onChange={(data) => {
							setFieldValue('fecha_cobro', data)
							handleChange()
						}}
					/>
				</Row>
				<Row className={'col-11'} style={{ paddingRight: '0px' }}>
					<InputAutocomplete
						fields={fields}
						inputFormCol={{ md: 6, lg: 6 }}
						label={'Cuenta'}
						inputId={'chq_bco'}
						name={'chq_bco'}
						minLength={1}
						placeholder={t('Collection.form.insert_bank')}
						divStyle={{ width: '100%', paddingLeft: '1.1rem' }}
						colLabel={'col-lg-4 col-md-4'}
						colInput={'col-lg-8 col-md-8'}
						handleSearch={handleSearch}
						auoptions={bankoptions}
						handleLoading={handleLoading}
						handleSelect={this.handleSelect}
						onBlur={handleBlur}
						onChange={handleChange}
						labelKey={'label'}
						disable={readOnly}
						defaultValue={
							values.chq_bco ? String(values.chq_bco) : values.chq_bco
						}
					/>
					<InputText
						inputFormCol={{ lg: 3, md: 6 }}
						fields={fields}
						label={'label'}
						inputId={'chq_suc'}
						name={'chq_suc'}
						placeholder={t('Collection.form.insert_branch')}
						colLabel={'col-lg-3 col-md-4'}
						colInput={'col-lg-9 col-md-8'}
						disable={readOnly}
						divStyle={{}}
						value={values.chq_suc}
						onBlur={handleBlur}
						onChange={(data) => {
							setFieldValue('chq_suc', data)
							handleChange()
						}}
					/>
					<InputText
						inputFormCol={{ lg: 3, md: 6 }}
						fields={fields}
						label={'label'}
						inputId={'chq_cpos'}
						name={'chq_cpos'}
						placeholder={t('Collection.form.insert_cp')}
						colLabel={'col-lg-3 col-md-4'}
						colInput={'col-lg-9 col-md-8'}
						divStyle={{ width: '100%', paddingLeft: '1.1rem' }}
						disable={readOnly}
						value={values.chq_cpos}
						onBlur={handleBlur}
						onChange={(data) => {
							setFieldValue('chq_cpos', data)
							handleChange()
						}}
					/>
				</Row>
				<Col sm={1}>
					<Row>
						{showSearch && showSearch.visible > 0 && (
							<Col sm={6} className={'text-right pr-1'}>
								<FontAwesomeIcon
									icon={faSearch}
									onClick={() => this.props.changeModal('search')}
									style={{ cursor: 'pointer' }}
								/>
							</Col>
						)}
						{showImport && showImport.visible > 0 && (
							<Col sm={6} className={'text-right pr-1'}>
								<FontAwesomeIcon
									icon={faFileImport}
									onClick={() => this.props.changeModal('impt')}
									style={{ cursor: 'pointer' }}
								/>
							</Col>
						)}
					</Row>
				</Col>

				<InputText
					fwRef={this.props.cheqNo}
					inputFormCol={{ md: 11, lg: 6 }}
					fields={fields}
					label={'label'}
					inputId={'chq_nro'}
					name={'chq_nro'}
					placeholder={t('Collection.form.insert_nro_document')}
					colLabel={'col-lg-4 col-md-3'}
					colInput={'col-lg-8 col-md-9 pl-0'}
					disable={readOnly}
					styleLabel={{ paddingRight: '0px' }}
					value={values.chq_nro}
					onBlur={handleBlur}
					onChange={(data) => {
						setFieldValue('chq_nro', data)
						handleChange()
					}}
				/>

				<InputText
					inputFormCol={{ lg: 11, md: 11 }}
					fields={fields}
					label={'label'}
					inputId={'nro_cta'}
					name={'nro_cta'}
					placeholder={t('Collection.form.insert_cta_bancaria')}
					styles={{ width: '100%' }}
					colLabel={'col-lg-2 col-md-2'}
					colInput={'col-lg-10 col-md-10'}
					disable={readOnly}
					onBlur={handleBlur}
					value={values.nro_cta}
					onChange={(data) => {
						setFieldValue('nro_cta', data)
						handleChange()
					}}
				/>
				<Row className={'col-11'} style={{ paddingRight: '0px' }}>
					<InputDropdown
						inputFormCol={{ lg: 6, md: 12 }}
						fields={fields}
						label={'label'}
						inputId={'cod_mone'}
						name={'cod_mone'}
						placeholder={'placeHolder'}
						styles={{ width: '100%' }}
						colLabel={'col-lg-4 col-md-2'}
						colInput={'col-lg-8 col-md-10 pr-0'}
						divStyle={{ paddingLeft: '1.1rem' }}
						options={this.state.optionsCurrency}
						onBlur={handleBlur}
						onChange={(e) => this.handleChangeCurrency(e)}
						value={values.cod_mone}
					/>
					<InputText
						inputFormCol={{ md: 12, lg: 6 }}
						fields={fields}
						label={'label'}
						inputId={'importe'}
						name={'importe'}
						placeholder={t('Collection.form.insert_imp')}
						colLabel={'col-md-2 col-lg-2'}
						colInput={'col-md-10 col-lg-10 pr-0'}
						divStyle={{ paddingLeft: '1.1rem' }}
						disable={readOnly}
						styleLabel={{ textAlign: 'right' }}
						value={values.importe}
						onBlur={handleBlur}
						onChange={(data) => {
							setFieldValue('importe', data)
							handleChange()
						}}
					/>
				</Row>

				<InputDropdown
					inputFormCol={{ sm: 11 }}
					fields={fields}
					label={'label'}
					inputId={'cto_fon'}
					name={'cto_fon'}
					placeholder={'placeHolder'}
					styles={{ width: '100%' }}
					colLabel={'col-md-2 col-lg-2'}
					colInput={'col-md-10 col-lg-10'}
					options={this.props.moneyOptions}
					onBlur={handleBlur}
					onChange={(e) => this.handleSelectChange(e, 'cto_fon')}
					value={values.cto_fon}
				/>
				<InputText
					inputFormCol={{ sm: 11 }}
					fields={fields}
					label={'label'}
					inputId={'titular'}
					name={'titular'}
					placeholder={t('Collection.form.insert_titular')}
					styles={{ width: '100%' }}
					colLabel={'col-lg-2 col-md-2'}
					colInput={'col-lg-10 col-md-10'}
					disable={readOnly}
					onBlur={handleBlur}
					value={values.titular}
					onChange={(data) => {
						setFieldValue('titular', data)
						setFieldValue('cuit', '')
						handleChange()
					}}
				/>
				<InputText
					inputFormCol={{ sm: 11 }}
					fields={fields}
					label={'label'}
					inputId={'cuit'}
					name={'cuit'}
					placeholder={t('Collection.form.insert_cliente_identificador')}
					styles={{ width: '100%' }}
					colLabel={'col-lg-2 col-md-2'}
					colInput={'col-lg-10 col-md-10'}
					disable={readOnly}
					onBlur={handleBlur}
					value={values.cuit}
					onChange={(data) => {
						setFieldValue('cuit', data)
						handleChange()
					}}
				/>
			</Row>
		)
	}
}

export default withTranslation()(PaymentFormInput)
