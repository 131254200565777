import Axios from 'axios'
import {
	ICrudCreateLedgerAccountsCode,
	ICrudGetLedgerAccountsCode,
	ICrudGetLedgerAccountsCodeConfig
} from 'models/CrudLedgerAccountsInterface'
const urlCRUD = '/abm_cont_cuentas'

export const crudGetLedgerAccountsCode = async (
	params: ICrudGetLedgerAccountsCode
) => {
	const response = await Axios.get(`${urlCRUD}/niveles/datos`, { params })
	return response.data
}

export const crudGetLedgerAccountsCodeConfig = async (
	params: ICrudGetLedgerAccountsCodeConfig
) => {
	const response = await Axios.get(
		`${urlCRUD}/niveles/config?cod_val=${params}`
	)
	return response.data
}

export const crudCreateLedgerAccountsCode = async (
	params: ICrudCreateLedgerAccountsCode
) => {
	const response = await Axios.post(`${urlCRUD}/niveles/aceptar`, params)
	return response.data
}
