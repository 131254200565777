import {
	crudCreateLedgerAccountsCode,
	crudGetLedgerAccountsCode,
	crudGetLedgerAccountsCodeConfig
} from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import CrudLedgerAccountsCodeField from './CrudLedgerAccountsCodeField'

interface CrudLedgerAccountsCodeFormProps extends WithTranslation {
	crudGetLedgerAccountsCode: any
	crudCreateLedgerAccountsCode: any
	t: any
	itemInfo?: any
	method: string
	disableForm: boolean
	handleSubmitModal: any
	handleCloseModal: any
	ledgerAccountsCodeConfig: any
	ledgerAccountsCodeInfo: any
	ledgerAccountsCodeCreated: any
	cod_val: string
	cod_cta: string
}

type CrudLedgerAccountsCodeFormState = {
	disableForm: boolean
	fieldDefaults: {}
}

class CrudLedgerAccountsCodeForm extends Component<
	CrudLedgerAccountsCodeFormProps,
	CrudLedgerAccountsCodeFormState
> {
	constructor(props: CrudLedgerAccountsCodeFormProps) {
		super(props)
		this.state = {
			disableForm: false,
			fieldDefaults: {}
		}
	}

	// Gets initial values and dropdowns
	componentDidMount = () => {
		const { cod_val, cod_cta } = this.props
		this.props.crudGetLedgerAccountsCode({
			cod_val: cod_val,
			codigo_actual: cod_cta
		})
	}

	// If info exists setups default values
	componentDidUpdate = () => {
		const { ledgerAccountsCodeInfo } = this.props
		const { fieldDefaults } = this.state
		if (ledgerAccountsCodeInfo && Object.entries(fieldDefaults).length < 1) {
			this.setState({
				fieldDefaults: {
					nivel1: ledgerAccountsCodeInfo.nivel1
						? _.find(ledgerAccountsCodeInfo.nivel1, (values) => {
								return values.codigo === ledgerAccountsCodeInfo.default_nivel1
						  })
							? ledgerAccountsCodeInfo.default_nivel1
							: ledgerAccountsCodeInfo.nivel1[0].codigo
						: ledgerAccountsCodeInfo.default_nivel1,
					nivel2: ledgerAccountsCodeInfo.nivel2
						? _.find(ledgerAccountsCodeInfo.nivel2, (values) => {
								return values.codigo === ledgerAccountsCodeInfo.default_nivel2
						  })
							? ledgerAccountsCodeInfo.default_nivel2
							: ledgerAccountsCodeInfo.nivel2[0].codigo
						: ledgerAccountsCodeInfo.default_nivel2,
					nivel3: ledgerAccountsCodeInfo.nivel3
						? _.find(ledgerAccountsCodeInfo.nivel3, (values) => {
								return values.codigo === ledgerAccountsCodeInfo.default_nivel3
						  })
							? ledgerAccountsCodeInfo.default_nivel3
							: ledgerAccountsCodeInfo.nivel3[0].codigo
						: ledgerAccountsCodeInfo.default_nivel3,
					nivel4: ledgerAccountsCodeInfo.nivel4
						? _.find(ledgerAccountsCodeInfo.nivel4, (values) => {
								return values.codigo === ledgerAccountsCodeInfo.default_nivel4
						  })
							? ledgerAccountsCodeInfo.default_nivel4
							: ledgerAccountsCodeInfo.nivel4[0].codigo
						: ledgerAccountsCodeInfo.default_nivel4,
					nivel5: ledgerAccountsCodeInfo.nivel5
						? _.find(ledgerAccountsCodeInfo.nivel5, (values) => {
								return values.codigo === ledgerAccountsCodeInfo.default_nivel5
						  })
							? ledgerAccountsCodeInfo.default_nivel5
							: ledgerAccountsCodeInfo.nivel5[0].codigo
						: ledgerAccountsCodeInfo.default_nivel5,
					nivel6: ledgerAccountsCodeInfo.nivel6
						? _.find(ledgerAccountsCodeInfo.nivel6, (values) => {
								return values.codigo === ledgerAccountsCodeInfo.default_nivel6
						  })
							? ledgerAccountsCodeInfo.default_nivel6
							: ledgerAccountsCodeInfo.nivel6[0].codigo
						: ledgerAccountsCodeInfo.default_nivel6,
					nivel7: ledgerAccountsCodeInfo.nivel7
						? _.find(ledgerAccountsCodeInfo.nivel7, (values) => {
								return values.codigo === ledgerAccountsCodeInfo.default_nivel7
						  })
							? ledgerAccountsCodeInfo.default_nivel7
							: ledgerAccountsCodeInfo.nivel7[0].codigo
						: ledgerAccountsCodeInfo.default_nivel7,
					nivel8: ledgerAccountsCodeInfo.nivel8
						? _.find(ledgerAccountsCodeInfo.nivel8, (values) => {
								return values.codigo === ledgerAccountsCodeInfo.default_nivel8
						  })
							? ledgerAccountsCodeInfo.default_nivel8
							: ledgerAccountsCodeInfo.nivel8[0].codigo
						: ledgerAccountsCodeInfo.default_nivel8
				}
			})
		}
	}

	render() {
		const {
			t,
			disableForm,
			method,
			handleSubmitModal,
			handleCloseModal,
			ledgerAccountsCodeConfig,
			ledgerAccountsCodeInfo,
			cod_val,
			cod_cta
		} = this.props

		const { fieldDefaults } = this.state

		if (ledgerAccountsCodeConfig) {
			const fields = ledgerAccountsCodeConfig.campos
			const initialValues =
				fieldDefaults || (fields.length ? getInitValues(fields) : {})

			const columnsCancel = disableForm ? 12 : 6
			const validationSchema = fields.length
				? getValidationSchema(fields, t, [], [])
				: {}

			return (
				<Fragment>
					<Formik
						initialValues={initialValues}
						onSubmit={(values, actions) => {
							handleSubmitModal({
								cod_val: cod_val,
								cod_cta: cod_cta,
								values: values
							})
						}}
						enableReinitialize={true}
						validationSchema={validationSchema}
					>
						{(props: FormikProps<any>) => (
							<Fragment>
								<Col sm={12}>
									<NotificationError
										errors={props.errors}
										touched={props.touched}
									/>
								</Col>
								<Alert variant={'dark'}>
									<b>{t('CRUDS.general_use.formulariocodigo')}</b>
								</Alert>
								<Form>
									{(method === 'add' ||
										((method === 'edit' || method === 'view') &&
											ledgerAccountsCodeInfo)) &&
										_.map(fields, (field: any) => {
											return (
												<CrudLedgerAccountsCodeField
													key={field.idCampo}
													formikProps={props}
													field={field}
													crudProps={{
														...this.props
													}}
													initialValue={
														initialValues[
															field.idCampo as keyof typeof initialValues
														]
													}
												/>
											)
										})}
									<Row className={'pt-3'}>
										{!disableForm && (
											<Col sm={6} className={'text-center mt-2'}>
												<Button
													style={{ minWidth: '145px' }}
													type={'button'}
													onClick={() => props.handleSubmit()}
												>
													{t('Collection.form.buttons.accept')}
												</Button>
											</Col>
										)}
										<Col sm={columnsCancel} className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={handleCloseModal}
											>
												{t('Collection.form.buttons.cancel')}
											</Button>
										</Col>
									</Row>
								</Form>
							</Fragment>
						)}
					</Formik>
				</Fragment>
			)
		} else {
			return <div>Cargando...</div>
		}
	}
}

const mapStateToProps = ({ ledgerAccountsCode }: any) => {
	const {
		ledgerAccountsCodeConfig,
		ledgerAccountsCodeInfo,
		ledgerAccountsCodeCreated
	} = ledgerAccountsCode
	return {
		ledgerAccountsCodeConfig,
		ledgerAccountsCodeInfo,
		ledgerAccountsCodeCreated
	}
}

const connectForm = connect(mapStateToProps, {
	crudGetLedgerAccountsCodeConfig,
	crudGetLedgerAccountsCode,
	crudCreateLedgerAccountsCode
})(CrudLedgerAccountsCodeForm)

export default withTranslation()(withRouter(connectForm))
