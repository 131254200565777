import React, { Component } from 'react'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import styles from './ProviderPickStock.module.scss'

class ProviderPickStockResume extends Component {
	constructor(props) {
		super(props)
		this.state = {
			product: '',
			ultCant: '',
			cantPend: '',
			cantIng: ''
		}
	}

	componentDidUpdate = (prevProps) => {
		const { ingrPickeo } = this.props
		if (prevProps.ingrPickeo !== ingrPickeo && ingrPickeo) {
			this.setState({
				product: ingrPickeo.ult_producto || '',
				ultCant: ingrPickeo.ult_cant || '',
				cantPend: ingrPickeo.cant_pend || '',
				cantIng: ingrPickeo.cant_ing || ''
			})
		}
	}

	render() {
		const { theme, t } = this.props
		const { product, ultCant, cantPend, cantIng } = this.state

		return (
			<Col sm={12} className={`pb-2`}>
				<Row>
					<Col className={theme.divLabel}>{t('pickStock.last_reading')}</Col>
				</Row>
				<Row>
					<Col sm={7}>
						<Row>
							<Col sm={3} className={theme.divLabel}>
								{t('pickStock.product')}
							</Col>
							<Col sm={9} className={theme.redLabel}>
								{product}
							</Col>
						</Row>
					</Col>
					<Col sm={1} className={`text-left ${theme.divLabel}`}>
						{t('global.quantity')}
					</Col>
					<Col className={theme.redLabel}>{ultCant}</Col>
				</Row>
				<Row>
					<Col sm={7}>
						<Row>
							<Col className={theme.divLabel} sm={3}>
								{t('pickStock.controlled_quantity')}
							</Col>
							<Col sm={6} className={theme.redLabel}>
								{cantIng}
							</Col>
						</Row>
					</Col>
					<Col sm={1} className={theme.divLabel}>
						{t('pickStock.pending')}
					</Col>
					<Col className={theme.redLabel}> {cantPend}</Col>
				</Row>
			</Col>
		)
	}
}

const connectForm = connect()(withTranslation()(ProviderPickStockResume))
export default themr('ProviderPickStockStyles', styles)(connectForm)
