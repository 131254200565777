import { getConfigFieldsCrud } from 'actions'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import UserProfilesAssignCenterTable from './UserProfilesAssignCenterDynamicTable'

type ValuesProps = {
	getConfigFieldsCrud: any
	handleSubmit: any
	setFormChanged?: any
	fieldsConfig: any
	cod_usu?: string
	values?: any
	disableForm: boolean
	showWarning: boolean
	handleWarning: any
}

type ValuesState = {
	activeTab: string
}

class LedgerAccountAssignCenterTab extends Component<ValuesProps, ValuesState> {
	constructor(props: ValuesProps) {
		super(props)
		this.state = {
			activeTab: 'assignCenter'
		}
	}

	render() {
		const {
			fieldsConfig,
			disableForm,
			values,
			cod_usu,
			handleSubmit,
			setFormChanged,
			showWarning,
			handleWarning
		} = this.props

		const propsTable = {
			fieldsConfig: fieldsConfig,
			disableForm: disableForm,
			values: values,
			cod_usu: cod_usu,
			handleSubmit: handleSubmit,
			setFormChanged: setFormChanged,
			showWarning: showWarning,
			handleWarning: handleWarning
		}
		return (
			<Fragment>
				{fieldsConfig && <UserProfilesAssignCenterTable {...propsTable} />}
			</Fragment>
		)
	}
}

const mapStateToProps = ({ configCrud }: any) => {
	const { fieldsConfig } = configCrud
	return { fieldsConfig }
}

const connectForm = connect(mapStateToProps, {
	getConfigFieldsCrud
})(LedgerAccountAssignCenterTab)

export default connectForm
