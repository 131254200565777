import { crudGetLastAccountingDate } from 'actions'
import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import NotificationError from 'components/common/notificationsErrors'
import { FormRefsContext } from 'context/FormRefsContext'
import { Form, Formik } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import {
	ICrudGetAccountingPeriodRes,
	ICrudGetLastAccountingDateRes
} from 'models/CrudAccountingPeriodsInterface'
import { ICrudFundConceptsField } from 'models/CrudFundsConceptsInterface'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import CrudAccountingPeriodDataField from './CrudAccountingPeriodDataField'

export interface CrudAccountingPeriodsProps extends WithTranslation {
	crudGetLastAccountingDate: () => void
	fields: Array<ICrudFundConceptsField>
	lastAccountingPeriod: ICrudGetLastAccountingDateRes
	handleSubmit: (param: ICrudGetAccountingPeriodRes) => void
	disableForm: boolean
	showWarning: boolean
	handleWarning: (value: any, action: string) => void
	handleCloseModal: () => void
	setFormChanged: (formChanged: boolean) => void
	itemInfo?: any
	method: string
	t?: any
}

class CrudAccountingPeriod extends Component<CrudAccountingPeriodsProps> {
	componentDidMount = () => {
		this.props.crudGetLastAccountingDate()
	}

	render() {
		const {
			fields,
			t,
			handleSubmit,
			itemInfo,
			disableForm,
			showWarning,
			handleWarning,
			setFormChanged,
			handleCloseModal,
			method
		} = this.props

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})

		const columnsCancel = disableForm ? 12 : 6
		const validationSchema = fields.length
			? getValidationSchema(fields, t, [], [])
			: {}

		return (
			<FormRefsContext.Consumer>
				{({ formRef, cancelButtonRef }) => (
					<Formik
						ref={formRef}
						initialValues={initialValues}
						onSubmit={(values, actions) => {
							handleSubmit(values)
						}}
						enableReinitialize={true}
						validationSchema={validationSchema}
					>
						{(props) => (
							<Fragment>
								<Col sm={12}>
									<NotificationError
										errors={props.errors}
										touched={props.touched}
									/>
								</Col>
								<Form
									onChange={(e) => {
										setFormChanged(true)
									}}
								>
									{(method === 'add' ||
										((method === 'edit' || method === 'view') && itemInfo)) &&
										_.map(fields, (field) => {
											return (
												<CrudAccountingPeriodDataField
													key={field.idCampo}
													formikProps={props}
													field={field}
													crudProps={this.props}
													initialValue={initialValues[field.idCampo]}
												/>
											)
										})}
									<Row className={'pt-3'}>
										{!disableForm && (
											<Col sm={6} className={'text-center mt-2'}>
												<Button style={{ minWidth: '145px' }} type={'submit'}>
													{t('Collection.form.buttons.accept')}
												</Button>
											</Col>
										)}
										<Col sm={columnsCancel} className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={handleCloseModal}
												ref={cancelButtonRef}
											>
												{t('Collection.form.buttons.cancel')}
											</Button>
										</Col>
									</Row>
									<ConfirmModalNoButton
										modalTitle={t('CRUDS.modal.title_warning')}
										messageBody={t('CRUDS.modal.message_changes')}
										showModal={showWarning}
										handleCloseModal={() => handleWarning(false, 'close')}
										handleCancelButton={() => {
											handleWarning(false, 'cancel')
										}}
										handleSubmitButton={(e: any) => {
											props.handleSubmit(e)
											handleWarning(false, 'submit')
										}}
									/>
								</Form>
							</Fragment>
						)}
					</Formik>
				)}
			</FormRefsContext.Consumer>
		)
	}
}

const mapStateToProps = ({ accountingPeriodsCrud }: any) => {
	const { lastAccountingPeriod } = accountingPeriodsCrud
	return {
		lastAccountingPeriod
	}
}

const connectForm = connect(mapStateToProps, {
	crudGetLastAccountingDate
})(CrudAccountingPeriod)

export default withTranslation()(withRouter(connectForm))
