import Axios from 'axios'

export const getProvider = async (params) => {
	const response = await Axios.get('/Proveedores/Consulta', { params })
	return response.data
}

export const searchProviders = async (params) => {
	const response = await Axios.get('/Proveedores/Seleccion', { params })
	return response.data
}

export const confirmationProvider = async (params) => {
	const response = await Axios.get('/Proveedores/Seleccion/Confirmar', {
		params
	})
	return response.data
}

export const getProviderHeader = async (params) => {
	const response = await Axios.get('/cpa_cab_compr', { params })
	return response.data
}

export const providerHeaderCheckDate = async (params) => {
	const response = await Axios.get('/cpa_cab_compr/fecha_valid', { params })
	return response.data
}

export const providerHeaderConfirm = async (params) => {
	const response = await Axios.post('/cpa_cab_compr/confirmar', params)
	return response.data
}

export const providerHeaderAuto = async (params) => {
	const response = await Axios.get('/cpa_cab_compr/atrib_autocompl', { params })
	return response.data
}

export const confirmProviderTable = async (params) => {
	const response = await Axios.post('/carga_item_cpa/confirmar', params)
	return response.data
}
