import CommonTable from 'components/common/commonTable'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getDefaultColumns } from 'lib/FormUtils'
import { IGetValidationsSuccessResponse } from 'models/HeaderDataValidation'
import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'

interface IHeaderTableProps {
	fields: Array<IFieldStructure>
	authUser: any
	validationList: IGetValidationsSuccessResponse
}

type State = {}

export class HeaderDataValidationTable extends Component<
	IHeaderTableProps,
	State
> {
	primaryKey: string = 'niparam'
	getPaginationOptions = () => {}

	handleChangeTable = () => {}

	render() {
		const { fields, authUser, validationList } = this.props

		const propsTable = {
			remote: true,
			columns: _.isEmpty(fields) ? [] : getDefaultColumns(fields, { authUser }),
			data: _.isEmpty(validationList) ? [] : validationList.lista_valid,
			onTableChange: this.handleChangeTable,
			keyField: this.primaryKey,
			pagination: false
		}

		return (
			<Container>
				{!_.isEmpty(fields) && <CommonTable {...propsTable} />}
			</Container>
		)
	}
}

const mapStateToProps = ({ auth, headerDataValidation }: any) => {
	const { authUser } = auth
	const { validationList } = headerDataValidation

	return { authUser, validationList }
}

const mapDispatchToProps = {}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HeaderDataValidationTable)
