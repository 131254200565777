import {
	crudApplyLedgerAccountsPointers,
	crudGetLedgerAccountsPointers,
	crudsClearSearch,
	showMessage
} from 'actions'
import CommonModal from 'components/common/commonModal'
import NotificationError from 'components/common/notificationsErrors'
import InputButton from 'components/form/inputButton'
import { searchFields } from 'constants/crudsConfig/crudLedgerAccountsConstants/crudLedgerAccountsSearchFormFields'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { FormRefsContext } from 'context/FormRefsContext'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues } from 'lib/FieldValidations'
import { getFiltersData } from 'lib/Utils'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { ADMIN } from 'utils/RoutePath'
import CrudLedgerAccountGlobalModal from '../CrudLedgerAccountGlobalModal'
import SearchLedgerAccountField from './CrudSearchLedgerAccountField'

type ComponentProps = {
	fields: Array<IFieldStructure>
	itemInfo?: any
	alta: number
	crudsClearSearch: () => void
	handleSubmit: (params: any) => void
	homeTarget: string
	handleNewCrud: () => void
	history: any
	t: any
	hideSearch: boolean
	hideNew: boolean
	fieldsConfig: any
	crudApplyLedgerAccountsPointers: any
	crudGetLedgerAccountsPointers: any
	ledgerAccountsPointersSearched: any
	ledgerAccountsPointersCreated: any
	showMessage: any
}

type ComponentState = {
	showEditPopup: boolean
}

class SearchLedgerAccountForm extends Component<
	ComponentProps,
	ComponentState
> {
	constructor(props: ComponentProps) {
		super(props)
		this.state = {
			showEditPopup: false
		}
	}

	componentDidMount = () => {
		this.props.crudGetLedgerAccountsPointers()
	}

	/**
	 * to go to the page home
	 */
	handleHome = (homeTarget: string) => {
		const { history } = this.props
		history.push(`${ADMIN}/${homeTarget}`)
	}

	callBackGlobalPointers = () => {
		this.setState({ showEditPopup: true })
	}

	handleCloseEditPopup = () => {
		this.setState({ showEditPopup: false })
	}

	handleSubmitPopup = (values: any) => {
		const { hideNew, crudApplyLedgerAccountsPointers, showMessage, t } =
			this.props
		!hideNew
			? crudApplyLedgerAccountsPointers({ ...values })
			: showMessage({
					type: 'error',
					description: t('CRUDS.message.rights'),
					showError: true
			  })
		this.setState({ showEditPopup: false })
	}

	render() {
		const {
			fields,
			itemInfo,
			homeTarget,
			handleSubmit,
			t,
			handleNewCrud,
			hideSearch,
			hideNew,
			fieldsConfig,
			ledgerAccountsPointersSearched
		} = this.props

		const { showEditPopup } = this.state

		for (const key in itemInfo) {
			itemInfo[key] =
				typeof itemInfo[key] === 'string' ? itemInfo[key].trim() : itemInfo[key]
		}

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})
		// const columnsCancel = disableForm ? 12 : 6

		const fieldsGlobal = fieldsConfig
			? getFiltersData(fieldsConfig.campos, { agrupador: 'grupo.global' })
			: []

		const propsEdit = {
			fields: fieldsGlobal,
			pointers: ledgerAccountsPointersSearched,
			handleSubmit: this.handleSubmitPopup,
			handleCloseModal: this.handleCloseEditPopup
		}

		const propsEditModal = {
			showModal: showEditPopup,
			handleCloseModal: this.handleCloseEditPopup,
			modalTitle: 'Carga Global',
			modalBody: <CrudLedgerAccountGlobalModal {...propsEdit} />,
			buttons: false,
			handleSubmit: this.handleSubmitPopup,
			sizeModal: 'md'
		}

		return (
			<>
				<FormRefsContext.Consumer>
					{({ formRef, cancelButtonRef, searchButtonRef }) => (
						<Formik
							ref={formRef}
							initialValues={initialValues}
							onSubmit={(values, actions) => {
								const parsedValues = {
									...values,
									lk_movim_desde: moment(values.lk_movim_desde),
									lk_movim_hasta: moment(values.lk_movim_hasta)
								}
								handleSubmit(parsedValues)
							}}
							enableReinitialize={true}
						>
							{(props: FormikProps<any>) => (
								<Fragment>
									<Col sm={12}>
										<NotificationError
											errors={props.errors}
											touched={props.touched}
										/>
									</Col>
									<Form>
										<Row className={'p-5 justify-content-md-center'}>
											{_.map(fields, (field: any) => {
												const selectedFieldConfig = searchFields.find(
													(fieldConfig) => {
														return fieldConfig.id === field.idCampo
													}
												)
												return (
													<Col
														className={
															(selectedFieldConfig &&
																selectedFieldConfig.height) ||
															'col-sm-12'
														}
													>
														<SearchLedgerAccountField
															key={field.idCampo}
															formikProps={props}
															field={field}
															crudProps={this.props}
															initialValue={
																initialValues[
																	field.idCampo as keyof typeof initialValues
																]
															}
														/>
													</Col>
												)
											})}
										</Row>
										<Row className={'pt-1 pr-2'}>
											<Col className={'offset-md-6 text-center mt-2'}>
												{!hideSearch && (
													<Button
														style={{ minWidth: '105px' }}
														type={'submit'}
														ref={searchButtonRef}
													>
														{t('global.search')}
													</Button>
												)}
											</Col>
											<Col className={'text-center mt-2'}>
												<Button
													style={{ minWidth: '105px' }}
													type={'button'}
													onClick={() => this.callBackGlobalPointers()}
												>
													{t('global.global_pointers_button')}
												</Button>
											</Col>
											<Col className={'text-center mt-2'}>
												{!hideNew && (
													<InputButton
														valueButton={t('global.new_button')}
														onClick={() => handleNewCrud()}
														type={'primary'}
														customStyle={{ minWidth: '105px' }}
													/>
												)}
											</Col>
											<Col className={'text-center mt-2'}>
												<Button
													style={{ minWidth: '105px' }}
													type={'button'}
													onClick={() => this.handleHome(homeTarget)}
													ref={cancelButtonRef}
												>
													{t('global.return_button')}
												</Button>
											</Col>
										</Row>
									</Form>
								</Fragment>
							)}
						</Formik>
					)}
				</FormRefsContext.Consumer>
				<Row>
					{ledgerAccountsPointersSearched && (
						<CommonModal {...propsEditModal} />
					)}
				</Row>
			</>
		)
	}
}

const mapStateToProps = ({ configCrud, ledgerAccountsPointers }: any) => {
	const { fieldsConfig } = configCrud
	const { ledgerAccountsPointersSearched, ledgerAccountsPointersCreated } =
		ledgerAccountsPointers

	return {
		fieldsConfig,
		ledgerAccountsPointersSearched,
		ledgerAccountsPointersCreated
	}
}

const connectForm = connect(mapStateToProps, {
	crudsClearSearch,
	crudGetLedgerAccountsPointers,
	crudApplyLedgerAccountsPointers,
	showMessage
})(SearchLedgerAccountForm)

export default withTranslation()(withRouter(connectForm))
