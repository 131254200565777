import InputButton from 'components/form/inputButton'
import React, { Fragment, PureComponent } from 'react'
import ModalComponent from './modalComponent.js'

export default class PaymentModal extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			showModal: false
		}
	}

	handleOpenModal = () => {
		this.setState({ showModal: true })
	}

	handleCloseModal = () => {
		this.setState({ showModal: false })
	}

	handleSubmit = () => {
		const { onSubmitModal } = this.props
		onSubmitModal()
		this.handleCloseModal()
	}

	handleChangeModal = () => {
		this.setState({ showSearch: !this.state.showSearch })
	}

	render() {
		const {
			labelButton,
			typeButton,
			handleMoveArrow,
			page,
			page_size,
			idOperacion,
			paymentTotals
		} = this.props
		const { showModal } = this.state

		return (
			<Fragment>
				<InputButton
					valueButton={labelButton}
					type={typeButton || 'primary'}
					onClick={this.handleOpenModal}
					customStyle={{ minWidth: '100px' }}
				/>
				{showModal && (
					<ModalComponent
						{...this.props}
						showModal={showModal}
						handleCloseModal={this.handleCloseModal}
						page={page}
						page_size={page_size}
						idOperacion={idOperacion}
						handleMoveArrow={handleMoveArrow}
						paymentTotals={paymentTotals}
					/>
				)}
			</Fragment>
		)
	}
}
