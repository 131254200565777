import { InventoryExpensesStockActions } from 'constants/ActionsTypesTs'
import {
	IGetCufPickStockResponse,
	IGetDepositsPickStockResponse
} from 'models/InventoryPickStock'

export interface valueState {
	cufList: IGetCufPickStockResponse | null
	depositList: IGetDepositsPickStockResponse | null
}

const initialState = {
	cufList: null,
	depositList: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case InventoryExpensesStockActions.GET_CUF_PICK_EXPENSES:
			return { ...state, cufList: null }
		case InventoryExpensesStockActions.GET_CUF_PICK_EXPENSES_SUCCESS:
			return { ...state, cufList: action.payload }
		case InventoryExpensesStockActions.GET_DEPOSITS_PICK_EXPENSES:
			return { ...state, depositList: null }
		case InventoryExpensesStockActions.GET_DEPOSITS_PICK_EXPENSES_SUCCESS:
			return { ...state, depositList: action.payload }
		default:
			return state
	}
}

export default rootReducer
