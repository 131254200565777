import {
	cancelVoucherSuccess,
	getDetailInventorySummarySuccess,
	getDetailTaxSummarySuccess,
	getDetailTreasurySummarySuccess,
	getDetailVoucherAffectationsSuccess,
	getDetailVoucherHeadboardSuccess,
	getDetailVoucherItemsSuccess,
	getDetailVoucherSeatSuccess,
	getDetailVoucherTaxesSuccess,
	getFilterAttributesSuccess,
	getFilterBranchesSuccess,
	getFilterCurrencySuccess,
	getFilterDepositsSuccess,
	getFilterFundAccountTypesSuccess,
	getFilterModulesSuccess,
	getFilterPaymentConditionsSuccess,
	getFilterSellersSuccess,
	getFilterTypeAccountingSuccess,
	getFilterUsersSuccess,
	getFilterVoucherCategorySuccess,
	getFilterVoucherNamesSuccess,
	getFilterVouchersSuccess,
	getInventorySerieDataSuccess,
	getItemCategoriesSuccess,
	getOrderDirOptionsSuccess,
	getOrderOptionsSuccess,
	printVoucherDetailSuccess,
	removeVoucherSuccess,
	searchVouchersSuccess
} from 'actions'
import {
	cancelVoucherApi,
	getDetailInventorySummaryApi,
	getDetailTaxSummaryApi,
	getDetailTreasurySummaryApi,
	getDetailVoucherAffectationsApi,
	getDetailVoucherHeadboardApi,
	getDetailVoucherItemsApi,
	getDetailVoucherSeatApi,
	getDetailVoucherTaxesApi,
	getFilterAttributesApi,
	getFilterBranchesApi,
	getFilterCurrencyApi,
	getFilterDepositsApi,
	getFilterFundAccountTypesApi,
	getFilterModulesApi,
	getFilterPaymentConditionsApi,
	getFilterSellersApi,
	getFilterTypeAccountingApi,
	getFilterUsersApi,
	getFilterVoucherCategory,
	getFilterVoucherNames,
	getFilterVouchersApi,
	getInventorySerieDataApi,
	getItemCategoriesApi,
	getOrderDirOptionsApi,
	getOrderOptionsApi,
	printVoucherDetailApi,
	removeVoucherApi,
	searchVoucherApi
} from 'api/SearchVouchers'
import {
	CANCEL_VOUCHER,
	GET_DETAIL_INVENTORY_SUMMARY,
	GET_DETAIL_TAX_SUMMARY,
	GET_DETAIL_TREASURY_SUMMARY,
	GET_DETAIL_VOUCHER_AFFECTATIONS,
	GET_DETAIL_VOUCHER_HEADBOARD,
	GET_DETAIL_VOUCHER_ITEMS,
	GET_DETAIL_VOUCHER_SEAT,
	GET_DETAIL_VOUCHER_TAXES,
	GET_FILTER_ATTRIBUTES,
	GET_FILTER_BRANCHES,
	GET_FILTER_CURRENCY,
	GET_FILTER_DEPOSITS,
	GET_FILTER_FUND_ACCOUNT_TYPES,
	GET_FILTER_MODULES,
	GET_FILTER_PAYMENT_CONDITIONS,
	GET_FILTER_SELLERS,
	GET_FILTER_TYPE_ACCOUNTING,
	GET_FILTER_USERS,
	GET_FILTER_VOUCHERS,
	GET_FILTER_VOUCHER_CATEGORY,
	GET_FILTER_VOUCHER_NAMES,
	GET_INVENTORY_SERIE_DATA,
	GET_ITEM_CATEGORIES,
	GET_ORDER_DIR_OPTIONS,
	GET_ORDER_OPTIONS,
	PRINT_VOUCHER_DETAIL,
	REMOVE_VOUCHER,
	SEARCH_VOUCHER
} from 'constants/ActionsTypes'
import {
	IActionFilters,
	IGetDetailVoucherHeadboardAction,
	IGetDetailVoucherHeadboardResponse,
	IGetDetailVoucherInventoryAction,
	IGetDetailVoucherItemsAction,
	IGetDetailVoucherItemsResponse,
	IGetDetailVoucherTreasuryAction,
	IGetInventorySerieDataAction,
	IGetItemCategoriesAction,
	ISearchVoucherAction,
	ISearchVoucherResponse
} from 'models/SearchVoucher'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* searchVoucherRequest({ payload }: ISearchVoucherAction) {
	try {
		const data: ISearchVoucherResponse = yield call(searchVoucherApi, payload)
		yield put(searchVouchersSuccess(data))
	} catch (error) {}
}

function* getDetailVoucherHeadboardRequest({
	payload
}: IGetDetailVoucherHeadboardAction) {
	try {
		const data: IGetDetailVoucherHeadboardResponse = yield call(
			getDetailVoucherHeadboardApi,
			payload
		)
		yield put(getDetailVoucherHeadboardSuccess(data))
	} catch (error) {}
}

function* getDetailVoucherItemsRequest({
	payload
}: IGetDetailVoucherItemsAction) {
	try {
		const data: IGetDetailVoucherItemsResponse = yield call(
			getDetailVoucherItemsApi,
			payload
		)
		yield put(getDetailVoucherItemsSuccess(data))
	} catch (error) {}
}

function* getDetailVoucherTaxesRequest({ payload }: any): any {
	try {
		const data: any = yield call(getDetailVoucherTaxesApi, payload)
		yield put(getDetailVoucherTaxesSuccess(data))
	} catch (error) {}
}

function* getDetailVoucherAffectationsRequest({ payload }: any): any {
	try {
		const data: any = yield call(getDetailVoucherAffectationsApi, payload)
		yield put(getDetailVoucherAffectationsSuccess(data))
	} catch (error) {}
}

function* getDetailVoucherSeatRequest({ payload }: any): any {
	try {
		const data: any = yield call(getDetailVoucherSeatApi, payload)
		yield put(getDetailVoucherSeatSuccess(data))
	} catch (error) {}
}

function* getDetailTaxSummaryRequest({ payload }: any): any {
	try {
		const data: any = yield call(getDetailTaxSummaryApi, payload)
		yield put(getDetailTaxSummarySuccess(data))
	} catch (error) {}
}

function* getFilterModulesRequest(): any {
	try {
		const data: any = yield call(getFilterModulesApi)
		yield put(getFilterModulesSuccess(data))
	} catch (error) {}
}

function* getFilterVouchersRequest({ payload }: any): any {
	try {
		const data: any = yield call(getFilterVouchersApi, payload)
		yield put(getFilterVouchersSuccess(data))
	} catch (error) {}
}

function* getFilterBranchesRequest({ payload }: any): any {
	try {
		const data: any = yield call(getFilterBranchesApi, payload)
		yield put(getFilterBranchesSuccess(data))
	} catch (error) {}
}

function* getFilterAttributesRequest({ payload }: IActionFilters): any {
	try {
		const data: any = yield call(getFilterAttributesApi, payload)
		yield put(getFilterAttributesSuccess(data))
	} catch (error) {}
}

function* getFilterPaymentConditionsRequest({ payload }: IActionFilters): any {
	try {
		const data: any = yield call(getFilterPaymentConditionsApi, payload)
		yield put(getFilterPaymentConditionsSuccess(data))
	} catch (error) {}
}

function* getFilterSellersRequest(): any {
	try {
		const data: any = yield call(getFilterSellersApi)
		yield put(getFilterSellersSuccess(data))
	} catch (error) {}
}

function* getFilterCurrencyRequest(): any {
	try {
		const data: any = yield call(getFilterCurrencyApi)
		yield put(getFilterCurrencySuccess(data))
	} catch (error) {}
}

function* getFilterFundAccountTypesRequest(): any {
	try {
		const data: any = yield call(getFilterFundAccountTypesApi)
		yield put(getFilterFundAccountTypesSuccess(data))
	} catch (error) {}
}

function* getFilterUsersRequest(): any {
	try {
		const data: any = yield call(getFilterUsersApi)
		yield put(getFilterUsersSuccess(data))
	} catch (error) {}
}

function* getFilterTypeAccountingRequest(): any {
	try {
		const data: any = yield call(getFilterTypeAccountingApi)
		yield put(getFilterTypeAccountingSuccess(data))
	} catch (error) {}
}

function* getFilterDepositsRequest(): any {
	try {
		const data: any = yield call(getFilterDepositsApi)
		yield put(getFilterDepositsSuccess(data))
	} catch (error) {}
}

function* getOrderOptionsRequest(): any {
	try {
		const opts: any = yield call(getOrderOptionsApi)
		yield put(getOrderOptionsSuccess(opts))
	} catch (error) {}
}

function* getOrderDirOptionsRequest(): any {
	try {
		const opts: any = yield call(getOrderDirOptionsApi)
		yield put(getOrderDirOptionsSuccess(opts))
	} catch (error) {}
}

function* getItemCategoriesRequest({ payload }: IGetItemCategoriesAction): any {
	try {
		const categories: any = yield call(getItemCategoriesApi, payload)
		yield put(getItemCategoriesSuccess(categories))
	} catch (error) {}
}

function* removeVoucherRequest({ payload }: IGetItemCategoriesAction): any {
	try {
		const voucher: any = yield call(removeVoucherApi, payload)
		yield put(removeVoucherSuccess(voucher))
	} catch (error) {}
}

function* cancelVoucherRequest({ payload }: IGetItemCategoriesAction): any {
	try {
		const voucher: any = yield call(cancelVoucherApi, payload)
		yield put(cancelVoucherSuccess(voucher))
	} catch (error) {}
}

function* getDetailTreasurySummaryRequest({
	payload
}: IGetDetailVoucherTreasuryAction): any {
	try {
		const treasury: any = yield call(getDetailTreasurySummaryApi, payload)
		yield put(getDetailTreasurySummarySuccess(treasury))
	} catch (error) {}
}

function* getDetailInventorySummaryRequest({
	payload
}: IGetDetailVoucherInventoryAction): any {
	try {
		const inventory: any = yield call(getDetailInventorySummaryApi, payload)
		yield put(getDetailInventorySummarySuccess(inventory))
	} catch (error) {}
}

function* printVoucherDetailRequest({
	payload
}: IGetItemCategoriesAction): any {
	try {
		const voucher: any = yield call(printVoucherDetailApi, payload)
		yield put(printVoucherDetailSuccess(voucher))
	} catch (error) {}
}

function* getInventorySerieDataRequest({
	payload
}: IGetInventorySerieDataAction): any {
	try {
		const series: any = yield call(getInventorySerieDataApi, payload)
		yield put(getInventorySerieDataSuccess(series))
	} catch (error) {}
}

function* getFilterVoucherCategoryRequest({ payload }: any): any {
	try {
		const values: any = yield call(getFilterVoucherCategory, payload)
		yield put(getFilterVoucherCategorySuccess(values))
	} catch (error) {}
}

function* getFilterVoucherNamesRequest({ payload }: any): any {
	try {
		const values: any = yield call(getFilterVoucherNames, payload)
		yield put(getFilterVoucherNamesSuccess(values))
	} catch (error) {}
}

export function* searchVoucherSaga() {
	yield takeEvery(SEARCH_VOUCHER, searchVoucherRequest)
}

export function* getDetailVoucherHeadboardSaga() {
	yield takeEvery(
		GET_DETAIL_VOUCHER_HEADBOARD,
		getDetailVoucherHeadboardRequest
	)
}

export function* getDetailVoucherItemsSaga() {
	yield takeEvery(GET_DETAIL_VOUCHER_ITEMS, getDetailVoucherItemsRequest)
}

export function* getDetailVoucherTaxesSaga() {
	yield takeEvery(GET_DETAIL_VOUCHER_TAXES, getDetailVoucherTaxesRequest)
}

export function* getDetailVoucherAffectationsSaga() {
	yield takeEvery(
		GET_DETAIL_VOUCHER_AFFECTATIONS,
		getDetailVoucherAffectationsRequest
	)
}

export function* getDetailVoucherSeatSaga() {
	yield takeEvery(GET_DETAIL_VOUCHER_SEAT, getDetailVoucherSeatRequest)
}

export function* getDetailTaxSummarySaga() {
	yield takeEvery(GET_DETAIL_TAX_SUMMARY, getDetailTaxSummaryRequest)
}

export function* getFilterModulesSaga() {
	yield takeEvery(GET_FILTER_MODULES, getFilterModulesRequest)
}

export function* getFilterVouchersSaga() {
	yield takeEvery(GET_FILTER_VOUCHERS, getFilterVouchersRequest)
}
export function* getFilterBranchesSaga() {
	yield takeEvery(GET_FILTER_BRANCHES, getFilterBranchesRequest)
}
export function* getFilterAttributesSaga() {
	yield takeEvery(GET_FILTER_ATTRIBUTES, getFilterAttributesRequest)
}
export function* getFilterPaymentConditionsSaga() {
	yield takeEvery(
		GET_FILTER_PAYMENT_CONDITIONS,
		getFilterPaymentConditionsRequest
	)
}

export function* getFilterSellersSaga() {
	yield takeEvery(GET_FILTER_SELLERS, getFilterSellersRequest)
}

export function* getFilterCurrencySaga() {
	yield takeEvery(GET_FILTER_CURRENCY, getFilterCurrencyRequest)
}

export function* getFilterFundAccountTypesSaga() {
	yield takeEvery(
		GET_FILTER_FUND_ACCOUNT_TYPES,
		getFilterFundAccountTypesRequest
	)
}

export function* getFilterUsersSaga() {
	yield takeEvery(GET_FILTER_USERS, getFilterUsersRequest)
}

export function* getFilterTypeAccountingSaga() {
	yield takeEvery(GET_FILTER_TYPE_ACCOUNTING, getFilterTypeAccountingRequest)
}

export function* getFilterDepositsSaga() {
	yield takeEvery(GET_FILTER_DEPOSITS, getFilterDepositsRequest)
}

export function* getOrderOptionsSaga() {
	yield takeEvery(GET_ORDER_OPTIONS, getOrderOptionsRequest)
}

export function* getOrderDirOptionsSaga() {
	yield takeEvery(GET_ORDER_DIR_OPTIONS, getOrderDirOptionsRequest)
}

export function* getItemCategoriesSaga() {
	yield takeEvery(GET_ITEM_CATEGORIES, getItemCategoriesRequest)
}

export function* removeVoucherSaga() {
	yield takeEvery(REMOVE_VOUCHER, removeVoucherRequest)
}

export function* cancelVoucherSaga() {
	yield takeEvery(CANCEL_VOUCHER, cancelVoucherRequest)
}

export function* getDetailTreasurySummarySaga() {
	yield takeEvery(GET_DETAIL_TREASURY_SUMMARY, getDetailTreasurySummaryRequest)
}

export function* getDetailInventorySummarySaga() {
	yield takeEvery(
		GET_DETAIL_INVENTORY_SUMMARY,
		getDetailInventorySummaryRequest
	)
}

export function* printVoucherDetailSaga() {
	yield takeEvery(PRINT_VOUCHER_DETAIL, printVoucherDetailRequest)
}

export function* getInventorySerieDataSaga() {
	yield takeEvery(GET_INVENTORY_SERIE_DATA, getInventorySerieDataRequest)
}

export function* getFilterVoucherCategorySaga() {
	yield takeEvery(GET_FILTER_VOUCHER_CATEGORY, getFilterVoucherCategoryRequest)
}

export function* getFilterVoucherNamesSaga() {
	yield takeEvery(GET_FILTER_VOUCHER_NAMES, getFilterVoucherNamesRequest)
}

export default function* rootSaga() {
	yield all([
		fork(searchVoucherSaga),
		fork(getDetailVoucherHeadboardSaga),
		fork(getDetailVoucherItemsSaga),
		fork(getDetailVoucherTaxesSaga),
		fork(getDetailVoucherAffectationsSaga),
		fork(getDetailVoucherSeatSaga),
		fork(getDetailTaxSummarySaga),
		fork(getFilterModulesSaga),
		fork(getFilterVouchersSaga),
		fork(getFilterBranchesSaga),
		fork(getFilterAttributesSaga),
		fork(getFilterPaymentConditionsSaga),
		fork(getFilterSellersSaga),
		fork(getFilterCurrencySaga),
		fork(getFilterFundAccountTypesSaga),
		fork(getFilterUsersSaga),
		fork(getFilterTypeAccountingSaga),
		fork(getFilterDepositsSaga),
		fork(getOrderOptionsSaga),
		fork(getOrderDirOptionsSaga),
		fork(getItemCategoriesSaga),
		fork(removeVoucherSaga),
		fork(cancelVoucherSaga),
		fork(getDetailTreasurySummarySaga),
		fork(printVoucherDetailSaga),
		fork(getDetailInventorySummarySaga),
		fork(getInventorySerieDataSaga),
		fork(getFilterVoucherCategorySaga),
		fork(getFilterVoucherNamesSaga)
	])
}
