import {
	CLEAR_TABLE_VALIDATIONS,
	GET_TABLE_IS_CUIT_REPEATED,
	GET_TABLE_IS_CUIT_REPEATED_SUCCESS,
	GET_TABLE_IS_DNI_REPEATED,
	GET_TABLE_IS_DNI_REPEATED_SUCCESS,
	GET_TABLE_IS_POSTAL_CODE_VALID,
	GET_TABLE_IS_POSTAL_CODE_VALID_SUCCESS,
	GET_TABLE_IS_TAXES_ID_VALID,
	GET_TABLE_IS_TAXES_ID_VALID_SUCCESS
} from 'constants/ActionsTypes'
import { getValuesDataSuccessInterface } from 'constants/valuesInterfaces/interfaces'
import {
	IGetTableIsPostalCodeValid,
	IGetTableIsTaxesIdValid,
	IGetTableIsTaxesIdValidRes
} from 'models/TableCalls/DynamicValidationsInterface'

export const getTableIsPostalCodeValid = (
	payload: IGetTableIsPostalCodeValid
) => {
	return {
		type: GET_TABLE_IS_POSTAL_CODE_VALID,
		payload
	}
}

export const getTableIsPostalCodeValidSuccess = (
	payload: any
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_IS_POSTAL_CODE_VALID_SUCCESS,
		payload
	}
}

export const getTableIsTaxesIdValid = (payload: IGetTableIsTaxesIdValid) => {
	return {
		type: GET_TABLE_IS_TAXES_ID_VALID,
		payload
	}
}

export const getTableIsTaxesIdValidSuccess = (
	payload: IGetTableIsTaxesIdValidRes
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_IS_TAXES_ID_VALID_SUCCESS,
		payload
	}
}

export const getTableIsDNIRepeated = (payload: any) => {
	return {
		type: GET_TABLE_IS_DNI_REPEATED,
		payload
	}
}

export const getTableIsDNIRepeatedSuccess = (payload: any) => {
	return {
		type: GET_TABLE_IS_DNI_REPEATED_SUCCESS,
		payload
	}
}

export const getTableIsCUITRepeated = (payload: any) => {
	return {
		type: GET_TABLE_IS_CUIT_REPEATED,
		payload
	}
}

export const getTableIsCUITRepeatedSuccess = (payload: any) => {
	return {
		type: GET_TABLE_IS_CUIT_REPEATED_SUCCESS,
		payload
	}
}

export const clearTableValidations = () => ({
	type: CLEAR_TABLE_VALIDATIONS
})
