import InputAutoCompleteFormik from 'components/form/InputAutoCompleteFormik'
import InputCheckBox from 'components/form/InputCheckBox'
import InputDate from 'components/form/inputDate'
import InputDropdown from 'components/form/inputDropdown'
import InputHidden from 'components/form/inputHidden'
import InputText from 'components/form/inputText'
import InputTextArea from 'components/form/InputTextArea'
import InputWithModal from 'components/form/inputWithModal'
import React from 'react'
import { Col } from 'react-bootstrap'
import _ from 'underscore'
import { getValueMask } from './MaskValues'

/**
 * to show the form fields from the api configuration
 * @param {object} formikProps these are the formik props that are used for required field validations, this field is not required
 * @param {object} fields object with the configuration of all the inputs, this configuration comes regularly from the api of '/ProcessDeComprobante'
 * "idCampo":string,"descrip":string, "label":string, "mascara": string || null, "editable":int, "visible":int, "requerido":int, "valid":null,
 * @param {array} noRenderInputs array with keys not rendered in function, this field is not required
 * @param {boolean} disableForm  Boolean used to disable the entire form and leave it in read mode, this field is not required
 * @returns fields to formik
 */
export const renderFormInputs = (
	formikProps,
	fields,
	noRenderInputs = [],
	disableForm
) => {
	const result = []
	if (fields.length) {
		fields.forEach((field) => {
			const fieldId = field.idCampo.trim()
			if (
				field.visible &&
				field.editable &&
				!noRenderInputs.includes(fieldId)
			) {
				result.push(
					<Col key={field.idCampo}>
						{renderInput(field, formikProps, disableForm)}
					</Col>
				)
			}
		})
	}

	return result
}

/**
 * to show the form fields from the api configuration
 * @param {object} fields object with the configuration of all the inputs, this configuration comes regularly from the api of '/ProcessDeComprobante'
 * "idCampo":string,"descrip":string, "label":string, "mascara": string || null, "editable":int, "visible":int, "requerido":int, "valid":null,
 * @param {object} formikProps these are the formik props that are used for required field validations, this field is not required
 * @param {array} noRenderInputs array with keys not rendered in function
 * @param {boolean} disableForm  Boolean used to disable the entire form and leave it in read mode, this field is not required
 * @returns fields to formik
 */
export const renderInput = (field, formikProps, disableForm = false) => {
	const { values, handleChange, setFieldValue } = formikProps
	const fieldId = field.idCampo.trim()
	let result = null
	const optionsInput = {
		...field.customProps,
		inputFormCol: { sm: 12 },
		fields: [{ ...field, label: false }],
		label: field.label,
		inputId: `${fieldId}`,
		id: `${fieldId}`,
		name: `${fieldId}`,
		prefix: field.prefix,
		suffix: field.suffix,
		placeholder: field.placeholder,
		colLabel: field.colLabel || 'col-sm-3',
		colInput: field.colInput || 'col-sm-9',
		divStyle: { paddingLeft: '10px', paddingRight: '10px' },
		disabledInput: disableForm || field.disabled || false,
		autoComplete: field.autoComplete || 'on',
		value: field.value !== undefined ? field.value : values[fieldId] || '',
		rowStyle: { marginBottom: '5px' },
		handleEnterKey: field.handleEnterKey
			? field.handleEnterKey
			: (e, data) => {},
		onBlur: field.onBlur ? field.onBlur : () => {},
		onChange: field.onChange
			? field.onChange
			: (value) => {
					value === ''
						? setFieldValue(fieldId, '')
						: value === true
						? setFieldValue(fieldId, 1)
						: value === false
						? setFieldValue(fieldId, 0)
						: setFieldValue(fieldId, value)
			  },
		handleClear: field.handleClear
			? field.handleClear
			: () => setFieldValue(fieldId, '')
	}

	let options

	switch (field.tipo) {
		case 'combo':
			options =
				field.valores && field.valores.length
					? field.valores.map((value) => ({
							id: value.cod_valor,
							label: value.desc_valor
					  }))
					: []
			result = (
				<InputDropdown
					{...optionsInput}
					onChange={field.onChange || handleChange}
					options={options}
				/>
			)
			break
		case 'check':
			result = <InputCheckBox {...optionsInput} />
			break
		case 'textArea':
			result = <InputTextArea {...optionsInput} />
			break
		case 'autoComplete':
			result = <InputAutoCompleteFormik {...optionsInput} />
			break
		case 'date':
			result = <InputDate {...optionsInput} />
			break
		case 'withModal':
			result = <InputWithModal {...optionsInput} />
			break
		case 'hidden':
			result = <InputHidden {...optionsInput} />
			break
		default:
			result = <InputText {...optionsInput} />
			break
	}
	return result
}

/**
 * to set value in formik values,
 * @param data
 * @param inputId
 */
export const handleSetValueInput = (
	data,
	inputId,
	formikProps,
	typeInput = 'text'
) => {
	const { setFieldValue, setFieldTouched } = formikProps

	if (data === null) {
		setFieldValue(inputId, '')
	} else if (typeof data === 'object') {
		if ('target' in data) {
			const { value } = data.target
			setFieldValue(inputId, value)
		} else {
			if (typeInput === 'date') {
				setFieldValue(inputId, data) // TODO when it is a date object it is necessary to save it as an object
			} else {
				setFieldValue(inputId, String(data))
			}
		}
	} else {
		setFieldValue(inputId, data)
	}

	setFieldTouched(inputId, true)
}

/**
 * to get options for dropdown
 * @param options
 */
export const getOptionsDropDown = (options) => {
	return _.isEmpty(options)
		? []
		: options
				.filter((opt, indx) => {
					return _.findIndex(options, { codigo: opt.codigo }) === indx
				})
				.map((opt) => ({
					id: opt.codigo,
					label: opt.descrip
				}))
}

/**
 * to get currency options in search vouchers
 * @param {*} items
 * @returns
 */
export const getCurrencyOptions = (items) => {
	return items && items.filtro_moneda
		? items.filtro_moneda.map((filter) => ({
				id: filter.cod_mone,
				label: filter.cod_mone
		  }))
		: []
}

/**
 * to render las columns
 * @returns
 */
export const getDefaultColumns = (fields, store, additionalColumns = []) => {
	const rows = fields.map((field) => {
		const idField = field.idCampo.trim()
		return {
			dataField: idField,
			text: field.label || '',
			align: 'center',
			headerAlign: 'center',
			hidden: !field.visible,
			formatter: (cell, row) => renderFormat(field, cell, store)
		}
	})

	return rows.concat(additionalColumns)
}

/**
 * add format to all table cell
 */
const renderFormat = (field, cell, store) => {
	let result = ''
	if (field.mascara && !_.isEmpty(store)) {
		result = getValueMask(cell !== null ? `${cell}` : '', field.mascara, store)
	} else {
		result = cell
	}

	return result
}

/**
 * to render checkInputs from modules.
 * @returns
 */
export const renderCheckBasicModules = (modules, callBackChange) => {
	return modules.map((module) => {
		const propsCheck = {
			inputFormCol: { sm: 2 },
			fields: [
				{
					idCampo: module.label,
					label: module.label,
					mascara: '',
					editable: 1,
					visible: 1,
					requerido: 0,
					valid: ''
				}
			],
			label: module.label,
			inputId: module.label,
			name: module.label,
			placeholder: '',
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			styleLabel: { fontSize: '15px' },
			checked: module.check,
			onChange: (data) => callBackChange(data, module)
		}

		return <InputCheckBox key={module.label} {...propsCheck} />
	})
}
