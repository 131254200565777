import { LoadInventoryItemsActions } from 'constants/ActionsTypesTs'
import { ICommonInitialState } from 'models/CommonInterface'
import {
	ILoadItemIncomeInventoryProps,
	ILoadItemInventoryProps,
	ILoadItemInventoryResponse
} from 'models/LoadInventoryItems'
import { AnyAction } from 'redux'

export interface ILoadItemInventoryStore extends ICommonInitialState {
	data: ILoadItemInventoryResponse | null
	params: ILoadItemInventoryProps | null
}

export interface ILoadItemIncomeInventoryStore extends ICommonInitialState {
	data: ILoadItemInventoryResponse | null
	params: ILoadItemIncomeInventoryProps | null
}

export interface ILoadItemInventoryState {
	inventoryItems: ILoadItemInventoryStore
	inventoryIncomeItems: ILoadItemIncomeInventoryStore
}

const initialState: ILoadItemInventoryState = {
	inventoryItems: {
		data: null,
		params: null,
		status: 'idle',
		error: null
	},
	inventoryIncomeItems: {
		data: null,
		params: null,
		status: 'idle',
		error: null
	}
}

function LoadInventoryItemsReducer(
	state: ILoadItemInventoryState = initialState,
	action: AnyAction
): ILoadItemInventoryState {
	switch (action.type) {
		case LoadInventoryItemsActions.LOAD_ITEM_INVENTORY:
			return {
				...state,
				inventoryItems: {
					status: 'loading',
					params: action.payload,
					data: null,
					error: null
				}
			}
		case LoadInventoryItemsActions.LOAD_ITEM_INVENTORY_SUCCESS:
			return {
				...state,
				inventoryItems: {
					...state.inventoryItems,
					status: 'succeeded',
					data: action.payload,
					error: null
				}
			}
		case LoadInventoryItemsActions.LOAD_ITEM_INVENTORY_ERROR:
			return {
				...state,
				inventoryItems: {
					status: 'failed',
					error: action.payload,
					data: null,
					params: null
				}
			}
		case LoadInventoryItemsActions.LOAD_ITEM_INCOME_INVENTORY:
			return {
				...state,
				inventoryIncomeItems: {
					status: 'loading',
					params: action.payload,
					data: null,
					error: null
				}
			}
		case LoadInventoryItemsActions.LOAD_ITEM_INCOME_INVENTORY_SUCCESS:
			return {
				...state,
				inventoryIncomeItems: {
					...state.inventoryIncomeItems,
					status: 'succeeded',
					data: action.payload,
					error: null
				}
			}
		case LoadInventoryItemsActions.LOAD_ITEM_INCOME_INVENTORY_ERROR:
			return {
				...state,
				inventoryIncomeItems: {
					status: 'failed',
					error: action.payload,
					data: null,
					params: null
				}
			}

		default:
			return { ...state }
	}
}

export default LoadInventoryItemsReducer
