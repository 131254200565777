import withMenu from 'components/common/withMenu'
import GlobalContainer from 'components/layout/globalContainer'
import ProviderHeaderForm from 'components/providerHeader/providerHeaderForm'
import { P_CARGAITEM_CPA, P_CPACAB } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

class ProviderHeader extends Component {
	constructor(props) {
		super(props)
		this.formRef = React.createRef()
	}

	callBackButton = () => {
		if (this.formRef.current) {
			this.formRef.current.handleSubmit()
		}
	}

	render() {
		return (
			<Row>
				<GlobalContainer
					codeProcess={P_CPACAB}
					callBackButton={this.callBackButton}
					breadCrumbButtonType={'primary'}
					shoppingCartProcess={P_CARGAITEM_CPA}
					nextPage
				>
					<ProviderHeaderForm formRef={this.formRef} />
				</GlobalContainer>
			</Row>
		)
	}
}

export default withTranslation()(withMenu(ProviderHeader))
