import React, { useEffect, useState } from 'react'
import { DropzoneState, useDropzone } from 'react-dropzone'
import _ from 'underscore'
import { useStyles } from './DragDropFileStyles'
import { DragDropFileProps } from './DragDropFileTypes'

const DragDropFile = (props: DragDropFileProps) => {
	const {
		id,
		children,
		displayStyle,
		actionSetFile,
		acceptedFiles,
		disabled,
		className,
		style
	} = props

	const [file, setFile] = useState<Array<File>>([])
	const classes = useStyles()

	useEffect(() => {
		if (_.isEmpty(file)) {
			actionSetFile(null)
		} else {
			actionSetFile(file)
		}
	}, [file])

	const { getRootProps, getInputProps }: DropzoneState = useDropzone({
		accept: acceptedFiles || '.txt, .xls, .xlsx',
		onDrop: (files: Array<File>) => {
			if (!disabled) {
				setFile(
					files.map((upFile) =>
						Object.assign(upFile, {
							preview: URL.createObjectURL(upFile)
						})
					)
				)
			}
		}
	})

	return (
		<div {...getRootProps()}>
			<div
				id={id}
				{...getInputProps()}
				style={style}
				className={`
					${className || ''}
					${classes.drag_drop_container}
					${displayStyle.success && !disabled ? classes.drag_drop_container_success : ''}
					${displayStyle.error && !disabled ? classes.drag_drop_container_error : ''}
					${disabled ? classes.drag_drop_container_disabled : ''}
				`}
			>
				{children}
			</div>
		</div>
	)
}

export default DragDropFile
