import Axios from 'axios'
import {
	ICrudGetUserProfiles,
	ICrudUpdateUserProfiles
} from 'models/CrudUserInterface'
const urlCRUD = '/abm_usuarios'

export const crudGetUserProfiles = async (params: ICrudGetUserProfiles) => {
	const response = await Axios.get(`${urlCRUD}/perfiles/sel`, { params })
	return response.data
}

export const crudUpdateUserProfiles = async (
	params: ICrudUpdateUserProfiles
) => {
	const response = await Axios.post(`${urlCRUD}/perfiles/confirmar`, params)
	return response.data
}
