import InputButton from 'components/form/inputButton'
import InputText from 'components/form/inputText'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './stockProvider.module.scss'

const fields = [
	{
		idCampo: 'product_name',
		descrip: '',
		label: 'Producto',
		mascara: '',
		editable: 1,
		visible: 1,
		requerido: 0,
		valid: null
	},
	{
		idCampo: 'product_presentation',
		descrip: '',
		label: 'Presentacion',
		mascara: '',
		editable: 1,
		visible: 1,
		requerido: 0,
		valid: null
	},
	{
		idCampo: 'product_filtro',
		descrip: '',
		label: false,
		mascara: '',
		editable: 1,
		visible: 1,
		requerido: 0,
		valid: null
	}
]

export default class StockDisplay extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cantPendent: 0,
			cantAffect: 0
		}
	}

	componentDidUpdate = (prevProps) => {
		const { productStock, applyStock, searchItems, loadStock } = this.props
		if (productStock !== prevProps.productStock && productStock) {
			this.handleSetTotals(productStock)
		}

		if (applyStock !== prevProps.applyStock && applyStock) {
			this.handleSetTotals(applyStock)
		}

		if (loadStock !== prevProps.loadStock && loadStock) {
			this.handleSetTotals(loadStock)
		}

		if (searchItems !== prevProps.searchItems && searchItems) {
			this.handleSetTotals(searchItems)
		}
	}

	handleSetTotals = (totals) => {
		this.setState({
			cantPendent: totals.tot_pendiente,
			cantAffect: totals.tot_afectado
		})
	}

	render() {
		const { t, theme, productStock } = this.props
		const { cantPendent, cantAffect } = this.state
		const descProd = productStock ? productStock.producto : ''
		const presentProd =
			productStock && productStock.presentacion ? productStock.presentacion : ''
		const uniVta =
			productStock && productStock.unid_vta ? productStock.unid_vta : ''
		return (
			<Container className={styles.containerDisplay}>
				<Row>
					<Col lg={10} md={12} className={`${styles.contentDisplay} `}>
						<Row>
							<InputText
								inputFormCol={{ lg: 12, md: 12 }}
								fields={fields}
								label={'label'}
								inputId={'product_name'}
								name={'product_name'}
								placeholder={''}
								styles={{ width: '100%' }}
								colLabel={'col-lg-2 col-md-3'}
								colInput={'col-lg-10 col-md-9'}
								disable={true}
								onBlur={() => {}}
								value={descProd}
								onChange={() => {}}
								divStyle={{}}
							/>
							<InputText
								inputFormCol={{ lg: 12, md: 12 }}
								fields={fields}
								label={'label'}
								inputId={'product_presentation'}
								name={'product_presentation'}
								placeholder={''}
								styles={{ width: '100%' }}
								colLabel={'col-lg-2 col-md-3'}
								colInput={'col-lg-10 col-md-9'}
								disable={true}
								onBlur={() => {}}
								value={presentProd}
								onChange={() => {}}
								divStyle={{}}
							/>

							<Col sm={2}>{t('Stock.pending')}</Col>
							<Col sm={4} className={'text-left'}>
								{`${cantPendent} ${uniVta}`}
							</Col>
							<Col sm={2}>{t('Stock.affected')}</Col>
							<Col sm={4} className={'text-left'}>
								{`${cantAffect} ${uniVta}`}
							</Col>
						</Row>
					</Col>
					<Col lg={2} md={12} className={'text-center pt-2 pb-3'}>
						<Row>
							<Col lg={12} md={6} className={'text-right  mb-3 mt-2'}>
								<InputButton
									valueButton={t('Collection.form.buttons.accept')}
									type={'primary'}
									onClick={() => this.props.handleConfirmStock()}
									customStyle={{ minWidth: '125px' }}
								/>
							</Col>
							<Col lg={12} md={6} className={'text-right mb-3 mt-2'}>
								<InputButton
									valueButton={t('Collection.form.buttons.cancel')}
									type={'primary'}
									onClick={() => this.props.handleCancelStock()}
									customStyle={{ minWidth: '125px' }}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		)
	}
}
