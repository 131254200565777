import {
	crudGetTermsOfSalesDuesSuccess,
	crudUpdateTermsOfSalesDuesSuccess
} from 'actions'
import {
	crudGetTermsOfSalesDues,
	crudUpdateTermsOfSalesDues
} from 'api/TermsOfSalesDuesApi'
import {
	CRUD_GET_TERMS_OF_SALES_DUES,
	CRUD_UPDATE_TERMS_OF_SALES_DUES
} from 'constants/ActionsTypes'
import {
	ICrudGetTermsOfSalesDuesSaga,
	ICrudUpdateTermsOfSalesDuesSaga
} from 'models/CrudTermsOfSalesInterface'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

function* crudGetTermsOfSalesDuesRequest({
	payload
}: ICrudGetTermsOfSalesDuesSaga): any {
	try {
		const values: any = yield call(crudGetTermsOfSalesDues, payload)
		yield put(crudGetTermsOfSalesDuesSuccess(values))
	} catch (error) {}
}

function* crudUpdateTermsOfSalesDuesRequest({
	payload
}: ICrudUpdateTermsOfSalesDuesSaga): any {
	try {
		const values: any = yield call(crudUpdateTermsOfSalesDues, payload)
		yield put(crudUpdateTermsOfSalesDuesSuccess(values))
	} catch (error) {}
}

export function* crudGetTermsOfSalesDuesSaga() {
	yield takeLatest(CRUD_GET_TERMS_OF_SALES_DUES, crudGetTermsOfSalesDuesRequest)
}

export function* crudUpdateTermsOfSalesDuesSaga() {
	yield takeLatest(
		CRUD_UPDATE_TERMS_OF_SALES_DUES,
		crudUpdateTermsOfSalesDuesRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetTermsOfSalesDuesSaga),
		fork(crudUpdateTermsOfSalesDuesSaga)
	])
}
