import {
	confirmIndLoadItems,
	confirmLoadItemsApi,
	confirmProvIndLoadItems,
	confirmProvLoadItemsApi,
	confirmTableItems,
	getCartItemInfo,
	getLoadItems,
	getProviderCartItemInfo,
	itemProviderAddToCartApi,
	updateCartItem,
	updateProviderCartItem
} from 'api/LoadItems'
import { LoadInventoryItemsActions } from 'constants/ActionsTypesTs'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
	confirmIndLoadItemsSuccess,
	confirmLoadItems,
	confirmLoadItemsSuccess,
	confirmProvIndLoadItemsSuccess,
	confirmProvLoadItemsSuccess,
	confirmTableItemsSuccess,
	getCartItemInfoSuccess,
	getLoadItemsSuccess,
	getProviderCartItemInfoSuccess,
	itemProviderAddToCart,
	itemProviderAddToCartSuccess,
	loadItemIncomeInventory,
	loadItemInventory,
	updateCartItemSuccess,
	updateProviderCartItemSuccess
} from '../actions'
import { getPriceByProduct } from '../api/Product'
import {
	CONFIRM_IND_LOAD_ITEMS,
	CONFIRM_LOAD_ITEMS,
	CONFIRM_PROVIDER_IND_LOAD_ITEMS,
	CONFIRM_PROVIDER_LOAD_ITEMS,
	CONFIRM_TABLE_ITEMS,
	GET_CART_ITEM_INFO,
	GET_LOAD_ITEMS,
	GET_PRICE_ADD_CART,
	GET_PROVIDER_CART_ITEM_INFO,
	ITEM_PROVIDER_ADD_TO_CART,
	UPDATE_CART_ITEM,
	UPDATE_PROVIDER_CART_ITEM
} from '../constants/ActionsTypes'

function* getLoadItemsRequest({ payload }) {
	try {
		const loadItems = yield call(getLoadItems, payload)
		yield put(getLoadItemsSuccess(loadItems))
	} catch (error) {}
}

function* confirmLoadItemsRequest({ payload }) {
	try {
		const loadItems = yield call(confirmLoadItemsApi, payload)
		yield put(confirmLoadItemsSuccess(loadItems))
	} catch (error) {}
}

function* confirmTableItemsRequest({ payload }) {
	const { idOperacion, callBackReturn } = payload
	try {
		const items = yield call(confirmTableItems, { idOperacion })
		callBackReturn()
		yield put(confirmTableItemsSuccess(items))
	} catch (error) {}
}

function* itemProviderAddToCartRequest({ payload }) {
	try {
		const loadItems = yield call(itemProviderAddToCartApi, payload)
		yield put(itemProviderAddToCartSuccess(loadItems))
	} catch (error) {}
}

function* getPriceAddCartRequest({ payload }) {
	const { params, apiByForm } = payload
	const { cuf_dest, ...rest } = params

	try {
		const { prod_pcio_vta } = yield call(getPriceByProduct, rest)

		if (prod_pcio_vta !== undefined && prod_pcio_vta !== null) {
			const itemsConfirm = {
				idOperacion: params.idOperacion,
				Niprod: params.Idproducto,
				cod_unid: params.unid_vta,
				cantidad: params.cantidad,
				pcio_unit: prod_pcio_vta,
				desc_prod: params.desc_prod,
				neto: parseFloat(prod_pcio_vta * params.cantidad)
			}

			switch (apiByForm) {
				case LoadInventoryItemsActions.FORM_LOAD_ITEM_FORM:
					yield put(confirmLoadItems(itemsConfirm))
					break
				case LoadInventoryItemsActions.FORM_LOAD_ITEM_PROVIDER:
					itemsConfirm.fec_entrega = params.fec_entrega
					yield put(itemProviderAddToCart(itemsConfirm))
					break
				case LoadInventoryItemsActions.FORM_LOAD_INCOME_ITEM_INVENTORY:
					itemsConfirm.fec_entrega = params.fec_entrega
					itemsConfirm.niprod = params.Idproducto
					delete itemsConfirm.Niprod
					yield put(loadItemIncomeInventory(itemsConfirm))
					break
				case LoadInventoryItemsActions.FORM_LOAD_ITEM_INVENTORY:
					itemsConfirm.cuf_dest = cuf_dest
					yield put(loadItemInventory(itemsConfirm))
					break
			}
		}
	} catch (error) {}
}

function* getCartItemInfoRequest({ payload }) {
	try {
		const itemInfo = yield call(getCartItemInfo, payload)
		yield put(getCartItemInfoSuccess(itemInfo))
	} catch (error) {}
}

function* updateCartItemRequest({ payload }) {
	try {
		const item = yield call(updateCartItem, payload)
		yield put(updateCartItemSuccess(item))
	} catch (error) {}
}

function* confirmIndLoadItemsRequest({ payload }) {
	const { idOperacion, callBackReturn } = payload
	try {
		const items = yield call(confirmIndLoadItems, { idOperacion })
		callBackReturn()
		yield put(confirmIndLoadItemsSuccess(items))
	} catch (error) {}
}

function* getProviderCartItemInfoRequest({ payload }) {
	try {
		const itemInfo = yield call(getProviderCartItemInfo, payload)
		yield put(getProviderCartItemInfoSuccess(itemInfo))
	} catch (error) {}
}

function* updateProviderCartItemRequest({ payload }) {
	try {
		const item = yield call(updateProviderCartItem, payload)
		yield put(updateProviderCartItemSuccess(item))
	} catch (error) {}
}

function* confirmProvIndLoadItemsRequest({ payload }) {
	const { idOperacion, callBackReturn } = payload
	try {
		const items = yield call(confirmProvIndLoadItems, { idOperacion })
		callBackReturn()
		yield put(confirmProvIndLoadItemsSuccess(items))
	} catch (error) {}
}

function* confirmProvLoadItemsRequest({ payload }) {
	const { idOperacion, callBackReturn } = payload
	try {
		const items = yield call(confirmProvLoadItemsApi, { idOperacion })
		callBackReturn()
		yield put(confirmProvLoadItemsSuccess(items))
	} catch (error) {}
}

export function* getLoadItemsSaga() {
	yield takeLatest(GET_LOAD_ITEMS, getLoadItemsRequest)
}

export function* confirmLoadItemsSaga() {
	yield takeLatest(CONFIRM_LOAD_ITEMS, confirmLoadItemsRequest)
}

export function* confirmTableItemsSaga() {
	yield takeLatest(CONFIRM_TABLE_ITEMS, confirmTableItemsRequest)
}

export function* itemProviderAddToCartSaga() {
	yield takeLatest(ITEM_PROVIDER_ADD_TO_CART, itemProviderAddToCartRequest)
}

export function* getPriceAddCartSaga() {
	yield takeLatest(GET_PRICE_ADD_CART, getPriceAddCartRequest)
}

export function* getCartItemInfoSaga() {
	yield takeLatest(GET_CART_ITEM_INFO, getCartItemInfoRequest)
}

export function* updateCartItemSaga() {
	yield takeLatest(UPDATE_CART_ITEM, updateCartItemRequest)
}

export function* confirmIndLoadItemsSaga() {
	yield takeLatest(CONFIRM_IND_LOAD_ITEMS, confirmIndLoadItemsRequest)
}

export function* getProviderCartItemInfoSaga() {
	yield takeLatest(GET_PROVIDER_CART_ITEM_INFO, getProviderCartItemInfoRequest)
}

export function* updateProviderCartItemSaga() {
	yield takeLatest(UPDATE_PROVIDER_CART_ITEM, updateProviderCartItemRequest)
}

export function* confirmProvIndLoadItemsSaga() {
	yield takeLatest(
		CONFIRM_PROVIDER_IND_LOAD_ITEMS,
		confirmProvIndLoadItemsRequest
	)
}

export function* confirmProvLoadItemsSaga() {
	yield takeLatest(CONFIRM_PROVIDER_LOAD_ITEMS, confirmProvLoadItemsRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getLoadItemsSaga),
		fork(confirmLoadItemsSaga),
		fork(confirmTableItemsSaga),
		fork(itemProviderAddToCartSaga),
		fork(getPriceAddCartSaga),
		fork(getCartItemInfoSaga),
		fork(updateCartItemSaga),
		fork(confirmIndLoadItemsSaga),
		fork(getProviderCartItemInfoSaga),
		fork(updateProviderCartItemSaga),
		fork(confirmProvIndLoadItemsSaga),
		fork(confirmProvLoadItemsSaga)
	])
}
