import {
	crudGetUserProfiles,
	crudUpdateUserProfiles
} from 'api/UserProfilesApi'
import {
	ICrudGetUserProfilesSaga,
	ICrudUpdateUserProfilesSaga
} from 'models/CrudUserInterface'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
	crudGetUserProfilesSuccess,
	crudUpdateUserProfilesSuccess
} from '../actions'
import {
	CRUD_GET_USER_PROFILES,
	CRUD_UPDATE_USER_PROFILES
} from '../constants/ActionsTypes'

function* crudGetUserProfilesReq({ payload }: ICrudGetUserProfilesSaga): any {
	try {
		const costCenter = yield call(crudGetUserProfiles, payload)
		yield put(crudGetUserProfilesSuccess(costCenter))
	} catch (error) {}
}

function* crudUpdateUserProfilesReq({
	payload
}: ICrudUpdateUserProfilesSaga): any {
	try {
		const costCenter = yield call(crudUpdateUserProfiles, payload)
		yield put(crudUpdateUserProfilesSuccess(costCenter))
	} catch (error) {}
}

export function* crudGetUserProfilesSaga() {
	yield takeLatest(CRUD_GET_USER_PROFILES, crudGetUserProfilesReq)
}

export function* crudUpdateUserProfilesSaga() {
	yield takeLatest(CRUD_UPDATE_USER_PROFILES, crudUpdateUserProfilesReq)
}

export default function* rootSaga() {
	yield all([fork(crudGetUserProfilesSaga), fork(crudUpdateUserProfilesSaga)])
}
