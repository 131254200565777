import {
	confirmProvIndLoadItems,
	getProductsCart,
	itemProviderAddToCart,
	searchProducts
} from 'actions'
import NotificationMessage from 'components/common/notificationMessage'
import IndividualSearchProduct from 'components/individualLoadItems/IndividualSearchProduct'
import React, { PureComponent } from 'react'
import { Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import ProviderIndividualLoadItemsTable from './ProviderIndividualLoadItemsTable'

class ProviderIndividualLoadItemsForm extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			showMessage: false,
			notificationMessage: 'Se actualizo el item del carro'
		}
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.formConfirmation(this.handleConfirmation)
		this.props.getProductsCart({ idOperacion, page_number: 1, page_size: 10 })
		this.handleConfirmation = this.handleConfirmation.bind(this)
	}

	componentDidUpdate = (prevProps) => {
		const {
			providerItemsAdd,
			idOperacion,
			provUpdateItemCart,
			removeItemCart,
			canceledStock
		} = this.props

		if (prevProps.providerItemsAdd !== providerItemsAdd && providerItemsAdd) {
			this.props.getProductsCart({ idOperacion, page_number: 1, page_size: 10 })
		}

		if (removeItemCart !== prevProps.removeItemCart && removeItemCart) {
			this.setState({
				showMessage: true,
				notificationMessage: 'El item se removió con éxito'
			})
		}

		if (
			provUpdateItemCart !== prevProps.provUpdateItemCart &&
			provUpdateItemCart
		) {
			this.props.getProductsCart({ idOperacion, page_number: 1, page_size: 10 })
			this.setState({ showMessage: true })
		}

		if (
			prevProps.canceledStock !== canceledStock &&
			canceledStock &&
			canceledStock.Resultado
		) {
			this.props.getProductsCart({ idOperacion, page_number: 1, page_size: 10 })
		}
	}

	/**
	 * to call confirm api and go to the next page
	 * @param {Function} callBackReturn
	 */
	handleConfirmation = () => {
		const { idOperacion, callBackReturn } = this.props
		this.props.confirmProvIndLoadItems({ idOperacion, callBackReturn })
	}

	/**
	 * callback used when change of pagination
	 * @param type
	 * @param pagination
	 */
	handleOnTableChange = (type, pagination) => {
		const { idOperacion } = this.props
		const { page, sizePerPage } = pagination
		this.props.getProductsCart({
			idOperacion,
			page_number: page,
			page_size: sizePerPage
		})
	}

	/**
	 * to close notification message
	 */
	handleCloseMessage = () => {
		this.setState({ showMessage: false })
	}

	render() {
		const {
			idOperacion,
			config,
			preventAddToCart,
			handleMoveArrow,
			itemProviderAddToCart
		} = this.props
		const { showMessage, notificationMessage } = this.state
		return (
			<Col sm={12}>
				<NotificationMessage
					showError={showMessage}
					errorMessage={notificationMessage}
					handleCloseError={this.handleCloseMessage}
					type={'success'}
				/>
				<IndividualSearchProduct
					idOperacion={idOperacion}
					config={config}
					preventAddToCart={preventAddToCart}
					handleMoveArrow={handleMoveArrow}
					handleItemAddToCart={itemProviderAddToCart}
					isProvider={true}
				/>
				{config && (
					<ProviderIndividualLoadItemsTable
						idOperacion={idOperacion}
						config={config}
						handleChangeTable={this.handleOnTableChange}
					/>
				)}
			</Col>
		)
	}
}

const mapStateToProps = ({ product, auth, loadItems, stockProvider }) => {
	const { authUser } = auth
	const { providerItemsAdd, provUpdateItemCart } = loadItems
	const { removeItemCart, search } = product
	const { canceledStock } = stockProvider

	return {
		search,
		authUser,
		providerItemsAdd,
		provUpdateItemCart,
		removeItemCart,
		canceledStock
	}
}

const connectForm = connect(mapStateToProps, {
	searchProducts,
	getProductsCart,
	confirmProvIndLoadItems,
	itemProviderAddToCart
})(ProviderIndividualLoadItemsForm)

export default connectForm
