import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { WithTranslation, withTranslation } from 'react-i18next'
import _ from 'underscore'
import styles from './inputAutocomplete.module.css'

interface InputAutoCompleteProps extends WithTranslation {
	label: string
	placeholder: string
	name: string
	inputId: string
	colInput: string
	colLabel: string
	styleLabel: React.CSSProperties
	divStyle: React.CSSProperties
	auoptions: Array<{ id: string; label: string }>
	handleLoading: boolean
	disable: boolean
	disabledInput: boolean
	inputFormCol: {}
	minLength: number
	defaultValue: string
	disableValid?: boolean
	t: any
	clearButton: boolean
	fields: Array<IFieldStructure>
	handleSelect: (params: { id: string; label: string }) => void
	handleClear: () => void
	handleSearch: (params: any) => void
	onBlur: () => void
	handlePartialChange?: (params: any) => void
}

type InputAutoCompleteState = {
	isLoading: boolean
	isSelected: boolean
	isInvalid: boolean
	query?: any
	options?: { id: string; label: string }
}

class InputAutoComplete extends Component<
	InputAutoCompleteProps,
	InputAutoCompleteState
> {
	constructor(props: InputAutoCompleteProps) {
		super(props)
		this.state = {
			isLoading: false,
			isSelected: false,
			isInvalid: false
		}
	}

	inputRef: any

	_cache: any = {}

	_handleInputChange = (query: any) => {
		this.setState({ query })
	}

	_handlePagination = (e: any, shownResults: any) => {
		const { query } = this.state
		const cache = this._cache
		const cachedQuery = cache[query as keyof typeof cache]

		// Don't make another request if:
		// - the cached results exceed the shown results
		// - we've already fetched all possible results
		if (
			cachedQuery.options.length > shownResults ||
			cachedQuery.options.length === cachedQuery.total_count
		) {
			return
		}

		this.setState({ isLoading: true })
		// const page = cachedQuery.page + 1;
	}

	_handleSearch = (query: string) => {
		if (this._cache[query]) {
			this.setState({ options: this._cache[query].options })
			return
		}

		this.props.handleSearch(query)
	}

	/**
	 * To handle selection change. Pass handleClear if the selection is empty ( delete / supr )
	 * to reset form status.
	 * @param selected
	 */
	handleChange = (selected: Array<{ id: string; label: string }>) => {
		const { handleSelect, handleClear } = this.props

		if (_.isEmpty(selected)) {
			this.inputRef.clear()
			handleClear()
			this.setState({ isSelected: false, isInvalid: true })
		} else {
			handleSelect(selected[0])
			this.setState({ isSelected: true, isInvalid: false })
		}
	}

	/**
	 * To get the configuration of the field
	 * @param id
	 * @returns
	 */
	getConfigField = (id: string) => {
		const { fields } = this.props
		let result: IFieldStructure = {
			agrupador: '',
			descrip: '',
			editable: 0,
			requerido: 0,
			visible: 0,
			idCampo: '',
			label: '',
			mascara: '',
			valid: ''
		}
		if (fields) {
			fields.forEach((field: IFieldStructure) => {
				if (field.idCampo.trim() === id) {
					result = field
				}
			})
		}

		return result
	}

	render() {
		const {
			label,
			placeholder,
			inputId,
			colInput,
			colLabel,
			styleLabel,
			divStyle,
			auoptions,
			handleLoading,
			disabledInput,
			inputFormCol,
			minLength,
			defaultValue,
			disableValid,
			t,
			handlePartialChange,
			clearButton
		} = this.props

		const classInput = label ? colInput : 'col-sm-12'
		const classLabel = label ? colLabel : ''
		const inputConfig = this.getConfigField(inputId)
		const finalLabel =
			inputConfig && inputConfig.label ? inputConfig.label : label
		const customStyleLabel =
			inputConfig && inputConfig.requerido
				? { ...styleLabel, color: 'red' }
				: { ...styleLabel }

		const showInput = inputConfig.idCampo ? inputConfig.visible : true

		if (!showInput) {
			return false
		} else {
			return (
				<Col {...inputFormCol}>
					<Row className={'form-group'}>
						<label
							className={`${styles.inputLabel}  ${classLabel}`}
							style={customStyleLabel}
						>
							{finalLabel}
						</label>
						<Col className={classInput} style={{ ...divStyle }}>
							<AsyncTypeahead
								ref={(typeahead) => typeahead && (this.inputRef = typeahead)}
								clearButton={clearButton || false}
								disabled={disabledInput}
								isLoading={handleLoading}
								id={inputId}
								options={auoptions}
								filterBy={(option, props) => {
									return true
								}}
								minLength={minLength || 3}
								onSearch={this._handleSearch}
								onChange={(selected) => {
									this.handleChange(selected)
								}}
								onInputChange={(params) => {
									handlePartialChange && handlePartialChange(params)
									this.setState({ isSelected: false })
								}}
								className={`${styles.inputText}`}
								placeholder={placeholder}
								onBlur={this.props.onBlur}
								isValid={this.state.isSelected}
								isInvalid={disableValid ? false : this.state.isInvalid}
								renderMenu={(results, menuProps) => (
									<Menu {...menuProps} style={{ width: 'auto' }}>
										{results.map((result, index) => (
											<MenuItem
												key={result.toString() + index}
												option={result}
												position={index}
											>
												{result.label}
											</MenuItem>
										))}
									</Menu>
								)}
								renderMenuItemChildren={(option) => (
									<option key={option.id}>{option.label}</option>
								)}
								inputProps={{
									style: {
										height: '35px',
										fontSize: '10pt',
										padding: '0.375rem 0.375rem 0.375rem 0.75rem',
										borderColor: '#a9a9a9'
									}
								}}
								defaultInputValue={defaultValue || ''}
								searchText={t('autocomplete.searching')}
								promptText={t('autocomplete.searching')}
								emptyLabel={t('autocomplete.notfound')}
							/>
						</Col>
					</Row>
				</Col>
			)
		}
	}
}

export default withTranslation()(InputAutoComplete)
