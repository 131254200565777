import { crudsClearSearch, crudsGetList } from 'actions'
import InputButton from 'components/form/inputButton'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { FormRefsContext } from 'context/FormRefsContext'
import { Formik } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import React, { Component, Fragment } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ADMIN } from 'utils/RoutePath'
import SearchProductsInputs from './CrudSearchProductsInputs'

interface CrudSearchProductsFromProps extends WithTranslation {
	t?: any
	fields: IFieldStructure[]
	paramsSearch: any
	crudsClearSearch: () => void
	crudsGetList: ({ ...params }: any, method: string) => void
	handleNewCrud: () => void
	handleSubmit: any
	history: any
	homeTarget: string
	hideSearch: boolean
	hideNew: boolean
}

class CrudSearchProductsForm extends Component<CrudSearchProductsFromProps> {
	/**
	 * to go to the page home
	 */
	handleHome = (homeTarget: string) => {
		const { history } = this.props
		history.push(`${ADMIN}/${homeTarget}`)
	}

	handleSubmit = (values: any) => {
		const { handleSubmit: handleSubmitParams } = this.props

		handleSubmitParams(values)
	}

	render() {
		const { fields, t, handleNewCrud, homeTarget, hideSearch, hideNew } =
			this.props
		const validationSchema = getValidationSchema(fields, t, [])
		const initialValues = fields ? getInitValues(fields) : []

		return (
			<FormRefsContext.Consumer>
				{({ formRef, cancelButtonRef, searchButtonRef }) => (
					<Formik
						ref={formRef}
						initialValues={initialValues}
						onSubmit={(values: any, actions: any) => {
							this.handleSubmit(values)
						}}
						validationSchema={validationSchema}
						enableReinitialize={true}
						render={(props) => {
							const {
								values,
								handleChange,
								handleBlur,
								setFieldValue,
								setFieldTouched
							} = props
							return (
								<Fragment>
									<Col sm={12}>
										{/* <NotificationError errors={props.errors} touched={props.touched} /> */}
									</Col>
									<Form
										onSubmit={(e) => props.handleSubmit(e as any)}
										className="voucher-info-form"
									>
										<SearchProductsInputs
											fields={fields}
											{...{
												values,
												handleChange,
												handleBlur,
												setFieldValue,
												setFieldTouched,
												readOnly: false,
												t
											}}
										/>

										<Row className={'pt-1 pr-2'}>
											<Col className={'offset-md-6 text-center mt-2'}>
												{!hideSearch && (
													<Button
														style={{ minWidth: '105px' }}
														type={'submit'}
														ref={searchButtonRef}
													>
														{t('global.search')}
													</Button>
												)}
											</Col>
											<Col className={'text-center mt-2'}>
												<Button
													style={{ minWidth: '105px' }}
													type={'button'}
													onClick={() => {
														props.resetForm()
														this.props.crudsClearSearch()
													}}
												>
													{t('global.clear')}
												</Button>
											</Col>
											<Col className={'text-center mt-2'}>
												{!hideNew && (
													<InputButton
														valueButton={t('global.new_button')}
														onClick={() => handleNewCrud()}
														type={'primary'}
														customStyle={{ minWidth: '105px' }}
													/>
												)}
											</Col>
											<Col className={'text-center mt-2'}>
												<Button
													style={{ minWidth: '105px' }}
													type={'button'}
													onClick={() => this.handleHome(homeTarget)}
													ref={cancelButtonRef}
												>
													{t('global.return_button')}
												</Button>
											</Col>
										</Row>
									</Form>
								</Fragment>
							)
						}}
					/>
				)}
			</FormRefsContext.Consumer>
		)
	}
}

const mapStateToProps = ({ configCrud }: any) => {
	const { valuesDataList, paramsSearch } = configCrud
	return {
		valuesDataList,
		paramsSearch
	}
}

const connectForm = connect(mapStateToProps, {
	crudsClearSearch,
	crudsGetList
})(CrudSearchProductsForm)

export default withTranslation()(withRouter(connectForm))
