import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	crudGetLAAssignations,
	crudUpdateLAAssignations,
	getConfigFieldsCrud,
	getConfigFieldsCrudAlta,
	getFilterLAAssignOrder,
	getFilterLAAssignStatus,
	getFilterLAAssignType,
	showMessage
} from 'actions'
import CrudFilters from 'components/abmUtils/CrudFilters'
import TableWithSelection from 'components/abmUtils/TableWithSelection'
import CommonModal from 'components/common/commonModal'
import withMenu from 'components/common/withMenu'
import DirectLAAssignationsDeleteForm from 'components/directLAAssignations/DirectLAAssignationsDeleteForm'
import DirectLAAssignationsForm from 'components/directLAAssignations/DirectLAAssignationsForm'
import CrudsContainer from 'components/layout/CrudsContainer'
import { LAAssignFields } from 'constants/crudsConfig/crudDirectLAAssign/crudDirectLAAssignFields'
import { getFiltersData } from 'lib/Utils'
import {
	ICrudGetLAAssignationsRes,
	ICrudGetLAAssignationsResArray,
	ICrudUpdateLAAssignationsResArray
} from 'models/CrudDirectLAAssignationInterface'
import { ICrudFieldConfig } from 'models/CrudInterface'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'

type CrudLAAssignProps = {
	fieldsConfig: ICrudFieldConfig
	fieldsAltaConfig: ICrudFieldConfig
	getConfigFieldsCrud: any
	getConfigFieldsCrudAlta: any
	getFilterLAAssignType: any
	getFilterLAAssignStatus: any
	getFilterLAAssignOrder: any
	crudGetLAAssignations: any
	crudUpdateLAAssignations: any
	LAAssignationsSearched: ICrudGetLAAssignationsResArray
	LAAssignationsUpdated: ICrudUpdateLAAssignationsResArray
	paramsSearch: any
	history: any
	t: any
	showMessage: any
}

type CrudLAAssignState = {
	paramsSearch: any
	selectedRows: Array<string>
	tableValues: Array<ICrudGetLAAssignationsRes>
	sustainsClick: boolean
	showEditModal: boolean
	showDeleteModal: boolean
	disableFields: boolean
	disableAbm: boolean
	configLoaded: boolean
}

class CrudLAAssignData extends Component<CrudLAAssignProps, CrudLAAssignState> {
	constructor(props: CrudLAAssignProps) {
		super(props)
		this.state = {
			paramsSearch: '',
			selectedRows: [],
			tableValues:
				this.props.LAAssignationsSearched &&
				this.props.LAAssignationsSearched.lista,
			sustainsClick: false,
			showEditModal: false,
			showDeleteModal: false,
			disableFields: true,
			disableAbm: true,
			configLoaded: false
		}
	}

	componentDidMount = () => {
		const {
			getConfigFieldsCrud,
			getConfigFieldsCrudAlta,
			getFilterLAAssignType,
			getFilterLAAssignStatus,
			getFilterLAAssignOrder
		} = this.props
		getConfigFieldsCrud({ cod_abm: 'abm_cont_asig_cta' })
		getConfigFieldsCrudAlta({ cod_abm: 'abm_cont_asig_cta_alta' })
		getFilterLAAssignType()
		getFilterLAAssignStatus()
		getFilterLAAssignOrder()
	}

	componentDidUpdate = (prevProps: CrudLAAssignProps) => {
		const {
			LAAssignationsUpdated,
			crudGetLAAssignations,
			paramsSearch,
			showMessage,
			fieldsConfig,
			t
		} = this.props

		if (
			prevProps.LAAssignationsUpdated !== LAAssignationsUpdated &&
			LAAssignationsUpdated
		) {
			crudGetLAAssignations({ ...paramsSearch })
		}

		if (fieldsConfig && fieldsConfig !== prevProps.fieldsConfig) {
			const newConfigState = {
				disableAbm: !(
					fieldsConfig.tipo_perm === 'L' || fieldsConfig.tipo_perm === 'E'
				),
				disableFields: !(fieldsConfig.tipo_perm === 'E'),
				configLoaded: true
			}
			if (_.isEmpty(fieldsConfig.campos)) {
				showMessage({
					type: 'error',
					description: t('CRUDS.message.config_error'),
					showError: true
				})
				newConfigState.configLoaded = false
			}
			this.setState({ ...newConfigState })
		}
	}

	/**
	 * to call api to search customers
	 * @param {object} filters
	 */

	handleCrudSearchItems = (filters: any) => {
		const { crudGetLAAssignations, showMessage, t } = this.props
		const { disableAbm } = this.state

		if (!disableAbm) {
			crudGetLAAssignations({ ...filters, page_size: 10 })
			this.setState({ paramsSearch: filters })
		} else {
			showMessage({
				type: 'error',
				description: t('CRUDS.message.rights'),
				showError: true
			})
		}
	}
	/**
	 * to handle table pagination event
	 * @param {string} type
	 * @param {object} pagination
	 */

	handleChangeTable = (type: String, pagination: any) => {
		const { paramsSearch, selectedRows, disableAbm } = this.state
		const { crudGetLAAssignations, showMessage, t } = this.props
		if (type === 'pagination') {
			if (!disableAbm) {
				crudGetLAAssignations({
					...paramsSearch,
					page_size: pagination.sizePerPage,
					page_number: pagination.page
				})
				this.setState({
					selectedRows: selectedRows.filter(() => false)
				})
			} else {
				showMessage({
					type: 'error',
					description: t('CRUDS.message.rights'),
					showError: true
				})
			}
		}
	}

	handleMouseDown = (event: any) => {
		if (event.target.type !== 'checkbox') {
			event.preventDefault()
			this.setState({ sustainsClick: true })
		}
	}

	/**
	 * Handles mouse up, sets sustained click to false
	 * @param event
	 */
	handleMouseUp = (event: any) => {
		const { sustainsClick } = this.state
		if (sustainsClick) {
			event.preventDefault()
			this.setState({ sustainsClick: false })
		}
	}

	/**
	 * to handle double click events, gets called by the TableWithSelection comp
	 * @param side
	 * @param row
	 */
	handleDoubleClick = (side: string, row: string) => {
		const newArray = []
		newArray.push(row)
		this.setState({ selectedRows: newArray, showEditModal: true })
	}

	/**
	 * to handle selection state, gets labels as params
	 * @param selectedRow
	 * @param side
	 * @param action
	 */
	handleSelection = (selectedRow: string, table: string, action: string) => {
		const { selectedRows } = this.state
		if (action === 'add') {
			this.setState(() => ({
				selectedRows: [...selectedRows, selectedRow]
			}))
		} else if (action === 'delete') {
			this.setState(() => ({
				selectedRows: selectedRows.filter((row) => row !== selectedRow)
			}))
		}
	}

	handleSelectAll = (action: string) => {
		const { LAAssignationsSearched } = this.props
		const { selectedRows } = this.state
		if (action === 'add') {
			const selectAll: Array<any> = []
			LAAssignationsSearched &&
				_.forEach(LAAssignationsSearched.lista, (item) => {
					selectAll.push(item.codigo)
				})
			this.setState(() => ({
				selectedRows: selectAll
			}))
		} else if (action === 'delete') {
			this.setState(() => ({
				selectedRows: selectedRows.filter((id: any) => false)
			}))
		}
	}

	showEditModal = () => {
		this.setState(() => ({
			showEditModal: true
		}))
	}

	showDeleteModal = () => {
		this.setState(() => ({
			showDeleteModal: true
		}))
	}

	handleSubmitPopup = ({ nuevo_cod_cta, cod_cta }: any) => {
		const id = nuevo_cod_cta || cod_cta
		const { selectedRows, paramsSearch } = this.state
		const { crudUpdateLAAssignations } = this.props
		const newAssignations: Array<any> = []
		_.forEach(selectedRows, (row) => {
			const newValue = {
				nicodcta: id,
				codigo: row
			}
			newAssignations.push(newValue)
		})
		const payload = {
			tipo_asig: paramsSearch.tipo_asig,
			lista: newAssignations
		}
		crudUpdateLAAssignations(payload)
		this.setState(() => ({
			showEditModal: false
		}))
	}

	handleDeletePopup = () => {
		const { selectedRows, paramsSearch } = this.state
		const { crudUpdateLAAssignations } = this.props
		const newAssignations: Array<any> = []
		_.forEach(selectedRows, (row) => {
			const newValue = {
				nicodcta: '',
				codigo: row
			}
			newAssignations.push(newValue)
		})
		const payload = {
			tipo_asig: paramsSearch.tipo_asig,
			lista: newAssignations
		}
		crudUpdateLAAssignations(payload)
		this.setState(() => ({
			showDeleteModal: false
		}))
	}

	handleClosePopup = () => {
		this.setState(() => ({
			showEditModal: false,
			showDeleteModal: false
		}))
	}

	getSelectedData = (selection: Array<string>) => {
		const { LAAssignationsSearched } = this.props
		const fieldsInfo: Array<ICrudGetLAAssignationsRes> = []
		LAAssignationsSearched &&
			_.forEach(selection, (selectedRow) => {
				const field = _.find(LAAssignationsSearched.lista, (field) => {
					return field.codigo === selectedRow
				})
				field && fieldsInfo.push(field)
			})
		return fieldsInfo
	}

	render() {
		const { t, fieldsConfig, LAAssignationsSearched, fieldsAltaConfig } =
			this.props
		const {
			showEditModal,
			showDeleteModal,
			disableFields,
			disableAbm,
			selectedRows,
			sustainsClick,
			configLoaded
		} = this.state

		const fieldFilters = fieldsConfig
			? getFiltersData(fieldsConfig.campos, { agrupador: 'formulario' })
			: []
		const fieldsTable = fieldsConfig
			? getFiltersData(fieldsConfig.campos, { agrupador: 'grilla' })
			: []
		const fields = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('principal')
			  })
			: []

		const filtersProps = {
			fields: fieldFilters,
			handleSearch: this.handleCrudSearchItems,
			customFieldsConfig: LAAssignFields,
			crudProps: this.props,
			homeTarget: 'A',
			hideSearch: disableAbm,
			hideNew: disableFields
		}
		const propsEdit = {
			fields,
			itemInfo: this.getSelectedData(selectedRows),
			disableForm: false,
			handleSubmit: this.handleSubmitPopup,
			handleCloseModal: this.handleClosePopup
		}
		const propsDelete = {
			fields,
			itemInfo: this.getSelectedData(selectedRows),
			disableForm: false,
			handleSubmit: this.handleDeletePopup,
			handleCloseModal: this.handleClosePopup
		}

		const propsEditModal = {
			showModal: showEditModal,
			handleCloseModal: this.handleClosePopup,
			modalTitle: t('CRUDS.direct_LA_assign.edit.title'),
			modalBody: <DirectLAAssignationsForm {...propsEdit} />,
			buttons: false,
			handleSubmit: this.handleSubmitPopup,
			sizeModal: 'xl',
			disableHide: true
		}
		const propsDeleteModal = {
			showModal: showDeleteModal,
			handleCloseModal: this.handleClosePopup,
			modalTitle: t('CRUDS.direct_LA_assign.delete.title'),
			modalBody: <DirectLAAssignationsDeleteForm {...propsDelete} />,
			buttons: false,
			handleSubmit: this.handleDeletePopup,
			sizeModal: 'lg',
			disableHide: true
		}
		const propsTable = {
			fields: LAAssignationsSearched && LAAssignationsSearched.lista,
			tableFields: fieldsTable,
			disableForm: false,
			keyfield: 'codigo',
			sustainsClick: sustainsClick,
			selected: selectedRows,
			handleSelectAll: this.handleSelectAll,
			setSelection: this.handleSelection,
			doDoubleClick: !disableFields ? this.handleDoubleClick : () => {},
			paginationOption: LAAssignationsSearched || null,
			handleChangeTable: this.handleChangeTable,
			label: 'LAATable',
			checkBox: true,
			buttons: !disableFields
				? [
						{
							label: 'Edit',
							action: this.showEditModal,
							component: (
								<FontAwesomeIcon
									id="Edit"
									icon={faPen}
									style={{ cursor: 'pointer' }}
									color={'#007bff'}
									title={t('global.edit')}
									onClick={this.showEditModal}
								/>
							)
						},
						{
							label: 'Delete',
							action: this.showDeleteModal,
							component: (
								<FontAwesomeIcon
									id="Delete"
									icon={faTrash}
									style={{ cursor: 'pointer' }}
									color={'#007bff'}
									title={t('global.delete')}
									onClick={this.showDeleteModal}
								/>
							)
						}
				  ]
				: []
		}

		return (
			<CrudsContainer>
				{configLoaded && (
					<Row>
						<Col sm={12}>
							<CrudFilters {...filtersProps} />
							<Col
								onMouseDown={this.handleMouseDown}
								onMouseUp={this.handleMouseUp}
							>
								{fieldsTable.length > 0 && (
									<TableWithSelection {...propsTable} />
								)}
							</Col>
						</Col>
					</Row>
				)}
				<Row>
					<CommonModal {...propsEditModal} />
					<CommonModal {...propsDeleteModal} />
				</Row>
			</CrudsContainer>
		)
	}
}

const mapStateToProps = ({
	configCrud,
	directLAAssignations,
	tableCalls
}: any) => {
	const { fieldsConfig, fieldsAltaConfig } = configCrud
	const { LAAssignationsSearched, LAAssignationsUpdated, paramsSearch } =
		directLAAssignations
	const {
		directLAAssignationType,
		directLAAssignationStatus,
		directLAAssignationOrder
	} = tableCalls
	return {
		fieldsConfig,
		fieldsAltaConfig,
		LAAssignationsSearched,
		LAAssignationsUpdated,
		directLAAssignationType,
		directLAAssignationStatus,
		directLAAssignationOrder,
		paramsSearch
	}
}

const connectForm = connect(mapStateToProps, {
	getConfigFieldsCrud,
	getConfigFieldsCrudAlta,
	crudGetLAAssignations,
	crudUpdateLAAssignations,
	getFilterLAAssignType,
	getFilterLAAssignStatus,
	getFilterLAAssignOrder,
	showMessage
})(CrudLAAssignData)

export default withTranslation()(withMenu(withRouter(connectForm)))
