import {
	getOrderDirOptions,
	getOrderOptions,
	getProductsOrderOptions,
	productCategoryFilter,
	searchProviders
} from 'actions'
import InputAutocomplete from 'components/form/inputAutocomplete'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getOptionsDropDown, handleSetValueInput } from 'lib/FormUtils'
import { IResponseOptions } from 'models/SearchProduct'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'
import FormInputsAttributes from './CrudFormInputsAttributes'
import styles from './SearchProducts.module.scss'

interface InputsProps {
	fields: IFieldStructure[]
	values: any
	handleChange: any
	handleBlur: any
	setFieldValue: any
	readOnly: boolean
	setFieldTouched: any
	searchProviders: ({ criterio_proveedor }: any) => void
	getOrderOptions: () => void
	getOrderDirOptions: () => void
	getProductsOrderOptions: () => void
	productCategoryFilter: () => void
	listProviders: any
	sortOptions: any
	sortDirOptions: any
	categoryOptions: IResponseOptions[]
}

interface InputsState {
	searching: boolean
}

class SearchProductsInputs extends Component<InputsProps, InputsState> {
	constructor(props: InputsProps) {
		super(props)
		this.state = {
			searching: false
		}
	}

	componentDidMount = () => {
		const { sortDirOptions, sortOptions, categoryOptions } = this.props
		if (_.isEmpty(sortDirOptions)) {
			this.props.getOrderDirOptions()
		}
		if (_.isEmpty(sortOptions)) {
			this.props.getProductsOrderOptions()
		}

		if (_.isEmpty(categoryOptions)) {
			this.props.productCategoryFilter()
		}
	}

	componentDidUpdate = (prevProps: InputsProps) => {
		const { listProviders } = this.props
		if (listProviders !== prevProps.listProviders && listProviders !== null) {
			this.setState({ searching: false })
		}
	}

	/**
	 * to search providers.
	 */
	handleSearch = (value: string = '') => {
		this.props.searchProviders({ criterio_proveedor: value })
		this.setState({ searching: true })
	}

	handleSelect = (provider: any) => {}

	/**
	 * to manage provider data when change
	 */
	handleChange = (provider: any) => {
		const { setFieldValue } = this.props
		setFieldValue('prov_usu', !_.isEmpty(provider) ? provider[0].id : '')
	}

	render() {
		const {
			fields,
			values,
			handleBlur,
			readOnly,
			setFieldTouched,
			setFieldValue,
			listProviders,
			sortDirOptions,
			sortOptions,
			categoryOptions
		} = this.props

		const { searching } = this.state

		const optionsSync = listProviders
			? listProviders.proveedores.map((opt: any) => {
					return { id: opt.idProveedor, label: opt.rsocial }
			  })
			: []

		const formikProps = { setFieldTouched, setFieldValue }

		const propsCode = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'code',
			inputId: 'lk_cod_prod',
			name: 'lk_cod_prod',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.lk_cod_prod,
			divStyle: { paddingRight: '0px' },
			onChange: (data: any) =>
				handleSetValueInput(data, 'lk_cod_prod', formikProps)
		}

		const propsCodeAbr = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'code',
			inputId: 'lk_cod_abrev',
			name: 'lk_cod_abrev',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.lk_cod_abrev,
			divStyle: { paddingRight: '0px' },
			onChange: (data: any) =>
				handleSetValueInput(data, 'lk_cod_abrev', formikProps)
		}

		const propsDescription = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'code',
			inputId: 'lk_descrip',
			name: 'lk_descrip',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.lk_descrip,
			divStyle: { paddingRight: '0px' },
			onChange: (data: any) =>
				handleSetValueInput(data, 'lk_descrip', formikProps)
		}

		const propsBarcode = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'code',
			inputId: 'lk_codbarra',
			name: 'lk_codbarra',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.lk_codbarra,
			divStyle: { paddingRight: '0px' },
			onChange: (data: any) =>
				handleSetValueInput(data, 'lk_codbarra', formikProps)
		}

		const propsCategory = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'l',
			inputId: 'lk_cod_categ',
			name: 'lk_cod_categ',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.lk_cod_categ,
			divStyle: { paddingRight: '0px' },
			options: getOptionsDropDown(categoryOptions),
			onChange: (data: any) =>
				handleSetValueInput(data, 'lk_cod_categ', formikProps)
		}

		const propsCodeProv = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'code',
			inputId: 'lk_prod_prov_usu',
			name: 'lk_prod_prov_usu',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			divStyle: { paddingRight: '0px' },
			disable: readOnly,
			onBlur: handleBlur,
			value: values.lk_prod_prov_usu,
			onChange: (data: any) =>
				handleSetValueInput(data, 'lk_prod_prov_usu', formikProps)
		}

		const propsUsualProv = {
			fields,
			inputFormCol: { md: 6, lg: 6 },
			label: 'Cuenta',
			inputId: 'lk_prov_usu',
			name: 'lk_prov_usu',
			minLength: 1,
			divStyle: { width: '100%', paddingRight: '0px' },
			colLabel: 'col-lg-3 col-md-3',
			colInput: 'col-lg-9 col-md-9',
			handleSearch: (value: string) => this.handleSearch(value),
			auoptions: optionsSync,
			handleLoading: searching,
			handleSelect: this.handleSelect,
			onBlur: handleBlur,
			onChange: this.handleChange,
			labelKey: 'label',
			disable: readOnly,
			defaultValue: values.lk_prov_usu
				? String(values.lk_prov_usu)
				: values.lk_prov_usu
		}

		const propsSort = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'l',
			inputId: 'orden',
			name: 'orden',
			placeholder: '',
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.orden,
			options: getOptionsDropDown(sortOptions),
			onChange: (data: any) => handleSetValueInput(data, 'orden', formikProps)
		}

		const propsOrder = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'L',
			inputId: 'orden_dir',
			name: 'orden_dir',
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.orden_dir,
			divStyle: { paddingRight: '0px' },
			options: getOptionsDropDown(sortDirOptions),
			onChange: (data: any) =>
				handleSetValueInput(data, 'orden_dir', formikProps)
		}

		return (
			<Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsCode} />
					<InputText {...propsCodeAbr} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsDescription} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsBarcode} />
					<InputDropdown {...propsCategory} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsCodeProv} />
					<InputAutocomplete {...propsUsualProv} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputDropdown {...propsSort} />
					<InputDropdown {...propsOrder} />
				</Row>
				<FormInputsAttributes fields={fields} setFieldValue={setFieldValue} />
			</Row>
		)
	}
}

const mapStateToProps = ({ searchVoucher, providers, searchProducts }: any) => {
	const { listProviders } = providers
	const { sortDirOptions } = searchVoucher
	const { sortOptions, categoryOptions } = searchProducts
	return { sortOptions, sortDirOptions, listProviders, categoryOptions }
}

export default connect(mapStateToProps, {
	searchProviders,
	getOrderDirOptions,
	getOrderOptions,
	getProductsOrderOptions,
	productCategoryFilter
})(SearchProductsInputs)
