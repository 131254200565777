import { UploadFilesActions } from 'constants/ActionsTypesTs'
import { ICommonInitialState } from 'models/CommonInterface'
import {
	IDownloadFileParams,
	IGetTempDocumentsSuccessResponse,
	IRemoveDocumentParams,
	IRemoveTempDocumentParams
} from 'models/FileManager'

interface IDocumentDelete extends ICommonInitialState {
	data: IRemoveDocumentParams | null
}
export interface IUploadFileState {
	uploadFileList: any
	uploadFileTempList: IGetTempDocumentsSuccessResponse | null
	documentTempDelete: IRemoveTempDocumentParams | null
	documentDelete: IDocumentDelete
	downloadFile: IDownloadFileParams | null
}

const initialState = {
	uploadFileList: null,
	uploadFileTempList: null,
	documentTempDelete: null,
	documentDelete: {
		data: null,
		status: 'iddle',
		error: null
	},
	downloadFile: null
}

function rootReducer(state: IUploadFileState = initialState, action: any) {
	switch (action.type) {
		case UploadFilesActions.UPLOAD_FILES:
			return { ...state, uploadFileTempList: null }
		case UploadFilesActions.UPLOAD_FILES_SUCCESS:
			return { ...state, uploadFileTempList: action.payload }
		case UploadFilesActions.GET_TEMP_DOCUMENT_LIST:
			return { ...state, uploadFileTempList: null }
		case UploadFilesActions.GET_TEMP_DOCUMENT_LIST_SUCCESS:
			return { ...state, uploadFileTempList: action.payload }
		case UploadFilesActions.GET_DOCUMENT_LIST:
			return { ...state, uploadFileList: null }
		case UploadFilesActions.GET_DOCUMENT_LIST_SUCCESS:
			return { ...state, uploadFileList: action.payload }
		case UploadFilesActions.REMOVE_TEMP_DOCUMENT:
			return { ...state, documentTempDelete: action.payload }
		case UploadFilesActions.REMOVE_TEMP_DOCUMENT_SUCCESS:
			return { ...state, uploadFileTempList: action.payload }
		case UploadFilesActions.REMOVE_DOCUMENT:
			return {
				...state,
				documentDelete: { data: action.payload, status: 'loading' }
			}
		case UploadFilesActions.REMOVE_DOCUMENT_SUCCESS:
			return {
				...state,
				uploadFileList: action.payload,
				documentDelete: { ...state.documentDelete, status: 'succeeded' }
			}
		case UploadFilesActions.DOWNLOAD_FILE:
			return { ...state, downloadFile: action.payload }
		default:
			return state
	}
}

export default rootReducer
