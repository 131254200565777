import { DELAY_TIME_API } from 'constants/ConfigProcessNames'
import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects'
import {
	getConfigVoucherSuccess,
	getVoucherHeadInfoSuccess,
	getVoucherHeadSuccess,
	voucherHeadAutoSuccess,
	voucherHeadCheckDateSuccess,
	voucherHeadConfirmSuccess,
	voucherHeadValidatekeySuccess
} from '../actions/Voucher'
import {
	getConfigVoucher,
	getVoucherHead,
	getVoucherHeadInfo,
	voucherHeadAuto,
	voucherHeadCheckDate,
	voucherHeadConfirm,
	voucherHeadValidatekey
} from '../api/Voucher'
import {
	GET_CONFIG_VOUCHER,
	GET_VOUCHER_HEAD,
	GET_VOUCHER_HEAD_INFO,
	VOUCHER_HEAD_AUTO,
	VOUCHER_HEAD_CHECK_DATE,
	VOUCHER_HEAD_CONFIRM,
	VOUCHER_HEAD_VALIDATE_KEY
} from '../constants/ActionsTypes'

function* getConfigVoucherRequest({ payload }) {
	try {
		const config = yield call(getConfigVoucher, payload)
		yield put(getConfigVoucherSuccess(config))
	} catch (error) {}
}

function* getVoucherHeadRequest({ payload }) {
	try {
		const headData = yield call(getVoucherHead, payload)
		yield put(getVoucherHeadSuccess(headData))
	} catch (error) {}
}

function* voucherHeadAutoRequest({ payload }) {
	yield delay(DELAY_TIME_API)

	try {
		const autoData = yield call(voucherHeadAuto, payload)
		yield put(voucherHeadAutoSuccess(autoData))
	} catch (error) {}
}

function* voucherHeadValidatekeyRequest({ payload }) {
	try {
		const validate = yield call(voucherHeadValidatekey, payload)
		yield put(voucherHeadValidatekeySuccess(validate))
	} catch (error) {}
}

function* voucherHeadCheckDateRequest({ payload }) {
	try {
		const checkDate = yield call(voucherHeadCheckDate, payload)
		yield put(voucherHeadCheckDateSuccess(checkDate))
	} catch (error) {}
}

function* voucherHeadConfirmRequest({ payload }) {
	const { headInfo, callBackReturn } = payload

	try {
		const confirm = yield call(voucherHeadConfirm, headInfo)
		callBackReturn()
		yield put(voucherHeadConfirmSuccess(confirm))
	} catch (error) {}
}

function* getVoucherHeadInfoRequest({ payload }) {
	try {
		const headInfo = yield call(getVoucherHeadInfo, payload)
		yield put(getVoucherHeadInfoSuccess(headInfo))
	} catch (error) {}
}

export function* getConfigVoucherSaga() {
	yield takeLatest(GET_CONFIG_VOUCHER, getConfigVoucherRequest)
}

export function* getVoucherHeadSaga() {
	yield takeLatest(GET_VOUCHER_HEAD, getVoucherHeadRequest)
}

export function* voucherHeadAutoSaga() {
	yield takeLatest(VOUCHER_HEAD_AUTO, voucherHeadAutoRequest)
}

export function* voucherHeadValidatekeySaga() {
	yield takeLatest(VOUCHER_HEAD_VALIDATE_KEY, voucherHeadValidatekeyRequest)
}

export function* voucherHeadCheckDateSaga() {
	yield takeLatest(VOUCHER_HEAD_CHECK_DATE, voucherHeadCheckDateRequest)
}

export function* voucherHeadConfirmSaga() {
	yield takeLatest(VOUCHER_HEAD_CONFIRM, voucherHeadConfirmRequest)
}

export function* getVoucherHeadInfoSaga() {
	yield takeLatest(GET_VOUCHER_HEAD_INFO, getVoucherHeadInfoRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getConfigVoucherSaga),
		fork(getVoucherHeadSaga),
		fork(voucherHeadAutoSaga),
		fork(voucherHeadValidatekeySaga),
		fork(voucherHeadCheckDateSaga),
		fork(getVoucherHeadInfoSaga),
		fork(voucherHeadConfirmSaga)
	])
}
