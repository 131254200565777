import { HeaderDataValidationActions } from 'constants/ActionsTypesTs'
import {
	IConfirmValidationAction,
	IConfirmValidationParams,
	IConfirmValidationSuccessAction,
	IConfirmValidationSuccessResponse,
	IGetValidationsAction,
	IGetValidationsParams,
	IGetValidationsSuccessAction,
	IGetValidationsSuccessResponse
} from 'models/HeaderDataValidation'

export const getValidations = (
	payload: IGetValidationsParams
): IGetValidationsAction => ({
	type: HeaderDataValidationActions.GET_VALIDATIONS,
	payload
})

export const getValidationsSuccess = (
	payload: IGetValidationsSuccessResponse
): IGetValidationsSuccessAction => ({
	type: HeaderDataValidationActions.GET_VALIDATIONS_SUCCESS,
	payload
})

export const confirmValidation = (
	payload: IConfirmValidationParams
): IConfirmValidationAction => ({
	type: HeaderDataValidationActions.CONFIRM_VALIDATIONS,
	payload
})

export const confirmValidationSuccess = (
	payload: IConfirmValidationSuccessResponse
): IConfirmValidationSuccessAction => ({
	type: HeaderDataValidationActions.CONFIRM_VALIDATIONS_SUCCESS,
	payload
})
