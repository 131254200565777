import Axios from 'axios'
import {
	ICrudCreateCustomerCode,
	ICrudGetCustomerCode,
	ICrudGetCustomerCodeConfig
} from 'models/CrudCustomerInterface'
const urlCRUD = '/abm_vta_clientes'

export const crudGetCustomerCode = async (params: ICrudGetCustomerCode) => {
	const response = await Axios.get(`${urlCRUD}/niveles/datos`, { params })
	return response.data
}

export const crudGetCustomerCodeConfig = async (
	params: ICrudGetCustomerCodeConfig
) => {
	const response = await Axios.get(
		`${urlCRUD}/niveles/config?cod_val=${params}`
	)
	return response.data
}

export const crudCreateCustomerCode = async (
	params: ICrudCreateCustomerCode
) => {
	const response = await Axios.post(`${urlCRUD}/niveles/aceptar`, params)
	return response.data
}
