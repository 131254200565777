import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from '@material-ui/core'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
	getTempDocumentList,
	removeTempDocument,
	showMessage,
	uploadFiles
} from 'actions'
import acceptExt from 'attr-accept'
import CommonFileIcon from 'components/common/CommonFileIcon/CommonFileIcon'
import DragFileInput from 'components/common/DragFileInput'
import InputButton from 'components/form/inputButton'
import {
	IGetTempDocumentsParams,
	IGetTempDocumentsSuccessResponse,
	IRemoveTempDocumentParams,
	ITempFileItem,
	IUploadFilesParams
} from 'models/FileManager'
import React, { Component } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import { styles } from './UploadFileFormStyles'

interface IUploadFileFormProps
	extends WithTranslation,
		WithStyles<typeof styles> {
	t: any
	uploadFiles: (payload: IUploadFilesParams) => void
	idOperacion: string
	getTempDocumentList: (payload: IGetTempDocumentsParams) => void
	uploadFileTempList: IGetTempDocumentsSuccessResponse
	removeTempDocument: (payload: IRemoveTempDocumentParams) => void
	showMessage: (payload: any) => void
}

interface IUploadFileFormState {
	uploadFiles: Array<File>
	stateDragDrop: { success: boolean; error: boolean; message?: any }
	acceptedFiles: Array<string>
	titleUploadDragFile: string
	buttonUpload: string
	fileTempList: Array<ITempFileItem> | null
}

class UploadFileForm extends Component<
	IUploadFileFormProps,
	IUploadFileFormState
> {
	constructor(props: IUploadFileFormProps) {
		super(props)
		this.state = {
			uploadFiles: [],
			stateDragDrop: {
				success: false,
				error: false,
				message: null
			},
			acceptedFiles: [
				'.png',
				'.pdf',
				'.jpg',
				'.bmp',
				'.xls',
				'.xlsx',
				'.xlsm',
				'.csv',
				'.doc',
				'.docx',
				'.txt'
			],
			titleUploadDragFile:
				'Arrastre o seleccione el archivo desde su computadora',
			buttonUpload: 'Examinar',
			fileTempList: null
		}
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.getTempDocumentList({ idOperacion })
	}

	componentDidUpdate(prevProps: IUploadFileFormProps) {
		const { uploadFileTempList } = this.props
		if (
			!_.isEqual(prevProps.uploadFileTempList, uploadFileTempList) &&
			!_.isEmpty(uploadFileTempList)
		) {
			const stateDragDrop = {
				success: false,
				error: false,
				message: null
			}

			const fileTempList = uploadFileTempList.lista_arch

			if (!uploadFileTempList.Resultado.Resultado) {
				this.closeLoading('error', `${uploadFileTempList.Resultado.Mens_error}`)
			} else {
				// this.closeLoading('success', `Archivo cargado con éxito`)
			}

			this.setState({
				fileTempList,
				stateDragDrop,
				uploadFiles: []
			})
		}
	}

	/**
	 * to manage to load files
	 * @param files
	 */
	handleUploadFiles = (files: FileList | Array<File> | File | null) => {
		const { uploadFiles } = this.state

		if (!_.isEmpty(files)) {
			if (_.isArray(files)) {
				files.forEach((fileUpload: File) => {
					this.handleUploadFile(fileUpload)
				})
			}
		} else if (_.isEmpty(uploadFiles)) {
			this.setState({
				stateDragDrop: {
					success: false,
					error: false,
					message: null
				}
			})
		}
	}

	/**
	 * manage only one file
	 * @param fileUpload
	 */
	handleUploadFile = (fileUpload: File) => {
		const { acceptedFiles, uploadFiles } = this.state

		let stateDragDrop = {
			success: false,
			error: false,
			message: <></>
		}

		const existFile = _.find(uploadFiles, (currentFile: File) => {
			const { name, size, type } = currentFile
			if (
				name === fileUpload.name &&
				size === fileUpload.size &&
				type === fileUpload.type
			) {
				return true
			}
			return false
		})

		if (acceptExt(fileUpload, acceptedFiles)) {
			if (_.isEmpty(existFile)) {
				uploadFiles.push(fileUpload)
				stateDragDrop = {
					success: true,
					error: false,
					message: this.renderPreviewFiles(uploadFiles)
				}

				this.closeLoading(
					'success',
					'Formato y extensión correctos. Puede continuar.'
				)

				this.setState({
					titleUploadDragFile: '',
					uploadFiles,
					stateDragDrop
				})
			} else {
				this.closeLoading('error', 'El archivo ya se encuentra seleccionado')
			}
		} else {
			const { uploadFiles } = this.state
			if (_.isEmpty(uploadFiles)) {
				stateDragDrop = {
					success: false,
					error: true,
					message: <>Deben ser un PDF o PNG </>
				}
				this.setState({ stateDragDrop })
			}
			this.closeLoading('error', 'El tipo de archivo es incorrecto')
		}
	}

	/**
	 * render preview file already uploaded
	 * @param files
	 * @returns
	 */
	renderPreviewFiles = (files: Array<File>) => {
		return (
			<Grid container>
				{files.map((file) => {
					return this.renderIconFile(file)
				})}
			</Grid>
		)
	}

	/**
	 * render icon file in drag and drop component
	 */
	renderIconFile = (file: File) => {
		const { name, type: fileType } = file
		const { classes } = this.props
		return (
			<Grid item key={name} className={classes.fileIconItem}>
				<Grid container direction="column">
					<Grid item>
						<CommonFileIcon typeFile={fileType} />
					</Grid>
					<Grid item>{name}</Grid>
					<Grid item>
						<FontAwesomeIcon
							icon={faTrash}
							color="red"
							className={classes.iconTemp}
							onClick={() => this.handleRemoveFile(file)}
						/>
					</Grid>
				</Grid>
			</Grid>
		)
	}

	/**
	 * to remove file selected before call api, file are currently  in temp folder
	 * @param rmFile
	 */
	handleRemoveFile = (rmFile: File) => {
		const { uploadFiles } = this.state

		const newFiles = _.filter(uploadFiles, (upFile) => {
			return !_.isEqual(upFile, rmFile)
		})

		const stateDragDrop = {
			success: true,
			error: false,
			message: this.renderPreviewFiles(newFiles)
		}

		this.setState({ uploadFiles: newFiles, stateDragDrop })
	}

	/**
	 * render files from api.
	 * @returns
	 */
	renderTempPreviewFiles = () => {
		const { fileTempList } = this.state

		return (
			<Grid container justifyContent="center">
				{fileTempList?.map((file) => {
					return this.renderTempIconFile(file)
				})}
			</Grid>
		)
	}

	/**
	 * render icon name from api
	 * @param file
	 * @returns
	 */
	renderTempIconFile = (file: ITempFileItem) => {
		const { classes } = this.props
		const [name, typeFile] = file.nombre.split('.')

		return (
			<Grid item key={name} className={classes.fileTempIconItem}>
				<Grid container direction="column">
					<Grid item>
						<CommonFileIcon typeFile={typeFile} />
					</Grid>
					<Grid item>
						<span className={classes.fileTmpName}>{name}</span>
						<FontAwesomeIcon
							icon={faTrash}
							className={classes.iconTemp}
							onClick={() => this.handleRemoveTempFile(file)}
						/>
					</Grid>
				</Grid>
			</Grid>
		)
	}

	/**
	 * call api to remove temp file
	 * @param file
	 */
	handleRemoveTempFile = (file: ITempFileItem) => {
		const { idOperacion } = this.props
		const { idarch } = file
		this.props.removeTempDocument({ idarch, idOperacion })
	}

	/**
	 * show message in screen, calling api.
	 */
	closeLoading = (type?: string, message?: string) => {
		this.props.showMessage({
			showError: true,
			type,
			message: type,
			description: message
		})
	}

	/**
	 * to load files in BD
	 */
	handleUploadApi = () => {
		const { idOperacion } = this.props
		const { uploadFiles } = this.state
		this.props.uploadFiles({
			idOperacion,
			files: uploadFiles
		})
	}

	render() {
		const { t, classes } = this.props
		const {
			stateDragDrop,
			acceptedFiles,
			titleUploadDragFile,
			buttonUpload,
			fileTempList
		} = this.state

		return (
			<Card className={`pb-3 pt-3 mb-3 ${classes.containerCard}`}>
				<Card.Body>
					<Card.Title>{t('global.attach_files')}</Card.Title>
					<Row>
						<Col>
							<DragFileInput
								id="upload-file-final-form"
								acceptedFiles={acceptedFiles}
								className="form-control"
								setFileUpload={this.handleUploadFiles}
								stateDragDrop={stateDragDrop}
								defaultText={false}
								infoMessage={{
									title: titleUploadDragFile,
									body: (
										<div
											style={{
												paddingTop: 8,
												paddingBottom: 40,
												margin: '0 auto'
											}}
										>
											<span style={{ fontWeight: 'normal' }}>
												Puede ser un archivo PDF, PNG, JPG, BMP, XLS, XLSX, CSV,
												DOC, DOCX Y TXT
											</span>
										</div>
									)
								}}
								name="document"
								titleButtonUpload={buttonUpload}
								disabled={false}
								permissions={['upload', 'delete']}
								typeAction="upload"
							/>
						</Col>
					</Row>
					{!_.isEmpty(fileTempList) && this.renderTempPreviewFiles()}
					{stateDragDrop.success && (
						<Container className={'mt-3 text-center'}>
							<Row>
								<Col>
									<InputButton
										valueButton={t('global.upload_file')}
										onClick={this.handleUploadApi}
									/>
								</Col>
							</Row>
						</Container>
					)}
				</Card.Body>
			</Card>
		)
	}
}

const mapStateToProps = ({ filesManager }: any) => {
	const { uploadFileTempList } = filesManager
	return { uploadFileTempList }
}

export default connect(mapStateToProps, {
	uploadFiles,
	getTempDocumentList,
	removeTempDocument,
	showMessage
})(withStyles(styles, { withTheme: true })(withTranslation()(UploadFileForm)))
