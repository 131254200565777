import DisplayAmount from 'components/common/displayAmount'
import InputDropdown from 'components/form/inputDropdown'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import styles from './totalsImportDoc.module.scss'

const config = {
	idCampo: 'import_cant',
	descrip: '',
	label: false,
	mascara: '',
	editable: 1,
	visible: 1,
	requerido: 0,
	valid: null
}

class TotalsImportDoc extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.selectOptions = [
			{
				id: 'T',
				label: 'Todos'
			},
			{
				id: 'C',
				label: 'Correctos Solamente'
			},
			{
				id: 'E',
				label: 'Errores Solamente'
			}
		]
	}

	handleChange = (envt) => {
		this.props.handleChangeTable({ mostrar: envt.target.value })
	}

	render() {
		const { theme, t, totals } = this.props
		const impOk = totals ? totals.cant_ok : 0
		const impError = totals ? totals.cant_error : 0
		const importUsual = totals && totals.impo_usual ? totals.impo_usual : 0
		const importExt = totals && totals.imp_ext ? totals.imp_ext : 0
		const codMonExt =
			totals && totals.cod_mone_ext ? totals.cod_mone_ext : 'U$S'

		return (
			<Row className={`${theme.divContent} container`}>
				<Col lg={4} md={12}>
					<Row>
						<Col className={theme.labelTitle} sm={7}>
							{t('Collection.import.correct_record')}
						</Col>
						<Col sm={2}>{impOk}</Col>
					</Row>
				</Col>
				<Col lg={4} md={12}>
					<Row>
						<Col className={theme.labelTitle} sm={7}>{`${t(
							'loadItem.table.totalImp'
						)}:`}</Col>
						<Col sm={4}>
							<div>
								<DisplayAmount amount={importUsual} />
							</div>
							<div>
								<DisplayAmount
									amount={importExt}
									currency={'USD'}
									prefix={codMonExt}
								/>
							</div>
						</Col>
					</Row>
				</Col>
				<Col lg={4} md={12}>
					<Row>
						<Col className={theme.labelTitle} sm={2}>
							{t('voucherInvolvement.form.sample')}
						</Col>
						<Col sm={10}>
							<InputDropdown
								inputFormCol={{ sm: 11 }}
								fields={[config]}
								label={false}
								inputId={'import_cant'}
								name={'import_cant'}
								placeholder={t('client.form.insert_client_branch')}
								styles={{ width: '100%' }}
								colLabel={'col-sm-2'}
								colInput={'col-sm-10'}
								options={this.selectOptions}
								noInitialExecute
								onChange={(e) => this.handleChange(e)}
							/>
						</Col>
					</Row>
				</Col>
				<Col lg={4} md={12}>
					<Row>
						<Col className={theme.labelTitle} sm={7}>
							{' '}
							{t('Collection.import.error_record')}{' '}
						</Col>
						<Col sm={2}>{impError}</Col>
					</Row>
				</Col>
			</Row>
		)
	}
}

export default themr(
	'TotalImportDocsStyles',
	styles
)(withTranslation()(TotalsImportDoc))
