import { ICrudGenericFields } from 'models/CrudInterface'

export const LAAssignModalFields: Array<ICrudGenericFields> = [
	{
		id: 'codigo',
		type: 'text',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'descrip',
		type: 'text',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_cta',
		type: 'text',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'desc_cta',
		type: 'text',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'nuevo_cod_cta',
		type: 'autoComplete',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 60,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
