import Axios from 'axios'
import {
	ICrudGetLAAssignations,
	ICrudUpdateLAAssignations
} from 'models/CrudDirectLAAssignationInterface'

const urlCRUD = '/abm_cont_asigcta'

export const crudGetLAAssignations = async (params: ICrudGetLAAssignations) => {
	const response = await Axios.get(`${urlCRUD}/buscar`, { params })
	return response.data
}

export const crudUpdateLAAssignations = async (
	params: ICrudUpdateLAAssignations
) => {
	const response = await Axios.post(`${urlCRUD}/confirmar`, params)
	return response.data
}
