import * as Sentry from '@sentry/react'
import _ from 'underscore'
import logger from './logger'

export const downloadFileType = (response, name) => {
	const url = window.URL.createObjectURL(new Blob([response.data]))
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', name)
	link.click()
	window.URL.revokeObjectURL(url)
}

export async function readFile(file) {
	const reader = new FileReader()
	reader.readAsText(file)
	const result = await new Promise((resolve, reject) => {
		reader.onload = function (event) {
			resolve(reader.result)
		}
	})

	return result
}

export const getFiltersTable = (filters) => {
	const result = {}
	Object.keys(filters).forEach((filter) => {
		result[`filtro_${filter}`] = filters[filter].filterVal
	})

	return result
}

export const debugError = ({ message, stacktrace = null }) => {
	const authUser = JSON.parse(localStorage.getItem('user'))

	if (
		process.env.REACT_APP_DEBUG === 'true' ||
		process.env.REACT_APP_DEBUG === true
	) {
		if (
			process.env.REACT_APP_DEBUG_URL !== '' &&
			stacktrace.response.status >= 500
		) {
			// here script to send data to api. Sentry
			Sentry.setUser(`${authUser.user}`)
			Sentry.captureException(stacktrace)
			// Sentry.captureMessage(message);
		} else {
			debugMessage(message, stacktrace)
		}
	}
}

/**
 * to show message error
 * @param {object} params
 */
export const debugMessage = ({ message, stacktrace = null }) => {
	if (
		process.env.REACT_APP_DEBUG === 'true' ||
		process.env.REACT_APP_DEBUG === true
	) {
		logger({
			module: 'Utils',
			msg: message,
			data: message,
			type: 'error'
		})
	}
}

/**
 * to set focus a input field
 * @param {ref} refInput
 */
export const setInputFocus = (refInput) => {
	if (refInput && refInput.current) {
		if (refInput.current.element) {
			refInput.current.element.focus()
		} else if (refInput.current.focus) {
			refInput.current.focus()
		} else {
			refInput.current.input.element.focus() // this works for date field
		}
	}
}

/**
 * to return array of filter data.
 * @param {array} arrayData
 * @param {object} filter
 * @returns
 */
export const getFiltersData = (arrayData, filter) => {
	return _.filter(arrayData, filter)
}

/**
 * to return array of filter data.
 * @param {array} arrayData
 * @param {object} filter
 * @returns
 */
export const getExcludeFiltersData = (arrayData, filter) => {
	return _.filter(arrayData, (field) => {
		return field.agrupador !== filter.agrupador
	})
}
