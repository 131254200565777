import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getDetailTreasurySummary } from 'actions'
import CommonModal from 'components/common/commonModal'
import CommonTable from 'components/common/commonTable'
import InputDropdown from 'components/form/inputDropdown'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getDefaultColumns } from 'lib/FormUtils'
import { IGetDetailVoucherTreasuryParams } from 'models/SearchVoucher'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import SearchVoucherTreasuryModal from './SearchVoucherTreasuryModal'

interface SearchVouchersTreasuryTableProps extends WithTranslation {
	id: string
	fields: Array<IFieldStructure>
	fieldsTable: Array<IFieldStructure>
	fieldsTreasuryModal: Array<IFieldStructure>
	getDetailTreasurySummary: (payload: IGetDetailVoucherTreasuryParams) => void
	authUser: any
	treasuryData?: any
	t?: any
}

interface SearchVouchersTreasuryTableState {
	cod_catfon: string
	primaryKey: string
	showModal: boolean
	treasuryInfo: any
}

class SearchVouchersTreasuryTable extends Component<
	SearchVouchersTreasuryTableProps,
	SearchVouchersTreasuryTableState
> {
	constructor(props: SearchVouchersTreasuryTableProps) {
		super(props)
		this.state = {
			cod_catfon: '',
			primaryKey: 'nro',
			showModal: false,
			treasuryInfo: null
		}
	}

	componentDidMount = () => {
		this.handleGetTreasury({})
	}

	/**
	 * to get treasury data of voucher
	 */
	handleGetTreasury = (filter: any) => {
		const { id, authUser } = this.props
		const { cod_catfon } = this.state
		if (id) {
			this.props.getDetailTreasurySummary({
				id,
				page_size: 10,
				page_number: 1,
				idEmpresa: authUser.userCompany,
				cod_catfon,
				...filter
			})
		}
	}

	/**
	 * to get all pagination options
	 * @returns object
	 */
	getPaginationOptions = () => {
		const { treasuryData } = this.props
		return {
			pageStartIndex: 1,
			sizePerPage: treasuryData.page_size,
			page: treasuryData.page_number,
			totalSize: treasuryData.total_count,
			onPageChange: this.handleChangeTable
		}
	}

	/**
	 * to handle pagination
	 */
	handleChangeTable = (type: string, pagination: any) => {
		if (type === 'pagination') {
			this.handleGetTreasury({
				page_size: pagination.sizePerPage,
				page_number: pagination.page
			})
		}
	}

	handleViewButton = (row: any) => {
		this.setState({ treasuryInfo: row, showModal: true })
	}

	toggleDetailModal = () => {
		this.setState((prevState) => ({ showModal: !prevState.showModal }))
	}

	render() {
		const {
			fieldsTable,
			treasuryData,
			fields,
			fieldsTreasuryModal,
			t,
			authUser
		} = this.props
		const { primaryKey, showModal, treasuryInfo } = this.state

		const additionalColumns = [
			{
				dataField: 'actions',
				text: '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '5%' },
				formatter: (cell: any, row: any, rowindex: any) => {
					return (
						<FontAwesomeIcon
							className={'mr-3'}
							icon={faEye}
							style={{ cursor: 'pointer' }}
							color={'#007bff'}
							onClick={() => this.handleViewButton(row)}
						/>
					)
				}
			}
		]

		const propsTable = {
			remote: true,
			columns: _.isEmpty(fieldsTable)
				? []
				: getDefaultColumns(fieldsTable, { authUser }, additionalColumns),
			keyField: primaryKey,
			data: _.isEmpty(treasuryData) ? [] : treasuryData.items,
			rowClasses: 'theme.tableRow',
			headerClasses: 'theme.tableHeader',
			paginationOptions: this.getPaginationOptions,
			onTableChange: this.handleChangeTable
		}

		const propsFilter = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'fil',
			inputId: 'filtro_tipo',
			name: 'filtro_tipo',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: false,
			onBlur: () => {},
			value: 'taxId',
			noInitialExecute: true,
			options: _.isEmpty(treasuryData.filtro_cat_fon)
				? []
				: treasuryData.filtro_cat_fon.map((filter: any) => ({
						id: filter.cod_catfon,
						label: `${filter.descrip}`
				  })),
			onChange: (data: any) => {
				const filter = { cod_catfon: data.target.value }
				this.setState(filter)
				this.handleGetTreasury(filter)
			}
		}

		return (
			<Fragment>
				<CommonModal
					sizeModal="xl"
					showModal={showModal}
					modalTitle={t('search_voucher.treasury_detail')}
					handleCloseModal={this.toggleDetailModal}
					buttons={false}
					modalBody={
						<SearchVoucherTreasuryModal
							fields={fieldsTreasuryModal}
							treasuryInfo={treasuryInfo}
							closeModal={this.toggleDetailModal}
						/>
					}
				/>
				<Row>
					<Col sm={6}>
						<InputDropdown {...propsFilter} />
					</Col>
				</Row>
				<Row>{!_.isEmpty(fieldsTable) && <CommonTable {...propsTable} />}</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth, searchVoucher }: any) => {
	const { authUser } = auth
	const { treasuryData } = searchVoucher
	return { authUser, treasuryData }
}

const connectForm = connect(mapStateToProps, { getDetailTreasurySummary })(
	SearchVouchersTreasuryTable
)

export default withTranslation()(connectForm)
