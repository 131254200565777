import { getIngStock, pickeoSearchBarcode, pickStockConfirm } from 'actions'
import AudioComponent from 'components/common/audioComponent'
import CommonModal from 'components/common/commonModal'
import NotificationMessage from 'components/common/notificationMessage'
import { P_PICK_STOCK_CPA } from 'constants/ConfigProcessNames'
import { addPrevValidations } from 'lib/AxiosInterceptors'
import { getFiltersData } from 'lib/Utils'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import BarCodeErrorForm from './BarCodeErrorForm'
import ProviderPickStockBarCode from './ProviderPickStockBarCode'
import ProviderPickStockFilters from './ProviderPickStockFilters'
import ProviderPickStockTable from './ProviderPickStockTable'
class ProviderPickStockForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			ingrPickeo: null,
			itemsTable: [],
			showError: false,
			errorMessage: '',
			typeError: 'danger',
			filtersPick: {},
			showConfirmationPopup: false,
			buttonsConfirmation: true
		}

		addPrevValidations((e) => {
			if (e.request.responseURL.includes('cod_barra')) {
				return true
			}
			return false
		})
	}

	componentDidMount() {
		const { idOperacion } = this.props
		this.props.formConfirmation(this.handleConfirmation)

		if (idOperacion) {
			this.props.getIngStock({
				idOperacion,
				page_size: 10,
				ind_filtro_fecha: 0,
				filtro_fecha: 0,
				filtro_afectados: 0,
				page_number: 1
				// orden: 2,
				// fec_desde: "2021-06-22",
				// fec_hasta: "2021-07-22",
			})
			this.handleConfirmation = this.handleConfirmation.bind(this)
		}
	}

	componentDidUpdate = (prevProps) => {
		const { ingrPickeo, barCodeSearched, barCodeError, t } = this.props

		if (ingrPickeo !== prevProps.ingrPickeo && ingrPickeo) {
			this.setState({ ingrPickeo })
		}

		if (barCodeSearched !== prevProps.barCodeSearched && barCodeSearched) {
			this.setState({ ingrPickeo: barCodeSearched })
			this.handleSoundSuccess()
		}

		if (barCodeError !== prevProps.barCodeError && barCodeError) {
			this.handleSoundError()
			let showError = true

			if (barCodeError.typeError === 'C') {
				showError = false
				this.setState({
					showConfirmationPopup: true,
					errorMessage: `${barCodeError.message}`,
					titleBarCodeError: t('global.confirm'),
					buttonsConfirmation: true,
					showError
				})
			} else if (barCodeError.typeError === 'S') {
				showError = false
				const { requireFields, dataFields } = barCodeError
				const errorMessage = (
					<BarCodeErrorForm
						requireFields={requireFields}
						dataFields={dataFields}
						t={t}
						handleSubmitForm={this.handleConfirmationPopup}
						handleCloseModal={this.handleCloseErrorPopup}
					/>
				)
				this.setState({
					showConfirmationPopup: true,
					errorMessage,
					titleBarCodeError: t('global.mandatory_fields'),
					buttonsConfirmation: false,
					showError: false
				})
			} else if (showError) {
				this.setState({
					showError,
					buttonsConfirmation: true,
					errorMessage: `${barCodeError.message}`,
					typeError: 'danger'
				})
			}
		}
	}

	/**
	 * to apply change when call api
	 */
	handleChangeFilter = (filters) => {
		const { idOperacion } = this.props
		const { filtersPick } = this.state
		const allFilters = { ...filtersPick, ...filters }

		this.props.getIngStock({
			idOperacion,
			page: 1,
			page_size: 10,
			ind_filtro_fecha: 0,
			filtro_fecha: 0,
			filtro_afectados: 0,
			...allFilters
		})

		this.setState({ filtersPick: allFilters })
	}

	handleConfirmation = () => {
		const { idOperacion, callBackReturn } = this.props
		this.props.pickStockConfirm({ idOperacion, callBackReturn })
	}

	/**
	 * call api sound success
	 */
	handleSoundSuccess = async () => {
		this.soundSuccess()
	}

	/**
	 * call api sound error
	 */
	handleSoundError = async () => {
		this.soundError()
	}

	/**
	 * to close error notification
	 */
	handleCloseErrorNotification = () => {
		this.setState({ showError: false })
	}

	/**
	 * to close error popup
	 */
	handleCloseErrorPopup = () => {
		this.setState({ showConfirmationPopup: false })
	}

	/**
	 * to resend barcode request
	 */
	handleConfirmationPopup = (valuesForm = {}) => {
		const { barCodeParams, pickeoSearchBarcode } = this.props

		const fec_vto = valuesForm.fec_vto
			? moment(valuesForm.fec_vto).format('YYYY-MM-DD')
			: ''

		const params = !_.isEmpty(valuesForm)
			? { ...barCodeParams, ...valuesForm, fec_vto }
			: { ...barCodeParams, confirmacion: 1 }
		pickeoSearchBarcode(params)
		this.handleCloseErrorPopup()
	}

	/**
	 *  to paginate the table.
	 * @param {string} type
	 * @param {object} pagination
	 */
	handleChangeTable = (type, pagination) => {
		const { idOperacion } = this.props
		const { filtersPick } = this.state
		if (type === 'pagination') {
			this.props.getIngStock({
				...filtersPick,
				idOperacion,
				page_number: pagination.page,
				page_size: pagination.sizePerPage,
				ind_filtro_fecha: 0,
				filtro_fecha: 0,
				filtro_afectados: 0
			})
		}
	}

	render() {
		const {
			config,
			idOperacion,
			searchInput,
			propShowFilters,
			enableCant,
			propEnableLast
		} = this.props
		const {
			ingrPickeo,
			showError,
			errorMessage,
			typeError,
			showConfirmationPopup,
			titleBarCodeError,
			buttonsConfirmation
		} = this.state
		const fieldsForm = config
			? getFiltersData(config.campos, { agrupador: 'formulario' })
			: null
		return (
			<Fragment>
				<AudioComponent
					handleSuccess={(click) => (this.soundSuccess = click)}
					handleError={(click) => (this.soundError = click)}
				/>
				<Col md={12} className={'mb-1'}>
					<NotificationMessage
						showError={showError}
						errorMessage={errorMessage}
						type={typeError}
						handleCloseError={this.handleCloseErrorNotification}
					/>
					<CommonModal
						showModal={showConfirmationPopup}
						handleCloseModal={this.handleCloseErrorPopup}
						modalTitle={titleBarCodeError}
						modalBody={errorMessage}
						buttons={buttonsConfirmation}
						handleSubmit={this.handleConfirmationPopup}
					/>
				</Col>
				<ProviderPickStockFilters
					ingrPickeo={ingrPickeo}
					fieldsForm={fieldsForm}
					propShowFilters={propShowFilters}
					handleChangeFilter={this.handleChangeFilter}
					idOperacion={idOperacion}
				/>
				<ProviderPickStockBarCode
					propEnableLast={propEnableLast}
					fieldsForm={fieldsForm}
					ingrPickeo={ingrPickeo}
					idOperacion={idOperacion}
					searchInput={searchInput}
					enableCant={enableCant}
					handleToggleCant={this.props.handleToggleCant}
				/>
				<ProviderPickStockTable
					fieldsTable={
						config
							? getFiltersData(config.campos, { agrupador: 'grilla' })
							: null
					}
					itemsTable={ingrPickeo ? ingrPickeo.Items : []}
					page={ingrPickeo ? ingrPickeo.page_number : 1}
					pageSize={ingrPickeo ? ingrPickeo.page_size : 10}
					totalPage={ingrPickeo ? ingrPickeo.total_count : 10}
					handleChangeTable={this.handleChangeTable}
				/>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ voucher, pickeoIngStock }) => {
	const config = voucher.config ? voucher.config[P_PICK_STOCK_CPA] : null
	const {
		ingrPickeo,
		barCodeSearched,
		barCodeError,
		barCodeParams,
		pickStockImportError
	} = pickeoIngStock
	return {
		config,
		ingrPickeo,
		barCodeSearched,
		barCodeError,
		barCodeParams,
		pickStockImportError
	}
}

export default connect(mapStateToProps, {
	getIngStock,
	pickStockConfirm,
	pickeoSearchBarcode
})(withTranslation()(ProviderPickStockForm))
