import CommonTable from 'components/common/commonTable'
import { getValueMask } from 'lib/MaskValues'
import React, { Component, Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { connect } from 'react-redux'
import { getProductStock, validateProductStock } from '../../actions'
import styles from './stockTable.module.scss'

class StockTableProvider extends Component {
	constructor(props) {
		super(props)

		this.state = {
			itemsTable: [],
			selectedCheck: [],
			rowSelected: [],
			primaryKey: null,
			paginationOptions: { sizePerPage: 10, page: 1, totalSize: 10 }
		}
		this.primaryKey = 'nipartida'
	}

	componentDidUpdate = (prevProps) => {
		const { productStock, searchItems, loadStock, applyStock } = this.props

		if (productStock !== prevProps.productStock && productStock) {
			this.primaryKey = productStock.ind_series === 1 ? 'nserie' : 'nipartida' // Se setea la llave primaria.
			const tableColumns = [
				{
					idCampo: 'cuf',
					descripcion: 'Cuf',
					label: 'CUF',
					editable: 0,
					visible: 1,
					requerido: 0,
					valid: null,
					mascara: ''
				},
				{
					idCampo: 'lote',
					descripcion: 'partida',
					label: 'Partida',
					editable: 0,
					visible: 1,
					requerido: 0,
					valid: null
				},
				{
					idCampo: 'fec_vto',
					descripcion: 'fecha vto',
					label: 'Fecha Vto.',
					editable: 0,
					visible: 1,
					requerido: 0,
					valid: null,
					mascara: 'FechaLarga'
				},
				{
					idCampo: 'nserie',
					descripcion: 'numero de serie',
					label: 'Nro. Serie',
					editable: 0,
					visible: productStock.ind_series,
					requerido: 0,
					valid: null
				},
				{
					idCampo: 'cantidad',
					descripcion: 'Cantidad',
					label: 'Cantidad',
					editable: 0,
					visible: !productStock.ind_series,
					requerido: 0,
					valid: null
				}
			]

			const paginationOptions = {
				sizePerPage: productStock.page_size,
				page: productStock.page_number,
				totalSize: productStock.total_count
			}
			this.setState({
				primaryKey: this.primaryKey,
				itemsTable: productStock.partidas,
				tableColumns,
				paginationOptions
			})
		}

		if (loadStock !== prevProps.loadStock && loadStock) {
			const paginationOptions = {
				sizePerPage: loadStock.page_size,
				page: loadStock.page_number,
				totalSize: loadStock.total_count
			}
			this.setState({ itemsTable: loadStock.partidas, paginationOptions })
		}

		if (applyStock !== prevProps.applyStock && applyStock) {
			const paginationOptions = {
				sizePerPage: applyStock.page_size,
				page: applyStock.page_number,
				totalSize: applyStock.total_count
			}

			this.setState({ itemsTable: applyStock.partidas, paginationOptions })
		}

		if (prevProps.searchItems !== searchItems && searchItems) {
			const paginationOptions = {
				sizePerPage: searchItems.page_size,
				page: searchItems.page_number,
				totalSize: searchItems.total_count
			}

			this.setState({
				itemsTable: searchItems.partidas ? searchItems.partidas : [],
				paginationOptions
			})
		}
	}

	getColumns = () => {
		const { tableColumns } = this.state
		const rows = tableColumns.map((field) => {
			const idField = field.idCampo.trim()
			return {
				dataField: idField,
				text: field.label,
				align: 'center',
				headerAlign: 'center',
				headerStyle: this.getStyleColumn(field),
				hidden: !field.visible,
				formatter: (cell, row, rowIndex) => {
					return this.renderFormat(field, cell, row)
				}
			}
		})
		return rows
	}

	handleRowStyle = (row) => {
		const style = {}
		if (row.ind_error) {
			style.backgroundColor = '#FFCC99'
		} else if (!row.ind_error) {
			style.backgroundColor = '#33CCFF'
		}
		return style
	}

	renderFormat = (field, value, row) => {
		let result = value
		if (field.mascara) {
			result = getValueMask(value, field.mascara, this.props)
		}
		return result
	}

	getStyleColumn = (field) => {
		const idField = field.idCampo.trim()
		let style = {}
		switch (idField) {
			default:
				style = { width: '25%' }
				break
		}

		if (field.requerido === '1' || field.requerido) {
			style.color = 'red'
		}

		return style
	}

	render() {
		const { theme } = this.props
		const { itemsTable, tableColumns, paginationOptions } = this.state

		const options = itemsTable
			? {
					pageStartIndex: 1,
					...paginationOptions
			  }
			: {}

		return (
			<Fragment>
				<Col className={`col-6 pt-2 pl-0 pr-0`}>
					{tableColumns && (
						<CommonTable
							remote
							columns={this.getColumns()}
							keyField={this.primaryKey}
							data={itemsTable}
							rowClasses={theme.tableRow}
							headerClasses={theme.tableHeader}
							paginationOptions={options}
							onTableChange={this.props.handleChangeTable}
						/>
					)}
				</Col>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth, stock }) => {
	const { authUser } = auth
	const { paramsValidate } = stock

	return { authUser, paramsValidate }
}

const connectForm = connect(mapStateToProps, {
	getProductStock,
	validateProductStock
})(StockTableProvider)

export default themr('StateTableStyles', styles)(connectForm)
