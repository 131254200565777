import CommonCard from 'components/common/CommonCard'
import NotificationMessage from 'components/common/notificationMessage'
import { P_AUTOMATIC_PURCHASE_ORDER } from 'constants/ConfigProcessNames'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getFiltersData } from 'lib/Utils'
import { IConfigFieldsForm } from 'models/Budget'
import { ICrudFieldStructure } from 'models/CrudInterface'
import React, { Component, Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import AutomaticPurchaseFields from './AutomaticPurchaseFields'
import AutomaticPurchaseFilters from './AutomaticPurchaseFilters'
import AutomaticPurchaseProvider from './AutomaticPurchaseProvider'
import AutomaticPurchaseTable from './AutomaticPurchaseTable'

interface AutomaticPurchaseProps extends WithTranslation {
	config: IConfigFieldsForm
	t?: any
	idOperacion?: any
	providerTableRef: any
	callBackReturn?: any
	automaticPurchaseData: any
}
interface AutomaticPurchaseState {
	openFilters: boolean
	openProducts: boolean
	showProductsForm: boolean
	filters: any
	typeNotification: string
	errorMessage: string
	showError: boolean
	fieldsTable: Array<IFieldStructure>
	fieldsForm: Array<IFieldStructure>
}

interface IParams {
	showColumns: boolean
}
class AutomaticPurchase extends Component<
	AutomaticPurchaseProps,
	AutomaticPurchaseState
> {
	constructor(props: AutomaticPurchaseProps) {
		super(props)
		this.state = {
			openFilters: true,
			openProducts: true,
			showProductsForm: false,
			filters: null,
			typeNotification: 'success',
			errorMessage: '',
			showError: false,
			fieldsTable: [],
			fieldsForm: []
		}
	}

	componentDidMount = (): void => {
		const { config } = this.props
		if (!_.isEmpty(config)) {
			this.handleSetFields(config)
		}
	}

	handleSetFields = (config: IConfigFieldsForm) => {
		const fieldsTable = config
			? getFiltersData(config.campos, { agrupador: 'grilla' })
			: []

		const fieldsForm = config
			? getFiltersData(config.campos, { agrupador: 'formulario' })
			: []

		this.setState({ fieldsTable, fieldsForm })
	}

	componentDidUpdate(prevProps: Readonly<AutomaticPurchaseProps>): void {
		const { automaticPurchaseData, config } = this.props

		if (
			!_.isEqual(prevProps.automaticPurchaseData, automaticPurchaseData) &&
			!_.isEmpty(automaticPurchaseData)
		) {
			if (_.has(automaticPurchaseData, 'Resultado')) {
				const typeNotification = _.isEmpty(automaticPurchaseData.Items)
					? 'danger'
					: 'success'

				const { Mens_error } = automaticPurchaseData.Resultado

				this.setState({
					showError: !!Mens_error,
					errorMessage: Mens_error || '',
					typeNotification
				})
			}
		}
	}

	toggleStateFilters = () => {
		this.setState((state) => ({ openFilters: !state.openFilters }))
	}

	toggleStateProducts = () => {
		this.setState((state) => ({ openProducts: !state.openProducts }))
	}

	toggleColumns = ({ showColumns }: IParams) => {
		const { config } = this.props

		const fieldsTable = config
			? getFiltersData(config.campos, { agrupador: 'grilla' })
			: []

		// List of objects to filter
		const filteredObjects = ['vta_prom', 'dias_stock']

		// Create a filter function based on the value of includeAllObjects
		const filterFunction = showColumns
			? () => true // Include all objects
			: (object: ICrudFieldStructure) =>
					!filteredObjects.includes(object.idCampo) // Exclude objects in filteredObjects

		// Filter the original array using the filter function
		const filteredFields = fieldsTable.filter(filterFunction)

		this.setState({ fieldsTable: filteredFields })
	}

	handleFilters = (filters: any) => {
		this.setState({
			showProductsForm: true,
			openFilters: false,
			filters
		})
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	render() {
		const {
			openFilters,
			openProducts,
			showProductsForm,
			filters,
			typeNotification,
			errorMessage,
			showError,
			fieldsForm,
			fieldsTable
		} = this.state
		const { t, idOperacion, providerTableRef, callBackReturn } = this.props

		return (
			<Fragment>
				<CommonCard
					isOpen={openFilters}
					toggleState={this.toggleStateFilters}
					content={
						<AutomaticPurchaseFilters
							idOperacion={idOperacion}
							handleFilters={this.handleFilters}
							toggleColumns={this.toggleColumns}
						/>
					}
					title={t('global.filters')}
				/>
				{showProductsForm && (
					<Fragment>
						<Col sm={12}>
							<AutomaticPurchaseFields
								fields={fieldsForm}
								idOperacion={idOperacion}
							/>
						</Col>
						<CommonCard
							isOpen={openProducts}
							toggleState={this.toggleStateProducts}
							content={
								<AutomaticPurchaseProvider
									handleFilters={this.handleFilters}
									idOperacion={idOperacion}
									providerTableRef={providerTableRef}
									callBackReturn={callBackReturn}
								/>
							}
							title={t('automatic_purchase.provider_data')}
						/>
						<Col sm={12}>
							<NotificationMessage
								showError={showError}
								errorMessage={errorMessage}
								handleCloseError={this.handleCloseError}
								type={typeNotification}
							/>
						</Col>
						<AutomaticPurchaseTable
							fields={fieldsTable}
							idOperacion={idOperacion}
							filters={filters}
						/>
					</Fragment>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = ({ voucher, autoGenerate, automaticPurchase }: any) => {
	const config = voucher.config
		? voucher.config[P_AUTOMATIC_PURCHASE_ORDER]
		: null
	const { generatedStatus, autoConfirm } = autoGenerate
	const { automaticPurchaseData } = automaticPurchase

	return { config, generatedStatus, autoConfirm, automaticPurchaseData }
}

const mapDispatchToProps = {}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(AutomaticPurchase))
