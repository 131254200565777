import InputText from 'components/form/inputText'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

class LocationFormInput extends Component {
	render() {
		const {
			t,
			values,
			handleChange,
			handleBlur,
			setFieldValue,
			readOnly,
			fields,
			inputsForm
		} = this.props

		return (
			<Row>
				<Row className={'col-11'} style={{ paddingRight: '0px' }}>
					<InputText
						inputFormCol={{ sm: 6 }}
						fields={fields}
						label={t('client.form.location')}
						inputId={inputsForm.location}
						name={inputsForm.location}
						placeholder={t('client.form.insert_location')}
						colLabel={'col-sm-4'}
						colInput={'col-sm-8'}
						disable={readOnly}
						value={values[inputsForm.location]}
						onBlur={handleBlur}
						onChange={(data) => {
							setFieldValue(inputsForm.location, data)
							handleChange()
						}}
					/>

					<InputText
						inputFormCol={{ sm: 6, style: { paddingRight: '0px' } }}
						fields={fields}
						label={t('client.form.province')}
						inputId={inputsForm.province}
						name={inputsForm.province}
						placeholder={t('client.form.insert_province')}
						colLabel={'col-sm-4'}
						colInput={'col-sm-8'}
						divStyle={{ paddingLeft: '10px' }}
						styleLabel={{ textAlign: 'right' }}
						disable={readOnly}
						onBlur={handleBlur}
						value={values[inputsForm.province]}
						onChange={(data) => {
							setFieldValue(inputsForm.province, data)
							handleChange()
						}}
					/>
				</Row>
				<Row className={'col-11'} style={{ paddingRight: '0px' }}>
					<InputText
						inputFormCol={{ sm: 6 }}
						fields={fields}
						label={t('client.form.postalCode')}
						inputId={inputsForm.codPos}
						name={inputsForm.codPos}
						placeholder={t('client.form.insert_postal_code')}
						colLabel={'col-sm-4'}
						colInput={'col-sm-8'}
						disable={readOnly}
						onBlur={handleBlur}
						value={values[inputsForm.codPos]}
						onChange={(data) => {
							handleChange()
							setFieldValue(inputsForm.codPos, data)
						}}
					/>
				</Row>
			</Row>
		)
	}
}

export default withTranslation()(LocationFormInput)
