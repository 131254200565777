import {
	crudGetProviderCodeConfig,
	crudValidateProviderCode,
	getTableConceptsBackgrounds,
	getTableCountry,
	getTableForeignCurrencies,
	getTableGenerationType,
	getTableIsCUITRepeated,
	getTableIsPostalCodeValid,
	getTableIsTaxesIdValid,
	getTablePaymentConditions,
	getTableProviderCat,
	getTableProvince,
	getTableResponsibleType,
	getTableVoucherTypeAdmin
} from 'actions'
import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import NotificationError from 'components/common/notificationsErrors'
import { FormRefsContext } from 'context/FormRefsContext'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { ICrudProviderField } from 'models/CrudProviderInterface'
import {
	IGetTableConceptsBackgroundRes,
	IGetTableCountryRes,
	IGetTableForeignCurrenciesRes,
	IGetTableGenerationTypeRes,
	IGetTablePaymentConditionsRes,
	IGetTableProviderCategoryRes,
	IGetTableProvinceRes,
	IGetTableResponsibleTypeRes,
	IGetTableVoucherTypeRes
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import * as Yup from 'yup'
import CrudProviderDataField from './CrudProviderDataField'
import { ISubGroup } from './CrudProviderTabsDataContents'

interface CrudProviderDataProps extends WithTranslation {
	getTableResponsibleType: any
	getTableProvince: any
	getTableCountry: any
	getTableForeignCurrencies: any
	getTableConceptsBackgrounds: any
	getTableGenerationType: any
	getTableVoucherTypeAdmin: any
	getTableIsPostalCodeValid: any
	getTableIsTaxesIdValid: any
	getTablePaymentConditions: any
	getTableProviderCat: any
	getTableIsCUITRepeated: any
	crudGetProviderCodeConfig: any
	crudValidateProviderCode: any
	responsibleType: IGetTableResponsibleTypeRes
	province: IGetTableProvinceRes
	country: IGetTableCountryRes
	foreignCurrencies: IGetTableForeignCurrenciesRes
	conceptsBackgrounds: IGetTableConceptsBackgroundRes
	generationType: IGetTableGenerationTypeRes
	voucherType: IGetTableVoucherTypeRes
	paymentConditions: IGetTablePaymentConditionsRes
	providerCategory: IGetTableProviderCategoryRes
	providerCodeConfig: any
	providerCodeValidated: any
	isPostalCodeValid: number
	isTaxesIdValid: boolean
	disableForm: boolean
	fields: Array<ICrudProviderField>
	showMessage: any
	t: any
	handleSubmit: any
	itemInfo?: any
	handleCloseModal: any
	method: string
	showWarning: boolean
	handleWarning: any
	setFormChanged: any
	subGroups: Array<ISubGroup>
}

type CrudProviderDataState = {
	showCodePopup: boolean
	values?: any
}

class CrudProviderData extends Component<
	CrudProviderDataProps,
	CrudProviderDataState
> {
	constructor(props: CrudProviderDataProps) {
		super(props)
		this.state = {
			showCodePopup: false
		}
	}

	// Initial dropdown setup
	componentDidMount = () => {
		this.props.getTableResponsibleType()
		this.props.getTableProvince()
		this.props.getTableCountry()
		this.props.getTableForeignCurrencies()
		this.props.getTableConceptsBackgrounds()
		this.props.getTableGenerationType()
		this.props.getTableVoucherTypeAdmin()
		this.props.getTablePaymentConditions()
		this.props.getTableProviderCat()
	}

	componentDidUpdate = (prevProps: CrudProviderDataProps) => {
		const { providerCodeValidated } = this.props
		const { providerCodeValidated: prevProviderCodeValidated } = prevProps
		if (
			providerCodeValidated &&
			providerCodeValidated !== prevProviderCodeValidated
		) {
			this.setState({ showCodePopup: false })
		}
	}

	handleCodePopup = (showCodePopup: boolean) => {
		const { providerCategory } = this.props
		providerCategory && this.setState({ showCodePopup })
	}

	render() {
		const {
			fields,
			t,
			handleSubmit,
			itemInfo,
			disableForm,
			showWarning,
			handleWarning,
			setFormChanged,
			isPostalCodeValid,
			isTaxesIdValid,
			method,
			subGroups
		} = this.props

		const { showCodePopup } = this.state

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})

		const columnsCancel = disableForm ? 12 : 6
		const validationSchema = fields.length
			? getValidationSchema(
					fields,
					t,
					[],
					[
						{
							id: 'cuit',
							validation: Yup.string().when(['tipo_resp'], {
								is: (tipo_resp) => tipo_resp !== 'CF',
								then: Yup.string().test(
									'ValidCuit',
									t('validation-invalid', { field: 'CUIT' }),
									(cuit: any) => {
										return isTaxesIdValid === true
									}
								)
							})
						},
						{
							id: 'cpos',
							validation: Yup.string().test(
								'ValidCpos',
								t('validation-invalid', { field: 'Cod.Postal' }),
								(cpos: any) => {
									return isPostalCodeValid === 1
								}
							)
						}
					]
			  )
			: {}

		const allFields = _.map(subGroups, (subGroup) => {
			const id = subGroup.groupId
			const filterFields = fields.filter((field: ICrudProviderField) => {
				return field.agrupador === subGroup.groupId
			})
			return { name: id, fields: filterFields, rights: subGroup.accessRight }
		})

		return (
			<FormRefsContext.Consumer>
				{({ formRef, cancelButtonRef }) => (
					<Fragment>
						<Formik
							ref={formRef}
							initialValues={initialValues}
							onSubmit={(values, actions) => {
								actions.setErrors({ cuit: '' })
								handleSubmit(values)
							}}
							enableReinitialize={true}
							validationSchema={validationSchema}
						>
							{(props: FormikProps<any>) => (
								<Fragment>
									<Col sm={12}>
										<NotificationError
											errors={props.errors}
											touched={props.touched}
										/>
									</Col>
									<Form
										onChange={(e) => {
											setFormChanged(true)
										}}
									>
										{(method === 'add' ||
											((method === 'edit' || method === 'view') && itemInfo)) &&
											_.map(
												allFields,
												(category: {
													name: string
													fields: Array<ICrudProviderField>
													rights: string
												}) => {
													return (
														<Fragment key={category.name}>
															{category.rights !== 'N' && (
																<Alert variant={'dark'}>
																	<b>
																		{t(
																			'CRUDS.general_use.' +
																				category.name.replace('.', '')
																		)}
																	</b>
																</Alert>
															)}
															{_.map(
																category.fields,
																(field: ICrudProviderField) => {
																	return (
																		<CrudProviderDataField
																			key={field.idCampo}
																			formikProps={props}
																			field={field}
																			showCodePopup={showCodePopup}
																			handleCodePopup={this.handleCodePopup}
																			crudProps={this.props}
																			initialValue={
																				initialValues[
																					field.idCampo as keyof typeof initialValues
																				]
																			}
																			accessRights={category.rights}
																		/>
																	)
																}
															)}
														</Fragment>
													)
												}
											)}
										<Row className={'pt-3'}>
											{!disableForm && (
												<Col sm={6} className={'text-center mt-2'}>
													<Button style={{ minWidth: '145px' }} type={'submit'}>
														{t('Collection.form.buttons.accept')}
													</Button>
												</Col>
											)}
											<Col sm={columnsCancel} className={'text-center mt-2'}>
												<Button
													style={{ minWidth: '145px' }}
													type={'button'}
													onClick={this.props.handleCloseModal}
													ref={cancelButtonRef}
												>
													{t('Collection.form.buttons.cancel')}
												</Button>
											</Col>
										</Row>
										<ConfirmModalNoButton
											modalTitle={t('CRUDS.modal.title_warning')}
											messageBody={t('CRUDS.modal.message_changes')}
											showModal={showWarning}
											handleCloseModal={() => handleWarning(false, 'close')}
											handleCancelButton={() => {
												handleWarning(false, 'cancel')
											}}
											handleSubmitButton={(e: any) => {
												props.handleSubmit(e as any)
												handleWarning(false, 'submit')
											}}
										/>
									</Form>
								</Fragment>
							)}
						</Formik>
					</Fragment>
				)}
			</FormRefsContext.Consumer>
		)
	}
}

const mapStateToProps = ({
	tableCalls,
	validationResponses,
	providerCode
}: any) => {
	const {
		responsibleType,
		province,
		country,
		foreignCurrencies,
		conceptsBackgrounds,
		generationType,
		voucherType,
		paymentConditions,
		providerCategory
	} = tableCalls
	const { isPostalCodeValid, isTaxesIdValid } = validationResponses
	const { providerCodeConfig, providerCodeValidated } = providerCode
	return {
		responsibleType,
		province,
		country,
		foreignCurrencies,
		conceptsBackgrounds,
		generationType,
		voucherType,
		isPostalCodeValid,
		paymentConditions,
		providerCategory,
		isTaxesIdValid,
		providerCodeConfig,
		providerCodeValidated
	}
}

const connectForm = connect(mapStateToProps, {
	getTableResponsibleType,
	getTableProvince,
	getTableCountry,
	getTableForeignCurrencies,
	getTableConceptsBackgrounds,
	getTableGenerationType,
	getTableVoucherTypeAdmin,
	getTableIsPostalCodeValid,
	getTablePaymentConditions,
	getTableProviderCat,
	getTableIsTaxesIdValid,
	getTableIsCUITRepeated,
	crudGetProviderCodeConfig,
	crudValidateProviderCode
})(CrudProviderData)

export default withTranslation()(withRouter(connectForm))
