import { getTableDepots } from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues } from 'lib/FieldValidations'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import DepotsFormField from './DepotsFormField'

type DepotProps = {
	fields: Array<IFieldStructure>
	handleSubmit: any
	itemInfo?: any
	disableForm?: boolean
	handleCloseModal: any
	renderFormInputs: any
	niprod: number
	depots: any
	alta: number
	getTableDepots: any
	t: any
}
class DepotsForm extends Component<DepotProps> {
	componentDidMount = () => {
		this.props.getTableDepots()
	}

	render() {
		const { fields, handleSubmit, itemInfo, disableForm, t, alta } = this.props

		for (const key in itemInfo) {
			itemInfo[key] =
				typeof itemInfo[key] === 'string' ? itemInfo[key].trim() : itemInfo[key]
		}

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})
		const columnsCancel = disableForm ? 12 : 6

		return (
			<>
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						handleSubmit(values)
					}}
					enableReinitialize={true}
				>
					{(props: FormikProps<any>) => (
						<Fragment>
							<Col sm={12}>
								<NotificationError
									errors={props.errors}
									touched={props.touched}
								/>
							</Col>
							<Form>
								{(alta === 1 || (alta === 0 && itemInfo)) &&
									_.map(fields, (field: any) => {
										return (
											<Fragment>
												{(field.idCampo !== 'nicomp' || alta !== 0) && (
													<DepotsFormField
														key={field.idCampo}
														formikProps={props}
														field={field}
														crudProps={this.props}
														initialValue={
															initialValues[
																field.idCampo as keyof typeof initialValues
															]
														}
													/>
												)}
												{field.idCampo === 'nicomp' && alta !== 0 && <hr />}
											</Fragment>
										)
									})}
								<Row className={'pt-3'}>
									{!disableForm && (
										<Col sm={6} className={'text-center mt-2'}>
											<Button style={{ minWidth: '145px' }} type={'submit'}>
												{t('Collection.form.buttons.accept')}
											</Button>
										</Col>
									)}
									<Col sm={columnsCancel} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px' }}
											type={'button'}
											onClick={this.props.handleCloseModal}
										>
											{t('Collection.form.buttons.cancel')}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					)}
				</Formik>
			</>
		)
	}
}

const mapStateToProps = ({ tableCalls }: any) => {
	const { depots } = tableCalls
	return { depots }
}

const connectForm = connect(mapStateToProps, {
	getTableDepots
})(DepotsForm)

export default withTranslation()(withRouter(connectForm))
