import Axios from 'axios'
import {
	ICrudCreateProviderCode,
	ICrudGetProviderCode,
	ICrudGetProviderCodeConfig
} from 'models/CrudProviderInterface'
const urlCRUD = '/abm_cpa_proveedores'

export const crudGetProviderCode = async (params: ICrudGetProviderCode) => {
	const response = await Axios.get(`${urlCRUD}/niveles/datos`, { params })
	return response.data
}

export const crudGetProviderCodeConfig = async (
	params: ICrudGetProviderCodeConfig
) => {
	const response = await Axios.get(
		`${urlCRUD}/niveles/config?cod_val=${params}`
	)
	return response.data
}

export const crudCreateProviderCode = async (
	params: ICrudCreateProviderCode
) => {
	const response = await Axios.post(`${urlCRUD}/niveles/aceptar`, params)
	return response.data
}
