import {
	CLEAR_VALIDATION_TABLE_DATA,
	GET_FILTER_CC_ASSIGN_TYPE,
	GET_FILTER_CC_ASSIGN_TYPE_SUCCESS,
	GET_FILTER_CONDITION_SALE,
	GET_FILTER_CONDITION_SALE_SUCCESS,
	GET_FILTER_LA_ASSIGN_ORDER,
	GET_FILTER_LA_ASSIGN_ORDER_SUCCESS,
	GET_FILTER_LA_ASSIGN_STATUS,
	GET_FILTER_LA_ASSIGN_STATUS_SUCCESS,
	GET_FILTER_LA_ASSIGN_TYPE,
	GET_FILTER_LA_ASSIGN_TYPE_SUCCESS,
	GET_FILTER_PRODUCT,
	GET_FILTER_PRODUCT_SUCCESS,
	GET_FILTER_TRANSPORT,
	GET_FILTER_TRANSPORT_SUCCESS,
	GET_OPTIONS_FILTER_AFFECTED,
	GET_OPTIONS_FILTER_AFFECTED_SUCCESS,
	GET_ORDER_CBT_CAB,
	GET_ORDER_CBT_CAB_SUCCESS,
	GET_TABLE_ABM_TABS,
	GET_TABLE_ABM_TABS_SUCCESS,
	GET_TABLE_ALIGNMENT_TYPE,
	GET_TABLE_ALIGNMENT_TYPE_SUCCESS,
	GET_TABLE_AV_ACCOUNTING,
	GET_TABLE_AV_ACCOUNTING_SUCCESS,
	GET_TABLE_AV_FUNDS,
	GET_TABLE_AV_FUNDS_SUCCESS,
	GET_TABLE_AV_PURCHASES,
	GET_TABLE_AV_PURCHASES_SUCCESS,
	GET_TABLE_AV_SALES,
	GET_TABLE_AV_SALES_SUCCESS,
	GET_TABLE_AV_STOCK,
	GET_TABLE_AV_STOCK_SUCCESS,
	GET_TABLE_BARCODE_FORMAT,
	GET_TABLE_BARCODE_FORMAT_SUCCESS,
	GET_TABLE_BONIFICATIONS,
	GET_TABLE_BONIFICATIONS_SUCCESS,
	GET_TABLE_BONIF_RATES,
	GET_TABLE_BONIF_RATES_SUCCESS,
	GET_TABLE_BRANCH_OFFICES,
	GET_TABLE_BRANCH_OFFICES_SUCCESS,
	GET_TABLE_CC_IMPUTATION_TYPE,
	GET_TABLE_CC_IMPUTATION_TYPE_SUCCESS,
	GET_TABLE_COMMISSION_TYPE,
	GET_TABLE_COMMISSION_TYPE_SUCCESS,
	GET_TABLE_CONCEPTS_BACKGROUNDS,
	GET_TABLE_CONCEPTS_BACKGROUNDS_SUCCESS,
	GET_TABLE_COST_ACCOUNTING_TYPE,
	GET_TABLE_COST_ACCOUNTING_TYPE_SUCCESS,
	GET_TABLE_COST_CENTERS,
	GET_TABLE_COST_CENTERS_CATEGORY,
	GET_TABLE_COST_CENTERS_CATEGORY_SUCCESS,
	GET_TABLE_COST_CENTERS_SUCCESS,
	GET_TABLE_COUNTRY,
	GET_TABLE_COUNTRY_SUCCESS,
	GET_TABLE_COURIERS,
	GET_TABLE_COURIERS_SUCCESS,
	GET_TABLE_CREDIT,
	GET_TABLE_CREDIT_SUCCESS,
	GET_TABLE_CUF,
	GET_TABLE_CUF_DATA,
	GET_TABLE_CUF_DATA_SUCCESS,
	GET_TABLE_CUF_SUCCESS,
	GET_TABLE_CURRENCIES,
	GET_TABLE_CURRENCIES_SUCCESS,
	GET_TABLE_CUSTOMER_ATTR,
	GET_TABLE_CUSTOMER_ATTR_LIST,
	GET_TABLE_CUSTOMER_ATTR_LIST_SUCCESS,
	GET_TABLE_CUSTOMER_ATTR_SUCCESS,
	GET_TABLE_CUSTOMER_BRANCHES,
	GET_TABLE_CUSTOMER_BRANCHES_SUCCESS,
	GET_TABLE_CUSTOMER_CATEGORY,
	GET_TABLE_CUSTOMER_CATEGORY_SUCCESS,
	GET_TABLE_DATA_TYPE,
	GET_TABLE_DATA_TYPE_SUCCESS,
	GET_TABLE_DEPOTS,
	GET_TABLE_DEPOTS_SUCCESS,
	GET_TABLE_DOCUMENT_TYPE,
	GET_TABLE_DOCUMENT_TYPE_SUCCESS,
	GET_TABLE_ESTIMATION_TYPE,
	GET_TABLE_ESTIMATION_TYPE_SUCCESS,
	GET_TABLE_FOREIGN_CURRENCIES,
	GET_TABLE_FOREIGN_CURRENCIES_SUCCESS,
	GET_TABLE_FUNDS_ACCOUNTS_CAT,
	GET_TABLE_FUNDS_ACCOUNTS_CAT_SUCCESS,
	GET_TABLE_FUNDS_CONCEPTS_TYPE,
	GET_TABLE_FUNDS_CONCEPTS_TYPE_SUCCESS,
	GET_TABLE_FUND_ACCOUNT_ATTR,
	GET_TABLE_FUND_ACCOUNT_ATTR_LIST,
	GET_TABLE_FUND_ACCOUNT_ATTR_LIST_SUCCESS,
	GET_TABLE_FUND_ACCOUNT_ATTR_SUCCESS,
	GET_TABLE_GENERATION_TYPE,
	GET_TABLE_GENERATION_TYPE_SUCCESS,
	GET_TABLE_IMPUTATION_TYPE,
	GET_TABLE_IMPUTATION_TYPE_SUCCESS,
	GET_TABLE_ITEM_CAT,
	GET_TABLE_ITEM_CAT_SUCCESS,
	GET_TABLE_LEDGER_ACCOUNTS,
	GET_TABLE_LEDGER_ACCOUNTS_CATEGORY,
	GET_TABLE_LEDGER_ACCOUNTS_CATEGORY_SUCCESS,
	GET_TABLE_LEDGER_ACCOUNTS_SUCCESS,
	GET_TABLE_MASKS,
	GET_TABLE_MASKS_SUCCESS,
	GET_TABLE_MEASUREMENT_UNIT,
	GET_TABLE_MEASUREMENT_UNIT_SUCCESS,
	GET_TABLE_MESSAGE,
	GET_TABLE_MESSAGE_SUCCESS,
	GET_TABLE_NUMERATORS,
	GET_TABLE_NUMERATORS_SUCCESS,
	GET_TABLE_PARENT_COST_CENTER,
	GET_TABLE_PARENT_COST_CENTER_SUCCESS,
	GET_TABLE_PARENT_LEDGER_ACCOUNT,
	GET_TABLE_PARENT_LEDGER_ACCOUNT_SUCCESS,
	GET_TABLE_PAYMENT_CONDITIONS,
	GET_TABLE_PAYMENT_CONDITIONS_SUCCESS,
	GET_TABLE_PERMISSION_TYPE,
	GET_TABLE_PERMISSION_TYPE_SUCCESS,
	GET_TABLE_PRICES,
	GET_TABLE_PRICES_SUCCESS,
	GET_TABLE_PRICE_LIST,
	GET_TABLE_PRICE_LIST_SUCCESS,
	GET_TABLE_PROCESS,
	GET_TABLE_PROCESS_SUCCESS,
	GET_TABLE_PRODUCTS,
	GET_TABLE_PRODUCTS_SUCCESS,
	GET_TABLE_PRODUCT_ATTR,
	GET_TABLE_PRODUCT_ATTR_LIST,
	GET_TABLE_PRODUCT_ATTR_LIST_SUCCESS,
	GET_TABLE_PRODUCT_ATTR_SUCCESS,
	GET_TABLE_PRODUCT_CAT,
	GET_TABLE_PRODUCT_CAT_SUCCESS,
	GET_TABLE_PROVIDER_ATTR,
	GET_TABLE_PROVIDER_ATTR_LIST,
	GET_TABLE_PROVIDER_ATTR_LIST_SUCCESS,
	GET_TABLE_PROVIDER_ATTR_SUCCESS,
	GET_TABLE_PROVIDER_CAT,
	GET_TABLE_PROVIDER_CAT_SUCCESS,
	GET_TABLE_PROVINCE,
	GET_TABLE_PROVINCE_SUCCESS,
	GET_TABLE_RESPONSIBLE_TYPE,
	GET_TABLE_RESPONSIBLE_TYPE_SUCCESS,
	GET_TABLE_SELLERS,
	GET_TABLE_SELLERS_SUCCESS,
	GET_TABLE_SYMBOLS,
	GET_TABLE_SYMBOLS_SUCCESS,
	GET_TABLE_TABLE,
	GET_TABLE_TABLE_SUCCESS,
	GET_TABLE_TABLE_TYPE,
	GET_TABLE_TABLE_TYPE_SUCCESS,
	GET_TABLE_TAXES,
	GET_TABLE_TAXES_RATE,
	GET_TABLE_TAXES_RATE_SUCCESS,
	GET_TABLE_TAXES_SUCCESS,
	GET_TABLE_TERMSOFSALES,
	GET_TABLE_TERMSOFSALES_SUCCESS,
	GET_TABLE_TES_STATES,
	GET_TABLE_TES_STATES_SUCCESS,
	GET_TABLE_TITLES,
	GET_TABLE_TITLES_SUCCESS,
	GET_TABLE_USUAL_COIN,
	GET_TABLE_USUAL_COIN_SUCCESS,
	GET_TABLE_VALIDATIONS,
	GET_TABLE_VALIDATIONS_SUCCESS,
	GET_TABLE_VALIDATION_TYPE,
	GET_TABLE_VALIDATION_TYPE_SUCCESS,
	GET_TABLE_VALIDATION_TYPE_T,
	GET_TABLE_VALIDATION_TYPE_T_SUCCESS,
	GET_TABLE_VALIDATION_VALUES,
	GET_TABLE_VALIDATION_VALUES_SUCCESS,
	GET_TABLE_VOUCHER_FIELDS,
	GET_TABLE_VOUCHER_FIELDS_SUCCESS,
	GET_TABLE_VOUCHER_MODULE,
	GET_TABLE_VOUCHER_MODULE_SUCCESS,
	GET_TABLE_VOUCHER_PROCESS,
	GET_TABLE_VOUCHER_PROCESS_SUCCESS,
	GET_TABLE_VOUCHER_TYPE_ABM,
	GET_TABLE_VOUCHER_TYPE_ABM_SUCCESS
} from 'constants/ActionsTypes'

const initialState = {
	commissionType: {},
	message: {},
	table: {},
	tableType: {},
	measurementUnit: {},
	alignmentType: {},
	dataType: {},
	validationType: {},
	validationTypeT: {},
	responsibleType: {},
	documentType: {},
	province: {},
	country: {},
	couriers: {},
	termsOfSales: {},
	priceList: {},
	sellers: {},
	foreignCurrencies: {},
	conceptsBackgrounds: {},
	branchOffices: {},
	generationType: {},
	voucherType: {},
	paramsSearch: {},
	credit: {},
	postalCode: {},
	customerCategory: {},
	paymentConditions: {},
	providerCategory: {},
	providerAttributes: {},
	providerAttributesList: {},
	customerBranches: {},
	fundsConceptsType: {},
	imputationType: {},
	ledgerAccountsCategory: {},
	parentLedgerAccount: {},
	costCentersCategory: {},
	CCImputationType: {},
	parentCostCenter: {},
	directLAAssignationType: {},
	directLAAssignationStatus: {},
	directLAAssignationOrder: {},
	ledgerAccounts: {},
	directCCAssignationType: {},
	costCenters: {},
	productCategory: {},
	barcodeFormat: {},
	costAccountingType: {},
	numerators: {},
	itemCategory: {},
	CUF: {},
	products: {},
	filterTransport: {},
	filterCondVta: {},
	sortDirCbtOptions: {},
	optionsFilterAffected: {},
	pricesList: {},
	depots: {},
	AVPurchases: {},
	AVSales: {},
	AVAccounting: {},
	AVStock: {},
	AVFunds: {},
	voucherModules: {},
	voucherProcess: {},
	voucherProcessData: {},
	voucherFields: {},
	titles: {},
	masks: {},
	validations: {},
	validationValues: {},
	cufData: {},
	bonifications: {},
	bonifRates: {},
	usualCoin: {},
	permissionType: {},
	product: {},
	estimationType: {}
}

export interface valueState {
	commissionType: any
	message?: any
	table?: any
	tableType?: any
	measurementUnit?: any
	alignmentType?: any
	dataType?: any
	validationType?: any
	validationTypeT?: any
	responsibleType?: any
	documentType?: any
	province?: any
	country?: any
	couriers?: any
	termsOfSales?: any
	priceList?: any
	sellers?: any
	foreignCurrencies?: any
	conceptsBackgrounds?: any
	branchOffices?: any
	generationType?: any
	voucherType?: any
	postalCode?: any
	customerCategory?: any
	paymentConditions?: any
	providerCategory?: any
	providerAttributes?: any
	providerAttributesList?: any
	customerBranches?: any
	fundsConceptsType?: any
	imputationType?: any
	ledgerAccountsCategory?: any
	parentLedgerAccount?: any
	costCentersCategory?: any
	CCImputationType?: any
	parentCostCenter?: any
	directLAAssignationType?: any
	directLAAssignationStatus?: any
	directLAAssignationOrder?: any
	ledgerAccounts?: any
	directCCAssignationType?: any
	costCenters?: any
	productCategory?: any
	barcodeFormat?: any
	costAccountingType?: any
	numerators?: any
	itemCategory?: any
	CUF?: any
	products?: any
	filterTransport?: any
	filterCondSale?: any
	sortDirCbtOptions?: any
	optionsFilterAffected?: any
	pricesList?: any
	depots?: any
	AVPurchases?: any
	AVSales?: any
	AVStock?: any
	AVAccounting?: any
	AVFunds?: any
	voucherModules?: any
	voucherProcess?: any
	voucherProcessData?: any
	voucherFields?: any
	titles?: any
	masks?: any
	validations?: any
	validationValues?: any
	cufData?: any
	bonifications?: any
	bonifRates?: any
	usualCoin?: any
	currencies?: any
	permissionType?: any
	product?: any
	estimationType?: any
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case GET_TABLE_COMMISSION_TYPE:
			return { ...state, commissionType: null }
		case GET_TABLE_COMMISSION_TYPE_SUCCESS:
			return { ...state, commissionType: action.payload }
		case GET_TABLE_MESSAGE:
			return { ...state, messageList: null }
		case GET_TABLE_MESSAGE_SUCCESS:
			return { ...state, messageList: action.payload }
		case GET_TABLE_TABLE:
			return { ...state, table: null }
		case GET_TABLE_TABLE_SUCCESS:
			return { ...state, table: action.payload }
		case GET_TABLE_TABLE_TYPE:
			return { ...state, tableType: null }
		case GET_TABLE_TABLE_TYPE_SUCCESS:
			return { ...state, tableType: action.payload }
		case GET_TABLE_MEASUREMENT_UNIT:
			return { ...state, measurementUnit: null }
		case GET_TABLE_MEASUREMENT_UNIT_SUCCESS:
			return { ...state, measurementUnit: action.payload }
		case GET_TABLE_ALIGNMENT_TYPE:
			return { ...state, alignmentType: null }
		case GET_TABLE_ALIGNMENT_TYPE_SUCCESS:
			return { ...state, alignmentType: action.payload }
		case GET_TABLE_DATA_TYPE:
			return { ...state, dataType: null }
		case GET_TABLE_DATA_TYPE_SUCCESS:
			return { ...state, dataType: action.payload }
		case GET_TABLE_VALIDATION_TYPE:
			return { ...state, validationType: null }
		case GET_TABLE_VALIDATION_TYPE_SUCCESS:
			return { ...state, validationType: action.payload }
		case GET_TABLE_VALIDATION_TYPE_T:
			return { ...state, validationTypeT: null }
		case GET_TABLE_VALIDATION_TYPE_T_SUCCESS:
			return { ...state, validationTypeT: action.payload }
		case GET_TABLE_RESPONSIBLE_TYPE:
			return { ...state, responsibleType: null }
		case GET_TABLE_RESPONSIBLE_TYPE_SUCCESS:
			return { ...state, responsibleType: action.payload }
		case GET_TABLE_DOCUMENT_TYPE:
			return { ...state, documentType: null }
		case GET_TABLE_DOCUMENT_TYPE_SUCCESS:
			return { ...state, documentType: action.payload }
		case GET_TABLE_PROVINCE:
			return { ...state, province: null }
		case GET_TABLE_PROVINCE_SUCCESS:
			return { ...state, province: action.payload }
		case GET_TABLE_COUNTRY:
			return { ...state, country: null }
		case GET_TABLE_COUNTRY_SUCCESS:
			return { ...state, country: action.payload }
		case GET_TABLE_COURIERS:
			return { ...state, couriers: null }
		case GET_TABLE_COURIERS_SUCCESS:
			return { ...state, couriers: action.payload }
		case GET_TABLE_TERMSOFSALES:
			return { ...state, termsOfSales: null }
		case GET_TABLE_TERMSOFSALES_SUCCESS:
			return { ...state, termsOfSales: action.payload }
		case GET_TABLE_SELLERS:
			return { ...state, sellers: null }
		case GET_TABLE_SELLERS_SUCCESS:
			return { ...state, sellers: action.payload }
		case GET_TABLE_FOREIGN_CURRENCIES:
			return { ...state, foreignCurrencies: null }
		case GET_TABLE_FOREIGN_CURRENCIES_SUCCESS:
			return { ...state, foreignCurrencies: action.payload }
		case GET_TABLE_CONCEPTS_BACKGROUNDS:
			return { ...state, conceptsBackgrounds: null }
		case GET_TABLE_CONCEPTS_BACKGROUNDS_SUCCESS:
			return { ...state, conceptsBackgrounds: action.payload }
		case GET_TABLE_BRANCH_OFFICES:
			return { ...state, branchOffices: null }
		case GET_TABLE_BRANCH_OFFICES_SUCCESS:
			return { ...state, branchOffices: action.payload }
		case GET_TABLE_GENERATION_TYPE:
			return { ...state, generationType: null }
		case GET_TABLE_GENERATION_TYPE_SUCCESS:
			return { ...state, generationType: action.payload }
		case GET_TABLE_VOUCHER_TYPE_ABM:
			return { ...state, voucherType: null }
		case GET_TABLE_VOUCHER_TYPE_ABM_SUCCESS:
			return { ...state, voucherType: action.payload }
		case GET_TABLE_PRICE_LIST:
			return { ...state, priceList: null }
		case GET_TABLE_PRICE_LIST_SUCCESS:
			return { ...state, priceList: action.payload }
		case GET_TABLE_CREDIT:
			return { ...state, credit: null }
		case GET_TABLE_CREDIT_SUCCESS:
			return { ...state, credit: action.payload }
		case GET_TABLE_CUSTOMER_CATEGORY:
			return { ...state, customerCategory: null }
		case GET_TABLE_CUSTOMER_CATEGORY_SUCCESS:
			return { ...state, customerCategory: action.payload }
		case GET_TABLE_PAYMENT_CONDITIONS:
			return { ...state, paymentConditions: null }
		case GET_TABLE_PAYMENT_CONDITIONS_SUCCESS:
			return { ...state, paymentConditions: action.payload }
		case GET_TABLE_PROVIDER_CAT:
			return { ...state, providerCategory: null }
		case GET_TABLE_PROVIDER_CAT_SUCCESS:
			return { ...state, providerCategory: action.payload }
		case GET_TABLE_PROVIDER_ATTR:
			return { ...state, catributoValues: null }
		case GET_TABLE_PROVIDER_ATTR_SUCCESS:
			return { ...state, catributoValues: action.payload }
		case GET_TABLE_PROVIDER_ATTR_LIST:
			return { ...state, catributoListValues: null }
		case GET_TABLE_PROVIDER_ATTR_LIST_SUCCESS:
			return { ...state, catributoListValues: action.payload }
		case GET_TABLE_TAXES:
			return { ...state, taxesListValues: null }
		case GET_TABLE_TAXES_SUCCESS:
			return { ...state, taxesListValues: action.payload }
		case GET_TABLE_TAXES_RATE:
			return { ...state, taxesRateValues: null }
		case GET_TABLE_TAXES_RATE_SUCCESS:
			return { ...state, taxesRateValues: action.payload }
		case GET_TABLE_CUSTOMER_ATTR:
			return { ...state, catributoValues: null }
		case GET_TABLE_CUSTOMER_ATTR_SUCCESS:
			return { ...state, catributoValues: action.payload }
		case GET_TABLE_CUSTOMER_ATTR_LIST:
			return { ...state, catributoListValues: null }
		case GET_TABLE_CUSTOMER_ATTR_LIST_SUCCESS:
			return { ...state, catributoListValues: action.payload }
		case GET_TABLE_CUSTOMER_BRANCHES:
			return { ...state, customerBranchesValues: null }
		case GET_TABLE_CUSTOMER_BRANCHES_SUCCESS:
			return { ...state, customerBranchesValues: action.payload }
		case GET_TABLE_FUNDS_ACCOUNTS_CAT:
			return { ...state, fundsAccountsCategory: null }
		case GET_TABLE_FUNDS_ACCOUNTS_CAT_SUCCESS:
			return { ...state, fundsAccountsCategory: action.payload }
		case GET_TABLE_ITEM_CAT:
			return { ...state, itemCategory: null }
		case GET_TABLE_ITEM_CAT_SUCCESS:
			return { ...state, itemCategory: action.payload }
		case GET_TABLE_NUMERATORS:
			return { ...state, numerators: null }
		case GET_TABLE_NUMERATORS_SUCCESS:
			return { ...state, numerators: action.payload }
		case GET_TABLE_SYMBOLS:
			return { ...state, symbols: null }
		case GET_TABLE_SYMBOLS_SUCCESS:
			return { ...state, symbols: action.payload }
		case GET_TABLE_TES_STATES:
			return { ...state, tesStates: null }
		case GET_TABLE_TES_STATES_SUCCESS:
			return { ...state, tesStates: action.payload }
		case GET_TABLE_FUND_ACCOUNT_ATTR:
			return { ...state, catributoValues: null }
		case GET_TABLE_FUND_ACCOUNT_ATTR_SUCCESS:
			return { ...state, catributoValues: action.payload }
		case GET_TABLE_FUND_ACCOUNT_ATTR_LIST:
			return { ...state, catributoListValues: null }
		case GET_TABLE_FUND_ACCOUNT_ATTR_LIST_SUCCESS:
			return { ...state, catributoListValues: action.payload }
		case GET_TABLE_FUNDS_CONCEPTS_TYPE:
			return { ...state, fundsConceptsType: null }
		case GET_TABLE_FUNDS_CONCEPTS_TYPE_SUCCESS:
			return { ...state, fundsConceptsType: action.payload }
		case GET_TABLE_IMPUTATION_TYPE:
			return { ...state, imputationType: null }
		case GET_TABLE_IMPUTATION_TYPE_SUCCESS:
			return { ...state, imputationType: action.payload }
		case GET_TABLE_LEDGER_ACCOUNTS_CATEGORY:
			return { ...state, ledgerAccountsCategory: null }
		case GET_TABLE_LEDGER_ACCOUNTS_CATEGORY_SUCCESS:
			return { ...state, ledgerAccountsCategory: action.payload }
		case GET_TABLE_PARENT_LEDGER_ACCOUNT:
			return { ...state, parentLedgerAccount: null }
		case GET_TABLE_PARENT_LEDGER_ACCOUNT_SUCCESS:
			return { ...state, parentLedgerAccount: action.payload }
		case GET_TABLE_COST_CENTERS_CATEGORY:
			return { ...state, costCentersCategory: null }
		case GET_TABLE_COST_CENTERS_CATEGORY_SUCCESS:
			return { ...state, costCentersCategory: action.payload }
		case GET_TABLE_CC_IMPUTATION_TYPE:
			return { ...state, CCImputationType: null }
		case GET_TABLE_CC_IMPUTATION_TYPE_SUCCESS:
			return { ...state, CCImputationType: action.payload }
		case GET_TABLE_PARENT_COST_CENTER:
			return { ...state, parentCostCenter: null }
		case GET_TABLE_PARENT_COST_CENTER_SUCCESS:
			return { ...state, parentCostCenter: action.payload }
		case GET_FILTER_LA_ASSIGN_TYPE:
			return { ...state, directLAAssignationType: null }
		case GET_FILTER_LA_ASSIGN_TYPE_SUCCESS:
			return { ...state, directLAAssignationType: action.payload }
		case GET_FILTER_LA_ASSIGN_STATUS:
			return { ...state, directLAAssignationStatus: null }
		case GET_FILTER_LA_ASSIGN_STATUS_SUCCESS:
			return { ...state, directLAAssignationStatus: action.payload }
		case GET_FILTER_LA_ASSIGN_ORDER:
			return { ...state, directLAAssignationOrder: null }
		case GET_FILTER_LA_ASSIGN_ORDER_SUCCESS:
			return { ...state, directLAAssignationOrder: action.payload }
		case GET_TABLE_LEDGER_ACCOUNTS:
			return { ...state, ledgerAccounts: null }
		case GET_TABLE_LEDGER_ACCOUNTS_SUCCESS:
			return { ...state, ledgerAccounts: action.payload }
		case GET_TABLE_COST_CENTERS:
			return { ...state, costCenters: null }
		case GET_FILTER_CC_ASSIGN_TYPE:
			return { ...state, directCCAssignationType: null }
		case GET_FILTER_CC_ASSIGN_TYPE_SUCCESS:
			return { ...state, directCCAssignationType: action.payload }
		case GET_TABLE_COST_CENTERS_SUCCESS:
			return { ...state, costCenters: action.payload }
		case GET_TABLE_PRODUCT_CAT:
			return { ...state, productCategory: null }
		case GET_TABLE_PRODUCT_CAT_SUCCESS:
			return { ...state, productCategory: action.payload }
		case GET_TABLE_BARCODE_FORMAT:
			return { ...state, barcodeFormat: null }
		case GET_TABLE_BARCODE_FORMAT_SUCCESS:
			return { ...state, barcodeFormat: action.payload }
		case GET_TABLE_COST_ACCOUNTING_TYPE:
			return { ...state, costAccountingType: null }
		case GET_TABLE_COST_ACCOUNTING_TYPE_SUCCESS:
			return { ...state, costAccountingType: action.payload }
		case GET_TABLE_PRODUCT_ATTR:
			return { ...state, catributoValues: null }
		case GET_TABLE_PRODUCT_ATTR_SUCCESS:
			return { ...state, catributoValues: action.payload }
		case GET_TABLE_PRODUCT_ATTR_LIST:
			return { ...state, catributoListValues: null }
		case GET_TABLE_PRODUCT_ATTR_LIST_SUCCESS:
			return { ...state, catributoListValues: action.payload }
		case GET_TABLE_CUF:
			return { ...state, CUF: null }
		case GET_TABLE_CUF_SUCCESS:
			return { ...state, CUF: action.payload }
		case GET_TABLE_PRODUCTS:
			return { ...state, products: null }
		case GET_TABLE_PRODUCTS_SUCCESS:
			return { ...state, products: action.payload }
		case GET_FILTER_TRANSPORT:
			return { ...state, filterTransport: null }
		case GET_FILTER_TRANSPORT_SUCCESS:
			return { ...state, filterTransport: action.payload.lista }
		case GET_FILTER_CONDITION_SALE:
			return { ...state, filterCondSale: null }
		case GET_FILTER_CONDITION_SALE_SUCCESS:
			return { ...state, filterCondSale: action.payload.lista }
		case GET_ORDER_CBT_CAB:
			return { ...state, sortDirCbtOptions: null }
		case GET_ORDER_CBT_CAB_SUCCESS:
			return { ...state, sortDirCbtOptions: action.payload.lista }
		case GET_OPTIONS_FILTER_AFFECTED:
			return { ...state, optionsFilterAffected: null }
		case GET_OPTIONS_FILTER_AFFECTED_SUCCESS:
			return { ...state, optionsFilterAffected: action.payload.lista }
		case GET_TABLE_PRICES:
			return { ...state, pricesList: null }
		case GET_TABLE_PRICES_SUCCESS:
			return { ...state, pricesList: action.payload }
		case GET_TABLE_DEPOTS:
			return { ...state, depots: null }
		case GET_TABLE_DEPOTS_SUCCESS:
			return { ...state, depots: action.payload }
		case GET_TABLE_AV_PURCHASES:
			return { ...state, AVPurchases: null }
		case GET_TABLE_AV_PURCHASES_SUCCESS:
			return { ...state, AVPurchases: action.payload }
		case GET_TABLE_AV_SALES:
			return { ...state, AVSales: null }
		case GET_TABLE_AV_SALES_SUCCESS:
			return { ...state, AVSales: action.payload }
		case GET_TABLE_AV_STOCK:
			return { ...state, AVStock: null }
		case GET_TABLE_AV_STOCK_SUCCESS:
			return { ...state, AVStock: action.payload }
		case GET_TABLE_AV_FUNDS:
			return { ...state, AVFunds: null }
		case GET_TABLE_AV_FUNDS_SUCCESS:
			return { ...state, AVFunds: action.payload }
		case GET_TABLE_AV_ACCOUNTING:
			return { ...state, AVAccounting: null }
		case GET_TABLE_AV_ACCOUNTING_SUCCESS:
			return { ...state, AVAccounting: action.payload }
		case GET_TABLE_VOUCHER_MODULE:
			return { ...state, voucherModules: null }
		case GET_TABLE_VOUCHER_MODULE_SUCCESS:
			return { ...state, voucherModules: action.payload }
		case GET_TABLE_PROCESS:
			return { ...state, voucherProcess: null }
		case GET_TABLE_PROCESS_SUCCESS:
			return { ...state, voucherProcess: action.payload }
		case GET_TABLE_VOUCHER_PROCESS:
			return { ...state, voucherProcessData: null }
		case GET_TABLE_VOUCHER_PROCESS_SUCCESS:
			return { ...state, voucherProcessData: action.payload }
		case GET_TABLE_VOUCHER_FIELDS:
			return { ...state, voucherFields: null }
		case GET_TABLE_VOUCHER_FIELDS_SUCCESS:
			return { ...state, voucherFields: action.payload }
		case GET_TABLE_TITLES:
			return { ...state, titles: null }
		case GET_TABLE_TITLES_SUCCESS:
			return { ...state, titles: action.payload }
		case GET_TABLE_MASKS:
			return { ...state, masks: null }
		case GET_TABLE_MASKS_SUCCESS:
			return { ...state, masks: action.payload }
		case GET_TABLE_VALIDATIONS:
			return { ...state, validations: null }
		case GET_TABLE_VALIDATIONS_SUCCESS:
			return { ...state, validations: action.payload }
		case GET_TABLE_VALIDATION_VALUES:
			return { ...state, validationValues: null }
		case GET_TABLE_VALIDATION_VALUES_SUCCESS:
			return { ...state, validationValues: action.payload }
		case GET_TABLE_CUF_DATA:
			return { ...state, cufData: null }
		case GET_TABLE_CUF_DATA_SUCCESS:
			return { ...state, cufData: action.payload }
		case GET_TABLE_BONIFICATIONS:
			return { ...state, bonifications: null }
		case GET_TABLE_BONIFICATIONS_SUCCESS:
			return { ...state, bonifications: action.payload }
		case GET_TABLE_BONIF_RATES:
			return { ...state, bonifRates: null }
		case GET_TABLE_BONIF_RATES_SUCCESS:
			return { ...state, bonifRates: action.payload }
		case GET_TABLE_USUAL_COIN:
			return { ...state, usualCoin: null }
		case GET_TABLE_USUAL_COIN_SUCCESS:
			return { ...state, usualCoin: action.payload }
		case GET_TABLE_CURRENCIES:
			return { ...state, currencies: null }
		case GET_TABLE_CURRENCIES_SUCCESS:
			return { ...state, currencies: action.payload }
		case GET_TABLE_PERMISSION_TYPE:
			return { ...state, permissionType: null }
		case GET_TABLE_PERMISSION_TYPE_SUCCESS:
			return { ...state, permissionType: action.payload }
		case GET_TABLE_ABM_TABS:
			return { ...state, abmTabs: null }
		case GET_TABLE_ABM_TABS_SUCCESS:
			return { ...state, abmTabs: action.payload }
		case GET_FILTER_PRODUCT:
			return { ...state, product: null }
		case GET_FILTER_PRODUCT_SUCCESS:
			return { ...state, product: action.payload }
		case CLEAR_VALIDATION_TABLE_DATA:
			return { ...state, validationValues: null }
		case GET_TABLE_ESTIMATION_TYPE:
			return { ...state, estimationType: null }
		case GET_TABLE_ESTIMATION_TYPE_SUCCESS:
			return { ...state, estimationType: action.payload }
		default:
			return state
	}
}

export default rootReducer
