import {
	confirmTreasuryAttributesSuccess,
	getAttributesTreasuryHeaderSuccess,
	getTreasuryCurrenciesSuccess
} from 'actions'
import {
	confirmTreasuryAttributesApi,
	getAttributesTreasuryHeaderApi,
	getTreasuryCurrenciesApi
} from 'api/TreasuryHeader'
import { TreasuryHeaderActions } from 'constants/ActionsTypesTs'
import { IResponseResult } from 'models/ResultsInterface'
import {
	IConfirmTreasuryAttributesAction,
	IGetAttributesTreasuryHeaderAction,
	IGetTreasuryCurrenciesAction
} from 'models/TreasuryHeader'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

function* getAttributesTreasuryHeaderRequest({
	payload
}: IGetAttributesTreasuryHeaderAction): any {
	try {
		const config: any = yield call(getAttributesTreasuryHeaderApi, payload)
		yield put(getAttributesTreasuryHeaderSuccess(config))
	} catch (error) {}
}

function* getTreasuryCurrenciesRequest({
	payload
}: IGetTreasuryCurrenciesAction): any {
	try {
		const currencies: any = yield call(getTreasuryCurrenciesApi, payload)
		yield put(getTreasuryCurrenciesSuccess(currencies))
	} catch (error) {}
}

function* confirmTreasuryAttributesRequest({
	payload
}: IConfirmTreasuryAttributesAction): any {
	try {
		const { callBackReturn, params } = payload
		const attributes: IResponseResult = yield call(
			confirmTreasuryAttributesApi,
			params
		)
		yield put(confirmTreasuryAttributesSuccess(attributes))

		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error: any) {}
}

export function* getAttributesTreasuryHeaderSaga() {
	yield takeLatest(
		TreasuryHeaderActions.GET_ATTRIBUTES_TREASURY_HEADER,
		getAttributesTreasuryHeaderRequest
	)
}

export function* confirmTreasuryAttributesSaga() {
	yield takeLatest(
		TreasuryHeaderActions.CONFIRM_ATTRIBUTES_TREASURY_HEADER,
		confirmTreasuryAttributesRequest
	)
}

export function* getTreasuryCurrenciesSaga() {
	yield takeLatest(
		TreasuryHeaderActions.GET_TREASURY_CURRENCIES,
		getTreasuryCurrenciesRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(getTreasuryCurrenciesSaga),
		fork(getAttributesTreasuryHeaderSaga),
		fork(confirmTreasuryAttributesSaga)
	])
}
