import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { FormikProps } from 'formik'
import { getOptionsDropDown, handleSetValueInput } from 'lib/FormUtils'
import { IGetTableBranchOfficesResArray } from 'models/TableCalls/TableCallsInterface'
import moment from 'moment'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
	ICurrencyListStore,
	IFormAttributesStore
} from 'reducers/TreasuryHeader'
import _ from 'underscore'

interface IHeaderEntryFormInputsProps extends WithTranslation {
	t: any
	fields: Array<IFieldStructure>
	formikProps: FormikProps<any>
	branchOffices: IGetTableBranchOfficesResArray
	currencyList: ICurrencyListStore
	formAttributes: IFormAttributesStore
}

interface IHeaderEntryFormInputsState {}

class HeaderEntryFormInputs extends Component<
	IHeaderEntryFormInputsProps,
	IHeaderEntryFormInputsState
> {
	componentDidUpdate(prevProps: Readonly<IHeaderEntryFormInputsProps>): void {
		const { formAttributes, formikProps } = this.props
		if (
			!_.isEqual(prevProps.formAttributes, formAttributes) &&
			formAttributes.status === 'succeeded' &&
			!_.isEmpty(formAttributes.data)
		) {
			const { data } = formAttributes

			const date = data?.fecha
				? moment(new Date(`${data.fecha}T00:00:00`))
				: new Date()

			formikProps.setFieldValue('titulo_comp_fon', data?.titulo_comp_fon)
			formikProps.setFieldValue('fecha', date)
			formikProps.setFieldValue('descrip', data?.descrip)
		}
	}

	/**
	 * to handle the change of currency values
	 * @param env
	 */
	handleChangeCurrency = (env: any) => {
		const { formikProps, currencyList } = this.props
		const { value } = env.target
		const { data } = currencyList

		const quotation = _.findWhere(data?.lista || [], { codigo: value })
		handleSetValueInput(env, 'mon_comp_fon', formikProps)
		formikProps.setFieldValue('cotiz_comp_fon', quotation?.cotiz)
	}

	render() {
		const { fields, formikProps, t, branchOffices, currencyList } = this.props
		const { values, handleBlur } = formikProps

		const descriptionProps = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'Descripcion',
			inputId: 'titulo_comp_fon',
			name: 'titulo_comp_fon',
			placeholder: '',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			onBlur: handleBlur,
			value: values.titulo_comp_fon,
			onChange: (data: any) =>
				handleSetValueInput(data, 'titulo_comp_fon', formikProps),
			autoComplete: 'Off'
		}

		const propsDate = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Fecha',
			inputId: 'fecha',
			name: 'fecha',
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			onBlur: handleBlur,
			value: values.fecha,
			onChange: (data: any) =>
				handleSetValueInput(data, 'fecha', formikProps, 'date')
		}

		const propsCurrency = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.module'),
			inputId: 'mon_comp_fon',
			name: 'mon_comp_fon',
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			onBlur: handleBlur,
			value: values.mon_comp_fon,
			options:
				currencyList.status === 'succeeded'
					? getOptionsDropDown(currencyList.data?.lista)
					: [],
			onChange: this.handleChangeCurrency
		}

		const quotationProps = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Cotizacion',
			inputId: 'cotiz_comp_fon',
			name: 'cotiz_comp_fon',
			placeholder: t('global.quotation'),
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.cotiz_comp_fon,
			autoComplete: 'off',
			onChange: (data: any) => {
				const value = data || ''
				handleSetValueInput(value, 'cotiz_comp_fon', formikProps)
			}
		}

		const branchesProps = {
			inputFormCol: { sm: 12 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'suc_empresa_fon',
			name: 'suc_empresa_fon',
			placeholder: '',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			onBlur: handleBlur,
			value: values.suc_empresa_fon,
			options: getOptionsDropDown(branchOffices?.lista),
			onChange: (data: any) =>
				handleSetValueInput(data, 'suc_empresa_fon', formikProps)
		}

		return (
			<Row>
				<Row className={`col-12`}>
					<InputDropdown {...branchesProps} />
				</Row>
				<Row className={`col-12`}>
					<InputText {...descriptionProps} />
				</Row>
				<Row className={`col-12`}>
					<InputText {...propsDate} />
				</Row>
				<Row className={`col-12`}>
					<InputDropdown {...propsCurrency} />
					<InputText {...quotationProps} />
				</Row>
			</Row>
		)
	}
}

const mapStateToProps = ({ tableCalls, treasuryHeader }: any) => {
	const { branchOffices } = tableCalls
	const { currencyList, formAttributes } = treasuryHeader

	return { formAttributes, branchOffices, currencyList }
}

const mapDispatchToProps = {}

const connectForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(HeaderEntryFormInputs)

export default withTranslation()(connectForm)
