import {
	faCheck,
	faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	getConfigVoucher,
	newVoucherType,
	showMessage,
	voucherSaveAndNew
} from 'actions'
import InputButton from 'components/form/inputButton'
import {
	P_CCPRINT,
	P_FINCOMPROB,
	P_PRINT_VOUCHER
} from 'constants/ConfigProcessNames'
import React, { Fragment, PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ConfirmModal from './ConfirmModal'
import stepStyles from './steps.module.css'

const firstForms = ['voucher', 'provider']
class Steps extends PureComponent {
	handleCallback = (url) => {
		const { callBackButton } = this.props
		if (callBackButton) {
			callBackButton(url)
		}
	}

	componentDidUpdate = (prevProps) => {
		const { voucherConfirmation, backButton, config, showBreadCrumbs } =
			this.props
		if (backButton) {
			const [, form, idOperacion] = backButton.url.split('/')
			if (firstForms.includes(form) && showBreadCrumbs) {
				// Only execute when back to the first page and bread top.
				if (
					voucherConfirmation !== prevProps.voucherConfirmation &&
					voucherConfirmation &&
					voucherConfirmation.NiMovimiento
				) {
					this.props.getConfigVoucher({ idOperacion })
				}
				if (config !== prevProps.config && config) {
					this.props.newVoucherType({
						idComprobante: config.idComprobante,
						idEmpresa: config.idEmpresa,
						history: this.props.history
					})
				}
			}
		}
	}

	renderSteps = () => {
		const { steps, theme, nextButton, backButton, completed, t } = this.props
		const rows = []

		if (backButton) {
			const [, form] = backButton.url.split('/')
			const initForm = firstForms.includes(form)

			if (initForm) {
				rows.push(
					<Col style={{ textAlign: 'left' }} className={'col-sm'} key={101}>
						<ConfirmModal
							backButton
							modalTitle={t('global.warning')}
							iconTitle={
								<FontAwesomeIcon
									icon={faExclamationTriangle}
									color={'#ffc107'}
									style={{ paddingLeft: '8px' }}
								/>
							}
							messageBody={t('global.warning_back_message')}
							onSubmitModal={() => this.props.handlePreviousPage()}
						/>
					</Col>
				)
			} else {
				const buttonOptions = {
					backButton,
					urlForm: backButton.url,
					onClick: () => this.props.handlePreviousPage()
				}

				rows.push(
					<Col
						style={{ textAlign: 'left' }}
						className={'col-sm pl-3'}
						key={101}
					>
						<InputButton {...buttonOptions} />
					</Col>
				)
			}
		}

		const tmp = []

		steps.forEach((step, index) => {
			const classBadge =
				step.main || step.before ? 'badge-primary' : 'badge-secondary'
			const badgeNumber =
				step.before || completed ? (
					<FontAwesomeIcon icon={faCheck} />
				) : (
					index + 1
				)
			const divider =
				index + 1 === steps.length ? null : steps[index + 1] &&
				  steps[index + 1].cod_proceso !== P_PRINT_VOUCHER ? (
					<span
						style={{ borderColor: '#2F80ED', marginTop: '18px' }}
						className={'dropdown-divider col'}
					/>
				) : null

			if (step.cod_proceso !== P_PRINT_VOUCHER) {
				rows.push(
					<Fragment key={index + 1}>
						<Col className={'col-sm pt-2 text-center'}>
							<span className={`badge ${classBadge} ${theme.step} `}>
								{badgeNumber}
							</span>{' '}
							<p className={theme.title}> {step.label} </p>
						</Col>
						{divider}
					</Fragment>
				)
			}
		})

		const result = rows.concat(tmp)

		if (nextButton) {
			result.push(
				<Col
					style={{ textAlign: 'right' }}
					className={'col-sm pr-3'}
					key={result.length * 5}
				>
					<InputButton
						nextButton
						urlForm={nextButton.url}
						type={this.props.buttonsType}
						onClick={() => this.handleCallback(nextButton.url)}
					/>
				</Col>
			)
		}

		return result
	}

	render() {
		const { steps, theme } = this.props

		return (
			<Col sm={12}>
				<Row className={`${theme.containerBody} mt-4 mb-3 `}>
					{steps && this.renderSteps()}
				</Row>
			</Col>
		)
	}
}

const mapStateToProps = ({ vouchertype, voucher }) => {
	const { voucherConfirmation } = vouchertype
	const config = voucher.config
		? voucher.config[P_FINCOMPROB] || voucher.config[P_CCPRINT]
		: null
	return { voucherConfirmation, config }
}

const connectForm = connect(mapStateToProps, {
	voucherSaveAndNew,
	getConfigVoucher,
	newVoucherType,
	showMessage
})(withRouter(Steps))

export default themr(
	'stylesStepsGlobal',
	stepStyles
)(withTranslation()(connectForm))
