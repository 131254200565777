import {
	getDetailStock,
	getOrderDirOptions,
	getStockOrderOptions
} from 'actions'
import CommonLabelCol from 'components/common/CommonLabelCol'
import CommonTable from 'components/common/commonTable'
import InputCheckBox from 'components/form/InputCheckBox'
import InputDropdown from 'components/form/inputDropdown'
import { getOptionsDropDown } from 'lib/FormUtils'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'

class DetailStockData extends Component {
	constructor(props) {
		super(props)
		this.state = {
			sortValue: '',
			sortDirValue: '',
			groupCuf: 0,
			groupBatch: 0,
			groupDate: 0,
			onlyAvailable: 0
		}
	}

	componentDidMount = () => {
		this.props.getStockOrderOptions()
		this.props.getOrderDirOptions()
		this.handleGetStock()
	}

	/**
	 * to search all data stock
	 * @param {*} filters
	 */
	handleGetStock = (filters) => {
		const { productId, authUser } = this.props
		const {
			sortValue,
			sortDirValue,
			groupBatch,
			groupCuf,
			groupDate,
			onlyAvailable
		} = this.state
		this.props.getDetailStock({
			id: productId,
			idEmpresa: authUser.userCompany,
			orden: sortValue,
			dir_orden: sortDirValue,
			agrupa_fec_ing: groupDate,
			agrupa_cuf: groupCuf,
			agrupa_partida: groupBatch,
			filtro_disponible: onlyAvailable,
			...filters
		})
	}

	/**
	 * to render las columns
	 * @returns
	 */
	getColumns = (fields) => {
		const rows = fields.map((field) => {
			const idField = field.idCampo.trim()
			return {
				dataField: idField,
				text: field.label || '',
				align: 'center',
				headerAlign: 'center',
				// headerStyle: this.getStyleColumn(idField),
				hidden: !field.visible
				// formatter: (cell) => this.formatterRow(cell, idField)
			}
		})

		return rows
	}

	render() {
		const { fields, fieldsTable, detailStock, stockOptions, sortDirOptions } =
			this.props
		const {
			sortValue,
			sortDirValue,
			groupBatch,
			groupCuf,
			groupDate,
			onlyAvailable
		} = this.state

		return (
			<Fragment>
				<Row>
					<InputDropdown
						inputFormCol={{ sm: 6 }}
						fields={fields}
						label="t"
						inputId="orden"
						name="orden"
						placeholder=""
						colLabel="col-sm-3"
						colInput="col-sm-6"
						disable={false}
						// onBlur= handleBlur
						value={sortValue}
						options={getOptionsDropDown(stockOptions)}
						noInitialExecute={true}
						onChange={(data) => {
							const { value } = data.target
							this.setState({ sortValue: value })
							this.handleGetStock({ orden: value })
						}}
					/>
					<InputDropdown
						inputFormCol={{ sm: 6 }}
						fields={fields}
						label="t"
						inputId="dir_orden"
						name="dir_orden"
						placeholder=""
						colLabel="col-sm-3"
						colInput="col-sm-6"
						disable={false}
						// onBlur= handleBlur
						value={sortDirValue}
						options={getOptionsDropDown(sortDirOptions)}
						noInitialExecute={true}
						onChange={(data) => {
							const { value } = data.target
							this.setState({ sortDirValue: value })
							this.handleGetStock({ dir_orden: value })
						}}
					/>
				</Row>
				<Row>
					<InputCheckBox
						inputFormCol={{ sm: 4 }}
						fields={fields}
						label="t"
						inputId="grp_cuf"
						name="grp_cuf"
						placeholder=""
						colLabel="col-sm-6"
						colInput="col-sm-6"
						disable={false}
						value={groupCuf}
						onChange={(data) => {
							this.setState({ groupCuf: Number(data) })
							this.handleGetStock({ agrupa_cuf: Number(data) })
						}}
					/>
					<InputCheckBox
						inputFormCol={{ sm: 4 }}
						fields={fields}
						label="t"
						inputId="grp_part"
						name="grp_part"
						placeholder=""
						colLabel="col-sm-6"
						colInput="col-sm-6"
						disable={false}
						value={groupBatch}
						onChange={(data) => {
							this.setState({ groupBatch: Number(data) })
							this.handleGetStock({ agrupa_partida: Number(data) })
						}}
					/>
					<InputCheckBox
						inputFormCol={{ sm: 4 }}
						fields={fields}
						label="t"
						inputId="grp_fec"
						name="grp_fec"
						placeholder=""
						colLabel="col-sm-6"
						colInput="col-sm-6"
						disable={false}
						value={groupDate}
						onChange={(data) => {
							this.setState({ groupDate: Number(data) })
							this.handleGetStock({ agrupa_fec_ing: Number(data) })
						}}
					/>
				</Row>
				<Row>
					<InputCheckBox
						inputFormCol={{ sm: 4 }}
						fields={fields}
						label="t"
						inputId="disp"
						name="disp"
						placeholder=""
						colLabel="col-sm-6"
						colInput="col-sm-6"
						disable={false}
						value={onlyAvailable}
						onChange={(data) => {
							this.setState({ onlyAvailable: Number(data) })
							this.handleGetStock({ filtro_disponible: Number(data) })
						}}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="tot_disp_suc"
						value={`${detailStock.tot_disp_suc}` || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="tot_suc"
						value={`${detailStock.tot_suc}` || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 3 }}
						colLabel={{ sm: 6 }}
						fields={fields}
						labelId="disp_tot"
						value={`${detailStock.disp_tot}` || ''}
					/>
				</Row>
				<Row>
					<Col sm={12}>
						{!_.isEmpty(fieldsTable) && (
							<CommonTable
								remote
								keyField="cod_valor"
								columns={this.getColumns(fieldsTable)}
								data={
									!_.isEmpty(detailStock) && detailStock.Items
										? detailStock.Items
										: []
								}
								paginationOptions={{}}
								onTableChange={this.handleChangeTable}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth, searchProducts, searchVoucher }) => {
	const { authUser } = auth
	const { detailStock, stockOptions } = searchProducts
	const { sortDirOptions } = searchVoucher
	return {
		authUser,
		detailStock,
		stockOptions,
		sortDirOptions
	}
}
const connectForm = connect(mapStateToProps, {
	getDetailStock,
	getStockOrderOptions,
	getOrderDirOptions
})(DetailStockData)

export default withTranslation()(connectForm)
