import { HeaderEntryActions } from 'constants/ActionsTypesTs'
import { IGetFormAttributesResponse } from 'models/HeaderEntry'

export interface valueState {
	headerFormAttributes: IGetFormAttributesResponse | null
}

const initialState = {
	headerFormAttributes: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case HeaderEntryActions.GET_FORM_ATTRIBUTES:
			return { ...state, headerFormAttributes: null }
		case HeaderEntryActions.GET_FORM_ATTRIBUTES_SUCCESS:
			return { ...state, headerFormAttributes: action.payload }

		default:
			return state
	}
}

export default rootReducer
