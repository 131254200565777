import {
	CRUD_CLEAR_SEARCH,
	CRUD_CLEAR_STORE,
	CRUD_CLONE,
	CRUD_CLONE_SUCCESS,
	CRUD_CREATE,
	CRUD_CREATE_SUCCESS,
	CRUD_DELETE,
	CRUD_DELETE_SUCCESS,
	CRUD_GET_LIST,
	CRUD_GET_LIST_SUCCESS,
	CRUD_GET_SINGLE,
	CRUD_GET_SINGLE_SUCCESS,
	GET_ABM_CODE,
	GET_ABM_CODE_SUCCESS,
	GET_ALL_CRUDS,
	GET_ALL_CRUDS_SUCCESS,
	GET_CONFIG_FIELDS_CRUD,
	GET_CONFIG_FIELDS_CRUD_ALTA,
	GET_CONFIG_FIELDS_CRUD_ALTA_SUCCESS,
	GET_CONFIG_FIELDS_CRUD_SUCCESS
} from 'constants/ActionsTypes'
import {
	IGetAbmCode,
	IGetAbmCodeSuccess,
	IGetAllCruds,
	IGetAllCrudsRes,
	IGetConfigFieldsCrud,
	IGetConfigFieldsCrudRes
} from 'models/CrudInterface'
import moment from 'moment'

export const getConfigFieldsCrud = (params: IGetConfigFieldsCrud) => {
	return {
		type: GET_CONFIG_FIELDS_CRUD,
		payload: params
	}
}

export const getConfigFieldsCrudSuccess = (
	response: IGetConfigFieldsCrudRes
) => {
	return {
		type: GET_CONFIG_FIELDS_CRUD_SUCCESS,
		payload: response
	}
}

export const getConfigFieldsCrudAlta = (params: IGetConfigFieldsCrud) => {
	return {
		type: GET_CONFIG_FIELDS_CRUD_ALTA,
		payload: params
	}
}

export const getConfigFieldsCrudAltaSuccess = (
	response: IGetConfigFieldsCrudRes
) => {
	return {
		type: GET_CONFIG_FIELDS_CRUD_ALTA_SUCCESS,
		payload: response
	}
}

export const getAllCruds = (params: IGetAllCruds) => {
	return {
		type: GET_ALL_CRUDS,
		payload: params
	}
}

export const getAllCrudsSuccess = (response: IGetAllCrudsRes) => {
	return {
		type: GET_ALL_CRUDS_SUCCESS,
		payload: response
	}
}

export const getAmbCode = (payload: IGetAbmCode) => {
	return {
		type: GET_ABM_CODE,
		payload
	}
}

export const getAmbCodeSuccess = (response: IGetAbmCodeSuccess) => {
	return {
		type: GET_ABM_CODE_SUCCESS,
		payload: response
	}
}

export const crudsGetList = (payload: any) => {
	return {
		type: CRUD_GET_LIST,
		payload
	}
}

export const crudsGetListSuccess = (response: any) => {
	return {
		type: CRUD_GET_LIST_SUCCESS,
		payload: response
	}
}

export const crudsCreate = (payload: any) => {
	return {
		type: CRUD_CREATE,
		payload
	}
}

export const crudsCreateSuccess = (response: any) => {
	return {
		type: CRUD_CREATE_SUCCESS,
		payload: response
	}
}

export const crudsDelete = (payload: any) => {
	return {
		type: CRUD_DELETE,
		payload
	}
}

export const crudsDeleteSuccess = (response: any) => {
	return {
		type: CRUD_DELETE_SUCCESS,
		payload: response
	}
}

export const crudsGetSingle = (payload: any) => {
	return {
		type: CRUD_GET_SINGLE,
		payload
	}
}

export const crudsGetSingleSuccess = (response: any) => {
	response.fin_ej &&
		(response.fin_ej = new Date(moment(response.fin_ej).format()))
	response.inicio_ej &&
		(response.inicio_ej = new Date(moment(response.inicio_ej).format()))
	response.fec_ini &&
		(response.fec_ini = new Date(moment(response.fec_ini).format()))
	response.fec_uc &&
		(response.fec_uc = new Date(moment(response.fec_uc).format()))
	response.fmovim &&
		(response.fmovim = new Date(moment(response.fmovim).format()))

	return {
		type: CRUD_GET_SINGLE_SUCCESS,
		payload: response
	}
}

export const crudsClearStore = () => ({
	type: CRUD_CLEAR_STORE
})

export const crudsClearSearch = () => ({
	type: CRUD_CLEAR_SEARCH
})

export const crudsClone = (payload: any) => {
	return {
		type: CRUD_CLONE,
		payload
	}
}

export const crudsCloneSuccess = (payload: any) => {
	return {
		type: CRUD_CLONE_SUCCESS,
		payload
	}
}
