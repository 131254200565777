import Axios from 'axios'
import {
	ICrudGetProductBonif,
	ICrudUpdateProductBonif
} from 'models/CrudProductsInterface'

const urlBase = '/abm_inv_productos_bon'

export const crudGetProductBonification = async (
	params: ICrudGetProductBonif
) => {
	const response = await Axios.get(`${urlBase}/consulta`, { params })
	return response.data
}

export const crudUpdateProductBonification = async (
	params: ICrudUpdateProductBonif
) => {
	const response = await Axios.post(`${urlBase}/confirmar`, params)
	return response.data
}
