import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import tinyColor from 'tinycolor2'
import ColorPick from '../common/colorPick'

class DocumentItems extends Component {
	constructor(props) {
		super(props)
		this.state = {
			bgcolor: {
				r: '27',
				g: '187',
				b: '233',
				a: '1'
			},
			colorText: '#fff'
		}
	}

	handleChangeColor = (color) => {
		this.props.onColorChange(this.props.voucher.nioperacion, color)
	}

	/**
	 * Given a color, compute a contrasting color which can be used alongside the first one
	 * @param {*} rgb color code
	 */
	computeContrastingColor = (rgb) => {
		const color = tinyColor(rgb)
		return color.isLight() ? '#000' : '#FFF'
	}

	handleClick = () => {
		const { isMoving, idCompany, voucher } = this.props

		if (!isMoving) {
			this.props.handleCrearVoucherType({
				idComprobante: voucher.nioperacion,
				idEmpresa: parseInt(idCompany)
			})
		}
	}

	render() {
		const { voucher, closePick } = this.props
		const colorChange = voucher.color
			? voucher.color
			: `rgba(${this.state.bgcolor.r}, ${this.state.bgcolor.g}, ${this.state.bgcolor.b}, ${this.state.bgcolor.a})`

		const styleTextContainer = {
			background: 'transparent',
			borderColor: 'transparent',
			userSelect: 'none',
			textAlign: 'center',
			height: '150px',
			paddingTop: 10,
			color: this.computeContrastingColor(colorChange),
			width: '100%',
			outline: 'none',
			fontWeight: 'bold',
			boxShadow: '0px 0px'
		}
		const styleDiv = {
			position: 'absolute',
			right: 0,
			left: 0,
			top: '50%',
			margin: 'auto',
			background: colorChange,
			width: '170px',
			transform: 'translateY(-50%)',
			boxShadow: '2px 2px #EDEDED',
			cursor: 'pointer'
		}

		return (
			<Fragment>
				<div className={'alert col'} role={'alert'} style={styleDiv}>
					<div
						role={'presentation'}
						style={styleTextContainer}
						onClick={() => this.handleClick()}
					>
						{voucher.descrip_comprob}
					</div>
					<ColorPick
						handleChange={this.handleChangeColor}
						color={colorChange}
						iconColor={this.computeContrastingColor}
						closeAllPickers={() =>
							this.props.closeAllPickers(voucher.nioperacion)
						}
						closePick={closePick}
					/>
				</div>
			</Fragment>
		)
	}
}

export default withTranslation()(withRouter(DocumentItems))
