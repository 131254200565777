import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
	changeCollectionTableSuccess,
	changeTableAffectSuccess,
	changeTableItemsSuccess,
	exitPrintSuccess,
	finishGenerateSuccess,
	getClientHeadboardSuccess,
	getGenerateItemsSuccess,
	getGeneratesalesAffectedSuccess,
	printGenerateSuccess,
	printVoucherSuccess
} from '../actions/Generate'
import {
	changeCollectionTable,
	changeTableAffect,
	changeTableItems,
	exitPrint,
	finishGenerate,
	getClientHeadboard,
	getGenerateItems,
	getGeneratesalesAffected,
	printGenerate,
	printGenerateFile,
	printVoucher
} from '../api/Generate'
import {
	CHANGE_COLLECTION_TABLE,
	CHANGE_TABLE_AFFECT,
	CHANGE_TABLE_ITEMS,
	EXIT_PRINT_VOUCHER,
	FINISH_GENERATE,
	GET_CLIENT_HEADBOARD,
	GET_GENERATE_ITEMS,
	GET_GENERATE_SALES_AFFECTED,
	PRINT_GENERATE,
	PRINT_VOUCHER
} from '../constants/ActionsTypes'

function* getClientHeadboardRequest({ payload }) {
	try {
		const client = yield call(getClientHeadboard, payload)
		yield put(getClientHeadboardSuccess(client))
	} catch (error) {}
}

function* getGenerateItemsRequest({ payload }) {
	try {
		const items = yield call(getGenerateItems, payload)
		yield put(getGenerateItemsSuccess(items))
	} catch (error) {}
}

function* getGeneratesalesAffectedRequest({ payload }) {
	try {
		const salesAffected = yield call(getGeneratesalesAffected, payload)
		yield put(getGeneratesalesAffectedSuccess(salesAffected))
	} catch (error) {}
}

function* finishGenerateRequest({ payload }) {
	try {
		const generated = yield call(finishGenerate, payload)
		yield put(finishGenerateSuccess(generated))
	} catch (error) {}
}

function* printGenerateRequest({ payload }) {
	const endPoint = payload.endPoint ? payload.endPoint : 'ver'
	const url = `/ventas/comprobante/imprimir/${endPoint}`
	const { idOperacion, nameFile } = payload
	let printed = null

	try {
		if (endPoint === 'ver' || endPoint === 'exportar') {
			printed = yield call(printGenerateFile, { url, idOperacion, nameFile })
		} else {
			printed = yield call(printGenerate, {
				url,
				params: { idOperacion, ...payload.params }
			})
		}
		yield put(printGenerateSuccess(printed))
	} catch (error) {}
}

function* changeTableItemsRequest({ payload }) {
	try {
		const items = yield call(changeTableItems, payload)
		yield put(changeTableItemsSuccess(items))
	} catch (error) {}
}

function* changeTableAffectRequest({ payload }) {
	try {
		const items = yield call(changeTableAffect, payload)
		yield put(changeTableAffectSuccess(items))
	} catch (error) {}
}

function* changeCollectionTableRequest({ payload }) {
	try {
		const collections = yield call(changeCollectionTable, payload)
		yield put(changeCollectionTableSuccess(collections))
	} catch (error) {}
}

function* printVoucherRequest({ payload }) {
	try {
		const print = yield call(printVoucher, payload)
		yield put(printVoucherSuccess(print))
	} catch (error) {}
}

function* exitPrintRequest({ payload }) {
	try {
		const print = yield call(exitPrint, payload)
		yield put(exitPrintSuccess(print))
	} catch (error) {}
}

export function* getClientHeadboardSaga() {
	yield takeLatest(GET_CLIENT_HEADBOARD, getClientHeadboardRequest)
}

export function* getGenerateItemsSaga() {
	yield takeLatest(GET_GENERATE_ITEMS, getGenerateItemsRequest)
}

export function* getGeneratesalesAffectedSaga() {
	yield takeLatest(GET_GENERATE_SALES_AFFECTED, getGeneratesalesAffectedRequest)
}

export function* finishGenerateSaga() {
	yield takeLatest(FINISH_GENERATE, finishGenerateRequest)
}

export function* printGenerateSaga() {
	yield takeLatest(PRINT_GENERATE, printGenerateRequest)
}

export function* changeTableItemsSaga() {
	yield takeLatest(CHANGE_TABLE_ITEMS, changeTableItemsRequest)
}

export function* changeTableAffectSaga() {
	yield takeLatest(CHANGE_TABLE_AFFECT, changeTableAffectRequest)
}

export function* changeCollectionTableSaga() {
	yield takeLatest(CHANGE_COLLECTION_TABLE, changeCollectionTableRequest)
}

export function* printVoucherSaga() {
	yield takeLatest(PRINT_VOUCHER, printVoucherRequest)
}

export function* exitPrintSaga() {
	yield takeLatest(EXIT_PRINT_VOUCHER, exitPrintRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getClientHeadboardSaga),
		fork(getGenerateItemsSaga),
		fork(getGeneratesalesAffectedSaga),
		fork(finishGenerateSaga),
		fork(printGenerateSaga),
		fork(changeTableItemsSaga),
		fork(changeTableAffectSaga),
		fork(changeCollectionTableSaga),
		fork(printVoucherSaga),
		fork(exitPrintSaga)
	])
}
