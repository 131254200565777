import {
	getConfigVoucher,
	getEgrStock,
	pickeoEgrStockConfirm,
	pickeoSearchBarcode
} from 'actions'
import AudioComponent from 'components/common/audioComponent'
import CommonModal from 'components/common/commonModal'
import NotificationMessage from 'components/common/notificationMessage'
import { P_PICK_STOCK_VTA } from 'constants/ConfigProcessNames'
import { addPrevValidations } from 'lib/AxiosInterceptors'
import { getFiltersData } from 'lib/Utils'
import React, { Component, Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import ProviderPickStockTable from '../providerPickStock/ProviderPickStockTable'
import BarCodeErrorForm from './BarCodeErrorForm'
import PickStockBarCode from './PickStockBarCode'
import PickStockFilters from './PickStockFilters'

class PickStockForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			egrPickeo: null,
			itemsTable: [],
			showError: false,
			errorMessage: '',
			typeError: '',
			filtersPick: {},
			showConfirmationPopup: false,
			buttonsConfirmation: true
		}

		addPrevValidations((e) => {
			if (e.request.responseURL.includes('cod_barra')) {
				return true
			}
			return false
		})
	}

	componentDidMount() {
		const { idOperacion } = this.props
		this.props.formConfirmation(this.handleConfirmation)

		if (idOperacion) {
			this.props.getConfigVoucher({
				cod_proceso: P_PICK_STOCK_VTA,
				idOperacion
			})

			this.props.getEgrStock({
				idOperacion,
				page_size: 10,
				ind_filtro_fecha: 0,
				filtro_fecha: 0,
				filtro_afectados: 0
			})
			this.handleConfirmation = this.handleConfirmation.bind(this)
		}
	}

	componentDidUpdate = (prevProps) => {
		const { egrPickeo, barCodeSearched, barCodeError, t } = this.props

		if (egrPickeo !== prevProps.egrPickeo && egrPickeo) {
			this.setState({ egrPickeo })
		}

		if (barCodeSearched !== prevProps.barCodeSearched && barCodeSearched) {
			this.setState({ egrPickeo: barCodeSearched })
			this.handleSoundSuccess()
		}

		if (barCodeError !== prevProps.barCodeError && barCodeError) {
			this.handleSoundError()
			let showError = true
			// console.log(barCodeError, "logs errores");

			if (barCodeError.typeError === 'C') {
				showError = false
				this.setState({
					showConfirmationPopup: true,
					errorMessage: `${barCodeError.message}`,
					titleBarCodeError: t('global.confirm'),
					buttonsConfirmation: true
				})
			} else if (barCodeError.typeError === 'S') {
				const { requireFields } = barCodeError
				const errorMessage = (
					<BarCodeErrorForm
						requireFields={requireFields}
						t={t}
						handleSubmitForm={this.handleConfirmationPopup}
						handleCloseModal={this.handleCloseErrorPopup}
					/>
				)
				this.setState({
					showConfirmationPopup: true,
					errorMessage,
					titleBarCodeError: t('global.mandatory_fields'),
					buttonsConfirmation: false
				})
			} else if (showError) {
				this.setState({
					showError: true,
					buttonsConfirmation: true,
					errorMessage: `${barCodeError.message}`,
					typeError: 'danger'
				})
			}
		}
	}

	/**
	 * to apply change when call api
	 */
	handleChangeFilter = (filters) => {
		const { idOperacion } = this.props
		const { filtersPick } = this.state
		const allFilters = { ...filtersPick, ...filters }

		this.props.getEgrStock({
			idOperacion,
			page: 1,
			page_size: 10,
			ind_filtro_fecha: 0,
			filtro_fecha: 0,
			filtro_afectados: 0,
			...allFilters
		})

		this.setState({ filtersPick: allFilters })
	}

	handleConfirmation = () => {
		const { idOperacion, callBackReturn } = this.props
		this.props.pickeoEgrStockConfirm({
			idOperacion,
			callBackReturn
		})
	}

	/**
	 * call api sound success
	 */
	handleSoundSuccess = async () => {
		this.soundSuccess()
	}

	/**
	 * call api sound error
	 */
	handleSoundError = async () => {
		this.soundError()
	}

	/**
	 * to close error notification
	 */
	handleCloseErrorNotification = () => {
		this.setState({ showError: false })
	}

	/**
	 * to close error popup
	 */
	handleCloseErrorPopup = () => {
		this.setState({ showConfirmationPopup: false })
	}

	/**
	 * to resend barcode request
	 */
	handleConfirmationPopup = (valuesForm = {}) => {
		const { barCodeParams, pickeoSearchBarcode } = this.props
		const params = !_.isEmpty(valuesForm)
			? { ...barCodeParams, ...valuesForm }
			: { ...barCodeParams, confirmacion: 1 }
		pickeoSearchBarcode(params)
		this.handleCloseErrorPopup()
	}

	/**
	 *  to paginate the table.
	 * @param {string} type
	 * @param {object} pagination
	 */
	handleChangeTable = (type, pagination) => {
		const { idOperacion } = this.props
		const { filtersPick } = this.state
		if (type === 'pagination') {
			this.props.getEgrStock({
				...filtersPick,
				idOperacion,
				page_number: pagination.page,
				page_size: pagination.sizePerPage,
				ind_filtro_fecha: 0,
				filtro_fecha: 0,
				filtro_afectados: 0
			})
		}
	}

	render() {
		const { config, idOperacion, searchInput, propShowFilters, enableCant } =
			this.props
		const {
			egrPickeo,
			showError,
			errorMessage,
			typeError,
			showConfirmationPopup,
			titleBarCodeError,
			buttonsConfirmation
		} = this.state
		const fieldsForm = config
			? getFiltersData(config.campos, { agrupador: 'formulario' })
			: null
		return (
			<Fragment>
				<AudioComponent
					handleSuccess={(click) => (this.soundSuccess = click)}
					handleError={(click) => (this.soundError = click)}
				/>
				<Col md={12} className={'mb-1'}>
					<NotificationMessage
						showError={showError}
						errorMessage={errorMessage}
						type={typeError}
						handleCloseError={this.handleCloseErrorNotification}
					/>
					<CommonModal
						showModal={showConfirmationPopup}
						handleCloseModal={this.handleCloseErrorPopup}
						modalTitle={titleBarCodeError}
						modalBody={errorMessage}
						buttons={buttonsConfirmation}
						handleSubmit={this.handleConfirmationPopup}
					/>
				</Col>
				<PickStockFilters
					egrPickeo={egrPickeo}
					fieldsForm={fieldsForm}
					propShowFilters={propShowFilters}
					handleChangeFilter={this.handleChangeFilter}
					idOperacion={idOperacion}
				/>
				<PickStockBarCode
					fieldsForm={fieldsForm}
					egrPickeo={egrPickeo}
					idOperacion={idOperacion}
					searchInput={searchInput}
					enableCant={enableCant}
					handleToggleCant={this.props.handleToggleCant}
				/>
				<ProviderPickStockTable
					fieldsTable={
						config
							? getFiltersData(config.campos, { agrupador: 'grilla' })
							: null
					}
					itemsTable={egrPickeo ? egrPickeo.Items : []}
					page={egrPickeo ? egrPickeo.page_number : 1}
					pageSize={egrPickeo ? egrPickeo.page_size : 10}
					totalPage={egrPickeo ? egrPickeo.total_count : 10}
					handleChangeTable={this.handleChangeTable}
				/>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ voucher, pickeoEgrStock }) => {
	const config = voucher.config ? voucher.config[P_PICK_STOCK_VTA] : null
	const { egrPickeo, barCodeSearched, barCodeError, barCodeParams } =
		pickeoEgrStock
	return { config, egrPickeo, barCodeSearched, barCodeError, barCodeParams }
}

export default connect(mapStateToProps, {
	getConfigVoucher,
	getEgrStock,
	pickeoEgrStockConfirm,
	pickeoSearchBarcode
})(withTranslation()(PickStockForm))
