import {
	getTableCCImputationType,
	getTableCostCentersCategory,
	getTableParentCostCenter
} from 'actions'
import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import NotificationError from 'components/common/notificationsErrors'
import { FormRefsContext } from 'context/FormRefsContext'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { ICrudCostCenterField } from 'models/CrudCostCentersInterface'
import {
	IGetTableCCImputationTypeResArray,
	IGetTableCostCentersCategoryResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import CrudCostCenterDataField from './CrudCostCenterDataField'

// planCC

interface CrudCostCenterDataProps extends WithTranslation {
	getTableCCImputationType: any
	getTableCostCentersCategory: any
	getTableParentCostCenter: any
	CCImputationType: IGetTableCCImputationTypeResArray
	costCentersCategory: IGetTableCostCentersCategoryResArray
	parentCostCenter: string
	isTaxesIdValid: boolean
	disableForm: boolean
	fields: Array<ICrudCostCenterField>
	showMessage: any
	t: any
	handleSubmit: any
	itemInfo?: any
	handleCloseModal: any
	method: string
	showWarning: boolean
	handleWarning: any
	setFormChanged: any
}

type CrudCostCenterDataState = {
	values?: any
}

class CrudCostCenterData extends Component<
	CrudCostCenterDataProps,
	CrudCostCenterDataState
> {
	// Initial dropdown setup
	componentDidMount = () => {
		this.props.getTableCCImputationType()
		this.props.getTableCostCentersCategory()
	}

	render() {
		const { fields, t, handleSubmit, itemInfo, disableForm, method } =
			this.props

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})

		const columnsCancel = disableForm ? 12 : 6
		const validationSchema = fields.length
			? getValidationSchema(fields, t, [], [])
			: {}
		const subCategories: Array<string> = []

		_.each(
			fields,
			(
				field: ICrudCostCenterField,
				index: number,
				array: Array<ICrudCostCenterField>
			) => {
				if (array[index - 1]) {
					if (field.agrupador !== array[index - 1].agrupador) {
						subCategories.push(field.agrupador)
					}
				} else {
					subCategories.push(field.agrupador)
				}
			}
		)

		const allFields = _.map(subCategories, (category: string) => {
			const id = category
			const filterFields = fields.filter((field: ICrudCostCenterField) => {
				return field.agrupador === category
			})
			return { name: id, fields: filterFields }
		})

		return (
			<FormRefsContext.Consumer>
				{({ formRef, cancelButtonRef }) => (
					<Fragment>
						<Formik
							ref={formRef}
							initialValues={initialValues}
							onSubmit={(values, actions) => {
								handleSubmit(values)
							}}
							enableReinitialize={true}
							validationSchema={validationSchema}
						>
							{(props: FormikProps<any>) => (
								<Fragment>
									<Col sm={12}>
										<NotificationError
											errors={props.errors}
											touched={props.touched}
										/>
									</Col>
									<Form
										onChange={(e) => {
											this.props.setFormChanged(true)
										}}
									>
										{_.map(
											allFields,
											(category: {
												name: string
												fields: Array<ICrudCostCenterField>
											}) => {
												return (
													<Fragment key={category.name}>
														<Alert variant={'dark'}>
															<b>
																{t(
																	'CRUDS.general_use.' +
																		category.name.replace('.', '')
																)}
															</b>
														</Alert>
														{(method === 'add' ||
															((method === 'edit' || method === 'view') &&
																itemInfo)) &&
															_.map(
																category.fields,
																(field: ICrudCostCenterField) => {
																	return (
																		<CrudCostCenterDataField
																			key={field.idCampo}
																			formikProps={props}
																			field={field}
																			crudProps={this.props}
																			initialValue={
																				initialValues[
																					field.idCampo as keyof typeof initialValues
																				]
																			}
																		/>
																	)
																}
															)}
													</Fragment>
												)
											}
										)}
										<Row className={'pt-3'}>
											{!disableForm && (
												<Col sm={6} className={'text-center mt-2'}>
													<Button style={{ minWidth: '145px' }} type={'submit'}>
														{t('Collection.form.buttons.accept')}
													</Button>
												</Col>
											)}
											<Col sm={columnsCancel} className={'text-center mt-2'}>
												<Button
													style={{ minWidth: '145px' }}
													type={'button'}
													onClick={this.props.handleCloseModal}
													ref={cancelButtonRef}
												>
													{t('Collection.form.buttons.cancel')}
												</Button>
											</Col>
										</Row>
										<ConfirmModalNoButton
											modalTitle={t('CRUDS.modal.title_warning')}
											messageBody={t('CRUDS.modal.message_changes')}
											showModal={this.props.showWarning}
											handleCloseModal={() =>
												this.props.handleWarning(false, 'close')
											}
											handleCancelButton={() => {
												this.props.handleWarning(false, 'cancel')
											}}
											handleSubmitButton={(e: any) => {
												props.handleSubmit(e as any)
												this.props.handleWarning(false, 'submit')
											}}
										/>
									</Form>
								</Fragment>
							)}
						</Formik>
					</Fragment>
				)}
			</FormRefsContext.Consumer>
		)
	}
}

const mapStateToProps = ({ tableCalls }: any) => {
	const { CCImputationType, costCentersCategory, parentCostCenter } = tableCalls
	return {
		CCImputationType,
		costCentersCategory,
		parentCostCenter
	}
}

const connectForm = connect(mapStateToProps, {
	getTableCCImputationType,
	getTableCostCentersCategory,
	getTableParentCostCenter
})(CrudCostCenterData)

export default withTranslation()(withRouter(connectForm))
