import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drag_drop_container: {
			backgroundColor: '#e5ecf3',
			border: '1px dashed #3B89E5',
			color: '#165677',
			maxWidth: '100%',
			height: 'max-content',
			padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
			borderRadius: 10
		},
		drag_drop_container_success: {
			backgroundColor: '#e8fff7',
			color: '#165677',
			borderColor: '#00c880',
			'&:focus': {
				backgroundColor: '#e8fff7'
			}
		},
		drag_drop_container_error: {
			backgroundColor: '#FFF1D0',
			borderColor: '#E34162',
			color: '#700017',
			'&:focus': {
				backgroundColor: '#FFF1D0'
			}
		},
		drag_drop_container_disabled: {
			backgroundColor: '#ededed',
			border: '1px dashed #cacaca',
			borderRadius: 8,
			color: '#959595'
		}
	})
)
