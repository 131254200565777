import GenerateForm from 'components/generate/generateForm'
import GlobalContainer from 'components/layout/globalContainer'
import { P_FINCOMPROB } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import withMenu from '../../components/common/withMenu'

class Generate extends Component {
	constructor(props) {
		super(props)
		this.state = {
			completed: false
		}
	}

	successStep = () => {
		this.setState({ completed: true })
	}

	render() {
		return (
			<Row>
				<GlobalContainer codeProcess={P_FINCOMPROB}>
					<GenerateForm handleChangeSuccess={this.successStep} />
				</GlobalContainer>
			</Row>
		)
	}
}

const mapStateToProps = () => {
	return {}
}

export default connect(
	mapStateToProps,
	{}
)(withTranslation()(withMenu(Generate)))
