import {
	faArrowAltCircleLeft,
	faArrowAltCircleRight,
	faArrowAltCircleUp
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormRefsContext } from 'context/FormRefsContext'
import React, { PureComponent } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { CRUD_EDIT } from 'utils/RoutePath'
import { clearMessage, getAmbCode, getVoucherType } from '../../actions'
import GlobalNotifications from '../common/globalNotifications'
import GlobalShortcut from './globalShortcut'

class CrudsContainer extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			showMessage: false,
			message: '',
			catributo: null,
			showChildren: true,
			onlyRead: false
		}
		this.formRef = React.createRef()
		this.cancelButtonRef = React.createRef()
		this.searchButtonRef = React.createRef()
	}

	componentDidMount = () => {
		const { voucherType } = this.props
		if (voucherType === null && this.props.match) {
			const { id } = this.props.match ? this.props.match.params : null
			this.setState({ catributo: id })
		}
	}

	componentDidUpdate = (prevProps) => {
		const {
			showWarning: prevWarning,
			newAbmCode: prevNewAbmCode,
			match: prevMatch
		} = prevProps

		const {
			message,
			clearMessage,
			t,
			newAbmCode,
			keyApi,
			match,
			showWarning,
			nextAction,
			history,
			getNewData,
			children,
			op
		} = this.props

		if (match !== prevMatch && match) {
			const { id } = match ? match.params : null
			this.setState({ catributo: id })
		}

		if (message) {
			if (message.showError) {
				let errorMessage =
					typeof message.description === 'object'
						? typeof message.description.resultado === 'object'
							? message.description.resultado
							: typeof message.description.Resultado === 'object'
							? message.description.Resultado
							: message.description
						: t(message.description)
				errorMessage = errorMessage.type ? t('error_image') : errorMessage // update error message type blob
				this.setState({
					showMessage: true,
					message: errorMessage,
					typeMessage: message.type
				})
			}

			clearMessage()
		}

		if (newAbmCode !== prevNewAbmCode && newAbmCode) {
			const pathParams = match.url.split('/')
			history.push(`${CRUD_EDIT}/${pathParams[2]}/${op}/${newAbmCode}`)
			const paramsApi = {}
			paramsApi[keyApi] = newAbmCode
			getNewData(paramsApi)
			this.setState({ showChildren: true })
		}

		if (newAbmCode !== prevNewAbmCode && newAbmCode === '') {
			history.go(0)
			this.setState({ showChildren: true })
		}

		if (prevProps.children !== children && _.isArray(children)) {
			let onlyRead = false
			children.forEach((child) => {
				if (child) {
					if (child.props.disableForm) {
						onlyRead = true
					}
				}
			})
			this.setState({ onlyRead })
		}
		if (prevWarning !== showWarning && nextAction) {
			this.handleChangeAbm(nextAction)
		}
	}

	handleSetShow = (state) => {
		this.setState({ showMessage: state })
	}

	handleChangeAbm = (arrow) => {
		const { catributo } = this.state
		const {
			urlApi,
			keyApi,
			setNextAction,
			handleExitAbm,
			handleWarning,
			showWarning
		} = this.props
		setNextAction && setNextAction(arrow)
		if (showWarning === true) {
			handleWarning(true)
		} else {
			if (arrow === 'siguiente' || arrow === 'anterior') {
				let params = {}
				if (keyApi) {
					params[keyApi] = catributo
				} else {
					params = { catributo }
				}
				this.setState({ showChildren: false })
				this.props.getAmbCode({ arrow, urlApi, params })
			} else if (arrow === 'salir') {
				handleExitAbm()
			}
		}
	}

	/**
	 * to get all shortcuts to amb layout
	 * @returns
	 */
	getShortcuts = () => {
		const { shortcuts } = this.props
		const rtShort = shortcuts || []
		rtShort.push(
			{
				hotkey: { charCode: '123', modifiers: ['f12'] },
				action: this.handleSubmitPage,
				name: 'Accept',
				description: 'to call submit page'
			},
			{
				hotkey: { charCode: '123', modifiers: ['f9'] },
				action: this.handleCancelPage,
				name: 'Cancel',
				description: 'to call cancel page'
			},
			{
				hotkey: { charCode: '123', modifiers: ['f8'] },
				action: this.handleNextPage,
				name: 'Back',
				description: 'to call back page'
			},
			{
				hotkey: { charCode: '123', modifiers: ['f7'] },
				action: this.handleBackPage,
				name: 'Next',
				description: 'to call Next page'
			},
			{
				hotkey: { charCode: '123', modifiers: ['enter'] },
				action: this.handleSearchPage,
				name: 'Search',
				description: 'to call Search page'
			}
		)

		return rtShort
	}

	/**
	 * callback is used when the user press f7 key
	 */
	handleBackPage = () => {
		const { catributo } = this.state
		if (catributo && catributo !== 'new') {
			this.handleChangeAbm('anterior')
		}
	}

	/**
	 * callback is used when the user press f8 key
	 */
	handleNextPage = () => {
		const { catributo } = this.state
		if (catributo && catributo !== 'new') {
			this.handleChangeAbm('siguiente')
		}
	}

	/**
	 * callback is used when the user press f9 key
	 */
	handleCancelPage = () => {
		if (this.cancelButtonRef.current) {
			this.cancelButtonRef.current.click() // Only works to click in a button
		}
	}

	handleSearchPage = () => {
		if (this.searchButtonRef.current) {
			this.searchButtonRef.current.click() // Only works to click in a button
		}
	}

	/**
	 * callback is used when the user press f12 key
	 */
	handleSubmitPage = () => {
		const { onlyRead } = this.state

		if (this.formRef.current && !onlyRead) {
			this.formRef.current.handleSubmit() // only works to managed submit from formik forms
		}
	}

	render() {
		const { children, handleExitAbm, title, disableArrows, t } = this.props
		const { catributo } = this.state
		const customShortcuts = this.getShortcuts()

		return (
			<GlobalShortcut shortcuts={customShortcuts}>
				<GlobalNotifications
					{...this.state}
					setShow={(s) => this.handleSetShow(s)}
					voucherType
				/>
				<Container fluid>
					<Row>
						{catributo && catributo !== 'new' && (
							<Col
								className={
									'offset-xs-7 col-xs-5 offset-sm-7 col-sm-5 offset-md-9 col-md-3 offset-lg-9 col-lg-3 offset-xl-10 col-xl-2 text-right pr-3 mr-3 '
								}
							>
								{!disableArrows && (
									<Row
										style={{ position: 'relative', top: '3rem', zIndex: 500 }}
									>
										<Col>
											<FontAwesomeIcon
												icon={faArrowAltCircleLeft}
												style={{ fontSize: '16pt', cursor: 'pointer' }}
												title={t('CRUDS.general_use.prev_crud', {
													title: title
												})}
												onClick={() => this.handleChangeAbm('anterior')}
											/>
										</Col>
										{handleExitAbm && (
											<Col>
												<FontAwesomeIcon
													icon={faArrowAltCircleUp}
													style={{ fontSize: '16pt', cursor: 'pointer' }}
													title={t('global.exit')}
													onClick={() => this.handleChangeAbm('salir')}
												/>
											</Col>
										)}
										<Col>
											<FontAwesomeIcon
												icon={faArrowAltCircleRight}
												style={{ fontSize: '16pt', cursor: 'pointer' }}
												title={t('CRUDS.general_use.next_crud', {
													title: title
												})}
												onClick={() => this.handleChangeAbm('siguiente')}
											/>
										</Col>
									</Row>
								)}
							</Col>
						)}
						{
							<Col sm={12}>
								<FormRefsContext.Provider
									value={{
										formRef: this.formRef,
										cancelButtonRef: this.cancelButtonRef,
										searchButtonRef: this.searchButtonRef
									}}
								>
									{children}
								</FormRefsContext.Provider>
							</Col>
						}
					</Row>
				</Container>
			</GlobalShortcut>
		)
	}
}

const mapStateToProps = ({ common, vouchertype, auth, configCrud }) => {
	const { message } = common
	const { voucherTypeCancel, voucherType } = vouchertype
	const { authUser } = auth
	const { newAbmCode } = configCrud
	return { message, voucherTypeCancel, authUser, voucherType, newAbmCode }
}

export default connect(mapStateToProps, {
	clearMessage,
	getVoucherType,
	getAmbCode
})(withTranslation()(withRouter(CrudsContainer)))
