import React, { Component, Fragment } from 'react'
import StockDisplayProvider from './stockDisplayProvider'
import StockInputsProvider from './stockInputsProvider'
import StockTableProvider from './stockTableProvider'

export default class StockFormProvider extends Component {
	componentDidMount() {
		this.props.clearStoreStockProvider()
	}

	componentWillUnmount = () => {
		const { confirmedStock, handleCancelApi } = this.props
		if (confirmedStock === null) {
			handleCancelApi()
		}
	}

	render() {
		return (
			<Fragment>
				<StockDisplayProvider {...this.props} />
				<StockInputsProvider {...this.props} />
				<StockTableProvider {...this.props} />
			</Fragment>
		)
	}
}
