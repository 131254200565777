import { voucherHeadValidatekey } from 'actions'
import InputCheckBox from 'components/form/InputCheckBox'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getOptionsDropDown, handleSetValueInput } from 'lib/FormUtils'
import React, { Component, Fragment } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
interface IAutoGenerateInputsProps extends WithTranslation {
	fields?: Array<IFieldStructure>
	filterBranches?: any
	filterSellers?: any
	filterCurrencies?: any
	filterCondSale?: any
	t?: any
	values: any
	handleChange: any
	handleBlur: any
	setFieldValue: any
	setFieldTouched: any
	idOperacion?: any
	voucherHeadValidatekey: any
	checkKey: string
	disableForm: boolean
}

interface IAutoGenerateInputsState {
	dateFromValue: Date
	lockQuotation: boolean
}

class AutoGenerateInputs extends Component<
	IAutoGenerateInputsProps,
	IAutoGenerateInputsState
> {
	constructor(props: IAutoGenerateInputsProps) {
		super(props)
		this.state = {
			dateFromValue: new Date(),
			lockQuotation: true
		}
	}

	componentDidUpdate = (prevProps: IAutoGenerateInputsProps) => {
		const { checkKey } = this.props

		if (prevProps.checkKey !== checkKey && checkKey) {
			this.setState({ lockQuotation: false })
		}
	}

	/**
	 * call api to unlock the quotation input
	 * @param data
	 */
	handleLockQuotation = (data: string) => {
		this.props.voucherHeadValidatekey({ idProceso: 'CC_003', clave: data })
	}

	/**
	 * to set quotation value when change currency
	 * @param evnt
	 */
	handleSetQuotationValue = (evnt: any) => {
		const { filterCurrencies } = this.props
		const { value } = evnt.target
		const quotation = _.find(filterCurrencies, { codigo: value })
		this.props.setFieldValue('cotizacion', quotation.cotiz || '')
	}

	render() {
		const {
			fields,
			filterBranches,
			t,
			filterSellers,
			filterCurrencies,
			filterCondSale,
			setFieldTouched,
			setFieldValue,
			handleBlur,
			values,
			disableForm
		} = this.props

		const formikProps = { setFieldTouched, setFieldValue }

		const { dateFromValue, lockQuotation } = this.state

		const dateFrom = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Fecha',
			inputId: 'fecha',
			name: 'fecha',
			placeholder: t('global.date'),
			colLabel: 'col-sm-4',
			colInput: 'col-sm-4',
			onBlur: () => {},
			value: dateFromValue,
			autoComplete: 'off',
			// disable: disableForm,
			disabledInput: disableForm,
			onChange: (data: any) => {
				const date = data || ''
				this.setState({ dateFromValue: date })
				handleSetValueInput(date, 'fecha', formikProps)
			}
		}

		const branchesProps = {
			inputFormCol: { sm: 12 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'suc_fac',
			name: 'suc_fac',
			placeholder: '',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			onBlur: handleBlur,
			value: values.suc_fac,
			options: getOptionsDropDown(filterBranches),
			noInitialExecute: true,
			// disable: disableForm,
			disabledInput: disableForm,
			onChange: (data: any) => handleSetValueInput(data, 'suc_fac', formikProps)
		}

		const clientsProps = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'agrupa_cli',
			name: 'agrupa_cli',
			placeholder: '',
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			onBlur: handleBlur,
			value: values.agrupa_cli,
			// disable: disableForm,
			disabledInput: disableForm,
			onChange: (data: any) =>
				handleSetValueInput(data, 'agrupa_cli', formikProps)
		}

		const currencyProps = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'moneda',
			name: 'moneda',
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			onBlur: handleBlur,
			value: values.moneda,
			options: getOptionsDropDown(filterCurrencies),
			// disable: disableForm,
			disabledInput: disableForm,
			onChange: (data: any) => {
				this.handleSetQuotationValue(data)
				// handleSetValueInput(data, 'moneda', formikProps)
				handleSetValueInput(data, 'moneda', formikProps)
			}
		}

		const quotationProps = {
			inputFormCol: { sm: 5 },
			fields,
			label: 'Cotizacion',
			inputId: 'cotizacion',
			name: 'cotizacion',
			placeholder: t('global.quotation'),
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			handleSubmit: this.handleLockQuotation,
			// disable: disableForm,
			disabledInput: disableForm || lockQuotation,
			onBlur: handleBlur,
			value: values.cotizacion,
			lock: true,
			autoComplete: 'off',
			onChange: (data: any) => {
				const date = data || ''
				handleSetValueInput(date, 'cotizacion', formikProps)
			}
		}

		const conditionsSaleProps = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'cond_vta',
			name: 'cond_vta',
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			onBlur: handleBlur,
			value: values.cond_vta,
			options: getOptionsDropDown(filterCondSale),
			disabledInput: disableForm,
			noInitialExecute: true,
			onChange: (data: any) =>
				handleSetValueInput(data, 'cond_vta', formikProps)
		}

		const sellerProps = {
			inputFormCol: { sm: 5 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'vendedor',
			name: 'vendedor',
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			onBlur: handleBlur,
			value: values.vendedor,
			options: getOptionsDropDown(filterSellers),
			noInitialExecute: true,
			disabledInput: disableForm,
			onChange: (data: any) =>
				handleSetValueInput(data, 'vendedor', formikProps)
		}

		return (
			<Fragment>
				<Row className={`col-12`}>
					<InputText {...dateFrom} />
					<InputCheckBox {...clientsProps} />
				</Row>
				<Row className={`col-12`}>
					<InputDropdown {...branchesProps} />
				</Row>
				<Row className={`col-12`}>
					<InputDropdown {...currencyProps} />
					<InputText {...quotationProps} />
				</Row>
				<Row className={`col-12`}>
					<InputDropdown {...conditionsSaleProps} />
					<InputDropdown {...sellerProps} />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({
	searchVoucher,
	tableCalls,
	utilsFilters,
	voucher
}: any) => {
	const { filterBranches, filterSellers } = searchVoucher
	const { filterCurrencies } = utilsFilters
	const { filterCondSale } = tableCalls
	const { checkKey } = voucher
	return {
		filterBranches,
		filterCurrencies,
		filterCondSale,
		filterSellers,
		checkKey
	}
}

const mapDispatchToProps = {
	voucherHeadValidatekey
}

const connectForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(AutoGenerateInputs)

export default withTranslation()(connectForm)
