import InputCheckBox from 'components/form/InputCheckBox'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import React, { Component, Fragment } from 'react'
import { Row } from 'react-bootstrap'

interface IMainDataFieldsProps {
	fields: IFieldStructure[]
	detailMainData?: any
}

class MainDataIndicators extends Component<IMainDataFieldsProps> {
	render() {
		const { fields, detailMainData } = this.props

		const propsStock = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'Pendientes',
			inputId: 'ind_stock',
			name: 'ind_stock',
			placeholder: '',
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			styleLabel: { fontSize: '15px' },
			value: detailMainData.ind_stock || '',
			checked: detailMainData.ind_stock && detailMainData.ind_stock === 'Si',
			onChange: (data: any) => {}
		}

		const propsSlice = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'Pendientes',
			inputId: 'ind_partidas',
			name: 'ind_partidas',
			placeholder: '',
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			styleLabel: { fontSize: '15px' },
			value: detailMainData.ind_partidas || '',
			checked:
				detailMainData.ind_partidas && detailMainData.ind_partidas === 'Si',
			onChange: (data: any) => {}
		}

		const propsDate = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'Pendientes',
			inputId: 'ind_fvto',
			name: 'ind_fvto',
			placeholder: '',
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			styleLabel: { fontSize: '15px' },
			value: detailMainData.ind_fvto || '',
			checked: detailMainData.ind_fvto && detailMainData.ind_fvto === 'Si',
			onChange: (data: any) => {}
		}

		const propsSerie = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'Pendientes',
			inputId: 'ind_series',
			name: 'ind_series',
			placeholder: '',
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			styleLabel: { fontSize: '15px' },
			value: detailMainData.ind_series || '',
			checked: detailMainData.ind_series && detailMainData.ind_series === 'Si',
			onChange: (data: any) => {}
		}

		return (
			<Fragment>
				<Row className={`col-12 `}>
					<InputCheckBox {...propsStock} />
					<InputCheckBox {...propsSlice} />
					<InputCheckBox {...propsDate} />
					<InputCheckBox {...propsSerie} />
				</Row>
				<Row>
					{/* <CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="descrip1"
						value={`${detailMainData.descrip1}` || ''}
					/> */}
				</Row>
			</Fragment>
		)
	}
}

export default MainDataIndicators
