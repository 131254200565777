import {
	CANCEL_PRODUCT_STOCK,
	CANCEL_PRODUCT_STOCK_ERROR,
	CANCEL_PRODUCT_STOCK_SUCCESS,
	CLEAR_STORE_STOCK,
	CONFIRM_PRODUCT_STOCK,
	CONFIRM_PRODUCT_STOCK_ERROR,
	CONFIRM_PRODUCT_STOCK_SUCCESS,
	GET_PRODUCT_STOCK,
	GET_PRODUCT_STOCK_SUCCESS,
	SEARCH_BARCODE,
	SEARCH_BARCODE_ERROR,
	SEARCH_BARCODE_SUCCESS,
	VALIDATE_PRODUCT_STOCK,
	VALIDATE_PRODUCT_STOCK_ERROR,
	VALIDATE_PRODUCT_STOCK_SUCCESS
} from '../constants/ActionsTypes'

export const getProductStock = (params) => {
	return {
		type: GET_PRODUCT_STOCK,
		payload: params
	}
}

export const getProductStockSuccess = (response) => {
	return {
		type: GET_PRODUCT_STOCK_SUCCESS,
		payload: response
	}
}

export const validateProductStock = (params) => {
	return {
		type: VALIDATE_PRODUCT_STOCK,
		payload: params
	}
}

export const validateProductStockSuccess = (response) => {
	return {
		type: VALIDATE_PRODUCT_STOCK_SUCCESS,
		payload: response
	}
}

export const validateProductStockError = (error) => {
	return {
		type: VALIDATE_PRODUCT_STOCK_ERROR,
		payload: error
	}
}

export const confirmProductStockSuccess = (response) => {
	return {
		type: CONFIRM_PRODUCT_STOCK_SUCCESS,
		payload: response
	}
}

export const confirmProductStock = (params) => {
	return {
		type: CONFIRM_PRODUCT_STOCK,
		payload: params
	}
}

export const confirmProductStockError = (error) => {
	return {
		type: CONFIRM_PRODUCT_STOCK_ERROR,
		payload: error
	}
}

export const cancelProductStockSuccess = (response) => {
	return {
		type: CANCEL_PRODUCT_STOCK_SUCCESS,
		payload: response
	}
}

export const cancelProductStock = (params) => {
	return {
		type: CANCEL_PRODUCT_STOCK,
		payload: params
	}
}

export const cancelProductStockError = (error) => {
	return {
		type: CANCEL_PRODUCT_STOCK_ERROR,
		payload: error
	}
}

export const searchBarcodeSuccess = (response) => {
	return {
		type: SEARCH_BARCODE_SUCCESS,
		payload: response
	}
}

export const searchBarcode = (params) => {
	return {
		type: SEARCH_BARCODE,
		payload: params
	}
}

export const searchBarcodeError = (error) => {
	return {
		type: SEARCH_BARCODE_ERROR,
		payload: error
	}
}

export const clearStoreStock = () => {
	return {
		type: CLEAR_STORE_STOCK
	}
}
