import { hideLoader, showLoader, showMessage, userSignOut } from 'actions'
import Axios from 'axios'
import NProgress from 'nprogress'
import i18n from '../translations'
import { env } from './env'
import { debugError } from './Utils'

const prevValidations = []
let showError = true

export const addPrevValidations = (newValidation) => {
	prevValidations.push(newValidation)
}

NProgress.configure({
	template:
		'<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>'
})

const configureAxios = async (store) => {
	const config = {
		apiURL: env.REACT_APP_API_URL
	}

	Axios.defaults.baseURL = config.apiURL
	Axios.defaults.timeout = 30000

	Axios.interceptors.request.use(
		(config) => {
			// Before request is sent

			NProgress.start()

			store.dispatch(showLoader())

			return config
		},
		function (error) {
			// Request error
			return Promise.reject(error)
		}
	)

	// Add a response interceptor
	Axios.interceptors.response.use(
		(response) => {
			// Response
			store.dispatch(hideLoader())

			setTimeout(() => {
				NProgress.done()
			}, 1000)

			return response
		},
		function (error) {
			// Response error

			showError = true // Init every time.

			prevValidations.forEach((validation) => {
				if (validation(error)) {
					return (showError = false)
				}
			})

			store.dispatch(hideLoader())

			setTimeout(() => {
				NProgress.done()
			}, 1000)

			if (window.loggingOut) {
				// logout request failed, possibly had an old token. Ignore the error and proceed.
				delete window.loggingOut
			}

			if (error && error.code && error.code === 'ECONNABORTED') {
				debugError({ message: 'Timeout error', stacktrace: error })
				store.dispatch(
					showMessage({
						showError,
						type: 'error',
						message: 'Error',
						description: 'Timeout error'
					})
				)
			} else if (
				!error.response &&
				error.request &&
				error.request.status === 0
			) {
				debugError({ message: 'Network error', stacktrace: error })
				store.dispatch(
					showMessage({
						showError,
						type: 'error',
						message: 'Error',
						description: 'Network error'
					})
				)
			} else if (
				error.response &&
				error.response.request &&
				error.response.request.responseType === 'arraybuffer' &&
				error.response.data.toString() === '[object ArrayBuffer]'
			) {
				// only enters when the error response is of arraybuffer type
				store.dispatch(
					showMessage({
						showError,
						type: 'error',
						message: 'Error',
						description: JSON.parse(
							Buffer.from(error.response.data).toString('utf8')
						)
					})
				)
			} else {
				let errorMessage = ''
				let error500
				let data
				let message
				switch (error.response.status) {
					case 401:
						errorMessage = 'ERROR 401 Logout'
						if (
							!error.response.config.url.includes('/logout') &&
							!error.response.config.url.includes('/login')
						) {
							store.dispatch(userSignOut())
						}
						break
					case 404:
						errorMessage = i18n.t('errors.error404')
						store.dispatch(
							showMessage({
								showError,
								type: 'error',
								message: 'Error 404',
								description: i18n.t('errors.error404')
							})
						)
						break
					case 409:
						errorMessage = 'Error 409'
						store.dispatch(
							showMessage({
								showError,
								type: 'error',
								message: 'Error 409',
								description: error.response.data
							})
						)
						break
					case 403:
						errorMessage =
							error.response && error.response.data
								? error.response.data
								: i18n.t('errors.unauthorized')
						store.dispatch(
							showMessage({
								showError,
								type: 'error',
								message: 'Error',
								description: errorMessage
							})
						)
						break
					case 500:
						error500 = error.response ? error.response.data : error.data
						errorMessage =
							error500 && error500.message
								? error500.message
								: i18n.t('errors.error500')

						store.dispatch(
							showMessage({
								showError,
								type: 'error',
								message: 'Error',
								description: errorMessage
							})
						)
						break
					default:
						data = error.response ? error.response.data : error.data
						message = data
							? data.message || data.toString()
							: i18n.t('errors.error500')
						errorMessage = message

						store.dispatch(
							showMessage({
								showError,
								type: 'error',
								message: 'Error',
								description: message
							})
						)
						break
				}
				debugError({ message: errorMessage, stacktrace: error })
			}
			return Promise.reject(error)
		}
	)

	// Set initial token from store when loading a direct URL
	if (typeof window !== 'undefined') {
		const token = window.localStorage.getItem('token')

		if (token) {
			Axios.defaults.headers.common.Authorization = `Bearer ${token}`
		}
	}
}

export default configureAxios
