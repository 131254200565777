import CommonTable from 'components/common/commonTable'
import { getValueMask } from 'lib/MaskValues'
import React, { Component } from 'react'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Col } from 'reactstrap'
import _ from 'underscore'
import styles from './ProviderPickStock.module.scss'
class ProviderPickStockTable extends Component {
	constructor(props) {
		super(props)
		this.primaryKey = 'id'
		this.state = {}
	}

	/**
	 * to get columns
	 * @returns table columns
	 */
	getColumns = () => {
		const { fieldsTable } = this.props
		const rows = fieldsTable.map((field) => {
			const idField = field.idCampo.trim()
			return {
				headerStyle: function callback(column, colIndex) {
					return { width: field.width }
				},
				dataField: idField,
				text: field.label,
				align: 'center',
				headerAlign: 'center',
				// headerStyle: this.getStyleColumn(field),
				hidden: !field.visible,
				formatter: (cell, row, rowIndex) => {
					return this.renderFormat(field, cell, row)
				}
			}
		})
		return rows
	}

	/**
	 * to render values in table.
	 * @param {object} field
	 * @param {string} value
	 * @param {object} row
	 * @returns
	 */
	renderFormat = (field, value, row) => {
		let result = value

		if (field.mascara) {
			result = getValueMask(value, field.mascara, this.props)
		}

		return result
	}

	/**
	 * to get all pagination options
	 */
	getPaginationOptions = () => {
		const { itemsTable, page, pageSize, totalPage } = this.props

		return itemsTable
			? {
					pageStartIndex: 1,
					sizePerPage: pageSize,
					page: page,
					totalSize: totalPage
			  }
			: {}
	}

	render() {
		const { theme, itemsTable, fieldsTable } = this.props

		return (
			<Col className={theme.containerBorder} sm={12}>
				{!_.isEmpty(fieldsTable) && (
					<CommonTable
						remote
						columns={fieldsTable ? this.getColumns() : []}
						keyField={this.primaryKey}
						data={itemsTable || []}
						rowClasses={theme.tableRow}
						headerClasses={theme.tableHeader}
						paginationOptions={this.getPaginationOptions()}
						onTableChange={this.props.handleChangeTable}
					/>
				)}
			</Col>
		)
	}
}

const mapStateToProps = ({ auth }) => {
	const { authUser } = auth
	return {
		authUser
	}
}

const connectForm = connect(mapStateToProps)(
	withTranslation()(ProviderPickStockTable)
)
export default themr('ProviderPickStockStyles', styles)(connectForm)
