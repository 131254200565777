import {
	faAlignJustify,
	faBuilding,
	faCog,
	faHouseDamage,
	faNewspaper,
	faPlusCircle,
	faSearch,
	faSignOutAlt,
	faUserAlt,
	faUserCog,
	faWindowMaximize
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getVoucherType, userSignOut } from 'actions'
import GlobalNotifications from 'components/common/globalNotifications'
import SelectForm from 'components/selectType/selectForm'
import { isCompanyAllow, isLoggedIn } from 'lib/AuthUtils'
import React, { Component, Fragment } from 'react'
import { Button, Form, Navbar } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { IoCubeSharp } from 'react-icons/io5'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import fullScreen from 'screenfull'
import tinyColor from 'tinycolor2'
import _ from 'underscore'
import {
	ADMIN,
	HOME,
	LANDING,
	LOGIN,
	SEARCH_PRODUCTS,
	SEARCH_VOUCHER
} from '../../utils/RoutePath'
import ChangeCompany from '../company/changeCompany'
import logo from './../../menu_logo.png'
import CommonModal from './commonModal'
import styles from './navbar.module.css'
import TitleComponent from './titleComponent'

class MainBar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showModal: false,
			showModalComp: false,
			showMessageError: false,
			messageError: 'La URL de informes no está configurada',
			typeMessage: 'error'
		}
	}

	handleSingOut = () => {
		this.props.userSignOut()
	}

	componentDidMount = () => {
		const { auth } = this.props
		if (!isLoggedIn(auth)) {
			this.props.history.push(LOGIN) // Accion para cuando se carga el form y se encuentra logeado
		}
	}

	componentDidUpdate = (prevProps) => {
		const { auth } = this.props
		if (prevProps.auth !== auth && !isLoggedIn(auth)) {
			this.props.history.push(LOGIN) // Accion para cuando deslogea
		}

		if (
			prevProps.auth.companyChanged !== auth.companyChanged &&
			isCompanyAllow(auth.authUser)
		) {
			this.setState({ showModal: false })
		}

		this.validateChangePassword(
			prevProps.auth.changePasswordSuccess,
			auth.changePasswordSuccess
		)
	}

	/**
	 * to validate change of password
	 * @param {*} prevChangePassword
	 * @param {*} changePassword
	 */
	validateChangePassword = (prevChangePassword, changePassword) => {
		if (
			!_.isEqual(prevChangePassword, changePassword) &&
			!_.isEmpty(changePassword)
		) {
			this.setState({
				showMessageError: true,
				messageError: changePassword.Mens_error,
				typeMessage: 'success'
			})
		}
	}

	toggleCompanyModal = () => {
		this.setState((prevState) => ({ showModal: !prevState.showModal }))
	}

	/**
	 * to open and close new voucher modal
	 */
	toggleVoucherModal = () => {
		const { params } = this.props.match
		const { showModalComp } = this.state
		const showModal = 'idOperacion' in params ? false : !showModalComp
		this.setState({ showModalComp: showModal })
	}

	fullScreenMode = () => {
		fullScreen.toggle()
	}

	/**
	 * to redirect admin page
	 */
	handleSendPage = (urlPage) => {
		this.props.history.push(urlPage) // Redirect to page
	}

	computeContrastingColor = (rgb) => {
		const color = tinyColor(rgb)
		return color.isLight() ? '#000' : '#FFF'
	}

	/**
	 * to close error message
	 * @param {*} state
	 */
	handleSetShow = (state) => {
		this.setState({ showMessageError: state })
	}

	handleShowReports = () => {
		const { configApp } = this.props.auth.authUser

		if (configApp.url_informes === null) {
			this.setState({ showMessageError: true, typeMessage: 'error' })
		} else {
			window.open(configApp.url_informes)
		}
	}

	render() {
		const { theme, auth, t, userVoucherType, match } = this.props
		const { showCompanyModal, authUser } = auth
		const { usuario, empresa, userCompany } = authUser
		const {
			showModal,
			showModalComp,
			showMessageError,
			messageError,
			typeMessage
		} = this.state

		const showmod =
			match.path.search('landing') !== 1 ? false : showCompanyModal || showModal

		const styleBar = empresa.colorFondo
			? { backgroundColor: empresa.colorFondo }
			: {}
		const backgroundColor = this.computeContrastingColor(empresa.colorFondo)
		const classIcon =
			backgroundColor === '#FFF'
				? theme.iconButton_ligth
				: theme.iconButton_dark

		return (
			<Fragment>
				<GlobalNotifications
					showMessage={showMessageError}
					message={messageError}
					typeMessage={typeMessage}
					setShow={(s) => this.handleSetShow(s)}
					voucherType={userVoucherType}
				/>
				<Navbar
					className={['nav-bar justify-content-between', theme.NavBar]}
					style={styleBar}
				>
					<Form inline className={theme.LogoMenu}>
						<Link
							to={'#'}
							onClick={() => {
								this.props.history.push(`${LANDING}/${userCompany}`)
							}}
						>
							<img src={logo} className={theme.imgLogo} alt="logo" />
						</Link>
						<Button
							variant="link"
							className={`${theme.iconButton_ligth}`}
							onClick={() => this.props.openDrawer(true)}
						>
							<FontAwesomeIcon icon={faAlignJustify} />
						</Button>
						{empresa && (
							<Fragment>
								<TitleComponent title={empresa.nombre} />
								<Button
									className={`${theme.iconButton_ligth} pl-0`}
									variant="link"
									onClick={() => this.toggleCompanyModal()}
								>
									<FontAwesomeIcon icon={faBuilding} />
									{` ${empresa.nombre}`}
								</Button>
								<CommonModal
									showModal={showmod}
									modalTitle={'Empresa'}
									handleCloseModal={this.toggleCompanyModal}
									buttons={false}
									modalBody={
										<ChangeCompany closeWindow={this.toggleCompanyModal} />
									}
								/>
							</Fragment>
						)}
					</Form>
					<Form inline>
						{usuario && (
							<Button className={`${classIcon} ml-3`} variant="link">
								<FontAwesomeIcon icon={faUserAlt} />
								{` ${usuario.nombre}`}
							</Button>
						)}

						<Fragment>
							<Button
								className={`${classIcon} ml-3`}
								variant="link"
								title={t('menu-bar.web_reports')}
								onClick={this.handleShowReports}
							>
								<FontAwesomeIcon icon={faNewspaper} />
							</Button>
							<Button
								className={`${classIcon} ml-3`}
								variant="link"
								title={t('menu-bar.search_products')}
								onClick={() => this.handleSendPage(SEARCH_PRODUCTS)}
							>
								<IoCubeSharp size={19} />
							</Button>
							<Button
								className={`${classIcon} ml-3`}
								variant="link"
								onClick={() => this.handleSendPage(SEARCH_VOUCHER)}
							>
								<FontAwesomeIcon
									title={t('menu-bar.search_vouchers')}
									icon={faSearch}
								/>
							</Button>

							<Button
								className={`${classIcon} ml-3`}
								variant="link"
								onClick={this.toggleVoucherModal}
							>
								<FontAwesomeIcon
									title={t('menu-bar.vouchers')}
									icon={faPlusCircle}
								/>
							</Button>
						</Fragment>
						<Fragment>
							<Button
								className={`${classIcon} ml-3`}
								variant="link"
								onClick={() => this.handleSendPage(`${ADMIN}/A`)}
							>
								<FontAwesomeIcon title={t('menu-bar.cruds')} icon={faUserCog} />
							</Button>

							<Button
								className={`${classIcon} ml-3`}
								variant="link"
								onClick={() => this.handleSendPage(`${ADMIN}/C`)}
							>
								<FontAwesomeIcon title={t('menu-bar.config')} icon={faCog} />
							</Button>
							<Button
								className={`${classIcon} ml-3`}
								variant="link"
								onClick={() => this.handleSendPage(HOME)}
							>
								<FontAwesomeIcon title={'Inicio'} icon={faHouseDamage} />
							</Button>
						</Fragment>
						<Button
							className={`${classIcon} ml-3`}
							variant="link"
							onClick={this.fullScreenMode}
						>
							<FontAwesomeIcon
								title={t('menu-bar.full-screen')}
								icon={faWindowMaximize}
							/>
						</Button>
						<Button
							className={`${classIcon} ml-3`}
							variant="link"
							onClick={this.handleSingOut}
						>
							<FontAwesomeIcon
								title={t('menu-bar.logout')}
								icon={faSignOutAlt}
							/>
						</Button>
					</Form>

					{showModalComp && (
						<CommonModal
							showModal={showModalComp}
							handleCloseModal={this.toggleVoucherModal}
							modalTitle={t('menu-bar.vouchers')}
							sizeModal={'lg'}
							modalBody={
								<SelectForm
									colForm={12}
									optionsSelect={
										userVoucherType.comprobantes
											? userVoucherType.comprobantes
											: []
									}
									userCompany={authUser.userCompany}
									getVoucherType={this.props.getVoucherType}
								/>
							}
						/>
					)}
				</Navbar>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth, vouchertype }) => {
	const { voucherType, userVoucherType } = vouchertype

	return { auth, voucherType, userVoucherType }
}

export default connect(mapStateToProps, { userSignOut, getVoucherType })(
	themr('MenuTheme', styles)(withTranslation()(withRouter(MainBar)))
)
