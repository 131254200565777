import { ICrudGenericFields } from 'models/CrudInterface'

export const voucherSearchFields: Array<ICrudGenericFields> = [
	{
		id: 'lk_nom',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-6',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'lk_niop',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-6',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'lk_modulo',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-4',
		height: 'col-sm-6',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: true,
		comboData: 'voucherModules',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
