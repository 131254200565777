import { autoGenerateConfirm, voucherCancel } from 'actions'
import ConfirmModal from 'components/common/ConfirmModal'
import { API_FORMAT_DATE } from 'constants/ApiFormats'
import { Formik } from 'formik'
import { getInitValues } from 'lib/FieldValidations'
import { IAutoGenerateConfirmParams } from 'models/AutoGenerate'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import AutoGenerateInputs from './AutoGenerateInputs'

interface IAutoGenerateFormProps extends WithTranslation {
	fields?: any
	t?: any
	idOperacion: string
	autoGenerateConfirm: (payload: IAutoGenerateConfirmParams) => void
	voucherCancel: ({ idOperacion }: any) => void
	generatedStatus: any
}
interface IAutoGenerateFormState {
	disableForm: boolean
}
class AutoGenerateForm extends Component<
	IAutoGenerateFormProps,
	IAutoGenerateFormState
> {
	constructor(props: IAutoGenerateFormProps) {
		super(props)
		this.state = {
			disableForm: false
		}
	}

	componentDidUpdate = (prevProps: IAutoGenerateFormProps) => {
		const { generatedStatus } = this.props
		if (
			!_.isEqual(generatedStatus, prevProps.generatedStatus) &&
			!_.isEmpty(generatedStatus)
		) {
			if (generatedStatus.proceso_iniciado === 1) {
				this.setState({ disableForm: true })
			}
		}
	}

	/**
	 * to call api confirm
	 * @param values
	 */
	handleConfirmForm = (values: any) => {
		const { idOperacion } = this.props

		const fecha = values.fecha
			? moment(values.fecha).format(API_FORMAT_DATE)
			: ''
		this.setState((state) => ({ disableForm: !state.disableForm }))

		this.props.autoGenerateConfirm({ ...values, idOperacion, fecha })
	}

	/**
	 * cancel Voucher and exit
	 */
	handleCancel = () => {
		const { idOperacion } = this.props
		this.props.voucherCancel({ idOperacion })
	}

	render() {
		const { fields, t, idOperacion } = this.props
		const { disableForm } = this.state

		const initialValues = fields.length
			? getInitValues(fields, { fecha: new Date() })
			: {}

		const validationSchema = {}
		return (
			<Fragment>
				<Col sm={2}></Col>
				<Formik
					initialValues={initialValues}
					onSubmit={(values: any, actions) => this.handleConfirmForm(values)}
					validationSchema={validationSchema}
					enableReinitialize={true}
					render={(props) => {
						const {
							values,
							handleChange,
							handleBlur,
							setFieldValue,
							setFieldTouched
						} = props

						return (
							<Form
								onSubmit={(e) => props.handleSubmit(e as any)}
								className="voucher-info-form"
							>
								<AutoGenerateInputs
									{...{
										values,
										handleChange,
										handleBlur,
										setFieldValue,
										setFieldTouched,
										fields,
										idOperacion,
										disableForm
									}}
								/>

								<Row className={'pb-2 pr-3'}>
									<Col
										sm={{ span: 2, offset: 8 }}
										className={'text-right mt-2'}
									>
										<ConfirmModal
											messageBody={t('auto_generate.confirmationExitMessage')}
											onSubmitModal={this.handleCancel}
											labelButton={t('global.exit')}
											modalTitle={t('auto_generate.confirmationExitTitle')}
											buttonStyle={{ minWidth: '125px' }}
											typeButton="button"
										/>
									</Col>
									<Col sm={{ span: 2 }} className={'text-right  mt-2'}>
										<Button
											disabled={disableForm}
											style={{ minWidth: '125px' }}
											type={'submit'}
										>
											{t('global.generate')}
										</Button>
									</Col>
								</Row>
							</Form>
						)
					}}
				/>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ autoGenerate }: any) => {
	const { generatedStatus } = autoGenerate
	return { generatedStatus }
}

const mapDispatchToProps = {
	autoGenerateConfirm,
	voucherCancel
}

const connectForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(AutoGenerateForm)

export default withTranslation()(connectForm)
