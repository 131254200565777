import {
	getIngStockSuccess,
	pickeoSearchBarcodeError,
	pickeoSearchBarcodeSuccess,
	pickStockAnmatCancelSuccess,
	pickStockAnmatConfirmSuccess,
	pickStockAnmatSuccess,
	pickStockConfirmSuccess,
	pickStockImportCancelSuccess,
	pickStockImportConfirmError,
	pickStockImportConfirmSuccess,
	pickStockImportError,
	pickStockImportSuccess,
	pickStockTableSuccess
} from 'actions/PickeoIngStock'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
	getIngStock,
	pickeoSearchBarcode,
	pickStockAnmat,
	pickStockAnmatCancel,
	pickStockAnmatConfirm,
	pickStockConfirm,
	pickStockImport,
	pickStockImportCancel,
	pickStockImportConfirm,
	pickStockImportData
} from '../api/PickeoIngStock'
import {
	GET_ING_STOCK,
	PICKEO_SEARCH_BARCODE,
	PICK_STOCK_ANMAT,
	PICK_STOCK_ANMAT_CANCEL,
	PICK_STOCK_ANMAT_CONFIRM,
	PICK_STOCK_CONFIRM,
	PICK_STOCK_IMPORT,
	PICK_STOCK_IMPORT_CANCEL,
	PICK_STOCK_IMPORT_CONFIRM,
	PICK_STOCK_IMPORT_CONFIRM_ERROR,
	PICK_STOCK_IMPORT_ERROR,
	PICK_STOCK_TABLE
} from '../constants/ActionsTypes'

function* getIngStockRequest({ payload }) {
	try {
		const stock = yield call(getIngStock, payload)
		yield put(getIngStockSuccess(stock))
	} catch (error) {}
}

function* pickeoSearchBarcodeRequest({ payload }) {
	try {
		const stock = yield call(pickeoSearchBarcode, payload)
		yield put(pickeoSearchBarcodeSuccess(stock))
	} catch (error) {
		// console.log(error.response, "xxxxxxxxxxxxxx", error, "***");
		if (error.response) {
			const { data } = error.response
			const { Resultado } = data
			const [message, typeError] = !Resultado.Resultado
				? [Resultado.Mens_error, Resultado.Tipo_error]
				: ['', '']
			yield put(
				pickeoSearchBarcodeError({
					message,
					typeError,
					requireFields: {
						ind_lote: data.ind_lote,
						ind_vto: data.ind_vto,
						ind_nserie: data.ind_nserie
					},
					dataFields: {
						lote: data.lote,
						fec_vto: data.fec_vto,
						nserie: data.nserie
					}
				})
			)
		}
	}
}

function* pickStockConfirmRequest({ payload }) {
	const { idOperacion, callBackReturn } = payload
	try {
		const items = yield call(pickStockConfirm, { idOperacion })
		callBackReturn()
		yield put(pickStockConfirmSuccess(items))
	} catch (error) {}
}

function* pickStockImportRequest({ payload }) {
	const formData = new FormData()
	formData.append('archivo', payload.archivo)
	formData.append('idOperacion', payload.idOperacion)

	try {
		const importdata = yield call(pickStockImport, formData)
		yield put(pickStockImportSuccess(importdata))
	} catch (error) {
		const { Resultado } = error.response.data
		const messageError = Resultado.Mens_error || ''
		yield put(
			pickStockImportError({ messageError, error: PICK_STOCK_IMPORT_ERROR })
		)
	}
}

function* pickStockImportConfirmRequest({ payload }) {
	try {
		const stock = yield call(pickStockImportConfirm, payload)
		yield put(pickStockImportConfirmSuccess(stock))
	} catch (error) {
		const { Resultado } = error.response.data
		const messageError = Resultado.Mens_error || ''
		yield put(
			pickStockImportConfirmError({
				messageError,
				error: PICK_STOCK_IMPORT_CONFIRM_ERROR
			})
		)
	}
}

function* pickStockImportCancelRequest({ payload }) {
	try {
		const stock = yield call(pickStockImportCancel, payload)
		yield put(pickStockImportCancelSuccess(stock))
	} catch (error) {}
}

function* pickStockAnmatConfirmRequest({ payload }) {
	try {
		const stock = yield call(pickStockAnmatConfirm, payload)

		yield put(pickStockAnmatConfirmSuccess(stock))
		const importdata = yield call(pickStockImportData, {
			idOperacion: payload.idOperacion,
			page_size: 10,
			page_number: 1,
			mostrar: 'T'
		})
		yield put(pickStockImportSuccess(importdata))
	} catch (error) {}
}

function* pickStockAnmatCancelRequest({ payload }) {
	try {
		const stock = yield call(pickStockAnmatCancel, payload)
		yield put(pickStockAnmatCancelSuccess(stock))
	} catch (error) {}
}

function* pickStockTableRequest({ payload }) {
	try {
		const stock = yield call(pickStockImportData, payload)
		yield put(pickStockTableSuccess(stock))
	} catch (error) {}
}

function* pickStockAnmatRequest({ payload }) {
	try {
		const stock = yield call(pickStockAnmat, payload)
		yield put(pickStockAnmatSuccess(stock))
	} catch (error) {}
}

export function* getIngStockSaga() {
	yield takeLatest(GET_ING_STOCK, getIngStockRequest)
}

export function* pickeoSearchBarcodeSaga() {
	yield takeLatest(PICKEO_SEARCH_BARCODE, pickeoSearchBarcodeRequest)
}

export function* pickStockConfirmSaga() {
	yield takeLatest(PICK_STOCK_CONFIRM, pickStockConfirmRequest)
}

export function* pickStockImportSaga() {
	yield takeLatest(PICK_STOCK_IMPORT, pickStockImportRequest)
}

export function* pickStockImportConfirmSaga() {
	yield takeLatest(PICK_STOCK_IMPORT_CONFIRM, pickStockImportConfirmRequest)
}

export function* pickStockImportCancelSaga() {
	yield takeLatest(PICK_STOCK_IMPORT_CANCEL, pickStockImportCancelRequest)
}

export function* pickStockAnmatConfirmSaga() {
	yield takeLatest(PICK_STOCK_ANMAT_CONFIRM, pickStockAnmatConfirmRequest)
}

export function* pickStockAnmatCancelSaga() {
	yield takeLatest(PICK_STOCK_ANMAT_CANCEL, pickStockAnmatCancelRequest)
}

export function* pickStockTableSaga() {
	yield takeLatest(PICK_STOCK_TABLE, pickStockTableRequest)
}

export function* pickStockAnmatSaga() {
	yield takeLatest(PICK_STOCK_ANMAT, pickStockAnmatRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getIngStockSaga),
		fork(pickeoSearchBarcodeSaga),
		fork(pickStockConfirmSaga),
		fork(pickStockImportSaga),
		fork(pickStockImportConfirmSaga),
		fork(pickStockImportCancelSaga),
		fork(pickStockAnmatConfirmSaga),
		fork(pickStockAnmatCancelSaga),
		fork(pickStockTableSaga),
		fork(pickStockAnmatSaga)
	])
}
