import React, { Component } from 'react'
import { Tab, Tabs } from 'react-bootstrap'

interface customTab {
	title: string
	key: string
	content: any
	disabled?: boolean
}

interface TabsProps {
	showWarning?: boolean
	customTabs: Array<customTab>
	defaultActiveKey?: string
	getActiveKey?: (key: string) => void
	handleWarning?: any
	nextTab?: any
	setNextTab?: any
}

interface TabsState {
	activeTab: string
}

export default class CommonTabs extends Component<TabsProps, TabsState> {
	constructor(props: TabsProps) {
		super(props)
		this.state = {
			activeTab: props.defaultActiveKey || ''
		}
	}

	componentDidUpdate = (prevProps: any) => {
		const { showWarning: prevWarning } = prevProps
		const { showWarning, nextTab } = this.props
		const { activeTab } = this.state
		if (prevWarning !== showWarning && nextTab !== activeTab) {
			this.handleChangeTab(nextTab)
		}
	}

	renderTabs = () => {
		const { customTabs } = this.props

		return customTabs.map((tab) => {
			return (
				<Tab
					eventKey={tab.key}
					key={tab.key}
					title={tab.title}
					disabled={tab.disabled}
				>
					{tab.content}
				</Tab>
			)
		})
	}

	/**
	 * to handle change tab
	 * @param tab
	 */
	handleChangeTab = (tab: string) => {
		const { getActiveKey, showWarning, handleWarning, setNextTab } = this.props
		const { activeTab } = this.state
		setNextTab && setNextTab(tab)
		if (showWarning === true && tab !== activeTab) {
			handleWarning(true)
		} else {
			if (getActiveKey) {
				getActiveKey(tab)
			}
			this.setState({ activeTab: tab })
		}
	}

	render() {
		const { activeTab } = this.state
		const { defaultActiveKey } = this.props

		return (
			<Tabs
				id="uncontrolled-tab-example"
				className="mb-3"
				defaultActiveKey={defaultActiveKey}
				activeKey={activeTab}
				onSelect={(k) => this.handleChangeTab(k || '')}
			>
				{this.renderTabs()}
			</Tabs>
		)
	}
}
