import {
	CRUD_CLEAR_LEDGER_ACCOUNT_COST_CENTER_STORE,
	CRUD_GET_LEDGER_ACCOUNT_COST_CENTER,
	CRUD_GET_LEDGER_ACCOUNT_COST_CENTER_SUCCESS,
	CRUD_UPDATE_LEDGER_ACCOUNT_COST_CENTER,
	CRUD_UPDATE_LEDGER_ACCOUNT_COST_CENTER_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	ledgerAccountCostCentersSearched: any
	ledgerAccountCostCenterUpdated: any
}

const initialState = {
	ledgerAccountCostCentersSearched: null,
	ledgerAccountCostCenterUpdated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_LEDGER_ACCOUNT_COST_CENTER:
			return { ...state, ledgerAccountCostCentersSearched: null }
		case CRUD_GET_LEDGER_ACCOUNT_COST_CENTER_SUCCESS:
			return { ...state, ledgerAccountCostCentersSearched: action.payload }
		case CRUD_UPDATE_LEDGER_ACCOUNT_COST_CENTER:
			return { ...state, ledgerAccountCostCentersUpdated: null }
		case CRUD_UPDATE_LEDGER_ACCOUNT_COST_CENTER_SUCCESS:
			return { ...state, ledgerAccountCostCentersUpdated: action.payload }
		case CRUD_CLEAR_LEDGER_ACCOUNT_COST_CENTER_STORE:
			return {
				...state,
				ledgerAccountCostCentersSearched: null,
				ledgerAccountCostCentersUpdated: null
			}
		default:
			return state
	}
}

export default rootReducer
