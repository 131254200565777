import { ICrudGenericFields } from 'models/CrudInterface'

export const movementsFields: Array<ICrudGenericFields> = [
	{
		id: 'nicfondo',
		type: 'text',
		width: 'col-sm-4',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cat_item',
		type: 'combo',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'itemCategory',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'ninum',
		type: 'combo',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'numerators',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'signo',
		type: 'combo',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'symbols',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_estado',
		type: 'combo',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'tesStates',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'afecta_limcred',
		type: 'check',
		width: 'col-sm-6',
		height: '',
		default: '0',
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_formcompr',
		type: 'text',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'dias_dc',
		type: 'text',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
