import {
	crudGetProfileAbmsSuccess,
	crudUpdateProfileAbmsSuccess
} from 'actions/ProfileAbmsActions'
import { crudGetProfileAbms, crudUpdateProfileAbms } from 'api/ProfileAbmsApi'
import {
	CRUD_GET_PROFILE_ABMS,
	CRUD_UPDATE_PROFILE_ABMS
} from 'constants/ActionsTypes'
import {
	ICrudGetProfileAbmsSaga,
	ICrudUpdateProfileAbmsSaga
} from 'models/CrudProfilesInterface'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

function* crudGetProfileAbmsReq({ payload }: ICrudGetProfileAbmsSaga): any {
	try {
		const abms: any = yield call(crudGetProfileAbms, payload)
		yield put(crudGetProfileAbmsSuccess(abms))
	} catch (error) {}
}

function* crudUpdateProfileAbmsReq({
	payload
}: ICrudUpdateProfileAbmsSaga): any {
	try {
		const abms: any = yield call(crudUpdateProfileAbms, payload)
		yield put(crudUpdateProfileAbmsSuccess(abms))
	} catch (error) {}
}

export function* crudGetProfileAbmsSaga() {
	yield takeLatest(CRUD_GET_PROFILE_ABMS, crudGetProfileAbmsReq)
}

export function* crudUpdateProfileAbmsSaga() {
	yield takeLatest(CRUD_UPDATE_PROFILE_ABMS, crudUpdateProfileAbmsReq)
}

export default function* rootSaga() {
	yield all([fork(crudGetProfileAbmsSaga), fork(crudUpdateProfileAbmsSaga)])
}
