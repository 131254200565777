import { ICrudGenericFields } from 'models/CrudInterface'

export const voucherFieldsFields: Array<ICrudGenericFields> = [
	{
		id: 'campo',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-12',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: true,
		comboData: 'voucherFields',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'idlabel',
		type: 'autoComplete',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-12',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'titles',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'idmascara',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-12',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'masks',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'idvalid',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-12',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'validations',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'valor',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-12',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'validationValues',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'visible',
		type: 'check',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-12',
		default: 1,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'editable',
		type: 'check',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-12',
		default: 1,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'requerido',
		type: 'check',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-12',
		default: 1,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'orden',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-12',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'grupo',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-12',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'placeholder',
		type: 'autoComplete',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-12',
		default: null,
		maxChars: 30,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'titles',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'adicional',
		type: 'check',
		labelWidth: 'col-sm-3',
		width: 'col-sm-8',
		height: 'col-sm-12',
		default: 1,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
