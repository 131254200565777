import { getDetailVoucherAffectations } from 'actions'
import CommonLabelCol from 'components/common/CommonLabelCol'
import CommonTable from 'components/common/commonTable'
import { getCurrencyOptions } from 'lib/FormUtils'
import { getValueMask } from 'lib/MaskValues'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'underscore'
import { SEARCH_VOUCHER_DETAIL } from 'utils/RoutePath'
import InputDropdown from '../form/inputDropdown'
class SearchVoucherAffectationsTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showDetailModal: true,
			currencyFilter: '$',
			affectationsDataTable: [],
			affectInfo: {},
			itemId: ''
		}
		this.primaryKey = 'nro'
	}

	componentDidMount = () => {
		this.handleGetAffectations()
	}

	/**
	 * get all affectations data
	 * @param {*} filters
	 */
	handleGetAffectations = (filters = {}) => {
		const { id, authUser } = this.props
		if (id) {
			this.props.getDetailVoucherAffectations({
				id,
				page_size: 10,
				page_number: 1,
				idEmpresa: authUser.userCompany,
				...filters
			})
		}
	}

	/**
	 * to render las columns
	 * @returns
	 */
	getColumnsTable = () => {
		const { fieldsTable } = this.props

		const rows = fieldsTable.map((field) => {
			const idField = field.idCampo.trim()
			return {
				dataField: idField,
				text: field.label || '',
				align: 'center',
				headerAlign: 'center',
				hidden: !field.visible,
				formatter: (cell, row) => this.renderFormat(field, cell, row)
			}
		})

		return rows
	}

	/**
	 * add format to all table cell
	 */
	renderFormat = (field, value, row) => {
		const { authUser } = this.props
		const idField = field.idCampo.trim()
		let result = ''

		if (idField === 'numero') {
			result = (
				<Link target="_blank" to={`${SEARCH_VOUCHER_DETAIL}/${row.id}/consult`}>
					{value}
				</Link>
			)
		} else if (field.mascara) {
			result = getValueMask(value !== null ? `${value}` : '', field.mascara, {
				authUser
			})
		} else {
			result = value
		}

		return result
	}

	handleChangeTable = () => {}

	/**
	 * use when change select item
	 */
	handleChangeSelect = (itemId) => {
		const { affectationsData } = this.props
		const affect = _.findWhere(affectationsData.items, { nro: itemId })
		this.setState({
			affectationsDataTable: affect.afectaciones,
			affectInfo: affect
		})
	}

	render() {
		const { affectationsData, fields, fieldsTable } = this.props
		const { currencyFilter, affectationsDataTable, itemId, affectInfo } =
			this.state

		const propsTable = {
			remote: true,
			columns: _.isEmpty(fieldsTable) ? [] : this.getColumnsTable(),
			data: affectationsDataTable,
			rowClasses: 'theme.tableRow',
			headerClasses: 'theme.tableHeader',
			paginationOptions: {},
			onTableChange: this.handleChangeTable
		}

		return (
			<Fragment>
				<Row>
					<InputDropdown
						inputFormCol={{ sm: 6 }}
						fields={fields}
						label="label"
						inputId="filtro_it"
						name="filtro_it"
						placeholder=""
						colLabel="col-sm-2"
						colInput="col-sm-10"
						disable={false}
						onBlur={() => {}}
						value={itemId}
						options={
							_.isEmpty(affectationsData)
								? []
								: affectationsData.items.map((affect) => ({
										id: affect.nro,
										label: `${affect.nro} - ${affect.detalle}`
								  }))
						}
						onChange={(data) => {
							const itemId = parseInt(data.target.value)
							this.handleChangeSelect(itemId)
							this.setState({ itemId })
						}}
					/>

					<InputDropdown
						inputFormCol={{ sm: 6 }}
						fields={fields}
						label="C"
						inputId="filtro_moneda"
						name="filtro_moneda"
						placeholder=""
						colLabel="col-sm-2"
						colInput="col-sm-10"
						disable={false}
						onBlur={() => {}}
						value={currencyFilter}
						options={getCurrencyOptions(affectationsData)}
						noInitialExecute
						onChange={(data) => {
							const { value } = data.target
							this.setState({ currencyFilter: value })
							this.handleGetAffectations({ filtro_moneda: value })
						}}
					/>
					<Col md={12}>
						<Row>
							<CommonLabelCol
								formCol={{ sm: 3 }}
								fields={fields}
								labelId="it"
								value={affectInfo.it ? `${affectInfo.it}` : ''}
							/>
							<CommonLabelCol
								colLabel={{ sm: 3 }}
								fields={fields}
								labelId="detalle"
								value={affectInfo.detalle ? `${affectInfo.detalle}` : ''}
							/>
						</Row>
						<Row>
							<CommonLabelCol
								fields={fields}
								labelId="neto"
								value={affectInfo.neto ? `${affectInfo.neto}` : ''}
							/>
							<CommonLabelCol
								fields={fields}
								labelId="imp_pend"
								value={affectInfo.imp_pend ? `${affectInfo.imp_pend}` : ''}
							/>
							<CommonLabelCol
								fields={fields}
								labelId="cant"
								value={affectInfo.cant ? `${affectInfo.cant}` : ''}
							/>
							<CommonLabelCol
								fields={fields}
								labelId="cant_pend"
								value={affectInfo.cant_pend ? `${affectInfo.cant_pend}` : ''}
							/>
						</Row>
					</Col>
					<Col className={'col-12 mt-3'}>
						{!_.isEmpty(fieldsTable) && <CommonTable {...propsTable} />}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ configCrud, searchVoucher, auth }) => {
	const { fieldsConfig } = configCrud
	const { affectationsData } = searchVoucher
	const { authUser } = auth
	return { fieldsConfig, affectationsData, authUser }
}

const connectForm = connect(mapStateToProps, { getDetailVoucherAffectations })(
	SearchVoucherAffectationsTable
)

export default withTranslation()(connectForm)
