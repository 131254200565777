import {
	confirmHeaderSelectionError,
	confirmHeaderSelectionSuccess,
	getHeaderSelectionSuccess,
	validatePurchaseBarcodeSuccess
} from 'actions'
import {
	confirmHeaderSelectionApi,
	getHeaderSelectionApi,
	validatePurchaseBarCodeApi
} from 'api/HeaderSelection'
import {
	CONFIRM_HEADER_SELECTION,
	GET_HEADER_SELECTION,
	VALIDATE_PURCHASE_BARCODE
} from 'constants/ActionsTypes'
import {
	IConfirmHeaderSelectionAction,
	IGetHeaderSelectionAction,
	IValidateBarCodeAction
} from 'models/HeaderSelection'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* getHeaderSelectionRequest({
	payload
}: IGetHeaderSelectionAction): any {
	try {
		const values: any = yield call(getHeaderSelectionApi, payload)
		yield put(getHeaderSelectionSuccess(values))
	} catch (error: any) {
		const { Resultado } = error.response.data
		const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
		yield put(
			confirmHeaderSelectionError({ message: errorMessage, confirm: false })
		)
	}
}

function* confirmHeaderSelectionRequest({
	payload
}: IConfirmHeaderSelectionAction): any {
	try {
		const { values, callBackReturn } = payload
		const resp: any = yield call(confirmHeaderSelectionApi, values)
		yield put(confirmHeaderSelectionSuccess(resp))

		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error: any) {
		const { Resultado } = error.response.data
		const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
		yield put(
			confirmHeaderSelectionError({ message: errorMessage, confirm: true })
		)
	}
}

function* validatePurchaseBarcodeRequest({
	payload
}: IValidateBarCodeAction): any {
	try {
		const barCode: any = yield call(validatePurchaseBarCodeApi, payload)
		yield put(validatePurchaseBarcodeSuccess(barCode))
	} catch (error: any) {
		const { Resultado } = error.response.data
		const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
		yield put(
			confirmHeaderSelectionError({ message: errorMessage, confirm: false })
		)
	}
}

export function* getHeaderSelectionSaga() {
	yield takeEvery(GET_HEADER_SELECTION, getHeaderSelectionRequest)
}

export function* confirmHeaderSelectionSaga() {
	yield takeEvery(CONFIRM_HEADER_SELECTION, confirmHeaderSelectionRequest)
}

export function* validatePurchaseBarcodeSaga() {
	yield takeEvery(VALIDATE_PURCHASE_BARCODE, validatePurchaseBarcodeRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getHeaderSelectionSaga),
		fork(confirmHeaderSelectionSaga),
		fork(validatePurchaseBarcodeSaga)
	])
}
