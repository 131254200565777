import { getConfigVoucher } from 'actions'
import withMenu from 'components/common/withMenu'
import EquivalentProducts from 'components/EquivalentProducts/EquivalentProducts'
import GlobalContainer from 'components/layout/globalContainer'
import { P_CONF_PROD_EQ } from 'constants/ConfigProcessNames'
import { Ihistory } from 'models/NavigationInterface'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

interface IEquivalentProductsProps extends WithTranslation {
	history: Ihistory
	match: any
	getConfigVoucher: any
	t: any
}

class EquivalentProductsContainer extends Component<IEquivalentProductsProps> {
	render() {
		const { t } = this.props

		const containerProps = {
			codeProcess: P_CONF_PROD_EQ,
			configTitles: true
		}

		const propsTable = {
			t: t
		}

		return (
			<Row>
				<GlobalContainer {...containerProps}>
					<EquivalentProducts {...propsTable} />
				</GlobalContainer>
			</Row>
		)
	}
}

const mapStateToProps = ({ auth }: any) => ({})

const connectForm = connect(mapStateToProps, {
	getConfigVoucher
})(EquivalentProductsContainer)

export default withTranslation()(withMenu(withRouter(connectForm)))
