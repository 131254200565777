import Axios from 'axios'
const baseUrl = '/stock/ingr_pickeo'

export const getIngStock = async (params) => {
	const response = await Axios.get(baseUrl, { params })
	return response.data
}

export const pickeoSearchBarcode = async (params) => {
	const response = await Axios.post(`${baseUrl}/cod_barra`, params)
	return response.data
}

export const pickStockConfirm = async (params) => {
	const response = await Axios.post(`${baseUrl}/confirmar`, params)
	return response.data
}

export const pickStockImport = async (params) => {
	const response = await Axios.post('stock/ingresos/impo_mov/importar', params)
	return response.data
}

export const pickStockImportData = async (params) => {
	const response = await Axios.get('stock/ingresos/impo_mov', { params })
	return response.data
}

export const pickStockImportConfirm = async (params) => {
	const response = await Axios.post('stock/ingresos/impo_mov/confirmar', params)
	return response.data
}

export const pickStockImportCancel = async (params) => {
	const response = await Axios.post(
		'stock/ingresos/impo_mov/importar/cancel',
		params
	)
	return response.data
}

export const pickStockAnmatConfirm = async (params) => {
	const response = await Axios.post(
		'stock/ingresos/impo_anmat/confirmar',
		params
	)
	return response.data
}

export const pickStockAnmatCancel = async (params) => {
	const response = await Axios.post(
		'stock/ingresos/impo_mov/importar/cancel',
		params
	)
	return response.data
}

export const pickStockAnmat = async (params) => {
	const response = await Axios.post(
		'stock/ingresos/impo_anmat/importar',
		params
	)
	return response.data
}
