import {
	CLEAR_PURCHASES_HEADER_FILTERS,
	CONFIRM_HEADER_SELECTION,
	CONFIRM_HEADER_SELECTION_ERROR,
	CONFIRM_HEADER_SELECTION_SUCCESS,
	GET_HEADER_SELECTION,
	GET_HEADER_SELECTION_SUCCESS,
	SET_PURCHASES_HEADER_FILTERS,
	VALIDATE_PURCHASE_BARCODE,
	VALIDATE_PURCHASE_BARCODE_SUCCESS
} from 'constants/ActionsTypes'
import {
	IConfirmHeaderSelectionAction,
	IConfirmHeaderSelectionParams,
	IGetHeaderSelectionAction,
	IGetHeaderSelectionParams,
	IValidateBarCodeAction,
	IValidateBarCodeParams
} from 'models/HeaderSelection'

export const getHeaderSelection = (
	payload: IGetHeaderSelectionParams
): IGetHeaderSelectionAction => ({
	type: GET_HEADER_SELECTION,
	payload
})

export const getHeaderSelectionSuccess = (payload: any) => ({
	type: GET_HEADER_SELECTION_SUCCESS,
	payload
})

export const confirmHeaderSelection = (
	payload: IConfirmHeaderSelectionParams
): IConfirmHeaderSelectionAction => ({
	type: CONFIRM_HEADER_SELECTION,
	payload
})

export const confirmHeaderSelectionSuccess = (payload: any) => ({
	type: CONFIRM_HEADER_SELECTION_SUCCESS,
	payload
})

export const confirmHeaderSelectionError = (payload: any) => ({
	type: CONFIRM_HEADER_SELECTION_ERROR,
	payload
})

export const validatePurchaseBarcode = (
	payload: IValidateBarCodeParams
): IValidateBarCodeAction => ({
	type: VALIDATE_PURCHASE_BARCODE,
	payload
})

export const validatePurchaseBarcodeSuccess = (payload: any) => ({
	type: VALIDATE_PURCHASE_BARCODE_SUCCESS,
	payload
})

export const clearPurchasesHeaderFilters = (payload: any) => ({
	type: CLEAR_PURCHASES_HEADER_FILTERS,
	payload
})

export const setPurchasesHeaderFilters = (payload: any) => ({
	type: SET_PURCHASES_HEADER_FILTERS,
	payload
})
