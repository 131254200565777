import {
	crudGetProfileTabs,
	crudUpdateProfileTabs
} from 'actions/ProfileTabsActions'
import CommonTable from 'components/common/commonTable'
import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import NotificationMessage from 'components/common/notificationMessage'
import {
	ICrudGetProfileTabRes,
	ICrudGetProfileTabs,
	ICrudGetProfileTabsResArray,
	ICrudUpdateProfileTabs
} from 'models/CrudProfilesInterface'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import CrudSearchProfileTabsForm from '../SearchForm/CrudSearchProfileTabsForm'

interface ValuesTableProps extends WithTranslation {
	cod_grupo: string
	fields: any
	fieldsSearch: any
	t: any
	disableForm: boolean
	profileTabsSearched: ICrudGetProfileTabsResArray
	profileTabsUpdated: ICrudUpdateProfileTabs
	crudUpdateProfileTabs: (params: ICrudUpdateProfileTabs) => void
	crudGetProfileTabs: (params: ICrudGetProfileTabs) => void
}

interface ValuesTableState {
	alta: number
	errorTitle: string
	errorMessage: string
	typeNotification: string
	showError: boolean
	paramsSearch: ICrudGetProfileTabs
	selectRow?: any
	titleModal: string
	values: ICrudUpdateProfileTabs
	showWarning: boolean
	action: string
	checked: boolean
}

class ValuesTable extends Component<ValuesTableProps, ValuesTableState> {
	constructor(props: ValuesTableProps) {
		super(props)
		this.state = {
			alta: 0,
			paramsSearch: {
				cod_grupo: props.cod_grupo
			},
			errorTitle: '',
			errorMessage: '',
			typeNotification: 'success',
			titleModal: '',
			action: '',
			checked: false,
			showError: false,
			showWarning: false,
			values: {
				cod_grupo: props.cod_grupo,
				lista: []
			}
		}
	}

	componentDidUpdate(prevProps: ValuesTableProps, prevState: ValuesTableState) {
		const {
			profileTabsSearched,
			profileTabsUpdated,
			crudGetProfileTabs,
			cod_grupo
		} = this.props
		const {
			profileTabsSearched: prevProfileTabsSearched,
			profileTabsUpdated: prevProfileTabsUpdated
		} = prevProps

		const { paramsSearch } = this.state
		const { paramsSearch: prevParamsSearch } = prevState

		if (
			profileTabsSearched &&
			profileTabsSearched !== prevProfileTabsSearched
		) {
			const newValues: ICrudUpdateProfileTabs = {
				cod_grupo: cod_grupo,
				lista: profileTabsSearched.lista.map((tab) => {
					return {
						cod_tab: tab.cod_tab,
						niabm: tab.niabm,
						tipo_perm: tab.tipo_perm
					}
				})
			}

			this.setState({ values: newValues })
		}

		if (profileTabsUpdated && profileTabsUpdated !== prevProfileTabsUpdated) {
			crudGetProfileTabs(paramsSearch)
		}

		if (paramsSearch && paramsSearch !== prevParamsSearch) {
			crudGetProfileTabs(paramsSearch)
		}
	}

	handleSearch = (filters: any) => {
		const { cod_grupo } = this.props

		const newParams = {
			cod_grupo,
			cod_tab: filters.lk_cod_tab,
			niabm: filters.lk_niabm,
			busqueda: filters.lk_nom,
			tipo_perm: filters.lk_tipoperm
		}

		this.setState({ paramsSearch: newParams })
	}

	handleClearSearch = () => {
		const { cod_grupo } = this.props
		const newParams = {
			cod_grupo
		}

		this.setState({ paramsSearch: newParams })
	}

	/**
	 * to get columns table.
	 * @returns object
	 */
	getColumns = () => {
		const { fields, t, disableForm, profileTabsSearched } = this.props
		const rows = fields.map((field: any) => {
			const fieldId = field.idCampo.trim()
			return {
				dataField: fieldId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '10%' },
				hidden: !field.visible
			}
		})
		if (!disableForm) {
			rows.push({
				dataField: 'read',
				text: t('CRUDS.general_use.read_abreviation'),
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '3% ' },
				formatter: (cell: any, row: any, rowIndex: any) => {
					const checked = row.tipo_perm === 'L'

					return (
						<input
							type="checkbox"
							onClick={() => this.handleCheckClick('R', !checked, row)}
							checked={checked}
						/>
					)
				},
				headerFormatter: (cell: any, row: any, rowIndex: any) => {
					const checked = !profileTabsSearched?.lista.some((tab) => {
						return tab.tipo_perm !== 'L'
					})

					return (
						<Row className="justify-content-center">
							<div style={{ marginRight: '10px' }}>
								{t('CRUDS.general_use.read_abreviation')}
							</div>
							<input
								style={{ marginRight: '19px' }}
								type="checkbox"
								onClick={() => this.handleWarning(true, 'allR', !checked)}
								checked={checked}
								disabled={_.isEmpty(profileTabsSearched?.lista)}
							/>
						</Row>
					)
				}
			})

			rows.push({
				dataField: 'write',
				text: t('CRUDS.general_use.write_abreviation'),
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '3% ' },
				formatter: (cell: any, row: any, rowIndex: any) => {
					const checked = row.tipo_perm === 'E'

					return (
						<input
							type="checkbox"
							onClick={() => this.handleCheckClick('W', !checked, row)}
							checked={checked}
						/>
					)
				},
				headerFormatter: (cell: any, row: any, rowIndex: any) => {
					const checked = !profileTabsSearched?.lista.some((tab) => {
						return tab.tipo_perm !== 'E'
					})

					return (
						<Row className="justify-content-center">
							<div style={{ marginRight: '10px' }}>
								{t('CRUDS.general_use.write_abreviation')}
							</div>
							<input
								style={{ marginRight: '19px' }}
								type="checkbox"
								onClick={() => this.handleWarning(true, 'allW', !checked)}
								checked={checked}
								disabled={_.isEmpty(profileTabsSearched?.lista)}
							/>
						</Row>
					)
				}
			})
		}

		return rows
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	handleCheckClick = (
		type: string,
		check: boolean,
		id?: ICrudGetProfileTabRes
	) => {
		const { profileTabsSearched, cod_grupo, crudUpdateProfileTabs } = this.props

		let newValues

		switch (type) {
			case 'allR':
				newValues = {
					cod_grupo: cod_grupo,
					lista: profileTabsSearched.lista.map((tab) => {
						return {
							cod_tab: tab.cod_tab,
							niabm: tab.niabm,
							tipo_perm: check === true ? 'L' : 'N'
						}
					})
				}
				break
			case 'allW':
				newValues = {
					cod_grupo: cod_grupo,
					lista: profileTabsSearched.lista.map((tab) => {
						return {
							cod_tab: tab.cod_tab,
							niabm: tab.niabm,
							tipo_perm: check === true ? 'E' : 'N'
						}
					})
				}
				break
			case 'R':
				{
					const checked = check === true ? 'L' : 'N'
					newValues = {
						cod_grupo: cod_grupo,
						lista: profileTabsSearched.lista.map((tab) => {
							return {
								cod_tab: tab.cod_tab,
								niabm: tab.niabm,
								tipo_perm: tab.nro === id?.nro ? checked : tab.tipo_perm
							}
						})
					}
				}
				break
			case 'W':
				{
					const checked = check === true ? 'E' : 'N'
					newValues = {
						cod_grupo: cod_grupo,
						lista: profileTabsSearched.lista.map((tab) => {
							return {
								cod_tab: tab.cod_tab,
								niabm: tab.niabm,
								tipo_perm: tab.nro === id?.nro ? checked : tab.tipo_perm
							}
						})
					}
				}
				break
			default:
				newValues = {
					cod_grupo: cod_grupo,
					lista: []
				}
				break
		}

		crudUpdateProfileTabs(newValues)
	}

	handleWarning = (value: boolean, data: string, checked: boolean) => {
		this.setState({ showWarning: value, action: data, checked })
	}

	render() {
		const { fields, fieldsSearch, profileTabsSearched, t, disableForm } =
			this.props

		const {
			errorTitle,
			errorMessage,
			showError,
			typeNotification,
			showWarning,
			action,
			checked
		} = this.state

		const tableColumns = fields ? this.getColumns() : []

		const propsTable = {
			remote: true,
			columns: tableColumns,
			keyField: 'nro',
			data: profileTabsSearched
				? disableForm
					? profileTabsSearched.lista.filter((abm) => abm.tipo_perm !== 'N')
					: profileTabsSearched.lista
				: [],
			pagination: false,
			classes: 'overflow-hidden'
		}
		const filtersProps = {
			hideNew: true,
			handleSubmit: this.handleSearch,
			handleClearSearch: this.handleClearSearch,
			fields: fieldsSearch,
			alta: 0,
			hideBack: true
		}

		return (
			<Fragment>
				<Col sm={12} className={'mb-1'}>
					<NotificationMessage
						errorTitle={errorTitle}
						errorMessage={errorMessage}
						showError={showError}
						handleCloseError={this.handleCloseError}
						type={typeNotification}
					/>
				</Col>
				<Row className={'justify-content-center mb-5'}>
					<CrudSearchProfileTabsForm {...filtersProps} />
				</Row>
				<Row>
					<Col sm={12} className={'mb-1'}>
						<CommonTable {...propsTable} />
					</Col>
				</Row>
				<ConfirmModalNoButton
					modalTitle={t('CRUDS.modal.title_warning')}
					messageBody={t('CRUDS.modal.message_confirm')}
					labelSubmitButton={t('global.confirm')}
					labelCancelButtonModal={t('global.cancel')}
					showModal={showWarning}
					handleCloseModal={() => this.handleWarning(false, '', false)}
					handleCancelButton={() => {
						this.handleWarning(false, '', false)
					}}
					handleSubmitButton={(e: any) => {
						this.handleCheckClick(action, checked)
						this.handleWarning(false, '', false)
					}}
				/>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ profileTabs }: any) => {
	const { profileTabsSearched, profileTabsUpdated } = profileTabs
	return { profileTabsSearched, profileTabsUpdated }
}

const connectForm = connect(mapStateToProps, {
	crudGetProfileTabs,
	crudUpdateProfileTabs
})(ValuesTable)

export default withTranslation()(withRouter(connectForm))
