import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CommonTable from 'components/common/commonTable'
import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import _ from 'underscore'
class AttributeTable extends Component {
	/**
	 * to render las columns
	 * @returns
	 */
	getColumns = () => {
		const { fields } = this.props
		const fieldsTable = _.filter(fields, (field) => {
			return (
				field.idCampo === 'lk_atributo' || field.idCampo === 'lk_valor_atrib'
			)
		})
		const rows = fieldsTable.map((field) => {
			const idField = field.idCampo.trim()
			return {
				dataField: idField,
				text: field.label || '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: this.getStyleColumn(idField),
				hidden: !field.visible,
				formatter: (cell) => this.formatterRow(cell, idField)
			}
		})

		rows.push({
			dataField: 'actions',
			text: '',
			align: 'center',
			headerAlign: 'center',
			headerStyle: { width: '5%' },
			formatter: (cell, row) => {
				return (
					<Button
						variant="link"
						onClick={() => this.props.removeAttribute(row)}
					>
						<FontAwesomeIcon icon={faMinusCircle} />
					</Button>
				)
			}
		})

		return rows
	}

	/**
	 * to add custom format to cell
	 * @param {*} cell
	 * @param {*} field
	 * @returns
	 */
	formatterRow = (cell, field) => {
		const { attrOptions } = this.props
		let label = cell
		if (field === 'lk_atributo') {
			const attrLabel = _.findWhere(attrOptions, { id: cell })
			label = `${cell} - ${attrLabel.label}`
		}

		return label
	}

	/**
	 * to set style by column
	 * @param {*} idField
	 * @returns
	 */
	getStyleColumn = (idField) => {
		let style = {}

		switch (idField) {
			case 'lk_atributo':
			case 'lk_valor_atrib':
				style = { width: '45%' }
				break

			default:
				style = { width: '10%' }
				break
		}

		return style
	}

	handleChangeTable = () => {}

	render() {
		const { attributes } = this.props

		return (
			<CommonTable
				columns={this.getColumns()}
				keyField="lk_atributo"
				data={attributes}
				rowClasses="theme.tableRow"
				headerClasses="theme.tableHeader"
				paginationOptions={null}
				onTableChange={this.handleChangeTable}
				pagination={false}
			/>
		)
	}
}

export default AttributeTable
