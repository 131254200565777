import { renderInput } from 'lib/FormUtils'
import {
	IGetTableGenericRes,
	IGetTableGenericResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'

type CrudLedgerAccountsDataFieldProps = {
	field: any
	formikProps: any
	crudProps: any
	initialValue: any
}

type CrudLedgerAccountsDataFieldState = {
	fieldConfig: any
	idField: any
	fieldLevel: number
}

class CrudLedgerAccountsCodeField extends Component<
	CrudLedgerAccountsDataFieldProps,
	CrudLedgerAccountsDataFieldState
> {
	constructor(props: CrudLedgerAccountsDataFieldProps) {
		super(props)
		this.state = {
			fieldConfig: {},
			idField: '',
			fieldLevel: 0
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field } = this.props
		const idField = field.idCampo.trim()
		const type = field.agrupador.split('.')

		const fieldConfig = {
			...field,
			tipo:
				type[1] === 'lista'
					? 'combo'
					: type[1] === 'text'
					? 'text'
					: type[1] === 'correlativo'
					? 'dependant'
					: 'generic',
			customProps: {
				maxLength: field.cantdigitos ? field.cantdigitos : 999
			}
		}

		this.setState({
			fieldConfig: fieldConfig,
			idField: idField,
			fieldLevel: parseInt(idField.replace('nivel', ''))
		})
	}

	// Update check to generate new code and fill out dependant values after api response
	componentDidUpdate = (prevProps: CrudLedgerAccountsDataFieldProps) => {
		const { formikProps: prevFormikProps, crudProps: prevCrudProps } = prevProps
		const { formikProps, crudProps } = this.props
		const { idField, fieldConfig } = this.state

		if (
			idField !== 'preview' &&
			prevFormikProps.values[idField] !== formikProps.values[idField]
		) {
			crudProps.crudCreateLedgerAccountsCode({
				alta: 0,
				cod_val: crudProps.cod_val,
				codigo_actual: crudProps.cod_clie,
				valor_niv1: formikProps.values.nivel1 && formikProps.values.nivel1,
				valor_niv2: formikProps.values.nivel2 && formikProps.values.nivel2,
				valor_niv3: formikProps.values.nivel3 && formikProps.values.nivel3,
				valor_niv4: formikProps.values.nivel4 && formikProps.values.nivel4,
				valor_niv5: formikProps.values.nivel5 && formikProps.values.nivel5,
				valor_niv6: formikProps.values.nivel6 && formikProps.values.nivel6,
				valor_niv7: formikProps.values.nivel7 && formikProps.values.nivel7,
				valor_niv8: formikProps.values.nivel8 && formikProps.values.nivel8
			})
		}

		if (
			idField === 'preview' &&
			crudProps.ledgerAccountsCodeCreated !==
				prevCrudProps.ledgerAccountsCodeCreated
		) {
			crudProps.ledgerAccountsCodeCreated &&
				formikProps.setFieldValue(
					idField,
					crudProps.ledgerAccountsCodeCreated.codigo_completo
				)
		}

		if (
			fieldConfig.tipo === 'dependant' &&
			crudProps.ledgerAccountsCodeCreated &&
			crudProps.ledgerAccountsCodeCreated !==
				prevCrudProps.ledgerAccountsCodeCreated
		) {
			const defaultId = 'default_' + idField
			const value = crudProps.ledgerAccountsCodeCreated[defaultId]
			formikProps.setFieldValue(idField, value)
		}

		if (fieldConfig.disabled === true && formikProps.values[idField]) {
			formikProps.setFieldValue(idField, '')
		}
	}

	// Setup combo field if it's empty
	comboDataField = (data: IGetTableGenericResArray) => {
		const values: { cod_valor: string; desc_valor: string }[] = []
		data &&
			_.each(data, (opt: IGetTableGenericRes) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	renderInputs = (formikProps: any, disableForm: boolean) => {
		const { crudProps } = this.props
		const { fieldConfig, idField, fieldLevel } = this.state
		const field = fieldConfig

		if (field.tipo === 'combo') {
			field.valores = crudProps.ledgerAccountsCodeInfo
				? this.comboDataField(crudProps.ledgerAccountsCodeInfo[idField])
				: []
		}

		if (
			crudProps.ledgerAccountsCodeConfig?.campos[fieldLevel - 2]
				?.cantdigitos !==
				formikProps.values['nivel' + (fieldLevel - 1)]?.length &&
			idField !== 'nivel1'
		) {
			field.disabled = true
		} else {
			field.disabled = false
		}

		!field.editable && (field.disabled = true)
		!field.editable && (field.editable = true)

		if (idField === 'preview') {
			field.mascara = 'PlanCta1'
		}

		if (field.visible) {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { formikProps, crudProps } = this.props
		return (
			<Fragment>
				{this.renderInputs(formikProps, crudProps.disableForm)}
			</Fragment>
		)
	}
}

export default CrudLedgerAccountsCodeField
