import {
	crudGetProfileUsersSuccess,
	crudUpdateProfileUsersSuccess
} from 'actions'
import {
	crudGetProfileUsers,
	crudUpdateProfileUsers
} from 'api/ProfileUsersApi'
import {
	ICrudGetProfileUsersSaga,
	ICrudUpdateProfileUsersSaga
} from 'models/CrudProfilesInterface'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
	CRUD_GET_PROFILE_USERS,
	CRUD_UPDATE_PROFILE_USERS
} from '../constants/ActionsTypes'

function* crudGetProfileUsersReq({ payload }: ICrudGetProfileUsersSaga): any {
	try {
		const users = yield call(crudGetProfileUsers, payload)
		yield put(crudGetProfileUsersSuccess(users))
	} catch (error) {}
}

function* crudUpdateProfileUsersReq({
	payload
}: ICrudUpdateProfileUsersSaga): any {
	try {
		const users = yield call(crudUpdateProfileUsers, payload)
		yield put(crudUpdateProfileUsersSuccess(users))
	} catch (error) {}
}

export function* crudGetProfileUsersSaga() {
	yield takeLatest(CRUD_GET_PROFILE_USERS, crudGetProfileUsersReq)
}

export function* crudUpdateProfileUsersSaga() {
	yield takeLatest(CRUD_UPDATE_PROFILE_USERS, crudUpdateProfileUsersReq)
}

export default function* rootSaga() {
	yield all([fork(crudGetProfileUsersSaga), fork(crudUpdateProfileUsersSaga)])
}
