import Axios from 'axios'
import {
	IDeleteSearchedProductsParams,
	IGetMainDataParams,
	IGetSalesDataParams,
	IGetStockParams,
	IGetSuggestParams,
	ISelectSearchedProductParams
} from 'models/SearchProduct'

export const getProductsOrderOptionsApi = async () => {
	const response = await Axios.get('tablas/ordenProd')
	return response.data
}

export const productCategoryFilterApi = async () => {
	const response = await Axios.get('tablas/FiltroCatProd')
	return response.data
}

export const getFilterProductAttributesApi = async () => {
	const response = await Axios.get('tablas/AtribProd')
	return response.data
}

export const getDetailMainDataApi = async (params: IGetMainDataParams) => {
	const response = await Axios.get('cons_productos/ppal', { params })
	return response.data
}

export const getDetailStockApi = async (params: IGetStockParams) => {
	const response = await Axios.get('cons_productos/stock', { params })
	return response.data
}

export const getDetailSalesApi = async (params: IGetSalesDataParams) => {
	const response = await Axios.get('cons_productos/ventas', { params })
	return response.data
}

export const getDetailShoppingApi = async (params: IGetSalesDataParams) => {
	const response = await Axios.get('cons_productos/compras', { params })
	return response.data
}

export const getSuggestedDataApi = async (params: IGetSuggestParams) => {
	const response = await Axios.get('cons_productos/sugeridos', { params })
	return response.data
}

export const getEquivalentDataApi = async (params: IGetSuggestParams) => {
	const response = await Axios.get('cons_productos/equivalentes', { params })
	return response.data
}

export const getStockOrderOptionsApi = async () => {
	const response = await Axios.get('tablas/OrdenStProd')
	return response.data
}

export const getPeriodFilterApi = async () => {
	const response = await Axios.get('tablas/FiltroPeriodo')
	return response.data
}

export const selectSearchedProductsApi = async (
	params: ISelectSearchedProductParams
) => {
	const response = await Axios.post('cons_productos/seleccionar', params)
	return response.data
}

export const deleteSearchedProductsApi = async (
	params: IDeleteSearchedProductsParams
) => {
	const response = await Axios.post(
		`cons_productos/eliminar?idOperacion=${params.idOperacion}`
	)
	return response.data
}
