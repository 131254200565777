import {
	ICrudGetCCAssignations,
	ICrudGetCCAssignationsResArray,
	ICrudUpdateCCAssignationsArray,
	ICrudUpdateCCAssignationsResArray
} from 'models/CrudDirectCCAssignationInterface'
import {
	CRUD_CLEAR_DIRECT_CC_ASSIGNATIONS_STORE,
	CRUD_GET_DIRECT_CC_ASSIGNATIONS,
	CRUD_GET_DIRECT_CC_ASSIGNATIONS_SUCCESS,
	CRUD_UPDATE_DIRECT_CC_ASSIGNATIONS,
	CRUD_UPDATE_DIRECT_CC_ASSIGNATIONS_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetCCAssignations = (payload: ICrudGetCCAssignations) => {
	return {
		type: CRUD_GET_DIRECT_CC_ASSIGNATIONS,
		payload
	}
}

export const crudGetCCAssignationsSuccess = (
	payload: ICrudGetCCAssignationsResArray
) => {
	return {
		type: CRUD_GET_DIRECT_CC_ASSIGNATIONS_SUCCESS,
		payload
	}
}

export const crudUpdateCCAssignations = (
	payload: ICrudUpdateCCAssignationsArray
) => {
	return {
		type: CRUD_UPDATE_DIRECT_CC_ASSIGNATIONS,
		payload
	}
}

export const crudUpdateCCAssignationsSuccess = (
	payload: ICrudUpdateCCAssignationsResArray
) => {
	return {
		type: CRUD_UPDATE_DIRECT_CC_ASSIGNATIONS_SUCCESS,
		payload
	}
}

export const crudClearCCAssignationsStore = () => ({
	type: CRUD_CLEAR_DIRECT_CC_ASSIGNATIONS_STORE
})
