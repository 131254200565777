import withMenu from 'components/common/withMenu'
import InventoryExpensesStockForm from 'components/InventoryExpensesStock/InventoryExpensesStockForm'
import GlobalContainer from 'components/layout/globalContainer'
import { P_INVETORY_EXPENSE } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'

interface InventoryPickStockProps {}
interface InventoryPickStockState {
	isVisibleBarCode: boolean
}

class InventoryExpensesStock extends Component<
	InventoryPickStockProps,
	InventoryPickStockState
> {
	inventoryChildRef: any
	inventoryFormRef: any
	constructor(props: InventoryPickStockProps) {
		super(props)
		this.inventoryChildRef = React.createRef()
		this.inventoryFormRef = React.createRef()
		this.state = {
			isVisibleBarCode: true
		}
	}

	getShortcuts = () => {
		const shortcuts = [
			{
				hotkey: { charCode: 'F2', modifiers: ['f2'] },
				action: this.changeInput,
				name: 'Change input search',
				description: 'Change input search'
			},
			{
				hotkey: { charCode: 'F4', modifiers: ['f4'] },
				action: this.toggleCant,
				name: 'change between enable and disable',
				description: 'change between enable and disable'
			}
		]

		return shortcuts
	}

	changeInput = () => {
		this.setState((state) => ({
			isVisibleBarCode: !state.isVisibleBarCode
		}))
	}

	toggleCant = () => {
		this.inventoryChildRef.current.toggleDisableCant()
	}

	handleCallBackAction = () => {
		this.inventoryFormRef.current.handleSubmit()
	}

	render() {
		const { isVisibleBarCode } = this.state
		const containerProps = {
			codeProcess: P_INVETORY_EXPENSE,
			shortcuts: this.getShortcuts(),
			nextPage: true,
			callBackButton: this.handleCallBackAction
		}
		return (
			<Row>
				<GlobalContainer {...containerProps}>
					<InventoryExpensesStockForm
						ref={this.inventoryChildRef}
						inventoryFormRef={this.inventoryFormRef}
						isVisibleBarCode={isVisibleBarCode}
					/>
				</GlobalContainer>
			</Row>
		)
	}
}

export default withMenu(InventoryExpensesStock)
