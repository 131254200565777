import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment, PureComponent } from 'react'
import { Button } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { Link } from 'react-router-dom'
import styles from './inputButton.module.css'

class InputButton extends PureComponent {
	handleOnclick = () => {
		const { onClick } = this.props
		if (onClick) {
			onClick()
		}
	}

	render() {
		const {
			theme,
			backButton,
			nextButton,
			valueButton,
			urlForm,
			type,
			customStyle,
			onClick,
			disabled
		} = this.props
		const withStyle =
			nextButton || backButton ? null : { ...customStyle, minWidth: '100px' }

		const pathTo =
			!nextButton && !onClick ? (urlForm ? { pathname: urlForm } : {}) : {}

		const labelButton =
			nextButton || backButton ? (
				nextButton ? (
					<FontAwesomeIcon icon={faAngleRight} />
				) : backButton ? (
					<FontAwesomeIcon icon={faAngleLeft} />
				) : (
					'Button'
				)
			) : (
				valueButton
			)

		return (
			<Fragment>
				{!type && (
					<Link
						onClick={this.handleOnclick}
						className={theme.linkClass}
						to={pathTo}
					>
						<div
							className={`btn btn-primary ${theme.formButton}`}
							style={withStyle}
						>
							{labelButton}
						</div>
					</Link>
				)}
				{type === 'primary' && (
					<Button
						type="primary"
						disabled={disabled}
						style={customStyle}
						className={`btn btn-primary ${theme.formButton}`}
						onClick={() => this.props.onClick()}
					>
						{labelButton}
					</Button>
				)}
				{type === 'light' && (
					<Button
						className={`btn btn-light  ${theme.buttonLight}`}
						style={customStyle}
						disabled={disabled}
						onClick={() => this.props.onClick()}
					>
						{labelButton}
					</Button>
				)}
				{type === 'button' && (
					<Button
						disabled={disabled}
						style={customStyle}
						className={`btn btn-primary ${theme.formButton}`}
						onClick={() => this.props.onClick()}
					>
						{labelButton}
					</Button>
				)}
			</Fragment>
		)
	}
}

export default themr('InputButtonStyles', styles)(InputButton)
