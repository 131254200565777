import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import NotificationError from 'components/common/notificationsErrors'
import { FormRefsContext } from 'context/FormRefsContext'
import { Formik } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { renderFormInputs } from 'lib/FormUtils'
import React, { Component, Fragment } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'

const noRenderInputs = ['cancelar', 'aceptar']

class CrudNew extends Component {
	/**
	 * to add callbacks to fields.
	 * @param {object} fields
	 * @param {object} props
	 * @returns html inputs
	 */

	/**
	 * to show the form fields from the api configuration     *
	 */
	// Function to fill combo data
	comboDataField = (data) => {
		const values = []
		data &&
			_.each(data.lista, (opt) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	renderInputs = (fields, props) => {
		const { disableForm, itemInfo } = this.props

		fields.forEach((field) => {
			const idField = field.idCampo.trim()
			if (idField === 'cod_mens') {
				if (itemInfo) {
					field.disabled = true
				}
				field.customProps = { maxLength: '3' }
				field.colInput = 'col-sm-1'
			} else if (idField === 'mensaje') {
				field.tipo = 'textArea'
			}
		})
		return renderFormInputs(props, fields, noRenderInputs, disableForm)
	}

	/**
	 * to get data of sellers
	 */

	render() {
		const {
			fields,
			t,
			handleSubmit,
			itemInfo,
			disableForm,
			showWarning,
			handleWarning,
			setFormChanged
		} = this.props

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})

		const columnsCancel = disableForm ? 12 : 6
		const validationSchema = fields.length
			? getValidationSchema(fields, t, [])
			: {}

		return (
			<FormRefsContext.Consumer>
				{({ formRef, cancelButtonRef }) => (
					<Formik
						ref={formRef}
						initialValues={initialValues}
						onSubmit={(values, actions) => {
							handleSubmit(values)
						}}
						validationSchema={validationSchema}
						enableReinitialize={true}
						render={(props) => (
							<Fragment>
								<Col sm={12}>
									<NotificationError
										errors={props.errors}
										touched={props.touched}
									/>
								</Col>
								<Form
									onSubmit={props.handleSubmit}
									className="voucher-info-form"
									onChange={(e) => {
										setFormChanged && setFormChanged(true)
									}}
								>
									{fields && this.renderInputs(fields, props)}

									<Row className={'pt-3'}>
										{!disableForm && (
											<Col sm={6} className={'text-center mt-2'}>
												<Button style={{ minWidth: '145px' }} type={'submit'}>
													{t('Collection.form.buttons.accept')}
												</Button>
											</Col>
										)}
										<Col sm={columnsCancel} className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={this.props.handleCloseModal}
												ref={cancelButtonRef}
											>
												{t('Collection.form.buttons.cancel')}
											</Button>
										</Col>
									</Row>
									<ConfirmModalNoButton
										modalTitle={t('CRUDS.modal.title_warning')}
										messageBody={t('CRUDS.modal.message_changes')}
										showModal={showWarning}
										handleCloseModal={() => handleWarning(false, 'close')}
										handleCancelButton={() => {
											handleWarning(false, 'cancel')
										}}
										handleSubmitButton={(e) => {
											props.handleSubmit(e)
											handleWarning(false, 'submit')
										}}
									/>
								</Form>
							</Fragment>
						)}
					/>
				)}
			</FormRefsContext.Consumer>
		)
	}
}

export default withTranslation()(withRouter(CrudNew))
