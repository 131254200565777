export const HOME = '/'
export const LOGIN = '/login'
export const ORDER = '/order'
export const VOUCHER = '/voucher'
export const HEADERBOARD = '/headerboard'
export const GENERATE = '/generate'
export const LANDING = '/landing'
export const LOADITEMS = '/loaditems'
export const INDLOADITEMS = '/indLoaditems'
export const PROVIDERINDLOADITEMS = '/provIndLoaditems'
export const SELECTTYPE = '/selecttype'
export const VOUCHERINVOLVEMENT = '/involvement'
export const VOUCHERAFFECTING = '/voucherAffecting'
export const VOUCHERSTATE = '/voucherState'
export const ACCOUNTINGSEAT = '/accountingSeat'
export const ACCOUNTINGVOUCHER = '/accountingVoucher'
export const COLLECTION = '/collection'
export const PROVIDER = '/provider'
export const PROVIDERHEADER = '/providerHeader'
export const PROVIDERLOADITEMS = '/providerLoadItems'
export const PROVIDERAFFECTING = '/providerAfctQuant'
export const AFFECTATION_AMOUNTS = '/affectationAmounts'
export const PROVIDER_AFFECT_STATE = '/providerAfctState'
export const PROVIDER_PICK_STOCK = '/providerPickStock'
export const PICK_STOCK = '/pickStock'
export const ADMIN = '/admin'
export const SUGESTED_PROD_CONF = '/sugestedProductConf'
export const EQUIVALENT_PROD_CONF = '/equivalentProductConf'

export const SEARCH_VOUCHER = '/searchVoucher'
export const SEARCH_VOUCHER_DETAIL = '/searchVoucherDetail'
export const SEARCH_PRODUCTS = '/searchProducts'
export const SEARCHED_PRODUCT_DETAILS = '/searchedProductDetails'
export const BUDGET = '/budget'
export const HEADER_SELECTION = '/headerSelection'
export const FINAL_CONSUMER = '/finalConsumer'
export const AUTO_GENERATE = '/autoGenerate'
export const AUTOMATIC_PURCHASE_ORDER = '/automaticPurchaseOrder'
export const HEADER_DATA_VALIDATION_PROCESS = '/headerDataValidationProcess'
export const CALCULATION_BONUSES = '/calculationBonuses'
export const INVENTORY_PICK_STOCK = '/inventoryPickStock'
export const INVENTORY_EXPENSE_STOCK = '/inventoryExpenseStock'
export const LOAD_PACKAGES_DISPATCH = '/loadPackages'
export const HEADER_ENTRY = '/headerEntry'
export const CRUD_ATTR_ACCOUNT_PAYABLE_DATA = 'attrAccountPayableCrud'
export const CRUD_COURIER_DATA = 'courierCrud'
export const CRUD_SELLER_DATA = 'sellerCrud'
export const CRUD_ATTR_CUSTOMER_DATA = 'customerAttrCrud'
export const CRUD_ATTR_PROVIDER_DATA = 'providerAttrCrud'
export const CRUD_ATTR_ACCOUNT_RECEIVABLE_DATA = 'attrAccountReceivableCrud'
export const CRUD_ATTR_TREASURY_RECEIPTS_DATA = 'attrTreasuryReceiptsCrud'
export const CRUD_ATTR_TREASURY_ACCOUNTS_DATA = 'attrTreasuryAccountsCrud'
export const CRUD_ATTR_PRODUCT_DATA = 'productAttrCrud'
export const CRUD_VOUCHER_CONFIG_DATA = 'voucherConfigCrud'
export const CRUD_MESSAGES_DATA = 'messagesCrud'
export const CRUD_FUNDS_CONCEPTS_DATA = 'fundsConceptsCrud'
export const CRUD_ACCOUNTING_PERIODS_DATA = 'accountingPeriodsCrud'
export const CRUD_ATTR_INVENTORY_VOUCHERS_DATA = 'attrInventoryVouchersCrud'
export const CRUD_TERMS_OF_SALE_DATA = 'termsOfSaleCrud'
export const CRUD_CUSTOMER_DATA = 'customerCrud'
export const CRUD_FUNDS_ACCOUNTS_DATA = 'fundsAccountsCrud'
export const CRUD_LEDGER_ACCOUNTS_DATA = 'ledgerAccountsCrud'
export const CRUD_COST_CENTERS_DATA = 'costCentersCrud'
export const CRUD_CC_ASSIGNATIONS = 'costCenterAssignCrud'
export const CRUD_LA_ASSIGNATIONS = 'ledgerAccountAssignCrud'
export const CRUD_PRODUCTS_DATA = 'productsCrud'
export const CRUD_PROVIDER_DATA = 'providerCrud'
export const CRUD_PURCHASE_CONDITIONS_DATA = 'purchaseConditionsCrud'
export const CRUD_USER_DATA = 'userCrud'
export const CRUD_PROFILES_DATA = 'profilesCrud'
export const CRUD_PRICES_LIST = 'pricesListCrud'
export const CRUD = '/crud'
export const CRUD_EDIT = '/crudEdit'
export const CRUD_WRONG_ABM = 'wrongCrud'
export const LOAD_ITEMS_INV = '/loadItemsInv'
export const LOAD_ITEMS_INCOME_INV = '/loadItemsIncomeInv'
export const TREASURY_HEADER = '/treasuryHeader'
