import Axios from 'axios'
import {
	ICrudGetProfileAbms,
	ICrudUpdateProfileAbms
} from 'models/CrudProfilesInterface'

const urlCRUD = '/abm_perfiles/abm'

export const crudGetProfileAbms = async (params: ICrudGetProfileAbms) => {
	const response = await Axios.get(`${urlCRUD}/buscar`, { params })
	return response.data
}

export const crudUpdateProfileAbms = async (params: ICrudUpdateProfileAbms) => {
	const response = await Axios.post(`${urlCRUD}/confirmar`, params)
	return response.data
}
