import {
	getTableBranchOffices,
	getTableCountry,
	getTableCouriers,
	getTableCredit,
	getTableIsPostalCodeValid,
	getTableProvince,
	getTableSellers,
	getTableTermsOfSales
} from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { ICrudCustomerField } from 'models/CrudCustomerInterface'
import {
	IGetTableBranchOfficesResArray,
	IGetTableCountryResArray,
	IGetTableCouriersResArray,
	IGetTableCredit,
	IGetTableProvinceResArray,
	IGetTableSellersResArray,
	IGetTableTermOfSalesResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import * as Yup from 'yup'
import CrudCustomerDataField from '../CrudCustomerDataField'

type ComponentProps = {
	fields: Array<ICrudCustomerField>
	handleSubmit: any
	itemInfo?: any
	disableForm?: boolean
	handleCloseModal: any
	getTableProvince: any
	getTableCountry: any
	getTableTermsOfSales: any
	getTableSellers: any
	getTableCouriers: any
	getTableCredit: any
	getTableBranchOffices: any
	getTableIsPostalCodeValid: any
	province: IGetTableProvinceResArray
	country: IGetTableCountryResArray
	termsOfSales: IGetTableTermOfSalesResArray
	sellers: IGetTableSellersResArray
	couriers: IGetTableCouriersResArray
	branchOffices: IGetTableBranchOfficesResArray
	isPostalCodeValid: number
	credit: IGetTableCredit
	renderFormInputs: any
	niclie: number
	alta: number
	t: any
	method: any
}

type ComponentState = {
	rate: any
}

class ComponentUpdate extends Component<ComponentProps, ComponentState> {
	constructor(props: ComponentProps) {
		super(props)
		this.state = {
			rate: []
		}
	}

	/**
	 * to add callbacks to fields.
	 * @param {object} fields
	 * @param {object} props
	 * @returns html inputs
	 */

	componentDidMount = () => {
		this.props.getTableProvince()
		this.props.getTableCountry({ por_defecto: 'ARG' })
		this.props.getTableCouriers()
		this.props.getTableTermsOfSales()
		this.props.getTableSellers()
	}

	/**
	 * to get data of sellers
	 */

	render() {
		const {
			fields,
			t,
			handleSubmit,
			itemInfo,
			disableForm,
			alta,
			isPostalCodeValid
		} = this.props

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})

		const columnsCancel = disableForm ? 12 : 6
		const validationSchema = fields.length
			? getValidationSchema(
					fields,
					t,
					[],
					[
						{
							id: 'cpos',
							validation: Yup.string().test(
								'ValidCpos',
								t('validation-invalid', { field: 'Cod.Postal' }),
								(cpos: any) => {
									return isPostalCodeValid === 1
								}
							)
						}
					]
			  )
			: {}

		const subCategories: Array<string> = []

		_.each(
			fields,
			(
				field: ICrudCustomerField,
				index: number,
				array: Array<ICrudCustomerField>
			) => {
				if (array[index - 1]) {
					if (field.agrupador !== array[index - 1].agrupador) {
						subCategories.push(field.agrupador)
					}
				} else {
					subCategories.push(field.agrupador)
				}
			}
		)

		const allFields = _.map(subCategories, (category: string) => {
			const id = category
			const filterFields = fields.filter((field: ICrudCustomerField) => {
				return field.agrupador === category
			})
			return { name: id, fields: filterFields }
		})

		return (
			<>
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						handleSubmit(values)
					}}
					validationSchema={validationSchema}
					enableReinitialize={true}
				>
					{(props: FormikProps<any>) => (
						<Fragment>
							<Col sm={12}>
								<NotificationError
									errors={props.errors}
									touched={props.touched}
								/>
							</Col>
							<Form>
								{_.map(
									allFields,
									(category: {
										name: string
										fields: Array<ICrudCustomerField>
									}) => {
										return (
											<Fragment key={category.name}>
												<Alert variant={'dark'}>
													<b>
														{t(
															'CRUDS.general_use.' +
																category.name.replace(/[.]/g, '')
														)}
													</b>
												</Alert>
												{(alta === 1 || (alta === 0 && itemInfo)) &&
													_.map(
														category.fields,
														(field: ICrudCustomerField) => {
															return (
																<CrudCustomerDataField
																	key={field.idCampo}
																	formikProps={props}
																	field={
																		field.idCampo === 'suc_clie' && alta === 0
																			? { ...field, disabled: true }
																			: field
																	}
																	crudProps={this.props}
																	initialValue={
																		initialValues[
																			field.idCampo as keyof typeof initialValues
																		]
																	}
																/>
															)
														}
													)}
											</Fragment>
										)
									}
								)}
								<Row className={'pt-3'}>
									{!disableForm && (
										<Col sm={6} className={'text-center mt-2'}>
											<Button style={{ minWidth: '145px' }} type={'submit'}>
												{t('Collection.form.buttons.accept')}
											</Button>
										</Col>
									)}
									<Col sm={columnsCancel} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px' }}
											type={'button'}
											onClick={this.props.handleCloseModal}
										>
											{t('Collection.form.buttons.cancel')}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					)}
				</Formik>
			</>
		)
	}
}

const mapStateToProps = ({ tableCalls, validationResponses }: any) => {
	const { province, country, termsOfSales, sellers, couriers, branchOffices } =
		tableCalls
	const { isPostalCodeValid } = validationResponses
	return {
		province,
		country,
		termsOfSales,
		sellers,
		couriers,
		isPostalCodeValid,
		branchOffices
	}
}

export default withTranslation()<any>(
	withRouter(
		connect(mapStateToProps, {
			getTableIsPostalCodeValid,
			getTableProvince,
			getTableCountry,
			getTableTermsOfSales,
			getTableSellers,
			getTableCouriers,
			getTableCredit,
			getTableBranchOffices
		})<any>(ComponentUpdate)
	)
)
