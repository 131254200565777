import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import _ from 'underscore'
import styles from './inputAutocomplete.module.css'

class InputAutocomplete extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			isSelected: false,
			isInvalid: false
		}
	}

	_cache = {}

	_handleInputChange = (query) => {
		this.setState({ query })
	}

	_handlePagination = (e, shownResults) => {
		const { query } = this.state
		const cachedQuery = this._cache[query]

		// Don't make another request if:
		// - the cached results exceed the shown results
		// - we've already fetched all possible results
		if (
			cachedQuery.options.length > shownResults ||
			cachedQuery.options.length === cachedQuery.total_count
		) {
			return
		}

		this.setState({ isLoading: true })
		// const page = cachedQuery.page + 1;
	}

	_handleSearch = (query) => {
		if (this._cache[query]) {
			this.setState({ options: this._cache[query].options })
			return
		}

		this.props.handleSearch(query)
	}

	getConfigField = (id) => {
		const { fields } = this.props
		let result = {}
		if (fields) {
			fields.forEach((field) => {
				if (field.idCampo.trim() === id) {
					result = field
				}
			})
		}

		return result
	}

	render() {
		const {
			label,
			placeholder,
			name,
			inputId,
			colInput,
			colLabel,
			styleLabel,
			divStyle,
			theme,
			auoptions,
			handleLoading,
			labelKey,
			disable,
			inputFormCol,
			refs,
			minLength,
			defaultValue,
			t,
			clearButton,
			onChange,
			handleSelect
		} = this.props

		const classInput = label ? colInput : 'col-sm-12'
		const classLabel = label ? colLabel : ''
		const inputConfig = this.getConfigField(inputId)
		const finalLabel =
			inputConfig && inputConfig.label ? inputConfig.label : label
		const customStyleLabel =
			inputConfig && inputConfig.requerido
				? { ...styleLabel, color: 'red' }
				: { ...styleLabel }

		const showInput = inputConfig.idCampo ? inputConfig.visible : true

		if (!showInput) {
			return false
		} else {
			return (
				<Col {...inputFormCol}>
					<Row className={'form-group'}>
						<label
							className={`${theme.inputLabel}  ${classLabel}`}
							style={customStyleLabel}
						>
							{finalLabel}
						</label>
						<Col className={classInput} style={{ ...divStyle }}>
							<AsyncTypeahead
								ref={refs}
								clearButton={clearButton || false}
								disabled={disable}
								isLoading={handleLoading}
								id={inputId}
								options={auoptions}
								name={name}
								labelKey={labelKey}
								filterBy={(option, props) => {
									return true
								}}
								minLength={minLength || 3}
								onSearch={this._handleSearch}
								onChange={(selected) => {
									onChange(selected)
									handleSelect(selected)
									if (_.isEmpty(selected)) {
										this.setState({ isInvalid: true })
									} else {
										this.setState({ isSelected: true })
										this.setState({ isInvalid: false })
									}
								}}
								onInputChange={() => {
									this.setState({ isSelected: false })
								}}
								className={`${theme.inputText}`}
								placeholder={inputConfig.placeholder || placeholder}
								onBlur={this.props.onBlur}
								isValid={this.state.isSelected}
								isInvalid={this.state.isInvalid}
								renderMenu={(results, menuProps) => (
									<Menu {...menuProps} style={{ width: 'auto' }}>
										{results.map((result, index) => (
											<MenuItem
												key={result.id}
												option={result}
												position={index}
											>
												{result.label}
											</MenuItem>
										))}
									</Menu>
								)}
								renderMenuItemChildren={(option) => (
									<option key={option.id}>{option.label}</option>
								)}
								inputProps={{
									style: {
										height: '35px',
										fontSize: '10pt',
										padding: '0.375rem 0.375rem 0.375rem 0.75rem',
										borderColor: '#a9a9a9'
									}
								}}
								defaultInputValue={defaultValue || ''}
								searchText={t('autocomplete.searching')}
								promptText={t('autocomplete.searching')}
								emptyLabel={t('autocomplete.notfound')}
							/>
						</Col>
					</Row>
				</Col>
			)
		}
	}
}

export default themr(
	'InputAutocompleteStyles',
	styles
)(withTranslation()(InputAutocomplete))
