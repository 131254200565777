import {
	ICrudGetCostCenterAccounts,
	ICrudGetCostCenterAccountsRes,
	ICrudUpdateCostCenterAccounts,
	ICrudUpdateCostCenterAccountsRes
} from 'models/CrudCostCentersInterface'
import {
	CRUD_CLEAR_COST_CENTER_LEDGER_ACCOUNTS_STORE,
	CRUD_GET_COST_CENTER_LEDGER_ACCOUNTS,
	CRUD_GET_COST_CENTER_LEDGER_ACCOUNTS_SUCCESS,
	CRUD_UPDATE_COST_CENTER_LEDGER_ACCOUNTS,
	CRUD_UPDATE_COST_CENTER_LEDGER_ACCOUNTS_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetCostCenterAccounts = (
	payload: ICrudGetCostCenterAccounts
) => {
	return {
		type: CRUD_GET_COST_CENTER_LEDGER_ACCOUNTS,
		payload
	}
}

export const crudGetCostCenterAccountsSuccess = (
	payload: ICrudGetCostCenterAccountsRes
) => {
	return {
		type: CRUD_GET_COST_CENTER_LEDGER_ACCOUNTS_SUCCESS,
		payload
	}
}

export const crudUpdateCostCenterAccounts = (
	payload: ICrudUpdateCostCenterAccounts
) => {
	return {
		type: CRUD_UPDATE_COST_CENTER_LEDGER_ACCOUNTS,
		payload
	}
}

export const crudUpdateCostCenterAccountsSuccess = (
	payload: ICrudUpdateCostCenterAccountsRes
) => {
	return {
		type: CRUD_UPDATE_COST_CENTER_LEDGER_ACCOUNTS_SUCCESS,
		payload
	}
}

export const crudClearCostCenterAccountsStore = () => ({
	type: CRUD_CLEAR_COST_CENTER_LEDGER_ACCOUNTS_STORE
})
