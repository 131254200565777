import {
	CRUD_GET_CUSTOMER_OBSERVATION,
	CRUD_GET_CUSTOMER_OBSERVATION_SUCCESS,
	CRUD_UPDATE_CUSTOMER_OBSERVATION,
	CRUD_UPDATE_CUSTOMER_OBSERVATION_SUCCESS
} from '../constants/ActionsTypes'
import {
	getValueInfoParamsInterface,
	updateValueParamsInterface
} from '../constants/valuesInterfaces/interfaces'

export const crudGetCustomerObservation = (
	payload: getValueInfoParamsInterface
) => ({
	type: CRUD_GET_CUSTOMER_OBSERVATION,
	payload
})

export const crudGetCustomerObservationSuccess = (payload: any) => ({
	type: CRUD_GET_CUSTOMER_OBSERVATION_SUCCESS,
	payload
})

export const crudUpdateCustomerObservation = (
	payload: updateValueParamsInterface
) => ({
	type: CRUD_UPDATE_CUSTOMER_OBSERVATION,
	payload
})

export const crudUpdateCustomerObservationSuccess = (payload: any) => ({
	type: CRUD_UPDATE_CUSTOMER_OBSERVATION_SUCCESS,
	payload
})
