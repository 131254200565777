import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	getListDrugs,
	getListLaboratories,
	getOrderDirOptions,
	getOrderOptions,
	getProductsOrderOptions,
	productCategoryFilter,
	searchProviders
} from 'actions'
import InputAutocomplete from 'components/form/inputAutocomplete'
import InputCheckBox from 'components/form/InputCheckBox'
import InputDropdown from 'components/form/inputDropdown'
import InputMultiselect from 'components/form/InputMultiselect'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getOptionsDropDown, handleSetValueInput } from 'lib/FormUtils'
import {
	IGetLaboratoriesParams,
	IGetListDrugsParams
} from 'models/AutomaticPurchase'
import {
	IGetTableBranchOfficesResArray,
	IGetTableGenericRes
} from 'models/TableCalls/TableCallsInterface'
import React, { Component } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'

interface IAutomaticPurchaseFiltersInputs {
	fields: IFieldStructure[]
	values: any
	handleChange: any
	handleBlur: any
	setFieldValue: any
	setFieldTouched: any
	getListLaboratories: (payload: IGetLaboratoriesParams) => void
	getListDrugs: (payload: IGetListDrugsParams) => void
	depositList: any
	calculationFormulaList: null | Array<IGetTableGenericRes>
	orderList: null | Array<IGetTableGenericRes>
	labList: null | Array<IGetTableGenericRes>
	drugList: null | Array<IGetTableGenericRes>
	idOperacion: string
	handleOpenModal: () => void
	selectedProducts: any
	branchOffices: IGetTableBranchOfficesResArray
	toggleColumns: (payload: { showColumns: boolean }) => void
}

interface IAutomaticPurchaseFiltersState {
	searchingLab: boolean
	searchingDrugs: boolean
}

class SearchProductsInputs extends Component<
	IAutomaticPurchaseFiltersInputs,
	IAutomaticPurchaseFiltersState
> {
	constructor(props: IAutomaticPurchaseFiltersInputs) {
		super(props)
		this.state = {
			searchingLab: false,
			searchingDrugs: false
		}
	}

	componentDidUpdate = (prevProps: IAutomaticPurchaseFiltersInputs) => {
		const { labList, drugList, selectedProducts } = this.props
		if (labList !== prevProps.labList && labList !== null) {
			this.setState({ searchingLab: false })
		}

		if (drugList !== prevProps.drugList && drugList !== null) {
			this.setState({ searchingDrugs: false })
		}
		if (
			!_.isEqual(selectedProducts, prevProps.selectedProducts) &&
			!_.isEmpty(selectedProducts)
		) {
			this.props.setFieldValue('producto_resultado', selectedProducts.mensaje)
		}
	}

	/**
	 * to search providers.
	 */
	handleSearchLaboratory = (value: string) => {
		const { idOperacion } = this.props
		this.props.getListLaboratories({ idOperacion, descrip: value })
		this.setState({ searchingLab: true })
	}

	handleSelectLab = (provider: any) => {}

	/**
	 * to manage provider data when change
	 */
	handleChangeLab = (provider: any) => {
		const { setFieldValue } = this.props
		setFieldValue('fab', !_.isEmpty(provider) ? provider[0].id : '')
	}

	/**
	 * to search drugs.
	 */
	handleSearchDrugs = (value: string) => {
		const { idOperacion } = this.props
		this.props.getListDrugs({ idOperacion, descrip: value })
		this.setState({ searchingDrugs: true })
	}

	handleSelectDrugs = (provider: any) => {}

	/**
	 * to manage provider data when change
	 */
	handleChangeDrugs = (provider: any) => {
		const { setFieldValue } = this.props
		setFieldValue('espec', !_.isEmpty(provider) ? provider[0].id : '')
	}

	handleChangeFormula = (data: any) => {
		this.props.toggleColumns({ showColumns: data.target.value === 'DS' })

		const { setFieldTouched, setFieldValue } = this.props
		const formikProps = { setFieldTouched, setFieldValue }
		handleSetValueInput(data, 'forma_calc', formikProps)
	}

	render() {
		const {
			fields,
			values,
			handleBlur,
			setFieldTouched,
			setFieldValue,
			labList,
			depositList,
			calculationFormulaList,
			orderList,
			drugList,
			branchOffices
		} = this.props

		const { searchingLab, searchingDrugs } = this.state

		const editableDate = !(
			values.forma_calc === 'DS' || values.forma_calc === 'RS'
		)
		const editableDays = values.forma_calc !== 'DS'

		const formikProps = { setFieldTouched, setFieldValue }

		const propsDrugs = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'code',
			inputId: 'espec',
			name: 'espec',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			minLength: 1,
			divStyle: { width: '100%', paddingRight: '0px' },
			handleSearch: (value: string) => this.handleSearchDrugs(value),
			auoptions: getOptionsDropDown(drugList),
			handleLoading: searchingDrugs,
			handleSelect: this.handleSelectDrugs,
			onChange: this.handleChangeDrugs,
			onBlur: handleBlur,
			value: values.drugs
		}

		const propsLab = {
			fields,
			inputFormCol: { md: 6, lg: 6 },
			label: 'Laboratorio',
			inputId: 'fab',
			name: 'fab',
			minLength: 1,
			divStyle: { width: '100%', paddingRight: '0px' },
			colLabel: 'col-lg-4 col-md-4',
			colInput: 'col-lg-8 col-md-8',
			handleSearch: (value: string) => this.handleSearchLaboratory(value),
			auoptions: getOptionsDropDown(labList),
			handleLoading: searchingLab,
			handleSelect: this.handleSelectLab,
			onChange: this.handleChangeLab,
			onBlur: handleBlur,
			labelKey: 'label',
			defaultValue: values.fab ? String(values.fab) : values.fab
		}

		const propsProducts = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'code',
			inputId: 'producto_resultado',
			name: 'producto_resultado',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-10',
			onBlur: handleBlur,
			value: values.producto_resultado,
			divStyle: { paddingRight: '0px' },
			suffix: (
				<FontAwesomeIcon
					icon={faSearch}
					style={{ cursor: 'pointer', width: '20px', height: '20px' }}
					color={'#007bff'}
					title={'Buscar Productos'}
					onClick={() => this.props.handleOpenModal()}
				/>
			),
			onChange: (data: any) =>
				handleSetValueInput(data, 'producto_resultado', formikProps)
		}

		const propsDateFrom = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'Fecha',
			inputId: 'fec_des',
			name: 'fec_des',
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			placeholder: 'Desde',
			onBlur: handleBlur,
			disabledInput: editableDate,
			autoComplete: 'off',
			value: values.fec_des,
			divStyle: { paddingRight: '0px' },
			onChange: (data: any) =>
				handleSetValueInput(data, 'fec_des', formikProps, 'date')
		}

		const propsDateTo = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'Fecha',
			inputId: 'fec_has',
			name: 'fec_has',
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			placeholder: 'Hasta',
			onBlur: handleBlur,
			disabledInput: editableDate,
			autoComplete: 'off',
			value: values.fec_has,
			divStyle: { paddingRight: '0px' },
			styleLabel: { textAlign: 'right' },
			onChange: (data: any) =>
				handleSetValueInput(data, 'fec_has', formikProps, 'date')
		}

		const propsStockDays = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Fecha',
			inputId: 'dias_stock',
			name: 'dias_stock',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			placeholder: 'Días Stock',
			onBlur: handleBlur,
			disabledInput: editableDays,
			value: values.dias_stock,
			divStyle: { paddingRight: '0px' },
			onChange: (data: any) =>
				handleSetValueInput(data, 'dias_stock', formikProps)
		}

		const propsCalculationFormula = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'l',
			inputId: 'forma_calc',
			name: 'forma_calc',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.forma_calc,
			divStyle: { paddingRight: '0px' },
			options: getOptionsDropDown(calculationFormulaList),
			onChange: (data: any) => this.handleChangeFormula(data)
		}

		const propsDept = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'l',
			inputId: 'dep',
			name: 'dep',
			placeholder: 'Seleccione uno o varios depositos',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.dep,
			divStyle: { paddingRight: '0px' },
			options: getOptionsDropDown(depositList),
			onChange: (selectedList: Array<any>, selectedItem: object) => {
				const list = _.isArray(selectedList)
					? selectedList.map((item) => item.id)
					: []

				handleSetValueInput(list, 'dep', formikProps)
			}
		}

		const clientsProps = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Test',
			inputId: 'ver_comprar',
			name: 'ver_comprar',
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			onBlur: handleBlur,
			value: values.ver_comprar,
			checked: values.ver_comprar,
			onChange: (data: any) =>
				handleSetValueInput(data, 'ver_comprar', formikProps)
		}

		const propsSort = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'l',
			inputId: 'orden',
			name: 'orden',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.orden,
			divStyle: { paddingRight: '0px' },
			options: getOptionsDropDown(orderList),
			onChange: (data: any) => handleSetValueInput(data, 'orden', formikProps)
		}

		const branchesProps = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'sucursal',
			inputId: 'sucursal',
			name: 'sucursal',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.sucursal,
			options: getOptionsDropDown(branchOffices?.lista),
			onChange: (data: any) =>
				handleSetValueInput(data, 'sucursal', formikProps)
		}

		return (
			<Row>
				<Row className={`col-12`}>
					<InputDropdown {...propsCalculationFormula} />
					<InputAutocomplete {...propsLab} />
				</Row>
				<Row className={`col-12`}>
					<InputText {...propsDateFrom} />
					<InputText {...propsDateTo} />
					<InputText {...propsStockDays} />
				</Row>
				<Row className={`col-12`}>
					<InputMultiselect {...propsDept} />
					<InputAutocomplete {...propsDrugs} />
				</Row>
				<Row className={`col-12`}>
					<InputDropdown {...branchesProps} />
				</Row>
				<Row className={`col-12 `}>
					<InputText {...propsProducts} />
					<InputCheckBox {...clientsProps} />
				</Row>
				<Row className={`col-12 `}>
					<InputDropdown {...propsSort} />
					<Col className={'text-center mt-2'}>
						<Button style={{ minWidth: '145px' }} type={'submit'}>
							{'Aplicar'}
						</Button>
					</Col>
				</Row>
			</Row>
		)
	}
}

const mapStateToProps = ({
	automaticPurchase,
	searchProducts,
	tableCalls
}: any) => {
	const { depositList, calculationFormulaList, orderList, labList, drugList } =
		automaticPurchase
	const { branchOffices } = tableCalls
	const { selectedProducts } = searchProducts

	return {
		depositList,
		calculationFormulaList,
		orderList,
		labList,
		drugList,
		selectedProducts,
		branchOffices
	}
}

export default connect(mapStateToProps, {
	searchProviders,
	getOrderDirOptions,
	getOrderOptions,
	getProductsOrderOptions,
	productCategoryFilter,
	getListLaboratories,
	getListDrugs
})(SearchProductsInputs)
