import Axios from 'axios'
import { downloadFileType } from 'lib/Utils'
import {
	IDownloadFileActionParams,
	IGetDocumentsParams,
	IGetTempDocumentsParams,
	IRemoveDocumentParams,
	IRemoveTempDocumentParams,
	IUploadFilesParams
} from 'models/FileManager'
const urlUpload = '/Documentos'

export const uploadFilesApi = async (params: IUploadFilesParams) => {
	const formData = new FormData()
	const { files, idOperacion } = params
	files.forEach((file) => formData.append('archivos', file as Blob))
	formData.append('idOperacion', idOperacion)
	const response = await Axios.post(`${urlUpload}/Adjuntar`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return response.data
}

export const getTempDocumentListApi = async (
	params: IGetTempDocumentsParams
) => {
	const response = await Axios.get(`${urlUpload}/ListarTemporal`, { params })
	return response.data
}

export const getDocumentListApi = async (params: IGetDocumentsParams) => {
	const response = await Axios.get(`${urlUpload}/Listar`, { params })
	return response.data
}

export const removeDocumentApi = async (params: IRemoveDocumentParams) => {
	const urlParams = new URLSearchParams({
		...params,
		idEmpresa: params.idEmpresa.toString(),
		idarch: params.idarch.toString()
	}).toString()

	const response = await Axios.post(`${urlUpload}/Eliminar?${urlParams}`)
	return response.data
}

export const removeTempDocumentApi = async (
	params: IRemoveTempDocumentParams
) => {
	const urlParams = new URLSearchParams({
		...params,
		idarch: params.idarch.toString()
	})
	const response = await Axios.post(`${urlUpload}/BorrarTemporal?${urlParams}`)
	return response.data
}

export const downloadFileApi = async (payload: IDownloadFileActionParams) => {
	const { params, name } = payload
	const response = await Axios.get(`${urlUpload}/Descargar`, {
		params,
		responseType: 'blob'
	})
	downloadFileType(response, name)
	return response.data
}
