import {
	CRUD_CLEAR_COST_CENTER_LEDGER_ACCOUNTS_STORE,
	CRUD_GET_COST_CENTER_LEDGER_ACCOUNTS,
	CRUD_GET_COST_CENTER_LEDGER_ACCOUNTS_SUCCESS,
	CRUD_UPDATE_COST_CENTER_LEDGER_ACCOUNTS,
	CRUD_UPDATE_COST_CENTER_LEDGER_ACCOUNTS_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	costCenterLedgerAccountsSearched: any
	costCenterLedgerAccountsUpdated: any
}

const initialState = {
	costCenterLedgerAccountsSearched: null,
	costCenterLedgerAccountsUpdated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_COST_CENTER_LEDGER_ACCOUNTS:
			return { ...state, costCenterLedgerAccountsSearched: null }
		case CRUD_GET_COST_CENTER_LEDGER_ACCOUNTS_SUCCESS:
			return { ...state, costCenterLedgerAccountsSearched: action.payload }
		case CRUD_UPDATE_COST_CENTER_LEDGER_ACCOUNTS:
			return { ...state, costCenterLedgerAccountsUpdated: null }
		case CRUD_UPDATE_COST_CENTER_LEDGER_ACCOUNTS_SUCCESS:
			return { ...state, costCenterLedgerAccountsUpdated: action.payload }
		case CRUD_CLEAR_COST_CENTER_LEDGER_ACCOUNTS_STORE:
			return {
				...state,
				costCenterLedgerAccountsSearched: null,
				costCenterLedgerAccountsUpdated: null
			}
		default:
			return state
	}
}

export default rootReducer
