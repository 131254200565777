import InputButton from 'components/form/inputButton'
import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
	cancelSelDocuments,
	confirmSelDocuments,
	getCollections,
	getSelDocuments
} from '../../actions'
import CollectionOrderForm from './collectionOrderForm'
import CollectionSearchTable from './collectionSearchTable.js'
import CollectionTotals from './collectionTotals.js'

class CollectionSearchForm extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			showError: false,
			errorMessage: '',
			urlForm: '',
			orden_dato: 'F',
			orden_tipo: 'A'
		}

		this.firtsRefs = React.createRef()

		this.optionsOrder = [
			{
				id: 'F',
				label: 'Fecha Cobro'
			},
			{
				id: 'B',
				label: 'Banco'
			},
			{
				id: 'I',
				label: 'Importe'
			}
		]

		this.optionsOrderType = [
			{
				id: 'A',
				label: '\u25B2'
			},
			{
				id: 'D',
				label: '\u25BC'
			}
		]
	}

	componentDidUpdate = (prevProps) => {
		const { confirmDocs, idOperacion } = this.props
		if (
			prevProps.confirmDocs !== confirmDocs &&
			confirmDocs &&
			confirmDocs.Resultado
		) {
			this.props.getCollections({ idOperacion, page_size: 10, page_number: 1 })
			this.props.closeWindow()
		}
	}

	componentWillUnmount = () => {
		const { idOperacion } = this.props
		this.props.cancelSelDocuments({ idOperacion })
	}

	handleConfirmSelDocuments = () => {
		const { idOperacion } = this.props
		this.props.confirmSelDocuments({ idOperacion })
	}

	handleCancelSelDocuments = () => {
		this.props.changeModal()
	}

	handleChangeData = ({ orden_dato, orden_tipo }) => {
		const { idOperacion } = this.props
		this.setState({ orden_dato, orden_tipo })
		this.props.getSelDocuments({
			idOperacion,
			page: 1,
			page_size: 10,
			orden_dato,
			orden_tipo
		})
	}

	render() {
		const {
			t,
			idOperacion,
			selDocumentsValidate,
			selDocuments,
			handleMoveArrow
		} = this.props
		const totals = selDocumentsValidate
			? {
					...selDocumentsValidate,
					pend_cobro_usual: selDocumentsValidate.pend_usual,
					pend_cobro_ext: selDocumentsValidate.pend_ext
			  }
			: selDocuments
			? {
					...selDocuments,
					cobro_usual: selDocuments.afec_usual,
					cobro_ext: selDocuments.afec_ext,
					pend_cobro_usual: selDocuments.pend_usual,
					pend_cobro_ext: selDocuments.pend_ext
			  }
			: {}

		return (
			<Row className={'pt-3'}>
				<Col md={{ span: 12, offset: 0 }}>
					<CollectionTotals totals={totals} t={t} />
				</Col>
				<Col md={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }}>
					<CollectionOrderForm
						handleChangeData={this.handleChangeData}
						filterOrder={this.optionsOrder}
						filterOrderType={this.optionsOrderType}
					/>
				</Col>

				<Col md={6} lg={3} className={'text-center'}>
					<InputButton
						valueButton={t('Collection.form.buttons.accept')}
						type={'primary'}
						onClick={this.handleConfirmSelDocuments}
						customStyle={{ minWidth: '145px' }}
					/>
				</Col>
				<Col md={6} lg={3} className={'text-center'}>
					<InputButton
						valueButton={t('Collection.form.buttons.cancel')}
						type={'primary'}
						onClick={this.handleCancelSelDocuments}
						customStyle={{ minWidth: '145px' }}
					/>
				</Col>
				<Col sm={12} style={{ overflow: 'scroll' }}>
					<CollectionSearchTable
						idOperacion={idOperacion}
						orden_dato={this.state.orden_dato}
						orden_tipo={this.state.orden_tipo}
						handleMoveArrow={handleMoveArrow}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = ({ collections }) => {
	const { confirmDocs, selDocumentsValidate, selDocuments } = collections
	return { confirmDocs, selDocumentsValidate, selDocuments }
}

export default connect(mapStateToProps, {
	confirmSelDocuments,
	getCollections,
	getSelDocuments,
	cancelSelDocuments
})(withTranslation()(withRouter(CollectionSearchForm)))
