import {
	ICrudGetSellerCommission,
	ICrudGetSellerCommissionRes,
	ICrudGetSellerCommissions,
	ICrudGetSellerCommissionsResArray,
	ICrudUpdateSellerCommission
} from 'models/CrudSellerInterface'
import {
	CRUD_CLEAR_SELLER_COMMISSIONS_STORE,
	CRUD_DEL_SELLER_COMMISSION,
	CRUD_DEL_SELLER_COMMISSION_SUCCESS,
	CRUD_GET_SELLER_COMMISSION,
	CRUD_GET_SELLER_COMMISSIONS,
	CRUD_GET_SELLER_COMMISSIONS_SUCCESS,
	CRUD_GET_SELLER_COMMISSION_SUCCESS,
	CRUD_UPDATE_SELLER_COMMISSION,
	CRUD_UPDATE_SELLER_COMMISSION_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetSellerCommissions = (
	payload: ICrudGetSellerCommissions
) => ({
	type: CRUD_GET_SELLER_COMMISSIONS,
	payload
})

export const crudGetSellerCommissionsSuccess = (
	payload: ICrudGetSellerCommissionsResArray
) => ({
	type: CRUD_GET_SELLER_COMMISSIONS_SUCCESS,
	payload
})

export const crudGetSellerCommission = (payload: ICrudGetSellerCommission) => ({
	type: CRUD_GET_SELLER_COMMISSION,
	payload
})

export const crudGetSellerCommissionSuccess = (
	payload: ICrudGetSellerCommissionRes
) => ({
	type: CRUD_GET_SELLER_COMMISSION_SUCCESS,
	payload
})

export const crudUpdateSellerCommission = (
	payload: ICrudUpdateSellerCommission
) => ({
	type: CRUD_UPDATE_SELLER_COMMISSION,
	payload
})

export const crudUpdateSellerCommissionSuccess = (payload: any) => ({
	type: CRUD_UPDATE_SELLER_COMMISSION_SUCCESS,
	payload
})

export const crudDelSellerCommission = (payload: ICrudGetSellerCommission) => ({
	type: CRUD_DEL_SELLER_COMMISSION,
	payload
})

export const crudDelSellerCommissionSuccess = (payload: any) => ({
	type: CRUD_DEL_SELLER_COMMISSION_SUCCESS,
	payload
})

export const crudClearSellerCommissionsStore = () => ({
	type: CRUD_CLEAR_SELLER_COMMISSIONS_STORE
})
