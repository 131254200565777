import Axios from 'axios'

export const getVoucherType = async (params) => {
	const response = await Axios.get('TipoDeComprobante', { params })
	return response.data
}

export const getVoucherTypeByUser = async (params) => {
	const response = await Axios.get('Comprobantes/usuario', { params })
	return response.data
}

export const getAffectationVoucherApi = async (params) => {
	const response = await Axios.get('Comprobantes/usuario/aseguir', { params })
	return response.data
}

export const voucherCancel = async (params) => {
	const response = await Axios.get('TipoDeComprobante/Cancelar', { params })
	return response.data
}

export const voucherSave = async (params) => {
	const response = await Axios.post('Comprobante/confirmar', params)
	return response.data
}

export const saveVouchersStyleConfigByUser = async (params) => {
	const response = await Axios.post('configuracion/actualizar', params)
	return response.data
}

export const getVoucherNumberApi = async (params) => {
	const response = await Axios.post('Comprobante/numerar', params)
	return response.data
}
export const cancelVoucherNumberApi = async (params) => {
	const response = await Axios.post('Comprobante/numerar/cancelar', params)
	return response.data
}
