import { getTableUsualCoin, searchProviders } from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues } from 'lib/FieldValidations'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import CostsFormField from './CostsFormField'

type CostProps = {
	fields: Array<IFieldStructure>
	getTableUsualCoin: () => void
	usualCoin: any
	handleSubmit: any
	itemInfo?: any
	disableForm?: boolean
	handleCloseModal: any
	renderFormInputs: any
	valueDataSelected: any
	niprod: number
	products: any
	alta: number
	searchProviders: any
	t: any
}

type CostState = {
	isSearching: boolean
	prices: {
		id: string
		pc_pv: number
		pc_psl: number
		cost: number
	}
}

class CostsForm extends Component<CostProps, CostState> {
	constructor(props: CostProps) {
		super(props)
		this.state = {
			isSearching: false,
			prices: {
				id: '',
				pc_pv: 0,
				pc_psl: 0,
				cost: 0
			}
		}
	}

	componentDidMount = () => {
		const { getTableUsualCoin } = this.props

		getTableUsualCoin()
	}

	handleSearchProviders = (value: string) => {
		const { searchProviders } = this.props
		searchProviders({ criterio_proveedor: value })
		this.handleIsSearching(true)
	}

	handleIsSearching = (isSearching: boolean) => {
		this.setState({ isSearching })
	}

	handlePrices = (
		values: any,
		changedValue: number,
		changedId: string,
		percId?: string
	) => {
		const { mon_v, precio } = values
		const { usualCoin } = this.props

		const value = changedValue > 0 ? changedValue : 0

		if (changedId.includes('pc')) {
			this.setState({
				prices: {
					id: mon_v === usualCoin.mon_usu ? 'usual' : 'ext',
					pc_pv: changedId === 'pc_pv' ? value : 0,
					pc_psl: changedId === 'pc_psl' ? value : 0,
					cost:
						changedId === 'pc_pv'
							? value !== 0
								? (precio * (100 - value)) / 100
								: 0
							: value !== 0
							? (precio * 0.6897 * (100 - value)) / 100
							: 0
				}
			})
		} else if (changedId.includes('cost')) {
			this.setState({
				prices: {
					id: mon_v === usualCoin.mon_usu ? 'usual' : 'ext',
					pc_pv:
						value !== 0 && percId === 'pc_pv'
							? 100 - (value * 100) / precio
							: 0,
					pc_psl:
						value !== 0 && percId === 'pc_psl'
							? 100 - (value * 100) / precio / 0.6897
							: 0,
					cost: value
				}
			})
		}
	}

	render() {
		const {
			fields,
			handleSubmit,
			itemInfo,
			disableForm,
			t,
			alta,
			valueDataSelected
		} = this.props
		const { isSearching, prices } = this.state

		for (const key in itemInfo) {
			itemInfo[key] =
				typeof itemInfo[key] === 'string' ? itemInfo[key].trim() : itemInfo[key]
		}

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})

		const pricesValues = {
			...initialValues,
			precio: valueDataSelected.precio,
			mon_v: valueDataSelected.mon_v
		}
		const columnsCancel = disableForm ? 12 : 6

		return (
			<>
				<Formik
					initialValues={pricesValues}
					onSubmit={(values, actions) => {
						handleSubmit(values)
					}}
					enableReinitialize={true}
				>
					{(props: FormikProps<any>) => (
						<Fragment>
							<Col sm={12}>
								<NotificationError
									errors={props.errors}
									touched={props.touched}
								/>
							</Col>
							<Form>
								{(alta === 1 || (alta === 0 && itemInfo)) &&
									_.map(fields, (field: any) => {
										return (
											<CostsFormField
												key={field.idCampo}
												formikProps={props}
												field={field}
												crudProps={this.props}
												isSearching={isSearching}
												searchProviders={this.handleSearchProviders}
												handleIsSearching={this.handleIsSearching}
												prices={prices}
												handlePrices={this.handlePrices}
												initialValue={
													pricesValues[
														field.idCampo as keyof typeof pricesValues
													]
												}
											/>
										)
									})}
								<Row className={'pt-3'}>
									{!disableForm && (
										<Col sm={6} className={'text-center mt-2'}>
											<Button style={{ minWidth: '145px' }} type={'submit'}>
												{t('Collection.form.buttons.accept')}
											</Button>
										</Col>
									)}
									<Col sm={columnsCancel} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px' }}
											type={'button'}
											onClick={this.props.handleCloseModal}
										>
											{t('Collection.form.buttons.cancel')}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					)}
				</Formik>
			</>
		)
	}
}

const mapStateToProps = ({ providers, configCrud, tableCalls }: any) => {
	const { valueDataSelected } = configCrud
	const { listProviders } = providers
	const { usualCoin } = tableCalls
	return {
		listProviders,
		valueDataSelected,
		usualCoin
	}
}

const connectForm = connect(mapStateToProps, {
	searchProviders,
	getTableUsualCoin
})(CostsForm)

export default withTranslation()(withRouter(connectForm))
