import {
	confirmationClient,
	getClient,
	getConfigVoucher,
	searchClients
} from 'actions'
import { P_SELCLI } from 'constants/ConfigProcessNames'
import { Formik } from 'formik'
import React, { Fragment, PureComponent } from 'react'
import { Col, Form } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getValidationSchema } from '../../lib/FieldValidations'
import inputInitFocus from '../common/inputInitFocus'
import NotificationsErrors from '../common/notificationsErrors'
import AccountFormInput from './accountFormInput'
import ClientFormInput from './clientFormInput'
import LocationFormInput from './locationFormInput'
import VoucherFormInput from './voucherFormInput'

class VoucherClientForm extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			showError: false,
			errorTitle: '',
			errorMessage: '',
			urlForm: ''
		}

		this.firstRefs = this.getRefFirstInput()

		this.inputsForm = {
			searchInpt: 'cliente_criterio',
			razonSocial: 'cliente_razon_social',
			codigo: 'cliente_codigo',
			tipoResp: 'cliente_Tipo_resp',
			cuit: 'cliente_identificador',
			contact: 'cliente_Contacto',
			phone: 'cliente_Telefono',
			email: 'cliente_email',
			address: 'cliente_domicilio',
			location: 'cliente_Localidad',
			province: 'cliente_Provincia',
			codPos: 'cliente_Cpos',
			obsCC: 'cliente_Obs_cc',
			obsTrans: 'cliente_Obs_cpa',
			balance: 'cliente_Saldo',
			branches: 'cliente_Sucursales',
			branchId: 'suc_nro',
			branchName: 'suc_nombre',
			branchAddress: 'suc_domicilio'
		}
	}

	componentDidMount = () => {
		const { idOperacion } = this.props

		if (idOperacion) {
			this.handleConfirmation = this.handleConfirmation.bind(this)
		}
	}

	componentDidUpdate = (prevProps) => {
		if (
			this.props.search !== prevProps.search &&
			this.props.search &&
			this.props.search !== null
		) {
			this.setState({ loading: false })
		}

		if (this.props.config !== prevProps.config) {
			if (this.firstRefs.current) {
				this.firstRefs.current.focus()
			}
		}
	}

	/**
	 * function to call when the form is co
	 */
	handleConfirmation = (values) => {
		const { client, idOperacion, callBackReturn } = this.props

		const params = {
			idOperacion,
			Cuit: client.cliente_identificador,
			idCliente: client.idCliente,
			Rsocial: client.cliente_razon_social,
			idSucursal: values.client_branch
		}

		if (client) {
			this.props.confirmationClient({ client: { ...params }, callBackReturn })
		}
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	handleSearch = (value) => {
		const { idOperacion } = this.props
		this.props.searchClients({ criterio_cliente: value, idOperacion })
		this.setState({ loading: true })
	}

	handleSelect = (client) => {
		const { idOperacion } = this.props
		const selected = client[0]
		if (selected) {
			this.props.getClient({ idCliente: selected.id, idOperacion })
			this.setState({ cliente_criterio: selected.label })
		}
	}

	render() {
		const { search, client, config, t } = this.props

		const defaultInitial = {
			cliente_razon_social: '',
			cliente_codigo: '',
			cliente_Tipo_resp: '',
			cliente_identificador: '',
			cliente_Contacto: '',
			cliente_Obs_cc: '',
			cliente_Obs_vta: '',
			cliente_Limcred: '',
			cliente_Pendcred: '',
			cliente_Saldo: '',
			cliente_email: '',
			cliente_Telefono: '',
			cliente_domicilio: '',
			cliente_Localidad: '',
			cliente_Provincia: '',
			cliente_Cpos: '',
			cliente_criterio: '',
			cliente_Sucursales: [],
			client_branch: ''
		}

		const initial = client
			? { ...client, cliente_criterio: this.state.cliente_criterio }
			: defaultInitial

		const optionsSync = search
			? search.clientes.map((opt) => {
					return { id: opt.idCliente, label: opt.Rsocial }
			  })
			: []

		const validationSchema = config ? getValidationSchema(config.campos, t) : {}

		if (config) {
			return (
				<Col sm={12} className={'mb-1'}>
					<Formik
						ref={this.props.formRef}
						initialValues={{ ...initial }}
						onSubmit={(values, actions) => this.handleConfirmation(values)}
						validationSchema={validationSchema}
						enableReinitialize={true}
						render={({
							values,
							handleBlur,
							handleChange,
							setErrors,
							errors,
							touched,
							isSubmitting,
							handleSubmit,
							setFieldValue,
							setFieldTouched
						}) => (
							<Fragment>
								<Col sm={11}>
									<NotificationsErrors
										errors={errors}
										touched={touched}
										setErrors={setErrors}
									/>
								</Col>
								<Form onSubmit={handleSubmit} className="voucher-info-form">
									<Col>
										<VoucherFormInput
											inputsForm={this.inputsForm}
											handleSearch={this.handleSearch}
											auoptions={optionsSync}
											handleLoading={this.state.loading}
											handleSelect={this.handleSelect}
											firtsRefs={this.firstRefs}
											fields={config ? config.campos : null}
											{...{
												values,
												handleBlur,
												handleChange,
												errors,
												touched,
												isSubmitting,
												handleSubmit,
												setFieldValue,
												setFieldTouched
											}}
										/>
									</Col>
									<div className="dropdown-divider col-11 p-1" />
									<Col>
										<ClientFormInput
											inputsForm={this.inputsForm}
											fields={config ? config.campos : null}
											{...{
												values,
												handleBlur,
												handleChange,
												errors,
												touched,
												isSubmitting,
												handleSubmit,
												setFieldValue,
												setFieldTouched
											}}
										/>
										<LocationFormInput
											inputsForm={this.inputsForm}
											fields={config ? config.campos : null}
											{...{
												values,
												handleBlur,
												handleChange,
												errors,
												touched,
												isSubmitting,
												handleSubmit,
												setFieldValue,
												setFieldTouched
											}}
										/>
									</Col>
									<div className="dropdown-divider col-11 p-1" />
									<Col>
										<AccountFormInput
											inputsForm={this.inputsForm}
											fields={config ? config.campos : null}
											{...{
												values,
												handleBlur,
												handleChange,
												errors,
												touched,
												isSubmitting,
												handleSubmit,
												setFieldValue,
												setFieldTouched
											}}
										/>
									</Col>
								</Form>
							</Fragment>
						)}
					/>
				</Col>
			)
		} else {
			return null
		}
	}
}

const mapStateToProps = ({ clients, voucher, vouchertype }) => {
	const { search, client } = clients
	const config = voucher.config ? voucher.config[P_SELCLI] : null
	const { voucherTypeCancel } = vouchertype
	return { search, client, config, voucherTypeCancel }
}

export default connect(mapStateToProps, {
	searchClients,
	getClient,
	getConfigVoucher,
	confirmationClient
})(withTranslation()(withRouter(inputInitFocus(VoucherClientForm))))
