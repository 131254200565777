import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { getValueUnmask } from '../../lib/MaskValues'
import styles from './inputTextArea.module.css'

class InputTextArea extends Component {
	constructor(props) {
		super(props)
		this.state = {
			inputValue: this.props.value,
			configInput: this.getConfigField(props.inputId),
			trueValue: null
		}
	}

	componentDidUpdate = (prevProps) => {
		const { fields, value } = this.props
		const { inputValue } = this.state

		if (prevProps.fields !== fields && fields) {
			this.setState({ configInput: this.getConfigField(this.props.inputId) })
		}

		if (prevProps.value !== inputValue && value) {
			this.setState({ inputValue: value }) // Valor inicial
		}
	}

	handleChange = (data) => {
		const { onChange } = this.props
		const { mascara } = this.state.configInput
		const value = data && data.target ? data.target.value : data
		const trueValue = getValueUnmask(value, mascara, this.props)

		this.setState({ inputValue: value, trueValue })
		if (onChange) {
			onChange(trueValue)
		}
	}

	getConfigField = (id) => {
		const { fields } = this.props
		let result = {}

		fields.forEach((field) => {
			if (field.idCampo === id) {
				result = field
			}
		})

		return result
	}

	renderField = () => {
		const {
			label,
			placeholder,
			name,
			styles,
			inputId,
			colInput,
			colLabel,
			styleLabel,
			divStyle,
			cols,
			rows,
			disable,
			theme,
			inputFormCol,
			disabledInput
		} = this.props
		const classInput = label ? colInput : 'col-sm-12'
		const classLabel = label ? colLabel : ''
		const classText = disable ? theme.inputDisabled : ''
		const config = this.getConfigField(inputId)
		if (config.visible) {
			return (
				<Col {...inputFormCol}>
					<Row className={'form-group'}>
						<label
							className={`${theme.label}  ${classLabel}`}
							style={{ ...styleLabel }}
						>
							{config.label ? config.label : label}
						</label>
						<Col className={classInput} style={{ ...divStyle }}>
							<textarea
								id={inputId}
								name={name}
								style={{ margin: '5px ,0px', ...styles }}
								placeholder={placeholder}
								rows={rows}
								cols={cols}
								disabled={!config.editable || disabledInput}
								className={`${theme.inputTextArea} ${classText}`}
								onChange={(v) => this.handleChange(v)}
								onBlur={this.props.onBlur}
								value={this.state.inputValue}
							/>
						</Col>
					</Row>
				</Col>
			)
		} else {
			return null
		}
	}

	render() {
		const { fields } = this.props
		if (fields) {
			return this.renderField()
		} else {
			return null
		}
	}
}

export default themr('InputTextAreaStyle', styles)(InputTextArea)
