import {
	GET_TABLE_CURRENCY,
	GET_TABLE_CURRENCY_SUCCESS
} from 'constants/ActionsTypes'
import {
	ICurrencyFilterAction,
	ICurrencyFilterParams
} from 'models/UtilsFilters'

export const getTableCurrency = (
	payload: ICurrencyFilterParams
): ICurrencyFilterAction => {
	return {
		type: GET_TABLE_CURRENCY,
		payload
	}
}

export const getTableCurrencySuccess = (payload: any) => {
	return {
		type: GET_TABLE_CURRENCY_SUCCESS,
		payload
	}
}
