import { getTablePermissionType, getTableVoucherModules } from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import InputButton from 'components/form/inputButton'
import { searchFields } from 'constants/crudsConfig/crudProfilesConstants/CrudProfileAbmsSearchFields'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { FormRefsContext } from 'context/FormRefsContext'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues } from 'lib/FieldValidations'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { ADMIN } from 'utils/RoutePath'
import SearchCustomerField from './CrudSearchProfileAbmsField'

type ComponentProps = {
	fields: Array<IFieldStructure>
	itemInfo?: any
	alta: number
	handleClearSearch: () => void
	handleSubmit: (params: any) => void
	homeTarget: string
	handleNewCrud: () => void
	getTableVoucherModules: (params: any) => void
	getTablePermissionType: (params: any) => void
	history: any
	t: any
	hideSearch: boolean
	hideNew: boolean
	hideBack: boolean
}

type ComponentState = {}

class SearchCustomerForm extends Component<ComponentProps, ComponentState> {
	componentDidMount = () => {
		this.props.getTableVoucherModules({ incluye_ninguno: 1 })
		this.props.getTablePermissionType({ incluye_ninguno: 1 })
	}

	/**
	 * to go to the page home
	 */
	handleHome = (homeTarget: string) => {
		const { history } = this.props
		history.push(`${ADMIN}/${homeTarget}`)
	}

	render() {
		const {
			fields,
			itemInfo,
			homeTarget,
			handleSubmit,
			t,
			handleNewCrud,
			handleClearSearch,
			hideSearch,
			hideNew,
			hideBack
		} = this.props

		for (const key in itemInfo) {
			itemInfo[key] =
				typeof itemInfo[key] === 'string' ? itemInfo[key].trim() : itemInfo[key]
		}

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})
		// const columnsCancel = disableForm ? 12 : 6

		return (
			<FormRefsContext.Consumer>
				{({ formRef, cancelButtonRef, searchButtonRef }) => (
					<Formik
						ref={formRef}
						initialValues={initialValues}
						onSubmit={(values, actions) => {
							const parsedValues = {
								...values,
								lk_movim_desde: moment(values.lk_movim_desde),
								lk_movim_hasta: moment(values.lk_movim_hasta)
							}
							handleSubmit(parsedValues)
						}}
						enableReinitialize={true}
					>
						{(props: FormikProps<any>) => (
							<Fragment>
								<Col sm={12}>
									<NotificationError
										errors={props.errors}
										touched={props.touched}
									/>
								</Col>
								<Form>
									<Row className={'p-2'}>
										{_.map(fields, (field: any) => {
											const selectedFieldConfig = searchFields.find(
												(fieldConfig) => {
													return fieldConfig.id === field.idCampo
												}
											)
											return (
												<Col
													className={
														(selectedFieldConfig &&
															selectedFieldConfig.height) ||
														'col-sm-12'
													}
												>
													<SearchCustomerField
														key={field.idCampo}
														formikProps={props}
														field={field}
														crudProps={this.props}
														initialValue={
															initialValues[
																field.idCampo as keyof typeof initialValues
															]
														}
													/>
												</Col>
											)
										})}
									</Row>
									<Row className={'pt-1 pr-2'}>
										<Col className={'offset-md-6 text-center mt-2'}>
											{!hideSearch && (
												<Button
													style={{ minWidth: '105px' }}
													type={'submit'}
													ref={searchButtonRef}
												>
													{t('global.search')}
												</Button>
											)}
										</Col>
										<Col className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '105px' }}
												type={'button'}
												onClick={() => {
													props.resetForm()
													handleClearSearch()
												}}
											>
												{t('global.clear')}
											</Button>
										</Col>
										<Col className={'text-center mt-2'}>
											{!hideNew && (
												<InputButton
													valueButton={t('global.new_button')}
													onClick={() => handleNewCrud()}
													type={'primary'}
													customStyle={{ minWidth: '105px' }}
												/>
											)}
										</Col>
										<Col className={'text-center mt-2'}>
											{!hideBack && (
												<Button
													style={{ minWidth: '105px' }}
													type={'button'}
													onClick={() => this.handleHome(homeTarget)}
													ref={cancelButtonRef}
												>
													{t('global.return_button')}
												</Button>
											)}
										</Col>
									</Row>
								</Form>
							</Fragment>
						)}
					</Formik>
				)}
			</FormRefsContext.Consumer>
		)
	}
}

const mapStateToProps = ({ tableCalls }: any) => {
	const { voucherModules, permissionType } = tableCalls
	return { voucherModules, permissionType }
}

const connectForm = connect(mapStateToProps, {
	getTableVoucherModules,
	getTablePermissionType
})(SearchCustomerForm)

export default withTranslation()(withRouter(connectForm))
