import { crudGetCustomerReferred, crudUpdateCustomerReferred } from 'actions'
import CrudFilters from 'components/abmUtils/CrudFilters'
import TableWithSelection from 'components/abmUtils/TableWithSelection'
import {
	ICrudGetCustomerRefer,
	ICrudUpdateCustomerRefer
} from 'models/CrudCustomerInterface'
import { ICrudFieldStructure } from 'models/CrudInterface'
import React, { Component } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'

interface CustomerReferProps extends WithTranslation {
	niclie: number
	niclieRef: number
	alta: number
	fields: ICrudFieldStructure
	searchFields: ICrudFieldStructure
	disableForm: boolean
	referredSearched: any
	referredUpdated: any
	handleCloseModal: () => void
	crudGetCustomerReferred: (params: ICrudGetCustomerRefer) => void
	crudUpdateCustomerReferred: (params: ICrudUpdateCustomerRefer) => void
	t?: any
}

interface CustomerReferState {
	paramsSearch: {
		niclie_ref: number
		niclie: number
		busqueda: string
		page_size: number
		page_number: number
	}
	sustainsClick: boolean
	selectedRows: Array<any>
}

class CustomerRefer extends Component<CustomerReferProps, CustomerReferState> {
	constructor(props: CustomerReferProps) {
		super(props)
		this.state = {
			paramsSearch: {
				niclie_ref: props.niclieRef ? props.niclieRef : 0,
				niclie: props.niclie,
				busqueda: '',
				page_number: 1,
				page_size: 10
			},
			sustainsClick: false,
			selectedRows: []
		}
	}

	componentDidMount = () => {
		const { crudGetCustomerReferred } = this.props
		const { paramsSearch } = this.state

		crudGetCustomerReferred({ ...paramsSearch })
	}

	componentDidUpdate = (prevProps: CustomerReferProps) => {
		const { referredSearched, referredUpdated, crudGetCustomerReferred } =
			this.props
		const {
			referredSearched: prevReferredSearched,
			referredUpdated: prevReferredUpdated
		} = prevProps
		const { paramsSearch } = this.state

		if (referredSearched && referredSearched !== prevReferredSearched) {
			const selectedRows: Array<any> = []
			_.forEach(referredSearched.lista, (referred) => {
				referred.sel === 1 && selectedRows.push(referred.niclie)
			})

			this.setState({ selectedRows: selectedRows })
		}

		if (referredUpdated && referredUpdated !== prevReferredUpdated) {
			crudGetCustomerReferred({
				...paramsSearch
			})
		}
	}

	handleChangeTable = (type: String, pagination: any) => {
		const { paramsSearch } = this.state
		const { crudGetCustomerReferred } = this.props
		if (type === 'pagination') {
			crudGetCustomerReferred({
				...paramsSearch,
				page_size: pagination.sizePerPage,
				page_number: pagination.page
			})
		}
		this.setState({
			paramsSearch: {
				...paramsSearch,
				page_number: pagination.page,
				page_size: pagination.sizePerPage
			}
		})
	}

	/**
	 * to call api to search
	 * @param {object} filters
	 */

	handleCrudSearch = (filters: { lk_rsoc: string }) => {
		const { niclie, crudGetCustomerReferred, niclieRef } = this.props
		const { paramsSearch } = this.state
		const values = Object.values(filters)
		crudGetCustomerReferred({
			busqueda: values[0],
			page_size: 10,
			page_number: 1,
			niclie: niclie,
			niclie_ref: niclieRef
		})
		this.setState({ paramsSearch: { ...paramsSearch, busqueda: values[0] } })
	}

	/**
	 * to handle selection state, gets labels as params
	 * @param selectedRow
	 * @param side
	 * @param action
	 */
	handleSelection = (selectedRow: number, table: string, action: string) => {
		const { crudUpdateCustomerReferred, niclie, referredSearched } = this.props

		const selectedReferred = referredSearched.lista.find((referred: any) => {
			return referred.niclie === selectedRow
		})

		const newReferred: ICrudUpdateCustomerRefer = {
			alta: action === 'add' ? 1 : 0,
			niclie: niclie,
			suc_clie: selectedRow,
			rsoc: selectedReferred ? selectedReferred.rsoc : ''
		}
		crudUpdateCustomerReferred(newReferred)
	}

	render() {
		const { fields, searchFields, referredSearched, t } = this.props
		const { sustainsClick, selectedRows } = this.state

		const filtersProps = {
			fields: searchFields,
			handleSearch: this.handleCrudSearch,
			hideNew: true,
			hideBack: true
		}

		const propsTable = {
			fields: referredSearched && referredSearched.lista,
			tableFields: fields,
			disableForm: false,
			keyfield: 'niclie',
			sustainsClick: sustainsClick,
			selected: selectedRows,
			setSelection: this.handleSelection,
			paginationOption: referredSearched || null,
			handleChangeTable: this.handleChangeTable,
			label: 'ReferredTable',
			checkBox: true,
			height: 600,
			hideScrollX: true,
			hideScrollY: true,
			buttons: []
		}

		return (
			<Row>
				<Col className={'text-center mt-2'}>
					<CrudFilters {...filtersProps} />
					<TableWithSelection {...propsTable} />
					<Button
						style={{ minWidth: '145px' }}
						type={'button'}
						onClick={this.props.handleCloseModal}
					>
						{t('Collection.form.buttons.exit')}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = ({ customerReferred }: any) => {
	const { referredSearched, referredUpdated } = customerReferred
	return { referredSearched, referredUpdated }
}

const connectForm = connect(mapStateToProps, {
	crudGetCustomerReferred,
	crudUpdateCustomerReferred
})(CustomerRefer)

export default withTranslation()(withRouter(connectForm))
