import { getTableIsTaxesIdValid, searchProviders } from 'actions'
import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import NotificationError from 'components/common/notificationsErrors'
import { FormRefsContext } from 'context/FormRefsContext'
import { Form, Formik } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import * as Yup from 'yup'
import CrudCourierDataField from './CrudCourierDataField'

class CrudNew extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isSearching: false
		}
	}

	handleValidateCuit = (codigo) => {
		this.props.getTableIsTaxesIdValid(codigo)
	}

	handleSearchProviders = (value) => {
		this.props.searchProviders({ criterio_proveedor: value })
		this.handleIsSearching(true)
	}

	handleIsSearching = (isSearching) => {
		this.setState({ isSearching })
	}

	render() {
		const {
			fields,
			t,
			handleSubmit,
			itemInfo,
			disableForm,
			showWarning,
			handleWarning,
			setFormChanged,
			isTaxesIdValid,
			method
		} = this.props

		const { isSearching } = this.state

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})

		const columnsCancel = disableForm ? 12 : 6
		const validationSchema = fields.length
			? getValidationSchema(
					fields,
					t,
					[],
					[
						{
							id: 'tr_cuit',
							validation: Yup.string().when(['tipo_resp'], {
								is: (tipo_resp) => tipo_resp !== 'CF',
								then: Yup.string().test(
									'ValidCuit',
									t('validation-invalid', { field: 'CUIT' }),
									(cuit) => {
										return isTaxesIdValid === true
									}
								)
							})
						}
					]
			  )
			: {}

		return (
			<FormRefsContext.Consumer>
				{({ formRef, cancelButtonRef }) => (
					<Formik
						ref={formRef}
						initialValues={initialValues}
						onSubmit={(values, actions) => {
							handleSubmit(values)
						}}
						enableReinitialize={true}
						validationSchema={validationSchema}
					>
						{(props) => (
							<Fragment>
								<Col sm={12}>
									<NotificationError
										errors={props.errors}
										touched={props.touched}
									/>
								</Col>
								<Form
									onChange={(e) => {
										setFormChanged(true)
									}}
								>
									{(method === 'add' ||
										((method === 'edit' || method === 'view') && itemInfo)) &&
										_.map(fields, (field) => {
											return (
												<CrudCourierDataField
													key={field.idCampo}
													formikProps={props}
													field={field}
													crudProps={this.props}
													isSearching={isSearching}
													searchProviders={this.handleSearchProviders}
													handleIsSearching={this.handleIsSearching}
													initialValue={initialValues[field.idCampo]}
												/>
											)
										})}
									<Row className={'pt-3'}>
										{!disableForm && (
											<Col sm={6} className={'text-center mt-2'}>
												<Button style={{ minWidth: '145px' }} type={'submit'}>
													{t('Collection.form.buttons.accept')}
												</Button>
											</Col>
										)}
										<Col sm={columnsCancel} className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={this.props.handleCloseModal}
												ref={cancelButtonRef}
											>
												{t('Collection.form.buttons.cancel')}
											</Button>
										</Col>
									</Row>
									<ConfirmModalNoButton
										modalTitle={t('CRUDS.modal.title_warning')}
										messageBody={t('CRUDS.modal.message_changes')}
										showModal={showWarning}
										handleCloseModal={() => handleWarning(false, 'close')}
										handleCancelButton={() => {
											handleWarning(false, 'cancel')
										}}
										handleSubmitButton={(e) => {
											props.handleSubmit(e)
											handleWarning(false, 'submit')
										}}
									/>
								</Form>
							</Fragment>
						)}
					</Formik>
				)}
			</FormRefsContext.Consumer>
		)
	}
}

const mapStateToProps = ({ validationResponses, providers }) => {
	const { isTaxesIdValid } = validationResponses
	const { listProviders } = providers
	return {
		isTaxesIdValid,
		listProviders
	}
}

const connectForm = connect(mapStateToProps, {
	getTableIsTaxesIdValid,
	searchProviders
})(CrudNew)

export default withTranslation()(connectForm)
