import {
	crudCreateLedgerAccountsCode,
	crudGetLedgerAccountsCode,
	crudGetLedgerAccountsCodeConfig
} from 'api/LedgerAccountsCrudCodeApi'
import {
	ICrudCreateLedgerAccountsCodeSaga,
	ICrudGetLedgerAccountsCodeConfigSaga,
	ICrudGetLedgerAccountsCodeSaga
} from 'models/CrudLedgerAccountsInterface'
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
	crudCreateLedgerAccountsCodeSuccess,
	crudGetLedgerAccountsCodeConfigSuccess,
	crudGetLedgerAccountsCodeSuccess,
	crudValidateLedgerAccountsCodeSuccess
} from '../actions'
import {
	CRUD_CREATE_LEDGER_ACCOUNTS_CODE,
	CRUD_GET_LEDGER_ACCOUNTS_CODE,
	CRUD_GET_LEDGER_ACCOUNTS_CODE_CONFIG,
	CRUD_VALIDATE_LEDGER_ACCOUNTS_CODE
} from '../constants/ActionsTypes'

function* crudGetLedgerAccountsCodeReq({
	payload
}: ICrudGetLedgerAccountsCodeSaga): any {
	try {
		const ledgerAccounts: any = yield call(crudGetLedgerAccountsCode, payload)
		yield put(crudGetLedgerAccountsCodeSuccess(ledgerAccounts))
	} catch (error) {}
}

function* crudGetLedgerAccountsCodeConfigReq({
	payload
}: ICrudGetLedgerAccountsCodeConfigSaga): any {
	try {
		const ledgerAccounts: any = yield call(
			crudGetLedgerAccountsCodeConfig,
			payload
		)
		yield put(crudGetLedgerAccountsCodeConfigSuccess(ledgerAccounts))
	} catch (error) {}
}

function* crudCreateLedgerAccountsCodeReq({
	payload
}: ICrudCreateLedgerAccountsCodeSaga): any {
	try {
		const ledgerAccounts = yield call(crudCreateLedgerAccountsCode, payload)
		yield put(crudCreateLedgerAccountsCodeSuccess(ledgerAccounts))
	} catch (error) {}
}

function* crudValidateLedgerAccountsCodeReq({
	payload
}: ICrudCreateLedgerAccountsCodeSaga): any {
	try {
		const ledgerAccounts = yield call(crudCreateLedgerAccountsCode, payload)
		yield put(crudValidateLedgerAccountsCodeSuccess(ledgerAccounts))
	} catch (error) {}
}

export function* crudGetLedgerAccountsCodeSaga() {
	yield takeLatest(CRUD_GET_LEDGER_ACCOUNTS_CODE, crudGetLedgerAccountsCodeReq)
}

export function* crudGetLedgerAccountsCodeConfigSaga() {
	yield takeLatest(
		CRUD_GET_LEDGER_ACCOUNTS_CODE_CONFIG,
		crudGetLedgerAccountsCodeConfigReq
	)
}

export function* crudCreateLedgerAccountsCodeSaga() {
	yield takeEvery(
		CRUD_CREATE_LEDGER_ACCOUNTS_CODE,
		crudCreateLedgerAccountsCodeReq
	)
}

export function* crudValidateLedgerAccountsCodeSaga() {
	yield takeEvery(
		CRUD_VALIDATE_LEDGER_ACCOUNTS_CODE,
		crudValidateLedgerAccountsCodeReq
	)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetLedgerAccountsCodeSaga),
		fork(crudGetLedgerAccountsCodeConfigSaga),
		fork(crudCreateLedgerAccountsCodeSaga),
		fork(crudValidateLedgerAccountsCodeSaga)
	])
}
