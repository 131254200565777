import {
	crudGetProfileAbms,
	crudUpdateProfileAbms
} from 'actions/ProfileAbmsActions'
import CommonTable from 'components/common/commonTable'
import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import NotificationMessage from 'components/common/notificationMessage'
import {
	ICrudGetProfileAbmRes,
	ICrudGetProfileAbms,
	ICrudGetProfileAbmsResArray,
	ICrudUpdateProfileAbms
} from 'models/CrudProfilesInterface'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import CrudSearchProfileAbmsForm from '../SearchForm/CrudSearchProfileAbmsForm'

interface ValuesTableProps extends WithTranslation {
	cod_grupo: string
	fields: any
	fieldsSearch: any
	t: any
	disableForm: boolean
	profileAbmsSearched: ICrudGetProfileAbmsResArray
	profileAbmsUpdated: ICrudUpdateProfileAbms
	crudUpdateProfileAbms: (params: ICrudUpdateProfileAbms) => void
	crudGetProfileAbms: (params: ICrudGetProfileAbms) => void
}

interface ValuesTableState {
	alta: number
	errorTitle: string
	errorMessage: string
	typeNotification: string
	showError: boolean
	paramsSearch: ICrudGetProfileAbms
	selectRow?: any
	titleModal: string
	values: ICrudUpdateProfileAbms
	showWarning: boolean
	action: string
	checked: boolean
}

class ValuesTable extends Component<ValuesTableProps, ValuesTableState> {
	constructor(props: ValuesTableProps) {
		super(props)
		this.state = {
			alta: 0,
			paramsSearch: {
				cod_grupo: props.cod_grupo
			},
			errorTitle: '',
			errorMessage: '',
			typeNotification: 'success',
			titleModal: '',
			action: '',
			checked: false,
			showError: false,
			showWarning: false,
			values: {
				cod_grupo: props.cod_grupo,
				lista: []
			}
		}
	}

	componentDidUpdate(prevProps: ValuesTableProps, prevState: ValuesTableState) {
		const {
			profileAbmsSearched,
			profileAbmsUpdated,
			crudGetProfileAbms,
			cod_grupo
		} = this.props
		const {
			profileAbmsSearched: prevProfileAbmsSearched,
			profileAbmsUpdated: prevProfileAbmsUpdated
		} = prevProps

		const { paramsSearch } = this.state
		const { paramsSearch: prevParamsSearch } = prevState

		if (
			profileAbmsSearched &&
			profileAbmsSearched !== prevProfileAbmsSearched
		) {
			const newValues: ICrudUpdateProfileAbms = {
				cod_grupo: cod_grupo,
				lista: profileAbmsSearched.lista.map((abm) => {
					return {
						niabm: abm.niabm,
						tipo_perm: abm.tipo_perm
					}
				})
			}

			this.setState({ values: newValues })
		}

		if (profileAbmsUpdated && profileAbmsUpdated !== prevProfileAbmsUpdated) {
			crudGetProfileAbms(paramsSearch)
		}

		if (paramsSearch && paramsSearch !== prevParamsSearch) {
			crudGetProfileAbms(paramsSearch)
		}
	}

	handleSearch = (filters: any) => {
		const { cod_grupo } = this.props

		const newParams = {
			cod_grupo,
			modulo: filters.lk_modulo,
			niabm: filters.lk_niabm,
			busqueda: filters.lk_nom,
			tipo_perm: filters.lk_tipoperm
		}

		this.setState({ paramsSearch: newParams })
	}

	handleClearSearch = () => {
		const { cod_grupo } = this.props
		const newParams = {
			cod_grupo
		}

		this.setState({ paramsSearch: newParams })
	}

	/**
	 * to get columns table.
	 * @returns object
	 */
	getColumns = () => {
		const { fields, t, disableForm, profileAbmsSearched } = this.props
		const rows = fields.map((field: any) => {
			const fieldId = field.idCampo.trim()
			return {
				dataField: fieldId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '10%' },
				hidden: !field.visible
			}
		})

		if (!disableForm) {
			rows.push({
				dataField: 'read',
				text: t('CRUDS.general_use.read_abreviation'),
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '3% ' },
				formatter: (cell: any, row: any, rowIndex: any) => {
					const checked = row.tipo_perm === 'L'

					return (
						<input
							type="checkbox"
							onClick={() => this.handleCheckClick('R', !checked, row)}
							checked={checked}
						/>
					)
				},
				headerFormatter: (cell: any, row: any, rowIndex: any) => {
					const checked = !profileAbmsSearched?.lista.some((abm) => {
						return abm.tipo_perm !== 'L'
					})

					return (
						<Row className="justify-content-center">
							<div style={{ marginRight: '10px' }}>
								{t('CRUDS.general_use.read_abreviation')}
							</div>
							<input
								style={{ marginRight: '19px' }}
								type="checkbox"
								onClick={() => this.handleWarning(true, 'allR', !checked)}
								checked={checked}
								disabled={_.isEmpty(profileAbmsSearched?.lista)}
							/>
						</Row>
					)
				}
			})

			rows.push({
				dataField: 'write',
				text: t('CRUDS.general_use.write_abreviation'),
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '3% ' },
				formatter: (cell: any, row: any, rowIndex: any) => {
					const checked = row.tipo_perm === 'E'

					return (
						<input
							type="checkbox"
							onClick={() => this.handleCheckClick('W', !checked, row)}
							checked={checked}
						/>
					)
				},
				headerFormatter: (cell: any, row: any, rowIndex: any) => {
					const checked = !profileAbmsSearched?.lista.some((abm) => {
						return abm.tipo_perm !== 'E'
					})

					return (
						<Row className="justify-content-center">
							<div style={{ marginRight: '10px' }}>
								{t('CRUDS.general_use.write_abreviation')}
							</div>
							<input
								style={{ marginRight: '19px' }}
								type="checkbox"
								onClick={() => this.handleWarning(true, 'allW', !checked)}
								checked={checked}
								disabled={_.isEmpty(profileAbmsSearched?.lista)}
							/>
						</Row>
					)
				}
			})
		}

		return rows
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	handleCheckClick = (
		type: string,
		check: boolean,
		id?: ICrudGetProfileAbmRes
	) => {
		const { profileAbmsSearched, cod_grupo, crudUpdateProfileAbms } = this.props

		let newValues

		switch (type) {
			case 'allR':
				newValues = {
					cod_grupo: cod_grupo,
					lista: profileAbmsSearched.lista.map((abm) => {
						return {
							niabm: abm.niabm,
							tipo_perm: check === true ? 'L' : 'N'
						}
					})
				}
				break
			case 'allW':
				newValues = {
					cod_grupo: cod_grupo,
					lista: profileAbmsSearched.lista.map((abm) => {
						return {
							niabm: abm.niabm,
							tipo_perm: check === true ? 'E' : 'N'
						}
					})
				}
				break
			case 'R':
				{
					const checked = check === true ? 'L' : 'N'
					newValues = {
						cod_grupo: cod_grupo,
						lista: profileAbmsSearched.lista.map((abm) => {
							return {
								niabm: abm.niabm,
								tipo_perm: abm.niabm === id?.niabm ? checked : abm.tipo_perm
							}
						})
					}
				}
				break
			case 'W':
				{
					const checked = check === true ? 'E' : 'N'
					newValues = {
						cod_grupo: cod_grupo,
						lista: profileAbmsSearched.lista.map((abm) => {
							return {
								niabm: abm.niabm,
								tipo_perm: abm.niabm === id?.niabm ? checked : abm.tipo_perm
							}
						})
					}
				}
				break
			default:
				newValues = {
					cod_grupo: cod_grupo,
					lista: []
				}
				break
		}

		crudUpdateProfileAbms(newValues)
	}

	handleWarning = (value: boolean, data: string, checked: boolean) => {
		this.setState({ showWarning: value, action: data, checked })
	}

	render() {
		const { fields, fieldsSearch, profileAbmsSearched, t, disableForm } =
			this.props

		const {
			errorTitle,
			errorMessage,
			showError,
			typeNotification,
			showWarning,
			action,
			checked
		} = this.state

		const tableColumns = fields ? this.getColumns() : []

		const propsTable = {
			remote: true,
			columns: tableColumns,
			keyField: 'niabm',
			data: profileAbmsSearched
				? disableForm
					? profileAbmsSearched.lista.filter((abm) => abm.tipo_perm !== 'N')
					: profileAbmsSearched.lista
				: [],
			pagination: false,
			classes: 'overflow-hidden'
		}
		const filtersProps = {
			hideNew: true,
			handleSubmit: this.handleSearch,
			handleClearSearch: this.handleClearSearch,
			fields: fieldsSearch,
			alta: 0,
			hideBack: true
		}

		return (
			<Fragment>
				<Col sm={12} className={'mb-1'}>
					<NotificationMessage
						errorTitle={errorTitle}
						errorMessage={errorMessage}
						showError={showError}
						handleCloseError={this.handleCloseError}
						type={typeNotification}
					/>
				</Col>
				<Row className={'justify-content-center mb-5'}>
					<CrudSearchProfileAbmsForm {...filtersProps} />
				</Row>
				<Row>
					<CommonTable {...propsTable} />
					<ConfirmModalNoButton
						modalTitle={t('CRUDS.modal.title_warning')}
						messageBody={t('CRUDS.modal.message_confirm')}
						labelSubmitButton={t('global.confirm')}
						labelCancelButtonModal={t('global.cancel')}
						showModal={showWarning}
						handleCloseModal={() => this.handleWarning(false, '', false)}
						handleCancelButton={() => {
							this.handleWarning(false, '', false)
						}}
						handleSubmitButton={(e: any) => {
							this.handleCheckClick(action, checked)
							this.handleWarning(false, '', false)
						}}
					/>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ profileAbms }: any) => {
	const { profileAbmsSearched, profileAbmsUpdated } = profileAbms
	return { profileAbmsSearched, profileAbmsUpdated }
}

const connectForm = connect(mapStateToProps, {
	crudGetProfileAbms,
	crudUpdateProfileAbms
})(ValuesTable)

export default withTranslation()(withRouter(connectForm))
