import {
	crudClearCostCenterStore,
	crudGetLedgerAccountCenters,
	crudsClearStore,
	crudsCreate,
	crudsGetSingle,
	crudUpdateLedgerAccountCenters,
	showMessage
} from 'actions'
import CommonTabs from 'components/abmUtils/CommonTabs'
import withMenu from 'components/common/withMenu'
import CrudsContainer from 'components/layout/CrudsContainer'
import { IAbmStructureResponse } from 'constants/valuesInterfaces/interfaces'
import { ICrudsApi, ICrudsTypeList } from 'models/CrudInterface'
import {
	ICrudGetLedgerAccountRes,
	ICrudUpdateLedgerAccountCenters
} from 'models/CrudLedgerAccountsInterface'
import { Ihistory } from 'models/NavigationInterface'
import React, { Component } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CRUD, CRUD_EDIT, CRUD_LEDGER_ACCOUNTS_DATA } from 'utils/RoutePath'
import LedgerAccountAssignCenterTab from './AdditionalTabs/LedgerAccountAssignCenterTab'
import CrudLedgerAccountData from './CrudLedgerAccountData'

type LedgerAccountDataContentProps = {
	crudCode: number
	op: string
	selectedAbm: ICrudsTypeList
	crudsGetSingle: (params: ICrudsApi) => void
	crudsCreate: (params: ICrudsApi) => void
	crudsClearStore: () => void
	valueDataUpdated: any
	valueDataSelected: ICrudGetLedgerAccountRes
	crudGetLedgerAccountCenters: any
	crudUpdateLedgerAccountCenters: any
	crudClearCostCenterStore: any
	ledgerAccountCostCentersSearched: any
	ledgerAccountCostCentersUpdated: any
	showMessage: any
	history: Ihistory
	fieldsConfig: IAbmStructureResponse
	fieldsAltaConfig: IAbmStructureResponse
	disableForm: boolean
	t: any
}

type LedgerAccountDataContentState = {
	crudCode: number | string | null
	info: boolean
	tabActive: string
	disabledBankReconciliationFields: boolean
	showWarningPopup: boolean
	formChanged: boolean
	nextTab: string
	nextAction: string
}

const ledgerAccountTabs = [
	{
		key: 'basic',
		label: 'CRUDS.general_use.basic_tab',
		type: 'basic'
	},
	{
		key: 'cc',
		label: 'CRUDS.general_use.cost_centers',
		type: 'assignCenter'
	}
]

class LedgerAccountDataContent extends Component<
	LedgerAccountDataContentProps,
	LedgerAccountDataContentState
> {
	constructor(props: LedgerAccountDataContentProps) {
		super(props)
		this.state = {
			crudCode: this.props.crudCode,
			info: false,
			tabActive: 'basic',
			disabledBankReconciliationFields: true,
			showWarningPopup: false,
			formChanged: false,
			nextTab: 'basic',
			nextAction: ''
		}
	}

	// setups initial store state for each action respectively
	componentDidMount() {
		const {
			crudCode,
			op,
			crudsClearStore,
			crudGetLedgerAccountCenters,
			crudClearCostCenterStore
		} = this.props

		if (crudCode && (op === 'edit' || op === 'view')) {
			crudGetLedgerAccountCenters({ nicodcta: crudCode })
		} else if (op === 'add') {
			crudsClearStore()
			crudClearCostCenterStore()
		}
	}

	// Handles changes and sets form changed state to false if there was a submit
	componentDidUpdate = (prevProps: LedgerAccountDataContentProps) => {
		const {
			valueDataSelected,
			op,
			crudCode,
			crudsGetSingle,
			valueDataUpdated,
			selectedAbm,
			crudGetLedgerAccountCenters,
			ledgerAccountCostCentersUpdated
		} = this.props
		const {
			valueDataUpdated: prevValueDataUpdated,
			valueDataSelected: prevValueDataSelected,
			ledgerAccountCostCentersUpdated: prevLedgerAccountCostCentersUpdated
		} = prevProps

		if (prevValueDataSelected !== valueDataSelected && valueDataSelected) {
			let info = false
			if (op === 'view' || op === 'edit') {
				info = true
			} else if (op === 'add') {
				info = false
			}

			this.setState({ info })
		}

		if (
			(valueDataUpdated !== prevValueDataUpdated && valueDataUpdated) ||
			(ledgerAccountCostCentersUpdated !==
				prevLedgerAccountCostCentersUpdated &&
				ledgerAccountCostCentersUpdated)
		) {
			crudsGetSingle({
				crudName: selectedAbm.id,
				urlApi: selectedAbm.urlApi,
				action: 'consulta',
				params: { [selectedAbm.key]: crudCode }
			})
			crudGetLedgerAccountCenters({ nicodcta: crudCode })
			this.setState({ formChanged: false })
		}
	}

	// Cancel button function
	handleRedirectHome = () => {
		this.props.history.push(`${CRUD}/${CRUD_LEDGER_ACCOUNTS_DATA}`)
	}

	/**
	 * to call api to create new abm
	 * @param {object} value
	 */

	handleNewCrud = (values: any) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 1 }
		})
	}

	/**
	 * to call api to edit abm
	 * @param {object} value
	 */

	handleEditCrud = (values: any) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 0 }
		})
	}

	handleUpdateCostCenters = (values: ICrudUpdateLedgerAccountCenters) => {
		this.props.crudUpdateLedgerAccountCenters(values)
	}

	// Set's current tab in state
	handleActiveKey = (tabActive: string) => {
		this.setState({ tabActive })
	}

	// Handles the Crud change arrows
	handleCrudChange = (param: any) => {
		const { history } = this.props
		history.go(`${CRUD_EDIT}/${CRUD_LEDGER_ACCOUNTS_DATA}/${param.nicodcta}`)
	}

	// Handles the changes warning state
	handleWarningPopup = (value: boolean, action: string) => {
		const { tabActive } = this.state
		this.setState({ showWarningPopup: value })
		action === 'close' && this.setState({ nextTab: tabActive, nextAction: '' })
		action === 'cancel' &&
			this.setState({
				formChanged: false,
				disabledBankReconciliationFields: true
			})
	}

	// Handles the changes state, used in detecting changes
	handleSetFormChanged = (formChanged: boolean) => {
		this.setState({ formChanged })
	}

	// Stores the value of the clicked tab in the component state
	handleSetNextTab = (nextTab: string) => {
		this.setState({ nextTab })
	}

	// Stores the value of the clicked arrow in the component state
	handleSetNextAction = (nextAction: string) => {
		this.setState({ nextAction })
	}

	render() {
		const {
			t,
			fieldsConfig,
			fieldsAltaConfig,
			valueDataSelected,
			disableForm,
			op,
			crudCode,
			showMessage,
			ledgerAccountCostCentersSearched
		} = this.props

		const { tabActive, showWarningPopup, nextTab, formChanged, nextAction } =
			this.state

		const fields = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('principal')
			  })
			: []

		const tabProps = {
			basic: {
				disableForm: disableForm,
				itemInfo: valueDataSelected,
				fields: fields,
				showMessage: showMessage,
				handleSubmit: op === 'add' ? this.handleNewCrud : this.handleEditCrud,
				method: op,
				handleCloseModal: this.handleRedirectHome,
				showWarning: showWarningPopup,
				handleWarning: this.handleWarningPopup,
				setFormChanged: this.handleSetFormChanged
			},
			assign_center: {
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'cc')?.tipo_perm ===
						'L' ||
					false,
				values: ledgerAccountCostCentersSearched,
				nicodcta: crudCode,
				handleSubmit: this.handleUpdateCostCenters,
				setFormChanged: this.handleSetFormChanged,
				showWarning: showWarningPopup,
				handleWarning: this.handleWarningPopup
			}
		}
		const propsCrudContainer = {
			urlApi: 'abm_cont_cuentas',
			getNewData: this.handleCrudChange,
			keyApi: 'cod_cta',
			handleExitAbm: this.handleRedirectHome,
			title: t('global.ledger_account'),
			showWarning: formChanged,
			handleWarning: this.handleWarningPopup,
			nextAction: nextAction,
			setNextAction: this.handleSetNextAction
		}

		const componentTabs = Array.from(ledgerAccountTabs).map((tab) => {
			const selectedTab: any =
				tab.type === 'basic'
					? {
							key: tab.key,
							title: t(tab.label),
							content:
								tabActive === tab.key ? (
									<CrudLedgerAccountData {...tabProps.basic} />
								) : (
									<div />
								)
					  }
					: tab.type === 'assignCenter' && {
							key: tab.key,
							title: t(tab.label),
							disabled: !(
								!(
									op === 'add' ||
									fieldsConfig.tabs.find(
										(tabResult) => tabResult.cod_tab === tab.key
									)?.tipo_perm === 'N'
								) &&
								ledgerAccountCostCentersSearched &&
								(ledgerAccountCostCentersSearched.lista1.length > 0 ||
									ledgerAccountCostCentersSearched.lista2.length > 0)
							),
							content:
								tabActive === tab.key ? (
									<LedgerAccountAssignCenterTab {...tabProps.assign_center} />
								) : (
									<div />
								)
					  }
			return selectedTab
		})

		return (
			<CrudsContainer {...propsCrudContainer}>
				<Row className={'pt-4'}>
					<Col>
						<Card.Title>
							{disableForm
								? t('ledger_account_data.title_view')
								: op === 'edit'
								? t('ledger_account_data.title_edit')
								: t('ledger_account_data.title_new')}
						</Card.Title>
					</Col>
				</Row>
				{valueDataSelected && (
					<>
						<Row>
							<Col sm={1}>
								<strong>{t('global.internal_code')}</strong>
							</Col>
							<Col className={'text-left'}>
								{valueDataSelected && valueDataSelected.nicodcta}
							</Col>
						</Row>
						<Row className={'pb-2'}>
							<Col sm={1}>
								<strong>{t('global.name')}</strong>
							</Col>
							<Col className={'text-left'}>
								{valueDataSelected && valueDataSelected.descrip}
							</Col>
						</Row>
					</>
				)}
				<CommonTabs
					customTabs={componentTabs}
					defaultActiveKey={tabActive}
					getActiveKey={this.handleActiveKey}
					showWarning={formChanged}
					handleWarning={this.handleWarningPopup}
					nextTab={nextTab}
					setNextTab={this.handleSetNextTab}
				/>
			</CrudsContainer>
		)
	}
}

const mapStateToProps = ({ configCrud, ledgerAccountCostCenters }: any) => {
	const {
		fieldsConfig,
		fieldsAltaConfig,
		valueDataSelected,
		valueDataUpdated
	} = configCrud
	const { ledgerAccountCostCentersSearched, ledgerAccountCostCentersUpdated } =
		ledgerAccountCostCenters
	return {
		fieldsAltaConfig,
		fieldsConfig,
		valueDataSelected,
		valueDataUpdated,
		ledgerAccountCostCentersSearched,
		ledgerAccountCostCentersUpdated
	}
}

const connectForm = connect(mapStateToProps, {
	crudsGetSingle,
	crudsCreate,
	crudsClearStore,
	showMessage,
	crudGetLedgerAccountCenters,
	crudUpdateLedgerAccountCenters,
	crudClearCostCenterStore
})(LedgerAccountDataContent)

export default withTranslation()(withMenu(withRouter(connectForm)))
