import { getValidations } from 'actions'
import NotificationMessage from 'components/common/notificationMessage'
import { P_HEADER_DATA_VALIDATION } from 'constants/ConfigProcessNames'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getFiltersData } from 'lib/Utils'
import { IConfigFieldsForm } from 'models/Budget'
import {
	IGetValidationsParams,
	IGetValidationsSuccessResponse
} from 'models/HeaderDataValidation'
import React, { Component, Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'
import HeaderDataValidationForm from './HeaderDataValidationForm'
import HeaderDataValidationTable from './HeaderDataValidationTable'
interface IHeaderDataValidationComponentProps {
	headerValidationFormRef: any
	getValidations: (payload: IGetValidationsParams) => void
	idOperacion?: string
	validationList: IGetValidationsSuccessResponse | null
	config: IConfigFieldsForm
	callBackReturn?: () => void
}

interface IHeaderDataValidationComponentState {}

class HeaderDataValidationComponent extends Component<
	IHeaderDataValidationComponentProps,
	IHeaderDataValidationComponentState
> {
	componentDidMount = () => {
		const { idOperacion, getValidations } = this.props
		getValidations({ idOperacion: idOperacion || '' })
	}

	handleCallBack = () => {}

	handleCloseError = () => {}

	render() {
		const { config, idOperacion, callBackReturn, headerValidationFormRef } =
			this.props
		const fieldsTable = config
			? getFiltersData(config.campos, { agrupador: 'grilla' })
			: []

		const fieldsForm = config
			? getFiltersData(config.campos, { agrupador: 'formulario' })
			: []

		const fieldsTitle: IFieldStructure | any = config
			? _.findWhere(config.campos, { agrupador: 'titulo' })
			: {}

		const showError = fieldsTitle ? fieldsTitle.visible : 0

		return (
			<Fragment>
				<Col sm={12}>
					{showError && (
						<NotificationMessage
							showError={showError === 1}
							errorMessage={fieldsTitle.label}
							handleCloseError={this.handleCloseError}
							type={'danger'}
						/>
					)}
				</Col>
				<HeaderDataValidationTable fields={fieldsTable} />
				<HeaderDataValidationForm
					fields={fieldsForm}
					idOperacion={idOperacion || ''}
					callBackReturn={callBackReturn || this.handleCallBack}
					headerValidationFormRef={headerValidationFormRef}
				/>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ headerDataValidation, voucher }: any) => {
	const config = voucher.config
		? voucher.config[P_HEADER_DATA_VALIDATION]
		: null

	const { validationList } = headerDataValidation
	return { validationList, config }
}

const mapDispatchToProps = { getValidations }

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HeaderDataValidationComponent)
