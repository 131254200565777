import React, { Component, Fragment } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

class ConfirmModalNoButton extends Component {
	render() {
		const {
			handleCloseModal,
			handleCancelButton,
			handleSubmitButton,
			labelCancelButtonModal,
			labelSubmitButton,
			modalTitle,
			messageBody,
			showModal,
			iconTitle,
			t
		} = this.props

		const closeButton = labelCancelButtonModal || t('global.discard')
		const submitButton = labelSubmitButton || t('global.save')

		return (
			<Fragment>
				<Modal
					show={showModal}
					onHide={handleCloseModal}
					aria-labelledby={'ModalHeader'}
				>
					<Modal.Header closeButton>
						{modalTitle && (
							<Modal.Title id={'ModalHeader'}>
								<Fragment>
									{modalTitle}
									{iconTitle}
								</Fragment>
							</Modal.Title>
						)}
					</Modal.Header>
					<Modal.Body>{messageBody}</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={handleSubmitButton}>
							{submitButton}
						</Button>
						<Button variant="secondary" onClick={handleCancelButton}>
							{closeButton}
						</Button>
					</Modal.Footer>
				</Modal>
			</Fragment>
		)
	}
}

export default withTranslation()(ConfirmModalNoButton)
