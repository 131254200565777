import {
	CLEAR_VALIDATION_TABLE_DATA,
	GET_FILTER_CC_ASSIGN_TYPE,
	GET_FILTER_CC_ASSIGN_TYPE_SUCCESS,
	GET_FILTER_CONDITION_SALE,
	GET_FILTER_CONDITION_SALE_SUCCESS,
	GET_FILTER_LA_ASSIGN_ORDER,
	GET_FILTER_LA_ASSIGN_ORDER_SUCCESS,
	GET_FILTER_LA_ASSIGN_STATUS,
	GET_FILTER_LA_ASSIGN_STATUS_SUCCESS,
	GET_FILTER_LA_ASSIGN_TYPE,
	GET_FILTER_LA_ASSIGN_TYPE_SUCCESS,
	GET_FILTER_PRODUCT,
	GET_FILTER_PRODUCT_SUCCESS,
	GET_FILTER_TRANSPORT,
	GET_FILTER_TRANSPORT_SUCCESS,
	GET_OPTIONS_FILTER_AFFECTED,
	GET_OPTIONS_FILTER_AFFECTED_SUCCESS,
	GET_ORDER_CBT_CAB,
	GET_ORDER_CBT_CAB_SUCCESS,
	GET_TABLE_ABM_TABS,
	GET_TABLE_ABM_TABS_SUCCESS,
	GET_TABLE_ALIGNMENT_TYPE,
	GET_TABLE_ALIGNMENT_TYPE_SUCCESS,
	GET_TABLE_AV_ACCOUNTING,
	GET_TABLE_AV_ACCOUNTING_SUCCESS,
	GET_TABLE_AV_FUNDS,
	GET_TABLE_AV_FUNDS_SUCCESS,
	GET_TABLE_AV_PURCHASES,
	GET_TABLE_AV_PURCHASES_SUCCESS,
	GET_TABLE_AV_SALES,
	GET_TABLE_AV_SALES_SUCCESS,
	GET_TABLE_AV_STOCK,
	GET_TABLE_AV_STOCK_SUCCESS,
	GET_TABLE_BARCODE_FORMAT,
	GET_TABLE_BARCODE_FORMAT_SUCCESS,
	GET_TABLE_BONIFICATIONS,
	GET_TABLE_BONIFICATIONS_SUCCESS,
	GET_TABLE_BONIF_RATES,
	GET_TABLE_BONIF_RATES_SUCCESS,
	GET_TABLE_BRANCH_OFFICES,
	GET_TABLE_BRANCH_OFFICES_SUCCESS,
	GET_TABLE_CC_IMPUTATION_TYPE,
	GET_TABLE_CC_IMPUTATION_TYPE_SUCCESS,
	GET_TABLE_COMMISSION_TYPE,
	GET_TABLE_COMMISSION_TYPE_SUCCESS,
	GET_TABLE_CONCEPTS_BACKGROUNDS,
	GET_TABLE_CONCEPTS_BACKGROUNDS_SUCCESS,
	GET_TABLE_COST_ACCOUNTING_TYPE,
	GET_TABLE_COST_ACCOUNTING_TYPE_SUCCESS,
	GET_TABLE_COST_CENTERS,
	GET_TABLE_COST_CENTERS_CATEGORY,
	GET_TABLE_COST_CENTERS_CATEGORY_SUCCESS,
	GET_TABLE_COST_CENTERS_SUCCESS,
	GET_TABLE_COUNTRY,
	GET_TABLE_COUNTRY_SUCCESS,
	GET_TABLE_COURIERS,
	GET_TABLE_COURIERS_SUCCESS,
	GET_TABLE_CREDIT,
	GET_TABLE_CREDIT_SUCCESS,
	GET_TABLE_CUF,
	GET_TABLE_CUF_DATA,
	GET_TABLE_CUF_DATA_SUCCESS,
	GET_TABLE_CUF_SUCCESS,
	GET_TABLE_CURRENCIES,
	GET_TABLE_CURRENCIES_SUCCESS,
	GET_TABLE_CUSTOMER_ATTR,
	GET_TABLE_CUSTOMER_ATTR_LIST,
	GET_TABLE_CUSTOMER_ATTR_LIST_SUCCESS,
	GET_TABLE_CUSTOMER_ATTR_SUCCESS,
	GET_TABLE_CUSTOMER_BRANCHES,
	GET_TABLE_CUSTOMER_BRANCHES_SUCCESS,
	GET_TABLE_CUSTOMER_CATEGORY,
	GET_TABLE_CUSTOMER_CATEGORY_SUCCESS,
	GET_TABLE_DATA_TYPE,
	GET_TABLE_DATA_TYPE_SUCCESS,
	GET_TABLE_DEPOTS,
	GET_TABLE_DEPOTS_SUCCESS,
	GET_TABLE_DOCUMENT_TYPE,
	GET_TABLE_DOCUMENT_TYPE_SUCCESS,
	GET_TABLE_ESTIMATION_TYPE,
	GET_TABLE_ESTIMATION_TYPE_SUCCESS,
	GET_TABLE_FOREIGN_CURRENCIES,
	GET_TABLE_FOREIGN_CURRENCIES_SUCCESS,
	GET_TABLE_FUNDS_ACCOUNTS_CAT,
	GET_TABLE_FUNDS_ACCOUNTS_CAT_SUCCESS,
	GET_TABLE_FUNDS_CONCEPTS_TYPE,
	GET_TABLE_FUNDS_CONCEPTS_TYPE_SUCCESS,
	GET_TABLE_FUND_ACCOUNT_ATTR,
	GET_TABLE_FUND_ACCOUNT_ATTR_LIST,
	GET_TABLE_FUND_ACCOUNT_ATTR_LIST_SUCCESS,
	GET_TABLE_FUND_ACCOUNT_ATTR_SUCCESS,
	GET_TABLE_GENERATION_TYPE,
	GET_TABLE_GENERATION_TYPE_SUCCESS,
	GET_TABLE_IMPUTATION_TYPE,
	GET_TABLE_IMPUTATION_TYPE_SUCCESS,
	GET_TABLE_ITEM_CAT,
	GET_TABLE_ITEM_CAT_SUCCESS,
	GET_TABLE_LEDGER_ACCOUNTS,
	GET_TABLE_LEDGER_ACCOUNTS_CATEGORY,
	GET_TABLE_LEDGER_ACCOUNTS_CATEGORY_SUCCESS,
	GET_TABLE_LEDGER_ACCOUNTS_SUCCESS,
	GET_TABLE_MASKS,
	GET_TABLE_MASKS_SUCCESS,
	GET_TABLE_MEASUREMENT_UNIT,
	GET_TABLE_MEASUREMENT_UNIT_SUCCESS,
	GET_TABLE_MESSAGE,
	GET_TABLE_MESSAGE_SUCCESS,
	GET_TABLE_NUMERATORS,
	GET_TABLE_NUMERATORS_SUCCESS,
	GET_TABLE_PARENT_COST_CENTER,
	GET_TABLE_PARENT_COST_CENTER_SUCCESS,
	GET_TABLE_PARENT_LEDGER_ACCOUNT,
	GET_TABLE_PARENT_LEDGER_ACCOUNT_SUCCESS,
	GET_TABLE_PAYMENT_CONDITIONS,
	GET_TABLE_PAYMENT_CONDITIONS_SUCCESS,
	GET_TABLE_PERMISSION_TYPE,
	GET_TABLE_PERMISSION_TYPE_SUCCESS,
	GET_TABLE_PRICES,
	GET_TABLE_PRICES_SUCCESS,
	GET_TABLE_PRICE_LIST,
	GET_TABLE_PRICE_LIST_SUCCESS,
	GET_TABLE_PROCESS,
	GET_TABLE_PROCESS_SUCCESS,
	GET_TABLE_PRODUCTS,
	GET_TABLE_PRODUCTS_SUCCESS,
	GET_TABLE_PRODUCT_ATTR,
	GET_TABLE_PRODUCT_ATTR_LIST,
	GET_TABLE_PRODUCT_ATTR_LIST_SUCCESS,
	GET_TABLE_PRODUCT_ATTR_SUCCESS,
	GET_TABLE_PRODUCT_CAT,
	GET_TABLE_PRODUCT_CAT_SUCCESS,
	GET_TABLE_PROVIDER_ATTR,
	GET_TABLE_PROVIDER_ATTR_LIST,
	GET_TABLE_PROVIDER_ATTR_LIST_SUCCESS,
	GET_TABLE_PROVIDER_ATTR_SUCCESS,
	GET_TABLE_PROVIDER_CAT,
	GET_TABLE_PROVIDER_CAT_SUCCESS,
	GET_TABLE_PROVINCE,
	GET_TABLE_PROVINCE_SUCCESS,
	GET_TABLE_RESPONSIBLE_TYPE,
	GET_TABLE_RESPONSIBLE_TYPE_SUCCESS,
	GET_TABLE_SELLERS,
	GET_TABLE_SELLERS_SUCCESS,
	GET_TABLE_SYMBOLS,
	GET_TABLE_SYMBOLS_SUCCESS,
	GET_TABLE_TABLE,
	GET_TABLE_TABLE_SUCCESS,
	GET_TABLE_TABLE_TYPE,
	GET_TABLE_TABLE_TYPE_SUCCESS,
	GET_TABLE_TAXES,
	GET_TABLE_TAXES_RATE,
	GET_TABLE_TAXES_RATE_SUCCESS,
	GET_TABLE_TAXES_SUCCESS,
	GET_TABLE_TERMSOFSALES,
	GET_TABLE_TERMSOFSALES_SUCCESS,
	GET_TABLE_TES_STATES,
	GET_TABLE_TES_STATES_SUCCESS,
	GET_TABLE_TITLES,
	GET_TABLE_TITLES_SUCCESS,
	GET_TABLE_USUAL_COIN,
	GET_TABLE_USUAL_COIN_SUCCESS,
	GET_TABLE_VALIDATIONS,
	GET_TABLE_VALIDATIONS_SUCCESS,
	GET_TABLE_VALIDATION_TYPE,
	GET_TABLE_VALIDATION_TYPE_SUCCESS,
	GET_TABLE_VALIDATION_TYPE_T,
	GET_TABLE_VALIDATION_TYPE_T_SUCCESS,
	GET_TABLE_VALIDATION_VALUES,
	GET_TABLE_VALIDATION_VALUES_SUCCESS,
	GET_TABLE_VOUCHER_FIELDS,
	GET_TABLE_VOUCHER_FIELDS_SUCCESS,
	GET_TABLE_VOUCHER_MODULE,
	GET_TABLE_VOUCHER_MODULE_SUCCESS,
	GET_TABLE_VOUCHER_PROCESS,
	GET_TABLE_VOUCHER_PROCESS_SUCCESS,
	GET_TABLE_VOUCHER_TYPE_ABM,
	GET_TABLE_VOUCHER_TYPE_ABM_SUCCESS
} from 'constants/ActionsTypes'
import { getValuesDataSuccessInterface } from 'constants/valuesInterfaces/interfaces'
import {
	IGetFilterCCAssignTypeResArray,
	IGetFilterLAAssignOrderResArray,
	IGetFilterLAAssignStatusResArray,
	IGetFilterLAAssignTypeResArray,
	IGetTableAlignmentTypeResArray,
	IGetTableBarcodeFormat,
	IGetTableBarcodeFormatRes,
	IGetTableBonifRates,
	IGetTableBranchOfficesParams,
	IGetTableBranchOfficesResArray,
	IGetTableCCImputationTypeResArray,
	IGetTableComissionTypeResArray,
	IGetTableConceptsBackgroundResArray,
	IGetTableCostAccountingTypeRes,
	IGetTableCostCenters,
	IGetTableCostCentersCategoryResArray,
	IGetTableCountryResArray,
	IGetTableCouriersResArray,
	IGetTableCredit,
	IGetTableCUF,
	IGetTableCufData,
	IGetTableCufDataRes,
	IGetTableCUFResArray,
	IGetTableCustomerAttrList,
	IGetTableCustomerAttrListResArray,
	IGetTableCustomerAttrRes,
	IGetTableCustomerBranches,
	IGetTableCustomerBranchesResArray,
	IGetTableCustomerCategoryResArray,
	IGetTableDataTypeResArray,
	IGetTableDocumentTypeResArray,
	IGetTableForeignCurrenciesResArray,
	IGetTableFundAccountAttrList,
	IGetTableFundAccountAttrListResArray,
	IGetTableFundAccountAttrRes,
	IGetTableFundsAccountsCatResArray,
	IGetTableFundsConceptsTypeResArray,
	IGetTableGenerationTypeResArray,
	IGetTableGenericRequest,
	IGetTableGenericResArray,
	IGetTableImputationTypeResArray,
	IGetTableItemCatResArray,
	IGetTableLedgerAccounts,
	IGetTableLedgerAccountsCategoryResArray,
	IGetTableLedgerAccountsResArray,
	IGetTableMeasurementUnitResArray,
	IGetTableMessageListResArray,
	IGetTableNumeratorsResArray,
	IGetTableParentCostCenter,
	IGetTableParentLedgerAccount,
	IGetTablePaymentConditionsResArray,
	IGetTablePriceListResArray,
	IGetTablePricesResArray,
	IGetTableProductAttrListResArray,
	IGetTableProductAttrRes,
	IGetTableProductCategoryRes,
	IGetTableProducts,
	IGetTableProductsResArray,
	IGetTableProviderAttrList,
	IGetTableProviderAttrListResArray,
	IGetTableProviderAttrRes,
	IGetTableProviderCategoryResArray,
	IGetTableProvinceResArray,
	IGetTableResponsibleTypeResArray,
	IGetTableSellersResArray,
	IGetTableSymbolsResArray,
	IGetTableTableResArray,
	IGetTableTableTypeResArray,
	IGetTableTaxes,
	IGetTableTaxesRate,
	IGetTableTaxesRateResArray,
	IGetTableTaxesResArray,
	IGetTableTermOfSalesResArray,
	IGetTableTesStatesResArray,
	IGetTableValidationTypeResArray,
	IGetTableVoucherFields,
	IGetTableVoucherProcess,
	IGetTableVoucherTypeResArray
} from 'models/TableCalls/TableCallsInterface'

export const getTableCommissionType = (payload: any) => {
	return {
		type: GET_TABLE_COMMISSION_TYPE,
		payload
	}
}

export const getTableCommissionTypeSuccess = (
	payload: IGetTableComissionTypeResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_COMMISSION_TYPE_SUCCESS,
		payload
	}
}

export const getTableMessage = (payload: any) => {
	return {
		type: GET_TABLE_MESSAGE,
		payload
	}
}

export const getTableMessageSuccess = (
	payload: IGetTableMessageListResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_MESSAGE_SUCCESS,
		payload
	}
}

export const getTableTable = (payload: any) => {
	return {
		type: GET_TABLE_TABLE,
		payload
	}
}

export const getTableTableSuccess = (
	payload: IGetTableTableResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_TABLE_SUCCESS,
		payload
	}
}

export const getTableTableType = (payload: any) => {
	return {
		type: GET_TABLE_TABLE_TYPE,
		payload
	}
}

export const getTableTableTypeSuccess = (
	payload: IGetTableTableTypeResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_TABLE_TYPE_SUCCESS,
		payload
	}
}

export const getTableMeasurementUnit = (payload: any) => {
	return {
		type: GET_TABLE_MEASUREMENT_UNIT,
		payload
	}
}

export const getTableMeasurementUnitSuccess = (
	payload: IGetTableMeasurementUnitResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_MEASUREMENT_UNIT_SUCCESS,
		payload
	}
}

export const getTableAlignmentType = (payload: any) => {
	return {
		type: GET_TABLE_ALIGNMENT_TYPE,
		payload
	}
}

export const getTableAlignmentTypeSuccess = (
	payload: IGetTableAlignmentTypeResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_ALIGNMENT_TYPE_SUCCESS,
		payload
	}
}

export const getTableDataType = (payload: any) => {
	return {
		type: GET_TABLE_DATA_TYPE,
		payload
	}
}

export const getTableDataTypeSuccess = (
	payload: IGetTableDataTypeResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_DATA_TYPE_SUCCESS,
		payload
	}
}

export const getTableValidationType = (payload: any) => {
	return {
		type: GET_TABLE_VALIDATION_TYPE,
		payload
	}
}

export const getTableValidationTypeSuccess = (
	payload: IGetTableValidationTypeResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_VALIDATION_TYPE_SUCCESS,
		payload
	}
}

export const getTableValidationTypeT = (payload: any) => {
	return {
		type: GET_TABLE_VALIDATION_TYPE_T,
		payload
	}
}

export const getTableValidationTypeTSuccess = (
	payload: IGetTableValidationTypeResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_VALIDATION_TYPE_T_SUCCESS,
		payload
	}
}

export const getTableResponsibleType = (payload: any) => {
	return {
		type: GET_TABLE_RESPONSIBLE_TYPE,
		payload
	}
}

export const getTableResponsibleTypeSuccess = (
	payload: IGetTableResponsibleTypeResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_RESPONSIBLE_TYPE_SUCCESS,
		payload
	}
}

export const getTableDocumentType = (payload: any) => {
	return {
		type: GET_TABLE_DOCUMENT_TYPE,
		payload
	}
}

export const getTableDocumentTypeSuccess = (
	payload: IGetTableDocumentTypeResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_DOCUMENT_TYPE_SUCCESS,
		payload
	}
}

export const getTableProvince = (payload: any) => {
	return {
		type: GET_TABLE_PROVINCE,
		payload
	}
}

export const getTableProvinceSuccess = (
	payload: IGetTableProvinceResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_PROVINCE_SUCCESS,
		payload
	}
}

export const getTableCountry = (payload: any) => {
	return {
		type: GET_TABLE_COUNTRY,
		payload
	}
}

export const getTableCountrySuccess = (
	payload: IGetTableCountryResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_COUNTRY_SUCCESS,
		payload
	}
}

export const getTableCouriers = (payload: any) => {
	return {
		type: GET_TABLE_COURIERS,
		payload
	}
}

export const getTableCouriersSuccess = (
	payload: IGetTableCouriersResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_COURIERS_SUCCESS,
		payload
	}
}

export const getTableTermsOfSales = (payload: any) => {
	return {
		type: GET_TABLE_TERMSOFSALES,
		payload
	}
}

export const getTableTermsOfSalesSuccess = (
	payload: IGetTableTermOfSalesResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_TERMSOFSALES_SUCCESS,
		payload
	}
}

export const getTablePriceList = (payload: any) => {
	return {
		type: GET_TABLE_PRICE_LIST,
		payload
	}
}

export const getTablePriceListSuccess = (
	payload: IGetTablePriceListResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_PRICE_LIST_SUCCESS,
		payload
	}
}

export const getTableSellers = (payload: any) => {
	return {
		type: GET_TABLE_SELLERS,
		payload
	}
}

export const getTableSellersSuccess = (
	payload: IGetTableSellersResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_SELLERS_SUCCESS,
		payload
	}
}

export const getTableForeignCurrencies = (payload: any) => {
	return {
		type: GET_TABLE_FOREIGN_CURRENCIES,
		payload
	}
}

export const getTableForeignCurrenciesSuccess = (
	payload: IGetTableForeignCurrenciesResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_FOREIGN_CURRENCIES_SUCCESS,
		payload
	}
}

export const getTableCurrencies = (payload: any) => {
	return {
		type: GET_TABLE_CURRENCIES,
		payload
	}
}

export const getTableCurrenciesSuccess = (
	payload: IGetTableForeignCurrenciesResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_CURRENCIES_SUCCESS,
		payload
	}
}

export const getTableConceptsBackgrounds = (payload: any) => {
	return {
		type: GET_TABLE_CONCEPTS_BACKGROUNDS,
		payload
	}
}

export const getTableConceptsBackgroundsSuccess = (
	payload: IGetTableConceptsBackgroundResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_CONCEPTS_BACKGROUNDS_SUCCESS,
		payload
	}
}

export const getTableBranchOffices = (
	payload: IGetTableBranchOfficesParams
) => {
	return {
		type: GET_TABLE_BRANCH_OFFICES,
		payload
	}
}

export const getTableBranchOfficesSuccess = (
	payload: IGetTableBranchOfficesResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_BRANCH_OFFICES_SUCCESS,
		payload
	}
}

export const getTableGenerationType = (payload: any) => {
	return {
		type: GET_TABLE_GENERATION_TYPE,
		payload
	}
}

export const getTableGenerationTypeSuccess = (
	payload: IGetTableGenerationTypeResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_GENERATION_TYPE_SUCCESS,
		payload
	}
}

export const getTableVoucherTypeAdmin = (payload: any) => {
	return {
		type: GET_TABLE_VOUCHER_TYPE_ABM,
		payload
	}
}

export const getTableVoucherTypeAdminSuccess = (
	payload: IGetTableVoucherTypeResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_VOUCHER_TYPE_ABM_SUCCESS,
		payload
	}
}

export const getTableCustomerCategory = (payload: any) => {
	return {
		type: GET_TABLE_CUSTOMER_CATEGORY,
		payload
	}
}

export const getTableCustomerCategorySuccess = (
	payload: IGetTableCustomerCategoryResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_CUSTOMER_CATEGORY_SUCCESS,
		payload
	}
}

export const getTablePaymentConditions = (payload: any) => {
	return {
		type: GET_TABLE_PAYMENT_CONDITIONS,
		payload
	}
}

export const getTablePaymentConditionsSuccess = (
	payload: IGetTablePaymentConditionsResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_PAYMENT_CONDITIONS_SUCCESS,
		payload
	}
}

export const getTableProviderCat = (payload: any) => {
	return {
		type: GET_TABLE_PROVIDER_CAT,
		payload
	}
}

export const getTableProviderCatSuccess = (
	payload: IGetTableProviderCategoryResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_PROVIDER_CAT_SUCCESS,
		payload
	}
}

export const getTableProviderAttr = () => ({
	type: GET_TABLE_PROVIDER_ATTR
})

export const getTableProviderAttrSuccess = (
	payload: IGetTableProviderAttrRes
): getValuesDataSuccessInterface => ({
	type: GET_TABLE_PROVIDER_ATTR_SUCCESS,
	payload
})

export const getTableProviderAttrList = (
	payload: IGetTableProviderAttrList
) => ({
	type: GET_TABLE_PROVIDER_ATTR_LIST,
	payload
})

export const getTableProviderAttrListSuccess = (
	payload: IGetTableProviderAttrListResArray
): getValuesDataSuccessInterface => ({
	type: GET_TABLE_PROVIDER_ATTR_LIST_SUCCESS,
	payload
})

export const getTableTaxes = (payload: IGetTableTaxes) => {
	return {
		type: GET_TABLE_TAXES,
		payload
	}
}

export const getTableTaxesSuccess = (
	payload: IGetTableTaxesResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_TAXES_SUCCESS,
		payload
	}
}

export const getTableTaxesRate = (payload: IGetTableTaxesRate) => {
	return {
		type: GET_TABLE_TAXES_RATE,
		payload
	}
}

export const getTableTaxesRateSuccess = (
	payload: IGetTableTaxesRateResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_TAXES_RATE_SUCCESS,
		payload
	}
}

export const getTableCredit = (payload: IGetTableCredit) => {
	return {
		type: GET_TABLE_CREDIT,
		payload
	}
}

export const getTableCreditSuccess = (
	payload: any
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_CREDIT_SUCCESS,
		payload
	}
}

export const getTableCustomerAttr = (payload: any) => ({
	type: GET_TABLE_CUSTOMER_ATTR,
	payload
})

export const getTableCustomerAttrSuccess = (
	payload: IGetTableCustomerAttrRes
): getValuesDataSuccessInterface => ({
	type: GET_TABLE_CUSTOMER_ATTR_SUCCESS,
	payload
})

export const getTableCustomerAttrList = (
	payload: IGetTableCustomerAttrList
) => ({
	type: GET_TABLE_CUSTOMER_ATTR_LIST,
	payload
})

export const getTableCustomerAttrListSuccess = (
	payload: IGetTableCustomerAttrListResArray
): getValuesDataSuccessInterface => ({
	type: GET_TABLE_CUSTOMER_ATTR_LIST_SUCCESS,
	payload
})

export const getTableCustomerBranches = (
	payload: IGetTableCustomerBranches
) => {
	return {
		type: GET_TABLE_CUSTOMER_BRANCHES,
		payload
	}
}

export const getTableCustomerBranchesSuccess = (
	payload: IGetTableCustomerBranchesResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_CUSTOMER_BRANCHES_SUCCESS,
		payload
	}
}

export const getTableFundsAccountsCat = (payload: any) => {
	return {
		type: GET_TABLE_FUNDS_ACCOUNTS_CAT,
		payload
	}
}

export const getTableFundsAccountsCatSuccess = (
	payload: IGetTableFundsAccountsCatResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_FUNDS_ACCOUNTS_CAT_SUCCESS,
		payload
	}
}

export const getTableItemCat = (payload: any) => {
	return {
		type: GET_TABLE_ITEM_CAT,
		payload
	}
}

export const getTableItemCatSuccess = (
	payload: IGetTableItemCatResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_ITEM_CAT_SUCCESS,
		payload
	}
}

export const getTableNumerators = (payload: any) => {
	return {
		type: GET_TABLE_NUMERATORS,
		payload
	}
}

export const getTableNumeratorsSuccess = (
	payload: IGetTableNumeratorsResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_NUMERATORS_SUCCESS,
		payload
	}
}

export const getTableSymbols = (payload: any) => {
	return {
		type: GET_TABLE_SYMBOLS,
		payload
	}
}

export const getTableSymbolsSuccess = (
	payload: IGetTableSymbolsResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_SYMBOLS_SUCCESS,
		payload
	}
}

export const getTableTesStates = (payload: any) => {
	return {
		type: GET_TABLE_TES_STATES,
		payload
	}
}

export const getTableTesStatesSuccess = (
	payload: IGetTableTesStatesResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_TES_STATES_SUCCESS,
		payload
	}
}

export const getTableFundAccountAttr = () => ({
	type: GET_TABLE_FUND_ACCOUNT_ATTR
})

export const getTableFundAccountAttrSuccess = (
	payload: IGetTableFundAccountAttrRes
): getValuesDataSuccessInterface => ({
	type: GET_TABLE_FUND_ACCOUNT_ATTR_SUCCESS,
	payload
})

export const getTableFundAccountAttrList = (
	payload: IGetTableFundAccountAttrList
) => ({
	type: GET_TABLE_FUND_ACCOUNT_ATTR_LIST,
	payload
})

export const getTableFundAccountAttrListSuccess = (
	payload: IGetTableFundAccountAttrListResArray
): getValuesDataSuccessInterface => ({
	type: GET_TABLE_FUND_ACCOUNT_ATTR_LIST_SUCCESS,
	payload
})

export const getTableFundsConceptsType = (payload: any) => {
	return {
		type: GET_TABLE_FUNDS_CONCEPTS_TYPE,
		payload
	}
}

export const getTableFundsConceptsTypeSuccess = (
	payload: IGetTableFundsConceptsTypeResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_FUNDS_CONCEPTS_TYPE_SUCCESS,
		payload
	}
}

export const getTableImputationType = (payload: any) => {
	return {
		type: GET_TABLE_IMPUTATION_TYPE,
		payload
	}
}

export const getTableImputationTypeSuccess = (
	payload: IGetTableImputationTypeResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_IMPUTATION_TYPE_SUCCESS,
		payload
	}
}

export const getTableLedgerAccountsCategory = (payload: any) => {
	return {
		type: GET_TABLE_LEDGER_ACCOUNTS_CATEGORY,
		payload
	}
}

export const getTableLedgerAccountsCategorySuccess = (
	payload: IGetTableLedgerAccountsCategoryResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_LEDGER_ACCOUNTS_CATEGORY_SUCCESS,
		payload
	}
}

export const getTableParentLedgerAccount = (
	payload: IGetTableParentLedgerAccount
) => ({
	type: GET_TABLE_PARENT_LEDGER_ACCOUNT,
	payload
})

export const getTableParentLedgerAccountSuccess = (payload: string): any => {
	return {
		type: GET_TABLE_PARENT_LEDGER_ACCOUNT_SUCCESS,
		payload
	}
}

export const getTableCostCentersCategory = (payload: any) => {
	return {
		type: GET_TABLE_COST_CENTERS_CATEGORY
	}
}

export const getTableCostCentersCategorySuccess = (
	payload: IGetTableCostCentersCategoryResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_COST_CENTERS_CATEGORY_SUCCESS,
		payload
	}
}

export const getTableCCImputationType = (payload: any) => {
	return {
		type: GET_TABLE_CC_IMPUTATION_TYPE,
		payload
	}
}

export const getTableCCImputationTypeSuccess = (
	payload: IGetTableCCImputationTypeResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_CC_IMPUTATION_TYPE_SUCCESS,
		payload
	}
}

export const getTableParentCostCenter = (
	payload: IGetTableParentCostCenter
) => {
	return {
		type: GET_TABLE_PARENT_COST_CENTER,
		payload
	}
}

export const getTableParentCostCenterSuccess = (payload: String): any => {
	return {
		type: GET_TABLE_PARENT_COST_CENTER_SUCCESS,
		payload
	}
}

export const getTableLedgerAccounts = (payload: IGetTableLedgerAccounts) => {
	return {
		type: GET_TABLE_LEDGER_ACCOUNTS,
		payload
	}
}

export const getTableLedgerAccountsSuccess = (
	payload: IGetTableLedgerAccountsResArray
) => {
	return {
		type: GET_TABLE_LEDGER_ACCOUNTS_SUCCESS,
		payload
	}
}

export const getFilterTransport = () => {
	return {
		type: GET_FILTER_TRANSPORT
	}
}

export const getFilterTransportSuccess = (
	payload: IGetTableGenericResArray
): any => {
	return {
		type: GET_FILTER_TRANSPORT_SUCCESS,
		payload
	}
}

export const getFilterLAAssignType = (payload: any) => {
	return {
		type: GET_FILTER_LA_ASSIGN_TYPE,
		payload
	}
}

export const getFilterLAAssignTypeSuccess = (
	payload: IGetFilterLAAssignTypeResArray
) => {
	return {
		type: GET_FILTER_LA_ASSIGN_TYPE_SUCCESS,
		payload
	}
}

export const getFilterLAAssignStatus = (payload: any) => {
	return {
		type: GET_FILTER_LA_ASSIGN_STATUS,
		payload
	}
}

export const getFilterLAAssignStatusSuccess = (
	payload: IGetFilterLAAssignStatusResArray
) => {
	return {
		type: GET_FILTER_LA_ASSIGN_STATUS_SUCCESS,
		payload
	}
}

export const getFilterLAAssignOrder = (payload: any) => {
	return {
		type: GET_FILTER_LA_ASSIGN_ORDER,
		payload
	}
}

export const getFilterConditionSale = () => {
	return {
		type: GET_FILTER_CONDITION_SALE
	}
}

export const getFilterConditionSaleSuccess = (
	payload: IGetTableGenericResArray
): any => {
	return {
		type: GET_FILTER_CONDITION_SALE_SUCCESS,
		payload
	}
}

export const getFilterLAAssignOrderSuccess = (
	payload: IGetFilterLAAssignOrderResArray
) => {
	return {
		type: GET_FILTER_LA_ASSIGN_ORDER_SUCCESS,
		payload
	}
}

export const getTableCostCenters = (payload: IGetTableCostCenters) => {
	return {
		type: GET_TABLE_COST_CENTERS,
		payload
	}
}

export const getTableCostCentersSuccess = (
	payload: IGetTableLedgerAccountsResArray
) => {
	return {
		type: GET_TABLE_COST_CENTERS_SUCCESS,
		payload
	}
}

export const getFilterCCAssignType = (payload: any) => {
	return {
		type: GET_FILTER_CC_ASSIGN_TYPE,
		payload
	}
}

export const getFilterCCAssignTypeSuccess = (
	payload: IGetFilterCCAssignTypeResArray
) => {
	return {
		type: GET_FILTER_CC_ASSIGN_TYPE_SUCCESS,
		payload
	}
}

export const getTableProductCategory = (payload: any) => {
	return {
		type: GET_TABLE_PRODUCT_CAT,
		payload
	}
}

export const getTableProductCategorySuccess = (
	payload: IGetTableProductCategoryRes
) => {
	return {
		type: GET_TABLE_PRODUCT_CAT_SUCCESS,
		payload
	}
}

export const getTableBarcodeFormat = (payload: IGetTableBarcodeFormat) => {
	return {
		type: GET_TABLE_BARCODE_FORMAT,
		payload
	}
}

export const getTableBarcodeFormatSuccess = (
	payload: IGetTableBarcodeFormatRes
) => {
	return {
		type: GET_TABLE_BARCODE_FORMAT_SUCCESS,
		payload
	}
}

export const getTableCostAccountingType = (payload: any) => {
	return {
		type: GET_TABLE_COST_ACCOUNTING_TYPE,
		payload
	}
}

export const getTableCostAccountingTypeSuccess = (
	payload: IGetTableCostAccountingTypeRes
) => {
	return {
		type: GET_TABLE_COST_ACCOUNTING_TYPE_SUCCESS,
		payload
	}
}

export const getOrdenCbtCab = () => {
	return {
		type: GET_ORDER_CBT_CAB
	}
}

export const getOrdenCbtCabSuccess = (
	payload: IGetTableGenericResArray
): any => {
	return {
		type: GET_ORDER_CBT_CAB_SUCCESS,
		payload
	}
}

export const getTableCUF = (payload: IGetTableCUF) => {
	return {
		type: GET_TABLE_CUF,
		payload
	}
}

export const getTableCUFSuccess = (payload: IGetTableCUFResArray) => {
	return {
		type: GET_TABLE_CUF_SUCCESS,
		payload
	}
}

export const getTableProductAttr = () => ({
	type: GET_TABLE_PRODUCT_ATTR
})

export const getTableProductAttrSuccess = (
	payload: IGetTableProductAttrRes
): getValuesDataSuccessInterface => ({
	type: GET_TABLE_PRODUCT_ATTR_SUCCESS,
	payload
})

export const getTableProductAttrList = (
	payload: IGetTableCustomerAttrList
) => ({
	type: GET_TABLE_PRODUCT_ATTR_LIST,
	payload
})

export const getTableProductAttrListSuccess = (
	payload: IGetTableProductAttrListResArray
): getValuesDataSuccessInterface => ({
	type: GET_TABLE_PRODUCT_ATTR_LIST_SUCCESS,
	payload
})

export const getTableProducts = (payload: IGetTableProducts) => ({
	type: GET_TABLE_PRODUCTS,
	payload
})

export const getTableProductsSuccess = (
	payload: IGetTableProductsResArray
): getValuesDataSuccessInterface => ({
	type: GET_TABLE_PRODUCTS_SUCCESS,
	payload
})
export const getOptionsFilterAffected = () => {
	return {
		type: GET_OPTIONS_FILTER_AFFECTED
	}
}

export const getOptionsFilterAffectedSuccess = (payload: any): any => {
	return {
		type: GET_OPTIONS_FILTER_AFFECTED_SUCCESS,
		payload
	}
}

export const getTablePrices = (payload: any) => {
	return {
		type: GET_TABLE_PRICES,
		payload
	}
}

export const getTablePricesSuccess = (
	payload: IGetTablePricesResArray
): getValuesDataSuccessInterface => {
	return {
		type: GET_TABLE_PRICES_SUCCESS,
		payload
	}
}

export const getTableDepots = () => {
	return {
		type: GET_TABLE_DEPOTS
	}
}

export const getTableDepotsSuccess = (
	payload: IGetTableGenericResArray
): any => {
	return {
		type: GET_TABLE_DEPOTS_SUCCESS,
		payload
	}
}

export const getTableAVPurchases = (payload: IGetTableGenericRequest) => {
	return {
		type: GET_TABLE_AV_PURCHASES,
		payload
	}
}

export const getTableCufData = (payload: IGetTableCufData) => {
	return {
		type: GET_TABLE_CUF_DATA,
		payload
	}
}

export const getTableAVPurchasesSuccess = (
	payload: IGetTableGenericResArray
) => {
	return {
		type: GET_TABLE_AV_PURCHASES_SUCCESS,
		payload
	}
}

export const getTableCufDataSuccess = (payload: IGetTableCufDataRes) => {
	return {
		type: GET_TABLE_CUF_DATA_SUCCESS,
		payload
	}
}

export const getTableBonifications = () => {
	return {
		type: GET_TABLE_BONIFICATIONS
	}
}

export const getTableBonificationsSuccess = (
	payload: IGetTableGenericResArray
) => {
	return {
		type: GET_TABLE_BONIFICATIONS_SUCCESS,
		payload
	}
}

export const getTableAVSales = (payload: IGetTableGenericRequest) => {
	return {
		type: GET_TABLE_AV_SALES,
		payload
	}
}

export const getTableBonifRates = (payload: IGetTableBonifRates) => {
	return {
		type: GET_TABLE_BONIF_RATES,
		payload
	}
}

export const getTableAVSalesSuccess = (payload: IGetTableGenericResArray) => {
	return {
		type: GET_TABLE_AV_SALES_SUCCESS,
		payload
	}
}

export const getTableAVFunds = (payload: IGetTableGenericRequest) => {
	return {
		type: GET_TABLE_AV_FUNDS,
		payload
	}
}

export const getTableAVFundsSuccess = (payload: IGetTableGenericResArray) => {
	return {
		type: GET_TABLE_AV_FUNDS_SUCCESS,
		payload
	}
}

export const getTableAVStock = (payload: IGetTableGenericRequest) => {
	return {
		type: GET_TABLE_AV_STOCK,
		payload
	}
}

export const getTableAVStockSuccess = (payload: IGetTableGenericResArray) => {
	return {
		type: GET_TABLE_AV_STOCK_SUCCESS,
		payload
	}
}

export const getTableAVAccounting = (payload: IGetTableGenericRequest) => {
	return {
		type: GET_TABLE_AV_ACCOUNTING,
		payload
	}
}

export const getTableAVAccountingSuccess = (
	payload: IGetTableGenericResArray
) => {
	return {
		type: GET_TABLE_AV_ACCOUNTING_SUCCESS,
		payload
	}
}

export const getTableBonifRatesSuccess = (
	payload: IGetTableGenericResArray
) => {
	return {
		type: GET_TABLE_BONIF_RATES_SUCCESS,
		payload
	}
}

export const getTableVoucherModules = (payload: IGetTableGenericRequest) => {
	return {
		type: GET_TABLE_VOUCHER_MODULE,
		payload
	}
}

export const getTableVoucherModulesSuccess = (
	payload: IGetTableGenericResArray
) => {
	return {
		type: GET_TABLE_VOUCHER_MODULE_SUCCESS,
		payload
	}
}

export const getTableProcess = (payload: IGetTableGenericRequest) => {
	return {
		type: GET_TABLE_PROCESS,
		payload
	}
}

export const getTableProcessSuccess = (payload: IGetTableGenericResArray) => {
	return {
		type: GET_TABLE_PROCESS_SUCCESS,
		payload
	}
}

export const getTableVoucherProcess = (payload: IGetTableVoucherProcess) => {
	return {
		type: GET_TABLE_VOUCHER_PROCESS,
		payload
	}
}

export const getTableVoucherProcessSuccess = (
	payload: IGetTableGenericResArray
) => {
	return {
		type: GET_TABLE_VOUCHER_PROCESS_SUCCESS,
		payload
	}
}

export const getTableVoucherFields = (payload: IGetTableVoucherFields) => {
	return {
		type: GET_TABLE_VOUCHER_FIELDS,
		payload
	}
}

export const getTableVoucherFieldsSuccess = (
	payload: IGetTableGenericResArray
) => {
	return {
		type: GET_TABLE_VOUCHER_FIELDS_SUCCESS,
		payload
	}
}

export const getTableTitles = (payload: IGetTableGenericRequest) => {
	return {
		type: GET_TABLE_TITLES,
		payload
	}
}

export const getTableTitlesSuccess = (payload: IGetTableGenericResArray) => {
	return {
		type: GET_TABLE_TITLES_SUCCESS,
		payload
	}
}

export const getTableMasks = (payload: IGetTableGenericRequest) => {
	return {
		type: GET_TABLE_MASKS,
		payload
	}
}

export const getTableMasksSuccess = (payload: IGetTableGenericResArray) => {
	return {
		type: GET_TABLE_MASKS_SUCCESS,
		payload
	}
}

export const getTableValidations = (payload: IGetTableGenericRequest) => {
	return {
		type: GET_TABLE_VALIDATIONS,
		payload
	}
}

export const getTableValidationsSuccess = (
	payload: IGetTableGenericResArray
) => {
	return {
		type: GET_TABLE_VALIDATIONS_SUCCESS,
		payload
	}
}

export const getTableValidationValues = (payload: IGetTableGenericRequest) => {
	return {
		type: GET_TABLE_VALIDATION_VALUES,
		payload
	}
}

export const getTableValidationValuesSuccess = (
	payload: IGetTableGenericResArray
) => {
	return {
		type: GET_TABLE_VALIDATION_VALUES_SUCCESS,
		payload
	}
}

export const getTableUsualCoin = () => {
	return {
		type: GET_TABLE_USUAL_COIN
	}
}

export const getTableUsualCoinSuccess = (payload: any) => {
	return {
		type: GET_TABLE_USUAL_COIN_SUCCESS,
		payload
	}
}

export const getTablePermissionType = () => {
	return {
		type: GET_TABLE_PERMISSION_TYPE
	}
}

export const getTablePermissionTypeSuccess = (payload: any) => {
	return {
		type: GET_TABLE_PERMISSION_TYPE_SUCCESS,
		payload
	}
}

export const getTableAbmTabs = (payload: IGetTableGenericRequest) => {
	return {
		type: GET_TABLE_ABM_TABS,
		payload
	}
}

export const getTableAbmTabsSuccess = (payload: any) => {
	return {
		type: GET_TABLE_ABM_TABS_SUCCESS,
		payload
	}
}

export const getFilterProduct = (payload: any) => {
	return {
		type: GET_FILTER_PRODUCT,
		payload
	}
}

export const getFilterProductSuccess = (payload: any) => {
	return {
		type: GET_FILTER_PRODUCT_SUCCESS,
		payload
	}
}

export const getTableEstimationType = (payload: any) => {
	return {
		type: GET_TABLE_ESTIMATION_TYPE,
		payload
	}
}

export const getTableEstimationTypeSuccess = (payload: any) => {
	return {
		type: GET_TABLE_ESTIMATION_TYPE_SUCCESS,
		payload
	}
}

export const clearValidationTableData = () => {
	return {
		type: CLEAR_VALIDATION_TABLE_DATA
	}
}
