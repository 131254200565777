import CollapseButton from 'components/common/collapseButton'
import CommonTable from 'components/common/commonTable'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Card, Collapse } from 'reactstrap'

export default class ComponentCardTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			collapseTable: false
		}
	}

	toggleTableItem() {
		this.setState((state) => ({ collapseTable: !state.collapseTable }))
	}

	render() {
		const {
			theme,
			t,
			dataTable,
			keyField,
			fieldTable,
			title,
			fieldItems,
			paginationDataTable
		} = this.props

		const dataItems = fieldItems || 'Items'
		const updateData = paginationDataTable || dataTable
		const paginationOptions = updateData
			? {
					page: updateData.page_number,
					sizePerPage: updateData.page_size,
					totalSize: updateData.total_count
			  }
			: {}

		return (
			<Card className={`pb-3 mt-3 pt-3 mb-4 ${theme.containerCard}`}>
				<Row className={'mb-1'}>
					<Col sm={6} className={`${theme.title} pb-2`}>
						{title}
					</Col>
					<Col sm={3} className={theme.title} />
				</Row>
				<Row>
					<Col className={'pt-2'} sm={3}>
						<strong>{t('voucherAffecting.table.totalLines')}</strong>
					</Col>
					<Col className={'pt-2'} sm={3}>
						{updateData ? updateData.total_count : null}
					</Col>
				</Row>
				<Row className={'mt-2'}>
					<Col sm={1}>
						<CollapseButton
							onPress={() => this.toggleTableItem()}
							status={this.state.collapseTable}
						/>
					</Col>
					<Col sm={11}>
						<div className="dropdown-divider col-11 p-1" />
					</Col>
				</Row>
				<Collapse isOpen={this.state.collapseTable}>
					{updateData && (
						<Row className={`mt-2 ${theme.containerTable}`}>
							<CommonTable
								remote
								columns={fieldTable}
								keyField={keyField}
								data={updateData ? updateData[dataItems] : []}
								rowClasses={theme.tableRow}
								headerClasses={theme.tableHeader}
								paginationOptions={paginationOptions}
								onTableChange={this.props.handleOnTableChange}
							/>
						</Row>
					)}
				</Collapse>
			</Card>
		)
	}
}
