import {
	clearPurchasesHeaderFilters,
	confirmHeaderSelection,
	getConfigVoucher,
	getHeaderSelection,
	getOptionsFilterAffected,
	getOrdenCbtCab,
	getOrderDirOptions,
	setPurchasesHeaderFilters
} from 'actions'
import CommonTable from 'components/common/commonTable'
import NotificationMessage from 'components/common/notificationMessage'
import { P_SELCAB_CPA } from 'constants/ConfigProcessNames'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { addPrevValidations } from 'lib/AxiosInterceptors'
import { getSelectFilter, getValueMask } from 'lib/MaskValues'
import { getFiltersData, getFiltersTable } from 'lib/Utils'
import { IConfigFieldsForm } from 'models/Budget'
import {
	IConfirmHeaderSelectionParams,
	IGetHeaderSelectionParams,
	IGetHeaderSelectionTableFilters
} from 'models/HeaderSelection'
import { Ihistory } from 'models/NavigationInterface'
import { IGetConfigVoucherParams } from 'models/Voucher'
import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import _ from 'underscore'
import { SEARCH_VOUCHER_DETAIL } from 'utils/RoutePath'
import HeaderFilterForms from './HeaderFilterForms'
import style from './HeaderSelectionTable.module.scss'

interface IHeaderSelectionTableProps {
	idOperacion?: any
	config?: IConfigFieldsForm
	authUser?: any
	shoppingHeaders?: any
	history: Ihistory
	confirmationForm?: any
	urlNextPage?: string
	confirmHeaders: any
	getConfigVoucher: (payload: IGetConfigVoucherParams) => void
	getHeaderSelection: (payload: Partial<IGetHeaderSelectionParams>) => void
	confirmHeaderSelection: (payload: IConfirmHeaderSelectionParams) => void
	setPurchasesHeaderFilters: (payload: any) => void
	clearPurchasesHeaderFilters: () => void
	getOrderDirOptions: () => void
	getOrdenCbtCab: () => void
	getOptionsFilterAffected: () => void
	messageError: { message: string; confirm: boolean }
	barCodeResponse: any
	budgetTableRef?: any
	callBackReturn: any
	headerFilters: IGetHeaderSelectionParams
}

interface IHeaderSelectionTableState {
	filtersKey: any
	rowSelected: string[]
	lastRowSelected: string
	messageError: string
	showError: boolean
	typeError: string
	filtersForm: any
	filtersTable: any
	tableFiltersReset: boolean
}

class HeaderSelectionTable extends Component<
	IHeaderSelectionTableProps,
	IHeaderSelectionTableState
> {
	constructor(props: IHeaderSelectionTableProps) {
		super(props)

		this.state = {
			filtersKey: {
				proveedor: 'filtro_proveedor',
				cod_comp: 'filtro_cod_comp',
				detalle: 'filtro_detalle',
				fecha: 'filtro_fecha',
				nro_comp: 'filtro_nro_comp',
				sucursal: 'filtro_sucursal',
				imp_total: 'filtro_total'
			},
			rowSelected: [],
			lastRowSelected: '',
			messageError: '',
			showError: false,
			typeError: 'danger',
			filtersForm: props.headerFilters,
			filtersTable: {},
			tableFiltersReset: false
		}

		addPrevValidations((e: any) => {
			if (e.request.responseURL.includes('/cpa_cab_selcompr')) {
				return true
			}
			return false
		})
	}

	componentDidMount = () => {
		this.props.budgetTableRef(this)
		const { idOperacion, headerFilters } = this.props

		this.props.getHeaderSelection({
			...headerFilters,
			idOperacion
		})

		this.props.getOrderDirOptions()
		this.props.getOrdenCbtCab()
		this.props.getOptionsFilterAffected()
	}

	componentDidUpdate = (
		prevProps: IHeaderSelectionTableProps,
		prevState: IHeaderSelectionTableState
	) => {
		const {
			confirmHeaders,
			urlNextPage,
			messageError,
			shoppingHeaders,
			barCodeResponse,
			idOperacion,
			setPurchasesHeaderFilters
		} = this.props

		const { rowSelected, lastRowSelected, filtersForm, filtersTable } =
			this.state

		if (
			!_.isEqual(barCodeResponse, prevProps.barCodeResponse && barCodeResponse)
		) {
			const params = {
				page_number: 1,
				page_size: 10,
				idOperacion,
				...filtersForm
			}
			this.props.getHeaderSelection({ ...params })
		}

		if (messageError !== prevProps.messageError && messageError) {
			if (messageError.confirm) {
				const idxFind = rowSelected.indexOf(lastRowSelected)
				rowSelected.splice(idxFind, 1)
			}

			if (lastRowSelected === 'All' && messageError.confirm) {
				this.setState({
					messageError: messageError.message,
					showError: true,
					rowSelected: [],
					lastRowSelected: ''
				})
			} else {
				this.setState({
					messageError: messageError.message,
					showError: true,
					rowSelected
				})
			}
		}

		if (
			confirmHeaders &&
			confirmHeaders !== prevProps.confirmHeaders &&
			!_.isEmpty(urlNextPage)
		) {
			this.props.history.push(urlNextPage)
		}

		if (
			shoppingHeaders !== prevProps.shoppingHeaders &&
			!_.isEmpty(shoppingHeaders) &&
			shoppingHeaders.Items
		) {
			const rowSelected: any = []

			shoppingHeaders.Items.forEach((item: any) => {
				if (item.sel) {
					rowSelected.push(item.id)
				}
			})

			this.setState({ rowSelected, showError: false }) // Items Selected From DB
		}

		if (
			filtersForm !== prevState.filtersForm ||
			filtersTable !== prevState.filtersTable
		) {
			setPurchasesHeaderFilters(filtersForm)
			this.setState({ tableFiltersReset: false })
			this.props.getHeaderSelection({ ...filtersForm, ...filtersTable })
		}
	}

	/**
	 * to confirm the form and call CallBack
	 */
	handleConfirmForm = () => {
		const { idOperacion, callBackReturn } = this.props

		this.props.confirmHeaderSelection({
			values: { idOperacion, confirmar: 1, lista_movs: [] },
			callBackReturn
		})
	}

	/**
	 * to create columns in the table
	 * @returns columns
	 */
	getColumns = () => {
		const { config, shoppingHeaders } = this.props
		const { filtersKey } = this.state

		const fieldsTable = config
			? getFiltersData(config.campos, { agrupador: 'grilla' })
			: []

		const rows = fieldsTable.map((field) => {
			const campoId = field.idCampo.trim()
			return {
				dataField: campoId,
				text:
					filtersKey[campoId] && shoppingHeaders
						? ''
						: field.label
						? field.label
						: '',
				align: campoId === 'imp_total' ? 'right' : 'center',
				headerAlign: 'center',
				hidden: !field.visible,
				filter: getSelectFilter(
					field,
					shoppingHeaders,
					filtersKey,
					this.props,
					style.inputFilter
				),
				formatter: (cell: string, row: any) =>
					this.renderFormat(field, cell, row)
			}
		})

		return rows
	}

	/**
	 * render mask value
	 * @param field
	 * @param value
	 * @param row
	 * @returns
	 */
	renderFormat = (field: IFieldStructure, value: any, row: any) => {
		const { idCampo } = field
		let result = value
		const { authUser } = this.props

		if (idCampo === 'nro_comp') {
			result = (
				<Link target="_blank" to={`${SEARCH_VOUCHER_DETAIL}/${row.id}/consult`}>
					{value}
				</Link>
			)
		} else if (field.mascara) {
			result = getValueMask(value, field.mascara, { authUser })
		}

		return result
	}

	/**
	 * to handle change table
	 */
	handleChangeTable = (type: any, pagination: any) => {
		const { idOperacion } = this.props
		const { filtersForm, filtersTable } = this.state

		let formParams: IGetHeaderSelectionParams = { ...filtersForm, idOperacion }
		let tableParams: IGetHeaderSelectionTableFilters = { ...filtersTable }

		if (type === 'pagination') {
			const pag = {
				page_number: pagination.page,
				page_size: pagination.sizePerPage
			}
			formParams = { ...formParams, ...pag }
		} else if (type === 'filter') {
			const filters =
				type === 'filter' ? getFiltersTable(pagination.filters) : {}

			tableParams = { ...filters }
		}

		this.setState({
			filtersForm: { ...formParams },
			filtersTable: { ...tableParams }
		}) // Save Pagination or filters
	}

	/**
	 * call search api with filters params.
	 * @param filters
	 */
	handleFilterSearch = (filters: any) => {
		const { idOperacion } = this.props
		const { filtersForm } = this.state

		const params = {
			...filtersForm,
			page_size: 10,
			page_number: 1,
			idOperacion
		}

		this.setState({ filtersForm: { ...params, ...filters } })
	}

	/**
	 * to get all select row options
	 */
	getSelectRowOptions = () => {
		const { rowSelected } = this.state

		return {
			mode: 'checkbox',
			selectColumnPosition: 'right',
			selected: rowSelected,
			headerColumnStyle: {
				textAlign: 'center',
				paddingRight: '8px !important'
			},
			style: (row: any) => {
				const backgroundColor = row.error ? '#f8d7da' : '#FFF'
				const marginBottom = 120
				return { backgroundColor, marginBottom }
			},
			onSelect: this.handleOnSelectCheck,
			onSelectAll: this.handleOnSelectAll
		}
	}

	/**
	 * to validate click on check
	 * @param row
	 */
	handleOnSelectCheck = (row: any, isSelect: boolean) => {
		const { idOperacion } = this.props
		const { rowSelected } = this.state
		let lastRowSelected = ''

		if (isSelect) {
			rowSelected.push(row.id)
			lastRowSelected = row.id
		} else {
			const idxFind = rowSelected.indexOf(row.id)
			rowSelected.splice(idxFind, 1)
		}
		const values = {
			idOperacion,
			confirmar: 0,
			lista_movs: [{ id: row.id, sel: isSelect ? 1 : 0 }]
		}
		this.props.confirmHeaderSelection({ values })

		this.setState({ rowSelected, lastRowSelected })
	}

	/**
	 * to render the actions of the inputs checks
	 *
	 */
	handleOnSelectAll = (isSelect: boolean, rows: any) => {
		const { idOperacion } = this.props
		const rowSelected: string[] = []

		const lista_movs = rows.map((row: any) => {
			if (isSelect) {
				rowSelected.push(row.id)
			}
			return {
				id: row.id,
				sel: isSelect ? 1 : 0
			}
		})

		const values = {
			idOperacion,
			confirmar: 0,
			lista_movs
		}
		this.props.confirmHeaderSelection({ values })
		this.setState({ rowSelected, lastRowSelected: 'All' })
	}

	/**
	 * close notification error
	 */
	handleCloseErrorNotification = () => {
		this.setState({ showError: false })
	}

	handleClearFilters = () => {
		const { idOperacion } = this.props

		this.setState({
			filtersForm: {
				idOperacion,
				page_number: 1,
				page_size: 10,
				fec_desde: '',
				fec_hasta: '',
				suc_emp: '',
				vendedor: '',
				transporte: '',
				cond_vta: '',
				orden: 'F',
				sentido: 'D'
			},
			filtersTable: {},
			tableFiltersReset: true
		})
	}

	render() {
		const { config, shoppingHeaders, idOperacion } = this.props
		const {
			showError,
			messageError,
			typeError,
			filtersForm,
			tableFiltersReset
		} = this.state
		const fieldsFilter = config
			? getFiltersData(config.campos, { agrupador: 'formulario' })
			: []

		const budgetTableProps = {
			remote: true,
			columns: !_.isEmpty(config) ? this.getColumns() : [],
			selectRow: this.getSelectRowOptions(),
			data: _.isEmpty(shoppingHeaders)
				? []
				: shoppingHeaders.Items
				? shoppingHeaders.Items
				: [],
			paginationOptions: shoppingHeaders
				? {
						pageStartIndex: 1,
						sizePerPage: shoppingHeaders.page_size,
						page: shoppingHeaders.page_number,
						totalSize: shoppingHeaders.total_count
				  }
				: {},
			onTableChange: this.handleChangeTable
		}

		const notificationsProps = {
			showError,
			errorMessage: messageError,
			type: typeError,
			handleCloseError: this.handleCloseErrorNotification
		}

		return (
			<Col className={`col-12`}>
				{!_.isEmpty(fieldsFilter) && (
					<HeaderFilterForms
						initialState={filtersForm}
						fields={fieldsFilter}
						handleFilterSearch={this.handleFilterSearch}
						idOperacion={idOperacion}
						clearFilters={this.handleClearFilters}
					/>
				)}
				<NotificationMessage {...notificationsProps} />
				{!_.isEmpty(config) && !tableFiltersReset && (
					<CommonTable {...budgetTableProps} />
				)}
			</Col>
		)
	}
}

const mapStateToProps = ({ voucher, auth, headerSelection }: any) => {
	const config = voucher.config ? voucher.config[P_SELCAB_CPA] : null
	const { authUser } = auth
	const {
		shoppingHeaders,
		confirmHeaders,
		messageError,
		barCodeResponse,
		headerFilters
	} = headerSelection

	return {
		config,
		authUser,
		confirmHeaders,
		messageError,
		barCodeResponse,
		shoppingHeaders,
		headerFilters
	}
}

const mapDispatchToProps = {
	getConfigVoucher,
	getHeaderSelection,
	getOrderDirOptions,
	getOrdenCbtCab,
	confirmHeaderSelection,
	getOptionsFilterAffected,
	setPurchasesHeaderFilters,
	clearPurchasesHeaderFilters
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(HeaderSelectionTable))
