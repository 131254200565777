import { pickStockAnmat, pickStockAnmatConfirm } from 'actions'
import bsCustomFileInput from 'bs-custom-file-input'
import CommonModal from 'components/common/commonModal'
import InputButton from 'components/form/inputButton'
import PickStockImportTable from 'components/providerPickStockImport/PickStockImportTable'
import ProviderPickStockTotals from 'components/providerPickStockImport/ProviderPickStockTotals'
import React, { Component, Fragment } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styles from './ProviderPickStockAnmat.module.scss'

class ProviderPickStockAnmat extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showImportModal: false
		}
	}

	componentDidMount = () => {
		bsCustomFileInput.init()
	}

	/**
	 * to close import modal
	 */
	handleCloseModal = () => {
		this.setState({ showImportModal: false })
	}

	/**
	 * to call api from import pick stock file.
	 */
	handleImportFile = () => {
		const { idOperacion } = this.props
		this.props.pickStockAnmat({
			idOperacion,
			mostrar: 'T',
			page_size: 10,
			page_number: 1
		})
	}

	/**
	 * call api to confirm import
	 */
	handleConfirmForm = () => {
		const { idOperacion } = this.props
		this.props.pickStockAnmatConfirm({ idOperacion })
		this.setState({ showImportModal: false })
	}

	/**
	 * call api to cal
	 */
	handleCancelForm = () => {
		this.setState({ showImportModal: false })
	}

	/**
	 * to call api when use pagination options of table.
	 * @param {string} type
	 * @param {object} pagination
	 */
	handleChangeTable = (type, pagination) => {
		const { idOperacion } = this.props
		if (type === 'pagination') {
			this.props.pickStockAnmat({
				idOperacion,
				page_size: pagination.sizePerPage,
				page_number: pagination.page,
				mostrar: 'T'
			})
		}
	}

	/**
	 * to render import form
	 */
	renderForm = () => {
		const { t, anmatImportData, idOperacion } = this.props
		return (
			<Container>
				<Row>
					<Col sm={{ span: 6, offset: 6 }}>
						<Row>
							<Col>
								<InputButton
									valueButton={t('Collection.form.buttons.import')}
									type={'primary'}
									onClick={this.handleImportFile}
									customStyle={{ minWidth: '145px' }}
								/>
							</Col>

							<Col>
								<InputButton
									valueButton={t('Collection.form.buttons.accept')}
									type={'primary'}
									onClick={this.handleConfirmForm}
									customStyle={{ minWidth: '145px' }}
								/>
							</Col>
							<Col className={'pr-0'}>
								<InputButton
									valueButton={t('Collection.form.buttons.cancel')}
									type={'primary'}
									onClick={this.handleCancelForm}
									customStyle={{ minWidth: '145px' }}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<ProviderPickStockTotals
							totals={anmatImportData || null}
							handleChangeFilter={(filters) => {
								this.props.pickStockAnmat({
									idOperacion,
									page_size: 10,
									page_number: 1,
									...filters
								})
							}}
						/>
					</Col>
				</Row>
				<Row>
					<PickStockImportTable
						primaryKey={'nro'}
						pickStockData={anmatImportData || null}
						idOperacion={idOperacion}
						handleChangeTable={this.handleChangeTable}
					/>
				</Row>
			</Container>
		)
	}

	render() {
		const { t, theme } = this.props
		const { showImportModal } = this.state
		return (
			<Fragment>
				<Button
					style={{ minWidth: '125px' }}
					type={'button'}
					onClick={() => this.setState({ showImportModal: true })}
				>
					{t('pickStock.buttons.anmat')}
				</Button>
				<CommonModal
					sizeModal={'xl'}
					customClass={theme.size_modal}
					showModal={showImportModal}
					handleCloseModal={this.handleCloseModal}
					modalTitle={t('pickStock.buttons.anmat')}
					modalBody={this.renderForm()}
				/>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ pickeoIngStock }) => {
	const { anmatImportData } = pickeoIngStock
	return { anmatImportData }
}

const connectForm = connect(mapStateToProps, {
	pickStockAnmat,
	pickStockAnmatConfirm
})(withTranslation()(ProviderPickStockAnmat))
export default themr('ProviderPickStockImportStyles', styles)(connectForm)
