import React, { PureComponent } from 'react'
import { Dropdown, Navbar } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import cellEditFactory from 'react-bootstrap-table2-editor'
import filterFactory from 'react-bootstrap-table2-filter'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { themr } from 'react-css-themr'
import styles from './commonTable.module.scss'
class CommonTable extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			isSetFocus: true
		}
	}

	componentDidMount() {
		this.handleSetFocus(false)
	}

	componentDidUpdate = (prevProps) => {
		const { data, isRendeTableFinish, keyField } = this.props
		const primaryKey = keyField || 'id'
		const { isSetFocus } = this.state

		if (prevProps.data !== data && data.length > 0) {
			if (isSetFocus) {
				this.handleSetFocus()
			}
		}
		if (
			data[0] &&
			prevProps.data[0] &&
			data[0][primaryKey] !== prevProps.data[0][primaryKey] &&
			isRendeTableFinish
		) {
			// to set focus when data change in the table
			isRendeTableFinish()
		}
	}

	/**
	 * when paginate options is called
	 * @param {string} type
	 * @param {object} pagination
	 */
	handleOnChange = (type, pagination) => {
		this.setState({ isSetFocus: true })
		this.props.onTableChange(type, pagination)
	}

	/**
	 * to call api of table to set focus.
	 * @param {boolean} isSetFocus
	 */
	handleSetFocus = (isSetFocus = false) => {
		const { isRendeTableFinish } = this.props
		if (isRendeTableFinish) {
			this.setState({ isSetFocus })
			isRendeTableFinish()
		}
	}

	/**
	 * render dropdown page options.
	 */
	sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
		<Navbar>
			<Dropdown className="">
				<Dropdown.Toggle id="dropdown-autoclose-true">
					{currSizePerPage}
				</Dropdown.Toggle>
				<Dropdown.Menu style={{ minWidth: '0px' }}>
					{options.map((option) => {
						return (
							<Dropdown.Item
								onMouseDown={(e) => {
									e.preventDefault()
									onSizePerPageChange(option.page)
								}}
								key={option.text}
							>
								{option.text}
							</Dropdown.Item>
						)
					})}
				</Dropdown.Menu>
			</Dropdown>
		</Navbar>
	)

	render() {
		const {
			columns,
			classes,
			wrapperClasses,
			bordered,
			rowStyle,
			rowClasses,
			headerClasses,
			data,
			selectRow,
			expandRow,
			defaultSorted,
			keyField,
			paginationOptions,
			remote,
			editProperties,
			refTable,
			loading,
			theme,
			pagination,
			hover,
			rowEvents
		} = this.props

		const border = bordered || false
		const customKeyField = keyField || 'id'
		const customPagination = paginationOptions || {}

		// const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => {
		// 	return (
		// 		<li
		// 			key={text}
		// 			role="presentation"
		// 			className="dropdown-item"
		// 			style={{ cursor: 'pointer' }}
		// 			onMouseDown={(e) => {
		// 				e.preventDefault()
		// 				onSizePerPageChange(page)
		// 			}}
		// 		>
		// 			{text}
		// 		</li>
		// 	)
		// }

		const options = {
			...customPagination,
			sizePerPageRenderer: this.sizePerPageRenderer
		}

		return (
			<BootstrapTable
				classes={`${classes}`}
				ref={refTable}
				remote={remote}
				keyField={customKeyField}
				data={data}
				hover={hover || false}
				rowEvents={rowEvents && rowEvents}
				columns={columns}
				rowClasses={`${rowClasses} ${theme.globalTableRow} `}
				headerClasses={`${headerClasses} ${theme.globalTableHeader}`}
				rowStyle={rowStyle}
				bordered={border}
				selectRow={selectRow}
				filter={filterFactory()}
				expandRow={expandRow}
				defaultSorted={defaultSorted}
				pagination={pagination !== false ? paginationFactory(options) : null}
				onTableChange={this.handleOnChange}
				cellEdit={cellEditFactory(editProperties)}
				loading={loading}
				wrapperClasses={`${theme.mainTable} ${wrapperClasses} table-responsive`}
			/>
		)
	}
}

export default themr('GlobalTableStyles', styles)(CommonTable)
