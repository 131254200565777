import {
	CLEAR_EQUIVALENT_PRODUCT_STORE,
	DELETE_EQUIVALENT_PRODUCT_GROUP,
	DELETE_EQUIVALENT_PRODUCT_GROUP_SUCCESS,
	GET_EQUIVALENT_PRODUCTS,
	GET_EQUIVALENT_PRODUCTS_SUCCESS,
	GET_EQUIVALENT_PRODUCT_GROUP,
	GET_EQUIVALENT_PRODUCT_GROUP_SUCCESS,
	SET_EQUIVALENT_PRODUCT_GROUP,
	SET_EQUIVALENT_PRODUCT_GROUP_SUCCESS,
	SET_EQUIVALENT_PRODUCT_SELECTION,
	SET_EQUIVALENT_PRODUCT_SELECTION_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	products: any
	productSelection: any
	productGroup: any
	editGroup: any
	deleteGroup: any
}

const initialState = {
	products: null,
	productSelection: null,
	productGroup: null,
	editGroup: null,
	deleteGroup: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case GET_EQUIVALENT_PRODUCTS:
			return { ...state, products: null }
		case GET_EQUIVALENT_PRODUCTS_SUCCESS:
			return { ...state, products: action.payload }
		case SET_EQUIVALENT_PRODUCT_SELECTION:
			return { ...state, productSelection: null }
		case SET_EQUIVALENT_PRODUCT_SELECTION_SUCCESS:
			return { ...state, productSelection: action.payload }
		case SET_EQUIVALENT_PRODUCT_GROUP:
			return { ...state, editGroup: null }
		case SET_EQUIVALENT_PRODUCT_GROUP_SUCCESS:
			return { ...state, editGroup: action.payload }
		case DELETE_EQUIVALENT_PRODUCT_GROUP:
			return { ...state, deleteGroup: null }
		case DELETE_EQUIVALENT_PRODUCT_GROUP_SUCCESS:
			return { ...state, deleteGroup: action.payload }
		case GET_EQUIVALENT_PRODUCT_GROUP:
			return { ...state, productGroup: null }
		case GET_EQUIVALENT_PRODUCT_GROUP_SUCCESS:
			return { ...state, productGroup: action.payload }
		case CLEAR_EQUIVALENT_PRODUCT_STORE:
			return { ...state, products: null }
		default:
			return state
	}
}

export default rootReducer
