import Axios from 'axios'
import {
	IGetSugestedProducts,
	ISetSugestedProductPriority,
	ISetSugestedProductSelection
} from 'models/SugestedProducts'

export const getSugestedProducts = async (params: IGetSugestedProducts) => {
	const response = await Axios.get(`/conf_prod_sug/buscar`, { params })
	return response.data
}

export const setSugestedProductSelection = async (
	params: ISetSugestedProductSelection
) => {
	const response = await Axios.post(`/conf_prod_sug/seleccionar`, params)
	return response.data
}

export const setSugestedProductPriority = async (
	params: ISetSugestedProductPriority
) => {
	const response = await Axios.post(`/conf_prod_sug/prioridad`, params)
	return response.data
}
