import {
	clearTableValidations,
	crudGetFundAccountBankReconciliation,
	crudsClearStore,
	crudsCreate,
	crudsGetSingle,
	crudUpdateFundAccountBankReconciliation,
	showMessage
} from 'actions'
import CommonTabs from 'components/abmUtils/CommonTabs'
import withMenu from 'components/common/withMenu'
import ValuesCrudTable from 'components/genericCrud/ValuesCrudTable'
import CrudsContainer from 'components/layout/CrudsContainer'
import { IAbmStructureResponse } from 'constants/valuesInterfaces/interfaces'
import {
	ICrudGetFundAccountRes,
	ICrudUpdateFundAccountBankReconciliation
} from 'models/CrudFundsAccountsInterface'
import { ICrudsApi, ICrudsTypeList } from 'models/CrudInterface'
import { Ihistory } from 'models/NavigationInterface'
import React, { Component } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CRUD, CRUD_EDIT, CRUD_FUNDS_ACCOUNTS_DATA } from 'utils/RoutePath'
import AdditionalDataForm from './AdditionalTabs/AdditionalDataForm'
import ComponentUpdateFundAccountMovementsSegment from './AdditionalTabs/ComponentUpdateFundAccountMovementsSegment'
import FundAccountBankReconciliationTab from './AdditionalTabs/FundAccountBankReconciliationTab'
import TaxesForm from './AdditionalTabs/TaxesForm'
import CrudFundAccountData from './CrudFundAccountData'

type FundAccountDataContentProps = {
	crudCode: number
	op: string
	selectedAbm: ICrudsTypeList
	crudsGetSingle: (params: ICrudsApi) => void
	crudsCreate: (params: ICrudsApi) => void
	crudsClearStore: () => void
	valueDataSelected: ICrudGetFundAccountRes
	valueDataUpdated: any
	crudGetFundAccountBankReconciliation: any
	crudUpdateFundAccountBankReconciliation: any
	clearTableValidations: any
	valueUpdated: any
	showMessage: any
	history: Ihistory
	fieldsConfig: IAbmStructureResponse
	fieldsAltaConfig: IAbmStructureResponse
	disableForm: boolean
	bankReconciliationInfo: any
	t: any
}

type FundAccountDataContentState = {
	crudCode: number | string | null
	info: boolean
	tabActive: string
	disabledBankReconciliationFields: boolean
	showWarningPopup: boolean
	formChanged: boolean
	nextTab: string
	nextAction: string
}

const fundAccountTabs = [
	{
		key: 'basic',
		label: 'CRUDS.general_use.basic_tab',
		type: 'basic'
	},
	{
		key: 'tmov',
		label: 'CRUDS.general_use.movements',
		type: 'movem'
	},
	{
		key: 'impu',
		label: 'CRUDS.general_use.taxes_tab',
		type: 'taxes'
	},
	{
		key: 'atrib',
		label: 'CRUDS.general_use.additional_tab',
		type: 'additional'
	},
	{
		key: 'concil',
		label: 'CRUDS.general_use.bank_recon_tab',
		type: 'bank'
	}
]

class FundAccountDataContent extends Component<
	FundAccountDataContentProps,
	FundAccountDataContentState
> {
	constructor(props: FundAccountDataContentProps) {
		super(props)
		this.state = {
			crudCode: this.props.crudCode,
			info: false,
			tabActive: 'basic',
			disabledBankReconciliationFields: true,
			showWarningPopup: false,
			formChanged: false,
			nextTab: 'basic',
			nextAction: ''
		}
	}

	// setups initial store state for each action respectively
	componentDidMount() {
		const {
			crudCode,
			op,
			crudsClearStore,
			crudGetFundAccountBankReconciliation,
			clearTableValidations
		} = this.props

		if (crudCode && (op === 'edit' || op === 'view')) {
			crudGetFundAccountBankReconciliation({ nicfondo: crudCode })
		} else if (op === 'add') {
			crudsClearStore()
			clearTableValidations()
		}
	}

	// Handles changes and sets form changed state to false if there was a submit
	componentDidUpdate = (prevProps: FundAccountDataContentProps) => {
		const {
			valueDataSelected,
			op,
			crudCode,
			crudsGetSingle,
			valueDataUpdated,
			selectedAbm,
			valueUpdated,
			crudGetFundAccountBankReconciliation
		} = this.props
		const {
			valueDataUpdated: prevValueDataUpdated,
			valueDataSelected: prevValueDataSelected,
			valueUpdated: prevValueUpdated
		} = prevProps

		if (prevValueDataSelected !== valueDataSelected && valueDataSelected) {
			let info = false
			if (op === 'view' || op === 'edit') {
				info = true
			} else if (op === 'add') {
				info = false
			}

			this.setState({ info })
		}

		if (valueUpdated !== prevValueUpdated && valueUpdated) {
			this.props.showMessage({
				type: 'success',
				description: 'Se actualizo correctamente',
				showError: true
			})
			crudGetFundAccountBankReconciliation({ nicfondo: crudCode })
			this.handleBankReconciliationDisable()
			this.setState({ formChanged: false })
		}

		if (valueDataUpdated !== prevValueDataUpdated && valueDataUpdated) {
			crudsGetSingle({
				crudName: selectedAbm.id,
				urlApi: selectedAbm.urlApi,
				action: 'consulta',
				params: { [selectedAbm.key]: crudCode }
			})
			this.setState({ formChanged: false })
		}
	}

	// Cancel button function
	handleRedirectHome = () => {
		this.props.history.push(`${CRUD}/${CRUD_FUNDS_ACCOUNTS_DATA}`)
	}

	/**
	 * to call api to create new abm
	 * @param {object} value
	 */

	handleNewCrud = (values: any) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 1 }
		})
	}

	/**
	 * to call api to edit abm
	 * @param {object} value
	 */

	handleEditCrud = (values: any) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 0 }
		})
	}

	// Set's current tab in state
	handleActiveKey = (tabActive: string) => {
		this.setState({ tabActive })
	}

	// Handles observation submit
	handleEditBankReconciliation = (
		values: ICrudUpdateFundAccountBankReconciliation
	) => {
		this.props.crudUpdateFundAccountBankReconciliation({ ...values, alta: 0 })
	}

	// Disables observation fields
	handleBankReconciliationDisable = () => {
		if (this.state.disabledBankReconciliationFields) {
			this.setState({ disabledBankReconciliationFields: false })
		} else {
			this.setState({ disabledBankReconciliationFields: true })
		}
	}

	// Handles the Crud change arrows
	handleCrudChange = (param: any) => {
		const { history } = this.props
		history.go(`${CRUD_EDIT}/${CRUD_FUNDS_ACCOUNTS_DATA}/${param.nicfondo}`)
	}

	// Handles the changes warning state
	handleWarningPopup = (value: boolean, action: string) => {
		const { tabActive } = this.state
		this.setState({ showWarningPopup: value })
		action === 'close' && this.setState({ nextTab: tabActive, nextAction: '' })
		action === 'cancel' &&
			this.setState({
				formChanged: false,
				disabledBankReconciliationFields: true
			})
	}

	// Handles the changes state, used in detecting changes
	handleSetFormChanged = (formChanged: boolean) => {
		this.setState({ formChanged })
	}

	// Stores the value of the clicked tab in the component state
	handleSetNextTab = (nextTab: string) => {
		this.setState({ nextTab })
	}

	// Stores the value of the clicked arrow in the component state
	handleSetNextAction = (nextAction: string) => {
		this.setState({ nextAction })
	}

	render() {
		const {
			t,
			fieldsConfig,
			fieldsAltaConfig,
			valueDataSelected,
			disableForm,
			op,
			crudCode,
			bankReconciliationInfo,
			showMessage,
			selectedAbm
		} = this.props

		const {
			tabActive,
			showWarningPopup,
			nextTab,
			disabledBankReconciliationFields,
			formChanged,
			nextAction
		} = this.state

		const fields = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('principal')
			  })
			: []
		const taxesFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.impu')
			  })
			: []
		const taxesFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.impu')
			  })
			: []
		const attrDataValuesFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.atrib')
			  })
			: []
		const attrDataValuesFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.atrib')
			  })
			: []
		const bankReconciliationsFields = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.concil')
			  })
			: []
		const movementsFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.tmov')
			  })
			: []
		const movementsFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.tmov')
			  })
			: []

		const tabProps = {
			basic: {
				disableForm: disableForm,
				itemInfo: valueDataSelected,
				fields: fields,
				showMessage: showMessage,
				handleSubmit: op === 'add' ? this.handleNewCrud : this.handleEditCrud,
				method: op,
				handleCloseModal: this.handleRedirectHome,
				showWarning: showWarningPopup,
				handleWarning: this.handleWarningPopup,
				setFormChanged: this.handleSetFormChanged
			},
			taxes: {
				catributo: crudCode,
				urlApi: 'abm_fon_cuentas_impu',
				tabId: 'nicfondo',
				valueId: 'cod_imp',
				crudId: selectedAbm.id,
				fields: taxesFields,
				fieldsAlta: taxesFieldsAlta,
				Component: TaxesForm,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'impu')?.tipo_perm ===
						'L' ||
					false,
				t: t
			},
			movem: {
				catributo: crudCode,
				urlApi: 'abm_fon_cuentas_tmov',
				tabId: 'nicfondo',
				valueId: 'cat_item',
				crudId: selectedAbm.id,
				fields: movementsFields,
				fieldsAlta: movementsFieldsAlta,
				Component: ComponentUpdateFundAccountMovementsSegment,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'tmov')?.tipo_perm ===
						'L' ||
					false,
				t: t
			},
			additional_data: {
				catributo: crudCode,
				urlApi: 'abm_fon_cuentas_atrib',
				tabId: 'nicfondo',
				valueId: 'catributo',
				crudId: selectedAbm.id,
				fields: attrDataValuesFields,
				fieldsAlta: attrDataValuesFieldsAlta,
				Component: AdditionalDataForm,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'atrib')
						?.tipo_perm === 'L' ||
					false,
				t: t
			},
			bank: {
				nicfondo: crudCode,
				fields: bankReconciliationsFields,
				itemInfo: bankReconciliationInfo,
				disableFields: disabledBankReconciliationFields,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'concil')
						?.tipo_perm === 'L' ||
					false,
				handleSubmit: this.handleEditBankReconciliation,
				handleBankReconciliationDisable: this.handleBankReconciliationDisable,
				handleWarning: this.handleWarningPopup,
				showWarning: showWarningPopup,
				setFormChanged: this.handleSetFormChanged
			}
		}
		const propsCrudContainer = {
			urlApi: 'abm_fon_cuentas',
			getNewData: this.handleCrudChange,
			keyApi: 'nicfondo',
			handleExitAbm: this.handleRedirectHome,
			title: t('global.fund_account'),
			showWarning: formChanged,
			handleWarning: this.handleWarningPopup,
			nextAction: nextAction,
			setNextAction: this.handleSetNextAction
		}

		const componentTabs = Array.from(fundAccountTabs).map((tab) => {
			const selectedTab: any =
				tab.type === 'basic'
					? {
							key: tab.key,
							title: t(tab.label),
							content:
								tabActive === tab.key ? (
									<CrudFundAccountData
										{...tabProps[tab.key as keyof typeof tabProps]}
									/>
								) : (
									<div />
								)
					  }
					: tab.type === 'additional'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<ValuesCrudTable {...tabProps.additional_data} />
								) : (
									<div />
								)
					  }
					: tab.type === 'taxes'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<ValuesCrudTable {...tabProps.taxes} />
								) : (
									<div />
								)
					  }
					: tab.type === 'movem'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<ValuesCrudTable {...tabProps.movem} />
								) : (
									<div />
								)
					  }
					: tab.type === 'bank' && {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<FundAccountBankReconciliationTab {...tabProps.bank} />
								) : (
									<div />
								)
					  }
			return selectedTab
		})

		return (
			<CrudsContainer {...propsCrudContainer}>
				<Row className={'pt-4'}>
					<Col>
						<Card.Title>
							{disableForm
								? t('fund_account_data.title_view')
								: op === 'edit'
								? t('fund_account_data.title_edit')
								: t('fund_account_data.title_new')}
						</Card.Title>
					</Col>
				</Row>
				{valueDataSelected && (
					<>
						<Row>
							<Col sm={1}>
								<strong>{t('global.internal_code')}</strong>
							</Col>
							<Col className={'text-left'}>
								{valueDataSelected && valueDataSelected.nicfondo}
							</Col>
						</Row>
						<Row className={'pb-2'}>
							<Col sm={1}>
								<strong>{t('global.name')}</strong>
							</Col>
							<Col className={'text-left'}>
								{valueDataSelected && valueDataSelected.descrip}
							</Col>
						</Row>
					</>
				)}
				<CommonTabs
					customTabs={componentTabs}
					defaultActiveKey={tabActive}
					getActiveKey={this.handleActiveKey}
					showWarning={formChanged}
					handleWarning={this.handleWarningPopup}
					nextTab={nextTab}
					setNextTab={this.handleSetNextTab}
				/>
			</CrudsContainer>
		)
	}
}

const mapStateToProps = ({
	configCrud,
	fundAccountBankReconciliation
}: any) => {
	const {
		fieldsConfig,
		fieldsAltaConfig,
		valueDataSelected,
		valueDataUpdated
	} = configCrud
	const { bankReconciliationInfo, valueUpdated } = fundAccountBankReconciliation
	return {
		valueDataSelected,
		valueDataUpdated,
		fieldsConfig,
		fieldsAltaConfig,
		bankReconciliationInfo,
		valueUpdated
	}
}

const connectForm = connect(mapStateToProps, {
	crudsGetSingle,
	crudsCreate,
	crudsClearStore,
	showMessage,
	crudGetFundAccountBankReconciliation,
	crudUpdateFundAccountBankReconciliation,
	clearTableValidations
})(FundAccountDataContent)

export default withTranslation()(withMenu(withRouter(connectForm)))
