import {
	ICrudGetTermsOfSalesDues,
	ICrudGetTermsOfSalesDuesRes,
	ICrudUpdateTermsOfSalesDues,
	ICrudUpdateTermsOfSalesDuesRes
} from 'models/CrudTermsOfSalesInterface'
import {
	CRUD_GET_TERMS_OF_SALES_DUES,
	CRUD_GET_TERMS_OF_SALES_DUES_SUCCESS,
	CRUD_UPDATE_TERMS_OF_SALES_DUES,
	CRUD_UPDATE_TERMS_OF_SALES_DUES_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetTermsOfSalesDues = (payload: ICrudGetTermsOfSalesDues) => ({
	type: CRUD_GET_TERMS_OF_SALES_DUES,
	payload
})

export const crudGetTermsOfSalesDuesSuccess = (
	payload: ICrudGetTermsOfSalesDuesRes
) => ({
	type: CRUD_GET_TERMS_OF_SALES_DUES_SUCCESS,
	payload
})

export const crudUpdateTermsOfSalesDues = (
	payload: ICrudUpdateTermsOfSalesDues
) => ({
	type: CRUD_UPDATE_TERMS_OF_SALES_DUES,
	payload
})

export const crudUpdateTermsOfSalesDuesSuccess = (
	payload: ICrudUpdateTermsOfSalesDuesRes
) => ({
	type: CRUD_UPDATE_TERMS_OF_SALES_DUES_SUCCESS,
	payload
})
