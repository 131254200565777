import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { PureComponent } from 'react'
import { themr } from 'react-css-themr'
import styles from './collapseButton.module.scss'

class CollapseButton extends PureComponent {
	handlePress = () => {
		const { onPress } = this.props

		if (onPress) {
			onPress()
		}
	}

	render() {
		const { status, theme } = this.props
		const icon = status ? faMinusCircle : faPlusCircle
		return (
			<FontAwesomeIcon
				icon={icon}
				onClick={this.handlePress}
				className={theme.CollapseButton}
			/>
		)
	}
}

export default themr('MenuTheme', styles)(CollapseButton)
