import {
	confirmSalesHeadersError,
	confirmSalesHeadersSuccess,
	getSalesHeadersSuccess,
	validateBarCodeSalesSuccess
} from 'actions'
import {
	CONFIRM_SALES_HEADERS,
	GET_SALES_HEADERS,
	VALIDATE_BARCODE_SALES_HEADERS
} from 'constants/ActionsTypes'
import {
	IConfirmSalesHeadersAction,
	IGetSalesHeadersAction,
	IValidateBarCodeAction
} from 'models/Budget'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
	confirmSalesHeadersApi,
	getSalesHeadersApi,
	validateBarCodeSalesApi
} from '../api/Budget'

function* getSalesHeadersRequest({ payload }: IGetSalesHeadersAction): any {
	try {
		const values: any = yield call(getSalesHeadersApi, payload)
		yield put(getSalesHeadersSuccess(values))
	} catch (error: any) {
		const { Resultado } = error.response.data
		const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
		yield put(confirmSalesHeadersError(errorMessage))
	}
}

function* confirmSalesHeadersRequest({
	payload
}: IConfirmSalesHeadersAction): any {
	try {
		const { values, callBackReturn } = payload
		const resp: any = yield call(confirmSalesHeadersApi, values)
		yield put(confirmSalesHeadersSuccess(resp))

		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error: any) {
		const { Resultado } = error.response.data
		const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
		yield put(
			confirmSalesHeadersError({ message: errorMessage, confirm: true })
		)
	}
}

function* validateBarCodeSalesRequest({
	payload
}: IValidateBarCodeAction): any {
	try {
		const barCode: any = yield call(validateBarCodeSalesApi, payload)
		yield put(validateBarCodeSalesSuccess(barCode))
	} catch (error: any) {
		const { Resultado } = error.response.data
		const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
		yield put(
			confirmSalesHeadersError({ message: errorMessage, confirm: false })
		)
	}
}

export function* getSalesHeadersSaga() {
	yield takeEvery(GET_SALES_HEADERS, getSalesHeadersRequest)
}

export function* confirmSalesHeadersSaga() {
	yield takeEvery(CONFIRM_SALES_HEADERS, confirmSalesHeadersRequest)
}

export function* validateBarCodeSalesSaga() {
	yield takeEvery(VALIDATE_BARCODE_SALES_HEADERS, validateBarCodeSalesRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getSalesHeadersSaga),
		fork(confirmSalesHeadersSaga),
		fork(validateBarCodeSalesSaga)
	])
}
