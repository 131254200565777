import { confirmLoadItems, confirmTableItems, getVoucherType } from 'actions'
import withMenu from 'components/common/withMenu'
import GlobalContainer from 'components/layout/globalContainer'
import LoadItemsTable from 'components/loadItems/loadItemsTable'
import { P_CARGAITEMVTA } from 'constants/ConfigProcessNames'
import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class LoadItems extends PureComponent {
	getShortcuts = () => {
		const shortcuts = [
			{
				hotkey: { charCode: '65', modifiers: ['insert'] },
				action: this.addToCart,
				name: 'Add Product',
				description: 'Add product to car'
			},
			{
				hotkey: { charCode: '65', modifiers: ['home'] },
				action: this.homeFocus,
				name: 'Focus',
				description: 'Focus init'
			},
			{
				hotkey: {
					charCode: '40',
					modifiers: ['arrowDown', 'arrowUp' /*, "arrowLeft", "arrowRight", */]
				},
				action: this.moveArrow,
				name: 'Arrow move',
				description: 'Arrow move'
			}
		]

		return shortcuts
	}

	homeFocus = async () => {
		this.handleFocus()
	}

	getRowParams = (event) => {
		const params = event.target.id.split('-')
		const rowId = parseInt(params[1])
		const field = params[0]

		return { rowId, field }
	}

	moveArrow = async (e) => {
		if (e.target.id) {
			const { rowId, field } = this.getRowParams(e)
			this.handleMoveArrow({ rowId, field, key: e.code })
		}
	}

	addToCart = async (e) => {
		if (e.target.id) {
			const { rowId, field } = this.getRowParams(e)
			this.preventAddToCart(rowId, field)
		}
	}

	handleCallBackButton = () => {
		this.loadItemsConfirmation()
	}

	render() {
		const {
			config,
			itemsCart,
			parameterConfirm,
			confirmLoadItems,
			confirmTableItems
		} = this.props
		return (
			<Row>
				<GlobalContainer
					codeProcess={P_CARGAITEMVTA}
					shortcuts={this.getShortcuts()}
					callBackButton={this.handleCallBackButton}
					nextPage
				>
					<LoadItemsTable
						showSearchBox
						config={config}
						divClass={'mt-1'}
						preventAddToCart={(click) => (this.preventAddToCart = click)}
						homeFocus={(click) => (this.handleFocus = click)}
						formConfirmation={(click) => (this.loadItemsConfirmation = click)}
						handleMoveArrow={(click) => (this.handleMoveArrow = click)}
						handleItemAddToCart={confirmLoadItems}
						itemsCart={itemsCart}
						parameterConfirm={parameterConfirm}
						apiConfirm={confirmTableItems}
					/>
				</GlobalContainer>
			</Row>
		)
	}
}

const mapStateToProps = ({ product, voucher, loadItems }) => {
	const config = voucher.config ? voucher.config[P_CARGAITEMVTA] : null
	const { itemsCart, parameterConfirm } = loadItems
	const { productsUpdate, search } = product
	return { productsUpdate, search, config, itemsCart, parameterConfirm }
}

export default connect(mapStateToProps, {
	getVoucherType,
	confirmLoadItems,
	confirmTableItems
})(withRouter(withMenu(LoadItems)))
