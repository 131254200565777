import withMenu from 'components/common/withMenu'
import GlobalContainer from 'components/layout/globalContainer'
import ProviderIndividualLoadItemsForm from 'components/ProviderindividualLoadItems/ProviderIndividualLoadItemsForm'
import { P_CARGAINDIV_CPA } from 'constants/ConfigProcessNames'
import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class ProviderIndividualLoaditems extends PureComponent {
	getShortcuts = () => {
		const shortcuts = [
			{
				hotkey: { charCode: '65', modifiers: ['insert'] },
				action: this.addToCart,
				name: 'Save',
				description: 'Save a file'
			},
			{
				hotkey: { charCode: '65', modifiers: ['home'] },
				action: this.homeFocus,
				name: 'Focus',
				description: 'Focus init'
			}
		]

		return shortcuts
	}

	homeFocus = async () => {
		this.handleFocus()
	}

	getRowParams = (event) => {
		const params = event.target.id.split('-')
		const field = params[0]
		return field
	}

	addToCart = async (e) => {
		if (e.target.id) {
			const field = this.getRowParams(e)
			this.preventAddToCart(field)
		}
	}

	handleCallBackButton = () => {
		this.loadItemsConfirmation()
	}

	render() {
		const { config } = this.props
		return (
			<Row>
				<GlobalContainer
					codeProcess={P_CARGAINDIV_CPA}
					shoppingCartProcess={P_CARGAINDIV_CPA}
					shortcuts={this.getShortcuts()}
					callBackButton={this.handleCallBackButton}
					nextPage
				>
					<ProviderIndividualLoadItemsForm
						config={config}
						setClick={(click) => (this.addRowToCart = click)}
						homeFocus={(click) => (this.handleFocus = click)}
						formConfirmation={(click) => (this.loadItemsConfirmation = click)}
						preventAddToCart={(click) => (this.preventAddToCart = click)}
					/>
				</GlobalContainer>
			</Row>
		)
	}
}

const mapStateToProps = ({ voucher }) => {
	const config = voucher.config ? voucher.config[P_CARGAINDIV_CPA] : null

	return { config }
}

export default connect(
	mapStateToProps,
	{}
)(withRouter(withMenu(ProviderIndividualLoaditems)))
