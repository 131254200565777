import { getDetailSales, getOrderDirOptions, getPeriodFilter } from 'actions'
import CommonLabelCol from 'components/common/CommonLabelCol'
import CommonTable from 'components/common/commonTable'
import InputDropdown from 'components/form/inputDropdown'
import { getDefaultColumns, getOptionsDropDown } from 'lib/FormUtils'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'

class DetailSalesData extends Component {
	constructor(props) {
		super(props)
		this.state = {
			sortDirValue: 'M',
			sortValue: 'A',
			page: '1',
			pageSize: '10'
		}
	}

	componentDidMount = () => {
		this.handleGetSales({
			page_number: 1,
			page_size: 10
		})
		this.props.getOrderDirOptions()
		this.props.getPeriodFilter()
	}

	/**
	 * get data of sales in product detail
	 * @param {*} filters
	 */
	handleGetSales = (filters) => {
		const { productId, authUser } = this.props
		const { sortDirValue, sortValue, page, pageSize } = this.state

		this.props.getDetailSales({
			id: productId,
			idEmpresa: authUser.userCompany,
			periodo: sortDirValue,
			dir_orden: sortValue || filters.dir_orden,
			page_number: page,
			page_size: pageSize,
			...filters
		})
	}

	render() {
		const {
			fields,
			fieldsTable,
			detailSales,
			t,
			fieldsTableStatic,
			sortDirOptions,
			periodOptions,
			authUser
		} = this.props

		const { sortValue, sortDiValue } = this.state

		return (
			<Fragment>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="precio"
						value={detailSales.precio ? `${detailSales.precio}` : ''}
					/>
				</Row>
				<Row>
					<InputDropdown
						inputFormCol={{ sm: 6 }}
						fields={fields}
						label="t"
						inputId="periodo"
						name="periodo"
						placeholder=""
						colLabel="col-sm-3"
						colInput="col-sm-6"
						disable={false}
						value={sortDiValue}
						options={getOptionsDropDown(periodOptions)}
						noInitialExecute={true}
						onChange={(data) => {
							const { value } = data.target
							this.setState({ sortDirValue: value })
							this.handleGetSales({ periodo: value })
						}}
					/>
					<InputDropdown
						inputFormCol={{ sm: 6 }}
						fields={fields}
						label="t"
						inputId="orden"
						name="orden"
						placeholder=""
						colLabel="col-sm-3"
						colInput="col-sm-6"
						disable={false}
						value={sortValue}
						options={getOptionsDropDown(sortDirOptions)}
						noInitialExecute={true}
						onChange={(data) => {
							const { value } = data.target
							this.setState({ sortValue: value })
							this.handleGetSales({ dir_orden: value })
						}}
					/>
				</Row>
				<Row>
					<Col className="font-weight-bold text-center" lg={6} md={12} sm={12}>
						{t('search_product_detail.price_list')}
					</Col>
					<Col className="font-weight-bold text-center" lg={6} md={12} sm={12}>
						{t('search_product_detail.sales_statistics')}
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						{!_.isEmpty(fieldsTable) && (
							<CommonTable
								pagination={false}
								keyField="cod_preclis"
								columns={getDefaultColumns(fieldsTable, { authUser })}
								data={
									!_.isEmpty(detailSales) && detailSales.listasPrecios
										? detailSales.listasPrecios
										: []
								}
								paginationOptions={{}}
								onTableChange={this.handleChangeTable}
							/>
						)}
					</Col>
					<Col sm={6}>
						{!_.isEmpty(fieldsTableStatic) && (
							<CommonTable
								pagination={false}
								keyField="cod_valor"
								columns={getDefaultColumns(fieldsTableStatic, { authUser })}
								data={
									!_.isEmpty(detailSales) && detailSales.estVta
										? detailSales.estVta
										: []
								}
								paginationOptions={{}}
								onTableChange={this.handleChangeTable}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth, searchProducts, searchVoucher }) => {
	const { authUser } = auth
	const { detailSales, periodOptions } = searchProducts
	const { sortDirOptions } = searchVoucher
	return {
		authUser,
		detailSales,
		periodOptions,
		sortDirOptions
	}
}
const connectForm = connect(mapStateToProps, {
	getDetailSales,
	getOrderDirOptions,
	getPeriodFilter
})(DetailSalesData)

export default withTranslation()(connectForm)
