import {
	CRUD_GET_CUSTOMER_REFER,
	CRUD_GET_CUSTOMER_REFER_SUCCESS,
	CRUD_UPDATE_CUSTOMER_REFER,
	CRUD_UPDATE_CUSTOMER_REFER_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	referredSearched: any
	referredUpdated: any
}

const initialState = {
	referredSearched: null,
	referredUpdated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_CUSTOMER_REFER:
			return { ...state, referredSearched: null }
		case CRUD_GET_CUSTOMER_REFER_SUCCESS:
			return { ...state, referredSearched: action.payload }
		case CRUD_UPDATE_CUSTOMER_REFER:
			return { ...state, referredUpdated: null }
		case CRUD_UPDATE_CUSTOMER_REFER_SUCCESS:
			return { ...state, referredUpdated: action.payload }
		default:
			return state
	}
}

export default rootReducer
