import {
	addConfigPendingVouchersSuccess,
	getPendingVouchersSuccess,
	removeConfigPendingVouchersSuccess
} from 'actions'
import {
	ADD_CONFIG_PENDING_VOUCHERS,
	GET_PENDING_VOUCHERS,
	REMOVE_CONFIG_PENDING_VOUCHERS
} from 'constants/ActionsTypes'
import {
	IAddConfigPendingVouchersAction,
	IPendingVouchersParams
} from 'models/Landing'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
	addConfigPendingVouchersApi,
	getPendingVouchersApi,
	removeConfigPendingVouchersApi
} from '../api/Landing'

function* getPendingVouchersRequest({ payload }: IPendingVouchersParams): any {
	try {
		const values: any = yield call(getPendingVouchersApi, payload)
		yield put(getPendingVouchersSuccess(values))
	} catch (error) {}
}

function* addConfigPendingVouchersRequest({
	payload
}: IAddConfigPendingVouchersAction): any {
	try {
		const values: any = yield call(addConfigPendingVouchersApi, payload)
		yield put(addConfigPendingVouchersSuccess(values))
	} catch (error) {}
}

function* removeConfigPendingVouchersRequest({
	payload
}: IAddConfigPendingVouchersAction): any {
	try {
		const values: any = yield call(removeConfigPendingVouchersApi, payload)
		yield put(removeConfigPendingVouchersSuccess(values))
	} catch (error) {}
}

export function* getPendingVouchersSaga() {
	yield takeEvery(GET_PENDING_VOUCHERS, getPendingVouchersRequest)
}

export function* addConfigPendingVouchersSaga() {
	yield takeEvery(ADD_CONFIG_PENDING_VOUCHERS, addConfigPendingVouchersRequest)
}

export function* removeConfigPendingVouchersSaga() {
	yield takeEvery(
		REMOVE_CONFIG_PENDING_VOUCHERS,
		removeConfigPendingVouchersRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(getPendingVouchersSaga),
		fork(addConfigPendingVouchersSaga),
		fork(removeConfigPendingVouchersSaga)
	])
}
