import { CCAssignModalFields } from 'constants/crudsConfig/crudDirectCCAssign/crudDirectCCAssignModalFields'
import { renderInput } from 'lib/FormUtils'
import { ICrudFieldStructure, ICrudGenericFields } from 'models/CrudInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'

type CCAssignationsFieldProps = {
	field: ICrudFieldStructure
	formikProps: any
	crudProps: any
	initialValue: any
	isSearching?: boolean
	searchCostCenters?: any
	handleIsSearching?: any
}

type CCAssignationsFieldState = {
	dependsOn: any
	fieldConfig: ICrudGenericFields
	defaultValue?: string
	idField?: any
	value?: any
}

class CCAssignationsField extends Component<
	CCAssignationsFieldProps,
	CCAssignationsFieldState
> {
	constructor(props: CCAssignationsFieldProps) {
		super(props)
		this.state = {
			fieldConfig: {
				id: '',
				type: '',
				width: '',
				autoComplete: '',
				comboData: '',
				maxChars: 0,
				dependsOn: {
					id: '',
					values: [
						{
							idValue: '',
							disabled: false
						}
					]
				}
			},
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field } = this.props
		const idField = field.idCampo.trim()
		const addData = _.find(CCAssignModalFields, (field: ICrudGenericFields) => {
			return field.id === idField
		})
		addData && this.setState({ dependsOn: addData.dependsOn })
		addData && this.setState({ fieldConfig: addData })
		addData && this.setState({ idField: idField })
		addData && this.setState({ defaultValue: addData.default })
	}

	// Update check for CPOS validation, and default state of dropdowns
	componentDidUpdate = (prevProps: CCAssignationsFieldProps) => {
		const { formikProps } = this.props
		const { idField, defaultValue, fieldConfig } = this.state

		if (
			fieldConfig.type !== 'combo' &&
			!formikProps.values[idField] &&
			formikProps.values[idField] !== 0 &&
			defaultValue
		) {
			formikProps.setFieldValue(idField, defaultValue)
		}
	}

	handleBlur = async () => {
		const { formikProps, handleIsSearching } = this.props
		const { idField } = this.state
		formikProps.setTouched({
			...formikProps.touched,
			[idField]: true
		})
		idField === 'nuevo_cod_cc' && handleIsSearching(false)
	}

	renderInputs = (
		field: any,
		formikProps: any,
		disableForm: boolean,
		fieldConfig: ICrudGenericFields
	) => {
		const { isSearching, searchCostCenters, handleIsSearching, crudProps } =
			this.props
		const { costCenters } = crudProps
		const addData = fieldConfig

		addData &&
			(field = {
				...field,
				tipo: addData.type,
				colInput: addData.width,
				autoComplete: addData.autoComplete,
				onBlur: this.handleBlur,
				disabled: addData.disabled,
				customProps: {
					maxLength: addData.maxChars
				}
			})
		const accounts = !_.isEmpty(costCenters)
			? costCenters.lista.map((opt: any) => {
					return { id: opt.codigo, label: opt.descrip }
			  })
			: []

		if (field.idCampo === 'nuevo_cod_cc') {
			field.customProps = {
				minLength: 2,
				handleSearch: searchCostCenters,
				handleLoading: isSearching,
				auoptions: accounts,
				handleSelect: (cta: { id: string; label: string }) => {
					formikProps.setFieldValue('nuevo_cod_cc', cta.id)
					handleIsSearching(false)
				},
				disable: disableForm
			}
		}
		if (
			field.idCampo === 'cod_cc' &&
			formikProps.values[field.idCampo] === '*VARIOS*'
		) {
			field.mascara = null
		}

		if (!field.editable) field.disabled = true
		if (field.visible) {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { field, formikProps, crudProps } = this.props
		const { fieldConfig } = this.state
		return (
			<Fragment>
				{this.renderInputs(
					field,
					formikProps,
					crudProps.disableForm,
					fieldConfig
				)}
			</Fragment>
		)
	}
}

export default CCAssignationsField
