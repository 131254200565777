import {
	clearEquivalentProductsStore,
	deleteEquivalentProductGroup,
	deleteSearchedProducts,
	getConfigFieldsCrud,
	getConfigVoucher,
	getEquivalentProductGroup,
	getEquivalentProducts,
	selectSearchedProducts,
	setEquivalentProductGroup,
	setEquivalentProductSelection
} from 'actions'
import CommonModal from 'components/common/commonModal'
import SearchProductsForm from 'components/SearchProducts/SearchProductsForm'
import SearchProductsTable from 'components/SearchProducts/SearchProductsTable'
import { P_CONF_PROD_EQ, P_SEARCH_PRODUCTS } from 'constants/ConfigProcessNames'
import { IAbmStructureResponse } from 'constants/valuesInterfaces/interfaces'
import { getFiltersData } from 'lib/Utils'
import {
	IConfigFieldsForm,
	IDeleteEquivalentProductGroup,
	IGetEquivalentProductGroupResponse,
	IGetEquivalentProducts,
	IGetEquivalentProductsResponse,
	IGetEquivalentProductsResponseArray,
	ISetEquivalentProductGroup,
	ISetEquivalentProductSelection
} from 'models/EquivalentProducts'
import { IResponseResult } from 'models/ResultsInterface'
import { IDeleteSearchedProductsParams } from 'models/SearchProduct'
import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import EquivalentProductsForm from './EquivalentProductsForm'
import EquivalentProductsTable from './EquivalentProductsTable'

interface IEquivalentProductsProps extends WithTranslation {
	config?: IConfigFieldsForm
	t?: any
	products: IGetEquivalentProductsResponseArray
	getEquivalentProducts: (payload: IGetEquivalentProducts) => void
	clearEquivalentProductsStore: () => void
	setEquivalentProductSelection: (
		payload: ISetEquivalentProductSelection
	) => void
	getEquivalentProductGroup: (niprod: number) => void
	setEquivalentProductGroup: (payload: ISetEquivalentProductGroup) => void
	deleteEquivalentProductGroup: (payload: IDeleteEquivalentProductGroup) => void
	getConfigFieldsCrud: (payload: { cod_abm: string }) => void
	selectSearchedProducts: (payload: any) => void
	deleteSearchedProducts: (payload: IDeleteSearchedProductsParams) => void
	fieldsConfig: IAbmStructureResponse
	idOperacion?: any
	productSelection: any
	editGroup: IResponseResult
	deleteGroup: IResponseResult
	productGroup: IGetEquivalentProductGroupResponse
}

interface IEquivalentProductsState {
	searchParams: IGetEquivalentProducts
	niprod: number
	disabledProducts: Array<number>
	totalProducts: number
	showSearchModal: boolean
	searchProduct: string
}
class EquivalentProducts extends Component<
	IEquivalentProductsProps,
	IEquivalentProductsState
> {
	constructor(props: IEquivalentProductsProps) {
		super(props)
		this.state = {
			niprod: 0,
			searchParams: {
				idOperacion: props.idOperacion,
				niref: 0,
				page_number: 0,
				page_size: 0
			},
			disabledProducts: [],
			totalProducts: 0,
			showSearchModal: false,
			searchProduct: ''
		}
	}

	componentDidMount = () => {
		this.props.clearEquivalentProductsStore()
		this.props.getConfigFieldsCrud({ cod_abm: P_SEARCH_PRODUCTS })
	}

	componentDidUpdate = (
		prevProps: IEquivalentProductsProps,
		prevState: IEquivalentProductsState
	) => {
		const {
			getEquivalentProducts,
			getEquivalentProductGroup,
			deleteSearchedProducts,
			clearEquivalentProductsStore,
			productSelection,
			editGroup,
			deleteGroup,
			products
		} = this.props
		const { searchParams, niprod, totalProducts } = this.state

		if (searchParams !== prevState.searchParams && searchParams.niref) {
			getEquivalentProducts(searchParams)
		}

		if (productSelection && prevProps.productSelection !== productSelection) {
			if (!totalProducts) {
				getEquivalentProducts(searchParams)
				getEquivalentProductGroup(niprod)
			}
		}

		if (
			editGroup &&
			prevProps.editGroup !== editGroup &&
			editGroup.Resultado === true
		) {
			clearEquivalentProductsStore()
			getEquivalentProductGroup(niprod)
		}

		if (
			deleteGroup &&
			prevProps.deleteGroup !== deleteGroup &&
			deleteGroup.Resultado === true
		) {
			clearEquivalentProductsStore()
		}

		if (niprod !== prevState.niprod) {
			clearEquivalentProductsStore()
			niprod && getEquivalentProductGroup(niprod)
		}

		if (products && products !== prevProps.products) {
			const disabledProducts: Array<number> = []
			_.forEach(products.productos, (producto) => {
				producto.niref &&
					producto.niref !== products.niref &&
					disabledProducts.push(producto.niprod)
			})

			deleteSearchedProducts({ idOperacion: searchParams.idOperacion })
			this.setState({ disabledProducts: disabledProducts })
		}
	}

	handleSearchPagination = (page_number: number, page_size: number) => {
		const { searchParams } = this.state

		this.setState({
			searchParams: {
				...searchParams,
				page_number: page_number,
				page_size: page_size
			}
		})
	}

	handleSearchProduct = (niprod: number) => {
		this.setState({ niprod: niprod })
	}

	handleSearchFilter = (values: any) => {
		const { searchParams } = this.state

		this.setState({
			searchParams: {
				...searchParams,
				page_number: 0,
				page_size: 0,
				niref: values?.niref,
				mascara: values?.mascara,
				descrip: values?.desc_filtro,
				sel_primero: values?.check_selected
			},
			niprod: values?.desc_prod_ref
		})
	}

	handleSelection = (selection: Array<number>) => {
		const { products, setEquivalentProductSelection } = this.props
		const { searchParams, niprod } = this.state

		const newSelection: Array<{ niprod: number; seleccion: number }> = []

		_.forEach(products.productos, (product: IGetEquivalentProductsResponse) => {
			_.some(selection, (selectedProduct) => product.niprod === selectedProduct)
				? newSelection.push({ niprod: product.niprod, seleccion: 1 })
				: newSelection.push({ niprod: product.niprod, seleccion: 0 })
		})

		setEquivalentProductSelection({
			idoperacion: searchParams.idOperacion,
			niref: products.niref,
			select: newSelection
		})

		this.setState({
			totalProducts: newSelection.filter(
				(selection) => selection.seleccion === 1
			).length
		})
	}

	handleGroupConfirm = (values: ISetEquivalentProductGroup) => {
		const { setEquivalentProductGroup } = this.props

		setEquivalentProductGroup(values)
	}

	handleGroupDelete = (niref: number) => {
		const { deleteEquivalentProductGroup } = this.props

		deleteEquivalentProductGroup({ niref: niref })
	}

	renderSearchProducts = () => {
		const { fieldsConfig } = this.props
		return (
			<>
				<SearchProductsForm
					fields={getFiltersData(fieldsConfig.campos, {
						agrupador: 'formulario'
					})}
					isSearchProducts={true}
					handleSendSearch={this.handleSendSearch}
				/>
				<SearchProductsTable
					fields={getFiltersData(fieldsConfig.campos, {
						agrupador: 'grilla'
					})}
				/>
			</>
		)
	}

	/**
	 * to close search products modal
	 */
	handleCloseSearchModal = () => {
		this.setState({ showSearchModal: false })
	}

	/**
	 * to open and close search modal
	 */

	handleToggleSearchModal = () => {
		this.setState((state) => ({
			showSearchModal: !state.showSearchModal
		}))
	}

	handleSendSearch = (values: any) => {
		const { idOperacion } = this.props
		this.setState({ showSearchModal: false })
		this.props.selectSearchedProducts({ ...values, idOperacion })
	}

	render() {
		const { config, t, idOperacion, products, productGroup } = this.props
		const { disabledProducts, searchParams, showSearchModal } = this.state

		const fieldsFilter = config
			? getFiltersData(config.campos, { agrupador: 'formulario' })
			: []
		const allFields = [
			...fieldsFilter,
			...(config ? getFiltersData(config.campos, { agrupador: 'filtros' }) : [])
		]
		const tableFields = config
			? getFiltersData(config.campos, { agrupador: 'grilla' })
			: []

		const formProps = {
			fields: allFields,
			t: t,
			idOperacion: idOperacion,
			handleFilterChange: this.handleSearchFilter,
			handleProductChange: this.handleSearchProduct,
			handleGroupConfirm: this.handleGroupConfirm,
			handleGroupDelete: this.handleGroupDelete,
			productGroup: productGroup,
			searchParams: searchParams,
			handleOpenModal: this.handleToggleSearchModal
		}

		const tableProps = {
			products: products,
			tableFields: tableFields,
			disableVoucher: false,
			disabledProducts: disabledProducts,
			handlePaginationChange: this.handleSearchPagination,
			handleProductSelect: this.handleSelection
		}

		const propsSearchModal = {
			showModal: showSearchModal,
			handleCloseModal: this.handleToggleSearchModal,
			modalTitle: 'Busqueda de productos',
			modalBody: showSearchModal ? this.renderSearchProducts() : <></>,
			buttons: false,
			sizeModal: 'xl'
		}

		return (
			<Col className={`col-12`}>
				<EquivalentProductsForm {...formProps} />
				{products && <EquivalentProductsTable {...tableProps} />}
				{showSearchModal && <CommonModal {...propsSearchModal} />}
			</Col>
		)
	}
}

const mapStateToProps = ({
	voucher,
	auth,
	equivalentProducts,
	configCrud
}: any) => {
	const config = voucher.config ? voucher.config[P_CONF_PROD_EQ] : null
	const { products, productSelection, editGroup, deleteGroup, productGroup } =
		equivalentProducts
	const { fieldsConfig } = configCrud
	const { authUser } = auth
	return {
		productSelection,
		config,
		authUser,
		products,
		editGroup,
		deleteGroup,
		productGroup,
		fieldsConfig
	}
}

const connectForm = connect(mapStateToProps, {
	getEquivalentProducts,
	getConfigVoucher,
	setEquivalentProductSelection,
	clearEquivalentProductsStore,
	setEquivalentProductGroup,
	deleteEquivalentProductGroup,
	getEquivalentProductGroup,
	getConfigFieldsCrud,
	selectSearchedProducts,
	deleteSearchedProducts
})(EquivalentProducts)

export default withTranslation()(withRouter(connectForm))
