import {
	ICrudCreateLedgerAccountsCode,
	ICrudCreateLedgerAccountsCodeRes,
	ICrudGetLedgerAccountsCode,
	ICrudGetLedgerAccountsCodeConfig,
	ICrudGetLedgerAccountsCodeConfigRes,
	ICrudGetLedgerAccountsCodeRes
} from 'models/CrudLedgerAccountsInterface'
import {
	CRUD_CLEAR_LEDGER_ACCOUNTS_CODE_STORE,
	CRUD_CREATE_LEDGER_ACCOUNTS_CODE,
	CRUD_CREATE_LEDGER_ACCOUNTS_CODE_SUCCESS,
	CRUD_GET_LEDGER_ACCOUNTS_CODE,
	CRUD_GET_LEDGER_ACCOUNTS_CODE_CONFIG,
	CRUD_GET_LEDGER_ACCOUNTS_CODE_CONFIG_SUCCESS,
	CRUD_GET_LEDGER_ACCOUNTS_CODE_SUCCESS,
	CRUD_VALIDATE_LEDGER_ACCOUNTS_CODE,
	CRUD_VALIDATE_LEDGER_ACCOUNTS_CODE_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetLedgerAccountsCode = (
	payload: ICrudGetLedgerAccountsCode
) => {
	return {
		type: CRUD_GET_LEDGER_ACCOUNTS_CODE,
		payload
	}
}

export const crudGetLedgerAccountsCodeSuccess = (
	payload: ICrudGetLedgerAccountsCodeRes
) => {
	return {
		type: CRUD_GET_LEDGER_ACCOUNTS_CODE_SUCCESS,
		payload
	}
}

export const crudGetLedgerAccountsCodeConfig = (
	payload: ICrudGetLedgerAccountsCodeConfig
) => {
	return {
		type: CRUD_GET_LEDGER_ACCOUNTS_CODE_CONFIG,
		payload
	}
}

export const crudGetLedgerAccountsCodeConfigSuccess = (
	payload: ICrudGetLedgerAccountsCodeConfigRes
) => {
	return {
		type: CRUD_GET_LEDGER_ACCOUNTS_CODE_CONFIG_SUCCESS,
		payload
	}
}

export const crudCreateLedgerAccountsCode = (
	payload: ICrudCreateLedgerAccountsCode
) => {
	return {
		type: CRUD_CREATE_LEDGER_ACCOUNTS_CODE,
		payload
	}
}

export const crudCreateLedgerAccountsCodeSuccess = (
	payload: ICrudCreateLedgerAccountsCodeRes
) => {
	return {
		type: CRUD_CREATE_LEDGER_ACCOUNTS_CODE_SUCCESS,
		payload
	}
}

export const crudValidateLedgerAccountsCode = (
	payload: ICrudCreateLedgerAccountsCode
) => {
	return {
		type: CRUD_VALIDATE_LEDGER_ACCOUNTS_CODE,
		payload
	}
}

export const crudValidateLedgerAccountsCodeSuccess = (
	payload: ICrudCreateLedgerAccountsCodeRes
) => {
	return {
		type: CRUD_VALIDATE_LEDGER_ACCOUNTS_CODE_SUCCESS,
		payload
	}
}

export const crudClearLedgerAccountsCodeStore = () => ({
	type: CRUD_CLEAR_LEDGER_ACCOUNTS_CODE_STORE
})
