import { CalculationBonusesActions } from 'constants/ActionsTypesTs'
import { IGetBonusesResponse } from 'models/CalculationBonuses'

export interface valueState {
	bonusesData: IGetBonusesResponse | null
}

const initialState = {
	bonusesData: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CalculationBonusesActions.GET_BONUSES:
			return { ...state, bonusesData: null }
		case CalculationBonusesActions.GET_BONUSES_SUCCESS:
			return { ...state, bonusesData: action.payload }

		default:
			return state
	}
}

export default rootReducer
