import Axios from 'axios'
import {
	ICrudGetProfileUsers,
	ICrudUpdateProfileUsers
} from 'models/CrudProfilesInterface'

const urlCRUD = '/abm_perfiles'

export const crudGetProfileUsers = async (params: ICrudGetProfileUsers) => {
	const response = await Axios.get(`${urlCRUD}/usuarios/sel`, { params })
	return response.data
}

export const crudUpdateProfileUsers = async (
	params: ICrudUpdateProfileUsers
) => {
	const response = await Axios.post(`${urlCRUD}/usuarios/confirmar`, params)
	return response.data
}
