import { getAffectationVoucherByUser, getVoucherType } from 'actions'
import InputDropdown from 'components/form/inputDropdown'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'

interface AffectationVoucherProps extends WithTranslation {
	affectationVoucher: any
	t?: any
	nioperacion: number
	getAffectationVoucherByUser: Function
	getVoucherType: Function
	authUser: any
	handleCloseModal: () => void
}

interface AffectationVoucherState {
	idComprobante: string
}

export class AffectationVoucher extends Component<
	AffectationVoucherProps,
	AffectationVoucherState
> {
	constructor(props: AffectationVoucherProps) {
		super(props)
		this.state = {
			idComprobante: ''
		}
	}

	componentDidMount = () => {
		const { nioperacion } = this.props
		this.props.getAffectationVoucherByUser({ nioperacion })
	}

	/**
	 * To create new voucher
	 * @params value, document selected
	 */
	handleCreateVoucher = () => {
		const { authUser } = this.props
		const { idComprobante } = this.state

		if (idComprobante) {
			this.props.getVoucherType({
				idComprobante,
				idEmpresa: authUser.userCompany
			})
		}
	}

	render() {
		const { t, affectationVoucher } = this.props

		const dropDownProps = {
			inputFormCol: { sm: 12 },
			fields: [
				{
					idCampo: 'voucherType',
					label: 'Tipo de comprobante',
					visible: 1,
					requerido: 0,
					editable: 1
				}
			],
			label: 'l',
			inputId: 'voucherType',
			name: 'voucherType',
			styles: { width: '100%' },
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			options: _.isEmpty(affectationVoucher)
				? []
				: affectationVoucher.comprobantes.map((voucher: any) => {
						return {
							id: voucher.nioperacion,
							label: voucher.descrip_comprob
						}
				  }),
			// value:'',
			onChange: (evnt: any) => {
				this.setState({ idComprobante: evnt.target.value })
			}
		}

		return (
			<Fragment>
				<Row>
					<Col sm={10}>
						<InputDropdown {...dropDownProps} />
					</Col>
					<Col sm={2} className={'text-left'}>
						<Button onClick={this.handleCreateVoucher} type={'primary'}>
							{t('global.new')}
						</Button>
					</Col>
				</Row>
				<Row>
					<Col sm={12} className="text-center">
						<Button
							style={{ minWidth: '145px' }}
							type={'button'}
							onClick={this.props.handleCloseModal}
						>
							{'Cancelar'}
						</Button>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ vouchertype, auth }: any) => {
	const { affectationVoucher } = vouchertype
	const { authUser } = auth
	return { affectationVoucher, authUser }
}

const mapDispatchToProps = { getAffectationVoucherByUser, getVoucherType }

export default withTranslation()(
	connect(mapStateToProps, mapDispatchToProps)(AffectationVoucher)
)
