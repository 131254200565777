import {
	salesAffectedImportConfirm,
	salesAffectedSubCalculation,
	setTableDataInvolvement
} from 'actions'
import CommonTable from 'components/common/commonTable'
import NotificationMessage from 'components/common/notificationMessage'
import InputText from 'components/form/inputText'
import { validateField } from 'lib/FieldValidations'
import { getSelectFilter, getValueMask } from 'lib/MaskValues'
import { handleIsRendeTableFinish, handleSetFocus } from 'lib/TableUtils'
import React, { Fragment, PureComponent } from 'react'
import { Col } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import styles from './voucherImportTable.module.scss'

class VoucherImportTable extends PureComponent {
	constructor(props) {
		super(props)
		this.inputRefs = {}
		this.state = {
			rowSelected: [],
			showError: false,
			errorMessage: '',
			selectedCheck: [],
			checksByPage: [],
			dataTable: [],
			tableColumns: [],
			updateDataTable: []
		}

		this.rowErrors = []
		this.primaryKey = 'id'
		this.firstRefs = null
	}

	componentDidMount = () => {
		this.props.formConfirmation(this.handleConfirmation)
		this.props.handleMoveArrow(this.handleMoveArrow)
		this.handleMoveArrow = this.handleMoveArrow.bind(this)
		this.handleConfirmation = this.handleConfirmation.bind(this)
	}

	componentDidUpdate = (prevProps) => {
		const { products, config } = this.props

		if (prevProps.products !== products && products && config) {
			const tableColumns = this.getColumns()
			this.setState({ dataTable: products.Items, tableColumns })
		}

		if (config !== prevProps.config && config) {
			const tableColumns = this.getColumns()
			this.setState({ tableColumns })
		}
	}

	/**
	 *
	 * to update data from table
	 */
	static getDerivedStateFromProps(props, state) {
		const { productsUpdate } = props
		const { dataTable } = state

		if (productsUpdate !== dataTable && productsUpdate) {
			const rowData = dataTable.map((prod) => {
				let result = {}
				if (productsUpdate) {
					productsUpdate.forEach((update) => {
						if (update.id === prod.id) {
							result = {
								...update
							}
						} else {
							result = prod
						}
					})
				}

				return result
			})

			return {
				dataTable: rowData
			}
		}
		return null
	}

	/**
	 * To move focus to other rows.
	 * @params {rowId, field, key}
	 */
	handleMoveArrow = (params) => {
		const { rowId, field, key } = params
		const reverse = key === 'ArrowUp'
		const nextRow = this.getNextProductId(rowId, reverse)
		handleSetFocus(field, nextRow, this.inputRefs)
	}

	/**
	 * to get id of the next row
	 * @params idProduct
	 * @params revers >> to validate if down or up
	 */
	getNextProductId = (idProduct, reverse = false) => {
		const { dataTable } = this.state
		let result = 0

		dataTable.forEach((prd, index) => {
			if (prd.id === idProduct) {
				result = !reverse ? index + 1 : index - 1 // Back o next row
			}
		})

		if (dataTable[result]) {
			return dataTable[result].id ? dataTable[result].id : null
		} else {
			return null
		}
	}

	/**
	 * callback used when table is render to set
	 * focus in the first editable field
	 */

	handleIsRendeTableFinish = () => {
		if (this.firstRefs && this.firstRefs.current) {
			if (this.firstRefs.current.element) {
				this.firstRefs.current.element.focus()
			} else {
				this.firstRefs.current.focus()
			}
		}
	}

	handleConfirmation = () => {
		const { idOperacion, callBackReturn } = this.props
		const items = this.getSelectedCheck()
		this.props.handleConfirmImp({
			items: { idOperacion, items },
			callBackReturn
		})
	}

	/**
	 * to create columns in the table
	 * @returns columns
	 */
	getColumns = () => {
		const { config, readOnly, filtersKey, products, theme } = this.props
		const rows = config.campos.map((field) => {
			const campoId = field.idCampo.trim()
			return {
				dataField: campoId,
				text:
					filtersKey[campoId] && products
						? ''
						: field.label === null
						? ''
						: field.label,
				align:
					campoId === 'cotiz' ||
					campoId === 'imp_pend' ||
					campoId === 'imp_afec' ||
					campoId === 'saldo'
						? 'right'
						: 'center',
				headerAlign: 'center',
				headerStyle: this.getStyleColumn(field),
				hidden: !field.visible,
				filter: getSelectFilter(
					field,
					products,
					filtersKey,
					this.props,
					theme.inputFilter
				),
				formatter:
					(field.editable || field.mascara) && !readOnly
						? (cell, row, rowIndex) => {
								return this.renderFormat(field, cell, row)
						  }
						: null
			}
		})

		return rows
	}

	getFilterOptionsTable = (idField, field) => {
		const { products } = this.props
		const filters = products[`filtro_${idField}`]
			? products[`filtro_${idField}`]
			: []
		const result = filters.map((filter) => {
			const labelValue = field.mascara
				? getValueMask(filter, field.mascara, this.props)
				: filter
			return { value: filter, label: labelValue }
		})

		return result
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	getStyleColumn = (field) => {
		const idField = field.idCampo.trim()
		let style = {}
		const colPadding = '12px 4px'

		switch (idField) {
			case 'fec_emis':
				style = { width: '8%' }
				break
			case 'fec_vto':
				style = { width: '8%' }
				break
			case 'fec_real':
				style = { width: '8%' }
				break
			case 'comprob_desc':
				style = { width: '15%' }
				break
			case 'comprob_nro':
				style = { width: '12%' }
				break
			case 'sucursal':
			case 'desc_cond_cpa':
			case 'desc_cond_vta':
				style = { width: '10%', maxWidth: '195px' }
				break
			case 'desc_item':
				style = { width: '12%' }
				break
			case 'cod_mone':
				style = { width: '5%' }
				break
			case 'cotiz':
				style = { width: '10%' }
				break
			case 'imp_pend':
				style = { width: '12%', minWidth: '160px' }
				break
			case 'imp_afec':
				style = { width: '12%', minWidth: '160px' }
				break
			case 'saldo':
				style = { width: '4%' }
				break
			case 'desc_prod':
				// style = { width: "10%" };
				break
			case 'mon_comp_cpa':
				// style = { width: "3%" };
				break
			default:
				style = { width: '5%' }
				break
		}

		style = { ...style, padding: colPadding }

		if (field.requerido === '1') {
			style.color = 'red'
		}

		return style
	}

	/**
	 * to validate data in the onblour and enter event
	 * @param row
	 * @param field
	 * @param value
	 */

	validateAfectImport = (row, field, value) => {
		const { idOperacion, t, keysValidate } = this.props
		const fieldId = field.idCampo.trim()
		const newItem = {
			id: row[this.primaryKey],
			Nitem: row.nitem,
			imp_afec: value
		}
		newItem[keysValidate.label] = row[keysValidate.field]
		const items = [newItem]
		const selected = this.state.selectedCheck ? this.state.selectedCheck : []
		const rows = this.state.rowSelected ? this.state.rowSelected : []
		let saveItem = true

		rows.forEach((toSave, index) => {
			if (toSave[this.primaryKey] === row[this.primaryKey]) {
				saveItem = false
				toSave.imp_afec = value
			}
		})

		if (saveItem) {
			rows.push(newItem)
		}

		if (this.isValueChange(row.id, fieldId, value)) {
			const nextRow = this.getNextProductId(row.id)

			if (field.valid) {
				let message = ''
				if (!validateField(value, field.valid)) {
					message = t('validation-required', { field: field.label })
					this.setState({ showError: true, errorMessage: message })
				} else {
					selected.push(row[this.primaryKey])
					this.setState({ selectedCheck: selected, rowSelected: rows })
					this.props.handleValidateImp({ idOperacion, items })
				}
			} else {
				if (parseInt(value) === 0) {
					selected.forEach((delet, index) => {
						if (delet === row[this.primaryKey]) {
							selected.splice(index, 1)
						}
					})
				} else {
					selected.push(row[this.primaryKey])
				}

				this.setState({ selectedCheck: selected, rowSelected: rows })
				this.props.handleValidateImp({ idOperacion, items })
			}

			handleSetFocus(fieldId, nextRow, this.inputRefs)
		}
	}

	// Render inputs
	renderFormat = (field, value, row) => {
		const fieldId = field.idCampo.trim()
		let result = null
		const inputError = value === 'error_input'
		const customValue = value === 'error_input' ? '' : value
		const inputStyle = fieldId === 'imp_afec' ? { textAlign: 'right' } : {}

		if (field.editable && !this.inputRefs[fieldId]) {
			this.inputRefs[fieldId] = {}
		}

		if (field.editable && !this.inputRefs[fieldId][row[this.primaryKey]]) {
			const customRef = React.createRef()
			this.inputRefs[fieldId][row[this.primaryKey]] = customRef
			if (this.firstRefs === null) {
				this.firstRefs = customRef
			}
		}

		const optionsInput = {
			fwRef: field.editable
				? this.inputRefs[fieldId][row[this.primaryKey]]
				: null,
			inputFormCol: { sm: 12 },
			fields: [{ ...field, label: false }],
			label: false,
			inputId: `${fieldId}`,
			id: `${fieldId}-${row[this.primaryKey]}`,
			name: `${fieldId}-${row[this.primaryKey]}`,
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			divStyle: { paddingLeft: '17px' },
			disable: false,
			value: customValue,
			showError: inputError,
			styles: inputStyle,
			rowStyle: { marginBottom: '5px' },
			onChange: (value) => {
				this.handleUpdateDataTable(row[this.primaryKey], fieldId, value)
			}
		}

		if (field.editable) {
			result = (
				<InputText
					{...optionsInput}
					handleEnterKey={(e, value) => {
						if (fieldId === 'imp_afec') {
							this.validateAfectImport(row, field, value)
						}
						return true
					}}
					onBlur={(value) => {
						if (fieldId === 'imp_afec') {
							// pendiente logica.
							this.validateAfectImport(row, field, value)
						} else {
							const params = { niprod: row.niprod, idcampo: fieldId, value }
							this.props.setTableDataInvolvement([params])
						}
					}}
				/>
			)
		} else if (field.mascara) {
			result = getValueMask(
				value === 0 ? `${value}` : value,
				field.mascara,
				this.props
			)
		}

		return result
	}

	getSelectedCheck = () => {
		const { rowSelected } = this.state
		const { products } = this.props
		const items = []
		if (products) {
			products.Items.forEach((row) => {
				rowSelected.forEach((check) => {
					if (row[this.primaryKey] === check.id) {
						items.push({
							id: check.id,
							Nimovcli: row.nimovcli,
							Nitem: row.nitem,
							imp_afec: row.imp_afec,
							niprod: row.niprod
						})
					}
				})
			})
		}

		return items
	}

	getRowsImpAfect = () => {
		const { products } = this.props
		const { dataTable } = this.state
		const result = []
		const selectProducts = _.isEmpty(dataTable) ? products.Items : dataTable

		selectProducts.forEach((row) => {
			if (row.imp_afec) {
				result.push(row[this.primaryKey])
			}
		})

		return result
	}

	/**
	 * To validate if the field values changed
	 * @params id row
	 * @params fieldId
	 * @params value
	 */
	isValueChange = (rowId, fieldId, value) => {
		const { dataTable } = this.state
		if (value) {
			const updateIndex = _.findIndex(dataTable, { id: rowId })
			if (updateIndex >= 0) {
				const updateValue = dataTable[updateIndex][fieldId]
					? dataTable[updateIndex][fieldId]
					: null
				if (updateValue === null) {
					return true
				} else if (String(updateValue) === String(value)) {
					return false
				} else {
					return true
				}
			} else {
				return true
			}
		}

		return false
	}

	/**
	 * To save and update data table to validate changes..
	 * @params id row
	 * @params fieldId
	 * @params value
	 */
	handleUpdateDataTable = (rowId, fieldId, value) => {
		const { updateDataTable } = this.state
		const updateIndex = _.findIndex(updateDataTable, { rowId })
		if (updateIndex === -1) {
			const params = { rowId }
			params[fieldId] = value
			updateDataTable.push(params)
		} else {
			updateDataTable[updateIndex][fieldId] = value
		}
		this.setState({ updateDataTable })
	}

	/**
	 * to render the actions of the inputs checks
	 *
	 */
	handleOnSelect = (row, isSelect, rowIndex, e) => {
		const { keysValidate, idOperacion } = this.props
		const rows = this.state.rowSelected ? this.state.rowSelected : []
		let updateRecord = false

		if (isSelect) {
			// new row
			const cantSend = row.imp_afec ? row.imp_afec : row.imp_pend
			rows.forEach((toAdd) => {
				// update row
				if (toAdd.id === row.id) {
					toAdd.imp_afec = cantSend
					updateRecord = true
				}
			})

			if (!updateRecord) {
				// new row
				const newItem = {
					id: row[this.primaryKey],
					nitem: row.nitem,
					imp_afec: cantSend
				}
				newItem[keysValidate.label] = row[keysValidate.field]
				rows.push(newItem)
			}
		} else {
			// Less
			rows.forEach((toDelete) => {
				if (toDelete[this.primaryKey] === row[this.primaryKey]) {
					toDelete.imp_afec = 0
					updateRecord = true
				}
			})

			if (!updateRecord) {
				// new when uncheck
				const restItem = {
					id: row[this.primaryKey],
					Nimovcli: row.nimovcli,
					nitem: row.nitem,
					imp_afec: 0
				}
				restItem[keysValidate.label] = row[keysValidate.field]
				rows.push(restItem)
			}
		}

		if (rows.length) {
			this.props.handleValidateImp({ idOperacion, items: rows })
		}

		this.setState({ rowSelected: rows })
	}

	/**
	 * to render the actions of the inputs checks
	 *
	 */
	handleOnSelectAll = (isSelect, rows, e) => {
		const { keysValidate, idOperacion } = this.props
		let selected = []
		if (isSelect) {
			this.setState({ selectedCheck: null })
			selected = rows.map((fila) => {
				const tmprow = {
					id: fila[this.primaryKey],
					nitem: fila.nitem,
					imp_afec: fila.imp_afec ? fila.imp_afec : fila.imp_pend
				}
				tmprow[keysValidate.label] = fila[keysValidate.field]
				return tmprow
			})
		} else {
			selected = rows.map((fila) => {
				const tmprow = {
					id: fila[this.primaryKey],
					nitem: fila.nitem,
					imp_afec: 0
				}
				tmprow[keysValidate.label] = fila[keysValidate.field]
				return tmprow
			})
		}

		this.setState({ rowSelected: selected })
		if (selected.length) {
			this.props.handleValidateImp({ idOperacion, items: selected })
		}
	}

	/**
	 * to get all select options
	 * @returns object with the select options
	 */
	getSelectRow = () => {
		const { products, readOnly } = this.props
		const selected = products ? this.getRowsImpAfect() : []
		return {
			mode: 'checkbox',
			selectColumnPosition: 'right',
			selected: selected,
			hideSelectColumn: !!readOnly,
			style: (row) => {
				const backgroundColor = row.error ? '#f8d7da' : '#FFF'
				return { backgroundColor }
			},
			onSelect: this.handleOnSelect,
			onSelectAll: this.handleOnSelectAll
		}
	}

	/**
	 * to get all pagination options
	 * @returns object with the pagination options
	 */

	getPaginationOptions = () => {
		const { products, idOperacion } = this.props
		return products
			? {
					pageStartIndex: 1,
					sizePerPage: products.page_size,
					page: products.page_number,
					totalSize: products.total_count,
					onPageChange: (page, sizePerPage) => {
						const items = this.getSelectedCheck()
						if (items.length) {
							this.props.handleConfirmImp({ items: { idOperacion, items } })
						}
					}
			  }
			: {}
	}

	/**
	 * to handle on change table event
	 * @param {string} type
	 * @param {object} pagination
	 */
	handleOnChangeTable = (type, pagination) => {
		this.firstRefs = null
		this.inputRefs = {}
		this.props.handleChangeTable(type, pagination)
	}

	render() {
		const { theme } = this.props
		const { dataTable, tableColumns } = this.state
		const defaultSorted = [
			{
				dataField: 'fec_entrega',
				order: 'desc'
			}
		]

		return (
			<Fragment>
				<Col sm={12} className={'mb-1'}>
					<NotificationMessage
						{...this.state}
						handleCloseError={this.handleCloseError}
						type={'danger'}
					/>
				</Col>
				<Col className={`col-12 pl-0 pr-0 ${theme.divContainer}`}>
					{dataTable.length > 0 && tableColumns.length > 0 && (
						<CommonTable
							remote
							columns={tableColumns}
							keyField={this.primaryKey}
							data={dataTable}
							selectRow={this.getSelectRow()}
							defaultSorted={defaultSorted}
							rowClasses={theme.tableRow}
							headerClasses={theme.tableHeader}
							paginationOptions={this.getPaginationOptions()}
							onTableChange={this.handleOnChangeTable}
							isRendeTableFinish={() =>
								handleIsRendeTableFinish(this.firstRefs)
							}
						/>
					)}
				</Col>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ salesAffected, auth, vouchertype }) => {
	const { authUser } = auth
	const { cantValidate, productsImport } = salesAffected
	const { voucherTypeCancel } = vouchertype
	return { cantValidate, authUser, productsImport, voucherTypeCancel }
}

const connectForm = connect(mapStateToProps, {
	setTableDataInvolvement,
	salesAffectedSubCalculation,
	salesAffectedImportConfirm
})(VoucherImportTable)

export default themr(
	'InvolvementTableStyles',
	styles
)(withTranslation()(connectForm))
