/* eslint-disable no-unneeded-ternary */
import CommonTable from 'components/common/commonTable'
import InputText from 'components/form/inputText'
import React, { Component, Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'

interface ValuesTableProps extends WithTranslation {
	tableFields: any
	fields: any
	disableForm: boolean
	t?: any
	disableClick?: boolean
	sustainsClick?: boolean
	selected: Array<string | number>
	setSelection: any
	label: string
	doDoubleClick?: any
	hasChecks?: boolean
	keyfield: string
	check?: boolean
	buttons?: any
	checkBox?: boolean
	paginationOption?: any
	handleChangeTable?: any
	handleSelectAll?: any
	height?: string | number
	hideScrollX?: boolean
	hideScrollY?: boolean
	editValidation?: boolean
	nonSelectableRows?: Array<number>
	validationFunction?: (row: any, cell: any) => boolean
}

type ValuesTableState = {
	selectRow: any
	mouseClicked: boolean
	label: string
}

class TableWithSelection extends Component<ValuesTableProps, ValuesTableState> {
	constructor(props: ValuesTableProps) {
		super(props)
		this.state = {
			selectRow: 0,
			mouseClicked: false,
			label: this.props.label
		}
	}

	/**
	 * to handle click on table items
	 * @param params
	 */
	handleClick = (...params: any) => {
		const { setSelection, selected, label, keyfield } = this.props
		const key = params[1][keyfield]
		const itemIndex = _.indexOf(selected, key)

		params[0].target.tagName === 'TD'
			? itemIndex !== -1
				? setSelection(key, label, 'delete')
				: setSelection(key, label, 'add')
			: itemIndex === -1 && setSelection(key, label, 'add')
	}

	/**
	 * to handle click on checks
	 * @param row
	 * @param isSelect
	 */

	handleOnSelect = (row: any, isSelect: any) => {
		const { setSelection, label, keyfield } = this.props

		if (isSelect) {
			setSelection(row[keyfield], label, 'add')
		} else {
			setSelection(row[keyfield], label, 'delete')
		}
	}

	/**
	 * to handle click on main check
	 * @param isSelect
	 */

	handleOnSelectAll = (isSelect: any, rows: any) => {
		const { handleSelectAll } = this.props
		if (isSelect) {
			handleSelectAll('add')
		} else {
			handleSelectAll('delete')
		}
	}

	/**
	 * to handle double click on table items
	 * @param params
	 */
	handleDoubleClick = (...params: any) => {
		const { setSelection, label, doDoubleClick, keyfield } = this.props
		const key = params[1][keyfield]
		setSelection(key, label, 'delete')
		doDoubleClick(label, key)
	}

	/**
	 * to handle mouse enter event on table fields
	 * @param params
	 */
	handleOnMouseEnter = (...params: any) => {
		const { sustainsClick, setSelection, selected, label, keyfield } =
			this.props
		const key = params[1][keyfield]
		if (sustainsClick) {
			const itemIndex = _.indexOf(selected, key)

			itemIndex === -1 && setSelection(key, label, 'add')
		}
	}

	/**
	 * to get table columns
	 */

	getColumns = () => {
		const { tableFields, buttons, editValidation, validationFunction } =
			this.props

		const width = Math.floor(
			100 / (tableFields.length + (buttons && buttons.length / 8))
		)
		const columns = tableFields.map((field: any) => {
			const fieldId = field.idCampo.trim()

			return {
				dataField: fieldId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: `${width}%` },
				hidden: !field.visible,
				formatExtraData: editValidation,
				formatter: (
					cell: any,
					row: any,
					rowIndex: any,
					formatExtraData: any
				) => {
					const disable = validationFunction
						? validationFunction(row, cell)
						: false
					return this.renderFormat(field, cell, row, disable)
				}
			}
		})

		buttons &&
			buttons.map((button: any) => {
				return columns.push({
					dataField: button.label,
					text: '',
					align: 'center',
					headerAlign: 'center',
					headerStyle: { width: `${width / 8}%` },
					formatter: (cell: any, row: any, rowIndex: any) => {
						return <Fragment>{button.component}</Fragment>
					},
					headerFormatter: (cell: any, row: any, rowIndex: any) => {
						return <Fragment>{button.component}</Fragment>
					}
				})
			})

		return columns
	}

	renderFormat = (field: any, value: any, row: any, disable: boolean) => {
		const { keyfield, handleChangeTable } = this.props
		const fieldId = field.idCampo.trim()
		let result = null

		const optionsInput = {
			inputFormCol: { sm: 12 },
			fields: [{ ...field, label: false }],
			label: false,
			inputId: `${fieldId}`,
			id: `${fieldId}-${row[keyfield]}`,
			name: `${fieldId}-${row[keyfield]}`,
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			styles: {
				textAlign: 'center'
			},
			type: 'number',
			divStyle: { paddingLeft: '4px', paddingRight: '10px' },
			disable: false,
			value: value,
			rowStyle: { marginBottom: '1px' },
			onChange: (value: string, event: any) => {
				handleChangeTable &&
					handleChangeTable('input', 0, {
						fieldId,
						rowId: row[keyfield],
						value
					})
			}
		}

		if (field.editable && !disable) {
			result = <InputText {...optionsInput} />
		} else {
			result = value
		}

		return result
	}

	render() {
		const {
			fields,
			selected,
			disableForm,
			keyfield,
			checkBox,
			paginationOption,
			handleChangeTable,
			doDoubleClick,
			handleSelectAll,
			height,
			hideScrollX,
			hideScrollY,
			disableClick,
			nonSelectableRows
		} = this.props

		const tableColumns = this.getColumns()

		const options = paginationOption
			? {
					page: paginationOption.page_number,
					sizePerPage: paginationOption.page_size,
					totalSize: paginationOption.total_count
			  }
			: {}

		const selectRow = {
			mode: 'checkbox',
			selected: selected,
			bgColor: 'lightgray',
			onSelect: this.handleOnSelect,
			onSelectAll: handleSelectAll && this.handleOnSelectAll,
			hideSelectAll: !handleSelectAll || !checkBox,
			hideSelectColumn: !checkBox,
			nonSelectable: nonSelectableRows || []
		}

		const propsTable = {
			remote: true,
			columns: tableColumns,
			keyField: keyfield,
			data: fields ? fields : [],
			pagination: paginationOption ? true : false,
			paginationOptions: paginationOption ? options : false,
			selectRow: selectRow,
			hover: true,
			rowEvents: !disableForm && {
				onClick: !disableClick ? this.handleClick : () => {},
				onDoubleClick: doDoubleClick ? this.handleDoubleClick : () => {},
				onMouseEnter: this.handleOnMouseEnter
			},
			onTableChange: handleChangeTable ? handleChangeTable : () => {}
		}

		return (
			<div
				style={{
					height: height
						? typeof height === 'number'
							? `${height}px`
							: height
						: '500px',
					overflowY: `${hideScrollY ? 'hidden' : 'scroll'}`,
					overflowX: `${hideScrollX ? 'hidden' : 'scroll'}`
				}}
			>
				<CommonTable {...propsTable} />
			</div>
		)
	}
}

const mapStateToProps = () => {
	return {}
}

const connectForm = connect(
	mapStateToProps,
	{}
)(withTranslation()(TableWithSelection))
export default connectForm
