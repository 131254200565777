import { ICrudGenericFields } from 'models/CrudInterface'

export const productBundlesFields: Array<ICrudGenericFields> = [
	{
		id: 'codbarra',
		type: 'text',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'nipresent',
		type: 'text',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_pres',
		type: 'text',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'descrip',
		type: 'text',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cont_un',
		type: 'text',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cont_pr',
		type: 'text',
		width: 'col-sm-6',
		height: '',
		default: 1,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'hab_vta',
		type: 'check',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'hab_cpa',
		type: 'check',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'pc_descrec',
		type: 'text',
		width: 'col-sm-6',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
