import {
	applyProductStockProvider,
	applyProductStockProviderError,
	applyProductStockProviderSuccess,
	cancelProductStockProviderError,
	cancelProductStockProviderSuccess,
	confirmProductStockProviderError,
	confirmProductStockProviderSuccess,
	getProductStockProviderSuccess,
	loadProductStockProviderError,
	loadProductStockProviderSuccess,
	searchBarcodeProviderError,
	searchBarcodeProviderSuccess,
	validateProductStockProviderError,
	validateProductStockProviderSuccess
} from 'actions'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
	applyProductStockProviderApi,
	cancelProductStockProvider,
	confirmProductStockProvider,
	getProductStockProvider,
	loadProductStockProvider,
	searchBarcodeProvider,
	validateProductStockProvider
} from '../api/StockProvider'
import {
	APPLY_PRODUCT_STOCK_PROVIDER,
	CANCEL_PRODUCT_STOCK_ERROR,
	CANCEL_PRODUCT_STOCK_PROVIDER,
	CONFIRM_PRODUCT_STOCK_PROVIDER,
	CONFIRM_PRODUCT_STOCK_PROVIDER_ERROR,
	GET_PRODUCT_STOCK_PROVIDER,
	LOAD_PRODUCT_STOCK_PROVIDER,
	SEARCH_BARCODE_PROVIDER,
	SEARCH_BARCODE_PROVIDER_ERROR,
	VALIDATE_PRODUCT_STOCK_PROVIDER
} from '../constants/ActionsTypes'

function* getProductStockProviderRequest({ payload }) {
	try {
		const products = yield call(getProductStockProvider, payload)
		yield put(getProductStockProviderSuccess(products))
	} catch (error) {}
}

function* validateProductStockProviderRequest({ payload }) {
	const {
		idOperacion,
		it,
		nserie,
		cant,
		lote,
		fec_vto,
		cuf,
		ultimo_cuf_ingreso
	} = payload
	try {
		const validate = yield call(validateProductStockProvider, {
			idOperacion,
			it,
			nserie,
			cant
		})
		if (validate.Resultado.Resultado) {
			const params = {
				idOperacion,
				it,
				nserie,
				cant,
				lote,
				fec_vto,
				cuf,
				ultimo_cuf_ingreso
			}
			if (!ultimo_cuf_ingreso) {
				params.cuf = cuf
			}
			yield put(applyProductStockProvider(params))
		}
		yield put(validateProductStockProviderSuccess(validate))
	} catch (error) {
		yield put(validateProductStockProviderError(error.response.data))
	}
}

function* confirmProductStockProviderRequest({ payload }) {
	try {
		const confirm = yield call(confirmProductStockProvider, payload)
		yield put(confirmProductStockProviderSuccess(confirm))
	} catch (error) {
		const { Resultado } = error.response.data
		const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
		yield put(
			confirmProductStockProviderError({
				error: CONFIRM_PRODUCT_STOCK_PROVIDER_ERROR,
				errorMessage
			})
		)
	}
}

function* cancelProductStockProviderRequest({ payload }) {
	try {
		const canceled = yield call(cancelProductStockProvider, payload)
		yield put(cancelProductStockProviderSuccess(canceled))
	} catch (error) {
		const { Resultado } = error.response.data
		const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
		yield put(
			cancelProductStockProviderError({
				error: CANCEL_PRODUCT_STOCK_ERROR,
				errorMessage
			})
		)
	}
}

function* searchBarCodeProviderRequest({ payload }) {
	try {
		const barcode = yield call(searchBarcodeProvider, payload)
		yield put(searchBarcodeProviderSuccess(barcode))
	} catch (error) {
		const { data } = error.response
		const errorMessage = !data.Resultado.Resultado
			? data.Resultado.Mens_error
			: ''
		yield put(
			searchBarcodeProviderError({
				error: SEARCH_BARCODE_PROVIDER_ERROR,
				errorMessage,
				data
			})
		)
	}
}

function* applyProductStockProviderRequest({ payload }) {
	try {
		const apply = yield call(applyProductStockProviderApi, payload)
		yield put(applyProductStockProviderSuccess(apply))
	} catch (error) {
		yield put(applyProductStockProviderError(error.response.data))
	}
}

function* loadProductStockProviderRequest({ payload }) {
	try {
		const loadResponse = yield call(loadProductStockProvider, payload)
		yield put(loadProductStockProviderSuccess(loadResponse))
	} catch (error) {
		const { data } = error.response
		yield put(loadProductStockProviderError(data))
	}
}

export function* getProductStockProviderSaga() {
	yield takeLatest(GET_PRODUCT_STOCK_PROVIDER, getProductStockProviderRequest)
}

export function* validateProductStockProviderSaga() {
	yield takeLatest(
		VALIDATE_PRODUCT_STOCK_PROVIDER,
		validateProductStockProviderRequest
	)
}

export function* confirmProductStockProviderSaga() {
	yield takeLatest(
		CONFIRM_PRODUCT_STOCK_PROVIDER,
		confirmProductStockProviderRequest
	)
}

export function* cancelProductStockProviderSaga() {
	yield takeLatest(
		CANCEL_PRODUCT_STOCK_PROVIDER,
		cancelProductStockProviderRequest
	)
}

export function* searchBarCodeProviderSaga() {
	yield takeLatest(SEARCH_BARCODE_PROVIDER, searchBarCodeProviderRequest)
}

export function* applyProductStockProviderSaga() {
	yield takeLatest(
		APPLY_PRODUCT_STOCK_PROVIDER,
		applyProductStockProviderRequest
	)
}

export function* loadProductStockProviderSaga() {
	yield takeLatest(LOAD_PRODUCT_STOCK_PROVIDER, loadProductStockProviderRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getProductStockProviderSaga),
		fork(validateProductStockProviderSaga),
		fork(applyProductStockProviderSaga),
		fork(confirmProductStockProviderSaga),
		fork(cancelProductStockProviderSaga),
		fork(searchBarCodeProviderSaga),
		fork(loadProductStockProviderSaga)
	])
}
