import Axios from 'axios'
import {
	ICrudGetCostCenterAccounts,
	ICrudUpdateCostCenterAccounts
} from 'models/CrudCostCentersInterface'
const urlCRUD = '/abm_cont_cc'

export const crudGetCostCenterAccounts = async (
	params: ICrudGetCostCenterAccounts
) => {
	const response = await Axios.get(`${urlCRUD}/cuentas/sel`, { params })
	return response.data
}

export const crudUpdateCostCenterAccounts = async (
	params: ICrudUpdateCostCenterAccounts
) => {
	const response = await Axios.post(`${urlCRUD}/cuentas/confirmar`, params)
	return response.data
}
