import NotificationError from 'components/common/notificationsErrors'
import { API_FORMAT_DATE } from 'constants/ApiFormats'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { Formik } from 'formik'
import { getInitValues } from 'lib/FieldValidations'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import HeaderFiltersBudget from './HeaderFiltersBudget'

interface IFiltersFormProps extends WithTranslation {
	fields: Array<IFieldStructure>
	t?: any
	handleFilterSearch: (filters: any) => void
	clearFilters: () => void
	idOperacion?: any
	initialState: any
}

class HeaderFilterForms extends Component<IFiltersFormProps> {
	/**
	 * to get data of sellers
	 */

	render() {
		const { fields, t, idOperacion, initialState } = this.props

		const initialValues = fields.length
			? getInitValues(fields, initialState)
			: {}
		const validationSchema = {}

		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values: any, actions) => {
					const fec_desde =
						values.fec_desde !== ''
							? moment(values.fec_desde).format(API_FORMAT_DATE)
							: ''

					const fec_hasta =
						values.fec_hasta !== ''
							? moment(values.fec_hasta).format(API_FORMAT_DATE)
							: ''

					this.props.handleFilterSearch({ ...values, fec_desde, fec_hasta })
				}}
				validationSchema={validationSchema}
				enableReinitialize={true}
				render={(props) => {
					const {
						values,
						handleChange,
						handleBlur,
						setFieldValue,
						setFieldTouched
					} = props

					return (
						<Fragment>
							<Col sm={12}>
								<NotificationError
									errors={props.errors}
									touched={props.touched}
								/>
							</Col>
							<Form
								onSubmit={(e) => props.handleSubmit(e as any)}
								className="voucher-info-form"
							>
								<HeaderFiltersBudget
									{...{
										values,
										handleChange,
										handleBlur,
										setFieldValue,
										setFieldTouched,
										fields,
										idOperacion
									}}
								/>

								<Row className={'pb-2 pr-3'}>
									<Col
										sm={{ span: 3, offset: 7 }}
										className={'text-right mt-2'}
									>
										<Button style={{ minWidth: '125px' }} type={'submit'}>
											{t('global.filter')}
										</Button>
									</Col>
									<Col sm={{ span: 1 }} className={'text-right mt-2'}>
										<Button
											style={{ minWidth: '105px' }}
											type={'button'}
											onClick={() => {
												this.props.clearFilters()
												props.resetForm()
											}}
										>
											{'Limpiar'}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					)
				}}
			/>
		)
	}
}

export default withTranslation()(HeaderFilterForms)
