import {
	crudGetLAAssignationsSuccess,
	crudUpdateLAAssignationsSuccess
} from 'actions'
import {
	crudGetLAAssignations,
	crudUpdateLAAssignations
} from 'api/DirectLAAssignationApi'
import {
	CRUD_GET_DIRECT_LA_ASSIGNATIONS,
	CRUD_UPDATE_DIRECT_LA_ASSIGNATIONS
} from 'constants/ActionsTypes'
import {
	ICrudGetLAAssignationsSaga,
	ICrudUpdateLAAssignationsSaga
} from 'models/CrudDirectLAAssignationInterface'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

function* crudGetLAAssignationsReq({
	payload
}: ICrudGetLAAssignationsSaga): any {
	try {
		const assignations: any = yield call(crudGetLAAssignations, payload)
		yield put(crudGetLAAssignationsSuccess(assignations))
	} catch (error) {}
}

function* crudUpdateLAAssignationsReq({
	payload
}: ICrudUpdateLAAssignationsSaga): any {
	try {
		const assignations: any = yield call(crudUpdateLAAssignations, payload)
		yield put(crudUpdateLAAssignationsSuccess(assignations))
	} catch (error) {}
}

export function* crudGetLAAssignationsSaga() {
	yield takeLatest(CRUD_GET_DIRECT_LA_ASSIGNATIONS, crudGetLAAssignationsReq)
}

export function* crudUpdateLAAssignationsSaga() {
	yield takeLatest(
		CRUD_UPDATE_DIRECT_LA_ASSIGNATIONS,
		crudUpdateLAAssignationsReq
	)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetLAAssignationsSaga),
		fork(crudUpdateLAAssignationsSaga)
	])
}
