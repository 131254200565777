import {
	crudClearValuesStore,
	crudCreateValue,
	crudDeleteValue,
	crudGetValue,
	crudGetValuesList
} from 'actions'
import ComponentUpdateSegment from 'components/abmUtils/ComponentUpdateSegment'
import CrudTableAddButton from 'components/abmUtils/CrudTableAddButton'
import CrudTableButtons from 'components/abmUtils/CrudTableButtons'
import CommonModal from 'components/common/commonModal'
import CommonTable from 'components/common/commonTable'
import NotificationMessage from 'components/common/notificationMessage'
import { ICrudPageOptions, ICrudsApi } from 'models/CrudInterface'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import FieldsSearchForm from './FieldsSearchForm'

const excludeFields = ['modif', 'baja']

interface FieldsTableProps extends WithTranslation {
	catributo: string | number
	urlApi: string
	fieldsAlta: any
	fields: any
	fieldsSearch: any
	tabId: string
	valueId: string
	crudId: string
	Component: any
	valueRemoved: any
	valueUpdated: any
	valuesList: any
	valueSelected: any
	crudGetValuesList: (params: ICrudsApi) => void
	crudCreateValue: (params: ICrudsApi) => void
	crudDeleteValue: (params: ICrudsApi) => void
	crudGetValue: (params: ICrudsApi) => void
	crudClearValuesStore: () => void
	disableForm: boolean
	t: any
}

interface FieldsTableState {
	showEditPopup: boolean
	disablePopup: boolean
	alta: number
	errorTitle: string
	errorMessage: string
	typeNotification: string
	showError: boolean
	selectRow?: any
	titleModal: string
	selectedProcess: number
}

class FieldsTable extends Component<FieldsTableProps, FieldsTableState> {
	constructor(props: FieldsTableProps) {
		super(props)
		this.state = {
			showEditPopup: false,
			disablePopup: true,
			alta: 0,
			errorTitle: '',
			errorMessage: '',
			typeNotification: 'success',
			titleModal: '',
			showError: false,
			selectedProcess: 0
		}
	}

	componentDidUpdate = (
		prevProps: FieldsTableProps,
		prevState: FieldsTableState
	) => {
		const {
			valueUpdated,
			catributo,
			valueRemoved,
			urlApi,
			crudGetValuesList,
			t,
			crudId
		} = this.props
		const { alta, selectedProcess } = this.state

		if (prevProps.valueUpdated !== valueUpdated && valueUpdated) {
			crudGetValuesList({
				crudName: crudId,
				urlApi: urlApi,
				action: 'buscar',
				params: {
					page_number: 1,
					page_size: 10,
					nioperacion: catributo,
					niproceso: selectedProcess
				}
			})
			this.handleCloseEditPopup()
			this.setState({
				errorMessage: alta
					? t('values.success_create')
					: t('values.success_update'),
				showError: true
			})
		}

		if (prevProps.valueRemoved !== valueRemoved && valueRemoved) {
			this.setState({
				errorMessage: t('values.success_remove'),
				showError: true
			})
			crudGetValuesList({
				crudName: crudId,
				urlApi: urlApi,
				action: 'buscar',
				params: {
					page_number: 1,
					page_size: 10,
					nioperacion: catributo,
					niproceso: selectedProcess
				}
			})
		}

		if (selectedProcess !== prevState.selectedProcess) {
			crudGetValuesList({
				crudName: crudId,
				urlApi: 'abm_comprob_campos',
				action: 'buscar',
				params: {
					page_number: 1,
					page_size: 10,
					nioperacion: catributo,
					niproceso: selectedProcess
				}
			})
		}
	}

	/**
	 * to get columns table.
	 * @returns object
	 */
	getColumns = () => {
		const { fields, t, disableForm } = this.props
		const rows = fields.map((field: any) => {
			const fieldId = field.idCampo.trim()
			return {
				dataField: fieldId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '10%' },
				hidden: !field.visible || excludeFields.includes(fieldId)
			}
		})

		rows.push({
			dataField: 'actions',
			text: '',
			align: 'center',
			headerAlign: 'center',
			headerStyle: { width: '5%' },
			hidden: false,
			formatter: (value: any, row: any) => (
				<CrudTableButtons
					view={this.handleViewValue}
					edit={this.handleEditValue}
					delete={this.handleRemoveValue}
					row={row}
					deleteMessage={t('CRUDS.generic.delete.body')}
					deleteAction={this.handleRemoveValue}
					deleteTitle={t('CRUDS.generic.delete.title') || t('global.delete')}
					disableForm={disableForm}
				/>
			)
		})

		return rows
	}

	/**
	 * to close popup
	 */
	handleCloseEditPopup = () => {
		this.setState({ showEditPopup: false })
	}

	/**
	 * to save or update data modal
	 */
	handleSubmitPopup = (values: any) => {
		const { alta, selectedProcess } = this.state
		const { crudCreateValue, urlApi, catributo, crudId } = this.props

		crudCreateValue({
			crudName: crudId,
			urlApi: urlApi,
			action: 'confirmar',
			params: {
				...values,
				nioperacion: catributo,
				niproceso: selectedProcess,
				alta: alta
			}
		})
	}

	handleRemoveValue = (selectedValue: any) => {
		const { catributo, crudDeleteValue, urlApi, tabId, valueId, crudId } =
			this.props
		crudDeleteValue({
			crudName: crudId,
			urlApi: urlApi,
			action: 'eliminar',
			params: {
				id: tabId,
				param: catributo,
				value: valueId,
				valueParam: selectedValue[valueId]
			},
			addParams: `&niproceso=${selectedValue.niproceso}&grupo=${selectedValue.grupo}`
		})
	}

	/**
	 * to show value data info.
	 * @param {*} row
	 */
	handleViewValue = (row: any) => {
		const { urlApi, crudGetValue, crudId } = this.props
		crudGetValue({
			crudName: crudId,
			urlApi: urlApi,
			action: 'consulta',
			params: {
				nioperacion: row.nioperacion,
				niproceso: row.niproceso,
				grupo: row.grupo,
				campo: row.campo
			}
		})

		this.setState({
			showEditPopup: true,
			disablePopup: true,
			selectRow: row,
			titleModal: 'Consultando'
		})
	}

	/**
	 * to update value
	 * @param {object} row
	 */
	handleEditValue = (row: any) => {
		const { urlApi, crudGetValue, crudId } = this.props
		crudGetValue({
			crudName: crudId,
			urlApi: urlApi,
			action: 'consulta',
			params: {
				nioperacion: row.nioperacion,
				niproceso: row.niproceso,
				campo: row.campo,
				grupo: row.grupo
			}
		})
		this.setState({
			showEditPopup: true,
			disablePopup: false,
			selectRow: row,
			titleModal: 'Editando',
			alta: 0
		})
	}

	handleAddValue = () => {
		this.props.crudClearValuesStore()
		this.setState({
			showEditPopup: true,
			disablePopup: false,
			selectRow: null,
			titleModal: 'Creando',
			alta: 1
		})
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	/**
	 * to handle change table
	 */
	handleChangeTable = (type: string, pagination: ICrudPageOptions) => {
		const { catributo, crudGetValuesList, urlApi, crudId } = this.props
		const { selectedProcess } = this.state
		if (type === 'pagination') {
			crudGetValuesList({
				crudName: crudId,
				urlApi: urlApi,
				action: 'buscar',
				params: {
					page_number: pagination.page,
					page_size: pagination.sizePerPage,
					nioperacion: catributo,
					niproceso: selectedProcess
				}
			})
		}
	}

	setSelectedProcess = (value: number) => {
		this.setState({ selectedProcess: value })
	}

	render() {
		const {
			fields,
			fieldsAlta,
			fieldsSearch,
			valuesList,
			valueSelected,
			disableForm,
			Component,
			catributo,
			tabId,
			t
		} = this.props
		const {
			showEditPopup,
			disablePopup,
			selectRow,
			titleModal,
			typeNotification,
			errorTitle,
			errorMessage,
			showError,
			alta,
			selectedProcess
		} = this.state

		const tableColumns = fields ? this.getColumns() : []

		valuesList &&
			!_.isEmpty(valuesList.lista) &&
			(valuesList.lista[0].label_descrip =
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ut turpis vel ex pretium semper sed.')

		const propsTable = {
			remote: true,
			columns: tableColumns,
			keyField: 'nro',
			data: valuesList ? valuesList.lista : [],
			paginationOptions: valuesList
				? {
						pageStartIndex: 1,
						sizePerPage: valuesList.page_size,
						page: valuesList.page_number,
						totalSize: valuesList.total_count
				  }
				: {},
			onTableChange: this.handleChangeTable
		}

		const propsEdit = {
			[tabId]: catributo,
			alta: alta,
			fields: fieldsAlta,
			niproceso: selectedProcess,
			itemInfo: valueSelected || selectRow,
			disableForm: disablePopup,
			handleSubmit: this.handleSubmitPopup,
			handleCloseModal: this.handleCloseEditPopup
		}

		const propsEditModal = {
			showModal: showEditPopup,
			handleCloseModal: this.handleCloseEditPopup,
			modalTitle: titleModal,
			modalBody: Component ? (
				<Component {...propsEdit} />
			) : (
				<ComponentUpdateSegment {...propsEdit} />
			),
			buttons: false,
			handleSubmit: this.handleSubmitPopup,
			sizeModal: 'xl'
		}

		const searchProps = {
			nioperacion: catributo,
			disableForm: disableForm,
			fields: fieldsSearch,
			setProcess: this.setSelectedProcess
		}

		return (
			<Fragment>
				<Col sm={12} className={'mb-1'}>
					<NotificationMessage
						errorTitle={errorTitle}
						errorMessage={errorMessage}
						showError={showError}
						handleCloseError={this.handleCloseError}
						type={typeNotification}
					/>
				</Col>
				<FieldsSearchForm {...searchProps} />
				{!disableForm && (
					<CrudTableAddButton
						clickAction={this.handleAddValue}
						title={t('CRUDS.general_use.values_tab')}
					/>
				)}
				<Row>
					<CommonTable {...propsTable} />
					<CommonModal {...propsEditModal} />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ crudValues }: any) => {
	const { valuesList, valueSelected, valueUpdated, valueRemoved } = crudValues
	return { valuesList, valueSelected, valueUpdated, valueRemoved }
}

const connectForm = connect(mapStateToProps, {
	crudGetValuesList,
	crudGetValue,
	crudDeleteValue,
	crudCreateValue,
	crudClearValuesStore
})(FieldsTable)

export default withTranslation()(withRouter(connectForm))
