import Axios from 'axios'
import {
	ICrudGetLedgerAccountCenters,
	ICrudUpdateLedgerAccountCenters
} from 'models/CrudLedgerAccountsInterface'
const urlCRUD = '/abm_cont_cuentas'

export const crudGetLedgerAccountCostCenter = async (
	params: ICrudGetLedgerAccountCenters
) => {
	const response = await Axios.get(`${urlCRUD}/cc/sel`, { params })
	return response.data
}

export const crudUpdateLedgerAccountCostCenter = async (
	params: ICrudUpdateLedgerAccountCenters
) => {
	const response = await Axios.post(`${urlCRUD}/cc/confirmar`, params)
	return response.data
}
