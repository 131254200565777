import {
	crudCreateCustomerCode,
	crudGetCustomerCode,
	crudGetCustomerCodeConfig
} from 'api/CustomerCrudCodeApi'
import {
	ICrudCreateCustomerCodeSaga,
	ICrudGetCustomerCodeConfigSaga,
	ICrudGetCustomerCodeSaga
} from 'models/CrudCustomerInterface'
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
	crudCreateCustomerCodeSuccess,
	crudGetCustomerCodeConfigSuccess,
	crudGetCustomerCodeSuccess,
	crudValidateCustomerCodeSuccess
} from '../actions'
import {
	CRUD_CREATE_CUSTOMER_CODE,
	CRUD_GET_CUSTOMER_CODE,
	CRUD_GET_CUSTOMER_CODE_CONFIG,
	CRUD_VALIDATE_CUSTOMER_CODE
} from '../constants/ActionsTypes'

function* crudGetCustomerCodeReq({ payload }: ICrudGetCustomerCodeSaga): any {
	try {
		const customer: any = yield call(crudGetCustomerCode, payload)
		yield put(crudGetCustomerCodeSuccess(customer))
	} catch (error) {}
}

function* crudGetCustomerCodeConfigReq({
	payload
}: ICrudGetCustomerCodeConfigSaga): any {
	try {
		const customer: any = yield call(crudGetCustomerCodeConfig, payload)
		yield put(crudGetCustomerCodeConfigSuccess(customer))
	} catch (error) {}
}

function* crudCreateCustomerCodeReq({
	payload
}: ICrudCreateCustomerCodeSaga): any {
	try {
		const customer = yield call(crudCreateCustomerCode, payload)
		yield put(crudCreateCustomerCodeSuccess(customer))
	} catch (error) {}
}

function* crudValidateCustomerCodeReq({
	payload
}: ICrudCreateCustomerCodeSaga): any {
	try {
		const customer = yield call(crudCreateCustomerCode, payload)
		yield put(crudValidateCustomerCodeSuccess(customer))
	} catch (error) {}
}

export function* crudGetCustomerCodeSaga() {
	yield takeLatest(CRUD_GET_CUSTOMER_CODE, crudGetCustomerCodeReq)
}

export function* crudGetCustomerCodeConfigSaga() {
	yield takeLatest(CRUD_GET_CUSTOMER_CODE_CONFIG, crudGetCustomerCodeConfigReq)
}

export function* crudCreateCustomerCodeSaga() {
	yield takeEvery(CRUD_CREATE_CUSTOMER_CODE, crudCreateCustomerCodeReq)
}

export function* crudValidateCustomerCodeSaga() {
	yield takeEvery(CRUD_VALIDATE_CUSTOMER_CODE, crudValidateCustomerCodeReq)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetCustomerCodeSaga),
		fork(crudGetCustomerCodeConfigSaga),
		fork(crudCreateCustomerCodeSaga),
		fork(crudValidateCustomerCodeSaga)
	])
}
