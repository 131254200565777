import {
	PURCHASES_AFFECTED_CONFIRM,
	PURCHASES_AFFECTED_CONFIRM_SUCCESS,
	PURCHASES_AFFECTED_IMPORT,
	PURCHASES_AFFECTED_IMPORT_CONFIRM,
	PURCHASES_AFFECTED_IMPORT_CONFIRM_SUCCESS,
	PURCHASES_AFFECTED_IMPORT_SUCCESS,
	PURCHASES_AFFECTED_IMPORT_VALIDATE,
	PURCHASES_AFFECTED_IMPORT_VALIDATE_SUCCESS,
	PURCHASES_AFFECTED_QUANTITY,
	PURCHASES_AFFECTED_QUANTITY_SUCCESS,
	PURCHASES_AFFECTED_STATE,
	PURCHASES_AFFECTED_STATE_CONFIRM,
	PURCHASES_AFFECTED_STATE_CONFIRM_SUCCESS,
	PURCHASES_AFFECTED_STATE_SUCCESS,
	PURCHASES_AFFECTED_STATE_VALIDATE,
	PURCHASES_AFFECTED_STATE_VALIDATE_SUCCESS,
	PURCHASES_AFFECTED_SUB_CALCULATION,
	PURCHASES_AFFECTED_SUB_CALCULATION_SUCCESS,
	PURCHASES_AFFECTED_VALIDATE,
	PURCHASES_AFFECTED_VALIDATE_SUCCESS,
	PURCHASES_VALIDATE_DATE,
	PURCHASES_VALIDATE_DATE_SUCCESS
} from '../constants/ActionsTypes'

export const purchasesQuantityAffected = (params) => {
	return {
		type: PURCHASES_AFFECTED_QUANTITY,
		payload: params
	}
}

export const purchasesQuantityAffectedSuccess = (response) => {
	return {
		type: PURCHASES_AFFECTED_QUANTITY_SUCCESS,
		payload: response
	}
}

export const purchasesAffectedValidate = (params) => {
	return {
		type: PURCHASES_AFFECTED_VALIDATE,
		payload: params
	}
}

export const purchasesAffectedValidateSuccess = (response) => {
	return {
		type: PURCHASES_AFFECTED_VALIDATE_SUCCESS,
		payload: response
	}
}

export const purchasesAffectedSubCalculation = (params) => {
	return {
		type: PURCHASES_AFFECTED_SUB_CALCULATION,
		payload: params
	}
}

export const purchasesAffectedSubCalculationSuccess = (params) => {
	return {
		type: PURCHASES_AFFECTED_SUB_CALCULATION_SUCCESS,
		payload: params
	}
}

export const purchasesAffectedConfirm = (params) => {
	return {
		type: PURCHASES_AFFECTED_CONFIRM,
		payload: params
	}
}

export const purchasesAffectedConfirmSuccess = (response) => {
	return {
		type: PURCHASES_AFFECTED_CONFIRM_SUCCESS,
		payload: response
	}
}

export const purchasesAffectedImport = (params) => {
	return {
		type: PURCHASES_AFFECTED_IMPORT,
		payload: params
	}
}

export const purchasesAffectedImportSuccess = (response) => {
	return {
		type: PURCHASES_AFFECTED_IMPORT_SUCCESS,
		payload: response
	}
}

export const purchasesAffectedImpValidate = (params) => {
	return {
		type: PURCHASES_AFFECTED_IMPORT_VALIDATE,
		payload: params
	}
}

export const purchasesAffectedImpValidateSuccess = (response) => {
	return {
		type: PURCHASES_AFFECTED_IMPORT_VALIDATE_SUCCESS,
		payload: response
	}
}

export const purchasesValidateDate = (params) => {
	return {
		type: PURCHASES_VALIDATE_DATE,
		payload: params
	}
}

export const purchasesValidateDateSuccess = (response) => {
	return {
		type: PURCHASES_VALIDATE_DATE_SUCCESS,
		payload: response
	}
}

export const purchasesAffectedImportConfirm = (params) => {
	return {
		type: PURCHASES_AFFECTED_IMPORT_CONFIRM,
		payload: params
	}
}

export const purchasesAffectedImportConfirmSuccess = (response) => {
	return {
		type: PURCHASES_AFFECTED_IMPORT_CONFIRM_SUCCESS,
		payload: response
	}
}

export const purchasesAffectedState = (params) => {
	return {
		type: PURCHASES_AFFECTED_STATE,
		payload: params
	}
}

export const purchasesAffectedStateSuccess = (response) => {
	return {
		type: PURCHASES_AFFECTED_STATE_SUCCESS,
		payload: response
	}
}

export const purchasesAffectedStateValidate = (params) => {
	return {
		type: PURCHASES_AFFECTED_STATE_VALIDATE,
		payload: params
	}
}

export const purchasesAffectedStateValidateSuccess = (response) => {
	return {
		type: PURCHASES_AFFECTED_STATE_VALIDATE_SUCCESS,
		payload: response
	}
}

export const purchasesAffectedStateConfirm = (params) => {
	return {
		type: PURCHASES_AFFECTED_STATE_CONFIRM,
		payload: params
	}
}

export const purchasesAffectedStateConfirmSuccess = (response) => {
	return {
		type: PURCHASES_AFFECTED_STATE_CONFIRM_SUCCESS,
		payload: response
	}
}
