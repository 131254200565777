import CollectionForm from 'components/collection/collectionForm'
import GlobalContainer from 'components/layout/globalContainer'
import { P_LOAD_COLLECTION } from 'constants/ConfigProcessNames'
import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import withMenu from '../../components/common/withMenu'

/**
 * this is the cobranzas page
 *
 */
class Collection extends PureComponent {
	handleCallBackButton = () => {
		this.accountingConfirmation()
	}

	getShortcuts = () => {
		return [
			{
				hotkey: {
					charCode: '40',
					modifiers: ['arrowDown', 'arrowUp' /*, "arrowLeft", "arrowRight", */]
				},
				action: this.moveArrow,
				name: 'Arrow move',
				description: 'Arrow move'
			}
		]
	}

	/**
	 * to get params from target id of element
	 * @params event
	 */
	getRowParams = (event) => {
		const params = event.target.id.split('-')
		const rowId = params[1]
		const field = params[0]

		return { rowId, field }
	}

	/**
	 * function to call the moving.
	 * @params event
	 */
	moveArrow = async (e) => {
		if (e.target.id) {
			const { rowId, field } = this.getRowParams(e)
			this.handleMoveArrow({ rowId, field, key: e.code })
		}
	}

	render() {
		return (
			<Row>
				<GlobalContainer
					shortcuts={this.getShortcuts()}
					codeProcess={P_LOAD_COLLECTION}
					nextPage
					callBackButton={this.handleCallBackButton}
				>
					<CollectionForm
						formConfirmation={(click) => (this.accountingConfirmation = click)}
						handleMoveArrow={(click) => (this.handleMoveArrow = click)}
					/>
				</GlobalContainer>
			</Row>
		)
	}
}

export default withTranslation()(withRouter(withMenu(Collection)))
