import withMenu from 'components/common/withMenu'
import GlobalContainer from 'components/layout/globalContainer'
import LoadItemsIncomeInventory from 'components/LoadItemsIncomeInventory/LoadItemsIncomeInventory'
import { P_LOAD_ITEM_INCOME_INV } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'

interface LoadItemsIncomeInventoryProps {}

interface LoadItemsIncomeInventoryState {}

class LoadItemsIncomeInventoryPage extends Component<
	LoadItemsIncomeInventoryProps,
	LoadItemsIncomeInventoryState
> {
	loadItemIncomeTableRef: any = React.createRef()
	constructor(props: LoadItemsIncomeInventoryProps) {
		super(props)
		this.state = {}
	}

	getShortcuts = () => {
		const shortcuts = [
			{
				hotkey: {
					charCode: '40',
					modifiers: ['arrowDown', 'arrowUp' /*, "arrowLeft", "arrowRight", */]
				},
				action: this.moveArrow,
				name: 'Arrow move',
				description: 'Arrow move'
			},
			{
				hotkey: { charCode: '65', modifiers: ['insert'] },
				action: this.addToCart,
				name: 'Add Product',
				description: 'Add product to car'
			}
		]

		return shortcuts
	}

	addToCart = async (
		event: Event & {
			target: HTMLInputElement
		}
	) => {
		if (event.target.id) {
			const { rowId, field } = this.getRowParams(event)
			this.loadItemIncomeTableRef.current.preventAddToCart(rowId, field)
		}
	}

	/**
	 * to move focus to up or down arrow
	 * @param e
	 */
	moveArrow = async (
		event: Event & {
			target: HTMLInputElement
			code: string
		}
	) => {
		const { target } = event

		if (target.id) {
			const { rowId, field } = this.getRowParams(event)
			this.loadItemIncomeTableRef.current.handleMoveArrow({
				rowId,
				field,
				key: event.code
			})
		}
	}

	/**
	 * get data from target id
	 * @param event
	 * @returns
	 */
	getRowParams = (
		event: Event & {
			target: HTMLInputElement
		}
	) => {
		const params = event.target.id.split('-')
		const rowId = parseInt(params[1])
		const field = params[0]

		return { rowId, field }
	}

	handleCallBackButton = () => {
		this.loadItemIncomeTableRef.current.handleItemsConfirm()
	}

	render() {
		const containerProps = {
			codeProcess: P_LOAD_ITEM_INCOME_INV,
			nextPage: true,
			shortcuts: this.getShortcuts(),
			callBackButton: this.handleCallBackButton
		}

		return (
			<Row>
				<GlobalContainer {...containerProps}>
					<LoadItemsIncomeInventory tableRef={this.loadItemIncomeTableRef} />
				</GlobalContainer>
			</Row>
		)
	}
}

export default withMenu(LoadItemsIncomeInventoryPage)
