import CommonTable from 'components/common/commonTable'
import NotificationMessage from 'components/common/notificationMessage'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { P_LOAD_COLLECTION } from 'constants/ConfigProcessNames'
import { validateField } from 'lib/FieldValidations'
import { getSelectFilter, getValueMask } from 'lib/MaskValues'
import { handleSetFocus } from 'lib/TableUtils'
import React, { PureComponent } from 'react'
import { Col } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import { getSelDocuments, validateSelDocuments } from '../../actions'
import { handleIsRendeTableFinish } from '../../lib/TableUtils'
import styles from './collectionsTable.module.scss'

class CollectionSearchTable extends PureComponent {
	constructor(props) {
		super(props)
		this.inputRefs = {}
		this.state = {
			selectedCheck: [],
			rowSelected: [],
			showError: false,
			errorMessage: '',
			type: 'success',
			docSelected: null,
			updateDataTable: []
		}
		this.rowErrors = []
		this.primaryKey = 'iditem'
		this.firstRefs = null

		this.filtersKey = {
			fecha_cobro: 'filtro_fec_cobro',
			detalle: 'filtro_detalle',
			nro_movim: 'filtro_nro',
			cod_estado_orig: 'filtro_estado',
			importe: 'filtro_importe',
			chq_bco: 'filtro_banco'
		}

		this.tableColumns = [
			{
				idCampo: 'fecha_cobro',
				descripcion: 'Fecha de cobro',
				label: 'Fec. Cobro',
				editable: 0,
				visible: 1,
				requerido: '0',
				valid: null,
				mascara: 'FechaLarga'
			},
			{
				idCampo: 'detalle',
				descrip: '',
				label: 'Detalle',
				mascara: '',
				editable: 0,
				visible: 1,
				requerido: 0,
				valid: null
			},
			{
				idCampo: 'nro_movim',
				descrip: '',
				label: 'Nro. Mov.',
				mascara: '',
				editable: 0,
				visible: 1,
				requerido: 0,
				valid: null
			},
			{
				idCampo: 'chq_bco',
				descrip: '',
				label: 'Cheque Banco',
				mascara: '',
				editable: 0,
				visible: 1,
				requerido: 0,
				valid: null
			},
			{
				idCampo: 'chq_nro',
				descrip: '',
				label: 'Cheque Nro.',
				mascara: '',
				editable: 0,
				visible: 1,
				requerido: 0,
				valid: null
			},
			{
				idCampo: 'cod_mone',
				descrip: '',
				label: 'Mon.',
				mascara: '',
				editable: 0,
				visible: 1,
				requerido: 0,
				valid: null
			},
			{
				idCampo: 'importe',
				descripcion: 'Importe',
				label: 'Importe',
				editable: 0,
				visible: 1,
				requerido: '0',
				valid: null,
				mascara: 'NetoUsual'
			},
			{
				idCampo: 'cod_estado_orig',
				descrip: '',
				label: 'Estado',
				mascara: '',
				editable: 0,
				visible: 1,
				requerido: 0,
				valid: null
			},
			{
				idCampo: 'imp_afec',
				descrip: '',
				label: 'Imp. Afectado',
				mascara: 'NetoUsual',
				editable: 1,
				visible: 1,
				requerido: 0,
				valid: null
			},
			{
				idCampo: 'estado_dest',
				descrip: '',
				label: 'Est. Destino',
				mascara: '',
				editable: 1,
				visible: 1,
				requerido: 0,
				valid: null
			}
		]
	}

	componentDidMount = () => {
		const {
			idOperacion,
			orden_dato,
			orden_tipo,
			handleMoveArrow,
			getSelDocuments
		} = this.props
		handleMoveArrow(this.handleMoveArrow)
		getSelDocuments({
			idOperacion,
			page: 1,
			page_size: 10,
			orden_dato,
			orden_tipo
		})
		this.handleMoveArrow = this.handleMoveArrow.bind(this)
	}

	componentDidUpdate = (prevProps) => {
		const { t, selDocumentsValidate } = this.props
		if (
			prevProps.selDocumentsValidate !== selDocumentsValidate &&
			selDocumentsValidate
		) {
			this.setState({
				showError: true,
				errorMessage: t('Collection.message.success'),
				type: 'success',
				docSelected: null
			})
		}
	}

	/**
	 * api to move focus to next o last row.
	 * @params {rowId, field, key}
	 */
	handleMoveArrow = (params) => {
		const { rowId, field, key } = params
		const reverse = key === 'ArrowUp'
		const nextRow = this.getNextRow(rowId, reverse)
		handleSetFocus(field, nextRow, this.inputRefs)
	}

	_handleSetNextFocus = (rowId) => {
		const nextRow = this.getNextRow(rowId)
		if (nextRow) {
			const nextRef = this.inputRefs.imp_afec[nextRow]
			if (nextRef.current.element) {
				nextRef.current.element.focus()
			} else {
				nextRef.current.focus()
			}
		}
	}

	/**
	 * to get next row in the table
	 * @param idDocument
	 * @param reverse
	 */
	getNextRow = (idDocument, reverse = false) => {
		const { selDocuments } = this.props
		let result = 0
		selDocuments.Items.forEach((prd, index) => {
			if (prd[this.primaryKey] === idDocument) {
				result = !reverse ? index + 1 : index - 1 // Back o next row
			}
		})

		if (selDocuments.Items) {
			return selDocuments.Items[result]
				? selDocuments.Items[result][this.primaryKey]
				: null
		} else {
			return null
		}
	}

	/**
	 * To save and update data table to validate changes..
	 * @params id row
	 * @params fieldId
	 * @params value
	 */
	handleUpdateDataTable = (rowId, fieldId, value) => {
		const { updateDataTable } = this.state
		const updateIndex = _.findIndex(updateDataTable, { rowId })
		if (updateIndex === -1) {
			const params = { rowId }
			params[fieldId] = value
			updateDataTable.push(params)
		} else {
			updateDataTable[updateIndex][fieldId] = value
		}
		this.setState({ updateDataTable })
	}

	renderFormat = (field, value, row) => {
		let result = value
		const fieldId = field.idCampo.trim()
		const customValue = value === 'error_input' ? '' : value
		const inputError = value === 'error_input'

		if (field.editable && !this.inputRefs[fieldId]) {
			this.inputRefs[fieldId] = {}
		}

		if (field.editable && !this.inputRefs[fieldId][row[this.primaryKey]]) {
			const customRef = React.createRef()
			this.inputRefs[fieldId][row[this.primaryKey]] = customRef
			if (this.firstRefs === null) {
				this.firstRefs = customRef
			}
		}

		const optionsInput = {
			fwRef: field.editable
				? this.inputRefs[fieldId][row[this.primaryKey]]
				: null,
			inputFormCol: { sm: 12 },
			fields: [{ ...field, label: false }],
			label: false,
			inputId: `${fieldId}`,
			id: `${fieldId}-${row[this.primaryKey]}`,
			name: `${fieldId}-${row[this.primaryKey]}`,
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			divStyle: { paddingLeft: '17px' },
			disable: false,
			value: customValue,
			showError: inputError,
			styles: { textAlign: 'right' },
			rowStyle: { marginBottom: '5px' }
		}

		if (field.editable) {
			if (fieldId === 'estado_dest') {
				const optionsState =
					value && value.length
						? value.map((state) => {
								return {
									id: state.cod_estado_dest,
									label: state.desc_estado_dest
								}
						  })
						: []

				result = (
					<InputDropdown
						{...optionsInput}
						options={optionsState}
						value={row.estado_afec_selected}
						onChange={(obj) => this.handleChangeSelect(obj, row)}
					/>
				)
			} else if (fieldId === 'imp_afec') {
				result = (
					<InputText
						{...optionsInput}
						onChange={(val) => {
							row.imp_afec = val // Se adiciona el valor a la Fila
							this.handleUpdateDataTable(row[this.primaryKey], fieldId, true) // Set flag when change input valur
						}}
						handleEnterKey={(e, value) => {
							this._handleSetNextFocus(row[this.primaryKey])
						}}
						onBlur={(value) => {
							this.validateAfectImport(row, field, value)
						}}
					/>
				)
			}
		} else if (field.mascara) {
			result = getValueMask(value, field.mascara, this.props)
		}

		return result
	}

	/**
	 * to validate data of inputs
	 * @params row
	 * @params field
	 * @params value
	 */
	validateAfectImport = (row, field, value) => {
		const { idOperacion, t } = this.props
		const newItem = {
			idItem: row[this.primaryKey],
			imp_afec: value,
			cod_estado_destino: row.cod_estado_destino,
			selec: 1
		}
		const selected = this.state.selectedCheck ? this.state.selectedCheck : []
		const rows = this.state.rowSelected ? this.state.rowSelected : []
		let saveItem = true
		const fieldId = field.idCampo.trim()

		if (this.isValueChange(row[this.primaryKey], fieldId, value)) {
			rows.forEach((toSave, index) => {
				if (toSave.idItem === row[this.primaryKey]) {
					saveItem = false
					toSave.imp_afec = value
				}
			})

			if (saveItem) {
				rows.push(newItem)
			}
			if (field.valid) {
				let message = ''
				if (!validateField(value, field.valid)) {
					message = t('validation-required', { field: field.label })
					this.setState({ showError: true, errorMessage: message })
				} else {
					selected.push(row[this.primaryKey])
					this.setState({ selectedCheck: selected, rowSelected: rows })
					this.props.validateSelDocuments({ idOperacion, Items: rows })
				}
			} else {
				if (parseFloat(value) === 0) {
					selected.forEach((delet, index) => {
						if (delet === row[this.primaryKey]) {
							selected.splice(index, 1)
						}
					})
				} else {
					selected.push(row[this.primaryKey])
				}

				this.setState({
					selectedCheck: selected,
					rowSelected: rows,
					docSelected: row[this.primaryKey]
				})
				this.props.validateSelDocuments({ idOperacion, Items: rows })
			}
		}
	}

	/**
	 * To validate if the field values changed
	 * @params id row
	 * @params fieldId
	 * @params value
	 */

	isValueChange = (rowId, fieldId, value) => {
		const { updateDataTable } = this.state

		if (value) {
			const updateIndex = _.findIndex(updateDataTable, { rowId })

			if (updateIndex === -1) {
				return false
			}

			const isChange = updateDataTable[updateIndex][fieldId]
				? updateDataTable[updateIndex][fieldId]
				: false

			if (isChange === null) {
				return true
			} else if (!isChange) {
				return false
			} else {
				this.handleUpdateDataTable(rowId, fieldId, false) // Set flag false after validation
				return true
			}
		}

		return false
	}

	handleChangeSelect = (obj, row) => {
		row.cod_estado_destino = obj.target.value
		if (obj.target.name) {
			// Solo se llama la función después de cargar
			this.handleSelectrow(row, true)
		}
	}

	/**
	 * when table use filter o paginate.
	 * @param {string} type
	 * @param {object} pagination
	 */
	handleChangeTable = (type, pagination) => {
		const { idOperacion, orden_dato, orden_tipo } = this.props
		this.firstRefs = null
		this.inputRefs = {}

		if (type === 'pagination') {
			this.props.getSelDocuments({
				idOperacion,
				page: pagination.page,
				page_size: pagination.sizePerPage,
				orden_dato,
				orden_tipo
			})
		}

		if (type === 'filter') {
			const filters = _.keys(pagination.filters)
			const queryFilters = {}
			filters.forEach((filter) => {
				queryFilters[this.filtersKey[filter]] =
					pagination.filters[filter].filterVal
			})
			this.props.getSelDocuments({
				idOperacion,
				page: pagination.page,
				page_size: pagination.sizePerPage,
				orden_dato,
				orden_tipo,
				...queryFilters
			})
		}
	}

	getColumns = () => {
		const { selDocuments, theme } = this.props
		const rows = this.tableColumns
			? this.tableColumns.map((field) => {
					const campoId = field.idCampo.trim()
					return {
						dataField: campoId,
						text: this.filtersKey[campoId] && selDocuments ? '' : field.label,
						align: 'center',
						headerAlign: 'center',
						headerStyle: this.getStyleColumn(field),
						hidden: !field.visible,
						filter: getSelectFilter(
							field,
							selDocuments,
							this.filtersKey,
							this.props,
							theme.inputFilter
						),
						formatter: (cell, row, rowIndex) => {
							return this.renderFormat(field, cell, row)
						}
					}
			  })
			: []

		return rows
	}

	getStyleColumn = (field) => {
		const idField = field.idCampo.trim()
		let style = {}

		switch (idField) {
			case 'imp_afec':
			case 'estado_dest':
				style = { width: '15%' }
				break
			case 'cod_mone':
				style = { width: '5%' }
				break
			case 'estado':
				style = { width: '8%' }
				break
			default:
				style = { width: '10%' }
				break
		}

		if (field.requerido === '1') {
			style.color = 'red'
		}

		return style
	}

	getRowsCantAfect = () => {
		const { selDocuments } = this.props

		const result = []
		if (selDocuments.Items) {
			selDocuments.Items.forEach((row) => {
				if (parseInt(row.imp_afec)) {
					result.push(row[this.primaryKey])
				}
			})
		}

		return result
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	handleUpdateArray = (array, keySearch, compare, updateKey, updateValue) => {
		let update = false

		array.forEach((element) => {
			if (element[keySearch] === compare) {
				element[updateKey] = updateValue
				update = true
			}
		})

		return update
	}

	handleSelectrow = (row, isSelect, rowIndex, e) => {
		const { idOperacion, selDocuments } = this.props
		const { selectedCheck, rowSelected } = this.state
		const selected = selectedCheck || []
		const rows = rowSelected || []

		let updateRecord = false

		this.setState({ docSelected: row[this.primaryKey] })

		if (isSelect) {
			// Se adiciona
			const cantSend = row.imp_afec ? row.imp_afec : row.importe
			this.handleUpdateArray(
				selDocuments.Items,
				this.primaryKey,
				row[this.primaryKey],
				'imp_afec',
				cantSend
			)
			updateRecord = this.handleUpdateArray(
				rows,
				'idItem',
				row[this.primaryKey],
				'imp_afec',
				cantSend
			)
			updateRecord = this.handleUpdateArray(
				rows,
				'idItem',
				row[this.primaryKey],
				'selec',
				1
			)

			if (!updateRecord) {
				// Nuevo
				rows.push({
					idItem: row[this.primaryKey],
					imp_afec: cantSend,
					cod_estado_destino: row.cod_estado_destino,
					selec: 1
				})
			}

			selected.push(row[this.primaryKey])
		} else {
			// Se resta
			this.handleUpdateArray(
				selDocuments.Items,
				this.primaryKey,
				row[this.primaryKey],
				'imp_afec',
				0
			)
			updateRecord = this.handleUpdateArray(
				rows,
				'idItem',
				row[this.primaryKey],
				'imp_afec',
				0
			)
			updateRecord = this.handleUpdateArray(
				rows,
				'idItem',
				row[this.primaryKey],
				'selec',
				0
			)

			if (!updateRecord) {
				// Nuevo
				rows.push({
					idItem: row[this.primaryKey],
					imp_afec: 0,
					cod_estado_destino: row.cod_estado_destino,
					selec: 0
				})
			}

			selected.forEach((delet, index) => {
				if (delet === row[this.primaryKey]) {
					selected.splice(index, 1)
				}
			})
		}

		this.setState({ selectedCheck: selected, rowSelected: rows })
		this.props.validateSelDocuments({ idOperacion, Items: rows })
	}

	handleSelectAllrow = (isSelect, rows, e) => {
		const { selDocuments, idOperacion } = this.props
		const { selectedCheck } = this.state
		let selected = []
		const checks = selectedCheck || []

		if (isSelect) {
			this.setState({ selectedCheck: null })

			rows.forEach((check) => {
				checks.push(check[this.primaryKey])
			})

			selected = rows.map((row) => {
				const cantSend = row.imp_afec ? row.imp_afec : row.importe
				return {
					idItem: row[this.primaryKey],
					imp_afec: cantSend,
					cod_estado_destino: row.cod_estado_destino,
					selec: 1
				}
			})

			selDocuments.Items.forEach((toDelete, index) => {
				const cantSend = toDelete.imp_afec
					? toDelete.imp_afec
					: toDelete.importe // Los valores que se muestran en pantalla
				toDelete.imp_afec = cantSend
			})
		} else {
			for (let index = 0; index < checks.length; index++) {
				const check = checks[index]
				rows.forEach((fila) => {
					if (check === fila[this.primaryKey]) {
						delete checks[index]
					}
				})
			}

			selDocuments.Items.forEach((toDelete, index) => {
				toDelete.imp_afec = 0
			})

			selected = rows.map((row) => {
				return {
					idItem: row[this.primaryKey],
					imp_afec: 0,
					cod_estado_destino: row.cod_estado_destino,
					selec: 0
				}
			})
		}

		this.props.validateSelDocuments({ idOperacion, Items: selected })
		this.setState({ selectedCheck: checks, rowSelected: selected })
	}

	/**
	 * to get all select options
	 */
	getSelectRow = () => {
		const { selDocuments, readOnly } = this.props
		const selected = selDocuments ? this.getRowsCantAfect() : []
		return {
			mode: 'checkbox',
			selectColumnPosition: 'right',
			selected: selected,
			hideSelectColumn: !!readOnly,
			style: (row) => {
				const backgroundColor = row.error ? '#f8d7da' : '#FFF'
				const marginBottom = 120
				return { backgroundColor, marginBottom }
			},
			onSelect: this.handleSelectrow,
			onSelectAll: this.handleSelectAllrow
		}
	}

	/**
	 * to get pagination options
	 */
	getPaginationOptions = () => {
		const { selDocuments } = this.props

		return selDocuments
			? {
					pageStartIndex: 1,
					sizePerPage: selDocuments.page_size,
					page: selDocuments.page_number,
					totalSize: selDocuments.total_count
			  }
			: {}
	}

	render() {
		const { theme, selDocuments } = this.props

		return (
			<div className={theme.scrollContainer}>
				<div className={`${theme.divContainer}`}>
					<Col sm={12} className={'mb-1'}>
						<NotificationMessage
							{...this.state}
							handleCloseError={this.handleCloseError}
						/>
					</Col>
					<Col className={`col-12 pl-0 pr-0`}>
						{
							<CommonTable
								remote
								columns={this.getColumns()}
								keyField={this.primaryKey}
								selectRow={this.getSelectRow()}
								data={selDocuments ? selDocuments.Items : []}
								rowClasses={theme.tableRow}
								headerClasses={theme.tableHeader}
								paginationOptions={this.getPaginationOptions()}
								onTableChange={this.handleChangeTable}
								isRendeTableFinish={() =>
									handleIsRendeTableFinish(this.firstRefs)
								}
							/>
						}
					</Col>
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ voucher, auth, collections }) => {
	const config = voucher.config ? voucher.config[P_LOAD_COLLECTION] : null
	const { selDocuments, selDocumentsValidate } = collections
	const { authUser } = auth
	return { config, authUser, selDocuments, selDocumentsValidate }
}

const connectForm = connect(mapStateToProps, {
	getSelDocuments,
	validateSelDocuments
})(CollectionSearchTable)

export default themr('StateTableStyles', styles)(withTranslation()(connectForm))
