import {
	crudCreateFundAccountsCode,
	crudGetFundAccountsCode,
	crudGetFundAccountsCodeConfig
} from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import CrudFundAccountsCodeField from './CrudFundAccountsCodeField'

interface CrudFundAccountsCodeFormProps extends WithTranslation {
	crudGetFundAccountsCode: any
	crudCreateFundAccountsCode: any
	t: any
	itemInfo?: any
	method: string
	disableForm: boolean
	handleSubmitModal: any
	handleCloseModal: any
	fundAccountsCodeConfig: any
	fundAccountsCodeInfo: any
	fundAccountsCodeCreated: any
	cod_val: string
	cod_fond: string
}

type CrudFundAccountsCodeFormState = {
	disableForm: boolean
	fieldDefaults: {}
}

class CrudFundAccountsCodeForm extends Component<
	CrudFundAccountsCodeFormProps,
	CrudFundAccountsCodeFormState
> {
	constructor(props: CrudFundAccountsCodeFormProps) {
		super(props)
		this.state = {
			disableForm: false,
			fieldDefaults: {}
		}
	}

	// Gets initial values and dropdowns
	componentDidMount = () => {
		const { cod_val, cod_fond } = this.props
		this.props.crudGetFundAccountsCode({
			cod_val: cod_val,
			codigo_actual: cod_fond
		})
	}

	// If info exists setups default values
	componentDidUpdate = () => {
		const { fundAccountsCodeInfo } = this.props
		const { fieldDefaults } = this.state
		if (fundAccountsCodeInfo && Object.entries(fieldDefaults).length < 1) {
			this.setState({
				fieldDefaults: {
					nivel1: fundAccountsCodeInfo.nivel1
						? _.find(fundAccountsCodeInfo.nivel1, (values) => {
								return values.codigo === fundAccountsCodeInfo.default_nivel1
						  })
							? fundAccountsCodeInfo.default_nivel1
							: fundAccountsCodeInfo.nivel1[0].codigo
						: fundAccountsCodeInfo.default_nivel1,
					nivel2: fundAccountsCodeInfo.nivel2
						? _.find(fundAccountsCodeInfo.nivel2, (values) => {
								return values.codigo === fundAccountsCodeInfo.default_nivel2
						  })
							? fundAccountsCodeInfo.default_nivel2
							: fundAccountsCodeInfo.nivel2[0].codigo
						: fundAccountsCodeInfo.default_nivel2,
					nivel3: fundAccountsCodeInfo.nivel3
						? _.find(fundAccountsCodeInfo.nivel3, (values) => {
								return values.codigo === fundAccountsCodeInfo.default_nivel3
						  })
							? fundAccountsCodeInfo.default_nivel3
							: fundAccountsCodeInfo.nivel3[0].codigo
						: fundAccountsCodeInfo.default_nivel3,
					nivel4: fundAccountsCodeInfo.nivel4
						? _.find(fundAccountsCodeInfo.nivel4, (values) => {
								return values.codigo === fundAccountsCodeInfo.default_nivel4
						  })
							? fundAccountsCodeInfo.default_nivel4
							: fundAccountsCodeInfo.nivel4[0].codigo
						: fundAccountsCodeInfo.default_nivel4,
					nivel5: fundAccountsCodeInfo.nivel5
						? _.find(fundAccountsCodeInfo.nivel5, (values) => {
								return values.codigo === fundAccountsCodeInfo.default_nivel5
						  })
							? fundAccountsCodeInfo.default_nivel5
							: fundAccountsCodeInfo.nivel5[0].codigo
						: fundAccountsCodeInfo.default_nivel5
				}
			})
		}
	}

	render() {
		const {
			t,
			disableForm,
			method,
			handleSubmitModal,
			handleCloseModal,
			fundAccountsCodeConfig,
			fundAccountsCodeInfo,
			cod_val,
			cod_fond
		} = this.props

		const { fieldDefaults } = this.state

		if (fundAccountsCodeConfig) {
			const fields = fundAccountsCodeConfig.campos
			const initialValues =
				fieldDefaults || (fields.length ? getInitValues(fields) : {})

			const columnsCancel = disableForm ? 12 : 6
			const validationSchema = fields.length
				? getValidationSchema(fields, t, [], [])
				: {}

			return (
				<Fragment>
					<Formik
						initialValues={initialValues}
						onSubmit={(values, actions) => {
							handleSubmitModal({
								cod_val: cod_val,
								cod_fond: cod_fond,
								values: values
							})
						}}
						enableReinitialize={true}
						validationSchema={validationSchema}
					>
						{(props: FormikProps<any>) => (
							<Fragment>
								<Col sm={12}>
									<NotificationError
										errors={props.errors}
										touched={props.touched}
									/>
								</Col>
								<Alert variant={'dark'}>
									<b>{t('CRUDS.general_use.formulariocodigo')}</b>
								</Alert>
								<Form>
									{(method === 'add' ||
										((method === 'edit' || method === 'view') &&
											fundAccountsCodeInfo)) &&
										_.map(fields, (field: any) => {
											return (
												<CrudFundAccountsCodeField
													key={field.idCampo}
													formikProps={props}
													field={field}
													crudProps={{
														...this.props
													}}
													initialValue={
														initialValues[
															field.idCampo as keyof typeof initialValues
														]
													}
												/>
											)
										})}
									<Row className={'pt-3'}>
										{!disableForm && (
											<Col sm={6} className={'text-center mt-2'}>
												<Button
													style={{ minWidth: '145px' }}
													type={'button'}
													onClick={() => props.handleSubmit()}
												>
													{t('Collection.form.buttons.accept')}
												</Button>
											</Col>
										)}
										<Col sm={columnsCancel} className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={handleCloseModal}
											>
												{t('Collection.form.buttons.cancel')}
											</Button>
										</Col>
									</Row>
								</Form>
							</Fragment>
						)}
					</Formik>
				</Fragment>
			)
		} else {
			return <div>Cargando...</div>
		}
	}
}

const mapStateToProps = ({ fundAccountsCode }: any) => {
	const {
		fundAccountsCodeConfig,
		fundAccountsCodeInfo,
		fundAccountsCodeCreated
	} = fundAccountsCode
	return {
		fundAccountsCodeConfig,
		fundAccountsCodeInfo,
		fundAccountsCodeCreated
	}
}

const connectForm = connect(mapStateToProps, {
	crudGetFundAccountsCodeConfig,
	crudGetFundAccountsCode,
	crudCreateFundAccountsCode
})(CrudFundAccountsCodeForm)

export default withTranslation()(withRouter(connectForm))
