import { renderInput } from 'lib/FormUtils'
import { ICrudCostCenterField } from 'models/CrudCostCentersInterface'
import { ICrudGenericFields } from 'models/CrudInterface'
import {
	IGetTableGenericRes,
	IGetTableGenericResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'
import { principalFields } from '../../constants/crudsConfig/crudCostCentersConstants/crudCostCentersFormFields'

type CrudCostCenterDataFieldProps = {
	field: ICrudCostCenterField
	formikProps: any
	crudProps: any
	initialValue: any
}

type CrudCostCenterDataFieldState = {
	dependsOn: any
	fieldConfig: ICrudGenericFields
	defaultValue?: string
	idField?: any
	value?: any
}

class CrudCostCenterDataField extends Component<
	CrudCostCenterDataFieldProps,
	CrudCostCenterDataFieldState
> {
	constructor(props: CrudCostCenterDataFieldProps) {
		super(props)
		this.state = {
			fieldConfig: {
				id: '',
				type: '',
				width: '',
				autoComplete: '',
				comboData: '',
				maxChars: 0,
				dependsOn: {
					id: '',
					values: [
						{
							idValue: '',
							disabled: false
						}
					]
				}
			},
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field } = this.props
		const idField = field.idCampo.trim()
		const addData = _.find(principalFields, (field: ICrudGenericFields) => {
			return field.id === idField
		})
		addData && this.setState({ dependsOn: addData.dependsOn })
		addData && this.setState({ fieldConfig: addData })
		addData && this.setState({ idField: idField })
		addData && this.setState({ defaultValue: addData.default })
	}

	// Update check for CPOS validation, and default state of dropdowns
	componentDidUpdate = (prevProps: CrudCostCenterDataFieldProps) => {
		const { crudProps: prevCrudProps, formikProps: prevFormikProps } = prevProps
		const { formikProps, crudProps } = this.props

		const { idField, defaultValue, fieldConfig } = this.state

		if (
			fieldConfig.type !== 'combo' &&
			!formikProps.values[idField] &&
			formikProps.values[idField] !== 0 &&
			defaultValue
		) {
			formikProps.setFieldValue(idField, defaultValue)
		}

		if (
			idField === 'cod_cc' &&
			formikProps.values[idField] !== prevFormikProps.values[idField]
		) {
			formikProps.setFieldValue(
				idField,
				formikProps.values[idField].replace('.', '')
			)
		}

		if (
			idField === 'niccmayor_descrip' &&
			prevCrudProps.parentCostCenter !== crudProps.parentCostCenter
		) {
			formikProps.setFieldValue(idField, crudProps.parentCostCenter)
		}
	}

	// Setup combo field if it's empty
	comboDataField = (data: IGetTableGenericResArray) => {
		const values: { cod_valor: string; desc_valor: string }[] = []
		data &&
			_.each(data.lista, (opt: IGetTableGenericRes) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	handleBlur = async () => {
		const { formikProps, crudProps } = this.props
		const { idField } = this.state
		formikProps.setTouched({
			...formikProps.touched,
			[idField]: true
		})
		if (idField === 'cat_ctacont' || idField === 'cod_cc') {
			crudProps.getTableParentCostCenter({
				cod_cc: formikProps.values.cod_cc ? formikProps.values.cod_cc : 1,
				cat_ctacont: formikProps.values.cat_ctacont
			})
		}
	}

	renderInputs = (
		field: ICrudCostCenterField,
		formikProps: any,
		disableForm: boolean,
		fieldConfig: ICrudGenericFields
	) => {
		const { crudProps } = this.props
		const { values, setFieldValue } = formikProps
		const { dependsOn, idField } = this.state
		const addData = fieldConfig

		addData &&
			(field = {
				...field,
				tipo: addData.type,
				colInput: addData.width,
				autoComplete: addData.autoComplete,
				disabled: addData.disabled,
				onBlur: this.handleBlur
			})

		if (field.tipo === 'combo') {
			addData &&
				(field.valores = crudProps[addData.comboData as keyof typeof crudProps]
					? this.comboDataField(
							crudProps[addData.comboData as keyof typeof crudProps]
					  )
					: [])
			!formikProps.values[idField] &&
				(field.onChange = (event: any) =>
					setFieldValue(field.idCampo, event.target.value))
		} else if (field.tipo === 'check') {
			field.customProps = {
				checked: Number(values[field.idCampo])
			}
		}

		dependsOn.id !== '' &&
			_.each(dependsOn.values, (dependency) => {
				values[dependsOn.id] === dependency.idValue &&
					(field.disabled = dependency.disabled)
			})

		if (!field.editable) field.disabled = true
		if (field.visible) {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { field, formikProps, crudProps } = this.props
		const { fieldConfig } = this.state
		return (
			<Fragment>
				{this.renderInputs(
					field,
					formikProps,
					crudProps.disableForm,
					fieldConfig
				)}
			</Fragment>
		)
	}
}

export default CrudCostCenterDataField
