import {
	CRUD_CLEAR_VALUES_STORE,
	CRUD_CREATE_VALUE,
	CRUD_CREATE_VALUE_SUCCESS,
	CRUD_DEL_VALUE,
	CRUD_DEL_VALUE_SUCCESS,
	CRUD_GET_VALUE,
	CRUD_GET_VALUES_LIST,
	CRUD_GET_VALUES_LIST_SUCCESS,
	CRUD_GET_VALUE_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetValuesList = (payload: any): any => ({
	type: CRUD_GET_VALUES_LIST,
	payload
})

export const crudGetValuesListSuccess = (payload: any): any => ({
	type: CRUD_GET_VALUES_LIST_SUCCESS,
	payload
})

export const crudGetValue = (payload: any) => ({
	type: CRUD_GET_VALUE,
	payload
})

export const crudGetValueSuccess = (payload: any) => ({
	type: CRUD_GET_VALUE_SUCCESS,
	payload
})

export const crudCreateValue = (payload: any) => ({
	type: CRUD_CREATE_VALUE,
	payload
})

export const crudCreateValueSuccess = (payload: any) => ({
	type: CRUD_CREATE_VALUE_SUCCESS,
	payload
})

export const crudDeleteValue = (payload: any) => ({
	type: CRUD_DEL_VALUE,
	payload
})

export const crudDeleteValueSuccess = (payload: any) => ({
	type: CRUD_DEL_VALUE_SUCCESS,
	payload
})

export const crudClearValuesStore = () => ({
	type: CRUD_CLEAR_VALUES_STORE
})
