import {
	crudsGetList,
	crudsGetSingle,
	getConfigFieldsCrud,
	getConfigFieldsCrudAlta,
	showMessage
} from 'actions'
import { CrudsList } from 'constants/crudsList/CrudsData'
import {
	ICrudFieldConfig,
	ICrudParamsSearch,
	ICrudsApi,
	ICrudsTypeList,
	IGetConfigFieldsCrud
} from 'models/CrudInterface'
import { IResponseResult } from 'models/ResultsInterface'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CRUD, CRUD_EDIT } from 'utils/RoutePath'

interface CrudsDataEditProps {
	getConfigFieldsCrudAlta: (params: IGetConfigFieldsCrud) => void
	getConfigFieldsCrud: (params: IGetConfigFieldsCrud) => void
	crudsGetList: (params: ICrudsApi) => void
	crudsGetSingle: (params: ICrudsApi) => void
	match: any
	valueDataRemoved: IResponseResult
	valueDataUpdated: any
	valueDataSelected: any
	valueDataCloned: any
	paramsSearch: ICrudParamsSearch
	showMessage: any
	fieldsAltaConfig: ICrudFieldConfig
	fieldsConfig: ICrudFieldConfig
	history: any
}

interface CrudsDataEditState {
	crudCode: number | string | null
	selectedAbm: ICrudsTypeList
	action: string
	error: boolean
	disableFields: boolean
	disableAbm: boolean
}

class CrudsDataEdit extends Component<CrudsDataEditProps, CrudsDataEditState> {
	constructor(props: CrudsDataEditProps) {
		super(props)
		this.state = {
			crudCode: null,
			action: this.props.match.params.action,
			selectedAbm:
				CrudsList.find((crud) => {
					return crud.dataUrl === this.props.match.params.abmKey
				}) || CrudsList[0],
			error: false,
			disableFields: true,
			disableAbm: true
		}
	}

	componentDidMount() {
		const { match, crudsGetSingle } = this.props
		const { id, action } = match.params
		const { selectedAbm } = this.state

		this.props.getConfigFieldsCrud({
			cod_abm: `${selectedAbm?.urlApi}`
		})

		this.props.getConfigFieldsCrudAlta({
			cod_abm: `${selectedAbm?.urlApi}_alta`
		})

		id && this.setState({ crudCode: id })

		if (id && (action === 'edit' || action === 'view')) {
			crudsGetSingle({
				crudName: selectedAbm.id,
				urlApi: selectedAbm.urlApi,
				action: 'consulta',
				params: { [selectedAbm.key]: id }
			})
		}
	}

	componentDidUpdate = (prevProps: CrudsDataEditProps) => {
		const {
			valueDataUpdated,
			valueDataSelected,
			valueDataCloned,
			match,
			history,
			showMessage,
			fieldsConfig
		} = this.props
		const { selectedAbm, crudCode } = this.state
		const { action } = match.params
		if (prevProps.valueDataUpdated !== valueDataUpdated && valueDataUpdated) {
			showMessage({
				type: 'success',
				description: 'Se actualizo correctamente',
				showError: true
			})
			if (crudCode === 'new') {
				if (valueDataUpdated.id) {
					this.setState({ crudCode: valueDataUpdated.id, action: 'edit' })
					history.push(
						`${CRUD_EDIT}/${selectedAbm.dataUrl}/edit/${valueDataUpdated.id}`
					)
				} else {
					history.push(`${CRUD}/${selectedAbm.dataUrl}`)
				}
			}
		}

		if (
			valueDataSelected &&
			valueDataSelected.nro === 0 &&
			prevProps.valueDataSelected !== valueDataSelected &&
			action !== 'add'
		) {
			showMessage({
				type: 'error',
				description: 'No existe el abm',
				showError: true
			})
			this.setState({ error: true })
		}

		if (
			valueDataCloned &&
			valueDataCloned.id &&
			prevProps.valueDataCloned !== valueDataCloned
		) {
			showMessage({
				type: 'success',
				description: 'Se actualizo correctamente',
				showError: true
			})
			history.push(
				`${CRUD_EDIT}/${selectedAbm.dataUrl}/edit/${valueDataCloned.id}`
			)
			history.go()
		}

		if (fieldsConfig && fieldsConfig !== prevProps.fieldsConfig) {
			switch (fieldsConfig.tipo_perm) {
				case 'L':
					this.setState({ disableAbm: false })
					break
				case 'E':
					this.setState({ disableAbm: false, disableFields: false })
					break
				default:
					break
			}
		}
	}

	render() {
		const { fieldsAltaConfig, fieldsConfig } = this.props
		const { crudCode, selectedAbm, action, error, disableAbm, disableFields } =
			this.state

		const PropsContent = {
			fieldsAltaConfig: fieldsAltaConfig,
			crudCode: crudCode,
			op: action,
			disableForm: disableFields || action === 'view',
			selectedAbm: selectedAbm
		}

		return !disableAbm &&
			crudCode &&
			fieldsConfig &&
			selectedAbm.component &&
			!error ? (
			<selectedAbm.component {...PropsContent} />
		) : (
			<div>asd</div>
		)
	}
}

const mapStateToProps = ({ configCrud }: any) => {
	const {
		fieldsAltaConfig,
		fieldsConfig,
		valueDataUpdated,
		valueDataSelected,
		valueDataCloned,
		paramsSearch
	} = configCrud
	return {
		fieldsAltaConfig,
		fieldsConfig,
		valueDataUpdated,
		valueDataSelected,
		valueDataCloned,
		paramsSearch
	}
}

const connectForm = connect(mapStateToProps, {
	getConfigFieldsCrudAlta,
	getConfigFieldsCrud,
	crudsGetList,
	crudsGetSingle,
	showMessage
})(CrudsDataEdit)

export default withTranslation()(withRouter(connectForm))
