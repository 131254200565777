import { InventoryPickStockActions } from 'constants/ActionsTypesTs'
import { IGetDepositsParams } from 'models/AutomaticPurchase'
import {
	IConfirmInventoryPickStockAction,
	IConfirmInventoryPickStockPayload,
	IConfirmInventoryPickStockResponse,
	IConfirmInventoryPickStockSuccessAction,
	IGetCufPickStockAction,
	IGetCufPickStockParams,
	IGetCufPickStockResponse,
	IGetCufPickStockSuccessAction,
	IGetDepositsPickStockAction,
	IGetDepositsPickStockResponse,
	IGetDepositsPickStockSuccessAction,
	IGetInventoryPickStockAction,
	IGetInventoryPickStockParams,
	IGetInventoryPickStockResponse,
	IGetInventoryPickStockSuccessAction,
	InventorySearchBarcodeAction,
	InventorySearchBarcodeErrorAction,
	InventorySearchBarcodeParams,
	InventorySearchBarcodeResponse,
	InventorySearchBarcodeSuccessAction,
	ISearchBarcodeErrorResponse
} from 'models/InventoryPickStock'

export const inventoryPickStockSearchBarCode = (
	payload: InventorySearchBarcodeParams
): InventorySearchBarcodeAction => ({
	type: InventoryPickStockActions.INVENTORY_PICK_BAR_CODE_SEARCH,
	payload
})

export const inventoryPickStockSearchBarCodeSuccess = (
	payload: InventorySearchBarcodeResponse
): InventorySearchBarcodeSuccessAction => ({
	type: InventoryPickStockActions.INVENTORY_PICK_BAR_CODE_SEARCH_SUCCESS,
	payload
})

export const inventoryPickStockSearchBarCodeError = (
	payload: ISearchBarcodeErrorResponse
): InventorySearchBarcodeErrorAction => ({
	type: InventoryPickStockActions.INVENTORY_PICK_BAR_CODE_SEARCH_ERROR,
	payload
})

export const getInventoryPickStock = (
	payload: IGetInventoryPickStockParams
): IGetInventoryPickStockAction => ({
	type: InventoryPickStockActions.GET_INVENTORY_PICK_STOCK,
	payload
})

export const getInventoryPickStockSuccess = (
	payload: IGetInventoryPickStockResponse
): IGetInventoryPickStockSuccessAction => ({
	type: InventoryPickStockActions.GET_INVENTORY_PICK_STOCK_SUCCESS,
	payload
})

export const getDepositsPickStock = (
	payload: IGetDepositsParams
): IGetDepositsPickStockAction => ({
	type: InventoryPickStockActions.GET_DEPOSITS_PICK_STOCK,
	payload
})

export const getDepositsPickStockSuccess = (
	payload: IGetDepositsPickStockResponse
): IGetDepositsPickStockSuccessAction => ({
	type: InventoryPickStockActions.GET_DEPOSITS_PICK_STOCK_SUCCESS,
	payload
})

export const getCufsPickStock = (
	payload: IGetCufPickStockParams
): IGetCufPickStockAction => ({
	type: InventoryPickStockActions.GET_CUF_PICK_STOCK,
	payload
})

export const getCufsPickStockSuccess = (
	payload: IGetCufPickStockResponse
): IGetCufPickStockSuccessAction => ({
	type: InventoryPickStockActions.GET_CUF_PICK_STOCK_SUCCESS,
	payload
})

export const confirmInventoryPickStock = (
	payload: IConfirmInventoryPickStockPayload
): IConfirmInventoryPickStockAction => ({
	type: InventoryPickStockActions.CONFIRM_INVENTORY_PICK_STOCK,
	payload
})

export const confirmInventoryPickStockSuccess = (
	payload: IConfirmInventoryPickStockResponse
): IConfirmInventoryPickStockSuccessAction => ({
	type: InventoryPickStockActions.CONFORM_INVENTORY_PICK_STOCK_SUCCESS,
	payload
})
