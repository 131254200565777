import ListCrudTabContent from 'components/genericCrud/ListCrudTabContent'
import CrudSeller from 'components/seller/CrudSeller'
import SellerCommissionTable from 'components/seller/SellerCommissionTable'
import { CRUD_SELLER_DATA } from 'utils/RoutePath'

export const sellerConfig = {
	id: 'sellerCrud',
	key: 'nivend',
	descrip: 'descrip',
	searchMethod: 'get',
	category: 'A',
	translation: 'seller_data',
	dataUrl: CRUD_SELLER_DATA,
	urlApi: 'abm_vta_vend',
	component: ListCrudTabContent,
	tabsList: [
		{
			key: 'basic',
			title: 'CRUDS.general_use.basic_tab',
			tabComponent: CrudSeller
		},
		{
			key: 'comission',
			title: 'CRUDS.general_use.grupocom',
			tabComponent: SellerCommissionTable
		}
	],
	tabsGroups: ['grilla', 'grilla.comision'],
	tabsGroupsAlta: ['form_alta', 'form_alta.comision']
}
