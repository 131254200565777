import { CRUD_CC_ASSIGNATIONS } from 'utils/RoutePath'

export const directCCAssignConfig = {
	id: 'costCenterAssignCrud',
	key: 'nicc',
	descrip: 'descrip',
	searchMethod: 'get',
	category: 'A',
	dataUrl: CRUD_CC_ASSIGNATIONS,
	urlApi: 'abm_cont_asig_cc'
}
