import CommonTable from 'components/common/commonTable'
import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import CrudTableButtons from './CrudTableButtons'

const excludeFields = ['modif', 'baja']

interface ComponentProps extends WithTranslation {
	fields: any
	t: any
	deleteMessage: string
	deleteTitle: string
	handleDeleteAction: any
	handleCallBackView: any
	handleCallBackEdit: any
	dataTable: any
	primaryKey: string
	paginationOption: any
	handleChangeTable: any
	disableForm: any
}

type ComponentState = {
	filterVal: any
}

class CrudTable extends Component<ComponentProps, ComponentState> {
	constructor(props: ComponentProps) {
		super(props)
		this.state = {
			filterVal: ''
		}
	}

	/**
	 * to get columns table.
	 * @returns object
	 */
	getColumns = () => {
		const {
			fields,
			t,
			deleteMessage,
			deleteTitle,
			handleDeleteAction,
			disableForm
		} = this.props
		const rows = fields.map((field: any) => {
			const fieldId = field.idCampo.trim()
			return {
				dataField: fieldId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '10%' },
				hidden: !field.visible || excludeFields.includes(fieldId),
				filterValue: (cell: any, row: any) => {
					const filter: any = []
					row.Bonificaciones.forEach((bonif: any) => {
						if (bonif.cod_bonif === this.state.filterVal) {
							filter.push(bonif.cod_bonif)
						}
					})

					return filter
				}
			}
		})

		rows.push({
			dataField: 'actions',
			text: '',
			align: 'center',
			headerAlign: 'center',
			headerStyle: { width: '5%' },
			formatter: (cell: any, row: any, rowIndex: any) => {
				return (
					<CrudTableButtons
						view={this.props.handleCallBackView}
						edit={this.props.handleCallBackEdit}
						delete={handleDeleteAction}
						row={row}
						deleteMessage={deleteMessage}
						deleteAction={handleDeleteAction}
						deleteTitle={deleteTitle || t('global.delete')}
						disableForm={disableForm}
					/>
				)
			}
		})

		return rows
	}

	render() {
		const { dataTable, fields, primaryKey, paginationOption } = this.props
		const tableColumns = fields.length ? this.getColumns() : []

		const options = paginationOption
			? {
					page: paginationOption.page_number,
					sizePerPage: paginationOption.page_size,
					totalSize: paginationOption.total_count
			  }
			: {}

		return (
			<Col className={`col-12`}>
				<CommonTable
					remote
					columns={tableColumns}
					keyField={primaryKey}
					data={dataTable}
					paginationOptions={options}
					onTableChange={this.props.handleChangeTable}
				/>
			</Col>
		)
	}
}

export default withTranslation()(CrudTable)
