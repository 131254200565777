import { ICrudFieldStructure } from 'models/CrudInterface'
import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import _ from 'underscore'
import moduleStyles from './inputText.module.scss'

interface ITypeRadioProps {
	label: string
	name: string
	inputId: string
	styles?: any
	rowStyle?: any
	inputFormCol?: any
	colInput: string
	colLabel: string
	styleLabel?: any
	disable: boolean
	checked?: boolean
	fields: ICrudFieldStructure[]
	showError?: boolean
	onChange: any
}

interface ITypeRadioState {
	configInput: ICrudFieldStructure | any
	requireError: boolean
}

class InputCheckRadio extends PureComponent<ITypeRadioProps, ITypeRadioState> {
	constructor(props: ITypeRadioProps) {
		super(props)
		this.state = {
			configInput: this.getConfigField(props.inputId),
			requireError: props.showError || false
		}
	}

	componentDidUpdate = (prevProps: ITypeRadioProps) => {
		const { fields, inputId } = this.props
		if (prevProps.fields !== fields && fields) {
			this.setState({ configInput: this.getConfigField(inputId) })
		}
	}

	/**
	 * to get all config data of input
	 * @param id
	 * @returns
	 */
	getConfigField = (id: string) => {
		const { fields } = this.props
		return !_.isEmpty(fields) ? _.findWhere(fields, { idCampo: id }) : {}
	}

	renderField = () => {
		const {
			label,
			name,
			inputId,
			colInput,
			colLabel,
			styleLabel,
			disable,
			inputFormCol,
			rowStyle,
			checked,
			styles
		} = this.props

		const classInput = label ? colInput : 'col-sm-12'
		const classLabel = label ? colLabel : ''
		const classText = disable ? moduleStyles.inputDisabled : ''
		const config = this.state.configInput

		const customStyleLabel = config.requerido
			? { ...styleLabel, color: 'red' }
			: { ...styleLabel }
		const inputStyles = this.state.requireError
			? { ...styles, border: '1px solid red' }
			: styles

		if (config.visible) {
			const optionsInput = {
				id: inputId,
				name: name,
				type: 'radio',
				style: inputStyles,
				disabled: !config.editable || disable,
				className: `${classText}`,
				checked: checked,
				onChange: (event: any) => this.props.onChange(event.target.checked)
			}

			return (
				<Col {...inputFormCol}>
					<Row style={rowStyle}>
						<Col className={`${classInput}`}>{<input {...optionsInput} />}</Col>
						{label ||
							(config.label && (
								<Col className={`${classLabel}`}>
									<label
										className={`${moduleStyles.inputLabelCheckBox}`}
										style={customStyleLabel}
									>
										{label || config.label}
									</label>
								</Col>
							))}
					</Row>
				</Col>
			)
		} else {
			return null
		}
	}

	render() {
		const { fields } = this.props
		if (fields) {
			return this.renderField()
		} else {
			return null
		}
	}
}

const mapStateToProps = ({ auth }: any) => {
	const { authUser } = auth
	return { authUser }
}

export default connect(mapStateToProps)(InputCheckRadio)
