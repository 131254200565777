import {
	confirmPackagesAttributesSuccess,
	getPackagesAttributesSuccess
} from 'actions'
import {
	confirmPackagesAttributesApi,
	getPackagesAttributesApi
} from 'api/LoadPackagesApi'
import { LoadPackageActions } from 'constants/ActionsTypesTs'
import {
	IConfirmPackageAction,
	IGetPackagesAction,
	IGetPackageSuccessResponse
} from 'models/LoadPackages'
import { IResponseResult } from 'models/ResultsInterface'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* getPackagesAttributesRequest({ payload }: IGetPackagesAction): any {
	try {
		const values: IGetPackageSuccessResponse = yield call(
			getPackagesAttributesApi,
			payload
		)
		yield put(getPackagesAttributesSuccess(values))
	} catch (error: any) {}
}

function* confirmPackagesAttributesRequest({
	payload
}: IConfirmPackageAction): any {
	try {
		const { params, callBackReturn } = payload
		const resp: IResponseResult = yield call(
			confirmPackagesAttributesApi,
			params
		)
		yield put(confirmPackagesAttributesSuccess(resp))

		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error: any) {}
}

export function* getPackagesAttributesSaga() {
	yield takeEvery(LoadPackageActions.GET_PACKAGES, getPackagesAttributesRequest)
}

export function* confirmPackagesAttributesSaga() {
	yield takeEvery(
		LoadPackageActions.CONFIRM_PACKAGES,
		confirmPackagesAttributesRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(getPackagesAttributesSaga),
		fork(confirmPackagesAttributesSaga)
	])
}
