import {
	GET_EGR_STOCK,
	GET_EGR_STOCK_SUCCESS,
	PICKEO_EGR_SEARCH_BARCODE,
	PICKEO_EGR_SEARCH_BARCODE_ERROR,
	PICKEO_EGR_SEARCH_BARCODE_SUCCESS,
	PICKEO_EGR_STOCK_CONFIRM,
	PICKEO_EGR_STOCK_CONFIRM_SUCCESS
} from 'constants/ActionsTypes'

const initialState = {
	egrPickeo: null,
	barCodeSearched: null,
	barCodeError: null,
	barCodeParams: null,
	confirmStock: null
}

function rootReducer(state = initialState, action) {
	switch (action.type) {
		case GET_EGR_STOCK:
			return { ...state, egrPickeo: null }
		case GET_EGR_STOCK_SUCCESS:
			return { ...state, egrPickeo: action.payload }
		case PICKEO_EGR_SEARCH_BARCODE:
			return {
				...state,
				barCodeSearched: null,
				barCodeError: null,
				barCodeParams: action.payload
			}
		case PICKEO_EGR_SEARCH_BARCODE_SUCCESS:
			return { ...state, barCodeSearched: action.payload }
		case PICKEO_EGR_SEARCH_BARCODE_ERROR:
			return { ...state, barCodeError: action.payload }
		case PICKEO_EGR_STOCK_CONFIRM:
			return { ...state, confirmStock: null }
		case PICKEO_EGR_STOCK_CONFIRM_SUCCESS:
			return { ...state, confirmStock: action.payload }

		default:
			return state
	}
}

export default rootReducer
