import React, { Component, Fragment } from 'react'
import StockDisplay from './stockDisplay'
import StockInputs from './stockInputs'
import StockTable from './stockTable'

export default class StockForm extends Component {
	componentDidMount() {
		this.props.clearStoreStock()
	}

	componentWillUnmount = () => {
		const { confirmedStock, handleCancelApi } = this.props
		if (confirmedStock === null) {
			handleCancelApi()
		}
	}

	render() {
		const { productStock } = this.props
		const showInputs = productStock ? productStock.ind_series : 0

		return (
			<Fragment>
				<StockDisplay {...this.props} />
				{showInputs > 0 && <StockInputs {...this.props} />}
				<StockTable {...this.props} />
			</Fragment>
		)
	}
}
