import {
	deleteSearchedProductsApi,
	getDetailMainDataApi,
	getDetailSalesApi,
	getDetailShoppingApi,
	getDetailStockApi,
	getEquivalentDataApi,
	getFilterProductAttributesApi,
	getPeriodFilterApi,
	getProductsOrderOptionsApi,
	getStockOrderOptionsApi,
	getSuggestedDataApi,
	productCategoryFilterApi,
	selectSearchedProductsApi
} from 'api/SearchProducts'
import {
	DELETE_SEARCHED_PRODUCTS,
	GET_DETAIL_MAIN_DATA,
	GET_DETAIL_SALES_DATA,
	GET_DETAIL_SHOPPING_DATA,
	GET_DETAIL_STOCK,
	GET_EQUIVALENT_DATA,
	GET_FILTER_PRODUCT_ATTRIBUTES,
	GET_PERIOD_FILTER,
	GET_PRODUCT_ORDER_OPTIONS,
	GET_STOCK_ORDER_OPTIONS,
	GET_SUGGESTED_DATA,
	PRODUCT_CATEGORY_FILTER,
	SELECT_SEARCHED_PRODUCTS
} from 'constants/ActionsTypes'
import {
	IDeleteSearchedProductsSaga,
	IGetMainDataAction,
	IGetSalesDataAction,
	IGetStockAction,
	IGetSuggestAction,
	ISelectSearchedProductAction
} from 'models/SearchProduct'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
	getDetailMainDataSuccess,
	getDetailSalesSuccess,
	getDetailShoppingSuccess,
	getDetailStockSuccess,
	getEquivalentDataSuccess,
	getFilterProductAttributesSuccess,
	getPeriodFilterSuccess,
	getProductsOrderOptionsSuccess,
	getStockOrderOptionsSuccess,
	getSuggestedDataSuccess,
	productCategoryFilterSuccess,
	selectSearchedProductsSuccess
} from './../actions/SearchProducts'

function* getProductsOrderOptionsRequest(): any {
	try {
		const data: any = yield call(getProductsOrderOptionsApi)
		yield put(getProductsOrderOptionsSuccess(data))
	} catch (error) {}
}

function* productCategoryFilterRequest(): any {
	try {
		const data: any = yield call(productCategoryFilterApi)
		yield put(productCategoryFilterSuccess(data))
	} catch (error) {}
}

function* getFilterProductAttributesRequest(): any {
	try {
		const data: any = yield call(getFilterProductAttributesApi)
		yield put(getFilterProductAttributesSuccess(data))
	} catch (error) {}
}

function* getDetailMainDataRequest({ payload }: IGetMainDataAction): any {
	try {
		const data: any = yield call(getDetailMainDataApi, payload)
		yield put(getDetailMainDataSuccess(data))
	} catch (error) {}
}

function* getDetailStockRequest({ payload }: IGetStockAction): any {
	try {
		const data: any = yield call(getDetailStockApi, payload)
		yield put(getDetailStockSuccess(data))
	} catch (error) {}
}

function* getDetailSalesRequest({ payload }: IGetSalesDataAction): any {
	try {
		const data: any = yield call(getDetailSalesApi, payload)
		yield put(getDetailSalesSuccess(data))
	} catch (error) {}
}

function* getDetailShoppingRequest({ payload }: IGetSalesDataAction): any {
	try {
		const data: any = yield call(getDetailShoppingApi, payload)
		yield put(getDetailShoppingSuccess(data))
	} catch (error) {}
}

function* getSuggestedDataRequest({ payload }: IGetSuggestAction): any {
	try {
		const data: any = yield call(getSuggestedDataApi, payload)
		yield put(getSuggestedDataSuccess(data))
	} catch (error) {}
}

function* getEquivalentDataRequest({ payload }: IGetSuggestAction): any {
	try {
		const data: any = yield call(getEquivalentDataApi, payload)
		yield put(getEquivalentDataSuccess(data))
	} catch (error) {}
}

function* getStockOrderOptionsRequest(): any {
	try {
		const data: any = yield call(getStockOrderOptionsApi)
		yield put(getStockOrderOptionsSuccess(data))
	} catch (error) {}
}

function* getPeriodFilterRequest(): any {
	try {
		const filter: any = yield call(getPeriodFilterApi)
		yield put(getPeriodFilterSuccess(filter))
	} catch (error) {}
}

function* selectSearchedProductsRequest({
	payload
}: ISelectSearchedProductAction): any {
	try {
		const searched: any = yield call(selectSearchedProductsApi, payload)
		yield put(selectSearchedProductsSuccess(searched))
	} catch (error) {}
}

function* deleteSearchedProductsRequest({
	payload
}: IDeleteSearchedProductsSaga): any {
	try {
		const searched: any = yield call(deleteSearchedProductsApi, payload)
		yield put(selectSearchedProductsSuccess(searched))
	} catch (error) {}
}

export function* getProductsOrderOptionsSaga() {
	yield takeEvery(GET_PRODUCT_ORDER_OPTIONS, getProductsOrderOptionsRequest)
}

export function* productCategoryFilterSaga() {
	yield takeEvery(PRODUCT_CATEGORY_FILTER, productCategoryFilterRequest)
}

export function* getFilterProductAttributesSaga() {
	yield takeEvery(
		GET_FILTER_PRODUCT_ATTRIBUTES,
		getFilterProductAttributesRequest
	)
}

export function* getDetailMainDataSaga() {
	yield takeEvery(GET_DETAIL_MAIN_DATA, getDetailMainDataRequest)
}

export function* getDetailStockSaga() {
	yield takeEvery(GET_DETAIL_STOCK, getDetailStockRequest)
}

export function* getStockOrderOptionsSaga() {
	yield takeEvery(GET_STOCK_ORDER_OPTIONS, getStockOrderOptionsRequest)
}

export function* getDetailSalesSaga() {
	yield takeEvery(GET_DETAIL_SALES_DATA, getDetailSalesRequest)
}

export function* getPeriodFilterSaga() {
	yield takeEvery(GET_PERIOD_FILTER, getPeriodFilterRequest)
}

export function* getDetailShoppingSaga() {
	yield takeEvery(GET_DETAIL_SHOPPING_DATA, getDetailShoppingRequest)
}

export function* getSuggestedDataSaga() {
	yield takeEvery(GET_SUGGESTED_DATA, getSuggestedDataRequest)
}

export function* getEquivalentDataSaga() {
	yield takeEvery(GET_EQUIVALENT_DATA, getEquivalentDataRequest)
}

export function* selectSearchedProductsSaga() {
	yield takeEvery(SELECT_SEARCHED_PRODUCTS, selectSearchedProductsRequest)
}

export function* deleteSearchedProductsSaga() {
	yield takeEvery(DELETE_SEARCHED_PRODUCTS, deleteSearchedProductsRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getProductsOrderOptionsSaga),
		fork(productCategoryFilterSaga),
		fork(getFilterProductAttributesSaga),
		fork(getDetailMainDataSaga),
		fork(getDetailStockSaga),
		fork(getStockOrderOptionsSaga),
		fork(getDetailSalesSaga),
		fork(getPeriodFilterSaga),
		fork(getDetailShoppingSaga),
		fork(getSuggestedDataSaga),
		fork(getEquivalentDataSaga),
		fork(selectSearchedProductsSaga),
		fork(deleteSearchedProductsSaga)
	])
}
