import TableWithSelection from 'components/abmUtils/TableWithSelection'
import { ICrudCompleteFields } from 'models/CrudInterface'
import { IGetSugestedProductsResponseArray } from 'models/SugestedProducts'
import React, { Component } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import _ from 'underscore'

interface ISugestedProductsProps extends WithTranslation {
	tableFields: Array<ICrudCompleteFields>
	products: IGetSugestedProductsResponseArray
	disableVoucher: boolean
	handlePaginationChange: (page_number: number, page_size: number) => void
	handleChange: (type: string, row: number, value: number) => void
	handleProductSelect: (selection: Array<number>) => void
	handleAddProduct: (selectedRow: number) => void
	handleDeleteProduct: (selectedRow: number) => void
	handleSelectAll: (action: string) => void
	selectedRows: Array<number>
}

class SugestedProductsTable extends Component<ISugestedProductsProps> {
	handleChangeTable = (type: String, pagination: any, value: any) => {
		const { disableVoucher, handlePaginationChange, handleChange } = this.props

		if (type === 'pagination') {
			if (!disableVoucher) {
				handlePaginationChange(pagination.page, pagination.sizePerPage)
			}
		}

		if (type === 'input') {
			handleChange('priority', value.rowId, value.value)
		}
	}

	handleSelection = (selectedRow: number, table: string, action: string) => {
		const { handleAddProduct, handleDeleteProduct } = this.props

		if (action === 'add') {
			handleAddProduct(selectedRow)
		} else if (action === 'delete') {
			handleDeleteProduct(selectedRow)
		}
	}

	validateOnCheck = (row: any, cell: any) => {
		const { selectedRows } = this.props

		const response = _.isEmpty(
			selectedRows?.filter((id: any) => {
				return id === row.nisuger
			})
		)

		return response
	}

	render() {
		const { products, tableFields, handleSelectAll, selectedRows } = this.props

		const propsTable = {
			fields: products && products.productos,
			tableFields: tableFields,
			disableForm: false,
			keyfield: 'nisuger',
			sustainsClick: false,
			doDoubleClick: false,
			disableClick: true,
			hideScrollX: true,
			hideScrollY: true,
			selected: selectedRows || [],
			handleSelectAll: handleSelectAll,
			setSelection: this.handleSelection,
			paginationOption: products || null,
			handleChangeTable: this.handleChangeTable,
			label: 'SugestedPTable',
			checkBox: true,
			height: '100%',
			validationFunction: this.validateOnCheck
		}

		return (
			<>
				<TableWithSelection {...propsTable} />
			</>
		)
	}
}

export default withTranslation()(SugestedProductsTable)
