import { automaticPurchaseUpdate, getListCostTypes } from 'actions'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getOptionsDropDown } from 'lib/FormUtils'
import { IAutomaticPurchaseUpdateParams } from 'models/AutomaticPurchase'
import { IGetTableGenericRes } from 'models/TableCalls/TableCallsInterface'
import React, { Component } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'

interface IAutomaticPurchaseFieldsProps {
	fields: IFieldStructure[]
	getListCostTypes: () => void
	costTypeList: null | Array<IGetTableGenericRes>
	automaticPurchaseUpdate: (payload: IAutomaticPurchaseUpdateParams) => void
	idOperacion: string
}

interface IAutomaticPurchaseFieldsState {
	pc_dtodef: number
	tipo_costo: string
}

export class AutomaticPurchaseFields extends Component<
	IAutomaticPurchaseFieldsProps,
	IAutomaticPurchaseFieldsState
> {
	constructor(props: IAutomaticPurchaseFieldsProps) {
		super(props)
		this.state = {
			pc_dtodef: 0,
			tipo_costo: ''
		}
	}

	componentDidMount = () => {
		this.props.getListCostTypes()
	}

	handleUpdate = () => {
		const { idOperacion } = this.props
		const { pc_dtodef, tipo_costo } = this.state
		this.props.automaticPurchaseUpdate({
			idOperacion,
			page_number: 1,
			page_size: 10,
			pc_dtodef,
			tipo_costo
		})
	}

	render() {
		const { fields, costTypeList } = this.props
		const { pc_dtodef, tipo_costo } = this.state

		const propsDiscount = {
			inputFormCol: { sm: 5 },
			type: 'number',
			fields,
			label: 'code',
			inputId: 'pc_dtodef',
			name: 'pc_dtodef',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			value: pc_dtodef,
			divStyle: { paddingRight: '0px' },
			onChange: (data: string) =>
				this.setState({
					pc_dtodef: data && parseInt(data) ? parseInt(data) : 0
				})
		}

		const propsCategory = {
			inputFormCol: { sm: 5 },
			fields,
			label: 'l',
			inputId: 'tipo_costo',
			name: 'tipo_costo',
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-7',
			value: tipo_costo,
			divStyle: { paddingRight: '0px' },
			options: getOptionsDropDown(costTypeList),
			onChange: (event: any) =>
				this.setState({ tipo_costo: event.target.value })
		}

		return (
			<Row>
				<Row className={`col-12 `}>
					<InputDropdown {...propsCategory} />
					<InputText {...propsDiscount} />
					<Col className={'text-center'}>
						<Button
							style={{ minWidth: '145px' }}
							type={'button'}
							onClick={this.handleUpdate}
						>
							{'Actualizar'}
						</Button>
					</Col>
				</Row>
			</Row>
		)
	}
}

const mapStateToProps = ({ automaticPurchase }: any) => {
	const { costTypeList } = automaticPurchase
	return { costTypeList }
}

const mapDispatchToProps = { getListCostTypes, automaticPurchaseUpdate }

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AutomaticPurchaseFields)
