import InputText from 'components/form/inputText'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import InputTextArea from '../form/InputTextArea'

class AccountFormInput extends Component {
	render() {
		const {
			t,
			values,
			handleChange,
			handleBlur,
			setFieldValue,
			readOnly,
			fields,
			inputsForm
		} = this.props

		return (
			<Row>
				<InputTextArea
					inputFormCol={{ sm: 11 }}
					fields={fields}
					label={t('client.form.obs_current_account')}
					inputId={inputsForm.obsCC}
					name={inputsForm.obsCC}
					placeholder={t('client.form.insert_obs_current_account')}
					styles={{ width: '100%' }}
					colLabel={'col-sm-2'}
					colInput={'col-sm-10'}
					cols={3}
					rows={3}
					disable={readOnly}
					onBlur={handleBlur}
					value={values[inputsForm.obsCC]}
					onChange={(data) => {
						setFieldValue(inputsForm.obsCC, data.target.value)
						handleChange()
					}}
				/>

				<InputTextArea
					inputFormCol={{ sm: 11 }}
					fields={fields}
					label={t('client.form.obs_sales')}
					inputId={inputsForm.obsTrans}
					name={inputsForm.obsTrans}
					placeholder={t('client.form.insert_obs_sales')}
					styles={{ width: '100%' }}
					colLabel={'col-sm-2'}
					colInput={'col-sm-10'}
					cols={3}
					rows={3}
					disable={readOnly}
					onBlur={handleBlur}
					value={values[inputsForm.obsTrans]}
					onChange={(data) => {
						setFieldValue(inputsForm.obsTrans, data.target.value)
						handleChange()
					}}
				/>

				<Row className={'col-11'} style={{ paddingRight: '0px' }}>
					<InputText
						inputFormCol={{ sm: 6 }}
						fields={fields}
						label={t('client.form.limit_credit')}
						type={'number'}
						inputId={'cliente_Limcred'}
						name={'cliente_Limcred'}
						placeholder={t('client.form.insert_limit_credit')}
						colLabel={'col-sm-4'}
						colInput={'col-sm-8'}
						divStyle={{ paddingLeft: '17px' }}
						disable={readOnly}
						onBlur={handleBlur}
						value={values.cliente_Limcred}
						onChange={(data) => {
							setFieldValue('cliente_Limcred', data)
							handleChange()
						}}
					/>

					<InputText
						inputFormCol={{ sm: 6, style: { paddingRight: '0px' } }}
						fields={fields}
						label={t('client.form.pendingCredit')}
						inputId={'cliente_Pendcred'}
						name={'cliente_Pendcred'}
						placeholder={t('client.form.insert_pending_credit')}
						colLabel={'col-sm-4'}
						colInput={'col-sm-8'}
						divStyle={{ paddingLeft: '10px' }}
						styleLabel={{ textAlign: 'right' }}
						disable={readOnly}
						onBlur={handleBlur}
						value={values.cliente_Pendcred}
						onChange={(data) => {
							setFieldValue('cliente_Pendcred', data)
							handleChange()
						}}
					/>
				</Row>
				<Row className={'col-11'} style={{ paddingRight: '0px' }}>
					<InputText
						inputFormCol={{ sm: 6 }}
						fields={fields}
						label={t('client.form.balance')}
						inputId={inputsForm.balance}
						name={inputsForm.balance}
						placeholder={t('client.form.insert_client_balance')}
						colLabel={'col-sm-4'}
						colInput={'col-sm-8'}
						disable={readOnly}
						divStyle={{ paddingLeft: '17px' }}
						onBlur={handleBlur}
						value={values[inputsForm.balance]}
						onChange={(data) => {
							setFieldValue(inputsForm.balance, data)
							handleChange()
						}}
					/>
				</Row>
			</Row>
		)
	}
}

export default withTranslation()(AccountFormInput)
