import HeadboardForm from 'components/headboard/headboardForm'
import GlobalContainer from 'components/layout/globalContainer'
import { P_VTACAB } from 'constants/ConfigProcessNames'
import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import withMenu from '../../components/common/withMenu'

class Headboard extends PureComponent {
	constructor(props) {
		super(props)

		this.formRef = React.createRef()
	}

	callBackButton = () => {
		if (this.formRef.current) {
			this.formRef.current.handleSubmit()
		}
	}

	render() {
		return (
			<Row>
				<GlobalContainer
					codeProcess={P_VTACAB}
					callBackButton={this.callBackButton}
					breadCrumbButtonType={'primary'}
					nextPage
				>
					<HeadboardForm formRef={this.formRef} />
				</GlobalContainer>
			</Row>
		)
	}
}

const mapStateToProps = () => {
	return {}
}

export default connect(
	mapStateToProps,
	{}
)(withTranslation()(withMenu(Headboard)))
