import {
	CLEAR_SALES_HEADER_FILTERS,
	CONFIRM_SALES_HEADERS,
	CONFIRM_SALES_HEADERS_ERROR,
	CONFIRM_SALES_HEADERS_SUCCESS,
	GET_SALES_HEADERS,
	GET_SALES_HEADERS_SUCCESS,
	SET_SALES_HEADER_FILTERS,
	VALIDATE_BARCODE_SALES_HEADERS,
	VALIDATE_BARCODE_SALES_HEADERS_SUCCESS
} from 'constants/ActionsTypes'
import {
	IConfirmSalesHeadersAction,
	IConfirmSalesHeadersParams,
	IGetSalesHeadersAction,
	IGetSalesHeadersParams,
	IValidateBarCodeAction,
	IValidateBarCodeParams
} from 'models/Budget'

export const getSalesHeaders = (
	payload: IGetSalesHeadersParams
): IGetSalesHeadersAction => ({
	type: GET_SALES_HEADERS,
	payload
})

export const getSalesHeadersSuccess = (payload: any) => ({
	type: GET_SALES_HEADERS_SUCCESS,
	payload
})

export const confirmSalesHeaders = (
	payload: IConfirmSalesHeadersParams
): IConfirmSalesHeadersAction => ({
	type: CONFIRM_SALES_HEADERS,
	payload
})

export const confirmSalesHeadersSuccess = (payload: any) => ({
	type: CONFIRM_SALES_HEADERS_SUCCESS,
	payload
})

export const confirmSalesHeadersError = (payload: any) => ({
	type: CONFIRM_SALES_HEADERS_ERROR,
	payload
})

export const validateBarCodeSales = (
	payload: IValidateBarCodeParams
): IValidateBarCodeAction => ({
	type: VALIDATE_BARCODE_SALES_HEADERS,
	payload
})

export const validateBarCodeSalesSuccess = (payload: any) => ({
	type: VALIDATE_BARCODE_SALES_HEADERS_SUCCESS,
	payload
})

export const clearSalesHeaderFilters = (payload: any) => ({
	type: CLEAR_SALES_HEADER_FILTERS,
	payload
})

export const setSalesHeaderFilters = (payload: any) => ({
	type: SET_SALES_HEADER_FILTERS,
	payload
})
