import { HeaderDataValidationActions } from 'constants/ActionsTypesTs'
import {
	IConfirmValidationSuccessResponse,
	IGetValidationsSuccessResponse
} from 'models/HeaderDataValidation'

export interface valueState {
	validationList: IGetValidationsSuccessResponse | null
	confirmValidation: IConfirmValidationSuccessResponse | null
}

const initialState = {
	validationList: null,
	confirmValidation: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case HeaderDataValidationActions.GET_VALIDATIONS:
			return { ...state, validationList: null }
		case HeaderDataValidationActions.GET_VALIDATIONS_SUCCESS:
			return { ...state, validationList: action.payload }
		case HeaderDataValidationActions.CONFIRM_VALIDATIONS:
			return { ...state, confirmValidation: null }
		case HeaderDataValidationActions.CONFIRM_VALIDATIONS_SUCCESS:
			return { ...state, confirmValidation: action.payload }

		default:
			return state
	}
}

export default rootReducer
