import { getValuesDataSuccessInterface } from 'constants/valuesInterfaces/interfaces'
import {
	ICrudApplyLedgerAccountsPointers,
	ICrudGetLedgerAccountsPointersResArray
} from 'models/CrudLedgerAccountsInterface'
import { IResponseResult } from 'models/ResultsInterface'
import {
	CRUD_APPLY_LEDGER_ACCOUNTS_POINTERS,
	CRUD_APPLY_LEDGER_ACCOUNTS_POINTERS_SUCCESS,
	CRUD_GET_LEDGER_ACCOUNTS_POINTERS,
	CRUD_GET_LEDGER_ACCOUNTS_POINTERS_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetLedgerAccountsPointers = (payload: any) => {
	return {
		type: CRUD_GET_LEDGER_ACCOUNTS_POINTERS,
		payload
	}
}

export const crudGetLedgerAccountsPointersSuccess = (
	payload: ICrudGetLedgerAccountsPointersResArray
): getValuesDataSuccessInterface => {
	return {
		type: CRUD_GET_LEDGER_ACCOUNTS_POINTERS_SUCCESS,
		payload
	}
}

export const crudApplyLedgerAccountsPointers = (
	payload: ICrudApplyLedgerAccountsPointers
): any => {
	return {
		type: CRUD_APPLY_LEDGER_ACCOUNTS_POINTERS,
		payload
	}
}

export const crudApplyLedgerAccountsPointersSuccess = (
	payload: IResponseResult
): any => {
	return {
		type: CRUD_APPLY_LEDGER_ACCOUNTS_POINTERS_SUCCESS,
		payload
	}
}
