import {
	purchasesAffectedConfirm,
	purchasesAffectedSubCalculation,
	purchasesAffectedValidate,
	purchasesQuantityAffected
} from 'actions/'
import FiltersTable from 'components/common/filtersTable'
import StockProvider from 'components/stockProvider/stockProvider'
import { P_AFEC_CANT_CPA } from 'constants/ConfigProcessNames'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import styles from '../voucherInvolvement/involvementTable.module.scss'
import InvolvementTotalResume from '../voucherInvolvement/involvementTotalResume'
import AffectationTable from './AffectationTable'

class ProviderAffectationTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			total_item: 0,
			total_cant: 0,
			total_importe: 0,
			ComprobAvencer: 0,
			OpcionMuestra: 0,
			showStock: false,
			referenceItem: null,
			params: {},
			queryFilters: {}
		}

		this.filtersKey = {
			Cant_pend: 'filtro_cant_pend',
			Cod_prod: 'filtro_cod_prod',
			Cod_unid: 'filtro_cod_unid',
			Cod_barra: 'filtro_codbarra',
			Comprob_desc: 'filtro_comprob_desc',
			Comprob_nro: 'filtro_comprob_nro',
			Desc_prod: 'filtro_desc_prod',
			Fec_emis: 'filtro_fec_emis',
			Fec_vto: 'filtro_fec_vto',
			nimovpro: 'filtro_niprod',
			pcio_unit: 'filtro_precio_unit',
			Presentacion: 'filtro_presentacion'
		}
	}

	componentDidMount = () => {
		const { ComprobAvencer, OpcionMuestra } = this.state
		const { idOperacion } = this.props

		if (idOperacion) {
			this.props.purchasesQuantityAffected({
				ComprobAvencer,
				OpcionMuestra,
				idOperacion,
				page_number: 1,
				page_size: 10
			})
		}
	}

	componentDidUpdate = (prevProps) => {
		const {
			subCalculations,
			cantValidate,
			salesconfirm,
			purchasedProducts,
			paramsValidate,
			canceledStock
		} = this.props

		if (
			cantValidate !== prevProps.cantValidate &&
			cantValidate &&
			cantValidate.Items.length > 0
		) {
			if (cantValidate.Items[0].ind_stock === 1) {
				this.setState({
					showStock: true,
					referenceItem: cantValidate.Items[0].it,
					params: {
						Niprod: paramsValidate.items[0].niprod,
						cantidad: cantValidate.Items[0].Cant_afec
					}
				})
			}
		}

		if (canceledStock !== prevProps.canceledStock && canceledStock) {
			const { ComprobAvencer, OpcionMuestra } = this.state
			const { idOperacion } = this.props
			this.props.purchasesQuantityAffected({
				ComprobAvencer,
				OpcionMuestra,
				idOperacion,
				page_number: 1,
				page_size: 10
			})
		}

		if (
			prevProps.purchasedProducts !== purchasedProducts &&
			purchasedProducts
		) {
			this.setState({
				total_item: purchasedProducts.total_item,
				total_cant: purchasedProducts.total_cant,
				total_importe: purchasedProducts.total_importe
			})
		}

		if (
			prevProps.subCalculations !== subCalculations &&
			!prevProps.subCalculations
		) {
			this.setState({
				total_item: subCalculations.total_item,
				total_cant: subCalculations.total_cant,
				total_importe: subCalculations.total_importe
			})
		}

		if (prevProps.cantValidate !== cantValidate && !prevProps.cantValidate) {
			this.setState({
				total_item: cantValidate.total_item,
				total_cant: cantValidate.total_cant,
				total_importe: cantValidate.total_importe
			})
		}

		if (
			prevProps.salesconfirm !== salesconfirm &&
			!prevProps.salesconfirm &&
			salesconfirm
		) {
			this.setState({
				total_item: salesconfirm.total_item,
				total_cant: salesconfirm.total_cant,
				total_importe: salesconfirm.total_importe
			})
		}
	}

	onChangeTable = (type, pagination) => {
		const { idOperacion } = this.props
		const queryFilters = {}

		if (type === 'filter') {
			const filters = _.keys(pagination.filters)
			filters.forEach((filter) => {
				queryFilters[this.filtersKey[filter]] =
					pagination.filters[filter].filterVal
			})

			this.setState({ queryFilters })
		}

		this.props.purchasesQuantityAffected({
			idOperacion,
			page_number: pagination.page,
			page_size: pagination.sizePerPage,
			...queryFilters
		})
	}

	handleGetCant = (e) => {
		const comprobante = e.target.checked ? 1 : 0
		const { OpcionMuestra } = this.state
		const { idOperacion } = this.props

		this.setState({ ComprobAvencer: comprobante })
		this.props.purchasesQuantityAffected({
			ComprobAvencer: comprobante,
			OpcionMuestra,
			idOperacion,
			page_number: 1,
			page_size: 10
		})
	}

	handleChangeSelect = (value) => {
		const { ComprobAvencer } = this.state
		const { idOperacion } = this.props

		this.setState({ OpcionMuestra: value })
		this.props.purchasesQuantityAffected({
			ComprobAvencer,
			OpcionMuestra: value,
			idOperacion,
			page_number: 1,
			page_size: 10
		})
	}

	handleCloseStockProvider = () => {
		this.setState({ showStock: false })
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	render() {
		const {
			t,
			purchasedProducts,
			readOnly,
			config,
			productsUpdate,
			cantValidate,
			paramsValidate,
			idOperacion,
			canceledStock,
			callBackReturn
		} = this.props
		const { showStock, params, referenceItem } = this.state
		const inputConfig = [
			{
				idCampo: 'checkComprobAvencer',
				label: t('voucherInvolvement.form.sample'),
				visible: 1,
				requerido: 0,
				editable: 1
			}
		]
		const customCol = readOnly ? 4 : { span: 5, offset: 7 }

		return (
			<Fragment>
				<Row style={{ marginLeft: '0px' }}>
					{!readOnly && (
						<Fragment>
							<FiltersTable
								handleGetCant={this.handleGetCant}
								t={t}
								inputConfig={inputConfig}
								handleChangeSelect={(value) => this.handleChangeSelect(value)}
							/>
							<Col lg={3} md={6} className={'mb-2'}>
								{purchasedProducts && (
									<InvolvementTotalResume
										classDiv={'pl-5'}
										formatCol={{ span: 11, offset: 1 }}
										data={this.state}
									/>
								)}
							</Col>
						</Fragment>
					)}
					{readOnly && (
						<Col sm={12}>
							<InvolvementTotalResume
								formatCol={{ span: 4 }}
								data={this.state}
							/>
						</Col>
					)}
				</Row>
				<Col sm={12} className={`pb-2 pl-0`}>
					<StockProvider
						params={params}
						showStock={showStock}
						idOperacion={idOperacion}
						referenceItem={referenceItem}
						handleClose={this.handleCloseStockProvider}
						handleCloseCartMessage={this.handleCloseError}
					/>
					{!_.isEmpty(config) && (
						<AffectationTable
							products={purchasedProducts}
							productsUpdate={productsUpdate}
							filtersKey={this.filtersKey}
							keysValidate={{ label: 'Nimovpro', field: 'nimovpro' }}
							queryFilters={this.state.queryFilters}
							cantValidate={cantValidate}
							paramsValidate={paramsValidate}
							config={config}
							readOnly={readOnly}
							idOperacion={this.props.idOperacion}
							handleChangeTable={this.onChangeTable}
							formConfirmation={this.props.formConfirmation}
							handleCantValidate={this.props.purchasesAffectedValidate}
							handleAffectedConfirm={this.props.purchasesAffectedConfirm}
							handleAffectedSubCalculation={
								this.props.purchasesAffectedSubCalculation
							}
							handleMoveArrow={this.props.handleMoveArrow}
							canceledStock={canceledStock}
							callBackReturn={callBackReturn}
						/>
					)}
					{purchasedProducts && (
						<InvolvementTotalResume
							classDiv={'pl-0'}
							formatCol={customCol}
							data={this.state}
						/>
					)}
				</Col>
			</Fragment>
		)
	}
}

const mapStateToProps = ({
	vouchertype,
	purchasesAffected,
	voucher,
	stockProvider
}) => {
	const config = voucher.config ? voucher.config[P_AFEC_CANT_CPA] : null
	const { voucherType } = vouchertype
	const { purchasedProducts, productsUpdate, cantValidate, paramsValidate } =
		purchasesAffected
	const { canceledStock } = stockProvider

	return {
		voucherType,
		config,
		purchasedProducts,
		productsUpdate,
		cantValidate,
		paramsValidate,
		canceledStock
	}
}

const formConnect = connect(mapStateToProps, {
	purchasesQuantityAffected,
	purchasesAffectedValidate,
	purchasesAffectedConfirm,
	purchasesAffectedSubCalculation
})(withTranslation()(ProviderAffectationTable))

export default themr('ProviderAffectationTableStyles', styles)(formConnect)
