import {
	CLEAR_SUGESTED_PRODUCT_STORE,
	GET_SUGESTED_PRODUCTS,
	GET_SUGESTED_PRODUCTS_SUCCESS,
	SET_SUGESTED_PRODUCT_PRIORITY,
	SET_SUGESTED_PRODUCT_PRIORITY_SUCCESS,
	SET_SUGESTED_PRODUCT_SELECTION,
	SET_SUGESTED_PRODUCT_SELECTION_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	products: any
	productSelection: any
	productPriority: any
}

const initialState = {
	products: null,
	productSelection: null,
	productPriority: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case GET_SUGESTED_PRODUCTS:
			return { ...state, products: null }
		case GET_SUGESTED_PRODUCTS_SUCCESS:
			return { ...state, products: action.payload }
		case SET_SUGESTED_PRODUCT_SELECTION:
			return { ...state, productSelection: null }
		case SET_SUGESTED_PRODUCT_SELECTION_SUCCESS:
			return { ...state, productSelection: action.payload }
		case SET_SUGESTED_PRODUCT_PRIORITY:
			return { ...state, productPriority: null }
		case SET_SUGESTED_PRODUCT_PRIORITY_SUCCESS:
			return { ...state, productPriority: action.payload }
		case CLEAR_SUGESTED_PRODUCT_STORE:
			return { ...initialState }
		default:
			return state
	}
}

export default rootReducer
