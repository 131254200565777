import NotificationMessage from 'components/common/notificationMessage'
import { ERROR_GET_IMPDOCS } from 'constants/ActionsTypes'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
	collectionImportDoc,
	confirImpDocuments,
	getCollections,
	getImpDocuments
} from '../../actions'
import CollectionImportDocs from './collectionImportDocs'
import CollectionImportTable from './collectionImportTable'

class CollectionImportForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			showError: false,
			errorMessage: '',
			urlForm: '',
			buttonClicked: null,
			showImpTable: false
		}

		this.firtsRefs = React.createRef()
	}

	componentDidUpdate = (prevProps) => {
		const { impConfirm, idOperacion, impDocuments, docsImported } = this.props
		if (
			prevProps.impConfirm !== impConfirm &&
			impConfirm &&
			impConfirm.Resultado
		) {
			if (this.state.buttonClicked === 'accept') {
				this.props.getCollections({
					idOperacion,
					page_size: 10,
					page_number: 1
				})
			}
			this.props.closeWindow()
		}

		if (prevProps.docsImported !== this.props.docsImported && docsImported) {
			this.setState({ showImpTable: true })
		}

		if (
			prevProps.impDocuments !== impDocuments &&
			impDocuments &&
			impDocuments.error === ERROR_GET_IMPDOCS
		) {
			this.props.changeModal()
		}
	}

	handleImport = ({ fileUpload }) => {
		const { idOperacion, cat_item, nicfondo } = this.props
		this.props.collectionImportDoc({
			idOperacion,
			archivo: fileUpload,
			nicfondo,
			cat_item
		})
	}

	handleErrorForm = (errorObject) => {
		this.setState({ ...errorObject })
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	handleChangeTable = ({ mostrar }) => {
		const { idOperacion, nicfondo, cat_item } = this.props
		this.props.getImpDocuments({
			idOperacion,
			page_number: 1,
			page_size: 10,
			nicfondo,
			cat_item,
			mostrar
		})
	}

	handleConfirmation = (button) => {
		const { idOperacion } = this.props
		this.setState({ buttonClicked: button })
		this.props.confirImpDocuments({ idOperacion })
	}

	render() {
		const { idOperacion } = this.props

		return (
			<Row className={'pt-3'}>
				<Col sm={12} className={'mb-1'}>
					<NotificationMessage
						{...this.state}
						handleCloseError={this.handleCloseError}
						type={this.state.typeNotifaction}
					/>
				</Col>

				<Col md={12} className={'mb-1'}>
					<CollectionImportDocs
						{...this.props}
						handleImport={this.handleImport}
						handleError={this.handleErrorForm}
						handleChangeTable={this.handleChangeTable}
						handleConfirmation={this.handleConfirmation}
						impSuccess={this.state.showImpTable}
					/>
				</Col>
				<Col sm={12}>
					<CollectionImportTable
						idOperacion={idOperacion}
						cat_item={this.props.cat_item}
						nicfondo={this.props.nicfondo}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = ({ collections }) => {
	const { confirmDocs, impDocuments, impConfirm, docsImported } = collections
	return { confirmDocs, impDocuments, impConfirm, docsImported }
}

export default connect(mapStateToProps, {
	getImpDocuments,
	collectionImportDoc,
	confirImpDocuments,
	getCollections
})(withTranslation()(withRouter(CollectionImportForm)))
