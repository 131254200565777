import { cancelVoucher, printVoucherDetail, removeVoucher } from 'actions'
import CommonLabelCol from 'components/common/CommonLabelCol'
import ConfirmModal from 'components/common/ConfirmModal'
import InputButton from 'components/form/inputButton'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import {
	IGetDetailVoucherHeadboardResponse,
	IGetItemCategoriesParams
} from 'models/SearchVoucher'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'underscore'
import { SEARCH_VOUCHER } from 'utils/RoutePath'

interface DetailProps extends WithTranslation {
	t?: any
	voucherInfo: Partial<IGetDetailVoucherHeadboardResponse>
	fields: Array<IFieldStructure>
	authUser: any
	voucherId: string
	removeVoucher: ({ idEmpresa, id }: IGetItemCategoriesParams) => void
	cancelVoucher: ({ idEmpresa, id }: IGetItemCategoriesParams) => void
	printVoucherDetail: ({ idEmpresa, id }: IGetItemCategoriesParams) => void
}

type DetailState = {}

class DetailHeader extends Component<DetailProps, DetailState> {
	handleRemove = () => {
		const { authUser, voucherId } = this.props
		this.props.removeVoucher({ idEmpresa: authUser.userCompany, id: voucherId })
	}

	handleCancel = () => {
		const { authUser, voucherId } = this.props
		this.props.cancelVoucher({ idEmpresa: authUser.userCompany, id: voucherId })
	}

	handlePrint = () => {
		const { authUser, voucherId } = this.props
		this.props.printVoucherDetail({
			idEmpresa: authUser.userCompany,
			id: voucherId
		})
	}

	render() {
		const { voucherInfo, fields, t } = this.props

		const propsPrint = _.findWhere(fields, { idCampo: 'imprimir' })
		const propsRemove = _.findWhere(fields, { idCampo: 'eliminar' })
		const propsDisabled = _.findWhere(fields, { idCampo: 'anular' })

		return (
			<Fragment>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="comprob"
						value={voucherInfo.comprob || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="numero"
						value={voucherInfo.numero || ''}
					/>
					<Col className={'pb-1'}>
						<Link to={`${SEARCH_VOUCHER}/filters`}>
							<InputButton
								valueButton={t('global.return_button')}
								onClick={() => {}}
								type={'primary'}
								customStyle={{ minWidth: '125px' }}
							/>
						</Link>
					</Col>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="descrip"
						value={voucherInfo.descrip || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="fecha"
						value={voucherInfo.fecha || ''}
					/>
					<Col className={'pb-1'}>
						{propsPrint && propsPrint.visible && (
							<InputButton
								valueButton={propsPrint.label}
								onClick={this.handlePrint}
								type={'primary'}
								customStyle={{ minWidth: '125px' }}
							/>
						)}
						{propsRemove && propsRemove.visible && (
							<ConfirmModal
								messageBody={t(
									'search_voucher.detail.remove_confirmation_message'
								)}
								onSubmitModal={this.handleRemove}
								labelButton={propsRemove.label}
								modalTitle={t('search_voucher.detail.remove_voucher')}
								buttonStyle={{
									minWidth: '125px',
									backgroundColor: '#dc3545',
									borderColor: 'red'
								}}
							/>
						)}
					</Col>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 10 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="rsoc"
						value={
							voucherInfo.rsoc !== ''
								? `${voucherInfo.codigo} ${voucherInfo.rsoc}`
								: ''
						}
					/>
					<Col className={'pb-1'}>
						{propsDisabled && propsDisabled.visible && (
							<ConfirmModal
								messageBody={t(
									'search_voucher.detail.cancel_confirmation_message'
								)}
								onSubmitModal={this.handleCancel}
								labelButton={propsDisabled.label}
								modalTitle={t('search_voucher.detail.cancel_voucher')}
								buttonStyle={{ minWidth: '125px' }}
							/>
						)}
					</Col>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="cuit"
						value={voucherInfo.cuit || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="estado"
						value={voucherInfo.estado || ''}
					/>
					<Col />
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="suc_clie"
						value={voucherInfo.suc_clie || ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="vers"
						value={`${voucherInfo.vers}` || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="nro_int"
						value={`${voucherInfo.nro_int}` || ''}
					/>
					<Col />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth }: any) => {
	const { authUser } = auth
	return { authUser }
}

const connectForm = connect(mapStateToProps, {
	cancelVoucher,
	removeVoucher,
	printVoucherDetail
})(DetailHeader)
export default withTranslation()(connectForm)
