import { CalculationBonusesActions } from 'constants/ActionsTypesTs'
import {
	IConfirmBonusesAction,
	IConfirmBonusesParams,
	IConfirmBonusesSuccessAction,
	IGetBonusesAction,
	IGetBonusesParams,
	IGetBonusesResponse,
	IGetBonusesSuccessAction
} from 'models/CalculationBonuses'
import { IResponseResult } from 'models/ResultsInterface'

export const getBonuses = (payload: IGetBonusesParams): IGetBonusesAction => ({
	type: CalculationBonusesActions.GET_BONUSES,
	payload
})

export const getBonusesSuccess = (
	payload: IGetBonusesResponse
): IGetBonusesSuccessAction => ({
	type: CalculationBonusesActions.GET_BONUSES_SUCCESS,
	payload
})

export const confirmBonuses = (
	payload: IConfirmBonusesParams
): IConfirmBonusesAction => ({
	type: CalculationBonusesActions.CONFIRM_BONUSES,
	payload
})

export const confirmBonusesSuccess = (
	payload: IResponseResult
): IConfirmBonusesSuccessAction => ({
	type: CalculationBonusesActions.CONFIRM_BONUSES_SUCCESS,
	payload
})
