import { confirmSalesHeaders, getConfigVoucher } from 'actions'
import withMenu from 'components/common/withMenu'
import HeaderSelectionTable from 'components/HeaderSelection/HeaderSelectionTable'
import GlobalContainer from 'components/layout/globalContainer'
import { P_SELCAB_CPA } from 'constants/ConfigProcessNames'
import { IConfirmSalesHeadersParams } from 'models/Budget'
import { Ihistory } from 'models/NavigationInterface'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

interface IHeaderSelectionProps {
	history: Ihistory
	confirmSalesHeaders: (payload: IConfirmSalesHeadersParams) => void
	match: any
	getConfigVoucher: any
}

export class HeaderSelection extends Component<IHeaderSelectionProps> {
	myRef: any = React.createRef()

	/**
	 * redirect next page
	 * @param url
	 */
	handleConfirmationForm = (url: string) => {
		this.props.history.push(url)
	}

	handleCallBackButton = () => {
		this.myRef.handleConfirmForm()
	}

	render() {
		const containerProps = {
			codeProcess: P_SELCAB_CPA,
			callBackButton: this.handleCallBackButton,
			nextPage: true
		}

		const propsTable = {
			budgetTableRef: (ref: any) => (this.myRef = ref)
		}

		return (
			<Row>
				<GlobalContainer {...containerProps}>
					<HeaderSelectionTable {...propsTable} />
				</GlobalContainer>
			</Row>
		)
	}
}

const mapStateToProps = ({ auth }: any) => ({})

const mapDispatchToProps = { confirmSalesHeaders, getConfigVoucher }

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withMenu(HeaderSelection)))
