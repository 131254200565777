import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getValueMask } from 'lib/MaskValues'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'

interface LabelProps {
	fields: Array<IFieldStructure>
	labelId: string
	value: any
	colLabel?: any
	colValue?: any
	formCol?: any
	typeLabel?: string
	authUser?: any
}

type LabelState = {
	configLabel: IFieldStructure | undefined
}

class CommonLabelCol extends Component<LabelProps, LabelState> {
	constructor(props: LabelProps) {
		super(props)
		this.state = { configLabel: undefined }
	}

	componentDidMount = () => {
		const { labelId, fields } = this.props
		const configLabel = _.findWhere(fields, { idCampo: labelId })
		this.setState({ configLabel })
	}

	componentDidUpdate = (prevProps: LabelProps) => {
		const { fields, labelId } = this.props
		if (prevProps.fields !== fields && !_.isEmpty(fields)) {
			const configLabel = _.findWhere(fields, { idCampo: labelId })
			this.setState({ configLabel })
		}
	}

	/**
	 * render labels and value with the grid system
	 */
	renderColBlock = (configLabel: IFieldStructure) => {
		const { value, colLabel, formCol, colValue } = this.props

		return (
			<Col {...formCol}>
				<Row>
					<Col {...colLabel} className="font-weight-bold">
						{configLabel.label}
					</Col>
					<Col {...colValue}> {this.renderValue(value)} </Col>
				</Row>
			</Col>
		)
	}

	renderFlexBlock = (configLabel: IFieldStructure) => {
		const { value, formCol } = this.props

		return (
			<Col {...formCol} className="d-flex flex-row">
				<div className="p-2 font-weight-bold">{configLabel.label}</div>
				<div className="p-2">{this.renderValue(value)}</div>
			</Col>
		)
	}

	renderValue = (value: any): any => {
		const { configLabel } = this.state
		const { authUser } = this.props
		if (configLabel?.mascara) {
			if (_.isObject(value)) {
				return value
			}

			return getValueMask(value, configLabel.mascara, { authUser })
		}

		return `${value}`
	}

	render() {
		const { configLabel } = this.state
		const { typeLabel } = this.props

		if (configLabel) {
			if (typeLabel === 'flex') {
				return this.renderFlexBlock(configLabel)
			} else {
				return this.renderColBlock(configLabel)
			}
		}
		return <div />
	}
}

const mapStateToProps = ({ auth }: any) => {
	const { authUser } = auth

	return { authUser }
}

export default connect(mapStateToProps, {})(CommonLabelCol)
