import { clearItemStore, getCartItemInfo, updateCartItem } from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { Formik } from 'formik'
import { getValidationSchema } from 'lib/FieldValidations'
import React, { Component, Fragment } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Label } from 'reactstrap'

const noRenderInputs = ['sig_proceso', 'fin_item', 'modif_pcio', 'ind_stock']

class IndividualEditProduct extends Component {
	constructor(props) {
		super(props)
		this.state = {
			codUnidOptions: []
		}
	}

	componentDidMount = () => {
		const { idOperacion, product } = this.props
		this.props.getCartItemInfo({ idOperacion, nitem: product.nitem })
	}

	componentDidUpdate = (prevProps) => {
		const { updateItemCart, itemInfo } = this.props
		if (updateItemCart !== prevProps.updateItemCart && updateItemCart) {
			this.props.handleCloseModal()
		}
		if (itemInfo !== prevProps.itemInfo && itemInfo) {
			const codUnidOptions = itemInfo
				? Array.from(
						new Set(
							itemInfo.presentaciones.map((pre) => ({
								id: pre.cod_pres,
								label: pre.desc_pres
							}))
						)
				  )
				: []
			this.setState({ codUnidOptions })
		}
	}

	componentWillUnmount = () => {
		this.props.clearItemStore()
	}

	/**
	 * to show the form fields from the api configuration     *
	 */
	renderFormInputs = (formikProps) => {
		const { config } = this.props
		const result = []

		if (config) {
			config.campos.forEach((field) => {
				const fieldId = field.idCampo.trim()

				if (
					field.editable &&
					field.visible &&
					!noRenderInputs.includes(fieldId)
				) {
					result.push(
						<Col key={field.idCampo}>
							{this.renderInput(field, formikProps)}
						</Col>
					)
				}
			})
		}

		return result
	}

	/**
	 * To update state data in the onblur event
	 * @params value
	 * @params fieldId
	 * @params row
	 */

	handleOnblurInput = async (value, fieldId, formikProps) => {
		const { setFieldValue, values } = formikProps
		this.setState({ isUpdateRecord: fieldId })

		if (value) {
			if (fieldId === 'cantidad') {
				setFieldValue('neto', parseFloat(value * values.pcio_unit))
			} else if (fieldId === 'pcio_unit') {
				setFieldValue('neto', parseFloat(values.cantidad * value))
			} else if (fieldId === 'neto') {
				if (values.pcio_unit > 0) {
					setFieldValue('cantidad', Math.round(value / values.pcio_unit))
				}
			}
		}
	}

	/**
	 * to show the form fields from the api configuration
	 * @param field
	 * @param value
	 */
	renderInput = (field, formikProps) => {
		const { values, handleChange, setFieldValue } = formikProps

		const fieldId = field.idCampo.trim()
		let result = null

		const optionsInput = {
			inputFormCol: { sm: 12 },
			fields: [{ ...field, label: false }],
			label: field.label,
			inputId: `${fieldId}`,
			id: `${fieldId}`,
			name: `${fieldId}`,
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			divStyle: { paddingLeft: '10px', paddingRight: '10px' },
			disable: false,
			value: values[fieldId] || '',
			rowStyle: { marginBottom: '5px' },
			onBlur: (data) => this.handleOnblurInput(data, fieldId, formikProps),
			onChange: (value) => {
				setFieldValue(fieldId, value)
			}
		}

		if (fieldId === 'cod_unid') {
			const { codUnidOptions } = this.state

			result = (
				<InputDropdown
					{...optionsInput}
					options={codUnidOptions}
					onChange={handleChange}
				/>
			)
		} else if (field.editable) {
			result = <InputText {...optionsInput} />
		}

		return result
	}

	/**
	 * to update item of shopping cart
	 * @param {object} values
	 */
	handleEditItemCar = (values) => {
		const { idOperacion, config } = this.props

		const params = {}
		config.campos.forEach((field) => {
			const fieldId = field.idCampo.trim()
			if (
				field.editable &&
				field.visible &&
				!noRenderInputs.includes(fieldId)
			) {
				params[fieldId] = values[fieldId]
			}
		})
		this.props.updateCartItem({
			idOperacion,
			...params,
			nitem: values.nitem,
			Niprod: values.niprod,
			cod_unid: values.cod_unid
		})
	}

	render() {
		const { config, t, itemInfo } = this.props
		const validationSchema = config
			? getValidationSchema(config.campos, t, [])
			: {}
		const descProd = itemInfo ? itemInfo.desc_prod : ''
		return (
			<Formik
				initialValues={itemInfo}
				onSubmit={(values, actions) => {
					this.handleEditItemCar(values)
				}}
				validationSchema={validationSchema}
				enableReinitialize={true}
				render={(props) => (
					<Fragment>
						<Col sm={12}>
							<NotificationError
								errors={props.errors}
								touched={props.touched}
							/>
						</Col>
						<Form onSubmit={props.handleSubmit} className="voucher-info-form">
							<Row>
								<Col sm={2}>
									<Label style={{ fontSize: '10pt', marginLeft: '28px' }}>
										{'Producto'}
									</Label>
								</Col>
								<Col sm={10}>
									<Label style={{ fontSize: '10pt', marginLeft: '15px' }}>
										{descProd}
									</Label>
								</Col>
							</Row>

							{this.renderFormInputs(props)}
							<Row className={'pt-3'}>
								<Col sm={6} className={'text-center mt-2'}>
									<Button
										style={{ minWidth: '145px' }}
										type={'submit'}
										disabled={!itemInfo}
									>
										{t('Collection.form.buttons.accept')}
									</Button>
								</Col>
								<Col sm={6} className={'text-center mt-2'}>
									<Button
										style={{ minWidth: '145px' }}
										type={'button'}
										disabled={!itemInfo}
										onClick={this.props.handleCloseModal}
									>
										{t('Collection.form.buttons.cancel')}
									</Button>
								</Col>
							</Row>
						</Form>
					</Fragment>
				)}
			/>
		)
	}
}

const mapStateToProps = ({ product, loadItems }) => {
	const { productsCart, search } = product
	const { updateItemCart, itemInfo } = loadItems

	return { search, productsCart, updateItemCart, itemInfo }
}

const connectForm = connect(mapStateToProps, {
	getCartItemInfo,
	updateCartItem,
	clearItemStore
})(IndividualEditProduct)

export default withTranslation()(connectForm)
