import CommonLabelCol from 'components/common/CommonLabelCol'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import React, { Component } from 'react'
import { Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
interface IExpandStateFormProps {
	row: any
	fields: Array<IFieldStructure>
}

class ExpandStateForm extends Component<IExpandStateFormProps> {
	/**
	 * to show the form fields from the api configuration     *
	 */
	renderInputs = () => {
		const { row, fields } = this.props
		return fields.map((field) => {
			const idField = field.idCampo.trim()
			return this.renderLabelCol(
				fields,
				idField,
				row[idField] !== null ? row[idField] : ''
			)
		})
	}

	/**
	 * render label col
	 */
	renderLabelCol = (
		fields: Array<IFieldStructure>,
		fieldId: string,
		value: string
	) => {
		return (
			<CommonLabelCol
				// colLabel={{ sm: 3 }}
				fields={fields}
				labelId={fieldId}
				key={fieldId}
				value={value}
			/>
		)
	}

	render() {
		return (
			<Container
				className="pr-0 "
				style={{ border: '1px solid #ccc', backgroundColor: '#fff' }}
			>
				<Row>{this.renderInputs()}</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandStateForm)
