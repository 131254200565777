import Axios from 'axios'
import {
	ICancelAttributesParams,
	IConfirmAttributesParams,
	IGetAttributesParams
} from 'models/Attributes'
const urlBase = 'carga_item_vta/atributos'

export const getAttributesApi = async (params: IGetAttributesParams) => {
	const response = await Axios.get(urlBase, { params })
	return response.data
}

export const confirmAttributesApi = async (
	params: IConfirmAttributesParams
) => {
	const response = await Axios.post(`${urlBase}/confirmar`, params)
	return response.data
}

export const cancelAttributesApi = async (params: ICancelAttributesParams) => {
	const response = await Axios.post(`${urlBase}/cancelar`, params)
	return response.data
}
