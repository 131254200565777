import {
	getFilterCurrency,
	getFilterPaymentConditions,
	getFilterSellers
} from 'actions'
import InputDropdown from 'components/form/inputDropdown'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getOptionsDropDown, handleSetValueInput } from 'lib/FormUtils'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'
import styles from './SearchVoucher.module.scss'

type SearchFormProps = {
	t: any
	values: any
	handleChange: any
	handleBlur: any
	setFieldValue: any
	readOnly: boolean
	fields: Array<IFieldStructure>
	setFieldTouched: any
	filterPayments: any
	filterCurrencies: any
	filterSellers: any
	getFilterPaymentConditions: ({ module: string }: any) => void
	getFilterSellers: () => void
	getFilterCurrency: () => void
}

class AdvancedSearchFormInput extends Component<SearchFormProps> {
	componentDidMount = () => {
		const { filterPayments, filterCurrencies, filterSellers } = this.props
		if (_.isEmpty(filterPayments)) {
			this.props.getFilterPaymentConditions({ module: '' })
		}

		if (_.isEmpty(filterCurrencies)) {
			this.props.getFilterCurrency()
		}

		if (_.isEmpty(filterSellers)) {
			this.props.getFilterSellers()
		}
	}

	render() {
		const {
			t,
			values,
			handleBlur,
			readOnly,
			fields,
			setFieldTouched,
			setFieldValue,
			filterPayments,
			filterCurrencies,
			filterSellers
		} = this.props

		const formikProps = { setFieldTouched, setFieldValue }

		const propsCondPay = {
			inputFormCol: { sm: 8 },
			fields,
			label: t('search_voucher.module'),
			inputId: 'cond_pago',
			name: 'cond_pago',
			placeholder: '',
			colLabel: 'col-sm-5',
			colInput: 'col-sm-7',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.cond_pago,
			options: getOptionsDropDown(filterPayments),
			noInitialExecute: true,
			onChange: (data: any) =>
				handleSetValueInput(data, 'cond_pago', formikProps)
		}

		const propsSeller = {
			inputFormCol: { sm: 8 },
			fields,
			label: t('search_voucher.module'),
			inputId: 'vendedor',
			name: 'vendedor',
			placeholder: '',
			colLabel: 'col-sm-5',
			colInput: 'col-sm-7',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.vendedor,
			options: getOptionsDropDown(filterSellers),
			noInitialExecute: true,
			onChange: (data: any) =>
				handleSetValueInput(data, 'vendedor', formikProps)
		}

		const propsCurrency = {
			inputFormCol: { sm: 8 },
			fields,
			label: t('search_voucher.module'),
			inputId: 'moneda',
			name: 'moneda',
			placeholder: '',
			colLabel: 'col-sm-5',
			colInput: 'col-sm-7',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.moneda,
			options: getOptionsDropDown(filterCurrencies),
			noInitialExecute: true,
			onChange: (data: any) => handleSetValueInput(data, 'moneda', formikProps)
		}

		return (
			<Row>
				<Col sm={12} className={styles.module_search_row}>
					<InputDropdown {...propsCondPay} />
				</Col>
				<Col sm={12} className={styles.module_search_row}>
					<InputDropdown {...propsSeller} />
				</Col>
				<Col sm={12} className={styles.module_search_row}>
					<InputDropdown {...propsCurrency} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = ({ searchVoucher }: any) => {
	const { filterPayments, filterCurrencies, filterSellers } = searchVoucher
	return { filterPayments, filterCurrencies, filterSellers }
}

export default connect(mapStateToProps, {
	getFilterPaymentConditions,
	getFilterSellers,
	getFilterCurrency
})(AdvancedSearchFormInput)
