import Multiselect from 'multiselect-react-dropdown'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import _ from 'underscore'

interface ICommonMultiSelectProps extends WithTranslation {
	options: any
	onSelect: (items: Array<object>, item: object) => void
	onRemove: (items: Array<object>, item: object) => void
	placeholder?: string
	inputClass?: any
	style?: any
	t?: any
}
interface ICommonMultiSelectState {
	selectedList: Array<any>
	checkedAll: boolean
}

class CommonMultiSelect extends Component<
	ICommonMultiSelectProps,
	ICommonMultiSelectState
> {
	constructor(props: ICommonMultiSelectProps) {
		super(props)
		this.state = {
			selectedList: [],
			checkedAll: false
		}
	}

	/**
	 * handle select and unselect all
	 * @param event
	 */
	handleSelectAll = (event: any) => {
		const { checked } = event.target
		const { options, onSelect, onRemove } = this.props

		if (checked) {
			onSelect(options, {})
		} else {
			onRemove([], {})
		}
		this.setState({
			selectedList: checked ? [...options] : [],
			checkedAll: checked
		})
	}

	/**
	 * to handle dropdown select
	 * @param selectedList
	 * @param selectedItem
	 */
	handleSelect = (selectedList: Array<any>, selectedItem: object) => {
		this.props.onSelect(selectedList, selectedItem)
		const { options } = this.props
		this.setState({
			selectedList,
			checkedAll: _.isEqual(selectedList, options)
		})
	}

	/**
	 * to handle dropdown select
	 * @param selectedList
	 * @param selectedItem
	 */
	handleRemoveSelect = (selectedList: Array<any>, selectedItem: object) => {
		this.setState({
			selectedList,
			checkedAll: false
		})
		this.props.onRemove(selectedList, selectedItem)
	}

	render() {
		const { options, placeholder, inputClass, t } = this.props
		const { selectedList, checkedAll } = this.state
		const labelCheckAll = checkedAll
			? t('global.uncheck_all')
			: t('global.check_all')

		return (
			<Row>
				<Col sm={12}>
					<Multiselect
						id="css_custom"
						showArrow
						className={inputClass}
						showCheckbox
						displayValue="label"
						selectedValues={selectedList}
						onKeyPressFn={(opt) => {
							// console.log('key press', opt)
						}}
						onSearch={(opt) => {
							// console.log('on search::', opt)
						}}
						onRemove={this.handleRemoveSelect}
						onSelect={this.handleSelect}
						options={options}
						placeholder={placeholder}
						style={{
							// chips: {
							// 	background: 'red'
							// },
							// multiselectContainer: {
							// 	color: 'red'
							// },
							searchBox: {
								width: '100%',
								minHeight: '35px',
								fontSize: '10pt',
								borderColor: '#a9a9a9'
							}
						}}
					/>
				</Col>
				<Col>
					<div className="form-group">
						<input
							type="checkbox"
							checked={checkedAll}
							onChange={this.handleSelectAll}
						/>
						<label style={{ marginLeft: '5px' }}>{labelCheckAll}</label>
					</div>
				</Col>
			</Row>
		)
	}
}

export default withTranslation()(CommonMultiSelect)
