import { productCufFields } from 'constants/crudsConfig/crudProductsConstants/CrudProductCufFormFields'
import { renderInput } from 'lib/FormUtils'
import { ICrudCompleteFields, ICrudGenericFields } from 'models/CrudInterface'
import {
	IGetTableGenericRes,
	IGetTableGenericResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'
import { CufProps } from './CufForm'

type CrudProductCufFieldProps = {
	field: ICrudCompleteFields
	formikProps: any
	crudProps: CufProps
	initialValue: any
	changedIndex: number
	handleIndex: (index: number) => void
	alta: number
}

type CrudProductCufFieldState = {
	dependsOn: any
	fieldConfig: ICrudGenericFields
	defaultValue?: string
	idField?: any
	value?: any
	index: number
}

class CrudProductCufField extends Component<
	CrudProductCufFieldProps,
	CrudProductCufFieldState
> {
	constructor(props: CrudProductCufFieldProps) {
		super(props)
		this.state = {
			fieldConfig: {
				id: '',
				type: '',
				width: '',
				autoComplete: '',
				comboData: '',
				maxChars: 0,
				dependsOn: {
					id: '',
					values: [
						{
							idValue: '',
							disabled: false
						}
					]
				}
			},
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			},
			index: 0
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field } = this.props
		const idField = field.idCampo.trim()
		const addData = _.find(productCufFields, (field: ICrudGenericFields) => {
			return field.id === idField
		})

		idField.includes('cod_cuf') &&
			this.setState({ index: Number(idField.replace('cod_cuf', '')) })
		addData && this.setState({ dependsOn: addData.dependsOn })
		addData && this.setState({ fieldConfig: addData })
		addData && this.setState({ idField: idField })
		addData && this.setState({ defaultValue: addData.default })
	}

	// Update check for CPOS validation, and default state of dropdowns
	componentDidUpdate = (prevProps: CrudProductCufFieldProps) => {
		const { formikProps, crudProps, changedIndex, handleIndex } = this.props
		const { formikProps: prevFormikProps, crudProps: prevCrudProps } = prevProps
		const { idField, defaultValue, fieldConfig, index } = this.state

		if (
			fieldConfig.type !== 'combo' &&
			!formikProps.values[idField] &&
			formikProps.values[idField] !== 0 &&
			defaultValue
		) {
			formikProps.setFieldValue(idField, defaultValue)
		}

		if (
			fieldConfig.type === 'combo' &&
			crudProps.cufData !== prevCrudProps.cufData &&
			index > changedIndex
		) {
			formikProps.setFieldValue(idField, '')
		}

		if (
			fieldConfig.type === 'combo' &&
			formikProps.values[idField] &&
			formikProps.values[idField] !== prevFormikProps.values[idField]
		) {
			let parsed = {
				cod_dep: formikProps.values.cod_dep
			}

			for (let i = 0; i < 8; i++) {
				index > i &&
					(parsed = {
						...parsed,
						['cod_cuf' + (i + 1)]: formikProps.values['cod_cuf' + (i + 1)]
					})
			}

			crudProps.getTableCufData(parsed)
			handleIndex(index)
		}
	}

	handleBlur = async () => {
		const { formikProps } = this.props
		const { idField } = this.state
		formikProps.setTouched({
			...formikProps.touched,
			[idField]: true
		})
	}

	// Setup depot field if it's empty
	comboDataField = (data: IGetTableGenericResArray) => {
		const values: { cod_valor: string; desc_valor: string }[] = []
		data &&
			_.each(data.lista, (opt: IGetTableGenericRes) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	cufDataField = (data: any) => {
		const values: { cod_valor: string; desc_valor: string }[] = []
		data &&
			_.each(data.cod_cuf, (opt: IGetTableGenericRes) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.codigo })
			})
		return values
	}

	renderInputs = (
		field: any,
		formikProps: any,
		disableForm: boolean,
		fieldConfig: ICrudGenericFields
	) => {
		const { crudProps, alta } = this.props
		const { values, setFieldValue } = formikProps
		const { idField, index, dependsOn } = this.state
		const addData = fieldConfig

		addData &&
			(field = {
				...field,
				tipo: addData.type,
				colInput: addData.width,
				autoComplete: addData.autoComplete,
				onBlur: this.handleBlur,
				disabled: addData.disabled,
				customProps: {
					maxLength: addData.maxChars
				}
			})

		if (field.tipo === 'combo') {
			if (!index) {
				addData &&
					(field.valores = crudProps[
						addData.comboData as keyof typeof crudProps
					]
						? this.comboDataField(
								crudProps[addData.comboData as keyof typeof crudProps]
						  )
						: [])
			} else {
				field.valores =
					crudProps.cufData && crudProps.cufData.lista_cuf
						? this.cufDataField(crudProps.cufData.lista_cuf[index - 1])
						: []
			}
			_.isEmpty(field.valores) && (field.visible = false)
			!formikProps.values[idField] &&
				(field.onChange = (event: any) => {
					setFieldValue(field.idCampo, event.target.value)
				})
		} else if (field.tipo === 'check') {
			field.customProps = {
				...field.customProps,
				checked: Number(values[field.idCampo])
			}
		}

		dependsOn.id !== '' &&
			_.each(dependsOn?.values, (dependency) => {
				alta === dependency.idValue && (field.editable = !dependency.disabled)
			})

		if (!field.editable) field.disabled = true
		if (field.visible) {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { field, formikProps, crudProps } = this.props
		const { fieldConfig } = this.state
		return (
			<Fragment>
				{this.renderInputs(
					field,
					formikProps,
					crudProps.disableForm,
					fieldConfig
				)}
			</Fragment>
		)
	}
}

export default CrudProductCufField
