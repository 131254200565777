import {
	crudGetLedgerAccountCostCenter,
	crudUpdateLedgerAccountCostCenter
} from 'api/LedgerAccountsCostCentersApi'
import {
	ICrudGetLedgerAccountCentersSaga,
	ICrudUpdateLedgerAccountCentersSaga
} from 'models/CrudLedgerAccountsInterface'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
	crudGetLedgerAccountCentersSuccess,
	crudUpdateLedgerAccountCentersSuccess
} from '../actions'
import {
	CRUD_GET_LEDGER_ACCOUNT_COST_CENTER,
	CRUD_UPDATE_LEDGER_ACCOUNT_COST_CENTER
} from '../constants/ActionsTypes'

function* crudGetLedgerAccountCostCentersReq({
	payload
}: ICrudGetLedgerAccountCentersSaga): any {
	try {
		const ledgerAccounts = yield call(crudGetLedgerAccountCostCenter, payload)
		yield put(crudGetLedgerAccountCentersSuccess(ledgerAccounts))
	} catch (error) {}
}

function* crudUpdateLedgerAccountCostCentersReq({
	payload
}: ICrudUpdateLedgerAccountCentersSaga): any {
	try {
		const ledgerAccounts = yield call(
			crudUpdateLedgerAccountCostCenter,
			payload
		)
		yield put(crudUpdateLedgerAccountCentersSuccess(ledgerAccounts))
	} catch (error) {}
}

export function* crudGetLedgerAccountCostCentersSaga() {
	yield takeLatest(
		CRUD_GET_LEDGER_ACCOUNT_COST_CENTER,
		crudGetLedgerAccountCostCentersReq
	)
}

export function* crudUpdateLedgerAccountCostCentersSaga() {
	yield takeLatest(
		CRUD_UPDATE_LEDGER_ACCOUNT_COST_CENTER,
		crudUpdateLedgerAccountCostCentersReq
	)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetLedgerAccountCostCentersSaga),
		fork(crudUpdateLedgerAccountCostCentersSaga)
	])
}
