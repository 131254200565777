export const P_AFEC_CANT_VTA = 'p_afec_cant_vta'
export const P_CARGAITEMVTA = 'p_cargaitemvta'
export const P_AFEC_IMPO_VTA = 'p_afec_impo_vta'
export const P_AFEC_STADO_VTA = 'p_afec_estados_vta'
export const P_SELCLI = 'p_selcli'
export const P_VTACAB = 'p_vtacab'
export const P_FINCOMPROB = 'p_fincomprob'
export const P_ASIEN_CONT = 'p_asto_comprob'
export const P_IMP_COMPROB = 'p_impuesto'
export const P_LOAD_COLLECTION = 'p_focob'
export const P_COLLECTION_PAYMENT = 'p_collection_payment'
export const P_PRINT_VOUCHER = 'p_ccimprimir'
export const P_SELPROV = 'p_selprov'
export const P_CPACAB = 'p_cpacab'
export const P_CARGAITEM_CPA = 'p_cargaitem_cpa'
export const P_AFEC_CANT_CPA = 'p_afec_cant_cpa'
export const P_AFEC_IMPO_CPA = 'p_afec_impo_cpa'
export const P_AFEC_STATE_CPA = 'p_afec_estados_cpa'
export const P_CCPRINT = 'p_ccimprimir'
export const P_CARGAINDIV_VTA = 'p_cargaindiv_vta'
export const P_CARGAINDIV_CPA = 'p_cargaindiv_cpa'
export const P_PICK_STOCK_CPA = 'pick_ingreso'
export const P_IMPO_STOCK_ING = 'p_impo_stock_ing'
export const P_VTASTOCK_ING = 'p_vtastock_ing'
export const P_PICK_STOCK_VTA = 'pick_egreso'
export const P_SEARCH_VOUCHER = 'cons_comprob'
export const P_SEARCH_VOUCHER_DETAIL = 'cons_comprob_datos'
export const P_SEARCH_PRODUCTS = 'cons_productos'
export const P_SEARCH_PRODUCT_DETAIL = 'cons_productos_datos'
export const P_SELPRE = 'p_selcab'
export const P_SELCAB_CPA = 'p_selcab_cpa'
export const P_AUTO_GENERATE = 'p_vta_genaut'
export const P_FINAL_CONSUMER = 'p_cc_consfinal'
export const P_CONF_PROD_SUG = 'p_conf_prod_sug'
export const P_AUTOMATIC_PURCHASE_ORDER = 'p_oc_auto'
export const P_CONF_PROD_EQ = 'p_conf_prod_eq'
export const P_HEADER_DATA_VALIDATION = 'p_cc_valid_cab'
export const P_CALCULATION_BONUSES = 'p_cc_bonif'
export const P_INV_ING_ITEM_PIC = 'p_inv_ingitem_pic'
export const P_LOAD_PACKAGES_DISPATCH = 'p_cc_bultos'
export const P_HEADER_ENTRY = 'p_invcab'
export const P_INVETORY_EXPENSE = 'p_inv_pickegr'
export const P_LOAD_ITEM_INV = 'p_cargaitem_inv'
export const P_LOAD_ITEM_INCOME_INV = 'p_inv_ingitem'
export const P_TREASURY_HEADER = 'p_foncab'

export const IDCLIENT = 1
export const DELAY_TIME_API = 1000 // this numbers is in milliseconds
export const P_CONST_SEARCH_VOUCHER_DETAIL = 'cons_comprob_datos'
export const P_REMOVE_SEARCH_VOUCHER_DETAIL = 'anul_comprob_datos'
