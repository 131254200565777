import {
	confirmExpensesPick,
	getDepositsPickExpenses,
	getDepositsPickStock,
	getEgrStock,
	getInventoryPickStock,
	pickeoEgrSearchBarcode
} from 'actions'
import CommonAudio from 'components/common/CommonAudio/CommonAudio'
import CommonModal from 'components/common/commonModal'
import NotificationMessage from 'components/common/notificationMessage'
import StockPopup from 'components/Stock/stockPopup'
import { API_FORMAT_DATE } from 'constants/ApiFormats'
import { P_INVETORY_EXPENSE } from 'constants/ConfigProcessNames'
import { Form, Formik, FormikProps } from 'formik'
import { addPrevValidations } from 'lib/AxiosInterceptors'
import { getValidationSchema } from 'lib/FieldValidations'
import { getFiltersData } from 'lib/Utils'
import { IGetDepositsParams } from 'models/AutomaticPurchase'
import { IConfigFieldsForm } from 'models/Budget'
import { ICommonComponent } from 'models/CommonInterface'
import {
	IConfirmExpensesPickPayload,
	IGetEgrStock
} from 'models/InventoryExpensesStock'
import {
	IGetInventoryPickStockParams,
	IGetInventoryPickStockResponse,
	InventoryForm,
	InventorySearchBarcodeParams,
	ISearchBarcodeErrorResponse
} from 'models/InventoryPickStock'
import { IConfirmStock } from 'models/Stock'
import moment from 'moment'
import React, { Component, forwardRef, Fragment, Ref } from 'react'
import { Col } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import InventoryExpensesStockBarCode from './InventoryExpensesStockBarCode/InventoryExpensesStockBarCode'
import InventoryExpensesStockTable from './InventoryExpensesStockTable'

interface InventoryExpensesStockFormProps
	extends WithTranslation,
		Partial<ICommonComponent> {
	t?: any
	config: IConfigFieldsForm
	inventoryFormRef: any
	getInventoryPickStock: (payload: IGetInventoryPickStockParams) => void
	getEgrStock: (payload: IGetEgrStock) => void
	isVisibleBarCode: boolean
	pickeoEgrSearchBarcode: (payload: InventorySearchBarcodeParams) => void
	inventoryBarCodeItems: IGetInventoryPickStockResponse
	barCodeError: ISearchBarcodeErrorResponse
	barCodeParams: InventorySearchBarcodeParams
	confirmExpensesPick: (payload: IConfirmExpensesPickPayload) => void
	confirmedStock: IConfirmStock
	getDepositsPickExpenses: (payload: IGetDepositsParams) => void
	getDepositsPickStock: (payload: IGetDepositsParams) => void
}

interface IStockParamsProps {
	cantidad?: string
	Niprod?: string
	cod_barra?: string
	cuf?: string
	cuf_dest?: string
}

interface InventoryExpensesStockFormState {
	showConfirmationPopup: boolean
	errorMessage: any
	titleBarCodeError: string
	buttonsConfirmation: boolean
	showError: boolean
	typeError: string
	showStock: boolean
	referenceItem: string
	stockParams: IStockParamsProps
}

class InventoryExpensesStockForm extends Component<
	InventoryExpensesStockFormProps,
	InventoryExpensesStockFormState
> {
	inventoryPickStockBarCodeRef: any
	audioComponentRef: any
	constructor(props: InventoryExpensesStockFormProps) {
		super(props)
		this.inventoryPickStockBarCodeRef = React.createRef()
		this.audioComponentRef = React.createRef()
		this.state = {
			buttonsConfirmation: false,
			errorMessage: null,
			showConfirmationPopup: false,
			showError: false,
			titleBarCodeError: '',
			typeError: '',
			showStock: false,
			referenceItem: '',
			stockParams: _.isEmpty(props.barCodeParams)
				? {}
				: this.getStockParams(props.barCodeParams)
		}

		addPrevValidations((e: any) => {
			return e.request.responseURL.includes('stock/egr_pickeo/cod_barra')
		})
	}

	componentDidMount = (): void => {
		const { idOperacion } = this.props

		this.props.getDepositsPickExpenses({ idOperacion: idOperacion || '' })
		this.props.getDepositsPickStock({ idOperacion: idOperacion || '' })

		this.props.getEgrStock({
			idOperacion: idOperacion || '',
			page_number: 0,
			page_size: 10
		})
	}

	componentDidUpdate = (
		prevProps: Readonly<InventoryExpensesStockFormProps>
	): void => {
		const {
			inventoryBarCodeItems,
			barCodeError,
			barCodeParams,
			confirmedStock
		} = this.props
		if (
			inventoryBarCodeItems !== prevProps.inventoryBarCodeItems &&
			inventoryBarCodeItems
		) {
			this.handleSoundSuccess()
		} else {
			this.handleBarCodeError(prevProps.barCodeError, barCodeError)
		}

		this.handleBarcodeParams(prevProps.barCodeParams, barCodeParams)

		this.handleConfirmStock(prevProps.confirmedStock, confirmedStock)
	}

	handleConfirmStock = (
		prevConfirmStock: IConfirmStock,
		confirmedStock: IConfirmStock
	) => {
		const { idOperacion } = this.props
		if (
			prevConfirmStock !== confirmedStock &&
			confirmedStock &&
			confirmedStock.Resultado
		) {
			this.props.getEgrStock({
				idOperacion: idOperacion || '',
				page_number: 0,
				page_size: 10
			})
		}
	}

	handleBarcodeParams = (
		prevBarCodeParams: InventorySearchBarcodeParams,
		barCodeParams: InventorySearchBarcodeParams
	) => {
		if (
			!_.isEqual(prevBarCodeParams, barCodeParams) &&
			!_.isEmpty(barCodeParams)
		) {
			const stockParams: IStockParamsProps = this.getStockParams(barCodeParams)
			this.setState({ stockParams })
		}
	}

	getStockParams = (barCodeParams: InventorySearchBarcodeParams) => {
		const { isVisibleBarCode } = this.props
		const stockParams: IStockParamsProps = {
			cantidad: barCodeParams.cant,
			cuf: barCodeParams.cuf,
			cuf_dest: barCodeParams.cuf_dest
		}

		if (isVisibleBarCode) {
			stockParams.cod_barra = barCodeParams.cod_barra
		} else {
			stockParams.Niprod = barCodeParams.niprod
		}

		return stockParams
	}

	/**
	 * to handle barcode error
	 * @param prevBarCodeError ISearchBarcodeErrorResponse
	 * @param barcodeError ISearchBarcodeErrorResponse
	 */
	handleBarCodeError = (
		prevBarCodeError: ISearchBarcodeErrorResponse,
		barCodeError: ISearchBarcodeErrorResponse
	) => {
		if (
			!_.isEqual(barCodeError, prevBarCodeError) &&
			!_.isEmpty(barCodeError)
		) {
			const { t } = this.props
			this.handleSoundError()
			let showError = true

			if (barCodeError.typeError === 'C') {
				showError = false
				this.setState({
					showConfirmationPopup: true,
					errorMessage: `${barCodeError.message}`,
					titleBarCodeError: t('global.confirm'),
					buttonsConfirmation: true,
					showError
				})
			} else if (barCodeError.typeError === 'S') {
				this.setState({
					showStock: true,
					referenceItem: barCodeError.dataFields.nitem
				})
			} else if (showError) {
				this.setState({
					showError,
					buttonsConfirmation: true,
					errorMessage: `${barCodeError.message}`,
					typeError: 'danger'
				})
			}
		}
	}

	/**
	 * to resend barcode request
	 */
	handleConfirmationPopup = (valuesForm: {
		fec_vto: string
		lote: string
		nserie: string
	}) => {
		const { barCodeParams } = this.props

		const fec_vto = valuesForm.fec_vto
			? moment(valuesForm.fec_vto).format(API_FORMAT_DATE)
			: ''
		const params = valuesForm.lote
			? { ...barCodeParams, ...valuesForm, fec_vto }
			: { ...barCodeParams, confirmacion: 1 }

		this.props.pickeoEgrSearchBarcode({
			...params,
			page: 1,
			page_size: 10
		})
		this.handleCloseErrorPopup()
	}

	/**
	 * to close error popup
	 */
	handleCloseErrorPopup = () => {
		this.setState({ showConfirmationPopup: false })
	}

	/**
	 * call child function of success sound
	 */
	handleSoundSuccess = () => {
		this.audioComponentRef.current.handleSuccess()
	}

	/**
	 * call child function of success error
	 */
	handleSoundError = () => {
		this.audioComponentRef.current.handleError()
	}

	/**
	 * handle submit form and call confirm action
	 * @param values
	 */
	handleSubmitForm = (values: Partial<InventoryForm>) => {
		const { idOperacion, callBackReturn } = this.props

		this.props.confirmExpensesPick({
			params: { idOperacion: idOperacion || '' },
			callBackReturn
		})
	}

	toggleLastCuf = () => {
		this.inventoryPickStockBarCodeRef.current.toggleLastCuf()
	}

	toggleDisableCant = () => {
		this.inventoryPickStockBarCodeRef.current.toggleDisableCant()
	}

	handleChangeSearchInput = () => {}

	handleCloseStockPopup = () => {
		this.setState({ showStock: false })
	}

	handleSubmitStock = () => {
		// console.log('handle submit')
	}

	handleCloseCartMessage = () => {
		// console.log('cancelando mensaje')
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	render() {
		const { t, config, inventoryFormRef, idOperacion, isVisibleBarCode } =
			this.props

		const {
			showConfirmationPopup,
			titleBarCodeError,
			errorMessage,
			buttonsConfirmation,
			showStock,
			referenceItem,
			stockParams,
			typeError,
			showError
		} = this.state

		const fields = config
			? getFiltersData(config.campos, { agrupador: 'formulario' })
			: []

		const fieldsTable = config
			? getFiltersData(config.campos, { agrupador: 'grilla' })
			: []

		const initialValues = { cant_ns: '1', pick_cod_barra: '', ult_cuf: 0 }
		const validationSchema = fields.length ? getValidationSchema(fields, t) : {}

		const stockPopupProps = {
			params: stockParams,
			showStock,
			idOperacion,
			referenceItem,
			handleClose: this.handleCloseStockPopup,
			handleSubmit: this.handleSubmitStock,
			handleCloseCartMessage: this.handleCloseCartMessage
		}

		return (
			<Fragment>
				<Col sm="12">
					<NotificationMessage
						showError={showError}
						errorMessage={errorMessage}
						handleCloseError={this.handleCloseError}
						type={typeError}
					/>
				</Col>
				<Formik
					ref={inventoryFormRef}
					initialValues={initialValues}
					onSubmit={(values) => {
						this.handleSubmitForm(values)
					}}
					validationSchema={validationSchema}
					enableReinitialize={true}
					render={(props: FormikProps<Partial<InventoryForm>>) => {
						return (
							<Fragment>
								<CommonAudio ref={this.audioComponentRef} />
								<CommonModal
									showModal={showConfirmationPopup}
									handleCloseModal={this.handleCloseErrorPopup}
									modalTitle={titleBarCodeError}
									modalBody={errorMessage}
									buttons={buttonsConfirmation}
									handleSubmit={this.handleConfirmationPopup}
								/>
								<Col sm={12}>
									<Form>
										<InventoryExpensesStockBarCode
											ref={this.inventoryPickStockBarCodeRef}
											formikProps={props}
											fields={fields}
											idOperacion={idOperacion || ''}
											isVisibleBarCode={isVisibleBarCode}
										/>
									</Form>
								</Col>
							</Fragment>
						)
					}}
				/>
				<Col sm="12">
					<StockPopup {...stockPopupProps} />
				</Col>
				<Col sm={12}>
					<InventoryExpensesStockTable
						fields={fieldsTable}
						idOperacion={idOperacion || ''}
					/>
				</Col>
			</Fragment>
		)
	}
}

const mapStateToProps = ({
	voucher,
	inventoryPickStock,
	pickeoEgrStock,
	stock
}: any) => {
	const config = voucher.config ? voucher.config[P_INVETORY_EXPENSE] : null
	const { inventoryBarCodeItems } = inventoryPickStock
	const { barCodeError, barCodeParams } = pickeoEgrStock
	const { confirmedStock } = stock

	return {
		config,
		inventoryBarCodeItems,
		barCodeError,
		barCodeParams,
		confirmedStock
	}
}

const InventoryPickStockFormWithRef = forwardRef(
	function InventoryPickStockFormWrapper(
		props: InventoryExpensesStockFormProps,
		ref: Ref<InventoryExpensesStockForm>
	) {
		return <InventoryExpensesStockForm {...props} ref={ref} />
	}
)

const mapDispatchToProps = {
	getInventoryPickStock,
	pickeoEgrSearchBarcode,
	getEgrStock,
	confirmExpensesPick,
	getDepositsPickExpenses,
	getDepositsPickStock
}

export default withTranslation('', { withRef: true })(
	connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
		InventoryPickStockFormWithRef
	)
)
