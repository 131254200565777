import CollapseButton from 'components/common/collapseButton'
import NotificationMessage from 'components/common/notificationMessage'
import GenericInputForm from 'components/form/genericInputForm'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import moment from 'moment'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Collapse } from 'reactstrap'
import {
	providerHeaderAuto,
	providerHeaderCheckDate,
	voucherHeadConfirm,
	voucherHeadValidatekey
} from '../../actions'

class ProviderHeaderFormInput extends Component {
	constructor(props) {
		super(props)
		this.state = {
			collapse: true,
			showError: false,
			errorTitle: '',
			errorMessage: ''
		}
	}

	componentDidMount = () => {
		const { firstRefs } = this.props
		if (firstRefs && firstRefs.current) {
			firstRefs.current.focus()
		}
	}

	componentDidUpdate = (prevProps) => {
		const { fields, setFieldValue, checkDate, checkKey } = this.props

		if (!prevProps.checkKey && checkKey) {
			if (checkKey.codigo === 200) {
				fields.forEach((field) => {
					if (field.idcampo === 'cotiz') {
						field.editable = 1
						setFieldValue('cotiz')
					}
				})
			} else {
				this.setError(checkKey)
			}
		}

		if (!prevProps.checkDate && checkDate) {
			if (checkDate.codigo && checkDate.codigo !== 200) {
				setFieldValue('date', new Date())
				this.setError(checkDate)
			} else {
				setFieldValue('cotiz_comp_cpa', checkDate.cotizacion)
			}
		}
	}

	setError = (error) => {
		this.setState({
			showError: true,
			errorMessage: error.mensaje,
			errorTitle: error.descripcion
		})
	}

	toggle() {
		this.setState((state) => ({ collapse: !state.collapse }))
	}

	companyChange = (select) => {
		// console.log(select.target.value)
	}

	handleChangeCurrency = (select) => {
		const { providerHeader, setFieldValue } = this.props
		const code = select.target.value
		providerHeader.moneda.forEach((currency) => {
			if (currency.cod_moneda === code) {
				setFieldValue('cotiz_comp_cpa', currency.cotiz)
				setFieldValue('mon_comp_cpa', code)
			}
		})
	}

	handleValidateInput = (data) => {
		const { idOperacion } = this.props
		this.props.voucherHeadValidatekey({ idOperacion, clave: data })
	}

	handleChangeContDate = (date, evt) => {
		if (
			date === null ||
			evt.target.value === undefined ||
			(evt.keyCode === 13 && date !== null)
		) {
			this.handleSetContDate(date)
		}
	}

	handleSetContDate = (date) => {
		const { idOperacion, setFieldValue } = this.props
		const dateFormated = moment(date).format('YYYY-MM-DD')
		this.props.providerHeaderCheckDate({ idOperacion, fecha: dateFormated })
		const valueDate = date || ''
		setFieldValue('fecha_cont_cpa', valueDate)
	}

	handleChangeRealDate = (date, evt) => {
		const valueDate = date || ''
		if (
			date === null ||
			evt.target.value === undefined ||
			(evt.keyCode === 13 && date !== null)
		) {
			this.handleChangeDates(valueDate)
		}
	}

	handleChangeDates = (date) => {
		const { setFieldValue } = this.props
		setFieldValue('fecha_real', date)
		setFieldValue('fecha_cont_cpa', date)
	}

	handleChangeGeneric = (data) => {
		const { values, setFieldValue } = this.props
		const { atrib_comp_cpa_field } = values
		const generic = atrib_comp_cpa_field || []
		let ban = true

		generic.forEach((field, index) => {
			if (field.cod_atributo === data.cod_atributo) {
				generic[index] = data // Editando
				ban = false
			}
		})

		if (ban) {
			generic.push(data) // La primera vez
		}

		setFieldValue('atrib_comp_cpa_field', generic)
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	handleSearchAuto = (params) => {
		this.props.providerHeaderAuto(params)
	}

	renderCarrier = () => {
		const { fields, providerHeader } = this.props
		const result = []
		let config = {}

		fields.forEach((field) => {
			const idField = field.idCampo.trim()
			if (idField === 'atrib_comp_cpa') {
				config = field
			}
		})

		const genericFields =
			providerHeader && providerHeader.atrib_comp_cpa
				? providerHeader.atrib_comp_cpa
				: []

		genericFields.forEach((element) => {
			result.push(
				<GenericInputForm
					style={{ marginLeft: -5 }}
					config={{ ...element, ...config }}
					key={element.cod_atrib}
					handleChange={this.handleChangeGeneric}
					handleSearchAuto={this.handleSearchAuto}
					autodata={this.props.autodata}
				/>
			)
		})

		return result
	}

	convertArrayToObject = (array, key) => {
		const initialValue = {}
		return array.reduce((obj, item) => {
			return {
				...obj,
				[item[key].trim()]: item
			}
		}, initialValue)
	}

	render() {
		const {
			t,
			values,
			handleChange,
			handleBlur,
			setFieldValue,
			providerHeader,
			readOnly,
			collapse,
			fields
		} = this.props

		const optionsCompany =
			providerHeader && providerHeader.suc_empresa_cpa
				? providerHeader.suc_empresa_cpa.map((opt) => {
						return { id: opt.Cod_Suc, label: opt.Nom_suc }
				  })
				: []

		const optionsCurrency =
			providerHeader && providerHeader.moneda
				? providerHeader.moneda.map((opt) => {
						return { id: opt.cod_moneda, label: opt.desc_moneda }
				  })
				: []

		const optionsConditions =
			providerHeader && providerHeader.cond_comp_cpa
				? providerHeader.cond_comp_cpa.map((opt) => {
						return { id: opt.cod_cond_cpa, label: opt.desc_cond_cpa }
				  })
				: []

		const optionsLetter =
			providerHeader && providerHeader.nro_letra
				? providerHeader.nro_letra.map((letter) => {
						return { id: letter, label: letter }
				  })
				: []

		if (fields && values) {
			const fieldsObject = this.convertArrayToObject(fields, 'idCampo')

			return (
				<Row>
					<NotificationMessage
						{...this.state}
						handleCloseError={this.handleCloseError}
						type={'danger'}
					/>
					<InputDropdown
						inputFormCol={{ sm: 11 }}
						fields={fields}
						label={t('headboard.form.company_branch')}
						inputId={'suc_empresa_cpa'}
						name={'suc_empresa_cpa'}
						placeholder={t('voucher.headboard.form.insert_company_branch')}
						styles={{ width: '100%' }}
						colLabel={'col-sm-2'}
						colInput={'col-sm-10'}
						options={optionsCompany}
						disable={readOnly}
						value={values ? values.suc_empresa_cpa : null}
						onBlur={handleBlur}
						onChange={(data) => {
							setFieldValue('suc_empresa_cpa', data.target.value)
							handleChange()
						}}
					/>
					<InputText
						inputFormCol={{ sm: 11 }}
						fields={fields}
						label={t('headboard.form.voucher')}
						inputId={'titulo_comp_cpa'}
						name={'titulo_comp_cpa'}
						placeholder={t('headboard.form.insert_voucher')}
						colLabel={'col-sm-2'}
						colInput={'col-sm-10'}
						divStyle={{ paddingLeft: '17px' }}
						disable={readOnly}
						value={values.titulo_comp_cpa}
						onBlur={handleBlur}
						onChange={(value) => {
							setFieldValue('titulo_comp_cpa', value)
							handleChange()
						}}
					/>

					<Row className={'col-11'} style={{ paddingRight: '0px' }}>
						<InputDropdown
							inputFormCol={{ sm: 4 }}
							fields={fields}
							label={'Letra'}
							inputId={'nro_letra'}
							name={'nro_letra'}
							colLabel={'col-sm-6'}
							colInput={'col-sm-6'}
							divStyle={{ paddingLeft: '21px' }}
							disable={readOnly}
							value={values.nro_letra}
							options={optionsLetter}
							onChange={(opt) => {
								setFieldValue('nro_letra', opt.target.value)
								handleChange()
							}}
							onBlur={handleBlur}
						/>
						<InputText
							inputFormCol={{ sm: 4 }}
							fields={fields}
							label={t('Provider.header.insert_nro_pv')}
							inputId={'nro_pv'}
							name={'nro_pv'}
							placeholder={t('Provider.header.insert_nro_pv')}
							colLabel={
								fieldsObject.nro_letra.visible ? 'col-sm-4' : 'col-sm-6'
							}
							colInput={
								fieldsObject.nro_letra.visible ? 'col-sm-8' : 'col-sm-6'
							}
							styleLabel={
								fieldsObject.nro_letra.visible ? { textAlign: 'right' } : {}
							}
							divStyle={
								!fieldsObject.nro_letra.visible ? { paddingLeft: '19px' } : {}
							}
							disable={readOnly}
							value={values.nro_pv}
							onChange={(data) => {
								setFieldValue('nro_pv', data)
								handleChange()
							}}
							onBlur={handleBlur}
						/>
						<InputText
							inputFormCol={
								fieldsObject.nro_pv.visible && fieldsObject.nro_letra.visible
									? { sm: 4 }
									: { sm: 12 }
							}
							fields={fields}
							label={t('Provider.header.insert_nro')}
							inputId={'nro'}
							name={'nro'}
							placeholder={t('Provider.header.insert_nro')}
							colLabel={
								fieldsObject.nro_pv.visible && fieldsObject.nro_letra.visible
									? 'col-sm-4'
									: 'col-sm-2'
							}
							colInput={
								fieldsObject.nro_pv.visible && fieldsObject.nro_letra.visible
									? 'col-sm-8'
									: 'col-sm-10'
							}
							styleLabel={
								fieldsObject.nro_pv.visible && fieldsObject.nro_letra.visible
									? { textAlign: 'right' }
									: {}
							}
							divStyle={
								!fieldsObject.nro_letra.visible
									? { paddingLeft: '19px', paddingRight: '0px' }
									: { paddingRight: '0px' }
							}
							disable={readOnly}
							value={values.nro}
							onChange={(data) => {
								setFieldValue('nro', data)
								handleChange()
							}}
							onBlur={handleBlur}
						/>
						<InputText
							inputFormCol={{ sm: 4 }}
							fields={fields}
							label={t('headboard.form.date')}
							inputId={'fecha_real'}
							name={'fecha_real'}
							placeholder={t('headboard.form.insert_date')}
							colLabel={'col-sm-6'}
							colInput={'col-sm-6'}
							divStyle={{ paddingLeft: '19px' }}
							disable={readOnly}
							value={values.fecha_real}
							onChange={this.handleChangeRealDate}
							handleEnterKey={(e, date) => {
								this.handleChangeDates(date)
							}}
							onBlur={handleBlur}
						/>
						<InputText
							inputFormCol={{ sm: 4 }}
							fields={fields}
							label={t('headboard.form.date')}
							inputId={'fecha_cont_cpa'}
							name={'fecha_cont_cpa'}
							placeholder={t('headboard.form.insert_date')}
							colLabel={
								fieldsObject.fecha_real.visible ? 'col-sm-4' : 'col-sm-6'
							}
							colInput={
								fieldsObject.fecha_real.visible ? 'col-sm-8' : 'col-sm-6'
							}
							styleLabel={
								fieldsObject.fecha_real.visible ? { textAlign: 'right' } : {}
							}
							divStyle={
								!fieldsObject.fecha_real.visible ? { paddingLeft: '19px' } : {}
							}
							disable={readOnly}
							value={values.fecha_cont_cpa}
							onChange={this.handleChangeContDate}
							handleEnterKey={(e, date) => {
								this.handleSetContDate(date)
							}}
							onBlur={handleBlur}
						/>
						<Col sm={12} />
						<InputDropdown
							inputFormCol={{ sm: 4 }}
							fields={fields}
							label={t('headboard.form.currency')}
							inputId={'mon_comp_cpa'}
							name={'mon_comp_cpa'}
							placeholder={t('headboard.form.insert_currency')}
							colLabel={'col-sm-6'}
							colInput={'col-sm-6'}
							divStyle={{ paddingLeft: '19px' }}
							disable={readOnly}
							value={values.mon_comp_cpa}
							options={optionsCurrency}
							onChange={this.handleChangeCurrency}
							onBlur={handleBlur}
						/>
						<InputText
							inputFormCol={{ sm: fieldsObject.mon_comp_cpa.visible ? 4 : 6 }}
							fields={fields}
							handleSubmit={this.handleValidateInput}
							label={t('headboard.form.quotation')}
							inputId={'cotiz_comp_cpa'}
							name={'cotiz_comp_cpa'}
							placeholder={t('headboard.form.insert_quotation')}
							colLabel={'col-sm-4'}
							colInput={'col-sm-8'}
							styleLabel={
								fieldsObject.mon_comp_cpa.visible ? { textAlign: 'right' } : {}
							}
							divStyle={
								!fieldsObject.mon_comp_cpa.visible
									? { paddingLeft: '19px' }
									: {}
							}
							disable={readOnly}
							value={values.cotiz_comp_cpa}
							onBlur={handleBlur}
							onChange={(data) => {
								setFieldValue('cotiz_comp_cpa', data)
								handleChange()
							}}
						/>

						<InputDropdown
							inputFormCol={{ sm: { span: 6 } }}
							fields={fields}
							label={'Cond. Pago'}
							inputId={'cond_comp_cpa'}
							name={'cond_comp_cpa'}
							colLabel={'col-sm-4'}
							colInput={'col-sm-8'}
							divStyle={
								fieldsObject.cotiz_comp_cpa.visible &&
								!fieldsObject.mon_comp_cpa.visible
									? { paddingRight: '0px' }
									: { paddingLeft: '19px' }
							}
							styleLabel={
								fieldsObject.cotiz_comp_cpa.visible &&
								!fieldsObject.mon_comp_cpa.visible
									? { textAlign: 'right' }
									: {}
							}
							disable={readOnly}
							value={values.cond_comp_cpa}
							options={optionsConditions}
							onChange={(data) => {
								setFieldValue('cond_comp_cpa', data.target.value)
							}}
							onBlur={handleBlur}
						/>
					</Row>

					{collapse && (
						<>
							<Row className={'col-12'}>
								<Col sm={1}>
									<CollapseButton
										onPress={() => this.toggle()}
										status={this.state.collapse}
									/>
								</Col>
								<Col sm={11}>
									<div className="dropdown-divider col-11 p-2" />
								</Col>
							</Row>

							<Collapse
								style={{ width: '100%' }}
								isOpen={this.state.collapse && collapse}
							>
								{values && this.renderCarrier()}
							</Collapse>
						</>
					)}
					{!collapse && this.renderCarrier()}
				</Row>
			)
		} else {
			return null
		}
	}
}

const mapStateToProps = ({ vouchertype, providers }) => {
	const { providerHeader, checkDate, autodata } = providers
	const { voucherTypeCancel, voucherType } = vouchertype
	return { voucherTypeCancel, voucherType, providerHeader, checkDate, autodata }
}

export default connect(mapStateToProps, {
	voucherHeadValidatekey,
	providerHeaderCheckDate,
	voucherHeadConfirm,
	providerHeaderAuto
})(withTranslation()(ProviderHeaderFormInput))
