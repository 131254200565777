import { createStyles } from '@material-ui/core'

export const styles = (theme: any) =>
	createStyles({
		iconTemp: {
			cursor: 'pointer',
			color: '#007bff'
		},
		fileContainer: {
			textAlign: 'center'
		},
		fileItem: {
			backgroundColor: '#e8fff7',
			border: '1px dashed #00c880',
			color: '#165677',
			maxWidth: '100%',
			height: 'max-content',
			padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
			borderRadius: 10,
			margin: 3,
			textAlign: 'center'
		}
	})
