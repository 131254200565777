import { validatePurchaseBarcode } from 'actions'
import BarcodeInputText from 'components/form/barcodeInputText'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getOptionsDropDown, handleSetValueInput } from 'lib/FormUtils'
import { IValidateBarCodeParams } from 'models/HeaderSelection'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'

interface IHeaderFiltersBudgetProps extends WithTranslation {
	fields?: Array<IFieldStructure>
	filterBranches?: any
	filterSellers?: any
	filterTransport?: any
	filterCondSale?: any
	sortDirCbtOptions?: any
	sortDirOptions?: any
	optionsFilterAffected?: any
	t?: any
	values: any
	handleChange: any
	handleBlur: any
	setFieldValue: any
	setFieldTouched: any
	validatePurchaseBarcode: (payload: IValidateBarCodeParams) => void
	idOperacion?: any
}

interface IHeaderFiltersBudgetState {
	dateFromValue: string
	dateUntilValue: string
	firstRefInput: any
	barCodeValue: string
}

class HeaderFiltersBudget extends Component<
	IHeaderFiltersBudgetProps,
	IHeaderFiltersBudgetState
> {
	constructor(props: IHeaderFiltersBudgetProps) {
		super(props)
		this.state = {
			dateFromValue: '',
			dateUntilValue: '',
			firstRefInput: React.createRef(),
			barCodeValue: ''
		}
	}

	componentDidMount = () => {
		const { values } = this.props

		const dateFromValue = values && values.fec_desde ? values.fec_desde : ''
		const dateUntilValue = values && values.fec_hasta ? values.fec_hasta : ''

		this.setState({
			dateFromValue,
			dateUntilValue
		})
	}

	componentDidUpdate = (prevProps: any) => {
		const { values } = this.props

		if (
			!_.isEqual(prevProps.values, values) &&
			values.fec_desde === '' &&
			values.fec_hasta === ''
		) {
			this.setState({ dateFromValue: '', dateUntilValue: '' })
		}
	}

	/**
	 * validate barcode.
	 * @param e
	 * @param value
	 */
	handleSearchBarcode = (e: any, value: string) => {
		e.preventDefault()
		const { idOperacion } = this.props
		this.props.validatePurchaseBarcode({
			idOperacion,
			cod_barra: value
		})
		this.setState({ barCodeValue: '' })
	}

	render() {
		const {
			fields,
			t,
			sortDirCbtOptions,
			sortDirOptions,
			setFieldTouched,
			setFieldValue,
			handleBlur,
			values,
			optionsFilterAffected
		} = this.props
		const formikProps = { setFieldTouched, setFieldValue }
		const { firstRefInput, barCodeValue, dateFromValue, dateUntilValue } =
			this.state

		const dateFrom = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'Fecha',
			inputId: 'fec_desde',
			name: 'fec_desde',
			placeholder: t('global.from'),
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			disable: false,
			onBlur: () => {},
			value: dateFromValue ? new Date(moment(dateFromValue).format()) : '',
			autoComplete: 'off',
			onChange: (data: any) => {
				const date = data || ''
				this.setState({ dateFromValue: date })
				handleSetValueInput(date, 'fec_desde', formikProps)
			}
		}

		const dateUntil = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'Fecha',
			inputId: 'fec_hasta',
			name: 'fec_hasta',
			placeholder: t('global.until'),
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			disable: false,
			styleLabel: { textAlign: 'right' },
			onBlur: () => {},
			value: dateUntilValue ? new Date(moment(dateUntilValue).format()) : '',
			autoComplete: 'off',
			onChange: (data: any) => {
				const date = data || ''
				this.setState({ dateUntilValue: date })
				handleSetValueInput(date, 'fec_hasta', formikProps)
			}
		}

		const orderProps = {
			inputFormCol: { sm: 3 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'orden',
			name: 'orden',
			placeholder: '',
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			styleLabel: { textAlign: 'right' },
			onBlur: handleBlur,
			value: values.orden,
			options: getOptionsDropDown(sortDirCbtOptions),
			noInitialExecute: true,
			onChange: (data: any) => handleSetValueInput(data, 'orden', formikProps)
		}

		const sortProps = {
			inputFormCol: { sm: 3 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'sentido',
			name: 'sentido',
			placeholder: '',
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			onBlur: handleBlur,
			value: values.sentido,
			styleLabel: { textAlign: 'right' },
			options: getOptionsDropDown(sortDirOptions),
			noInitialExecute: true,
			onChange: (data: any) => handleSetValueInput(data, 'sentido', formikProps)
		}

		const barCodeProps = {
			fwRef: firstRefInput,
			inputFormCol: { sm: 9 },
			fields,
			label: 'label',
			inputId: 'cod_barra',
			name: 'cod_barra',
			placeholder: '',
			styles: { width: '100%' },
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			onBlur: () => {},
			handleEnterKey: (e: any, value: string) =>
				this.handleSearchBarcode(e, value),
			value: barCodeValue,
			onChange: (data: string) => this.setState({ barCodeValue: data }),
			activeListeners: true
		}

		const filterProps = {
			fwRef: firstRefInput,
			inputFormCol: { sm: 3 },
			fields,
			label: 'label',
			inputId: 'filtro',
			name: 'filtro',
			placeholder: '',
			styles: { width: '100%' },
			styleLabel: { textAlign: 'right' },
			colLabel: 'col-sm-6',
			colInput: 'col-sm-6',
			onBlur: () => {},
			value: values.filtro,
			options: getOptionsDropDown(optionsFilterAffected),
			noInitialExecute: true,
			onChange: (data: any) => handleSetValueInput(data, 'filtro', formikProps)
		}

		return (
			<Fragment>
				<Row className={`col-12`}>
					<InputText {...dateFrom} />
					<InputText {...dateUntil} />
					<InputDropdown {...orderProps} />
					<InputDropdown {...sortProps} />
				</Row>
				<Row className={`col-12`}>
					{!_.isEmpty(fields) && <BarcodeInputText {...barCodeProps} />}
					<InputDropdown {...filterProps} />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ searchVoucher, tableCalls }: any) => {
	const { sortDirOptions } = searchVoucher
	const { sortDirCbtOptions, optionsFilterAffected } = tableCalls
	return {
		sortDirCbtOptions,
		sortDirOptions,
		optionsFilterAffected
	}
}

const mapDispatchToProps = { validatePurchaseBarcode }

const connectForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(HeaderFiltersBudget)

export default withTranslation()(connectForm)
