import {
	crudGetFundAccountBankReconciliationSuccess,
	crudUpdateFundAccountBankReconciliationSuccess
} from 'actions'
import {
	crudGetFundAccountBankReconciliation,
	crudUpdateFundAccountBankReconciliation
} from 'api/FundAccountBankReconciliationApi'
import {
	CRUD_GET_FUND_ACCOUNT_BANK_RECOINCILIATION,
	CRUD_UPDATE_FUND_ACCOUNT_BANK_RECOINCILIATION
} from 'constants/ActionsTypes'
import {
	ICrudGetFundAccountBankReconciliationSaga,
	ICrudUpdateFundAccountBankReconciliationSaga
} from 'models/CrudFundsAccountsInterface'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* crudGetFundAccountBankReconciliationRequest({
	payload
}: ICrudGetFundAccountBankReconciliationSaga): any {
	try {
		const values: any = yield call(
			crudGetFundAccountBankReconciliation,
			payload
		)
		yield put(crudGetFundAccountBankReconciliationSuccess(values))
	} catch (error) {}
}

function* crudUpdateFundAccountBankReconciliationRequest({
	payload
}: ICrudUpdateFundAccountBankReconciliationSaga): any {
	try {
		const values: any = yield call(
			crudUpdateFundAccountBankReconciliation,
			payload
		)
		yield put(crudUpdateFundAccountBankReconciliationSuccess(values))
	} catch (error) {}
}

export function* crudGetFundAccountBankReconciliationSaga() {
	yield takeEvery(
		CRUD_GET_FUND_ACCOUNT_BANK_RECOINCILIATION,
		crudGetFundAccountBankReconciliationRequest
	)
}

export function* crudUpdateFundAccountBankReconciliationSaga() {
	yield takeEvery(
		CRUD_UPDATE_FUND_ACCOUNT_BANK_RECOINCILIATION,
		crudUpdateFundAccountBankReconciliationRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetFundAccountBankReconciliationSaga),
		fork(crudUpdateFundAccountBankReconciliationSaga)
	])
}
