import {
	CRUD_GET_PRODUCT_BONIFICATION,
	CRUD_GET_PRODUCT_BONIFICATION_SUCCESS,
	CRUD_UPDATE_PRODUCT_BONIFICATION,
	CRUD_UPDATE_PRODUCT_BONIFICATION_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	bonificationInfo: any
	valueUpdated: any
}

const initialState = {
	bonificationInfo: null,
	valueUpdated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_PRODUCT_BONIFICATION:
			return { ...state, bonificationInfo: null }
		case CRUD_GET_PRODUCT_BONIFICATION_SUCCESS:
			return { ...state, bonificationInfo: action.payload }
		case CRUD_UPDATE_PRODUCT_BONIFICATION:
			return { ...state, valueUpdated: null }
		case CRUD_UPDATE_PRODUCT_BONIFICATION_SUCCESS:
			return { ...state, valueUpdated: action.payload }
		default:
			return state
	}
}

export default rootReducer
