import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	finishProductCart,
	getConfigVoucher,
	getLoadItems,
	getVoucherHeadInfo,
	voucherCancel
} from 'actions'
import ConfirmModal from 'components/common/ConfirmModal'
import DisplayAmount from 'components/common/displayAmount'
import { getUrl } from 'lib/BreadCrumbsUtils'
import React, { Fragment, PureComponent } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import styles from '../../containers/Loaditems/Loaditems.module.css'
import ShoppingCart from './shoppingCart'

class HeadCartResume extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			showModal: false,
			totalCant: 0,
			totalItems: 0,
			totalImporte: 0
		}
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.getVoucherHeadInfo({ idOperacion })
	}

	componentDidUpdate = (prevProps) => {
		const {
			productsCart,
			headInfo,
			itemsCart,
			cantValidate,
			itemsCartProvider,
			purchCantValidate,
			purchasedProducts,
			canceledStock,
			finishCart,
			idOperacion,
			codeProcess,
			salesPrice,
			salesconfirm,
			salesDiscount,
			canceledAttributes,
			inventoryItems,
			inventoryBarCodeItems,
			barCodeSearched,
			egrPickeo,
			inventoryIncomeItems
		} = this.props

		this.setCartTotals(prevProps.productsCart, productsCart)
		this.setCartTotals(prevProps.headInfo, headInfo)
		this.setCartTotals(prevProps.itemsCart, itemsCart)

		// SalesAffected
		// **Cant** //
		this.setCartTotals(prevProps.cantValidate, cantValidate)
		// **Price**//
		this.setCartTotals(prevProps.salesPrice, salesPrice)
		// **Confirm** //
		this.setCartTotals(prevProps.salesconfirm, salesconfirm)
		// ** Discount ** //
		this.setCartTotals(prevProps.salesDiscount, salesDiscount)

		// Purchases
		this.setCartTotals(prevProps.itemsCartProvider, itemsCartProvider)
		this.setCartTotals(prevProps.purchasedProducts, purchasedProducts)
		this.setCartTotals(prevProps.purchCantValidate, purchCantValidate)

		// Stock
		this.setCartTotals(prevProps.canceledStock, canceledStock)

		// Attributes
		this.setCartTotals(prevProps.canceledAttributes, canceledAttributes)

		// **Inventory **//
		this.setCartTotals(prevProps.inventoryItems.data, inventoryItems.data)
		this.setCartTotals(
			prevProps.inventoryIncomeItems.data,
			inventoryIncomeItems.data
		)
		this.setCartTotals(prevProps.inventoryBarCodeItems, inventoryBarCodeItems)
		this.setCartTotals(prevProps.barCodeSearched, barCodeSearched)
		this.setCartTotals(prevProps.egrPickeo, egrPickeo)

		if (finishCart !== prevProps.finishCart && !prevProps.finishCart) {
			if (codeProcess !== finishCart.cod_proceso) {
				// se valida que el proceso envié una pagina diferente a la actual.
				const newUrlSubmit = getUrl(finishCart.cod_proceso, idOperacion)
				this.props.history.push(newUrlSubmit) // Se redirecciona a la nueva pagina.
			}
			this.setState({ showModal: false })
		}
	}

	/**
	 * set total and shopping cart
	 * @param {*} prevTotals
	 * @param {*} totals
	 */
	setCartTotals = (prevTotals, totals) => {
		if (!_.isEqual(prevTotals, totals) && !_.isEmpty(totals)) {
			this.setState({
				totalItems: totals.total_item || 0,
				totalCant: totals.total_cant || 0,
				totalImporte: totals.total_importe || 0
			})
		}
	}

	handleOpen = () => {
		this.setState({ showModal: true })
	}

	handleClose = () => {
		const { idOperacion } = this.props
		this.props.finishProductCart({ idOperacion })
	}

	handleCancel = () => {
		const { idOperacion } = this.props
		this.props.voucherCancel({ idOperacion })
	}

	render() {
		const { theme, t, headInfo, shoppingCartProcess, configTitles } = this.props
		const { totalCant, totalItems, totalImporte } = this.state
		return (
			<Fragment>
				<Col sm={12} className={''}>
					{this.state.showModal && (
						<ShoppingCart
							showModal={this.state.showModal}
							handleClose={this.handleClose}
							idOperacion={this.props.idOperacion}
							codeProcess={shoppingCartProcess}
						/>
					)}
				</Col>
				<Col sm={4} className={`${theme.Title} col-12 mt-2`}>
					{configTitles ? (
						<h5>{t('configItem.title')}</h5>
					) : (
						<h5>{t('loadItem.title')}</h5>
					)}
				</Col>
				<Col sm={2}>
					{configTitles ? (
						<Button
							style={{ minWidth: '145px', fontSize: '11pt', marginTop: '8px' }}
							type={'button'}
							onClick={this.handleCancel}
						>
							{t('form.button.exit')}
						</Button>
					) : (
						<ConfirmModal
							messageBody={t('form.modal.confirmationMessage')}
							onSubmitModal={this.handleCancel}
							labelButton={t('form.button.cancel')}
							modalTitle={t('form.modal.confirmationTitle')}
							buttonStyle={{ fontSize: '11pt', marginTop: '8px' }}
						/>
					)}
				</Col>
				<Col sm={3} className={'text-center  mt-3 mb-3'}>
					{!configTitles && (
						<Fragment>
							{`${t('loadItem.table.totalImp')} :`}{' '}
							<DisplayAmount amount={totalImporte} />
						</Fragment>
					)}
				</Col>
				<Col
					sm={1}
					className={'text-right  mt-1'}
					style={{ paddingRight: '5px' }}
				>
					{!configTitles && (
						<Fragment>
							<span
								className={`badge badge-primary ${theme.cantBadge}`}
							>{`${totalItems} / ${totalCant}`}</span>
						</Fragment>
					)}
				</Col>

				<Col
					sm={2}
					className={'text-left  mt-3'}
					style={{ paddingLeft: '5px', cursor: 'pointer' }}
				>
					{!configTitles && (
						<Fragment>
							<FontAwesomeIcon
								icon={faShoppingCart}
								onClick={this.handleOpen}
							/>
						</Fragment>
					)}
				</Col>
				{headInfo && (
					<Col sm={6} style={{ lineHeight: '1.4' }}>
						<Row>
							<Col sm={10}>
								<span
									style={{ fontSize: '13pt' }}
								>{`${headInfo.tipo_comprobante} - ${headInfo.descrip_comprobante}`}</span>
								<br />
								<span style={{ fontSize: '9pt', color: 'grey' }}>
									{headInfo.razon_social}
								</span>
								<br />
							</Col>
							<Col sm={6}>
								<span style={{ fontSize: '9pt' }}>{headInfo.dato1}</span>
							</Col>
							<Col sm={6}>
								<span style={{ fontSize: '9pt' }}>{headInfo.dato2}</span>
							</Col>
							<Col sm={6}>
								<span style={{ fontSize: '9pt' }}>{headInfo.dato3}</span>
							</Col>
							<Col sm={6}>
								<span style={{ fontSize: '9pt' }}>{headInfo.dato4}</span>
							</Col>
						</Row>
					</Col>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = ({
	loadItems,
	voucher,
	vouchertype,
	salesAffected,
	product,
	providers,
	purchasesAffected,
	stock,
	attributes,
	loadInventoryItems,
	inventoryPickStock,
	pickeoEgrStock
}) => {
	const { itemsCart } = loadItems
	const { itemsCartProvider } = providers
	const { voucherTypeCancel } = vouchertype
	const { headInfo } = voucher
	const { cantValidate, salesPrice, salesconfirm, salesDiscount } =
		salesAffected
	const purchCantValidate = purchasesAffected.cantValidate
	const { purchasedProducts } = purchasesAffected
	const { productsCart, finishCart } = product
	const { canceledStock } = stock
	const { canceledAttributes } = attributes
	const { inventoryItems, inventoryIncomeItems } = loadInventoryItems
	const { inventoryBarCodeItems } = inventoryPickStock
	const { barCodeSearched, egrPickeo } = pickeoEgrStock

	return {
		itemsCart,
		headInfo,
		voucherTypeCancel,
		cantValidate,
		productsCart,
		finishCart,
		itemsCartProvider,
		purchCantValidate,
		purchasedProducts,
		canceledStock,
		salesPrice,
		salesconfirm,
		salesDiscount,
		canceledAttributes,
		inventoryItems,
		inventoryBarCodeItems,
		barCodeSearched,
		egrPickeo,
		inventoryIncomeItems
	}
}

const connectForm = connect(mapStateToProps, {
	getLoadItems,
	getVoucherHeadInfo,
	voucherCancel,
	getConfigVoucher,
	finishProductCart
})(withRouter(HeadCartResume))
export default themr('LoaditemsPage', styles)(withTranslation()(connectForm))
