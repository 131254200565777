import {
	automaticPurchaseExport,
	getPaymentTermsFilterList,
	getProvider,
	searchProviders
} from 'actions'
import InputAutocomplete from 'components/form/inputAutocomplete'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import InputTextArea from 'components/form/InputTextArea'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getOptionsDropDown, handleSetValueInput } from 'lib/FormUtils'
import {
	IGetPaymentTermsFilterListParams,
	IGetProviderParams
} from 'models/AutomaticPurchase'
import { IGetTableGenericRes } from 'models/TableCalls/TableCallsInterface'
import React, { Component } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'

interface IAutomaticPurchaseProps {
	fields: IFieldStructure[]
	values: any
	handleChange: any
	handleBlur: any
	setFieldValue: any
	setFieldTouched: any
	idOperacion: string
	listProviders: any
	paymentTermsList: null | Array<IGetTableGenericRes>
	getPaymentTermsFilterList: (payload: IGetPaymentTermsFilterListParams) => void
	getProvider: (payload: IGetProviderParams) => void
	provider?: any
	automaticPurchaseExport: (payload: any) => void
	searchProviders: any
}

interface IAutomaticPurchaseState {
	searchingProviders: boolean
}

class AutomaticPurchaseProviderInputs extends Component<
	IAutomaticPurchaseProps,
	IAutomaticPurchaseState
> {
	constructor(props: IAutomaticPurchaseProps) {
		super(props)
		this.state = {
			searchingProviders: false
		}
	}

	componentDidUpdate = (prevProps: IAutomaticPurchaseProps) => {
		const { listProviders, provider, setFieldValue } = this.props
		if (listProviders !== prevProps.listProviders && listProviders !== null) {
			this.setState({ searchingProviders: false })
		}
		if (!_.isEqual(provider, prevProps.provider) && !_.isEmpty(provider)) {
			setFieldValue('cond_pago', provider.cpago_cod)
		}
	}

	/**
	 * to search providers.
	 */
	handleSearchProviders = (value: string = '') => {
		const { idOperacion } = this.props
		this.props.searchProviders({ idOperacion, criterio_proveedor: value })
		this.setState({ searchingProviders: true })
	}

	handleSelectProviders = (provider: any) => {}

	/**
	 * to manage provider data when change
	 */
	handleChangeProviders = (provider: any) => {
		const { setFieldValue, idOperacion } = this.props
		this.props.getPaymentTermsFilterList({
			idOperacion,
			proveedor: provider[0].id
		})
		this.props.getProvider({
			idOperacion,
			IdProveedor: provider[0].id
		})
		setFieldValue('proveedor', !_.isEmpty(provider) ? provider[0].id : '')
	}

	handleExport = () => {
		const { idOperacion } = this.props
		this.props.automaticPurchaseExport({ idOperacion })
	}

	render() {
		const {
			fields,
			values,
			handleBlur,
			setFieldTouched,
			setFieldValue,
			listProviders,
			paymentTermsList
		} = this.props

		const { searchingProviders } = this.state
		const formikProps = { setFieldTouched, setFieldValue }

		const propsProvider = {
			inputFormCol: { sm: 5 },
			fields,
			label: 'code',
			inputId: 'proveedor',
			name: 'proveedor',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			onBlur: handleBlur,
			value: '',
			divStyle: { paddingRight: '0px', width: '100%' },
			handleSearch: (value: string) => this.handleSearchProviders(value),
			auoptions: listProviders
				? listProviders.proveedores.map((provider: any) => ({
						id: provider.idProveedor,
						label: provider.rsocial
				  }))
				: [],
			handleLoading: searchingProviders,
			handleSelect: this.handleSelectProviders,
			onChange: this.handleChangeProviders
		}

		const propsDate = {
			inputFormCol: { sm: 5 },
			fields,
			label: 'code',
			inputId: 'fec_entrega',
			name: 'fec_entrega',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			onBlur: handleBlur,
			value: '',
			divStyle: { paddingRight: '0px' },
			autoComplete: 'off',
			onChange: (data: any) =>
				handleSetValueInput(data, 'fec_entrega', formikProps)
		}

		const propsProducts = {
			inputFormCol: { sm: 10 },
			fields,
			label: 'observ',
			inputId: 'observ',
			name: 'observ',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			onBlur: handleBlur,
			value: values.cod_prod,
			divStyle: { paddingRight: '0px' },
			onChange: (data: any) => handleSetValueInput(data, 'observ', formikProps)
		}

		const propsSort = {
			inputFormCol: { sm: 5 },
			fields,
			label: 'l',
			inputId: 'cond_pago',
			name: 'cond_pago',
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			onBlur: handleBlur,
			value: values.cond_pago,
			divStyle: { paddingRight: '0px' },
			options: getOptionsDropDown(paymentTermsList),
			onChange: (data: any) =>
				handleSetValueInput(data, 'cond_pago', formikProps)
		}

		return (
			<Row>
				<Row className={`col-12 `}>
					<InputAutocomplete {...propsProvider} />
					<InputText {...propsDate} />
					<Col className={'text-center '}>
						<Button
							style={{ minWidth: '145px' }}
							type={'button'}
							onClick={this.handleExport}
						>
							{'Exportar'}
						</Button>
					</Col>
				</Row>
				<Row className={`col-12 `}>
					<InputDropdown {...propsSort} />
				</Row>
				<Row className={`col-12 `}>
					<InputTextArea {...propsProducts} />
				</Row>
			</Row>
		)
	}
}

const mapStateToProps = ({ automaticPurchase, providers }: any) => {
	const { providerList, paymentTermsList } = automaticPurchase
	const { provider, listProviders } = providers
	return { providerList, paymentTermsList, provider, listProviders }
}

export default connect(mapStateToProps, {
	getPaymentTermsFilterList,
	getProvider,
	automaticPurchaseExport,
	searchProviders
})(AutomaticPurchaseProviderInputs)
