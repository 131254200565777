import React, { Component, Fragment } from 'react'
import { Modal } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import PrintForm from './printForm'

class PrintModal extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { t, idOperacion, showModal, toggleModal } = this.props
		return (
			<Fragment>
				<Modal
					show={showModal}
					onHide={toggleModal}
					size={'xl'}
					aria-labelledby={'contained-modal-title-vcenter'}
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							{t('finishVoucher.modalTitle')}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<PrintForm
							idOperacion={idOperacion}
							handleCloseModal={toggleModal}
						/>
					</Modal.Body>
				</Modal>
			</Fragment>
		)
	}
}

export default withTranslation()(PrintModal)
