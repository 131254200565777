import {
	confirmTreasuryAttributes,
	getAttributesTreasuryHeader,
	getTableBranchOffices,
	getTreasuryCurrencies
} from 'actions'
import CollapseButton from 'components/common/collapseButton'
import NotificationError from 'components/common/notificationsErrors'
import GenericInputForm from 'components/form/genericInputForm'
import { API_FORMAT_DATE } from 'constants/ApiFormats'
import { P_TREASURY_HEADER } from 'constants/ConfigProcessNames'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { getFiltersData } from 'lib/Utils'
import { IAttribCompFon } from 'models/TreasuryHeader'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Collapse } from 'reactstrap'
import _ from 'underscore'
import TreasuryHeaderFormInputs from './TreasuryHeaderFormInputs'
import {
	ITreasuryHeaderComponentProps,
	ITreasuryHeaderComponentState,
	ITreasuryHeaderForm
} from './TreasuryHeaderType'

class HeaderEntryComponent extends Component<
	ITreasuryHeaderComponentProps,
	ITreasuryHeaderComponentState
> {
	constructor(props: ITreasuryHeaderComponentProps) {
		super(props)
		this.state = { openCollapse: true, genericData: [] }
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.getAttributesTreasuryHeader({
			idOperacion: idOperacion || ''
		})
		this.props.getTreasuryCurrencies({
			idOperacion: idOperacion || ''
		})
		this.props.getTableBranchOffices({ requerido: 1 })
	}

	handleCallBack = () => {}

	handleSubmitForm = (values: any) => {
		const { callBackReturn, idOperacion } = this.props
		const { genericData } = this.state

		this.props.confirmTreasuryAttributes({
			callBackReturn,
			params: {
				...values,
				idOperacion,
				fecha: values.fecha ? moment(values.fecha).format(API_FORMAT_DATE) : '',
				atrib_comp_fon: genericData
			}
		})
	}

	toggleCollapse = () => {
		this.setState((state) => ({ openCollapse: !state.openCollapse }))
	}

	/**
	 * function called when the values are changed
	 */
	handleChangeGeneric = (data: any, formikProps: FormikProps<any>) => {
		const { genericData } = this.state
		const indexField = _.findIndex(genericData, {
			cod_atributo: data.cod_atributo
		})

		if (indexField === -1) {
			genericData.push(data)
		} else {
			genericData[indexField] = data
		}

		this.setState({ genericData })
	}

	handleSearchAuto = () => {}

	/**
	 * render generic fields
	 * @param formikProps
	 * @returns
	 */
	renderGenericFields = (formikProps: FormikProps<any>) => {
		const { formAttributes, config } = this.props
		const result: any = []
		const configGeneric = _.findWhere(config.campos, {
			idCampo: 'atrib_comp_fon'
		})

		if (
			formAttributes.status === 'succeeded' &&
			!_.isEmpty(formAttributes.data)
		) {
			formAttributes.data?.atrib_comp_fon.forEach((element: IAttribCompFon) => {
				result.push(
					<GenericInputForm
						style={{ marginLeft: -5 }}
						config={{ ...configGeneric, ...element }}
						key={element.cod_atrib}
						handleChange={(data: any) => {
							this.handleChangeGeneric(data, formikProps)
						}}
						handleSearchAuto={this.handleSearchAuto}
						autodata={[]}
					/>
				)
			})
		}

		return result
	}

	render() {
		const { headerEntryFormRef, config, t } = this.props
		const { openCollapse } = this.state
		const fields = config
			? getFiltersData(config.campos, { agrupador: 'formulario' })
			: []

		const initialValues = !_.isEmpty(fields) ? getInitValues(fields) : {}
		const validationSchema = fields.length ? getValidationSchema(fields, t) : {}

		return (
			!_.isEmpty(fields) && (
				<Formik
					ref={headerEntryFormRef}
					initialValues={initialValues}
					onSubmit={(values) => {
						this.handleSubmitForm(values)
					}}
					validationSchema={validationSchema}
					enableReinitialize={true}
					render={(props: FormikProps<Partial<ITreasuryHeaderForm>>) => {
						return (
							<Fragment>
								<Col sm={12}>
									<NotificationError
										errors={props.errors}
										touched={props.touched}
									/>
								</Col>
								<Col sm={12}>
									<Form>
										<TreasuryHeaderFormInputs
											formikProps={props}
											fields={fields}
											t={t}
										/>
										<Row className={'col-12'}>
											<Col sm={1}>
												<CollapseButton
													onPress={this.toggleCollapse}
													status={openCollapse}
												/>
											</Col>
											<Col sm={11}>
												<div className="dropdown-divider col-11 p-2" />
											</Col>
										</Row>
										<Collapse style={{ width: '100%' }} isOpen={openCollapse}>
											{this.renderGenericFields(props)}
										</Collapse>
									</Form>
								</Col>
							</Fragment>
						)
					}}
				/>
			)
		)
	}
}

const mapStateToProps = ({ voucher, treasuryHeader }: any) => {
	const config = voucher.config ? voucher.config[P_TREASURY_HEADER] : null
	const { formAttributes } = treasuryHeader

	return { config, formAttributes }
}

const mapDispatchToProps = {
	confirmTreasuryAttributes,
	getAttributesTreasuryHeader,
	getTableBranchOffices,
	getTreasuryCurrencies
}

export default withTranslation()(
	connect(mapStateToProps, mapDispatchToProps)(HeaderEntryComponent)
)
