import {
	clearSalesHeaderFilters,
	confirmSalesHeaders,
	getConfigVoucher,
	getFilterBranches,
	getFilterConditionSale,
	getFilterSellers,
	getFilterTransport,
	getOptionsFilterAffected,
	getOrdenCbtCab,
	getOrderDirOptions,
	getSalesHeaders,
	setSalesHeaderFilters
} from 'actions'
import CommonTable from 'components/common/commonTable'
import NotificationMessage from 'components/common/notificationMessage'
import { P_SELPRE } from 'constants/ConfigProcessNames'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { addPrevValidations } from 'lib/AxiosInterceptors'
import { getSelectFilter, getValueMask } from 'lib/MaskValues'
import { getFiltersData, getFiltersTable } from 'lib/Utils'
import {
	IConfigFieldsForm,
	IConfirmSalesHeadersParams,
	IGetSalesHeadersParams,
	IGetSalesHeadersTableFilters
} from 'models/Budget'
import { Ihistory } from 'models/NavigationInterface'
import { IGetConfigVoucherParams } from 'models/Voucher'
import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import _ from 'underscore'
import { SEARCH_VOUCHER_DETAIL } from 'utils/RoutePath'
import style from './BudgetTable.module.scss'
import HeaderFilterForms from './HeaderFilterForms'

interface BudgetTableProps {
	idOperacion?: any
	config?: IConfigFieldsForm
	authUser?: any
	salesHeaders?: any
	history: Ihistory
	confirmationForm?: any
	urlNextPage?: string
	confirmHeaders: any
	getConfigVoucher: (payload: IGetConfigVoucherParams) => void
	getSalesHeaders: (payload: Partial<IGetSalesHeadersParams>) => void
	confirmSalesHeaders: (payload: IConfirmSalesHeadersParams) => void
	getFilterBranches: () => void
	getOrderDirOptions: () => void
	getFilterSellers: () => void
	getFilterTransport: () => void
	getFilterConditionSale: () => void
	getOrdenCbtCab: () => void
	getOptionsFilterAffected: () => void
	setSalesHeaderFilters: (payload: any) => void
	clearSalesHeaderFilters: () => void
	messageError: { message: string; confirm: boolean }
	barCodeResponse: any
	budgetTableRef?: any
	callBackReturn: any
	confirmParams: any
	headerFilters: IGetSalesHeadersParams
}

interface BudgetTableState {
	filtersKey: any
	rowSelected: string[]
	lastRowSelected: string
	messageError: string
	showError: boolean
	typeError: string
	filtersForm: IGetSalesHeadersParams
	filtersTable: IGetSalesHeadersTableFilters
	tableFiltersReset: any
}

class BudgetTable extends Component<BudgetTableProps, BudgetTableState> {
	constructor(props: BudgetTableProps) {
		super(props)
		this.state = {
			filtersKey: {
				cliente: 'filtro_cliente',
				cod_comp: 'filtro_cod_comp',
				detalle: 'filtro_detalle',
				fecha: 'filtro_fecha',
				nro_comp: 'filtro_nro_comp',
				sucursal: 'filtro_sucursal',
				imp_total: 'filtro_total'
			},
			rowSelected: [],
			lastRowSelected: '',
			messageError: '',
			showError: false,
			typeError: 'danger',
			filtersForm: props.headerFilters,
			filtersTable: {},
			tableFiltersReset: false
		}

		addPrevValidations((e: any) => {
			if (e.request.responseURL.includes('/vta_cab_selcompr')) {
				return true
			}
			return false
		})
	}

	componentDidMount = () => {
		this.props.budgetTableRef(this)
		const { idOperacion, headerFilters } = this.props
		this.props.getSalesHeaders({ ...headerFilters, idOperacion })
		this.props.getFilterBranches()
		this.props.getOrderDirOptions()
		this.props.getFilterSellers()
		this.props.getFilterTransport()
		this.props.getFilterConditionSale()
		this.props.getOrdenCbtCab()
		this.props.getOptionsFilterAffected()
	}

	componentDidUpdate = (
		prevProps: BudgetTableProps,
		prevState: BudgetTableState
	) => {
		const {
			confirmHeaders,
			urlNextPage,
			messageError,
			salesHeaders,
			barCodeResponse,
			idOperacion,
			confirmParams,
			setSalesHeaderFilters
		} = this.props

		const { rowSelected, lastRowSelected, filtersForm, filtersTable } =
			this.state

		if (
			!_.isEqual(barCodeResponse, prevProps.barCodeResponse && barCodeResponse)
		) {
			const params = {
				...filtersForm,
				...filtersTable,
				page_number: 1,
				page_size: 10,
				idOperacion
			}
			this.props.getSalesHeaders({ ...params })
		}

		if (messageError !== prevProps.messageError && messageError) {
			const { message, confirm } = messageError
			const showError = true

			if (confirm) {
				const { values } = confirmParams
				const idxFind = rowSelected.indexOf(lastRowSelected)
				if (idxFind !== -1 && values.confirmar === 0) {
					rowSelected.splice(idxFind, 1)
				}
				this.setState({
					rowSelected: lastRowSelected === 'All' ? [] : rowSelected,
					lastRowSelected: lastRowSelected === 'All' ? '' : lastRowSelected,
					showError,
					messageError: message
				})
			}
		}

		if (
			confirmHeaders &&
			confirmHeaders !== prevProps.confirmHeaders &&
			!_.isEmpty(urlNextPage)
		) {
			this.props.history.push(urlNextPage)
		}

		if (
			salesHeaders !== prevProps.salesHeaders &&
			!_.isEmpty(salesHeaders) &&
			salesHeaders.Items
		) {
			const rowSelected: any = []

			salesHeaders.Items.forEach((item: any) => {
				if (item.sel) {
					rowSelected.push(item.id)
				}
			})

			this.setState({ rowSelected, showError: false }) // Items Selected From DB
		}

		if (
			filtersForm !== prevState.filtersForm ||
			filtersTable !== prevState.filtersTable
		) {
			setSalesHeaderFilters(filtersForm)
			this.setState({ tableFiltersReset: false })
			this.props.getSalesHeaders({ ...filtersForm, ...filtersTable })
		}
	}

	/**
	 * to confirm the form and call CallBack
	 */
	handleConfirmForm = () => {
		const { idOperacion, callBackReturn } = this.props

		this.props.confirmSalesHeaders({
			values: { idOperacion, confirmar: 1, lista_movs: [] },
			callBackReturn
		})
	}

	/**
	 * to create columns in the table
	 * @returns columns
	 */
	getColumns = () => {
		const { config, salesHeaders } = this.props
		const { filtersKey } = this.state

		const fieldsTable = config
			? getFiltersData(config.campos, { agrupador: 'grilla' })
			: []

		const rows = fieldsTable.map((field) => {
			const campoId = field.idCampo.trim()
			return {
				dataField: campoId,
				text:
					filtersKey[campoId] && salesHeaders
						? ''
						: field.label
						? field.label
						: '',
				align: campoId === 'imp_total' ? 'right' : 'center',
				headerAlign: 'center',
				hidden: !field.visible,
				filter: getSelectFilter(
					field,
					salesHeaders,
					filtersKey,
					this.props,
					style.inputFilter
				),
				formatter: (cell: string, row: any) =>
					this.renderFormat(field, cell, row)
			}
		})

		return rows
	}

	/**
	 * render mask value
	 * @param field
	 * @param value
	 * @param row
	 * @returns
	 */
	renderFormat = (field: IFieldStructure, value: any, row: any) => {
		const { idCampo } = field
		let result = value
		const { authUser } = this.props

		if (idCampo === 'nro_comp') {
			result = (
				<Link target="_blank" to={`${SEARCH_VOUCHER_DETAIL}/${row.id}/consult`}>
					{value}
				</Link>
			)
		} else if (field.mascara) {
			result = getValueMask(value, field.mascara, { authUser })
		}

		return result
	}

	/**
	 * to handle change table
	 */
	handleChangeTable = (type: any, pagination: any) => {
		const { idOperacion } = this.props
		const { filtersForm, filtersTable } = this.state

		let formParams: IGetSalesHeadersParams = { ...filtersForm, idOperacion }
		let tableParams: IGetSalesHeadersTableFilters = { ...filtersTable }

		if (type === 'pagination') {
			const pag = {
				page_number: pagination.page,
				page_size: pagination.sizePerPage
			}
			formParams = { ...formParams, ...pag }
		} else if (type === 'filter') {
			const filters =
				type === 'filter' ? getFiltersTable(pagination.filters) : {}
			tableParams = { ...filters }
		}
		this.setState({
			filtersForm: { ...formParams },
			filtersTable: { ...tableParams }
		}) // Save Pagination or filters
	}

	/**
	 * call search api with filters params.
	 * @param filters
	 */
	handleFilterSearch = (filters: any) => {
		const { idOperacion } = this.props
		const { filtersForm } = this.state

		const params = {
			...filtersForm,
			page_size: 10,
			page_number: 1,
			idOperacion
		}

		this.setState({ filtersForm: { ...params, ...filters } })
	}

	/**
	 * to get all select row options
	 */
	getSelectRowOptions = () => {
		const { rowSelected } = this.state

		return {
			mode: 'checkbox',
			selectColumnPosition: 'right',
			selected: rowSelected,
			headerColumnStyle: {
				textAlign: 'center',
				paddingRight: '8px !important'
			},
			style: (row: any) => {
				const backgroundColor = row.error ? '#f8d7da' : '#FFF'
				const marginBottom = 120
				return { backgroundColor, marginBottom }
			},
			onSelect: this.handleOnSelectCheck,
			onSelectAll: this.handleOnSelectAll
		}
	}

	/**
	 * to validate click on check
	 * @param row
	 */
	handleOnSelectCheck = (row: any, isSelect: boolean) => {
		const { idOperacion } = this.props
		const { rowSelected } = this.state
		let lastRowSelected = ''

		if (isSelect) {
			rowSelected.push(row.id)
			lastRowSelected = row.id
		} else {
			const idxFind = rowSelected.indexOf(row.id)
			rowSelected.splice(idxFind, 1)
		}
		const values = {
			idOperacion,
			confirmar: 0,
			lista_movs: [{ id: row.id, sel: isSelect ? 1 : 0 }]
		}
		this.props.confirmSalesHeaders({ values })

		this.setState({ rowSelected, lastRowSelected })
	}

	/**
	 * to render the actions of the inputs checks
	 *
	 */
	handleOnSelectAll = (isSelect: boolean, rows: any) => {
		const { idOperacion } = this.props
		const rowSelected: string[] = []

		const lista_movs = rows.map((row: any) => {
			if (isSelect) {
				rowSelected.push(row.id)
			}
			return {
				id: row.id,
				sel: isSelect ? 1 : 0
			}
		})

		const values = {
			idOperacion,
			confirmar: 0,
			lista_movs
		}
		this.props.confirmSalesHeaders({ values })
		this.setState({ rowSelected, lastRowSelected: 'All' })
	}

	/**
	 * close notification error
	 */
	handleCloseErrorNotification = () => {
		this.setState({ showError: false })
	}

	handleClearFilters = () => {
		const { idOperacion } = this.props

		this.setState({
			filtersForm: {
				idOperacion,
				page_number: 1,
				page_size: 10,
				fec_desde: '',
				fec_hasta: '',
				suc_emp: '',
				vendedor: '',
				transporte: '',
				cond_vta: '',
				orden: 'F',
				sentido: 'D',
				rsoc: ''
			},
			filtersTable: {},
			tableFiltersReset: true
		})
	}

	render() {
		const { config, salesHeaders, idOperacion } = this.props
		const {
			showError,
			messageError,
			typeError,
			filtersForm,
			tableFiltersReset
		} = this.state

		const fieldsFilter = config
			? getFiltersData(config.campos, { agrupador: 'formulario' })
			: []

		const budgetTableProps = {
			remote: true,
			columns: !_.isEmpty(config) ? this.getColumns() : [],
			selectRow: this.getSelectRowOptions(),
			data: _.isEmpty(salesHeaders)
				? []
				: salesHeaders.Items
				? salesHeaders.Items
				: [],
			paginationOptions: salesHeaders
				? {
						pageStartIndex: 1,
						sizePerPage: filtersForm.page_size,
						page: filtersForm.page_number,
						totalSize: salesHeaders.total_count
				  }
				: {},
			onTableChange: this.handleChangeTable
		}

		const notificationsProps = {
			showError,
			errorMessage: messageError,
			type: typeError,
			handleCloseError: this.handleCloseErrorNotification
		}

		return (
			<Col className={`col-12`}>
				{!_.isEmpty(fieldsFilter) && (
					<HeaderFilterForms
						initialState={filtersForm}
						fields={fieldsFilter}
						handleFilterSearch={this.handleFilterSearch}
						idOperacion={idOperacion}
						clearFilters={this.handleClearFilters}
					/>
				)}
				<NotificationMessage {...notificationsProps} />
				{!_.isEmpty(config) && !tableFiltersReset && (
					<CommonTable {...budgetTableProps} />
				)}
			</Col>
		)
	}
}

const mapStateToProps = ({ voucher, auth, budgetItems }: any) => {
	const config = voucher.config ? voucher.config[P_SELPRE] : null
	const { authUser } = auth
	const {
		salesHeaders,
		confirmHeaders,
		messageError,
		barCodeResponse,
		confirmParams,
		headerFilters
	} = budgetItems
	return {
		config,
		authUser,
		salesHeaders,
		confirmHeaders,
		messageError,
		barCodeResponse,
		confirmParams,
		headerFilters
	}
}

const mapDispatchToProps = {
	getConfigVoucher,
	getSalesHeaders,
	getFilterBranches,
	getOrderDirOptions,
	getFilterSellers,
	getFilterTransport,
	getFilterConditionSale,
	getOrdenCbtCab,
	confirmSalesHeaders,
	getOptionsFilterAffected,
	setSalesHeaderFilters,
	clearSalesHeaderFilters
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(BudgetTable))
