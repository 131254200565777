import SelectForm from 'components/selectType/selectForm'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
	getVoucherType,
	getVoucherTypeByUser,
	selectCompany
} from '../../actions'
import withMenu from '../../components/common/withMenu'
import { isCompanyAllow } from '../../lib/AuthUtils'
import { getUrl } from '../../lib/BreadCrumbsUtils'
import { SELECTTYPE } from '../../utils/RoutePath'

/**
 *
 * Form to select a new voucher
 *
 **/
class SelectType extends Component {
	constructor(props) {
		super(props)
		this.state = {
			type: null
		}
	}

	componentDidMount = () => {
		const { authUser } = this.props
		if (this.props.match) {
			const { params } = this.props.match

			if (params.idCompany && authUser.userCompany !== params.id) {
				if (
					isCompanyAllow(authUser, params.idCompany) &&
					authUser.userCompany !== params.idCompany
				) {
					this.props.selectCompany({ company: params.idCompany })
				} else if (!isCompanyAllow(authUser, params.idCompany)) {
					this.props.setCompanyError(true)
				}
				this.setState({ idCompany: params.idCompany }) // Set in the state the new id company
			} else {
				this.props.history.push(`${SELECTTYPE}/${authUser.userCompany}`)
			}
		}

		this.props.getVoucherTypeByUser()
	}

	componentDidUpdate = (prevProps, prevState, snapshot) => {
		const { voucherType } = this.props
		if (
			prevProps.voucherType !== voucherType &&
			voucherType &&
			voucherType.idOperacion
		) {
			const urlForm = getUrl(
				voucherType.procesos[0].cod_proceso,
				voucherType.idOperacion
			)
			this.props.history.push(urlForm)
		}
	}

	render() {
		const { t, theme, userVoucherType, authUser } = this.props

		return (
			<Row>
				<Col sm={12} className={theme.Title}>
					<h5>{t('selectType.title')}</h5>
				</Col>
				<Col sm={12} className={theme.Title}>
					<SelectForm
						optionsSelect={
							userVoucherType.comprobantes ? userVoucherType.comprobantes : []
						}
						userCompany={authUser.userCompany}
						getVoucherType={this.props.getVoucherType}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = ({ vouchertype, auth }) => {
	const { userVoucherType, voucherType } = vouchertype
	const { authUser } = auth
	return { userVoucherType, authUser, voucherType }
}

export default connect(mapStateToProps, {
	getVoucherTypeByUser,
	selectCompany,
	getVoucherType
})(withTranslation()(withMenu(SelectType)))
