import {
	ICrudCreateProductCode,
	ICrudCreateProductCodeRes,
	ICrudGetProductCode,
	ICrudGetProductCodeConfig,
	ICrudGetProductCodeConfigRes,
	ICrudGetProductCodeInfo,
	ICrudGetProductCodeInfoRes,
	ICrudGetProductCodeRes
} from 'models/CrudProductsInterface'
import {
	CRUD_ACCEPT_PRODUCT_CODE,
	CRUD_ACCEPT_PRODUCT_CODE_SUCCESS,
	CRUD_CLEAR_PRODUCT_CODE_STORE,
	CRUD_GET_PRODUCT_CODE,
	CRUD_GET_PRODUCT_CODE_CONFIG,
	CRUD_GET_PRODUCT_CODE_CONFIG_SUCCESS,
	CRUD_GET_PRODUCT_CODE_INFO,
	CRUD_GET_PRODUCT_CODE_INFO_SUCCESS,
	CRUD_GET_PRODUCT_CODE_SUCCESS,
	CRUD_VALIDATE_PRODUCT_CODE,
	CRUD_VALIDATE_PRODUCT_CODE_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetProductCode = (payload: ICrudGetProductCode) => {
	return {
		type: CRUD_GET_PRODUCT_CODE,
		payload
	}
}

export const crudGetProductCodeSuccess = (payload: ICrudGetProductCodeRes) => {
	return {
		type: CRUD_GET_PRODUCT_CODE_SUCCESS,
		payload
	}
}

export const crudGetProductCodeConfig = (
	payload: ICrudGetProductCodeConfig
) => {
	return {
		type: CRUD_GET_PRODUCT_CODE_CONFIG,
		payload
	}
}

export const crudGetProductCodeConfigSuccess = (
	payload: ICrudGetProductCodeConfigRes
) => {
	return {
		type: CRUD_GET_PRODUCT_CODE_CONFIG_SUCCESS,
		payload
	}
}

export const crudValidateProductCode = (payload: ICrudCreateProductCode) => {
	return {
		type: CRUD_VALIDATE_PRODUCT_CODE,
		payload
	}
}

export const crudValidateProductCodeSuccess = (
	payload: ICrudCreateProductCodeRes
) => {
	return {
		type: CRUD_VALIDATE_PRODUCT_CODE_SUCCESS,
		payload
	}
}

export const crudGetProductCodeInfo = (payload: ICrudGetProductCodeInfo) => {
	return {
		type: CRUD_GET_PRODUCT_CODE_INFO,
		payload
	}
}

export const crudGetProductCodeInfoSuccess = (
	payload: ICrudGetProductCodeInfoRes
) => {
	return {
		type: CRUD_GET_PRODUCT_CODE_INFO_SUCCESS,
		payload
	}
}

export const crudAcceptProductCode = (payload: ICrudCreateProductCode) => {
	return {
		type: CRUD_ACCEPT_PRODUCT_CODE,
		payload
	}
}

export const crudAcceptProductCodeSuccess = (
	payload: ICrudCreateProductCodeRes
) => {
	return {
		type: CRUD_ACCEPT_PRODUCT_CODE_SUCCESS,
		payload
	}
}

export const crudClearProductCodeStore = () => ({
	type: CRUD_CLEAR_PRODUCT_CODE_STORE
})
