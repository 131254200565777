import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	getPriceAddCart,
	getPriceByProduct,
	loadItemInventory,
	loadItemInventoryConfirm,
	searchProducts,
	setTableDataProducts,
	validateProductCost,
	validateProductCostNet,
	validateProductCostPC,
	validateProductDiscount,
	validateProductPrice
} from 'actions'
import CommonTable from 'components/common/commonTable'
import DisplayLight from 'components/common/displayLight'
import NotificationMessage from 'components/common/notificationMessage'
import PopupImage from 'components/common/popupImage'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import InputPriceUnit from 'components/loadItems/inputPriceUnit'
import StockPopup from 'components/Stock/stockPopup'
import { LoadInventoryItemsActions } from 'constants/ActionsTypesTs'
import { P_LOAD_ITEM_INV } from 'constants/ConfigProcessNames'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { validateField } from 'lib/FieldValidations'
import { getValueMask } from 'lib/MaskValues'
import {
	handleIsRendeTableFinish,
	handleSetFocus,
	isValueChange
} from 'lib/TableUtils'
import { getFiltersData } from 'lib/Utils'
import { ILoadItemInventoryProps } from 'models/LoadInventoryItems'
import {
	IGetPriceAddCartParams,
	IProductItem,
	IProductsSearched
} from 'models/Products'
import React, { Component, forwardRef, Fragment, Ref } from 'react'
import { Button, Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { ILoadItemInventoryStore } from 'reducers/LoadInventoryItems'
import _ from 'underscore'
import './LoadInventoryItemsTable.scss'
import {
	IHandleMoveArrowProps,
	ILoadInventoryItemsTableProps,
	IOnInputEvent,
	IRenderFieldProps,
	LoadInventoryItemsTableState,
	onBlurInputProps
} from './LoadInventoryItemsTableType'
import {
	excludeFields,
	getCountVisibleColumns,
	getExpandRow,
	getInputOptions,
	getNextNameField,
	getNextProductId,
	getRowById,
	getStyleColumn,
	getValueUpdateTable,
	handleUpdateDataTable,
	setInitRow
} from './LoadInventoryItemsTableUtils'

const SHOW_STOCK_PARAMS = 1
class LoadInventoryItemsTable extends Component<
	ILoadInventoryItemsTableProps,
	LoadInventoryItemsTableState
> {
	inputRefs: any = []
	firstInputRef: any = null
	primaryKey: keyof IProductItem = 'niprod'

	constructor(props: ILoadInventoryItemsTableProps) {
		super(props)
		this.state = {
			fieldsPriceEstTable: null,
			fieldsPriceForm: null,
			fieldsPriceTable: [],
			fieldsTable: [],
			fieldsExpandRow: [],
			tableColumns: null,
			productTable: [],
			updatedRow: null,
			updateDataTable: [],
			updateField: '',
			showStock: false,
			referenceItem: null,
			showError: false,
			typeNotification: 'success',
			errorMessage: '',
			errorTitle: ''
		}
	}

	componentDidMount = (): void => {
		const { config } = this.props
		if (!_.isEmpty(config)) {
			const tableColumns = this.getColumns()
			this.setState({ tableColumns })
		}
	}

	componentDidUpdate = (
		prevProps: Readonly<ILoadInventoryItemsTableProps>
	): void => {
		const { search, inventoryItems } = this.props

		this.handleSetFields(prevProps.search, search)
		this.handleValidateStock(prevProps.inventoryItems, inventoryItems)
	}

	/**
	 * to update data from table
	 */
	static getDerivedStateFromProps(
		props: ILoadInventoryItemsTableProps,
		state: LoadInventoryItemsTableState
	) {
		const { productsUpdate } = props
		const { productTable, updatedRow } = state
		if (!_.isEqual(productsUpdate, updatedRow) && !_.isEmpty(productsUpdate)) {
			if (!_.isArray(productsUpdate)) {
				const rowData = productTable.map((prod) => {
					let result = {}
					if (productsUpdate.niprod === prod.niprod) {
						result = {
							...productsUpdate,
							id: productsUpdate.niprod
						}
					} else {
						result = {
							...prod,
							id: prod.niprod
						}
					}
					return result
				})
				return {
					productTable: rowData,
					updatedRow: { ...productsUpdate }
				}
			} else {
				return {
					productTable: productsUpdate.map((prd) => prd)
				}
			}
		}
		return null
	}

	/**
	 * To set focus in next row in the first editable field
	 * depends on the product that has just been entered into the cart
	 */
	setFocusNextRow = () => {
		const { inventoryItems, search, setTableDataProducts } = this.props
		const { fieldsTable } = this.state
		const { productos } = search
		const { params } = inventoryItems

		if (!params) {
			return
		}

		this.handleShowConfirmationMessage(params)

		const nextRow = getNextProductId({
			idProduct: params.Niprod,
			products: productos
		})

		if (nextRow) {
			const firstField = fieldsTable[0]
			const field = firstField.editable
				? firstField.idCampo
				: this.getNextEditField(firstField.idCampo) // first field

			// Set focus
			handleSetFocus(field, nextRow, this.inputRefs)
			setInitRow({ niprod: params.Niprod, setTableDataProducts }) // Clear last table row
		}
	}

	/**
	 * to show messages after send product to cart
	 * @param params
	 */

	handleShowConfirmationMessage = (params: any) => {
		const { t } = this.props
		const { productTable, updateDataTable, fieldsTable } = this.state
		const configNeto = _.find(fieldsTable, { idCampo: 'neto' })

		const addRow = getRowById({ rowId: params.Niprod, productTable })

		const updateDesc = _.findWhere(updateDataTable, {
			rowId: params.Niprod
		})
		const product = !_.isEmpty(updateDesc?.desc_prod)
			? updateDesc.desc_prod
			: addRow?.desc_prod

		const successMessage = t('itemAdd-Cart', {
			cantidad: params.cantidad,
			unidad: params.cod_unid,
			producto: product,
			neto: configNeto?.mascara
				? getValueMask(params.neto, configNeto.mascara, this.props)
				: params.neto
		})

		this.setState({
			showError: true,
			errorMessage: successMessage,
			errorTitle: '',
			typeNotification: 'success'
		})
	}

	/**
	 * to manage responde cart to open stock popup
	 * @param prevItems
	 * @param items
	 */
	handleValidateStock = (
		prevItems: ILoadItemInventoryStore,
		items: ILoadItemInventoryStore
	) => {
		if (!_.isEqual(prevItems, items) && items.status === 'succeeded') {
			const { data } = items
			if (data?.solic_stock === SHOW_STOCK_PARAMS) {
				this.setState({ showStock: true, referenceItem: data?.it })
			}
			this.handleCloseError() // to close the error message
			this.setFocusNextRow() // to focus the next row
		}
	}

	/**
	 * to set fields of forms
	 * @param prevSearch
	 * @param search
	 */
	handleSetFields = (
		prevSearch: IProductsSearched,
		search: IProductsSearched
	) => {
		const { config } = this.props
		if (search !== prevSearch && search && search.productos) {
			const fieldsPriceEstTable = getFiltersData(config.campos, {
				agrupador: 'vprec.est'
			})

			const fieldsPriceTable = getFiltersData(config.campos, {
				agrupador: 'vprec.lprec'
			})

			const fieldsPriceForm = getFiltersData(config.campos, {
				agrupador: 'vprec.cab'
			})

			const fieldsExpandRow = getFiltersData(config.campos, {
				agrupador: 'grilla',
				adicional: 1
			})

			const fieldsTable = getFiltersData(config.campos, { agrupador: 'grilla' })
			const tableColumns = this.getColumns()
			this.setState({
				showError: false,
				productTable: search.productos,
				tableColumns,
				fieldsPriceEstTable,
				fieldsPriceTable,
				fieldsPriceForm,
				fieldsTable,
				fieldsExpandRow
			})
		}
	}

	/**
	 * to print columns table
	 * @returns
	 */

	getColumns = () => {
		const { config } = this.props
		const fieldsTable = getFiltersData(config.campos, { agrupador: 'grilla' })
		const countColumns = getCountVisibleColumns(fieldsTable) as any
		const rows: any = fieldsTable?.map((field: IFieldStructure) => {
			const fieldId = field.idCampo.trim()
			return {
				dataField: fieldId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: getStyleColumn({ field, countColumns }),
				hidden:
					!field.visible || fieldId === 'fin_item' || fieldId === 'sig_proceso',
				formatter: (cell: any, row: any) => this.renderFormat(field, cell, row)
			}
		})

		rows.push({
			dataField: 'actions',
			text: '',
			align: 'center',
			headerAlign: 'center',
			headerStyle: { width: '2%' },
			hidden: false,
			formatter: (cell: string, row: any) => {
				const customRef = React.createRef<HTMLButtonElement>()
				if (!this.inputRefs.addToCart) {
					this.inputRefs.addToCart = {}
				}
				this.inputRefs.addToCart[row[this.primaryKey]] = customRef
				return (
					<Button
						ref={customRef}
						id={`bottomAdd-${row[this.primaryKey]}`}
						className={`btn btn-light buttonAddToCart`}
						onClick={() => this.handlePreventClickAddCart(row)}
					>
						<FontAwesomeIcon icon={faShoppingCart} />
					</Button>
				)
			}
		})

		return [
			{
				dataField: 'id_imagen',
				text: '',
				align: 'right',
				headerStyle: { width: '2%' },
				formatter: (cell: string, row: any) => {
					return (
						<Fragment>
							<PopupImage product={row} />
						</Fragment>
					)
				}
			}
		].concat(rows)
	}

	/**
	 * to render field on table
	 * @param field IFieldStructure
	 * @param cell string
	 * @param row IProductItem
	 * @returns
	 */
	renderFormat = (
		field: IFieldStructure,
		value: string = '',
		row: IProductItem
	) => {
		/*******************************/
		this.handleSetInputRefs(field, row[this.primaryKey] as number)
		/*******************************/

		const inputOptions = getInputOptions({
			field,
			row,
			value,
			onEnterKeyPress: this.handleEnterKey,
			onChangeInput: this.handleOnChange,
			inputRefs: this.inputRefs
		})

		return this.renderField({
			field,
			row,
			inputOptions,
			value
		})
	}

	/**
	 * to validate click event and add to cart.
	 * @param row from product
	 */
	handlePreventClickAddCart = (row: IProductItem) => {
		const { updateField } = this.state
		// Set focus field neto
		handleSetFocus('neto', row[this.primaryKey], this.inputRefs)
		this.preventAddToCart(row[this.primaryKey] as number, updateField)
	}

	/**
	 * To add products to cart when used insert key
	 * @params rowId
	 * @params field
	 */
	preventAddToCart = async (rowId: number, field: string) => {
		const { idOperacion, setTableDataProducts, searchParameters } = this.props
		const { productTable, updateDataTable } = this.state
		const rowData = getRowById({ rowId, productTable })
		const stateValue = getValueUpdateTable({ rowId, field, updateDataTable })
		const rowDataValue = rowData ? rowData[field as keyof IProductItem] : ''
		const fieldValue = stateValue || rowDataValue

		if (fieldValue && fieldValue !== 'error_input') {
			if (field === 'cantidad') {
				// Call api to return price
				const params: IGetPriceAddCartParams = {
					idOperacion,
					Idproducto: rowId,
					cantidad: fieldValue,
					unid_vta: rowData?.cod_unid || '',
					desc_prod: rowData?.desc_prod || '',
					cuf_dest: searchParameters.cuf_dest
				}

				this.props.getPriceAddCart({
					params,
					apiByForm: LoadInventoryItemsActions.FORM_LOAD_ITEM_INVENTORY
				})
			} else {
				await this.handleOnblurInput({
					value: fieldValue,
					fieldId: field,
					row: rowData as IProductItem
				})
				if (rowData) this.handleAddToCart(rowData)
			}

			setInitRow({ niprod: rowId, setTableDataProducts })
		} else if (rowData) {
			this.handleAddToCart(rowData)
		}
	}

	/**
	 * To add products to shopping cart
	 * @params row
	 */
	handleAddToCart = (row: IProductItem) => {
		const { loadItemInventory, idOperacion, t, searchParameters } = this.props
		const { updateDataTable, fieldsTable } = this.state
		const updateDesc = _.findWhere(updateDataTable, { rowId: row.niprod })
		const message: Array<string> = []

		const params: ILoadItemInventoryProps = {
			idOperacion,
			Niprod: row.niprod,
			cod_unid: row.cod_unid,
			cantidad: row.cantidad,
			pcio_unit: row.pcio_unit,
			neto: row.neto,
			cuf_dest: searchParameters.cuf_dest || '',
			desc_prod: !_.isEmpty(updateDesc?.desc_prod)
				? updateDesc.desc_prod
				: row.desc_prod
		}
		const requireFields = _.filter(fieldsTable, { requerido: 1 })

		requireFields.forEach((field) => {
			const fieldId = field.idCampo.trim()
			if (
				!excludeFields.includes(fieldId) &&
				!validateField(row[fieldId as keyof IProductItem], field.valid)
			) {
				const params: any = { niprod: row.niprod }
				params[fieldId] = 'error_input'
				message.push(
					'El campo ' + t('validation-required', { field: field.label })
				)
			}
		})

		if (_.isEmpty(message)) {
			loadItemInventory(params)
		} else {
			this.setState({
				// updateRecord: null,
				showError: true,
				typeNotification: 'danger',
				errorMessage: message,
				errorTitle: t('global.input_require')
			})
		}
	}

	/**
	 * render input field in the table
	 * @param props
	 * @returns
	 */
	renderField = (props: IRenderFieldProps) => {
		const { authUser, idOperacion } = this.props
		const {
			fieldsPriceEstTable,
			fieldsPriceTable,
			fieldsPriceForm,
			fieldsTable
		} = this.state
		const { field, row, value, inputOptions } = props
		const configPrice = _.findWhere(fieldsTable, {
			idCampo: 'modif_pcio'
		})
		const fieldId = field.idCampo.trim()

		const selectOptions = Array.from(
			new Set(row.presentaciones.map((s) => s.cod_pres))
		).map((cod_pres) => ({
			id: cod_pres,
			label: row.presentaciones.find((s) => s.cod_pres === cod_pres)?.cod_pres
		}))

		const showPriceIcon =
			row.cantidad > 0 && !_.isEmpty(configPrice) && configPrice.visible === 1

		switch (fieldId) {
			case 'cod_unid':
				return (
					<span
						className="d-inline-block"
						data-bs-toggle="tooltip"
						title={row[fieldId]}
					>
						<InputDropdown
							{...inputOptions}
							options={selectOptions}
							tooltip
							noInitialExecute
							onChange={(data: React.ChangeEvent<HTMLInputElement>) => {
								const { value } = data.target
								this.props.getPriceByProduct({
									idOperacion: this.props.idOperacion,
									Idproducto: row.niprod,
									cantidad: row.cantidad || 0,
									unid_vta: value,
									pcio_unit: row.pcio_unit
								})
							}}
						/>
					</span>
				)

			case 'ind_stock':
				return <DisplayLight title={row.stock_disp} semaforo={value} />

			case 'modif_pcio':
				return (
					<InputPriceUnit
						idOperacion={idOperacion}
						handleSubmitPrice={this.handleSubmitPrice}
						row={row}
						showPriceIcon={showPriceIcon}
						fieldsPriceEstTable={fieldsPriceEstTable}
						fieldsPriceTable={fieldsPriceTable}
						fieldsPriceForm={fieldsPriceForm}
					/>
				)

			default:
				if (field.editable) {
					return (
						<InputText
							{...inputOptions}
							autoComplete="off"
							onBlur={(value: string) => {
								this.handleOnblurInput({ value, fieldId, row })
							}}
						/>
					)
				}

				if (field.mascara) {
					return getValueMask(value, field.mascara, { authUser })
				}

				return value
		}
	}

	/**
	 * To update data in reducer in the onblur event
	 * @params value
	 * @params fieldId
	 * @params row
	 */
	handleOnblurInput = async (props: onBlurInputProps) => {
		const { value, fieldId, row } = props
		const { idOperacion } = this.props
		const { productTable } = this.state

		if (
			isValueChange(
				row[this.primaryKey],
				fieldId,
				value,
				this.primaryKey,
				productTable
			)
		) {
			this.setState({ updateField: fieldId })
			switch (fieldId) {
				case 'pc_dto':
					this.props.validateProductCostPC({
						idOperacion,
						idProducto: row.niprod,
						pc_dto: value,
						cantidad: row.cantidad,
						unid_vta: row.cod_unid,
						pcio_unit: row.pcio_unit
					})
					break
				case 'pc_lista':
					this.props.validateProductDiscount({
						idOperacion,
						idProducto: row.niprod,
						cantidad: row.cantidad,
						pc_lista: value
					})
					break
				case 'cantidad':
					this.props.getPriceByProduct({
						idOperacion: this.props.idOperacion,
						Idproducto: row.niprod,
						cantidad: value,
						unid_vta: row.cod_unid,
						pcio_unit: row.pcio_unit
					})
					break
				case 'pcio_unit':
					this.props.validateProductCost({
						idOperacion,
						idProducto: row.niprod,
						pcio_unit: value,
						cantidad: row.cantidad,
						unid_vta: row.cod_unid
					})
					break
				case 'neto':
					this.props.validateProductCostNet({
						idOperacion,
						idProducto: row.niprod,
						neto: value,
						cantidad: row.cantidad,
						unid_vta: row.cod_unid
					})
					break
			}
		}
	}

	/**
	 *  to manage the press of enter key
	 * @param props IOnEnterKeyPressProps
	 */
	handleEnterKey = (props: IOnInputEvent) => {
		const { event, value, fieldId, row } = props
		const nextField = this.getNextEditField(fieldId)

		if (fieldId === 'fec_entrega') {
			this.handleOnblurInput({ value, fieldId, row })
		}

		handleSetFocus(nextField, row.niprod, this.inputRefs)
		event.preventDefault()
	}

	/**
	 * to manage the onchange event from the input
	 * @param props
	 */
	handleOnChange = (props: IOnInputEvent) => {
		const { fieldId, row, value, event } = props
		const { updateDataTable } = this.state

		const newTable = handleUpdateDataTable({
			rowId: row[this.primaryKey] as number,
			fieldId,
			value,
			updateDataTable
		})
		this.setState({ updateDataTable: newTable })
		if (
			fieldId === 'fec_entrega' &&
			event !== undefined &&
			event.nativeEvent.type === 'click'
		) {
			// To validate event click of calendar.
			this.handleOnblurInput({ value, fieldId, row })
			const nextField = this.getNextEditField(fieldId)
			handleSetFocus(nextField, row.niprod, this.inputRefs)
		}
	}

	/**
	 * call api to validate price
	 * @param {*} row
	 * @param {*} newPrice
	 */
	handleSubmitPrice = (row: IProductItem, precio: number) => {
		const { idOperacion } = this.props

		this.props.validateProductPrice({
			idOperacion,
			idProducto: row.niprod,
			cantidad: row.cantidad || 0,
			unid_vta: row.cod_unid,
			precio
		})

		this.setState({ updatedRow: null, updateField: 'modif_pcio' })
	}

	/**
	 * to get the next editable field.
	 * @param {*} field
	 * @returns
	 */
	getNextEditField = (field: string) => {
		const { fieldsTable } = this.state
		return getNextNameField(field, fieldsTable, excludeFields)
	}

	/**
	 * to creates a reference array of all table inputs
	 * @param field IFieldStructure
	 * @param rowId string
	 */
	handleSetInputRefs = (field: IFieldStructure, rowId: number) => {
		const fieldId = field.idCampo.trim()

		if (field.editable && !this.inputRefs[fieldId]) {
			this.inputRefs[fieldId] = {}
		}

		if (field.editable && !this.inputRefs[fieldId][rowId]) {
			const customRef = React.createRef()
			this.inputRefs[fieldId][rowId] = customRef
			if (this.firstInputRef === null) {
				this.firstInputRef = customRef
			}
		}
	}

	/**
	 * To move focus to other rows.
	 * @params {rowId, field, key}
	 */
	handleMoveArrow = (props: IHandleMoveArrowProps) => {
		const { productos } = this.props.search
		const { rowId, field, key } = props
		const reverse = key === 'ArrowUp'
		const nextRow = getNextProductId({
			idProduct: rowId,
			reverse,
			products: productos
		})
		handleSetFocus(field, nextRow, this.inputRefs)
	}

	/**
	 * handle table pagination
	 * @param type
	 * @param pagination
	 */
	handleTableChange = (type: string, pagination: any) => {
		this.inputRefs = {}
		const { searchParameters } = this.props
		const { page, sizePerPage } = pagination
		this.props.searchProducts({
			...searchParameters,
			page_number: page,
			page_size: sizePerPage
		})
	}

	/**
	 * to set focus in the first editable field after render table
	 */
	focusAfterRender = () => {
		setTimeout(() => {
			handleIsRendeTableFinish(this.firstInputRef)
		}, 100)
	}

	handleCloseStock = () => {
		this.setState({ showStock: false })
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	handleItemsConfirm = () => {
		const { idOperacion, callBackReturn } = this.props
		this.props.loadItemInventoryConfirm({
			params: { idOperacion, isIncome: false },
			callBackReturn
		})
	}

	render() {
		const { search, idOperacion, inventoryItems, cufSelected } = this.props
		const {
			tableColumns,
			productTable,
			showStock,
			referenceItem,
			showError,
			errorMessage,
			errorTitle,
			typeNotification,
			fieldsExpandRow
		} = this.state

		const params: any = { ...inventoryItems.params, cuf: cufSelected }

		const stockPopupParams = {
			params,
			showStock,
			idOperacion,
			referenceItem,
			handleClose: this.handleCloseStock,
			handleCloseCartMessage: this.handleCloseError
		}

		const notificationMessageProps = {
			showError,
			errorMessage,
			errorTitle,
			handleCloseError: this.handleCloseError,
			type: typeNotification
		}

		const options = search.productos
			? {
					page: search.page_number,
					sizePerPage: search.page_size,
					totalSize: search.total_count
			  }
			: {}

		return (
			!_.isEmpty(productTable) && (
				<Col>
					<NotificationMessage {...notificationMessageProps} />
					<StockPopup {...stockPopupParams} />
					<CommonTable
						remote
						columns={tableColumns}
						data={productTable || []}
						keyField={this.primaryKey}
						rowClasses={'tableRow'}
						headerClasses={'tableHeader'}
						wrapperClasses={'tableWrapper'}
						expandRow={getExpandRow({
							products: search.productos,
							idOperacion,
							fieldsExpandRow
						})}
						paginationOptions={options}
						onTableChange={this.handleTableChange}
						isRendeTableFinish={this.focusAfterRender}
					/>
				</Col>
			)
		)
	}
}

const mapStateToProps = ({
	product,
	voucher,
	auth,
	loadInventoryItems
}: any) => {
	const { search, productsUpdate, searchParameters } = product
	const { inventoryItems } = loadInventoryItems
	const config = voucher.config ? voucher.config[P_LOAD_ITEM_INV] : null
	const { authUser } = auth

	return {
		search,
		config,
		authUser,
		productsUpdate,
		inventoryItems,
		searchParameters
	}
}

const mapDispatchToProps = {
	validateProductCostPC,
	validateProductDiscount,
	validateProductCost,
	validateProductCostNet,
	getPriceByProduct,
	getPriceAddCart,
	validateProductPrice,
	loadItemInventory,
	setTableDataProducts,
	searchProducts,
	loadItemInventoryConfirm
}

const LoadInventoryItemsTableWithRef = forwardRef(
	function LoadInventoryItemsTableWrapper(
		props: ILoadInventoryItemsTableProps,
		ref: Ref<LoadInventoryItemsTable>
	) {
		return <LoadInventoryItemsTable {...props} ref={ref} />
	}
)

export default withTranslation('', { withRef: true })(
	connect(mapStateToProps, mapDispatchToProps, null, {
		forwardRef: true
	})(LoadInventoryItemsTableWithRef)
)
