import {
	CRUD_CLEAR_VALUES_STORE,
	CRUD_CREATE_VALUE,
	CRUD_CREATE_VALUE_SUCCESS,
	CRUD_DEL_VALUE,
	CRUD_DEL_VALUE_SUCCESS,
	CRUD_GET_VALUE,
	CRUD_GET_VALUES_LIST,
	CRUD_GET_VALUES_LIST_SUCCESS,
	CRUD_GET_VALUE_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	valuesList: any
	valueSelected: any
	valueUpdated: any
	valueRemoved: any
}

const initialState = {
	valuesList: null,
	valueSelected: null,
	valueUpdated: null,
	valueRemoved: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_VALUES_LIST:
			return { ...state, valuesList: null }
		case CRUD_GET_VALUES_LIST_SUCCESS:
			return { ...state, valuesList: action.payload }
		case CRUD_GET_VALUE:
			return { ...state, valueSelected: null }
		case CRUD_GET_VALUE_SUCCESS:
			return { ...state, valueSelected: action.payload }
		case CRUD_CREATE_VALUE:
			return { ...state, valueUpdated: null }
		case CRUD_CREATE_VALUE_SUCCESS:
			return { ...state, valueUpdated: action.payload }
		case CRUD_CLEAR_VALUES_STORE:
			return { ...state, valueSelected: null, valueUpdated: null }
		case CRUD_DEL_VALUE:
			return { ...state, valueRemoved: null }
		case CRUD_DEL_VALUE_SUCCESS:
			return { ...state, valueRemoved: action.payload }

		default:
			return state
	}
}

export default rootReducer
