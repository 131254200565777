export const TYPE_VOUCHER = 'tipo_comp'
export const CATEGORY_VOUCHER = 'cat_comp'
export const NRO_VOUCHER = 'numero'
export const INTER_NRO = 'nro_int'
export const TYPE_NUMBER = 'typeNumber'
export const NRO_NUMBER = 'nroNumber'
export const DATE_FROM = 'fec_desde'
export const DATE_UNTIL = 'fec_hasta'
export const DESCRIPTION = 'descrip'
export const CLIENT = 'rsoc'
export const PENDING = 'solo_pend'
export const SORT = 'orden'
export const INPUT_ORDER = 'orden_dir'
export const MODULE = 'modulo'
export const BRANCH = 'sucursal'
export const VOUCHER_DESC = 'comprobante'
export const IMP_UNTIL = 'impo_hasta'
export const IMP_FROM = 'impo_desde'
export const ATTRIB_DESC = 'atrib_desc'
export const ATTRIB_VALUE = 'atrib_valor'
export const BASIC_TAB = 'basic_tab'
export const ADVANCED_TAB = 'advanced_tab'
export const INVENTORY_TAB = 'inventory_tab'
export const TREASURY_TAB = 'treasury_tab'
export const ACCOUNTANT_TAB = 'accountant_tab'
export const AUDIT_TAB = 'audit_tab'
