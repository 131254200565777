import { getFilterProduct, getSugestedProducts } from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { Form, Formik, FormikProps } from 'formik'
import { getValidationSchema } from 'lib/FieldValidations'
import { ICrudCompleteFields } from 'models/CrudInterface'
import { IGetSugestedProducts } from 'models/SugestedProducts'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import SugestedProductsFields from './SugestedProductsFields'

export interface ISugestedProductsFormProps extends WithTranslation {
	getFilterProduct: (payload: any) => void
	getSugestedProducts: (payload: IGetSugestedProducts) => void
	handleProductChange: (niprod: number) => void
	handleFilterChange: (values: any) => void
	handleOpenModal: () => void
	product: any
	fields: Array<ICrudCompleteFields>
	t: any
	idOperacion: number
	selectedProducts: any
}

interface ISugestedProductsState {
	isSearching: boolean
}

class SugestedProductsForm extends Component<
	ISugestedProductsFormProps,
	ISugestedProductsState
> {
	constructor(props: ISugestedProductsFormProps) {
		super(props)
		this.state = {
			isSearching: false
		}
	}

	handleSearchProducts = (value: string) => {
		this.props.getFilterProduct({ busqueda: value })
		this.handleIsSearching(true)
	}

	handleIsSearching = (isSearching: boolean) => {
		this.setState({ isSearching })
	}

	render() {
		const {
			fields,
			t,
			getFilterProduct,
			handleFilterChange,
			handleOpenModal,
			selectedProducts
		} = this.props
		const { isSearching } = this.state

		const validationSchema = fields.length
			? getValidationSchema(fields, t, [], [])
			: {}

		const mainFields = fields.filter(
			(field) => field.agrupador === 'formulario'
		)
		const filterFields = fields.filter((field) => field.agrupador === 'filtros')

		return (
			<>
				<Formik
					initialValues={{}}
					onSubmit={(values, actions) => {
						handleFilterChange(values)
					}}
					enableReinitialize={true}
					validationSchema={validationSchema}
				>
					{(props: FormikProps<any>) => (
						<Fragment>
							<Col sm={12}>
								<NotificationError
									errors={props.errors}
									touched={props.touched}
								/>
							</Col>
							<Form>
								<Row>
									<Col sm={6}>
										{!_.isEmpty(mainFields) &&
											_.map(mainFields, (field) => {
												return (
													<SugestedProductsFields
														field={field}
														formikProps={props}
														crudProps={this.props}
														searchProduct={this.handleSearchProducts}
														isSearching={isSearching}
														handleIsSearching={this.handleIsSearching}
													/>
												)
											})}
									</Col>
									<Col sm={6}>
										{!_.isEmpty(filterFields) &&
											_.map(filterFields, (field) => {
												return (
													<SugestedProductsFields
														field={field}
														formikProps={props}
														crudProps={this.props}
														searchProduct={getFilterProduct}
														isSearching={isSearching}
														handleIsSearching={this.handleIsSearching}
														handleOpenModal={handleOpenModal}
														selectedProducts={selectedProducts}
													/>
												)
											})}
									</Col>
								</Row>
								<Row className={'pt-3 pb-3'}>
									<Col sm={6} />
									<Col sm={6} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px' }}
											type={'submit'}
											disabled={
												!props.values.desc_prod_ref ||
												props.values.desc_prod_ref === ''
											}
										>
											{t('Collection.form.buttons.search')}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					)}
				</Formik>
			</>
		)
	}
}

const mapStateToProps = ({ tableCalls, searchProducts }: any) => {
	const { selectedProducts } = searchProducts
	const { product } = tableCalls
	return {
		selectedProducts,
		product
	}
}

const connectForm = connect(mapStateToProps, {
	getFilterProduct,
	getSugestedProducts
})(SugestedProductsForm)

export default withTranslation()(withRouter(connectForm))
