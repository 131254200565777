import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { Col, Toast } from 'react-bootstrap'

export default class GlobalNotifications extends Component {
	renderMessage = (message) => {
		const result = message.Mens_error ? message.Mens_error : ''
		const errors = []
		if (message.Errores) {
			message.Errores.forEach((error, index) => {
				errors.push(<li key={`${error.idCampo}_${index}`}>{error.mensaje}</li>)
			})
		}

		return errors.length ? errors : result
	}

	render() {
		const { message, showMessage, voucherType, typeMessage } = this.props

		const resultMessage =
			typeof message === 'object' ? this.renderMessage(message) : message

		const classDiv =
			typeMessage === 'error'
				? 'alert alert-danger danger'
				: 'alert alert-success success'
		const styles = voucherType
			? {
					position: 'fixed',
					top: 40,
					right: 0,
					minWidth: 300,
					zIndex: 2050
			  }
			: {
					position: 'fixed',
					top: 25,
					minWidth: 300
			  }

		return (
			<Col>
				{showMessage && (
					<Toast
						onClose={() => this.props.setShow(false)}
						show={showMessage}
						delay={10000}
						style={styles}
						autohide
					>
						<Toast.Body className={`${classDiv} mb-0`}>
							<Grid
								container
								direction="row"
								spacing={2}
								justifyContent="center"
								alignContent="center"
								alignItems="center"
							>
								<Grid item xs={1} lg={1}>
									<FontAwesomeIcon
										icon={faTimesCircle}
										style={{ width: '20px', height: '20px' }}
										className={'highlight pr-1'}
										onClick={() => this.props.setShow(false)}
									/>
								</Grid>
								<Grid item xs={11} lg={11}>
									<Typography variant="subtitle2">{resultMessage}</Typography>
								</Grid>
							</Grid>
						</Toast.Body>
					</Toast>
				)}
			</Col>
		)
	}
}
