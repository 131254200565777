import {
	CLEAR_PURCHASES_HEADER_FILTERS,
	CONFIRM_HEADER_SELECTION,
	CONFIRM_HEADER_SELECTION_ERROR,
	CONFIRM_HEADER_SELECTION_SUCCESS,
	GET_HEADER_SELECTION,
	GET_HEADER_SELECTION_SUCCESS,
	SET_PURCHASES_HEADER_FILTERS,
	VALIDATE_PURCHASE_BARCODE,
	VALIDATE_PURCHASE_BARCODE_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	shoppingHeaders: any
	confirmHeaders: any
	messageError: string
	barCodeResponse: any
	headerFilters: any
}

const initialState = {
	shoppingHeaders: null,
	confirmHeaders: null,
	messageError: '',
	barCodeResponse: null,
	headerFilters: {
		idOperacion: '',
		page_number: 1,
		page_size: 10,
		fec_desde: '',
		fec_hasta: '',
		suc_emp: '',
		vendedor: '',
		transporte: '',
		cond_vta: '',
		orden: '',
		sentido: ''
	}
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case GET_HEADER_SELECTION:
			return { ...state, shoppingHeaders: null }
		case GET_HEADER_SELECTION_SUCCESS:
			return { ...state, shoppingHeaders: action.payload }
		case CONFIRM_HEADER_SELECTION:
			return { ...state, confirmHeaders: null, messageError: '' }
		case CONFIRM_HEADER_SELECTION_SUCCESS:
			return { ...state, confirmHeaders: action.payload }
		case CONFIRM_HEADER_SELECTION_ERROR:
			return { ...state, messageError: action.payload }
		case VALIDATE_PURCHASE_BARCODE:
			return { ...state, barCodeResponse: null, messageError: '' }
		case VALIDATE_PURCHASE_BARCODE_SUCCESS:
			return { ...state, barCodeResponse: action.payload }
		case SET_PURCHASES_HEADER_FILTERS:
			return { ...state, headerFilters: action.payload }
		case CLEAR_PURCHASES_HEADER_FILTERS:
			return {
				...state,
				headerFilters: {
					idOperacion: '',
					page_number: 1,
					page_size: 10,
					fec_desde: '',
					fec_hasta: '',
					suc_emp: '',
					vendedor: '',
					transporte: '',
					cond_vta: '',
					orden: '',
					sentido: ''
				}
			}
		default:
			return state
	}
}

export default rootReducer
