import { selectCompany, setCompanyError } from 'actions'
import InputButton from 'components/form/inputButton'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { LANDING } from 'utils/RoutePath'
import InputDropdown from '../form/inputDropdown'

class ChangeCompany extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedCompany: props.userCompany ? props.userCompany : 1
		}
	}

	handleSelectChange = (e) => {
		this.setState({ selectedCompany: e.target.value })
	}

	handleSubmitChange = () => {
		const { selectedCompany } = this.state
		this.props.selectCompany({ company: selectedCompany })
		this.props.setCompanyError(false)
		this.props.history.push(`${LANDING}/${selectedCompany}`) // Se redirecciona.
		this.props.closeWindow()
	}

	render() {
		const { empresas, t } = this.props
		const options = empresas
			? empresas.map((company) => {
					return { id: company.id, label: company.razonSocial }
			  })
			: []

		const config = [
			{
				idCampo: 'mainCompany',
				name: 'mainCompany',
				editable: 1,
				visible: 1,
				requerido: 0
			}
		]

		return (
			<Row>
				<InputDropdown
					inputFormCol={{ sm: 12 }}
					fields={config}
					label={'Empresas'}
					inputId={'mainCompany'}
					name={'mainCompany'}
					placeholder={'placeHolder'}
					styles={{ width: '100%' }}
					colLabel={'col-sm-2'}
					colInput={'col-sm-10'}
					options={options}
					onChange={(e) => this.handleSelectChange(e)}
					value={this.state.selectedCompany}
					divStyle={{ paddingLeft: '23px' }}
				/>
				<Col className={'text-center'}>
					<InputButton
						valueButton={t('Collection.form.buttons.accept')}
						type={'primary'}
						onClick={this.handleSubmitChange}
						customStyle={{ minWidth: '145px' }}
					/>
				</Col>
				<Col className={'text-center'}>
					<InputButton
						valueButton={t('Collection.form.buttons.cancel')}
						type={'primary'}
						onClick={this.props.closeWindow}
						customStyle={{ minWidth: '145px' }}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = ({ auth }) => {
	const { companyChanged } = auth
	const { empresas, userCompany } = auth.authUser

	return { empresas, companyChanged, userCompany }
}

export default connect(mapStateToProps, { selectCompany, setCompanyError })(
	withTranslation()(withRouter(ChangeCompany))
)
