import CommonMultiSelect from 'components/common/CommonMultiSelect'
import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import stylesComponent from './InputMultiSelect.module.scss'
interface IMultiselectProps {
	label?: any
	placeholder?: any
	name?: any
	styles?: any
	inputId?: any
	id?: any
	colInput?: any
	colLabel?: any
	styleLabel?: any
	divStyle?: any
	options?: any
	disable?: any
	theme?: any
	inputFormCol?: any
	rowStyle?: any
	value?: any
	fwRef?: any
	disabledInput?: any
	onBlur?: any
	onChange?: any
	noInitialExecute?: boolean
	tooltip?: any
	fields?: any
	config?: any
}

class InputMultiselect extends PureComponent<IMultiselectProps> {
	// componentDidMount = () => {
	// 	const { options, onChange, noInitialExecute, value } = this.props
	// 	if (value && !noInitialExecute) {
	// 		onChange({ target: { value } }) // Se hace el llamado al cargar las opciones desde el inicio
	// 	} else if (options[0] && onChange && !noInitialExecute) {
	// 		onChange({ target: { value: options[0].id } }) // Se hace el llamado al cargar las opciones desde el inicio
	// 	}
	// }

	componentDidUpdate = (prevProps: IMultiselectProps) => {
		const { options, onChange, noInitialExecute } = this.props

		if (
			prevProps.options !== options &&
			prevProps.options.length !== options.length &&
			options.length > 0
		) {
			if (options[0] && onChange && !noInitialExecute) {
				onChange({ target: { value: options[0].id } })
			}
		}
	}

	renderOptions = () => {
		const { options, tooltip } = this.props
		const result: any = []
		options.forEach((option: any) => {
			const titleOption = tooltip ? option.label : null
			result.push(
				<option
					data-toggle="tooltip"
					title={titleOption}
					value={option.id}
					key={option.id}
				>
					{option.label}
				</option>
			)
		})

		return result
	}

	getConfigField = (id: string) => {
		const { fields } = this.props
		let result: any = {}
		fields.forEach((field: any) => {
			if (field.idCampo.trim() === id) {
				result = field
			}
		})

		return result
	}

	getCustomValue = (options: any) => {
		let result = ''
		options.forEach((opt: any) => {
			result = opt.label
		})
		return result
	}

	renderField = () => {
		const {
			label,
			placeholder,
			// name,
			// styles,
			inputId,
			// id,
			colInput,
			colLabel,
			styleLabel,
			divStyle,
			options,
			disable,
			// theme,
			inputFormCol,
			rowStyle,
			// value,
			// fwRef,
			// disabledInput,
			// onBlur,
			onChange
		} = this.props

		const classInput = label ? colInput : 'col-sm-12'
		const classLabel = label ? colLabel : ''
		const config = this.getConfigField(inputId)
		const customStyleLabel = config.requerido
			? { ...styleLabel, color: 'red' }
			: styleLabel

		// const customValue = value || this.getCustomValue(options)

		if (config.visible) {
			return (
				<Col {...inputFormCol}>
					<Row style={rowStyle}>
						<label className={`${classLabel} `} style={customStyleLabel}>
							{config.label ? config.label : label}
						</label>
						{!disable && (
							<Col className={classInput} style={{ ...divStyle }}>
								<CommonMultiSelect
									options={options}
									inputClass={stylesComponent.classButton}
									placeholder={config.placeholder ?? placeholder}
									onSelect={onChange}
									onRemove={onChange}
								/>
							</Col>
						)}
					</Row>
				</Col>
			)
		} else {
			return null
		}
	}

	render() {
		const { fields } = this.props
		if (fields) {
			return this.renderField()
		} else {
			return null
		}
	}
}

export default InputMultiselect
