import {
	faAngleDoubleDown,
	faAngleDoubleUp
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NotificationError from 'components/common/notificationsErrors'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import ProviderPickStockImport from 'components/providerPickStockImport/ProviderPickStockImport'
import { Form, Formik } from 'formik'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Button } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import * as Yup from 'yup'
import styles from './PickStock.module.scss'

const optionsForm = {
	purchase: [
		{
			id: 0,
			label: 'Fecha'
		},
		{
			id: 1,
			label: 'Comprobante'
		},
		{
			id: 2,
			label: 'Producto'
		}
	],
	date: [
		{
			id: 0,
			label: 'Fecha Emisión'
		},
		{
			id: 1,
			label: 'Fecha Entrega'
		}
	],
	order: [
		{
			id: 'asc',
			label: 'ASC'
		},
		{
			id: 'desc',
			label: 'DESC'
		}
	],
	visualize: [
		{
			id: 0,
			label: 'Afectados'
		},
		{
			id: 1,
			label: 'Sin afectar'
		}
	]
}

class PickStockFilters extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showFilters: false
		}
	}

	componentDidMount() {
		this.props.propShowFilters(this.handleShowFilter)
		this.handleShowFilter = this.handleShowFilter.bind(this)
	}

	/**
	 * to apply the filters and use search api
	 * @param {object} filters
	 */
	handleApplyFilters = (filters) => {
		const params = {
			ind_filtro_fecha: filters.fec_desde !== '' ? 1 : 0,
			fec_desde: filters.fec_desde
				? moment(filters.fec_desde).format('YYYY-MM-DD')
				: '',
			fec_hasta: filters.fec_hasta
				? moment(filters.fec_hasta).format('YYYY-MM-DD')
				: '',
			filtro_fecha: filters.filtro_fecha,
			orden: filters.orden,
			sentido: filters.sentido,
			filtro_afectados: filters.opc_visualizar
		}
		this.props.handleChangeFilter(params)
	}

	/**
	 * to hide and show filters
	 */
	handleShowFilter = () => {
		this.setState((prevState) => ({ showFilters: !prevState.showFilters }))
	}

	render() {
		const { t, theme, fieldsForm, idOperacion, ingrPickeo } = this.props
		const { showFilters } = this.state
		const classIcon = showFilters ? '' : 'col-sm-1 offset-9'
		const arrowIcon = showFilters ? faAngleDoubleUp : faAngleDoubleDown
		const ocOptions =
			ingrPickeo && ingrPickeo.oc.length
				? ingrPickeo.oc.map((opt) => ({ id: opt, label: opt }))
				: []

		return (
			<Col className={theme.containerBorder} sm={12}>
				<Formik
					initialValues={{
						opc_visualizar: optionsForm.visualize[0].id,
						sentido: optionsForm.order[0].id,
						filtro_fecha: optionsForm.date[0].id,
						orden: optionsForm.purchase[0].id,
						filtro_oc: ocOptions.length > 0 ? ocOptions[0].id : '',
						fec_desde: '',
						fec_hasta: ''
					}}
					onSubmit={(values, actions) => {
						this.handleApplyFilters(values)
					}}
					validationSchema={Yup.object().shape(
						{
							fec_desde: Yup.string().when(['fec_hasta'], {
								is: (fec_hasta) => fec_hasta,
								then: Yup.string().required(
									t('enter-field', { field: t('pickStock.date_from') })
								)
							}),
							fec_hasta: Yup.string().when(['fec_desde'], {
								is: (fec_desde) => fec_desde,
								then: Yup.string().required(
									t('enter-field', { field: t('pickStock.date_until') })
								)
							})
						},
						['fec_desde', 'fec_hasta']
					)}
					enableReinitialize={true}
				>
					{(props) => {
						const {
							handleSubmit,
							values,
							handleChange,
							handleBlur,
							setFieldValue,
							errors
						} = props

						return (
							<Fragment>
								<Col sm={12}>
									<NotificationError errors={errors} touched={props.touched} />
								</Col>
								<Form onSubmit={handleSubmit}>
									<Row>
										<Col sm={2} className={theme.divLabel}>
											{t('pickStock.filters')}
										</Col>
										{showFilters && (
											<Fragment>
												<Col sm={{ offset: 7 }}>
													<ProviderPickStockImport idOperacion={idOperacion} />
												</Col>
												<Col>
													<Button style={{ minWidth: '125px' }} type={'submit'}>
														{t('Stock.buttons.apply')}
													</Button>
												</Col>
											</Fragment>
										)}
										<Col className={`text-right ${classIcon}`}>
											<FontAwesomeIcon
												style={{ cursor: 'pointer' }}
												icon={arrowIcon}
												onClick={this.handleShowFilter}
											/>
										</Col>
									</Row>
									{showFilters && fieldsForm.length > 0 && (
										<Row className={'mt-2'}>
											<InputDropdown
												inputFormCol={{ sm: 3 }}
												fields={fieldsForm}
												label={'label'}
												inputId={'filtro_oc'}
												name={'filtro_oc'}
												styles={{ width: '100%' }}
												styleLabel={{ textAlign: 'left' }}
												colLabel={'col-sm-4'}
												colInput={'col-sm-8'}
												options={ocOptions}
												value={values.filtro_oc}
												tooltip
												onChange={handleChange}
											/>
											<InputDropdown
												inputFormCol={{ sm: 3 }}
												fields={fieldsForm}
												label={'fecha'}
												inputId={'filtro_fecha'}
												name={'filtro_fecha'}
												styles={{ width: '100%' }}
												styleLabel={{ textAlign: 'left' }}
												colLabel={'col-sm-4'}
												colInput={'col-sm-8'}
												options={optionsForm.date}
												value={values.filtro_fecha}
												tooltip
												onChange={handleChange}
											/>
											<InputText
												inputFormCol={{ sm: 2 }}
												fields={fieldsForm}
												label={'Desde'}
												inputId={'fec_desde'}
												name={'fec_desde'}
												placeholder={t('affectationAmounts.filter.from')}
												styles={{ width: '100%', border: '1px solid #ccc' }}
												colLabel={'col-sm-3'}
												colInput={'col-sm-9'}
												onBlur={handleBlur}
												value={values.fec_desde}
												autoComplete="off"
												onChange={(date) => {
													setFieldValue('fec_desde', date || '')
												}}
											/>
											<InputText
												inputFormCol={{ sm: 2 }}
												fields={fieldsForm}
												label={'hasta'}
												inputId={'fec_hasta'}
												name={'fec_hasta'}
												placeholder={t('affectationAmounts.filter.until')}
												styles={{ width: '100%', border: '1px solid #ccc' }}
												colLabel={'col-sm-3'}
												colInput={'col-sm-9'}
												disable={true}
												onBlur={handleBlur}
												value={values.fec_hasta}
												autoComplete="off"
												onChange={(date) => {
													setFieldValue('fec_hasta', date || '')
												}}
											/>
										</Row>
									)}
									{showFilters && fieldsForm.length > 0 && (
										<Row style={{ marginTop: '-6px', marginBottom: '-4px' }}>
											<InputDropdown
												inputFormCol={{ sm: 3 }}
												fields={fieldsForm}
												label={'orden'}
												inputId={'orden'}
												name={'orden'}
												styles={{ width: '100%' }}
												styleLabel={{ textAlign: 'left' }}
												colLabel={'col-sm-4'}
												colInput={'col-sm-8'}
												options={optionsForm.purchase}
												value={values.orden}
												tooltip
												noInitialExecute
												onChange={(opt) => {
													handleChange(opt)
													this.props.handleChangeFilter({
														orden: opt.target.value
													})
												}}
											/>
											<InputDropdown
												inputFormCol={{ sm: 2 }}
												fields={fieldsForm}
												label={'sentido'}
												inputId={'sentido'}
												name={'sentido'}
												styles={{ width: '100%' }}
												styleLabel={{ textAlign: 'left' }}
												colLabel={'col-sm-3'}
												colInput={'col-sm-9'}
												options={optionsForm.order}
												value={values.sentido}
												tooltip
												noInitialExecute
												onChange={(opt) => {
													handleChange(opt)
													this.props.handleChangeFilter({
														sentido: opt.target.value
													})
												}}
											/>
											<InputDropdown
												inputFormCol={{ sm: 2 }}
												fields={fieldsForm}
												label={'visualizar'}
												inputId={'opc_visualizar'}
												name={'opc_visualizar'}
												styles={{ width: '100%' }}
												styleLabel={{ textAlign: 'left' }}
												colLabel={'col-sm-4'}
												colInput={'col-sm-8'}
												options={optionsForm.visualize}
												value={values.opc_visualizar}
												tooltip
												noInitialExecute
												onChange={(opt) => {
													handleChange(opt)
													this.props.handleChangeFilter({
														filtro_afectados: opt.target.value
													})
												}}
											/>
										</Row>
									)}
								</Form>
							</Fragment>
						)
					}}
				</Formik>
			</Col>
		)
	}
}

const connectForm = connect()(withTranslation()(PickStockFilters))
export default themr('PickStockStyles', styles)(connectForm)
