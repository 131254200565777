import { courierFields } from 'constants/crudsConfig/crudCourierConstants/crudCourierFormFields'
import { renderInput } from 'lib/FormUtils'
import { ICrudGenericFields } from 'models/CrudInterface'
import {
	IGetTableGenericRes,
	IGetTableGenericResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'

type CrudCourierDataFieldProps = {
	field: any
	formikProps: any
	crudProps: any
	initialValue: any
	searchProviders: any
	isSearching: boolean
	handleIsSearching: any
}

type CrudCourierDataFieldState = {
	dependsOn: any
	isSearch: boolean
	fieldConfig: ICrudGenericFields
	defaultValue?: string
	idField?: any
	validCpos?: number
	value?: any
}

class CrudCourierDataField extends Component<
	CrudCourierDataFieldProps,
	CrudCourierDataFieldState
> {
	constructor(props: CrudCourierDataFieldProps) {
		super(props)
		this.state = {
			fieldConfig: {
				id: '',
				type: '',
				width: '',
				autoComplete: '',
				comboData: '',
				maxChars: 0,
				dependsOn: {
					id: '',
					values: [
						{
							idValue: '',
							disabled: false
						}
					]
				}
			},
			isSearch: false,
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field, crudProps, initialValue } = this.props
		const idField = field.idCampo.trim()
		const addData = _.find(courierFields, (field: ICrudGenericFields) => {
			return field.id === idField
		})
		addData && this.setState({ dependsOn: addData.dependsOn })
		addData && this.setState({ fieldConfig: addData })
		addData && this.setState({ idField: idField })
		addData && this.setState({ defaultValue: addData.default })

		idField === 'tr_cuit' && crudProps.getTableIsTaxesIdValid(initialValue)
	}

	// Update check for CPOS validation, and default state of dropdowns
	componentDidUpdate = (prevProps: CrudCourierDataFieldProps) => {
		const { crudProps: prevCrudProps } = prevProps
		const { formikProps, crudProps } = this.props

		const { idField, defaultValue, fieldConfig } = this.state

		if (
			fieldConfig.type !== 'combo' &&
			!formikProps.values[idField] &&
			formikProps.values[idField] !== 0 &&
			defaultValue
		) {
			formikProps.setFieldValue(idField, defaultValue)
		}

		if (
			idField === 'tr_cuit' &&
			prevCrudProps.isTaxesIdValid !== crudProps.isTaxesIdValid
		) {
			formikProps.setFieldValue(idField, formikProps.values[idField])
		}
	}

	handleBlur = async () => {
		const { formikProps, crudProps, handleIsSearching } = this.props
		const { idField } = this.state
		formikProps.setTouched({
			...formikProps.touched,
			[idField]: true
		})
		idField === 'tr_cuit' &&
			crudProps.getTableIsTaxesIdValid(formikProps.values[idField])
		idField === 'tr_prove' && handleIsSearching(false)
	}

	// Setup combo field if it's empty
	comboDataField = (data: IGetTableGenericResArray) => {
		const values: { cod_valor: string; desc_valor: string }[] = []
		data &&
			_.each(data.lista, (opt: IGetTableGenericRes) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	renderInputs = (
		field: any,
		formikProps: any,
		disableForm: boolean,
		fieldConfig: ICrudGenericFields
	) => {
		const {
			crudProps,
			isSearching,
			searchProviders,
			handleIsSearching,
			initialValue
		} = this.props
		const { listProviders } = crudProps
		const { values, setFieldValue } = formikProps
		const { dependsOn, idField } = this.state
		const addData = fieldConfig

		const providers = listProviders
			? listProviders.proveedores.map((opt: any) => {
					return { id: opt.idProveedor, label: opt.rsocial }
			  })
			: []

		addData &&
			(field = {
				...field,
				tipo: addData.type,
				colInput: addData.width,
				autoComplete: addData.autoComplete,
				disabled: addData && addData.disabled,
				onBlur: this.handleBlur
			})

		if (field.tipo === 'combo') {
			addData &&
				(field.valores = crudProps[addData.comboData as keyof typeof crudProps]
					? this.comboDataField(
							crudProps[addData.comboData as keyof typeof crudProps]
					  )
					: [])
			!formikProps.values[idField] &&
				(field.onChange = (event: any) =>
					setFieldValue(field.idCampo, event.target.value))
		} else if (field.tipo === 'check') {
			field.customProps = {
				checked: Number(values[field.idCampo])
			}
		}
		if (field.idCampo === 'tr_prove') {
			field.customProps = {
				minLength: 3,
				handleSearch: searchProviders,
				handleLoading: isSearching,
				auoptions: providers,
				handleSelect: (provider: { id: string; label: string }) => {
					formikProps.setFieldValue('niprov', provider.id)
					formikProps.setFieldValue('tr_prove', provider.label)
					handleIsSearching(false)
				},
				defaultValue: initialValue,
				disable: disableForm
			}
			field.requerido = true
		}

		dependsOn.id !== '' &&
			_.each(dependsOn.values, (dependency) => {
				values[dependsOn.id] === dependency.idValue &&
					(field.disabled = dependency.disabled)
			})
		if (field.idCampo === 'tr_cod' && crudProps.method === 'add')
			field.disabled = false

		if (field.editable && field.visible) {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { field, formikProps, crudProps } = this.props
		const { fieldConfig } = this.state
		return (
			<Fragment>
				{this.renderInputs(
					field,
					formikProps,
					crudProps.disableForm,
					fieldConfig
				)}
			</Fragment>
		)
	}
}

export default CrudCourierDataField
