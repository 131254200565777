import {
	crudGetCustomerObservationSuccess,
	crudUpdateCustomerObservationSuccess
} from 'actions'
import {
	crudGetCustomerObservation,
	crudUpdateCustomerObservation
} from 'api/CustomerObservationApi'
import {
	CRUD_GET_CUSTOMER_OBSERVATION,
	CRUD_UPDATE_CUSTOMER_OBSERVATION
} from 'constants/ActionsTypes'
import {
	ICrudGetCustomerObservationSaga,
	ICrudUpdateCustomerObservationSaga
} from 'models/CrudCustomerInterface'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* crudGetCustomerObservationRequest({
	payload
}: ICrudGetCustomerObservationSaga): any {
	try {
		const values: any = yield call(crudGetCustomerObservation, payload)
		yield put(crudGetCustomerObservationSuccess(values))
	} catch (error) {}
}

function* crudUpdateCustomerObservationRequest({
	payload
}: ICrudUpdateCustomerObservationSaga): any {
	try {
		const values: any = yield call(crudUpdateCustomerObservation, payload)
		yield put(crudUpdateCustomerObservationSuccess(values))
	} catch (error) {}
}

export function* crudGetCustomerObservationSaga() {
	yield takeEvery(
		CRUD_GET_CUSTOMER_OBSERVATION,
		crudGetCustomerObservationRequest
	)
}

export function* crudUpdateCustomerObservationSaga() {
	yield takeEvery(
		CRUD_UPDATE_CUSTOMER_OBSERVATION,
		crudUpdateCustomerObservationRequest
	)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetCustomerObservationSaga),
		fork(crudUpdateCustomerObservationSaga)
	])
}
