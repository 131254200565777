import { confirmValidationSuccess, getValidationsSuccess } from 'actions'
import {
	confirmValidationApi,
	getValidationsApi
} from 'api/HeaderDataValidation'
import { HeaderDataValidationActions } from 'constants/ActionsTypesTs'
import {
	IConfirmValidationAction,
	IConfirmValidationSuccessResponse,
	IGetValidationsAction,
	IGetValidationsSuccessResponse
} from 'models/HeaderDataValidation'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* getValidationsRequest({ payload }: IGetValidationsAction): any {
	try {
		const validations: IGetValidationsSuccessResponse = yield call(
			getValidationsApi,
			payload
		)
		yield put(getValidationsSuccess(validations))
	} catch (error: any) {
		// const { Resultado } = error.response.data
		// const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
	}
}

function* confirmValidationRequest({ payload }: IConfirmValidationAction): any {
	try {
		const { params, callBackReturn } = payload
		const validConfirm: IConfirmValidationSuccessResponse = yield call(
			confirmValidationApi,
			params
		)
		yield put(confirmValidationSuccess(validConfirm))

		if (callBackReturn) {
			callBackReturn()
		}
	} catch (error: any) {
		// const { Resultado } = error.response.data
		// const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
	}
}

export function* getValidationsSaga() {
	yield takeEvery(
		HeaderDataValidationActions.GET_VALIDATIONS,
		getValidationsRequest
	)
}

export function* confirmValidationSaga() {
	yield takeEvery(
		HeaderDataValidationActions.CONFIRM_VALIDATIONS,
		confirmValidationRequest
	)
}

export default function* rootSaga() {
	yield all([fork(getValidationsSaga), fork(confirmValidationSaga)])
}
