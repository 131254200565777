import InputDropdown from 'components/form/inputDropdown'
import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import styles from './ProviderPickStockTotals.module.scss'

const config = {
	idCampo: 'import_cant',
	descrip: '',
	label: false,
	mascara: '',
	editable: 1,
	visible: 1,
	requerido: 0,
	valid: null
}

class ProviderPickStockTotals extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {}
		this.selectOptions = [
			{
				id: 'T',
				label: 'Todos'
			},
			{
				id: 'C',
				label: 'Correctos Solamente'
			},
			{
				id: 'E',
				label: 'Errores Solamente'
			}
		]
	}

	handleChange = (envt) => {
		this.props.handleChangeFilter({ mostrar: envt.target.value })
	}

	render() {
		const { theme, t, totals } = this.props
		const impOk = totals ? totals.cant_ok : 0
		const impError = totals ? totals.cant_error : 0
		const cantUnits = totals && totals.cant_unid ? totals.cant_unid : 0
		const cantProd = totals && totals.cant_prod ? totals.cant_prod : 0
		return (
			<Row className={`${theme.divContent}`}>
				<Col lg={4} md={12}>
					<Row>
						<Col className={theme.labelTitle} sm={7}>
							{t('Collection.import.correct_record')}
						</Col>
						<Col sm={2}>{impOk}</Col>
					</Row>
				</Col>
				<Col lg={4} md={12}>
					<Row>
						<Col className={theme.labelTitle} sm={7}>{`${t(
							'pickStock.different_products'
						)}:`}</Col>
						<Col sm={4}>{cantProd}</Col>
					</Row>
				</Col>
				<Col lg={4} md={12}>
					<Row>
						<Col className={theme.labelTitle} sm={2}>
							{t('voucherInvolvement.form.sample')}
						</Col>
						<Col sm={10}>
							<InputDropdown
								inputFormCol={{ sm: 11 }}
								fields={[config]}
								label={false}
								inputId={'import_cant'}
								name={'import_cant'}
								placeholder={t('client.form.insert_client_branch')}
								styles={{ width: '100%' }}
								colLabel={'col-sm-2'}
								colInput={'col-sm-10'}
								options={this.selectOptions}
								noInitialExecute
								onChange={(e) => this.handleChange(e)}
							/>
						</Col>
					</Row>
				</Col>
				<Col lg={4} md={12}>
					<Row>
						<Col className={theme.labelTitle} sm={7}>
							{t('Collection.import.error_record')}
						</Col>
						<Col sm={2}>{impError}</Col>
					</Row>
				</Col>
				<Col lg={4} md={12}>
					<Row>
						<Col className={theme.labelTitle} sm={7}>
							{t('pickStock.units')}
						</Col>
						<Col sm={2}>{cantUnits}</Col>
					</Row>
				</Col>
			</Row>
		)
	}
}

export default themr(
	'ProviderPickStockTotalsStyles',
	styles
)(withTranslation()(ProviderPickStockTotals))
