import {
	accountConfirmSuccess,
	accountValidateSuccess,
	getAccountDetailSuccess,
	getVoucherAccountingSuccess,
	searchAccountSuccess
} from 'actions'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
	accountConfirm,
	accountValidate,
	getAccountDetail,
	getVoucherAccounting,
	searchAccount
} from '../api/AccountingSeat'
import {
	ACCOUNT_CONFIRM,
	ACCOUNT_VALIDATE,
	GET_ACCOUNT_DETAIL,
	GET_VOUCHER_ACCOUNTING,
	SEARCH_ACCOUNT
} from '../constants/ActionsTypes'

function* getVoucherAccountingRequest({ payload }) {
	try {
		const accountingSeats = yield call(getVoucherAccounting, payload)
		yield put(getVoucherAccountingSuccess(accountingSeats))
	} catch (error) {}
}

function* searchAccountRequest({ payload }) {
	try {
		const search = yield call(searchAccount, payload)
		yield put(searchAccountSuccess(search))
	} catch (error) {}
}

function* getAccountDetailRequest({ payload }) {
	try {
		const account = yield call(getAccountDetail, payload)
		yield put(getAccountDetailSuccess(account))
	} catch (error) {}
}

function* accountValidateRequest({ payload }) {
	try {
		const validate = yield call(accountValidate, payload)
		yield put(accountValidateSuccess(validate))
	} catch (error) {}
}

function* accountConfirmRequest({ payload }) {
	const { idOperacion, callBackReturn } = payload

	try {
		const comfirm = yield call(accountConfirm, { idOperacion })
		callBackReturn()
		yield put(accountConfirmSuccess(comfirm))
	} catch (error) {}
}

export function* getVoucherAccountingSaga() {
	yield takeLatest(GET_VOUCHER_ACCOUNTING, getVoucherAccountingRequest)
}

export function* searchAccountSaga() {
	yield takeLatest(SEARCH_ACCOUNT, searchAccountRequest)
}

export function* getAccountDetailSaga() {
	yield takeLatest(GET_ACCOUNT_DETAIL, getAccountDetailRequest)
}

export function* accountValidateSaga() {
	yield takeLatest(ACCOUNT_VALIDATE, accountValidateRequest)
}

export function* accountConfirmSaga() {
	yield takeLatest(ACCOUNT_CONFIRM, accountConfirmRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getVoucherAccountingSaga),
		fork(searchAccountSaga),
		fork(getAccountDetailSaga),
		fork(accountValidateSaga),
		fork(accountConfirmSaga)
	])
}
