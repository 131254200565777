import React, { Component } from 'react'
import styles from './CommonContainer.module.scss'
interface CommonContainerProps {
	children: any
}
export default class CommonContainer extends Component<CommonContainerProps> {
	render() {
		const { children } = this.props
		return <div className={`${styles.commonContainer}`}>{children}</div>
	}
}
