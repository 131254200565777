import GlobalContainer from 'components/layout/globalContainer'
import VoucherStateTable from 'components/voucherState/voucherStateTable'
import { P_AFEC_STADO_VTA } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import withMenu from '../../components/common/withMenu'

class VoucherState extends Component {
	handleCallBackButton = () => {
		this.loadItemsConfirmation()
	}

	render() {
		return (
			<Row>
				<GlobalContainer
					codeProcess={P_AFEC_STADO_VTA}
					nextPage
					callBackButton={this.handleCallBackButton}
				>
					<VoucherStateTable
						formConfirmation={(click) => (this.loadItemsConfirmation = click)}
					/>
				</GlobalContainer>
			</Row>
		)
	}
}

export default withTranslation()(withRouter(withMenu(VoucherState)))
