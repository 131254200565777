import { getTableLedgerAccounts } from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { FormRefsContext } from 'context/FormRefsContext'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues } from 'lib/FieldValidations'
import { ICrudFieldStructure } from 'models/CrudInterface'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import DirectLAAssignationsFormField from './DirectLAAssignationsFormField'

type LAAssignationsProps = {
	fields: Array<ICrudFieldStructure>
	getTableLedgerAccounts: any
	handleSubmit: any
	itemInfo?: any
	disableForm?: boolean
	handleCloseModal: any
	ledgerAccounts: any
	renderFormInputs: any
	t: any
}

type LAAssignationsState = {
	listState: boolean
	isSearching: boolean
}

class LAAssignationsForm extends Component<
	LAAssignationsProps,
	LAAssignationsState
> {
	constructor(props: LAAssignationsProps) {
		super(props)
		this.state = {
			listState: false,
			isSearching: false
		}
	}

	/**
	 * Search the accounts related to the filters
	 * @param value
	 */

	handleSearchLedgerAccounts = (value: number) => {
		this.props.getTableLedgerAccounts(value)
		this.handleIsSearching(true)
	}

	/**
	 * Autocomplete status handler
	 * @param isSearching
	 */

	handleIsSearching = (isSearching: boolean) => {
		this.setState({ isSearching })
	}

	render() {
		const { fields, handleSubmit, itemInfo, disableForm, t } = this.props
		const { isSearching } = this.state

		for (const key in itemInfo) {
			itemInfo[key] =
				typeof itemInfo[key] === 'string' ? itemInfo[key].trim() : itemInfo[key]
		}

		const subCategories: Array<string> = []

		_.each(
			fields,
			(
				field: ICrudFieldStructure,
				index: number,
				array: Array<ICrudFieldStructure>
			) => {
				if (array[index - 1]) {
					if (field.agrupador !== array[index - 1].agrupador) {
						subCategories.push(field.agrupador)
					}
				} else {
					subCategories.push(field.agrupador)
				}
			}
		)

		const allFields = _.map(subCategories, (category: string) => {
			const id = category
			const filterFields = fields.filter((field: ICrudFieldStructure) => {
				return field.agrupador === category
			})
			return { name: id, fields: filterFields }
		})

		const firstValue =
			itemInfo && itemInfo.length > 1
				? {
						cod_cta: '*VARIOS*',
						codigo: '*VARIOS*',
						desc_cta: '*VARIOS*',
						descrip: '*VARIOS*'
				  }
				: itemInfo[0]

		const initialValues =
			firstValue || (fields.length ? getInitValues(fields) : {})
		const columnsCancel = disableForm ? 12 : 6

		return (
			<FormRefsContext.Consumer>
				{({ formRef, cancelButtonRef }) => (
					<>
						<Formik
							ref={formRef}
							initialValues={initialValues}
							onSubmit={(values, actions) => {
								handleSubmit(values)
							}}
							enableReinitialize={true}
						>
							{(props: FormikProps<any>) => (
								<Fragment>
									<Col sm={12}>
										<NotificationError
											errors={props.errors}
											touched={props.touched}
										/>
									</Col>
									<Form>
										{_.map(
											allFields,
											(category: {
												name: string
												fields: Array<ICrudFieldStructure>
											}) => {
												return (
													<Fragment key={category.name}>
														<Alert variant={'dark'}>
															<b>
																{t(
																	'CRUDS.general_use.' +
																		category.name.replace('.', '')
																)}
															</b>
														</Alert>
														{_.map(
															category.fields,
															(field: ICrudFieldStructure) => {
																return (
																	<DirectLAAssignationsFormField
																		key={field.idCampo}
																		formikProps={props}
																		field={field}
																		crudProps={this.props}
																		isSearching={isSearching}
																		searchLedgerAccounts={
																			this.handleSearchLedgerAccounts
																		}
																		handleIsSearching={this.handleIsSearching}
																		initialValue={
																			initialValues[
																				field.idCampo as keyof typeof initialValues
																			]
																		}
																	/>
																)
															}
														)}
													</Fragment>
												)
											}
										)}
										<Row className={'pt-3'}>
											{!disableForm && (
												<Col sm={6} className={'text-center mt-2'}>
													<Button style={{ minWidth: '145px' }} type={'submit'}>
														{t('Collection.form.buttons.accept')}
													</Button>
												</Col>
											)}
											<Col sm={columnsCancel} className={'text-center mt-2'}>
												<Button
													style={{ minWidth: '145px' }}
													type={'button'}
													onClick={this.props.handleCloseModal}
													ref={cancelButtonRef}
												>
													{t('Collection.form.buttons.cancel')}
												</Button>
											</Col>
										</Row>
									</Form>
								</Fragment>
							)}
						</Formik>
					</>
				)}
			</FormRefsContext.Consumer>
		)
	}
}

const mapStateToProps = ({ tableCalls }: any) => {
	const { ledgerAccounts } = tableCalls
	return { ledgerAccounts }
}

const connectForm = connect(mapStateToProps, {
	getTableLedgerAccounts
})(LAAssignationsForm)

export default withTranslation()(withRouter(connectForm))
