import {
	getCufsPickStock,
	inventoryPickStockSearchBarCode,
	searchAutoProducts
} from 'actions'
import BarcodeInputText from 'components/form/barcodeInputText'
import InputAutocomplete from 'components/form/inputAutocomplete'
import InputCheckBox from 'components/form/InputCheckBox'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { handleSetValueInput } from 'lib/FormUtils'
import { setInputFocus } from 'lib/Utils'
import {
	IGetInventoryPickStockResponse,
	InventorySearchBarcodeParams
} from 'models/InventoryPickStock'
import React, { Component, forwardRef, Ref } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import InventoryPickStockResume from '../InventoryPickStockResume'
import {
	InventoryPickStockBarCodeProps,
	InventoryPickStockBarCodeState,
	IProductsAuOptions
} from './inventoryPickStockBarCodeType'

class InventoryPickStockBarCode extends Component<
	InventoryPickStockBarCodeProps,
	InventoryPickStockBarCodeState
> {
	cantInputRef: any
	firstInputRef: any
	searchInputRef: any
	constructor(props: InventoryPickStockBarCodeProps) {
		super(props)
		this.state = {
			prodOptions: [],
			isLoading: false,
			isCantDisabled: true,
			firstFocus: true,
			searchBarCode: ''
		}
		this.cantInputRef = React.createRef()
		this.firstInputRef = React.createRef()
		this.searchInputRef = React.createRef()
	}

	componentDidMount(): void {
		this.setFocusFirstInput()
	}

	componentDidUpdate(
		prevProps: Readonly<InventoryPickStockBarCodeProps>
	): void {
		const { searchAutoOptions, isVisibleBarCode, inventoryBarCodeItems } =
			this.props

		this.setFocusFirstInput()

		this.handleSetProductOptions(prevProps.searchAutoOptions, searchAutoOptions)

		if (prevProps.isVisibleBarCode !== isVisibleBarCode) {
			this.handleSetSearchFocus()
		}

		this.handleValidateBarCode(
			prevProps.inventoryBarCodeItems,
			inventoryBarCodeItems
		)
	}

	handleValidateBarCode = (
		prevBarcodeResponse: IGetInventoryPickStockResponse,
		barcodeResponse: IGetInventoryPickStockResponse
	) => {
		if (
			!_.isEqual(prevBarcodeResponse, barcodeResponse) &&
			!_.isEmpty(barcodeResponse)
		) {
			this.handleResetForm()
		}
	}

	handleSetProductOptions = (
		prevOptions: IProductsAuOptions,
		options: IProductsAuOptions
	) => {
		if (!_.isEqual(prevOptions, options) && !_.isEmpty(options)) {
			const prodOptions = options.productos.map((product: any) => ({
				id: product.niprod,
				label: product.desc_prod
			}))
			this.setState({ prodOptions })
		}
	}

	/**
	 * to set focus in the search bar after load the form
	 */
	setFocusFirstInput = () => {
		const { firstFocus } = this.state
		if (this.firstInputRef.current && firstFocus) {
			setInputFocus(this.firstInputRef)
			this.setState({ firstFocus: false })
		}
	}

	/**
	 * search codeBar
	 */
	handleSearchBarcode = () => {
		const { idOperacion, formikProps } = this.props
		const { values } = formikProps

		const params: InventorySearchBarcodeParams = {
			idOperacion,
			cod_barra: values.pick_cod_barra || '',
			ind_ultimo_cuf: values.ult_cuf ? 1 : 0,
			cant: values.cant_ns,
			page: 1,
			page_size: 10
		}

		if (!values.ult_cuf) {
			params.cuf = values.cuf
			params.depo = values.deposito
		}

		this.props.inventoryPickStockSearchBarCode(params)
		this.setState({ isLoading: true })
	}

	/**
	 * to format formik form to initial values
	 */
	handleResetForm = () => {
		const { formikProps } = this.props

		formikProps.setFieldValue('cant_ns', '1')
		formikProps.setFieldValue('pick_cod_barra', '')

		this.setState({ searchBarCode: '' })

		if (this.searchInputRef.current) {
			this.searchInputRef.current.clear()
		}
	}

	/**
	 * to call search api
	 * @param {string} search
	 */
	handleSearch = (search: string) => {
		const { idOperacion } = this.props
		this.props.searchAutoProducts({
			idOperacion,
			desc_prod: search
		})

		this.setState({ isLoading: true, searchBarCode: search })
	}

	/**
	 * to select a product from the search
	 * @param {string} productSelected
	 */
	handleSelect = (productSelected: any) => {
		const { formikProps, idOperacion } = this.props

		if (!_.isEmpty(productSelected)) {
			const params: InventorySearchBarcodeParams = {
				idOperacion,
				niprod: productSelected[0].id,
				ind_ultimo_cuf: formikProps.values.ult_cuf ? 1 : 0,
				cant: formikProps.values.cant_ns
			}

			if (!formikProps.values.ult_cuf) {
				params.depo = formikProps.values.deposito
				params.cuf = formikProps.values.cuf
			}
			this.props.inventoryPickStockSearchBarCode(params)
			this.setState({
				isLoading: false,
				searchBarCode: productSelected[0].label
			})
		}
	}

	toggleLastCuf = () => {
		const { formikProps } = this.props
		formikProps.setFieldValue('ult_cuf', !formikProps.values.ult_cuf)
	}

	/**
	 * to  enable and disable the quantity field
	 */
	toggleDisableCant = () => {
		const { isCantDisabled } = this.state
		const { formikProps } = this.props

		formikProps.setFieldValue('cant_ns', isCantDisabled ? '' : 1)
		this.setState({ isCantDisabled: !isCantDisabled })
		if (isCantDisabled) {
			setInputFocus(this.cantInputRef)
		} else {
			this.handleSetSearchFocus()
		}
	}

	/**
	 * to set focus in the search input tha is enabled
	 */
	handleSetSearchFocus = () => {
		const { isVisibleBarCode } = this.props
		if (!isVisibleBarCode) {
			setInputFocus(this.searchInputRef)
		} else {
			setInputFocus(this.firstInputRef)
		}
	}

	handleChangeDept = (data: any) => {
		const { formikProps, idOperacion } = this.props
		this.props.getCufsPickStock({ cod_dep: data.target.value, idOperacion })
		formikProps.setFieldValue('deposito', data.target.value)
	}

	render() {
		const {
			fields,
			formikProps,
			inventoryBarCodeItems,
			t,
			isVisibleBarCode,
			pickStockListDeposits,
			pickStockListCufs
		} = this.props
		const { values, handleBlur } = formikProps
		const { prodOptions, isLoading, isCantDisabled, searchBarCode } = this.state
		const depOptions = !_.isEmpty(pickStockListDeposits)
			? pickStockListDeposits.lista_dep.map((dept) => ({
					id: dept.codigo,
					label: dept.descrip
			  }))
			: []

		const cufOptions = !_.isEmpty(pickStockListCufs)
			? pickStockListCufs.lista.map((cuf) => ({
					id: cuf.codigo,
					label: cuf.descrip
			  }))
			: []

		const lastCufProps = {
			inputFormCol: { sm: 3 },
			fields,
			label: 'Test',
			inputId: 'ult_cuf',
			name: 'ult_cuf',
			placeholder: '',
			colLabel: 'col-sm-7',
			colInput: 'col-sm-5',
			onBlur: handleBlur,
			value: values.ult_cuf,
			checked: values.ult_cuf,
			onChange: (data: any) => handleSetValueInput(data, 'ult_cuf', formikProps)
		}

		const barCodeProps = {
			fwRef: this.firstInputRef,
			inputFormCol: { sm: 7 },
			fields,
			label: 'label',
			inputId: 'pick_cod_barra',
			name: 'pick_cod_barra',
			placeholder: 'Código de barra',
			styles: { width: '100%' },
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			handleEnterKey: () => this.handleSearchBarcode(),
			value: values.pick_cod_barra,
			onChange: (data: string) =>
				formikProps.setFieldValue('pick_cod_barra', data),
			activeListeners: true
		}

		const depositProps = {
			inputFormCol: { sm: 4 },
			fields,
			label: 'l',
			inputId: 'deposito',
			name: 'deposito',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.deposito,
			divStyle: { paddingRight: '0px' },
			disabledInput: values.ult_cuf,
			options: depOptions,
			noInitialExecute: false,
			onChange: (data: any) => this.handleChangeDept(data)
		}

		const locationProps = {
			inputFormCol: { sm: 4 },
			fields,
			label: 'l',
			inputId: 'cuf',
			name: 'cuf',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.cuf,
			divStyle: { paddingRight: '0px' },
			disabledInput: values.ult_cuf,
			options: cufOptions,
			noInitialExecute: false,
			onChange: (data: any) => handleSetValueInput(data, 'cuf', formikProps)
		}

		const cantProps = {
			fwRef: this.cantInputRef,
			inputFormCol: { sm: 4 },
			disabledInput: isCantDisabled,
			fields,
			label: 'Cantidad',
			inputId: 'cant_ns',
			name: 'cant_ns',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-6',
			placeholder: 'Cantidad',
			onBlur: handleBlur,
			autoComplete: 'off',
			value: values.cant_ns,
			divStyle: { paddingRight: '0px' },
			handleEnterKey: () => this.handleSetSearchFocus(),
			onChange: (data: any) => handleSetValueInput(data, 'cant_ns', formikProps)
		}

		const searchProdProps = {
			refs: this.searchInputRef,
			inputFormCol: { sm: 7 },
			label: 'Buscar Productos (F2)',
			inputId: 'search_product',
			name: 'search_product',
			placeholder: t('searchBox.form.insert_product'),
			fields,
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			handleSearch: this.handleSearch,
			auoptions: prodOptions,
			handleLoading: isLoading,
			handleSelect: this.handleSelect,
			onChange: () => {},
			defaultValue: searchBarCode,
			labelKey: 'label',
			extraCol: true
		}
		return (
			<Row>
				<Row className={`col-12`}>
					<InputCheckBox {...lastCufProps} />
					<InputDropdown {...depositProps} />
					<InputDropdown {...locationProps} />
				</Row>
				<Row className={`col-12`}>
					{isVisibleBarCode ? (
						<BarcodeInputText {...barCodeProps} />
					) : (
						<InputAutocomplete {...searchProdProps} />
					)}
					<InputText {...cantProps} />
				</Row>
				<Row className={`col-12`}>
					<InventoryPickStockResume
						fields={fields}
						data={inventoryBarCodeItems}
					/>
				</Row>
			</Row>
		)
	}
}

const mapStateToProps = ({ inventoryPickStock, product }: any) => {
	const {
		inventoryPickStockData,
		inventoryBarCodeItems,
		pickStockListDeposits,
		pickStockListCufs
	} = inventoryPickStock
	const { searchAutoOptions } = product
	return {
		inventoryPickStockData,
		inventoryBarCodeItems,
		searchAutoOptions,
		pickStockListDeposits,
		pickStockListCufs
	}
}

const mapDispatchToProps = {
	inventoryPickStockSearchBarCode,
	searchAutoProducts,
	getCufsPickStock
}

const InventoryPickStockBarCodeWithRef = forwardRef(
	function InventoryPickStockFormWrapper(
		props: InventoryPickStockBarCodeProps,
		ref: Ref<InventoryPickStockBarCode>
	) {
		return <InventoryPickStockBarCode {...props} ref={ref} />
	}
)

export default withTranslation('', { withRef: true })(
	connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
		InventoryPickStockBarCodeWithRef
	)
)
