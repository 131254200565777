import {
	getVoucherType,
	getVoucherTypeByUser,
	saveVouchersStyleConfigByUser,
	selectCompany,
	setCompanyError,
	voucherCancelSuccess
} from 'actions'
import InputCheckBox from 'components/form/InputCheckBox'
import { isCompanyAllow } from 'lib/AuthUtils'
import { getUrl } from 'lib/BreadCrumbsUtils'
import { ILandingVoucher, IUserVoucherType } from 'models/Landing'
import { Ihistory } from 'models/NavigationInterface'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { LANDING } from 'utils/RoutePath'
import DocumentsType from './documentsType'

interface ILandingProps extends WithTranslation {
	companyId: any
	authUser?: any
	voucherCancelSuccess: () => void
	getVoucherTypeByUser: () => void
	getVoucherType?: any
	voucherType?: any
	history: Ihistory
	t?: any
	userVoucherType: IUserVoucherType
	selectCompany: any
	setCompanyError: (payload: boolean) => void
	saveVouchersStyleConfigByUser: Function
	currentProcess: any
}

interface ILandingState {
	modules: any
}

interface IModule {
	label: string
	check: boolean
}

class LandingVoucherList extends Component<ILandingProps, ILandingState> {
	constructor(props: ILandingProps) {
		super(props)
		const { userVoucherType } = props
		this.state = {
			modules: !_.isEmpty(userVoucherType.comprobantes)
				? _.allKeys(_.countBy(userVoucherType.comprobantes, 'modulo')).map(
						(module: any) => {
							return { label: module, check: true }
						}
				  )
				: []
		}
	}

	componentDidMount = () => {
		this.props.voucherCancelSuccess()
		const { companyId, authUser } = this.props

		if (companyId && authUser.userCompany !== companyId) {
			if (
				isCompanyAllow(authUser, companyId) &&
				authUser.userCompany !== companyId
			) {
				this.props.selectCompany({ company: companyId })
			} else if (!isCompanyAllow(authUser, companyId)) {
				this.props.setCompanyError(true)
			}
		} else {
			this.props.history.push(`${LANDING}/${authUser.userCompany}`)
		}
		this.props.voucherCancelSuccess()
		this.props.getVoucherTypeByUser()
	}

	componentDidUpdate = (prevProps: ILandingProps) => {
		const { voucherType, userVoucherType, currentProcess } = this.props

		if (
			!_.isEqual(prevProps.userVoucherType, userVoucherType) &&
			!_.isEmpty(userVoucherType.comprobantes)
		) {
			const modules = _.allKeys(
				_.countBy(userVoucherType.comprobantes, 'modulo')
			).map((module: any) => {
				return { label: module, check: true }
			})

			this.setState({ modules })
		}

		if (currentProcess !== prevProps.currentProcess && currentProcess) {
			// se valida que el proceso envié una pagina diferente a la actual.
			// console.log(currentProcess.cod_proceso, voucherType, '??????????')
			const newUrlSubmit = getUrl(
				currentProcess.cod_proceso,
				voucherType.idOperacion
			)

			if (!newUrlSubmit.includes('undefined')) {
				this.props.history.push(newUrlSubmit) // redirect to next page
			} else {
				console.error('Comprobante no encontrado.')
			}
		}
	}

	/**
	 * to get options to select, return array with id and label
	 * @params options from voucherstate
	 */
	getOptionsSelect = (options: ILandingVoucher[]) => {
		const result = options.map((voucher) => {
			return {
				id: voucher.nioperacion,
				label: voucher.descrip_comprob
			}
		})

		return result
	}

	/**
	 * to render checkInputs from modules.
	 * @returns
	 */
	renderModules = () => {
		const { modules } = this.state
		return modules.map((module: IModule) => {
			const propsCheck = {
				inputFormCol: { sm: 2 },
				fields: [
					{
						idCampo: module.label,
						label: module.label,
						mascara: '',
						editable: 1,
						visible: 1,
						requerido: 0,
						valid: ''
					}
				],
				label: module.label,
				inputId: module.label,
				name: module.label,
				placeholder: '',
				colLabel: 'col-sm-6',
				colInput: 'col-sm-6',
				styleLabel: { fontSize: '15px' },
				checked: module.check,
				onChange: (data: any) => this.handleChangeCheck(data, module)
			}

			return <InputCheckBox key={module.label} {...propsCheck} />
		})
	}

	/**
	 * to manage the action of checkbox
	 * @param nextCheck
	 * @param module
	 */
	handleChangeCheck = (
		nextCheck: boolean,
		module: { label: string; check: boolean }
	) => {
		const { modules } = this.state
		modules.forEach((mod: IModule) => {
			if (mod.label === module.label) {
				mod.check = nextCheck
			}
		})

		this.setState({ modules })
	}

	/**
	 * render all voucher
	 * @returns
	 */
	renderVouchers = () => {
		const { modules } = this.state
		const { userVoucherType, companyId } = this.props
		const result: any = []

		if (!_.isEmpty(userVoucherType) && !_.isEmpty(modules))
			userVoucherType.comprobantes.forEach((voucher) => {
				const filter = _.findWhere(modules, { label: voucher.modulo })
				if (!_.isEmpty(filter) && filter.check) {
					result.push(voucher)
				}
			})

		if (!_.isEmpty(result)) {
			const typeProps = {
				documents: result,
				getVoucherTypeByUser: this.props.getVoucherTypeByUser,
				handleCrearVoucherType: this.handleCreateVoucherType,
				idCompany: companyId,
				saveVouchersStyleConfigByUser: this.props.saveVouchersStyleConfigByUser
			}
			return <DocumentsType {...typeProps} />
		}

		return <div />
	}

	handleCreateVoucherType = (params: any) => {
		this.props.getVoucherType({ ...params })
	}

	render() {
		const { t, userVoucherType } = this.props
		return (
			<Fragment>
				<Row>
					<Col className="p-2 font-weight-bold"> {t('landing.vouchers')} </Col>
				</Row>
				<Row>{this.renderModules()}</Row>
				{!_.isEmpty(userVoucherType) && <Row>{this.renderVouchers()}</Row>}
			</Fragment>
		)
	}
}

const mapStateToProps = ({ vouchertype, auth, voucher }: any) => {
	const {
		userVoucherType,
		userVoucherTypeStyle,
		userVoucherTypeStyleConfirmation,
		voucherType
	} = vouchertype

	const { authUser } = auth

	const { currentProcess } = voucher

	return {
		userVoucherType,
		authUser,
		userVoucherTypeStyle,
		userVoucherTypeStyleConfirmation,
		voucherType,
		currentProcess
	}
}

const connectForm = connect(mapStateToProps, {
	getVoucherTypeByUser,
	voucherCancelSuccess,
	saveVouchersStyleConfigByUser,
	selectCompany,
	setCompanyError,
	getVoucherType
})(withTranslation()(LandingVoucherList))

export default withRouter(connectForm)
