import { getConfigFieldsCrud } from 'actions'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import CostCenterAssignCenterTable from './CostCenterAssignCenterDynamicTable'

type ValuesProps = {
	getConfigFieldsCrud: any
	handleSubmit: any
	setFormChanged?: any
	fieldsConfig: any
	nicc?: number
	values?: any
	disableForm: boolean
	showWarning: boolean
	handleWarning: any
}

type ValuesState = {
	activeTab: string
}

class LedgerAccountAssignCenterTab extends Component<ValuesProps, ValuesState> {
	constructor(props: ValuesProps) {
		super(props)
		this.state = {
			activeTab: 'assignCenter'
		}
	}

	componentDidMount = () => {
		this.props.getConfigFieldsCrud({ cod_abm: 'abm_cont_cc' })
	}

	render() {
		const {
			fieldsConfig,
			disableForm,
			values,
			nicc,
			handleSubmit,
			setFormChanged,
			showWarning,
			handleWarning
		} = this.props

		const propsTable = {
			fieldsConfig: fieldsConfig,
			disableForm: disableForm,
			values: values,
			nicc: nicc,
			handleSubmit: handleSubmit,
			setFormChanged: setFormChanged,
			showWarning: showWarning,
			handleWarning: handleWarning
		}
		return (
			<Fragment>
				{fieldsConfig && <CostCenterAssignCenterTable {...propsTable} />}
			</Fragment>
		)
	}
}

const mapStateToProps = ({ configCrud }: any) => {
	const { fieldsConfig } = configCrud
	return { fieldsConfig }
}

const connectForm = connect(mapStateToProps, {
	getConfigFieldsCrud
})(LedgerAccountAssignCenterTab)

export default connectForm
