import {
	ICrudGetLAAssignations,
	ICrudGetLAAssignationsResArray,
	ICrudUpdateLAAssignationsArray,
	ICrudUpdateLAAssignationsResArray
} from 'models/CrudDirectLAAssignationInterface'
import {
	CRUD_CLEAR_DIRECT_LA_ASSIGNATIONS_STORE,
	CRUD_GET_DIRECT_LA_ASSIGNATIONS,
	CRUD_GET_DIRECT_LA_ASSIGNATIONS_SUCCESS,
	CRUD_UPDATE_DIRECT_LA_ASSIGNATIONS,
	CRUD_UPDATE_DIRECT_LA_ASSIGNATIONS_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetLAAssignations = (payload: ICrudGetLAAssignations) => {
	return {
		type: CRUD_GET_DIRECT_LA_ASSIGNATIONS,
		payload
	}
}

export const crudGetLAAssignationsSuccess = (
	payload: ICrudGetLAAssignationsResArray
) => {
	return {
		type: CRUD_GET_DIRECT_LA_ASSIGNATIONS_SUCCESS,
		payload
	}
}

export const crudUpdateLAAssignations = (
	payload: ICrudUpdateLAAssignationsArray
) => {
	return {
		type: CRUD_UPDATE_DIRECT_LA_ASSIGNATIONS,
		payload
	}
}

export const crudUpdateLAAssignationsSuccess = (
	payload: ICrudUpdateLAAssignationsResArray
) => {
	return {
		type: CRUD_UPDATE_DIRECT_LA_ASSIGNATIONS_SUCCESS,
		payload
	}
}

export const crudClearLAAssignationsStore = () => ({
	type: CRUD_CLEAR_DIRECT_LA_ASSIGNATIONS_STORE
})
