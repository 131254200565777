import {
	ICrudGetLedgerAccountCenters,
	ICrudGetLedgerAccountCentersRes,
	ICrudUpdateLedgerAccountCenters,
	ICrudUpdateLedgerAccountCentersRes
} from 'models/CrudLedgerAccountsInterface'
import {
	CRUD_CLEAR_LEDGER_ACCOUNT_COST_CENTER_STORE,
	CRUD_GET_LEDGER_ACCOUNT_COST_CENTER,
	CRUD_GET_LEDGER_ACCOUNT_COST_CENTER_SUCCESS,
	CRUD_UPDATE_LEDGER_ACCOUNT_COST_CENTER,
	CRUD_UPDATE_LEDGER_ACCOUNT_COST_CENTER_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetLedgerAccountCenters = (
	payload: ICrudGetLedgerAccountCenters
) => {
	return {
		type: CRUD_GET_LEDGER_ACCOUNT_COST_CENTER,
		payload
	}
}

export const crudGetLedgerAccountCentersSuccess = (
	payload: ICrudGetLedgerAccountCentersRes
) => {
	return {
		type: CRUD_GET_LEDGER_ACCOUNT_COST_CENTER_SUCCESS,
		payload
	}
}

export const crudUpdateLedgerAccountCenters = (
	payload: ICrudUpdateLedgerAccountCenters
) => {
	return {
		type: CRUD_UPDATE_LEDGER_ACCOUNT_COST_CENTER,
		payload
	}
}

export const crudUpdateLedgerAccountCentersSuccess = (
	payload: ICrudUpdateLedgerAccountCentersRes
) => {
	return {
		type: CRUD_UPDATE_LEDGER_ACCOUNT_COST_CENTER_SUCCESS,
		payload
	}
}

export const crudClearCostCenterStore = () => ({
	type: CRUD_CLEAR_LEDGER_ACCOUNT_COST_CENTER_STORE
})
