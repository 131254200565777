import {
	GET_AFFECTATION_VOUCHER_BY_USER,
	GET_AFFECTATION_VOUCHER_BY_USER_SUCCESS,
	GET_VOUCHER_NUMBER,
	GET_VOUCHER_NUMBER_SUCCESS,
	GET_VOUCHER_TYPE,
	GET_VOUCHER_TYPE_BY_USER,
	GET_VOUCHER_TYPE_BY_USER_SUCCESS,
	GET_VOUCHER_TYPE_SUCCESS,
	NEW_VOUCHER_TYPE,
	NEW_VOUCHER_TYPE_SUCCESS,
	SAVE_CONFIG_STYLE_VOUCHER_TYPE_BY_USER,
	SAVE_CONFIG_STYLE_VOUCHER_TYPE_BY_USER_SUCCESS,
	VOUCHER_CANCEL,
	VOUCHER_CANCEL_SUCCESS,
	VOUCHER_SAVE_AND_NEW,
	VOUCHER_SAVE_AND_NEW_SUCCESS
} from 'constants/ActionsTypes'

const initialState = {
	voucherType: null,
	voucherTypeCancel: null,
	userVoucherType:
		typeof window !== 'undefined'
			? window.localStorage.getItem('userVoucherType')
				? JSON.parse(window.localStorage.getItem('userVoucherType'))
				: []
			: [],
	userVoucherTypeStyle: [],
	userVoucherTypeStyleConfirmation: null,
	newVoucher: null,
	voucherConfirmation: null,
	voucherNumber: null
}

function rootReducer(state = initialState, action) {
	switch (action.type) {
		case GET_VOUCHER_TYPE:
			return { ...state, voucherType: null, newVoucher: null }
		case GET_VOUCHER_TYPE_SUCCESS:
			return { ...state, voucherType: action.payload }
		case GET_VOUCHER_TYPE_BY_USER:
			return { ...state, userVoucherType: [] }
		case GET_VOUCHER_TYPE_BY_USER_SUCCESS:
			return { ...state, userVoucherType: action.payload }
		case VOUCHER_CANCEL:
			return { ...state, voucherTypeCancel: null }
		case VOUCHER_CANCEL_SUCCESS:
			return { ...state, ...initialState, voucherTypeCancel: action.payload }
		case VOUCHER_SAVE_AND_NEW:
			return {
				...state,
				voucherConfirmation: null,
				voucherSaveParameter: action.payload
			}
		case VOUCHER_SAVE_AND_NEW_SUCCESS:
			return { ...state, voucherConfirmation: action.payload }
		case SAVE_CONFIG_STYLE_VOUCHER_TYPE_BY_USER:
			return { ...state, userVoucherTypeStyleConfirmation: null }
		case SAVE_CONFIG_STYLE_VOUCHER_TYPE_BY_USER_SUCCESS:
			return { ...state, userVoucherTypeStyleConfirmation: action.payload }
		case NEW_VOUCHER_TYPE:
			return { ...state, newVoucher: null }
		case NEW_VOUCHER_TYPE_SUCCESS:
			return { ...state, newVoucher: action.payload }
		case GET_AFFECTATION_VOUCHER_BY_USER:
			return { ...state, affectationVoucher: null }
		case GET_AFFECTATION_VOUCHER_BY_USER_SUCCESS:
			return { ...state, affectationVoucher: action.payload }
		case GET_VOUCHER_NUMBER:
			return { ...state, voucherNumber: null }
		case GET_VOUCHER_NUMBER_SUCCESS:
			return { ...state, voucherNumber: action.payload }
		default:
			return state
	}
}

export default rootReducer
