import Axios from 'axios'
import {
	ICrudGetProviderObservation,
	ICrudUpdateProviderObservation
} from 'models/CrudProviderInterface'

const urlBase = '/abm_cpa_proveedores_obs'

export const crudGetProviderObservation = async (
	params: ICrudGetProviderObservation
) => {
	const response = await Axios.get(`${urlBase}/consulta`, { params })
	return response.data
}

export const crudUpdateProviderObservation = async (
	params: ICrudUpdateProviderObservation
) => {
	const response = await Axios.post(`${urlBase}/confirmar`, params)
	return response.data
}
