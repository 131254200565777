import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
	cancelProductStockError,
	cancelProductStockSuccess,
	confirmProductStockError,
	confirmProductStockSuccess,
	getProductStockSuccess,
	searchBarcodeError,
	searchBarcodeSuccess,
	validateProductStockError,
	validateProductStockSuccess
} from '../actions/Stock'
import {
	cancelProductStock,
	confirmProductStock,
	getProductStock,
	searchBarcode,
	validateProductStock
} from '../api/Stock'
import {
	CANCEL_PRODUCT_STOCK,
	CANCEL_PRODUCT_STOCK_ERROR,
	CONFIRM_PRODUCT_STOCK,
	CONFIRM_PRODUCT_STOCK_ERROR,
	GET_PRODUCT_STOCK,
	SEARCH_BARCODE,
	SEARCH_BARCODE_ERROR,
	VALIDATE_PRODUCT_STOCK
} from '../constants/ActionsTypes'

function* getProductStockRequest({ payload }) {
	try {
		const stock = yield call(getProductStock, payload)

		yield put(getProductStockSuccess(stock))
	} catch (error) {}
}

function* validateProductStockRequest({ payload }) {
	try {
		const validate = yield call(validateProductStock, payload)
		yield put(validateProductStockSuccess(validate))
	} catch (error) {
		yield put(validateProductStockError(error.response.data))
	}
}

function* confirmProductStockRequest({ payload }) {
	try {
		const confirm = yield call(confirmProductStock, payload)
		yield put(confirmProductStockSuccess(confirm))
	} catch (error) {
		const { Resultado } = error.response.data
		const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
		yield put(
			confirmProductStockError({
				error: CONFIRM_PRODUCT_STOCK_ERROR,
				errorMessage
			})
		)
	}
}

function* cancelProductStockRequest({ payload }) {
	try {
		const canceled = yield call(cancelProductStock, payload)
		yield put(cancelProductStockSuccess(canceled))
	} catch (error) {
		const { Resultado } = error.response.data
		const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
		yield put(
			cancelProductStockError({
				error: CANCEL_PRODUCT_STOCK_ERROR,
				errorMessage
			})
		)
	}
}

function* SearchBarcodeRequest({ payload }) {
	try {
		const barcode = yield call(searchBarcode, payload)
		yield put(searchBarcodeSuccess(barcode))
	} catch (error) {
		const { Resultado } = error.response.data
		const errorMessage = !Resultado.Resultado ? Resultado.Mens_error : ''
		yield put(searchBarcodeError({ error: SEARCH_BARCODE_ERROR, errorMessage }))
	}
}

export function* getProductStockSaga() {
	yield takeLatest(GET_PRODUCT_STOCK, getProductStockRequest)
}

export function* validateProductStockSaga() {
	yield takeLatest(VALIDATE_PRODUCT_STOCK, validateProductStockRequest)
}

export function* confirmProductStockSaga() {
	yield takeLatest(CONFIRM_PRODUCT_STOCK, confirmProductStockRequest)
}

export function* cancelProductStockSaga() {
	yield takeLatest(CANCEL_PRODUCT_STOCK, cancelProductStockRequest)
}

export function* searchBarcodeSaga() {
	yield takeLatest(SEARCH_BARCODE, SearchBarcodeRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getProductStockSaga),
		fork(validateProductStockSaga),
		fork(confirmProductStockSaga),
		fork(cancelProductStockSaga),
		fork(searchBarcodeSaga)
	])
}
