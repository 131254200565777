import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setInputFocus } from 'actions'
import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ModalPriceUnit from './modalPriceUnit'

class InputPriceUnit extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			showModal: false
		}
	}

	openModal = () => {
		this.setState({ showModal: true })
	}

	handleCancelModal = () => {
		this.setState({ showModal: false })
	}

	/**
	 * finish and apply new price
	 */
	handleSubmit = (selectPrice) => {
		const { row } = this.props
		this.props.handleSubmitPrice(row, selectPrice)
		this.handleCancelModal()
	}

	render() {
		const {
			showPriceIcon,
			fieldsPriceEstTable,
			fieldsPriceTable,
			fieldsPriceForm
		} = this.props

		return (
			<Row>
				<Col sm={1} style={{ paddingLeft: '3px', paddingTop: '30%' }}>
					{showPriceIcon && (
						<FontAwesomeIcon
							icon={faEllipsisH}
							color="#007bff"
							style={{ cursor: 'pointer', position: 'absolute' }}
							onClick={this.openModal}
						/>
					)}
				</Col>

				{this.state.showModal && (
					<ModalPriceUnit
						{...this.props}
						showModal={this.state.showModal}
						handleClose={this.handleCancelModal}
						handleSubmit={this.handleSubmit}
						fieldsPriceEstTable={fieldsPriceEstTable}
						fieldsPriceTable={fieldsPriceTable}
						fieldsPriceForm={fieldsPriceForm}
					/>
				)}
			</Row>
		)
	}
}

const mapStateToProps = ({ product }) => {
	const { productsUpdate, focusInput } = product

	return { productsUpdate, focusInput }
}

export default connect(mapStateToProps, {
	setInputFocus
})(withTranslation()(InputPriceUnit))
