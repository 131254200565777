import {
	crudCreateProviderCode,
	crudGetProviderCode,
	crudGetProviderCodeConfig
} from 'api/ProviderCrudCodeApi'
import {
	ICrudCreateProviderCodeSaga,
	ICrudGetProviderCodeConfigSaga,
	ICrudGetProviderCodeSaga
} from 'models/CrudProviderInterface'
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
	crudCreateProviderCodeSuccess,
	crudGetProviderCodeConfigSuccess,
	crudGetProviderCodeSuccess,
	crudValidateProviderCodeSuccess
} from '../actions'
import {
	CRUD_CREATE_PROVIDER_CODE,
	CRUD_GET_PROVIDER_CODE,
	CRUD_GET_PROVIDER_CODE_CONFIG,
	CRUD_VALIDATE_PROVIDER_CODE
} from '../constants/ActionsTypes'

function* crudGetProviderCodeReq({ payload }: ICrudGetProviderCodeSaga): any {
	try {
		const customer: any = yield call(crudGetProviderCode, payload)
		yield put(crudGetProviderCodeSuccess(customer))
	} catch (error) {}
}

function* crudGetProviderCodeConfigReq({
	payload
}: ICrudGetProviderCodeConfigSaga): any {
	try {
		const customer: any = yield call(crudGetProviderCodeConfig, payload)
		yield put(crudGetProviderCodeConfigSuccess(customer))
	} catch (error) {}
}

function* crudCreateProviderCodeReq({
	payload
}: ICrudCreateProviderCodeSaga): any {
	try {
		const customer = yield call(crudCreateProviderCode, payload)
		yield put(crudCreateProviderCodeSuccess(customer))
	} catch (error) {}
}

function* crudValidateProviderCodeReq({
	payload
}: ICrudCreateProviderCodeSaga): any {
	try {
		const customer = yield call(crudCreateProviderCode, payload)
		yield put(crudValidateProviderCodeSuccess(customer))
	} catch (error) {}
}

export function* crudGetProviderCodeSaga() {
	yield takeLatest(CRUD_GET_PROVIDER_CODE, crudGetProviderCodeReq)
}

export function* crudGetProviderCodeConfigSaga() {
	yield takeLatest(CRUD_GET_PROVIDER_CODE_CONFIG, crudGetProviderCodeConfigReq)
}

export function* crudCreateProviderCodeSaga() {
	yield takeEvery(CRUD_CREATE_PROVIDER_CODE, crudCreateProviderCodeReq)
}

export function* crudValidateProviderCodeSaga() {
	yield takeEvery(CRUD_VALIDATE_PROVIDER_CODE, crudValidateProviderCodeReq)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetProviderCodeSaga),
		fork(crudGetProviderCodeConfigSaga),
		fork(crudCreateProviderCodeSaga),
		fork(crudValidateProviderCodeSaga)
	])
}
