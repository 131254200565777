import withMenu from 'components/common/withMenu'
import GlobalContainer from 'components/layout/globalContainer'
import TreasuryHeaderComponent from 'components/TreasuryHeader/TreasuryHeaderComponent'
import { P_TREASURY_HEADER } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'

class TreasuryHeader extends Component {
	myRef: any = React.createRef()

	handleCallBackButton = () => {
		if (this.myRef.submitForm) {
			this.myRef.submitForm()
		}
	}

	render() {
		const containerProps = {
			codeProcess: P_TREASURY_HEADER,
			callBackButton: this.handleCallBackButton,
			nextPage: true
		}

		const propsComponent = {
			headerEntryFormRef: (ref: any) => (this.myRef = ref)
		}

		return (
			<Row>
				<GlobalContainer {...containerProps}>
					<TreasuryHeaderComponent {...propsComponent} />
				</GlobalContainer>
			</Row>
		)
	}
}

export default withMenu(TreasuryHeader)
