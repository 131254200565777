import { InventoryPickStockActions } from 'constants/ActionsTypesTs'
import {
	IGetCufPickStockResponse,
	IGetDepositsPickStockResponse,
	IGetInventoryPickStockResponse,
	InventorySearchBarcodeParams,
	InventorySearchBarcodeResponse,
	ISearchBarcodeErrorResponse
} from 'models/InventoryPickStock'

export interface valueState {
	inventoryBarCodeItems: InventorySearchBarcodeResponse | null
	inventoryPickStockData: IGetInventoryPickStockResponse | null
	inventoryBarCodeError: ISearchBarcodeErrorResponse | null
	inventoryBarCodeParams: InventorySearchBarcodeParams | null
	pickStockListCufs: IGetCufPickStockResponse | null
	pickStockListDeposits: IGetDepositsPickStockResponse | null
}

const initialState = {
	inventoryBarCodeItems: null,
	inventoryPickStockData: null,
	inventoryBarCodeError: null,
	inventoryBarCodeParams: null,
	pickStockListCufs: null,
	pickStockListDeposits: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case InventoryPickStockActions.INVENTORY_PICK_BAR_CODE_SEARCH:
			return {
				...state,
				inventoryBarCodeItems: null,
				inventoryBarCodeParams: action.payload,
				inventoryBarCodeError: null
			}
		case InventoryPickStockActions.INVENTORY_PICK_BAR_CODE_SEARCH_SUCCESS:
			return {
				...state,
				inventoryBarCodeItems: action.payload,
				inventoryBarCodeParams: null,
				inventoryBarCodeError: null
			}
		case InventoryPickStockActions.INVENTORY_PICK_BAR_CODE_SEARCH_ERROR:
			return { ...state, inventoryBarCodeError: action.payload }
		case InventoryPickStockActions.GET_INVENTORY_PICK_STOCK:
			return { ...state, inventoryPickStockData: null }
		case InventoryPickStockActions.GET_INVENTORY_PICK_STOCK_SUCCESS:
			return {
				...state,
				inventoryPickStockData: action.payload,
				inventoryBarCodeItems: null
			}
		case InventoryPickStockActions.GET_CUF_PICK_STOCK:
			return { ...state, pickStockListCufs: null }
		case InventoryPickStockActions.GET_CUF_PICK_STOCK_SUCCESS:
			return { ...state, pickStockListCufs: action.payload }
		case InventoryPickStockActions.GET_DEPOSITS_PICK_STOCK:
			return { ...state, pickStockListDeposits: null }
		case InventoryPickStockActions.GET_DEPOSITS_PICK_STOCK_SUCCESS:
			return { ...state, pickStockListDeposits: action.payload }
		default:
			return state
	}
}

export default rootReducer
