import { validateSalesAffectedDiscount } from 'actions'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { handleSetFocus } from 'lib/TableUtils'
import { IValidateSalesAffectedDiscountParams } from 'models/VoucherInvolvement'
import React, { Component } from 'react'
import { Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'

interface IExpandInvolvementFormProps {
	row: any
	fields: Array<IFieldStructure>
	validateSalesAffectedDiscount: (
		payload: IValidateSalesAffectedDiscountParams
	) => void
	idOperacion: string
}

class ExpandInvolvementForm extends Component<IExpandInvolvementFormProps> {
	inputRefs: any = {}
	/**
	 * to show the form fields from the api configuration     *
	 */
	renderInputs = () => {
		const { row, fields } = this.props
		return fields.map((field) => {
			return this.renderInput(fields, field, row)
		})
	}

	/**
	 * call in the onblur event
	 * @param value
	 * @param fieldId
	 */
	handleOnBlurInput = (value: string, fieldId: string) => {
		const { idOperacion, row } = this.props
		if (fieldId === 'pc_lista') {
			this.props.validateSalesAffectedDiscount({
				idOperacion,
				nimovcli: row.nimovcli,
				nitem: row.nitem,
				pc_lista: value
			})
		}
	}

	/**
	 * to get the next editable field.
	 * @param {*} field
	 * @returns
	 */
	getNextEditField = (field: string) => {
		const { fields } = this.props
		let result = null
		let nextIndex = 0
		let restart = false
		let indexField = _.findIndex(fields, { idCampo: field })

		do {
			nextIndex = indexField + 1
			indexField = fields[nextIndex] ? nextIndex : 0
			result = fields[indexField].idCampo.trim()
			if (!fields[nextIndex]) {
				restart = true
			}
		} while (!fields[indexField].editable)

		return restart ? 'finalRow' : result
	}

	/**
	 * render label col
	 */
	renderInput = (
		fields: Array<IFieldStructure>,
		field: IFieldStructure,
		row: any
	) => {
		const fieldId = field.idCampo.trim()
		const value = row[fieldId] !== null ? row[fieldId] : ''
		if (field.editable && !this.inputRefs[fieldId]) {
			this.inputRefs[fieldId] = {}
		}

		if (field.editable && !this.inputRefs[fieldId][row.id]) {
			const customRef = React.createRef()
			this.inputRefs[fieldId][row.id] = customRef
		}

		const inputProps = {
			key: `${fieldId}-${row.id}`,
			fwRef: field.editable ? this.inputRefs[fieldId][row.id] : null,
			fields,
			label: fieldId,
			inputId: fieldId,
			name: fieldId,
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: false,
			disabledInput: !(row.Cant_afec > 0),
			onBlur: (value: string) => this.handleOnBlurInput(value, fieldId),
			value,
			autoComplete: 'off',
			handleEnterKey: (e: any, value: string) => {
				const nextField = this.getNextEditField(fieldId)
				if (nextField === 'finalRow') {
					this.handleOnBlurInput(value, fieldId)
				}
				handleSetFocus(nextField, row.id, this.inputRefs)
				e.preventDefault()
			},
			onChange: (data: any) => {}
		}
		return <InputText {...inputProps} />
	}

	render() {
		return (
			<Container className={'m-0'}>
				<Row>{this.renderInputs()}</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state: any) => {
	return {}
}

const mapDispatchToProps = { validateSalesAffectedDiscount }

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ExpandInvolvementForm)
