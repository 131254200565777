import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { FormikProps } from 'formik'
import { handleSetValueInput } from 'lib/FormUtils'
import { ICalculationFormValues } from 'models/CalculationBonuses'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'

interface ICalculationBonusesFormProps {
	t?: any
	fields: Array<IFieldStructure>
	formikProps: FormikProps<Partial<ICalculationFormValues>>
}

interface ICalculationBonusesFormState {}

class CalculationBonusesForm extends Component<
	ICalculationBonusesFormProps,
	ICalculationBonusesFormState
> {
	render() {
		const { fields, formikProps } = this.props
		const { handleBlur, values } = formikProps

		const propsSubTotal = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Subtotal',
			inputId: 'subtotal',
			name: 'subtotal',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.subtotal,
			onChange: (data: any) =>
				handleSetValueInput(data, 'subtotal', formikProps)
		}

		const propsDiscount = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Descuento',
			inputId: 'descuento',
			name: 'descuento',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.descuento,
			onChange: (data: any) =>
				handleSetValueInput(data, 'descuento', formikProps)
		}

		const propsBonus = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Bonificacion',
			inputId: 'bonif',
			name: 'bonif',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.bonif,
			onChange: (data: any) => handleSetValueInput(data, 'bonif', formikProps)
		}

		const propsSurcharge = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'recargo',
			inputId: 'recargo',
			name: 'recargo',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.recargo,
			onChange: (data: any) => handleSetValueInput(data, 'recargo', formikProps)
		}

		const propsNet = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Neto',
			inputId: 'neto',
			name: 'neto',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			onBlur: handleBlur,
			value: values.neto,
			onChange: (data: any) => handleSetValueInput(data, 'neto', formikProps)
		}

		return (
			<Row>
				<Row className={`col-12`}>
					<InputText {...propsSubTotal} />
				</Row>
				<Row className={`col-12`}>
					<InputText {...propsDiscount} />
				</Row>
				<Row className={`col-12`}>
					<InputText {...propsBonus} />
				</Row>
				<Row className={`col-12`}>
					<InputText {...propsSurcharge} />
				</Row>
				<Row className={`col-12`}>
					<InputText {...propsNet} />
				</Row>
			</Row>
		)
	}
}

export default CalculationBonusesForm
