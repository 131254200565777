import { LoadPackageActions } from 'constants/ActionsTypesTs'
import { IGetPackageSuccessResponse } from 'models/LoadPackages'

export interface valueState {
	packagesAttributes: IGetPackageSuccessResponse | null
}

const initialState = {
	packagesAttributes: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case LoadPackageActions.GET_PACKAGES:
			return { ...state, packagesAttributes: null }
		case LoadPackageActions.GET_PACKAGES_SUCCESS:
			return { ...state, packagesAttributes: action.payload }

		default:
			return state
	}
}

export default rootReducer
