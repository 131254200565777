import CommonLabelCol from 'components/common/CommonLabelCol'
import InputButton from 'components/form/inputButton'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import _ from 'underscore'

interface SourceModalProps {
	data: any
	fields: Array<IFieldStructure>
	handleClose: () => void
}

interface SourceModalState {}

class SearchVouchersInventorySourceModal extends Component<SourceModalProps> {
	render() {
		const { fields, data } = this.props

		return (
			<Fragment>
				<Row>
					{_.isEmpty(fields) ? (
						<div />
					) : (
						fields.map((field: IFieldStructure) => {
							const idField = field.idCampo.trim()
							return (
								<CommonLabelCol
									formCol={{ sm: 12 }}
									key={idField}
									colLabel={{ sm: 3 }}
									fields={fields}
									labelId={idField}
									value={data[idField] ? `${data[idField]}` : ''}
								/>
							)
						})
					)}
				</Row>
				<Row>
					<Col className="text-center">
						<InputButton
							valueButton={'Cancelar'}
							onClick={() => this.props.handleClose()}
							type={'primary'}
							customStyle={{ minWidth: '125px' }}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

export default SearchVouchersInventorySourceModal
