import { pickeoEgrSearchBarcode, searchAutoProducts } from 'actions'
import BarcodeInputText from 'components/form/barcodeInputText'
import InputAutocomplete from 'components/form/inputAutocomplete'
import InputText from 'components/form/inputText'
import { setInputFocus } from 'lib/Utils'
import React, { Component, createRef } from 'react'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import _ from 'underscore'
import styles from './PickStock.module.scss'
import PickStockResume from './PickStockResume'

class PickStockBarCode extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cufOptions: [],
			barCode: '',
			indLastCuf: false,
			auoptions: [],
			isLoading: false,
			depValue: '',
			cufValue: '',
			cant: 1,
			searchProduct: '',
			enableCant: false,
			firstFocus: true
		}

		this.firstRefInput = createRef()
		this.searchInputRef = createRef()
		this.canInputRef = createRef()
	}

	componentDidMount() {
		this.props.handleToggleCant(this.handleToggleCant)
		this.handleToggleCant = this.handleToggleCant.bind(this)
	}

	componentDidUpdate = (prevProps) => {
		const { firstFocus } = this.state
		if (this.firstRefInput.current && firstFocus) {
			setInputFocus(this.firstRefInput)
			this.setState({ firstFocus: false })
		}
		const { search, searchInput, barCodeSearched } = this.props
		if (search !== prevProps.search && search && search.productos) {
			const auoptions = search.productos.map((product) => ({
				id: product.niprod,
				label: product.desc_prod
			}))
			this.setState({ auoptions })
		}

		if (barCodeSearched !== prevProps.barCodeSearched && barCodeSearched) {
			if (this.searchInputRef && this.searchInputRef.current) {
				this.searchInputRef.current.clear()
				this.setState({ cant: 1, enableCant: true })
			}
		}

		if (searchInput !== prevProps.searchInput) {
			this.handleSetSearchFocus()
		}
	}

	/**
	 * to enable and disable cant field
	 */
	handleToggleCant = () => {
		this.setState((prevState) => ({ enableCant: !prevState.enableCant }))
		const { enableCant } = this.state

		if (enableCant) {
			setInputFocus(this.canInputRef)
		} else {
			this.handleSetSearchFocus()
		}
	}

	/**
	 * to set focus in the search input tha is enabled
	 */
	handleSetSearchFocus = () => {
		const { searchInput } = this.props
		if (!searchInput) {
			setInputFocus(this.firstRefInput)
		} else {
			setInputFocus(this.searchInputRef)
		}
	}

	/**
	 * to set cuf options when change deposit
	 * @param {event} dep
	 */
	handleChangeDep = (dep) => {
		const { value } = dep.target
		const { egrPickeo } = this.props

		const cufOptions =
			egrPickeo && egrPickeo.cuf.length
				? _.filter(egrPickeo.cuf, (opt) => {
						return opt.cod_dep === value
				  }).map((cufOpt) => ({ id: cufOpt.cuf, label: cufOpt.cuf }))
				: []
		this.setState({ cufOptions, depValue: value })
	}

	/**
	 * search codebar
	 */
	handleSearchBarcode = () => {
		const { pickeoEgrSearchBarcode, idOperacion } = this.props
		const { barCode, indLastCuf, depValue, cufValue, cant } = this.state
		const params = {
			idOperacion,
			cod_barra: barCode,
			ind_ultimo_cuf: indLastCuf ? 1 : 0,
			cant,
			page: 1,
			page_size: 10
		}

		if (!indLastCuf) {
			params.depo = depValue
			params.cuf = cufValue
		}

		pickeoEgrSearchBarcode(params)
		this.setState({ barCode: '', searchProduct: '' })
	}

	handleOnChangeCheckbox = (event) => {
		this.setState({ indLastCuf: event.target.checked })
	}

	/**
	 * to call search api
	 * @param {string} search
	 */
	handleSearch = (search) => {
		const { idOperacion } = this.props
		this.props.searchAutoProducts({
			idOperacion,
			desc_prod: search,
			page_number: 0,
			page_size: 10
		})
	}

	/**
	 * to select a product from the search
	 * @param {string} productSelected
	 */
	handleSelect = (productSelected) => {
		const { pickeoEgrSearchBarcode, idOperacion } = this.props
		const { indLastCuf, depValue, cufValue, cant } = this.state

		if (productSelected.length > 0) {
			const params = {
				idOperacion,
				niprod: productSelected[0].id,
				ind_ultimo_cuf: indLastCuf ? 1 : 0,
				cant
			}

			if (!indLastCuf) {
				params.depo = depValue
				params.cuf = cufValue
			}

			pickeoEgrSearchBarcode(params)
			this.setState({ searchProduct: productSelected[0].label })
		}
	}

	handleChange = (data) => {}

	render() {
		const { t, theme, egrPickeo, searchInput, config, fieldsForm } = this.props
		const { auoptions, isLoading, cant, barCode, searchProduct, enableCant } =
			this.state

		return fieldsForm ? (
			<Col
				className={`${theme.containerBorder}  ${theme.containerBarcode} `}
				sm={12}
			>
				<Row>
					<Col
						className={`${theme.divLabel} pb-3`}
						style={{ marginTop: '-3px' }}
					>
						{t('pickStock.stockOut')}
					</Col>
				</Row>
				<Row style={{ marginTop: '-0.5%', marginBottom: '-0.5%' }}>
					{searchInput ? (
						<InputAutocomplete
							refs={this.searchInputRef}
							inputFormCol={{ sm: 7 }}
							label={'Buscar Productos (F2)'}
							inputId={'search_product'}
							name={'search_product'}
							placeholder={t('searchBox.form.insert_product')}
							fields={fieldsForm}
							colLabel={'col-sm-3'}
							colInput={'col-sm-9'}
							handleSearch={this.handleSearch}
							auoptions={auoptions}
							handleLoading={isLoading}
							handleSelect={this.handleSelect}
							onChange={this.handleChange}
							labelKey={'label'}
							defaultValue={
								searchProduct ? String(searchProduct) : searchProduct
							}
							extraCol={true}
						/>
					) : (
						<BarcodeInputText
							fwRef={this.firstRefInput}
							inputFormCol={{ sm: 7 }}
							fields={fieldsForm}
							label={'label'}
							inputId={'pick_cod_barra'}
							name={'pick_cod_barra'}
							placeholder={''}
							styles={{ width: '100%' }}
							colLabel={'col-sm-3'}
							colInput={'col-sm-9'}
							onBlur={() => {}}
							handleEnterKey={() => this.handleSearchBarcode()}
							value={barCode}
							onChange={(data) => this.setState({ barCode: data })}
							activeListeners
						/>
					)}
					<InputText
						inputFormCol={{ sm: 4 }}
						disabledInput={!enableCant}
						fwRef={this.canInputRef}
						type={'number'}
						fields={fieldsForm}
						label={true}
						inputId={'cant_ns'}
						name={'cant_ns'}
						placeholder={t('global.quantity')}
						styles={{ width: '100%', border: '1px solid #ccc' }}
						colLabel={'col-sm-3'}
						colInput={'col-sm-3'}
						onBlur={() => {}}
						value={cant}
						onChange={(data) => this.setState({ cant: data })}
						handleEnterKey={this.handleSetSearchFocus}
					/>
				</Row>
				<Row>
					<PickStockResume config={config} egrPickeo={egrPickeo} />
				</Row>
			</Col>
		) : null
	}
}

const mapStateToProps = ({ pickeoEgrStock, product }) => {
	const { searchAutoOptions } = product
	const { barCodeResponse, barCodeSearched } = pickeoEgrStock
	return { barCodeResponse, search: searchAutoOptions, barCodeSearched }
}

const connectForm = connect(mapStateToProps, {
	pickeoEgrSearchBarcode,
	searchAutoProducts
})(withTranslation()(PickStockBarCode))
export default themr('ProviderPickStockStyles', styles)(connectForm)
