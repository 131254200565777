import { clearProductStore, getConfigFieldsCrud } from 'actions'
import withMenu from 'components/common/withMenu'
import SearchProductsForm from 'components/SearchProducts/SearchProductsForm'
import SearchProductsTable from 'components/SearchProducts/SearchProductsTable'
import { P_SEARCH_PRODUCTS } from 'constants/ConfigProcessNames'
import { IAbmStructureResponse } from 'constants/valuesInterfaces/interfaces'
import { getFiltersData } from 'lib/Utils'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import _ from 'underscore'

type SearchProductsProps = {
	getConfigFieldsCrud: (payload: { cod_abm: string }) => void
	fieldsConfig: IAbmStructureResponse
	match?: any
	clearProductStore: () => void
}

class SearchProducts extends Component<SearchProductsProps> {
	constructor(props: SearchProductsProps) {
		super(props)
		this.state = {}
	}

	componentDidMount = () => {
		const { params } = this.props.match
		if (_.isEmpty(params) || params.loadStore !== 'filters') {
			this.props.clearProductStore() // to clean store
		}
		this.props.getConfigFieldsCrud({ cod_abm: P_SEARCH_PRODUCTS })
	}

	render() {
		const { fieldsConfig } = this.props

		return (
			<Fragment>
				{fieldsConfig && !_.isEmpty(fieldsConfig.campos) && (
					<div className="mt-3">
						<SearchProductsForm
							fields={getFiltersData(fieldsConfig.campos, {
								agrupador: 'formulario'
							})}
							isSearchProducts={false}
							handleSendSearch={() => {}}
						/>
						<SearchProductsTable
							fields={getFiltersData(fieldsConfig.campos, {
								agrupador: 'grilla'
							})}
						/>
					</div>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = ({ configCrud, auth }: any) => {
	const { fieldsConfig } = configCrud
	const { authUser } = auth
	return { fieldsConfig, authUser }
}

const connectForm = connect(mapStateToProps, {
	getConfigFieldsCrud,
	clearProductStore
})(SearchProducts)

export default withMenu(connectForm)
