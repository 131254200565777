import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getOptionsDropDown, handleSetValueInput } from 'lib/FormUtils'
import React, { Fragment, PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'

interface IConsumerInputsProps extends WithTranslation {
	t?: any
	values: any
	handleChange: any
	handleBlur: any
	setFieldValue: any
	setFieldTouched: any
	idOperacion?: any
	fields?: Array<IFieldStructure>
	documentType: any
}

class FinalConsumerFormInputs extends PureComponent<IConsumerInputsProps> {
	render() {
		const {
			fields,
			t,
			setFieldTouched,
			setFieldValue,
			handleBlur,
			values,
			documentType
		} = this.props

		const formikProps = { setFieldTouched, setFieldValue }

		const branchesProps = {
			inputFormCol: { sm: 6 },
			fields,
			label: t('search_voucher.type_voucher'),
			inputId: 'doc_tipo',
			name: 'doc_tipo',
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			onBlur: handleBlur,
			value: values.doc_tipo,
			options: getOptionsDropDown(documentType ? documentType.lista : []),
			onChange: (data: any) =>
				handleSetValueInput(data, 'doc_tipo', formikProps)
		}

		const docNumberProp = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Fecha',
			inputId: 'doc_nro',
			name: 'doc_nro',
			placeholder: t('final_consumer.document_number'),
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: false,
			onBlur: () => {},
			value: values.doc_nro,
			autoComplete: 'off',
			onChange: (data: any) => {
				const date = data || ''
				handleSetValueInput(date, 'doc_nro', formikProps)
			}
		}
		const nameProp = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Fecha',
			inputId: 'nombre',
			name: 'nombre',
			placeholder: t('global.name'),
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			disable: false,
			onBlur: () => {},
			value: values.nombre,
			autoComplete: 'off',
			onChange: (data: any) => {
				const value = data || ''
				handleSetValueInput(value, 'nombre', formikProps)
			}
		}
		const lastNameProp = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Fecha',
			inputId: 'apellido',
			name: 'apellido',
			placeholder: t('global.lastName'),
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disable: false,
			onBlur: () => {},
			value: values.apellido,
			autoComplete: 'off',
			onChange: (data: any) => {
				const date = data || ''

				handleSetValueInput(date, 'apellido', formikProps)
			}
		}

		const addressProp = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'address',
			inputId: 'domicilio',
			name: 'domicilio',
			placeholder: t('global.address'),
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			disable: false,
			onBlur: () => {},
			value: values.domicilio,
			autoComplete: 'off',
			onChange: (data: any) => {
				const date = data || ''
				handleSetValueInput(date, 'domicilio', formikProps)
			}
		}

		const locationProp = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'Fecha',
			inputId: 'loc_pcia',
			name: 'loc_pcia',
			placeholder: t('global.location'),
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			disable: false,
			onBlur: () => {},
			value: values.loc_pcia,
			autoComplete: 'off',
			onChange: (data: any) => {
				const date = data || ''

				handleSetValueInput(date, 'loc_pcia', formikProps)
			}
		}

		return (
			<Fragment>
				<Row className={`col-12`}>
					<InputDropdown {...branchesProps} />
					<InputText {...docNumberProp} />
				</Row>
				<Row className={`col-12`}>
					<InputText {...nameProp} />
					<InputText {...lastNameProp} />
				</Row>
				<Row className={`col-12`}>
					<InputText {...addressProp} />
				</Row>
				<Row className={`col-12`}>
					<InputText {...locationProp} />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ tableCalls }: any) => {
	const { documentType } = tableCalls
	return { documentType }
}

const mapDispatchToProps = {}

const connectForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(FinalConsumerFormInputs)

export default withTranslation()(connectForm)
