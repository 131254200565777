import TableWithSelection from 'components/abmUtils/TableWithSelection'
import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import { getFiltersData } from 'lib/Utils'
import { ICrudCostCentersArray } from 'models/CrudLedgerAccountsInterface'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import _ from 'underscore'

interface ValuesTableProps extends WithTranslation {
	fieldsConfig: any
	disableForm: boolean
	values: any
	nicodcta?: number
	handleSubmit: any
	setFormChanged: any
	t?: any
	showWarning: boolean
	handleWarning: any
}

type ValuesTableState = {
	showEditPopup: boolean
	disablePopup: boolean
	alta: number
	errorTitle: string
	errorMessage: string
	typeNotification: string
	showError: boolean
	selectRow: number
	titleModal: string
	sustainsClick: boolean
	selectedLeft: Array<string>
	selectedRight: Array<string>
	leftValues: Array<ICrudCostCentersArray>
	rightValues: Array<ICrudCostCentersArray>
}

class DynamicTable extends Component<ValuesTableProps, ValuesTableState> {
	constructor(props: ValuesTableProps) {
		super(props)
		this.state = {
			showEditPopup: false,
			disablePopup: true,
			alta: 0,
			errorTitle: '',
			errorMessage: '',
			typeNotification: 'success',
			showError: false,
			selectRow: 0,
			titleModal: '',
			selectedLeft: [],
			selectedRight: [],
			sustainsClick: false,
			leftValues: this.props.values.lista1,
			rightValues: this.props.values.lista2
		}
	}

	/**
	 * Checks if the values into the array changed to setup changed state
	 * @param prevState
	 */
	componentDidUpdate = (
		prevProps: ValuesTableProps,
		prevState: ValuesTableState
	) => {
		const { values, setFormChanged } = this.props
		const { rightValues } = this.state
		const { rightValues: prevRightValues } = prevState

		if (values && rightValues !== prevRightValues) {
			JSON.stringify(values.lista2) !== JSON.stringify(rightValues)
				? setFormChanged(true)
				: setFormChanged(false)
		}
	}

	/**
	 * If click happens inside a table, setup sustain state to paint fields
	 * If click happens outside, clear selected values
	 * @param event
	 */
	handleMouseDown = (event: any) => {
		const { selectedLeft, selectedRight } = this.state
		event.preventDefault()
		if (event.target.children.length > 0) {
			this.setState({
				selectedLeft: selectedLeft.filter((id: any) => false),
				selectedRight: selectedRight.filter((id: any) => false)
			})
		} else {
			this.setState({ sustainsClick: true })
		}
	}

	/**
	 * Handles mouse up, sets sustained click to false
	 * @param event
	 */
	handleMouseUp = (event: any) => {
		event.preventDefault()
		this.setState({ sustainsClick: false })
	}

	/**
	 * to handle click on buttons, each button has a label to identify it
	 * @param side
	 */
	handleButtonClick = (side: string) => () => {
		const { selectedLeft, selectedRight, leftValues, rightValues } = this.state

		if (side === 'toRight') {
			// pass selected values to right
			const selectedFields: Array<ICrudCostCentersArray> = []

			_.forEach(selectedLeft, (field) => {
				const selectedField = _.find(
					leftValues,
					(value: ICrudCostCentersArray) => {
						return value.nicc === field
					}
				)
				selectedField && selectedFields.push(selectedField)
			})

			const newValues =
				selectedFields &&
				rightValues.concat(selectedFields).sort((a: any, b: any) => {
					return a.cod_cc.localeCompare(b.cod_cc)
				})

			this.setState(() => ({
				leftValues: leftValues.filter(
					(value: ICrudCostCentersArray) =>
						!_.includes(selectedLeft, value.nicc)
				),
				selectedLeft: selectedLeft.filter(() => false),
				rightValues: newValues
			}))
		} else if (side === 'toLeft') {
			// pass selected values to left
			const selectedFields: Array<ICrudCostCentersArray> = []

			_.forEach(selectedRight, (field) => {
				const selectedField = _.find(
					rightValues,
					(value: ICrudCostCentersArray) => {
						return value.nicc === field
					}
				)
				selectedField && selectedFields.push(selectedField)
			})

			const newValues = leftValues
				.concat(selectedFields)
				.sort((a: any, b: any) => {
					return a.cod_cc.localeCompare(b.cod_cc)
				})

			this.setState(() => ({
				rightValues: rightValues.filter(
					(value: ICrudCostCentersArray) =>
						!_.includes(selectedRight, value.nicc)
				),
				selectedRight: selectedRight.filter(() => false),
				leftValues: newValues
			}))
		} else if (side === 'allLeft') {
			// pass all values to left
			const newValues = leftValues
				.concat(rightValues)
				.sort((a: any, b: any) => {
					return a.cod_cc.localeCompare(b.cod_cc)
				})

			this.setState(() => ({
				leftValues: newValues,
				rightValues: rightValues.filter(() => false),
				selectedRight: selectedRight.filter(() => false)
			}))
		} else if (side === 'allRight') {
			// pass all values to right
			const newValues = rightValues
				.concat(leftValues)
				.sort((a: any, b: any) => {
					return a.cod_cc.localeCompare(b.cod_cc)
				})

			this.setState(() => ({
				rightValues: newValues,
				leftValues: leftValues.filter(() => false),
				selectedLeft: selectedLeft.filter(() => false)
			}))
		}
	}

	/**
	 * to handle double click events, gets called by the TableWithSelection comp
	 * @param side
	 * @param row
	 */
	handleDoubleClick = (side: string, row: string) => {
		const { leftValues, rightValues } = this.state
		if (side === 'leftTable') {
			// Click on left table
			const selectedField = _.find(
				leftValues,
				(value: ICrudCostCentersArray) => {
					return value.nicc === row
				}
			)

			const newValues =
				selectedField &&
				[...rightValues, selectedField].sort(function (a: any, b: any) {
					return a.cod_cc.localeCompare(b.cod_cc)
				})

			this.setState(() => ({
				leftValues: selectedField
					? leftValues.filter(
							(value: ICrudCostCentersArray) =>
								value.nicc !== selectedField.nicc
					  )
					: leftValues,
				rightValues: newValues || rightValues
			}))
		}
		if (side === 'rightTable') {
			// Click on right table
			const selectedField = _.find(
				rightValues,
				(value: ICrudCostCentersArray) => {
					return value.nicc === row
				}
			)

			const newValues =
				selectedField &&
				[...leftValues, selectedField].sort(function (a: any, b: any) {
					return a.cod_cc.localeCompare(b.cod_cc)
				})

			selectedField &&
				this.setState(() => ({
					rightValues: selectedField
						? rightValues.filter(
								(value: ICrudCostCentersArray) =>
									value.nicc !== selectedField.nicc
						  )
						: rightValues,
					leftValues: newValues || leftValues
				}))
		}
	}

	/**
	 * to handle selection state, gets labels as params
	 * @param selectedRow
	 * @param side
	 * @param action
	 */
	handleSelection = (selectedRow: string, side: string, action: string) => {
		const { selectedLeft, selectedRight } = this.state
		if (action === 'add') {
			side === 'leftTable' &&
				this.setState(() => ({
					selectedLeft: [...selectedLeft, selectedRow]
				}))
			side === 'rightTable' &&
				this.setState(() => ({
					selectedRight: [...selectedRight, selectedRow]
				}))
		} else if (action === 'delete') {
			side === 'leftTable' &&
				this.setState(() => ({
					selectedLeft: selectedLeft.filter((row) => row !== selectedRow)
				}))
			side === 'rightTable' &&
				this.setState(() => ({
					selectedRight: selectedRight.filter((row) => row !== selectedRow)
				}))
		}
	}

	/**
	 * to handle confirmation
	 *
	 */
	handleConfirm = () => {
		const { nicodcta, handleSubmit } = this.props
		const { rightValues } = this.state
		const payload = {
			nicodcta: nicodcta,
			lista: rightValues
		}
		handleSubmit(payload)
	}

	render() {
		const { fieldsConfig, disableForm, t, showWarning, handleWarning } =
			this.props
		const {
			sustainsClick,
			selectedLeft,
			selectedRight,
			leftValues,
			rightValues
		} = this.state

		const fieldsTable = fieldsConfig
			? getFiltersData(fieldsConfig.campos, { agrupador: 'grupo.cent' })
			: []

		const propsTableLeft = {
			fields: leftValues,
			tableFields: fieldsTable,
			disableForm: disableForm,
			keyfield: 'nicc',
			sustainsClick: sustainsClick,
			selected: selectedLeft,
			setSelection: this.handleSelection,
			doDoubleClick: this.handleDoubleClick,
			label: 'leftTable'
		}

		const propsTableRight = {
			fields: rightValues,
			tableFields: fieldsTable,
			disableForm: disableForm,
			keyfield: 'nicc',
			sustainsClick: sustainsClick,
			selected: selectedRight,
			setSelection: this.handleSelection,
			doDoubleClick: this.handleDoubleClick,
			label: 'rightTable'
		}

		return (
			<Fragment>
				<Row onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp}>
					<Col>
						<Alert variant={'dark'}>
							<b>{t('CRUDS.ledger_accounts_data.cost_centers.available')}</b>
						</Alert>
						{leftValues && <TableWithSelection {...propsTableLeft} />}
					</Col>
					{!disableForm && (
						<Col className={'mt-5'} sm={2}>
							<Row className={'mt-5'}>
								<Col className={'text-center'}>
									<Button
										style={{ minWidth: '145px' }}
										type={'button'}
										onClick={this.handleButtonClick('toRight')}
									>
										&gt;
									</Button>
								</Col>
							</Row>
							<Row className={'mt-2'}>
								<Col className={'text-center'}>
									<Button
										style={{ minWidth: '145px' }}
										type={'button'}
										onClick={this.handleButtonClick('toLeft')}
									>
										&lt;
									</Button>
								</Col>
							</Row>
							<Row className={'mt-2'}>
								<Col className={'text-center'}>
									<Button
										style={{ minWidth: '145px' }}
										type={'button'}
										onClick={this.handleButtonClick('allRight')}
									>
										&gt;&gt;
									</Button>
								</Col>
							</Row>
							<Row className={'mt-2'}>
								<Col className={'text-center'}>
									<Button
										style={{ minWidth: '145px' }}
										type={'button'}
										onClick={this.handleButtonClick('allLeft')}
									>
										&lt;&lt;
									</Button>
								</Col>
							</Row>
							<Row className={'mt-5'}>
								<Col className={'text-center'}>
									<Button
										style={{ minWidth: '145px' }}
										type={'button'}
										onClick={this.handleConfirm}
									>
										{t('global.confirm')}
									</Button>
								</Col>
							</Row>
						</Col>
					)}
					<Col>
						<Alert variant={'dark'}>
							<b>{t('CRUDS.ledger_accounts_data.cost_centers.assigned')}</b>
						</Alert>
						{rightValues && <TableWithSelection {...propsTableRight} />}
					</Col>
				</Row>
				<ConfirmModalNoButton
					modalTitle={t('CRUDS.modal.title_warning')}
					messageBody={t('CRUDS.modal.message_changes')}
					showModal={showWarning}
					handleCloseModal={() => handleWarning(false, 'close')}
					handleCancelButton={() => {
						handleWarning(false, 'cancel')
					}}
					handleSubmitButton={(e: any) => {
						this.handleConfirm()
						handleWarning(false, 'submit')
					}}
				/>
			</Fragment>
		)
	}
}

export default withTranslation()(DynamicTable)
