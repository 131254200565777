import {
	CLEAR_EQUIVALENT_PRODUCT_STORE,
	DELETE_EQUIVALENT_PRODUCT_GROUP,
	DELETE_EQUIVALENT_PRODUCT_GROUP_SUCCESS,
	GET_EQUIVALENT_PRODUCTS,
	GET_EQUIVALENT_PRODUCTS_SUCCESS,
	GET_EQUIVALENT_PRODUCT_GROUP,
	GET_EQUIVALENT_PRODUCT_GROUP_SUCCESS,
	SET_EQUIVALENT_PRODUCT_GROUP,
	SET_EQUIVALENT_PRODUCT_GROUP_SUCCESS,
	SET_EQUIVALENT_PRODUCT_SELECTION,
	SET_EQUIVALENT_PRODUCT_SELECTION_SUCCESS
} from 'constants/ActionsTypes'
import {
	IDeleteEquivalentProductGroup,
	IGetEquivalentProductGroupResponse,
	IGetEquivalentProducts,
	IGetEquivalentProductsResponseArray,
	ISetEquivalentProductGroup,
	ISetEquivalentProductSelection
} from 'models/EquivalentProducts'
import { IResponseResult } from 'models/ResultsInterface'

export const getEquivalentProducts = (payload: IGetEquivalentProducts) => {
	return {
		type: GET_EQUIVALENT_PRODUCTS,
		payload
	}
}

export const getEquivalentProductsSuccess = (
	payload: IGetEquivalentProductsResponseArray
) => {
	return {
		type: GET_EQUIVALENT_PRODUCTS_SUCCESS,
		payload
	}
}

export const setEquivalentProductSelection = (
	payload: ISetEquivalentProductSelection
) => {
	return {
		type: SET_EQUIVALENT_PRODUCT_SELECTION,
		payload
	}
}

export const setEquivalentProductSelectionSuccess = (
	payload: IResponseResult
) => {
	return {
		type: SET_EQUIVALENT_PRODUCT_SELECTION_SUCCESS,
		payload
	}
}

export const setEquivalentProductGroup = (
	payload: ISetEquivalentProductGroup
) => {
	return {
		type: SET_EQUIVALENT_PRODUCT_GROUP,
		payload
	}
}

export const setEquivalentProductGroupSuccess = (payload: IResponseResult) => {
	return {
		type: SET_EQUIVALENT_PRODUCT_GROUP_SUCCESS,
		payload
	}
}

export const deleteEquivalentProductGroup = (
	payload: IDeleteEquivalentProductGroup
) => {
	return {
		type: DELETE_EQUIVALENT_PRODUCT_GROUP,
		payload
	}
}

export const deleteEquivalentProductGroupSuccess = (
	payload: IResponseResult
) => {
	return {
		type: DELETE_EQUIVALENT_PRODUCT_GROUP_SUCCESS,
		payload
	}
}

export const getEquivalentProductGroup = (payload: number) => {
	return {
		type: GET_EQUIVALENT_PRODUCT_GROUP,
		payload
	}
}

export const getEquivalentProductGroupSuccess = (
	payload: IGetEquivalentProductGroupResponse
) => {
	return {
		type: GET_EQUIVALENT_PRODUCT_GROUP_SUCCESS,
		payload
	}
}
export const clearEquivalentProductsStore = () => {
	return { type: CLEAR_EQUIVALENT_PRODUCT_STORE }
}
