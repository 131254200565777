import Axios from 'axios'
import { downloadFileType } from 'lib/Utils'
import {
	IGetDetailVoucherHeadboardParams,
	IGetDetailVoucherInventoryParams,
	IGetDetailVoucherItemsParams,
	IGetDetailVoucherTreasuryParams,
	IGetInventorySerieDataParams,
	IGetItemCategoriesParams,
	IParamsFilters,
	ISearchVoucherParams
} from 'models/SearchVoucher'

const urlBase = 'cons_comprob'

export const searchVoucherApi = async (params: ISearchVoucherParams = {}) => {
	const response = await Axios.post(`${urlBase}/buscar`, params)
	return response.data
}

export const getDetailVoucherHeadboardApi = async (
	params: IGetDetailVoucherHeadboardParams
) => {
	const response = await Axios.get(`${urlBase}/cabecera`, { params })
	return response.data
}

export const getDetailVoucherItemsApi = async (
	params: IGetDetailVoucherItemsParams
) => {
	const response = await Axios.get(`${urlBase}/items`, { params })
	return response.data
}

export const getDetailVoucherTaxesApi = async (params: any) => {
	const response = await Axios.get(`${urlBase}/impuestos`, { params })
	return response.data
}

export const getDetailVoucherAffectationsApi = async (params: any) => {
	const response = await Axios.get(`${urlBase}/afectaciones`, { params })
	return response.data
}

export const getDetailVoucherSeatApi = async (params: any) => {
	const response = await Axios.get(`${urlBase}/asiento`, { params })
	return response.data
}

export const getDetailTaxSummaryApi = async (params: any) => {
	const response = await Axios.get(`${urlBase}/resumen_impuestos`, { params })
	return response.data
}

export const getFilterModulesApi = async () => {
	const response = await Axios.get('tablas/FiltroModulos')
	return response.data
}

export const getFilterVouchersApi = async (params: IParamsFilters) => {
	const response = await Axios.get('tablas/FiltroComprobantes', {
		params: { modulo: params.module }
	})
	return response.data
}
export const getFilterBranchesApi = async (params: any) => {
	const response = await Axios.get('tablas/FiltroSucursal', { params })
	return response.data
}
export const getFilterAttributesApi = async (params: IParamsFilters) => {
	const response = await Axios.get('tablas/Atributos', {
		params: { modulo: params.module }
	})
	return response.data
}
export const getFilterPaymentConditionsApi = async (params: IParamsFilters) => {
	const response = await Axios.get('tablas/FiltroCondOpe', {
		params: { modulo: params.module }
	})
	return response.data
}
export const getFilterSellersApi = async () => {
	const response = await Axios.get('tablas/FiltroVendedores')
	return response.data
}
export const getFilterCurrencyApi = async () => {
	const response = await Axios.get('tablas/FiltroMonedas')
	return response.data
}

export const getFilterFundAccountTypesApi = async () => {
	const response = await Axios.get('tablas/FiltroTiposCFondos')
	return response.data
}

export const getFilterUsersApi = async () => {
	const response = await Axios.get('tablas/FiltroUsuarios')
	return response.data
}

export const getFilterTypeAccountingApi = async () => {
	const response = await Axios.get('tablas/FiltroTiposAsto')
	return response.data
}

export const getFilterDepositsApi = async () => {
	const response = await Axios.get('tablas/FiltroDepositos')
	return response.data
}

export const getOrderOptionsApi = async () => {
	const response = await Axios.get('tablas/OrdenCbt')
	return response.data
}

export const getOrderDirOptionsApi = async () => {
	const response = await Axios.get('tablas/OrdenDireccion?default=D')
	return response.data
}

export const getItemCategoriesApi = async (
	params: IGetItemCategoriesParams
) => {
	const response = await Axios.get('tablas/FiltroCbtCatItem', { params })
	return response.data
}

export const cancelVoucherApi = async (params: IGetItemCategoriesParams) => {
	const response = await Axios.post(`${urlBase}/anular`, params) // Pendiente api cancel
	return response.data
}

export const removeVoucherApi = async (params: IGetItemCategoriesParams) => {
	const response = await Axios.post(`${urlBase}/eliminar`, params) // Pendiente api remove
	return response.data
}

export const printVoucherDetailApi = async (
	params: IGetItemCategoriesParams
) => {
	const response = await Axios.get(`${urlBase}/imprimir`, {
		params,
		responseType: 'blob'
	})

	downloadFileType(response, 'Detalle Comprobante.pdf') // Pendiente nombre del archivo
	return response.data
}

export const getDetailTreasurySummaryApi = async (
	params: IGetDetailVoucherTreasuryParams
) => {
	const response = await Axios.get(`${urlBase}/tesoreria`, { params })
	return response.data
}

export const getDetailInventorySummaryApi = async (
	params: IGetDetailVoucherInventoryParams
) => {
	const response = await Axios.get(`${urlBase}/inventarios`, { params })
	return response.data
}

export const getInventorySerieDataApi = async (
	params: IGetInventorySerieDataParams
) => {
	const response = await Axios.get(`${urlBase}/series`, { params })
	return response.data
}

export const getFilterVoucherCategory = async (params: any) => {
	const response = await Axios.get(
		`/tablas/CatComprobante?incluye_todos=${params.optionAll}&modulo=${params.module}`
	)
	return response.data
}

export const getFilterVoucherNames = async (params: any) => {
	const response = await Axios.get(
		`/tablas/NombreComprob?comprobante=${params.comprobante}`
	)
	return response.data
}
