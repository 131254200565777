import { faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ButtonFile from 'components/common/ButtonFile'
import DragDropFile from 'components/common/DragDropFile'
import React, { ChangeEvent, Component } from 'react'
import _ from 'underscore'
import { styles } from './DragFileInputStyles'
import { IDragFileInputProps, IDragFileInputState } from './DragFileInputTypes'

class DragFileInput extends Component<
	IDragFileInputProps,
	IDragFileInputState
> {
	constructor(props: IDragFileInputProps) {
		super(props)
		this.state = {
			messageUploadFile: '',
			titleFile: '',
			iconStatus: null
		}
	}

	componentDidUpdate(prevProps: IDragFileInputProps) {
		const { stateDragDrop, classes } = this.props
		let iconStatus, messageUploadFile
		if (!_.isEqual(stateDragDrop, prevProps.stateDragDrop)) {
			if (stateDragDrop.success) {
				iconStatus = (
					<FontAwesomeIcon
						icon={faCheckCircle}
						className={classes.icon_success}
					/>
				)
				messageUploadFile = (
					<div style={{ fontWeight: 'bold' }}>{stateDragDrop.message}</div>
				)
			} else if (stateDragDrop.error) {
				iconStatus = (
					<FontAwesomeIcon icon={faBan} className={classes.icon_error} />
				)
				messageUploadFile = (
					<p style={{ fontWeight: 'bold' }}>{stateDragDrop.message}</p>
				)
			}

			if (!stateDragDrop.success && !stateDragDrop.error) {
				iconStatus = null
				messageUploadFile = null
			}

			this.setState({
				iconStatus,
				messageUploadFile
			})
		}
	}

	handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
		const { setFileUpload } = this.props

		if (event && !_.isEmpty(event.target.files)) {
			setFileUpload(event.target.files)
		}
	}

	render() {
		const {
			id = 'drag-file-input',
			setFileUpload,
			stateDragDrop,
			infoMessage = { title: '', body: <div></div> },
			acceptedFiles,
			disabled,
			titleButtonUpload = 'Examinar',
			className,
			defaultText = true,
			classes
		} = this.props

		const { messageUploadFile, titleFile, iconStatus } = this.state

		return (
			<DragDropFile
				id={id}
				actionSetFile={setFileUpload}
				displayStyle={stateDragDrop}
				acceptedFiles={acceptedFiles}
				disabled={false}
				className={`${className}`}
				style={{ border: '100%' }}
			>
				<Grid
					className={classes.info_content}
					container
					direction="column"
					justifyContent="center"
					alignItems="center"
				>
					<Grid item xs={12}>
						<h2>{infoMessage?.title}</h2>

						{stateDragDrop.success ? (
							<>
								<Grid item>{iconStatus}</Grid>
								{messageUploadFile}
							</>
						) : (
							<div>
								{iconStatus === null || disabled ? (
									<>
										{infoMessage?.body}
										{defaultText && (
											<p>
												<span>Arrastre o seleccione&nbsp;</span> el archivo
												desde su computadora
											</p>
										)}
									</>
								) : (
									<>
										<Grid item>{iconStatus}</Grid>
										<p className={classes.text_message_short}>{titleFile}</p>
										{messageUploadFile}
									</>
								)}
							</div>
						)}
					</Grid>
					<Grid item>
						<ButtonFile
							id={`${id}-upload-button`}
							buttonLabel={titleButtonUpload}
							classButton="contained"
							color="primary"
							actionUpload={this.handleUploadFile}
						/>
					</Grid>
				</Grid>
			</DragDropFile>
		)
	}
}
export default withStyles(styles, { withTheme: true })(DragFileInput)
