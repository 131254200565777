import {
	cancelVoucherNumberSuccess,
	getAffectationVoucherByUserSuccess,
	getConfigVoucherSuccess,
	getVoucherNumberSuccess,
	getVoucherTypeByUserSuccess,
	getVoucherTypeSuccess,
	saveVouchersStyleConfigByUserSuccess,
	voucherCancelSuccess,
	voucherSaveAndNewSuccess
} from 'actions'
import { getConfigVoucher } from 'api/Voucher'
import {
	cancelVoucherNumberApi,
	getAffectationVoucherApi,
	getVoucherNumberApi,
	getVoucherType,
	getVoucherTypeByUser,
	saveVouchersStyleConfigByUser,
	voucherCancel,
	voucherSave
} from 'api/VoucherType'
import {
	CANCEL_VOUCHER_NUMBER,
	GET_AFFECTATION_VOUCHER_BY_USER,
	GET_VOUCHER_NUMBER,
	GET_VOUCHER_TYPE,
	GET_VOUCHER_TYPE_BY_USER,
	NEW_VOUCHER_TYPE,
	SAVE_CONFIG_STYLE_VOUCHER_TYPE_BY_USER,
	VOUCHER_CANCEL,
	VOUCHER_SAVE_AND_NEW
} from 'constants/ActionsTypes'
import { getUrl } from 'lib/BreadCrumbsUtils'
import {
	all,
	call,
	debounce as deb,
	fork,
	put,
	takeLatest
} from 'redux-saga/effects'

function* getVoucherTypeRequest({ payload }) {
	try {
		const type = yield call(getVoucherType, payload)
		const config = yield call(getConfigVoucher, {
			idOperacion: type.idOperacion
		})

		yield put(getVoucherTypeSuccess(type))
		yield put(getConfigVoucherSuccess(config))
	} catch (error) {}
}

function* getVoucherTypeByUserRequest({ payload }) {
	try {
		const voucherTypes = yield call(getVoucherTypeByUser, payload)
		yield put(getVoucherTypeByUserSuccess(voucherTypes))
	} catch (error) {
		// console.log('algun error', error)
	}
}

function* getAffectationVoucherByUserRequest({ payload }) {
	try {
		const voucherTypes = yield call(getAffectationVoucherApi, payload)
		yield put(getAffectationVoucherByUserSuccess(voucherTypes))
	} catch (error) {
		// console.log('algun error', error)
	}
}

function* voucherCancelRequest({ payload }) {
	try {
		const cancel = yield call(voucherCancel, payload)
		yield put(voucherCancelSuccess(cancel))
	} catch (error) {}
}

function* voucherSaveAndNewRequest({ payload }) {
	try {
		const voucher = yield call(voucherSave, payload)
		yield put(voucherSaveAndNewSuccess(voucher))
	} catch (error) {}
}

function* saveVoucherTypeStyleByUserRequest({ payload }) {
	try {
		const voucher = yield call(saveVouchersStyleConfigByUser, payload)
		yield put(saveVouchersStyleConfigByUserSuccess(voucher))
	} catch (error) {}
}

function* newVoucherTypeRequest({ payload }) {
	try {
		const { idComprobante, idEmpresa, history } = payload
		const voucherType = yield call(getVoucherType, { idComprobante, idEmpresa })
		const urlForm = getUrl(
			voucherType.procesos[0].cod_proceso,
			voucherType.idOperacion
		)
		yield put(getVoucherTypeSuccess(voucherType))
		yield put(history.push(urlForm))
	} catch (error) {}
}

function* getVoucherNumberRequest({ payload }) {
	try {
		const number = yield call(getVoucherNumberApi, payload)
		yield put(getVoucherNumberSuccess(number))
	} catch (error) {}
}

function* cancelVoucherNumberRequest({ payload }) {
	try {
		const cancel = yield call(cancelVoucherNumberApi, payload)
		yield put(cancelVoucherNumberSuccess(cancel))
	} catch (error) {}
}

export function* getVoucherTypeSaga() {
	yield takeLatest(GET_VOUCHER_TYPE, getVoucherTypeRequest)
}

export function* getVoucherTypeByUserSaga() {
	yield takeLatest(GET_VOUCHER_TYPE_BY_USER, getVoucherTypeByUserRequest)
}

export function* voucherCancelSaga() {
	yield takeLatest(VOUCHER_CANCEL, voucherCancelRequest)
}

export function* voucherSaveAndNewSaga() {
	yield takeLatest(VOUCHER_SAVE_AND_NEW, voucherSaveAndNewRequest)
}

export function* saveVoucherTypeStyleByUserSaga() {
	yield deb(
		2000,
		SAVE_CONFIG_STYLE_VOUCHER_TYPE_BY_USER,
		saveVoucherTypeStyleByUserRequest
	)
}

export function* newVoucherTypeSaga() {
	yield deb(2000, NEW_VOUCHER_TYPE, newVoucherTypeRequest)
}

export function* getAffectationVoucherByUserSaga() {
	yield takeLatest(
		GET_AFFECTATION_VOUCHER_BY_USER,
		getAffectationVoucherByUserRequest
	)
}

export function* getVoucherNumberSaga() {
	yield takeLatest(GET_VOUCHER_NUMBER, getVoucherNumberRequest)
}

export function* cancelVoucherNumberSaga() {
	yield takeLatest(CANCEL_VOUCHER_NUMBER, cancelVoucherNumberRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getVoucherTypeSaga),
		fork(getVoucherTypeByUserSaga),
		fork(voucherCancelSaga),
		fork(voucherSaveAndNewSaga),
		fork(saveVoucherTypeStyleByUserSaga),
		fork(newVoucherTypeSaga),
		fork(getAffectationVoucherByUserSaga),
		fork(getVoucherNumberSaga),
		fork(cancelVoucherNumberSaga)
	])
}
