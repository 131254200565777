import { Button } from '@material-ui/core'
import React from 'react'
import { useStyles } from './ButtonFileStyles'
import { ButtonFileProps } from './ButtonFileTypes'

const ButtonFile = ({
	id,
	buttonLabel,
	classButton,
	color,
	actionUpload,
	disabled
}: ButtonFileProps) => {
	const classes = useStyles()

	return (
		<>
			<input
				onChange={(e) => {
					if (actionUpload) {
						actionUpload(e)
					}
				}}
				className={classes.input}
				id={id}
				multiple
				type="file"
				disabled={disabled}
			/>
			<label htmlFor={id}>
				<Button
					component="span"
					disabled={disabled}
					className={classes.button}
					variant={classButton}
					color={color}
				>
					{buttonLabel}
				</Button>
			</label>
		</>
	)
}

export default ButtonFile
