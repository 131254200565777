import { getEquivalentProducts, getFilterProduct } from 'actions'
import ConfirmModal from 'components/common/ConfirmModal'
import NotificationError from 'components/common/notificationsErrors'
import { Form, Formik, FormikProps } from 'formik'
import { getValidationSchema } from 'lib/FieldValidations'
import { ICrudCompleteFields } from 'models/CrudInterface'
import {
	IGetEquivalentProductGroupResponse,
	IGetEquivalentProducts,
	ISetEquivalentProductGroup
} from 'models/EquivalentProducts'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import EquivalentProductsFields from './EquivalentProductsFields'

export interface IEquivalentProductsFormProps extends WithTranslation {
	getFilterProduct: (payload: any) => void
	getEquivalentProducts: (payload: IGetEquivalentProducts) => void
	productGroup: IGetEquivalentProductGroupResponse
	handleProductChange: (niprod: number) => void
	handleFilterChange: (values: any) => void
	handleGroupConfirm: (payload: ISetEquivalentProductGroup) => void
	handleGroupDelete: (niref: number) => void
	handleOpenModal: () => void
	product: any
	fields: Array<ICrudCompleteFields>
	t: any
	idOperacion: number
	searchParams: IGetEquivalentProducts
	selectedProducts: any
}

interface IEquivalentProductsState {
	isSearching: boolean
}

class EquivalentProductsForm extends Component<
	IEquivalentProductsFormProps,
	IEquivalentProductsState
> {
	constructor(props: IEquivalentProductsFormProps) {
		super(props)
		this.state = {
			isSearching: false
		}
	}

	handleSearchProducts = (value: string) => {
		this.props.getFilterProduct({ busqueda: value })
		this.handleIsSearching(true)
	}

	handleIsSearching = (isSearching: boolean) => {
		this.setState({ isSearching })
	}

	render() {
		const {
			fields,
			t,
			getFilterProduct,
			handleFilterChange,
			handleGroupConfirm,
			handleOpenModal,
			selectedProducts,
			handleGroupDelete
		} = this.props
		const { isSearching } = this.state

		const validationSchema = fields.length
			? getValidationSchema(fields, t, [], [])
			: {}

		const mainFields = fields.filter(
			(field) => field.agrupador === 'formulario'
		)
		const filterFields = fields.filter((field) => field.agrupador === 'filtros')

		return (
			<>
				<Formik
					initialValues={{}}
					onSubmit={(values, actions) => {
						handleFilterChange(values)
					}}
					enableReinitialize={true}
					validationSchema={validationSchema}
				>
					{(props: FormikProps<any>) => (
						<Fragment>
							<Col sm={12}>
								<NotificationError
									errors={props.errors}
									touched={props.touched}
								/>
							</Col>
							<Form>
								<Row>
									<Col sm={6}>
										{!_.isEmpty(mainFields) &&
											_.map(mainFields, (field) => {
												return (
													<EquivalentProductsFields
														field={field}
														formikProps={props}
														crudProps={this.props}
														searchProduct={this.handleSearchProducts}
														isSearching={isSearching}
														handleIsSearching={this.handleIsSearching}
													/>
												)
											})}
									</Col>
									<Col sm={6}>
										{!_.isEmpty(filterFields) &&
											_.map(filterFields, (field) => {
												return (
													<EquivalentProductsFields
														field={field}
														formikProps={props}
														crudProps={this.props}
														searchProduct={getFilterProduct}
														isSearching={isSearching}
														handleIsSearching={this.handleIsSearching}
														handleOpenModal={handleOpenModal}
														selectedProducts={selectedProducts}
													/>
												)
											})}
									</Col>
								</Row>
								<Row className={'pt-3 pb-3'}>
									<Col sm={6} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px', margin: '20px' }}
											type={'button'}
											onClick={() => {
												handleGroupConfirm({
													niprod: props.values.desc_prod_ref,
													niref: props.values.niref || 0,
													nombre: props.values.nombre
												})
											}}
											disabled={
												!props.values.nombre ||
												props.values.nombre === '' ||
												!props.values.desc_prod_ref ||
												props.values.desc_prod_ref === ''
											}
										>
											{t('global.confirm')}
										</Button>
										<ConfirmModal
											messageBody={<div>{t('CRUDS.group.delete.body')}</div>}
											onSubmitModal={() => {
												handleGroupDelete(props.values.niref)
												props.resetForm({
													desc_prod_ref: props.values.desc_prod_ref
												})
											}}
											typeButton="button"
											modalTitle={t('CRUDS.group.delete.title')}
											disabled={
												!props.values.niref ||
												props.values.niref === 0 ||
												!props.values.desc_prod_ref ||
												props.values.desc_prod_ref === ''
											}
											labelButton={t('global.delete')}
											buttonStyle={{
												minWidth: '145px',
												margin: '20px'
											}}
										/>
									</Col>
									<Col sm={6} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px', margin: '20px' }}
											type={'submit'}
											disabled={
												!props.values.niref ||
												props.values.niref === 0 ||
												!props.values.desc_prod_ref ||
												props.values.desc_prod_ref === ''
											}
										>
											{t('Collection.form.buttons.search')}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					)}
				</Formik>
			</>
		)
	}
}

const mapStateToProps = ({ tableCalls, searchProducts }: any) => {
	const { product } = tableCalls
	const { selectedProducts } = searchProducts
	return {
		selectedProducts,
		product
	}
}

const connectForm = connect(mapStateToProps, {
	getFilterProduct,
	getEquivalentProducts
})(EquivalentProductsForm)

export default withTranslation()(withRouter(connectForm))
