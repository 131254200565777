import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		fileIcon: {
			height: 40,
			width: 40,
			color: '#007bff'
		}
	})
)
