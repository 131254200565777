import { ICrudGenericFields } from 'models/CrudInterface'

export const principalFields: Array<ICrudGenericFields> = [
	{
		id: 'cat_ctacont',
		type: 'combo',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'ledgerAccountsCategory',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_cta',
		type: 'withModal',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 20,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'descrip',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 100,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'tipo_imp',
		type: 'combo',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'imputationType',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'nictamayor',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		disabled: true,
		maxChars: 100,
		autoComplete: 'new-off',
		comboData: 'parentLedgerAccount',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'nictamayor_descrip',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		disabled: true,
		maxChars: 100,
		autoComplete: 'new-off',
		comboData: 'parentLedgerAccount',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'manual',
		type: 'check',
		width: 'col-sm-6',
		height: '',
		default: '0',
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'arr_saldo',
		type: 'check',
		width: 'col-sm-6',
		height: '',
		default: '0',
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'actualiza',
		type: 'check',
		width: 'col-sm-6',
		height: '',
		default: '0',
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'habilitado',
		type: 'check',
		width: 'col-sm-6',
		height: '',
		default: 1,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'ej_ini',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		disabled: true,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'ej_fin',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		disabled: true,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
