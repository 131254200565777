import NotificationMessage from 'components/common/notificationMessage'
import { P_PRINT_VOUCHER } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import { printGenerate, printVoucher } from '../../actions'
import InputButton from '../form/inputButton'
import InputText from '../form/inputText'

const buttons = [
	{
		id: 1,
		idCampo: 'visualizar',
		service: 'ver',
		ext: 'pdf'
	},
	{
		id: 2,
		idCampo: 'exportar',
		service: 'exportar',
		ext: 'csv'
	},
	{
		id: 3,
		idCampo: 'enviarcorreo',
		service: 'ceomail',
		ext: 'pdf'
	},
	{
		id: 4,
		idCampo: 'envio1',
		service: 'enviar',
		parameter: { tipo_envio: 1 }
	},
	{
		id: 5,
		idCampo: 'envio2',
		service: 'enviar',
		parameter: { tipo_envio: 2 }
	},
	{
		id: 5,
		idCampo: 'envio3',
		service: 'enviar',
		parameter: { tipo_envio: 3 }
	}
]

class PrintForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showError: false,
			errorTitle: '',
			errorMessage: '',
			type: 'success'
		}
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.printVoucher({ idOperacion })
	}

	componentDidUpdate = (prevProps) => {
		const { printgenerate } = this.props
		if (
			printgenerate !== prevProps.printgenerate &&
			printgenerate &&
			printgenerate.Resultado
		) {
			if (printgenerate.mensaje) {
				this.setState({ errorMessage: printgenerate.mensaje, showError: true })
			}
		}
	}

	handleRenderButtons = () => {
		const { t, config, printOptions } = this.props
		const rows = []
		const nameFile = printOptions ? printOptions.comprobante : ''
		if (config) {
			config.campos.forEach((button) => {
				const idButton = button.idCampo.trim()
				const configButton = _.findWhere(buttons, { idCampo: idButton })
				if (button.visible && configButton) {
					rows.push(
						<Col key={idButton}>
							<InputButton
								onClick={() => this.handlePrintVoucher({ ...button, nameFile })}
								valueButton={button.label}
							/>
						</Col>
					)
				}
			})
		}

		rows.push(
			<Col key={7}>
				<InputButton
					onClick={this.props.handleCloseModal}
					valueButton={t('finishVoucher.exit')}
				/>
			</Col>
		)

		return <Row>{rows}</Row>
	}

	handlePrintVoucher = (optButton) => {
		const idButton = optButton.idCampo.trim()
		const { nameFile } = optButton
		const { idOperacion } = this.props
		const configButton = _.findWhere(buttons, { idCampo: idButton })
		const { ext } = configButton
		const params = configButton.parameter ? configButton.parameter : {}

		if (configButton) {
			this.props.printGenerate({
				idOperacion,
				endPoint: configButton.service,
				params,
				nameFile: `${nameFile}.${ext}`
			})
		}
	}

	handleCloseNotification = () => {
		this.setState({ showError: false })
	}

	render() {
		const { config, printOptions } = this.props
		const voucherValue = printOptions ? printOptions.comprobante : null
		const fileValue = printOptions ? printOptions.ruta : null
		// console.log(printOptions)
		return (
			<Container>
				<NotificationMessage
					{...this.state}
					handleCloseError={this.handleCloseNotification}
					type={this.state.typeNotifaction}
				/>

				<Col sm={9}>
					<Row>
						<InputText
							inputFormCol={{ sm: 12 }}
							fields={config ? config.campos : []}
							label={'label'}
							inputId={'comprobante'}
							name={'comprobante'}
							placeholder={''}
							styles={{ width: '100%' }}
							colLabel={'col-sm-2'}
							colInput={'col-sm-10'}
							onBlur={() => {}}
							value={voucherValue}
							onChange={() => {}}
							divStyle={{}}
						/>
						<InputText
							inputFormCol={{ sm: 12 }}
							fields={config ? config.campos : []}
							label={'label'}
							inputId={'archivo'}
							name={'archivo'}
							placeholder={''}
							styles={{ width: '100%' }}
							colLabel={'col-sm-2'}
							colInput={'col-sm-10'}
							onBlur={() => {}}
							value={fileValue}
							onChange={() => {}}
							divStyle={{}}
						/>
					</Row>
				</Col>
				{this.handleRenderButtons()}
			</Container>
		)
	}
}

const mapStateToProps = ({ generateForm, voucher }) => {
	const { clientHeadboard, printgenerate, printOptions } = generateForm
	const config = voucher.config ? voucher.config[P_PRINT_VOUCHER] : null
	const { headInfo } = voucher
	return { clientHeadboard, headInfo, config, printgenerate, printOptions }
}

export default connect(mapStateToProps, { printVoucher, printGenerate })(
	withTranslation()(PrintForm)
)
