import { validateField } from 'lib/FieldValidations'
import { getValueUnmask } from 'lib/MaskValues'
import { debugMessage } from 'lib/Utils'
import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { connect } from 'react-redux'
import _ from 'underscore'
import styles from './inputText.module.css'

/**
 * Maps special characters from caret notation to its corresponding ASCII code
 * See: https://en.wikipedia.org/wiki/Caret_notation and https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values
 */
const caretNotationTable = [
	{
		key: ']',
		code: 'BracketRight',
		asciiValue: 29
	},
	{
		key: '@',
		asciiValue: 0
	},
	{
		key: 'A',
		asciiValue: 1,
		code: 'KeyA'
	},
	{
		key: 'B',
		asciiValue: 2,
		code: 'KeyB'
	},
	{
		key: 'C',
		asciiValue: 3,
		code: 'KeyC'
	},
	{
		key: 'D',
		asciiValue: 4,
		code: 'KeyD'
	},
	{
		key: 'E',
		asciiValue: 5,
		code: 'KeyE'
	},
	{
		key: 'F',
		asciiValue: 6,
		code: 'KeyF'
	},
	{
		key: 'G',
		asciiValue: 7,
		code: 'KeyG'
	},
	{
		key: 'H',
		asciiValue: 8,
		code: 'KeyH'
	},
	{
		key: 'I',
		asciiValue: 9,
		code: 'KeyI'
	},
	{
		key: 'J',
		asciiValue: 10,
		code: 'KeyJ'
	},
	{
		key: 'K',
		asciiValue: 11,
		code: 'KeyK'
	},
	{
		key: 'L',
		asciiValue: 12,
		code: 'KeyL'
	},
	{
		key: 'M',
		asciiValue: 13,
		code: 'KeyM'
	},
	{
		key: 'N',
		asciiValue: 14,
		code: 'KeyN'
	},
	{
		key: 'O',
		asciiValue: 15,
		code: 'KeyO'
	},
	{
		key: 'P',
		asciiValue: 16,
		code: 'KeyP'
	},
	{
		key: 'Q',
		asciiValue: 17,
		code: 'KeyQ'
	},
	{
		key: 'R',
		asciiValue: 18,
		code: 'KeyR'
	},
	{
		key: 'S',
		asciiValue: 19,
		code: 'KeyS'
	},
	{
		key: 'T',
		asciiValue: 20,
		code: 'KeyT'
	},
	{
		key: 'U',
		asciiValue: 21,
		code: 'KeyU'
	},
	{
		key: 'V',
		asciiValue: 22,
		code: 'KeyV'
	},
	{
		key: 'W',
		asciiValue: 23,
		code: 'KeyW'
	},
	{
		key: 'X',
		asciiValue: 24,
		code: 'KeyX'
	},
	{
		key: 'Y',
		asciiValue: 25,
		code: 'KeyY'
	},
	{
		key: 'Z',
		asciiValue: 26,
		code: 'KeyZ'
	},
	{
		key: '[',
		asciiValue: 27,
		code: 'BracketLeft'
	},
	{
		key: 'Escape',
		asciiValue: 27,
		code: 'Escape'
	},
	{
		key: '^',
		asciiValue: 30
	},
	{
		key: '_',
		asciiValue: 31,
		code: 'Slash'
	},
	{
		key: '?',
		asciiValue: 127
	},
	{
		key: 'Delete',
		asciiValue: 127,
		code: 'Delete'
	}
]

const UTFNotationTable = [
	{
		key: 'GS',
		utfValue: 29,
		code: 'GS',
		replacement: '|'
	}
]

class BarcodeInputText extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			showLockModal: false,
			inputValue: props.value ? props.value : '',
			configInput: this.getConfigField(props.inputId),
			requireError: this.props.showError ? this.props.showError : false,
			trueValue: null,
			bufferRead: false,
			specialCharBuffer: []
		}
	}

	componentDidUpdate = (prevProps) => {
		const { value } = this.props
		if (prevProps.value !== value) {
			this.setState({ inputValue: value })
		}
	}

	componentWillUnmount = () => {
		// console.log("se desmonta....");
	}

	_handleSearchAscii = (keyEvent) => {
		const ascii = _.findWhere(caretNotationTable, { code: keyEvent.code })
		return ascii ? ascii.asciiValue : ''
	}

	_handleReplaceUTF = (value) => {
		const UTF = _.findWhere(UTFNotationTable, { utfValue: value })
		return UTF ? UTF.replacement : ''
	}

	_handleKeyUp = (e) => {
		const { specialCharBuffer, inputValue, bufferRead } = this.state

		if (e.key === 'Alt') {
			let input = `${inputValue}`

			const newChar = this._handleReplaceUTF(Number(specialCharBuffer.join('')))

			if (newChar === '|') {
				input = input + newChar

				this.props.onChange(inputValue)
			}

			this.setState({
				bufferRead: false,
				inputValue: input
			})
		} else if (bufferRead === true && specialCharBuffer.length > 4) {
			this.setState({
				bufferRead: false,
				specialCharBuffer: []
			})
		}
	}

	_handleKeyDown = (e) => {
		const { activeListeners } = this.props
		const { bufferRead, specialCharBuffer } = this.state
		// Enter
		if (e.keyCode === 13) {
			if (this.props.handleEnterKey) {
				this.props.handleEnterKey(e, this.state.trueValue)
			}
		}
		// Alt
		if (e.key === 'Alt') {
			this.setState({ bufferRead: true, specialCharBuffer: [] })
		}

		if (activeListeners) {
			if (e.ctrlKey) {
				e.preventDefault()
				// TODO move this to debug logic and set debug type to "code scanner"
				debugMessage({
					stacktrace: 'Received key with CTRL flag: ',
					message: {
						type: e.type,
						key: e.key,
						keyCode: e.which || e.keyCode,
						value: e.target.value,
						ctrlKey: e.ctrlKey,
						altKey: e.altKey,
						shiftKey: e.shiftKey,
						metaKey: e.metaKey
					}
				})

				// prevent browser from processing this event, since some characters reported
				// by the scanner may be understood by the browser as shortcut keys (given that
				// some scanners us caret notation instead of sending real ASCII codes)

				const asciiValue = this._handleSearchAscii(e.nativeEvent)

				if (asciiValue) {
					const inputValue = `${this.state.inputValue}${String.fromCharCode(
						asciiValue
					)}`
					this.setState({ inputValue })
					this.props.onChange(inputValue)
				}
			} else {
				if (e.key !== 'Alt' && bufferRead) {
					const charBuffer = [...specialCharBuffer]
					charBuffer.push(parseInt(e.key))

					this.setState({ specialCharBuffer: charBuffer })
				}
			}
		}
	}

	handleChange = (data) => {
		const { onChange } = this.props
		const { mascara } = this.state.configInput
		const value = data && data.target ? data.target.value : data
		const trueValue = getValueUnmask(value, mascara, this.props)

		this.setState({ inputValue: value, trueValue })
		if (onChange) {
			onChange(trueValue)
		}
	}

	handleOnblur = () => {
		const { onBlur } = this.props
		const { configInput, inputValue } = this.state
		let ban = true

		if (configInput.valid && !validateField(inputValue, configInput.valid)) {
			ban = false
		}

		if (onBlur && ban) {
			onBlur(this.state.inputValue)
		} else if (onBlur) {
			onBlur()
		}

		if (configInput.requerido && !this.state.inputValue) {
			this.setState({
				requireError: true
			})
		} else if (this.state.inputValue) {
			this.setState({
				requireError: false
			})
		}
	}

	getConfigField = (id) => {
		const { fields } = this.props
		let result = {}
		if (fields) {
			fields.forEach((field) => {
				if (field.idCampo.trim() === id) {
					result = field
				}
			})
		}

		return result
	}

	renderInput = (options, config) => {
		const response = <input {...options} />

		return response
	}

	renderField = () => {
		const {
			label,
			placeholder,
			name,
			styles,
			inputId,
			id,
			colInput,
			colLabel,
			styleLabel,
			divStyle,
			disable,
			theme,
			type,
			inputFormCol,
			rowStyle,
			autoFocus,
			fwRef,
			extraCol
		} = this.props

		const classInput = label ? colInput : 'col-sm-12'
		const classLabel = label ? colLabel : ''
		const classText = disable ? theme.inputDisabled : ''
		const customType = type || 'text'
		const config = this.state.configInput
		const customStyleLabel = config.requerido
			? { ...styleLabel, color: 'red' }
			: { ...styleLabel }
		const inputStyles = this.state.requireError
			? { ...styles, border: '1px solid red' }
			: styles

		if (config.visible) {
			const optionsInput = {
				id: id || inputId,
				name: name,
				type: customType,
				style: inputStyles,
				placeholder: placeholder,
				autoFocus: autoFocus,
				disabled: !config.editable,
				className: `${theme.inputText} ${classText}`,
				value: this.state.inputValue,
				ref: config.fwRef ? config.fwRef : fwRef,
				onChange: (v) => this.handleChange(v),
				onBlur: (v) => this.handleOnblur(v),
				onKeyDown: this._handleKeyDown,
				onKeyUp: this._handleKeyUp
			}

			return (
				<Col {...inputFormCol}>
					<Row className={'form-group'} style={rowStyle}>
						<label
							className={`${theme.inputLabel} ${classLabel}`}
							style={customStyleLabel}
						>
							{config.label ? config.label : label}
						</label>
						<Col
							className={classInput}
							style={extraCol ? { marginLeft: '-4%' } : { ...divStyle }}
						>
							{this.renderInput(optionsInput, config)}
						</Col>
					</Row>
				</Col>
			)
		} else {
			return null
		}
	}

	render() {
		const { fields } = this.props
		if (fields) {
			return this.renderField()
		} else {
			return null
		}
	}
}
const mapStateToProps = ({ auth }) => {
	const { authUser } = auth
	return {
		authUser
	}
}

export default connect(mapStateToProps)(
	themr('InputTextStyle', styles)(BarcodeInputText)
)
