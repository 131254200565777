import withMenu from 'components/common/withMenu'
import GlobalContainer from 'components/layout/globalContainer'
import ProviderStateTable from 'components/providerAffectState/ProviderStateTable'
import { P_AFEC_STATE_CPA } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

class ProviderAffectState extends Component {
	handleCallBackButton = () => {
		this.loadItemsConfirmation()
	}

	render() {
		return (
			<Row>
				<GlobalContainer
					codeProcess={P_AFEC_STATE_CPA}
					nextPage
					callBackButton={this.handleCallBackButton}
				>
					<ProviderStateTable
						formConfirmation={(click) => (this.loadItemsConfirmation = click)}
					/>
				</GlobalContainer>
			</Row>
		)
	}
}

export default withTranslation()(withRouter(withMenu(ProviderAffectState)))
