import { faArrowCircleDown, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@material-ui/core'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { downloadFile, getDocumentList, removeDocument } from 'actions'
import CommonFileIcon from 'components/common/CommonFileIcon/CommonFileIcon'
import CommonLabelCol from 'components/common/CommonLabelCol'
import {
	IDownloadFileActionParams,
	IGetDocumentsParams,
	IGetDocumentsSuccessResponse,
	IRemoveDocumentParams,
	ITempFileItem
} from 'models/FileManager'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import fields from '../../../__mocks__/data/fields_attach_modal.json'
import { styles } from './AttachModalStyles'

interface IAttachModalProps extends WithTranslation, WithStyles<typeof styles> {
	authUser: any
	downloadFile: (payload: IDownloadFileActionParams) => void
	getDocumentList: (payload: IGetDocumentsParams) => void
	voucherSelected: any
	uploadFileList: IGetDocumentsSuccessResponse
	removeDocument: (payload: IRemoveDocumentParams) => void
}

interface IAttachModalState {
	fileList: Array<ITempFileItem> | Array<any>
}

class AttachModal extends Component<IAttachModalProps, IAttachModalState> {
	constructor(props: IAttachModalProps) {
		super(props)
		this.state = {
			fileList: []
		}
	}

	componentDidMount = () => {
		const { authUser, voucherSelected } = this.props
		this.props.getDocumentList({
			idEmpresa: authUser.userCompany,
			id_comprob: voucherSelected.id
		})
	}

	componentDidUpdate(prevProps: Readonly<IAttachModalProps>): void {
		const { uploadFileList } = this.props
		if (
			!_.isEqual(uploadFileList, prevProps.uploadFileList) &&
			!_.isEmpty(uploadFileList)
		) {
			this.setState({ fileList: uploadFileList.lista_arch || [] })
		}
	}

	handleDownloadFile = (file: ITempFileItem) => {
		const { authUser, voucherSelected } = this.props
		this.props.downloadFile({
			params: {
				idEmpresa: authUser.userCompany,
				id_comprob: voucherSelected.id,
				idarch: file.idarch
			},
			name: file.nombre
		})
	}

	renderPreviewFiles = () => {
		const { fileList } = this.state
		const { classes } = this.props

		return (
			<Grid container spacing={1} className={classes.fileContainer}>
				{fileList.map((file) => {
					return this.renderIconFile(file)
				})}
			</Grid>
		)
	}

	renderIconFile = (file: ITempFileItem) => {
		const [, typeFile] = file.nombre.split('.')
		const { classes } = this.props

		return (
			<Grid item key={file.idarch} className={classes.fileItem}>
				<Grid container direction="column" key={file.idarch}>
					<Grid item>
						<CommonFileIcon typeFile={typeFile} />
					</Grid>
					<Grid item>
						<Typography
							variant="subtitle2"
							component="h2"
							style={{ paddingRight: '5px' }}
						>
							{file.nombre}
						</Typography>
					</Grid>
					<Grid
						item
						container
						direction="row"
						spacing={2}
						alignItems="center"
						alignContent="center"
					>
						<Grid item xs={6} lg={6}>
							<FontAwesomeIcon
								icon={faArrowCircleDown}
								className={classes.iconTemp}
								onClick={() => this.handleDownloadFile(file)}
							/>
						</Grid>
						<Grid item xs={6} lg={6}>
							<FontAwesomeIcon
								icon={faTrash}
								className={classes.iconTemp}
								onClick={() => this.handleRemoveFile(file)}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)
	}

	handleRemoveFile = (file: ITempFileItem): void => {
		const { voucherSelected, authUser } = this.props
		this.props.removeDocument({
			id_comprob: voucherSelected.id,
			idarch: file.idarch,
			idEmpresa: authUser.userCompany
		})
	}

	render() {
		const { voucherSelected } = this.props

		if (_.isEmpty(voucherSelected)) {
			return <div></div>
		}

		return (
			<Container>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 4 }}
						fields={fields}
						labelId="date"
						value={voucherSelected.fecha}
					/>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 4 }}
						fields={fields}
						labelId="ninter"
						value={voucherSelected.nroint}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 4 }}
						fields={fields}
						labelId="comprob"
						value={voucherSelected.comprobante}
					/>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 4 }}
						fields={fields}
						labelId="descript"
						value={voucherSelected.descrip}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 4 }}
						fields={fields}
						labelId="number"
						value={voucherSelected.nro_mov}
					/>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 4 }}
						colValue={{ sm: 8 }}
						fields={fields}
						labelId="rsocl"
						value={voucherSelected.razon_social}
					/>
				</Row>
				<Row>
					<Col className="font-weight-bold">{'Archivos'}</Col>
					{this.renderPreviewFiles()}
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = ({ auth, filesManager }: any) => {
	const { authUser } = auth
	const { uploadFileList } = filesManager
	return { authUser, uploadFileList }
}

const mapDispatchToProps = {
	downloadFile,
	getDocumentList,
	removeDocument
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withTranslation()(AttachModal)))
