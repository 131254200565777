import {
	GET_TABLE_CURRENCY,
	GET_TABLE_CURRENCY_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	filterCurrencies: any
}

const initialState = {
	filterCurrencies: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case GET_TABLE_CURRENCY:
			return { ...state, filterCurrencies: null }

		case GET_TABLE_CURRENCY_SUCCESS:
			return { ...state, filterCurrencies: action.payload.lista }

		default:
			return state
	}
}

export default rootReducer
