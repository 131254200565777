import { ICrudGenericFields } from 'models/CrudInterface'

export const sellerFields: Array<ICrudGenericFields> = [
	{
		id: 'cod_ven',
		type: 'text',
		width: 'col-sm-1',
		height: '',
		default: null,
		maxChars: 5,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'nom_ven',
		type: 'text',
		width: 'col-sm-4',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'fec_ing',
		type: 'text',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'tipo_com',
		type: 'combo',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'commissionType',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'p_com',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: 'tipo_com',
			values: [
				{
					idValue: '1',
					disabled: false
				},
				{
					idValue: '2',
					disabled: true
				},
				{
					idValue: '3',
					disabled: true
				},
				{
					idValue: '4',
					disabled: true
				}
			]
		}
	},
	{
		id: 'habilitado',
		type: 'check',
		width: 'col-sm-3',
		height: '',
		default: 1,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
