import CommonLabelCol from 'components/common/CommonLabelCol'
import InputDropdown from 'components/form/inputDropdown'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import React, { Component, Fragment } from 'react'
import { Row } from 'react-bootstrap'
import _ from 'underscore'

interface InventoryResumenProps {
	fields: Array<IFieldStructure>
	inventoryData: any
	handleChangeOptions: (payload: { id: string }) => void
}

interface InventoryResumenState {
	dependentValue: string
	upwardValue: string
}

const tmpProps = [
	{
		agrupador: 'form_invent_gr',
		descrip: '',
		editable: 1,
		idCampo: 'id_ascendente',
		label: 'Ascendente',
		mascara: null,
		placeholder: null,
		requerido: 0,
		valid: null,
		visible: 1
	},
	{
		agrupador: 'form_invent_gr',
		descrip: '',
		editable: 1,
		idCampo: 'id_dependiente',
		label: 'Dependiente',
		mascara: null,
		placeholder: null,
		requerido: 0,
		valid: null,
		visible: 1
	}
]

export default class SearchVouchersInventoryResumen extends Component<
	InventoryResumenProps,
	InventoryResumenState
> {
	constructor(props: InventoryResumenProps) {
		super(props)
		this.state = {
			upwardValue: '',
			dependentValue: ''
		}
	}

	render() {
		const { fields, inventoryData } = this.props

		const propsUpward = {
			inputFormCol: { sm: 6 },
			fields: tmpProps,
			label: 'Ascendente',
			inputId: 'id_ascendente',
			name: 'id_ascendente',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			// disable: readOnly,
			// onBlur: handleBlur,
			value: '',
			options:
				inventoryData && !_.isEmpty(inventoryData.id_ascendente)
					? inventoryData.id_ascendente.map((opt: any) => ({
							id: opt.id,
							label: opt.descrip
					  }))
					: [],
			noInitialExecute: true,
			onChange: (data: any) => {
				const { value } = data.target
				this.setState({ upwardValue: value })
				this.props.handleChangeOptions({ id: value })
			}
		}

		const propsDependent = {
			inputFormCol: { sm: 6 },
			fields: tmpProps,
			label: 'Dependiente',
			inputId: 'id_dependiente',
			name: 'id_dependiente',
			placeholder: '',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			// disable: readOnly,
			// onBlur: handleBlur,
			value: '',
			options:
				inventoryData && !_.isEmpty(inventoryData.id_dependiente)
					? inventoryData.id_dependiente.map((opt: any) => ({
							id: opt.id,
							label: opt.descrip
					  }))
					: [],
			noInitialExecute: true,
			onChange: (data: any) => {
				const { value } = data.target
				this.setState({ dependentValue: value })
				this.props.handleChangeOptions({ id: value })
			}
		}

		return (
			<Fragment>
				<Row>
					<CommonLabelCol
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="desc_comp"
						value={inventoryData.desc_comp ? `${inventoryData.desc_comp}` : ''}
					/>
					<CommonLabelCol
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="cod_comp"
						value={inventoryData.cod_comp ? `${inventoryData.cod_comp}` : ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="nro_int"
						value={inventoryData.nro_int ? `${inventoryData.nro_int}` : ''}
					/>
					<CommonLabelCol
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="fecha"
						value={inventoryData.fecha ? `${inventoryData.fecha}` : ''}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="rsoc"
						value={inventoryData.rsoc ? `${inventoryData.rsoc}` : ''}
					/>
				</Row>
				<Row>
					<InputDropdown {...propsUpward} />
					<InputDropdown {...propsDependent} />
				</Row>
			</Fragment>
		)
	}
}
