import Chip from '@material-ui/core/Chip'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Clear } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		chip: {
			borderRadius: 8,
			color: 'black',
			fontWeight: 'bold',
			margin: '1px',
			backgroundColor: 'rgb(27, 187, 233)',
			'&:focus': {
				backgroundColor: 'rgb(27, 187, 233)'
			},
			'& .MuiChip-deleteIcon': {
				color: 'black',
				'&:hover': {
					color: '#fff'
				}
			}
		}
	})
)

interface CommonChipProps {
	label?: string
	handleDelete?: (event: any) => void
}

const CommonChip = ({
	label = 'Custom Chip',
	handleDelete
}: CommonChipProps) => {
	const classes = useStyles()

	const handleInternalDelete = (event: any) => {
		handleDelete && handleDelete(event)
	}

	return (
		<Chip
			label={label}
			onDelete={handleInternalDelete}
			className={classes.chip}
			deleteIcon={<Clear />}
		/>
	)
}

export default CommonChip
