import Axios from 'axios'
import {
	ICrudsApi,
	IGetAbmCode,
	IGetAllCruds,
	IGetConfigFieldsCrud
} from 'models/CrudInterface'

export const getConfigFieldsCrud = async (params: IGetConfigFieldsCrud) => {
	const response = await Axios.get('/abm/camposform', { params })
	return response.data
}

export const getAllCruds = async (params: IGetAllCruds) => {
	const response = await Axios.get('/usuario/abm', { params })
	return response.data
}

export const getAbmCodeApi = async (paramsApi: IGetAbmCode) => {
	const { arrow, urlApi, params } = paramsApi
	const response = await Axios.get(`/${urlApi}/${arrow}`, { params })
	return response.data
}

export async function crudsGetList(paramsApi: ICrudsApi) {
	const { urlApi, action, params, method } = paramsApi
	let response
	method === 'post'
		? (response = await Axios.post(`/${urlApi}/${action}`, params))
		: (response = await Axios.get(`/${urlApi}/${action}`, { params }))
	return response.data
}

export const crudsCreate = async (paramsApi: ICrudsApi) => {
	const { urlApi, action, params } = paramsApi
	const response = await Axios.post(`/${urlApi}/${action}`, params)
	return response.data
}

export const crudsGetSingle = async (paramsApi: ICrudsApi) => {
	const { urlApi, action, params } = paramsApi
	const response = await Axios.get(`/${urlApi}/${action}`, { params })
	return response.data
}

export const crudsDelete = async (paramsApi: ICrudsApi) => {
	const { urlApi, action, params } = paramsApi
	const { id, param } = params
	const response = await Axios.post(`/${urlApi}/${action}?${id}=${param}`)
	return response.data
}

export const crudsClone = async (paramsApi: ICrudsApi) => {
	const { urlApi, action, params } = paramsApi
	const { id, param } = params
	const response = await Axios.post(`/${urlApi}/${action}?${id}=${param}`)
	return response.data
}
