import { InventoryExpensesStockActions } from 'constants/ActionsTypesTs'
import { IGetDepositsParams } from 'models/AutomaticPurchase'
import {
	IConfirmExpensesPickAction,
	IConfirmExpensesPickPayload,
	IConfirmExpensesPickSuccessAction,
	IGetCufPickExpensesAction,
	IGetCufPickExpensesSuccessAction,
	IGetDepositsPickExpensesAction,
	IGetDepositsPickExpensesSuccessAction
} from 'models/InventoryExpensesStock'
import {
	IGetCufPickStockParams,
	IGetCufPickStockResponse,
	IGetDepositsPickStockResponse
} from 'models/InventoryPickStock'
import { IResponseResult } from 'models/ResultsInterface'

export const getDepositsPickExpenses = (
	payload: IGetDepositsParams
): IGetDepositsPickExpensesAction => ({
	type: InventoryExpensesStockActions.GET_DEPOSITS_PICK_EXPENSES,
	payload
})

export const getDepositsPickExpensesSuccess = (
	payload: IGetDepositsPickStockResponse
): IGetDepositsPickExpensesSuccessAction => ({
	type: InventoryExpensesStockActions.GET_DEPOSITS_PICK_EXPENSES_SUCCESS,
	payload
})

export const getCufsPickExpenses = (
	payload: IGetCufPickStockParams
): IGetCufPickExpensesAction => ({
	type: InventoryExpensesStockActions.GET_CUF_PICK_EXPENSES,
	payload
})

export const getCufsPickExpensesSuccess = (
	payload: IGetCufPickStockResponse
): IGetCufPickExpensesSuccessAction => ({
	type: InventoryExpensesStockActions.GET_CUF_PICK_EXPENSES_SUCCESS,
	payload
})

export const confirmExpensesPick = (
	payload: IConfirmExpensesPickPayload
): IConfirmExpensesPickAction => ({
	type: InventoryExpensesStockActions.CONFIRM_INVENTORY_PICK_STOCK,
	payload
})

export const confirmExpensesPickSuccess = (
	payload: IResponseResult
): IConfirmExpensesPickSuccessAction => ({
	type: InventoryExpensesStockActions.CONFIRM_INVENTORY_PICK_STOCK_SUCCESS,
	payload
})
