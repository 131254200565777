import withMenu from 'components/common/withMenu'
import IndividualLoadItemsForm from 'components/individualLoadItems/IndividualLoadItemsForm'
import GlobalContainer from 'components/layout/globalContainer'
import { P_CARGAINDIV_VTA } from 'constants/ConfigProcessNames'
import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class IndividualLoadItems extends PureComponent {
	getShortcuts = () => {
		const shortcuts = [
			{
				hotkey: { charCode: '65', modifiers: ['insert'] },
				action: this.addToCart,
				name: 'Save',
				description: 'Save a file'
			},
			{
				hotkey: { charCode: '65', modifiers: ['home'] },
				action: this.homeFocus,
				name: 'Focus',
				description: 'Focus init'
			},
			{
				hotkey: {
					charCode: '40',
					modifiers: ['arrowDown', 'arrowUp' /*, "arrowLeft", "arrowRight", */]
				},
				action: this.moveArrow,
				name: 'Arrow move',
				description: 'Arrow move'
			}
		]

		return shortcuts
	}

	getRowParams = (event) => {
		const params = event.target.id.split('-')
		const rowId = parseInt(params[1])
		const field = params[0]

		return { rowId, field }
	}

	moveArrow = async (e) => {
		if (e.target.id) {
			const { rowId, field } = this.getRowParams(e)
			this.handleMoveArrow({ rowId, field, key: e.code })
		}
	}

	homeFocus = async () => {
		// this.handleFocus();
	}

	getRowParams = (event) => {
		const params = event.target.id.split('-')
		const field = params[0]
		return field
	}

	addToCart = async (e) => {
		if (e.target.id) {
			const field = this.getRowParams(e)
			this.preventAddToCart(field)
		}
	}

	handleCallBackButton = () => {
		this.loadItemsConfirmation()
	}

	render() {
		const { config } = this.props
		return (
			<Row>
				<GlobalContainer
					codeProcess={P_CARGAINDIV_VTA}
					shoppingCartProcess={P_CARGAINDIV_VTA}
					shortcuts={this.getShortcuts()}
					callBackButton={this.handleCallBackButton}
					nextPage
				>
					<IndividualLoadItemsForm
						config={config}
						setClick={(click) => (this.addRowToCart = click)}
						homeFocus={(click) => (this.handleFocus = click)}
						formConfirmation={(click) => (this.loadItemsConfirmation = click)}
						preventAddToCart={(click) => (this.preventAddToCart = click)}
						handleMoveArrow={(click) => (this.handleMoveArrow = click)}
					/>
				</GlobalContainer>
			</Row>
		)
	}
}

const mapStateToProps = ({ voucher }) => {
	const config = voucher.config ? voucher.config[P_CARGAINDIV_VTA] : null

	return { config }
}

export default connect(
	mapStateToProps,
	{}
)(withRouter(withMenu(IndividualLoadItems)))
