import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getPriceAddCart, getPriceByProduct, searchAutoProducts } from 'actions'
import InputAutocomplete from 'components/form/inputAutocomplete'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import StockPopup from 'components/Stock/stockPopup'
import StockProvider from 'components/stockProvider/stockProvider'
import React, { PureComponent } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'

const noRenderInputs = ['sig_proceso', 'fin_item', 'modif_pcio', 'ind_stock']
class IndividualSearchProduct extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			auoptions: [],
			productInfo: {},
			codUnidOptions: [],
			productValue: '',
			showStock: false,
			stockProviderModal: false,
			referenceItem: null
		}
		this.inputRefs = {}
		this.firstRefs = React.createRef()
	}

	componentDidMount = () => {
		this.props.preventAddToCart(this.preventAddToCart)
		this.preventAddToCart = this.preventAddToCart.bind(this)
		this.setInitFocus()
	}

	componentDidUpdate(prevProps) {
		const {
			search,
			productPrice,
			confirmItem,
			providerItemsAdd,
			itemsCartProvider
		} = this.props

		if (prevProps.confirmItem !== confirmItem && confirmItem) {
			// console.log(confirmItem);
			if (confirmItem.solic_stock === 1) {
				this.setState({ showStock: true, referenceItem: confirmItem.it })
			}
			this.setInitFocus()
		}

		if (
			itemsCartProvider !== prevProps.itemsCartProvider &&
			itemsCartProvider
		) {
			if (itemsCartProvider.solic_stock === 1) {
				this.setState({
					stockProviderModal: true,
					referenceItem: itemsCartProvider.it
				})
			}
			this.setInitFocus()
		}

		if (prevProps.providerItemsAdd !== providerItemsAdd && providerItemsAdd) {
			this.setInitFocus()
		}

		if (productPrice !== prevProps.productPrice && productPrice) {
			this.handleSetNetoValue(productPrice.prod_pcio_vta)
		}

		if (search !== prevProps.search && search && search.productos) {
			const auoptions = search.productos.map((product) => ({
				id: product.niprod,
				label: product.desc_prod
			}))
			this.setState({ auoptions })
		}
	}

	/**
	 * to set foco in the search input
	 */
	setInitFocus = () => {
		this.firstRefs.current.focus() // Focus in the search table
	}

	preventAddToCart = (field) => {
		const { productInfo } = this.state

		if (productInfo.cantidad) {
			this.handlePreventClickAddCart()
		}
	}

	handleSearch = (search) => {
		const { idOperacion } = this.props
		this.props.searchAutoProducts({
			idOperacion,
			desc_prod: search,
			page_number: 0,
			page_size: 10
		})
	}

	handleSelect = (productSelected) => {
		const { search } = this.props

		if (productSelected.length > 0) {
			const product = _.findWhere(search.productos, {
				niprod: productSelected[0].id
			})
			const options =
				product && product.presentaciones
					? product.presentaciones.map((pre) => [
							pre.cod_pres,
							{ id: pre.cod_pres, label: pre.desc_pres }
					  ])
					: []
			const optionsMapArr = new Map(options)
			const codUnidOptions = [...optionsMapArr.values()]

			this.setState({
				codUnidOptions,
				productInfo: { ...product },
				auoptions: []
			})
		} else {
			this.setState({ codUnidOptions: [], productInfo: {}, auoptions: [] })
		}
	}

	handleChange = (product) => {
		// console.log(product, "on change");
	}

	/**
	 * to render all search inputs
	 * @returns render inputs
	 */
	renderSearchInputs = () => {
		const { config } = this.props
		const result = []

		if (config) {
			config.campos.forEach((field, index) => {
				const fieldId = field.idCampo.trim()
				if (
					field.editable &&
					field.visible &&
					!noRenderInputs.includes(fieldId)
				) {
					if (fieldId === 'desc_prod') {
						result.push(
							<Col
								lg={3}
								md={3}
								sm={4}
								xs={12}
								className={'pr-0 pl-0 '}
								key={index}
							>
								{this.renderInput(field)}
							</Col>
						)
					} else {
						result.push(
							<Col className={'pr-0 pl-0 '} key={index}>
								{this.renderInput(field)}
							</Col>
						)
					}
				}
			})
		}

		return result
	}

	/**
	 * to render input field
	 * @param {string} field
	 * @param {string} value
	 * @returns Input
	 */
	renderInput = (field, value) => {
		const { productInfo } = this.state
		const fieldId = field.idCampo.trim()
		let result = null
		const inputError = value === 'error_input'
		const inputStyle =
			fieldId === 'cantidad' || fieldId === 'pcio_unit' || fieldId === 'neto'
				? { textAlign: 'right' }
				: {}

		if (field.editable && !this.inputRefs[fieldId]) {
			const customRef = React.createRef()
			this.inputRefs[fieldId] = customRef
		}

		const optionsInput = {
			fwRef: field.editable ? this.inputRefs[fieldId] : null,
			inputFormCol: { sm: 12 },
			fields: [{ ...field, label: false }],
			label: field.label,
			inputId: `${fieldId}`,
			id: `${fieldId}`,
			name: `${fieldId}`,
			colLabel: 'col-sm-12',
			colInput: 'col-sm-12',
			divStyle: { paddingLeft: '5px', paddingRight: '5px' },
			disable: false,
			value: productInfo[fieldId] || '',
			showError: inputError,
			styles: inputStyle,
			rowStyle: { marginBottom: '5px' },
			onChange: (value) => {
				this.handleUpdateProduct(fieldId, value)
			}
		}

		if (fieldId === 'cod_unid') {
			const { codUnidOptions } = this.state
			result = (
				<InputDropdown
					{...optionsInput}
					styleLabel={{ marginBottom: '5px' }}
					options={codUnidOptions}
					onChange={(data) => {
						const value = data.target.value
						this.handleUpdateProduct(fieldId, value)
					}}
				/>
			)
		} else if (field.editable) {
			result = (
				<InputText
					{...optionsInput}
					handleEnterKey={(e, value) => {
						this.handleOnblurInput(value, fieldId)
						return true
					}}
					onBlur={(value) => {
						this.handleOnblurInput(value, fieldId)
					}}
				/>
			)
		}

		return result
	}

	/**
	 * To update state data in the onblur event
	 * @params value
	 * @params fieldId
	 * @params row
	 */

	handleOnblurInput = async (value, fieldId) => {
		this.setState({ isUpdateRecord: fieldId })
		const { idOperacion } = this.props
		const { productInfo } = this.state

		if (value && productInfo.niprod) {
			if (fieldId === 'cantidad') {
				this.props.getPriceByProduct({
					idOperacion: idOperacion,
					Idproducto: productInfo.niprod,
					cantidad: value,
					unid_vta: productInfo.cod_unid
				})
			} else if (fieldId === 'pcio_unit') {
				productInfo.neto = value * productInfo.cantidad
				this.setState({ productInfo: { ...productInfo } })
			} else if (fieldId === 'neto') {
				if (productInfo.pcio_unit > 0) {
					productInfo.cantidad = Math.round(value / productInfo.pcio_unit)
				}
				this.setState({ productInfo: { ...productInfo } })
			}
		}
	}

	/**
	 * to get name of next editable field
	 * @param field string
	 * name of current field
	 */

	getNextEditField = (field) => {
		const { config } = this.props
		let indexField = null
		let result = null
		let nextIndex = 0
		let restart = false

		config.campos.forEach((campo, index) => {
			const campoId = campo.idCampo.trim()
			if (campoId === field) {
				indexField = index
			}
		})

		do {
			nextIndex = indexField + 1
			indexField = config.campos[nextIndex] ? nextIndex : 0
			result = config.campos[indexField].idCampo.trim()
			if (!config.campos[nextIndex]) {
				restart = true
			}
		} while (
			!config.campos[indexField].editable ||
			config.campos[indexField].idCampo.trim() === 'modif_pcio'
		)

		return restart ? 'addToCart' : result
	}

	/**
	 * To set focus in the field.
	 * @params input
	 * @params rowId
	 */
	handleSetFocus = (input) => {
		const nextRef = this.inputRefs[input]
		if (nextRef) {
			if (input === 'fec_entrega' && nextRef.current) {
				nextRef.current.setFocus()
			} else if (input === 'addToCart') {
				nextRef.current.focus()
			} else if (nextRef.current && nextRef.current.element) {
				nextRef.current.element.focus()
			} else {
				nextRef.current.focus()
			}
		}
	}

	handlePreventClickAddCart = () => {
		const { productInfo } = this.state
		const { idOperacion, handleItemAddToCart, isProvider } = this.props
		const cantidad = Math.round(productInfo.cantidad)
		if (productInfo.neto) {
			handleItemAddToCart({
				idOperacion,
				Niprod: productInfo.niprod,
				cod_unid: productInfo.cod_unid,
				cantidad,
				pcio_unit: productInfo.pcio_unit,
				neto: productInfo.neto,
				desc_prod: productInfo.desc_prod
			})
		} else {
			const params = {
				idOperacion,
				Idproducto: productInfo.niprod,
				cantidad,
				unid_vta: productInfo.cod_unid,
				desc_prod: productInfo.desc_prod
			}
			this.props.getPriceAddCart({ params, isProvider })
		}
		/**
				if (rowData.fec_entrega) {
					params.fec_entrega = moment(rowData.fec_entrega).format("YYYY-MM-DD");
				}
		 */
		this.setState({ productInfo: {}, codUnidOptions: [], productValue: '' })
		this.firstRefs.current.clear()
	}

	/**
	 * set new neto value
	 * @param prdPcio prod_pcio_vta
	 */
	handleSetNetoValue = (prdPcio) => {
		const { productInfo } = this.state
		productInfo.neto = prdPcio * productInfo.cantidad
		this.setState({ productInfo: { ...productInfo } })
	}

	handleUpdateProduct = (fieldId, value) => {
		const { productInfo } = this.state
		productInfo[fieldId] = value

		if (fieldId === 'neto' && productInfo.pcio_unit > 0) {
			productInfo.cantidad = Math.round(value / productInfo.pcio_unit) // update cant when use onchange
		}

		this.setState({ ...productInfo })
	}

	/**
	 * To save and update data table to validate changes
	 * @params fieldId
	 * @params value
	 */
	handleUpdateDataTable = (fieldId, value) => {
		const { productInfo } = this.state
		productInfo[fieldId] = value
		this.setState({ productInfo })
	}

	/**
	 * to close stock window.
	 */
	handleCloseStock = () => {
		this.setState({ showStock: false })
	}

	/**
	 * to close stock provider modal
	 */
	handleCloseStockProvider = () => {
		this.setState({ stockProviderModal: false })
	}

	/**
	 * to close error notification
	 */
	handleCloseError = () => {
		this.setState({ showError: false })
	}

	render() {
		const { t, idOperacion, parameterConfirm, handleMoveArrow } = this.props
		const {
			isLoading,
			auoptions,
			productValue,
			showStock,
			referenceItem,
			stockProviderModal
		} = this.state
		const configField = [
			{
				descrip: '',
				editable: 1,
				idCampo: 'search_product',
				label: 'Producto',
				mascara: null,
				requerido: 0,
				valid: null,
				visible: 1
			}
		]
		return (
			<Row>
				<Col lg={12} sm={12}>
					<StockPopup
						params={parameterConfirm}
						showStock={showStock}
						idOperacion={idOperacion}
						referenceItem={referenceItem}
						handleClose={this.handleCloseStock}
						handleCloseCartMessage={this.handleCloseError}
						handleMoveArrow={handleMoveArrow}
					/>
					<StockProvider
						params={parameterConfirm}
						showStock={stockProviderModal}
						idOperacion={idOperacion}
						referenceItem={referenceItem}
						handleClose={this.handleCloseStockProvider}
						handleCloseCartMessage={this.handleCloseError}
					/>
				</Col>
				<Col lg={4} md={4} sm={6} xs={12} className={'pr-0'}>
					<InputAutocomplete
						inputFormCol={{ sm: 12 }}
						label={'Producto'}
						inputId={'search_product'}
						name={'search_product'}
						placeholder={t('searchBox.form.insert_product')}
						fields={configField}
						colLabel={'col-sm-12'}
						colInput={'col-sm-12'}
						handleSearch={this.handleSearch}
						auoptions={auoptions}
						handleLoading={isLoading}
						handleSelect={this.handleSelect}
						onChange={this.handleChange}
						labelKey={'label'}
						defaultValue={productValue}
						refs={this.firstRefs}
					/>
				</Col>
				{this.renderSearchInputs()}
				<Col md={'auto'} className={'pt-4 mt-1 pl-0'}>
					<Button
						className={`btn btn-light`}
						onClick={this.handlePreventClickAddCart}
					>
						<FontAwesomeIcon icon={faShoppingCart} />
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = ({ product, auth, loadItems, providers }) => {
	const { searchAutoOptions } = product
	const { authUser } = auth
	const { itemsCartProvider, parameterConfirm } = providers
	const { productPrice, confirmItem, providerItemsAdd } = loadItems
	return {
		search: searchAutoOptions,
		authUser,
		productPrice,
		confirmItem,
		parameterConfirm,
		providerItemsAdd,
		itemsCartProvider
	}
}

const connectForm = connect(mapStateToProps, {
	searchAutoProducts,
	getPriceByProduct,
	getPriceAddCart
})(IndividualSearchProduct)

export default withTranslation()(connectForm)
