import NotificationMessage from 'components/common/notificationMessage'
import InputButton from 'components/form/inputButton'
import { ERROR_GET_IMPDOCS } from 'constants/ActionsTypes'
import { Formik } from 'formik'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { clearErrorImpDocs, searchBank, validatePayment } from '../../actions'
import { getInitValues, getValidationSchema } from '../../lib/FieldValidations'
import NotificationsErrors from '../common/notificationsErrors'
import PaymentFormInput from './paymentFormInput'

const excludeFields = [
	'mc_grabaryrepetir',
	'mc_grabarycontinuar',
	'mc_aceptar',
	'mc_cancelar',
	'mc_importar',
	'mc_buscar'
]
const excludeFocus = []

class CollectionPaymentForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			changeForm: true,
			showError: false,
			errorMessage: '',
			errorTitle: '',
			type: 'success',
			loadingSearch: false,
			importeValue: 0,
			pend_cobro_usual: this.props.paymentTotals.pend_cobro_usual,
			pend_cobro_ext: this.props.paymentTotals.pend_cobro_ext
		}

		this.firtsRef = React.createRef()
		this.formRef = React.createRef()
		this.dateRef = React.createRef()
		this.cheqNo = React.createRef()
		this.movNo = React.createRef()
		this.buttonClicked = ''
	}

	componentDidMount = () => {
		const { configFields, impDocuments } = this.props
		let banRef = true
		const fields = configFields.map((field) => {
			const exclude = _.contains(excludeFocus, field.idCampo)
			if (field.visible && !exclude && banRef) {
				banRef = false
				return { ...field, fwRef: this.firtsRef }
			}
			return { ...field }
		})

		if (impDocuments && impDocuments.error === ERROR_GET_IMPDOCS) {
			// this.setState({ showError: true, errorMessage: impDocuments.errorMessage, type: 'danger' });
			this.props.clearErrorImpDocs()
		}

		this.setState({ configFields: fields })
	}

	componentDidUpdate = (prevProps) => {
		const { payment, paymentBanks, t } = this.props

		if (prevProps.payment !== payment && payment) {
			const { values } = this.formRef.current.state

			if (this.buttonClicked === 'continue') {
				this.setState({ changeForm: !this.state.changeForm })
				this.formRef.current.handleReset()
			} else if (this.buttonClicked === 'repeat') {
				if (values.fecha_cobro) {
					const currentDate = moment(values.fecha_cobro)
					const futureMonth = moment(currentDate).add(1, 'M')
					this.formRef.current.state.values.fecha_cobro = futureMonth // Se incrementa la fecha de cobro en 1 mes.
					this.dateRef.current.input.value = futureMonth.format('DD/MM/YY')
					this.dateRef.current.handleBlur()
				}

				if (values.chq_nro) {
					const newValue = parseInt(values.chq_nro) + 1
					this.cheqNo.current.value = newValue
					this.formRef.current.state.values.chq_nro = newValue // Se incrementa el numero de chque en 1.
				}

				if (values.nro_movim) {
					const newValue = parseInt(values.nro_movim) + 1
					this.movNo.current.value = newValue
					this.formRef.current.state.values.nro_movim = newValue // Se incrementa el numero de chque en 1.
				}

				if (values.importe) {
					let newImport = values.importe
					if (values.cod_mone === payment.cod_mone_usu) {
						newImport =
							newImport < payment.pend_cobro_usual
								? newImport
								: payment.pend_cobro_usual
					} else if (values.cod_mone === payment.cod_mone_ext) {
						newImport =
							newImport < payment.pend_cobro_ext
								? newImport
								: payment.pend_cobro_ext
					}
					values.importe = newImport < 0 ? '0' : newImport
				}
			} else if (this.buttonClicked === 'accept') {
				this.props.closeWindow()
			}

			this.setState({
				showError: true,
				errorMessage: t('Collection.message.success'),
				type: 'success',
				pend_cobro_ext: payment.pend_cobro_ext,
				pend_cobro_usual: payment.pend_cobro_usual
			})
		}

		if (prevProps.paymentBanks !== paymentBanks && paymentBanks) {
			this.setState({ loadingSearch: false })
		}
	}

	handleValidatePayment = (values) => {
		const { idOperacion, page_size, page } = this.props
		const params = _.omit(values, 'cuenta')
		const nicfondo = values.cuenta
		this.props.validatePayment({
			cobros: [{ ...params, nicfondo }],
			idOperacion,
			page_size: page_size || 10,
			page_number: page || 1
		})
	}

	setButtonClick = (state) => {
		this.buttonClicked = state
	}

	setButtonClick = (state) => {
		this.buttonClicked = state
	}

	handleSearch = (value) => {
		const { idOperacion } = this.props
		this.props.searchBank({ criterio_banco: value, idOperacion })
		this.setState({ loadingSearch: true })
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	getFormatDate = (txtDate) => {
		const year = txtDate.substring(6, 10)
		const month = txtDate.substring(3, 5)
		const day = txtDate.substring(0, 2)
		return `${year}/${month}/${day}`
	}

	render() {
		const { changeForm, configFields } = this.state
		const {
			t,
			accounts,
			moneyOptions,
			initialValues,
			collectionList,
			paymentTotals,
			paymentBanks,
			headInfo
		} = this.props
		const { cuit, titular } = collectionList
		const { pend_cobro_usual, pend_cobro_ext } = paymentTotals
		const validationSchema = configFields
			? getValidationSchema(configFields, t, excludeFields)
			: {}
		const initDate = headInfo.dato1 ? headInfo.dato1.substring(0, 10) : null
		const initial =
			initialValues ||
			(configFields
				? getInitValues(configFields, {
						cuit,
						titular,
						importe: pend_cobro_usual,
						fecha_emis: this.getFormatDate(initDate)
				  })
				: {})

		const optionsImp = []
		optionsImp[collectionList.cod_mone_usu] =
			pend_cobro_usual < 0 ? '0' : pend_cobro_usual
		optionsImp[collectionList.cod_mone_ext] =
			pend_cobro_ext < 0 ? '0' : pend_cobro_ext

		const bankoptions = paymentBanks
			? paymentBanks.bancos.map((bank) => {
					return { id: bank.bco_id, label: bank.bco_nom }
			  })
			: []

		if (configFields) {
			return (
				<Fragment>
					<Col sm={12} className={'mb-1'}>
						<NotificationMessage
							{...this.state}
							handleCloseError={this.handleCloseError}
						/>
					</Col>
					<Col sm={12} className={'mb-1'}>
						<Formik
							ref={this.formRef}
							initialValues={initial}
							onSubmit={(values, actions) => {
								this.handleValidatePayment(values)
							}}
							validationSchema={validationSchema}
							enableReinitialize={false}
							render={({
								values,
								handleBlur,
								handleChange,
								setErrors,
								errors,
								touched,
								isSubmitting,
								handleSubmit,
								setFieldValue,
								setFieldTouched
							}) => (
								<Fragment>
									<Form onSubmit={handleSubmit} className="voucher-info-form">
										<Col sm={11}>
											<NotificationsErrors
												errors={errors}
												setErrors={setErrors}
												touched={touched}
											/>
										</Col>
										{changeForm && (
											<PaymentFormInput
												fields={configFields}
												optionsAccount={accounts}
												moneyOptions={moneyOptions}
												changeModal={this.props.changeModal}
												firtsRef={this.firtsRef}
												dateRef={this.dateRef}
												cheqNo={this.cheqNo}
												movNo={this.movNo}
												optionsImp={optionsImp}
												handleSearch={this.handleSearch}
												handleLoading={this.state.loadingSearch}
												bankoptions={bankoptions}
												handleChangeAccount={this.props.handleChangeAccount}
												{...{
													values,
													handleBlur,
													handleChange,
													errors,
													touched,
													isSubmitting,
													handleSubmit,
													setFieldValue,
													setFieldTouched
												}}
											/>
										)}
										{!changeForm && (
											<PaymentFormInput
												fields={configFields}
												optionsAccount={accounts}
												moneyOptions={moneyOptions}
												changeModal={this.props.changeModal}
												firtsRef={this.firtsRef}
												dateRef={this.dateRef}
												cheqNo={this.cheqNo}
												movNo={this.movNo}
												optionsImp={optionsImp}
												handleSearch={this.handleSearch}
												handleLoading={this.state.loadingSearch}
												bankoptions={bankoptions}
												handleChangeAccount={this.props.handleChangeAccount}
												{...{
													values,
													handleBlur,
													handleChange,
													errors,
													touched,
													isSubmitting,
													handleSubmit,
													setFieldValue,
													setFieldTouched
												}}
											/>
										)}
										<Row className={'pt-3'}>
											<Col className={'text-center mt-2'}>
												<InputButton
													valueButton={t('Collection.form.buttons.save_new')}
													type={'primary'}
													onClick={() => this.setButtonClick('continue')}
													customStyle={{ minWidth: '145px' }}
												/>
											</Col>
											<Col className={'text-center mt-2'}>
												<InputButton
													valueButton={t('Collection.form.buttons.save_repeat')}
													type={'primary'}
													onClick={() => this.setButtonClick('repeat')}
													customStyle={{ minWidth: '145px' }}
												/>
											</Col>
											<Col className={'text-center mt-2'}>
												<InputButton
													valueButton={t('Collection.form.buttons.accept')}
													onClick={() => this.setButtonClick('accept')}
													type={'primary'}
													customStyle={{ minWidth: '145px' }}
												/>
											</Col>
											<Col className={'text-center mt-2'}>
												<InputButton
													valueButton={t('Collection.form.buttons.cancel')}
													type={'primary'}
													onClick={this.props.closeWindow}
													customStyle={{ minWidth: '145px' }}
												/>
											</Col>
										</Row>
									</Form>
								</Fragment>
							)}
						/>
					</Col>
				</Fragment>
			)
		} else {
			return null
		}
	}
}

const mapStateToProps = ({ collections, voucher }) => {
	const { headInfo } = voucher
	const { payment, collectionList, paymentBanks, impDocuments } = collections

	return { payment, collectionList, paymentBanks, impDocuments, headInfo }
}

export default connect(mapStateToProps, {
	validatePayment,
	searchBank,
	clearErrorImpDocs
})(withTranslation()(withRouter(CollectionPaymentForm)))
