import {
	CRUD_CLEAR_USER_PROFILES_STORE,
	CRUD_GET_USER_PROFILES,
	CRUD_GET_USER_PROFILES_SUCCESS,
	CRUD_UPDATE_USER_PROFILES,
	CRUD_UPDATE_USER_PROFILES_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	userProfilesSearched: any
	userProfilesUpdated: any
}

const initialState = {
	userProfilesSearched: null,
	userProfilesUpdated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_USER_PROFILES:
			return { ...state, userProfilesSearched: null }
		case CRUD_GET_USER_PROFILES_SUCCESS:
			return { ...state, userProfilesSearched: action.payload }
		case CRUD_UPDATE_USER_PROFILES:
			return { ...state, userProfilesUpdated: null }
		case CRUD_UPDATE_USER_PROFILES_SUCCESS:
			return { ...state, userProfilesUpdated: action.payload }
		case CRUD_CLEAR_USER_PROFILES_STORE:
			return {
				...state,
				userProfilesSearched: null,
				userProfilesUpdated: null
			}
		default:
			return state
	}
}

export default rootReducer
