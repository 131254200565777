import { getProductStock, validateProductStock } from 'actions'
import CommonTable from 'components/common/commonTable'
import InputText from 'components/form/inputText'
import { addPrevValidations } from 'lib/AxiosInterceptors'
import { getValueMask } from 'lib/MaskValues'
import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { connect } from 'react-redux'
import _ from 'underscore'
import styles from './stockTable.module.scss'
class StockTable extends Component {
	constructor(props) {
		super(props)

		addPrevValidations((e) => {
			return e.request.responseURL.includes('Stock/sel_partida/egresos/validar')
		})

		this.state = {
			itemsTable: [],
			selectedCheck: [],
			rowSelected: [],
			primaryKey: null,
			cantPendent: 0,
			cantAfec: 0,
			validateError: {
				showError: false,
				type: 'danger',
				errorMessage: ''
			},
			isAllRows: false
		}
		this.primaryKey = 'id'
		this.tableColumns = [
			{
				idCampo: 'cuf',
				descripcion: 'Cuf',
				label: 'CUF',
				editable: 0,
				visible: 1,
				requerido: 0,
				valid: null,
				mascara: ''
			},
			{
				idCampo: 'fecha_ing',
				descripcion: 'la fecha de ingreso',
				label: 'Fecha Ing.',
				editable: 0,
				visible: 1,
				requerido: 0,
				valid: null,
				mascara: 'FechaLarga'
			},
			{
				idCampo: 'fecha_vto',
				descripcion: 'fecha vto',
				label: 'Fecha Vto.',
				editable: 0,
				visible: 1,
				requerido: 0,
				valid: null,
				mascara: 'FechaLarga'
			},
			{
				idCampo: 'partida',
				descripcion: 'partida',
				label: 'Partida',
				editable: 0,
				visible: 1,
				requerido: 0,
				valid: null
			},
			{
				idCampo: 'saldo',
				descripcion: 'saldo',
				label: 'Saldo',
				editable: 0,
				visible: 1,
				requerido: 0,
				mascara: 'NetoUsual',
				valid: null
			},
			{
				idCampo: 'saldo_afec',
				descripcion: 'saldo',
				label: 'Cant. Afect.',
				editable: 1,
				visible: 1,
				requerido: 0,
				valid: null
			},
			{
				idCampo: 'nserie',
				descripcion: 'numero de serie',
				label: 'Nro. Serie',
				editable: 0,
				visible: 1,
				requerido: 0,
				valid: null
			}
		]
	}

	componentDidUpdate = (prevProps) => {
		const { validateStock, productStock, searchItems } = this.props

		/****************************************************/
		this.handleValidateProducts(prevProps.productStock, productStock)
		/****************************************************/

		if (
			prevProps.searchItems !== searchItems &&
			searchItems &&
			searchItems.Validos
		) {
			this.handleSetCheckTables(searchItems.Validos)
		}

		if (searchItems !== prevProps.searchItems && searchItems) {
			this.handleSetTotals(searchItems)
		}

		/************************************************/
		this.handleValidateStock(prevProps.validateStock, validateStock)
		/************************************************/

		if (
			prevProps.validateStock !== validateStock &&
			validateStock &&
			validateStock.Validos
		) {
			this.handleSetCheckTables(validateStock.Validos)
		}
	}

	/**
	 * validate product stock
	 * @param {*} prevProductosStock
	 * @param {*} productsStock
	 */
	handleValidateProducts = (prevProductosStock, productsStock) => {
		if (
			!_.isEqual(productsStock, prevProductosStock) &&
			!_.isEmpty(productsStock)
		) {
			// this.primaryKey = productsStock.ind_series === 1 ? 'nserie' : 'nipartida' // to set primary key

			this.handleSetTotals(productsStock)
			this.setState({ itemsTable: productsStock.Partidas })
		}
	}

	/**
	 *  handle validate of change stock
	 * @param {*} prevValidateStock
	 * @param {*} validateStock
	 */
	handleValidateStock = (prevValidateStock, validateStock) => {
		const { itemsTable: newItemsTable } = this.state

		if (
			!_.isEqual(validateStock, prevValidateStock) &&
			!_.isEmpty(validateStock)
		) {
			this.handleSetTotals(validateStock)
			const { Validos } = validateStock
			if (!validateStock.Resultado.Resultado) {
				const errorMessage = validateStock.Resultado.Mens_error

				const params = {
					showError: true,
					errorMessage,
					type: 'danger'
				}

				if (errorMessage !== '') {
					this.props.handleSetErrorBarCode(params)
				}

				const validList = new Set(Validos.map((val) => val.id))

				newItemsTable.forEach((part) => {
					if (!validList.has(part.id)) {
						part.saldo_afec = 0
					}
				})
			}

			const selectedCheck = Validos.map((row) => {
				return row[this.primaryKey]
			})

			this.setState({
				selectedCheck,
				rowSelected: Validos,
				itemsTable: newItemsTable
			})
		}
	}

	/**
	 *  validate a value in array
	 * @param {*} row
	 * @param {*} Validos
	 * @param {*} primaryKey
	 * @returns
	 */
	isValid = (row, Validos, primaryKey) => {
		return !Validos.find((valid) => row[primaryKey] === valid[primaryKey])
	}

	/**
	 * to save the cant_pend by item
	 * @param {object} totals
	 */
	handleSetTotals = (totals) => {
		this.setState({ cantPendent: totals.cant_pend, cantAfec: totals.cant_afec })
	}

	handleSetCheckTables = (arrData) => {
		const valids = []

		arrData.forEach((valid) => {
			valids.push(valid[this.primaryKey])
		})

		if (valids.length) {
			this.setState({ selectedCheck: valids }) // Se adiciona check a la tabla
		}
	}

	getColumns = () => {
		const { productStock } = this.props
		const rows = this.tableColumns.map((field) => {
			const idField = field.idCampo.trim()
			const showCol =
				((idField === 'saldo' || idField === 'saldo_afec') &&
					productStock.ind_series === 1) ||
				(idField === 'nserie' && productStock.ind_series === 0)
			const hide = !showCol
			return {
				dataField: idField,
				text: field.label,
				align: 'center',
				headerAlign: 'center',
				headerStyle: this.getStyleColumn(field),
				hidden: !hide,
				formatter: (cell, row, rowIndex) => {
					return this.renderFormat(field, cell, row)
				}
			}
		})
		return rows
	}

	handleRowStyle = (row) => {
		const style = {}
		if (row.ind_error) {
			style.backgroundColor = '#FFCC99'
		} else if (!row.ind_error) {
			style.backgroundColor = '#33CCFF'
		}
		return style
	}

	renderFormat = (field, value, row) => {
		const idField = field.idCampo.trim()
		let result = value

		const optionsInput = {
			inputFormCol: { sm: 12 },
			fields: [{ ...field, label: false }],
			label: false,
			inputId: `${idField}`,
			id: `${idField}_${row[this.primaryKey]}`,
			name: `${idField}_${row[this.primaryKey]}`,
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			divStyle: { paddingLeft: '17px' },
			disable: false,
			value: value,
			styles: { textAlign: 'right' },
			rowStyle: { marginBottom: '5px' },
			type: 'number'
		}

		if (field.mascara) {
			result = getValueMask(value, field.mascara, this.props)
		} else if (idField === 'saldo_afec') {
			result = (
				<InputText
					{...optionsInput}
					onChange={(val) => {
						row.saldo_afec = val // Se adiciona el valor a la Fila
					}}
					handleEnterKey={(e, value) => {
						this.validateSaldo(row, field, value)
					}}
					onBlur={(val) => {
						this.validateSaldo(row, field, val)
					}}
				/>
			)
		}

		return result
	}

	validateSaldo = (row, field, value) => {
		const { idOperacion, referenceItem, params } = this.props
		const { selectedCheck, rowSelected } = this.state
		const newItem = {
			nipartida: row.nipartida,
			nimovpr: row.nimovpr,
			nitem: row.nitmovpr,
			saldo_afec: parseInt(value),
			nserie: row.nserie
		}

		newItem[this.primaryKey] = row[this.primaryKey]

		const selected = selectedCheck || []
		const rows = rowSelected || []
		let saveItem = true

		rows.forEach((toSave, index) => {
			if (toSave[this.primaryKey] === row[this.primaryKey]) {
				saveItem = false
				toSave.saldo_afec = value
			}
		})

		if (saveItem) {
			rows.push(newItem)
		}

		if (value) {
			if (parseFloat(value) === 0) {
				selected.forEach((delet, index) => {
					if (delet === row[this.primaryKey]) {
						selected.splice(index, 1)
					}
				})
			} else {
				selected.push(row[this.primaryKey])
			}
		}

		this.setState({ selectedCheck: selected, rowSelected: rows })
		const paramsApi = {
			idOperacion,
			it: referenceItem,
			Partidas: rows
		}

		if (!_.isEmpty(params.cuf_dest)) {
			paramsApi.cuf_dest = params.cuf_dest
		}

		this.props.validateProductStock(paramsApi)
	}

	getStyleColumn = (field) => {
		const idField = field.idCampo.trim()
		let style = {}
		switch (idField) {
			default:
				style = { width: '10%' }
				break
		}

		if (field.requerido === '1' || field.requerido) {
			style.color = 'red'
		}

		return style
	}

	handleUpdateArray = (array, keySearch, compare, updateKey, updateValue) => {
		let update = false

		array.forEach((element) => {
			if (element[keySearch] === compare) {
				element[updateKey] = updateValue
				update = true
			}
		})

		return update
	}

	/**
	 * to get value to set in the input
	 */
	getValueSend = (row) => {
		const { cantPendent } = this.state

		let cantSend

		if (row.saldo_afec) {
			// If saldo_afec is defined, use its value
			cantSend = row.saldo_afec
		} else if (row.saldo < cantPendent) {
			// If saldo is less than cantPendent, use saldo's value
			cantSend = row.saldo
		} else {
			// If none of the above conditions are met, use cantPendent
			cantSend = cantPendent
		}

		return cantSend
	}

	handleSelectRow = (row, isSelect) => {
		const { idOperacion, referenceItem, params } = this.props
		const { rowSelected, itemsTable: newItemsTable } = this.state
		const rows = rowSelected || []
		let updateRecord = false

		this.setState({ docSelected: row[this.primaryKey] })
		if (isSelect) {
			// Se adiciona
			const cantSend = this.getValueSend(row)

			this.handleUpdateArray(
				newItemsTable,
				this.primaryKey,
				row[this.primaryKey],
				'saldo_afec',
				cantSend
			) // update values of table

			updateRecord = this.handleUpdateArray(
				rows,
				this.primaryKey,
				row[this.primaryKey],
				'saldo_afec',
				cantSend
			) // update array values how save in the state.

			if (!updateRecord) {
				// Nuevo
				const newRowParams = {
					nipartida: row.nipartida,
					nimovpr: row.nimovpr,
					nitem: row.nitmovpr,
					saldo_afec: cantSend,
					nserie: row.nserie
				}
				newRowParams[this.primaryKey] = row[this.primaryKey]
				rows.push(newRowParams)
			}
		} else {
			// Se resta
			this.handleUpdateArray(
				newItemsTable,
				this.primaryKey,
				row[this.primaryKey],
				'saldo_afec',
				0
			)
			updateRecord = this.handleUpdateArray(
				rows,
				this.primaryKey,
				row[this.primaryKey],
				'saldo_afec',
				0
			)

			if (!updateRecord) {
				// Nuevo
				rows.push({
					nipartida: row.nipartida,
					nimovpr: row.nimovpr,
					nitem: row.nitmovpr,
					saldo_afec: 0,
					nserie: row.nserie
				})
			}
		}

		const selectedCheck = !_.isEmpty(rows)
			? rows.map((row) => {
					return row[this.primaryKey]
			  })
			: []

		this.setState({
			selectedCheck,
			rowSelected: rows,
			isAllRows: false,
			itemsTable: newItemsTable
		})

		const paramsValidate = {
			idOperacion,
			it: referenceItem,
			serie_desde: '',
			serie_cant: 0,
			Partidas: rows
		}

		if (!_.isEmpty(params.cuf_dest)) {
			paramsValidate.cuf_dest = params.cuf_dest
		}

		this.props.validateProductStock(paramsValidate)
	}

	handleSelectAllRow = (isSelect, rows) => {
		const { idOperacion, referenceItem, params } = this.props
		const { itemsTable: newItemsTable } = this.state
		let selected = []

		if (isSelect) {
			this.setState({ selectedCheck: [] })

			selected = rows.map((row) => {
				const cantSend = this.getValueSend(row)

				const params = {
					nipartida: row.nipartida,
					nimovpr: row.nimovpr,
					nitem: row.nitmovpr,
					saldo_afec: cantSend,
					nserie: row.nserie
				}

				params[this.primaryKey] = row[this.primaryKey]

				return params
			})

			newItemsTable.forEach((toDelete, index) => {
				// Los valores que se muestran en pantalla
				const cantSend = this.getValueSend(toDelete)
				toDelete.saldo_afec = cantSend
			})
		} else {
			newItemsTable.forEach((toDelete, index) => {
				toDelete.saldo_afec = 0
			})

			selected = rows.map((row) => {
				const params = {
					nipartida: row.nipartida,
					nimovpr: row.nimovpr,
					nitem: row.nitmovpr,
					saldo_afec: 0,
					nserie: row.nserie
				}
				params[this.primaryKey] = row[this.primaryKey]
				return params
			})
		}

		const paramsApi = {
			idOperacion,
			it: referenceItem,
			serie_desde: '',
			serie_cant: 0,
			Partidas: selected
		}

		if (!_.isEmpty(params.cuf_dest)) {
			paramsApi.cuf_dest = params.cuf_dest
		}

		this.props.validateProductStock(paramsApi)

		const selectedCheck = selected.map((row) => {
			return row[this.primaryKey]
		})

		this.setState({
			selectedCheck,
			rowSelected: selected,
			isAllRows: true,
			itemsTable: newItemsTable
		})
	}

	/**
	 * to close error notification.
	 */
	handleCloseError = () => {
		this.setState({
			validateError: { type: 'danger', showError: false, errorMessage: '' }
		})
	}

	render() {
		const { productStock, theme, readOnly } = this.props
		const { selectedCheck, itemsTable } = this.state

		const options = productStock
			? {
					pageStartIndex: 1,
					sizePerPage: productStock.page_size,
					page: productStock.page_number,
					totalSize: productStock.total_count
			  }
			: {}

		const selectRow = {
			mode: 'checkbox',
			clickToSelect: false,
			selectColumnPosition: 'right',
			selected: selectedCheck,
			hideSelectColumn: !!readOnly,
			headerColumnStyle: {
				width: '1%'
			},
			style: (row) => {
				const backgroundColor = row.error ? '#f8d7da' : '#FFF'
				const marginBottom = 120
				return { backgroundColor, marginBottom }
			},
			onSelect: this.handleSelectRow,
			onSelectAll: this.handleSelectAllRow
		}

		return (
			<Col className={`col-9 pl-0 pr-0`}>
				{productStock && this.primaryKey && (
					<CommonTable
						remote
						classes={theme.classTable}
						columns={this.getColumns()}
						keyField={this.primaryKey}
						data={itemsTable}
						rowClasses={theme.tableRow}
						headerClasses={theme.tableHeader}
						paginationOptions={options}
						onTableChange={this.props.handleChangeTable}
						selectRow={selectRow}
					/>
				)}
			</Col>
		)
	}
}

const mapStateToProps = ({ auth, stock }) => {
	const { authUser } = auth
	const { paramsValidate, searchItems } = stock

	return { authUser, paramsValidate, searchItems }
}

const connectForm = connect(mapStateToProps, {
	getProductStock,
	validateProductStock
})(StockTable)

export default themr('StateTableStyles', styles)(connectForm)
