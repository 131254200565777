import {
	CANCEL_SEL_DOCUMENTS,
	CANCEL_SEL_DOCUMENTS_SUCCESS,
	CLEAR_IMP_DOCUMENTS_ERROR,
	COLLECTION_IMPORT_DOC,
	COLLECTION_IMPORT_DOC_SUCCESS,
	CONFIRM_IMP_DOCUMENTS,
	CONFIRM_IMP_DOCUMENTS_SUCCESS,
	CONFIRM_PAYMENT,
	CONFIRM_PAYMENT_SUCCESS,
	CONFIRM_SEL_DOCUMENTS,
	CONFIRM_SEL_DOCUMENTS_SUCCESS,
	GET_COLLECTIONS,
	GET_COLLECTIONS_SUCCESS,
	GET_IMP_DOCUMENTS,
	GET_IMP_DOCUMENTS_ERROR,
	GET_IMP_DOCUMENTS_SUCCESS,
	GET_SEL_DOCUMENTS,
	GET_SEL_DOCUMENTS_SUCCESS,
	REMOVE_PAYMENT,
	REMOVE_PAYMENT_SUCCESS,
	SEARCH_BANK,
	SEARCH_BANK_SUCCESS,
	VALIDATE_PAYMENT,
	VALIDATE_PAYMENT_SUCCESS,
	VALIDATE_SEL_DOCUMENTS,
	VALIDATE_SEL_DOCUMENTS_SUCCESS
} from 'constants/ActionsTypes'

const initialState = {
	collectionList: null,
	payment: null,
	paymentConfirm: null,
	selDocuments: null,
	confirmDocs: null,
	selDocumentsValidate: null,
	paymentRemoved: null,
	paymentBanks: null,
	impDocuments: null
}

function rootReducer(state = initialState, action) {
	switch (action.type) {
		case GET_COLLECTIONS:
			return { ...state, collectionList: null }
		case GET_COLLECTIONS_SUCCESS:
			return { ...state, collectionList: action.payload }
		case VALIDATE_PAYMENT:
			return { ...state, payment: null }
		case VALIDATE_PAYMENT_SUCCESS:
			return { ...state, payment: action.payload }
		case CONFIRM_PAYMENT:
			return { ...state, paymentConfirm: null }
		case CONFIRM_PAYMENT_SUCCESS:
			return { ...state, paymentConfirm: action.payload }
		case GET_SEL_DOCUMENTS:
			return { ...state, selDocuments: null }
		case GET_SEL_DOCUMENTS_SUCCESS:
			return { ...state, selDocuments: action.payload }
		case CANCEL_SEL_DOCUMENTS:
			return { ...state, selDocumentsCancelled: null }
		case CANCEL_SEL_DOCUMENTS_SUCCESS:
			return { ...state, selDocumentsCancelled: action.payload }
		case GET_IMP_DOCUMENTS:
			return { ...state, impDocuments: null }
		case GET_IMP_DOCUMENTS_SUCCESS:
			return { ...state, impDocuments: action.payload }
		case GET_IMP_DOCUMENTS_ERROR:
			return { ...state, impDocuments: action.payload }
		case CLEAR_IMP_DOCUMENTS_ERROR:
			return { ...state, impDocuments: null }
		case VALIDATE_SEL_DOCUMENTS:
			return {
				...state,
				selDocumentsValidate: null,
				paramsValSelDocuments: action.payload
			}
		case VALIDATE_SEL_DOCUMENTS_SUCCESS:
			return { ...state, selDocumentsValidate: action.payload }
		case CONFIRM_SEL_DOCUMENTS:
			return { ...state, confirmDocs: null }
		case CONFIRM_SEL_DOCUMENTS_SUCCESS:
			return { ...state, confirmDocs: action.payload }
		case REMOVE_PAYMENT:
			return { ...state, paymentRemoved: null }
		case REMOVE_PAYMENT_SUCCESS:
			return { ...state, paymentRemoved: action.payload }
		case SEARCH_BANK:
			return { ...state, paymentBanks: null }
		case SEARCH_BANK_SUCCESS:
			return { ...state, paymentBanks: action.payload }
		case COLLECTION_IMPORT_DOC:
			return { ...state, docsImported: null }
		case COLLECTION_IMPORT_DOC_SUCCESS:
			return { ...state, docsImported: action.payload }
		case CONFIRM_IMP_DOCUMENTS:
			return { ...state, impConfirm: null }
		case CONFIRM_IMP_DOCUMENTS_SUCCESS:
			return { ...state, impConfirm: action.payload }
		default:
			return state
	}
}

export default rootReducer
