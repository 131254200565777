import {
	crudsClearSearch,
	crudsClearStore,
	crudsDelete,
	crudsGetList,
	getConfigFieldsCrud,
	showMessage
} from 'actions'
import CrudFilters from 'components/abmUtils/CrudFilters'
import CrudTable from 'components/abmUtils/CrudTable'
import withMenu from 'components/common/withMenu'
import CrudsContainer from 'components/layout/CrudsContainer'
import { CrudsList } from 'constants/crudsList/CrudsData'
import { getFiltersData } from 'lib/Utils'
import {
	ICrudFieldConfig,
	ICrudListResponse,
	ICrudPageOptions,
	ICrudsApi,
	ICrudsTypeList,
	IGetConfigFieldsCrud
} from 'models/CrudInterface'
import { IResponseResult } from 'models/ResultsInterface'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { CRUD_EDIT } from 'utils/RoutePath'

type CrudDataProps = {
	fieldsConfig: ICrudFieldConfig
	getConfigFieldsCrud: (params: IGetConfigFieldsCrud) => void
	crudsClearStore: () => void
	crudsClearSearch: () => void
	crudsGetList: (params: ICrudsApi) => void
	valuesDataList: ICrudListResponse
	valueDataRemoved: IResponseResult
	crudsDelete: (params: ICrudsApi) => void
	history: any
	showMessage: any
	paramsSearch: any
	match: any
	t: any
}

type CrudDataState = {
	paramsSearch: any
	selectedAbm: ICrudsTypeList
	disableFields: boolean
	disableAbm: boolean
	configLoaded: boolean
}

class CrudData extends Component<CrudDataProps, CrudDataState> {
	constructor(props: CrudDataProps) {
		super(props)
		this.props.crudsClearStore()
		this.state = {
			paramsSearch: {},
			selectedAbm:
				CrudsList.find((crud) => {
					return crud.dataUrl === this.props.match.params.abmKey
				}) || CrudsList[0],
			disableFields: true,
			disableAbm: true,
			configLoaded: false
		}
	}

	componentDidMount = () => {
		const {
			fieldsConfig,
			crudsClearSearch,
			getConfigFieldsCrud,
			paramsSearch
		} = this.props
		const { params } = this.props.match
		const { selectedAbm } = this.state

		if (params?.abmKey !== paramsSearch?.crudName) {
			crudsClearSearch()
		}

		this.props.getConfigFieldsCrud({ cod_abm: selectedAbm.urlApi })
		if (!fieldsConfig || fieldsConfig.cod_abm !== selectedAbm.urlApi) {
			crudsClearSearch()
			getConfigFieldsCrud({ cod_abm: selectedAbm.urlApi })
		}
	}

	componentDidUpdate = (prevProps: CrudDataProps) => {
		const { valueDataRemoved, fieldsConfig, t, showMessage } = this.props
		const { selectedAbm, paramsSearch } = this.state
		if (prevProps.valueDataRemoved !== valueDataRemoved && valueDataRemoved) {
			this.props.crudsGetList({
				crudName: selectedAbm.id,
				urlApi: selectedAbm.urlApi,
				action: 'buscar',
				method: selectedAbm.searchMethod,
				params: {
					...paramsSearch,
					busqueda: paramsSearch?.lk_nom,
					page_size: 10,
					page_number: 1
				}
			})
			showMessage({
				type: 'success',
				description: t('CRUDS.message.success.delete'),
				showError: true
			})
		}

		if (fieldsConfig && fieldsConfig !== prevProps.fieldsConfig) {
			const newConfigState = {
				disableAbm: !(
					fieldsConfig.tipo_perm === 'L' || fieldsConfig.tipo_perm === 'E'
				),
				disableFields: !(fieldsConfig.tipo_perm === 'E'),
				configLoaded: true
			}
			if (_.isEmpty(fieldsConfig.campos)) {
				showMessage({
					type: 'error',
					description: t('CRUDS.message.config_error'),
					showError: true
				})
				newConfigState.configLoaded = false
			}
			this.setState({ ...newConfigState })
		}
	}

	/**
	 * to call api to search
	 * @param {object} filters
	 */

	handleCrudSearch = (filters: any) => {
		const { t, showMessage, crudsGetList } = this.props
		const { selectedAbm, disableAbm } = this.state
		const values = Object.values(filters)
		!disableAbm
			? crudsGetList({
					crudName: selectedAbm.id,
					urlApi: selectedAbm.urlApi,
					action: 'buscar',
					method: selectedAbm.searchMethod,
					params: {
						...filters,
						busqueda: values[0],
						page_size: 10,
						page_number: 1
					}
			  })
			: showMessage({
					type: 'error',
					description: t('CRUDS.message.rights'),
					showError: true
			  })
		this.setState({ paramsSearch: { ...filters } })
	}

	/**
	 * to delete transport
	 * @param {object} row
	 */

	handleDelete = (row: any) => {
		const { showMessage, t, crudsDelete } = this.props
		const { selectedAbm, disableFields } = this.state
		!disableFields
			? crudsDelete({
					crudName: selectedAbm.id,
					urlApi: selectedAbm.urlApi,
					action: 'eliminar',
					params: { id: selectedAbm.key, param: row[selectedAbm.key] }
			  })
			: showMessage({
					type: 'error',
					description: t('CRUDS.message.rights'),
					showError: true
			  })
	}

	/**
	 * to manage the callback to edit row
	 * @param {object} param
	 */

	callBackEdit = (param: any) => {
		const { history } = this.props
		const { selectedAbm } = this.state
		history.push(
			`${CRUD_EDIT}/${selectedAbm.dataUrl}/edit/${param[selectedAbm.key]}`
		)
	}

	/**
	 * to manage the callback to view row
	 * @param {object} param
	 */

	callBackView = (param: any) => {
		const { history } = this.props
		const { selectedAbm } = this.state
		history.push(
			`${CRUD_EDIT}/${selectedAbm.dataUrl}/view/${param[selectedAbm.key]}`
		)
	}

	handleNewCrud = () => {
		const { history } = this.props
		const { selectedAbm } = this.state
		history.push(`${CRUD_EDIT}/${selectedAbm.dataUrl}/add/new`)
	}

	/**
	 * to handle table pagination event
	 * @param {string} type
	 * @param {object} pagination
	 */

	handleChangeTable = (type: String, pagination: ICrudPageOptions) => {
		const { t, showMessage, crudsGetList } = this.props
		const { paramsSearch, selectedAbm, disableAbm } = this.state
		if (type === 'pagination') {
			!disableAbm
				? crudsGetList({
						crudName: selectedAbm.id,
						urlApi: selectedAbm.urlApi,
						action: 'buscar',
						method: selectedAbm.searchMethod,
						params: {
							...paramsSearch,
							busqueda: paramsSearch?.lk_nom,
							page_size: pagination.sizePerPage,
							page_number: pagination.page
						}
				  })
				: showMessage({
						type: 'error',
						description: t('CRUDS.message.rights'),
						showError: true
				  })
		}
	}

	render() {
		const { t, fieldsConfig, valuesDataList } = this.props
		const { selectedAbm, disableAbm, disableFields, configLoaded } = this.state
		const fieldFilters = fieldsConfig
			? getFiltersData(fieldsConfig.campos, { agrupador: 'formulario' })
			: []
		const fieldsTable = fieldsConfig
			? getFiltersData(fieldsConfig.campos, { agrupador: 'grilla' })
			: []

		const filtersProps = {
			fields: fieldFilters,
			handleSearch: this.handleCrudSearch,
			urlAddCrud: `${CRUD_EDIT}/${selectedAbm.dataUrl}/add/new`,
			homeTarget: selectedAbm.category,
			hideSearch: disableAbm,
			hideNew: disableFields
		}
		const searchComponentProps = {
			handleSubmit: this.handleCrudSearch,
			handleNewCrud: this.handleNewCrud,
			fields: fieldFilters,
			homeTarget: selectedAbm.category,
			hideSearch: disableAbm,
			hideNew: disableFields
		}

		const tableProps = {
			fields: fieldsTable,
			dataTable: valuesDataList ? valuesDataList.lista : [],
			paginationOption: valuesDataList || null,
			primaryKey: selectedAbm.key,
			deleteMessage: t(`CRUDS.${selectedAbm.translation}.delete.body`),
			deleteTitle: t(`CRUDS.${selectedAbm.translation}.delete.title`),
			handleDeleteAction: this.handleDelete,
			handleCallBackEdit: this.callBackEdit,
			handleCallBackView: this.callBackView,
			handleChangeTable: this.handleChangeTable,
			t: t,
			disableForm: disableFields
		}

		return (
			<CrudsContainer>
				{configLoaded && (
					<div className="mt-3">
						{selectedAbm?.searchComponent ? (
							<selectedAbm.searchComponent {...searchComponentProps} />
						) : (
							<CrudFilters {...filtersProps} />
						)}
						<Row className={'pt-2'}>
							{fieldsTable.length > 0 && <CrudTable {...tableProps} />}
						</Row>
					</div>
				)}
			</CrudsContainer>
		)
	}
}

const mapStateToProps = ({ configCrud }: any) => {
	const { fieldsConfig, valuesDataList, valueDataRemoved, paramsSearch } =
		configCrud
	return {
		fieldsConfig,
		valuesDataList,
		valueDataRemoved,
		paramsSearch
	}
}

const connectForm = connect(mapStateToProps, {
	getConfigFieldsCrud,
	crudsGetList,
	crudsDelete,
	crudsClearStore,
	showMessage,
	crudsClearSearch
})(CrudData)

export default withTranslation()(withMenu(withRouter(connectForm)))
