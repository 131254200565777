import { productCombosFields } from 'constants/crudsConfig/crudProductsConstants/CrudProductCombosFormFields'
import { renderInput } from 'lib/FormUtils'
import { ICrudGenericFields } from 'models/CrudInterface'
import {
	IGetTableGenericRes,
	IGetTableGenericResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'

type CrudProductCombosFieldProps = {
	field: any
	formikProps: any
	crudProps: any
	initialValue: any
	searchProducts: (value: string) => void
	isSearching: boolean
	handleIsSearching: (isSearching: boolean) => void
}

type CrudProductCombosFieldState = {
	dependsOn: any
	fieldConfig: ICrudGenericFields
	defaultValue?: string
	idField?: any
	value?: any
}

class CrudProductCombosField extends Component<
	CrudProductCombosFieldProps,
	CrudProductCombosFieldState
> {
	constructor(props: CrudProductCombosFieldProps) {
		super(props)
		this.state = {
			fieldConfig: {
				id: '',
				type: '',
				width: '',
				autoComplete: '',
				comboData: '',
				maxChars: 0,
				dependsOn: {
					id: '',
					values: [
						{
							idValue: '',
							disabled: false
						}
					]
				}
			},
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field } = this.props
		const idField = field.idCampo.trim()
		const addData = _.find(productCombosFields, (field: ICrudGenericFields) => {
			return field.id === idField
		})
		addData && this.setState({ dependsOn: addData.dependsOn })
		addData && this.setState({ fieldConfig: addData })
		addData && this.setState({ idField: idField })
		addData && this.setState({ defaultValue: addData.default })
	}

	// Update check for CPOS validation, and default state of dropdowns
	componentDidUpdate = (prevProps: CrudProductCombosFieldProps) => {
		const { formikProps } = this.props

		const { idField, defaultValue, fieldConfig } = this.state

		if (
			fieldConfig.type !== 'combo' &&
			!formikProps.values[idField] &&
			formikProps.values[idField] !== 0 &&
			defaultValue
		) {
			formikProps.setFieldValue(idField, defaultValue)
		}
	}

	handleBlur = async () => {
		const { formikProps } = this.props
		const { idField } = this.state
		formikProps.setTouched({
			...formikProps.touched,
			[idField]: true
		})
	}

	// Setup combo field if it's empty
	comboDataField = (data: IGetTableGenericResArray) => {
		const values: { cod_valor: string; desc_valor: string }[] = []
		data &&
			_.each(data.lista, (opt: IGetTableGenericRes) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	renderInputs = (
		field: any,
		formikProps: any,
		disableForm: boolean,
		fieldConfig: ICrudGenericFields
	) => {
		const { crudProps, isSearching, searchProducts, handleIsSearching } =
			this.props
		const { products } = crudProps
		const addData = fieldConfig

		const productsList: any =
			products && products.lista
				? products.lista.map((opt: any) => {
						return {
							id: opt.niprod,
							label: opt.cod_prod + ' - ' + opt.desc_prod
						}
				  })
				: []

		addData &&
			(field = {
				...field,
				tipo: addData.type,
				colInput: addData.width,
				autoComplete: addData.autoComplete,
				onBlur: this.handleBlur,
				disabled: addData.disabled,
				customProps: {
					maxLength: addData.maxChars
				}
			})

		if (field.idCampo === 'nicomp') {
			field.customProps = {
				minLength: 3,
				handleSearch: searchProducts,
				handleLoading: isSearching,
				auoptions: productsList,
				handleSelect: (product: { id: string; label: string }) => {
					const fullOption = products.lista.find(
						(listedProduct: {
							niprod: string
							cod_prod: string
							desc_prod: string
							unid_c: string
						}) => {
							return listedProduct.niprod === product.id
						}
					)
					if (fullOption) {
						formikProps.setFieldValue('nicomp', fullOption.niprod)
						formikProps.setFieldValue('cod_comp', fullOption.cod_prod)
						formikProps.setFieldValue('desc_comp', fullOption.desc_prod)
						formikProps.setFieldValue('cod_unid', fullOption.unid_c)
					}
					handleIsSearching(false)
				},
				defaultValue: '',
				disable: disableForm
			}
		}

		if (!field.editable) field.disabled = true
		if (field.visible) {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { field, formikProps, crudProps } = this.props
		const { fieldConfig } = this.state
		return (
			<Fragment>
				{this.renderInputs(
					field,
					formikProps,
					crudProps.disableForm,
					fieldConfig
				)}
			</Fragment>
		)
	}
}

export default CrudProductCombosField
