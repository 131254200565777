import DisplayAmount from 'components/common/displayAmount'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'

interface IShoppingCartResumenProps extends WithTranslation {
	headInfo: any
	productsCart: any
	theme: any
	t?: any
}

class ShoppingCartResumen extends Component<IShoppingCartResumenProps> {
	render() {
		const { headInfo, productsCart, theme, t } = this.props

		const totalItem =
			productsCart && productsCart.total_item ? productsCart.total_item : 0
		const totalImporte =
			productsCart && productsCart.total_importe
				? productsCart.total_importe
				: 0

		const totalBruto =
			productsCart && productsCart.total_margen_bruto
				? productsCart.total_margen_bruto
				: 0

		return (
			<>
				<Row>
					<Col lg={8} md={12} style={{ lineHeight: '1.4' }}>
						<span style={{ fontSize: '13pt' }}>
							{`${headInfo.tipo_comprobante} - ${headInfo.descrip_comprobante}`}
						</span>
					</Col>
					<Col lg={4} sm={12} className={theme.divTotalTitle}>
						<span> {`Total Ítems: ${totalItem}`}</span>
					</Col>
				</Row>
				<Row>
					<Col lg={8} md={12}>
						<span style={{ fontSize: '9pt', color: 'grey' }}>
							{headInfo.razon_social}
						</span>
					</Col>
					<Col lg={4} md={12} className={theme.divTotalTitle}>
						<span>
							{`${t('loadItem.table.totalImp')}:`}
							<DisplayAmount amount={totalImporte} />
						</span>
					</Col>
				</Row>
				<Row>
					<Col sm={3}>
						<span style={{ fontSize: '9pt' }}>{headInfo.dato1}</span>
					</Col>
					<Col sm={3}>
						<span style={{ fontSize: '9pt' }}>{headInfo.dato2}</span>
					</Col>
					<Col
						lg={{ span: 4, offset: 2 }}
						md={12}
						className={theme.divTotalTitle}
					>
						<span>{`${t(
							'loadItem.table.total_gross_margin'
						)}: ${totalBruto}`}</span>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						<span style={{ fontSize: '9pt' }}>{headInfo.dato3}</span>
					</Col>
					<Col sm={6}>
						<span style={{ fontSize: '9pt' }}>{headInfo.dato4}</span>
					</Col>
				</Row>
			</>
		)
	}
}

const mapStateToProps = ({ voucher, product }: any) => {
	const { headInfo } = voucher
	const { productsCart } = product
	return { headInfo, productsCart }
}

const mapDispatchToProps = {}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(ShoppingCartResumen))
