import React, { Component } from 'react'
import CurrencyFormat from 'react-currency-format'

const mapper = {
	ARS: {
		prefix: '$ ',
		decimalSeparator: ',',
		thousandSeparator: '.'
	},
	USD: {
		prefix: 'U$S ',
		decimalSeparator: '.',
		thousandSeparator: ','
	}
}

export default class displayAmount extends Component {
	render() {
		const { amount, currency, prefix } = this.props
		const tmpamount = amount || 0
		let config = mapper.ARS

		if (currency) {
			config = mapper[currency]
		}

		const currencyprefix = prefix ? `${prefix} ` : config.prefix

		return (
			<CurrencyFormat
				value={tmpamount}
				displayType={'text'}
				decimalScale={2}
				fixedDecimalScale={true}
				decimalSeparator={config.decimalSeparator}
				thousandSeparator={config.thousandSeparator}
				prefix={currencyprefix}
			/>
		)
	}
}
