import {
	CRUD_CLEAR_FUND_ACCOUNTS_CODE_STORE,
	CRUD_CREATE_FUND_ACCOUNTS_CODE,
	CRUD_CREATE_FUND_ACCOUNTS_CODE_SUCCESS,
	CRUD_GET_FUND_ACCOUNTS_CODE,
	CRUD_GET_FUND_ACCOUNTS_CODE_CONFIG,
	CRUD_GET_FUND_ACCOUNTS_CODE_CONFIG_SUCCESS,
	CRUD_GET_FUND_ACCOUNTS_CODE_SUCCESS,
	CRUD_VALIDATE_FUND_ACCOUNTS_CODE,
	CRUD_VALIDATE_FUND_ACCOUNTS_CODE_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	fundAccountsCodeInfo: any
	fundAccountsCodeCreated: any
	fundAccountsCodeConfig: any
	fundAccountsCodeValidated: any
}

const initialState = {
	fundAccountsCodeInfo: null,
	fundAccountsCodeCreated: null,
	fundAccountsCodeConfig: null,
	fundAccountsCodeValidated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_FUND_ACCOUNTS_CODE:
			return { ...state, fundAccountsCodeInfo: null }
		case CRUD_GET_FUND_ACCOUNTS_CODE_SUCCESS:
			return { ...state, fundAccountsCodeInfo: action.payload }
		case CRUD_GET_FUND_ACCOUNTS_CODE_CONFIG:
			return { ...state, fundAccountsCodeConfig: null }
		case CRUD_GET_FUND_ACCOUNTS_CODE_CONFIG_SUCCESS:
			return { ...state, fundAccountsCodeConfig: action.payload }
		case CRUD_CREATE_FUND_ACCOUNTS_CODE:
			return { ...state, fundAccountsCodeCreated: null }
		case CRUD_CREATE_FUND_ACCOUNTS_CODE_SUCCESS:
			return { ...state, fundAccountsCodeCreated: action.payload }
		case CRUD_VALIDATE_FUND_ACCOUNTS_CODE:
			return { ...state, fundAccountsCodeValidated: null }
		case CRUD_VALIDATE_FUND_ACCOUNTS_CODE_SUCCESS:
			return { ...state, fundAccountsCodeValidated: action.payload }
		case CRUD_CLEAR_FUND_ACCOUNTS_CODE_STORE:
			return {
				...state,
				fundAccountsCodeConfig: null,
				fundAccountsCodeInfo: null,
				fundAccountsCodeValidated: null,
				fundAccountsCodeCreated: null
			}
		default:
			return state
	}
}

export default rootReducer
