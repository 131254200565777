import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputAutocomplete from 'components/form/inputAutocomplete'
import InputText from 'components/form/inputText'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

class VoucherFormInput extends Component {
	componentDidMount() {}

	render() {
		const {
			t,
			values,
			handleChange,
			handleBlur,
			setFieldValue,
			readOnly,
			handleSearch,
			auoptions,
			handleLoading,
			handleSelect,
			fields,
			inputsForm
		} = this.props

		return (
			<Row>
				{!readOnly && (
					<InputAutocomplete
						fields={fields}
						inputFormCol={{ sm: 11 }}
						label={'Cliente'}
						inputId={inputsForm.searchInpt}
						name={inputsForm.searchInpt}
						placeholder={t('voucher.form.insert_customer_criterion')}
						styles={{ width: '100%' }}
						colLabel={'col-sm-2'}
						colInput={'col-sm-10'}
						handleSearch={handleSearch}
						auoptions={auoptions}
						handleLoading={handleLoading}
						handleSelect={handleSelect}
						onBlur={handleBlur}
						onChange={handleChange}
						labelKey={'label'}
						disable={readOnly}
						refs={this.props.firtsRefs}
					/>
				)}
				{!readOnly && (
					<Col sm={1}>
						<FontAwesomeIcon icon={faPlus} />
					</Col>
				)}
				<InputText
					inputFormCol={{ sm: 11 }}
					fields={fields}
					label={t('voucher.form.business_name')}
					inputId={inputsForm.razonSocial}
					name={inputsForm.razonSocial}
					placeholder={t('voucher.form.insert_business_name')}
					styles={{ width: '100%' }}
					colLabel={'col-sm-2'}
					colInput={'col-sm-10'}
					disable={readOnly}
					value={values[inputsForm.razonSocial]}
					onBlur={handleBlur}
					onChange={(data) => {
						setFieldValue(inputsForm.razonSocial, data)
						handleChange()
					}}
				/>
				{!readOnly && (
					<Col sm={1}>
						<FontAwesomeIcon icon={faPencilAlt} />
					</Col>
				)}
				<InputText
					inputFormCol={{ sm: 11 }}
					fields={fields}
					label={t('voucher.form.code')}
					inputId={inputsForm.codigo}
					name={inputsForm.codigo}
					placeholder={t('voucher.form.insert_code_client')}
					colLabel={'col-sm-2'}
					colInput={'col-sm-10'}
					disable={readOnly}
					value={values[inputsForm.codigo]}
					onBlur={handleBlur}
					onChange={(data) => {
						setFieldValue(inputsForm.codigo, data)
						handleChange()
					}}
				/>

				<Row className={'col-11'} style={{ paddingRight: '0px' }}>
					<InputText
						inputFormCol={{ sm: 6 }}
						fields={fields}
						label={t('voucher.form.responsible_type')}
						inputId={inputsForm.tipoResp}
						name={inputsForm.tipoResp}
						placeholder={t('voucher.form.insert_responsible_type')}
						colLabel={'col-sm-4'}
						colInput={'col-sm-8'}
						disable={readOnly}
						styleLabel={{ paddingRight: '0px' }}
						divStyle={{ paddingLeft: '17px' }}
						onBlur={handleBlur}
						value={values[inputsForm.tipoResp]}
						onChange={(data) => {
							setFieldValue(inputsForm.tipoResp, data)
							handleChange()
						}}
					/>
					<InputText
						inputFormCol={{ sm: 6, style: { paddingRight: '0px' } }}
						fields={fields}
						label={t('voucher.form.cuit')}
						inputId={inputsForm.cuit}
						name={inputsForm.cuit}
						placeholder={t('voucher.form.insert_cuit')}
						colLabel={'col-sm-4'}
						colInput={'col-sm-8'}
						divStyle={{ paddingLeft: '10px' }}
						disable={readOnly}
						styleLabel={{ textAlign: 'right' }}
						value={values[inputsForm.cuit]}
						onBlur={handleBlur}
						onChange={(data) => {
							setFieldValue(inputsForm.cuit, data)
							handleChange()
						}}
					/>
				</Row>
			</Row>
		)
	}
}

export default withTranslation()(VoucherFormInput)
