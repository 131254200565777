import GlobalContainer from 'components/layout/globalContainer'
import VoucherInvolvementTable from 'components/voucherInvolvement/voucherInvolvementTable'
import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import withMenu from '../../components/common/withMenu'
import { P_AFEC_CANT_VTA } from '../../constants/ConfigProcessNames'

class VoucherInvolvement extends PureComponent {
	handleCallBackButton = () => {
		this.voucherConfirmation()
	}

	getShortcuts = () => {
		return [
			{
				hotkey: {
					charCode: '40',
					modifiers: ['arrowDown', 'arrowUp' /*, "arrowLeft", "arrowRight", */]
				},
				action: this.moveArrow,
				name: 'Arrow move',
				description: 'Arrow move'
			}
		]
	}

	getRowParams = (event) => {
		const params = event.target.id.split('-')
		const rowId = params[1]
		const field = params[0]

		return { rowId, field }
	}

	moveArrow = async (e) => {
		if (e.target.id) {
			const { rowId, field } = this.getRowParams(e)
			this.handleMoveArrow({ rowId, field, key: e.code })
		}
	}

	render() {
		return (
			<Row>
				<GlobalContainer
					codeProcess={P_AFEC_CANT_VTA}
					shortcuts={this.getShortcuts()}
					nextPage
					callBackButton={this.handleCallBackButton}
				>
					<VoucherInvolvementTable
						handleMoveArrow={(click) => (this.handleMoveArrow = click)}
						formConfirmation={(click) => (this.voucherConfirmation = click)}
					/>
				</GlobalContainer>
			</Row>
		)
	}
}

export default withTranslation()(withRouter(withMenu(VoucherInvolvement)))
