import { confirmValidation } from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import {
	IConfirmValidationParams,
	IGetValidationsSuccessResponse
} from 'models/HeaderDataValidation'
import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'

interface IHeaderDataValidationFormProps extends WithTranslation {
	fields: Array<IFieldStructure>
	t?: any
	callBackReturn: () => void
	idOperacion: string
	confirmValidation: (payload: IConfirmValidationParams) => void
	headerValidationFormRef: any
	validationList: IGetValidationsSuccessResponse
}

type State = {}

class HeaderDataValidationForm extends Component<
	IHeaderDataValidationFormProps,
	State
> {
	handleSubmitForm = (values: any) => {
		const { callBackReturn, idOperacion } = this.props

		this.props.confirmValidation({
			params: {
				clave: values.clave,
				idOperacion
			},
			callBackReturn
		})
	}

	render() {
		const { t, headerValidationFormRef, fields, validationList } = this.props
		const initialValues = !_.isEmpty(fields) ? getInitValues(fields) : {}
		const validationSchema = fields.length
			? getValidationSchema(fields, t, [])
			: {}

		const customFields = !_.isEmpty(fields)
			? fields.map((field) => {
					if (field.idCampo === 'clave') {
						return {
							...field,
							editable: !_.isEmpty(validationList)
								? validationList.controla_psw
								: 0
						}
					}
					return field
			  })
			: []

		return (
			<Container>
				<Formik
					ref={headerValidationFormRef}
					initialValues={initialValues}
					onSubmit={(values) => {
						this.handleSubmitForm(values)
					}}
					validationSchema={validationSchema}
					enableReinitialize={true}
					render={(props: FormikProps<any>) => {
						const { values, setFieldValue } = props

						const propsPassword = {
							inputFormCol: { sm: 6 },
							type: 'password',
							fields: customFields,
							label: 'code',
							inputId: 'clave',
							name: 'clave',
							colLabel: 'col-sm-6',
							colInput: 'col-sm-6',
							value: values.clave,
							divStyle: { paddingRight: '0px' },
							onChange: (data: string) => setFieldValue('clave', data)
						}
						return (
							<Fragment>
								<Row>
									<Col sm={12}>
										<NotificationError
											errors={props.errors}
											touched={props.touched}
										/>
									</Col>
									<Col sm={12}>
										<Form className="voucher-info-form">
											<Row className={`col-12`}>
												<InputText {...propsPassword} />
											</Row>
										</Form>
									</Col>
								</Row>
							</Fragment>
						)
					}}
				/>
			</Container>
		)
	}
}

const mapStateToProps = ({ headerDataValidation }: any) => {
	const { validationList } = headerDataValidation

	return { validationList }
}

const mapDispatchToProps = { confirmValidation }

export default withTranslation()(
	connect(mapStateToProps, mapDispatchToProps)(HeaderDataValidationForm)
)
