import { faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmModal from 'components/common/ConfirmModal'
import React, { Component, Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

interface ButtonProps extends WithTranslation {
	view: any
	edit: any
	delete: any
	row: any
	deleteMessage: string
	deleteAction: any
	deleteTitle: string
	disableForm: any
	t?: any
}

class CrudTableButtons extends Component<ButtonProps> {
	constructor(props: ButtonProps) {
		super(props)
		this.state = {}
	}

	render() {
		const { t } = this.props
		return (
			<Fragment>
				<FontAwesomeIcon
					className={'mr-3'}
					icon={faEye}
					style={{ cursor: 'pointer' }}
					color={'#007bff'}
					title={t('global.view')}
					onClick={() => this.props.view(this.props.row)}
				/>
				{!this.props.disableForm && (
					<Fragment>
						<FontAwesomeIcon
							icon={faPen}
							style={{ cursor: 'pointer' }}
							color={'#007bff'}
							title={t('global.edit')}
							onClick={() => this.props.edit(this.props.row)}
						/>
						<ConfirmModal
							messageBody={this.props.deleteMessage}
							onSubmitModal={() => this.props.deleteAction(this.props.row)}
							labelButton={
								<FontAwesomeIcon title={t('global.delete')} icon={faTrash} />
							}
							modalTitle={this.props.deleteTitle}
							typeButton={'light'}
							buttonStyle={{
								backgroundColor: '#007bff00',
								borderColor: '#007bff00'
							}}
						/>
					</Fragment>
				)}
			</Fragment>
		)
	}
}

export default withTranslation()(CrudTableButtons)
