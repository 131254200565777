import AudioComponent from 'components/common/audioComponent'
import BarcodeInputText from 'components/form/barcodeInputText'
import InputButton from 'components/form/inputButton'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import {
	CONFIRM_PRODUCT_STOCK_PROVIDER_ERROR,
	SEARCH_BARCODE_PROVIDER_ERROR
} from 'constants/ActionsTypes'
import { addPrevValidations } from 'lib/AxiosInterceptors'
import { debugMessage, setInputFocus } from 'lib/Utils'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './stockProvider.module.scss'

const fields = [
	{
		idCampo: 'barCode',
		descrip: '',
		label: 'Código barra',
		mascara: '',
		editable: 1,
		visible: 1,
		requerido: 0,
		valid: null
	},
	{
		idCampo: 'number_from',
		descrip: '',
		label: 'Desde',
		mascara: '',
		editable: 1,
		visible: 1,
		requerido: 0,
		valid: null
	},
	{
		idCampo: 'number_quantity',
		descrip: '',
		label: 'Cantidad',
		mascara: '',
		editable: 1,
		visible: 1,
		requerido: 0,
		valid: null
	},
	{
		idCampo: 'lote',
		descrip: '',
		label: 'Partida',
		mascara: '',
		editable: 1,
		visible: 1,
		requerido: 0,
		valid: null
	},
	{
		idCampo: 'fec_vto',
		descrip: '',
		label: 'Fec. Vto.',
		mascara: 'FechaCorta',
		editable: 1,
		visible: 1,
		requerido: 0,
		valid: null
	},
	{
		idCampo: 'cuf',
		descrip: '',
		label: 'CUF',
		mascara: '',
		editable: 1,
		visible: 1,
		requerido: 0,
		valid: null
	},
	{
		idCampo: 'cant',
		descrip: '',
		label: 'Cantidad',
		editable: 1,
		visible: 1,
		requerido: 0,
		valid: null
	}
]
export default class StockInputs extends Component {
	constructor(props) {
		super(props)
		this.state = {
			nserie: '',
			serieCant: props.params ? props.params.cantidad : 0,
			barCode: '',
			cufValue: '',
			lastCuf: 0,
			disableCuf: false,
			lote: '',
			nSeries: true,
			optionsCUF: [],
			showLote: true,
			showDateVto: true
		}
		this.firstRefInput = React.createRef()
		this.refNoSeries = React.createRef()
		this.refInputDate = React.createRef()
		this.refInputLote = React.createRef()

		addPrevValidations((e) => {
			debugMessage(
				e.request.responseURL.includes('busq_nserie'),
				'validacion del error',
				e.request.responseURL
			)
			return e.request.responseURL.includes('busq_nserie')
		})

		addPrevValidations((e) => {
			debugMessage(
				e.request.responseURL.includes('Stock/sel_partida/ingresos/aplicar'),
				'validacion del error',
				e.request.responseURL
			)
			return e.request.responseURL.includes(
				'Stock/sel_partida/ingresos/aplicar'
			)
		})

		addPrevValidations((e) => {
			debugMessage(
				e.request.responseURL.includes('Stock/sel_partida/ingresos/validar'),
				'validación del error',
				e.request.responseURL
			)
			return e.request.responseURL.includes(
				'Stock/sel_partida/ingresos/validar'
			)
		})
	}

	componentDidUpdate = (prevProps) => {
		const {
			applyStock,
			searchItems,
			searchItemError,
			loadStock,
			productStock,
			applyStockError,
			confirmedStockError,
			validateStock
		} = this.props

		if (productStock !== prevProps.productStock && productStock) {
			const nSeries = !!(productStock && productStock.ind_series === 1)
			const optionsCUF = productStock
				? productStock.ubicaciones.map((ubi) => ({
						id: ubi.cuf,
						label: ubi.cuf
				  }))
				: []
			const showLote = !!(productStock && productStock.ind_lote === 1)
			const showDateVto = !!(productStock && productStock.ind_vto === 1)

			if (nSeries) {
				setInputFocus(this.firstRefInput)
			} else if (this.refInputLote.current) {
				setInputFocus(this.refInputLote)
			}
			this.setState({ nSeries, optionsCUF, showLote, showDateVto })
		}

		if (applyStock !== prevProps.applyStock && applyStock) {
			// const nSeries = applyStock && applyStock.ind_series === 1 ? true : false;
			const optionsCUF = applyStock
				? applyStock.ubicaciones.map((ubi) => ({ id: ubi.cuf, label: ubi.cuf }))
				: []
			const showLote = !!(applyStock && applyStock.ind_lote === 1)
			const showDateVto = !!(applyStock && applyStock.ind_vto === 1)
			setInputFocus(this.refNoSeries)
			this.setState({ optionsCUF, showLote, showDateVto })
		}

		if (
			validateStock !== prevProps.validateStock &&
			validateStock &&
			!validateStock.Resultado.Resultado
		) {
			const params = {
				showError: true,
				errorMessage: validateStock.Resultado.Mens_error || '',
				type: 'danger'
			}
			this.props.handleSetErrorBarCode(params)
		}

		if (loadStock !== prevProps.loadStock && loadStock) {
			setInputFocus(this.refInputLote)
			if (this.refInputDate && this.refInputDate.current) {
				this.refInputDate.current.clear() // clear date value
			}
			this.setState({ serieCant: 0, fec_vto: '', lote: '' }) // clear all inputs forms.
		}

		if (prevProps.searchItemError !== searchItemError && searchItemError) {
			if (searchItemError.error === SEARCH_BARCODE_PROVIDER_ERROR) {
				const { data } = searchItemError
				const params = {
					showError: true,
					errorMessage: `${searchItemError.errorMessage}  Código: ${this.state.barCode}`,
					type: 'danger'
				}
				this.handleSoundError()
				this.setState({
					barCode: '',
					nserie: data.nserie,
					lote: data.lote,
					fec_vto: data.fec_vto,
					serieCant: 1
				})
				this.props.handleSetErrorBarCode(params)
				this.handleSetFocusErrorField(data)
			}
		}

		if (prevProps.searchItems !== searchItems && searchItems) {
			const params = {
				showError: false,
				type: 'success'
			}
			this.handleSoundSuccess()
			this.setState({ barCode: '' })
			this.props.handleSetErrorBarCode(params)
		}

		if (prevProps.applyStockError !== applyStockError && applyStockError) {
			const params = {
				showError: true,
				errorMessage: applyStockError.Resultado.Mens_error,
				type: 'danger'
			}
			this.props.handleSetErrorBarCode(params)
		}

		if (
			confirmedStockError !== prevProps.confirmedStockError &&
			confirmedStockError &&
			confirmedStockError.error === CONFIRM_PRODUCT_STOCK_PROVIDER_ERROR
		) {
			const params = {
				showError: true,
				errorMessage: confirmedStockError.errorMessage,
				type: 'danger'
			}
			this.props.handleSetErrorBarCode(params)
		}
	}

	handleSetFocusErrorField = (dataError) => {
		if (dataError.ind_lote === 1 && !dataError.lote) {
			this.refInputLote.current.focus()
		} else if (dataError.ind_fvto === 1 && dataError.fec_vto === '') {
			this.refInputDate.current.setFocus()
		}
	}

	/**
	 * function called in event button apply
	 */
	handleLoad = () => {
		const { idOperacion, referenceItem } = this.props
		const { cufValue, serieCant, fec_vto, lastCuf, lote } = this.state
		const formatDate = 'YYYY-MM-DD'

		const params = {
			idOperacion,
			it: referenceItem,
			lote,
			cantidad: serieCant,
			nserie: '',
			fec_vto:
				fec_vto !== '' && fec_vto ? moment(fec_vto).format(formatDate) : '',
			ultimo_cuf_ingreso: lastCuf
		}

		if (!lastCuf) {
			params.cuf = cufValue
		}

		this.props.loadProductStockProvider(params)
	}

	/**
	 * function called in event button apply
	 */
	handleValidate = () => {
		const { idOperacion, referenceItem } = this.props
		const { cufValue, serieCant, nserie, lastCuf, fec_vto, lote } = this.state
		const formatDate = 'YYYY-MM-DD'

		const params = {
			idOperacion,
			it: referenceItem,
			cant: serieCant,
			nserie,
			fec_vto:
				fec_vto !== '' && fec_vto ? moment(fec_vto).format(formatDate) : '',
			ultimo_cuf_ingreso: lastCuf,
			lote,
			cuf: cufValue
		}

		const fieldsToValidate = [
			{
				label: 'Cantidad',
				value: serieCant || ''
			},
			{
				label: 'Desde',
				value: nserie || ''
			}
		]

		if (this.isEmpty(fieldsToValidate)) {
			this.props.validateProductStockProvider(params)
			this.setState({ nserie: '' })
			if (this.refInputDate && this.refInputDate.current) {
				// this.refInputDate.current.clear()
			}
		}
	}

	/**
	 * to validate required empty fields
	 * @param {array} fields
	 * @returns boolean
	 */
	isEmpty = (fields) => {
		const { t } = this.props
		let showError = false
		const messages = []
		fields.forEach((field) => {
			if (field.value === '') {
				showError = true
				messages.push(
					t('validation-required', { field: `El campo ${field.label}` })
				)
			}
		})

		if (showError) {
			const params = {
				showError: true,
				errorMessage: messages,
				type: 'danger'
			}

			this.props.handleSetErrorBarCode(params)
		} else {
			this.props.handleSetErrorBarCode({ showError: false })
		}

		return !showError
	}

	handleSearchBarcode = () => {
		const { idOperacion, referenceItem } = this.props
		const { barCode, lastCuf, cufValue } = this.state

		const params = {
			idOperacion,
			it: referenceItem,
			codbarra: barCode,
			ultimo_cuf_ingreso: lastCuf,
			page_number: 1,
			page_size: 10
		}

		if (!lastCuf) {
			params.cuf = cufValue
		}

		this.props.searchBarcodeProvider(params)
	}

	handleSoundSuccess = async () => {
		this.soundSuccess()
	}

	handleSoundError = async () => {
		this.soundError()
	}

	handleLastCufChange = (event) => {
		const { checked } = event.target
		this.setState({ lastCuf: checked ? 1 : 0, disableCuf: checked })
	}

	render() {
		const { t } = this.props
		const {
			nSeries,
			optionsCUF,
			showLote,
			showDateVto,
			disableCuf,
			cufValue,
			fec_vto
		} = this.state

		return (
			<Container className={` mt-2 ${styles.containerDisplay}`}>
				<Row>
					<AudioComponent
						handleSuccess={(click) => (this.soundSuccess = click)}
						handleError={(click) => (this.soundError = click)}
					/>
					<Col sm={10} className={`${styles.containerBarcode} mb-2 `}>
						<Row>
							{showLote && (
								<InputText
									fwRef={this.refInputLote}
									inputFormCol={{ sm: showDateVto ? 6 : 12 }}
									fields={fields}
									label={'label'}
									inputId={'lote'}
									name={'lote'}
									placeholder={''}
									styles={{ width: '100%', border: '1px solid #ccc' }}
									colLabel={'col-sm-2'}
									colInput={'col-sm-10'}
									onBlur={() => {}}
									disable={true}
									value={this.state.lote}
									onChange={(data) => {
										this.setState({ lote: data })
									}}
									divStyle={{}}
								/>
							)}
							{showDateVto && (
								<InputText
									fwRef={this.refInputDate}
									inputFormCol={{ sm: showLote ? 6 : 12 }}
									fields={fields}
									label={'label'}
									inputId={'fec_vto'}
									name={'fec_vto'}
									placeholder={''}
									styles={{ width: '100%', border: '1px solid #ccc' }}
									colLabel={'col-sm-3'}
									colInput={'col-sm-9'}
									disable={true}
									onBlur={() => {}}
									value={fec_vto}
									onChange={(data) => {
										this.setState({ fec_vto: data })
									}}
									divStyle={{}}
								/>
							)}
						</Row>
						<Row>
							<Col sm={6}>
								<Row>
									<Col sm={2} className={'text-left'}>
										<input
											type="checkbox"
											className={' ml-4 mt-2'}
											value={1}
											onChange={this.handleLastCufChange}
										/>
									</Col>
									<Col sm={9} className={'text-left'}>
										<label style={{ fontSize: 14, fontFamily: 'inherit' }}>
											{'Última CUF Ingresada'}
										</label>
									</Col>
								</Row>
							</Col>
							<InputDropdown
								inputFormCol={{ sm: 6 }}
								fields={fields}
								label={t('affectationAmounts.table.show')}
								inputId={'cuf'}
								name={'cuf'}
								placeholder={t('client.form.select_sample')}
								styles={{ width: '100%' }}
								styleLabel={{ textAlign: 'left' }}
								colLabel={'col-sm-3'}
								colInput={'col-sm-9'}
								options={optionsCUF}
								value={cufValue}
								disabledInput={disableCuf}
								tooltip
								onChange={(data) => {
									this.setState({ cufValue: data.target.value })
								}}
							/>
							{!nSeries && (
								<Fragment>
									<InputText
										inputFormCol={{ sm: 6 }}
										fields={fields}
										label={'label'}
										inputId={'number_quantity'}
										name={'number_quantity'}
										placeholder={''}
										styles={{ width: '100%', border: '1px solid #ccc' }}
										colLabel={'col-sm-2'}
										colInput={'col-sm-10'}
										disable={true}
										onBlur={() => {}}
										value={this.state.serieCant}
										onChange={(data) => {
											this.setState({ serieCant: data })
										}}
										handleEnterKey={() => this.handleLoad()}
										divStyle={{}}
									/>
									<Col sm={6} className={'text-right mb-1'}>
										<InputButton
											valueButton={t('Stock.buttons.apply')}
											type={'primary'}
											onClick={() => this.handleLoad()}
											customStyle={{ minWidth: '125px' }}
										/>
									</Col>
								</Fragment>
							)}
						</Row>
					</Col>
					{nSeries && (
						<Col sm={10} className={styles.containerBarcode}>
							<Row>
								<BarcodeInputText
									fwRef={this.firstRefInput}
									inputFormCol={{ sm: 10 }}
									fields={fields}
									label={'label'}
									inputId={'barCode'}
									name={'barCode'}
									placeholder={''}
									styles={{ width: '100%' }}
									colLabel={'col-sm-2'}
									colInput={'col-sm-10 pl-0'}
									onBlur={() => {}}
									handleEnterKey={() => this.handleSearchBarcode()}
									value={this.state.barCode}
									onChange={(data) => this.setState({ barCode: data })}
									activeListeners
								/>
								<Col sm={12}>
									<label className={styles.subtitle}>
										{t('Stock.continuous_number')}
									</label>
								</Col>
								<InputText
									fwRef={this.refNoSeries}
									inputFormCol={{ sm: 6 }}
									fields={fields}
									label={'label'}
									inputId={'number_from'}
									name={'number_from'}
									placeholder={''}
									styles={{ width: '100%', border: '1px solid #ccc' }}
									colLabel={'col-sm-3'}
									colInput={'col-sm-9'}
									onBlur={() => {}}
									disable={true}
									value={this.state.nserie}
									onChange={(data) => {
										this.setState({ nserie: data })
									}}
									divStyle={{}}
								/>
								<InputText
									inputFormCol={{ sm: 4 }}
									fields={fields}
									label={'label'}
									inputId={'number_quantity'}
									name={'number_quantity'}
									placeholder={''}
									styles={{ width: '100%', border: '1px solid #ccc' }}
									colLabel={'col-sm-3'}
									colInput={'col-sm-9'}
									disable={true}
									onBlur={() => {}}
									value={this.state.serieCant}
									onChange={(data) => {
										this.setState({ serieCant: data })
									}}
									handleEnterKey={() => this.handleValidate()}
									divStyle={{}}
								/>
								<Col sm={2} className={'text-right mb-1'}>
									<InputButton
										valueButton={t('Stock.buttons.apply')}
										type={'primary'}
										onClick={() => this.handleValidate()}
										customStyle={{ minWidth: '125px' }}
									/>
								</Col>
							</Row>
						</Col>
					)}
				</Row>
			</Container>
		)
	}
}
