import AutomaticPurchase from 'components/AutomaticPurchase/AutomaticPurchase'
import withMenu from 'components/common/withMenu'
import GlobalContainer from 'components/layout/globalContainer'
import { P_AUTOMATIC_PURCHASE_ORDER } from 'constants/ConfigProcessNames'
import { Ihistory } from 'models/NavigationInterface'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

interface IAutomaticPurchaseOrderProps {
	history: Ihistory
	match: any
}

export class AutomaticPurchaseOrder extends Component<IAutomaticPurchaseOrderProps> {
	myRef: any = React.createRef()

	handleCallBackButton = () => {
		if (this.myRef.submitForm) {
			this.myRef.submitForm()
		}
	}

	render() {
		const containerProps = {
			codeProcess: P_AUTOMATIC_PURCHASE_ORDER,
			callBackButton: this.handleCallBackButton,
			nextPage: true
		}

		const propsModule = {
			providerTableRef: (ref: any) => (this.myRef = ref)
		}

		return (
			<Row>
				<GlobalContainer {...containerProps}>
					<AutomaticPurchase {...propsModule} />
				</GlobalContainer>
			</Row>
		)
	}
}

const mapStateToProps = ({ auth }: any) => ({})

const mapDispatchToProps = {}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withMenu(AutomaticPurchaseOrder)))
