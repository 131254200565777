import { confirmHeaderAttributes, getFormAttributes } from 'actions'
import CollapseButton from 'components/common/collapseButton'
import GenericInputForm from 'components/form/genericInputForm'
import { API_FORMAT_DATE } from 'constants/ApiFormats'
import { P_HEADER_ENTRY } from 'constants/ConfigProcessNames'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { getFiltersData } from 'lib/Utils'
import { IConfigFieldsForm } from 'models/Budget'
import {
	IAttributeComVtaItem,
	IConfirmAttributesPayload,
	IGetFormAttributesParams,
	IGetFormAttributesResponse
} from 'models/HeaderEntry'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Collapse } from 'reactstrap'
import _ from 'underscore'
import HeaderEntryFormInputs from './HeaderEntryFormInputs'

interface IHeaderEntryComponentProps extends WithTranslation {
	headerEntryFormRef: any
	idOperacion?: string
	config: IConfigFieldsForm
	callBackReturn?: () => void
	t?: any
	getFormAttributes: (payload: IGetFormAttributesParams) => void
	headerFormAttributes: IGetFormAttributesResponse
	confirmHeaderAttributes: (payload: IConfirmAttributesPayload) => void
}

interface IHeaderEntryComponentState {
	openCollapse: boolean
	genericData: any
}

class HeaderEntryComponent extends Component<
	IHeaderEntryComponentProps,
	IHeaderEntryComponentState
> {
	constructor(props: IHeaderEntryComponentProps) {
		super(props)
		this.state = { openCollapse: false, genericData: [] }
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.getFormAttributes({
			idOperacion: idOperacion || ''
		})
	}

	handleCallBack = () => {}

	handleSubmitForm = (values: any) => {
		const { callBackReturn, idOperacion } = this.props
		const { genericData } = this.state

		this.props.confirmHeaderAttributes({
			callBackReturn,
			params: {
				...values,
				idOperacion,
				fecha: values.fecha ? moment(values.fecha).format(API_FORMAT_DATE) : '',
				atrib_comp_inv: genericData
			}
		})
	}

	toggleCollapse = () => {
		this.setState((state) => ({ openCollapse: !state.openCollapse }))
	}

	/**
	 * function called when the values are changed
	 */
	handleChangeGeneric = (data: any, formikProps: FormikProps<any>) => {
		const { genericData } = this.state
		const indexField = _.findIndex(genericData, {
			cod_atributo: data.cod_atributo
		})

		if (indexField === -1) {
			genericData.push(data)
		} else {
			genericData[indexField] = data
		}

		this.setState({ genericData })
	}

	handleSearchAuto = () => {}

	/**
	 * render generic fields
	 * @param formikProps
	 * @returns
	 */
	renderGenericFields = (formikProps: FormikProps<any>) => {
		const { headerFormAttributes, config } = this.props
		const result: any = []
		const configGeneric = _.findWhere(config.campos, {
			idCampo: 'atrib_comp_inv'
		})

		if (
			headerFormAttributes &&
			!_.isEmpty(headerFormAttributes.atrib_comp_inv)
		) {
			headerFormAttributes.atrib_comp_inv.forEach(
				(element: IAttributeComVtaItem) => {
					result.push(
						<GenericInputForm
							style={{ marginLeft: -5 }}
							config={{ ...configGeneric, ...element }}
							key={element.cod_atrib}
							handleChange={(data: any) => {
								this.handleChangeGeneric(data, formikProps)
							}}
							handleSearchAuto={this.handleSearchAuto}
							autodata={[]}
						/>
					)
				}
			)
		}

		return result
	}

	render() {
		const { headerEntryFormRef, config, t } = this.props
		const { openCollapse } = this.state
		const fields = config
			? getFiltersData(config.campos, { agrupador: 'formulario' })
			: []
		const initialValues = !_.isEmpty(fields) ? getInitValues(fields) : {}

		const validationSchema = fields.length ? getValidationSchema(fields, t) : {}

		return (
			!_.isEmpty(fields) && (
				<Formik
					ref={headerEntryFormRef}
					initialValues={initialValues}
					onSubmit={(values) => {
						this.handleSubmitForm(values)
					}}
					validationSchema={validationSchema}
					enableReinitialize={true}
					render={(props: FormikProps<any>) => {
						return (
							<Fragment>
								<Col sm={12}>
									<Form>
										<HeaderEntryFormInputs
											formikProps={props}
											fields={fields}
											t={t}
										/>
										<Row className={'col-12'}>
											<Col sm={1}>
												<CollapseButton
													onPress={this.toggleCollapse}
													status={openCollapse}
												/>
											</Col>
											<Col sm={11}>
												<div className="dropdown-divider col-11 p-2" />
											</Col>
										</Row>
										<Collapse style={{ width: '100%' }} isOpen={openCollapse}>
											{this.renderGenericFields(props)}
										</Collapse>
									</Form>
								</Col>
							</Fragment>
						)
					}}
				/>
			)
		)
	}
}

const mapStateToProps = ({ voucher, headerEntry }: any) => {
	const config = voucher.config ? voucher.config[P_HEADER_ENTRY] : null
	const { headerFormAttributes } = headerEntry
	return { config, headerFormAttributes }
}

const mapDispatchToProps = {
	getFormAttributes,
	confirmHeaderAttributes
}

export default withTranslation()(
	connect(mapStateToProps, mapDispatchToProps)(HeaderEntryComponent)
)
