import { getInventorySerieData } from 'actions'
import CommonTable from 'components/common/commonTable'
import InputButton from 'components/form/inputButton'
import InputText from 'components/form/inputText'
import {
	IAbmStructureResponse,
	IFieldStructure
} from 'constants/valuesInterfaces/interfaces'
import { getDefaultColumns } from 'lib/FormUtils'
import { getFiltersData } from 'lib/Utils'
import { IGetInventorySerieDataParams } from 'models/SearchVoucher'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'

interface SerieModalProps extends WithTranslation {
	getInventorySerieData: (payload: IGetInventorySerieDataParams) => void
	fields: Array<IFieldStructure>
	it: number
	id: string
	authUser?: any
	inventorySerie: any
	handleClose: () => void
	fieldsConfig: IAbmStructureResponse
	t?: any
}

interface SerieModalState {
	serie: string
}

class SearchVouchersInventorySerieModal extends Component<
	SerieModalProps,
	SerieModalState
> {
	constructor(props: SerieModalProps) {
		super(props)
		this.state = {
			serie: ''
		}
	}

	componentDidMount = () => {
		const { id, it, authUser } = this.props
		this.props.getInventorySerieData({
			it,
			page_number: 1,
			page_size: 10,
			idEmpresa: authUser.userCompany,
			nimovpr: id
		})
	}

	/**
	 * to get all pagination options
	 * @returns object
	 */
	getPaginationOptions = () => {
		const { inventorySerie } = this.props
		return {
			pageStartIndex: 1,
			sizePerPage: inventorySerie.page_size,
			page: inventorySerie.page_number,
			totalSize: inventorySerie.total_count
		}
	}

	/**
	 * to handle pagination
	 */
	handleChangeTable = (type: string, pagination: any) => {
		if (type === 'pagination') {
			this.handleFilterTableSerie({
				page_size: pagination.sizePerPage,
				page_number: pagination.page
			})
		}
	}

	/**
	 * to send params to api filter
	 * @param filters
	 */
	handleFilterTableSerie = (filters: any) => {
		const { id, it, authUser } = this.props
		this.props.getInventorySerieData({
			it,
			idEmpresa: authUser.userCompany,
			nimovpr: id,
			...filters
		})
	}

	/**
	 * to filter series data
	 */
	handleFilterSerie = () => {
		const { serie } = this.state
		this.handleFilterTableSerie({
			page_size: 10,
			page_number: 1,
			serie
		})
		// this.setState({ serie: '' })  // waiting
	}

	render() {
		const { fields, authUser, inventorySerie, fieldsConfig, t } = this.props
		const { serie } = this.state

		const fieldsFilter = fieldsConfig
			? getFiltersData(fieldsConfig.campos, {
					agrupador: 'form_inv_series_sel'
			  })
			: []

		const filterProps = {
			inputFormCol: { sm: 12 },
			fields: fieldsFilter,
			label: 'filter',
			inputId: 'serie',
			id: 'serie',
			name: 'serie',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			divStyle: { paddingLeft: '17px' },
			value: serie,
			onChange: (value: string) => {
				this.setState({ serie: value })
			}
		}

		const propsTable = {
			remote: true,
			columns: _.isEmpty(fields) ? [] : getDefaultColumns(fields, { authUser }),
			data: _.isEmpty(inventorySerie)
				? []
				: inventorySerie.series.map((serie: string, index: number) => ({
						id: index,
						series: serie
				  })),
			rowClasses: 'theme.tableRow',
			headerClasses: 'theme.tableHeader',
			paginationOptions: this.getPaginationOptions,
			onTableChange: this.handleChangeTable
		}

		return (
			<Fragment>
				<Row>
					<Col sm={{ span: 8, offset: 1 }}>
						<InputText {...filterProps} />
					</Col>
					<Col>
						<InputButton
							valueButton={t('search_product_detail.filter')}
							onClick={() => this.handleFilterSerie()}
							type={'primary'}
							customStyle={{ minWidth: '105px' }}
						/>
					</Col>
					<Col sm={{ span: 10, offset: 1 }}>
						<CommonTable {...propsTable} />
					</Col>
				</Row>
				<Row>
					<Col className="text-center">
						<InputButton
							valueButton={'Cancelar'}
							onClick={() => this.props.handleClose()}
							type={'primary'}
							customStyle={{ minWidth: '105px' }}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth, searchVoucher, configCrud }: any) => {
	const { authUser } = auth
	const { fieldsConfig } = configCrud
	const { inventorySerie } = searchVoucher
	return { authUser, inventorySerie, fieldsConfig }
}

const connectForm = connect(mapStateToProps, { getInventorySerieData })(
	SearchVouchersInventorySerieModal
)

export default withTranslation()(connectForm)
