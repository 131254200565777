import {
	clearSearchVoucherParams,
	searchVouchers,
	setVoucherActiveTab
} from 'actions'
import CommonTabs from 'components/abmUtils/CommonTabs'
import { API_FORMAT_DATE } from 'constants/ApiFormats'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { Formik } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { debugMessage } from 'lib/Utils'
import { ISearchVoucherParams } from 'models/SearchVoucher'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'
import AccountantSearchFormInput from './AccountantSearchFormInput'
import AdvancedSearchFormInput from './AdvancedSearchFormInput'
import AuditSearchFormInput from './AuditSearchFormInput'
import BasicSearchFormInputs from './BasicSearchFormInputs'
import InventorySearchFormInput from './InventorySearchFormInput'
import TreasurySearchFormInput from './TreasurySearchFormInput'

type SearchProps = {
	t: any
	searchVouchers: Function
	selectedTab: string
	authUser: any
	setFilters: Function
	fields: Array<IFieldStructure>
	searchParams?: ISearchVoucherParams
	activeSearchVoucherTab: string
	setVoucherActiveTab?: any
	clearSearchVoucherParams: () => void
}

type SearchState = {}

class VoucherSearchForm extends Component<SearchProps, SearchState> {
	/**
	 * to send search params to api
	 * @param values
	 */
	handleSubmit = (values: any) => {
		const { searchVouchers, authUser, setFilters } = this.props
		const fec_desde =
			values.fec_desde !== ''
				? moment(values.fec_desde).format(API_FORMAT_DATE)
				: ''
		const fec_hasta =
			values.fec_hasta !== ''
				? moment(values.fec_hasta).format(API_FORMAT_DATE)
				: ''

		const fec_vto =
			values.fec_vto !== ''
				? moment(values.fec_vto).format(API_FORMAT_DATE)
				: ''

		const fpago_desde =
			values.fpago_desde !== ''
				? moment(values.fpago_desde).format(API_FORMAT_DATE)
				: ''

		const fpago_hasta =
			values.fpago_hasta !== ''
				? moment(values.fpago_hasta).format(API_FORMAT_DATE)
				: ''

		const fmovim_desde =
			values.fmovim_desde !== ''
				? moment(values.fmovim_desde).format(API_FORMAT_DATE)
				: ''
		const fmovim_hasta =
			values.fmovim_hasta !== ''
				? moment(values.fmovim_hasta).format(API_FORMAT_DATE)
				: ''

		const filters = {
			...values,
			page_number: 1,
			page_size: 10,
			fec_hasta,
			fec_desde,
			fec_vto,
			fpago_hasta,
			fpago_desde,
			fmovim_desde,
			fmovim_hasta
		}

		setFilters(filters)

		searchVouchers({
			idEmpresa: authUser.userCompany,
			...filters
		})
	}

	handleCancel = () => {
		debugMessage({ message: 'hola' })
	}

	handleSetActiveKey = (tab: string) => {
		this.props.setVoucherActiveTab(tab)
	}

	/**
	 * set fecha in init formik
	 */
	setDatesForm = (values: any) => {
		const dateTimeFormat = 'T00:00:00'

		const fec_desde = _.isEmpty(values.fec_desde)
			? ''
			: new Date(`${values.fec_desde}${dateTimeFormat}`)
		const fec_hasta = _.isEmpty(values.fec_hasta)
			? ''
			: new Date(`${values.fec_hasta}${dateTimeFormat}`)
		const fec_vto = _.isEmpty(values.fec_vto)
			? ''
			: new Date(`${values.fec_vto}${dateTimeFormat}`)
		const fpago_desde = _.isEmpty(values.fpago_desde)
			? ''
			: new Date(`${values.fpago_desde}${dateTimeFormat}`)
		const fpago_hasta = _.isEmpty(values.fpago_hasta)
			? ''
			: new Date(`${values.fpago_hasta}${dateTimeFormat}`)
		const fmovim_desde = _.isEmpty(values.fmovim_desde)
			? ''
			: new Date(`${values.fmovim_desde}${dateTimeFormat}`)
		const fmovim_hasta = _.isEmpty(values.fmovim_hasta)
			? ''
			: new Date(`${values.fmovim_hasta}${dateTimeFormat}`)

		return {
			...values,
			fec_desde,
			fec_hasta,
			fec_vto,
			fpago_hasta,
			fpago_desde,
			fmovim_desde,
			fmovim_hasta
		}
	}

	render() {
		const { fields, t, searchParams, activeSearchVoucherTab } = this.props

		const validationSchema = fields.length
			? getValidationSchema(fields, t, [])
			: {}

		const initialValues = _.isEmpty(searchParams)
			? fields.length
				? getInitValues(fields)
				: {}
			: this.setDatesForm(searchParams)
		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values: any, actions: any) => {
					this.handleSubmit(values)
				}}
				validationSchema={validationSchema}
				enableReinitialize={true}
				render={(props) => {
					const {
						values,
						handleChange,
						handleBlur,
						setFieldValue,
						setFieldTouched
					} = props
					return (
						<Fragment>
							<Col sm={12}>
								{/* <NotificationError errors={props.errors} touched={props.touched} /> */}
							</Col>
							<Form
								onSubmit={(e) => props.handleSubmit(e as any)}
								className="voucher-info-form"
							>
								<Col sm={12}>
									<CommonTabs
										getActiveKey={this.handleSetActiveKey}
										customTabs={[
											{
												key: 'basic',
												title: t('search_voucher.tabs.basic'),
												content: (
													<BasicSearchFormInputs
														{...{
															values,
															handleChange,
															handleBlur,
															setFieldValue,
															setFieldTouched,
															fields,
															readOnly: false,
															t
														}}
													/>
												)
											},
											{
												key: 'advanced',
												title: t('search_voucher.tabs.advanced'),
												content: (
													<AdvancedSearchFormInput
														{...{
															values,
															handleChange,
															handleBlur,
															setFieldValue,
															setFieldTouched,
															fields,
															readOnly: false,
															t
														}}
													/>
												)
											},
											{
												key: 'inventory',
												title: t('search_voucher.tabs.inventory'),
												content: (
													<InventorySearchFormInput
														{...{
															values,
															handleChange,
															handleBlur,
															setFieldValue,
															setFieldTouched,
															fields,
															readOnly: false,
															t
														}}
													/>
												)
											},
											{
												key: 'treasury',
												title: t('search_voucher.tabs.treasury'),
												content: (
													<TreasurySearchFormInput
														{...{
															values,
															handleChange,
															handleBlur,
															setFieldValue,
															setFieldTouched,
															fields,
															readOnly: false,
															t
														}}
													/>
												)
											},
											{
												key: 'accountant',
												title: t('search_voucher.tabs.accountant'),
												content: (
													<AccountantSearchFormInput
														{...{
															values,
															handleChange,
															handleBlur,
															setFieldValue,
															setFieldTouched,
															fields,
															readOnly: false,
															t
														}}
													/>
												)
											},
											{
												key: 'audit',
												title: t('search_voucher.tabs.audit'),
												content: (
													<AuditSearchFormInput
														{...{
															values,
															handleChange,
															handleBlur,
															setFieldValue,
															setFieldTouched,
															fields,
															readOnly: false,
															t
														}}
													/>
												)
											}
										]}
										defaultActiveKey={activeSearchVoucherTab || 'basic'}
									/>
								</Col>
								<Row className={'pt-1 pr-2'}>
									<Col className={'offset-md-8 text-center mt-2'}>
										<Button style={{ minWidth: '105px' }} type={'submit'}>
											{'Buscar'}
										</Button>
									</Col>
									<Col className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '105px' }}
											type={'button'}
											onClick={() => {
												this.props.clearSearchVoucherParams()
												props.resetForm()
											}}
										>
											{'Limpiar'}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					)
				}}
			/>
		)
	}
}

const mapStateToProps = ({ auth, searchVoucher }: any) => {
	const { authUser } = auth
	const { activeSearchVoucherTab } = searchVoucher
	return { authUser, activeSearchVoucherTab }
}

const connectForm = connect(mapStateToProps, {
	searchVouchers,
	setVoucherActiveTab,
	clearSearchVoucherParams
})(VoucherSearchForm)
export default connectForm
