import {
	CRUD_GET_FUND_ACCOUNT_BANK_RECOINCILIATION,
	CRUD_GET_FUND_ACCOUNT_BANK_RECOINCILIATION_SUCCESS,
	CRUD_UPDATE_FUND_ACCOUNT_BANK_RECOINCILIATION,
	CRUD_UPDATE_FUND_ACCOUNT_BANK_RECOINCILIATION_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	bankReconciliationInfo: any
	valueUpdated: any
}

const initialState = {
	bankReconciliationInfo: null,
	valueUpdated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_FUND_ACCOUNT_BANK_RECOINCILIATION:
			return { ...state, bankReconciliationInfo: null }
		case CRUD_GET_FUND_ACCOUNT_BANK_RECOINCILIATION_SUCCESS:
			return { ...state, bankReconciliationInfo: action.payload }
		case CRUD_UPDATE_FUND_ACCOUNT_BANK_RECOINCILIATION:
			return { ...state, valueUpdated: null }
		case CRUD_UPDATE_FUND_ACCOUNT_BANK_RECOINCILIATION_SUCCESS:
			return { ...state, valueUpdated: action.payload }
		default:
			return state
	}
}

export default rootReducer
