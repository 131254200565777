import { crudGetValuesList, getTableVoucherProcess } from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { FormRefsContext } from 'context/FormRefsContext'
import { Formik } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { renderFormInputs } from 'lib/FormUtils'
import { ICrudsApi } from 'models/CrudInterface'
import { IGetTableVoucherProcess } from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import { Col, Form } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'

interface FieldsSearchFormProps {
	nioperacion: number
	disableForm: boolean
	getTableVoucherProcess: (params: IGetTableVoucherProcess) => void
	crudGetValuesList: (params: ICrudsApi) => void
	setProcess: (value: number) => void
	itemInfo: any
	fields: any
	handleSubmit: any
	voucherProcessData: any
	t: any
}

class FieldsSearchForm extends Component<FieldsSearchFormProps> {
	componentDidMount = () => {
		const { nioperacion, getTableVoucherProcess } = this.props
		getTableVoucherProcess({ nioperacion: nioperacion, incluye_ninguno: 1 })
	}

	handleChange = (event: any, props: any) => {
		props.setFieldValue('lk_proceso', event.target.value)
		this.props.setProcess(event.target.value)
	}

	comboDataField = (data: any) => {
		const values: any = []
		data &&
			_.each(data.lista, (opt) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	renderInputs = (fields: any, props: any) => {
		const { disableForm, voucherProcessData } = this.props

		_.forEach(fields, (field) => {
			const fieldId = field.idCampo
			if (fieldId === 'lk_proceso') {
				field.tipo = 'combo'
				field.colLabel = ''
				field.colInput = ''
				field.valores = voucherProcessData
					? this.comboDataField(voucherProcessData)
					: []
				field.onChange = (event: any) => this.handleChange(event, props)
			}
		})

		return renderFormInputs(props, fields, [], disableForm)
	}

	/**
	 * to get data of sellers
	 */

	render() {
		const { fields, t, handleSubmit, itemInfo } = this.props

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})

		const validationSchema = fields.length
			? getValidationSchema(fields, t, [])
			: {}

		return (
			<FormRefsContext.Consumer>
				{({ formRef, cancelButtonRef }) => (
					<Formik
						ref={formRef}
						initialValues={initialValues}
						onSubmit={(values, actions) => {
							handleSubmit(values)
						}}
						validationSchema={validationSchema}
						enableReinitialize={true}
						render={(props) => (
							<Fragment>
								<Col sm={12}>
									<NotificationError
										errors={props.errors}
										touched={props.touched}
									/>
								</Col>
								<Form className="voucher-info-form">
									{fields && this.renderInputs(fields, props)}
								</Form>
							</Fragment>
						)}
					/>
				)}
			</FormRefsContext.Consumer>
		)
	}
}

const mapStateToProps = ({ tableCalls }: any) => {
	const { voucherProcessData } = tableCalls
	return {
		voucherProcessData
	}
}

const connectForm = connect(mapStateToProps, {
	crudGetValuesList,
	getTableVoucherProcess
})(FieldsSearchForm)

export default withTranslation()(withRouter(connectForm))
