import { globalPointersFields } from 'constants/crudsConfig/crudLedgerAccountsConstants/crudLedgerAccountsGlobalPointers'
import { renderInput } from 'lib/FormUtils'
import { ICrudGenericFields } from 'models/CrudInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'
import { pointersFields } from '../../constants/crudsConfig/crudLedgerAccountsConstants/crudLedgerAccountsPointersFields'

type CrudCustomerAttrFieldProps = {
	field: any
	formikProps: any
	crudProps: any
}

type CrudCustomerAttrFieldState = {
	fieldConfig: ICrudGenericFields
	defaultValue?: string
	idField?: any
	value?: any
}

class CrudCustomerAttrField extends Component<
	CrudCustomerAttrFieldProps,
	CrudCustomerAttrFieldState
> {
	constructor(props: CrudCustomerAttrFieldProps) {
		super(props)
		this.state = {
			fieldConfig: {
				id: '',
				type: '',
				width: '',
				autoComplete: '',
				comboData: '',
				maxChars: 0,
				dependsOn: {
					id: '',
					values: [
						{
							idValue: '',
							disabled: false
						}
					]
				}
			}
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field } = this.props
		const idField = field.idCampo.trim()
		const data = _.find(pointersFields, (field: ICrudGenericFields) => {
			return field.id === idField
		})

		const addData = data || globalPointersFields

		addData && this.setState({ fieldConfig: addData })
		addData && this.setState({ idField: idField })
	}

	handleBlur = async () => {
		const { formikProps } = this.props
		const { idField } = this.state
		formikProps.setTouched({
			...formikProps.touched,
			[idField]: true
		})
	}

	renderInputs = (
		field: any,
		formikProps: any,
		disableForm: boolean,
		fieldConfig: ICrudGenericFields
	) => {
		const addData = fieldConfig

		addData &&
			(field = {
				...field,
				tipo: addData.type,
				colInput: addData.width,
				autoComplete: addData.autoComplete,
				onBlur: this.handleBlur,
				customProps: {
					maxLength: addData.maxChars
				}
			})

		if (!field.editable) field.disabled = true
		if (field.visible) {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { field, formikProps, crudProps } = this.props
		const { fieldConfig } = this.state
		return (
			<Fragment>
				{this.renderInputs(
					field,
					formikProps,
					crudProps.disableForm,
					fieldConfig
				)}
			</Fragment>
		)
	}
}

export default CrudCustomerAttrField
