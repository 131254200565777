import {
	getConfigShoppingCart,
	getConfigVoucher,
	getProductsCart,
	removeProductCart
} from 'actions'
import ShoppingCartResumen from 'components/ShoppingCart/ShoppingCartResumen'
import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import ProductsTotalResume from './productsTotalResume'
import styles from './shoppingCart.module.css'
import ShoppingCartTable from './shoppingCartTable'

class ShoppingCart extends Component {
	constructor(props, context) {
		super(props, context)

		this.state = {
			show: false
		}
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.getConfigShoppingCart({ idOperacion })
		this.props.getProductsCart({ idOperacion, page_number: 1, page_size: 10 })
	}

	handleChangeTable = (type, pagination) => {
		const { idOperacion } = this.props
		this.props.getProductsCart({
			idOperacion,
			page_number: pagination.page,
			page_size: pagination.sizePerPage
		})
	}

	render() {
		const {
			showModal,
			handleClose,
			t,
			theme,
			productsCart,
			idOperacion,
			configCart,
			authUser,
			headInfo
		} = this.props

		return (
			<Modal
				show={showModal}
				size="xl"
				onHide={handleClose}
				aria-labelledby="ModalHeader"
			>
				<Modal.Header closeButton className={theme.divHeader}>
					<Modal.Title id="ModalHeader" className={theme.divTitle}>
						{t('shoppingCart.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{headInfo && <ShoppingCartResumen theme={theme} />}

					{productsCart && !_.isEmpty(configCart) && (
						<div className={theme.scrollContainer}>
							<ShoppingCartTable
								authUser={authUser}
								config={configCart}
								cartProducts={productsCart}
								idOperacion={idOperacion}
								handleChangeTable={this.handleChangeTable}
								removeProductCart={this.props.removeProductCart}
								t={t}
							/>
						</div>
					)}
					{productsCart && (
						<ProductsTotalResume
							formatCol={{ span: 4, offset: 8 }}
							data={productsCart}
						/>
					)}
				</Modal.Body>
			</Modal>
		)
	}
}

const mapStateToProps = ({ product, voucher, shoppingCart, auth }) => {
	const { config, headInfo } = voucher
	const { productsCart } = product
	const { configCart } = shoppingCart
	const { authUser } = auth
	return { productsCart, config, configCart, authUser, headInfo }
}

const connectForm = connect(mapStateToProps, {
	getProductsCart,
	getConfigVoucher,
	removeProductCart,
	getConfigShoppingCart
})(ShoppingCart)
export default themr(
	'LoadItemsTableStyles',
	styles
)(withTranslation()(connectForm))
