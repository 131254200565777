import Axios from 'axios'
import {
	IConfirmValidationData,
	IGetValidationsParams
} from 'models/HeaderDataValidation'

const urlBase = 'VtaCabValid'

export const getValidationsApi = async (params: IGetValidationsParams) => {
	const response = await Axios.get(urlBase, { params })
	return response.data
}

export const confirmValidationApi = async (params: IConfirmValidationData) => {
	const response = await Axios.post(`${urlBase}/confirmar`, params)
	return response.data
}
