import CrudFundAccountTabsDataContents from 'components/crudFundsAccounts/CrudFundAccountTabsDataContents'
import { CRUD_FUNDS_ACCOUNTS_DATA } from 'utils/RoutePath'

export const fundsAccountsConfig = {
	id: 'fundsAccountsCrud',
	key: 'nicfondo',
	descrip: 'descrip',
	searchMethod: 'get',
	category: 'A',
	translation: 'funds_accounts_data',
	dataUrl: CRUD_FUNDS_ACCOUNTS_DATA,
	urlApi: 'abm_fon_cuentas',
	component: CrudFundAccountTabsDataContents
}
