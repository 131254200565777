import { ICrudGenericFields } from 'models/CrudInterface'

export const principalFields: Array<ICrudGenericFields> = [
	{
		id: 'cod_usu',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'nombre',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'email',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'telefono',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'clave',
		type: 'hidden',
		labelWidth: 'col-sm-3',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'habilitado',
		type: 'check',
		labelWidth: 'col-sm-3',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
