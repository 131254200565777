import React, { Component } from 'react'
import successSound from '../../sounds/38799107_correct-answer-double-02.mp3'
import errorSound from '../../sounds/38806364_wrong-answer-notification-03.mp3'

class AudioComponent extends Component {
	componentDidMount = () => {
		this.props.handleSuccess(this.handleSuccess)
		this.handleSuccess = this.handleSuccess.bind(this)

		this.props.handleError(this.handleError)
		this.handleError = this.handleError.bind(this)
	}

	handleSuccess() {
		const audioEl = document.getElementsByClassName('audio-element-success')[0]
		audioEl.play()
	}

	handleError() {
		const audioEl = document.getElementsByClassName('audio-element-error')[0]
		audioEl.play()
	}

	render() {
		return (
			<div>
				<audio className={'audio-element-success'}>
					<source src={successSound} />
					<track default kind="captions" />
				</audio>
				<audio className={'audio-element-error'}>
					<source src={errorSound} />
					<track default kind="captions" />
				</audio>
			</div>
		)
	}
}

export default AudioComponent
