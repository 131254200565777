import CommonTable from 'components/common/commonTable'
import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import _ from 'underscore'
class MainDataAttributes extends Component {
	/**
	 * to render las columns
	 * @returns
	 */
	getColumns = (fields) => {
		const rows = fields.map((field) => {
			const idField = field.idCampo.trim()
			return {
				dataField: idField,
				text: field.label || '',
				align: 'center',
				headerAlign: 'center',
				hidden: !field.visible
			}
		})

		return rows
	}

	render() {
		const { fields, items } = this.props
		const propsTableAttributes = {
			remote: false,
			keyField: 'catributo',
			columns: !_.isEmpty(fields) ? this.getColumns(fields) : [],
			data: items || [],
			paginationOptions: {},
			onTableChange: this.handleChangeTable
		}

		return (
			<Col sm={6}>
				{!_.isEmpty(fields) && <CommonTable {...propsTableAttributes} />}
			</Col>
		)
	}
}

export default MainDataAttributes
