import {
	cancelProductStockProvider,
	clearStoreStockProvider,
	confirmProductStockProvider,
	getProductStockProvider,
	loadProductStockProvider,
	searchBarcodeProvider,
	validateProductStockProvider
} from 'actions'
import NotificationMessage from 'components/common/notificationMessage'
import { addPrevValidations } from 'lib/AxiosInterceptors'
import React, { Component } from 'react'
import { Col, Modal } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import StockFormProvider from './stockFormProvider'
class StockProvider extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showError: false,
			showErrorTable: false,
			page_number: 1,
			page_size: 10,
			filterShow: 'T',
			isConfirm: false
		}

		addPrevValidations((e) => {
			return e.request
				? e.request.responseURL.includes('Stock/sel_partida/ingresos/confirmar')
				: false
		})

		addPrevValidations((e) => {
			return e.request.responseURL.includes('Stock/sel_partida/ingresos/carga')
		})
	}

	componentDidUpdate = (prevProps) => {
		const {
			confirmedStock,
			canceledStock,
			idOperacion,
			referenceItem,
			showStock,
			t,
			loadStockError,
			params
		} = this.props

		if (prevProps.showStock !== showStock && showStock) {
			const apiParams = {
				idOperacion,
				page_number: 1,
				page_size: 10,
				niprod: params.Niprod,
				cant: params.cantidad,
				mostrar: 'T',
				it: referenceItem
			}

			if (params.cuf) {
				apiParams.cuf = params.cuf
			}

			this.props.getProductStockProvider(apiParams)

			this.handleCloseErrorTable()
		}

		if (prevProps.showStock !== showStock && !showStock) {
			this.handleCloseError()
		}

		if (
			prevProps.confirmedStock !== confirmedStock &&
			confirmedStock &&
			confirmedStock.Resultado
		) {
			this.setState({ isConfirm: true })
			this.props.handleClose()
		}

		if (
			prevProps.canceledStock !== canceledStock &&
			canceledStock &&
			canceledStock.Resultado
		) {
			this.setState({
				showErrorTable: true,
				errorMessageTable: t('Stock.delete_cart_product'),
				type: 'danger'
			})
			this.props.handleClose()
			this.props.handleCloseCartMessage()
		}

		if (prevProps.loadStockError !== loadStockError && loadStockError) {
			const params = {
				showError: true,
				errorMessage: loadStockError.Resultado
					? loadStockError.Resultado.Mens_error
					: '',
				type: 'danger'
			}
			this.handleSetErrorBarCode(params)
		}
	}

	/**
	 * to show error message.
	 * @param {object} params
	 */
	handleSetErrorBarCode = (params) => {
		this.setState({ ...params })
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	handleCloseErrorTable = () => {
		this.setState({ showErrorTable: false })
	}

	handleChangeTable = (type, pagination) => {
		const { idOperacion, params, referenceItem } = this.props
		const { filterShow } = this.state
		if (type === 'pagination') {
			this.props.getProductStockProvider({
				idOperacion,
				page_number: pagination.page,
				page_size: pagination.sizePerPage,
				niprod: params.Niprod,
				cant: params.cantidad,
				mostrar: filterShow,
				it: referenceItem
			})
			this.setState({
				page_number: pagination.page,
				page_size: pagination.sizePerPage
			})
		}
	}

	handleChangeFilter = (filter) => {
		const { idOperacion, params, referenceItem } = this.props
		const { page_number, page_size } = this.state

		this.props.getProductStockProvider({
			idOperacion,
			page_number,
			page_size,
			niprod: params.Niprod,
			cant: params.cantidad,
			mostrar: filter,
			it: referenceItem
		})

		this.setState({ filterShow: filter })
	}

	handleConfirmStock = () => {
		const { idOperacion, referenceItem } = this.props
		this.props.confirmProductStockProvider({ idOperacion, it: referenceItem })
	}

	handleCancelStock = () => {
		this.props.handleClose()
	}

	handleCancelApi = () => {
		const { idOperacion, referenceItem } = this.props
		this.props.cancelProductStockProvider({ idOperacion, it: referenceItem })
	}

	render() {
		const { t, productStock, showStock, idOperacion } = this.props

		return (
			<div style={{ cursor: 'pointer' }}>
				<Col md={12} className={'mb-1'}>
					<NotificationMessage
						showError={this.state.showErrorTable}
						errorMessage={this.state.errorMessageTable}
						type={this.state.type}
						handleCloseError={this.handleCloseErrorTable}
					/>
				</Col>
				{
					<Modal
						show={showStock}
						onHide={this.props.handleClose}
						aria-labelledby={'ModalHeader'}
						size="xl"
					>
						<Modal.Header closeButton>
							<Modal.Title id={'ModalHeader'}>
								{t('Stock.provider_title')}
							</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							<Col md={12} className={'mb-1'}>
								<NotificationMessage
									{...this.state}
									handleCloseError={this.handleCloseError}
								/>
							</Col>
							<StockFormProvider
								idOperacion={idOperacion}
								productStock={productStock}
								handleChangeTable={this.handleChangeTable}
								handleChangeFilter={this.handleChangeFilter}
								handleConfirmStock={this.handleConfirmStock}
								handleCancelStock={this.handleCancelStock}
								handleCancelApi={this.handleCancelApi}
								handleSetErrorBarCode={this.handleSetErrorBarCode}
								{...this.props}
							/>
						</Modal.Body>
					</Modal>
				}
			</div>
		)
	}
}

const mapStateToProps = ({ vouchertype, common, stockProvider }) => {
	const { message } = common
	const { voucherType } = vouchertype
	const {
		productStock,
		validateStock,
		loadStock,
		applyStock,
		searchItems,
		searchItemError,
		confirmedStock,
		canceledStock,
		applyStockError,
		confirmedStockError,
		loadStockError
	} = stockProvider
	return {
		voucherType,
		message,
		productStock,
		validateStock,
		confirmedStock,
		canceledStock,
		searchItems,
		searchItemError,
		loadStock,
		applyStock,
		applyStockError,
		confirmedStockError,
		loadStockError
	}
}

const connectForm = connect(mapStateToProps, {
	getProductStockProvider,
	confirmProductStockProvider,
	cancelProductStockProvider,
	searchBarcodeProvider,
	loadProductStockProvider,
	validateProductStockProvider,
	clearStoreStockProvider
})(StockProvider)

export default withTranslation()(connectForm)
