import { setTableDataInvolvement } from 'actions'
import CollapseButton from 'components/common/collapseButton'
import CommonTable from 'components/common/commonTable'
import NotificationMessage from 'components/common/notificationMessage'
import InputText from 'components/form/inputText'
import InputPriceUnit from 'components/loadItems/inputPriceUnit'
import { validateField } from 'lib/FieldValidations'
import { getSelectFilter, getValueMask } from 'lib/MaskValues'
import {
	handleIsRendeTableFinish,
	handleSetFocus,
	isInputEnabled,
	isValueChange
} from 'lib/TableUtils'
import { getFiltersData } from 'lib/Utils'
import React, { Fragment, PureComponent } from 'react'
import { Col } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import ExpandInvolvementForm from './ExpandInvolvementForm'
import styles from './involvementTable.module.scss'

class InvolvementTable extends PureComponent {
	constructor(props) {
		super(props)
		this.inputRefs = {}
		this.state = {
			rowSelected: [],
			showError: false,
			errorMessage: '',
			selectedCheck: [],
			checksByPage: [],
			showStock: false,
			referenceItem: null,
			tableColumns: [],
			tableData: null,
			updateDataTable: [],
			fieldsTable: [],
			fieldsExpandRow: [],
			fieldsPriceEstTable: [],
			fieldsPriceTable: [],
			fieldsPriceForm: [],
			expanded: []
		}

		this.rowErrors = []
		this.firstRefs = null
		this.primaryKey = 'id'
	}

	componentDidMount = () => {
		const { idOperacion, config, products } = this.props
		this.props.formConfirmation(this.handleConfirmation)
		this.props.handleMoveArrow(this.handleMoveArrow)

		if (idOperacion) {
			this.handleConfirmation = this.handleConfirmation.bind(this)
			this.handleMoveArrow = this.handleMoveArrow.bind(this)
		}

		if (!_.isEmpty(products)) {
			this.setState({ tableData: products.Items }) // Initialize data table
		}

		if (!_.isEmpty(config) && !_.isEmpty(products)) {
			// Initialize table columns
			this.setFieldsStates(config)
		}
	}

	componentDidUpdate = (prevProps) => {
		const { products, canceledStock, productsUpdate, config } = this.props

		/***************/
		this.validateFieldsConfigData(prevProps.config, config)
		/***************/

		if (!_.isEqual(products, prevProps.products) && !_.isEmpty(products)) {
			this.setState({ tableData: [...products.Items] }) // Initialize data table
			this.setFieldsStates(config)
		}

		if (canceledStock !== prevProps.canceledStock && canceledStock) {
			this.setState({ selectedCheck: [], rowSelected: [] })
		}

		if (
			!_.isEqual(prevProps.productsUpdate, productsUpdate) &&
			!_.isEmpty(productsUpdate)
		) {
			const tableColumns = this.getColumns()
			this.setExpandNewRows(productsUpdate)

			if (_.isArray(productsUpdate)) {
				productsUpdate.forEach((field) => {
					if (
						field.error &&
						field.type_error === 1 &&
						!this.rowErrors[field.nimovcli]
					) {
						this.rowErrors[field.nimovcli] = true
						this.setState({
							showError: 'true',
							errorMessage: 'No se soporta selección Manual de Stock.'
						})
					}
				})
			}

			this.setState({ tableColumns, tableData: [...productsUpdate] })
		}
	}

	/**
	 * to separe to fields of form
	 * @param {*} prevConfig
	 * @param {*} config
	 */
	validateFieldsConfigData = (prevConfig, config) => {
		if (!_.isEqual(prevConfig, config) && !_.isEmpty(config.campos)) {
			this.setFieldsStates(config)
		}
	}

	/**
	 * set config fields state
	 * @param {*} config
	 */
	setFieldsStates = (config) => {
		const fieldsTable = getFiltersData(config.campos, {
			agrupador: 'grilla',
			adicional: 0
		})

		const fieldsExpandRow = getFiltersData(config.campos, {
			agrupador: 'grilla',
			adicional: 1
		})

		const fieldsPriceEstTable = getFiltersData(config.campos, {
			agrupador: 'vprec.est'
		})

		const fieldsPriceTable = getFiltersData(config.campos, {
			agrupador: 'vprec.lprec'
		})

		const fieldsPriceForm = getFiltersData(config.campos, {
			agrupador: 'vprec.cab'
		})
		const tableColumns = this.getColumns(fieldsTable)

		this.setState({
			fieldsTable,
			fieldsExpandRow,
			fieldsPriceEstTable,
			fieldsPriceTable,
			fieldsPriceForm,
			tableColumns
		})
	}

	/**
	 * To move focus to other rows.
	 * @params {rowId, field, key}
	 */
	handleMoveArrow = (params) => {
		const { rowId, field, key } = params
		const reverse = key === 'ArrowUp'
		const nextRow = this.getNextProductId(rowId, reverse)
		handleSetFocus(field, nextRow, this.inputRefs)
	}

	/**
	 * to get id of the next row
	 * @params idProduct
	 * @params revers >> to validate if down or up
	 */
	getNextProductId = (idProduct, reverse = false) => {
		const { Items } = this.props.products

		let result = 0
		Items.forEach((prd, index) => {
			if (prd.id === idProduct) {
				result = !reverse ? index + 1 : index - 1 // Back o next row
			}
		})
		if (Items[result]) {
			return Items[result].id ? Items[result].id : null
		} else {
			return null
		}
	}

	/**
	 * To save and update data table to validate changes..
	 * @params id row
	 * @params fieldId
	 * @params value
	 */
	handleUpdateDataTable = (rowId, fieldId, value) => {
		const { updateDataTable } = this.state
		const updateIndex = _.findIndex(updateDataTable, { rowId })
		if (updateIndex === -1) {
			const params = { rowId }
			params[fieldId] = value
			updateDataTable.push(params)
		} else {
			updateDataTable[updateIndex][fieldId] = value
		}
		this.setState({ updateDataTable })
	}

	/**
	 * to call confirmation api and redirect to next page
	 */
	handleConfirmation = () => {
		const { callBackReturn } = this.props
		const items = this.getSelectedCheck()
		const { idOperacion } = this.props
		this.props.handleAffectedConfirm({
			items: { idOperacion, items },
			callBackReturn
		})
	}

	/**
	 * to get column for table.
	 */
	getColumns = (fields = {}) => {
		const { readOnly, products, filtersKey, theme } = this.props
		const { fieldsTable } = this.state
		const renderFields = !_.isEmpty(fields) ? fields : fieldsTable
		const rows = renderFields.map((field) => {
			const campoId = field.idCampo.trim()
			return {
				dataField: campoId,
				text:
					filtersKey[campoId] && products
						? ''
						: field.label === null
						? ''
						: field.label,
				align: 'center',
				headerAlign: 'center',
				title: true,
				headerStyle: this.getStyleColumn(field),
				hidden: !field.visible,
				filter: getSelectFilter(
					field,
					products,
					filtersKey,
					this.props,
					theme.inputFilter
				),
				formatter:
					(field.editable || field.mascara || campoId === 'modif_pcio') &&
					!readOnly
						? (cell, row, rowIndex) => {
								return this.renderFormat(field, cell, row)
						  }
						: null
			}
		})

		return rows
	}

	/**
	 * to close error modal
	 */
	handleCloseError = () => {
		this.setState({ showError: false })
	}

	/**
	 * to get header styles
	 * @param field name of column
	 */

	getStyleColumn = (field) => {
		const idField = field.idCampo.trim()
		let style = {}

		switch (idField) {
			case 'Cod_unid':
			case 'cod_mone':
			case 'Presentacion':
			case 'neto':
				style = { width: '5%' }
				break
			case 'Desc_prod':
				style = { width: '20%' }
				break
			case 'Fec_emis':
			case 'Fec_vto':
			case 'Cod_prod':
			case 'Comprob_nro':
			case 'Cant_pend':
			case 'Cant_afec':
				style = { width: '8%' }
				break
			case 'cant_saldo':
			case 'imp_afec':
				style = { width: '10%' }
				break
			case 'pcio_unit':
			case 'comprob_desc':
				style = { width: '12%' }
				break
			case 'modif_pcio':
				style = { width: '1%' }
				break

			default:
				style = { width: '10%' }
				break
		}

		if (field.requerido) {
			style.color = 'red'
		}

		return style
	}

	/**
	 * to validate data and call api.
	 * @param row
	 * @param field
	 * @param value
	 */
	validateFieldQuantity = (row, field, value) => {
		const { idOperacion, keysValidate } = this.props
		const { tableData } = this.state
		const fieldId = field.idCampo.trim()
		const selected = this.state.selectedCheck ? this.state.selectedCheck : []

		const newItem = {
			id: row[this.primaryKey],
			Nitem: row.nitem,
			Cant_afec: value,
			niprod: row.niprod
		}
		newItem[keysValidate.label] = row[keysValidate.field]

		const items = [newItem]
		let message = ''
		const rows = this.state.rowSelected ? this.state.rowSelected : []
		let saveItem = true

		if (
			isValueChange(
				row[this.primaryKey],
				fieldId,
				value,
				this.primaryKey,
				tableData
			)
		) {
			rows.forEach((toSave) => {
				// Update
				if (toSave[this.primaryKey] === row[this.primaryKey]) {
					saveItem = false
					toSave.Cant_afec = parseFloat(value)
				}
			})

			if (saveItem) {
				rows.push(newItem)
			}

			if (!validateField(value, field.valid)) {
				message = `El campo ${field.label} es requerido.`
				this.setState({ showError: true, errorMessage: message })
			} else {
				const paramsSubCal = {
					idOperacion,
					Nitem: row.nitem,
					Cant_afec: value,
					Neto: row.neto
				}
				paramsSubCal[keysValidate.label] = row[keysValidate.field]

				if (value === '0') {
					selected.forEach((delet, index) => {
						if (delet === row[this.primaryKey]) {
							selected.splice(index, 1)
						}
					})

					this.setState({ selectedCheck: selected, rowSelected: rows })
				}
				this.props.handleCantValidate({ idOperacion, items })
				// this.props.handleAffectedSubCalculation(paramsSubCal)
			}
		}
	}

	/**
	 * to validate neto field
	 * @param row
	 * @param field
	 **/

	validateFieldNeto = (row, field, value) => {
		const { idOperacion, keysValidate } = this.props
		const params = {
			idOperacion,
			nitem: row.nitem,
			niprod: row.niprod,
			cod_unid: row.cod_unid,
			cant_afec: row.cant_afec,
			precio_unit: row.precio_unit,
			neto: value
		}

		params[keysValidate.field] = row[keysValidate.field]

		if (field.valid) {
			let message = ''
			if (!validateField(value, field.valid)) {
				message = `El campo ${field.label} es requerido.`
				this.setState({ showError: true, errorMessage: message })
			} else {
				this.props.handleAffectedSubCalculation(params)
			}
		}
	}

	/**
	 * to render input in the table
	 * @param field
	 * @param value
	 * @param row
	 */
	renderFormat = (field, value, row) => {
		const {
			fieldsPriceEstTable,
			fieldsPriceTable,
			fieldsPriceForm,
			fieldsTable
		} = this.state
		const configPrice = _.findWhere(fieldsTable, { idCampo: 'modif_pcio' })
		const fieldId = field.idCampo.trim()

		let result = null
		const inputError = value === 'error_input'
		const customValue = value === 'error_input' ? '' : value
		const inputStyle =
			fieldId === 'cant_afec' || fieldId === 'precio_unit' || fieldId === 'neto'
				? { textAlign: 'right' }
				: {}

		if (field.editable && !this.inputRefs[fieldId]) {
			this.inputRefs[fieldId] = {}
		}

		if (field.editable && !this.inputRefs[fieldId][row[this.primaryKey]]) {
			const customRef = React.createRef()
			this.inputRefs[fieldId][row[this.primaryKey]] = customRef
			if (this.firstRefs === null) {
				this.firstRefs = customRef
			}
		}

		const optionsInput = {
			fwRef: field.editable
				? this.inputRefs[fieldId][row[this.primaryKey]]
				: null,
			inputFormCol: { sm: 12 },
			fields: [{ ...field, label: false }],
			label: false,
			inputId: `${fieldId}`,
			id: `${fieldId}-${row[this.primaryKey]}`,
			name: `${fieldId}-${row[this.primaryKey]}`,
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			divStyle: { paddingLeft: '17px' },
			disable: false,
			disabledInput: !!(row.Cant_afec <= 0 && fieldId === 'pcio_unit'),
			value: customValue,
			showError: inputError,
			styles: inputStyle,
			rowStyle: { marginBottom: '5px' },
			onChange: (value) => {
				this.handleUpdateDataTable(row[this.primaryKey], fieldId, value)
			}
		}

		if (field.editable) {
			result = (
				<InputText
					{...optionsInput}
					handleEnterKey={(e, value) => {
						const nextRow = this.getNextProductId(row[this.primaryKey])
						if (nextRow === null) {
							this.handleInputOnblur(row, field, value) // call apis from the last row
						} else {
							const isEnable = isInputEnabled(fieldId, nextRow, this.inputRefs)

							if (fieldId === 'pcio_unit' && !isEnable) {
								handleSetFocus('Cant_afec', nextRow, this.inputRefs)
							} else {
								handleSetFocus(fieldId, nextRow, this.inputRefs)
							}
						}
						return true
					}}
					onBlur={(value) => {
						this.handleInputOnblur(row, field, value)
					}}
				/>
			)
		} else if (fieldId === 'modif_pcio') {
			result = (
				<InputPriceUnit
					idOperacion={this.props.idOperacion}
					handleSubmitPrice={(row, price) =>
						this.props.validateFieldPrice(row, '', price)
					}
					row={row}
					showPriceIcon={
						row.Cant_afec > 0 &&
						!_.isEmpty(configPrice) &&
						configPrice.visible === 1
					}
					fieldsPriceEstTable={fieldsPriceEstTable}
					fieldsPriceTable={fieldsPriceTable}
					fieldsPriceForm={fieldsPriceForm}
				/>
			)
		} else if (field.mascara) {
			result = getValueMask(value, field.mascara, this.props)
		}

		return result
	}

	/**
	 * to call apis depende of input in the onBlur event
	 * @param {*} row
	 * @param {*} field
	 * @param {*} value
	 */
	handleInputOnblur = (row, field, value) => {
		const fieldId = field.idCampo.trim()

		if (fieldId === 'Cant_afec') {
			this.validateFieldQuantity(row, field, value)
		} else if (fieldId === 'neto') {
			this.validateFieldNeto(row, field, value)
		} else if (fieldId === 'pcio_unit') {
			this.props.validateFieldPrice(row, field, value)
		} else {
			const params = { niprod: row.niprod, idcampo: fieldId, value }
			this.props.setTableDataInvolvement([params])
		}
	}

	/**
	 * to get expand options
	 * @param {*} row
	 * @returns
	 */
	getExpandRow = () => {
		const { fieldsExpandRow } = this.state
		return {
			renderer: (row) => this.renderExpandRow(row),
			showExpandColumn: !_.isEmpty(fieldsExpandRow),
			expandByColumnOnly: true,
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				return <CollapseButton status={isAnyExpands} />
			},
			expandColumnRenderer: ({ expanded, rowKey }) => {
				return <CollapseButton status={expanded} />
			},
			expanded: this.state.expanded,
			onExpand: this.handleOnExpand
		}
	}

	/**
	 *to expand new row
	 */
	setExpandNewRows = () => {
		const { expanded, fieldsExpandRow } = this.state
		const { cantValidate } = this.props
		if (
			cantValidate &&
			cantValidate.Items.length === 1 &&
			!_.isEmpty(fieldsExpandRow)
		) {
			const newRow = cantValidate.Items[0]
			if (newRow.Cant_afec > 0) {
				this.setState({ expanded: [...expanded, newRow.id] })
			}
		}
	}

	handleOnExpand = (row, isExpand) => {
		const { expanded } = this.state
		if (isExpand) {
			this.setState(() => ({
				expanded: [...expanded, row.id]
			}))
		} else {
			this.setState(() => ({
				expanded: expanded.filter((x) => x !== row.id)
			}))
		}
	}

	/**
	 * to render html expand in row table
	 * @param {*} row
	 * @returns
	 */
	renderExpandRow = (row) => {
		const { idOperacion } = this.props
		const { fieldsExpandRow } = this.state
		return (
			<ExpandInvolvementForm
				row={row}
				fields={fieldsExpandRow}
				idOperacion={idOperacion}
			/>
		)
	}

	getSelectedCheck = () => {
		const { selectedCheck } = this.state
		const { products } = this.props

		const items = []
		if (products) {
			products.Items.forEach((row) => {
				selectedCheck.forEach((check) => {
					if (row[this.primaryKey] === check) {
						items.push({
							Nimovcli: row.nimovcli,
							Nitem: row.nitem,
							Cant_afec: row.Cant_afec
						})
					}
				})
			})
		}

		return items
	}

	/**
	 * to get all id of rows are checked.
	 * @returns
	 */
	getRowsCantAffect = () => {
		const { products, productsUpdate } = this.props

		const itemsProd =
			!_.isEmpty(productsUpdate) && _.isArray(productsUpdate)
				? productsUpdate
				: products.Items

		const result = []

		if (!_.isEmpty(itemsProd)) {
			itemsProd.forEach((row) => {
				if (row.Cant_afec && row.Cant_afec > 0) {
					result.push(row[this.primaryKey])
				}
			})
		}

		return result
	}

	/**
	 * to get ids of rows are not expand
	 * @returns
	 */
	noExpandRows = () => {
		const { products } = this.props
		const result = []
		if (products && !_.isEmpty(products.Items)) {
			products.Items.forEach((row) => {
				if (!row.Cant_afec) {
					result.push(row[this.primaryKey])
				}
			})
		}

		return result
	}

	/**
	 * when use check in the table row
	 * @param row
	 * @param isSelect boolean
	 */

	handleOnSelectCheck = (row, isSelect) => {
		const { keysValidate, idOperacion } = this.props
		const selected = this.state.selectedCheck ? this.state.selectedCheck : []
		// const rows = this.state.rowSelected ? this.state.rowSelected : [];
		const rows = []

		let updateRecord = false

		if (isSelect) {
			// add new
			const cantSend = row.Cant_afec ? row.Cant_afec : row.Cant_pend

			const newRow = {
				id: row[this.primaryKey],
				Nitem: row.nitem,
				niprod: row.niprod,
				Cant_afec: cantSend
			}
			newRow[keysValidate.label] = row[keysValidate.field]
			rows.push(newRow)
			selected.push(row[this.primaryKey])
		} else {
			// less
			rows.forEach((toDelete, index) => {
				if (toDelete[this.primaryKey] === row[this.primaryKey]) {
					toDelete.Cant_afec = 0
					updateRecord = true
				}
			})

			if (!updateRecord) {
				// new
				const newRow = {
					id: row[this.primaryKey],
					Nitem: row.nitem,
					niprod: row.niprod,
					Cant_afec: 0
				}
				newRow[keysValidate.label] = row[keysValidate.field]
				rows.push(newRow)
			}

			selected.forEach((delet, index) => {
				if (delet === row[this.primaryKey]) {
					selected.splice(index, 1)
				}
			})
		}
		this.setState({ rowSelected: rows, selectedCheck: selected })
		this.props.handleCantValidate({ idOperacion, items: rows })
	}

	/**
	 * when use check in the table row
	 * @param isSelect boolean
	 * @param rows
	 */

	handleOnSelectAllCheck = (isSelect, rows) => {
		const { keysValidate, idOperacion } = this.props
		let selected = []
		const checks = this.state.selectedCheck ? this.state.selectedCheck : []

		if (isSelect) {
			this.setState({ selectedCheck: null })
			rows.forEach((check) => {
				checks.push(check[this.primaryKey])
			})

			selected = rows.map((fila) => {
				const tmpdata = {
					id: fila[this.primaryKey],
					Nitem: fila.nitem,
					niprod: fila.niprod,
					Cant_afec: fila.Cant_afec ? fila.Cant_afec : fila.Cant_pend
				}
				tmpdata[keysValidate.label] = fila[keysValidate.field]

				return tmpdata
			})
		} else {
			for (let index = 0; index < checks.length; index++) {
				const check = checks[index]
				rows.forEach((fila) => {
					if (check === fila[this.primaryKey]) {
						delete checks[index]
					}
				})
			}

			selected = rows.map((fila) => {
				const tmpdata = {
					id: fila[this.primaryKey],
					Nimovcli: fila.nimovcli,
					Nitem: fila.nitem,
					Cant_afec: 0,
					niprod: fila.niprod
				}
				tmpdata[keysValidate.label] = fila[keysValidate.field]
				return tmpdata
			})
		}

		if (selected.length) {
			this.props.handleCantValidate({ idOperacion, items: selected })
		}

		this.setState({ rowSelected: selected, selectedCheck: checks })
	}

	/**
	 * to get all select row options
	 */
	getSelectRowOptions = () => {
		const { products, readOnly } = this.props
		const { fieldsTable } = this.state
		const selected = products ? this.getRowsCantAffect() : []

		const showCheckAll = fieldsTable
			? _.find(fieldsTable, (field) => field.idCampo.trim() === 'check_all')
			: { visible: 1 }
		return {
			mode: 'checkbox',
			selectColumnPosition: 'right',
			selected: selected,
			hideSelectColumn: !!readOnly,
			hideSelectAll: !showCheckAll ? false : !showCheckAll.visible,
			headerColumnStyle: {
				textAlign: 'center',
				paddingRight: '8px !important'
			},
			style: (row) => {
				const backgroundColor = row.error ? '#f8d7da' : '#FFF'
				const marginBottom = 120
				return { backgroundColor, marginBottom }
			},
			onSelect: this.handleOnSelectCheck,
			onSelectAll: this.handleOnSelectAllCheck
		}
	}

	/**
	 * to get all pagination options
	 */
	getPaginationOptions = () => {
		const { products, idOperacion } = this.props

		return products
			? {
					pageStartIndex: 1,
					sizePerPage: products.page_size,
					page: products.page_number,
					totalSize: products.total_count,
					onPageChange: (page, sizePerPage) => {
						const items = this.getSelectedCheck()
						if (items.length) {
							this.props.handleAffectedConfirm({
								items: { idOperacion, items }
							})
						}
					}
			  }
			: {}
	}

	/**
	 * when call pagination event.
	 * @param {string} type
	 * @param {object} pagination
	 */
	handleOnChangeTable = (type, pagination) => {
		this.firstRefs = null
		this.inputRefs = {}
		this.props.handleChangeTable(type, pagination)
	}

	render() {
		const { theme, productsUpdate } = this.props
		const { tableColumns, tableData } = this.state

		const defaultSorted = [
			{
				dataField: 'fec_entrega',
				order: 'desc'
			}
		]

		const rowData = tableData
			? !_.isEmpty(productsUpdate)
				? _.isArray(productsUpdate)
					? productsUpdate
					: tableData.map((prod) => {
							let result = { ...prod }
							if (productsUpdate.id === prod.id) {
								result = {
									...productsUpdate
								}
							}
							return result
					  })
				: tableData
			: []

		return (
			<Fragment>
				<Col sm={12} className={'mb-1'}>
					<NotificationMessage
						{...this.state}
						handleCloseError={this.handleCloseError}
						type={'danger'}
					/>
				</Col>

				<div className={theme.scrollContainer}>
					<Col className={`col-12 pl-0 pr-0 ${theme.divContainer}`}>
						{tableColumns.length > 0 && (
							<CommonTable
								remote
								columns={tableColumns}
								keyField={this.primaryKey}
								data={rowData}
								selectRow={this.getSelectRowOptions()}
								defaultSorted={defaultSorted}
								rowClasses={theme.tableRow}
								headerClasses={theme.tableHeader}
								paginationOptions={this.getPaginationOptions()}
								onTableChange={this.handleOnChangeTable}
								isRendeTableFinish={() =>
									handleIsRendeTableFinish(this.firstRefs)
								}
								expandRow={this.getExpandRow()}
							/>
						)}
					</Col>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth, vouchertype }) => {
	const { voucherTypeCancel } = vouchertype
	const { authUser } = auth
	return { authUser, voucherTypeCancel }
}

const connectForm = connect(mapStateToProps, {
	setTableDataInvolvement
})(InvolvementTable)

export default themr(
	'InvolvementTableStyles',
	styles
)(withTranslation()(connectForm))
