import Axios from 'axios'
import {
	ILoadItemIncomeInventoryProps,
	ILoadItemInventoryConfirmParams,
	ILoadItemInventoryProps
} from 'models/LoadInventoryItems'

const urlBase = '/carga_item_inv'

export const loadItemInventoryApi = async (params: ILoadItemInventoryProps) => {
	const response = await Axios.post('/carga_item_inv_egr', params)

	return response.data
}

export const loadItemIncomeInventoryApi = async (
	params: ILoadItemIncomeInventoryProps
) => {
	const response = await Axios.post(`${urlBase}`, params)
	return response.data
}

export const loadItemInventoryConfirmApi = async ({
	isIncome,
	idOperacion
}: ILoadItemInventoryConfirmParams) => {
	const urlApi = isIncome
		? `/inv/ingr_manual/confirmar`
		: `/inv/egr_manual/confirmar`

	const response = await Axios.post(urlApi, {
		idOperacion
	})
	return response.data
}
