import {
	FINAL_CONSUMER_CONFIRM,
	FINAL_CONSUMER_CONFIRM_SUCCESS
} from 'constants/ActionsTypes'
import {
	IFinalConsumerConfirmAction,
	IFinalConsumerParams
} from 'models/FinalConsumer'

export const finalConsumerConfirm = (
	payload: IFinalConsumerParams
): IFinalConsumerConfirmAction => ({
	type: FINAL_CONSUMER_CONFIRM,
	payload
})

export const finalConsumerConfirmSuccess = (payload: any) => ({
	type: FINAL_CONSUMER_CONFIRM_SUCCESS,
	payload
})
