import { ICrudGetLastAccountingDateRes } from 'models/CrudAccountingPeriodsInterface'
import {
	CRUD_GET_LAST_ACCOUNTING_DATE,
	CRUD_GET_LAST_ACCOUNTING_DATE_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetLastAccountingDate = () => {
	return {
		type: CRUD_GET_LAST_ACCOUNTING_DATE
	}
}

export const crudGetLastAccountingDateSuccess = (
	payload: ICrudGetLastAccountingDateRes
) => {
	return {
		type: CRUD_GET_LAST_ACCOUNTING_DATE_SUCCESS,
		payload
	}
}
