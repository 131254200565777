import {
	crudsClearStore,
	crudsCreate,
	crudsGetSingle,
	getConfigFieldsCrud,
	showMessage
} from 'actions'
import CommonTabs from 'components/abmUtils/CommonTabs'
import withMenu from 'components/common/withMenu'
import CrudsContainer from 'components/layout/CrudsContainer'
import { getFiltersData } from 'lib/Utils'
import {
	ICrudFieldConfig,
	ICrudsApi,
	ICrudsTypeList
} from 'models/CrudInterface'
import React, { Component } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CRUD, CRUD_EDIT } from 'utils/RoutePath'

interface ListCrudTabContentProps {
	crudCode: number | string | null
	crudsGetSingle: (params: ICrudsApi) => void
	crudsClearStore: () => void
	selectedAbm: ICrudsTypeList
	op: string
	valueDataSelected: any
	valueDataUpdated: any
	history: any
	crudsCreate: (params: ICrudsApi) => void
	fieldsAltaConfig: ICrudFieldConfig
	fieldsConfig: ICrudFieldConfig
	t: any
	disableForm: boolean
}

interface ListCrudTabContentState {
	tabActive: string
	crudCode: number | string | null
	showWarningPopup: boolean
	formChanged: boolean
	nextAction: string
	nextTab: string
	info?: any
}

class ListCrudTabContent extends Component<
	ListCrudTabContentProps,
	ListCrudTabContentState
> {
	constructor(props: ListCrudTabContentProps) {
		super(props)
		this.state = {
			crudCode: this.props.crudCode,
			tabActive: 'basic',
			showWarningPopup: false,
			formChanged: false,
			nextAction: '',
			nextTab: 'basic'
		}
	}

	// Checks form method, if Add, clears the current redux data
	componentDidMount() {
		const { crudsGetSingle, crudsClearStore, selectedAbm, op, crudCode } =
			this.props

		if (crudCode && (op === 'edit' || op === 'view')) {
			crudsGetSingle({
				crudName: selectedAbm.id,
				urlApi: selectedAbm.urlApi,
				action: 'consulta',
				params: { [selectedAbm.key]: crudCode }
			})
		}
		if (op === 'add') {
			crudsClearStore()
		}
	}

	// Checks for changes in the fields
	componentDidUpdate = (prevProps: ListCrudTabContentProps) => {
		const { valueDataSelected, valueDataUpdated, op } = this.props

		const {
			valueDataUpdated: prevValueDataUpdated,
			valueDataSelected: prevValueDataSelected
		} = prevProps

		if (prevValueDataSelected !== valueDataSelected && valueDataSelected) {
			let info = false
			if (op === 'view' || op === 'edit') {
				info = true
			} else if (op === 'add') {
				info = false
			}

			this.setState({ info })
		}

		if (valueDataUpdated !== prevValueDataUpdated && valueDataUpdated) {
			this.setState({ formChanged: false })
		}
	}

	/**
	 * to redirect to list
	 */

	handleRedirectHome = () => {
		const { selectedAbm } = this.props
		this.props.history.push(`${CRUD}/${selectedAbm.dataUrl}`)
	}

	/**
	 * to call api to create new abm
	 * @param {object} value
	 */

	handleNewCrud = (values: any) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 1 }
		})
	}

	/**
	 * to call api to edit abm
	 * @param {object} value
	 */

	handleEditCrud = (values: any) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 0 }
		})
	}

	// Get's the active tab
	handleActiveKey = (tabActive: any) => {
		this.setState({ tabActive })
	}

	/**
	 * Handles the Crud change arrows
	 * @param {object} value
	 */
	handleCrudChange = (param: any) => {
		const { history, selectedAbm, op } = this.props
		history.go(
			`${CRUD_EDIT}/${selectedAbm.dataUrl}/${op}/${param[selectedAbm.key]}`
		)
	}

	// Handles the changes warning state
	handleWarningPopup = (value: boolean, action: string) => {
		const { tabActive } = this.state
		this.setState({ showWarningPopup: value })
		action === 'close' && this.setState({ nextTab: tabActive, nextAction: '' })
		action === 'cancel' && this.setState({ formChanged: false })
	}

	// Handles the changes state, used in detecting changes
	handleSetFormChanged = (formChanged: boolean) => {
		this.setState({ formChanged })
	}

	// Stores the value of the clicked tab in the component state
	handleSetNextTab = (nextTab: string) => {
		this.setState({ nextTab })
	}

	// Stores the value of the clicked arrow in the component state
	handleSetNextAction = (nextAction: string) => {
		this.setState({ nextAction })
	}

	render() {
		const {
			t,
			fieldsConfig,
			fieldsAltaConfig,
			valueDataSelected,
			crudCode,
			disableForm,
			op,
			selectedAbm
		} = this.props

		const { tabActive, showWarningPopup, formChanged, nextAction, nextTab } =
			this.state

		const config =
			selectedAbm.tabsGroupsAlta &&
			selectedAbm.tabsGroupsAlta.map((tab: string) => {
				return fieldsAltaConfig
					? getFiltersData(fieldsAltaConfig.campos, { agrupador: tab })
					: []
			})
		const tableFields =
			selectedAbm.tabsGroups &&
			selectedAbm.tabsGroups.map((tab: string) => {
				return fieldsConfig
					? getFiltersData(fieldsConfig.campos, { agrupador: tab })
					: []
			})

		const tabProps = selectedAbm.tabsList
			? selectedAbm.tabsList.map((tabProp: any, index: number) => {
					return index === 0
						? {
								disableForm: disableForm,
								itemInfo: valueDataSelected,
								fields: config && config[index],
								showMessage: showMessage,
								handleSubmit:
									op === 'add' ? this.handleNewCrud : this.handleEditCrud,
								method: op,
								handleCloseModal: this.handleRedirectHome,
								showWarning: showWarningPopup,
								handleWarning: this.handleWarningPopup,
								setFormChanged: this.handleSetFormChanged
						  }
						: {
								[selectedAbm.key]: crudCode,
								urlApi: tabProp.urlApi,
								valueId: tabProp.valueId,
								tabId: tabProp.tabId,
								crudId: selectedAbm.id,
								fields: tableFields && tableFields[index],
								fieldsAlta: config && config[index],
								disableForm:
									disableForm ||
									fieldsConfig.tabs.find((tab) => tab.cod_tab === tabProp.key)
										?.tipo_perm === 'L' ||
									false,
								t: t
						  }
			  })
			: []

		const crudTabs: Array<any> = []

		selectedAbm.tabsList &&
			selectedAbm.tabsList.forEach((tab: any, index: number) => {
				;((tab.key === 'values' &&
					valueDataSelected &&
					valueDataSelected.tipo_valid === 'L') ||
					tab.key !== 'values') &&
					crudTabs.push({
						key: tab.key,
						title: t(tab.title),
						disabled:
							op === 'add' ||
							fieldsConfig.tabs.find(
								(tabResult) => tabResult.cod_tab === tab.key
							)?.tipo_perm === 'N',
						content:
							tabActive === tab.key ? (
								<tab.tabComponent {...tabProps[index]} />
							) : (
								<div />
							)
					})
			})

		const propsCrudContainer = {
			urlApi: selectedAbm.urlApi,
			getNewData: this.handleCrudChange,
			keyApi: selectedAbm.key,
			handleExitAbm: this.handleRedirectHome,
			title: t(`${selectedAbm.translation}.title_view`),
			showWarning: formChanged,
			handleWarning: this.handleWarningPopup,
			handleCrudChange: this.handleCrudChange,
			nextAction: nextAction,
			setNextAction: this.handleSetNextAction,
			op: op
		}

		return (
			<CrudsContainer {...propsCrudContainer}>
				<Row className={'pt-4'}>
					<Col>
						<Card.Title>
							{disableForm
								? t(`${selectedAbm.translation}.title_view`)
								: op === 'edit'
								? t(`${selectedAbm.translation}.title_edit`)
								: t(`${selectedAbm.translation}.title_new`)}
						</Card.Title>
					</Col>
				</Row>
				<Row>
					<Col sm={1}>
						<strong>{t('global.internal_code')}</strong>
					</Col>
					<Col className={'text-left'}>
						{valueDataSelected && valueDataSelected[selectedAbm.key]}
					</Col>
				</Row>
				<Row className={'pb-2'}>
					<Col sm={1}>
						<strong>{t('global.name')}</strong>
					</Col>
					<Col className={'text-left'}>
						{valueDataSelected && valueDataSelected[selectedAbm.descrip]}
					</Col>
				</Row>
				<CommonTabs
					customTabs={crudTabs}
					defaultActiveKey={tabActive}
					getActiveKey={this.handleActiveKey}
					showWarning={formChanged}
					handleWarning={this.handleWarningPopup}
					nextTab={nextTab}
					setNextTab={this.handleSetNextTab}
				/>
			</CrudsContainer>
		)
	}
}

const mapStateToProps = ({ configCrud }: any) => {
	const {
		fieldsConfig,
		fieldsAltaConfig,
		valueDataSelected,
		valueDataUpdated
	} = configCrud
	return {
		fieldsConfig,
		fieldsAltaConfig,
		valueDataSelected,
		valueDataUpdated
	}
}

const connectForm = connect(mapStateToProps, {
	getConfigFieldsCrud,
	crudsGetSingle,
	crudsCreate,
	crudsClearStore,
	showMessage
})(ListCrudTabContent)

export default withTranslation()(withMenu(withRouter(connectForm)))
