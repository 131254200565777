import {
	CLEAR_PROVIDER_HEADER,
	CONFIRMATION_PROVIDER,
	CONFIRMATION_PROVIDER_SUCCESS,
	CONFIRM_PROVIDER_TABLE,
	CONFIRM_PROVIDER_TABLE_SUCCESS,
	GET_PROVIDER,
	GET_PROVIDER_HEADER,
	GET_PROVIDER_HEADER_SUCCESS,
	GET_PROVIDER_SUCCESS,
	PROVIDER_HEADER_AUTO,
	PROVIDER_HEADER_AUTO_SUCCESS,
	PROVIDER_HEADER_CHECK_DATE,
	PROVIDER_HEADER_CHECK_DATE_SUCCESS,
	PROVIDER_HEADER_CONFIRMATION,
	PROVIDER_HEADER_CONFIRMATION_SUCCESS,
	SEARCH_PROVIDERS,
	SEARCH_PROVIDERS_SUCCESS
} from '../constants/ActionsTypes'

export const getProvider = (params) => {
	return {
		type: GET_PROVIDER,
		payload: params
	}
}

export const getProviderSuccess = (response) => {
	return {
		type: GET_PROVIDER_SUCCESS,
		payload: response
	}
}

export const searchProviders = (params) => {
	return {
		type: SEARCH_PROVIDERS,
		payload: params
	}
}

export const searchProvidersSuccess = (response) => {
	return {
		type: SEARCH_PROVIDERS_SUCCESS,
		payload: response
	}
}

export const confirmationProvider = (params) => {
	return {
		type: CONFIRMATION_PROVIDER,
		payload: params
	}
}

export const confirmationProviderSuccess = (response) => {
	return {
		type: CONFIRMATION_PROVIDER_SUCCESS,
		payload: response
	}
}

export const getProviderHeader = (params) => {
	return {
		type: GET_PROVIDER_HEADER,
		payload: params
	}
}

export const getProviderHeaderSuccess = (response) => {
	return {
		type: GET_PROVIDER_HEADER_SUCCESS,
		payload: response
	}
}

export const providerHeaderCheckDate = (params) => {
	return {
		type: PROVIDER_HEADER_CHECK_DATE,
		payload: params
	}
}

export const providerHeaderCheckDateSuccess = (response) => {
	return {
		type: PROVIDER_HEADER_CHECK_DATE_SUCCESS,
		payload: response
	}
}

export const providerHeaderConfirm = (params) => {
	return {
		type: PROVIDER_HEADER_CONFIRMATION,
		payload: params
	}
}

export const providerHeaderConfirmSuccess = (response) => {
	return {
		type: PROVIDER_HEADER_CONFIRMATION_SUCCESS,
		payload: response
	}
}

export const providerHeaderAuto = (params) => {
	return {
		type: PROVIDER_HEADER_AUTO,
		payload: params
	}
}

export const providerHeaderAutoSuccess = (response) => {
	return {
		type: PROVIDER_HEADER_AUTO_SUCCESS,
		payload: response
	}
}

export const confirmProviderTable = (params) => {
	return {
		type: CONFIRM_PROVIDER_TABLE,
		payload: params
	}
}

export const confirmProviderTableSuccess = (response) => {
	return {
		type: CONFIRM_PROVIDER_TABLE_SUCCESS,
		payload: response
	}
}

export const clearProviderHeader = () => {
	return {
		type: CLEAR_PROVIDER_HEADER
	}
}
