import { ICrudGenericFields } from 'models/CrudInterface'

export const productCostsFields: Array<ICrudGenericFields> = [
	{
		id: 'niprov',
		type: 'autoComplete',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_prodpr',
		type: 'text',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_barrpr',
		type: 'text',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'pc_pv',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: 'pc_psl',
			values: [
				{
					idValue: '',
					disabled: false
				},
				{
					idValue: 0,
					disabled: false
				},
				{
					idValue: undefined,
					disabled: false
				}
			]
		}
	},
	{
		id: 'pc_psl',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: 'pc_pv',
			values: [
				{
					idValue: '',
					disabled: false
				},
				{
					idValue: 0,
					disabled: false
				},
				{
					idValue: undefined,
					disabled: false
				}
			]
		}
	},
	{
		id: 'cost_usual',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cost_ext',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'prec_uc',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'prec_uc_ext',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'plazo_pago',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'preferido',
		type: 'check',
		width: 'col-sm-3',
		height: '',
		default: 0,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
