import Axios from 'axios'
import {
	IGetTableIsPostalCodeValid,
	IGetTableIsTaxesIdValid
} from 'models/TableCalls/DynamicValidationsInterface'

export const getTableIsPostalCodeValid = async (
	params: IGetTableIsPostalCodeValid
) => {
	const response = await Axios.get(`/tablas/CodigoPostal?cod_postal=${params}`)
	return response.data
}

export const getTableIsTaxesIdValid = async (
	params: IGetTableIsTaxesIdValid
) => {
	const response = await Axios.get(`/valid/idImpuesto?codigo=${params}`)
	return response.data
}

export const getTableIsDNIRepeated = async (params: any) => {
	const response = await Axios.get(
		`/valid/RepDNI?codigo=${params.doc_nro}&niclie=${params.niclie}`
	)
	return response.data
}

export const getTableIsCUITRepeated = async (params: any) => {
	const response = await Axios.get(
		`/valid/RepCUIT?codigo=${params.cuit}&niclie=${params.niclie}`
	)
	return response.data
}
