import { productTaxesFields } from 'constants/crudsConfig/crudProductsConstants/CrudProductTaxesFormFields'
import { renderInput } from 'lib/FormUtils'
import { ICrudGenericFields } from 'models/CrudInterface'
import {
	IGetTableGenericRes,
	IGetTableGenericResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'

type CrudProductTaxesFieldProps = {
	field: any
	formikProps: any
	crudProps: any
	initialValue: any
	listState: boolean
	handleTaxesRatesValues: any
}

type CrudProductTaxesFieldState = {
	dependsOn: any
	fieldConfig: ICrudGenericFields
	defaultValue?: string
	idField?: any
	value?: any
}

class CrudProductTaxesField extends Component<
	CrudProductTaxesFieldProps,
	CrudProductTaxesFieldState
> {
	constructor(props: CrudProductTaxesFieldProps) {
		super(props)
		this.state = {
			fieldConfig: {
				id: '',
				type: '',
				width: '',
				autoComplete: '',
				comboData: '',
				maxChars: 0,
				dependsOn: {
					id: '',
					values: [
						{
							idValue: '',
							disabled: false
						}
					]
				}
			},
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field, handleTaxesRatesValues, formikProps, crudProps } = this.props
		const idField = field.idCampo.trim()
		const addData = _.find(productTaxesFields, (field: ICrudGenericFields) => {
			return field.id === idField
		})
		addData && this.setState({ dependsOn: addData.dependsOn })
		addData && this.setState({ fieldConfig: addData })
		addData && this.setState({ idField: idField })
		addData && this.setState({ defaultValue: addData.default })

		idField === 'ntasa' &&
			crudProps.alta === 0 &&
			handleTaxesRatesValues(formikProps.values.cod_imp)
	}

	// Update check for CPOS validation, and default state of dropdowns
	componentDidUpdate = (prevProps: CrudProductTaxesFieldProps) => {
		const { formikProps: prevFormikProps, crudProps: prevCrudProps } = prevProps
		const { formikProps, crudProps, handleTaxesRatesValues } = this.props

		const { idField, defaultValue, fieldConfig } = this.state

		if (
			fieldConfig.type !== 'combo' &&
			!formikProps.values[idField] &&
			formikProps.values[idField] !== 0 &&
			defaultValue
		) {
			formikProps.setFieldValue(idField, defaultValue)
		}

		if (
			idField === 'cod_imp' &&
			prevFormikProps.values[idField] !== formikProps.values[idField]
		) {
			handleTaxesRatesValues(formikProps.values[idField])
		}

		if (
			crudProps.taxesRateValues !== prevCrudProps.taxesRateValues &&
			crudProps.taxesRateValues
		) {
			if (!_.isEmpty(crudProps.taxesRateValues.lista)) {
				formikProps.setFieldValue(
					'ntasa',
					crudProps.taxesRateValues.lista[0].codigo
				)
			}
		}
	}

	handleBlur = async () => {
		const { formikProps } = this.props
		const { idField } = this.state
		formikProps.setTouched({
			...formikProps.touched,
			[idField]: true
		})
	}

	// Setup combo field if it's empty
	comboDataField = (data: IGetTableGenericResArray) => {
		const values: { cod_valor: string; desc_valor: string }[] = []
		data &&
			_.each(data.lista, (opt: IGetTableGenericRes) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	renderInputs = (
		field: any,
		formikProps: any,
		disableForm: boolean,
		fieldConfig: ICrudGenericFields
	) => {
		const { crudProps, listState } = this.props
		const { values, setFieldValue } = formikProps
		const { dependsOn, idField } = this.state
		const addData = fieldConfig

		addData &&
			(field = {
				...field,
				tipo: addData.type,
				colInput: addData.width,
				autoComplete: addData.autoComplete,
				onBlur: this.handleBlur,
				customProps: {
					maxLength: addData.maxChars
				}
			})

		if (field.tipo === 'combo') {
			addData &&
				(field.valores = crudProps[addData.comboData as keyof typeof crudProps]
					? this.comboDataField(
							crudProps[addData.comboData as keyof typeof crudProps]
					  )
					: [])
			!formikProps.values[idField] &&
				(field.onChange = (event: any) =>
					setFieldValue(field.idCampo, event.target.value))
		} else if (field.tipo === 'check') {
			field.customProps = {
				...field.customProps,
				checked: Number(values[field.idCampo])
			}
		}

		if (field.idCampo === 'cod_imp' && crudProps.alta === 0) {
			field.disabled = true
		}

		dependsOn.id !== '' &&
			_.each(dependsOn.values, (dependency) => {
				listState === dependency.idValue &&
					(field.editable = !dependency.disabled)
			})

		if (!field.editable) field.disabled = true
		if (field.visible) {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { field, formikProps, crudProps } = this.props
		const { fieldConfig } = this.state
		return (
			<Fragment>
				{this.renderInputs(
					field,
					formikProps,
					crudProps.disableForm,
					fieldConfig
				)}
			</Fragment>
		)
	}
}

export default CrudProductTaxesField
