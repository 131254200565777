import {
	CRUD_CLEAR_PROFILE_TABS_STORE,
	CRUD_GET_PROFILE_TABS,
	CRUD_GET_PROFILE_TABS_SUCCESS,
	CRUD_UPDATE_PROFILE_TABS,
	CRUD_UPDATE_PROFILE_TABS_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	profileTabsSearched: any
	profileTabsUpdated: any
}

const initialState = {
	profileTabsSearched: null,
	profileTabsUpdated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_PROFILE_TABS:
			return {
				...state,
				profileTabsSearched: null,
				paramsSearch: action.payload
			}
		case CRUD_GET_PROFILE_TABS_SUCCESS:
			return { ...state, profileTabsSearched: action.payload }
		case CRUD_UPDATE_PROFILE_TABS:
			return { ...state, profileTabsUpdated: null }
		case CRUD_UPDATE_PROFILE_TABS_SUCCESS:
			return { ...state, profileTabsUpdated: action.payload }
		case CRUD_CLEAR_PROFILE_TABS_STORE:
			return {
				...state,
				profileTabsSearched: null,
				profileTabsUpdated: null
			}
		default:
			return state
	}
}

export default rootReducer
