import Axios from 'axios'
import {
	ICrudGetCustomerRefer,
	ICrudUpdateCustomerRefer
} from 'models/CrudCustomerInterface'

const urlBase = '/abm_vta_clientes_refer'

export const crudGetCustomerReferred = async (
	params: ICrudGetCustomerRefer
) => {
	const response = await Axios.get(`${urlBase}/buscar`, { params })
	return response.data
}

export const crudUpdateCustomerReferred = async (
	params: ICrudUpdateCustomerRefer
) => {
	const response = await Axios.post(`${urlBase}/actualizar`, params)
	return response.data
}
