import { ICrudGenericFields } from 'models/CrudInterface'

export const globalPointersFields: ICrudGenericFields = {
	id: 'descrip',
	type: 'check',
	width: 'col-sm-6',
	height: '',
	default: 0,
	maxChars: 99,
	autoComplete: 'new-off',
	comboData: 'globalPointers',
	dependsOn: {
		id: '',
		values: [
			{
				idValue: '',
				disabled: false
			}
		]
	}
}
