import { faCalculator } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CommonTable from 'components/common/commonTable'
import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import InputText from 'components/form/inputText'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import _ from 'underscore'

interface TermsOfSalesDuesTableProps extends WithTranslation {
	fields: any
	disableForm: boolean
	duesSearched: any
	disabledDuesFields: boolean
	handleDuesDisable: any
	handleTableSubmit: any
	handleWarning: any
	showWarning: boolean
	setFormChanged: any
	t: any
}

interface TermsOfSaleDuesTableState {
	duesInfo: any
	propsTable: any
	columnsTable: any
}

class TermsOfSalesDuesTable extends Component<
	TermsOfSalesDuesTableProps,
	TermsOfSaleDuesTableState
> {
	inputRefs: any = {}
	firstRefs: any = null
	refsBeforeSearch = React.createRef()
	primaryKey = 'ncuota'
	constructor(props: TermsOfSalesDuesTableProps) {
		super(props)

		this.state = {
			duesInfo: [],
			propsTable: null,
			columnsTable: null
		}
	}

	componentDidMount = () => {
		const { fields } = this.props
		const columnsTable = fields ? this.getColumns() : []
		this.setState({ columnsTable }) // Create columns table
	}

	componentDidUpdate = (prevProps: TermsOfSalesDuesTableProps) => {
		const { duesSearched, fields, disabledDuesFields } = this.props
		const { duesSearched: prevDuesSearched } = prevProps

		if (duesSearched !== prevDuesSearched && duesSearched) {
			this.setState({ duesInfo: duesSearched.cuotas })
		}

		if (disabledDuesFields !== prevProps.disabledDuesFields) {
			// Change columns to editable
			const columnsTable = fields ? this.getColumns() : []

			this.setState({ columnsTable })
		}
	}

	/**
	 * To update table data fields
	 * stores value in a state
	 * @param {number} rowId
	 * @param {string} fieldId
	 * @param {string} value
	 */

	handleUpdateDataTable = (rowId: any, fieldId: any, value: any) => {
		const { setFormChanged } = this.props
		const { duesInfo } = this.state
		const updateIndex = _.findIndex(duesInfo, { ncuota: rowId })
		const newDues = duesInfo.map((due: any, index: any) => {
			let newDue = {
				ncuota: due.ncuota,
				cant_dias: due.cant_dias,
				pc_afect: due.pc_afect
			}
			if (updateIndex === index) {
				newDue = { ...newDue, [fieldId]: value }
			}
			return newDue
		})
		setFormChanged(true)
		this.setState({ duesInfo: newDues })
	}

	getColumns = () => {
		const { fields, disabledDuesFields, t } = this.props
		const rows = fields.map((field: any) => {
			const fieldId = field.idCampo.trim()
			return {
				dataField: fieldId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '10%' },
				hidden: !field.visible,
				style: {
					height: '60px'
				},
				formatExtraData: disabledDuesFields,
				formatter: (
					cell: any,
					row: any,
					rowIndex: any,
					formatExtraData: any
				) => {
					return this.renderFormat(field, cell, row, formatExtraData)
				}
			}
		})

		rows.push({
			dataField: 'actions',
			text: '',
			align: 'center',
			headerAlign: 'center',
			headerStyle: { width: '5%', height: '70px' },
			formatExtraData: disabledDuesFields,
			formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
				return (
					<Fragment>
						{!formatExtraData ? (
							<Button
								id={`bottomAdd-${row.ncuota}`}
								className={`btn btn-light`}
								onClick={this.handleClick(row)}
							>
								<FontAwesomeIcon
									icon={faCalculator}
									style={{ cursor: 'pointer' }}
									color={'#007bff'}
									title={t('global.calc.partial')}
								/>
							</Button>
						) : (
							<div></div>
						)}
					</Fragment>
				)
			},
			headerFormatter: (cell: any, row: any, rowIndex: any) => {
				return (
					<Fragment>
						{!disabledDuesFields ? (
							<Button
								id={`bottomAdd-${row.ncuota}`}
								className={`btn btn-light`}
								onClick={this.handleClick(row)}
							>
								<FontAwesomeIcon
									icon={faCalculator}
									style={{ cursor: 'pointer' }}
									color={'#007bff'}
									title={t('global.calc.all')}
								/>
							</Button>
						) : (
							<div></div>
						)}
					</Fragment>
				)
			}
		})

		return rows
	}

	/**
	 * To format fields inside the table, adds up the input logic
	 * @param {object} field
	 * @param {string} value
	 * @param {object} row
	 * @param {boolean} disable
	 */

	renderFormat = (field: any, value: any, row: any, disable: boolean) => {
		const fieldId = field.idCampo.trim()
		const typeRef = this.inputRefs
		let result = null

		if (field.editable && !this.inputRefs[fieldId as keyof typeof typeRef]) {
			this.inputRefs[fieldId as keyof typeof typeRef] = {}
		}
		if (
			field.editable &&
			!this.inputRefs[fieldId as keyof typeof typeRef][row.ncuota]
		) {
			const customRef = React.createRef()
			this.inputRefs[fieldId as keyof typeof typeRef][row.ncuota] = customRef
			if (this.firstRefs === null) {
				this.firstRefs = customRef
			}
		}

		const optionsInput = {
			fwRef: field.editable
				? this.inputRefs[fieldId as keyof typeof typeRef][row.ncuota]
				: null,
			inputFormCol: { sm: 12 },
			fields: [{ ...field, label: false }],
			label: false,
			inputId: `${fieldId}`,
			id: `${fieldId}-${row.niprod}`,
			name: `${fieldId}-${row.niprod}`,
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			styles: {
				textAlign: 'center'
			},
			type: 'number',
			divStyle: { paddingLeft: '4px', paddingRight: '10px' },
			disable: false,
			value: value,
			rowStyle: { marginBottom: '1px' },
			onBlur: (value: string, event: any) => {
				this.handleUpdateDataTable(row[this.primaryKey], fieldId, value)
			}
		}

		if (field.editable && fieldId !== 'ncuota' && !disable) {
			result = <InputText {...optionsInput} />
		} else {
			result = value
		}

		return result
	}

	/**
	 * To handle table buttons click
	 * @param {object} row
	 */

	handleClick = (row: any) => (event: any) => {
		const { setFormChanged } = this.props
		const { duesInfo } = this.state
		setFormChanged(true)
		if (row === 3) {
			const dueAmount = parseFloat((100 / duesInfo.length).toFixed(2))
			const fixedDue = (100 - dueAmount * (duesInfo.length - 1)).toFixed(2)
			const newDues = duesInfo.map((due: any, index: any) => {
				const result = index === duesInfo.length - 1 ? fixedDue : dueAmount
				return { ...due, pc_afect: result }
			})
			this.setState({ duesInfo: newDues })
		} else {
			const updateIndex = _.findIndex(duesInfo, { ncuota: row.ncuota })
			const dueAmount = (
				100 -
				duesInfo.reduce((total: any, due: any) => {
					const affect =
						due.ncuota === row.ncuota ? 0 : parseFloat(due.pc_afect)
					return total + affect
				}, 0)
			).toFixed(2)

			const newDues = duesInfo.map((due: any, index: any) => {
				let newDue = {
					ncuota: due.ncuota,
					cant_dias: due.cant_dias,
					pc_afect: due.pc_afect
				}
				if (updateIndex === index) {
					newDue = { ...newDue, pc_afect: dueAmount }
				}
				return newDue
			})

			this.setState({ duesInfo: newDues })
		}
	}

	render() {
		const {
			disableForm,
			disabledDuesFields,
			handleDuesDisable,
			t,
			handleTableSubmit,
			handleWarning,
			showWarning,
			fields,
			duesSearched
		} = this.props

		const { duesInfo } = this.state

		const columnsTable = fields ? this.getColumns() : []

		const propsTable = {
			remote: true,
			columns: columnsTable,
			keyField: this.primaryKey,
			data: duesInfo || duesSearched?.cuotas,
			pagination: false
		}

		return (
			<Fragment>
				{propsTable && <CommonTable {...propsTable} />}
				<Row className={'pt-3'}>
					{!disabledDuesFields && (
						<Fragment>
							<Col sm={6} className={'text-center mt-2'}>
								<Button
									style={{ minWidth: '145px' }}
									type={'button'}
									onClick={() => handleTableSubmit(this.state.duesInfo)}
								>
									{t('Collection.form.buttons.accept')}
								</Button>
							</Col>
							<Col sm={6} className={'text-center mt-2'}>
								<Button
									style={{ minWidth: '145px' }}
									type={'button'}
									onClick={handleDuesDisable}
								>
									{t('Collection.form.buttons.cancel')}
								</Button>
							</Col>
						</Fragment>
					)}
					{!disableForm && (
						<Col className={'text-center mt-2 d-flex justify-content-around'}>
							{disabledDuesFields && (
								<Button
									style={{ minWidth: '145px' }}
									type={'button'}
									onClick={handleDuesDisable}
								>
									{t('Editar')}
								</Button>
							)}
						</Col>
					)}
				</Row>
				<ConfirmModalNoButton
					modalTitle={t('CRUDS.modal.title_warning')}
					messageBody={t('CRUDS.modal.message_changes')}
					showModal={showWarning}
					handleCloseModal={() => handleWarning(false, 'close')}
					handleCancelButton={() => {
						handleWarning(false, 'cancel')
					}}
					handleSubmitButton={(e: any) => {
						handleTableSubmit(this.state.duesInfo)
						handleWarning(false, 'submit')
					}}
				/>
			</Fragment>
		)
	}
}

export default withTranslation()(TermsOfSalesDuesTable)
