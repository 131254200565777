import CrudUserTabsDataContents from 'components/crudUsers/CrudUserTabsDataContents'
import { CRUD_USER_DATA } from 'utils/RoutePath'

export const userConfig = {
	id: 'userCrud',
	key: 'cod_usu',
	descrip: 'nombre',
	searchMethod: 'get',
	category: 'C',
	translation: 'user_data',
	dataUrl: CRUD_USER_DATA,
	urlApi: 'abm_usuarios',
	component: CrudUserTabsDataContents
}
