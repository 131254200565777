import React, { Component } from 'react'
import { Button, Col, Container, ProgressBar, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import CommonLabelCol from '../common/CommonLabelCol'

interface ICommonProgressProps extends WithTranslation {
	t?: any
	generatedStatus: any
	handleCancelProgress: () => void
}

class CommonProgressBar extends Component<ICommonProgressProps> {
	render() {
		const { t, generatedStatus } = this.props
		const totalRecords = generatedStatus.cant_comp || 0
		const current = generatedStatus.cant_gen || 0
		const now = generatedStatus ? generatedStatus.pc_gener : 0

		const fields = [
			{
				idCampo: 'total',
				descrip: 'Cantidad de Comprobantes a Generar',
				label: 'Cantidad de Comprobantes a Generar',
				mascara: 'string',
				editable: false,
				visible: 1,
				requerido: true,
				valid: '',
				agrupador: 'string'
			},
			{
				idCampo: 'current',
				descrip: 'Cantidad de Comprobantes Generados',
				label: 'Cantidad de Comprobantes Generados',
				mascara: 'string',
				editable: false,
				visible: 1,
				requerido: true,
				valid: '',
				agrupador: 'string'
			}
		]

		return (
			<Container>
				<Row>
					<Col className="p-2 font-weight-bold">
						{t('auto_generate.receipts_generate')}
					</Col>
					<div
						className="dropdown-divider col-12"
						style={{ backgroundColor: '#2e00ff', padding: '1px' }}
					/>
				</Row>
				<Row>
					<CommonLabelCol
						typeLabel="flex"
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="total"
						value={`${totalRecords}`}
					/>

					<CommonLabelCol
						typeLabel="flex"
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="current"
						value={`${current}`}
					/>

					<Col className="p-2 font-weight-bold ">
						<Button
							style={{ minWidth: '125px' }}
							type={'button'}
							onClick={this.props.handleCancelProgress}
						>
							{t('Collection.form.buttons.cancel')}
						</Button>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<ProgressBar animated now={now} label={`${now}%`} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = ({ autoGenerate }: any) => {
	const { generatedStatus } = autoGenerate
	return { generatedStatus }
}

const mapDispatchToProps = {}

const connectForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(CommonProgressBar)

export default withTranslation()(connectForm)
