import { productCostsFields } from 'constants/crudsConfig/crudProductsConstants/CrudProductCostsFormFields'
import { renderInput } from 'lib/FormUtils'
import { ICrudGenericFields } from 'models/CrudInterface'
import {
	IGetTableGenericRes,
	IGetTableGenericResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'

type CrudProductCostsFieldProps = {
	field: any
	formikProps: any
	crudProps: any
	initialValue: any
	searchProviders: (value: string) => void
	isSearching: boolean
	handleIsSearching: (isSearching: boolean) => void
	prices: {
		id: string
		pc_pv: number
		pc_psl: number
		cost: number
	}
	handlePrices: (
		values: any,
		changedValue: number,
		changedId: string,
		percId?: string
	) => void
}

type CrudProductCostsFieldState = {
	dependsOn: any
	fieldConfig: ICrudGenericFields
	defaultValue?: string
	idField?: any
	value?: any
}

class CrudProductCostsField extends Component<
	CrudProductCostsFieldProps,
	CrudProductCostsFieldState
> {
	constructor(props: CrudProductCostsFieldProps) {
		super(props)
		this.state = {
			fieldConfig: {
				id: '',
				type: '',
				width: '',
				autoComplete: '',
				comboData: '',
				maxChars: 0,
				dependsOn: {
					id: '',
					values: [
						{
							idValue: '',
							disabled: false
						}
					]
				}
			},
			dependsOn: {
				id: '',
				values: [
					{
						idValue: '',
						disabled: false
					}
				]
			}
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field } = this.props
		const idField = field.idCampo.trim()
		const addData = _.find(productCostsFields, (field: ICrudGenericFields) => {
			return field.id === idField
		})
		addData && this.setState({ dependsOn: addData.dependsOn })
		addData && this.setState({ fieldConfig: addData })
		addData && this.setState({ idField: idField })
		addData && this.setState({ defaultValue: addData.default })
	}

	// Update check for CPOS validation, and default state of dropdowns
	componentDidUpdate = (prevProps: CrudProductCostsFieldProps) => {
		const { formikProps, prices } = this.props

		const { idField, defaultValue, fieldConfig } = this.state

		if (
			fieldConfig.type !== 'combo' &&
			!formikProps.values[idField] &&
			formikProps.values[idField] !== 0 &&
			defaultValue
		) {
			formikProps.setFieldValue(idField, defaultValue)
		}

		if (prices && prevProps.prices !== prices) {
			switch (idField) {
				case 'pc_pv':
					formikProps.setFieldValue(idField, prices.pc_pv)
					break
				case 'pc_psl':
					formikProps.setFieldValue(idField, prices.pc_psl)
					break
				case 'cost_usual':
					prices.id === 'usual' &&
						formikProps.setFieldValue(idField, prices.cost)
					break
				case 'cost_ext':
					prices.id === 'ext' && formikProps.setFieldValue(idField, prices.cost)
					break
				default:
					break
			}
		}
	}

	handleBlur = async () => {
		const { formikProps } = this.props
		const { idField } = this.state
		formikProps.setTouched({
			...formikProps.touched,
			[idField]: true
		})
	}

	handleChange = (value: any) => {
		const { formikProps, handlePrices } = this.props
		const { idField } = this.state

		if (idField === 'pc_pv' || idField === 'pc_psl') {
			handlePrices(formikProps.values, value, idField)
		} else if (idField === 'cost_usual' || idField === 'cost_ext') {
			if (!formikProps.values.pc_psl)
				handlePrices(formikProps.values, value, idField, 'pc_pv')
			else handlePrices(formikProps.values, value, idField, 'pc_psl')
		}

		value === ''
			? formikProps.setFieldValue(idField, '')
			: value === true
			? formikProps.setFieldValue(idField, 1)
			: value === false
			? formikProps.setFieldValue(idField, 0)
			: formikProps.setFieldValue(idField, value)
	}

	// Setup combo field if it's empty
	comboDataField = (data: IGetTableGenericResArray) => {
		const values: { cod_valor: string; desc_valor: string }[] = []
		data &&
			_.each(data.lista, (opt: IGetTableGenericRes) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	renderInputs = (
		field: any,
		formikProps: any,
		disableForm: boolean,
		fieldConfig: ICrudGenericFields
	) => {
		const {
			crudProps,
			isSearching,
			searchProviders,
			handleIsSearching,
			initialValue
		} = this.props
		const { listProviders } = crudProps
		const { values, setFieldValue } = formikProps
		const { idField, dependsOn } = this.state
		const addData = fieldConfig

		const providers = listProviders
			? listProviders.proveedores.map((opt: any) => {
					return { id: opt.idProveedor, label: opt.rsocial }
			  })
			: []

		addData &&
			(field = {
				...field,
				tipo: addData.type,
				colInput: addData.width,
				autoComplete: addData.autoComplete,
				onBlur: this.handleBlur,
				disabled: addData.disabled,
				onChange: this.handleChange,
				customProps: {
					maxLength: addData.maxChars
				}
			})

		if (field.tipo === 'combo') {
			addData &&
				(field.valores = crudProps[addData.comboData as keyof typeof crudProps]
					? this.comboDataField(
							crudProps[addData.comboData as keyof typeof crudProps]
					  )
					: [])
			!formikProps.values[idField] &&
				(field.onChange = (event: any) =>
					setFieldValue(field.idCampo, event.target.value))
		} else if (field.tipo === 'check') {
			field.customProps = {
				...field.customProps,
				checked: Number(values[field.idCampo])
			}
		}

		if (field.idCampo === 'rsoc' && crudProps.alta === 0) {
			field.visible = true
			field.disabled = true
		}

		if (field.idCampo === 'niprov') {
			if (crudProps.alta === 0) {
				field.disabled = true
				field.visible = false
			}
			field.customProps = {
				minLength: 3,
				handleSearch: searchProviders,
				handleLoading: isSearching,
				auoptions: providers,
				handleSelect: (provider: { id: string; label: string }) => {
					formikProps.setFieldValue('niprov', provider.id)
					handleIsSearching(false)
				},
				defaultValue: initialValue,
				disable: disableForm
			}
			field.requerido = true
		}

		if (field.idCampo === 'cost_usual') {
			if (
				crudProps.usualCoin &&
				crudProps.usualCoin.mon_usu === formikProps.values.mon_v
			) {
				field.editable = true
			} else {
				field.editable = false
			}
		}

		if (field.idCampo === 'cost_ext') {
			if (
				crudProps.usualCoin &&
				crudProps.usualCoin.mon_usu !== formikProps.values.mon_v
			) {
				field.editable = true
			} else {
				field.editable = false
			}
		}

		dependsOn.id !== '' &&
			_.each(dependsOn.values, (dependency) => {
				values[dependsOn.id] === dependency.idValue &&
					(field.disabled = dependency.disabled)
			})

		if (!field.editable) field.disabled = true
		if (field.visible) {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { field, formikProps, crudProps } = this.props
		const { fieldConfig } = this.state
		return (
			<Fragment>
				{this.renderInputs(
					field,
					formikProps,
					crudProps.disableForm,
					fieldConfig
				)}
			</Fragment>
		)
	}
}

export default CrudProductCostsField
