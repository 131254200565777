import {
	AUTO_GENERATE_CANCEL,
	AUTO_GENERATE_CANCEL_SUCCESS,
	AUTO_GENERATE_CLEAN_STORE,
	AUTO_GENERATE_CONFIRM,
	AUTO_GENERATE_CONFIRM_SUCCESS,
	AUTO_GENERATE_STATUS,
	AUTO_GENERATE_STATUS_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	autoConfirm: any
	generatedStatus: any
	messageError: string
	generatedCancel: any
}

const initialState = {
	autoConfirm: null,
	generatedStatus: null,
	messageError: '',
	generatedCancel: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case AUTO_GENERATE_CONFIRM:
			return { ...state, autoConfirm: null }
		case AUTO_GENERATE_CONFIRM_SUCCESS:
			return { ...state, autoConfirm: action.payload }
		case AUTO_GENERATE_STATUS:
			return { ...state }
		case AUTO_GENERATE_STATUS_SUCCESS:
			return { ...state, generatedStatus: action.payload }
		case AUTO_GENERATE_CANCEL:
			return { ...state, generatedCancel: null }
		case AUTO_GENERATE_CANCEL_SUCCESS:
			return { ...state, generatedCancel: action.payload }
		case AUTO_GENERATE_CLEAN_STORE:
			return { ...initialState }
		default:
			return state
	}
}

export default rootReducer
