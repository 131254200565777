import withMenu from 'components/common/withMenu'
import InventoryPickStockForm from 'components/InventoryPickStock/InventoryPickStockForm'
import GlobalContainer from 'components/layout/globalContainer'
import { P_INV_ING_ITEM_PIC } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'

interface InventoryPickStockProps {}
interface InventoryPickStockState {
	isVisibleBarCode: boolean
}
class ProviderPickStock extends Component<
	InventoryPickStockProps,
	InventoryPickStockState
> {
	inventoryChildRef: any
	inventoryFormRef: any
	constructor(props: InventoryPickStockProps) {
		super(props)
		this.inventoryChildRef = React.createRef()
		this.inventoryFormRef = React.createRef()
		this.state = {
			isVisibleBarCode: true
		}
	}

	getShortcuts = () => {
		const shortcuts = [
			{
				hotkey: { charCode: 'F2', modifiers: ['f2'] },
				action: this.changeInput,
				name: 'Change input search',
				description: 'Change input search'
			},
			{
				hotkey: { charCode: 'F4', modifiers: ['f4'] },
				action: this.toggleCant,
				name: 'change between enable and disable',
				description: 'change between enable and disable'
			},
			{
				hotkey: { charCode: 'F3', modifiers: ['f3'] },
				action: this.enableLast,
				name: 'enable last',
				description: 'to active last ind checkbox'
			}
		]

		return shortcuts
	}

	changeInput = () => {
		this.setState((state) => ({
			isVisibleBarCode: !state.isVisibleBarCode
		}))
	}

	toggleCant = () => {
		this.inventoryChildRef.current.toggleDisableCant()
	}

	enableLast = () => {
		this.inventoryChildRef.current.toggleLastCuf()
	}

	handleCallBackAction = () => {
		this.inventoryFormRef.current.handleSubmit()
	}

	render() {
		const { isVisibleBarCode } = this.state
		const containerProps = {
			codeProcess: P_INV_ING_ITEM_PIC,
			shortcuts: this.getShortcuts(),
			nextPage: true,
			callBackButton: this.handleCallBackAction
		}
		return (
			<Row>
				<GlobalContainer {...containerProps}>
					<InventoryPickStockForm
						ref={this.inventoryChildRef}
						inventoryFormRef={this.inventoryFormRef}
						isVisibleBarCode={isVisibleBarCode}
					/>
				</GlobalContainer>
			</Row>
		)
	}
}

export default withMenu(ProviderPickStock)
