import { getTableProducts } from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues } from 'lib/FieldValidations'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import ComponentsFormField from './ComponentsFormField'

type ComponentProps = {
	fields: Array<IFieldStructure>
	handleSubmit: any
	itemInfo?: any
	disableForm?: boolean
	handleCloseModal: any
	renderFormInputs: any
	niprod: number
	products: any
	alta: number
	getTableProducts: any
	t: any
}

type ComponentState = {
	isSearching: boolean
}

class ComponentsForm extends Component<ComponentProps, ComponentState> {
	constructor(props: ComponentProps) {
		super(props)
		this.state = {
			isSearching: false
		}
	}

	componentDidMount = () => {
		const { niprod } = this.props
		this.props.getTableProducts({
			niprod: niprod,
			busqueda: 'S',
			habilitado: 1
		})
	}

	handleSearchProducts = (value: string) => {
		const { getTableProducts, niprod } = this.props
		getTableProducts({ niprod: niprod, busqueda: value, habilitado: 1 })
		this.handleIsSearching(true)
	}

	handleIsSearching = (isSearching: boolean) => {
		this.setState({ isSearching })
	}

	render() {
		const { fields, handleSubmit, itemInfo, disableForm, t, alta } = this.props
		const { isSearching } = this.state

		for (const key in itemInfo) {
			itemInfo[key] =
				typeof itemInfo[key] === 'string' ? itemInfo[key].trim() : itemInfo[key]
		}

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})
		const columnsCancel = disableForm ? 12 : 6

		return (
			<>
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						handleSubmit(values)
					}}
					enableReinitialize={true}
				>
					{(props: FormikProps<any>) => (
						<Fragment>
							<Col sm={12}>
								<NotificationError
									errors={props.errors}
									touched={props.touched}
								/>
							</Col>
							<Form>
								{(alta === 1 || (alta === 0 && itemInfo)) &&
									_.map(fields, (field: any) => {
										return (
											<Fragment>
												{(field.idCampo !== 'nicomp' || alta !== 0) && (
													<ComponentsFormField
														key={field.idCampo}
														formikProps={props}
														field={field}
														crudProps={this.props}
														isSearching={isSearching}
														searchProducts={this.handleSearchProducts}
														handleIsSearching={this.handleIsSearching}
														initialValue={
															initialValues[
																field.idCampo as keyof typeof initialValues
															]
														}
													/>
												)}
												{field.idCampo === 'nicomp' && alta !== 0 && <hr />}
											</Fragment>
										)
									})}
								<Row className={'pt-3'}>
									{!disableForm && (
										<Col sm={6} className={'text-center mt-2'}>
											<Button style={{ minWidth: '145px' }} type={'submit'}>
												{t('Collection.form.buttons.accept')}
											</Button>
										</Col>
									)}
									<Col sm={columnsCancel} className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '145px' }}
											type={'button'}
											onClick={this.props.handleCloseModal}
										>
											{t('Collection.form.buttons.cancel')}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					)}
				</Formik>
			</>
		)
	}
}

const mapStateToProps = ({ tableCalls }: any) => {
	const { products } = tableCalls
	return { products }
}

const connectForm = connect(mapStateToProps, {
	getTableProducts
})(ComponentsForm)

export default withTranslation()(withRouter(connectForm))
