import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { removeProductCart } from 'actions'
import CommonModal from 'components/common/commonModal'
import CommonTable from 'components/common/commonTable'
import ConfirmModal from 'components/common/ConfirmModal'
import React, { Component, Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ProviderIndividualEditProduct from './ProviderIndividualEditProduct'

class ProviderIndividualLoadItemsTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			filterVal: null,
			showEditModal: false,
			editProduct: null
		}
	}

	/**
	 * to get columns table.
	 * @returns object
	 */
	getColumns = () => {
		const { config, t } = this.props
		const rows = config.campos.map((field) => {
			const fieldId = field.idCampo.trim()
			return {
				dataField: fieldId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: this.getStyleColumn(field),
				hidden:
					!field.visible || fieldId === 'avisos' || fieldId === 'ind_stock',
				filterValue: (cell, row) => {
					const filter = []
					row.Bonificaciones.forEach((bonif) => {
						if (bonif.cod_bonif === this.state.filterVal) {
							filter.push(bonif.cod_bonif)
						}
					})

					return filter
				}
			}
		})

		rows.push({
			dataField: 'actions',
			text: '',
			align: 'center',
			headerAlign: 'center',
			headerStyle: { width: '5%' },
			formatter: (cell, row, rowIndex) => {
				return (
					<Fragment>
						<FontAwesomeIcon
							icon={faPen}
							style={{ cursor: 'pointer' }}
							color={'#007bff'}
							onClick={() => this.handleEditItem(row)}
						/>
						<ConfirmModal
							messageBody={t('shoppingCart.modal.body')}
							onSubmitModal={() => this.handleRemoveCart(row)}
							labelButton={<FontAwesomeIcon icon={faTrash} />}
							modalTitle={t('shoppingCart.modal.title')}
							typeButton={'light'}
						/>
					</Fragment>
				)
			}
		})

		return rows
	}

	handleRemoveCart = (row) => {
		const { idOperacion, productsCart } = this.props
		this.props.removeProductCart({
			idOperacion,
			nitem: row.nitem,
			page: productsCart.page_number,
			page_size: productsCart.page_size
		})
	}

	getStyleColumn = (field) => {
		const idField = field.idCampo.trim()

		let style = {}

		switch (idField) {
			case 'desc_prod':
				style = { width: '25%' }
				break
			case 'fec_entrega':
				style = { width: '13%' }
				break
			case 'avisos':
				style = { width: '10%' }
				break
			case 'ind_stock':
				style = { width: '1%' }
				break
			case 'precio_unit':
				style = { width: '10%' }
				break
			case 'modif_pcio':
				style = { width: '1%' }
				break

			case 'neto':
				style = { width: '10%' }
				break
			default:
				style = { width: '10%' }
				break
		}

		return style
	}

	validateKey = (key, rows) => {
		let result = false
		rows.forEach((row) => {
			if (row === key) {
				result = true
			}
		})
		return result
	}

	handleCloseEditModal = () => {
		this.setState({ showEditModal: false })
	}

	handleEditItem = (editProduct) => {
		this.setState({ showEditModal: true, editProduct })
	}

	render() {
		const { productsCart, config, idOperacion } = this.props
		const tableColumns = config ? this.getColumns() : []
		const { showEditModal, editProduct } = this.state
		const options =
			productsCart && productsCart.productos
				? {
						page: productsCart.page_number,
						sizePerPage: productsCart.page_size,
						totalSize: productsCart.total_count
				  }
				: {}

		return (
			<Col className={`col-12  `}>
				<CommonModal
					sizeModal="lg"
					config={config}
					modalBody={
						<ProviderIndividualEditProduct
							config={config}
							product={{ nitem: editProduct ? editProduct.nitem : null }}
							handleCloseModal={this.handleCloseEditModal}
							idOperacion={idOperacion}
						/>
					}
					modalTitle="Editar Item"
					showModal={showEditModal}
					handleCloseModal={this.handleCloseEditModal}
				/>
				<CommonTable
					remote
					columns={tableColumns}
					keyField={'nitem'}
					data={productsCart ? productsCart.productos : []}
					paginationOptions={options}
					onTableChange={this.props.handleChangeTable}
				/>
			</Col>
		)
	}
}

const mapStateToProps = ({ product }) => {
	const { productsCart, search } = product

	return { search, productsCart }
}

const connectForm = connect(mapStateToProps, { removeProductCart })(
	ProviderIndividualLoadItemsTable
)

export default withTranslation()(connectForm)
