import CrudCourier from 'components/courier/CrudCourier'
import SingleTabCrudContent from 'components/genericCrud/SingleTabCrudContent'
import { CRUD_COURIER_DATA } from 'utils/RoutePath'

export const courierConfig = {
	id: 'courierCrud',
	key: 'tr_cod',
	descrip: 'tr_nom',
	searchMethod: 'get',
	category: 'A',
	translation: 'courier_data',
	dataUrl: CRUD_COURIER_DATA,
	urlApi: 'abm_vta_transp',
	component: SingleTabCrudContent,
	tabsList: [
		{
			key: 'basic',
			title: 'CRUDS.general_use.basic_tab',
			tabComponent: CrudCourier
		}
	],
	tabsGroupsAlta: ['form_alta']
}
