import Axios from 'axios'
import {
	ICrudGetSellerCommission,
	ICrudGetSellerCommissions,
	ICrudUpdateSellerCommission
} from 'models/CrudSellerInterface'

const urlBase = '/abm_vta_vend_comi'

export const crudGetSellerCommissions = async (
	params: ICrudGetSellerCommissions
) => {
	const response = await Axios.get(`${urlBase}/buscar`, { params })
	return response.data
}

export const crudGetSellerCommission = async (
	params: ICrudGetSellerCommission
) => {
	const response = await Axios.get(`${urlBase}/consulta`, { params })
	return response.data
}

export const crudUpdateSellerCommission = async (
	params: ICrudUpdateSellerCommission
) => {
	const response = await Axios.post(`${urlBase}/confirmar`, params)
	return response.data
}

export const crudDelSellerCommission = async (
	params: ICrudGetSellerCommission
) => {
	const response = await Axios.post(
		`${urlBase}/eliminar?nivend=${params.nivend}&cod_prod=${params.cod_prod}`
	)
	return response.data
}
