import {
	clearTableValidations,
	crudGetProductBonification,
	crudsClearStore,
	crudsCreate,
	crudsGetSingle,
	crudUpdateProductBonification,
	showMessage
} from 'actions'
import CommonTabs from 'components/abmUtils/CommonTabs'
import withMenu from 'components/common/withMenu'
import ValuesCrudTable from 'components/genericCrud/ValuesCrudTable'
import CrudsContainer from 'components/layout/CrudsContainer'
import {
	IAbmStructureResponse,
	IFieldStructure
} from 'constants/valuesInterfaces/interfaces'
import { ICrudsApi, ICrudsTypeList } from 'models/CrudInterface'
import {
	ICrudGetProductBonif,
	ICrudGetProductRes,
	ICrudUpdateProductBonif
} from 'models/CrudProductsInterface'
import { Ihistory } from 'models/NavigationInterface'
import React, { Component } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { CRUD, CRUD_EDIT, CRUD_PRODUCTS_DATA } from 'utils/RoutePath'
import AdditionalDataForm from './AdditionalTabs/AdditionalDataForm'
import BonificationTab from './AdditionalTabs/BonificationTab'
import BundlesForm from './AdditionalTabs/BundlesForm'
import CombosForm from './AdditionalTabs/CombosForm'
import ComponentsForm from './AdditionalTabs/ComponentsForm'
import CostsForm from './AdditionalTabs/CostsForm'
import CufForm from './AdditionalTabs/CufForm'
import DepotsForm from './AdditionalTabs/DepotsForm'
import PricesForm from './AdditionalTabs/PricesForm'
import TaxesForm from './AdditionalTabs/TaxesForm'
import CrudProductData from './CrudProductData'

export interface ISubGroup {
	groupId: string
	accessRight: string
}

type ProductDataContentProps = {
	crudCode: number
	op: string
	selectedAbm: ICrudsTypeList
	crudsGetSingle: (params: ICrudsApi) => void
	crudsCreate: (params: ICrudsApi) => void
	crudsClearStore: () => void
	crudUpdateProductBonification: (params: ICrudUpdateProductBonif) => void
	crudGetProductBonification: (params: ICrudGetProductBonif) => void
	valueDataUpdated: any
	valueDataSelected: ICrudGetProductRes
	clearTableValidations: any
	showMessage: any
	history: Ihistory
	fieldsAltaConfig: IAbmStructureResponse
	fieldsConfig: IAbmStructureResponse
	disableForm: boolean
	bonificationInfo: ICrudUpdateProductBonif
	crudUpdateProductBonif: any
	valueUpdated: any
	t: any
}

type ProductDataContentState = {
	crudCode: number | string | null
	info: boolean
	tabActive: string
	showWarningPopup: boolean
	formChanged: boolean
	nextTab: any
	nextAction: string
	disabledBonificationFields: boolean
}

const productTabs = [
	{
		key: 'basic',
		label: 'CRUDS.general_use.basic_tab',
		type: 'basic'
	},
	{
		key: 'atrib',
		label: 'CRUDS.general_use.additional_tab',
		type: 'additional'
	},
	{
		key: 'lprec',
		label: 'CRUDS.general_use.prices',
		type: 'prices'
	},
	{
		key: 'cpact',
		label: 'CRUDS.general_use.costs',
		type: 'costs'
	},
	{
		key: 'comp',
		label: 'CRUDS.general_use.components',
		type: 'components'
	},
	{
		key: 'comb',
		label: 'CRUDS.general_use.combos',
		type: 'combos'
	},
	{
		key: 'deplim',
		label: 'CRUDS.general_use.depots',
		type: 'depots'
	},
	{
		key: 'CUF',
		label: 'CRUDS.general_use.cuf_tab',
		type: 'cuf'
	},
	{
		key: 'impu',
		label: 'CRUDS.general_use.taxes_tab',
		type: 'taxes'
	},
	{
		key: 'bon',
		label: 'CRUDS.general_use.bonif_tab',
		type: 'bonif'
	},
	{
		key: 'present',
		label: 'CRUDS.general_use.bundles_tab',
		type: 'bundles'
	}
]

class ProductDataContent extends Component<
	ProductDataContentProps,
	ProductDataContentState
> {
	constructor(props: ProductDataContentProps) {
		super(props)
		this.state = {
			crudCode: this.props.crudCode,
			disabledBonificationFields: true,
			info: false,
			tabActive: 'basic',
			showWarningPopup: false,
			formChanged: false,
			nextTab: 'basic',
			nextAction: ''
		}
	}

	// setups initial store state for each action respectively
	componentDidMount() {
		const {
			crudCode,
			op,
			crudsClearStore,
			clearTableValidations,
			crudGetProductBonification
		} = this.props

		if (crudCode && (op === 'edit' || op === 'view')) {
			crudGetProductBonification({ niprod: crudCode })
			// crudClearProductCodeStore()
		} else if (op === 'add') {
			crudsClearStore()
			// crudClearProductCodeStore()
			clearTableValidations()
		}
	}

	// Handles changes and sets form changed state to false if there was a submit
	componentDidUpdate = (prevProps: ProductDataContentProps) => {
		const {
			op,
			crudCode,
			crudsGetSingle,
			valueDataUpdated,
			selectedAbm,
			valueUpdated,
			crudGetProductBonification
		} = this.props
		const {
			valueDataUpdated: prevValueDataUpdated,
			valueUpdated: prevValueUpdated
		} = prevProps

		if (prevValueDataUpdated !== valueDataUpdated && valueDataUpdated) {
			let info = false
			if (op === 'view' || op === 'edit') {
				info = true
			} else if (op === 'add') {
				info = false
			}
			this.setState({ info, formChanged: false })
			crudsGetSingle({
				crudName: selectedAbm.id,
				urlApi: selectedAbm.urlApi,
				action: 'consulta',
				params: { [selectedAbm.key]: crudCode }
			})
		}

		if (valueUpdated !== prevValueUpdated && valueUpdated) {
			this.props.showMessage({
				type: 'success',
				description: 'Se actualizo correctamente',
				showError: true
			})
			crudGetProductBonification({ niprod: crudCode })
			this.setState({ formChanged: false })
			this.handleBonificationDisable()
		}
	}

	// Cancel button function
	handleRedirectHome = () => {
		this.props.history.push(`${CRUD}/${CRUD_PRODUCTS_DATA}`)
	}

	/**
	 * to call api to create new abm
	 * @param {object} value
	 */

	handleNewCrud = (values: any) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 1 }
		})
	}

	/**
	 * to call api to edit abm
	 * @param {object} value
	 */

	handleEditCrud = (values: any) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 0 }
		})
	}

	// Set's current tab in state
	handleActiveKey = (tabActive: string) => {
		this.setState({ tabActive })
	}

	// Handles the Crud change arrows
	handleCrudChange = (param: any) => {
		const { history } = this.props
		history.go(`${CRUD_EDIT}/${CRUD_PRODUCTS_DATA}/${param.niprod}`)
	}

	// Handles the changes warning state
	handleWarningPopup = (value: boolean, action: string) => {
		const { tabActive } = this.state
		this.setState({ showWarningPopup: value })
		action === 'close' && this.setState({ nextTab: tabActive, nextAction: '' })
		action === 'cancel' && this.setState({ formChanged: false })
	}

	// Handles the changes state, used in detecting changes
	handleSetFormChanged = (formChanged: boolean) => {
		this.setState({ formChanged })
	}

	// Stores the value of the clicked tab in the component state
	handleSetNextTab = (nextTab: string) => {
		this.setState({ nextTab })
	}

	// Stores the value of the clicked arrow in the component state
	handleSetNextAction = (nextAction: string) => {
		this.setState({ nextAction })
	}

	// Handles bonification submit
	handleEditBonification = (values: ICrudUpdateProductBonif) => {
		this.props.crudUpdateProductBonification({ ...values })
	}

	// Disables bonification fields
	handleBonificationDisable = () => {
		const { disabledBonificationFields } = this.state
		this.setState({ disabledBonificationFields: !disabledBonificationFields })
	}

	render() {
		const {
			t,
			fieldsConfig,
			fieldsAltaConfig,
			valueDataSelected,
			disableForm,
			op,
			crudCode,
			selectedAbm,
			bonificationInfo
		} = this.props

		const {
			tabActive,
			showWarningPopup,
			formChanged,
			nextTab,
			nextAction,
			disabledBonificationFields
		} = this.state

		const subGroups: Array<ISubGroup> = []

		const fields = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('principal')
			  })
			: []

		_.each(
			fields,
			(
				field: IFieldStructure,
				index: number,
				array: Array<IFieldStructure>
			) => {
				if (array[index - 1]) {
					if (field.agrupador !== array[index - 1].agrupador) {
						const groupId = field.agrupador
						const accessRight = fieldsConfig.tabs.find(
							(tab) => tab.cod_tab === groupId
						)?.tipo_perm
						subGroups.push({
							groupId: groupId,
							accessRight: accessRight || 'E'
						})
					}
				} else {
					subGroups.push({ groupId: field.agrupador, accessRight: 'E' })
				}
			}
		)

		const taxesFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grilla.imp')
			  })
			: []
		const taxesFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.impu')
			  })
			: []
		const attrDataValuesFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grilla.atrib')
			  })
			: []
		const attrDataValuesFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.atrib')
			  })
			: []
		const componentsFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grilla.comp')
			  })
			: []
		const componentsFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.comp')
			  })
			: []
		const combosFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grilla.comb')
			  })
			: []
		const combosFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.comb')
			  })
			: []
		const pricesFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grilla.lprec')
			  })
			: []
		const pricesFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.lprec')
			  })
			: []
		const costsFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grilla.cpact')
			  })
			: []
		const costsFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.cpact')
			  })
			: []
		const depotsFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grilla.deplim')
			  })
			: []
		const depotsFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.deplim')
			  })
			: []
		const cufFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grilla.cuf')
			  })
			: []
		const cufFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.cuf')
			  })
			: []
		const bonifFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.bon')
			  })
			: []
		const bundlesFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grilla.present')
			  })
			: []
		const bundlesFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.present')
			  })
			: []

		const tabProps = {
			basic: {
				disableForm: disableForm,
				itemInfo: valueDataSelected,
				fields: fields,
				showMessage: this.props.showMessage,
				handleSubmit: op === 'add' ? this.handleNewCrud : this.handleEditCrud,
				method: op,
				handleCloseModal: this.handleRedirectHome,
				showWarning: showWarningPopup,
				handleWarning: this.handleWarningPopup,
				setFormChanged: this.handleSetFormChanged,
				subGroups: subGroups
			},
			taxes: {
				catributo: crudCode,
				urlApi: 'abm_inv_productos_impu',
				tabId: 'niprod',
				valueId: 'cod_imp',
				crudId: selectedAbm.id,
				fields: taxesFields,
				fieldsAlta: taxesFieldsAlta,
				Component: TaxesForm,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'impu')?.tipo_perm ===
						'L' ||
					false,
				t: t
			},
			additional_data: {
				catributo: crudCode,
				urlApi: 'abm_inv_productos_atrib',
				tabId: 'niprod',
				valueId: 'catributo',
				crudId: selectedAbm.id,
				fields: attrDataValuesFields,
				fieldsAlta: attrDataValuesFieldsAlta,
				Component: AdditionalDataForm,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'atrib')
						?.tipo_perm === 'L' ||
					false,
				t: t
			},
			components: {
				catributo: crudCode,
				urlApi: 'abm_inv_productos_comp',
				tabId: 'niprod',
				valueId: 'nicomp',
				crudId: selectedAbm.id,
				fields: componentsFields,
				fieldsAlta: componentsFieldsAlta,
				Component: ComponentsForm,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'comp')?.tipo_perm ===
						'L' ||
					false,
				t: t
			},
			combos: {
				catributo: crudCode,
				urlApi: 'abm_inv_productos_comb',
				tabId: 'niprod',
				valueId: 'nicomp',
				crudId: selectedAbm.id,
				fields: combosFields,
				fieldsAlta: combosFieldsAlta,
				Component: CombosForm,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'comb')?.tipo_perm ===
						'L' ||
					false,
				t: t
			},
			prices: {
				catributo: crudCode,
				urlApi: 'abm_inv_productos_prec',
				tabId: 'niprod',
				valueId: 'cod_lpre',
				crudId: selectedAbm.id,
				fields: pricesFields,
				fieldsAlta: pricesFieldsAlta,
				Component: PricesForm,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'lprec')
						?.tipo_perm === 'L' ||
					false,
				t: t
			},
			costs: {
				catributo: crudCode,
				urlApi: 'abm_inv_productos_cpact',
				tabId: 'niprod',
				valueId: 'niprov',
				crudId: selectedAbm.id,
				fields: costsFields,
				fieldsAlta: costsFieldsAlta,
				Component: CostsForm,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'cpact')
						?.tipo_perm === 'L' ||
					false,
				t: t
			},
			depots: {
				catributo: crudCode,
				urlApi: 'abm_inv_productos_deplim',
				tabId: 'niprod',
				valueId: 'cod_dep',
				crudId: selectedAbm.id,
				fields: depotsFields,
				fieldsAlta: depotsFieldsAlta,
				Component: DepotsForm,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'deplim')
						?.tipo_perm === 'L' ||
					false,
				t: t
			},
			cuf: {
				catributo: crudCode,
				urlApi: 'abm_inv_productos_cuf',
				tabId: 'niprod',
				valueId: 'cuf',
				fields: cufFields,
				fieldsAlta: cufFieldsAlta,
				Component: CufForm,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'CUF')?.tipo_perm ===
						'L' ||
					false,
				t: t
			},
			bon: {
				niprod: crudCode,
				fields: bonifFieldsAlta,
				itemInfo: bonificationInfo,
				op: op,
				disableFields: disabledBonificationFields,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'bon')?.tipo_perm ===
						'L' ||
					false,
				handleSubmit: this.handleEditBonification,
				handleBonificationDisable: this.handleBonificationDisable,
				handleWarning: this.handleWarningPopup,
				showWarning: showWarningPopup,
				setFormChanged: this.handleSetFormChanged
			},
			bundles: {
				catributo: crudCode,
				urlApi: 'abm_inv_productos_present',
				tabId: 'niprod',
				valueId: 'nipresent',
				crudId: selectedAbm.id,
				fields: bundlesFields,
				fieldsAlta: bundlesFieldsAlta,
				Component: BundlesForm,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'present')
						?.tipo_perm === 'L' ||
					false,
				t: t
			}
		}
		const propsCrudContainer = {
			urlApi: 'abm_inv_productos',
			getNewData: this.handleCrudChange,
			keyApi: 'niprod',
			title: t('global.product'),
			handleExitAbm: this.handleRedirectHome,
			showWarning: formChanged,
			handleWarning: this.handleWarningPopup,
			nextAction: nextAction,
			setNextAction: this.handleSetNextAction,
			op: op
		}

		const componentTabs = Array.from(productTabs).map((tab) => {
			const selectedTab: any =
				tab.type === 'basic'
					? {
							key: tab.key,
							title: t(tab.label),
							content:
								tabActive === tab.key ? (
									<CrudProductData {...tabProps.basic} />
								) : (
									<div />
								)
					  }
					: tab.type === 'additional'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<ValuesCrudTable {...tabProps.additional_data} />
								) : (
									<div />
								)
					  }
					: tab.type === 'taxes'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<ValuesCrudTable {...tabProps.taxes} />
								) : (
									<div />
								)
					  }
					: tab.type === 'components'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<ValuesCrudTable {...tabProps.components} />
								) : (
									<div />
								)
					  }
					: tab.type === 'combos'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<ValuesCrudTable {...tabProps.combos} />
								) : (
									<div />
								)
					  }
					: tab.type === 'costs'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<ValuesCrudTable {...tabProps.costs} />
								) : (
									<div />
								)
					  }
					: tab.type === 'depots'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<ValuesCrudTable {...tabProps.depots} />
								) : (
									<div />
								)
					  }
					: tab.type === 'cuf'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<ValuesCrudTable {...tabProps.cuf} />
								) : (
									<div />
								)
					  }
					: tab.type === 'prices'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<ValuesCrudTable {...tabProps.prices} />
								) : (
									<div />
								)
					  }
					: tab.type === 'bundles'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<ValuesCrudTable {...tabProps.bundles} />
								) : (
									<div />
								)
					  }
					: tab.type === 'bonif' && {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<BonificationTab {...tabProps.bon} />
								) : (
									<div />
								)
					  }
			return selectedTab
		})

		return (
			<CrudsContainer {...propsCrudContainer}>
				<Row className={'pt-4'}>
					<Col>
						<Card.Title>
							{disableForm
								? t('product_data.title_view')
								: op === 'edit'
								? t('product_data.title_edit')
								: t('product_data.title_new')}
						</Card.Title>
					</Col>
				</Row>
				{valueDataSelected && (
					<>
						<Row>
							<Col sm={2}>
								<strong>{t('global.internal_code')}</strong>
							</Col>
							<Col className={'text-left'}>
								{valueDataSelected && valueDataSelected.niprod}
							</Col>
						</Row>
						<Row className={'pb-2'}>
							<Col sm={2}>
								<strong>{t('global.name')}</strong>
							</Col>
							<Col className={'text-left'}>
								{valueDataSelected && valueDataSelected.descrip1}
							</Col>
						</Row>
					</>
				)}
				<CommonTabs
					customTabs={componentTabs}
					defaultActiveKey={tabActive}
					getActiveKey={this.handleActiveKey}
					showWarning={formChanged}
					handleWarning={this.handleWarningPopup}
					nextTab={nextTab}
					setNextTab={this.handleSetNextTab}
				/>
			</CrudsContainer>
		)
	}
}

const mapStateToProps = ({ configCrud, productBonification }: any) => {
	const {
		fieldsAltaConfig,
		fieldsConfig,
		valueDataSelected,
		valueDataUpdated
	} = configCrud
	const { bonificationInfo, valueUpdated } = productBonification
	return {
		fieldsConfig,
		fieldsAltaConfig,
		valueDataSelected,
		valueDataUpdated,
		valueUpdated,
		bonificationInfo
	}
}

const connectForm = connect(mapStateToProps, {
	crudsGetSingle,
	crudsCreate,
	crudsClearStore,
	showMessage,
	crudGetProductBonification,
	crudUpdateProductBonification,
	// crudClearProductCodeStore,
	clearTableValidations
})(ProductDataContent)

export default withTranslation()(withMenu(withRouter(connectForm)))
