import Axios from 'axios'
import {
	ICrudGetFundAccountBankReconciliation,
	ICrudUpdateFundAccountBankReconciliation
} from 'models/CrudFundsAccountsInterface'

const urlBase = '/abm_fon_cuentas_concil'

export const crudGetFundAccountBankReconciliation = async (
	params: ICrudGetFundAccountBankReconciliation
) => {
	const response = await Axios.get(`${urlBase}/consulta`, { params })
	return response.data
}

export const crudUpdateFundAccountBankReconciliation = async (
	params: ICrudUpdateFundAccountBankReconciliation
) => {
	const response = await Axios.post(`${urlBase}/confirmar`, params)
	return response.data
}
