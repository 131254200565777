import {
	CLEAR_PRODUCT_STORE,
	DELETE_SEARCHED_PRODUCTS,
	DELETE_SEARCHED_PRODUCTS_SUCCESS,
	GET_DETAIL_MAIN_DATA,
	GET_DETAIL_MAIN_DATA_SUCCESS,
	GET_DETAIL_SALES_DATA,
	GET_DETAIL_SALES_DATA_SUCCESS,
	GET_DETAIL_SHOPPING_DATA,
	GET_DETAIL_SHOPPING_DATA_SUCCESS,
	GET_DETAIL_STOCK,
	GET_DETAIL_STOCK_SUCCESS,
	GET_EQUIVALENT_DATA,
	GET_EQUIVALENT_DATA_SUCCESS,
	GET_FILTER_PRODUCT_ATTRIBUTES,
	GET_FILTER_PRODUCT_ATTRIBUTES_SUCCESS,
	GET_PERIOD_FILTER,
	GET_PERIOD_FILTER_SUCCESS,
	GET_PRODUCT_ORDER_OPTIONS,
	GET_PRODUCT_ORDER_OPTIONS_SUCCESS,
	GET_STOCK_ORDER_OPTIONS,
	GET_STOCK_ORDER_OPTIONS_SUCCESS,
	GET_SUGGESTED_DATA,
	GET_SUGGESTED_DATA_SUCCESS,
	PRODUCT_CATEGORY_FILTER,
	PRODUCT_CATEGORY_FILTER_SUCCESS,
	SELECT_SEARCHED_PRODUCTS,
	SELECT_SEARCHED_PRODUCTS_SUCCESS
} from 'constants/ActionsTypes'
import { IResponseOptions } from 'models/SearchProduct'

export interface valueState {
	sortOptions?: Partial<IResponseOptions[]>
	categoryOptions?: Partial<IResponseOptions[]>
	filterAttributes?: Partial<IResponseOptions[]>
	detailMainData?: any
	detailStock?: any
	detailSales?: any
	detailShopping?: any
	stockOptions?: Partial<IResponseOptions[]>
	periodOptions?: Partial<IResponseOptions[]>
	detailSuggested: any
	detailEquivalent: any
	activeProductTab: string
	selectedProducts: any
}

const initialState = {
	sortOptions: [],
	categoryOptions: [],
	filterAttributes: [],
	detailMainData: [],
	detailStock: [],
	stockOptions: [],
	detailSales: [],
	detailShopping: [],
	periodOptions: [],
	detailSuggested: [],
	detailEquivalent: [],
	activeProductTab: '',
	selectedProducts: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case GET_PRODUCT_ORDER_OPTIONS:
			return { ...state, sortOptions: [] }
		case GET_PRODUCT_ORDER_OPTIONS_SUCCESS:
			return { ...state, sortOptions: action.payload.lista }
		case PRODUCT_CATEGORY_FILTER:
			return { ...state, categoryOptions: [] }
		case PRODUCT_CATEGORY_FILTER_SUCCESS:
			return { ...state, categoryOptions: action.payload.lista }
		case GET_FILTER_PRODUCT_ATTRIBUTES:
			return { ...state, filterAttributes: [] }
		case GET_FILTER_PRODUCT_ATTRIBUTES_SUCCESS:
			return { ...state, filterAttributes: action.payload.lista }
		case GET_DETAIL_MAIN_DATA:
			return { ...state, detailMainData: [] }
		case GET_DETAIL_MAIN_DATA_SUCCESS:
			return { ...state, detailMainData: action.payload }
		case GET_DETAIL_STOCK:
			return { ...state, detailStock: [] }
		case GET_DETAIL_STOCK_SUCCESS:
			return { ...state, detailStock: action.payload }
		case GET_STOCK_ORDER_OPTIONS:
			return { ...state, stockOptions: [] }
		case GET_STOCK_ORDER_OPTIONS_SUCCESS:
			return { ...state, stockOptions: action.payload.lista }
		case GET_DETAIL_SALES_DATA:
			return { ...state, detailSales: [] }
		case GET_DETAIL_SALES_DATA_SUCCESS:
			return { ...state, detailSales: action.payload }
		case GET_PERIOD_FILTER:
			return { ...state, periodOptions: [] }
		case GET_PERIOD_FILTER_SUCCESS:
			return { ...state, periodOptions: action.payload.lista }
		case GET_DETAIL_SHOPPING_DATA:
			return { ...state, detailShopping: [] }
		case GET_DETAIL_SHOPPING_DATA_SUCCESS:
			return { ...state, detailShopping: action.payload }
		case GET_SUGGESTED_DATA:
			return { ...state, detailSuggested: [] }
		case GET_SUGGESTED_DATA_SUCCESS:
			return { ...state, detailSuggested: action.payload }
		case GET_EQUIVALENT_DATA:
			return { ...state, detailEquivalent: [] }
		case GET_EQUIVALENT_DATA_SUCCESS:
			return { ...state, detailEquivalent: action.payload }
		case CLEAR_PRODUCT_STORE:
			return { ...state, activeProductTab: '' }
		case SELECT_SEARCHED_PRODUCTS:
			return { ...state, selectedProducts: null }
		case SELECT_SEARCHED_PRODUCTS_SUCCESS:
			return { ...state, selectedProducts: action.payload }
		case DELETE_SEARCHED_PRODUCTS:
			return { ...state, selectedProducts: null }
		case DELETE_SEARCHED_PRODUCTS_SUCCESS:
			return { ...state, selectedProdcuts: null }
		default:
			return state
	}
}

export default rootReducer
