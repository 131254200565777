import SingleTabCrudContent from 'components/genericCrud/SingleTabCrudContent'
import CrudMessage from 'components/message/CrudMessage'
import { CRUD_MESSAGES_DATA } from 'utils/RoutePath'

export const messageConfig = {
	id: 'messagesCrud',
	key: 'cod_mens',
	descrip: 'mensaje',
	searchMethod: 'get',
	category: 'A',
	translation: 'messages_data',
	dataUrl: CRUD_MESSAGES_DATA,
	urlApi: 'abm_vta_mensajes',
	component: SingleTabCrudContent,
	tabsList: [
		{
			key: 'basic',
			title: 'CRUDS.general_use.basic_tab',
			tabComponent: CrudMessage
		}
	],
	tabsGroupsAlta: ['form_alta']
}
