import CrudCostCenterTabsDataContents from 'components/crudCostCenters/CrudCostCenterTabsDataContents'
import { CRUD_COST_CENTERS_DATA } from 'utils/RoutePath'

export const costCentersConfig = {
	id: 'costCentersCrud',
	key: 'nicc',
	descrip: 'descrip',
	searchMethod: 'get',
	category: 'A',
	translation: 'cost_centers_data',
	dataUrl: CRUD_COST_CENTERS_DATA,
	urlApi: 'abm_cont_cc',
	component: CrudCostCenterTabsDataContents
}
