import { TreasuryHeaderActions } from 'constants/ActionsTypesTs'
import { IResponseResult } from 'models/ResultsInterface'
import {
	IConfirmTreasuryAttributesAction,
	IConfirmTreasuryAttributesActionSuccess,
	IConfirmTreasuryAttributesPayload,
	IGetAttributesTreasuryHeaderAction,
	IGetAttributesTreasuryHeaderActionSuccess,
	IGetAttributesTreasuryHeaderParams,
	IGetAttributesTreasuryHeaderResponse,
	IGetTreasuryCurrenciesAction,
	IGetTreasuryCurrenciesActionSuccess,
	IGetTreasuryCurrenciesParams,
	IGetTreasuryCurrenciesResponse
} from 'models/TreasuryHeader'

export const getAttributesTreasuryHeader = (
	payload: IGetAttributesTreasuryHeaderParams
): IGetAttributesTreasuryHeaderAction => ({
	payload,
	type: TreasuryHeaderActions.GET_ATTRIBUTES_TREASURY_HEADER
})

export const getAttributesTreasuryHeaderSuccess = (
	payload: IGetAttributesTreasuryHeaderResponse
): IGetAttributesTreasuryHeaderActionSuccess => ({
	payload,
	type: TreasuryHeaderActions.GET_ATTRIBUTES_TREASURY_HEADER_SUCCESS
})

export const confirmTreasuryAttributes = (
	payload: IConfirmTreasuryAttributesPayload
): IConfirmTreasuryAttributesAction => ({
	type: TreasuryHeaderActions.CONFIRM_ATTRIBUTES_TREASURY_HEADER,
	payload
})

export const confirmTreasuryAttributesSuccess = (
	payload: IResponseResult
): IConfirmTreasuryAttributesActionSuccess => ({
	type: TreasuryHeaderActions.CONFIRM_ATTRIBUTES_TREASURY_HEADER_SUCCESS,
	payload
})

export const getTreasuryCurrencies = (
	payload: IGetTreasuryCurrenciesParams
): IGetTreasuryCurrenciesAction => ({
	type: TreasuryHeaderActions.GET_TREASURY_CURRENCIES,
	payload
})

export const getTreasuryCurrenciesSuccess = (
	payload: IGetTreasuryCurrenciesResponse
): IGetTreasuryCurrenciesActionSuccess => ({
	type: TreasuryHeaderActions.GET_TREASURY_CURRENCIES_SUCCESS,
	payload
})
