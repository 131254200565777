import {
	GET_EGR_STOCK,
	GET_EGR_STOCK_SUCCESS,
	PICKEO_EGR_SEARCH_BARCODE,
	PICKEO_EGR_SEARCH_BARCODE_ERROR,
	PICKEO_EGR_SEARCH_BARCODE_SUCCESS,
	PICKEO_EGR_STOCK_CONFIRM,
	PICKEO_EGR_STOCK_CONFIRM_SUCCESS
} from '../constants/ActionsTypes'

export const getEgrStock = (params) => {
	return {
		type: GET_EGR_STOCK,
		payload: params
	}
}

export const getEgrStockSuccess = (response) => {
	return {
		type: GET_EGR_STOCK_SUCCESS,
		payload: response
	}
}

export const pickeoEgrSearchBarcode = (params) => {
	return {
		type: PICKEO_EGR_SEARCH_BARCODE,
		payload: params
	}
}

export const pickeoEgrSearchBarcodeSuccess = (response) => {
	return {
		type: PICKEO_EGR_SEARCH_BARCODE_SUCCESS,
		payload: response
	}
}

export const pickeoEgrSearchBarcodeError = (error) => {
	return {
		type: PICKEO_EGR_SEARCH_BARCODE_ERROR,
		payload: error
	}
}

export const pickeoEgrStockConfirm = (params) => {
	return {
		type: PICKEO_EGR_STOCK_CONFIRM,
		payload: params
	}
}

export const pickeoEgrStockConfirmSuccess = (response) => {
	return {
		type: PICKEO_EGR_STOCK_CONFIRM_SUCCESS,
		payload: response
	}
}
