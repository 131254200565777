import moment from 'moment'
import _ from 'underscore'
import * as Yup from 'yup'

export function validateForm(config, row, t) {
	const message = []
	let success = true
	config.campos.forEach((field) => {
		if (parseInt(field.requerido)) {
			const campoId = field.idCampo.trim()
			if (!validateField(row[campoId], field.valid)) {
				message.push(
					'El campo ' + t('validation-required', { field: field.label })
				)
				success = false
			}
		}
	})

	return { message, success }
}

export function validateField(value, type) {
	let result = false
	const today = moment()
	let customDate = moment()

	if (value === 'error_input') {
		value = null
	}

	switch (type) {
		case 'VAL>0':
			if (parseFloat(value) > 0) {
				result = true
			}
			break
		case 'VAL>=0':
			if (parseFloat(value) >= 0) {
				result = true
			}
			break
		case 'VAL<=0':
			if (parseFloat(value) <= 0) {
				result = true
			}
			break
		case 'VAL<0':
			if (parseFloat(value) < 0) {
				result = true
			}
			break
		case 'FEC>=H':
			customDate = moment(value)
			if (customDate.diff(today) >= 0) {
				result = true
			}
			break
		case 'FEC>H':
			customDate = moment(value)
			if (customDate.diff(today) > 0) {
				result = true
			}
			break
		case 'FEC<=H':
			customDate = moment(value)
			if (customDate.diff(today) <= 0) {
				result = true
			}
			break
		case 'FEC<H':
			customDate = moment(value)
			if (customDate.diff(today) < 0) {
				result = true
			}
			break

		default:
			if (value) {
				result = true // Default se valida campos vacios
			}
			break
	}

	return result
}

export function getValidationSchema(
	campos,
	t,
	excludeFields = [],
	apiValidationArray
) {
	const validations = {}
	campos.forEach((field) => {
		const idField = field.idCampo.trim()
		const exclude = _.contains(excludeFields, field.idCampo)
		const customValidation = _.find(apiValidationArray, (validationItem) => {
			return validationItem.id === idField
		})

		if (field.requerido && !exclude) {
			validations[idField] = Yup.string().required(
				t('validation-required', { field: field.label })
			)
		}

		if (customValidation) {
			validations[idField] = customValidation.validation // custom
		}
	})

	return Yup.object().shape(validations)
}

export function getInitValues(fields, values = {}) {
	const validations = {}

	fields.forEach((field) => {
		const idField = field.idCampo.trim()
		const defaultValue = idField === 'importe' ? '0' : ''
		if (field.requerido) {
			validations[idField] =
				values[idField] && parseInt(values[idField]) > 0
					? values[idField]
					: defaultValue
		} else {
			validations[idField] = values[idField] || ''
		}
	})

	return validations
}
