import { ICrudGenericFields } from 'models/CrudInterface'

export const equivalentProductsFields: Array<ICrudGenericFields> = [
	{
		id: 'desc_prod_ref',
		type: 'autoComplete',
		labelWidth: 'col-sm-3',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'product',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'nombre',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'mascara',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'desc_filtro',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'check_selected',
		type: 'check',
		labelWidth: 'col-sm-3',
		width: 'col-sm-9',
		height: '',
		default: 0,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'product_select',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-9',
		height: '',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
