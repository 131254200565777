import {
	CANCEL_VOUCHER_NUMBER,
	CANCEL_VOUCHER_NUMBER_SUCCESS,
	GET_AFFECTATION_VOUCHER_BY_USER,
	GET_AFFECTATION_VOUCHER_BY_USER_SUCCESS,
	GET_VOUCHER_NUMBER,
	GET_VOUCHER_NUMBER_SUCCESS,
	GET_VOUCHER_TYPE,
	GET_VOUCHER_TYPE_BY_USER,
	GET_VOUCHER_TYPE_BY_USER_SUCCESS,
	GET_VOUCHER_TYPE_SUCCESS,
	NEW_VOUCHER_TYPE,
	NEW_VOUCHER_TYPE_SUCCESS,
	SAVE_CONFIG_STYLE_VOUCHER_TYPE_BY_USER,
	SAVE_CONFIG_STYLE_VOUCHER_TYPE_BY_USER_SUCCESS,
	VOUCHER_CANCEL,
	VOUCHER_CANCEL_SUCCESS,
	VOUCHER_SAVE_AND_NEW,
	VOUCHER_SAVE_AND_NEW_SUCCESS
} from 'constants/ActionsTypes'

export const getVoucherType = (params) => {
	return {
		type: GET_VOUCHER_TYPE,
		payload: params
	}
}

export const getVoucherTypeSuccess = (response) => {
	return {
		type: GET_VOUCHER_TYPE_SUCCESS,
		payload: response
	}
}

export const getVoucherTypeByUser = (params) => {
	return {
		type: GET_VOUCHER_TYPE_BY_USER,
		payload: params
	}
}

export const getVoucherTypeByUserSuccess = (response) => {
	return {
		type: GET_VOUCHER_TYPE_BY_USER_SUCCESS,
		payload: response
	}
}

export const getAffectationVoucherByUser = (params) => {
	return {
		type: GET_AFFECTATION_VOUCHER_BY_USER,
		payload: params
	}
}

export const getAffectationVoucherByUserSuccess = (response) => {
	return {
		type: GET_AFFECTATION_VOUCHER_BY_USER_SUCCESS,
		payload: response
	}
}

export const voucherCancel = (params) => {
	return {
		type: VOUCHER_CANCEL,
		payload: params
	}
}

export const voucherCancelSuccess = (response) => {
	return {
		type: VOUCHER_CANCEL_SUCCESS,
		payload: response
	}
}

export const voucherSaveAndNew = (params) => {
	return {
		type: VOUCHER_SAVE_AND_NEW,
		payload: params
	}
}

export const voucherSaveAndNewSuccess = (response) => {
	return {
		type: VOUCHER_SAVE_AND_NEW_SUCCESS,
		payload: response
	}
}

export const saveVouchersStyleConfigByUser = (params) => {
	return {
		type: SAVE_CONFIG_STYLE_VOUCHER_TYPE_BY_USER,
		payload: params
	}
}

export const saveVouchersStyleConfigByUserSuccess = (response) => {
	return {
		type: SAVE_CONFIG_STYLE_VOUCHER_TYPE_BY_USER_SUCCESS,
		payload: response
	}
}

export const newVoucherType = (params) => {
	return {
		type: NEW_VOUCHER_TYPE,
		payload: params
	}
}

export const newVoucherTypeSuccess = (params) => {
	return {
		type: NEW_VOUCHER_TYPE_SUCCESS,
		payload: params
	}
}

export const getVoucherNumber = (params) => {
	return {
		type: GET_VOUCHER_NUMBER,
		payload: params
	}
}

export const getVoucherNumberSuccess = (params) => {
	return {
		type: GET_VOUCHER_NUMBER_SUCCESS,
		payload: params
	}
}

export const cancelVoucherNumber = (params) => {
	return {
		type: CANCEL_VOUCHER_NUMBER,
		payload: params
	}
}

export const cancelVoucherNumberSuccess = (params) => {
	return {
		type: CANCEL_VOUCHER_NUMBER_SUCCESS,
		payload: params
	}
}
