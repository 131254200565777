import { finalConsumerConfirm, getTableDocumentType } from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { P_FINAL_CONSUMER } from 'constants/ConfigProcessNames'
import { Formik } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { IConfigFieldsForm } from 'models/Budget'
import {
	IFinalConsumerParams,
	IFinalConsumerValues
} from 'models/FinalConsumer'
import React, { Fragment, PureComponent } from 'react'
import { Col, Form } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import FinalConsumerFormInputs from './FinalConsumerFormInputs'

interface IFinalConsumerFormProps extends WithTranslation {
	config: IConfigFieldsForm
	t?: any
	idOperacion?: any
	getTableDocumentType: (payload: any) => void
	formRef: any
	finalConsumerConfirm: (payload: IFinalConsumerParams) => void
	callBackReturn?: any
}

class FinalConsumerForm extends PureComponent<IFinalConsumerFormProps> {
	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.getTableDocumentType({ idOperacion })
	}

	handleConfirmFinalConsumer = (params: IFinalConsumerValues) => {
		const { callBackReturn, idOperacion } = this.props
		this.props.finalConsumerConfirm({
			values: { ...params, idOperacion },
			callBackReturn
		})
	}

	render() {
		const { t, idOperacion, config, formRef } = this.props
		const fields = config && !_.isEmpty(config.campos) ? config.campos : []
		const initialValues = getInitValues(fields)
		const validationSchema = getValidationSchema(fields, t)

		return (
			<Col className={`col-12`}>
				{config && (
					<Formik
						ref={formRef}
						initialValues={initialValues}
						onSubmit={(values: any, actions) =>
							this.handleConfirmFinalConsumer(values)
						}
						validationSchema={validationSchema}
						enableReinitialize={true}
						render={(props) => {
							const {
								values,
								handleChange,
								handleBlur,
								setFieldValue,
								setFieldTouched
							} = props

							return (
								<Fragment>
									<Col sm={12}>
										<NotificationError
											errors={props.errors}
											touched={props.touched}
										/>
									</Col>
									<Form
										onSubmit={(e) => props.handleSubmit(e as any)}
										className="voucher-info-form"
									>
										<FinalConsumerFormInputs
											{...{
												values,
												handleChange,
												handleBlur,
												setFieldValue,
												setFieldTouched,
												fields: config.campos,
												idOperacion
											}}
										/>

										{/* <Row className={'pb-2 pr-3'}>
											<Col
												sm={{ span: 6, offset: 6 }}
												className={'text-right mt-2'}
											>
												<Button style={{ minWidth: '125px' }} type={'submit'}>
													{t('global.filter')}
												</Button>
											</Col>
										</Row> */}
									</Form>
								</Fragment>
							)
						}}
					/>
				)}
			</Col>
		)
	}
}

const mapStateToProps = ({ voucher }: any) => {
	const config = voucher.config ? voucher.config[P_FINAL_CONSUMER] : null
	return { config }
}

const mapDispatchToProps = {
	getTableDocumentType,
	finalConsumerConfirm
}

const connectForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(FinalConsumerForm)

export default withTranslation()(connectForm)
