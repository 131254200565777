import CommonAutocomplete from 'components/common/commonAutocomplete'
import NotificationMessage from 'components/common/notificationMessage'
import InputDropdown from 'components/form/inputDropdown'
import { Formik } from 'formik'
import React, { Component } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import * as Yup from 'yup'

class SelectForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showFilters: false,
			optionsVoucherType: this.getOptionsSelect(props.optionsSelect), // set options in the select when init form.
			filterOptions: [],
			moduleOptions: _.allKeys(_.countBy(props.optionsSelect, 'modulo')).map(
				(module) => {
					return { label: module, check: true }
				}
			),
			showError: false,
			errorMessage: ''
		}
	}

	/**
	 * to get options to select, return array with id and label
	 * @params options from voucherstate
	 */
	getOptionsSelect = (options) => {
		const result = options.map((voucher) => {
			return {
				id: voucher.nioperacion,
				label: voucher.descrip_comprob
			}
		})

		return [
			{
				id: '',
				label: 'Seleccione un tipo'
			}
		].concat(result)
	}

	/**
	 * To create new voucher
	 * @params value, document selected
	 */
	onSubmit = (values) => {
		const { userCompany } = this.props
		const { voucherType, voucherTypeAuto } = values
		const idComprobante =
			voucherType !== ''
				? voucherType
				: voucherTypeAuto[0]
				? voucherTypeAuto[0].id
				: null

		if (idComprobante) {
			this.props.getVoucherType({ idComprobante, idEmpresa: userCompany })
		} else {
			this.setState({
				showError: true,
				errorMessage: 'Se debe seleccionar un tipo de comprobante'
			})
		}
	}

	/**
	 * to show and hide filters
	 */
	toggleFilters = () => {
		const { showFilters } = this.state
		const { optionsSelect } = this.props

		this.setState((prevState) => ({
			showFilters: !prevState.showFilters,
			optionsVoucherType: showFilters
				? this.getOptionsSelect(optionsSelect)
				: prevState.optionsVoucherType,
			filterOptions: []
		}))
	}

	/**
	 * to get filters by module from all vouchers.
	 * @param{
	 * } module
	 */
	getFilterByModule = (module) => {
		const { optionsSelect } = this.props
		return _.filter(optionsSelect, (opt) => {
			return opt.modulo === module
		})
	}

	/**
	 * to set filter in options
	 * @param {
	 * } options
	 */

	handleOnChange = (envt) => {
		const { value, checked } = envt.target
		const { moduleOptions } = this.state
		const result = []

		let optionsVoucherType = []

		moduleOptions.forEach((module) => {
			if (module.label === value) {
				module.check = checked
			}
		})

		moduleOptions.forEach((module) => {
			if (module.check) {
				result.push(...this.getFilterByModule(module.label))
			}
		})

		optionsVoucherType = this.getOptionsSelect(result)

		this.setState({ optionsVoucherType })
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	render() {
		const { t, colForm } = this.props
		const { optionsVoucherType, moduleOptions, showError, errorMessage } =
			this.state
		const inputConfig = [
			{
				idCampo: 'voucherType',
				label: 'Tipo de comprobante',
				visible: 1,
				requerido: 0,
				editable: 1
			}
		]
		const colSize = colForm || 8
		return (
			<Col sm={colSize} className={'mb-1 pt-3'}>
				<Formik
					initialValues={{ voucherTypeAuto: [], voucherType: '' }}
					onSubmit={(values, actions) => {
						this.onSubmit(values)

						actions.setSubmitting(false)
					}}
					validationSchema={Yup.object().shape({})}
					render={({ handleSubmit, setFieldValue, values }) => (
						<Form onSubmit={handleSubmit} className="voucher-info-form">
							<Row>
								<Col sm={12}>
									<NotificationMessage
										showError={showError}
										errorMessage={errorMessage}
										handleCloseError={this.handleCloseError}
										type={'danger'}
									/>
								</Col>
								<Col sm={10} className={'pb-4'}>
									<CommonAutocomplete
										options={optionsVoucherType}
										handleOnchange={(selected) => {
											setFieldValue('voucherTypeAuto', selected)
											setFieldValue('voucherType', '')
										}}
										placeHolder={t('selectType.form.insert_type_voucher')}
										id="voucherType"
										selected={values.voucherTypeAuto}
									/>
								</Col>
								<Col sm={10}>
									<InputDropdown
										inputFormCol={{ sm: 12 }}
										fields={inputConfig}
										label={t('client.form.client_branch')}
										inputId={'voucherType'}
										name={'voucherType'}
										placeholder={t('selectType.form.insert_type_voucher')}
										styles={{ width: '100%' }}
										colLabel={'col-sm-3'}
										colInput={'col-sm-9'}
										options={optionsVoucherType}
										value={values.voucherType}
										onChange={(value) => {
											setFieldValue('voucherType', value.target.value)
											setFieldValue('voucherTypeAuto', [])
										}}
									/>
								</Col>
								<Col sm={2} className={'text-left'}>
									<Button type={'primary'}>{t('global.new')}</Button>
								</Col>
							</Row>
						</Form>
					)}
				/>
				<Col sm={12}>
					{moduleOptions.map((module) => {
						return (
							<Col sm={6} key={module.label}>
								<input
									type="checkbox"
									checked={module.check}
									className={'form-check-input ml-4 mt-2'}
									onChange={this.handleOnChange}
									value={module.label}
								/>

								<label
									className={'form-check-label ml-5'}
									style={{ fontSize: 14, fontFamily: 'inherit' }}
								>
									{module.label}
								</label>
							</Col>
						)
					})}
				</Col>
			</Col>
		)
	}
}

export default withTranslation()(withRouter(SelectForm))
