import {
	SALES_AFFECTED_CONFIRM,
	SALES_AFFECTED_CONFIRM_SUCCESS,
	SALES_AFFECTED_IMPORT,
	SALES_AFFECTED_IMPORT_CONFIRM,
	SALES_AFFECTED_IMPORT_CONFIRM_SUCCESS,
	SALES_AFFECTED_IMPORT_SUCCESS,
	SALES_AFFECTED_IMPORT_VALIDATE,
	SALES_AFFECTED_IMPORT_VALIDATE_SUCCESS,
	SALES_AFFECTED_QUANTITY,
	SALES_AFFECTED_QUANTITY_SUCCESS,
	SALES_AFFECTED_STATE,
	SALES_AFFECTED_STATE_CONFIRM,
	SALES_AFFECTED_STATE_CONFIRM_SUCCESS,
	SALES_AFFECTED_STATE_SUCCESS,
	SALES_AFFECTED_STATE_VALIDATE,
	SALES_AFFECTED_STATE_VALIDATE_SUCCESS,
	SALES_AFFECTED_SUB_CALCULATION,
	SALES_AFFECTED_SUB_CALCULATION_SUCCESS,
	SALES_AFFECTED_VALIDATE,
	SALES_AFFECTED_VALIDATE_SUCCESS,
	SET_TABLE_DATA_INVOLVEMENT,
	VALIDATE_SALES_AFFECTED_DISCOUNT,
	VALIDATE_SALES_AFFECTED_DISCOUNT_SUCCESS,
	VALIDATE_SALES_AFFECTED_PRICE,
	VALIDATE_SALES_AFFECTED_PRICE_SUCCESS,
	VOUCHER_CANCEL_SUCCESS
} from 'constants/ActionsTypes'
import _ from 'underscore'

const initialState = {
	cantValidate: null,
	productsInvol: null,
	productsImport: null,
	subCalculations: null,
	productsUpdate: null,
	salesImportconfirm: null,
	salesconfirm: null,
	productsState: null,
	stateConfirm: null,
	paramsValidate: null,
	discountValidate: null,
	priceValidate: null,
	salesDiscount: null
}

function rootReducer(state = initialState, action) {
	let validateItems
	let updateState
	let importItems
	let updateImportState
	let updateCalsub
	let updateItem
	let stateValidateItems
	let createState
	let updateStateValidate
	let paramsArray
	let discountItem
	let updateDiscount
	let updateRowItems
	let updateRowItem

	switch (action.type) {
		case SALES_AFFECTED_VALIDATE:
			return {
				...state,
				cantValidate: null,
				paramsValidate: action.payload,
				...updateProductItems(state.productsInvol, state.productsUpdate)
			}
		case SALES_AFFECTED_VALIDATE_SUCCESS:
			validateItems = state.productsInvol.Items
			updateState = {
				...state,
				productsUpdate: getUpdateItems(
					state.productsInvol.Items,
					action.payload.Items
				),
				cantValidate: action.payload
			}

			updateRowItems = validateItems.map((prod) => {
				updateRowItem = _.findWhere(updateState.productsUpdate, { id: prod.id })
				return updateRowItem || { ...prod }
			})

			updateState.productsUpdate = updateRowItems

			return updateState
		case SALES_AFFECTED_IMPORT_VALIDATE:
			return { ...state, cantValidate: null }
		case SALES_AFFECTED_IMPORT_VALIDATE_SUCCESS:
			importItems = action.payload.Items
			updateImportState = {
				...state,
				productsUpdate: [...importItems],
				cantValidate: action.payload
			}

			if (updateImportState.productsUpdate) {
				updateImportState.productsUpdate.forEach((uptItem, index) => {
					state.productsImport.Items.forEach((currentItem) => {
						if (currentItem.id === uptItem.id) {
							const newItem = { ...currentItem, ...uptItem }
							newItem.saldo =
								parseFloat(currentItem.imp_pend) - parseFloat(newItem.imp_afec)
							updateImportState.productsUpdate[index] = newItem
						} else if (
							currentItem.id === uptItem.id &&
							uptItem.ind_stock !== 0
						) {
							currentItem.error = true
							currentItem.type_error = uptItem.ind_stock
						}
					})
				})
			}
			// console.log(updateImportState)
			return updateImportState
		case SALES_AFFECTED_QUANTITY:
			return {
				...state,
				productsUpdate: null,
				productsInvol: null,
				paramsProductsInvol: action.payload
			}
		case SALES_AFFECTED_QUANTITY_SUCCESS:
			return { ...state, productsInvol: action.payload }
		case SALES_AFFECTED_IMPORT:
			return { ...state, productsImport: null, productsUpdate: null }
		case SALES_AFFECTED_IMPORT_SUCCESS:
			return { ...state, productsImport: action.payload }
		case SALES_AFFECTED_SUB_CALCULATION:
			return {
				...state,
				subCalculations: null,
				...updateProductItems(state.productsInvol, state.productsUpdate)
			}
		case SALES_AFFECTED_SUB_CALCULATION_SUCCESS:
			updateItem = action.payload
			updateCalsub = {
				...state,
				productsUpdate: [...state.productsInvol.Items],
				subCalculations: action.payload
			}
			if (updateCalsub.productsUpdate) {
				updateCalsub.productsUpdate.forEach((prd) => {
					if (prd.nimovcli === updateItem.nimovcli) {
						prd.neto = updateItem.neto
						prd.precio_unit = updateItem.precio_unit
						// prd.cant_saldo = parseFloat(prd.cant_pend) - parseFloat(updateItem.cant_afec);
					}
				})
			}

			return updateCalsub
		case SALES_AFFECTED_CONFIRM:
			return { ...state, salesconfirm: null, productsUpdate: null }
		case SALES_AFFECTED_CONFIRM_SUCCESS:
			return { ...state, salesconfirm: action.payload }
		case SALES_AFFECTED_IMPORT_CONFIRM:
			return { ...state, salesImportconfirm: null, productsUpdate: null }
		case SALES_AFFECTED_IMPORT_CONFIRM_SUCCESS:
			return { ...state, salesImportconfirm: action.payload }
		case SET_TABLE_DATA_INVOLVEMENT:
			paramsArray = action.payload

			createState = {
				...state,
				productsUpdate: [...state.productsInvol.Items]
			}
			if (createState.productsUpdate) {
				createState.productsUpdate.forEach((prd) => {
					paramsArray.forEach((params) => {
						if (prd.niprod === params.niprod) {
							prd[params.idcampo] = params.value
						}
					})
				})
			}
			return createState
		case SALES_AFFECTED_STATE:
			return { ...state }
		case SALES_AFFECTED_STATE_SUCCESS:
			return { ...state, productsState: action.payload }
		case SALES_AFFECTED_STATE_VALIDATE:
			return { ...state, stateValidate: null }
		case SALES_AFFECTED_STATE_VALIDATE_SUCCESS:
			stateValidateItems = action.payload.Items
			updateStateValidate = {
				...state,
				productsUpdate: [...state.productsState.Items],
				stateValidate: action.payload
			}
			if (updateStateValidate.productsUpdate) {
				updateStateValidate.productsUpdate.forEach((prd) => {
					stateValidateItems.forEach((item) => {
						if (prd.id === item.id) {
							prd.nItem = item.nItem
							prd.state_afec_selected = item.estado_afec
						} else if (prd.id === item.id && item.ind_stock !== 0) {
							prd.error = true
							prd.type_error = item.ind_stock
						}
					})
				})
			}
			return updateStateValidate
		case SALES_AFFECTED_STATE_CONFIRM:
			return { ...state, stateConfirm: null, productsUpdate: null }
		case SALES_AFFECTED_STATE_CONFIRM_SUCCESS:
			return { ...state, stateConfirm: action.payload }
		case VOUCHER_CANCEL_SUCCESS:
			return { ...initialState }
		case VALIDATE_SALES_AFFECTED_DISCOUNT:
			return {
				...state,
				salesDiscount: null,
				discountValidate: action.payload,
				...updateProductItems(state.productsInvol, state.productsUpdate)
			}
		case VALIDATE_SALES_AFFECTED_DISCOUNT_SUCCESS:
			discountItem = action.payload
			updateDiscount = {
				...state,
				salesDiscount: discountItem,
				productsUpdate: _.findWhere(state.productsInvol.Items, {
					id: `${state.discountValidate.nimovcli}/${state.discountValidate.nitem}`
				})
			}
			return updateProductPrice(updateDiscount, discountItem)
		case VALIDATE_SALES_AFFECTED_PRICE:
			return {
				...state,
				priceValidate: action.payload,
				salesPrice: null,
				...updateProductItems(state.productsInvol, state.productsUpdate)
			}
		case VALIDATE_SALES_AFFECTED_PRICE_SUCCESS:
			discountItem = action.payload
			updateDiscount = {
				...state,
				salesPrice: action.payload,
				productsUpdate: _.findWhere(state.productsInvol.Items, {
					id: `${state.priceValidate.nimovcli}/${state.priceValidate.nitem}`
				})
			}
			return updateProductPrice(updateDiscount, discountItem)

		default:
			return state
	}
}

function updateProductPrice(updateState, price) {
	if (!_.isEmpty(updateState.productsUpdate)) {
		updateState.productsUpdate.pc_lista = price.pc_lista
		updateState.productsUpdate.pc_util = price.pc_util
		updateState.productsUpdate.pcio_unit = price.prod_pcio_vta
		updateState.productsUpdate.neto = price.neto
	}

	return updateState
}

function getUpdateItems(products, items) {
	const result = []
	items.forEach((item) => {
		const updateProduct = _.findWhere(products, { id: item.id })
		if (!_.isEmpty(updateProduct)) {
			result.push({
				...updateProduct,
				Cant_afec: item.Cant_afec,
				precio_unit: item.prod_pcio_vta
			})
		}
	})
	return result
}

function updateProductItems(products, productsUpdate) {
	return {
		productsInvol: {
			...products,
			Items: _.isArray(productsUpdate) ? productsUpdate : products.Items
		},
		productsUpdate: null
	}
}

export default rootReducer
