import {
	PURCHASES_AFFECTED_IMPORT,
	PURCHASES_AFFECTED_IMPORT_CONFIRM,
	PURCHASES_AFFECTED_IMPORT_CONFIRM_SUCCESS,
	PURCHASES_AFFECTED_IMPORT_SUCCESS,
	PURCHASES_AFFECTED_IMPORT_VALIDATE,
	PURCHASES_AFFECTED_IMPORT_VALIDATE_SUCCESS,
	PURCHASES_AFFECTED_QUANTITY,
	PURCHASES_AFFECTED_QUANTITY_SUCCESS,
	PURCHASES_AFFECTED_STATE,
	PURCHASES_AFFECTED_STATE_CONFIRM,
	PURCHASES_AFFECTED_STATE_CONFIRM_SUCCESS,
	PURCHASES_AFFECTED_STATE_SUCCESS,
	PURCHASES_AFFECTED_STATE_VALIDATE,
	PURCHASES_AFFECTED_STATE_VALIDATE_SUCCESS,
	PURCHASES_AFFECTED_VALIDATE,
	PURCHASES_AFFECTED_VALIDATE_SUCCESS,
	PURCHASES_VALIDATE_DATE,
	PURCHASES_VALIDATE_DATE_SUCCESS,
	VOUCHER_CANCEL_SUCCESS
} from '../constants/ActionsTypes'

const initialState = {
	purchasedProducts: null,
	paramsPurchasedProducts: null,
	productsUpdate: null,
	cantValidate: null,
	paramsValidate: null,
	productsImport: null,
	purchaimpConfirm: null,
	stateValidate: null,
	stateConfirm: null
}

function rootReducer(state = initialState, action) {
	let validateItems
	let updateState
	let importItems
	let updateImportState
	let itemsState
	let updateStateValidate

	switch (action.type) {
		case PURCHASES_AFFECTED_QUANTITY:
			return { ...initialState, paramsPurchasedProducts: action.payload }
		case PURCHASES_AFFECTED_QUANTITY_SUCCESS:
			return { ...state, purchasedProducts: action.payload }
		case PURCHASES_AFFECTED_VALIDATE:
			return { ...state, cantValidate: null, paramsValidate: action.payload }
		case PURCHASES_AFFECTED_VALIDATE_SUCCESS:
			validateItems = action.payload.Items
			updateState = {
				...state,
				productsUpdate: [...state.purchasedProducts.Items],
				cantValidate: action.payload
			}
			if (updateState.productsUpdate) {
				updateState.productsUpdate.forEach((prd) => {
					validateItems.forEach((item) => {
						if (prd.id === item.id) {
							prd.Cant_afec = item.Cant_afec
							prd.precio_unit = item.prod_pcio_vta
							prd.cant_saldo =
								parseFloat(prd.Cant_pend) - parseFloat(item.Cant_afec)
						} else if (prd.id === item.id && item.ind_stock !== 0) {
							prd.error = true
							prd.type_error = item.ind_stock
						}
					})
				})
			}
			return updateState
		case PURCHASES_AFFECTED_IMPORT:
			return { ...state, productsImport: null, productsUpdate: null }
		case PURCHASES_AFFECTED_IMPORT_SUCCESS:
			return { ...state, productsImport: action.payload }
		case PURCHASES_AFFECTED_IMPORT_VALIDATE:
		case PURCHASES_VALIDATE_DATE:
			return { ...state, cantValidate: null }
		case PURCHASES_AFFECTED_IMPORT_VALIDATE_SUCCESS:
		case PURCHASES_VALIDATE_DATE_SUCCESS:
			importItems = action.payload.Items
			updateImportState = {
				...state,
				productsUpdate: [...state.productsImport.Items],
				cantValidate: action.payload
			}
			if (updateImportState.productsUpdate) {
				updateImportState.productsUpdate.forEach((prd) => {
					importItems.forEach((item) => {
						if (prd.id === item.id && prd.Cant_afec) {
							prd.Cant_afec = item.Cant_afec
							prd.precio_unit = item.prod_pcio_vta
							prd.cant_saldo =
								parseFloat(prd.Cant_pend) - parseFloat(item.Cant_afec)
						} else if (prd.id === item.id && prd.nimovpro) {
							prd.imp_afec = item.imp_afec
							prd.saldo = parseFloat(prd.imp_pend) - parseFloat(item.imp_afec)
						}
					})
				})
			}
			return updateImportState
		case PURCHASES_AFFECTED_IMPORT_CONFIRM:
			return { ...state, purchaimpConfirm: null }
		case PURCHASES_AFFECTED_IMPORT_CONFIRM_SUCCESS:
			return { ...state, purchaimpConfirm: action.payload }
		case PURCHASES_AFFECTED_STATE:
			return { ...state, productsUpdate: null }
		case PURCHASES_AFFECTED_STATE_SUCCESS:
			return { ...state, productsState: action.payload }
		case PURCHASES_AFFECTED_STATE_VALIDATE:
			return { ...state, stateValidate: null, paramsValidate: action.payload }
		case PURCHASES_AFFECTED_STATE_VALIDATE_SUCCESS:
			itemsState = action.payload.Items
			updateStateValidate = {
				...state,
				productsUpdate: [...state.productsState.Items],
				stateValidate: action.payload
			}
			if (updateStateValidate.productsUpdate) {
				updateStateValidate.productsUpdate.forEach((prd) => {
					itemsState.forEach((item) => {
						if (prd.id === item.id) {
							prd.imp_afec = item.imp_afec
							prd.state_afec_selected = item.estado_afec
						}
					})
				})
			}
			return updateStateValidate
		case PURCHASES_AFFECTED_STATE_CONFIRM:
			return { ...state, stateConfirm: null }
		case PURCHASES_AFFECTED_STATE_CONFIRM_SUCCESS:
			return { ...state, stateConfirm: action.payload }
		case VOUCHER_CANCEL_SUCCESS:
			return { ...initialState }
		default:
			return state
	}
}

export default rootReducer
