import {
	CRUD_GET_PROVIDER_OBSERVATION,
	CRUD_GET_PROVIDER_OBSERVATION_SUCCESS,
	CRUD_UPDATE_PROVIDER_OBSERVATION,
	CRUD_UPDATE_PROVIDER_OBSERVATION_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	observationInfo: any
	valueUpdated: any
}

const initialState = {
	observationInfo: null,
	valueUpdated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_PROVIDER_OBSERVATION:
			return { ...state, observationInfo: null }
		case CRUD_GET_PROVIDER_OBSERVATION_SUCCESS:
			return { ...state, observationInfo: action.payload }
		case CRUD_UPDATE_PROVIDER_OBSERVATION:
			return { ...state, valueUpdated: null }
		case CRUD_UPDATE_PROVIDER_OBSERVATION_SUCCESS:
			return { ...state, valueUpdated: action.payload }
		default:
			return state
	}
}

export default rootReducer
