import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import _ from 'underscore'
import CollectionOrderForm from './collectionOrderForm'
import PaymentModal from './paymentModal'

export default class CollectionPaymentMethods extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {}
	}

	renderPayments = () => {
		const { t, handleMoveArrow, paymentTotals, idOperacion, page, page_size } =
			this.props
		const { accounts, moneyOptions } = this.props
		const { botones } = this.props.paymentData
		const result = []

		botones.forEach((bntPayment) => {
			if (bntPayment.visible) {
				result.push(
					<Col key={bntPayment.cod_catfon} className={'mt-2'}>
						<PaymentModal
							t={t}
							modalTitle={`Tipo cuenta ${bntPayment.descripcion}`}
							labelButton={bntPayment.descripcion}
							idOperacion={idOperacion}
							configFields={this._getConfigFields(bntPayment.cod_catfon)}
							accounts={accounts[bntPayment.cod_catfon]}
							moneyOptions={moneyOptions}
							page={page}
							page_size={page_size}
							paymentTotals={paymentTotals}
							handleMoveArrow={handleMoveArrow}
						/>
					</Col>
				)
			}
		})

		return <Row>{result}</Row>
	}

	_getConfigFields = (idField) => {
		const { configCampos } = this.props.paymentData
		const result = _.where(configCampos, { cod_catfon: idField })
		return result
	}

	render() {
		return (
			<Row className={'pt-3'}>
				<Col sm={8}>{this.renderPayments()}</Col>
				<Col sm={4}>
					<CollectionOrderForm
						handleChangeData={this.props.handleChangeFilter}
					/>
				</Col>
			</Row>
		)
	}
}
