import { faLock, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalValidate from 'components/headboard/modalValidate'
import { validateField } from 'lib/FieldValidations'
import { debugMessage } from 'lib/Utils'
import moment from 'moment'
import React, { Fragment, PureComponent } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { IMaskInput } from 'react-imask'
import { connect } from 'react-redux'
import _ from 'underscore'
import { getValueUnmask } from '../../lib/MaskValues'
import styles from './inputText.module.css'
class InputText extends PureComponent {
	constructor(props) {
		super(props)
		// console.log('se crea el input text', props.inputId)
		this.state = {
			showLockModal: false,
			inputValue: props.value,
			configInput: this.getConfigField(props.inputId),
			requireError: props.showError ? props.showError : false,
			trueValue: null
		}
	}

	componentDidUpdate = (prevProps) => {
		const { fields, value } = this.props
		// console.log('se actualiza el input text', this.props.inputId)

		if (!_.isEqual(prevProps.fields, fields) && !_.isEmpty(fields)) {
			this.setState({ configInput: this.getConfigField(this.props.inputId) })
		}
		if (prevProps.value !== value && value !== undefined && value !== null) {
			this.setState({ inputValue: value, trueValue: value }) // Valor inicial
		}
	}

	_handleKeyDown = (e) => {
		const { activeListeners, tabKeyPress, handleEnterKey } = this.props
		const { trueValue, inputValue } = this.state
		switch (e.keyCode) {
			case 13: {
				// ENTER
				if (handleEnterKey) {
					handleEnterKey(e, trueValue || inputValue)
					e.preventDefault()
				}
				break
			}
			default:
				break
		}

		// TAB
		if (tabKeyPress && !e.shiftKey && e.keyCode === 9) {
			if (this.props.handleEnterKey) {
				// this.props.handleEnterKey(e, this.state.trueValue);
			}
		}

		if (activeListeners) {
			debugMessage({
				stacktrace: e.type,
				message: `keyCode: ${e.keyCode} value:${
					e.target.value
				} ASCII:${e.target.value.charCodeAt(0)}`
			})
		}
		return false
	}

	_handleKeyUp = (e) => {
		debugMessage({
			stacktrace: e.type,
			message: `keyCode: ${e.keyCode} value:${e.target.value}`
		})
	}

	_handleKeyPress = (e) => {
		debugMessage({
			stacktrace: e.type,
			message: `keyCode: ${e.keyCode} value:${e.target.value}`
		})
		// debugMessage({ stacktrace: e.type, message: `keyCode: ${e.keyCode} value:${e.target.value}` })
	}

	handleShowModal = () => {
		this.setState({ showLockModal: true })
	}

	handleCancelModal = () => {
		this.setState({ showLockModal: false })
	}

	handleClearValue = () => {
		const { onBlur, onChange } = this.props
		this.setState({ inputValue: '' })
		if (onBlur) {
			onBlur('')
		}
		if (onChange) {
			onChange('')
		}
	}

	handleSubmit = (data) => {
		this.handleCancelModal()
		this.props.handleSubmit(data)
	}

	handleChange = (data) => {
		const { onChange } = this.props
		const { mascara } = this.state.configInput
		const value = data && data.target ? data.target.value : data
		const trueValue = getValueUnmask(value, mascara, this.props)

		this.setState({ inputValue: value, trueValue })
		if (onChange) {
			onChange(trueValue)
		}
	}

	handleOnblur = () => {
		const { onBlur } = this.props
		const { configInput, inputValue } = this.state
		let ban = true

		if (configInput.valid && !validateField(inputValue, configInput.valid)) {
			ban = false
		}

		if (onBlur && ban) {
			onBlur(this.state.inputValue)
		} else if (onBlur) {
			onBlur()
		}

		if (configInput.requerido && !this.state.inputValue) {
			this.setState({ requireError: true })
		} else if (this.state.inputValue) {
			this.setState({ requireError: false })
		}
	}

	handleNumberOnblur = (data) => {
		const { onBlur } = this.props
		const { inputValue, configInput } = this.state
		const value = data.target.value
		const trueValue =
			inputValue === ''
				? inputValue
				: getValueUnmask(value, configInput.mascara, this.props)
		this.setState({ inputValue: value, trueValue })

		if (configInput.requerido && !inputValue) {
			this.setState({ requireError: true })
		}
		if (onBlur) {
			onBlur(trueValue)
		}
	}

	getConfigField = (id) => {
		const { fields } = this.props
		let result = {}
		if (fields) {
			fields.forEach((field) => {
				if (field.idCampo.trim() === id) {
					result = field
				}
			})
		}
		return result
	}

	getMask = (config) => {
		const { authUser } = this.props
		const maskInput = authUser.configApp.mascaras[config.mascara]
			? authUser.configApp.mascaras[config.mascara]
			: null
		return maskInput || false
	}

	/**
	 * to get the onchange event to IMaskInput
	 */
	getOnchangeActionIMaskInput = (options) => {
		const { useOnchange } = this.props
		let result = {}
		if (useOnchange) {
			result = {
				onChange: (data) => options.onChange(data.target.value)
			}
		} else {
			result = {
				onAccept: (data) => options.onChange(data)
			}
		}
		return result
	}

	renderInput = (options, config) => {
		const { allowClear, isLoading, onBlur, popperPlacement } = this.props
		const { trueValue } = this.state
		let response
		if (config.mascara) {
			const mask = this.getMask(config) // Se obtiene las posibles opciones de mascara.. aca se agregan validaciones.
			if (mask.tipo === 'fecha') {
				const formatDate = mask.valor
					? mask.valor.replace(/D/g, 'd').replace(/Y/g, 'y')
					: 'MM-dd-yyyy'
				const customDate = options.value ? moment(options.value) : null // Para dar una fecha por defecto.
				const date = customDate ? customDate.format(mask.valor) : customDate

				const valueDate =
					new Date(date) === 'Invalid Date'
						? new Date(options.value)
						: new Date(options.value)

				const maskInput = formatDate.replace(/[A-Za-z\w]/g, '0')
				response = (
					<DatePicker
						{...options}
						popperPlacement={popperPlacement}
						onChange={(date, evt) => {
							this.props.onChange(date, evt)
							this.setState({ trueValue: date })
						}}
						value={trueValue}
						placeholderText={options.placeholder}
						selected={valueDate.getTime() ? valueDate : trueValue}
						dateFormat={formatDate}
						isClearable={!options.disabled}
						onCalendarClose={() => {
							if (onBlur) {
								onBlur(trueValue)
							}
						}}
						customInput={
							<IMaskInput
								mask={maskInput}
								{...options}
								placeholder={formatDate}
							/>
						}
						showYearDropdown
					/>
				)
			} else if (mask.tipo === 'personalizado') {
				const maskInput = mask.valor ? mask.valor.mask : null
				const newEvent = options.onChange
				response = (
					<IMaskInput
						{...options}
						onAccept={newEvent}
						value={options.value ? options.value.toString() : null}
						mask={maskInput}
						unmask={true}
					/>
				)
			} else if (mask.tipo === 'numero') {
				const onChangeOptions = this.getOnchangeActionIMaskInput(options)
				response = (
					<IMaskInput
						{...options}
						{...onChangeOptions}
						value={options.value !== null ? `${options.value}` : null}
						mask={Number}
						scale={mask.cantDecimales}
						thousandsSeparator={mask.usarSeparadorMil ? '.' : ''}
						onBlur={(v) => this.handleNumberOnblur(v)}
						signed={true}
					/>
				)
			} else {
				response = <input {...options} />
			}
		} else {
			if (allowClear) {
				response = (
					<div className="input-group mb-3">
						<input
							{...options}
							style={{ height: '30px', fontSize: '10pt' }}
							className="form-control"
						/>
						<div className="input-group-prepend" style={{ height: '30px' }}>
							<span className="input-group-text">
								{isLoading ? (
									<Image
										className={styles.loading_icon}
										src={'/assets/images/Loading.gif'}
									/>
								) : (
									<FontAwesomeIcon
										icon={faTimesCircle}
										className={styles.loading_icon}
										onClick={this.handleClearValue}
										style={{ cursor: 'pointer' }}
									/>
								)}
							</span>
						</div>
					</div>
				)
			} else {
				response = <input {...options} />
			}
		}

		return response
	}

	renderField = () => {
		const {
			label,
			placeholder,
			name,
			styles,
			inputId,
			id,
			colInput,
			colLabel,
			styleLabel,
			divStyle,
			disable,
			theme,
			type,
			inputFormCol,
			lock,
			rowStyle,
			autoFocus,
			fwRef,
			activeListeners,
			disabledInput,
			autoComplete,
			maxLength,
			prefix,
			suffix
		} = this.props

		const classInput = label ? colInput : 'col-sm-12'
		const classLabel = label ? colLabel : ''
		const classText = disable ? theme.inputDisabled : ''
		const customType = type || 'text'
		const config = this.state.configInput
		const customStyleLabel = config.requerido
			? { ...styleLabel, color: 'red' }
			: { ...styleLabel }
		const inputStyles = this.state.requireError
			? { ...styles, border: '1px solid red' }
			: styles

		const disabled = config.editable === 0 ? true : disabledInput || undefined

		if (config.visible) {
			const optionsInput = {
				id: id || inputId,
				name: name,
				type: customType,
				style: inputStyles,
				placeholder: config.placeholder || placeholder,
				autoFocus: autoFocus,
				disabled,
				className: `${theme.inputText} ${classText}`,
				value: this.state.inputValue,
				ref: config.fwRef ? config.fwRef : fwRef,
				autoComplete: autoComplete || 'off',
				onChange: (v) => this.handleChange(v),
				onBlur: (v) => this.handleOnblur(v),
				onKeyDown: this._handleKeyDown,
				maxLength: maxLength || Infinity
			}

			if (activeListeners) {
				optionsInput.onKeyPress = this._handleKeyPress
				optionsInput.onKeyUp = this._handleKeyUp
			}

			return (
				<Fragment>
					<Col {...inputFormCol}>
						<Row className={'form-group'} style={rowStyle}>
							<label
								className={`${theme.inputLabel}  ${classLabel}`}
								style={customStyleLabel}
							>
								{config.label ? config.label : label}
							</label>
							{prefix || suffix ? (
								<Col
									className={`${theme.divDataPicker}`}
									style={{ ...divStyle }}
								>
									<Row>
										{prefix && (
											<Col className="d-flex justify-content-center">
												<label
													className={`${theme.inputLabel}`}
													style={customStyleLabel}
												>
													{prefix}
												</label>
											</Col>
										)}
										<Col className={`${classInput}`}>
											{this.renderInput(optionsInput, config)}
										</Col>
										{suffix && (
											<Col className="d-flex justify-content-center">
												<label
													className={`${theme.inputLabel}`}
													style={customStyleLabel}
												>
													{suffix}
												</label>
											</Col>
										)}
									</Row>
								</Col>
							) : (
								<Col
									className={`${classInput} ${theme.divDataPicker}`}
									style={{ ...divStyle }}
								>
									{this.renderInput(optionsInput, config)}
								</Col>
							)}
						</Row>
					</Col>
					{lock && (
						<Col className={theme.lock} sm={1}>
							<FontAwesomeIcon
								icon={faLock}
								onClick={this.handleShowModal}
								style={{ cursor: 'pointer' }}
							/>
							<ModalValidate
								showModal={this.state.showLockModal}
								handleClose={this.handleCancelModal}
								handleSubmit={this.handleSubmit}
							/>
						</Col>
					)}
				</Fragment>
			)
		} else {
			return null
		}
	}

	render() {
		const { fields } = this.props
		if (fields) {
			return this.renderField()
		} else {
			return null
		}
	}
}

const mapStateToProps = ({ auth }) => {
	const { authUser } = auth
	return { authUser }
}

export default connect(mapStateToProps)(
	themr('InputTextStyle', styles)(InputText)
)
