import {
	CRUD_CLEAR_PROFILE_ABMS_STORE,
	CRUD_GET_PROFILE_ABMS,
	CRUD_GET_PROFILE_ABMS_SUCCESS,
	CRUD_UPDATE_PROFILE_ABMS,
	CRUD_UPDATE_PROFILE_ABMS_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	profileAbmsSearched: any
	profileAbmsUpdated: any
}

const initialState = {
	profileAbmsSearched: null,
	profileAbmsUpdated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_PROFILE_ABMS:
			return {
				...state,
				profileAbmsSearched: null,
				paramsSearch: action.payload
			}
		case CRUD_GET_PROFILE_ABMS_SUCCESS:
			return { ...state, profileAbmsSearched: action.payload }
		case CRUD_UPDATE_PROFILE_ABMS:
			return { ...state, profileAbmsUpdated: null }
		case CRUD_UPDATE_PROFILE_ABMS_SUCCESS:
			return { ...state, profileAbmsUpdated: action.payload }
		case CRUD_CLEAR_PROFILE_ABMS_STORE:
			return {
				...state,
				profileAbmsSearched: null,
				profileAbmsUpdated: null
			}
		default:
			return state
	}
}

export default rootReducer
