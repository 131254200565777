import {
	clearProviderHeader,
	getProviderHeader,
	providerHeaderConfirm,
	voucherHeadCheckDate
} from 'actions'
import inputInitFocus from 'components/common/inputInitFocus'
import NotificationError from 'components/common/notificationsErrors'
import InputButton from 'components/form/inputButton'
import { P_CPACAB } from 'constants/ConfigProcessNames'
import { Formik } from 'formik'
import { getBackNextButtons } from 'lib/BreadCrumbsUtils'
import { getValidationSchema } from 'lib/FieldValidations'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ProviderHeaderFormInput from './provideHeaderFormInput'

class ProviderHeaderForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			urlSubmitForm: '',
			timeSet: '',
			fieldsConfig: null
		}
		this.firstRefs = null
	}

	componentDidMount = () => {
		const { idOperacion, config } = this.props
		if (idOperacion) {
			this.props.getProviderHeader({ idOperacion })
		}
		if (config) {
			const fields = config ? this.getRefsField(config.campos) : {}
			this.setState({ fieldsConfig: fields })
		}
	}

	componentDidUpdate = (prevProps) => {
		const { config } = this.props
		if (config !== prevProps.config && config) {
			const fields = config ? this.getRefsField(config.campos) : {}
			this.setState({ fieldsConfig: fields })
		}
	}

	componentWillUnmount = () => {
		this.props.clearProviderHeader()
	}

	getRefsField = (fields) => {
		fields.forEach((field) => {
			if (field.editable) {
				const fieldRefs = React.createRef()
				if (!this.firstRefs) {
					this.firstRefs = fieldRefs // set the first focus
					this.setRefFirstInput(fieldRefs)
				}
				field.fwRef = fieldRefs
			}
		})

		return fields
	}

	handleSubmitForm = (values) => {
		const { idOperacion, callBackReturn } = this.props

		this.props.providerHeaderConfirm({
			headerInfo: {
				...values,
				atrib_comp_cpa: values.atrib_comp_cpa_field,
				idOperacion
			},
			callBackReturn
		})
	}

	render() {
		const { config, crumbs, current, urlParameter, t, providerHeader } =
			this.props
		const { fieldsConfig } = this.state

		const defaultInitial = providerHeader
			? {
					suc_empresa_cpa: providerHeader.suc_empresa_cpa[0].Cod_Suc,
					mon_comp_cpa: providerHeader.moneda[0].cod_moneda,
					cond_comp_cpa: providerHeader.cond_comp_cpa[0].cod_cond_cpa,
					vend_comp_cpa: '',
					fecha_cont_cpa: moment(),
					fecha_real: moment(),
					titulo_comp_cpa: providerHeader.titulo_comp_cpa,
					cotiz_comp_cpa: providerHeader.moneda[0].cotiz,
					nro_letra: providerHeader.nro_letra[0],
					nro_pv: providerHeader.nro_pv,
					nro: providerHeader.nro
			  }
			: {}

		const [backButton, nextButton] = crumbs
			? getBackNextButtons(crumbs, current, urlParameter)
			: []
		const validationSchema = config ? getValidationSchema(config.campos, t) : {}

		if (fieldsConfig && providerHeader) {
			return (
				<Col sm={12}>
					<Formik
						ref={this.props.formRef}
						initialValues={{ ...defaultInitial }}
						onSubmit={(values, actions) => {
							this.handleSubmitForm(values)
						}}
						validationSchema={validationSchema}
						enableReinitialize={false}
						render={({
							values,
							handleBlur,
							handleChange,
							setErrors,
							errors,
							touched,
							isSubmitting,
							handleSubmit,
							setFieldValue,
							setFieldTouched
						}) => (
							<Fragment>
								<Col sm={11}>
									<NotificationError
										errors={errors}
										setErrors={setErrors}
										touched={touched}
									/>
								</Col>
								<Form onSubmit={handleSubmit} className={'voucher-info-form'}>
									<Col>
										<ProviderHeaderFormInput
											fields={fieldsConfig}
											idOperacion={this.props.idOperacion}
											firstRefs={this.firstRefs}
											collapse
											{...{
												values,
												handleBlur,
												handleChange,
												errors,
												touched,
												isSubmitting,
												handleSubmit,
												setFieldValue,
												setFieldTouched
											}}
										/>
									</Col>
									<div className="dropdown-divider col-11 p-1" />
									<Row>
										<Col
											style={{ textAlign: 'left' }}
											className={'mt-2 col-1 '}
										>
											{backButton && (
												<InputButton backButton urlForm={backButton.url} />
											)}
										</Col>
										<Col
											style={{ textAlign: 'right', paddingRight: '2%' }}
											className={'mt-2 col-1 offset-10'}
										>
											{nextButton && (
												<InputButton
													nextButton
													urlForm={nextButton.url}
													type="primary"
													onClick={() => this.setUrlForm(nextButton.url)}
												/>
											)}
										</Col>
									</Row>
								</Form>
							</Fragment>
						)}
					/>
				</Col>
			)
		} else {
			return <div />
		}
	}
}

const mapStateToProps = ({ voucher, providers }) => {
	const config = voucher.config ? voucher.config[P_CPACAB] : null
	const { providerHeader } = providers
	const { headSale } = voucher
	return { config, headSale, providerHeader }
}

export default connect(mapStateToProps, {
	getProviderHeader,
	voucherHeadCheckDate,
	providerHeaderConfirm,
	clearProviderHeader
})(withTranslation()(withRouter(inputInitFocus(ProviderHeaderForm))))
