import Axios from 'axios'
import {
	ICrudGetCCAssignations,
	ICrudUpdateCCAssignations
} from 'models/CrudDirectCCAssignationInterface'

const urlCRUD = '/abm_cont_asigcc'

export const crudGetCCAssignations = async (params: ICrudGetCCAssignations) => {
	const response = await Axios.get(`${urlCRUD}/buscar`, { params })
	return response.data
}

export const crudUpdateCCAssignations = async (
	params: ICrudUpdateCCAssignations
) => {
	const response = await Axios.post(`${urlCRUD}/confirmar`, params)
	return response.data
}
