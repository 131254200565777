import { crudGetTermsOfSalesDues, crudUpdateTermsOfSalesDues } from 'actions'
import React, { Component, Fragment } from 'react'
import { Alert } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import TermsOfSalesDuesTable from './TermsOfSalesDuesTable'

interface TermsOfSalesDuesProps extends WithTranslation {
	crudGetTermsOfSalesDues: any
	crudUpdateTermsOfSalesDues: any
	duesSearched: any
	duesUpdated: any
	cod_cvta: number
	fields: any
	disableForm: boolean
	handleDuesDisable: any
	disabledDuesFields: boolean
	handleWarning: any
	showWarning: boolean
	setFormChanged: any
	t: any
}

class TermsOfSalesDues extends Component<TermsOfSalesDuesProps> {
	componentDidMount = () => {
		const { crudGetTermsOfSalesDues, cod_cvta } = this.props

		crudGetTermsOfSalesDues({ cod_cvta })
	}

	/**
	 * To submit table data
	 * @param {object} fieldsData
	 */

	handleTableSubmit = (fieldsData: any) => {
		const { cod_cvta, crudUpdateTermsOfSalesDues } = this.props
		const submitValues = {
			cod_cvta: cod_cvta,
			cuotas: fieldsData
		}
		crudUpdateTermsOfSalesDues(submitValues)
	}

	render() {
		const {
			fields,
			disableForm,
			duesSearched,
			t,
			disabledDuesFields,
			handleDuesDisable,
			showWarning,
			handleWarning,
			setFormChanged
		} = this.props

		const tableProps = {
			fields: fields,
			disableForm: disableForm,
			duesSearched: duesSearched,
			disabledDuesFields: disabledDuesFields,
			handleDuesDisable: handleDuesDisable,
			handleTableSubmit: this.handleTableSubmit,
			handleWarning: handleWarning,
			showWarning: showWarning,
			setFormChanged: setFormChanged,
			t: t
		}

		return (
			<Fragment>
				<Alert variant={'dark'}>
					<b>{t('CRUDS.general_use.dues')}</b>
				</Alert>
				<TermsOfSalesDuesTable {...tableProps} />
			</Fragment>
		)
	}
}

const mapStateToProps = ({ termsOfSalesDues }: any) => {
	const { duesSearched, duesUpdated } = termsOfSalesDues
	return { duesSearched, duesUpdated }
}

const connectForm = connect(mapStateToProps, {
	crudGetTermsOfSalesDues,
	crudUpdateTermsOfSalesDues
})(TermsOfSalesDues)

export default withTranslation()(connectForm)
