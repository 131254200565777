import {
	crudCreateValueSuccess,
	crudDeleteValueSuccess,
	crudGetValuesListSuccess,
	crudGetValueSuccess
} from 'actions'
import {
	crudCreateValue,
	crudDeleteValue,
	crudGetValue,
	crudGetValuesList
} from 'api/CrudValuesApi'
import {
	CRUD_CREATE_VALUE,
	CRUD_DEL_VALUE,
	CRUD_GET_VALUE,
	CRUD_GET_VALUES_LIST
} from 'constants/ActionsTypes'
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {} from '../api/Cruds'

function* crudGetValuesListRequest({ payload }: any): any {
	try {
		const values = yield call(crudGetValuesList, payload)
		yield put(crudGetValuesListSuccess(values))
	} catch (error) {}
}

function* crudDeleteValueRequest({ payload }: any): any {
	try {
		const values = yield call(crudDeleteValue, payload)
		yield put(crudDeleteValueSuccess(values))
	} catch (error) {}
}

function* crudGetValueRequest({ payload }: any): any {
	try {
		const values = yield call(crudGetValue, payload)
		yield put(crudGetValueSuccess(values))
	} catch (error) {}
}

function* crudCreateValueRequest({ payload }: any): any {
	try {
		const values = yield call(crudCreateValue, payload)
		yield put(crudCreateValueSuccess(values))
	} catch (error) {}
}

export function* crudGetValuesListSaga() {
	yield takeLatest(CRUD_GET_VALUES_LIST, crudGetValuesListRequest)
}

export function* crudDeleteValueSaga() {
	yield takeLatest(CRUD_DEL_VALUE, crudDeleteValueRequest)
}

export function* crudGetValueSaga() {
	yield takeEvery(CRUD_GET_VALUE, crudGetValueRequest)
}

export function* crudCreateValueSaga() {
	yield takeLatest(CRUD_CREATE_VALUE, crudCreateValueRequest)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetValuesListSaga),
		fork(crudDeleteValueSaga),
		fork(crudGetValueSaga),
		fork(crudCreateValueSaga)
	])
}
