import IMask from 'imask'
import moment from 'moment'
import { selectFilter } from 'react-bootstrap-table2-filter'
import _ from 'underscore'

/**
 * Return Header Filter of table
 * @param {*} field
 * @param {*} products
 * @param {*} filtersKey
 * @param {*} props
 * @param {*} selectClass
 */

export const getSelectFilter = (
	field,
	products,
	filtersKey,
	props,
	selectClass
) => {
	const fieldId = field.idCampo.trim()
	const { mascara } = field
	const fieldFilter = filtersKey[fieldId]
	const filterValue = []

	if (!products) {
		return null
	}

	if (mascara && products[fieldFilter] && products[fieldFilter].length > 0) {
		products[fieldFilter].forEach((item) => {
			filterValue.push(getValueMask(item, mascara, props))
		})
	}

	const optionsFilter = fieldFilter
		? _.object(
				products[fieldFilter],
				mascara ? filterValue : products[fieldFilter]
		  )
		: []

	const placeholder = field.label

	if (optionsFilter.length === 0) {
		return null
	}

	return selectFilter({
		withoutEmptyOption: false,
		options: optionsFilter,
		className: `${selectClass} mt-2`,
		placeholder
	})
}

export const getValueMask = (value, mascara, props) => {
	const { authUser } = props
	const maskConfig = authUser.configApp.mascaras[mascara]
	let result = value

	if (maskConfig) {
		if (maskConfig.tipo === 'fecha') {
			const date = moment(value)
			result = date.isValid() ? date.format(maskConfig.valor) : value
		} else if (maskConfig.tipo === 'personalizado') {
			const masked = IMask.createMask({ mask: maskConfig.valor.mask })
			result = masked.resolve(value)
		} else {
			if (maskConfig.usarSeparadorDecimal !== maskConfig.usarSeparadorMil) {
				const customValue =
					maskConfig.cantDecimales === 0 ? parseInt(value) : value // Se quitan los decimales del numero
				const masked = IMask.createMask({
					mask: Number,
					signed: true,
					scale: maskConfig.cantDecimales,
					radix: maskConfig.usarSeparadorDecimal
						? maskConfig.usarSeparadorDecimal
						: ',',
					mapToRadix: ['.'],
					thousandsSeparator: maskConfig.usarSeparadorMil
						? maskConfig.usarSeparadorMil
						: '.',
					normalizeZeros: true,
					padFractionalZeros: true
				})
				result =
					customValue !== null &&
					customValue !== undefined &&
					customValue !== ''
						? masked.resolve(`${customValue}`)
						: null
			} else {
				result = value
				console.error(
					'Error al inicializar la máscara: el separador de miles no puede ser igual al de decimales.'
				)
			}
		}
	}

	return result
}

export const getValueUnmask = (value, mascara, props) => {
	const { authUser } = props

	if (authUser && mascara) {
		const maskConfig = authUser.configApp.mascaras[mascara]
		if (
			maskConfig &&
			maskConfig.tipo === 'numero' &&
			value &&
			!_.isNaN(parseFloat(value))
		) {
			const radix = maskConfig.usarSeparadorDecimal
				? maskConfig.usarSeparadorDecimal
				: ','
			const thousandsSeparator = maskConfig.usarSeparadorMil
				? maskConfig.usarSeparadorMil
				: '.'
			const customValue = value.toString().split(thousandsSeparator).join('')
			const masked = IMask.createMask({
				mask: Number,
				signed: true,
				scale: maskConfig.cantDecimales,
				radix,
				thousandsSeparator
			})

			// Thousands and millions separators are removed
			masked.value = customValue

			// to return the value without the mask
			return masked.unmaskedValue
		}
	}

	return value
	// Replace "." for "," and "." for ""  await for imask config.
	// return value.split('.').join('').split(',').join('.');
}

/**
 * To get formated data from object
 * @param {*} dataValues
 * @param {*} fields
 * @param {*} authUser
 * @returns object with the masked value
 */
export const getValueMaskObject = (dataValues, fields, authUser) => {
	const result = {}

	_.forEach(fields, (field) => {
		const idField = field.idCampo.trim()
		if (field.mascara) {
			result[idField] = getValueMask(`${dataValues[idField]}`, field.mascara, {
				authUser
			})
		}
	})

	return result
}
