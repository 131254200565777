import Axios from 'axios'

export const getProducts = async (params) => {
	const response = await Axios.get('/Productos/consulta', { params: params })
	return response.data
}

export const searchProducts = async (params) => {
	const response = await Axios.get('/Productos/Seleccion', { params })
	return response.data
}

export const getPriceByProduct = async (params) => {
	const response = await Axios.get('/Productos/Precio', { params })
	return response.data
}

export const getProductCost = async (params) => {
	const response = await Axios.get('/Productos/Costo', { params })
	return response.data
}

export const checkItemByProduct = async (params) => {
	const response = await Axios.post('/Productos/checkItem', { params: params })
	return response.data
}

export const getProductsCart = async (params) => {
	const response = await Axios.get('/Productos/carro', { params })
	return response.data
}

export const removeProductCart = async (params) => {
	const response = await Axios.post('/Productos/carro/eliminar', params)
	return response.data
}

export const getProductImage = async (params) => {
	const response = await Axios.get(`/Productos/Imagen?id=${params.id}`, {
		responseType: 'blob'
	})
	const image = window.URL.createObjectURL(new Blob([response.data]))
	return image
}

export const finishProductCart = async (params) => {
	const response = await Axios.get('/ProcesoDeComprobante', { params })
	return response.data
}

export const searchAutoProduct = async (params) => {
	const response = await Axios.get('/Productos/Seleccion/autocompletar', {
		params
	})
	return response.data
}

export const advancedProductSearchApi = async (params) => {
	const response = await Axios.post('/cons_productos/buscar', params)
	return response.data
}

export const validateProductDiscountApi = async (params) => {
	const response = await Axios.get('/Productos/ValPcLista', { params })
	return response.data
}

export const validateProductPriceApi = async (params) => {
	const response = await Axios.get('/Productos/ValPrecio', { params })
	return response.data
}

export const getProductPriceListApi = async (params) => {
	const response = await Axios.get('/Productos/SelPrecio', { params })
	return response.data
}

export const validateProductCostApi = async (params) => {
	const response = await Axios.get('/Productos/ValCosto', { params })
	return response.data
}

export const validateProductCostPCApi = async (params) => {
	const response = await Axios.get('/Productos/ValCostoPC', { params })
	return response.data
}

export const validateProductNetApi = async (params) => {
	const response = await Axios.get('/Productos/ValNeto', { params })
	return response.data
}

export const validateProductCostNetApi = async (params) => {
	const response = await Axios.get('/Productos/ValNetoCosto', { params })
	return response.data
}
