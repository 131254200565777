import { getFilterUsers } from 'actions'
import InputDropdown from 'components/form/inputDropdown'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getOptionsDropDown, handleSetValueInput } from 'lib/FormUtils'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'
import InputText from '../form/inputText'
import styles from './SearchVoucher.module.scss'

type SearchFormProps = {
	t: any
	values: any
	handleChange: any
	handleBlur: any
	setFieldValue: any
	readOnly: boolean
	fields: Array<IFieldStructure>
	setFieldTouched: any
	getFilterUsers: () => void
	filterUsers: any
}

type AuditStateProps = {
	movingDateFrom: string
	movingDateUntil: string
}

class AccountantSearchFormInput extends Component<
	SearchFormProps,
	AuditStateProps
> {
	constructor(props: SearchFormProps) {
		super(props)
		this.state = {
			movingDateFrom: '',
			movingDateUntil: ''
		}
	}

	componentDidMount = () => {
		const { filterUsers, values } = this.props
		if (_.isEmpty(filterUsers)) {
			this.props.getFilterUsers()
		}

		const movingDateFrom =
			values && values.fmovim_desde ? values.fmovim_desde : ''
		const movingDateUntil =
			values && values.fmovim_hasta ? values.fmovim_hasta : ''

		this.setState({
			movingDateFrom,
			movingDateUntil
		}) // set input dates from reducer
	}

	componentDidUpdate = (prevProps: any) => {
		const { values } = this.props
		if (
			!_.isEqual(prevProps.values, values) &&
			values.fmovim_desde === '' &&
			values.fmovim_hasta === ''
		) {
			this.setState({ movingDateFrom: '', movingDateUntil: '' })
		}
	}

	render() {
		const {
			values,
			handleBlur,
			readOnly,
			fields,
			t,
			setFieldTouched,
			setFieldValue,
			filterUsers
		} = this.props
		const { movingDateUntil, movingDateFrom } = this.state
		const formikProps = { setFieldTouched, setFieldValue }

		const propsUser = {
			inputFormCol: { sm: 12 },
			fields,
			label: t('search_voucher.module'),
			inputId: 'usuario',
			name: 'usuario',
			placeholder: '',
			colLabel: 'col-sm-2',
			colInput: 'col-sm-10',
			disable: readOnly,
			onBlur: handleBlur,
			value: values.usuario,
			divStyle: { paddingLeft: '4.3%', paddingRight: '0px' },
			options: getOptionsDropDown(filterUsers),
			noInitialExecute: true,
			onChange: (data: any) => handleSetValueInput(data, 'usuario', formikProps)
		}

		const propsMovFrom = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Importe',
			inputId: 'fmovim_desde',
			name: 'fmovim_desde',
			placeholder: t('global.from'),
			colLabel: 'col-sm-5',
			colInput: 'col-sm-6',
			disable: readOnly,
			onBlur: handleBlur,
			value: movingDateFrom,
			divStyle: { paddingLeft: '0px' },
			autoComplete: 'off',
			onChange: (data: any) => {
				const date = data || ''
				this.setState({ movingDateFrom: date })
				handleSetValueInput(date, 'fmovim_desde', formikProps)
			}
		}

		const propsMovUntil = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Fecha',
			inputId: 'fmovim_hasta',
			name: 'fmovim_hasta',
			placeholder: t('global.until'),
			colLabel: 'col-sm-5',
			colInput: 'col-sm-7',
			disable: readOnly,
			onBlur: handleBlur,
			value: movingDateUntil,
			autoComplete: 'off',
			divStyle: { paddingRight: '0px' },
			onChange: (data: any) => {
				const date = data || ''
				this.setState({ movingDateUntil: date })
				handleSetValueInput(date, 'fmovim_hasta', formikProps)
			}
		}

		return (
			<Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputText {...propsMovFrom} />
					<InputText {...propsMovUntil} />
				</Row>
				<Row className={`col-12 ${styles.module_search_row}`}>
					<InputDropdown {...propsUser} />
				</Row>
			</Row>
		)
	}
}

const mapStateToProps = ({ searchVoucher }: any) => {
	const { filterUsers } = searchVoucher
	return { filterUsers }
}

export default connect(mapStateToProps, { getFilterUsers })(
	AccountantSearchFormInput
)
