import {
	addConfigPendingVouchers,
	getPendingVouchers,
	removeConfigPendingVouchers
} from 'actions'
import CommonAutocomplete from 'components/common/commonAutocomplete'
import CommonChip from 'components/common/CommonChip'
import { IAddPendingVoucherParams, IVoucherItem } from 'models/Landing'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import PendingVouchersTable from './PendingVouchersTable'

interface ILandingPendingVouchersProps extends WithTranslation {
	companyId: string
	userVoucherType: any
	t?: any
	getPendingVouchers: any
	pendingVouchers?: any
	addConfigPendingVouchers: (payload: IAddPendingVoucherParams) => void
	removeConfigPendingVouchers: (payload: IAddPendingVoucherParams) => void
	authUser?: any
	filterAdd: any
	filterRemove: any
}

interface ILandingPendingVouchersState {
	itemSelected: IVoucherItem[]
	chips: IVoucherItem[]
}

class LandingPendingVouchers extends Component<
	ILandingPendingVouchersProps,
	ILandingPendingVouchersState
> {
	constructor(props: ILandingPendingVouchersProps) {
		super(props)
		this.state = {
			itemSelected: [],
			chips: []
		}
	}

	componentDidUpdate = (prevProps: ILandingPendingVouchersProps) => {
		const { filterRemove, filterAdd, authUser } = this.props

		if (
			!_.isEqual(prevProps.filterRemove, filterRemove) &&
			!_.isEmpty(filterRemove)
		) {
			this.props.getPendingVouchers({ idEmpresa: authUser.userCompany })
		}

		if (!_.isEqual(prevProps.filterAdd, filterAdd) && !_.isEmpty(filterAdd)) {
			this.props.getPendingVouchers({ idEmpresa: authUser.userCompany })
		}
	}

	/**
	 * to get options to select, return array with id and label
	 * @params options from voucherstate
	 */
	getOptionsSelect = (options: any) => {
		const result = options.map((voucher: any) => {
			return {
				id: voucher.nioperacion,
				label: voucher.descrip_comprob
			}
		})

		return result
	}

	/**
	 * to render all chips selected
	 * @returns
	 */
	renderChips = () => {
		const { pendingVouchers } = this.props

		if (
			!_.isEmpty(pendingVouchers) &&
			!_.isEmpty(pendingVouchers.configuracion)
		) {
			return pendingVouchers.configuracion.map((chip: IVoucherItem) => {
				return (
					<CommonChip
						key={chip.nioperacion}
						label={chip.descrip_comprob}
						handleDelete={(event) => this.handleDeleteChip(chip.nioperacion)}
					/>
				)
			})
		}
		return null
	}

	/**
	 * to remove one chip for id
	 * @param chipId
	 */

	handleDeleteChip = (chipId: number) => {
		const { authUser } = this.props

		this.props.removeConfigPendingVouchers({
			idEmpresa: authUser.userCompany,
			nioperacion: chipId
		})
	}

	/**
	 * to add new chips in the array
	 */
	handleChips = (newChip: any) => {
		const { authUser } = this.props
		if (!_.isEmpty(newChip)) {
			this.props.addConfigPendingVouchers({
				idEmpresa: authUser.userCompany,
				nioperacion: newChip.id
			})
		}
	}

	render() {
		const { userVoucherType, t } = this.props
		const { itemSelected } = this.state

		return (
			<Fragment>
				<Row>
					<Col className="p-2 font-weight-bold"> {t('landing.title')} </Col>
				</Row>
				<Row>
					<Col sm={6}>
						<CommonAutocomplete
							options={
								!_.isEmpty(userVoucherType)
									? this.getOptionsSelect(userVoucherType.comprobantes)
									: []
							}
							handleOnchange={(itemSelected: IVoucherItem[]) => {
								this.setState({ itemSelected })
							}}
							placeHolder={t('selectType.form.insert_type_voucher')}
							id="voucherType"
							selected={itemSelected}
						/>
					</Col>
					<Col className={'text-left'}>
						<Button
							style={{ minWidth: '105px' }}
							type={'button'}
							onClick={() => {
								this.handleChips(itemSelected[0])
								this.setState({ itemSelected: [] })
							}}
						>
							{t('landing.add')}
						</Button>
					</Col>
				</Row>
				<Row className={'m-1'}>{this.renderChips()}</Row>
				<Row>
					<Col sm={6} className={'mt-2'}>
						<PendingVouchersTable />
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ vouchertype, landingItems, auth }: any) => {
	const { userVoucherType } = vouchertype
	const { pendingVouchers, filterAdd, filterRemove } = landingItems
	const { authUser } = auth
	return { userVoucherType, pendingVouchers, authUser, filterAdd, filterRemove }
}

export default connect(mapStateToProps, {
	getPendingVouchers,
	addConfigPendingVouchers,
	removeConfigPendingVouchers
})(withTranslation()(LandingPendingVouchers))
