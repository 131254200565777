import {
	GET_CONFIG_SHOPPING_CART,
	GET_CONFIG_SHOPPING_CART_SUCCESS
} from 'constants/ActionsTypes'
import { IShoppingCartAction, IShoppingCartParams } from 'models/ShoppingCart'

export const getConfigShoppingCart = (
	payload: IShoppingCartParams
): IShoppingCartAction => {
	return {
		type: GET_CONFIG_SHOPPING_CART,
		payload
	}
}

export const getConfigShoppingCartSuccess = (payload: any) => {
	return {
		type: GET_CONFIG_SHOPPING_CART_SUCCESS,
		payload
	}
}
