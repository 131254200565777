import {
	cancelAttributesSuccess,
	confirmAttributesSuccess,
	getAttributesSuccess
} from 'actions'
import {
	cancelAttributesApi,
	confirmAttributesApi,
	getAttributesApi
} from 'api/Attributes'
import {
	CANCEL_ATTRIBUTES,
	CONFIRM_ATTRIBUTES,
	GET_ATTRIBUTES
} from 'constants/ActionsTypes'
import {
	ICancelAttributesAction,
	IConfirmAttributeAction,
	IGetAttributesAction
} from 'models/Attributes'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* getAttributesRequest({ payload }: IGetAttributesAction): any {
	try {
		const values: any = yield call(getAttributesApi, payload)
		yield put(getAttributesSuccess(values))
	} catch (error: any) {}
}

function* confirmAttributesRequest({ payload }: IConfirmAttributeAction): any {
	try {
		const confirm: any = yield call(confirmAttributesApi, payload)
		yield put(confirmAttributesSuccess(confirm))
	} catch (error: any) {}
}

function* cancelAttributesRequest({ payload }: ICancelAttributesAction): any {
	try {
		const values: any = yield call(cancelAttributesApi, payload)
		yield put(cancelAttributesSuccess(values))
	} catch (error: any) {}
}

export function* getAttributesSaga() {
	yield takeEvery(GET_ATTRIBUTES, getAttributesRequest)
}

export function* confirmAttributesSaga() {
	yield takeEvery(CONFIRM_ATTRIBUTES, confirmAttributesRequest)
}

export function* cancelAttributesSaga() {
	yield takeEvery(CANCEL_ATTRIBUTES, cancelAttributesRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getAttributesSaga),
		fork(confirmAttributesSaga),
		fork(cancelAttributesSaga)
	])
}
