import {
	CLEAR_SUGESTED_PRODUCT_STORE,
	GET_SUGESTED_PRODUCTS,
	GET_SUGESTED_PRODUCTS_SUCCESS,
	SET_SUGESTED_PRODUCT_PRIORITY,
	SET_SUGESTED_PRODUCT_PRIORITY_SUCCESS,
	SET_SUGESTED_PRODUCT_SELECTION,
	SET_SUGESTED_PRODUCT_SELECTION_SUCCESS
} from 'constants/ActionsTypes'
import { IResponseResult } from 'models/ResultsInterface'
import {
	IGetSugestedProducts,
	IGetSugestedProductsResponseArray,
	ISetSugestedProductPriority,
	ISetSugestedProductSelection
} from 'models/SugestedProducts'

export const getSugestedProducts = (payload: IGetSugestedProducts) => {
	return {
		type: GET_SUGESTED_PRODUCTS,
		payload
	}
}

export const getSugestedProductsSuccess = (
	payload: IGetSugestedProductsResponseArray
) => {
	return {
		type: GET_SUGESTED_PRODUCTS_SUCCESS,
		payload
	}
}

export const setSugestedProductSelection = (
	payload: ISetSugestedProductSelection
) => {
	return {
		type: SET_SUGESTED_PRODUCT_SELECTION,
		payload
	}
}

export const setSugestedProductSelectionSuccess = (
	payload: IResponseResult
) => {
	return {
		type: SET_SUGESTED_PRODUCT_SELECTION_SUCCESS,
		payload
	}
}

export const setSugestedProductPriority = (
	payload: ISetSugestedProductPriority
) => {
	return {
		type: SET_SUGESTED_PRODUCT_PRIORITY,
		payload
	}
}

export const setSugestedProductPrioritySuccess = (payload: IResponseResult) => {
	return {
		type: SET_SUGESTED_PRODUCT_PRIORITY_SUCCESS,
		payload
	}
}

export const clearSugestedProductsStore = () => {
	return { type: CLEAR_SUGESTED_PRODUCT_STORE }
}
