import {
	faCog,
	faNewspaper,
	faSearch,
	faSignOutAlt,
	faUserCog
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import HomeIcon from '@material-ui/icons/Home'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import { userSignOut } from 'actions'
import clsx from 'clsx'
import ChangePassword from 'components/Auth/ChangePassword'
import CommonModal from 'components/common/commonModal'
import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { IoCubeSharp } from 'react-icons/io5'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
	ADMIN,
	LANDING,
	SEARCH_PRODUCTS,
	SEARCH_VOUCHER,
	SELECTTYPE
} from 'utils/RoutePath'

class MainDrawer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showUserModal: false
		}
	}

	/**
	 * get options to render.
	 * @returns
	 */
	getOptions = () => {
		const { userCompany, t } = this.props
		return [
			{
				name: 'Principal',
				icon: <HomeIcon />,
				onClick: () => this.goTo(`${LANDING}/${userCompany}`)
			},
			{
				name: t('menu-bar.web_reports'),
				icon: <FontAwesomeIcon icon={faNewspaper} />,
				onClick: () => this.handleShowReports()
			},
			{
				name: t('menu-bar.search_products'),
				icon: <IoCubeSharp size={19} />,
				onClick: () => this.goTo(`${SEARCH_PRODUCTS}`)
			},
			{
				name: t('menu-bar.search_vouchers'),
				icon: (
					<FontAwesomeIcon
						title={t('menu-bar.search_vouchers')}
						icon={faSearch}
					/>
				),
				onClick: () => this.goTo(`${SEARCH_VOUCHER}`)
			},
			{
				name: t('menu-bar.vouchers'),
				icon: <AddIcon />,
				onClick: () => this.handleRedirectVoucherPage()
			},
			{
				name: t('menu-bar.cruds'),
				icon: <FontAwesomeIcon icon={faUserCog} />,
				onClick: () => this.goTo(`${ADMIN}/A`)
			},
			{
				name: t('menu-bar.config'),
				icon: <FontAwesomeIcon icon={faCog} />,
				onClick: () => this.goTo(`${ADMIN}/C`)
			},
			{
				name: 'Cambiar Contraseña',
				icon: <LockOpenIcon />,
				onClick: () => this.handleChangePassword()
			},
			{
				name: t('menu-bar.logout'),
				icon: (
					<FontAwesomeIcon title={t('menu-bar.logout')} icon={faSignOutAlt} />
				),
				onClick: () => this.props.userSignOut()
			}
		]
	}

	/**
	 * to open and close new voucher modal
	 */
	handleRedirectVoucherPage = () => {
		const { authUser, match } = this.props
		const { showModalComp } = this.state
		const { params } = match
		const redirectPage = 'idOperacion' in params ? false : !showModalComp

		if (redirectPage) {
			this.goTo(`${SELECTTYPE}/${authUser.userCompany}`)
		}
	}

	/**
	 * open new window..
	 */
	handleShowReports = () => {
		const { configApp } = this.props.auth.authUser

		if (configApp.url_informes === null) {
			this.setState({ showMessageError: true, typeMessage: 'error' })
		} else {
			window.open(configApp.url_informes)
		}
	}

	/**
	 * to show open modal change password
	 */
	handleChangePassword = () => {
		this.props.drawerState(false)
		this.handleTogglePassword()
	}

	/**
	 * to change state from password modal
	 */
	handleTogglePassword = () => {
		// console.log('hereeeeee')
		this.setState((state) => ({ showUserModal: !state.showUserModal }))
	}

	/**
	 * to render all options.
	 * @param {*} anchor
	 * @returns
	 */
	renderList = (anchor) => {
		const classes = makeStyles({
			list: {
				width: 250
			},
			fullList: {
				width: 'auto'
			}
		})

		const options = this.getOptions()
		return (
			<div
				className={clsx(classes.list, {
					[classes.fullList]: anchor === 'top' || anchor === 'bottom'
				})}
				role="presentation"
			>
				<List>
					{options.map((opt) => (
						<ListItem button key={opt.name} onClick={opt.onClick}>
							<ListItemIcon>{opt.icon}</ListItemIcon>
							<ListItemText primary={opt.name} />
						</ListItem>
					))}
				</List>
			</div>
		)
	}

	/**
	 * to redirect a page
	 * @param {*} path
	 */
	goTo = (path) => {
		this.props.history.push(path)
		this.props.drawerState(false)
	}

	render() {
		const { showUserModal } = this.state
		return (
			<Fragment>
				<CommonModal
					showModal={showUserModal}
					handleCloseModal={this.handleTogglePassword}
					modalTitle={'Cambio de Clave'}
					sizeModal={'lg'}
					modalBody={
						<ChangePassword handleCloseModal={this.handleTogglePassword} />
					}
				/>
				<Drawer
					anchor={this.props.orientation}
					open={this.props.state}
					onClose={() => this.props.drawerState(false)}
				>
					{this.renderList(this.props.orientation)}
				</Drawer>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth }) => {
	return { auth }
}

const mapDispatchToProps = { userSignOut }

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(withRouter(MainDrawer)))
