import CrudVoucherConfigTabsDataContents from 'components/crudVoucherConfig/CrudVoucherConfigTabsDataContents'
import CrudSearchVoucherForm from 'components/crudVoucherConfig/SearchForm/CrudSearchVoucherForm'
import { CRUD_VOUCHER_CONFIG_DATA } from 'utils/RoutePath'

export const voucherConfig = {
	id: 'voucherConfigCrud',
	key: 'nioperacion',
	descrip: 'nioperacion',
	searchMethod: 'post',
	category: 'C',
	translation: 'voucher_config_data',
	dataUrl: CRUD_VOUCHER_CONFIG_DATA,
	urlApi: 'abm_comprob',
	component: CrudVoucherConfigTabsDataContents,
	searchComponent: CrudSearchVoucherForm
}
