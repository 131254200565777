import React, { PureComponent } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'

export default class CommonAutocomplete extends PureComponent {
	render() {
		const { handleOnchange, options, selected, placeHolder, id } = this.props
		return (
			<Typeahead
				id={id}
				onChange={handleOnchange}
				options={options}
				placeholder={placeHolder}
				selected={selected}
			/>
		)
	}
}
