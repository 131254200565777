import { createStyles } from '@material-ui/core'

export const styles = (theme: any) =>
	createStyles({
		containerCard: {
			paddingLeft: '4rem !important',
			borderRadius: '40px !important'
		},
		fileIconItem: {
			padding: 10
		},
		fileTempIconItem: {
			textAlign: 'center',
			padding: 10
		},
		fileTmpName: {
			paddingRight: 5,
			paddingLeft: 5
		},
		iconTemp: {
			cursor: 'pointer',
			color: '#007bff'
		}
	})
