import {
	ICrudGetProductBonif,
	ICrudUpdateProductBonif
} from 'models/CrudProductsInterface'
import {
	CRUD_GET_PRODUCT_BONIFICATION,
	CRUD_GET_PRODUCT_BONIFICATION_SUCCESS,
	CRUD_UPDATE_PRODUCT_BONIFICATION,
	CRUD_UPDATE_PRODUCT_BONIFICATION_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetProductBonification = (payload: ICrudGetProductBonif) => ({
	type: CRUD_GET_PRODUCT_BONIFICATION,
	payload
})

export const crudGetProductBonificationSuccess = (payload: any) => ({
	type: CRUD_GET_PRODUCT_BONIFICATION_SUCCESS,
	payload
})

export const crudUpdateProductBonification = (
	payload: ICrudUpdateProductBonif
) => ({
	type: CRUD_UPDATE_PRODUCT_BONIFICATION,
	payload
})

export const crudUpdateProductBonificationSuccess = (payload: any) => ({
	type: CRUD_UPDATE_PRODUCT_BONIFICATION_SUCCESS,
	payload
})
