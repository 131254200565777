import {
	CANCEL_PRODUCT_STOCK,
	CANCEL_PRODUCT_STOCK_ERROR,
	CANCEL_PRODUCT_STOCK_SUCCESS,
	CLEAR_STORE_STOCK,
	CONFIRM_PRODUCT_STOCK,
	CONFIRM_PRODUCT_STOCK_ERROR,
	CONFIRM_PRODUCT_STOCK_SUCCESS,
	GET_PRODUCT_STOCK,
	GET_PRODUCT_STOCK_SUCCESS,
	SEARCH_BARCODE,
	SEARCH_BARCODE_ERROR,
	SEARCH_BARCODE_SUCCESS,
	VALIDATE_PRODUCT_STOCK,
	VALIDATE_PRODUCT_STOCK_ERROR,
	VALIDATE_PRODUCT_STOCK_SUCCESS
} from '../constants/ActionsTypes'

const initialState = {
	productStock: null,
	validateStock: null,
	paramsValidate: null,
	confirmedStock: null,
	canceledStock: null,
	searchItems: null,
	confirmedStockError: null
}

function rootReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PRODUCT_STOCK:
			return { ...state, productStock: null, validateStock: null }
		case GET_PRODUCT_STOCK_SUCCESS:
			return { ...state, productStock: action.payload }
		case VALIDATE_PRODUCT_STOCK:
			return { ...state, validateStock: null, paramsValidate: action.payload }
		case VALIDATE_PRODUCT_STOCK_SUCCESS:
			return { ...state, validateStock: action.payload, paramsValidate: null }
		case VALIDATE_PRODUCT_STOCK_ERROR:
			return { ...state, validateStock: action.payload }
		case CONFIRM_PRODUCT_STOCK:
			return { ...state, confirmedStock: null }
		case CONFIRM_PRODUCT_STOCK_SUCCESS:
			return { ...state, confirmedStock: action.payload }
		case CONFIRM_PRODUCT_STOCK_ERROR:
			return { ...state, confirmedStockError: action.payload }
		case CANCEL_PRODUCT_STOCK:
			return { ...state, canceledStock: null }
		case CANCEL_PRODUCT_STOCK_SUCCESS:
		case CANCEL_PRODUCT_STOCK_ERROR:
			return { ...state, canceledStock: action.payload }
		case SEARCH_BARCODE:
			return { ...state, searchItems: null }
		case SEARCH_BARCODE_SUCCESS:
		case SEARCH_BARCODE_ERROR:
			return { ...state, searchItems: action.payload }
		case CLEAR_STORE_STOCK:
			return { ...initialState }
		default:
			return state
	}
}

export default rootReducer
