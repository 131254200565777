import {
	crudGetProductBonification,
	getTableBonifications,
	getTableBonifRates
} from 'actions'
import CommonTable from 'components/common/commonTable'
import ConfirmModalNoButton from 'components/common/ConfirmModalNoButton'
import NotificationError from 'components/common/notificationsErrors'
import { productBonifFields } from 'constants/crudsConfig/crudProductsConstants/CrudProductBonifFormFields'
import { Formik } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import { IGetTableBonifRates } from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import CrudProductBonifField from './BonificationTabFormField'

type ProductBonificationsTabProps = {
	niprod: number
	crudGetProductBonification: any
	disableForm: boolean
	disableFields: boolean
	getTableBonifications: () => void
	getTableBonifRates: (params: IGetTableBonifRates) => void
	bonifications: any
	bonifRates: any
	fields: any
	t: any
	handleSubmit: any
	itemInfo: any
	handleBonificationDisable: any
	handleWarning: any
	showWarning: boolean
	setFormChanged: any
	op: string
}

interface ProductBonificationTabState {
	vigency: boolean
}

class ProductBonificationsTab extends Component<
	ProductBonificationsTabProps,
	ProductBonificationTabState
> {
	constructor(props: ProductBonificationsTabProps) {
		super(props)
		this.state = {
			vigency: true
		}
	}

	componentDidMount = () => {
		const { niprod, crudGetProductBonification, getTableBonifications } =
			this.props
		crudGetProductBonification({ niprod })
		getTableBonifications()
	}

	/**
	 * to get columns table.
	 * @returns object
	 */
	getColumns = (gridFields: any) => {
		const rows = gridFields.map((field: any) => {
			const fieldId = field.idCampo.trim()
			return {
				dataField: fieldId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: { width: '10%' },
				hidden: !field.visible
			}
		})

		return rows
	}

	handleBonifRates = (cod_bonif: string) => {
		const { getTableBonifRates } = this.props
		getTableBonifRates({ cod_bonif })
	}

	handleVigency = (value: number) => {
		this.setState({ vigency: Boolean(value) })
	}

	/**
	 * to get data of sellers
	 */

	render() {
		const {
			fields,
			t,
			handleSubmit,
			itemInfo,
			disableForm,
			disableFields,
			handleWarning,
			setFormChanged,
			showWarning,
			handleBonificationDisable,
			op,
			bonifRates
		} = this.props

		const { vigency } = this.state

		const leftFields = fields.filter((field: any) => {
			return field.agrupador.includes('izq')
		})
		leftFields.push({
			idCampo: 'bonpre_vig',
			label: 'Vigente',
			editable: 1,
			visible: 1,
			requerido: 0
		})
		const rightFields = fields.filter((field: any) => {
			return field.agrupador.includes('der')
		})
		const gridFields = fields.filter((field: any) => {
			return field.agrupador.includes('grilla')
		})

		const initialValues =
			itemInfo || (fields.length ? getInitValues(fields) : {})
		const columnsCancel = disableFields ? 12 : 6
		const validationSchema = fields.length
			? getValidationSchema(fields, t, [])
			: {}

		const tableColumns = gridFields ? this.getColumns(gridFields) : []

		const gridData =
			bonifRates &&
			bonifRates.lista &&
			bonifRates.lista.map((bonif: any) => {
				return {
					lim_inf: bonif.codigo,
					porc: bonif.descrip
				}
			})

		const propsTable = {
			remote: true,
			columns: tableColumns,
			keyField: 'nro',
			data: !_.isEmpty(gridData) ? gridData : [],
			pagination: false
		}

		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values, actions) => {
					const finalValues = {
						...values,
						bonpre_d1: vigency ? values.bonpre_d1 : null,
						bonpre_d2: vigency ? values.bonpre_d2 : null
					}
					handleSubmit(finalValues)
				}}
				validationSchema={validationSchema}
				enableReinitialize={true}
				render={(props) => (
					<Fragment>
						<Col sm={12}>
							<NotificationError
								errors={props.errors}
								touched={props.touched}
							/>
						</Col>
						<Form
							onSubmit={(e) => props.handleSubmit(e as any)}
							className="voucher-info-form"
							onChange={(e) => {
								setFormChanged(true)
							}}
						>
							<Row>
								<Col sm={6}>
									<Alert variant={'dark'}>
										<b>{t('CRUDS.product_data.bonif.vol_title')}</b>
									</Alert>
									<Row>
										{leftFields &&
											_.map(leftFields, (field: any) => {
												const selectedFieldConfig = productBonifFields.find(
													(fieldConfig) => {
														return fieldConfig.id === field.idCampo
													}
												)
												return (
													<Col
														className={
															(selectedFieldConfig &&
																selectedFieldConfig.height) ||
															'col-sm-12'
														}
													>
														{field.idCampo === 'bonpre_d1' && !vigency && (
															<label
																style={{ fontSize: '10pt', marginLeft: '15px' }}
															>
																{t('CRUDS.product_data.bonif.vigency')}
															</label>
														)}
														<CrudProductBonifField
															key={field.idCampo}
															formikProps={props}
															field={field}
															crudProps={this.props}
															handleVigency={this.handleVigency}
															handleBonifRates={this.handleBonifRates}
															initialValue={
																initialValues[
																	field.idCampo as keyof typeof initialValues
																]
															}
														/>
													</Col>
												)
											})}
									</Row>
									<CommonTable {...propsTable} />
								</Col>
								<Col sm={6}>
									<Alert variant={'dark'}>
										<b>{t('CRUDS.product_data.bonif.quantity_title')}</b>
									</Alert>
									<Row>
										{rightFields &&
											_.map(rightFields, (field: any) => {
												const selectedFieldConfig = productBonifFields.find(
													(fieldConfig) => {
														return fieldConfig.id === field.idCampo
													}
												)
												return (
													<Col
														className={
															(selectedFieldConfig &&
																selectedFieldConfig.height) ||
															'col-sm-12'
														}
													>
														<CrudProductBonifField
															key={field.idCampo}
															formikProps={props}
															field={field}
															crudProps={this.props}
															initialValue={
																initialValues[
																	field.idCampo as keyof typeof initialValues
																]
															}
														/>
													</Col>
												)
											})}
									</Row>
								</Col>
							</Row>
							<Row className={'pt-3'}>
								{!disableFields && (
									<>
										<Col sm={6} className={'text-center mt-2'}>
											<Button style={{ minWidth: '145px' }} type={'submit'}>
												{t('Collection.form.buttons.accept')}
											</Button>
										</Col>
										<Col sm={6} className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={handleBonificationDisable}
											>
												{t('Collection.form.buttons.cancel')}
											</Button>
										</Col>
									</>
								)}

								{!disableForm && (
									<Col
										sm={columnsCancel}
										className={'text-center mt-2 d-flex justify-content-around'}
									>
										{disableFields && op !== 'view' && (
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={handleBonificationDisable}
											>
												{t('Editar')}
											</Button>
										)}
									</Col>
								)}
							</Row>
							<ConfirmModalNoButton
								modalTitle={t('CRUDS.modal.title_warning')}
								messageBody={t('CRUDS.modal.message_changes')}
								showModal={showWarning}
								handleCloseModal={() => handleWarning(false, 'close')}
								handleCancelButton={() => {
									handleWarning(false, 'cancel')
								}}
								handleSubmitButton={(e: any) => {
									props.handleSubmit(e as any)
									handleWarning(false, 'submit')
								}}
							/>
						</Form>
					</Fragment>
				)}
			/>
		)
	}
}

const mapStateToProps = ({ productBonification, tableCalls }: any) => {
	const { bonificationInfo } = productBonification
	const { bonifications, bonifRates } = tableCalls
	return {
		bonificationInfo,
		bonifications,
		bonifRates
	}
}

const connectForm = connect(mapStateToProps, {
	getTableBonifications,
	getTableBonifRates,
	crudGetProductBonification
})(ProductBonificationsTab)

export default withTranslation()(withRouter(connectForm))
