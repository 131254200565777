import Axios from 'axios'
import {
	ICrudCreateProductCode,
	ICrudGetProductCode,
	ICrudGetProductCodeConfig,
	ICrudGetProductCodeInfo
} from 'models/CrudProductsInterface'
const urlCRUD = '/abm_inv_productos'

export const crudGetProductCode = async (params: ICrudGetProductCode) => {
	const response = await Axios.get(`${urlCRUD}/niveles/buscar`, { params })
	return response.data
}

export const crudGetProductCodeConfig = async (
	params: ICrudGetProductCodeConfig
) => {
	const response = await Axios.get(`${urlCRUD}/niveles/config`, { params })
	return response.data
}

export const crudCreateProductCode = async (params: ICrudCreateProductCode) => {
	const response = await Axios.post(`${urlCRUD}/niveles/aceptar`, params)
	return response.data
}

export const crudGetProductCodeInfo = async (
	params: ICrudGetProductCodeInfo
) => {
	const response = await Axios.post(`${urlCRUD}/niveles/datos`, params)
	return response.data
}
