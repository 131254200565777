import {
	ADD_CONFIG_PENDING_VOUCHERS,
	ADD_CONFIG_PENDING_VOUCHERS_SUCCESS,
	GET_PENDING_VOUCHERS,
	GET_PENDING_VOUCHERS_SUCCESS,
	REMOVE_CONFIG_PENDING_VOUCHERS,
	REMOVE_CONFIG_PENDING_VOUCHERS_SUCCESS
} from 'constants/ActionsTypes'
import {
	IAddConfigPendingVouchersAction,
	IAddPendingVoucherParams,
	IPendingVouchersParams,
	IVoucherItem
} from 'models/Landing'

export const getPendingVouchers = (
	payload: IVoucherItem[]
): IPendingVouchersParams => {
	return {
		type: GET_PENDING_VOUCHERS,
		payload
	}
}

export const getPendingVouchersSuccess = (payload: any) => ({
	type: GET_PENDING_VOUCHERS_SUCCESS,
	payload
})

export const addConfigPendingVouchers = (
	payload: IAddPendingVoucherParams
): IAddConfigPendingVouchersAction => {
	return {
		type: ADD_CONFIG_PENDING_VOUCHERS,
		payload
	}
}

export const addConfigPendingVouchersSuccess = (payload: any) => ({
	type: ADD_CONFIG_PENDING_VOUCHERS_SUCCESS,
	payload
})

export const removeConfigPendingVouchers = (
	payload: IAddPendingVoucherParams
): IAddConfigPendingVouchersAction => {
	return {
		type: REMOVE_CONFIG_PENDING_VOUCHERS,
		payload
	}
}

export const removeConfigPendingVouchersSuccess = (payload: any) => ({
	type: REMOVE_CONFIG_PENDING_VOUCHERS_SUCCESS,
	payload
})
