import { getCufsPickExpenses, getDepositsPickExpenses } from 'actions'
import SearchBox from 'components/common/searchBox'
import { P_LOAD_ITEM_INV } from 'constants/ConfigProcessNames'
import { getFiltersData } from 'lib/Utils'
import { IConfigFieldsForm } from 'models/Budget'
import { IProductsSearched } from 'models/Products'
import React, { Component, Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import LoadInventoryItemsTable from './LoadInventoryItemsTable'
interface LoadInventoryItemsComponentProps {
	idOperacion?: string
	search: IProductsSearched
	tableRef: any
	callBackReturn?: () => void
	config: IConfigFieldsForm
}

interface LoadInventoryItemsComponentState {
	cufSelected: string
}

class LoadInventoryItemsComponent extends Component<
	LoadInventoryItemsComponentProps,
	LoadInventoryItemsComponentState
> {
	refsBeforeSearch: any = React.createRef()

	constructor(props: LoadInventoryItemsComponentProps) {
		super(props)
		this.state = {
			cufSelected: ''
		}
	}

	componentDidMount = () => {
		this.setInitFocus()
	}

	/**
	 * to set foco in the search input
	 */
	setInitFocus = () => {
		this.refsBeforeSearch.current.focus() // Focus in the search table
	}

	handleSetCuf = (cufSelected: string) => {
		this.setState({ cufSelected })
	}

	render() {
		const { idOperacion, search, tableRef, callBackReturn, config } = this.props
		const { cufSelected } = this.state
		const fieldsForms = config
			? getFiltersData(config.campos, { agrupador: 'formulario' })
			: []

		const searchBoxProps = {
			idOperacion,
			inputRef: this.refsBeforeSearch,
			bonuses: search?.bonificaciones,
			fields: fieldsForms,
			handleSetCuf: this.handleSetCuf,
			currentCuf: 'cuf_ori'
		}

		return (
			<Fragment>
				<Col sm={12}>
					<SearchBox {...searchBoxProps} />
				</Col>
				<Col sm={12}>
					<LoadInventoryItemsTable
						ref={tableRef}
						idOperacion={idOperacion || ''}
						callBackReturn={callBackReturn}
						cufSelected={cufSelected}
					/>
				</Col>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ product, voucher }: any) => {
	const { search } = product
	const config = voucher.config ? voucher.config[P_LOAD_ITEM_INV] : null

	return {
		search,
		config
	}
}

const mapDispatchToProps = {
	getDepositsPickExpenses,
	getCufsPickExpenses
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LoadInventoryItemsComponent)
