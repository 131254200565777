import {
	ICrudCreateProviderCode,
	ICrudCreateProviderCodeRes,
	ICrudGetProviderCode,
	ICrudGetProviderCodeConfig,
	ICrudGetProviderCodeConfigRes,
	ICrudGetProviderCodeRes
} from 'models/CrudProviderInterface'
import {
	CRUD_CLEAR_PROVIDER_CODE_STORE,
	CRUD_CREATE_PROVIDER_CODE,
	CRUD_CREATE_PROVIDER_CODE_SUCCESS,
	CRUD_GET_PROVIDER_CODE,
	CRUD_GET_PROVIDER_CODE_CONFIG,
	CRUD_GET_PROVIDER_CODE_CONFIG_SUCCESS,
	CRUD_GET_PROVIDER_CODE_SUCCESS,
	CRUD_VALIDATE_PROVIDER_CODE,
	CRUD_VALIDATE_PROVIDER_CODE_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetProviderCode = (payload: ICrudGetProviderCode) => {
	return {
		type: CRUD_GET_PROVIDER_CODE,
		payload
	}
}

export const crudGetProviderCodeSuccess = (
	payload: ICrudGetProviderCodeRes
) => {
	return {
		type: CRUD_GET_PROVIDER_CODE_SUCCESS,
		payload
	}
}

export const crudGetProviderCodeConfig = (
	payload: ICrudGetProviderCodeConfig
) => {
	return {
		type: CRUD_GET_PROVIDER_CODE_CONFIG,
		payload
	}
}

export const crudGetProviderCodeConfigSuccess = (
	payload: ICrudGetProviderCodeConfigRes
) => {
	return {
		type: CRUD_GET_PROVIDER_CODE_CONFIG_SUCCESS,
		payload
	}
}

export const crudCreateProviderCode = (payload: ICrudCreateProviderCode) => {
	return {
		type: CRUD_CREATE_PROVIDER_CODE,
		payload
	}
}

export const crudCreateProviderCodeSuccess = (
	payload: ICrudCreateProviderCodeRes
) => {
	return {
		type: CRUD_CREATE_PROVIDER_CODE_SUCCESS,
		payload
	}
}

export const crudValidateProviderCode = (payload: ICrudCreateProviderCode) => {
	return {
		type: CRUD_VALIDATE_PROVIDER_CODE,
		payload
	}
}

export const crudValidateProviderCodeSuccess = (
	payload: ICrudCreateProviderCodeRes
) => {
	return {
		type: CRUD_VALIDATE_PROVIDER_CODE_SUCCESS,
		payload
	}
}

export const crudClearProviderCodeStore = () => ({
	type: CRUD_CLEAR_PROVIDER_CODE_STORE
})
