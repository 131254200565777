import Axios from 'axios'
import { ICrudsApi } from 'models/CrudInterface'

export const crudGetValuesList = async (paramsApi: ICrudsApi) => {
	const { urlApi, action, params } = paramsApi
	const response = await Axios.get(`/${urlApi}/${action}`, { params })
	return response.data
}

export const crudCreateValue = async (paramsApi: ICrudsApi) => {
	const { urlApi, action, params } = paramsApi
	const response = await Axios.post(`/${urlApi}/${action}`, params)
	return response.data
}

export const crudGetValue = async (paramsApi: ICrudsApi) => {
	const { urlApi, action, params } = paramsApi
	const response = await Axios.get(`/${urlApi}/${action}`, { params })
	return response.data
}

export const crudDeleteValue = async (paramsApi: ICrudsApi) => {
	const { urlApi, action, params, addParams } = paramsApi
	const { id, param, value, valueParam } = params
	let requestString = `/${urlApi}/${action}?${id}=${param}&${value}=${valueParam}`
	addParams && (requestString = requestString + addParams)
	const response = await Axios.post(requestString)
	return response.data
}
