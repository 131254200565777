import {
	CRUD_CLEAR_PROVIDER_CODE_STORE,
	CRUD_CREATE_PROVIDER_CODE,
	CRUD_CREATE_PROVIDER_CODE_SUCCESS,
	CRUD_GET_PROVIDER_CODE,
	CRUD_GET_PROVIDER_CODE_CONFIG,
	CRUD_GET_PROVIDER_CODE_CONFIG_SUCCESS,
	CRUD_GET_PROVIDER_CODE_SUCCESS,
	CRUD_VALIDATE_PROVIDER_CODE,
	CRUD_VALIDATE_PROVIDER_CODE_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	providerCodeInfo: any
	providerCodeCreated: any
	providerCodeConfig: any
	providerCodeValidated: any
}

const initialState = {
	providerCodeInfo: null,
	providerCodeCreated: null,
	providerCodeConfig: null,
	providerCodeValidated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_PROVIDER_CODE:
			return { ...state, providerCodeInfo: null }
		case CRUD_GET_PROVIDER_CODE_SUCCESS:
			return { ...state, providerCodeInfo: action.payload }
		case CRUD_GET_PROVIDER_CODE_CONFIG:
			return { ...state, providerCodeConfig: null }
		case CRUD_GET_PROVIDER_CODE_CONFIG_SUCCESS:
			return { ...state, providerCodeConfig: action.payload }
		case CRUD_CREATE_PROVIDER_CODE:
			return { ...state, providerCodeCreated: null }
		case CRUD_CREATE_PROVIDER_CODE_SUCCESS:
			return { ...state, providerCodeCreated: action.payload }
		case CRUD_VALIDATE_PROVIDER_CODE:
			return { ...state, providerCodeValidated: null }
		case CRUD_VALIDATE_PROVIDER_CODE_SUCCESS:
			return { ...state, providerCodeValidated: action.payload }
		case CRUD_CLEAR_PROVIDER_CODE_STORE:
			return {
				...state,
				providerCodeConfig: null,
				providerCodeInfo: null,
				providerCodeValidated: null,
				providerCodeCreated: null
			}
		default:
			return state
	}
}

export default rootReducer
