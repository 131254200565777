import Axios from 'axios'
import {
	IAutoGenerateCancelParams,
	IAutoGenerateConfirmParams,
	IAutoGenerateStatusParams
} from 'models/AutoGenerate'
const urlBase = 'vta_genaut'

export const autoGenerateConfirmApi = async (
	params: IAutoGenerateConfirmParams
) => {
	const response = await Axios.post(`${urlBase}/confirmar`, params)
	return response.data
}
export const autoGenerateStatusApi = async (
	params: IAutoGenerateStatusParams
) => {
	const response = await Axios.get(`${urlBase}/estado`, { params })
	return response.data
}
export const autoGenerateCancelApi = async (
	params: IAutoGenerateCancelParams
) => {
	const response = await Axios.post(`${urlBase}/cancelar`, params)
	return response.data
}
