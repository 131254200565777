import CrudAccountingPeriod from 'components/crudAccountingPeriods/CrudAccountingPeriod'
import SingleTabCrudContent from 'components/genericCrud/SingleTabCrudContent'
import { CRUD_ACCOUNTING_PERIODS_DATA } from 'utils/RoutePath'

export const accountingPeriodCrudConfig = {
	id: 'accountingPeriodsCrud',
	key: 'niejerc',
	descrip: 'descrip',
	searchMethod: 'get',
	category: 'A',
	translation: 'accountingPeriodsData',
	dataUrl: CRUD_ACCOUNTING_PERIODS_DATA,
	urlApi: 'abm_cont_ejer',
	component: SingleTabCrudContent,
	tabsList: [
		{
			key: 'basic',
			title: 'CRUDS.general_use.basic_tab',
			tabComponent: CrudAccountingPeriod
		}
	],
	tabsGroupsAlta: ['principal']
}
