import Axios from 'axios'
import {
	IGetTableBarcodeFormat,
	IGetTableBonifRates,
	IGetTableBranchOfficesParams,
	IGetTableCostCenters,
	IGetTableCredit,
	IGetTableCUF,
	IGetTableCufData,
	IGetTableCustomerAttrList,
	IGetTableCustomerBranches,
	IGetTableFundAccountAttrList,
	IGetTableGenericRequest,
	IGetTableLedgerAccounts,
	IGetTableParentCostCenter,
	IGetTableParentLedgerAccount,
	IGetTableProductAttrList,
	IGetTableProducts,
	IGetTableProviderAttrList,
	IGetTableTaxes,
	IGetTableTaxesRate,
	IGetTableTermOfSales,
	IGetTableVoucherFields,
	IGetTableVoucherProcess
} from 'models/TableCalls/TableCallsInterface'

export const getTableCommisionType = async () => {
	const response = await Axios.get(`/tablas/TipoComision`)
	return response.data
}

export const getTableMessage = async () => {
	const response = await Axios.get(`/tablas/Mensajes`)
	return response.data
}

export const getTableTableType = async () => {
	const response = await Axios.get(`/tablas/TipoTabla`)
	return response.data
}

export const getTableTable = async () => {
	const response = await Axios.get(`/tablas/Tablas`)
	return response.data
}

export const getTableMeasurementUnit = async (params: any) => {
	const response = await Axios.get(`/tablas/UnidadesMedida`, { params })
	return response.data
}

export const getTableAlignmentType = async () => {
	const response = await Axios.get(`/tablas/TipoAlinea`)
	return response.data
}

export const getTableDataType = async () => {
	const response = await Axios.get(`/tablas/TipoDato`)
	return response.data
}

export const getTableValidationType = async () => {
	const response = await Axios.get(`/tablas/TipoValid`)
	return response.data
}

export const getTableValidationTypeT = async () => {
	const response = await Axios.get(`/tablas/TipoValidT`)
	return response.data
}

export const getTableResponsibleType = async (params: any) => {
	const response = await Axios.get(`/tablas/TiposResponsable`, { params })
	return response.data
}

export const getTableDocumentType = async () => {
	const response = await Axios.get(`/tablas/TiposDocumento`)
	return response.data
}

export const getTableProvince = async () => {
	const response = await Axios.get(`/tablas/Provincias`)
	return response.data
}

export const getTableCountry = async (params: any) => {
	const response = await Axios.get(`/tablas/Paises`, { params })
	return response.data
}

export const getTableCouriers = async () => {
	const response = await Axios.get(`/tablas/Transportistas`)
	return response.data
}

export const getTableTermsOfSales = async (params: IGetTableTermOfSales) => {
	const response = await Axios.get(`/tablas/CondicionesVenta`, { params })
	return response.data
}

export const getTableSellers = async () => {
	const response = await Axios.get(`/tablas/Vendedores`)
	return response.data
}

export const getTablePriceList = async () => {
	const response = await Axios.get(`/tablas/ListaPrecios`)
	return response.data
}

export const getTableForeignCurrencies = async () => {
	const response = await Axios.get(`/tablas/MonedasExtranjeras`)
	return response.data
}

export const getTableCurrencies = async (params: any) => {
	const response = await Axios.get(`/tablas/MonedasCompleta`, { params })
	return response.data
}

export const getTableConceptsBackgrounds = async (params: any) => {
	const response = await Axios.get(`/tablas/ConceptosFondos`, { params })
	return response.data
}

export const getTableBranchOffices = async (
	params: IGetTableBranchOfficesParams
) => {
	const response = await Axios.get(`/tablas/Sucursales`, { params })
	return response.data
}

export const getTableGenerationType = async () => {
	const response = await Axios.get(`/tablas/dc_tipogen`)
	return response.data
}

export const getTableVoucherType = async () => {
	const response = await Axios.get(`/tablas/dc_tipocomp`)
	return response.data
}

export const getTableCustomerCategory = async (params: any) => {
	const response = await Axios.get(`/tablas/ValCli`, { params })
	return response.data
}

export const getTablePaymentConditions = async () => {
	const response = await Axios.get(`/tablas/CondicionesPago`)
	return response.data
}

export const getTableProviderCat = async () => {
	const response = await Axios.get(`/tablas/ValProv`)
	return response.data
}

export const getTableProviderAttr = async () => {
	const response = await Axios.get(`/tablas/AtribProve`)
	return response.data
}

export const getTableProviderAttrList = async (
	params: IGetTableProviderAttrList
) => {
	const response = await Axios.get(`/tablas/AtribProveLista`, { params })
	return response.data
}

export const getTableTaxes = async (params: IGetTableTaxes) => {
	const response = await Axios.get(`/tablas/Impuestos`, { params })
	return response.data
}

export const getTableTaxesRate = async (params: IGetTableTaxesRate) => {
	const response = await Axios.get(`/tablas/TasaImpu`, { params })
	return response.data
}

export const getTableCredit = async (params: IGetTableCredit) => {
	const response = await Axios.get(`/CalculoCredito?credito=${params}`)
	return response.data
}

export const getTableCustomerAttr = async (params: any) => {
	const response = await Axios.get(`/tablas/AtribClie`, { params })
	return response.data
}

export const getTableCustomerAttrList = async (
	params: IGetTableCustomerAttrList
) => {
	const response = await Axios.get(`/tablas/AtribClieLista`, { params })
	return response.data
}

export const getTableCustomerBranches = async (
	params: IGetTableCustomerBranches
) => {
	const response = await Axios.get(`/tablas/SucClie`, { params })
	return response.data
}

export const getTableFundsAccountsCat = async () => {
	const response = await Axios.get(`/tablas/CatFondos`)
	return response.data
}

export const getTableItemCat = async (params: any) => {
	const response = await Axios.get(`/tablas/CatItem`, { params })
	return response.data
}

export const getTableNumerators = async (params: any) => {
	const { incluye_ninguno } = params
	const response = await Axios.get(
		`/tablas/Numeradores${incluye_ninguno && '?incluye_ninguno=1'}`
	)
	return response.data
}

export const getTableSymbols = async () => {
	const response = await Axios.get(`/tablas/Signos`)
	return response.data
}

export const getTableTesStates = async () => {
	const response = await Axios.get(`/tablas/EstadosTes`)
	return response.data
}

export const getTableFundAccountAttr = async () => {
	const response = await Axios.get(`/tablas/AtribCtasFon`)
	return response.data
}

export const getTableFundAccountAttrList = async (
	params: IGetTableFundAccountAttrList
) => {
	const response = await Axios.get(`/tablas/AtribCtasFonLista`, { params })
	return response.data
}

export const getTableFundsConceptsType = async () => {
	const response = await Axios.get(`/tablas/TipoCtoFondos`)
	return response.data
}

export const getTableImputationType = async () => {
	const response = await Axios.get(`/tablas/TipoImpCnt`)
	return response.data
}

export const getTableLedgerAccountsCategory = async () => {
	const response = await Axios.get(`/tablas/CntCatPlan`)
	return response.data
}

export const getTableParentLedgerAccount = async (
	params: IGetTableParentLedgerAccount
) => {
	const response = await Axios.get(`/tablas/CntCtaMayor`, { params })
	return response.data
}

export const getTableCostCentersCategory = async () => {
	const response = await Axios.get(`/tablas/CntCatCC`)
	return response.data
}

export const getTableCCImputationType = async () => {
	const response = await Axios.get(`/tablas/TipoImpCC`)
	return response.data
}

export const getTableParentCostCenter = async (
	params: IGetTableParentCostCenter
) => {
	const response = await Axios.get(`/tablas/CntCCConsol`, { params })
	return response.data
}

export const getFilterLAAssignType = async () => {
	const response = await Axios.get(`/tablas/FiltroCntTiposAsig`)
	return response.data
}

export const getFilterLAAssignStatus = async () => {
	const response = await Axios.get(`/tablas/FiltroCntEstAsig`)
	return response.data
}

export const getFilterLAAssignOrder = async () => {
	const response = await Axios.get(`/tablas/FiltroCntOrdAsig`)
	return response.data
}

export const getTableLedgerAccounts = async (
	params: IGetTableLedgerAccounts
) => {
	const response = await Axios.get(`/tablas/CntCuentasAsig?filtro=${params}`)
	return response.data
}

export const getTableCostCenters = async (params: IGetTableCostCenters) => {
	const response = await Axios.get(`/tablas/CntCentrosAsig?filtro=${params}`)
	return response.data
}

export const getFilterCCAssignType = async () => {
	const response = await Axios.get(`/tablas/FiltroCntTiposAsigCC`)
	return response.data
}

export const getOrdenCbtCabApi = async () => {
	const response = await Axios.get(`/tablas/OrdenCbtCab`)
	return response.data
}

export const getFilterConditionSaleApi = async () => {
	const response = await Axios.get(`/tablas/FiltroCondVta`)
	return response.data
}

export const getFilterTransportApi = async () => {
	const response = await Axios.get(`/tablas/FiltroTransportes`)
	return response.data
}

export const getOptionsFilterAffectedApi = async () => {
	const response = await Axios.get(`/tablas/FiltroAfec`)
	return response.data
}

export const getTableProductCategory = async () => {
	const response = await Axios.get(`/tablas/CatProd`)
	return response.data
}

export const getTableBarcodeFormat = async (params: IGetTableBarcodeFormat) => {
	const response = await Axios.get(`/tablas/FormatoCodBarra`, { params })
	return response.data
}

export const getTableCostAccountingType = async () => {
	const response = await Axios.get(`/tablas/TipoEgreso`)
	return response.data
}

export const getTableCUF = async (params: IGetTableCUF) => {
	const response = await Axios.get(`/tablas/seleccionCUF`, { params })
	return response.data
}

export const getTableProductAttr = async () => {
	const response = await Axios.get(`/tablas/AtribProd`)
	return response.data
}

export const getTableProductAttrList = async (
	params: IGetTableProductAttrList
) => {
	const response = await Axios.get(`/tablas/AtribProdLista`, { params })
	return response.data
}

export const getTableProducts = async (params: IGetTableProducts) => {
	const response = await Axios.post(`/tablas/Productos`, params)
	return response.data
}

export const getTablePrices = async () => {
	const response = await Axios.get(`/tablas/ListasPrecios`)
	return response.data
}

export const getTableDepots = async () => {
	const response = await Axios.get(`/tablas/Depositos`)
	return response.data
}

export const getTableAVSales = async (params: IGetTableGenericRequest) => {
	const response = await Axios.get(`/tablas/CAVentas`, { params })
	return response.data
}

export const getTableAVPurchases = async (params: IGetTableGenericRequest) => {
	const response = await Axios.get(`/tablas/CACompras`, { params })
	return response.data
}

export const getTableAVFunds = async (params: IGetTableGenericRequest) => {
	const response = await Axios.get(`/tablas/CAFondos`, { params })
	return response.data
}

export const getTableAVStock = async (params: IGetTableGenericRequest) => {
	const response = await Axios.get(`/tablas/CAStock`, { params })
	return response.data
}

export const getTableAVAccounting = async (params: IGetTableGenericRequest) => {
	const response = await Axios.get(`/tablas/CAContable`, { params })
	return response.data
}

export const getTableVoucherModules = async (
	params: IGetTableGenericRequest
) => {
	const response = await Axios.get(`/tablas/Modulos`, { params })
	return response.data
}

export const getTableProcess = async (params: IGetTableGenericRequest) => {
	const response = await Axios.get(`/tablas/Procesos`, { params })
	return response.data
}

export const getTableVoucherProcess = async (
	params: IGetTableVoucherProcess
) => {
	const response = await Axios.get(`/tablas/ProcComprob`, { params })
	return response.data
}

export const getTableVoucherFields = async (params: IGetTableVoucherFields) => {
	const response = await Axios.get(`/tablas/Campos`, { params })
	return response.data
}

export const getTableTitles = async (params: IGetTableGenericRequest) => {
	const response = await Axios.get(`/tablas/Titulos`, { params })
	return response.data
}

export const getTableMasks = async (params: IGetTableGenericRequest) => {
	const response = await Axios.get(`/tablas/Mascaras`, { params })
	return response.data
}

export const getTableValidations = async (params: IGetTableGenericRequest) => {
	const response = await Axios.get(`/tablas/Validaciones`, { params })
	return response.data
}

export const getTableValidationValues = async (
	params: IGetTableGenericRequest
) => {
	const response = await Axios.get(`/tablas/ValoresValid`, { params })
	return response.data
}

export const getTableCufData = async (params: IGetTableCufData) => {
	const response = await Axios.post(`/tablas/Cuf`, params)
	return response.data
}

export const getTableBonifications = async () => {
	const response = await Axios.get(`/tablas/Bonificaciones`)
	return response.data
}

export const getTableBonifRates = async (params: IGetTableBonifRates) => {
	const response = await Axios.get(`/tablas/EscalaBonif`, { params })
	return response.data
}

export const getTableUsualCoin = async () => {
	const response = await Axios.get(`/tablas/MonedaUsual`)
	return response.data
}

export const getTablePermissionType = async () => {
	const response = await Axios.get(`/tablas/TipoPermisos`)
	return response.data
}

export const getTableAbmTabs = async (params: IGetTableGenericRequest) => {
	const response = await Axios.get(`/tablas/TabsAbm`, { params })
	return response.data
}

export const getFilterProduct = async (params: any) => {
	const response = await Axios.get(`/tablas/FiltroProducto`, { params })
	return response.data
}

export const getTableEstimationType = async (params: any) => {
	const response = await Axios.get(`/tablas/FormaCalc`, { params })
	return response.data
}
