import { crudGetLastAccountingDateSuccess } from 'actions'
import { crudGetLastAccountingDate } from 'api/AccountingPeriodsApi'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { CRUD_GET_LAST_ACCOUNTING_DATE } from '../constants/ActionsTypes'

function* crudGetLastAccountingDateReq(): any {
	try {
		const accountingPeriods: any = yield call(crudGetLastAccountingDate)
		yield put(crudGetLastAccountingDateSuccess(accountingPeriods))
	} catch (error) {}
}

export function* crudGetLastAccountingDateSaga() {
	yield takeLatest(CRUD_GET_LAST_ACCOUNTING_DATE, crudGetLastAccountingDateReq)
}

export default function* rootSaga() {
	yield all([fork(crudGetLastAccountingDateSaga)])
}
