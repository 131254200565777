import { LoadPackageActions } from 'constants/ActionsTypesTs'
import {
	IConfirmPackageAction,
	IConfirmPackagePayload,
	IConfirmPackageSuccessAction,
	IGetPackagesAction,
	IGetPackagesParams,
	IGetPackagesSuccessAction,
	IGetPackageSuccessResponse
} from 'models/LoadPackages'
import { IResponseResult } from 'models/ResultsInterface'

export const getPackagesAttributes = (
	payload: IGetPackagesParams
): IGetPackagesAction => ({
	type: LoadPackageActions.GET_PACKAGES,
	payload
})

export const getPackagesAttributesSuccess = (
	payload: IGetPackageSuccessResponse
): IGetPackagesSuccessAction => ({
	type: LoadPackageActions.GET_PACKAGES_SUCCESS,
	payload
})

export const confirmPackagesAttributes = (
	payload: IConfirmPackagePayload
): IConfirmPackageAction => ({
	type: LoadPackageActions.CONFIRM_PACKAGES,
	payload
})

export const confirmPackagesAttributesSuccess = (
	payload: IResponseResult
): IConfirmPackageSuccessAction => ({
	type: LoadPackageActions.CONFIRM_PACKAGES_SUCCESS,
	payload
})
