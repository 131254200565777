import { ICrudGenericFields } from 'models/CrudInterface'

export const principalFields: Array<ICrudGenericFields> = [
	{
		id: 'cat_ctacont',
		type: 'combo',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'costCentersCategory',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_cc',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 20,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'descrip',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		maxChars: 100,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'tipo_imp',
		type: 'combo',
		width: 'col-sm-2',
		height: '',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: 'CCImputationType',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'niccmayor',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		disabled: true,
		maxChars: 100,
		autoComplete: 'new-off',
		comboData: 'parentCostCenter',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'niccmayor_descrip',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		disabled: true,
		maxChars: 100,
		autoComplete: 'new-off',
		comboData: 'parentCostCenter',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'habilitado',
		type: 'check',
		width: 'col-sm-6',
		height: '',
		default: 1,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'ej_ini',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		disabled: true,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'ej_fin',
		type: 'text',
		width: 'col-sm-3',
		height: '',
		default: null,
		disabled: true,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
