import {
	cancelProductStock,
	clearStoreStock,
	confirmProductStock,
	getProductStock,
	searchBarcode,
	validateProductStock
} from 'actions'
import NotificationMessage from 'components/common/notificationMessage'
import { CONFIRM_PRODUCT_STOCK_ERROR } from 'constants/ActionsTypes'
import { addPrevValidations } from 'lib/AxiosInterceptors'
import React, { Component } from 'react'
import { Col, Modal } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import StockForm from './stockForm'

class StockPopup extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showError: false,
			showErrorTable: false,
			page_number: 1,
			page_size: 10,
			filterShow: 'T'
		}

		addPrevValidations((e) => {
			return e.request.responseURL.includes(
				'Stock/sel_partida/egresos/confirmar'
			)
		})
	}

	componentDidUpdate = (prevProps) => {
		const {
			confirmedStock,
			canceledStock,
			params,
			showStock,
			t,
			confirmedStockError
		} = this.props

		if (prevProps.showStock !== showStock && showStock && params) {
			this.handleGetStockProducts()
			this.handleCloseErrorTable()
		}

		if (prevProps.showStock !== showStock && !showStock) {
			this.handleCloseError()
		}

		this.handleConfirmStockMessage(prevProps.confirmedStock, confirmedStock)

		if (
			prevProps.canceledStock !== canceledStock &&
			canceledStock &&
			canceledStock.Resultado
		) {
			this.setState({
				showErrorTable: true,
				errorMessageTable: t('Stock.delete_cart_product'),
				type: 'danger'
			})
			this.props.handleClose()
			this.props.handleCloseCartMessage()
		}

		if (
			confirmedStockError !== prevProps.confirmedStockError &&
			confirmedStockError &&
			confirmedStockError.error === CONFIRM_PRODUCT_STOCK_ERROR
		) {
			const params = {
				showError: true,
				errorMessage: confirmedStockError.errorMessage,
				type: 'danger'
			}
			this.handleSetErrorBarCode(params)
		}
	}

	handleConfirmStockMessage = (prevConfirmStock, confirmStock) => {
		if (
			prevConfirmStock !== confirmStock &&
			confirmStock &&
			confirmStock.Resultado
		) {
			const { Resultado } = confirmStock

			if (Resultado.Tipo_error === 'W') {
				this.setState({
					showErrorTable: true,
					errorMessageTable: Resultado.Mens_error,
					type: 'warning'
				})
			}
			this.props.handleClose()
		}
	}

	handleGetStockProducts = () => {
		const apiParams = this.buildApiParams()
		this.props.getProductStock(apiParams)
	}

	handleChangeTable = (type, pagination) => {
		const { filterShow } = this.state

		if (type === 'pagination') {
			const apiParams = this.buildApiParams(filterShow)
			apiParams.page_number = pagination.page
			apiParams.page_size = pagination.sizePerPage

			this.props.getProductStock(apiParams)
			this.setState({
				page_number: pagination.page,
				page_size: pagination.sizePerPage
			})
		}
	}

	handleChangeFilter = (filter) => {
		const apiParams = this.buildApiParams(filter)
		this.props.getProductStock(apiParams)
		this.setState({ filterShow: filter })
	}

	buildApiParams = (filterShow = null) => {
		const { idOperacion, referenceItem, params } = this.props
		const { Niprod, cantidad, cod_barra, cuf } = params

		const apiParams = {
			idOperacion,
			page_number: 1,
			page_size: 10,
			niprod: Niprod,
			cant: cantidad,
			mostrar: filterShow || 'T',
			it: referenceItem
		}

		if (!_.isEmpty(params.niprod)) {
			apiParams.niprod = Niprod
		} else if (!_.isEmpty(cod_barra)) {
			apiParams.cod_barra = cod_barra
		}

		if (!_.isEmpty(cuf)) {
			apiParams.cuf = cuf
		}

		return apiParams
	}

	/**
	 * to show error message
	 * @param {object} params
	 */
	handleSetErrorBarCode = (params) => {
		this.setState({ ...params })
	}

	handleCloseError = () => {
		this.setState({ showError: false })
	}

	handleCloseErrorTable = () => {
		this.setState({ showErrorTable: false })
	}

	handleConfirmStock = () => {
		const { idOperacion, referenceItem } = this.props
		this.props.confirmProductStock({ idOperacion, it: referenceItem })
	}

	handleCancelApi = () => {
		const { idOperacion, referenceItem } = this.props
		this.props.cancelProductStock({ idOperacion, it: referenceItem })
	}

	render() {
		const { t, productStock, showStock, idOperacion } = this.props
		const { showErrorTable, errorMessageTable, type } = this.state
		return (
			<div style={{ cursor: 'pointer' }}>
				<Col md={12} className={'mb-1'}>
					<NotificationMessage
						showError={showErrorTable}
						errorMessage={errorMessageTable}
						type={type}
						handleCloseError={this.handleCloseErrorTable}
					/>
				</Col>
				{
					<Modal
						show={showStock}
						onHide={this.props.handleClose}
						aria-labelledby={'ModalHeader'}
						size="xl"
					>
						<Modal.Header closeButton>
							<Modal.Title id={'ModalHeader'}>{t('Stock.title')}</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							<Col md={12} className={'mb-1'}>
								<NotificationMessage
									{...this.state}
									handleCloseError={this.handleCloseError}
								/>
							</Col>
							<StockForm
								idOperacion={idOperacion}
								productStock={productStock}
								handleChangeTable={this.handleChangeTable}
								handleChangeFilter={this.handleChangeFilter}
								handleConfirmStock={this.handleConfirmStock}
								handleCancelStock={this.props.handleClose}
								handleCancelApi={this.handleCancelApi}
								handleSetErrorBarCode={this.handleSetErrorBarCode}
								handleMoveArrow={this.props.handleMoveArrow}
								{...this.props}
							/>
						</Modal.Body>
					</Modal>
				}
			</div>
		)
	}
}

const mapStateToProps = ({ vouchertype, common, stock }) => {
	const { voucherType } = vouchertype
	const {
		productStock,
		validateStock,
		confirmedStock,
		canceledStock,
		searchItems,
		confirmedStockError
	} = stock

	const { message } = common
	return {
		voucherType,
		message,
		productStock,
		validateStock,
		confirmedStock,
		canceledStock,
		searchItems,
		confirmedStockError
	}
}

const connectForm = connect(mapStateToProps, {
	getProductStock,
	validateProductStock,
	confirmProductStock,
	cancelProductStock,
	searchBarcode,
	clearStoreStock
})(StockPopup)

export default withTranslation()(connectForm)
