import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import InputTypeRadio from 'components/form/InputTypeRadio'
import { P_CARGAITEMVTA } from 'constants/ConfigProcessNames'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'

interface IProductPriceForm extends WithTranslation {
	t: any
	config: any
	productPriceList: any
	tablePrice: number
	clearSelectedTable: () => void
	returnPriceValue: (price: number) => void
	fields: Array<IFieldStructure>
	handleChangeOrder: (payload: { sort: string; order: string }) => void
}

interface IProductPriceFormState {
	costDisabledInputs: boolean
	priceDisabledInputs: boolean
	basePrice: number
	baseCost: number
	discount: number
	unitPrice: number
	utility: number
	unitPrice2: number
	checkedCostRadioButton: boolean
	checkedPriceRadioButton: boolean
	selectedPrice: number
	sortValue: string
	orderValue: string
	useOnchangePrice: boolean
}

class ProductPriceForm extends Component<
	IProductPriceForm,
	IProductPriceFormState
> {
	refDiscountInput: any = React.createRef()
	refUtilityInput: any = React.createRef()
	constructor(props: IProductPriceForm) {
		super(props)
		this.state = {
			costDisabledInputs: true,
			priceDisabledInputs: true,
			basePrice: 0,
			baseCost: 0,
			discount: 0,
			unitPrice: 0,
			utility: 0,
			unitPrice2: 0,
			checkedCostRadioButton: false,
			checkedPriceRadioButton: false,
			selectedPrice: 0,
			sortValue: '',
			orderValue: '',
			useOnchangePrice: true
		}
	}

	componentDidUpdate(
		prevProps: Readonly<IProductPriceForm>,
		prevState: Readonly<IProductPriceFormState>
	): void {
		const { tablePrice, productPriceList } = this.props

		if (tablePrice !== prevProps.tablePrice && tablePrice > 0) {
			this.toggleRadioButton(true, true, false, false)
		}

		if (
			!_.isEqual(productPriceList, prevProps.productPriceList) &&
			!_.isEmpty(productPriceList)
		) {
			this.setState({
				utility: productPriceList.pc_util,
				discount: productPriceList.pc_dto,
				unitPrice: productPriceList.pcio_unit,
				unitPrice2: productPriceList.pcio_unit2
			})
		}
	}

	/**
	 * to calculate the new value
	 * @param discount
	 */
	calculatePrice = (discount: any, basePrice: any) => {
		const negative = Math.sign(discount)
		const disc = Math.abs(discount)

		let result = 0
		if (negative === -1 && discount !== '' && discount !== 0) {
			const rest = Math.floor(basePrice * disc) / 100
			result = basePrice - rest
		} else if (disc > 0) {
			const plus = Math.floor(basePrice * disc) / 100
			result = Math.abs(basePrice) + plus
		} else if (disc === 0) {
			result = basePrice
		}

		return result
	}

	/**
	 * to calculate the new value
	 * @param discount
	 */
	calculateDiscount = (base: any, price: any) => {
		let result: any = 0
		if (price === '') {
			return result
		}
		const discount = (price / base) * 100 - 100
		if (parseFloat(price) < parseFloat(base)) {
			result = -Math.abs(discount)
		} else {
			result = discount
		}
		return result
	}

	/**
	 * change disable inputs
	 * @param cost
	 * @param price
	 */
	toggleRadioButton = (
		cost: boolean,
		price: boolean,
		checkCost: boolean,
		checkPrice: boolean
	) => {
		const { productPriceList } = this.props

		if (!price) {
			this.setState({
				discount: productPriceList.pc_dto || 0,
				unitPrice: productPriceList.pcio_unit || 0
			})
		}
		if (!cost) {
			this.setState({
				utility: productPriceList.pc_util || 0,
				unitPrice2: productPriceList.pcio_unit2 || 0
			})
		}

		if (checkCost || checkPrice) {
			this.props.clearSelectedTable()
		}

		this.setState({
			costDisabledInputs: cost,
			priceDisabledInputs: price,
			checkedCostRadioButton: checkCost,
			checkedPriceRadioButton: checkPrice
		})
	}

	/**
	 * to return the value
	 */
	applyValue = () => {
		const { selectedPrice } = this.state
		this.props.returnPriceValue(selectedPrice)
	}

	render() {
		const { t, productPriceList, tablePrice, fields } = this.props

		const {
			costDisabledInputs,
			priceDisabledInputs,
			discount,
			unitPrice,
			unitPrice2,
			utility,
			baseCost,
			basePrice,
			checkedCostRadioButton,
			checkedPriceRadioButton,
			sortValue,
			orderValue,
			useOnchangePrice
		} = this.state

		// const fields = getFiltersData(config.campos, {
		// 	agrupador: 'vprec.cab'
		// })

		const radioFields = [
			{
				idCampo: 'pcio_base_radio',
				descrip: '',
				label: '',
				mascara: 'null',
				editable: true,
				visible: 1,
				requerido: false,
				valid: ' null',
				agrupador: ''
			},
			{
				idCampo: 'pcio_base_radio',
				descrip: '',
				label: '',
				mascara: 'null',
				editable: true,
				visible: 1,
				requerido: false,
				valid: ' null',
				agrupador: ''
			}
		]

		const selectedPrice =
			tablePrice > 0 ? tablePrice : priceDisabledInputs ? unitPrice : unitPrice2

		const priceSelected = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'precio_seleccionado',
			inputId: 'precio_seleccionado',
			name: 'precio_seleccionado',
			placeholder: t('products.selected_product'),
			colLabel: 'col-sm-5',
			colInput: 'col-sm-7',
			disable: false,
			onBlur: () => {},
			value: selectedPrice,
			autoComplete: 'off',
			onChange: (data: any) => this.setState({ selectedPrice: data })
		}

		const priceBase = {
			inputFormCol: { sm: 12 },
			fields: radioFields,
			label: '',
			inputId: 'pcio_base_radio',
			name: 'pcio_base_radio',
			colLabel: 'col-sm-8',
			colInput: 'col-sm-2',
			disable: false,
			checked: checkedPriceRadioButton,
			onBlur: () => {},
			value: 'base',
			onChange: (data: any) => {
				this.toggleRadioButton(false, true, false, data)
				setTimeout(() => {
					// Timer to set focus
					this.refDiscountInput.current.element.select()
					this.refDiscountInput.current.element.focus()
				}, 50)
			}
		}

		const costoBase = {
			inputFormCol: { sm: 12 },
			fields: radioFields,
			label: '',
			inputId: 'pcio_base_radio',
			name: 'pcio_base_radio',
			colLabel: 'col-sm-8',
			colInput: 'col-sm-2',
			disable: false,
			checked: checkedCostRadioButton,
			onBlur: () => {},
			value: 'base',
			onChange: (data: any) => {
				this.toggleRadioButton(true, false, data, false)
				setTimeout(() => {
					// Timer to set focus
					this.refUtilityInput.current.element.select()
					this.refUtilityInput.current.element.focus()
				}, 50)
			}
		}

		const priceBaseInput = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'precio base',
			inputId: 'precio',
			name: 'precio',
			colLabel: 'col-sm-5',
			colInput: 'col-sm-7',
			styleLabel: { paddingLeft: '0px', paddingRight: '0px' },
			disabledInput: costDisabledInputs,
			// disable: costDisabledInputs,
			onBlur: () => {},
			value: productPriceList ? productPriceList.precio : 0,
			onChange: (data: any) => {
				this.setState({ basePrice: data })
			}
		}

		const costBaseInput = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'costo base',
			inputId: 'costo',
			name: 'costo',
			colLabel: 'col-sm-5',
			colInput: 'col-sm-7',
			styleLabel: { paddingLeft: '0px', paddingRight: '0px' },
			disabledInput: priceDisabledInputs,
			// disable: priceDisabledInputs,
			onBlur: () => {},
			value: productPriceList ? productPriceList.costo : 0,
			onChange: (data: any) => {
				this.setState({ baseCost: data })
			}
		}

		const discountInput = {
			inputFormCol: { sm: 12 },
			fwRef: this.refDiscountInput,
			fields,
			label: 'descuento',
			inputId: 'pc_dto',
			name: 'pc_dto',
			colLabel: 'col-sm-5',
			colInput: 'col-sm-7',
			disabledInput: costDisabledInputs,
			useOnchange: useOnchangePrice,
			onBlur: () => {},
			value: discount,
			onChange: (data: any) => {
				const unitPrice = this.calculatePrice(data, basePrice)
				this.setState({ discount: data, unitPrice, useOnchangePrice: false })
			}
		}

		const utilInput = {
			inputFormCol: { sm: 12 },
			fwRef: this.refUtilityInput,
			fields,
			label: 'utilidad',
			inputId: 'pc_util',
			name: 'pc_util',
			colLabel: 'col-sm-5',
			colInput: 'col-sm-7',
			disabledInput: priceDisabledInputs,
			useOnchange: useOnchangePrice,
			onBlur: () => {},
			value: utility,
			onChange: (data: any) => {
				const unitPrice2 = this.calculatePrice(data, baseCost)
				this.setState({ utility: data, unitPrice2, useOnchangePrice: false })
			}
		}

		const priceInput = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'precio',
			inputId: 'pcio_unit',
			name: 'pcio_unit',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disabledInput: costDisabledInputs,
			useOnchange: !useOnchangePrice,
			onBlur: () => {},
			value: unitPrice,
			onChange: (price: any) => {
				const discount = this.calculateDiscount(basePrice, price)
				this.setState({ discount, unitPrice: price, useOnchangePrice: true })
			}
		}

		const price2Input = {
			inputFormCol: { sm: 12 },
			fields,
			label: 'precio',
			inputId: 'pcio_unit2',
			name: 'pcio_unit2',
			colLabel: 'col-sm-3',
			colInput: 'col-sm-9',
			disabledInput: priceDisabledInputs,
			useOnchange: !useOnchangePrice,
			onBlur: () => {},
			value: unitPrice2,
			onChange: (price: any) => {
				const utility = this.calculateDiscount(baseCost, price)
				this.setState({ utility, unitPrice2: price, useOnchangePrice: true })
			}
		}

		const orderProps = {
			inputFormCol: { sm: 12 },
			noInitialExecute: true,
			fields,
			label: 'periodo',
			inputId: 'periodo',
			name: 'periodo',
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			styleLabel: {
				paddingLeft: '0px',
				paddingRight: '0px'
			},
			value: orderValue,
			options:
				productPriceList && !_.isEmpty(productPriceList.periodo)
					? productPriceList.periodo.map((period: any) => ({
							id: period.codigo,
							label: period.descrip
					  }))
					: [],
			onChange: (event: any) => {
				const orderValue = event.target.value
				this.props.handleChangeOrder({ order: orderValue, sort: sortValue })
				this.setState({ orderValue })
			}
		}

		const sortProps = {
			inputFormCol: { sm: 12 },
			noInitialExecute: true,
			fields,
			label: 'orden',
			inputId: 'orden',
			name: 'orden',
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			styleLabel: {
				paddingLeft: '0px',
				paddingRight: '0px',
				textAlign: 'right'
			},
			value: sortValue,
			options:
				productPriceList && !_.isEmpty(productPriceList.orden)
					? productPriceList.orden.map((sort: any) => ({
							id: sort.codigo,
							label: sort.descrip
					  }))
					: [],
			onChange: (event: any) => {
				const sortValue = event.target.value
				this.props.handleChangeOrder({ order: sortValue, sort: orderValue })
				this.setState({ sortValue })
			}
		}

		return (
			<Fragment>
				<Row
					className={'col-10 ml-2 mb-2'}
					style={{ border: '1px solid #ccc', backgroundColor: '#75dbeb' }}
				>
					<Col className="pt-3" sm={6}>
						<InputText {...priceSelected} />
					</Col>
					<Col sm={6} className="pt-3">
						<Button
							style={{ minWidth: '125px' }}
							type={'button'}
							onClick={this.applyValue}
						>
							{t('Stock.buttons.apply')}
						</Button>
					</Col>
				</Row>
				<Row>
					<Col sm={1} className="mt-2 mr-0 pr-0">
						<InputTypeRadio {...priceBase} />
					</Col>
					<Col sm={2} style={{ paddingLeft: '5px' }}>
						<InputText {...priceBaseInput} />
					</Col>
					<Col sm={2} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
						<InputText {...discountInput} />
					</Col>
					<Col sm={2}>
						<InputText {...priceInput} />
					</Col>
				</Row>
				<Row>
					<Col sm={1} className="mt-2 mr-0 pr-0">
						<InputTypeRadio {...costoBase} />
					</Col>
					<Col sm={2} style={{ paddingLeft: '5px' }}>
						<InputText {...costBaseInput} />
					</Col>
					<Col sm={2} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
						<InputText {...utilInput} />
					</Col>
					<Col sm={2}>
						<InputText {...price2Input} />
					</Col>
					<Col sm={2}>
						<InputDropdown {...orderProps} />
					</Col>
					<Col sm={2}>
						<InputDropdown {...sortProps} />
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ voucher, product }: any) => {
	const { productPriceList } = product

	const config = voucher.config ? voucher.config[P_CARGAITEMVTA] : null

	return { config, productPriceList }
}

const mapDispatchToProps = {}

const connectForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductPriceForm)

export default withTranslation()(connectForm)
