// @flow
import LoginForm from 'components/login/loginForm'
import React, { Component, Fragment } from 'react'
import { Col, Image } from 'react-bootstrap'
import { Card } from 'reactstrap'

export default class Login extends Component {
	render() {
		const { theme } = this.props

		return (
			<Fragment>
				<div className={'container'}>
					<Col sm={12} className={`mt-5 ${theme.containerLogin}`}>
						<Card
							className={'pt-4 pb-2'}
							style={{ width: '90%', margin: '0px auto' }}
						>
							<Col className={'text-center'}>
								<Image
									className={theme.imgLogo}
									src="assets/images/login_logo.png"
								/>
							</Col>
							<LoginForm />
							<Col className={'pt-5 text-center'}>
								www.carenasistemas.com.ar
							</Col>
						</Card>
					</Col>
				</div>
			</Fragment>
		)
	}
}
