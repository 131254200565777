import {
	CLEAR_ITEM_STORE,
	CONFIRM_IND_LOAD_ITEMS,
	CONFIRM_IND_LOAD_ITEMS_SUCCESS,
	CONFIRM_LOAD_ITEMS,
	CONFIRM_LOAD_ITEMS_SUCCESS,
	CONFIRM_PROVIDER_IND_LOAD_ITEMS,
	CONFIRM_PROVIDER_IND_LOAD_ITEMS_SUCCESS,
	CONFIRM_TABLE_ITEMS,
	CONFIRM_TABLE_ITEMS_SUCCESS,
	GET_CART_ITEM_INFO,
	GET_CART_ITEM_INFO_SUCCESS,
	GET_LOAD_ITEMS,
	GET_LOAD_ITEMS_SUCCESS,
	GET_PRICE_BY_PRODUCT,
	GET_PRICE_BY_PRODUCT_SUCCESS,
	GET_PROVIDER_CART_ITEM_INFO,
	GET_PROVIDER_CART_ITEM_INFO_SUCCESS,
	ITEM_PROVIDER_ADD_TO_CART,
	ITEM_PROVIDER_ADD_TO_CART_SUCCESS,
	UPDATE_CART_ITEM,
	UPDATE_CART_ITEM_SUCCESS,
	UPDATE_PROVIDER_CART_ITEM,
	UPDATE_PROVIDER_CART_ITEM_SUCCESS,
	VOUCHER_CANCEL_SUCCESS
} from 'constants/ActionsTypes'

const initialState = {
	itemsCart: null,
	confirmItem: null,
	parameterConfirm: null,
	confirmItemsTable: null,
	productPrice: null,
	itemInfo: null,
	updateItemCart: null,
	confirmIndItems: null,
	providerItemsAdd: null
}

function rootReducer(state = initialState, action) {
	let itemsCart
	switch (action.type) {
		case GET_LOAD_ITEMS:
			return { ...state }
		case GET_LOAD_ITEMS_SUCCESS:
			return { ...state, itemsCart: action.payload.data }
		case CONFIRM_LOAD_ITEMS:
			return { ...state, confirmItem: null, parameterConfirm: action.payload }
		case CONFIRM_LOAD_ITEMS_SUCCESS:
			itemsCart = {
				total_importe: action.payload.total_importe,
				total_item: action.payload.total_item,
				total_cant: action.payload.total_cant
			}
			return { ...state, confirmItem: action.payload, itemsCart }
		case CONFIRM_TABLE_ITEMS:
			return { ...state, confirmItemsTable: null }
		case CONFIRM_TABLE_ITEMS_SUCCESS:
			return { ...state, confirmItemsTable: action.payload }
		case GET_PRICE_BY_PRODUCT:
			return { ...state, productPrice: null }
		case GET_PRICE_BY_PRODUCT_SUCCESS:
			return { ...state, productPrice: action.payload }
		case VOUCHER_CANCEL_SUCCESS:
			return { ...initialState }
		case GET_CART_ITEM_INFO:
			return { ...state, itemInfo: null }
		case GET_CART_ITEM_INFO_SUCCESS:
			return { ...state, itemInfo: action.payload }
		case UPDATE_CART_ITEM:
			return { ...state, updateItemCart: null }
		case UPDATE_CART_ITEM_SUCCESS:
			return { ...state, updateItemCart: action.payload }
		case CONFIRM_IND_LOAD_ITEMS:
			return { ...state, confirmIndItems: null }
		case CONFIRM_IND_LOAD_ITEMS_SUCCESS:
			return { ...state, confirmIndItems: action.payload }
		case ITEM_PROVIDER_ADD_TO_CART:
			return { ...state, providerItemsAdd: null }
		case ITEM_PROVIDER_ADD_TO_CART_SUCCESS:
			return { ...state, providerItemsAdd: action.payload }
		case GET_PROVIDER_CART_ITEM_INFO:
			return { ...state, providerItemInfo: null }
		case GET_PROVIDER_CART_ITEM_INFO_SUCCESS:
			return { ...state, providerItemInfo: action.payload }
		case UPDATE_PROVIDER_CART_ITEM:
			return { ...state, provUpdateItemCart: null }
		case UPDATE_PROVIDER_CART_ITEM_SUCCESS:
			return { ...state, provUpdateItemCart: action.payload }
		case CONFIRM_PROVIDER_IND_LOAD_ITEMS:
			return { ...state, confirmIndItems: null }
		case CONFIRM_PROVIDER_IND_LOAD_ITEMS_SUCCESS:
			return { ...state, confirmIndItems: action.payload }

		case CLEAR_ITEM_STORE:
			return { ...initialState }

		default:
			return state
	}
}

export default rootReducer
