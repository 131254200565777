import {
	ICrudGetUserProfiles,
	ICrudGetUserProfilesRes,
	ICrudUpdateUserProfiles,
	ICrudUpdateUserProfilesRes
} from 'models/CrudUserInterface'
import {
	CRUD_CLEAR_USER_PROFILES_STORE,
	CRUD_GET_USER_PROFILES,
	CRUD_GET_USER_PROFILES_SUCCESS,
	CRUD_UPDATE_USER_PROFILES,
	CRUD_UPDATE_USER_PROFILES_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetUserProfiles = (payload: ICrudGetUserProfiles) => {
	return {
		type: CRUD_GET_USER_PROFILES,
		payload
	}
}

export const crudGetUserProfilesSuccess = (
	payload: ICrudGetUserProfilesRes
) => {
	return {
		type: CRUD_GET_USER_PROFILES_SUCCESS,
		payload
	}
}

export const crudUpdateUserProfiles = (payload: ICrudUpdateUserProfiles) => {
	return {
		type: CRUD_UPDATE_USER_PROFILES,
		payload
	}
}

export const crudUpdateUserProfilesSuccess = (
	payload: ICrudUpdateUserProfilesRes
) => {
	return {
		type: CRUD_UPDATE_USER_PROFILES_SUCCESS,
		payload
	}
}

export const crudClearUserProfilesStore = () => ({
	type: CRUD_CLEAR_USER_PROFILES_STORE
})
