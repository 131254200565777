import Axios from 'axios'
const baseUrl = '/stock/egr_pickeo'

export const getEgrStock = async (params) => {
	const response = await Axios.get(baseUrl, { params })
	return response.data
}

export const pickeoEgrSearchBarcode = async (params) => {
	const response = await Axios.post(`${baseUrl}/cod_barra`, params)
	return response.data
}

export const pickeoEgrStockConfirm = async (params) => {
	const response = await Axios.post(`${baseUrl}/confirmar`, params)
	return response.data
}
