import withMenu from 'components/common/withMenu'
import GlobalContainer from 'components/layout/globalContainer'
import ProviderPickStockForm from 'components/providerPickStock/ProviderPickStockForm'
import { P_PICK_STOCK_CPA } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'

class ProviderPickStock extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isChange: false,
			enableCant: true
		}
	}

	getShortcuts = () => {
		const shortcuts = [
			{
				hotkey: { charCode: 'F2', modifiers: ['f2'] },
				action: this.changeInput,
				name: 'Change input search',
				description: 'Change input search'
			},
			{
				hotkey: { charCode: 'F4', modifiers: ['f4'] },
				action: this.toggleCant,
				name: 'change between enable and disable',
				description: 'change between enable and disable'
			},
			{
				hotkey: { charCode: 'F3', modifiers: ['f3'] },
				action: this.enableLast,
				name: 'enable last',
				description: 'to active last ind checkbox'
			},
			{
				hotkey: { charCode: 'F5', modifiers: ['f5'] },
				action: this.showFilters,
				name: 'to show and hide filters',
				description: 'to show and hide filters'
			}
		]

		return shortcuts
	}

	handleCallBackButton = () => {
		this.pickStockConfirmation()
	}

	changeInput = () => {
		this.setState((prevState) => ({ isChange: !prevState.isChange }))
	}

	toggleCant = () => {
		this.handleToggleCant()
		// this.setState((prevState) => ({ enableCant: !prevState.enableCant }));
	}

	showFilters = () => {
		this.handleShowFilters()
	}

	enableLast = () => {
		this.handleEnableLast()
	}

	render() {
		const { isChange, enableCant } = this.state
		return (
			<Row>
				<GlobalContainer
					codeProcess={P_PICK_STOCK_CPA}
					shortcuts={this.getShortcuts()}
					callBackButton={this.handleCallBackButton}
					nextPage
				>
					<ProviderPickStockForm
						searchInput={isChange}
						enableCant={enableCant}
						propShowFilters={(click) => (this.handleShowFilters = click)}
						propEnableLast={(click) => (this.handleEnableLast = click)}
						formConfirmation={(click) => (this.pickStockConfirmation = click)}
						handleToggleCant={(click) => (this.handleToggleCant = click)}
					/>
				</GlobalContainer>
			</Row>
		)
	}
}

export default withMenu(ProviderPickStock)
