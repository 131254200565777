import {
	ICrudGetProfileUsers,
	ICrudGetProfileUsersRes,
	ICrudUpdateProfileUsers,
	ICrudUpdateProfileUsersRes
} from 'models/CrudProfilesInterface'
import {
	CRUD_CLEAR_PROFILE_USERS_STORE,
	CRUD_GET_PROFILE_USERS,
	CRUD_GET_PROFILE_USERS_SUCCESS,
	CRUD_UPDATE_PROFILE_USERS,
	CRUD_UPDATE_PROFILE_USERS_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetProfileUsers = (payload: ICrudGetProfileUsers) => {
	return {
		type: CRUD_GET_PROFILE_USERS,
		payload
	}
}

export const crudGetProfileUsersSuccess = (
	payload: ICrudGetProfileUsersRes
) => {
	return {
		type: CRUD_GET_PROFILE_USERS_SUCCESS,
		payload
	}
}

export const crudUpdateProfileUsers = (payload: ICrudUpdateProfileUsers) => {
	return {
		type: CRUD_UPDATE_PROFILE_USERS,
		payload
	}
}

export const crudUpdateProfileUsersSuccess = (
	payload: ICrudUpdateProfileUsersRes
) => {
	return {
		type: CRUD_UPDATE_PROFILE_USERS_SUCCESS,
		payload
	}
}

export const crudClearProfileUsersStore = () => ({
	type: CRUD_CLEAR_PROFILE_USERS_STORE
})
