import {
	crudCreateFundAccountsCode,
	crudGetFundAccountsCode,
	crudGetFundAccountsCodeConfig
} from 'api/FundAccountsCrudCodeApi'
import {
	ICrudCreateFundAccountsCodeSaga,
	ICrudGetFundAccountsCodeConfigSaga,
	ICrudGetFundAccountsCodeSaga
} from 'models/CrudFundsAccountsInterface'
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
	crudCreateFundAccountsCodeSuccess,
	crudGetFundAccountsCodeConfigSuccess,
	crudGetFundAccountsCodeSuccess,
	crudValidateFundAccountsCodeSuccess
} from '../actions'
import {
	CRUD_CREATE_FUND_ACCOUNTS_CODE,
	CRUD_GET_FUND_ACCOUNTS_CODE,
	CRUD_GET_FUND_ACCOUNTS_CODE_CONFIG,
	CRUD_VALIDATE_FUND_ACCOUNTS_CODE
} from '../constants/ActionsTypes'

function* crudGetFundAccountsCodeReq({
	payload
}: ICrudGetFundAccountsCodeSaga): any {
	try {
		const fundAccounts: any = yield call(crudGetFundAccountsCode, payload)
		yield put(crudGetFundAccountsCodeSuccess(fundAccounts))
	} catch (error) {}
}

function* crudGetFundAccountsCodeConfigReq({
	payload
}: ICrudGetFundAccountsCodeConfigSaga): any {
	try {
		const fundAccounts: any = yield call(crudGetFundAccountsCodeConfig, payload)
		yield put(crudGetFundAccountsCodeConfigSuccess(fundAccounts))
	} catch (error) {}
}

function* crudCreateFundAccountsCodeReq({
	payload
}: ICrudCreateFundAccountsCodeSaga): any {
	try {
		const fundAccounts = yield call(crudCreateFundAccountsCode, payload)
		yield put(crudCreateFundAccountsCodeSuccess(fundAccounts))
	} catch (error) {}
}

function* crudValidateFundAccountsCodeReq({
	payload
}: ICrudCreateFundAccountsCodeSaga): any {
	try {
		const fundAccounts = yield call(crudCreateFundAccountsCode, payload)
		yield put(crudValidateFundAccountsCodeSuccess(fundAccounts))
	} catch (error) {}
}

export function* crudGetFundAccountsCodeSaga() {
	yield takeLatest(CRUD_GET_FUND_ACCOUNTS_CODE, crudGetFundAccountsCodeReq)
}

export function* crudGetFundAccountsCodeConfigSaga() {
	yield takeLatest(
		CRUD_GET_FUND_ACCOUNTS_CODE_CONFIG,
		crudGetFundAccountsCodeConfigReq
	)
}

export function* crudCreateFundAccountsCodeSaga() {
	yield takeEvery(CRUD_CREATE_FUND_ACCOUNTS_CODE, crudCreateFundAccountsCodeReq)
}

export function* crudValidateFundAccountsCodeSaga() {
	yield takeEvery(
		CRUD_VALIDATE_FUND_ACCOUNTS_CODE,
		crudValidateFundAccountsCodeReq
	)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetFundAccountsCodeSaga),
		fork(crudGetFundAccountsCodeConfigSaga),
		fork(crudCreateFundAccountsCodeSaga),
		fork(crudValidateFundAccountsCodeSaga)
	])
}
