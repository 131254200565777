import {
	CRUD_CLEAR_LEDGER_ACCOUNTS_CODE_STORE,
	CRUD_CREATE_LEDGER_ACCOUNTS_CODE,
	CRUD_CREATE_LEDGER_ACCOUNTS_CODE_SUCCESS,
	CRUD_GET_LEDGER_ACCOUNTS_CODE,
	CRUD_GET_LEDGER_ACCOUNTS_CODE_CONFIG,
	CRUD_GET_LEDGER_ACCOUNTS_CODE_CONFIG_SUCCESS,
	CRUD_GET_LEDGER_ACCOUNTS_CODE_SUCCESS,
	CRUD_VALIDATE_LEDGER_ACCOUNTS_CODE,
	CRUD_VALIDATE_LEDGER_ACCOUNTS_CODE_SUCCESS
} from 'constants/ActionsTypes'

export interface valueState {
	ledgerAccountsCodeInfo: any
	ledgerAccountsCodeCreated: any
	ledgerAccountsCodeConfig: any
	ledgerAccountsCodeValidated: any
}

const initialState = {
	ledgerAccountsCodeInfo: null,
	ledgerAccountsCodeCreated: null,
	ledgerAccountsCodeConfig: null,
	ledgerAccountsCodeValidated: null
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case CRUD_GET_LEDGER_ACCOUNTS_CODE:
			return { ...state, ledgerAccountsCodeInfo: null }
		case CRUD_GET_LEDGER_ACCOUNTS_CODE_SUCCESS:
			return { ...state, ledgerAccountsCodeInfo: action.payload }
		case CRUD_GET_LEDGER_ACCOUNTS_CODE_CONFIG:
			return { ...state, ledgerAccountsCodeConfig: null }
		case CRUD_GET_LEDGER_ACCOUNTS_CODE_CONFIG_SUCCESS:
			return { ...state, ledgerAccountsCodeConfig: action.payload }
		case CRUD_CREATE_LEDGER_ACCOUNTS_CODE:
			return { ...state, ledgerAccountsCodeCreated: null }
		case CRUD_CREATE_LEDGER_ACCOUNTS_CODE_SUCCESS:
			return { ...state, ledgerAccountsCodeCreated: action.payload }
		case CRUD_VALIDATE_LEDGER_ACCOUNTS_CODE:
			return { ...state, ledgerAccountsCodeValidated: null }
		case CRUD_VALIDATE_LEDGER_ACCOUNTS_CODE_SUCCESS:
			return { ...state, ledgerAccountsCodeValidated: action.payload }
		case CRUD_CLEAR_LEDGER_ACCOUNTS_CODE_STORE:
			return {
				...state,
				ledgerAccountsCodeConfig: null,
				ledgerAccountsCodeInfo: null,
				ledgerAccountsCodeValidated: null,
				ledgerAccountsCodeCreated: null
			}
		default:
			return state
	}
}

export default rootReducer
