import {
	autoGenerateCancel,
	autoGenerateCleanStore,
	autoGenerateStatus,
	getFilterBranches,
	getFilterConditionSale,
	getFilterSellers,
	getTableCurrency
} from 'actions'
import NotificationMessage from 'components/common/notificationMessage'
import { API_FORMAT_DATE } from 'constants/ApiFormats'
import { P_AUTO_GENERATE } from 'constants/ConfigProcessNames'
import { getFiltersData } from 'lib/Utils'
import {
	IAutoGenerateCancelParams,
	IAutoGenerateStatusParams
} from 'models/AutoGenerate'
import { IConfigFieldsForm } from 'models/Budget'
import { ICurrencyFilterParams } from 'models/UtilsFilters'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import AutoGenerateForm from './AutoGenerateForm'
import AutoGenerateProgressBar from './AutoGenerateProgressBar'
import AutoGenerateTable from './AutoGenerateTable'

interface IAutoGenerateComponentProps extends WithTranslation {
	idOperacion?: any
	config?: IConfigFieldsForm
	getFilterBranches: () => void
	getTableCurrency: (payload: ICurrencyFilterParams) => void
	getFilterConditionSale: () => void
	getFilterSellers: () => void
	generatedStatus: any
	autoGenerateStatus: (payload: IAutoGenerateStatusParams) => void
	t?: any
	autoGenerateCancel: (payload: IAutoGenerateCancelParams) => void
	autoConfirm: any
	autoGenerateCleanStore: () => void
}

interface IAutoGenerateComponentState {
	showError: boolean
	errorMessage: string
}
class AutoGenerateComponent extends Component<
	IAutoGenerateComponentProps,
	IAutoGenerateComponentState
> {
	timer: any = null

	constructor(props: IAutoGenerateComponentProps) {
		super(props)
		this.state = {
			showError: false,
			errorMessage: ''
		}
	}

	componentDidMount = () => {
		const { idOperacion } = this.props
		this.props.getFilterBranches()
		this.props.getTableCurrency({
			idOperacion,
			fecha: moment().format(API_FORMAT_DATE)
		})
		this.props.getFilterConditionSale()
		this.props.getFilterSellers()
		this.getStatus()
	}

	componentDidUpdate(prevProps: IAutoGenerateComponentProps): void {
		const { generatedStatus, autoConfirm } = this.props

		if (
			!_.isEqual(generatedStatus, prevProps.generatedStatus) &&
			!_.isEmpty(generatedStatus)
		) {
			if (
				generatedStatus.proceso_finalizado === 0 &&
				this.timer === null &&
				!_.isEmpty(autoConfirm)
			) {
				this.handlePollStatus()
			} else if (generatedStatus.proceso_finalizado === 1) {
				this.cleanIntervale()
			}
		}
	}

	componentWillUnmount = () => {
		this.cleanIntervale()
		this.props.autoGenerateCleanStore()
	}

	/**
	 * api is called after confirm
	 */
	handlePollStatus = () => {
		const time = process.env.REACT_APP_POLLING_TIME
			? parseInt(process.env.REACT_APP_POLLING_TIME)
			: 3000
		this.timer = setInterval(() => this.getStatus(), time)
	}

	/**
	 * to call api who get status
	 */
	getStatus = () => {
		const { idOperacion } = this.props
		this.props.autoGenerateStatus({
			idOperacion,
			page_number: 1,
			page_size: 10
		})
	}

	/**
	 * to stop polling
	 */
	cleanIntervale = () => {
		clearInterval(this.timer)
		this.timer = null
	}

	/**
	 * to call api cancel
	 */
	handleCancelForm = () => {
		const { t, idOperacion } = this.props
		const errorMessage = t('auto_generate.generar_cancel_message')
		this.cleanIntervale()
		this.setState({ errorMessage, showError: true })
		this.props.autoGenerateCancel({ idOperacion })
	}

	/**
	 * to close error message
	 */
	handleCloseError = () => {
		this.setState({ showError: false })
	}

	render() {
		const { config, idOperacion, generatedStatus } = this.props
		const { showError, errorMessage } = this.state
		const fieldsForms = config
			? getFiltersData(config.campos, { agrupador: 'formulario' })
			: []
		const fieldsTable = config
			? getFiltersData(config.campos, { agrupador: 'grilla' })
			: []

		return (
			<Fragment>
				<Col sm={12}>
					<AutoGenerateForm fields={fieldsForms} idOperacion={idOperacion} />
				</Col>
				<Col sm={12}>
					<NotificationMessage
						showError={showError}
						errorMessage={errorMessage}
						handleCloseError={this.handleCloseError}
						type={'danger'}
					/>
				</Col>
				<Col sm={12}>
					{generatedStatus && (
						<AutoGenerateProgressBar
							handleCancelProgress={this.handleCancelForm}
						/>
					)}
				</Col>
				<Col sm={12}>
					<AutoGenerateTable fields={fieldsTable} />
				</Col>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ voucher, autoGenerate }: any) => {
	const config = voucher.config ? voucher.config[P_AUTO_GENERATE] : null
	const { generatedStatus, autoConfirm } = autoGenerate

	return { config, generatedStatus, autoConfirm }
}

const mapDispatchToProps = {
	getFilterBranches,
	getTableCurrency,
	getFilterConditionSale,
	getFilterSellers,
	autoGenerateStatus,
	autoGenerateCancel,
	autoGenerateCleanStore
}

const connectForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(AutoGenerateComponent)

export default withTranslation()(connectForm)
