import {
	ICrudGetProfileTabs,
	ICrudGetProfileTabsResArray,
	ICrudUpdateProfileTabs
} from 'models/CrudProfilesInterface'
import { IResponseResult } from 'models/ResultsInterface'
import {
	CRUD_CLEAR_PROFILE_TABS_STORE,
	CRUD_GET_PROFILE_TABS,
	CRUD_GET_PROFILE_TABS_SUCCESS,
	CRUD_UPDATE_PROFILE_TABS,
	CRUD_UPDATE_PROFILE_TABS_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetProfileTabs = (payload: ICrudGetProfileTabs) => {
	return {
		type: CRUD_GET_PROFILE_TABS,
		payload
	}
}

export const crudGetProfileTabsSuccess = (
	payload: ICrudGetProfileTabsResArray
) => {
	return {
		type: CRUD_GET_PROFILE_TABS_SUCCESS,
		payload
	}
}

export const crudUpdateProfileTabs = (payload: ICrudUpdateProfileTabs) => {
	return {
		type: CRUD_UPDATE_PROFILE_TABS,
		payload
	}
}

export const crudUpdateProfileTabsSuccess = (payload: IResponseResult) => {
	return {
		type: CRUD_UPDATE_PROFILE_TABS_SUCCESS,
		payload
	}
}

export const crudClearProfileTabsStore = () => ({
	type: CRUD_CLEAR_PROFILE_TABS_STORE
})
