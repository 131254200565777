import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import styles from './inputGroupText.module.css'

class InputGroupText extends Component {
	renderOptions = () => {
		const { selectOptions, tooltip } = this.props
		const result = []
		selectOptions.forEach((option) => {
			const titleOption = tooltip ? option.label : null
			result.push(
				<option
					data-toggle="tooltip"
					title={titleOption}
					value={option.id}
					key={option.id}
				>
					{option.label}
				</option>
			)
		})

		return result
	}

	render() {
		const {
			label,
			placeholder,
			name,
			styles,
			stylesGroup,
			inputId,
			colInput,
			colLabel,
			styleLabel,
			divStyle,
			disable,
			theme,
			type,
			beforeInput,
			value,
			onChange,
			selectOptions
		} = this.props

		const classInput = label ? colInput : 'col-sm-12'
		const classLabel = label ? colLabel : ''
		const classText = disable ? theme.inputDisabled : ''
		const customType = type || 'text'

		return (
			<Row className={'form-group'}>
				<label
					className={`${classLabel} ${theme.inputLabel}`}
					style={{ ...styleLabel, paddingTop: '5px' }}
				>
					{label}
				</label>
				<Col className={classInput} style={{ ...divStyle }}>
					<div className="input-group form-group">
						<div className="input-group-prepend">
							<span className="input-group-text" style={stylesGroup}>
								{beforeInput}
							</span>
						</div>

						{customType !== 'dropdown' ? (
							<input
								id={inputId}
								name={name}
								type={customType}
								style={styles}
								placeholder={placeholder}
								disabled={disable}
								className={`${theme.inputText} ${classText}`}
								value={value}
								onChange={onChange}
							/>
						) : (
							<select
								id={inputId}
								name={name}
								type={customType}
								style={styles}
								placeholder={placeholder}
								disabled={disable}
								className={`${theme.inputText} ${classText}`}
								value={value}
								onChange={onChange}
							>
								{selectOptions && this.renderOptions()}
							</select>
						)}
					</div>
				</Col>
			</Row>
		)
	}
}

export default themr('InputGroupTextStyle', styles)(InputGroupText)
