import CommonLabelCol from 'components/common/CommonLabelCol'
import InputButton from 'components/form/inputButton'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { SEARCH_PRODUCTS } from 'utils/RoutePath'

interface ProductDetailProps extends WithTranslation {
	t?: any
	fields?: any
	productInfo?: any
}

interface ProductDetailState {
	id: string
	activeTab: string
	actionOp: string
}

class ProductDetailHeader extends Component<
	ProductDetailProps,
	ProductDetailState
> {
	render() {
		const { fields, productInfo, t } = this.props

		return (
			<Fragment>
				<Row>
					<CommonLabelCol
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="descrip1"
						value={productInfo.descrip1 || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="categ_prod"
						value={productInfo.categ_prod || ''}
					/>
					<Col className={'pb-1'}>
						<Link to={`${SEARCH_PRODUCTS}/filters`}>
							<InputButton
								valueButton={t('global.return_button')}
								onClick={() => {}}
								type={'primary'}
								customStyle={{ minWidth: '125px' }}
							/>
						</Link>
					</Col>
				</Row>
				<Row className={'pb-2'}>
					<CommonLabelCol
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="cod_prod"
						value={productInfo.cod_prod || ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 5 }}
						colLabel={{ sm: 3 }}
						fields={fields}
						labelId="niprod"
						value={productInfo.niprod || ''}
					/>
				</Row>
			</Fragment>
		)
	}
}

export default withTranslation()(ProductDetailHeader)
