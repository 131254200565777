import {
	GET_ING_STOCK,
	GET_ING_STOCK_SUCCESS,
	PICKEO_SEARCH_BARCODE,
	PICKEO_SEARCH_BARCODE_ERROR,
	PICKEO_SEARCH_BARCODE_SUCCESS,
	PICK_STOCK_ANMAT,
	PICK_STOCK_ANMAT_CANCEL,
	PICK_STOCK_ANMAT_CANCEL_SUCCESS,
	PICK_STOCK_ANMAT_CONFIRM,
	PICK_STOCK_ANMAT_CONFIRM_SUCCESS,
	PICK_STOCK_ANMAT_SUCCESS,
	PICK_STOCK_CONFIRM,
	PICK_STOCK_CONFIRM_SUCCESS,
	PICK_STOCK_IMPORT,
	PICK_STOCK_IMPORT_CANCEL,
	PICK_STOCK_IMPORT_CANCEL_SUCCESS,
	PICK_STOCK_IMPORT_CONFIRM,
	PICK_STOCK_IMPORT_CONFIRM_ERROR,
	PICK_STOCK_IMPORT_CONFIRM_SUCCESS,
	PICK_STOCK_IMPORT_ERROR,
	PICK_STOCK_IMPORT_SUCCESS,
	PICK_STOCK_TABLE,
	PICK_STOCK_TABLE_SUCCESS
} from '../constants/ActionsTypes'

export const getIngStock = (params) => {
	return {
		type: GET_ING_STOCK,
		payload: params
	}
}

export const getIngStockSuccess = (response) => {
	return {
		type: GET_ING_STOCK_SUCCESS,
		payload: response
	}
}

export const pickeoSearchBarcode = (params) => {
	return {
		type: PICKEO_SEARCH_BARCODE,
		payload: params
	}
}

export const pickeoSearchBarcodeSuccess = (response) => {
	return {
		type: PICKEO_SEARCH_BARCODE_SUCCESS,
		payload: response
	}
}

export const pickeoSearchBarcodeError = (error) => {
	return {
		type: PICKEO_SEARCH_BARCODE_ERROR,
		payload: error
	}
}

export const pickStockTable = (params) => {
	return {
		type: PICK_STOCK_TABLE,
		payload: params
	}
}

export const pickStockTableSuccess = (params) => {
	return {
		type: PICK_STOCK_TABLE_SUCCESS,
		payload: params
	}
}

export const pickStockConfirm = (params) => {
	return {
		type: PICK_STOCK_CONFIRM,
		payload: params
	}
}

export const pickStockConfirmSuccess = (response) => {
	return {
		type: PICK_STOCK_CONFIRM_SUCCESS,
		payload: response
	}
}

export const pickStockImport = (params) => {
	return {
		type: PICK_STOCK_IMPORT,
		payload: params
	}
}

export const pickStockImportSuccess = (response) => {
	return {
		type: PICK_STOCK_IMPORT_SUCCESS,
		payload: response
	}
}

export const pickStockImportError = (response) => {
	return {
		type: PICK_STOCK_IMPORT_ERROR,
		payload: response
	}
}

export const pickStockImportConfirm = (params) => {
	return {
		type: PICK_STOCK_IMPORT_CONFIRM,
		payload: params
	}
}

export const pickStockImportConfirmSuccess = (response) => {
	return {
		type: PICK_STOCK_IMPORT_CONFIRM_SUCCESS,
		payload: response
	}
}

export const pickStockImportConfirmError = (response) => {
	return {
		type: PICK_STOCK_IMPORT_CONFIRM_ERROR,
		payload: response
	}
}

export const pickStockImportCancel = (params) => {
	return {
		type: PICK_STOCK_IMPORT_CANCEL,
		payload: params
	}
}

export const pickStockImportCancelSuccess = (response) => {
	return {
		type: PICK_STOCK_IMPORT_CANCEL_SUCCESS,
		payload: response
	}
}

export const pickStockAnmat = (params) => {
	return {
		type: PICK_STOCK_ANMAT,
		payload: params
	}
}

export const pickStockAnmatSuccess = (response) => {
	return {
		type: PICK_STOCK_ANMAT_SUCCESS,
		payload: response
	}
}

export const pickStockAnmatConfirm = (params) => {
	return {
		type: PICK_STOCK_ANMAT_CONFIRM,
		payload: params
	}
}

export const pickStockAnmatConfirmSuccess = (response) => {
	return {
		type: PICK_STOCK_ANMAT_CONFIRM_SUCCESS,
		payload: response
	}
}

export const pickStockAnmatCancel = (params) => {
	return {
		type: PICK_STOCK_ANMAT_CANCEL,
		payload: params
	}
}

export const pickStockAnmatCancelSuccess = (response) => {
	return {
		type: PICK_STOCK_ANMAT_CANCEL_SUCCESS,
		payload: response
	}
}
