import {
	crudCreateProviderCode,
	crudGetProviderCode,
	crudGetProviderCodeConfig
} from 'actions'
import NotificationError from 'components/common/notificationsErrors'
import { Form, Formik, FormikProps } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import React, { Component, Fragment } from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import CrudProviderCodeField from './CrudProviderCodeField'

interface CrudProviderCodeFormProps extends WithTranslation {
	crudGetProviderCode: any
	crudCreateProviderCode: any
	t: any
	itemInfo?: any
	method: string
	disableForm: boolean
	handleSubmitModal: any
	handleCloseModal: any
	providerCodeConfig: any
	providerCodeInfo: any
	providerCodeCreated: any
	cod_val: string
	cod_prove: string
}

type CrudProviderCodeFormState = {
	disableForm: boolean
	fieldDefaults: {}
}

class CrudProviderCodeForm extends Component<
	CrudProviderCodeFormProps,
	CrudProviderCodeFormState
> {
	constructor(props: CrudProviderCodeFormProps) {
		super(props)
		this.state = {
			disableForm: false,
			fieldDefaults: {}
		}
	}

	// Gets initial values and dropdowns
	componentDidMount = () => {
		const { cod_val, cod_prove } = this.props
		this.props.crudGetProviderCode({ cod_val, codigo_actual: cod_prove })
	}

	// If info exists setups default values
	componentDidUpdate = () => {
		const { providerCodeInfo } = this.props
		const { fieldDefaults } = this.state
		if (providerCodeInfo && Object.entries(fieldDefaults).length < 1) {
			this.setState({
				fieldDefaults: {
					nivel1: providerCodeInfo.nivel1
						? _.find(providerCodeInfo.nivel1, (values) => {
								return values.codigo === providerCodeInfo.default_nivel1
						  })
							? providerCodeInfo.default_nivel1
							: providerCodeInfo.nivel1[0].codigo
						: providerCodeInfo.default_nivel1,
					nivel2: providerCodeInfo.nivel2
						? _.find(providerCodeInfo.nivel2, (values) => {
								return values.codigo === providerCodeInfo.default_nivel2
						  })
							? providerCodeInfo.default_nivel2
							: providerCodeInfo.nivel2[0].codigo
						: providerCodeInfo.default_nivel2,
					nivel3: providerCodeInfo.nivel3
						? _.find(providerCodeInfo.nivel3, (values) => {
								return values.codigo === providerCodeInfo.default_nivel3
						  })
							? providerCodeInfo.default_nivel3
							: providerCodeInfo.nivel3[0].codigo
						: providerCodeInfo.default_nivel3,
					nivel4: providerCodeInfo.nivel4
						? _.find(providerCodeInfo.nivel4, (values) => {
								return values.codigo === providerCodeInfo.default_nivel4
						  })
							? providerCodeInfo.default_nivel4
							: providerCodeInfo.nivel4[0].codigo
						: providerCodeInfo.default_nivel4,
					nivel5: providerCodeInfo.nivel5
						? _.find(providerCodeInfo.nivel5, (values) => {
								return values.codigo === providerCodeInfo.default_nivel5
						  })
							? providerCodeInfo.default_nivel5
							: providerCodeInfo.nivel5[0].codigo
						: providerCodeInfo.default_nivel5
				}
			})
		}
	}

	render() {
		const {
			t,
			disableForm,
			method,
			handleSubmitModal,
			handleCloseModal,
			providerCodeConfig,
			providerCodeInfo,
			cod_val,
			cod_prove
		} = this.props

		const { fieldDefaults } = this.state

		if (providerCodeConfig) {
			const fields = providerCodeConfig.campos
			const initialValues =
				fieldDefaults || (fields.length ? getInitValues(fields) : {})

			const columnsCancel = disableForm ? 12 : 6
			const validationSchema = fields.length
				? getValidationSchema(fields, t, [], [])
				: {}

			return (
				<Fragment>
					<Formik
						initialValues={initialValues}
						onSubmit={(values, actions) => {
							handleSubmitModal({
								cod_val: cod_val,
								cod_prove: cod_prove,
								values: values
							})
						}}
						enableReinitialize={true}
						validationSchema={validationSchema}
					>
						{(props: FormikProps<any>) => (
							<Fragment>
								<Col sm={12}>
									<NotificationError
										errors={props.errors}
										touched={props.touched}
									/>
								</Col>
								<Alert variant={'dark'}>
									<b>{t('CRUDS.general_use.formulariocodigo')}</b>
								</Alert>
								<Form>
									{(method === 'add' ||
										((method === 'edit' || method === 'view') &&
											providerCodeInfo)) &&
										_.map(fields, (field: any) => {
											return (
												<CrudProviderCodeField
													key={field.idCampo}
													formikProps={props}
													field={field}
													crudProps={{
														...this.props
													}}
													initialValue={
														initialValues[
															field.idCampo as keyof typeof initialValues
														]
													}
												/>
											)
										})}
									<Row className={'pt-3'}>
										{!disableForm && (
											<Col sm={6} className={'text-center mt-2'}>
												<Button
													style={{ minWidth: '145px' }}
													type={'button'}
													onClick={() => props.handleSubmit()}
												>
													{t('Collection.form.buttons.accept')}
												</Button>
											</Col>
										)}
										<Col sm={columnsCancel} className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '145px' }}
												type={'button'}
												onClick={handleCloseModal}
											>
												{t('Collection.form.buttons.cancel')}
											</Button>
										</Col>
									</Row>
								</Form>
							</Fragment>
						)}
					</Formik>
				</Fragment>
			)
		} else {
			return <div>Cargando...</div>
		}
	}
}

const mapStateToProps = ({ providerCode }: any) => {
	const { providerCodeConfig, providerCodeInfo, providerCodeCreated } =
		providerCode
	return {
		providerCodeConfig,
		providerCodeInfo,
		providerCodeCreated
	}
}

const connectForm = connect(mapStateToProps, {
	crudGetProviderCodeConfig,
	crudGetProviderCode,
	crudCreateProviderCode
})(CrudProviderCodeForm)

export default withTranslation()(withRouter(connectForm))
