import CrudProductTabsDataContents from 'components/crudProducts/CrudProductTabsDataContents'
import CrudSearchProductsForm from 'components/crudProducts/SearchForm/CrudSearchProductsForm'
import { CRUD_PRODUCTS_DATA } from 'utils/RoutePath'

export const productsConfig = {
	id: 'productsCrud',
	key: 'niprod',
	descrip: 'descrip1',
	searchMethod: 'post',
	category: 'A',
	translation: 'product_data',
	dataUrl: CRUD_PRODUCTS_DATA,
	urlApi: 'abm_inv_productos',
	component: CrudProductTabsDataContents,
	searchComponent: CrudSearchProductsForm
}
