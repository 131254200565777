import CommonLabelCol from 'components/common/CommonLabelCol'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { InventorySearchBarcodeResponse } from 'models/InventoryPickStock'
import React, { Component, Fragment } from 'react'
import { Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
interface InventoryPickStockResumeProps extends WithTranslation {
	t?: any
	fields: Array<IFieldStructure>
	data: InventorySearchBarcodeResponse
}

interface InventoryPickStockResumeState {}

class InventoryPickStockResume extends Component<
	InventoryPickStockResumeProps,
	InventoryPickStockResumeState
> {
	render() {
		const { fields, data } = this.props

		return (
			<Fragment>
				<Row className="col-sm-12">
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 5 }}
						fields={fields}
						labelId="ult_Producto"
						value={data ? data.ult_producto : ''}
					/>

					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 5 }}
						fields={fields}
						labelId="cant_ns"
						value={data ? data.cant_ing : ''}
					/>
				</Row>
				<Row className="col-sm-12">
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 5 }}
						fields={fields}
						labelId="tot_cant_ingr"
						value={data ? data.ult_cant : ''}
					/>
					<CommonLabelCol
						formCol={{ sm: 6 }}
						colLabel={{ sm: 5 }}
						fields={fields}
						labelId="tot_cant_pend"
						value={data ? data.cant_pend : ''}
					/>
				</Row>
			</Fragment>
		)
	}
}

export default withTranslation()(InventoryPickStockResume)
