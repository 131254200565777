import { automaticPurchaseChangeLine, automaticPurchaseFilter } from 'actions'
import CommonTable from 'components/common/commonTable'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getValueMask } from 'lib/MaskValues'
import { handleSetFocus, isValueChange } from 'lib/TableUtils'
import {
	IAutomaticPurchaseChangeLineParams,
	IAutomaticPurchaseChangeLineResponse
} from 'models/AutomaticPurchase'
import React, { Component, Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
interface IAutomaticPurchaseTableProps extends WithTranslation {
	authUser: any
	fields: Array<IFieldStructure>
	t?: any
	generatedStatus?: any
	automaticPurchaseData: any
	automaticPurchaseChangeLine: (
		payload: IAutomaticPurchaseChangeLineParams
	) => void
	changedLine: null | IAutomaticPurchaseChangeLineResponse
	idOperacion: string
	automaticPurchaseFilter: (payload: any) => void
	filters: any
}

interface IAutomaticPurchaseTableState {
	rowSelected: Array<string>
	columnsTable: any
	dataTable: any
	isFirstData: boolean
}

class AutomaticPurchaseTable extends Component<
	IAutomaticPurchaseTableProps,
	IAutomaticPurchaseTableState
> {
	inputRefs: any = {}
	firstRefs: any = ''
	primaryKey: string = 'nro'
	constructor(props: IAutomaticPurchaseTableProps) {
		super(props)
		this.state = {
			rowSelected: [],
			columnsTable: [],
			dataTable: [],
			isFirstData: true
		}
	}

	componentDidMount(): void {
		const { fields } = this.props
		const columnsTable = !_.isEmpty(fields) ? this.getColumns() : []
		this.setState({ columnsTable })
	}

	componentDidUpdate(prevProps: Readonly<IAutomaticPurchaseTableProps>): void {
		const { automaticPurchaseData } = this.props

		if (
			!_.isEqual(prevProps.automaticPurchaseData, automaticPurchaseData) &&
			!_.isEmpty(automaticPurchaseData)
		) {
			const columnsTable = this.getColumns()
			const rowSelected = _.filter(automaticPurchaseData.Items, (item) => {
				return item.sel
			}).map((item) => item[this.primaryKey])

			this.setState({ columnsTable: [...columnsTable], rowSelected })
		}

		this.saveItems(prevProps.automaticPurchaseData, automaticPurchaseData)
	}

	/**
	 * to validate when the data change
	 */
	saveItems = (prevData: any, automaticPurchaseData: any) => {
		if (
			!_.isEqual(prevData, automaticPurchaseData) &&
			!_.isEmpty(automaticPurchaseData)
		) {
			this.setState({
				dataTable: automaticPurchaseData.Items,
				isFirstData: false
			})
		}
	}

	onChangeTable = (type: string, pagination: any) => {
		const { idOperacion, filters } = this.props
		if (type === 'pagination') {
			this.props.automaticPurchaseFilter({
				...filters,
				idOperacion,
				page_size: pagination.sizePerPage,
				page_number: pagination.page
			})
		}
	}

	/**
	 * to print columns table
	 * @returns
	 */
	getColumns = () => {
		const { fields } = this.props
		const rows = fields.map((field) => {
			const fieldId = field.idCampo.trim()
			return {
				dataField: fieldId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: {},
				hidden:
					!field.visible || fieldId === 'fin_item' || fieldId === 'sig_proceso',
				formatter: (cell: string, row: any, rowIndex: number) => {
					return this.renderFormat(field, cell, row)
				}
			}
		})
		return rows
	}

	/**
	 * to get the next editable field.
	 * @param {*} field
	 * @returns
	 */
	getNextEditField = (field: string) => {
		const { fields } = this.props

		let result = null
		let nextIndex = 0
		let restart = false
		let indexField = _.findIndex(fields, { idCampo: field })

		do {
			nextIndex = indexField + 1
			indexField = fields[nextIndex] ? nextIndex : 0
			result = fields[indexField].idCampo.trim()
			if (!fields[nextIndex]) {
				restart = true
			}
		} while (!fields[indexField].editable || !fields[indexField].visible)

		return restart ? 'nextRow' : result
	}

	/**
	 * to get id of the next row
	 * @params idProduct
	 * @params revers >> to validate if down or up
	 */
	getNextProductId = (idRow: string, reverse: boolean = false) => {
		const { automaticPurchaseData } = this.props
		let result = 0
		if (idRow) {
			automaticPurchaseData.Items.forEach((prd: any, index: number) => {
				if (prd[this.primaryKey] === idRow) {
					result = !reverse ? index + 1 : index - 1 // Back o next row
				}
			})
			if (automaticPurchaseData.Items[result]) {
				return automaticPurchaseData.Items[result][this.primaryKey]
					? automaticPurchaseData.Items[result][this.primaryKey]
					: null
			} else {
				return null
			}
		}
		return null
	}

	/**
	 * to set focus to the next field
	 */
	handleNextFieldTable = (fieldId: string, rowId: string) => {
		const { fields } = this.props
		const nextField = this.getNextEditField(fieldId)

		if (nextField === 'nextRow') {
			const firstField = fields[0]
			const field = firstField.editable
				? firstField.idCampo
				: this.getNextEditField(firstField.idCampo)
			const nextRow = this.getNextProductId(rowId)
			handleSetFocus(field, nextRow, this.inputRefs)
		} else {
			handleSetFocus(nextField, rowId, this.inputRefs)
		}
	}

	/**
	 *To render table rows
	 * @param {string} field
	 * @param {string} value
	 * @param {object} row
	 * @returns input render field
	 */
	renderFormat = (field: IFieldStructure, value: string, row: any) => {
		const { authUser } = this.props
		const fieldId = field.idCampo.trim()
		let result = null
		const inputError = value === 'error_input'
		const customValue = value === 'error_input' ? '' : value
		const inputStyle =
			fieldId === 'cantidad' || fieldId === 'pcio_unit' || fieldId === 'neto'
				? { textAlign: 'right' }
				: {}

		if (field.editable && !this.inputRefs[fieldId]) {
			this.inputRefs[fieldId] = {}
		}

		if (field.editable && !this.inputRefs[fieldId][row[this.primaryKey]]) {
			const customRef = React.createRef()
			this.inputRefs[fieldId][row[this.primaryKey]] = customRef
			if (this.firstRefs === null) {
				this.firstRefs = customRef
			}
		}

		const optionsInput = {
			fwRef: field.editable
				? this.inputRefs[fieldId][row[this.primaryKey]]
				: null,
			inputFormCol: { sm: 12 },
			fields: [{ ...field, label: false }],
			label: false,
			inputId: `${fieldId}`,
			id: `${fieldId}-${row[this.primaryKey]}`,
			name: `${fieldId}-${row[this.primaryKey]}`,
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			divStyle: { paddingLeft: '10px', paddingRight: '10px' },
			disable: false,
			disabledInput: !row.sel,
			value: customValue,
			showError: inputError,
			styles: inputStyle,
			rowStyle: { marginBottom: '5px' },
			onBlur: () => {},
			popperPlacement: 'bottom-end',
			autoComplete: 'Off',
			handleEnterKey: (e: any, value: any) => {
				this.handleNextFieldTable(fieldId, row[this.primaryKey])
				e.preventDefault()
			},
			onChange: (value: any, event: any) => {}
		}

		if (field.editable) {
			result = (
				<InputText
					{...optionsInput}
					autoComplete="off"
					onBlur={(value: string) => {
						this.handleOnblurInput(value, fieldId, row)
					}}
				/>
			)
		} else if (field.mascara) {
			result = getValueMask(value, field.mascara, { authUser })
		} else {
			result = value
		}

		return result
	}

	/**
	 * To update data in reducer in the onblur event
	 * @params value
	 * @params fieldId
	 * @params row
	 */

	handleOnblurInput = async (value: any, fieldId: string, row: any) => {
		const { idOperacion } = this.props
		const { dataTable } = this.state
		const paramsApi = {
			idOperacion,
			niprod: row.niprod,
			sel: true,
			pc_dto: row.pc_dto,
			cant_acomprar: row.cant_acomprar,
			precio_cpa: row.precio_cpa
		}

		if (
			isValueChange(
				row[this.primaryKey],
				fieldId,
				value,
				this.primaryKey,
				dataTable
			)
		) {
			switch (fieldId) {
				case 'pc_dto':
					this.props.automaticPurchaseChangeLine({
						...paramsApi,
						pc_dto: value,
						idcampo: 'pc_dto'
					})
					break
				case 'cant_acomprar':
					this.props.automaticPurchaseChangeLine({
						...paramsApi,
						cant_acomprar: value,
						idcampo: 'cant_acomprar'
					})
					break
				case 'precio_cpa':
					this.props.automaticPurchaseChangeLine({
						...paramsApi,
						precio_cpa: value,
						idcampo: 'precio_cpa'
					})
					break
				default:
					this.props.automaticPurchaseChangeLine(paramsApi)
					break
			}
		}
	}

	handleOnSelectCheck = (row: any, isCheck: boolean) => {
		const { idOperacion } = this.props

		this.props.automaticPurchaseChangeLine({
			idOperacion,
			niprod: row.niprod,
			sel: isCheck,
			pc_dto: row.pc_dto,
			cant_acomprar: row.cant_acomprar,
			precio_cpa: row.precio_cpa
		})
	}

	handleOnSelectAllCheck = () => {}

	/**
	 * to get all select row options
	 */
	getSelectRowOptions = () => {
		const { rowSelected } = this.state

		return {
			mode: 'checkbox',
			selectColumnPosition: 'left',
			selected: rowSelected,
			headerColumnStyle: {
				textAlign: 'center',
				paddingRight: '8px !important'
			},
			style: (row: any) => {
				const backgroundColor = row.error ? '#f8d7da' : '#FFF'
				const marginBottom = 120
				return { backgroundColor, marginBottom }
			},
			onSelect: this.handleOnSelectCheck,
			onSelectAll: this.handleOnSelectAllCheck
		}
	}

	render() {
		const { automaticPurchaseData } = this.props
		const { columnsTable } = this.state
		const paginationOptions = automaticPurchaseData
			? {
					page: automaticPurchaseData.page_number,
					sizePerPage: automaticPurchaseData.page_size,
					totalSize: automaticPurchaseData.total_count
			  }
			: {}

		const propsTable = {
			remote: true,
			columns: columnsTable,
			data: automaticPurchaseData ? automaticPurchaseData.Items : [],
			rowClasses: 'theme.tableRow',
			headerClasses: 'theme.tableHeader',
			paginationOptions,
			keyField: this.primaryKey,
			selectRow: this.getSelectRowOptions(),
			onTableChange: this.onChangeTable
		}

		return (
			<Fragment>
				{!_.isEmpty(columnsTable) && <CommonTable {...propsTable} />}
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth, automaticPurchase }: any) => {
	const { automaticPurchaseData, changedLine } = automaticPurchase
	const { authUser } = auth

	return { authUser, automaticPurchaseData, changedLine }
}

const mapDispatchToProps = {
	automaticPurchaseChangeLine,
	automaticPurchaseFilter
}

const connectForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(AutomaticPurchaseTable)

export default withTranslation()(connectForm)
