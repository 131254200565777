import InputButton from 'components/form/inputButton'
import { renderInput } from 'lib/FormUtils'
import { ICrudGenericFields } from 'models/CrudInterface'
import {
	IGetTableGenericRes,
	IGetTableGenericResArray
} from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import _ from 'underscore'
import { ADMIN } from 'utils/RoutePath'

const excludeFields = ['alta', 'buscar']

export interface CrudFiltersProps extends WithTranslation {
	handleSearch: any
	urlAddCrud: string
	history: any
	t: any
	fields: any
	customButtons: any
	customFieldsConfig?: any
	crudProps?: any
	homeTarget: string
	hideNew?: boolean
	hideSearch?: boolean
	hideBack?: boolean
}

type CrudFiltersState = {
	fieldsValues: any
	filterButtons: any
	fieldsConfig?: any
}

class CrudFilters extends Component<CrudFiltersProps, CrudFiltersState> {
	constructor(props: CrudFiltersProps) {
		super(props)
		this.state = {
			fieldsValues: {},
			filterButtons: [
				{
					id: 'search_button',
					valueButton: this.props.t('global.search'),
					onClick: () => this.props.handleSearch(this.state.fieldsValues),
					showButton: !props.hideSearch
				},
				this.props.urlAddCrud && {
					id: 'new_button',
					valueButton: this.props.t('global.new_button'),
					onClick: () => this.handleNewCrud(),
					showButton: !props.hideNew
				},
				{
					id: 'home_button',
					valueButton: this.props.t('global.return_button'),
					onClick: () => this.handleHome(this.props.homeTarget),
					showButton: !props.hideBack
				}
			]
		}
	}

	componentDidMount = () => {
		const { customFieldsConfig } = this.props
		customFieldsConfig && this.setState({ fieldsConfig: customFieldsConfig })
	}

	comboDataField = (data: IGetTableGenericResArray) => {
		const values: { cod_valor: string; desc_valor: string }[] = []
		data &&
			_.each(data.lista, (opt: IGetTableGenericRes) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	/**
	 * to render field and add callBacks
	 * @param {array} fields
	 * @returns
	 */
	handleRenderInputs = (fields: any) => {
		const { crudProps } = this.props
		const { fieldsValues, fieldsConfig } = this.state
		return fields.map((field: any, index: any) => {
			const fieldId = field.idCampo.trim()
			const addData = _.find(fieldsConfig, (field: ICrudGenericFields) => {
				return field.id === fieldId
			})
			if (!excludeFields.includes(fieldId)) {
				field.value = fieldsValues[fieldId] || ''

				addData &&
					(field = {
						...field,
						tipo: addData.type,
						colInput: addData.width,
						autoComplete: addData.autoComplete,
						disabled: addData.disabled
					})

				if (field.tipo === 'combo') {
					addData &&
						(field.valores = crudProps[
							addData.comboData as keyof typeof crudProps
						]
							? this.comboDataField(
									crudProps[addData.comboData as keyof typeof crudProps]
							  )
							: [])
				}

				field.handleEnterKey = (e: any, data: any) => {
					e.preventDefault()
					this.props.handleSearch(fieldsValues)
					return false
				}

				field.onChange = (data: any) => {
					let value = ''
					if (data.target) {
						value = data.target.value
					} else {
						value = data
					}
					fieldsValues[fieldId] = value
					this.setState({ fieldsValues: { ...fieldsValues } })
				}

				return <Fragment key={index}> {renderInput(field, {})} </Fragment>
			} else {
				return <div key={index} />
			}
		})
	}

	/**
	 * to go to the page of addCrud.
	 */
	handleNewCrud = () => {
		const { urlAddCrud, history } = this.props
		history.push(urlAddCrud)
	}

	/**
	 * to go to the page home
	 */
	handleHome = (homeTarget: string) => {
		const { history } = this.props
		history.push(`${ADMIN}/${homeTarget}`)
	}

	render() {
		const { fields, customButtons } = this.props
		const { filterButtons } = this.state

		const allButtons = customButtons
			? [...filterButtons, ...customButtons]
			: filterButtons

		const renderButtons: Array<any> = []

		_.forEach(allButtons, (button) => {
			button && button.showButton && renderButtons.push(button)
		})

		return (
			<Container className={'p-5'}>
				<Row>{fields && this.handleRenderInputs(fields)}</Row>
				<Row className={'pt-4'}>
					{_.map(renderButtons, (button) => {
						return (
							<Col className={'text-center'} key={button.id}>
								<InputButton
									valueButton={button.valueButton}
									onClick={button.onClick}
									type={'primary'}
									customStyle={{ minWidth: '145px' }}
								/>
							</Col>
						)
					})}
				</Row>
			</Container>
		)
	}
}

export default withTranslation()(withRouter(CrudFilters))
