import {
	AUTOMATIC_PURCHASE_CHANGE_LINE,
	AUTOMATIC_PURCHASE_CHANGE_LINE_SUCCESS,
	AUTOMATIC_PURCHASE_CONFIRM,
	AUTOMATIC_PURCHASE_CONFIRM_SUCCESS,
	AUTOMATIC_PURCHASE_FILTER,
	AUTOMATIC_PURCHASE_FILTER_SUCCESS,
	AUTOMATIC_PURCHASE_UPDATE,
	AUTOMATIC_PURCHASE_UPDATE_SUCCESS,
	GET_DEPOSITS,
	GET_DEPOSITS_SUCCESS,
	GET_LIST_CALCULATION_FORMULAS,
	GET_LIST_CALCULATION_FORMULAS_SUCCESS,
	GET_LIST_COST_TYPE,
	GET_LIST_COST_TYPE_SUCCESS,
	GET_LIST_DRUGS,
	GET_LIST_DRUGS_SUCCESS,
	GET_LIST_LABORATORIES,
	GET_LIST_LABORATORIES_SUCCESS,
	GET_LIST_ORDER_OC_MULTI,
	GET_LIST_ORDER_OC_MULTI_SUCCESS,
	GET_LIST_PROVIDERS,
	GET_LIST_PROVIDERS_SUCCESS,
	GET_PAYMENT_TERMS_FILTER_LIST,
	GET_PAYMENT_TERMS_FILTER_LIST_SUCCESS
} from 'constants/ActionsTypes'
import {
	IAutomaticPurchaseChangeLineResponse,
	IAutomaticPurchaseConfirmResponse
} from 'models/AutomaticPurchase'
import { IGetTableGenericRes } from 'models/TableCalls/TableCallsInterface'
import _ from 'underscore'
export interface IAutomaticPurchaseStore {
	depositList: any
	calculationFormulaList: null | Array<IGetTableGenericRes>
	orderList: null | Array<IGetTableGenericRes>
	labList: null | Array<IGetTableGenericRes>
	drugList: null | Array<IGetTableGenericRes>
	costTypeList: null | Array<IGetTableGenericRes>
	providerList: null | Array<IGetTableGenericRes>
	paymentTermsList: null | Array<IGetTableGenericRes>
	automaticPurchaseData: any
	changedLine: null | IAutomaticPurchaseChangeLineResponse
	automaticConfirmed: null | IAutomaticPurchaseConfirmResponse
}

const initialState = {
	depositList: null,
	calculationFormulaList: null,
	orderList: null,
	labList: null,
	drugList: null,
	costTypeList: null,
	providerList: null,
	paymentTermsList: null,
	automaticPurchaseData: null,
	changedLine: null,
	automaticConfirmed: null
}

function rootReducer(
	state: IAutomaticPurchaseStore = initialState,
	action: any
) {
	let purchaseData

	switch (action.type) {
		case GET_DEPOSITS:
			return { ...state, depositList: null }
		case GET_DEPOSITS_SUCCESS:
			return { ...state, depositList: action.payload.lista }
		case GET_LIST_CALCULATION_FORMULAS:
			return { ...state, calculationFormulaList: null }
		case GET_LIST_CALCULATION_FORMULAS_SUCCESS:
			return { ...state, calculationFormulaList: action.payload.lista }
		case GET_LIST_ORDER_OC_MULTI:
			return { ...state, orderList: null }
		case GET_LIST_ORDER_OC_MULTI_SUCCESS:
			return { ...state, orderList: action.payload.lista }
		case GET_LIST_LABORATORIES:
			return { ...state, labList: null }
		case GET_LIST_LABORATORIES_SUCCESS:
			return { ...state, labList: action.payload.lista }
		case GET_LIST_DRUGS:
			return { ...state, drugList: null }
		case GET_LIST_DRUGS_SUCCESS:
			return { ...state, drugList: action.payload.lista }
		case GET_LIST_COST_TYPE:
			return { ...state, costTypeList: null }
		case GET_LIST_COST_TYPE_SUCCESS:
			return { ...state, costTypeList: action.payload.lista }
		case GET_LIST_PROVIDERS:
			return { ...state, providerList: null }
		case GET_LIST_PROVIDERS_SUCCESS:
			return { ...state, providerList: action.payload.lista }
		case GET_PAYMENT_TERMS_FILTER_LIST:
			return { ...state, paymentTermsList: null }
		case GET_PAYMENT_TERMS_FILTER_LIST_SUCCESS:
			return { ...state, paymentTermsList: action.payload.lista }
		case AUTOMATIC_PURCHASE_FILTER:
		case AUTOMATIC_PURCHASE_UPDATE:
			return { ...state, automaticPurchaseData: null }
		case AUTOMATIC_PURCHASE_FILTER_SUCCESS:
		case AUTOMATIC_PURCHASE_UPDATE_SUCCESS:
			return { ...state, automaticPurchaseData: action.payload }
		case AUTOMATIC_PURCHASE_CHANGE_LINE:
			return { ...state, changedLine: null }
		case AUTOMATIC_PURCHASE_CHANGE_LINE_SUCCESS:
			purchaseData = state.automaticPurchaseData.Items
			return {
				...state,
				changedLine: action.payload,
				automaticPurchaseData: {
					Items: updatePurchaseData(purchaseData, action.payload.Items)
				}
			}
		case AUTOMATIC_PURCHASE_CONFIRM:
			return { ...state, automaticConfirmed: null }
		case AUTOMATIC_PURCHASE_CONFIRM_SUCCESS:
			return { ...state, automaticConfirmed: action.payload }
		default:
			return state
	}
}

const updatePurchaseData = (prevPurchaseData: any, newPurchaseData: any) => {
	return prevPurchaseData.map((purchase: any) => {
		const newData = _.find(newPurchaseData, { niprod: purchase.niprod })
		if (!_.isEmpty(newData)) {
			return { ...purchase, ...newData }
		}
		return purchase
	})
}

export default rootReducer
