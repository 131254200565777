import GlobalContainer from 'components/layout/globalContainer'
import ProviderForm from 'components/provider/providerForm'
import { P_CARGAITEM_CPA, P_SELPROV } from 'constants/ConfigProcessNames'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import withMenu from '../../components/common/withMenu'

class Voucher extends Component {
	constructor(props) {
		super(props)
		this.state = {
			type: null,
			urlSubmitForm: ''
		}
		this.formRef = React.createRef()
	}

	callBackButton = () => {
		if (this.formRef.current) {
			this.formRef.current.handleSubmit()
		}
	}

	render() {
		return (
			<Row>
				<GlobalContainer
					codeProcess={P_SELPROV}
					callBackButton={this.callBackButton}
					breadCrumbButtonType={'primary'}
					shoppingCartProcess={P_CARGAITEM_CPA}
					nextPage
				>
					<ProviderForm current={P_SELPROV} formRef={this.formRef} />
				</GlobalContainer>
			</Row>
		)
	}
}

export default withTranslation()(withRouter(withMenu(Voucher)))
