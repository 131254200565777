import {
	ICrudGetCustomerRefer,
	ICrudGetCustomerReferArrayRes,
	ICrudUpdateCustomerRefer
} from 'models/CrudCustomerInterface'
import { IResponseResult } from 'models/ResultsInterface'
import {
	CRUD_CLEAR_CUSTOMER_REFER_STORE,
	CRUD_GET_CUSTOMER_REFER,
	CRUD_GET_CUSTOMER_REFER_SUCCESS,
	CRUD_UPDATE_CUSTOMER_REFER,
	CRUD_UPDATE_CUSTOMER_REFER_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetCustomerReferred = (payload: ICrudGetCustomerRefer) => {
	return {
		type: CRUD_GET_CUSTOMER_REFER,
		payload
	}
}

export const crudGetCustomerReferredSuccess = (
	payload: ICrudGetCustomerReferArrayRes
) => {
	return {
		type: CRUD_GET_CUSTOMER_REFER_SUCCESS,
		payload
	}
}

export const crudUpdateCustomerReferred = (
	payload: ICrudUpdateCustomerRefer
) => {
	return {
		type: CRUD_UPDATE_CUSTOMER_REFER,
		payload
	}
}

export const crudUpdateCustomerReferredSuccess = (payload: IResponseResult) => {
	return {
		type: CRUD_UPDATE_CUSTOMER_REFER_SUCCESS,
		payload
	}
}

export const crudClearCustomerReferredStore = () => {
	return {
		type: CRUD_CLEAR_CUSTOMER_REFER_STORE
	}
}
