import { DELAY_TIME_API } from 'constants/ConfigProcessNames'
import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects'
import {
	confirmationClientSuccess,
	getClientSuccess,
	searchClientsSuccess
} from '../actions/Client'
import { confirmationClient, getClient, searchClients } from '../api/Client'
import {
	CONFIRMATION_CLIENT,
	GET_CLIENT,
	SEARCH_CLIENTS
} from '../constants/ActionsTypes'

function* getClientRequest({ payload }) {
	try {
		const client = yield call(getClient, payload)
		yield put(getClientSuccess(client))
	} catch (error) {}
}

function* searchClientsRequest({ payload }) {
	yield delay(DELAY_TIME_API)
	try {
		const clients = yield call(searchClients, payload)
		yield put(searchClientsSuccess(clients))
	} catch (error) {}
}

function* confirmationClientRequest({ payload }) {
	const { client, callBackReturn } = payload
	try {
		const confirmation = yield call(confirmationClient, client)
		callBackReturn()
		yield put(confirmationClientSuccess(confirmation))
	} catch (error) {}
}

export function* getClientSaga() {
	yield takeLatest(GET_CLIENT, getClientRequest)
}

export function* searchClientsSaga() {
	yield takeLatest(SEARCH_CLIENTS, searchClientsRequest)
}

export function* confirmationClientSaga() {
	yield takeLatest(CONFIRMATION_CLIENT, confirmationClientRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getClientSaga),
		fork(searchClientsSaga),
		fork(confirmationClientSaga)
	])
}
