import FinalConsumerForm from 'components/FinalConsumer/FinalConsumerForm'
import GlobalContainer from 'components/layout/globalContainer'
import { P_FINAL_CONSUMER } from 'constants/ConfigProcessNames'
import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'

interface IFinalConsumerProps {}
interface IFinalConsumerState {
	formRef: any
}

export default class FinalConsumer extends PureComponent<
	IFinalConsumerProps,
	IFinalConsumerState
> {
	constructor(props: IFinalConsumerProps) {
		super(props)
		this.state = { formRef: React.createRef() }
	}

	handleCallBackButton = () => {
		const { formRef } = this.state

		if (formRef.current) {
			formRef.current.handleSubmit()
		}
	}

	render() {
		const containerProps = {
			codeProcess: P_FINAL_CONSUMER,
			callBackButton: this.handleCallBackButton,
			nextPage: true
		}

		return (
			<Row>
				<GlobalContainer {...containerProps}>
					<FinalConsumerForm formRef={this.state.formRef} />
				</GlobalContainer>
			</Row>
		)
	}
}
