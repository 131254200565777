import { accountingPeriodFields } from 'constants/crudsConfig/crudAccountingPeriodsConstants/crudAccountingPeriodsFormFields'
import { FormikProps } from 'formik'
import { renderInput } from 'lib/FormUtils'
import { ICrudGenericFields } from 'models/CrudInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'
import { CrudAccountingPeriodsProps } from './CrudAccountingPeriod'

type CrudAccountingPeriodDataFieldProps = {
	field: any
	formikProps: FormikProps<{}>
	crudProps: CrudAccountingPeriodsProps
	initialValue: any
}

type CrudAccountingPeriodDataFieldState = {
	fieldConfig: ICrudGenericFields
	defaultValue?: string
	idField?: any
	value?: any
}

class CrudAccountingPeriodDataField extends Component<
	CrudAccountingPeriodDataFieldProps,
	CrudAccountingPeriodDataFieldState
> {
	constructor(props: CrudAccountingPeriodDataFieldProps) {
		super(props)
		this.state = {
			fieldConfig: {
				id: '',
				type: '',
				width: '',
				autoComplete: '',
				comboData: '',
				maxChars: 0,
				dependsOn: {
					id: '',
					values: [
						{
							idValue: '',
							disabled: false
						}
					]
				}
			}
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field } = this.props
		const idField = field.idCampo.trim()
		const addData = _.find(
			accountingPeriodFields,
			(field: ICrudGenericFields) => {
				return field.id === idField
			}
		)
		addData && this.setState({ fieldConfig: addData })
		addData && this.setState({ idField: idField })
		addData && this.setState({ defaultValue: addData.default })
	}

	componentDidUpdate = (prevProps: CrudAccountingPeriodDataFieldProps) => {
		const { crudProps, formikProps } = this.props
		const { crudProps: prevCrudProps } = prevProps
		const { idField } = this.state

		if (
			crudProps.lastAccountingPeriod !== prevCrudProps.lastAccountingPeriod &&
			crudProps.lastAccountingPeriod &&
			(idField === 'inicio_ej' || idField === 'fin_ej')
		) {
			const dateTimeFormat = 'T00:00:00'
			const dateValue = new Date(
				`${crudProps.lastAccountingPeriod.fin_ej}${dateTimeFormat}`
			)
			idField === 'inicio_ej'
				? dateValue.setFullYear(
						dateValue.getFullYear(),
						dateValue.getMonth(),
						dateValue.getDay() + 1
				  )
				: dateValue.setFullYear(
						dateValue.getFullYear() + 1,
						dateValue.getMonth(),
						dateValue.getDay()
				  )
			formikProps.setFieldValue(idField, dateValue)
		}
	}

	handleBlur = async () => {
		const { formikProps } = this.props
		const { idField } = this.state
		formikProps.setTouched({
			...formikProps.touched,
			[idField]: true
		})
	}

	renderInputs = (
		field: any,
		formikProps: any,
		disableForm: boolean,
		fieldConfig: ICrudGenericFields
	) => {
		const addData = fieldConfig

		addData &&
			(field = {
				...field,
				tipo: addData.type,
				colInput: addData.width,
				autoComplete: addData.autoComplete,
				onBlur: this.handleBlur
			})

		if (!field.editable) field.disabled = true
		if (field.visible) {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { field, formikProps, crudProps } = this.props
		const { fieldConfig } = this.state
		return (
			<Fragment>
				{this.renderInputs(
					field,
					formikProps,
					crudProps.disableForm,
					fieldConfig
				)}
			</Fragment>
		)
	}
}

export default CrudAccountingPeriodDataField
