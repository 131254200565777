import Axios from 'axios'
import {
	ICrudCreateFundAccountsCode,
	ICrudGetFundAccountsCode,
	ICrudGetFundAccountsCodeConfig
} from 'models/CrudFundsAccountsInterface'
const urlCRUD = '/abm_fon_cuentas'

export const crudGetFundAccountsCode = async (
	params: ICrudGetFundAccountsCode
) => {
	const response = await Axios.get(`${urlCRUD}/niveles/datos`, { params })
	return response.data
}

export const crudGetFundAccountsCodeConfig = async (
	params: ICrudGetFundAccountsCodeConfig
) => {
	const response = await Axios.get(
		`${urlCRUD}/niveles/config?cod_val=${params}`
	)
	return response.data
}

export const crudCreateFundAccountsCode = async (
	params: ICrudCreateFundAccountsCode
) => {
	const response = await Axios.post(`${urlCRUD}/niveles/aceptar`, params)
	return response.data
}
