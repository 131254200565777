import ValuePurchaseConditionsContent from 'components/purchaseConditions/ValuePurchaseConditionsContent'
import { CRUD_PURCHASE_CONDITIONS_DATA } from 'utils/RoutePath'

export const purchaseConditionsConfig = {
	id: 'purchaseConditionsCrud',
	key: 'cod_cpag',
	descrip: 'descrip',
	searchMethod: 'get',
	category: 'A',
	translation: 'purchase_conditions_data',
	dataUrl: CRUD_PURCHASE_CONDITIONS_DATA,
	urlApi: 'abm_cpa_cond',
	component: ValuePurchaseConditionsContent
}
