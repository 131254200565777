import { getTableCurrencySuccess } from 'actions'
import { getTableCurrencyApi } from 'api/UtilsFilters'
import { GET_TABLE_CURRENCY } from 'constants/ActionsTypes'
import { ICurrencyFilterAction } from 'models/UtilsFilters'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* getTableCurrencyRequest({ payload }: ICurrencyFilterAction): any {
	try {
		const response: any = yield call(getTableCurrencyApi, payload)
		yield put(getTableCurrencySuccess(response))
	} catch (error: any) {}
}

export function* getTableCurrencySaga() {
	yield takeEvery(GET_TABLE_CURRENCY, getTableCurrencyRequest)
}

export default function* rootSaga() {
	yield all([fork(getTableCurrencySaga)])
}
