import CommonTable from 'components/common/commonTable'
import { getValueMask } from 'lib/MaskValues'
import React, { Component, Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { themr } from 'react-css-themr'
import { connect } from 'react-redux'
import { getImpDocuments } from '../../actions'
import styles from './collectionsTable.module.scss'

class CollectionImportTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			itemsTable: []
		}
		this.primaryKey = 'iditem'
	}

	componentDidMount = () => {
		const { idOperacion, nicfondo, cat_item } = this.props
		this.props.getImpDocuments({
			idOperacion,
			page_number: 1,
			page_size: 10,
			nicfondo,
			cat_item,
			mostrar: 'T'
		})
	}

	componentDidUpdate = (prevProps) => {
		const { docsImported, impDocuments, idOperacion, nicfondo, cat_item } =
			this.props

		if (prevProps.docsImported !== docsImported && docsImported) {
			this.props.getImpDocuments({
				idOperacion,
				page_number: 1,
				page_size: 10,
				nicfondo,
				cat_item
			})
		}

		if (prevProps.impDocuments !== impDocuments && !prevProps.impDocuments) {
			this.setState({ itemsTable: impDocuments.items })
		}
	}

	handleChangeTable = (type, pagination) => {
		const { idOperacion, nicfondo, cat_item } = this.props
		if (type === 'pagination') {
			this.props.getImpDocuments({
				idOperacion,
				page_size: pagination.sizePerPage,
				page_number: pagination.page,
				mostrar: 'T',
				nicfondo,
				cat_item
			})
		}
		// this.setState({ page: pagination.page, page_size: pagination.sizePerPage });
	}

	getColumns = () => {
		const { impDocuments } = this.props
		const rows = impDocuments.configCampos
			? impDocuments.configCampos.map((field) => {
					const campoId = field.idCampo.trim()
					return {
						dataField: campoId,
						text: field.label,
						align: 'center',
						headerAlign: 'center',
						headerStyle: this.getStyleColumn(field),
						hidden: !field.visible,
						formatter: (cell, row, rowIndex) => {
							return this.renderFormat(field, cell, row)
						}
					}
			  })
			: []
		return rows
	}

	handleRowStyle = (row) => {
		const style = {}
		if (row.ind_error) {
			style.backgroundColor = '#FFCC99'
		} else if (!row.ind_error) {
			style.backgroundColor = '#33CCFF'
		}
		return style
	}

	renderFormat = (field, value, row) => {
		const { authUser } = this.props
		const idField = field.idCampo.trim()
		let result = value

		if (field.mascara) {
			result = getValueMask(value, field.mascara, { authUser })
		}

		if (idField === 'mens_error' && value.length > 30) {
			result = (
				<div data-toggle="tooltip" data-placement="left" title={value}>
					{`${value.substring(0, 30)}...`}
				</div>
			)
		}
		return result
	}

	getStyleColumn = (field) => {
		const idField = field.idCampo.trim()
		let style = {}
		switch (idField) {
			default:
				style = { width: '10%' }
				break
		}

		if (field.requerido === '1' || field.requerido) {
			style.color = 'red'
		}

		return style
	}

	render() {
		const { impDocuments, theme } = this.props
		const tableColumns = impDocuments ? this.getColumns() : []

		const options = impDocuments
			? {
					pageStartIndex: 1,
					sizePerPage: impDocuments.page_size,
					page: impDocuments.page_number,
					totalSize: impDocuments.total_count
			  }
			: {}

		return (
			<Fragment>
				<Col className={`col-12 pl-0 pr-0`}>
					{impDocuments && tableColumns.length > 0 && (
						<CommonTable
							remote
							columns={tableColumns}
							keyField={this.primaryKey}
							data={this.state.itemsTable}
							rowClasses={theme.tableRow}
							headerClasses={theme.tableHeader}
							paginationOptions={options}
							onTableChange={this.handleChangeTable}
							rowStyle={this.handleRowStyle}
						/>
					)}
				</Col>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ collections, auth }) => {
	const { impDocuments, docsImported } = collections
	const { authUser } = auth

	return { impDocuments, authUser, docsImported }
}

const connectForm = connect(mapStateToProps, {
	getImpDocuments
})(CollectionImportTable)

export default themr('StateTableStyles', styles)(connectForm)
