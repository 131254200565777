import {
	automaticPurchaseChangeLineSuccess,
	automaticPurchaseConfirmSuccess,
	automaticPurchaseExportSuccess,
	automaticPurchaseFilterSuccess,
	automaticPurchaseUpdateSuccess,
	getDepositsSuccess,
	getListCalculationFormulasSuccess,
	getListCostTypesSuccess,
	getListDrugsSuccess,
	getListLaboratoriesSuccess,
	getListOrderOCMultiSuccess,
	getListProvidersSuccess,
	getPaymentTermsFilterListSuccess
} from 'actions'
import {
	automaticPurchaseChangeLineApi,
	automaticPurchaseConfirmApi,
	automaticPurchaseExportApi,
	automaticPurchaseFilterApi,
	automaticPurchaseUpdateApi,
	getDepositsApi,
	getLisDrugsApi,
	getLisLaboratoriesApi,
	getLisProvidersApi,
	getListCalculationFormulasApi,
	getListCostTypesApi,
	getListOrderOCMultiApi,
	getPaymentTermsFilterListApi
} from 'api/AutomaticPurchase'
import {
	AUTOMATIC_PURCHASE_CHANGE_LINE,
	AUTOMATIC_PURCHASE_CONFIRM,
	AUTOMATIC_PURCHASE_EXPORT,
	AUTOMATIC_PURCHASE_FILTER,
	AUTOMATIC_PURCHASE_UPDATE,
	GET_DEPOSITS,
	GET_LIST_CALCULATION_FORMULAS,
	GET_LIST_COST_TYPE,
	GET_LIST_DRUGS,
	GET_LIST_LABORATORIES,
	GET_LIST_ORDER_OC_MULTI,
	GET_LIST_PROVIDERS,
	GET_PAYMENT_TERMS_FILTER_LIST
} from 'constants/ActionsTypes'
import {
	IAutomaticPurchaseChangeLineAction,
	IAutomaticPurchaseConfirmAction,
	IAutomaticPurchaseFilterAction,
	IAutomaticPurchaseUpdateAction,
	IGetCostTypeSuccessResponse,
	IGetDepositsAction,
	IGetLaboratoriesAction,
	IGetListCalculationFormulasSuccessResponse,
	IGetListDrugsAction,
	IGetListDrugsSuccessResponse,
	IGetListLaboratoriesSuccessResponse,
	IGetListOrderOCMultiSuccessResponse,
	IGetListProvidersAction,
	IGetListProvidersResponse,
	IGetPaymentTermsFiltersListAction
} from 'models/AutomaticPurchase'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

function* getDepositsRequest({ payload }: IGetDepositsAction): any {
	try {
		const deposits: any = yield call(getDepositsApi, payload)
		yield put(getDepositsSuccess(deposits))
	} catch (error: any) {}
}

function* getListCalculationFormulasRequest(): any {
	try {
		const formulas: IGetListCalculationFormulasSuccessResponse = yield call(
			getListCalculationFormulasApi
		)
		yield put(getListCalculationFormulasSuccess(formulas))
	} catch (error: any) {}
}

function* getListOrderOCMultiRequest(): any {
	try {
		const order: IGetListOrderOCMultiSuccessResponse = yield call(
			getListOrderOCMultiApi
		)
		yield put(getListOrderOCMultiSuccess(order))
	} catch (error: any) {}
}

function* getListLaboratoriesRequest({ payload }: IGetLaboratoriesAction): any {
	try {
		const laboratories: IGetListLaboratoriesSuccessResponse = yield call(
			getLisLaboratoriesApi,
			payload
		)
		yield put(getListLaboratoriesSuccess(laboratories))
	} catch (error: any) {}
}

function* getListDrugsRequest({ payload }: IGetListDrugsAction): any {
	try {
		const drugs: IGetListDrugsSuccessResponse = yield call(
			getLisDrugsApi,
			payload
		)
		yield put(getListDrugsSuccess(drugs))
	} catch (error: any) {}
}

function* getListProvidersRequest({ payload }: IGetListProvidersAction): any {
	try {
		const providers: IGetListProvidersResponse = yield call(
			getLisProvidersApi,
			payload
		)
		yield put(getListProvidersSuccess(providers))
	} catch (error: any) {}
}

function* getListCostTypesRequest(): any {
	try {
		const types: IGetCostTypeSuccessResponse = yield call(getListCostTypesApi)
		yield put(getListCostTypesSuccess(types))
	} catch (error: any) {}
}

function* getPaymentTermsFilterListRequest({
	payload
}: IGetPaymentTermsFiltersListAction): any {
	try {
		const paymentTerms: any = yield call(getPaymentTermsFilterListApi, payload)
		yield put(getPaymentTermsFilterListSuccess(paymentTerms))
	} catch (error: any) {}
}

function* automaticPurchaseFilterRequest({
	payload
}: IAutomaticPurchaseFilterAction): any {
	try {
		const automaticPurchases: any = yield call(
			automaticPurchaseFilterApi,
			payload
		)
		yield put(automaticPurchaseFilterSuccess(automaticPurchases))
	} catch (error: any) {}
}

function* automaticPurchaseChangeLineRequest({
	payload
}: IAutomaticPurchaseChangeLineAction): any {
	try {
		const automaticPurchases: any = yield call(
			automaticPurchaseChangeLineApi,
			payload
		)
		yield put(automaticPurchaseChangeLineSuccess(automaticPurchases))
	} catch (error: any) {}
}

function* automaticPurchaseConfirmRequest({
	payload
}: IAutomaticPurchaseConfirmAction): any {
	const { params, callBackReturn } = payload
	try {
		const confirm: any = yield call(automaticPurchaseConfirmApi, params)
		yield put(automaticPurchaseConfirmSuccess(confirm))
		callBackReturn()
	} catch (error: any) {}
}

function* automaticPurchaseUpdateRequest({
	payload
}: IAutomaticPurchaseUpdateAction): any {
	try {
		const update: any = yield call(automaticPurchaseUpdateApi, payload)
		yield put(automaticPurchaseUpdateSuccess(update))
	} catch (error: any) {}
}

function* automaticPurchaseExportRequest({ payload }: any): any {
	try {
		const update: any = yield call(automaticPurchaseExportApi, payload)
		yield put(automaticPurchaseExportSuccess(update))
	} catch (error: any) {}
}

export function* getDepositsSaga() {
	yield takeEvery(GET_DEPOSITS, getDepositsRequest)
}

export function* getListCalculationFormulasSaga() {
	yield takeEvery(
		GET_LIST_CALCULATION_FORMULAS,
		getListCalculationFormulasRequest
	)
}

export function* getListOrderOCMultiSaga() {
	yield takeEvery(GET_LIST_ORDER_OC_MULTI, getListOrderOCMultiRequest)
}

export function* getListLaboratoriesSaga() {
	yield takeEvery(GET_LIST_LABORATORIES, getListLaboratoriesRequest)
}

export function* getListDrugsSaga() {
	yield takeEvery(GET_LIST_DRUGS, getListDrugsRequest)
}

export function* getListCostTypesSaga() {
	yield takeEvery(GET_LIST_COST_TYPE, getListCostTypesRequest)
}

export function* getListProvidersSaga() {
	yield takeEvery(GET_LIST_PROVIDERS, getListProvidersRequest)
}

export function* getPaymentTermsFilterListSaga() {
	yield takeEvery(
		GET_PAYMENT_TERMS_FILTER_LIST,
		getPaymentTermsFilterListRequest
	)
}

export function* automaticPurchaseFilterSaga() {
	yield takeEvery(AUTOMATIC_PURCHASE_FILTER, automaticPurchaseFilterRequest)
}

export function* automaticPurchaseChangeLineSaga() {
	yield takeEvery(
		AUTOMATIC_PURCHASE_CHANGE_LINE,
		automaticPurchaseChangeLineRequest
	)
}

export function* automaticPurchaseConfirmSaga() {
	yield takeEvery(AUTOMATIC_PURCHASE_CONFIRM, automaticPurchaseConfirmRequest)
}

export function* automaticPurchaseUpdateSaga() {
	yield takeEvery(AUTOMATIC_PURCHASE_UPDATE, automaticPurchaseUpdateRequest)
}

export function* automaticPurchaseExportSaga() {
	yield takeEvery(AUTOMATIC_PURCHASE_EXPORT, automaticPurchaseExportRequest)
}

export default function* rootSaga() {
	yield all([
		fork(getDepositsSaga),
		fork(getListCalculationFormulasSaga),
		fork(getListOrderOCMultiSaga),
		fork(getListLaboratoriesSaga),
		fork(getListDrugsSaga),
		fork(getListCostTypesSaga),
		fork(getListProvidersSaga),
		fork(getPaymentTermsFilterListSaga),
		fork(automaticPurchaseFilterSaga),
		fork(automaticPurchaseChangeLineSaga),
		fork(automaticPurchaseConfirmSaga),
		fork(automaticPurchaseUpdateSaga),
		fork(automaticPurchaseExportSaga)
	])
}
