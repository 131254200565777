import {
	crudGetCCAssignationsSuccess,
	crudUpdateCCAssignationsSuccess
} from 'actions'
import {
	crudGetCCAssignations,
	crudUpdateCCAssignations
} from 'api/DirectCCAssignationApi'
import {
	CRUD_GET_DIRECT_CC_ASSIGNATIONS,
	CRUD_UPDATE_DIRECT_CC_ASSIGNATIONS
} from 'constants/ActionsTypes'
import {
	ICrudGetCCAssignationsSaga,
	ICrudUpdateCCAssignationsSaga
} from 'models/CrudDirectCCAssignationInterface'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

function* crudGetCCAssignationsReq({
	payload
}: ICrudGetCCAssignationsSaga): any {
	try {
		const assignations: any = yield call(crudGetCCAssignations, payload)
		yield put(crudGetCCAssignationsSuccess(assignations))
	} catch (error) {}
}

function* crudUpdateCCAssignationsReq({
	payload
}: ICrudUpdateCCAssignationsSaga): any {
	try {
		const assignations: any = yield call(crudUpdateCCAssignations, payload)
		yield put(crudUpdateCCAssignationsSuccess(assignations))
	} catch (error) {}
}

export function* crudGetCCAssignationsSaga() {
	yield takeLatest(CRUD_GET_DIRECT_CC_ASSIGNATIONS, crudGetCCAssignationsReq)
}

export function* crudUpdateCCAssignationsSaga() {
	yield takeLatest(
		CRUD_UPDATE_DIRECT_CC_ASSIGNATIONS,
		crudUpdateCCAssignationsReq
	)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetCCAssignationsSaga),
		fork(crudUpdateCCAssignationsSaga)
	])
}
