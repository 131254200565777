import { getProductPriceList } from 'actions'
import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'underscore'
import ProductPriceForm from './ProductPriceForm'
import ProductPriceTable from './ProductPriceTable'
class ModalPriceUnit extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tablePrice: 0,
			resetPriceTable: false,
			callApi: true
		}
	}

	componentDidMount = () => {
		const { row, idOperacion } = this.props
		this.props.getProductPriceList({
			idOperacion,
			idProducto: row.niprod,
			precio: row.pcio_unit,
			periodo: 'D',
			dir_orden: 'A',
			unid_vta: row.cod_unid
		})
	}

	componentDidUpdate = (prevProps) => {
		const { salesPrice, price } = this.props

		/** Afectación de cantidades */
		this.validateChangePrice(prevProps.salesPrice, salesPrice)

		/** Carga de items */
		this.validateChangePrice(prevProps.price, price)
	}

	/**
	 * to validate change of price and call api
	 * @param {*} prevPrice
	 * @param {*} nextPrice
	 */
	validateChangePrice = (prevPrice, nextPrice) => {
		const { row, idOperacion } = this.props
		if (!_.isEqual(prevPrice, nextPrice) && !_.isEmpty(nextPrice)) {
			this.props.getProductPriceList({
				idOperacion,
				idProducto: row.niprod,
				precio: nextPrice.prod_pcio_vta,
				periodo: 'D',
				dir_orden: 'A'
			})
		}
	}

	handleChangeOrder = ({ order, sort }) => {
		const { row, idOperacion } = this.props

		this.props.getProductPriceList({
			idOperacion,
			idProducto: row.niprod,
			periodo: order,
			dir_orden: sort,
			price: row.pcio_unit
		})
	}

	/**
	 * to return price and clos popup
	 * @param {*} price
	 */
	handleSubmit = (price) => {
		const { handleSubmit } = this.props
		handleSubmit(price)
	}

	/**
	 * to get price of table
	 */
	getTablePrice = (price) => {
		this.setState({ tablePrice: price, resetPriceTable: false })
	}

	/**
	 * reset price lista table selected
	 */
	clearSelectedTable = () => {
		this.setState({ tablePrice: 0, resetPriceTable: true })
	}

	render() {
		const {
			showModal,
			handleClose,
			t,
			fieldsPriceEstTable,
			fieldsPriceTable,
			fieldsPriceForm
		} = this.props
		const { tablePrice, resetPriceTable } = this.state
		return (
			<Modal
				show={showModal}
				onHide={handleClose}
				aria-labelledby="ModalHeader"
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title id="ModalHeader">
						{t('products.product_price')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ProductPriceForm
						tablePrice={tablePrice}
						clearSelectedTable={this.clearSelectedTable}
						returnPriceValue={this.handleSubmit}
						fields={fieldsPriceForm}
						handleChangeOrder={this.handleChangeOrder}
					/>
					<ProductPriceTable
						getTablePrice={this.getTablePrice}
						resetPriceTable={resetPriceTable}
						fieldsPriceEstTable={fieldsPriceEstTable}
						fieldsPriceTable={fieldsPriceTable}
					/>
				</Modal.Body>
			</Modal>
		)
	}
}

const mapStateToProps = ({ salesAffected, product }) => {
	const { salesPrice } = salesAffected
	const { price } = product
	return { salesPrice, price }
}

const mapDispatchToProps = { getProductPriceList }

const connectForm = connect(mapStateToProps, mapDispatchToProps)(ModalPriceUnit)

export default connectForm
