import {
	CLEAR_TABLE_VALIDATIONS,
	GET_TABLE_IS_CUIT_REPEATED,
	GET_TABLE_IS_CUIT_REPEATED_SUCCESS,
	GET_TABLE_IS_DNI_REPEATED,
	GET_TABLE_IS_DNI_REPEATED_SUCCESS,
	GET_TABLE_IS_POSTAL_CODE_VALID,
	GET_TABLE_IS_POSTAL_CODE_VALID_SUCCESS,
	GET_TABLE_IS_TAXES_ID_VALID,
	GET_TABLE_IS_TAXES_ID_VALID_SUCCESS
} from 'constants/ActionsTypes'

const initialState = {
	isPostalCodeValid: {},
	isTaxesIdValid: {},
	isDNIRepeated: {},
	isCUITRepeated: {}
}

export interface valueState {
	isPostalCodeValid?: any
	isTaxesIdValid?: any
	isDNIRepeated?: any
	isCUITRepeated?: any
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case GET_TABLE_IS_POSTAL_CODE_VALID:
			return { ...state, isPostalCodeValid: null }
		case GET_TABLE_IS_POSTAL_CODE_VALID_SUCCESS:
			return { ...state, isPostalCodeValid: action.payload }
		case GET_TABLE_IS_TAXES_ID_VALID:
			return { ...state, isTaxesIdValid: null }
		case GET_TABLE_IS_TAXES_ID_VALID_SUCCESS:
			return { ...state, isTaxesIdValid: action.payload.Resultado }
		case GET_TABLE_IS_DNI_REPEATED:
			return { ...state, isDNIRepeated: null }
		case GET_TABLE_IS_DNI_REPEATED_SUCCESS:
			return { ...state, isDNIRepeated: action.payload }
		case GET_TABLE_IS_CUIT_REPEATED:
			return { ...state, isCUITRepeated: null }
		case GET_TABLE_IS_CUIT_REPEATED_SUCCESS:
			return { ...state, isCUITRepeated: action.payload }
		case CLEAR_TABLE_VALIDATIONS:
			return {
				...state,
				isPostalCodeValid: null,
				isTaxesIdValid: null,
				isCUITRepeated: null,
				isDNIRepeated: null
			}
		default:
			return state
	}
}

export default rootReducer
