import {
	CLEAR_SALES_HEADER_FILTERS,
	CONFIRM_SALES_HEADERS,
	CONFIRM_SALES_HEADERS_ERROR,
	CONFIRM_SALES_HEADERS_SUCCESS,
	GET_SALES_HEADERS,
	GET_SALES_HEADERS_SUCCESS,
	SET_SALES_HEADER_FILTERS,
	VALIDATE_BARCODE_SALES_HEADERS,
	VALIDATE_BARCODE_SALES_HEADERS_SUCCESS
} from 'constants/ActionsTypes'
import { IGetSalesHeadersParams } from 'models/Budget'

export interface valueState {
	salesHeaders: any
	confirmHeaders: any
	messageError: string
	barCodeResponse: any
	confirmParams: any
	headerFilters: IGetSalesHeadersParams
}

const initialState = {
	salesHeaders: null,
	confirmHeaders: null,
	messageError: '',
	barCodeResponse: null,
	confirmParams: null,
	headerFilters: {
		idOperacion: '',
		page_number: 1,
		page_size: 10,
		fec_desde: '',
		fec_hasta: '',
		suc_emp: '',
		vendedor: '',
		transporte: '',
		cond_vta: '',
		orden: '',
		sentido: '',
		rsoc: ''
	}
}

function rootReducer(state: valueState = initialState, action: any) {
	switch (action.type) {
		case GET_SALES_HEADERS:
			return { ...state, salesHeaders: null }
		case GET_SALES_HEADERS_SUCCESS:
			return { ...state, salesHeaders: action.payload }
		case CONFIRM_SALES_HEADERS:
			return {
				...state,
				confirmHeaders: null,
				messageError: '',
				confirmParams: action.payload
			}
		case CONFIRM_SALES_HEADERS_SUCCESS:
			return { ...state, confirmHeaders: action.payload, confirmParams: null }
		case CONFIRM_SALES_HEADERS_ERROR:
			return { ...state, messageError: action.payload }
		case VALIDATE_BARCODE_SALES_HEADERS:
			return { ...state, barCodeResponse: null, messageError: '' }
		case VALIDATE_BARCODE_SALES_HEADERS_SUCCESS:
			return { ...state, barCodeResponse: action.payload }
		case SET_SALES_HEADER_FILTERS:
			return { ...state, headerFilters: action.payload }
		case CLEAR_SALES_HEADER_FILTERS:
			return {
				...state,
				headerFilters: {
					idOperacion: '',
					page_number: 1,
					page_size: 10,
					fec_desde: '',
					fec_hasta: '',
					suc_emp: '',
					vendedor: '',
					transporte: '',
					cond_vta: '',
					orden: '',
					sentido: '',
					rsoc: ''
				}
			}
		default:
			return state
	}
}

export default rootReducer
