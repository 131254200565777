import { ICrudGenericFields } from 'models/CrudInterface'

export const pointersFields: Array<ICrudGenericFields> = [
	{
		id: 'arr_saldo',
		type: 'check',
		width: 'col-sm-6',
		height: '',
		default: 0,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'actualizable',
		type: 'check',
		width: 'col-sm-6',
		height: '',
		default: 0,
		maxChars: 99,
		autoComplete: 'new-off',
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	}
]
