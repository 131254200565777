import Axios from 'axios'

export const getLoadItems = async (params) => {
	const response = await Axios.get('/carga_item_vta/consulta', { params })
	return response.data
}

export const confirmLoadItemsApi = async (params) => {
	const response = await Axios.post('/carga_item_vta', params)
	return response.data
}

export const confirmTableItems = async (params) => {
	const response = await Axios.post('/carga_item_vta/confirmar', params)
	return response.data
}

export const itemProviderAddToCartApi = async (params) => {
	const response = await Axios.post('/carga_item_cpa', params)
	return response.data
}

export const getCartItemInfo = async (params) => {
	const response = await Axios.get('/carga_item_vta/modificar', { params })
	return response.data
}

export const updateCartItem = async (params) => {
	const response = await Axios.post(
		'/carga_item_vta/modificar/confirmar',
		params
	)
	return response.data
}

export const confirmIndLoadItems = async (params) => {
	const response = await Axios.post('/cargaindiv_vta/confirmar', params)
	return response.data
}

export const getProviderCartItemInfo = async (params) => {
	const response = await Axios.get('/carga_item_cpa/modificar', { params })
	return response.data
}

export const updateProviderCartItem = async (params) => {
	const response = await Axios.post(
		'/carga_item_cpa/modificar/confirmar',
		params
	)
	return response.data
}

export const confirmProvIndLoadItems = async (params) => {
	const response = await Axios.post('/cargaindiv_cpa/confirmar', params)
	return response.data
}

export const confirmProvLoadItemsApi = async (params) => {
	const response = await Axios.post('/carga_item_cpa/confirmar', params)
	return response.data
}
