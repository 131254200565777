import { faPercent, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CollapseButton from 'components/common/collapseButton'
import CommonTable from 'components/common/commonTable'
import ConfirmModal from 'components/common/ConfirmModal'
import DisplayLight from 'components/common/displayLight'
import PopupImage from 'components/common/popupImage'
import { getValueMask } from 'lib/MaskValues'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { selectFilter } from 'react-bootstrap-table2-filter'
import { themr } from 'react-css-themr'
import styles from './shoppingCart.module.css'

class ShoppingCartTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			filterVal: null,
			tableColumns: this.getColumns()
		}
	}

	/**
	 * to get all table's columns
	 * @returns array
	 */
	getColumns = () => {
		const { config, t } = this.props
		const rows = config.campos.map((field) => {
			const campoId = field.idCampo.trim()
			return {
				dataField: campoId,
				text: field.label ? field.label : '',
				align: 'center',
				headerAlign: 'center',
				headerStyle: this.getStyleColumn(field),
				hidden: !field.visible,
				title:
					campoId === 'avisos'
						? (cell, row) => {
								let title = ''
								if (row.Bonificaciones && row.Bonificaciones.length) {
									row.Bonificaciones.forEach((bonif) => {
										title = `${title} ${bonif.desc_bonif}`
									})
								}

								return title
						  }
						: null,

				filter:
					campoId === 'avisos'
						? selectFilter({
								options: this.getOptionsDeal(),
								className: `mt-2`,
								placeholder: 'Oferta',
								onFilter: (filterVal) => this.setState({ filterVal })
						  })
						: null,

				filterValue: (cell, row) => {
					const filter = []
					row.Bonificaciones.forEach((bonif) => {
						if (bonif.cod_bonif === this.state.filterVal) {
							filter.push(bonif.cod_bonif)
						}
					})

					return filter
				},
				formatter: (cell, row, rowIndex) => {
					return this.renderFormat(field, cell, row)
				}
			}
		})

		rows.push({
			dataField: 'actions',
			text: '',
			align: 'center',
			headerAlign: 'center',
			headerStyle: { width: '5%' },
			formatter: (cell, row, rowIndex) => {
				return (
					<ConfirmModal
						messageBody={t('shoppingCart.modal.body')}
						onSubmitModal={() => this.handleRemoveCart(row)}
						labelButton={<FontAwesomeIcon icon={faTrash} />}
						modalTitle={t('shoppingCart.modal.title')}
						typeButton={'light'}
					/>
				)
			}
		})

		return [
			{
				dataField: 'id_imagen',
				text: '',
				align: 'rigth',
				headerStyle: { width: '2%' },
				formatter: (cell, row, rowIndex) => {
					return <PopupImage product={row} />
				}
			}
		].concat(rows)
	}

	/**
	 * to remove product of cart
	 * @param {object} row
	 */
	handleRemoveCart = (row) => {
		const { idOperacion, cartProducts } = this.props
		this.props.removeProductCart({
			idOperacion,
			nitem: row.nitem,
			page: cartProducts.page_number,
			page_size: cartProducts.page_size
		})
	}

	/**
	 * to get header styles
	 * @param field name of column
	 */

	getStyleColumn = (field) => {
		const idField = field.idCampo.trim()

		let style = {}

		switch (idField) {
			case 'desc_prod':
				style = { width: '25%' }
				break
			case 'fec_entrega':
				style = { width: '13%' }
				break
			case 'avisos':
				style = { width: '10%' }
				break
			case 'ind_stock':
				style = { width: '1%' }
				break
			case 'precio_unit':
				style = { width: '10%' }
				break
			case 'modif_pcio':
				style = { width: '1%' }
				break

			case 'neto':
				style = { width: '10%' }
				break
			default:
				style = { width: '10%' }
				break
		}

		return style
	}

	/**
	 * to get array of options
	 * @returns array string
	 */
	getOptionsDeal = () => {
		const { cartProducts } = this.props
		const result = {}
		if (cartProducts.bonificaciones) {
			cartProducts.bonificaciones.forEach((bonif) => {
				result[bonif.cod_bonif] = bonif.desc_bonif
			})
		}
		return result
	}

	/**
	 * to render format in the table
	 * @param {string} field
	 * @param {string} value
	 * @param {string} row
	 * @returns  Array
	 */
	renderFormat = (field, value, row) => {
		const { authUser } = this.props

		let result = null
		const campoId = field.idCampo.trim()
		if (campoId === 'avisos') {
			result =
				row.Bonificaciones && row.Bonificaciones.length ? (
					<FontAwesomeIcon icon={faPercent} />
				) : null
		} else if (campoId === 'ind_stock') {
			result = <DisplayLight semaforo={value} />
		} else if (field.mascara) {
			result = getValueMask(value, field.mascara, { authUser })
		} else {
			result = value
		}
		return result
	}

	/**
	 * to render expando row.
	 * @param {object} row
	 * @returns array
	 */
	renderExpandRow = (row) => {
		const cols = []
		let result
		if (row.atributos && row.atributos.length) {
			row.atributos.forEach((atrb, index) => {
				cols.push(
					<Col key={index} className={'col-6 p-2'}>
						<b>{`${atrb.desc_atrib}:`}</b> {atrb.desc_dato}
					</Col>
				)
			})
			result = <Row className={'container mt-2'}>{cols}</Row>
		}

		return result
	}

	/**
	 * to get id of rows no expanded
	 * @returns array
	 */
	getNoexpandRows = () => {
		const { search } = this.props
		const result = []
		if (search && search.productos) {
			search.productos.forEach((prd) => {
				if (!prd.atributos || prd.atributos.length === 0) {
					result.push(parseInt(prd.niprod))
				}
			})
		}

		return result
	}

	/**
	 * to validate which rows can be expanded
	 * @param {string} key
	 * @param {array} rows
	 * @returns array boolean
	 */
	validateKey = (key, rows) => {
		let result = false
		rows.forEach((row) => {
			if (row === key) {
				result = true
			}
		})
		return result
	}

	/**
	 * to get expand options
	 * @returns expand options
	 */
	getOptionsExpandRow = () => {
		const noExpand = this.getNoexpandRows()

		return {
			renderer: (row) => this.renderExpandRow(row),
			showExpandColumn: true,
			nonExpandable: noExpand,
			expandByColumnOnly: true,
			expandHeaderColumnRenderer: ({ isAnyExpands }) => {
				return <CollapseButton status={isAnyExpands} />
			},
			expandColumnRenderer: ({ expanded, rowKey }) => {
				if (!this.validateKey(rowKey, noExpand)) {
					return <CollapseButton status={expanded} />
				}
			}
		}
	}

	/**
	 * to get all pagination options
	 * @returns pagination options
	 */
	getPaginationOptions = () => {
		const { cartProducts } = this.props

		return cartProducts.productos
			? {
					page: cartProducts.page_number,
					sizePerPage: cartProducts.page_size,
					totalSize: cartProducts.total_count
			  }
			: {}
	}

	render() {
		const { cartProducts, theme } = this.props
		const { tableColumns } = this.state

		return (
			<Col className={`col-12 ${theme.divContainer} `}>
				<CommonTable
					remote
					columns={tableColumns}
					keyField={'nitem'}
					data={cartProducts.productos || []}
					rowClasses={theme.tableRow}
					headerClasses={theme.tableHeader}
					expandRow={this.getOptionsExpandRow()}
					paginationOptions={this.getPaginationOptions()}
					onTableChange={this.props.handleChangeTable}
				/>
			</Col>
		)
	}
}

export default themr('ShoppingCartTableStyles', styles)(ShoppingCartTable)
