import React, { Component, forwardRef, Ref } from 'react'
import successSound from 'sounds/38799107_correct-answer-double-02.mp3'
import errorSound from 'sounds/38806364_wrong-answer-notification-03.mp3'

interface ICommonAudioProps {}

class CommonAudio extends Component<ICommonAudioProps> {
	handleSuccess() {
		const audioEl: HTMLAudioElement = document.getElementById(
			'audio-element-success'
		) as HTMLAudioElement
		audioEl.play()
	}

	handleError() {
		const audioEl: HTMLAudioElement = document.getElementById(
			'audio-element-error'
		) as HTMLAudioElement
		audioEl.play()
	}

	render() {
		return (
			<div>
				<audio id="audio-element-success">
					<source src={successSound} />
					<track default kind="captions" />
				</audio>
				<audio id="audio-element-error">
					<source src={errorSound} />
					<track default kind="captions" />
				</audio>
			</div>
		)
	}
}

export default forwardRef(function CommonAudioFormWrapper(
	props: ICommonAudioProps,
	ref: Ref<CommonAudio>
) {
	return <CommonAudio {...props} ref={ref} />
})
