import { UploadFilesActions } from 'constants/ActionsTypesTs'
import { IResponseErrorApi } from 'models/CommonInterface'
import {
	IDownloadFileAction,
	IDownloadFileActionParams,
	IDownloadFileSuccessAction,
	IDownloadFileSuccessActionResponse,
	IGetDocumentsAction,
	IGetDocumentsParams,
	IGetDocumentsSuccessAction,
	IGetDocumentsSuccessResponse,
	IGetTempDocumentsAction,
	IGetTempDocumentsParams,
	IGetTempDocumentsSuccessAction,
	IGetTempDocumentsSuccessResponse,
	IRemoveDocumentAction,
	IRemoveDocumentParams,
	IRemoveDocumentSuccessAction,
	IRemoveDocumentSuccessResponse,
	IRemoveTempDocumentAction,
	IRemoveTempDocumentParams,
	IRemoveTempDocumentSuccessAction,
	IRemoveTempDocumentSuccessResponse,
	IUploadFilesAction,
	IUploadFilesParams,
	IUploadFilesSuccessAction,
	IUploadFilesSuccessResponse
} from 'models/FileManager'

export const uploadFiles = (
	payload: IUploadFilesParams
): IUploadFilesAction => {
	return {
		type: UploadFilesActions.UPLOAD_FILES,
		payload
	}
}

export const uploadFilesSuccess = (
	payload: IUploadFilesSuccessResponse
): IUploadFilesSuccessAction => {
	return {
		type: UploadFilesActions.UPLOAD_FILES_SUCCESS,
		payload
	}
}

export const uploadFilesError = (payload: IResponseErrorApi) => ({
	type: UploadFilesActions.UPLOAD_FILES_ERROR,
	payload
})

export const getTempDocumentList = (
	payload: IGetTempDocumentsParams
): IGetTempDocumentsAction => ({
	payload,
	type: UploadFilesActions.GET_TEMP_DOCUMENT_LIST
})

export const getTempDocumentListSuccess = (
	response: IGetTempDocumentsSuccessResponse
): IGetTempDocumentsSuccessAction => ({
	payload: response,
	type: UploadFilesActions.GET_TEMP_DOCUMENT_LIST_SUCCESS
})

export const removeTempDocument = (
	payload: IRemoveTempDocumentParams
): IRemoveTempDocumentAction => ({
	payload,
	type: UploadFilesActions.REMOVE_TEMP_DOCUMENT
})

export const removeTempDocumentSuccess = (
	response: IRemoveTempDocumentSuccessResponse
): IRemoveTempDocumentSuccessAction => ({
	payload: response,
	type: UploadFilesActions.REMOVE_TEMP_DOCUMENT_SUCCESS
})

export const getDocumentList = (
	payload: IGetDocumentsParams
): IGetDocumentsAction => ({
	payload,
	type: UploadFilesActions.GET_DOCUMENT_LIST
})

export const getDocumentListSuccess = (
	response: IGetDocumentsSuccessResponse
): IGetDocumentsSuccessAction => ({
	payload: response,
	type: UploadFilesActions.GET_DOCUMENT_LIST_SUCCESS
})

export const removeDocument = (
	payload: IRemoveDocumentParams
): IRemoveDocumentAction => ({
	payload,
	type: UploadFilesActions.REMOVE_DOCUMENT
})

export const removeDocumentSuccess = (
	response: IRemoveDocumentSuccessResponse
): IRemoveDocumentSuccessAction => ({
	payload: response,
	type: UploadFilesActions.REMOVE_DOCUMENT_SUCCESS
})

export const downloadFile = (
	payload: IDownloadFileActionParams
): IDownloadFileAction => {
	return {
		type: UploadFilesActions.DOWNLOAD_FILE,
		payload
	}
}

export const downloadFilesSuccess = (
	payload: IDownloadFileSuccessActionResponse
): IDownloadFileSuccessAction => {
	return {
		type: UploadFilesActions.DOWNLOAD_FILE_SUCCESS,
		payload
	}
}
