import {
	clearTableValidations,
	crudsClearStore,
	crudsClone,
	crudsCreate,
	crudsGetSingle,
	showMessage
} from 'actions'
import CommonTabs from 'components/abmUtils/CommonTabs'
import ConfirmModal from 'components/common/ConfirmModal'
import withMenu from 'components/common/withMenu'
import ValuesCrudTable from 'components/genericCrud/ValuesCrudTable'
import CrudsContainer from 'components/layout/CrudsContainer'
import { IAbmStructureResponse } from 'constants/valuesInterfaces/interfaces'
import { ICrudsApi, ICrudsTypeList } from 'models/CrudInterface'
import { ICrudGetVoucherConfigRes } from 'models/CrudVoucherConfigInterface'
import { Ihistory } from 'models/NavigationInterface'
import React, { Component } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CRUD, CRUD_EDIT, CRUD_VOUCHER_CONFIG_DATA } from 'utils/RoutePath'
import FieldsCrudTable from './AdditionalTabs/FieldsCrudTable'
import FieldsDataForm from './AdditionalTabs/FieldsDataForm'
import ProcessDataForm from './AdditionalTabs/ProcessDataForm'
import CrudVoucherConfig from './CrudVoucherConfig'

type CustomerDataContentProps = {
	crudCode: number
	op: string
	selectedAbm: ICrudsTypeList
	crudsGetSingle: (params: ICrudsApi) => void
	crudsCreate: (params: ICrudsApi) => void
	crudsClone: (params: ICrudsApi) => void
	crudsClearStore: () => void
	clearTableValidations: () => void
	valueDataSelected: ICrudGetVoucherConfigRes
	valueUpdated: any
	showMessage: any
	history: Ihistory
	fieldsAltaConfig: IAbmStructureResponse
	fieldsConfig: IAbmStructureResponse
	disableForm: boolean
	valueDataUpdated: any
	t: any
}

type CustomerDataContentState = {
	crudCode: number | string | null
	info: boolean
	tabActive: string
	showWarningPopup: boolean
	formChanged: boolean
	nextTab: any
	nextAction: string
}

const customerTabs = [
	{
		key: 'basic',
		label: 'CRUDS.general_use.basic_tab',
		type: 'basic'
	},
	{
		key: 'proc',
		label: 'CRUDS.general_use.process_tab',
		type: 'process'
	},
	{
		key: 'campo',
		label: 'CRUDS.general_use.fields_tab',
		type: 'fields'
	}
]

class CustomerDataContent extends Component<
	CustomerDataContentProps,
	CustomerDataContentState
> {
	constructor(props: CustomerDataContentProps) {
		super(props)
		this.state = {
			crudCode: this.props.crudCode,
			info: false,
			tabActive: 'basic',
			showWarningPopup: false,
			formChanged: false,
			nextTab: 'basic',
			nextAction: ''
		}
	}

	// setups initial store state for each action respectively
	componentDidMount() {
		const { op, crudsClearStore } = this.props

		if (op === 'add') {
			crudsClearStore()
		}
	}

	// Handles changes and sets form changed state to false if there was a submit
	componentDidUpdate = (prevProps: CustomerDataContentProps) => {
		const {
			valueDataSelected,
			crudCode,
			crudsGetSingle,
			valueDataUpdated,
			selectedAbm,
			valueUpdated,
			op,
			showMessage
		} = this.props
		const {
			valueDataUpdated: prevValueDataUpdated,
			valueDataSelected: prevValueDataSelected,
			valueUpdated: prevValueUpdated
		} = prevProps

		if (prevValueDataSelected !== valueDataSelected && valueDataSelected) {
			let info = false
			if (op === 'view' || op === 'edit') {
				info = true
			} else if (op === 'add') {
				info = false
			}

			this.setState({ info })
		}

		if (valueUpdated !== prevValueUpdated && valueUpdated) {
			showMessage({
				type: 'success',
				description: 'Se actualizo correctamente',
				showError: true
			})
			this.setState({ formChanged: false })
		}

		if (valueDataUpdated !== prevValueDataUpdated && valueDataUpdated) {
			crudsGetSingle({
				crudName: selectedAbm.id,
				urlApi: selectedAbm.urlApi,
				action: 'consulta',
				params: { [selectedAbm.key]: crudCode }
			})
			this.setState({ formChanged: false })
		}
	}

	// Cancel button function
	handleRedirectHome = () => {
		this.props.history.push(`${CRUD}/${CRUD_VOUCHER_CONFIG_DATA}`)
	}

	/**
	 * to call api to create new abm
	 * @param {object} value
	 */

	handleNewCrud = (values: any) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 1 }
		})
	}

	/**
	 * to call api to edit abm
	 * @param {object} value
	 */

	handleEditCrud = (values: any) => {
		const { crudsCreate, selectedAbm } = this.props
		crudsCreate({
			crudName: selectedAbm.id,
			urlApi: selectedAbm.urlApi,
			action: 'confirmar',
			params: { ...values, alta: 0 }
		})
	}

	// Set's current tab in state
	handleActiveKey = (tabActive: string) => {
		this.setState({ tabActive })
	}

	// Handles the Crud change arrows
	handleCrudChange = (param: any) => {
		const { history } = this.props
		history.go(`${CRUD_EDIT}/${CRUD_VOUCHER_CONFIG_DATA}/${param.nioperacion}`)
	}

	// Handles the changes warning state
	handleWarningPopup = (value: boolean, action: string) => {
		const { tabActive } = this.state
		this.setState({ showWarningPopup: value })
		action === 'close' && this.setState({ nextTab: tabActive, nextAction: '' })
		action === 'cancel' && this.setState({ formChanged: false })
	}

	// Handles the changes state, used in detecting changes
	handleSetFormChanged = (formChanged: boolean) => {
		this.setState({ formChanged })
	}

	// Stores the value of the clicked tab in the component state
	handleSetNextTab = (nextTab: string) => {
		this.setState({ nextTab })
	}

	// Stores the value of the clicked arrow in the component state
	handleSetNextAction = (nextAction: string) => {
		this.setState({ nextAction })
	}

	handleCloneCrud = () => {
		const { valueDataSelected, crudsClone, selectedAbm } = this.props

		valueDataSelected &&
			crudsClone({
				crudName: selectedAbm.id,
				urlApi: selectedAbm.urlApi,
				action: 'clonar',
				params: { id: selectedAbm.key, param: valueDataSelected.nioperacion }
			})
	}

	render() {
		const {
			t,
			fieldsAltaConfig,
			valueDataSelected,
			disableForm,
			op,
			crudCode,
			fieldsConfig,
			selectedAbm
		} = this.props

		const { tabActive, showWarningPopup, formChanged, nextTab, nextAction } =
			this.state

		const fields = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('principal')
			  })
			: []
		const processDataValuesFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.proc')
			  })
			: []
		const processDataValuesFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('proc')
			  })
			: []
		const fieldsSearchValuesFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('formulario.campo')
			  })
			: []
		const fieldsDataValuesFields = fieldsConfig
			? fieldsConfig.campos.filter((field: any) => {
					return field.agrupador.includes('grupo.campo')
			  })
			: []
		const fieldsDataValuesFieldsAlta = fieldsAltaConfig
			? fieldsAltaConfig.campos.filter((field: any) => {
					return field.agrupador.includes('campo')
			  })
			: []

		const tabProps = {
			basic: {
				disableForm: disableForm,
				itemInfo: valueDataSelected,
				fields: fields,
				showMessage: this.props.showMessage,
				handleSubmit: op === 'add' ? this.handleNewCrud : this.handleEditCrud,
				method: op,
				handleCloseModal: this.handleRedirectHome,
				showWarning: showWarningPopup,
				handleWarning: this.handleWarningPopup,
				setFormChanged: this.handleSetFormChanged
			},
			process_data: {
				catributo: crudCode,
				urlApi: 'abm_comprob_procesos',
				tabId: 'nioperacion',
				valueId: 'd_proceso',
				crudId: selectedAbm.id,
				fields: processDataValuesFields,
				fieldsAlta: processDataValuesFieldsAlta,
				Component: ProcessDataForm,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'proc')?.tipo_perm ===
						'L' ||
					false,
				t: t
			},
			fields_data: {
				catributo: crudCode,
				urlApi: 'abm_comprob_campos',
				tabId: 'nioperacion',
				valueId: 'campo',
				crudId: selectedAbm.id,
				fields: fieldsDataValuesFields,
				fieldsSearch: fieldsSearchValuesFields,
				fieldsAlta: fieldsDataValuesFieldsAlta,
				Component: FieldsDataForm,
				disableForm:
					disableForm ||
					fieldsConfig.tabs.find((tab) => tab.cod_tab === 'campo')
						?.tipo_perm === 'L' ||
					false,
				t: t
			}
		}
		const propsCrudContainer = {
			urlApi: 'abm_comprob',
			getNewData: this.handleCrudChange,
			keyApi: 'nioperacion',
			title: t('global.voucher'),
			handleExitAbm: this.handleRedirectHome,
			showWarning: formChanged,
			handleWarning: this.handleWarningPopup,
			nextAction: nextAction,
			setNextAction: this.handleSetNextAction,
			op: op
		}

		const componentTabs = Array.from(customerTabs).map((tab) => {
			const selectedTab: any =
				tab.type === 'basic'
					? {
							key: tab.key,
							title: t(tab.label),
							content:
								tabActive === tab.key ? (
									<CrudVoucherConfig {...tabProps.basic} />
								) : (
									<div />
								)
					  }
					: tab.type === 'process'
					? {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<ValuesCrudTable {...tabProps.process_data} />
								) : (
									<div />
								)
					  }
					: tab.type === 'fields' && {
							key: tab.key,
							title: t(tab.label),
							disabled:
								op === 'add' ||
								fieldsConfig.tabs.find(
									(tabResult) => tabResult.cod_tab === tab.key
								)?.tipo_perm === 'N',
							content:
								tabActive === tab.key ? (
									<FieldsCrudTable {...tabProps.fields_data} />
								) : (
									<div />
								)
					  }
			return selectedTab
		})

		return (
			<CrudsContainer {...propsCrudContainer}>
				<Row className={'pt-4'}>
					<Col>
						<Card.Title>
							{disableForm
								? t('voucher_data.title_view')
								: op === 'edit'
								? t('voucher_data.title_edit')
								: t('voucher_data.title_new')}
						</Card.Title>
					</Col>
				</Row>
				{valueDataSelected && (
					<>
						<Row>
							<Col sm={2}>
								<strong>{t('global.internal_code')}</strong>
							</Col>
							<Col className={'text-left'}>
								{valueDataSelected && valueDataSelected.nioperacion}
							</Col>
						</Row>
						<Row className={'pb-2'}>
							<Col sm={2}>
								<strong>{t('global.name')}</strong>
							</Col>
							<Col className={'text-left'}>
								{valueDataSelected && valueDataSelected.nombre}
							</Col>
						</Row>
						<Row className={'pb-2'}>
							<Col sm={2} className={'text-center mt-2'}>
								<ConfirmModal
									messageBody={t('CRUDS.vouchers.clone.body')}
									onSubmitModal={() => this.handleCloneCrud()}
									labelButton={t('global.clone')}
									modalTitle={t('CRUDS.vouchers.clone.title')}
									buttonStyle={{
										minWidth: '145px'
									}}
								/>
							</Col>
						</Row>
					</>
				)}
				<CommonTabs
					customTabs={componentTabs}
					defaultActiveKey={tabActive}
					getActiveKey={this.handleActiveKey}
					showWarning={formChanged}
					handleWarning={this.handleWarningPopup}
					nextTab={nextTab}
					setNextTab={this.handleSetNextTab}
				/>
			</CrudsContainer>
		)
	}
}

const mapStateToProps = ({ configCrud, customerObservation }: any) => {
	const {
		fieldsConfig,
		fieldsAltaConfig,
		valueDataSelected,
		valueDataUpdated
	} = configCrud
	const { observationInfo, valueUpdated } = customerObservation
	return {
		fieldsConfig,
		fieldsAltaConfig,
		valueDataSelected,
		valueDataUpdated,
		observationInfo,
		valueUpdated
	}
}

const connectForm = connect(mapStateToProps, {
	crudsGetSingle,
	crudsCreate,
	crudsClearStore,
	showMessage,
	crudsClone,
	clearTableValidations
})(CustomerDataContent)

export default withTranslation()(withMenu(withRouter(connectForm)))
