import Axios from 'axios'
import { IAddPendingVoucherParams, IVoucherItem } from 'models/Landing'
const urlBase = '/pendientes/usuario'

export const getPendingVouchersApi = async (params?: IVoucherItem[]) => {
	const response = await Axios.post(urlBase, params)
	return response.data
}

export const addConfigPendingVouchersApi = async (
	params: IAddPendingVoucherParams
) => {
	const response = await Axios.post(`${urlBase}/agregar`, params)
	return response.data
}

export const removeConfigPendingVouchersApi = async (
	params: IAddPendingVoucherParams
) => {
	const response = await Axios.post(`${urlBase}/eliminar`, params)
	return response.data
}
