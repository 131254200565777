import React from 'react'

interface IFormContext {
	formRef: React.RefObject<any>
	cancelButtonRef: React.RefObject<any>
	searchButtonRef: React.RefObject<any>
}

const defaultState = {
	formRef: React.createRef(),
	cancelButtonRef: React.createRef(),
	searchButtonRef: React.createRef()
}

export const FormRefsContext = React.createContext<IFormContext>(defaultState)
