// TODO jfarina group them by module/UC/whatever to simplify implementation of a shortcuts configuration
export const SEARCH_TEST_PRODUCTS = 'SEARCH_TEST_PRODUCTS '
export const SEARCH_TEST_PRODUCTS_SUCCESS = 'SEARCH_TEST_PRODUCTS_SUCCESS'
export const SET_DATA_TEST_PRODUCTS = 'SET_DATA_TEST_PRODUCTS'
export const SET_DATA_TEST_PRODUCTS_SUCCESS = 'SET_DATA_TEST_PRODUCTS_SUCCESS'
export const GET_TEST_PRICE_PRODUCT = 'GET_TEST_PRICE_PRODUCT'
export const GET_TEST_PRICE_PRODUCT_SUCCESS = 'GET_TEST_PRICE_PRODUCT_SUCCESS'
//
export const ERROR_IMAGE = 'ERROR_IMAGEN'
export const ERROR_GET_IMPDOCS = 'ERROR_GET_IMPDOCS'

// Auth
export const SIGNIN_USER = 'signin_user'
export const SIGNIN_USER_SUCCESS = 'signin_user_success'
export const SIGNOUT_USER = 'signout_user'
export const SIGNOUT_USER_SUCCESS = 'signout_user_success'
export const GET_USER = 'get_user'
export const GET_USER_SUCCESS = 'get_user_success'
export const GET_PERMISSIONS = 'get_permissions'
export const GET_PERMISSIONS_SUCCESS = 'get_permissions_success'
export const SELECT_COMPANY = 'SELECT_COMPANY'
export const SELECT_COMPANY_SUCCESS = 'SELECT_COMPANY_SUCCESS'
export const SET_COMPANY_ERROR = 'SET_COMPANY_ERROR'
export const SET_COMPANY_ERROR_SUCCESS = 'SET_COMPANY_ERROR_SUCCESS'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'

// Common
export const SHOW_LOADER = 'show_loader'
export const HIDE_LOADER = 'hide_loader'
export const SHOW_ERROR = 'show_error'
export const CLEAR_ERROR = 'clear_error'
export const SHOW_MESSAGE = 'show_message'
export const CLEAR_MESSAGE = 'clear_message'

// Client
export const GET_CLIENT = 'GET_CLIENT'
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS'
export const SEARCH_CLIENTS = 'SEARCH_CLIENTS'
export const SEARCH_CLIENTS_SUCCESS = 'SEARCH_CLIENTS_SUCCESS'
export const CONFIRMATION_CLIENT = 'CONFIRMATION_CLIENT'
export const CONFIRMATION_CLIENT_SUCCESS = 'CONFIRMATION_CLIENT_SUCCESS'

// Voucher type
export const GET_VOUCHER_TYPE = 'GET_VOUCHER_TYPE'
export const GET_VOUCHER_TYPE_SUCCESS = 'GET_VOUCHER_TYPE_SUCCESS'
export const GET_VOUCHER_TYPE_BY_USER = 'GET_VOUCHER_TYPE_BY_USER'
export const GET_VOUCHER_TYPE_BY_USER_SUCCESS =
	'GET_VOUCHER_TYPE_BY_USER_SUCCESS'
export const VOUCHER_SAVE_AND_NEW = 'VOUCHER_SAVE_AND_NEW'
export const VOUCHER_SAVE_AND_NEW_SUCCESS = 'VOUCHER_SAVE_AND_NEW_SUCCESS'
export const SAVE_CONFIG_STYLE_VOUCHER_TYPE_BY_USER =
	'SAVE_CONFIG_STYLE_VOUCHER_TYPE_BY_USER'
export const SAVE_CONFIG_STYLE_VOUCHER_TYPE_BY_USER_SUCCESS =
	'SAVE_CONFIG_STYLE_VOUCHER_TYPE_SUCCESS'
export const GET_AFFECTATION_VOUCHER_BY_USER = 'GET_AFFECTATION_VOUCHER_BY_USER'
export const GET_AFFECTATION_VOUCHER_BY_USER_SUCCESS =
	'GET_AFFECTATION_VOUCHER_BY_USER_SUCCESS'
export const GET_VOUCHER_NUMBER = 'GET_VOUCHER_NUMBER,'
export const GET_VOUCHER_NUMBER_SUCCESS = 'GET_VOUCHER_NUMBER_SUCCESS'
export const CANCEL_VOUCHER_NUMBER = 'CANCEL_VOUCHER_NUMBER'
export const CANCEL_VOUCHER_NUMBER_SUCCESS = 'CANCEL_VOUCHER_NUMBER_SUCCESS'

// Voucher
export const GET_CONFIG_VOUCHER = 'GET_CONFIG_VOUCHER'
export const GET_CONFIG_VOUCHER_SUCCESS = 'GET_CONFIG_VOUCHER_SUCCESS'
export const GET_VOUCHER_HEAD = 'GET_VOUCHER_HEAD'
export const GET_VOUCHER_HEAD_SUCCESS = 'GET_VOUCHER_HEAD_SUCCESS'
export const VOUCHER_HEAD_AUTO = 'VOUCHER_HEAD_AUTO'
export const VOUCHER_HEAD_AUTO_SUCCESS = 'VOUCHER_HEAD_AUTO_SUCCESS'
export const VOUCHER_HEAD_VALIDATE_KEY = 'VOUCHER_HEAD_VALIDATE_KEY'
export const VOUCHER_HEAD_VALIDATE_KEY_SUCCESS =
	'VOUCHER_HEAD_VALIDATE_KEY_SUCCESS'
export const VOUCHER_HEAD_CHECK_DATE = 'VOUCHER_HEAD_CHECK_DATE'
export const VOUCHER_HEAD_CHECK_DATE_SUCCESS = 'VOUCHER_HEAD_CHECK_DATE_SUCCESS'
export const VOUCHER_HEAD_CONFIRM = 'VOUCHER_HEAD_CONFIRM'
export const VOUCHER_HEAD_CONFIRM_SUCCESS = 'VOUCHER_HEAD_CONFIRM_SUCCESS'
export const GET_VOUCHER_HEAD_INFO = 'GET_VOUCHER_HEAD_INFO'
export const GET_VOUCHER_HEAD_INFO_SUCCESS = 'GET_VOUCHER_HEAD_INFO_SUCCESS'
export const VOUCHER_CANCEL = 'VOUCHER_CANCEL'
export const VOUCHER_CANCEL_SUCCESS = 'VOUCHER_CANCEL_SUCCESS'
export const SALES_AFFECTED_STATE_VALIDATE = 'SALES_AFFECTED_STATE_VALIDATE'
export const SALES_AFFECTED_STATE_VALIDATE_SUCCESS =
	'SALES_AFFECTED_STATE_VALIDATE_SUCCESS'
export const SALES_AFFECTED_STATE_CONFIRM = 'SALES_AFFECTED_STATE_CONFIRM'
export const SALES_AFFECTED_STATE_CONFIRM_SUCCESS =
	'SALES_AFFECTED_STATE_CONFIRM_SUCCESS'
export const NEW_VOUCHER_TYPE = 'NEW_VOUCHER_TYPE'
export const NEW_VOUCHER_TYPE_SUCCESS = 'NEW_VOUCHER_TYPE_SUCCESS'
export const VALIDATE_SALES_AFFECTED_DISCOUNT =
	'VALIDATE_SALES_AFFECTED_DISCOUNT'
export const VALIDATE_SALES_AFFECTED_DISCOUNT_SUCCESS =
	'VALIDATE_SALES_AFFECTED_DISCOUNT_SUCCESS'
export const VALIDATE_SALES_AFFECTED_PRICE = 'VALIDATE_SALES_AFFECTED_PRICE'
export const VALIDATE_SALES_AFFECTED_PRICE_SUCCESS =
	'VALIDATE_SALES_AFFECTED_PRICE_SUCCESS'

// Products
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS'
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS'
export const GET_PRICE_BY_PRODUCT = 'GET_PRICE_BY_PRODUCT'
export const GET_PRICE_BY_PRODUCT_SUCCESS = 'GET_PRICE_BY_PRODUCT_SUCCESS'
export const CHECK_ITEM_BY_PRODUCT = 'CHECK_ITEM_BY_PRODUCT'
export const CHECK_ITEM_BY_PRODUCT_SUCCESS = 'CHECK_ITEM_BY_PRODUCT_SUCCESS'
export const SET_TABLE_DATA_PRODUCTS = 'SET_TABLE_DATA_PRODUCTS'
export const GET_PRODUCTS_CART = 'GET_PRODUCTS_CART'
export const GET_PRODUCTS_CART_SUCCESS = 'GET_PRODUCTS_CART_SUCCESS'
export const SET_INPUT_FOCUS = 'SET_INPUT_FOCUS'
export const SET_INPUT_FOCUS_SUCCESS = 'SET_INPUT_FOCUS_SUCCESS'
export const GET_PRODUCTS_INVOLVEMENT = 'GET_PRODUCTS_INVOLVEMENT'
export const GET_PRODUCTS_INVOLVEMENT_SUCCESS =
	'GET_PRODUCTS_INVOLVEMENT_SUCCESS'
export const REMOVE_PRODUCT_CART = 'REMOVE_PRODUCT_CART'
export const REMOVE_PRODUCT_CART_SUCCESS = 'REMOVE_PRODUCT_CART_SUCCESS'
export const GET_PRODUCT_IMAGE = 'GET_PRODUCT_IMAGE'
export const GET_PRODUCT_IMAGE_SUCCESS = 'GET_PRODUCT_IMAGE_SUCCESS'
export const GET_PRODUCT_IMAGE_ERROR = 'GET_PRODUCT_IMAGE_ERROR'
export const FINISH_PRODUCT_CART = 'FINISH_PRODUCT_CART'
export const FINISH_PRODUCT_CART_SUCCESS = 'FINISH_PRODUCT_CART_SUCCESS'
export const GET_PRODUCT_COST = 'GET_PRODUCT_COST'
export const GET_PRODUCT_COST_SUCCESS = 'GET_PRODUCT_COST_SUCCESS'
export const GET_PRICE_ADD_CART = 'GET_PRICE_ADD_CART'
export const SEARCH_AUTO_PRODUCTS = 'SEARCH_AUTO_PRODUCTS'
export const SEARCH_AUTO_PRODUCTS_SUCCESS = 'SEARCH_AUTO_PRODUCTS_SUCCESS'
export const ADVANCED_PRODUCT_SEARCH = 'ADVANCED_PRODUCT_SEARCH'
export const ADVANCED_PRODUCT_SEARCH_SUCCESS = 'ADVANCED_PRODUCT_SEARCH_SUCCESS'
export const VALIDATE_PRODUCT_DISCOUNT = 'VALIDATE_PRODUCT_DISCOUNT'
export const VALIDATE_PRODUCT_DISCOUNT_SUCCESS =
	'VALIDATE_PRODUCT_DISCOUNT_SUCCESS'
export const VALIDATE_PRODUCT_PRICE = 'VALIDATE_PRODUCT_PRICE'
export const VALIDATE_PRODUCT_PRICE_SUCCESS = 'VALIDATE_PRODUCT_PRICE_SUCCESS'
export const GET_PRODUCT_PRICE_LIST = 'GET_PRODUCT_PRICE_LIST'
export const GET_PRODUCT_PRICE_LIST_SUCCESS = 'GET_PRODUCT_PRICE_LIST_SUCCESS'
export const VALIDATE_PRODUCT_COST = 'VALIDATE_PRODUCT_COST'
export const VALIDATE_PRODUCT_COST_PC = 'VALIDATE_PRODUCT_COST_PC'
export const VALIDATE_PRODUCT_COST_SUCCESS = 'VALIDATE_PRODUCT_COST_SUCCESS'
export const VALIDATE_PRODUCT_COST_PC_SUCCESS =
	'VALIDATE_PRODUCT_COST_PC_SUCCESS'
export const VALIDATE_PRODUCT_NET = 'VALIDATE_PRODUCT_NET'
export const VALIDATE_PRODUCT_NET_SUCCESS = 'VALIDATE_PRODUCT_NET_SUCCESS'
export const VALIDATE_PRODUCT_COST_NET = 'VALIDATE_PRODUCT_COST_NET'
export const VALIDATE_PRODUCT_COST_NET_SUCCESS =
	'VALIDATE_PRODUCT_COST_NET_SUCCESS'
export const DELETE_SEARCHED_PRODUCTS = 'DELETE_SEARCHED_PRODUCTS'
export const DELETE_SEARCHED_PRODUCTS_SUCCESS =
	'DELETE_SEARCHED_PRODUCTS_SUCCESS'

// shopping cart
export const GET_CONFIG_SHOPPING_CART = 'GET_CONFIG_SHOPPING_CART'
export const GET_CONFIG_SHOPPING_CART_SUCCESS =
	'GET_CONFIG_SHOPPING_CART_SUCCESS'

// Load Items
export const GET_LOAD_ITEMS = 'GET_LOAD_ITEMS'
export const GET_LOAD_ITEMS_SUCCESS = 'GET_LOAD_ITEMS_SUCCESS'
export const CONFIRM_LOAD_ITEMS = 'CONFIRM_LOAD_ITEMS'
export const CONFIRM_LOAD_ITEMS_SUCCESS = 'CONFIRM_LOAD_ITEMS_SUCCESS'
export const CONFIRM_TABLE_ITEMS = 'CONFIRM_TABLE_ITEMS'
export const CONFIRM_TABLE_ITEMS_SUCCESS = 'CONFIRM_TABLE_ITEMS_SUCCESS'
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM'
export const UPDATE_CART_ITEM_SUCCESS = 'UPDATE_CART_ITEM_SUCCESS'
export const GET_CART_ITEM_INFO = 'GET_CART_ITEM_INFO'
export const GET_CART_ITEM_INFO_SUCCESS = 'GET_CART_ITEM_INFO_SUCCESS'
export const CONFIRM_IND_LOAD_ITEMS = 'CONFIRM_IND_LOAD_ITEMS'
export const CONFIRM_IND_LOAD_ITEMS_SUCCESS = 'CONFIRM_IND_LOAD_ITEMS_SUCCESS'
export const CLEAR_ITEM_STORE = 'CLEAR_ITEM_STORE'
export const GET_PROVIDER_CART_ITEM_INFO = 'PROVIDER_CART_ITEM_INFO'
export const GET_PROVIDER_CART_ITEM_INFO_SUCCESS =
	'PROVIDER_CART_ITEM_INFO_SUCCESS'
export const UPDATE_PROVIDER_CART_ITEM = 'UPDATE_PROVIDER_CART_ITEM'
export const UPDATE_PROVIDER_CART_ITEM_SUCCESS =
	'UPDATE_PROVIDER_CART_ITEM_SUCCESS'
export const CONFIRM_PROVIDER_IND_LOAD_ITEMS = 'CONFIRM_PROVIDER_IND_LOAD_ITEMS'
export const CONFIRM_PROVIDER_IND_LOAD_ITEMS_SUCCESS =
	'CONFIRM_PROVIDER_IND_LOAD_ITEMS_SUCCESS'
export const CONFIRM_PROVIDER_LOAD_ITEMS = 'CONFIRM_PROVIDER_LOAD_ITEMS'
export const CONFIRM_PROVIDER_LOAD_ITEMS_SUCCESS =
	'CONFIRM_PROVIDER_LOAD_ITEMS_SUCCESS'

// Sales affected
export const SALES_AFFECTED_VALIDATE = 'SALES_AFFECTED_VALIDATE'
export const SALES_AFFECTED_VALIDATE_SUCCESS = 'SALES_AFFECTED_VALIDATE_SUCCESS'
export const SALES_AFFECTED_QUANTITY = 'SALES_AFFECTED_QUANTITY'
export const SALES_AFFECTED_QUANTITY_SUCCESS = 'SALES_AFFECTED_QUANTITY_SUCCESS'
export const SALES_AFFECTED_SUB_CALCULATION = 'SALES_AFFECTED_SUB_CALCULATION'
export const SALES_AFFECTED_SUB_CALCULATION_SUCCESS =
	'SALES_AFFECTED_SUB_CALCULATION_SUCCESS'
export const SALES_AFFECTED_CONFIRM = 'SALES_AFFECTED_CONFIRM'
export const SALES_AFFECTED_CONFIRM_SUCCESS = 'SALES_AFFECTED_CONFIRM_SUCCESS'
export const SET_TABLE_DATA_INVOLVEMENT = 'SET_TABLE_DATA_INVOLVEMENT'
export const SET_TABLE_DATA_INVOLVEMENT_SUCCESS =
	'SET_TABLE_DATA_INVOLVEMENT_SUCCESS'
export const SALES_AFFECTED_IMPORT = 'SALES_AFFECTED_IMPORT'
export const SALES_AFFECTED_IMPORT_SUCCESS = 'SALES_AFFECTED_IMPORT_SUCCESS'
export const SALES_AFFECTED_IMPORT_VALIDATE = 'SALES_AFFECTED_IMPORT_VALIDATE'
export const SALES_AFFECTED_IMPORT_VALIDATE_SUCCESS =
	'SALES_AFFECTED_IMPORT_VALIDATE_SUCCESS'
export const SALES_AFFECTED_IMPORT_CONFIRM = 'SALES_AFFECTED_IMPORT_CONFIRM'
export const SALES_AFFECTED_IMPORT_CONFIRM_SUCCESS =
	'SALES_AFFECTED_IMPORT_CONFIRM_SUCCESS'
export const SALES_AFFECTED_STATE = 'SALES_AFFECTED_STATE'
export const SALES_AFFECTED_STATE_SUCCESS = 'SALES_AFFECTED_STATE_SUCCESS'

// Purchases affected
export const PURCHASES_AFFECTED_QUANTITY = 'PURCHASES_AFFECTED_QUANTITY'
export const PURCHASES_AFFECTED_QUANTITY_SUCCESS =
	'PURCHASES_AFFECTED_QUANTITY_SUCCESS'
export const PURCHASES_AFFECTED_VALIDATE = 'PURCHASES_AFFECTED_VALIDATE'
export const PURCHASES_AFFECTED_VALIDATE_SUCCESS =
	'PURCHASES_AFFECTED_VALIDATE_SUCCESS'
export const PURCHASES_AFFECTED_SUB_CALCULATION =
	'PURCHASES_AFFECTED_SUB_CALCULATION'
export const PURCHASES_AFFECTED_SUB_CALCULATION_SUCCESS =
	'PURCHASES_AFFECTED_SUB_CALCULATION_SUCCESS'
export const PURCHASES_AFFECTED_CONFIRM = 'PURCHASES_AFFECTED_CONFIRM'
export const PURCHASES_AFFECTED_CONFIRM_SUCCESS =
	'PURCHASES_AFFECTED_CONFIRM_SUCCESS'
export const PURCHASES_AFFECTED_IMPORT = 'PURCHASES_AFFECTED_IMPORT'
export const PURCHASES_AFFECTED_IMPORT_SUCCESS =
	'PURCHASES_AFFECTED_IMPORT_SUCCESS'
export const PURCHASES_AFFECTED_IMPORT_VALIDATE =
	'PURCHASES_AFFECTED_IMPORT_VALIDATE'
export const PURCHASES_AFFECTED_IMPORT_VALIDATE_SUCCESS =
	'PURCHASES_AFFECTED_IMPORT_VALIDATE_SUCCESS'
export const PURCHASES_VALIDATE_DATE = 'PURCHASES_VALIDATE_DATE'
export const PURCHASES_VALIDATE_DATE_SUCCESS = 'PURCHASES_VALIDATE_DATE_SUCCESS'
export const PURCHASES_AFFECTED_IMPORT_CONFIRM =
	'PURCHASES_AFFECTED_IMPORT_CONFIRM'
export const PURCHASES_AFFECTED_IMPORT_CONFIRM_SUCCESS =
	'PURCHASES_AFFECTED_IMPORT_CONFIRM_SUCCESS'
export const PURCHASES_AFFECTED_STATE = 'PURCHASES_AFFECTED_STATE'
export const PURCHASES_AFFECTED_STATE_SUCCESS =
	'PURCHASES_AFFECTED_STATE_SUCCESS'
export const PURCHASES_AFFECTED_STATE_VALIDATE =
	'PURCHASES_AFFECTED_STATE_VALIDATE'
export const PURCHASES_AFFECTED_STATE_VALIDATE_SUCCESS =
	'PURCHASES_AFFECTED_STATE_VALIDATE_SUCCESS'
export const PURCHASES_AFFECTED_STATE_CONFIRM =
	'PURCHASES_AFFECTED_STATE_CONFIRM'
export const PURCHASES_AFFECTED_STATE_CONFIRM_SUCCESS =
	'PURCHASES_AFFECTED_STATE_CONFIRM_SUCCESS'

// Client headboard
export const GET_CLIENT_HEADBOARD = 'GET_CLIENT_HEADBOARD'
export const GET_CLIENT_HEADBOARD_SUCCESS = 'GET_CLIENT_HEADBOARD_SUCCESS'
export const GET_GENERATE_ITEMS = 'GET_GENERATE_ITEMS'
export const GET_GENERATE_ITEMS_SUCCESS = 'GET_GENERATE_ITEMS_SUCCESS'
export const GET_GENERATE_SALES_AFFECTED = 'GET_GENERATE_SALES_AFFECTED'
export const GET_GENERATE_SALES_AFFECTED_SUCCESS =
	'GET_GENERATE_SALES_AFFECTED_SUCCESS'
export const FINISH_GENERATE = 'FINISH_GENERATE'
export const FINISH_GENERATE_SUCCESS = 'FINISH_GENERATE_SUCCESS'
export const PRINT_GENERATE = 'PRINT_GENERATE'
export const PRINT_GENERATE_SUCCESS = 'PRINT_GENERATE_SUCCESS'
export const CHANGE_TABLE_ITEMS = 'CHANGE_TABLE_ITEMS'
export const CHANGE_TABLE_ITEMS_SUCCESS = 'CHANGE_TABLE_ITEMS_SUCCESS'
export const CHANGE_TABLE_AFFECT = 'CHANGE_TABLE_AFFECT'
export const CHANGE_TABLE_AFFECT_SUCCESS = 'CHANGE_TABLE_AFFECT_SUCCESS'

// Accounting Seats
export const GET_VOUCHER_ACCOUNTING = 'GET_VOUCHER_ACCOUNTING'
export const GET_VOUCHER_ACCOUNTING_SUCCESS = 'GET_VOUCHER_ACCOUNTING_SUCCESS'
export const SEARCH_ACCOUNT = 'SEARCH_ACCOUNT'
export const SEARCH_ACCOUNT_SUCCESS = 'SEARCH_ACCOUNT_SUCCESS'
export const GET_ACCOUNT_DETAIL = 'GET_ACCOUNT_DETAIL'
export const GET_ACCOUNT_DETAIL_SUCCESS = 'GET_ACCOUNT_DETAIL_SUCCESS'
export const ACCOUNT_VALIDATE = 'ACCOUNT_VALIDATE'
export const ACCOUNT_VALIDATE_SUCCESS = 'ACCOUNT_VALIDATE_SUCCESS'
export const ACCOUNT_CONFIRM = 'ACCOUNT_CONFIRM'
export const ACCOUNT_CONFIRM_SUCCESS = 'ACCOUNT_CONFIRM_SUCCESS'

// Taxes Accounting Voucher.
export const GET_VOUCHER_TAXES = 'GET_VOUCHER_TAXES'
export const GET_VOUCHER_TAXES_SUCCESS = 'GET_VOUCHER_TAXES_SUCCESS'
export const TAXES_VALIDATE_ROW = 'TAXES_VALIDATE_ROW'
export const TAXES_VALIDATE_ROW_SUCCESS = 'TAXES_VALIDATE_ROW_SUCCESS'
export const TAXES_CONFIRM = 'TAXES_CONFIRM'
export const TAXES_CONFIRM_SUCCESS = 'TAXES_CONFIRM_SUCCESS'

// Collections
export const GET_COLLECTIONS = 'GET_COLLECTIONS'
export const GET_COLLECTIONS_SUCCESS = 'GET_COLLECTIONS_SUCCESS'
export const VALIDATE_PAYMENT = 'VALIDATE_PAYMENT'
export const VALIDATE_PAYMENT_SUCCESS = 'VALIDATE_PAYMENT_SUCCESS'
export const CONFIRM_PAYMENT = 'CONFIRM_PAYMENT'
export const CONFIRM_PAYMENT_SUCCESS = 'CONFIRM_PAYMENT_SUCCESS'
export const GET_SEL_DOCUMENTS = 'GET_SEL_DOCUMENTS'
export const GET_SEL_DOCUMENTS_SUCCESS = 'GET_SEL_DOCUMENTS_SUCCESS'
export const CONFIRM_SEL_DOCUMENTS = 'CONFIRM_SEL_DOCUMENTS'
export const CONFIRM_SEL_DOCUMENTS_SUCCESS = 'CONFIRM_SEL_DOCUMENTS_SUCCESS'
export const VALIDATE_SEL_DOCUMENTS = 'VALIDATE_SEL_DOCUMENTS'
export const VALIDATE_SEL_DOCUMENTS_SUCCESS = 'VALIDATE_SEL_DOCUMENTS_SUCCESS'
export const REMOVE_PAYMENT = 'REMOVE_PAYMENT'
export const REMOVE_PAYMENT_SUCCESS = 'REMOVE_PAYMENT_SUCCESS'
export const SEARCH_BANK = 'SEARCH_BANK'
export const SEARCH_BANK_SUCCESS = 'SEARCH_BANK_SUCCESS'
export const GET_IMP_DOCUMENTS = 'GET_IMP_DOCUMENTS'
export const GET_IMP_DOCUMENTS_SUCCESS = 'GET_IMP_DOCUMENTS_SUCCESS'
export const GET_IMP_DOCUMENTS_ERROR = 'GET_IMP_DOCUMENTS_ERROR'
export const CLEAR_IMP_DOCUMENTS_ERROR = 'CLEAR_IMP_DOCUMENTS_ERROR'
export const COLLECTION_IMPORT_DOC = 'COLLECTION_IMPORT_DOC'
export const COLLECTION_IMPORT_DOC_SUCCESS = 'COLLECTION_IMPORT_DOC_SUCCESS'
export const CONFIRM_IMP_DOCUMENTS = 'CONFIRM_IMP_DOCUMENTS'
export const CONFIRM_IMP_DOCUMENTS_SUCCESS = 'CONFIRM_IMP_DOCUMENTS_SUCCESS'
export const CANCEL_SEL_DOCUMENTS = 'CANCEL_SEL_DOCUMENTS'
export const CANCEL_SEL_DOCUMENTS_SUCCESS = 'CANCEL_SEL_DOCUMENTS_SUCCESS'
export const CHANGE_COLLECTION_TABLE = 'CHANGE_COLLECTION_TABLE'
export const CHANGE_COLLECTION_TABLE_SUCCESS = 'CHANGE_COLLECTION_TABLE_SUCCESS'

// Stock
export const GET_PRODUCT_STOCK = 'GET_PRODUCT_STOCK'
export const GET_PRODUCT_STOCK_SUCCESS = 'GET_PRODUCT_STOCK_SUCCESS'
export const VALIDATE_PRODUCT_STOCK = 'VALIDATE_PRODUCT_STOCK'
export const VALIDATE_PRODUCT_STOCK_SUCCESS = 'VALIDATE_PRODUCT_STOCK_SUCCESS'
export const VALIDATE_PRODUCT_STOCK_ERROR = 'VALIDATE_PRODUCT_STOCK_ERROR'
export const CONFIRM_PRODUCT_STOCK = 'CONFIRM_PRODUCT_STOCK'
export const CONFIRM_PRODUCT_STOCK_SUCCESS = 'CONFIRM_PRODUCT_STOCK_SUCCESS'
export const CONFIRM_PRODUCT_STOCK_ERROR = 'CONFIRM_PRODUCT_STOCK_ERROR'
export const CANCEL_PRODUCT_STOCK = 'CANCEL_PRODUCT_STOCK'
export const CANCEL_PRODUCT_STOCK_SUCCESS = 'CANCEL_PRODUCT_STOCK_SUCCESS'
export const CANCEL_PRODUCT_STOCK_ERROR = 'CANCEL_PRODUCT_STOCK_ERROR'
export const SEARCH_BARCODE = 'SEARCH_BARCODE'
export const SEARCH_BARCODE_SUCCESS = 'SEARCH_BARCODE_SUCCESS'
export const SEARCH_BARCODE_ERROR = 'SEARCH_BARCODE_ERROR'
export const CLEAR_STORE_STOCK = 'CLEAR_STORE_STOCK'
export const APPLY_PRODUCT_STOCK_PROVIDER_ERROR =
	'APPLY_PRODUCT_STOCK_PROVIDER_ERROR'

// FINISH VOUCHER
export const PRINT_VOUCHER = 'PRINT_VOUCHER'
export const PRINT_VOUCHER_SUCCESS = 'PRINT_VOUCHER_SUCCESS'
export const EXIT_PRINT_VOUCHER = 'EXIT_PRINT_VOUCHER'
export const EXIT_PRINT_VOUCHER_SUCCESS = 'EXIT_PRINT_VOUCHER_SUCCESS'

// provider
export const GET_PROVIDER = 'GET_PROVIDER'
export const GET_PROVIDER_SUCCESS = 'GET_PROVIDER_SUCCESS'
export const SEARCH_PROVIDERS = 'SEARCH_PROVIDERS'
export const SEARCH_PROVIDERS_SUCCESS = 'SEARCH_PROVIDERS_SUCCESS'
export const CONFIRMATION_PROVIDER = 'CONFIRMATION_PROVIDER'
export const CONFIRMATION_PROVIDER_SUCCESS = 'CONFIRMATION_PROVIDER_SUCCESS'
export const GET_PROVIDER_HEADER = 'GET_PROVIDER_HEADER'
export const GET_PROVIDER_HEADER_SUCCESS = 'GET_PROVIDER_HEADER_SUCCESS'
export const PROVIDER_HEADER_CHECK_DATE = 'PROVIDER_HEADER_CHECK_DATE'
export const PROVIDER_HEADER_CHECK_DATE_SUCCESS =
	'PROVIDER_HEADER_CHECK_DATE_SUCCESS'
export const PROVIDER_HEADER_CONFIRMATION = 'PROVIDER_HEADER_CONFIRMATION'
export const PROVIDER_HEADER_CONFIRMATION_SUCCESS =
	'PROVIDER_HEADER_CONFIRMATION_SUCCESS'
export const PROVIDER_HEADER_AUTO = 'PROVIDER_HEADER_AUTO'
export const PROVIDER_HEADER_AUTO_SUCCESS = 'PROVIDER_HEADER_AUTO_SUCCESS'
export const ITEM_PROVIDER_ADD_TO_CART = 'ITEM_PROVIDER_ADD_TO_CART'
export const ITEM_PROVIDER_ADD_TO_CART_SUCCESS =
	'ITEM_PROVIDER_ADD_TO_CART_SUCCESS'
export const CONFIRM_PROVIDER_TABLE = 'CONFIRM_PROVIDER_TABLE'
export const CONFIRM_PROVIDER_TABLE_SUCCESS = 'CONFIRM_PROVIDER_TABLE_SUCCESS'
export const CLEAR_PROVIDER_HEADER = 'CLEAR_PROVIDER_HEADER'

// Stock Provider

export const GET_PRODUCT_STOCK_PROVIDER = 'GET_PRODUCT_STOCK_PROVIDER'
export const GET_PRODUCT_STOCK_PROVIDER_SUCCESS =
	'GET_PRODUCT_STOCK_PROVIDER_SUCCESS'
export const VALIDATE_PRODUCT_STOCK_PROVIDER = 'VALIDATE_PRODUCT_STOCK_PROVIDER'
export const VALIDATE_PRODUCT_STOCK_PROVIDER_SUCCESS =
	'VALIDATE_PRODUCT_STOCK_PROVIDER_SUCCESS'
export const VALIDATE_PRODUCT_STOCK_PROVIDER_ERROR =
	'VALIDATE_PRODUCT_STOCK_PROVIDER_ERROR'
export const SEARCH_BARCODE_PROVIDER = 'SEARCH_BARCODE_PROVIDER'
export const SEARCH_BARCODE_PROVIDER_SUCCESS = 'SEARCH_BARCODE_PROVIDER_SUCCESS'
export const SEARCH_BARCODE_PROVIDER_ERROR = 'SEARCH_BARCODE_PROVIDER_ERROR'
export const CONFIRM_PRODUCT_STOCK_PROVIDER = 'CONFIRM_PRODUCT_STOCK_PROVIDER'
export const CONFIRM_PRODUCT_STOCK_PROVIDER_SUCCESS =
	'CONFIRM_PRODUCT_STOCK_PROVIDER_SUCCESS'
export const CONFIRM_PRODUCT_STOCK_PROVIDER_ERROR =
	'CONFIRM_PRODUCT_STOCK_PROVIDER_ERROR'
export const APPLY_PRODUCT_STOCK_PROVIDER = 'APPLY_PRODUCT_STOCK_PROVIDER'
export const APPLY_PRODUCT_STOCK_PROVIDER_SUCCESS =
	'APPLY_PRODUCT_STOCK_PROVIDER_SUCCESS'
export const LOAD_PRODUCT_STOCK_PROVIDER = 'LOAD_PRODUCT_STOCK_PROVIDER'
export const LOAD_PRODUCT_STOCK_PROVIDER_SUCCESS =
	'LOAD_PRODUCT_STOCK_PROVIDER_SUCCESS'
export const LOAD_PRODUCT_STOCK_PROVIDER_ERROR =
	'LOAD_PRODUCT_STOCK_PROVIDER_ERROR'
export const CANCEL_PRODUCT_STOCK_PROVIDER = 'CANCEL_PRODUCT_STOCK_PROVIDER'
export const CANCEL_PRODUCT_STOCK_PROVIDER_SUCCESS =
	'CANCEL_PRODUCT_STOCK_PROVIDER_SUCCESS'
export const CANCEL_PRODUCT_STOCK_PROVIDER_ERROR =
	'CANCEL_PRODUCT_STOCK_PROVIDER_ERROR'
export const CLEAR_STORE_STOCK_PROVIDER = 'CLEAR_STORE_STOCK_PROVIDER'

// Pickeo Ing Stock
export const GET_ING_STOCK = 'GET_ING_STOCK '
export const GET_ING_STOCK_SUCCESS = 'GET_ING_STOCK_SUCCESS'
export const PICKEO_SEARCH_BARCODE = 'PICKEO_SEARCH_BARCODE'
export const PICKEO_SEARCH_BARCODE_ERROR = 'PICKEO_SEARCH_BARCODE_ERROR'
export const PICKEO_SEARCH_BARCODE_SUCCESS = 'PICKEO_SEARCH_BARCODE_SUCCESS'
export const PICK_STOCK_CONFIRM = 'PICK_STOCK_CONFIRM'
export const PICK_STOCK_CONFIRM_SUCCESS = 'PICK_STOCK_CONFIRM_SUCCESS'
export const PICK_STOCK_IMPORT = 'PICK_STOCK_IMPORT'
export const PICK_STOCK_IMPORT_ERROR = 'PICK_STOCK_IMPORT_ERROR'
export const PICK_STOCK_IMPORT_SUCCESS = 'PICK_STOCK_IMPORT_SUCCESS'
export const PICK_STOCK_IMPORT_CONFIRM = 'PICK_STOCK_IMPORT_CONFIRM'
export const PICK_STOCK_IMPORT_CONFIRM_SUCCESS =
	'PICK_STOCK_IMPORT_CONFIRM_SUCCESS'
export const PICK_STOCK_IMPORT_CONFIRM_ERROR = 'PICK_STOCK_IMPORT_CONFIRM_ERROR'
export const PICK_STOCK_IMPORT_CANCEL = 'PICK_STOCK_IMPORT_CANCEL'
export const PICK_STOCK_IMPORT_CANCEL_SUCCESS =
	'PICK_STOCK_IMPORT_CANCEL_SUCCESS'
export const PICK_STOCK_ANMAT_CONFIRM = 'PICK_STOCK_ANMAT_CONFIRM'
export const PICK_STOCK_ANMAT_CONFIRM_SUCCESS =
	'PICK_STOCK_ANMAT_CONFIRM_SUCCESS'
export const PICK_STOCK_ANMAT_CANCEL = 'PICK_STOCK_ANMAT_CANCEL'
export const PICK_STOCK_ANMAT_CANCEL_SUCCESS = 'PICK_STOCK_ANMAT_CANCEL_SUCCESS'
export const PICK_STOCK_TABLE = 'PICK_STOCK_TABLE'
export const PICK_STOCK_TABLE_SUCCESS = 'PICK_STOCK_TABLE_SUCCESS'
export const PICK_STOCK_ANMAT = 'PICK_STOCK_ANMAT'
export const PICK_STOCK_ANMAT_SUCCESS = 'PICK_STOCK_ANMAT_SUCCESS'

// Pickeo Egr Stock
export const GET_EGR_STOCK = 'GET_EGR_STOCK'
export const GET_EGR_STOCK_SUCCESS = 'GET_EGR_STOCK_SUCCESS'
export const PICKEO_EGR_SEARCH_BARCODE = 'PICKEO_EGR_SEARCH_BARCODE'
export const PICKEO_EGR_SEARCH_BARCODE_ERROR = 'PICKEO_EGR_SEARCH_BARCODE_ERROR'
export const PICKEO_EGR_SEARCH_BARCODE_SUCCESS =
	'PICKEO_EGR_SEARCH_BARCODE_SUCCESS'
export const PICKEO_EGR_STOCK_CONFIRM = 'PICKEO_EGR_STOCK_CONFIRM'
export const PICKEO_EGR_STOCK_CONFIRM_SUCCESS =
	'PICKEO_EGR_STOCK_CONFIRM_SUCCESS'

// Crud
export const GET_CONFIG_FIELDS_CRUD = 'GET_CONFIG_FIELDS_CRUD'
export const GET_CONFIG_FIELDS_CRUD_SUCCESS = 'GET_CONFIG_FIELDS_CRUD_SUCCESS'
export const GET_CONFIG_FIELDS_CRUD_ALTA = 'GET_CONFIG_FIELDS_CRUD_ALTA'
export const GET_CONFIG_FIELDS_CRUD_ALTA_SUCCESS =
	'GET_CONFIG_FIELDS_CRUD_ALTA_SUCCESS'
export const GET_ALL_CRUDS = 'GET_ALL_CRUDS'
export const GET_ALL_CRUDS_SUCCESS = 'GET_ALL_CRUDS_SUCCESS'
export const GET_ABM_CODE = 'GET_ABM_CODE'
export const GET_ABM_CODE_SUCCESS = 'GET_ABM_CODE_SUCCESS'

export const GET_PRICELIST = 'GET_PRICELIST'
export const GET_PRICELIST_SUCCESS = 'GET_PRICELIST_SUCCESS'
export const GET_MESSAGELIST = 'GET_MESSAGELIST'
export const GET_MESSAGELIST_SUCCESS = 'GET_MESSAGELIST_SUCCESS'

// Search Voucher

export const SEARCH_VOUCHER = 'SEARCH_VOUCHER'
export const SEARCH_VOUCHER_SUCCESS = 'SEARCH_VOUCHER_SUCCESS'
export const GET_DETAIL_VOUCHER_HEADBOARD = 'GET_DETAIL_VOUCHER_HEADBOARD'
export const GET_DETAIL_VOUCHER_HEADBOARD_SUCCESS =
	'GET_DETAIL_VOUCHER_HEADBOARD_SUCCESS'
export const GET_DETAIL_VOUCHER_ITEMS = 'GET_DETAIL_VOUCHER_ITEMS'
export const GET_DETAIL_VOUCHER_ITEMS_SUCCESS =
	'GET_DETAIL_VOUCHER_ITEMS_SUCCESS'
export const GET_DETAIL_VOUCHER_TAXES = 'GET_DETAIL_VOUCHER_TAXES'
export const GET_DETAIL_VOUCHER_TAXES_SUCCESS =
	'GET_DETAIL_VOUCHER_TAXES_SUCCESS'
export const GET_DETAIL_VOUCHER_AFFECTATIONS = 'GET_DETAIL_VOUCHER_AFFECTATIONS'
export const GET_DETAIL_VOUCHER_AFFECTATIONS_SUCCESS =
	'GET_DETAIL_VOUCHER_AFFECTATIONS_SUCCESS'
export const GET_DETAIL_VOUCHER_SEAT = 'GET_DETAIL_VOUCHER_SEAT'
export const GET_DETAIL_VOUCHER_SEAT_SUCCESS = 'GET_DETAIL_VOUCHER_SEAT_SUCCESS'
export const GET_DETAIL_TAX_SUMMARY = 'GET_DETAIL_TAX_SUMMARY'
export const GET_DETAIL_TAX_SUMMARY_SUCCESS = 'GET_DETAIL_TAX_SUMMARY_SUCCESS'
export const GET_FILTER_MODULES = 'GET_FILTER_MODULES'
export const GET_FILTER_MODULES_SUCCESS = 'GET_FILTER_MODULES_SUCCESS'
export const GET_FILTER_VOUCHERS = 'GET_FILTER_VOUCHERS'
export const GET_FILTER_VOUCHERS_SUCCESS = 'GET_FILTER_VOUCHERS_SUCCESS'
export const GET_FILTER_BRANCHES = 'GET_FILTER_BRANCHES'
export const GET_FILTER_BRANCHES_SUCCESS = 'GET_FILTER_BRANCHES_SUCCESS'
export const GET_FILTER_ATTRIBUTES = 'GET_FILTER_ATTRIBUTES'
export const GET_FILTER_ATTRIBUTES_SUCCESS = 'GET_FILTER_ATTRIBUTES_SUCCESS'
export const GET_FILTER_PAYMENT_CONDITIONS = 'GET_FILTER_PAYMENT_CONDITIONS'
export const GET_FILTER_PAYMENT_CONDITIONS_SUCCESS =
	'GET_FILTER_PAYMENT_CONDITIONS_SUCCESS'
export const GET_FILTER_SELLERS = 'GET_FILTER_SELLERS'
export const GET_FILTER_SELLERS_SUCCESS = 'GET_FILTER_SELLERS_SUCCESS'
export const GET_FILTER_CURRENCY = 'GET_FILTER_CURRENCY'
export const GET_FILTER_CURRENCY_SUCCESS = 'GET_FILTER_CURRENCY_SUCCESS'
export const GET_FILTER_FUND_ACCOUNT_TYPES = 'GET_FILTER_FUND_ACCOUNT_TYPES'
export const GET_FILTER_FUND_ACCOUNT_TYPES_SUCCESS =
	'GET_FILTER_FUND_ACCOUNT_TYPES_SUCCESS'
export const GET_FILTER_USERS = 'GET_FILTER_USERS'
export const GET_FILTER_USERS_SUCCESS = 'GET_FILTER_USERS_SUCCESS'
export const GET_FILTER_TYPE_ACCOUNTING = 'GET_FILTER_TYPE_ACCOUNTING'
export const GET_FILTER_TYPE_ACCOUNTING_SUCCESS =
	'GET_FILTER_TYPE_ACCOUNTING_SUCCESS'
export const GET_FILTER_DEPOSITS = 'GET_FILTER_DEPOSITS'
export const GET_FILTER_DEPOSITS_SUCCESS = 'GET_FILTER_DEPOSITS_SUCCESS'
export const CLEAR_VOUCHER_SEARCH_STORE = 'CLEAR_VOUCHER_SEARCH_STORE'
export const GET_ORDER_OPTIONS = 'GET_ORDER_OPTIONS'
export const GET_ORDER_OPTIONS_SUCCESS = 'GET_ORDER_OPTIONS_SUCCESS'
export const GET_ORDER_DIR_OPTIONS = 'GET_ORDER_DIR_OPTIONS'
export const GET_ORDER_DIR_OPTIONS_SUCCESS = 'GET_ORDER_DIR_OPTIONS_SUCCESS'
export const SET_VOUCHER_ACTIVE_TAB = 'SET_VOUCHER_ACTIVE_TAB'
export const CLEAR_SEARCH_VOUCHER_PARAMS = 'CLEAR_SEARCH_VOUCHER_PARAMS'
export const GET_DETAIL_TREASURY_SUMMARY = 'GET_DETAIL_TREASURY_SUMMARY'
export const GET_DETAIL_TREASURY_SUMMARY_SUCCESS =
	'GET_DETAIL_TREASURY_SUMMARY_SUCCESS'
export const PRINT_VOUCHER_DETAIL = 'PRINT_VOUCHER_DETAIL'
export const PRINT_VOUCHER_DETAIL_SUCCESS = 'PRINT_VOUCHER_DETAIL_SUCCESS'
export const GET_DETAIL_INVENTORY_SUMMARY = 'GET_DETAIL_INVENTORY_SUMMARY'
export const GET_DETAIL_INVENTORY_SUMMARY_SUCCESS =
	'GET_DETAIL_INVENTORY_SUMMARY_SUCCESS'
export const GET_INVENTORY_SERIE_DATA = 'GET_INVENTORY_SERIE_DATA'
export const GET_INVENTORY_SERIE_DATA_SUCCESS =
	'GET_INVENTORY_SERIE_DATA_SUCCESS'

// Search Products
export const GET_PRODUCT_ORDER_OPTIONS = 'GET_PRODUCT_ORDER_OPTIONS'
export const GET_PRODUCT_ORDER_OPTIONS_SUCCESS =
	'GET_PRODUCT_ORDER_OPTIONS_SUCCESS'
export const PRODUCT_CATEGORY_FILTER = 'GET_PRODUCT_CATEGORY_FILTER'
export const PRODUCT_CATEGORY_FILTER_SUCCESS =
	'GET_PRODUCT_CATEGORY_FILTER_SUCCESS'
export const GET_FILTER_PRODUCT_ATTRIBUTES = 'GET_FILTER_PRODUCT_ATTRIBUTES'
export const GET_FILTER_PRODUCT_ATTRIBUTES_SUCCESS =
	'GET_FILTER_PRODUCT_ATTRIBUTES_SUCCESS'
export const GET_ITEM_CATEGORIES = 'GET_ITEM_CATEGORIES'
export const GET_ITEM_CATEGORIES_SUCCESS = 'GET_ITEM_CATEGORIES_SUCCESS'
export const GET_DETAIL_MAIN_DATA = 'GET_DETAIL_MAIN_DATA'
export const GET_DETAIL_MAIN_DATA_SUCCESS = 'GET_DETAIL_MAIN_DATA_SUCCESS'
export const GET_DETAIL_STOCK = 'GET_DETAIL_STOCK'
export const GET_DETAIL_STOCK_SUCCESS = 'GET_DETAIL_STOCK_SUCCESS'
export const GET_STOCK_ORDER_OPTIONS = 'GET_STOCK_ORDER_OPTIONS'
export const GET_STOCK_ORDER_OPTIONS_SUCCESS = 'GET_STOCK_ORDER_OPTIONS_SUCCESS'
export const GET_DETAIL_SALES_DATA = 'GET_DETAIL_SALES_DATA'
export const GET_DETAIL_SALES_DATA_SUCCESS = 'GET_DETAIL_SALES_DATA_SUCCESS'
export const GET_PERIOD_FILTER = 'GET_PERIOD_FILTER'
export const GET_PERIOD_FILTER_SUCCESS = 'GET_PERIOD_FILTER_SUCCESS'
export const GET_DETAIL_SHOPPING_DATA = 'GET_DETAIL_SHOPPING_DATA'
export const GET_DETAIL_SHOPPING_DATA_SUCCESS =
	'GET_DETAIL_SHOPPING_DATA_SUCCESS'
export const GET_SUGGESTED_DATA = 'GET_SUGGESTED_DATA'
export const GET_SUGGESTED_DATA_SUCCESS = 'GET_SUGGESTED_DATA_SUCCESS'
export const GET_EQUIVALENT_DATA = 'GET_EQUIVALENT_DATA'
export const GET_EQUIVALENT_DATA_SUCCESS = 'GET_EQUIVALENT_DATA_SUCCESS'
export const CANCEL_VOUCHER = 'CANCEL_VOUCHER '
export const CANCEL_VOUCHER_SUCCESS = 'CANCEL_VOUCHER_SUCCESS'
export const REMOVE_VOUCHER = 'REMOVE_VOUCHER'
export const REMOVE_VOUCHER_SUCCESS = 'REMOVE_VOUCHER_SUCCESS'
export const CLEAR_PRODUCT_STORE = 'CLEAR_PRODUCT_STORE'
export const SELECT_SEARCHED_PRODUCTS = 'SELECT_SEARCHED_PRODUCTS'
export const SELECT_SEARCHED_PRODUCTS_SUCCESS =
	'SELECT_SEARCHED_PRODUCTS_SUCCESS'

// Provider Observations
export const CRUD_UPDATE_PROVIDER_OBSERVATION =
	'CRUD_UPDATE_PROVIDER_OBSERVATION'
export const CRUD_UPDATE_PROVIDER_OBSERVATION_SUCCESS =
	'CRUD_UPDATE_PROVIDER_OBSERVATION_SUCCESS'
export const CRUD_GET_PROVIDER_OBSERVATION = 'CRUD_GET_PROVIDER_OBSERVATION'
export const CRUD_GET_PROVIDER_OBSERVATION_SUCCESS =
	'CRUD_GET_PROVIDER_OBSERVATION_SUCCESS'

// Provider code generation

export const CRUD_GET_PROVIDER_CODE = 'CRUD_GET_PROVIDER_CODE'
export const CRUD_GET_PROVIDER_CODE_SUCCESS = 'CRUD_GET_PROVIDER_CODE_SUCCESS'
export const CRUD_GET_PROVIDER_CODE_CONFIG = 'CRUD_GET_PROVIDER_LEVELS_CONFIG'
export const CRUD_GET_PROVIDER_CODE_CONFIG_SUCCESS =
	'CRUD_GET_PROVIDER_CODE_CONFIG_SUCCESS'
export const CRUD_CREATE_PROVIDER_CODE = 'CRUD_CREATE_PROVIDER_CODE'
export const CRUD_CREATE_PROVIDER_CODE_SUCCESS =
	'CRUD_CREATE_PROVIDER_CODE_SUCCESS'
export const CRUD_VALIDATE_PROVIDER_CODE = 'CRUD_VALIDATE_PROVIDER_CODE'
export const CRUD_VALIDATE_PROVIDER_CODE_SUCCESS =
	'CRUD_VALIDATE_PROVIDER_CODE_SUCCESS'
export const CRUD_CLEAR_PROVIDER_CODE_STORE = 'CRUD_CLEAR_PROVIDER_CODE_STORE'

// LedgerAcccounts Observations
export const CRUD_UPDATE_CUSTOMER_OBSERVATION =
	'CRUD_UPDATE_CUSTOMER_OBSERVATION'
export const CRUD_UPDATE_CUSTOMER_OBSERVATION_SUCCESS =
	'CRUD_UPDATE_CUSTOMER_OBSERVATION_SUCCESS'
export const CRUD_GET_CUSTOMER_OBSERVATION = 'CRUD_GET_CUSTOMER_OBSERVATION'
export const CRUD_GET_CUSTOMER_OBSERVATION_SUCCESS =
	'CRUD_GET_CUSTOMER_OBSERVATION_SUCCESS'

// LedgerAcccounts Code Generation

export const CRUD_GET_CUSTOMER_CODE = 'CRUD_GET_CUSTOMER_CODE'
export const CRUD_GET_CUSTOMER_CODE_SUCCESS = 'CRUD_GET_CUSTOMER_CODE_SUCCESS'
export const CRUD_GET_CUSTOMER_CODE_CONFIG = 'CRUD_GET_CUSTOMER_LEVELS_CONFIG'
export const CRUD_GET_CUSTOMER_CODE_CONFIG_SUCCESS =
	'CRUD_GET_CUSTOMER_CODE_CONFIG_SUCCESS'
export const CRUD_CREATE_CUSTOMER_CODE = 'CRUD_CREATE_CUSTOMER_CODE'
export const CRUD_CREATE_CUSTOMER_CODE_SUCCESS =
	'CRUD_CREATE_CUSTOMER_CODE_SUCCESS'
export const CRUD_VALIDATE_CUSTOMER_CODE = 'CRUD_VALIDATE_CUSTOMER_CODE'
export const CRUD_VALIDATE_CUSTOMER_CODE_SUCCESS =
	'CRUD_VALIDATE_CUSTOMER_CODE_SUCCESS'
export const CRUD_CLEAR_CUSTOMER_CODE_STORE = 'CRUD_CLEAR_CUSTOMER_CODE_STORE'

// Funds Accounts Bank Reconciliation
export const CRUD_UPDATE_FUND_ACCOUNT_BANK_RECOINCILIATION =
	'CRUD_UPDATE_FUND_ACCOUNT_BANK_RECOINCILIATION'
export const CRUD_UPDATE_FUND_ACCOUNT_BANK_RECOINCILIATION_SUCCESS =
	'CRUD_UPDATE_FUND_ACCOUNT_BANK_RECOINCILIATION_SUCCESS'
export const CRUD_GET_FUND_ACCOUNT_BANK_RECOINCILIATION =
	'CRUD_GET_FUND_ACCOUNT_BANK_RECOINCILIATION'
export const CRUD_GET_FUND_ACCOUNT_BANK_RECOINCILIATION_SUCCESS =
	'CRUD_GET_FUND_ACCOUNT_BANK_RECOINCILIATION_SUCCESS'

// dropdowns data ( originally called table )
// Table Calls
export const GET_TABLE_MESSAGE = 'GET_TABLE_MESSAGE'
export const GET_TABLE_MESSAGE_SUCCESS = 'GET_TABLE_MESSAGE_SUCCESS'
export const GET_TABLE_DATA_TYPE = 'GET_TABLE_DATA_TYPE'
export const GET_TABLE_DATA_TYPE_SUCCESS = 'GET_TABLE_DATA_TYPE_SUCCESS'
export const GET_TABLE_ALIGNMENT_TYPE = 'GET_TABLE_ALIGNMENT_TYPE'
export const GET_TABLE_ALIGNMENT_TYPE_SUCCESS =
	'GET_TABLE_ALIGNMENT_TYPE_SUCCESS'
export const GET_TABLE_VALIDATION_TYPE = 'GET_TABLE_VALIDATION_TYPE'
export const GET_TABLE_VALIDATION_TYPE_SUCCESS =
	'GET_TABLE_VALIDATION_TYPE_SUCCESS'
export const GET_TABLE_VALIDATION_TYPE_T = 'GET_TABLE_VALIDATION_TYPE_T'
export const GET_TABLE_VALIDATION_TYPE_T_SUCCESS =
	'GET_TABLE_VALIDATION_TYPE_T_SUCCESS'
export const GET_TABLE_MEASUREMENT_UNIT = 'GET_TABLE_MEASUREMENT_UNIT'
export const GET_TABLE_MEASUREMENT_UNIT_SUCCESS =
	'GET_TABLE_MEASUREMENT_UNIT_SUCCESS'
export const GET_TABLE_TABLE_TYPE = 'GET_TABLE_TABLE_TYPE'
export const GET_TABLE_TABLE_TYPE_SUCCESS = 'GET_TABLE_TABLE_TYPE_SUCCESS'
export const GET_TABLE_COMMISSION_TYPE = 'GET_TABLE_COMMISSION_TYPE'
export const GET_TABLE_COMMISSION_TYPE_SUCCESS =
	'GET_TABLE_COMMISSION_TYPE_SUCCESS'
export const GET_TABLE_TABLE = 'GET_TABLE_TABLE'
export const GET_TABLE_TABLE_SUCCESS = 'GET_TABLE_TABLE_SUCCESS'
export const GET_TABLE_RESPONSIBLE_TYPE = 'GET_TABLE_RESPONSIBLE_TYPE'
export const GET_TABLE_RESPONSIBLE_TYPE_SUCCESS =
	'GET_TABLE_RESPONSIBLE_TYPE_SUCCESS'
export const GET_TABLE_DOCUMENT_TYPE = 'GET_TABLE_DOCUMENT_TYPE'
export const GET_TABLE_DOCUMENT_TYPE_SUCCESS = 'GET_TABLE_DOCUMENT_TYPE_SUCCESS'
export const GET_TABLE_PROVINCE = 'GET_TABLE_PROVINCE'
export const GET_TABLE_PROVINCE_SUCCESS = 'GET_TABLE_PROVINCE_SUCCESS'
export const GET_TABLE_COUNTRY = 'GET_TABLE_COUNTRY'
export const GET_TABLE_COUNTRY_SUCCESS = 'GET_TABLE_COUNTRY_SUCCESS'
export const GET_TABLE_COURIERS = 'GET_TABLE_COURIERS'
export const GET_TABLE_COURIERS_SUCCESS = 'GET_TABLE_COURIERS_SUCCESS'
export const GET_TABLE_TERMSOFSALES = 'GET_TABLE_TERMSOFSALES'
export const GET_TABLE_TERMSOFSALES_SUCCESS = 'GET_TABLE_TERMSOFSALES_SUCCESS'
export const GET_TABLE_SELLERS = 'GET_TABLE_SELLERS'
export const GET_TABLE_SELLERS_SUCCESS = 'GET_TABLE_SELLERS_SUCCESS'
export const GET_TABLE_FOREIGN_CURRENCIES = 'GET_TABLE_FOREIGN_CURRENCIES'
export const GET_TABLE_FOREIGN_CURRENCIES_SUCCESS =
	'GET_TABLE_FOREIGN_CURRENCIES_SUCCESS'
export const GET_TABLE_CONCEPTS_BACKGROUNDS = 'GET_TABLE_CONCEPTS_BACKGROUNDS'
export const GET_TABLE_CONCEPTS_BACKGROUNDS_SUCCESS =
	'GET_TABLE_CONCEPTS_BACKGROUNDS_SUCCESS'
export const GET_TABLE_BRANCH_OFFICES = 'GET_TABLE_BRANCH_OFFICES'
export const GET_TABLE_BRANCH_OFFICES_SUCCESS =
	'GET_TABLE_BRANCH_OFFICES_SUCCESS'
export const GET_TABLE_GENERATION_TYPE = 'GET_TABLE_GENERATION_TYPE'
export const GET_TABLE_GENERATION_TYPE_SUCCESS =
	'GET_TABLE_GENERATION_TYPE_SUCCESS'
export const GET_TABLE_VOUCHER_TYPE_ABM = 'GET_TABLE_VOUCHER_TYPE_ABM'
export const GET_TABLE_VOUCHER_TYPE_ABM_SUCCESS =
	'GET_TABLE_VOUCHER_TYPE_ABM_SUCCESS'
export const GET_TABLE_CREDIT = 'GET_TABLE_CREDIT'
export const GET_TABLE_CREDIT_SUCCESS = 'GET_TABLE_CREDIT_SUCCESS'
export const GET_TABLE_IS_POSTAL_CODE_VALID = 'GET_TABLE_IS_POSTAL_CODE_VALID'
export const GET_TABLE_IS_POSTAL_CODE_VALID_SUCCESS =
	'GET_TABLE_IS_POSTAL_CODE_VALID_SUCCESS'
export const GET_TABLE_IS_TAXES_ID_VALID = 'GET_TABLE_IS_TAXES_ID_VALID'
export const GET_TABLE_IS_TAXES_ID_VALID_SUCCESS =
	'GET_TABLE_IS_TAXES_ID_VALID_SUCCESS'
export const GET_TABLE_CUSTOMER_CATEGORY = 'GET_TABLE_CUSTOMER_CATEGORY'
export const GET_TABLE_CUSTOMER_CATEGORY_SUCCESS =
	'GET_TABLE_CUSTOMER_CATEGORY_SUCCESS'
export const GET_TABLE_PROVIDER_CAT = 'GET_TABLE_PROVIDER_CAT'
export const GET_TABLE_PROVIDER_CAT_SUCCESS = 'GET_TABLE_PROVIDER_CAT_SUCCESS'
export const GET_TABLE_PAYMENT_CONDITIONS = 'GET_TABLE_PAYMENT_CONDITIONS'
export const GET_TABLE_PAYMENT_CONDITIONS_SUCCESS =
	'GET_TABLE_PAYMENT_CONDITIONS_SUCCESS'
export const GET_TABLE_PRICE_LIST = 'GET_TABLE_PRICE_LIST'
export const GET_TABLE_PRICE_LIST_SUCCESS = 'GET_TABLE_PRICE_LIST_SUCCESS'
export const GET_TABLE_PROVIDER_ATTR = 'GET_TABLE_PROVIDER_ATTR'
export const GET_TABLE_PROVIDER_ATTR_SUCCESS = 'GET_TABLE_PROVIDER_ATTR_SUCCESS'
export const GET_TABLE_PROVIDER_ATTR_LIST = 'GET_TABLE_PROVIDER_ATTR_LIST'
export const GET_TABLE_PROVIDER_ATTR_LIST_SUCCESS =
	'GET_TABLE_PROVIDER_ATTR_LIST_SUCCESS'
export const GET_TABLE_TAXES = 'GET_TABLE_TAXES'
export const GET_TABLE_TAXES_SUCCESS = 'GET_TABLE_TAXES_SUCCESS'
export const GET_TABLE_TAXES_RATE = 'GET_TABLE_TAXES_RATE'
export const GET_TABLE_TAXES_RATE_SUCCESS = 'GET_TABLE_TAXES_RATE_SUCCESS'
export const GET_TABLE_CUSTOMER_ATTR = 'GET_TABLE_CUSTOMER_ATTR'
export const GET_TABLE_CUSTOMER_ATTR_SUCCESS = 'GET_TABLE_CUSTOMER_ATTR_SUCCESS'
export const GET_TABLE_CUSTOMER_ATTR_LIST = 'GET_TABLE_CUSTOMER_ATTR_LIST'
export const GET_TABLE_CUSTOMER_ATTR_LIST_SUCCESS =
	'GET_TABLE_CUSTOMER_ATTR_LIST_SUCCESS'
export const GET_TABLE_CUSTOMER_BRANCHES = 'GET_TABLE_CUSTOMER_BRANCHES'
export const GET_TABLE_CUSTOMER_BRANCHES_SUCCESS =
	'GET_TABLE_CUSTOMER_BRANCHES_SUCCESS'
export const GET_TABLE_FUNDS_ACCOUNTS_CAT = 'GET_TABLE_FUNDS_ACCOUNTS_CAT'
export const GET_TABLE_FUNDS_ACCOUNTS_CAT_SUCCESS =
	'GET_TABLE_FUNDS_ACCOUNTS_CAT_SUCCESS'
export const GET_TABLE_ITEM_CAT = 'GET_TABLE_ITEM_CAT'
export const GET_TABLE_ITEM_CAT_SUCCESS = 'GET_TABLE_ITEM_CAT_SUCCESS'
export const GET_TABLE_NUMERATORS = 'GET_TABLE_NUMERATORS'
export const GET_TABLE_NUMERATORS_SUCCESS = 'GET_TABLE_NUMERATORS_SUCCESS'
export const GET_TABLE_SYMBOLS = 'GET_TABLE_SYMBOLS'
export const GET_TABLE_SYMBOLS_SUCCESS = 'GET_TABLE_SYMBOLS_SUCCESS'
export const GET_TABLE_TES_STATES = 'GET_TABLE_TES_STATES'
export const GET_TABLE_TES_STATES_SUCCESS = 'GET_TABLE_TES_STATES_SUCCESS'
export const GET_TABLE_FUND_ACCOUNT_ATTR = 'GET_TABLE_FUND_ACCOUNT_ATTR'
export const GET_TABLE_FUND_ACCOUNT_ATTR_SUCCESS =
	'GET_TABLE_FUND_ACCOUNT_ATTR_SUCCESS'
export const GET_TABLE_FUND_ACCOUNT_ATTR_LIST =
	'GET_TABLE_FUND_ACCOUNT_ATTR_LIST'
export const GET_TABLE_FUND_ACCOUNT_ATTR_LIST_SUCCESS =
	'GET_TABLE_FUND_ACCOUNT_ATTR_LIST_SUCCESS'
export const GET_TABLE_FUNDS_CONCEPTS_TYPE = 'GET_TABLE_FUNDS_CONCEPTS_TYPE'
export const GET_TABLE_FUNDS_CONCEPTS_TYPE_SUCCESS =
	'GET_TABLE_FUNDS_CONCEPTS_TYPE_SUCCESS'
export const GET_TABLE_LEDGER_ACCOUNTS_CATEGORY =
	'GET_TABLE_LEDGER_ACCOUNTS_CATEGORY'
export const GET_TABLE_LEDGER_ACCOUNTS_CATEGORY_SUCCESS =
	'GET_TABLE_LEDGER_ACCOUNTS_CATEGORY_SUCCESS'
export const GET_TABLE_IMPUTATION_TYPE = 'GET_TABLE_IMPUTATION_TYPE'
export const GET_TABLE_IMPUTATION_TYPE_SUCCESS =
	'GET_TABLE_IMPUTATION_TYPE_SUCCESS'
export const GET_TABLE_PARENT_LEDGER_ACCOUNT = 'GET_TABLE_PARENT_LEDGER_ACCOUNT'
export const GET_TABLE_PARENT_LEDGER_ACCOUNT_SUCCESS =
	'GET_TABLE_PARENT_LEDGER_ACCOUNTS_SUCCESS'
export const GET_TABLE_COST_CENTERS_CATEGORY = 'GET_TABLE_COST_CENTERS_CATEGORY'
export const GET_TABLE_COST_CENTERS_CATEGORY_SUCCESS =
	'GET_TABLE_COST_CENTERS_CATEGORY_SUCCESS'
export const GET_TABLE_CC_IMPUTATION_TYPE = 'GET_TABLE_CC_IMPUTATION_TYPE'
export const GET_TABLE_CC_IMPUTATION_TYPE_SUCCESS =
	'GET_TABLE_CC_IMPUTATION_TYPE_SUCCESS'
export const GET_TABLE_PARENT_COST_CENTER = 'GET_TABLE_PARENT_COST_CENTER'
export const GET_TABLE_PARENT_COST_CENTER_SUCCESS =
	'GET_TABLE_PARENT_COST_CENTER_SUCCESS'
export const GET_FILTER_LA_ASSIGN_TYPE = 'GET_FILTER_LA_ASSIGN_TYPE'
export const GET_FILTER_LA_ASSIGN_TYPE_SUCCESS =
	'GET_FILTER_LA_ASSIGN_TYPE_SUCCESS'
export const GET_FILTER_LA_ASSIGN_STATUS = 'GET_FILTER_LA_ASSIGN_STATUS'
export const GET_FILTER_LA_ASSIGN_STATUS_SUCCESS =
	'GET_FILTER_LA_ASSIGN_STATUS_SUCCESS'
export const GET_FILTER_LA_ASSIGN_ORDER = 'GET_FILTER_LA_ASSIGN_ORDER'
export const GET_FILTER_LA_ASSIGN_ORDER_SUCCESS =
	'GET_FILTER_LA_ASSIGN_ORDER_SUCCESS'
export const GET_TABLE_LEDGER_ACCOUNTS = 'GET_TABLE_LEDGER_ACCOUNTS'
export const GET_TABLE_LEDGER_ACCOUNTS_SUCCESS =
	'GET_TABLE_LEDGER_ACCOUNTS_SUCCESS'
export const GET_FILTER_CC_ASSIGN_TYPE = 'GET_FILTER_CC_ASSIGN_TYPE'
export const GET_FILTER_CC_ASSIGN_TYPE_SUCCESS =
	'GET_FILTER_CC_ASSIGN_TYPE_SUCCESS'
export const GET_TABLE_COST_CENTERS = 'GET_TABLE_COST_CENTERS'
export const GET_TABLE_COST_CENTERS_SUCCESS = 'GET_TABLE_COST_CENTERS_SUCCESS'
export const GET_TABLE_PRODUCT_CAT = 'GET_TABLE_PRODUCT_CAT'
export const GET_TABLE_PRODUCT_CAT_SUCCESS = 'GET_TABLE_PRODUCT_CAT_SUCCESS'
export const GET_TABLE_BARCODE_FORMAT = 'GET_TABLE_BARCODE_FORMAT'
export const GET_TABLE_BARCODE_FORMAT_SUCCESS =
	'GET_TABLE_BARCODE_FORMAT_SUCCESS'
export const GET_TABLE_COST_ACCOUNTING_TYPE = 'GET_TABLE_COST_ACCOUNTING_TYPE'
export const GET_TABLE_COST_ACCOUNTING_TYPE_SUCCESS =
	'GET_TABLE_COST_ACCOUNTING_TYPE_SUCCESS'
export const GET_TABLE_CUF = 'GET_TABLE_CUF'
export const GET_TABLE_CUF_SUCCESS = 'GET_TABLE_CUF_SUCCESS'
export const GET_TABLE_PRODUCT_ATTR = 'GET_TABLE_PRODUCT_ATTR'
export const GET_TABLE_PRODUCT_ATTR_SUCCESS = 'GET_TABLE_PRODUCT_ATTR_SUCCESS'
export const GET_TABLE_PRODUCT_ATTR_LIST = 'GET_TABLE_PRODUCT_ATTR_LIST'
export const GET_TABLE_PRODUCT_ATTR_LIST_SUCCESS =
	'GET_TABLE_PRODUCT_ATTR_LIST_SUCCESS'
export const GET_TABLE_PRODUCTS = 'GET_TABLE_PRODUCTS'
export const GET_TABLE_PRODUCTS_SUCCESS = 'GET_TABLE_PRODUCTS_SUCCESS'
export const CLEAR_TABLE_VALIDATIONS = 'CLEAR_TABLE_VALIDATIONS'
export const GET_FILTER_TRANSPORT = 'GET_FILTER_TRANSPORT'
export const GET_FILTER_TRANSPORT_SUCCESS = 'GET_FILTER_TRANSPORT_SUCCESS'
export const GET_FILTER_CONDITION_SALE = 'GET_FILTER_CONDITION_SALE'
export const GET_FILTER_CONDITION_SALE_SUCCESS =
	'GET_FILTER_CONDITION_SALE_SUCCESS'
export const GET_ORDER_CBT_CAB = 'GET_ORDER_CBT_CAB'
export const GET_ORDER_CBT_CAB_SUCCESS = 'GET_ORDER_CBT_CAB_SUCCESS'
export const GET_OPTIONS_FILTER_AFFECTED = 'GET_OPTIONS_FILTER_AFFECTED'
export const GET_OPTIONS_FILTER_AFFECTED_SUCCESS =
	'GET_OPTIONS_FILTER_AFFECTED_SUCCESS'
export const GET_TABLE_PRICES = 'GET_TABLE_PRICES'
export const GET_TABLE_PRICES_SUCCESS = 'GET_TABLE_PRICES_SUCCESS'
export const GET_TABLE_DEPOTS = 'GET_TABLE_DEPOTS'
export const GET_TABLE_DEPOTS_SUCCESS = 'GET_TABLE_DEPOTS_SUCCESS'
export const GET_TABLE_AV_SALES = 'GET_TABLE_AV_SALES'
export const GET_TABLE_AV_SALES_SUCCESS = 'GET_TABLE_AV_SALES_SUCCESS'
export const GET_TABLE_AV_PURCHASES = 'GET_TABLE_AV_PURCHASES'
export const GET_TABLE_AV_PURCHASES_SUCCESS = 'GET_TABLE_AV_PURCHASES_SUCCESS'
export const GET_TABLE_AV_FUNDS = 'GET_TABLE_AV_FUNDS'
export const GET_TABLE_AV_FUNDS_SUCCESS = 'GET_TABLE_AV_FUNDS_SUCCESS'
export const GET_TABLE_AV_STOCK = 'GET_TABLE_AV_STOCK'
export const GET_TABLE_AV_STOCK_SUCCESS = 'GET_TABLE_AV_STOCK_SUCCESS'
export const GET_TABLE_AV_ACCOUNTING = 'GET_TABLE_AV_ACCOUNTING'
export const GET_TABLE_AV_ACCOUNTING_SUCCESS = 'GET_TABLE_AV_ACCOUNTING_SUCCESS'
export const GET_TABLE_VOUCHER_MODULE = 'GET_TABLE_VOUCHER_MODULE'
export const GET_TABLE_VOUCHER_MODULE_SUCCESS =
	'GET_TABLE_VOUCHER_MODULE_SUCCESS'
export const GET_TABLE_PROCESS = 'GET_TABLE_PROCESS'
export const GET_TABLE_PROCESS_SUCCESS = 'GET_TABLE_PROCESS_SUCCESS'
export const GET_TABLE_VOUCHER_PROCESS = 'GET_TABLE_VOUCHER_PROCESS'
export const GET_TABLE_VOUCHER_PROCESS_SUCCESS =
	'GET_TABLE_VOUCHER_PROCESS_SUCCESS'
export const GET_TABLE_VOUCHER_FIELDS = 'GET_TABLE_VOUCHER_FIELDS'
export const GET_TABLE_VOUCHER_FIELDS_SUCCESS =
	'GET_TABLE_VOUCHER_FIELDS_SUCCESS'
export const GET_TABLE_TITLES = 'GET_TABLE_TITLES'
export const GET_TABLE_TITLES_SUCCESS = 'GET_TABLE_TITLES_SUCCESS'
export const GET_TABLE_MASKS = 'GET_TABLE_MASKS'
export const GET_TABLE_MASKS_SUCCESS = 'GET_TABLE_MASKS_SUCCESS'
export const GET_TABLE_VALIDATIONS = 'GET_TABLE_VALIDATIONS'
export const GET_TABLE_VALIDATIONS_SUCCESS = 'GET_TABLE_VALIDATIONS_SUCCESS'
export const GET_TABLE_VALIDATION_VALUES = 'GET_TABLE_VALIDATION_VALUES'
export const GET_TABLE_VALIDATION_VALUES_SUCCESS =
	'GET_TABLE_VALIDATION_VALUES_SUCCESS'
export const GET_TABLE_FIELDS = 'GET_TABLE_FIELDS'
export const GET_TABLE_FIELDS_SUCCESS = 'GET_TABLE_FIELDS_SUCCESS'
export const GET_TABLE_CUF_DATA = 'GET_TABLE_CUF_DATA'
export const GET_TABLE_CUF_DATA_SUCCESS = 'GET_TABLE_CUF_DATA_SUCCESS'
export const GET_TABLE_BONIFICATIONS = 'GET_TABLE_BONIFICATIONS'
export const GET_TABLE_BONIFICATIONS_SUCCESS = 'GET_TABLE_BONIFICATIONS_SUCCESS'
export const GET_TABLE_BONIF_RATES = 'GET_TABLE_BONIF_RATES'
export const GET_TABLE_BONIF_RATES_SUCCESS = 'GET_TABLE_BONIF_RATES_SUCCESS'
export const GET_TABLE_USUAL_COIN = 'GET_TABLE_USUAL_COIN'
export const GET_TABLE_USUAL_COIN_SUCCESS = 'GET_TABLE_USUAL_COIN_SUCCESS'
export const GET_TABLE_CURRENCIES = 'GET_TABLE_CURRENCIES'
export const GET_TABLE_CURRENCIES_SUCCESS = 'GET_TABLE_CURRENCIES_SUCCESS'
export const GET_TABLE_PERMISSION_TYPE = 'GET_TABLE_PERMISSION_TYPE'
export const GET_TABLE_PERMISSION_TYPE_SUCCESS =
	'GET_TABLE_PERMISSION_TYPE_SUCCESS'
export const GET_TABLE_ABM_TABS = 'GET_TABLE_ABM_TABS'
export const GET_TABLE_ABM_TABS_SUCCESS = 'GET_TABLE_ABM_TABS_SUCCESS'
export const GET_FILTER_VOUCHER_CATEGORY = 'GET_FILTER_VOUCHER_CATEGORY'
export const GET_FILTER_VOUCHER_CATEGORY_SUCCESS =
	'GET_FILTER_VOUCHER_CATEGORY_SUCCESS'
export const GET_FILTER_VOUCHER_NAMES = 'GET_FILTER_VOUCHER_NAMES'
export const GET_FILTER_VOUCHER_NAMES_SUCCESS =
	'GET_FILTER_VOUCHER_NAMES_SUCCESS'
export const GET_TABLE_IS_DNI_REPEATED = 'GET_TABLE_IS_DNI_REPEATED'
export const GET_TABLE_IS_DNI_REPEATED_SUCCESS =
	'GET_TABLE_IS_DNI_REPEATED_SUCCESS'
export const GET_TABLE_IS_CUIT_REPEATED = 'GET_TABLE_IS_CUIT_REPEATED'
export const GET_TABLE_IS_CUIT_REPEATED_SUCCESS =
	'GET_TABLE_IS_CUIT_REPEATED_SUCCESS'
export const GET_FILTER_PRODUCT = 'GET_FILTER_PRODUCT'
export const GET_FILTER_PRODUCT_SUCCESS = 'GET_FILTER_PRODUCT_SUCCESS'
export const GET_TABLE_ESTIMATION_TYPE = 'GET_TABLE_ESTIMATION_TYPE'
export const GET_TABLE_ESTIMATION_TYPE_SUCCESS =
	'GET_TABLE_ESTIMATION_TYPE_SUCCESS'
export const CLEAR_VALIDATION_TABLE_DATA = 'CLEAR_VALIDATION_TABLE_DATA'

// Ledger accounts global pointers

export const CRUD_GET_LEDGER_ACCOUNTS_POINTERS =
	'CRUD_GET_LEDGER_ACCOUNTS_POINTERS'
export const CRUD_GET_LEDGER_ACCOUNTS_POINTERS_SUCCESS =
	'CRUD_GET_LEDGER_ACCOUNTS_POINTERS_SUCCESS'
export const CRUD_APPLY_LEDGER_ACCOUNTS_POINTERS =
	'CRUD_APPLY_LEDGER_ACCOUNTS_POINTERS'
export const CRUD_APPLY_LEDGER_ACCOUNTS_POINTERS_SUCCESS =
	'CRUD_APPLY_LEDGER_ACCOUNTS_POINTERS_SUCCESS'

// Ledger accounts cost center

export const CRUD_GET_LEDGER_ACCOUNT_COST_CENTER =
	'CRUD_GET_LEDGER_ACCOUNT_COST_CENTER'
export const CRUD_GET_LEDGER_ACCOUNT_COST_CENTER_SUCCESS =
	'CRUD_GET_LEDGER_ACCOUNT_COST_CENTER_SUCCESS'
export const CRUD_UPDATE_LEDGER_ACCOUNT_COST_CENTER =
	'CRUD_UPDATE_LEDGER_ACCOUNT_COST_CENTER'
export const CRUD_UPDATE_LEDGER_ACCOUNT_COST_CENTER_SUCCESS =
	'CRUD_UPDATE_LEDGER_ACCOUNT_COST_CENTER_SUCCESS'
export const CRUD_CLEAR_LEDGER_ACCOUNT_COST_CENTER_STORE =
	'CRUD_CLEAR_LEDGER_ACCOUNT_COST_CENTER_STORE'

// Cost Centers Ledger Accounts

export const CRUD_GET_COST_CENTER_LEDGER_ACCOUNTS =
	'CRUD_GET_COST_CENTER_LEDGER_ACCOUNTS'
export const CRUD_GET_COST_CENTER_LEDGER_ACCOUNTS_SUCCESS =
	'CRUD_GET_COST_CENTER_LEDGER_ACCOUNTS_SUCCESS'
export const CRUD_UPDATE_COST_CENTER_LEDGER_ACCOUNTS =
	'CRUD_UPDATE_COST_CENTER_LEDGER_ACCOUNTS'
export const CRUD_UPDATE_COST_CENTER_LEDGER_ACCOUNTS_SUCCESS =
	'CRUD_UPDATE_COST_CENTER_LEDGER_ACCOUNTS_SUCCESS'
export const CRUD_CLEAR_COST_CENTER_LEDGER_ACCOUNTS_STORE =
	'CRUD_CLEAR_COST_CENTER_LEDGER_ACCOUNTS_STORE'

// Payment conditions dues

export const CRUD_GET_TERMS_OF_SALES_DUES = 'CRUD_GET_TERMS_OF_SALES_DUES'
export const CRUD_GET_TERMS_OF_SALES_DUES_SUCCESS =
	'CRUD_GET_TERMS_OF_SALES_DUES_SUCCESS'
export const CRUD_UPDATE_TERMS_OF_SALES_DUES = 'CRUD_UPDATE_TERMS_OF_SALES_DUES'
export const CRUD_UPDATE_TERMS_OF_SALES_DUES_SUCCESS =
	'CRUD_UPDATE_TERMS_OF_SALES_DUES_SUCCESS'

// Purchase conditions dues

export const CRUD_GET_PURCHASE_CONDITIONS_DUES =
	'CRUD_GET_PURCHASE_CONDITIONS_DUES'
export const CRUD_GET_PURCHASE_CONDITIONS_DUES_SUCCESS =
	'CRUD_GET_PURCHASE_CONDITIONS_DUES_SUCCESS'
export const CRUD_UPDATE_PURCHASE_CONDITIONS_DUES =
	'CRUD_UPDATE_PURCHASE_CONDITIONS_DUES'
export const CRUD_UPDATE_PURCHASE_CONDITIONS_DUES_SUCCESS =
	'CRUD_UPDATE_PURCHASE_CONDITIONS_DUES_SUCCESS'

// Direct Ledger Accounts Assignation

export const CRUD_GET_DIRECT_LA_ASSIGNATIONS = 'CRUD_GET_DIRECT_LA_ASSIGNATIONS'
export const CRUD_GET_DIRECT_LA_ASSIGNATIONS_SUCCESS =
	'CRUD_GET_DIRECT_LA_ASSIGNATIONS_SUCCESS'
export const CRUD_UPDATE_DIRECT_LA_ASSIGNATIONS =
	'CRUD_UPDATE_DIRECT_LA_ASSIGNATIONS'
export const CRUD_UPDATE_DIRECT_LA_ASSIGNATIONS_SUCCESS =
	'CRUD_UPDATE_DIRECT_LA_ASSIGNATIONS_SUCCESS'
export const CRUD_CLEAR_DIRECT_LA_ASSIGNATIONS_STORE =
	'CRUD_CLEAR_DIRECT_LA_ASSIGNATIONS_STORE'

// Direct Ledger Accounts Assignation

export const CRUD_GET_DIRECT_CC_ASSIGNATIONS = 'CRUD_GET_DIRECT_CC_ASSIGNATIONS'
export const CRUD_GET_DIRECT_CC_ASSIGNATIONS_SUCCESS =
	'CRUD_GET_DIRECT_CC_ASSIGNATIONS_SUCCESS'
export const CRUD_UPDATE_DIRECT_CC_ASSIGNATIONS =
	'CRUD_UPDATE_DIRECT_CC_ASSIGNATIONS'
export const CRUD_UPDATE_DIRECT_CC_ASSIGNATIONS_SUCCESS =
	'CRUD_UPDATE_DIRECT_CC_ASSIGNATIONS_SUCCESS'
export const CRUD_CLEAR_DIRECT_CC_ASSIGNATIONS_STORE =
	'CRUD_CLEAR_DIRECT_CC_ASSIGNATIONS_STORE'

// Seller Commission

export const CRUD_GET_SELLER_COMMISSIONS = 'CRUD_GET_SELLER_COMMISSIONS'
export const CRUD_GET_SELLER_COMMISSIONS_SUCCESS =
	'CRUD_GET_SELLER_COMMISSIONS_SUCCESS'
export const CRUD_GET_SELLER_COMMISSION = 'CRUD_GET_SELLER_COMMISSION'
export const CRUD_GET_SELLER_COMMISSION_SUCCESS =
	'CRUD_GET_SELLER_COMMISSION_SUCCESS'
export const CRUD_UPDATE_SELLER_COMMISSION = 'CRUD_UPDATE_SELLER_COMMISSION'
export const CRUD_UPDATE_SELLER_COMMISSION_SUCCESS =
	'CRUD_UPDATE_SELLER_COMMISSION_SUCCESS'
export const CRUD_DEL_SELLER_COMMISSION = 'CRUD_DEL_SELLER_COMMISSION'
export const CRUD_DEL_SELLER_COMMISSION_SUCCESS =
	'CRUD_DEL_SELLER_COMMISSION_SUCCESS'
export const CRUD_CLEAR_SELLER_COMMISSIONS_STORE =
	'CRUD_CLEAR_SELLER_COMMISSIONS_STORE'

// Accounting Period ABM

export const CRUD_GET_LAST_ACCOUNTING_DATE = 'CRUD_GET_LAST_ACCOUNTING_DATE'
export const CRUD_GET_LAST_ACCOUNTING_DATE_SUCCESS =
	'CRUD_GET_LAST_ACCOUNTING_DATE_SUCCESS'

// Landing
export const GET_PENDING_VOUCHERS = 'GET_PENDING_VOUCHERS'
export const GET_PENDING_VOUCHERS_SUCCESS = 'GET_PENDING_VOUCHERS_SUCCESS'
export const ADD_CONFIG_PENDING_VOUCHERS = 'ADD_CONFIG_PENDING_VOUCHERS'
export const ADD_CONFIG_PENDING_VOUCHERS_SUCCESS =
	'ADD_CONFIG_PENDING_VOUCHERS_SUCCESS'
export const REMOVE_CONFIG_PENDING_VOUCHERS = 'REMOVE_CONFIG_PENDING_VOUCHERS'
export const REMOVE_CONFIG_PENDING_VOUCHERS_SUCCESS =
	'REMOVE_CONFIG_PENDING_VOUCHERS_SUCCESS'

// Budget
export const GET_SALES_HEADERS = 'GET_SALES_HEADERS'
export const GET_SALES_HEADERS_SUCCESS = 'GET_SALES_HEADERS_SUCCESS'
export const CONFIRM_SALES_HEADERS = 'CONFIRM_SALES_HEADERS'
export const CONFIRM_SALES_HEADERS_SUCCESS = 'CONFIRM_SALES_HEADERS_SUCCESS'
export const CONFIRM_SALES_HEADERS_ERROR = 'CONFIRM_SALES_HEADERS_ERROR'
export const VALIDATE_BARCODE_SALES_HEADERS = 'VALIDATE_BARCODE_SALES_HEADERS'
export const VALIDATE_BARCODE_SALES_HEADERS_SUCCESS =
	'VALIDATE_BARCODE_SALES_HEADERS_SUCCESS'

// Final Consumer
export const FINAL_CONSUMER_CONFIRM = 'FINAL_CONSUMER_CONFIRM'
export const FINAL_CONSUMER_CONFIRM_SUCCESS = 'FINAL_CONSUMER_CONFIRM_SUCCESS'

// Utils filters
export const GET_TABLE_CURRENCY = 'GET_TABLE_CURRENCY'
export const GET_TABLE_CURRENCY_SUCCESS = 'GET_TABLE_CURRENCY_SUCCESS'

// Auto Generate
export const AUTO_GENERATE_CONFIRM = 'AUTO_GENERATE_CONFIRM'
export const AUTO_GENERATE_CONFIRM_SUCCESS = 'AUTO_GENERATE_CONFIRM_SUCCESS'
export const AUTO_GENERATE_STATUS = 'AUTO_GENERATE_STATUS'
export const AUTO_GENERATE_STATUS_SUCCESS = 'AUTO_GENERATE_STATUS_SUCCESS'
export const AUTO_GENERATE_CANCEL = 'AUTO_GENERATE_CANCEL'
export const AUTO_GENERATE_CANCEL_SUCCESS = 'AUTO_GENERATE_CANCEL_SUCCESS'
export const AUTO_GENERATE_CLEAN_STORE = 'AUTO_GENERATE_CLEAN_STORE'
// Generic ABM Actions

export const CRUD_GET_LIST = 'CRUD_GET_LIST'
export const CRUD_GET_LIST_SUCCESS = 'CRUD_GET_LIST_SUCCESS'
export const CRUD_GET_SINGLE = 'CRUD_GET_SINGLE'
export const CRUD_GET_SINGLE_SUCCESS = 'CRUD_GET_SINGLE_SUCCESS'
export const CRUD_CREATE = 'CRUD_CREATE'
export const CRUD_CREATE_SUCCESS = 'CRUD_CREATE_SUCCESS'
export const CRUD_DELETE = 'CRUD_DELETE'
export const CRUD_DELETE_SUCCESS = 'CRUD_DELETE_SUCCESS'
export const CRUD_CLEAR_STORE = 'CRUD_CLEAR_STORE'
export const CRUD_CLEAR_SEARCH = 'CRUD_CLEAR_SEARCH'
export const CRUD_CLONE = 'CRUD_CLONE'
export const CRUD_CLONE_SUCCESS = 'CRUD_CLONE_SUCCESS'

// Generic ABM Values Actions

export const CRUD_GET_VALUES_LIST = 'CRUD_GET_VALUES_LIST'
export const CRUD_GET_VALUES_LIST_SUCCESS = 'CRUD_GET_VALUES_LIST_SUCCESS'
export const CRUD_GET_VALUE = 'CRUD_GET_VALUE'
export const CRUD_GET_VALUE_SUCCESS = 'CRUD_GET_VALUE_SUCCESS'
export const CRUD_DEL_VALUE = 'CRUD_DEL_VALUE'
export const CRUD_DEL_VALUE_SUCCESS = 'CRUD_DEL_VALUE_SUCCESS'
export const CRUD_CREATE_VALUE = 'CRUD_CREATE_VALUE'
export const CRUD_CREATE_VALUE_SUCCESS = 'CRUD_CREATE_VALUE_SUCCESS'
export const CRUD_CLEAR_VALUES_STORE = 'CRUD_CLEAR_VALUES_STORE'

// LedgerAcccounts refer

export const CRUD_GET_CUSTOMER_REFER = 'CRUD_GET_CUSTOMER_REFER'
export const CRUD_GET_CUSTOMER_REFER_SUCCESS = 'CRUD_GET_CUSTOMER_REFER_SUCCESS'
export const CRUD_UPDATE_CUSTOMER_REFER = 'CRUD_UPDATE_CUSTOMER_REFER'
export const CRUD_UPDATE_CUSTOMER_REFER_SUCCESS =
	'CRUD_UPDATE_CUSTOMER_REFER_SUCCESS'
export const CRUD_CLEAR_CUSTOMER_REFER_STORE = 'CRUD_CLEAR_CUSTOMER_REFER_STORE'

// Product Bonification
export const CRUD_UPDATE_PRODUCT_BONIFICATION =
	'CRUD_UPDATE_PRODUCT_BONIFICATION'
export const CRUD_UPDATE_PRODUCT_BONIFICATION_SUCCESS =
	'CRUD_UPDATE_PRODUCT_BONIFICATION_SUCCESS'
export const CRUD_GET_PRODUCT_BONIFICATION = 'CRUD_GET_PRODUCT_BONIFICATION'
export const CRUD_GET_PRODUCT_BONIFICATION_SUCCESS =
	'CRUD_GET_PRODUCT_BONIFICATION_SUCCESS'

// Product Code
export const CRUD_GET_PRODUCT_CODE = 'CRUD_GET_PRODUCT_CODE'
export const CRUD_GET_PRODUCT_CODE_SUCCESS = 'CRUD_GET_PRODUCT_CODE_SUCCESS'
export const CRUD_GET_PRODUCT_CODE_CONFIG = 'CRUD_GET_PRODUCT_LEVELS_CONFIG'
export const CRUD_GET_PRODUCT_CODE_CONFIG_SUCCESS =
	'CRUD_GET_PRODUCT_CODE_CONFIG_SUCCESS'
export const CRUD_VALIDATE_PRODUCT_CODE = 'CRUD_VALIDATE_PRODUCT_CODE'
export const CRUD_VALIDATE_PRODUCT_CODE_SUCCESS =
	'CRUD_VALIDATE_PRODUCT_CODE_SUCCESS'
export const CRUD_GET_PRODUCT_CODE_INFO = 'CRUD_GET_PRODUCT_CODE_INFO'
export const CRUD_ACCEPT_PRODUCT_CODE = 'CRUD_ACCEPT_PRODUCT_CODE'
export const CRUD_ACCEPT_PRODUCT_CODE_SUCCESS =
	'CRUD_ACCEPT_PRODUCT_CODE_SUCCESS'
export const CRUD_GET_PRODUCT_CODE_INFO_SUCCESS =
	'CRUD_GET_PRODUCT_CODE_INFO_SUCCESS'
export const CRUD_CLEAR_PRODUCT_CODE_STORE = 'CRUD_CLEAR_PRODUCT_CODE_STORE'

// LedgerAccounts Code

export const CRUD_GET_LEDGER_ACCOUNTS_CODE = 'CRUD_GET_LEDGER_ACCOUNTS_CODE'
export const CRUD_GET_LEDGER_ACCOUNTS_CODE_SUCCESS =
	'CRUD_GET_LEDGER_ACCOUNTS_CODE_SUCCESS'
export const CRUD_GET_LEDGER_ACCOUNTS_CODE_CONFIG =
	'CRUD_GET_LEDGER_ACCOUNTS_LEVELS_CONFIG'
export const CRUD_GET_LEDGER_ACCOUNTS_CODE_CONFIG_SUCCESS =
	'CRUD_GET_LEDGER_ACCOUNTS_CODE_CONFIG_SUCCESS'
export const CRUD_VALIDATE_LEDGER_ACCOUNTS_CODE =
	'CRUD_VALIDATE_LEDGER_ACCOUNTS_CODE'
export const CRUD_VALIDATE_LEDGER_ACCOUNTS_CODE_SUCCESS =
	'CRUD_VALIDATE_LEDGER_ACCOUNTS_CODE_SUCCESS'
export const CRUD_GET_LEDGER_ACCOUNTS_CODE_INFO =
	'CRUD_GET_LEDGER_ACCOUNTS_CODE_INFO'
export const CRUD_CREATE_LEDGER_ACCOUNTS_CODE =
	'CRUD_CREATE_LEDGER_ACCOUNTS_CODE'
export const CRUD_CREATE_LEDGER_ACCOUNTS_CODE_SUCCESS =
	'CRUD_CREATE_LEDGER_ACCOUNTS_CODE_SUCCESS'
export const CRUD_GET_LEDGER_ACCOUNTS_CODE_INFO_SUCCESS =
	'CRUD_GET_LEDGER_ACCOUNTS_CODE_INFO_SUCCESS'
export const CRUD_CLEAR_LEDGER_ACCOUNTS_CODE_STORE =
	'CRUD_CLEAR_LEDGER_ACCOUNTS_CODE_STORE'

// FundsAccounts Code

export const CRUD_GET_FUND_ACCOUNTS_CODE = 'CRUD_GET_FUND_ACCOUNTS_CODE'
export const CRUD_GET_FUND_ACCOUNTS_CODE_SUCCESS =
	'CRUD_GET_FUND_ACCOUNTS_CODE_SUCCESS'
export const CRUD_GET_FUND_ACCOUNTS_CODE_CONFIG =
	'CRUD_GET_FUND_ACCOUNTS_LEVELS_CONFIG'
export const CRUD_GET_FUND_ACCOUNTS_CODE_CONFIG_SUCCESS =
	'CRUD_GET_FUND_ACCOUNTS_CODE_CONFIG_SUCCESS'
export const CRUD_VALIDATE_FUND_ACCOUNTS_CODE =
	'CRUD_VALIDATE_FUND_ACCOUNTS_CODE'
export const CRUD_VALIDATE_FUND_ACCOUNTS_CODE_SUCCESS =
	'CRUD_VALIDATE_FUND_ACCOUNTS_CODE_SUCCESS'
export const CRUD_GET_FUND_ACCOUNTS_CODE_INFO =
	'CRUD_GET_FUND_ACCOUNTS_CODE_INFO'
export const CRUD_CREATE_FUND_ACCOUNTS_CODE = 'CRUD_CREATE_FUND_ACCOUNTS_CODE'
export const CRUD_CREATE_FUND_ACCOUNTS_CODE_SUCCESS =
	'CRUD_CREATE_FUND_ACCOUNTS_CODE_SUCCESS'
export const CRUD_GET_FUND_ACCOUNTS_CODE_INFO_SUCCESS =
	'CRUD_GET_FUND_ACCOUNTS_CODE_INFO_SUCCESS'
export const CRUD_CLEAR_FUND_ACCOUNTS_CODE_STORE =
	'CRUD_CLEAR_FUND_ACCOUNTS_CODE_STORE'
// User Profiles Accounts

export const CRUD_GET_USER_PROFILES = 'CRUD_GET_USER_PROFILES'
export const CRUD_GET_USER_PROFILES_SUCCESS = 'CRUD_GET_USER_PROFILES_SUCCESS'
export const CRUD_UPDATE_USER_PROFILES = 'CRUD_UPDATE_USER_PROFILES'
export const CRUD_UPDATE_USER_PROFILES_SUCCESS =
	'CRUD_UPDATE_USER_PROFILES_SUCCESS'
export const CRUD_CLEAR_USER_PROFILES_STORE = 'CRUD_CLEAR_USER_PROFILES_STORE'
// Profile Users Accounts

export const CRUD_GET_PROFILE_USERS = 'CRUD_GET_PROFILE_USERS'
export const CRUD_GET_PROFILE_USERS_SUCCESS = 'CRUD_GET_PROFILE_USERS_SUCCESS'
export const CRUD_UPDATE_PROFILE_USERS = 'CRUD_UPDATE_PROFILE_USERS'
export const CRUD_UPDATE_PROFILE_USERS_SUCCESS =
	'CRUD_UPDATE_PROFILE_USERS_SUCCESS'
export const CRUD_CLEAR_PROFILE_USERS_STORE = 'CRUD_CLEAR_PROFILE_USERS_STORE'

// Profile Abms Assign

export const CRUD_GET_PROFILE_ABMS = 'CRUD_GET_PROFILE_ABMS'
export const CRUD_GET_PROFILE_ABMS_SUCCESS = 'CRUD_GET_PROFILE_ABMS_SUCCESS'
export const CRUD_UPDATE_PROFILE_ABMS = 'CRUD_UPDATE_PROFILE_ABMS'
export const CRUD_UPDATE_PROFILE_ABMS_SUCCESS =
	'CRUD_UPDATE_PROFILE_ABMS_SUCCESS'
export const CRUD_CLEAR_PROFILE_ABMS_STORE = 'CRUD_CLEAR_PROFILE_ABMS_STORE'

// Profile Tabs Assign
export const CRUD_GET_PROFILE_TABS = 'CRUD_GET_PROFILE_TABS'
export const CRUD_GET_PROFILE_TABS_SUCCESS = 'CRUD_GET_PROFILE_TABS_SUCCESS'
export const CRUD_UPDATE_PROFILE_TABS = 'CRUD_UPDATE_PROFILE_TABS'
export const CRUD_UPDATE_PROFILE_TABS_SUCCESS =
	'CRUD_UPDATE_PROFILE_TABS_SUCCESS'
export const CRUD_CLEAR_PROFILE_TABS_STORE = 'CRUD_CLEAR_PROFILE_TABS_STORE'

// header selection
export const GET_HEADER_SELECTION = 'GET_HEADER_SELECTION'
export const GET_HEADER_SELECTION_SUCCESS = 'GET_HEADER_SELECTION_SUCCESS'
export const CONFIRM_HEADER_SELECTION = 'CONFIRM_HEADER_SELECTION'
export const CONFIRM_HEADER_SELECTION_SUCCESS =
	'CONFIRM_HEADER_SELECTION_SUCCESS'
export const CONFIRM_HEADER_SELECTION_ERROR = 'CONFIRM_HEADER_SELECTION_ERROR'
export const VALIDATE_PURCHASE_BARCODE = 'VALIDATE_PURCHASE_BARCODE'
export const VALIDATE_PURCHASE_BARCODE_SUCCESS =
	'VALIDATE_PURCHASE_BARCODE_SUCCESS'
// Attributes
export const GET_ATTRIBUTES = 'GET_ATTRIBUTES'
export const GET_ATTRIBUTES_SUCCESS = 'GET_ATTRIBUTES_SUCCESS'
export const CONFIRM_ATTRIBUTES = 'CONFIRM_ATTRIBUTES'
export const CONFIRM_ATTRIBUTES_SUCCESS = 'CONFIRM_ATTRIBUTES_SUCCESS'
export const CANCEL_ATTRIBUTES = 'CANCEL_ATTRIBUTES'
export const CANCEL_ATTRIBUTES_SUCCESS = 'CANCEL_ATTRIBUTES_SUCCESS'

// Headers Filters
export const SET_SALES_HEADER_FILTERS = 'SET_SALES_HEADER_FILTERS'
export const CLEAR_SALES_HEADER_FILTERS = 'CLEAR_SALES_HEADER_FILTERS'
export const SET_PURCHASES_HEADER_FILTERS = 'SET_PURCHASES_HEADER_FILTERS'
export const CLEAR_PURCHASES_HEADER_FILTERS = 'CLEAR_PURCHASES_HEADER_FILTERS'

// Sugested Products Filters

export const GET_SUGESTED_PRODUCTS = 'GET_SUGESTED_PRODUCTS'
export const GET_SUGESTED_PRODUCTS_SUCCESS = 'GET_SUGESTED_PRODUCTS_SUCCESS'
export const SET_SUGESTED_PRODUCT_SELECTION = 'SET_SUGESTED_PRODUCT_SELECTION'
export const SET_SUGESTED_PRODUCT_SELECTION_SUCCESS =
	'SET_SUGESTED_PRODUCT_SELECTION_SUCCESS'
export const SET_SUGESTED_PRODUCT_PRIORITY = 'SET_SUGESTED_PRODUCT_PRIORITY'
export const SET_SUGESTED_PRODUCT_PRIORITY_SUCCESS =
	'SET_SUGESTED_PRODUCT_PRIORITY_SUCCESS'
export const CLEAR_SUGESTED_PRODUCT_STORE = 'CLEAR_SUGESTED_PRODUCT_STORE'
// AutomaticPurchase
export const GET_DEPOSITS = 'GET_DEPOSITS'
export const GET_DEPOSITS_SUCCESS = 'GET_DEPOSITS_SUCCESS'
export const GET_LIST_CALCULATION_FORMULAS = 'GET_LIST_CALCULATION_FORMULAS'
export const GET_LIST_CALCULATION_FORMULAS_SUCCESS =
	'GET_LIST_CALCULATION_FORMULAS_SUCCESS'
export const GET_LIST_ORDER_OC_MULTI = 'GET_LIST_ORDER_OC_MULTI'
export const GET_LIST_ORDER_OC_MULTI_SUCCESS = 'GET_LIST_ORDER_OC_MULTI_SUCCESS'
export const GET_LIST_LABORATORIES = 'GET_LIST_LABORATORIES'
export const GET_LIST_LABORATORIES_SUCCESS = 'GET_LIST_LABORATORIES_SUCCESS'
export const GET_LIST_DRUGS = 'GET_LIST_DRUGS'
export const GET_LIST_DRUGS_SUCCESS = 'GET_LIST_DRUGS_SUCCESS'
export const GET_LIST_COST_TYPE = 'GET_LIST_COST_TYPE'
export const GET_LIST_COST_TYPE_SUCCESS = 'GET_LIST_COST_TYPE_SUCCESS'
export const GET_LIST_PROVIDERS = 'GET_LIST_PROVIDERS'
export const GET_LIST_PROVIDERS_SUCCESS = 'GET_LIST_PROVIDERS_SUCCESS'
export const GET_PAYMENT_TERMS_FILTER_LIST = 'GET_PAYMENT_TERMS_FILTER_LIST'
export const GET_PAYMENT_TERMS_FILTER_LIST_SUCCESS =
	'GET_PAYMENT_TERMS_FILTER_LIST_SUCCESS'
export const AUTOMATIC_PURCHASE_FILTER = 'AUTOMATIC_PURCHASE_FILTER'
export const AUTOMATIC_PURCHASE_FILTER_SUCCESS =
	'AUTOMATIC_PURCHASE_FILTER_SUCCESS'
export const AUTOMATIC_PURCHASE_CHANGE_LINE = 'AUTOMATIC_PURCHASE_CHANGE_LINE'
export const AUTOMATIC_PURCHASE_CHANGE_LINE_SUCCESS =
	'AUTOMATIC_PURCHASE_CHANGE_LINE_SUCCESS'
export const AUTOMATIC_PURCHASE_CONFIRM = 'AUTOMATIC_PURCHASE_CONFIRM'
export const AUTOMATIC_PURCHASE_CONFIRM_SUCCESS =
	'AUTOMATIC_PURCHASE_CONFIRM_SUCCESS'
export const AUTOMATIC_PURCHASE_UPDATE = 'AUTOMATIC_PURCHASE_UPDATE'
export const AUTOMATIC_PURCHASE_UPDATE_SUCCESS =
	'AUTOMATIC_PURCHASE_UPDATE_SUCCESS'
export const AUTOMATIC_PURCHASE_EXPORT = 'AUTOMATIC_PURCHASE_EXPORT'
export const AUTOMATIC_PURCHASE_EXPORT_SUCCESS =
	'AUTOMATIC_PURCHASE_EXPORT_SUCCESS'

// Equivalent Products Filters

export const GET_EQUIVALENT_PRODUCT_GROUP = 'GET_EQUIVALENT_PRODUCT_GROUP'
export const GET_EQUIVALENT_PRODUCT_GROUP_SUCCESS =
	'GET_EQUIVALENT_PRODUCT_GROUP_SUCCESS'
export const GET_EQUIVALENT_PRODUCTS = 'GET_EQUIVALENT_PRODUCTS'
export const GET_EQUIVALENT_PRODUCTS_SUCCESS = 'GET_EQUIVALENT_PRODUCTS_SUCCESS'
export const SET_EQUIVALENT_PRODUCT_SELECTION =
	'SET_EQUIVALENT_PRODUCT_SELECTION'
export const SET_EQUIVALENT_PRODUCT_SELECTION_SUCCESS =
	'SET_EQUIVALENT_PRODUCT_SELECTION_SUCCESS'
export const SET_EQUIVALENT_PRODUCT_GROUP = 'SET_EQUIVALENT_PRODUCT_GROUP'
export const SET_EQUIVALENT_PRODUCT_GROUP_SUCCESS =
	'SET_EQUIVALENT_PRODUCT_GROUP_SUCCESS'
export const DELETE_EQUIVALENT_PRODUCT_GROUP = 'DELETE_EQUIVALENT_PRODUCT_GROUP'
export const DELETE_EQUIVALENT_PRODUCT_GROUP_SUCCESS =
	'DELETE_EQUIVALENT_PRODUCT_GROUP_SUCCESS'
export const CLEAR_EQUIVALENT_PRODUCT_STORE = 'CLEAR_EQUIVALENT_PRODUCT_STORE'
