import { getConfigVoucher } from 'actions'
import withMenu from 'components/common/withMenu'
import GlobalContainer from 'components/layout/globalContainer'
import SugestedProducts from 'components/SugestedProducts/SugestedProducts'
import { P_CONF_PROD_SUG } from 'constants/ConfigProcessNames'
import { Ihistory } from 'models/NavigationInterface'
import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

interface ISugestedProductsProps extends WithTranslation {
	history: Ihistory
	match: any
	getConfigVoucher: any
	t: any
}

class SugestedProductsContainer extends Component<ISugestedProductsProps> {
	render() {
		const { t } = this.props

		const containerProps = {
			codeProcess: P_CONF_PROD_SUG,
			configTitles: true
		}

		const propsTable = {
			t: t
		}

		return (
			<Row>
				<GlobalContainer {...containerProps}>
					<SugestedProducts {...propsTable} />
				</GlobalContainer>
			</Row>
		)
	}
}

const mapStateToProps = ({ auth }: any) => ({})

const connectForm = connect(mapStateToProps, {
	getConfigVoucher
})(SugestedProductsContainer)

export default withTranslation()(withMenu(withRouter(connectForm)))
