import { getDetailSales, getOrderDirOptions, getPeriodFilter } from 'actions'
import CommonTable from 'components/common/commonTable'
import InputText from 'components/form/inputText'
import { P_CARGAITEMVTA } from 'constants/ConfigProcessNames'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getDefaultColumns } from 'lib/FormUtils'
import { getFiltersData } from 'lib/Utils'
import { IConfigFieldsForm } from 'models/Budget'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'

interface IProductPriceTableProps extends WithTranslation {
	t: any
	authUser: any
	config: IConfigFieldsForm
	productPriceList: any
	getTablePrice: (price: number) => void
	resetPriceTable: boolean
	fieldsPriceEstTable: Array<IFieldStructure>
	fieldsPriceTable: Array<IFieldStructure>
}

interface IProductPriceTableState {
	selected: any
	filterPriceList: any
}

class ProductPriceTable extends Component<
	IProductPriceTableProps,
	IProductPriceTableState
> {
	constructor(props: IProductPriceTableProps) {
		super(props)
		this.state = {
			selected: [],
			filterPriceList:
				props.productPriceList &&
				!_.isEmpty(props.productPriceList.listasPrecios)
					? props.productPriceList.listasPrecios
					: []
		}
	}

	componentDidUpdate = (prevProps: IProductPriceTableProps) => {
		const { resetPriceTable, productPriceList } = this.props
		if (resetPriceTable !== prevProps.resetPriceTable && resetPriceTable) {
			this.setState({ selected: [] })
		}

		if (prevProps.productPriceList !== productPriceList && productPriceList) {
			const filterPriceList = !_.isEmpty(productPriceList.listasPrecios)
				? productPriceList.listasPrecios
				: []

			this.setState({ filterPriceList })
		}
	}

	handleChangeTable = () => {}

	/**
	 * to filter price list table.
	 */
	handleSearchPriceList = (filter: string) => {
		const { productPriceList } = this.props

		const filterByCode = _.filter(productPriceList.listasPrecios, (price) => {
			return price.cod_preclis.toLowerCase().includes(filter.toLowerCase())
		})

		const filterByDesc = _.filter(productPriceList.listasPrecios, (price) => {
			return price.desc_lista.toLowerCase().includes(filter.toLowerCase())
		})

		const filterPriceList = [...filterByCode, ...filterByDesc].filter(
			(item, pos) => [...filterByCode, ...filterByDesc].indexOf(item) === pos
		)

		this.setState({ filterPriceList })
	}

	/**
	 * to sent price table to form
	 * @param row
	 */
	handleOnSelect = (row: any) => {
		this.props.getTablePrice(row.precio)
		this.setState({ selected: [row.cod_preclis] })
	}

	render() {
		const {
			t,
			authUser,
			config,
			productPriceList,
			fieldsPriceTable,
			fieldsPriceEstTable
		} = this.props
		const { selected, filterPriceList } = this.state

		const selectRow = {
			mode: 'radio',
			selected,
			bgColor: 'lightgray',
			onSelect: this.handleOnSelect
		}

		const fieldsTableFilters = config
			? getFiltersData(config.campos, {
					agrupador: 'vprec.filtro.lprec'
			  })
			: []

		const priceTableProps = {
			pagination: false,
			keyField: 'cod_preclis',
			columns: getDefaultColumns(fieldsPriceTable, { authUser }),
			data: filterPriceList,
			paginationOptions: {},
			selectRow: selectRow,
			onTableChange: this.handleChangeTable
		}

		const salesStaticsProps = {
			pagination: false,
			keyField: 'nro',
			columns: getDefaultColumns(fieldsPriceEstTable, { authUser }),
			data:
				productPriceList && !_.isEmpty(productPriceList.estVta)
					? productPriceList.estVta
					: [],
			paginationOptions: {},
			onTableChange: this.handleChangeTable
		}

		const filterListProps = {
			inputFormCol: { sm: 12 },
			fields: fieldsTableFilters,
			label: 'Lista de Precios',
			inputId: 'lista_prec',
			name: 'lista_prec',
			placeholder: t('products.selected_product'),
			colLabel: 'col-sm-5',
			colInput: 'col-sm-7',
			disable: false,
			onBlur: () => {},
			value: '',
			autoComplete: 'off',
			onChange: (data: any) => this.handleSearchPriceList(data)
		}

		return (
			<Fragment>
				<Row>
					<Col className="font-weight-bold text-center" lg={6} md={12} sm={12}>
						{/* {// t('search_product_detail.price_list')} */}
						<InputText {...filterListProps} />
					</Col>

					<Col className="font-weight-bold text-center" lg={6} md={12} sm={12}>
						{t('search_product_detail.sales_statistics')}
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						{!_.isEmpty(fieldsPriceTable) && (
							<CommonTable {...priceTableProps} />
						)}
					</Col>
					<Col sm={6}>
						{!_.isEmpty(fieldsPriceEstTable) && (
							<CommonTable {...salesStaticsProps} />
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ auth, voucher, product }: any) => {
	const { productPriceList } = product
	const config = voucher.config ? voucher.config[P_CARGAITEMVTA] : null
	const { authUser } = auth
	return { authUser, config, productPriceList }
}

const connectForm = connect(mapStateToProps, {
	getDetailSales,
	getOrderDirOptions,
	getPeriodFilter
})(ProductPriceTable)

export default withTranslation()(connectForm)
