import { getFilterProductAttributes } from 'actions'
import CommonContainer from 'components/common/CommonContainer/CommonContainer'
import InputDropdown from 'components/form/inputDropdown'
import InputText from 'components/form/inputText'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { getOptionsDropDown } from 'lib/FormUtils'
import React, { Component, Fragment } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { FaPlusCircle } from 'react-icons/fa'
import { connect } from 'react-redux'
import _ from 'underscore'
import AttributeTable from './AttributeTable'

interface InputsAttributesProps extends WithTranslation {
	fields: IFieldStructure[]
	filterAttributes: any
	getFilterProductAttributes: () => void
	setFieldValue: Function
	t?: any
}

interface attributesObject {
	atributo: string
	valor_atrib: string
}

interface InputsAttributesState {
	attribute: string
	attrValue: string
	attributes: attributesObject[]
}

class FormInputsAttributes extends Component<
	InputsAttributesProps,
	InputsAttributesState
> {
	constructor(props: InputsAttributesProps) {
		super(props)
		this.state = {
			attribute: '',
			attrValue: '',
			attributes: []
		}
	}

	componentDidMount() {
		const { filterAttributes } = this.props

		if (_.isEmpty(filterAttributes)) {
			this.props.getFilterProductAttributes()
		}
	}

	/**
	 * to add new attribute to table
	 */
	addAttribute = () => {
		const { attribute, attrValue, attributes } = this.state
		const { setFieldValue } = this.props
		if (
			attribute !== '' &&
			attrValue !== '' &&
			!_.some(attributes, (obj) => obj.atributo === attribute)
		) {
			attributes.push({ atributo: attribute, valor_atrib: attrValue })
			this.setState({ attributes, attribute: '', attrValue: '' })
			setFieldValue('atributos', attributes)
		}
	}

	/**
	 * to remove attributes from table
	 * @param attr
	 */
	handleRemoveAttr = (attr: any) => {
		const { attributes } = this.state
		const { setFieldValue } = this.props
		const newAttrs = _.reject(attributes, { atributo: attr.atributo })
		this.setState({ attributes: newAttrs })
		setFieldValue('atributos', newAttrs)
	}

	render() {
		const { fields, filterAttributes, t } = this.props
		const { attrValue, attribute, attributes } = this.state

		const propsAttrib = {
			inputFormCol: { sm: 6 },
			fields,
			label: 'Pendientes',
			inputId: 'atributo',
			name: 'atributo',
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			divStyle: { paddingRight: '0px' },
			onBlur: () => {},
			value: attribute,
			options: getOptionsDropDown(filterAttributes),
			onChange: (data: any) => {
				const { value } = data.target
				this.setState({ attribute: value })
			}
		}

		const propsAttribValue = {
			inputFormCol: { sm: 5 },
			fields,
			label: 'l',
			inputId: 'valor_atrib',
			name: 'valor_atrib',
			placeholder: '',
			colLabel: 'col-sm-4',
			colInput: 'col-sm-8',
			divStyle: { paddingRight: '0px', paddingLeft: '0px' },
			onBlur: () => {},
			value: attrValue,
			onChange: (value: string) => {
				this.setState({ attrValue: value })
			}
		}

		const propsTableAttr = {
			fields,
			attributes,
			removeAttribute: (attr: any) => this.handleRemoveAttr(attr),
			attrOptions: getOptionsDropDown(filterAttributes)
		}

		return (
			<Fragment>
				<Row className={`col-12 `}>
					<Col sm={12} className="pr-0 h6 font-weight-bold">
						<CommonContainer>
							<div className="py-1 pl-2">{t('search_voucher.attributes')}</div>
						</CommonContainer>
					</Col>
					<InputDropdown {...propsAttrib} />
					<InputText {...propsAttribValue} />
					<Col className={'text-right pr-3'}>
						<Button variant="link" onClick={this.addAttribute}>
							<FaPlusCircle />
						</Button>
					</Col>
				</Row>
				<Row className={`col-6`}>
					<Col>
						<AttributeTable {...propsTableAttr} />
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ searchProducts, providers }: any) => {
	const { listProviders } = providers
	const { filterAttributes } = searchProducts
	return { filterAttributes, listProviders }
}

const connectForm = connect(mapStateToProps, {
	getFilterProductAttributes
})(FormInputsAttributes)

export default withTranslation()(connectForm)
