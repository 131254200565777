import { cancelAttributes, confirmAttributes, getAttributes } from 'actions'
import GenericInputForm from 'components/form/genericInputForm'
import InputButton from 'components/form/inputButton'
import {
	IAttributeSale,
	ICancelAttributesParams,
	IConfirmAttributesParams,
	IGetAttributesParams
} from 'models/Attributes'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'

interface IAttributesModalProps {
	getAttributes: (payload: IGetAttributesParams) => void
	cancelAttributes: (payload: ICancelAttributesParams) => void
	idOperacion: string
	it: string
	attributeList: any
	confirmAttributes: (payload: IConfirmAttributesParams) => void
}

interface IAttributesModalState {
	attribData: Partial<IAttributeSale>[]
}

class AttributesModal extends Component<
	IAttributesModalProps,
	IAttributesModalState
> {
	constructor(props: IAttributesModalProps) {
		super(props)
		this.state = {
			attribData: []
		}
	}

	componentDidMount = () => {
		const { idOperacion, it } = this.props
		this.props.getAttributes({ idOperacion, it })
	}

	/**
	 * set data from attributes
	 * @param data
	 */
	handleChangeData = (data: any) => {
		const { attribData } = this.state
		let ban = true

		attribData.forEach((field: any, index: number) => {
			if (field.cod_atributo === data.cod_atributo) {
				attribData[index] = data // Editando
				ban = false
			}
		})

		if (ban) {
			attribData.push(data) // La primera vez
		}

		this.setState({ attribData })
	}

	/**
	 * to confirm attributes data
	 */
	handleConfirmAttributes = () => {
		const { idOperacion, it } = this.props
		const { attribData } = this.state
		this.props.confirmAttributes({ idOperacion, it, atrib_it_vta: attribData })
	}

	/**
	 * to cancel update attributes
	 */
	handleCancelAttributes = () => {
		const { idOperacion, it } = this.props
		this.props.cancelAttributes({ idOperacion, it })
	}

	handleSearchAuto = () => {}

	/**
	 * to render all generic inputs attributes
	 * @returns
	 */
	renderFields = () => {
		const { attributeList } = this.props
		const result: any = []
		attributeList.atrib_it_vta.forEach((element: any) => {
			result.push(
				<GenericInputForm
					style={{ marginLeft: -5 }}
					config={{ ...element, visible: 1, editable: 1 }}
					key={element.cod_atrib}
					handleChange={this.handleChangeData}
					handleSearchAuto={this.handleSearchAuto}
					autodata={[]}
				/>
			)
		})

		return result
	}

	render() {
		const { attributeList } = this.props
		return (
			<Fragment>
				<Row>
					<Col className="font-weight-bold mb-3">
						{attributeList && attributeList.item}
					</Col>
					<Col sm={12}>{attributeList && this.renderFields()}</Col>
				</Row>
				<Row>
					<Col className="text-center">
						<InputButton
							valueButton={'Aceptar'}
							onClick={this.handleConfirmAttributes}
							type={'primary'}
							customStyle={{ minWidth: '145px' }}
						/>
					</Col>
					<Col className="text-center">
						<InputButton
							valueButton={'Cancelar'}
							onClick={this.handleCancelAttributes}
							type={'primary'}
							customStyle={{ minWidth: '145px' }}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = ({ attributes }: any) => {
	const { attributeList } = attributes

	return { attributeList }
}

const mapDispatchToProps = {
	getAttributes,
	confirmAttributes,
	cancelAttributes
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributesModal)
