import { ICrudGenericFields } from 'models/CrudInterface'

export const principalFields: Array<ICrudGenericFields> = [
	{
		id: 'cod_catprod',
		type: 'combo',
		labelWidth: 'col-sm-2',
		width: 'col-sm-3',
		height: 'col-sm-12',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'productCategory',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'descrip1',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-9',
		height: 'col-sm-6',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'descrip2',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-9',
		height: 'col-sm-6',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_prod',
		type: 'withModal',
		labelWidth: 'col-sm-2',
		width: 'col-sm-6',
		height: 'col-sm-12',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_abrev',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-9',
		height: 'col-sm-6',
		default: null,
		maxChars: 20,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'abrev',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-9',
		height: 'col-sm-6',
		default: null,
		maxChars: 20,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'habilitado',
		type: 'check',
		labelWidth: 'col-sm-6',
		width: 'col-sm-6',
		height: 'col-sm-2',
		default: 1,
		maxChars: 20,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'codbarra',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-6',
		height: 'col-sm-6',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'precio',
		type: 'text',
		labelWidth: 'col-sm-7',
		width: 'col-sm-5',
		height: 'col-sm-2',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'mon_v',
		type: 'combo',
		labelWidth: 'col-sm-7',
		width: 'col-sm-5',
		height: 'col-sm-3',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'currencies',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'base_v',
		type: 'text',
		labelWidth: 'col-sm-8',
		width: 'col-sm-4',
		height: 'col-sm-4',
		default: 1,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'unid_v',
		type: 'combo',
		labelWidth: 'col-sm-7',
		width: 'col-sm-5',
		height: 'col-sm-3',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'measurementUnit',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'costo',
		type: 'text',
		labelWidth: 'col-sm-7',
		width: 'col-sm-5',
		height: 'col-sm-2',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'mon_c',
		type: 'combo',
		labelWidth: 'col-sm-7',
		width: 'col-sm-5',
		height: 'col-sm-3',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'currencies',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'base_c',
		type: 'text',
		labelWidth: 'col-sm-8',
		width: 'col-sm-4',
		height: 'col-sm-4',
		default: 1,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'unid_c',
		type: 'combo',
		labelWidth: 'col-sm-6',
		width: 'col-sm-6',
		height: 'col-sm-4',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'measurementUnit',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_mone',
		type: 'combo',
		labelWidth: 'col-sm-6',
		width: 'col-sm-6',
		height: 'col-sm-4',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'foreignCurrencies',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'unid_alt',
		type: 'combo',
		labelWidth: 'col-sm-2',
		width: 'col-sm-2',
		height: 'col-sm-12',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'measurementUnit',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'rel_c_v',
		type: 'text',
		labelWidth: 'col-sm-5',
		width: 'col-sm-4',
		height: 'col-sm-6',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'rel_v_c',
		type: 'text',
		labelWidth: 'col-sm-5',
		width: 'col-sm-4',
		height: 'col-sm-6',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'rel_c_a',
		type: 'text',
		labelWidth: 'col-sm-5',
		width: 'col-sm-4',
		height: 'col-sm-6',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'rel_a_c',
		type: 'text',
		labelWidth: 'col-sm-5',
		width: 'col-sm-4',
		height: 'col-sm-6',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: true,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'ind_stock',
		type: 'check',
		labelWidth: 'col-sm-6',
		width: 'col-sm-6',
		height: 'col-sm-3',
		default: 1,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'ind_partidas',
		type: 'check',
		labelWidth: 'col-sm-6',
		width: 'col-sm-6',
		height: 'col-sm-3',
		default: 1,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'ind_fvto',
		type: 'check',
		labelWidth: 'col-sm-6',
		width: 'col-sm-6',
		height: 'col-sm-3',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'ind_series',
		type: 'check',
		labelWidth: 'col-sm-6',
		width: 'col-sm-6',
		height: 'col-sm-3',
		default: 0,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'ninum',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-6',
		height: 'col-sm-6',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: true,
		comboData: 'numerators',
		dependsOn: {
			id: 'ind_series',
			values: [
				{
					idValue: 1,
					disabled: false
				},
				{
					idValue: 0,
					disabled: true
				}
			]
		}
	},
	{
		id: 'ind_camestado',
		type: 'check',
		labelWidth: 'col-sm-6',
		width: 'col-sm-6',
		height: 'col-sm-3',
		default: 1,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cat_item',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-4',
		height: 'col-sm-12',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'itemCategory',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_fbarra',
		type: 'combo',
		labelWidth: 'col-sm-3',
		width: 'col-sm-6',
		height: 'col-sm-12',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'barcodeFormat',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'largo',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-6',
		height: 'col-sm-6',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'ancho',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-6',
		height: 'col-sm-6',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'alto',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-6',
		height: 'col-sm-6',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'volumen',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-6',
		height: 'col-sm-6',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'peso',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-6',
		height: 'col-sm-6',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'pc_utilidad',
		type: 'text',
		labelWidth: 'col-sm-6',
		width: 'col-sm-6',
		height: 'col-sm-3',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'tipo_egreso',
		type: 'combo',
		labelWidth: 'col-sm-6',
		width: 'col-sm-6',
		height: 'col-sm-4',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'costAccountingType',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cod_cvta',
		type: 'combo',
		labelWidth: 'col-sm-6',
		width: 'col-sm-6',
		height: 'col-sm-5',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: 'termsOfSales',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'observ',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-9',
		height: 'col-sm-12',
		default: null,
		maxChars: 99,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'cuf_rep',
		type: 'autoComplete',
		labelWidth: 'col-sm-3',
		width: 'col-sm-3',
		height: 'col-sm-12',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: '',
			values: [
				{
					idValue: '',
					disabled: false
				}
			]
		}
	},
	{
		id: 'pto_rep',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-6',
		height: 'col-sm-6',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: 'cuf_rep',
			values: [
				{
					idValue: '',
					disabled: true
				}
			]
		}
	},
	{
		id: 'cant_rep',
		type: 'text',
		labelWidth: 'col-sm-3',
		width: 'col-sm-6',
		height: 'col-sm-6',
		default: null,
		maxChars: 10,
		autoComplete: 'new-off',
		disabled: false,
		comboData: '',
		dependsOn: {
			id: 'cuf_rep',
			values: [
				{
					idValue: '',
					disabled: true
				}
			]
		}
	}
]
