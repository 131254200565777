import { renderInput } from 'lib/FormUtils'
import { IGetTableGenericRes } from 'models/TableCalls/TableCallsInterface'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'

type CrudProductDataFieldProps = {
	field: any
	formikProps: any
	crudProps: any
	initialValue: any
	changedIndex: number
	handleIndex: (index: number) => void
	getLevelsData: (values: any) => Array<any>
}

type CrudProductDataFieldState = {
	fieldConfig: any
	idField: any
	index: number
}

class CrudProductCodeField extends Component<
	CrudProductDataFieldProps,
	CrudProductDataFieldState
> {
	constructor(props: CrudProductDataFieldProps) {
		super(props)
		this.state = {
			fieldConfig: {},
			idField: '',
			index: 0
		}
	}

	// Mount actions, sets initial field state to make it independant of some props
	componentDidMount = () => {
		const { field } = this.props
		const idField = field.idCampo.trim()
		const type = field.agrupador.split('.')

		const fieldConfig = {
			...field,
			tipo:
				type[1] === 'lista'
					? 'combo'
					: type[1] === 'text'
					? 'text'
					: type[1] === 'correlativo'
					? 'dependant'
					: 'generic',
			customProps: {
				maxLength: field.cantdigitos ? field.cantdigitos : 999
			}
		}

		this.setState({
			fieldConfig: fieldConfig,
			idField: idField,
			index: idField.includes('nivel')
				? Number(idField.replace('nivel', ''))
				: 0
		})
	}

	// Update check to generate new code and fill out dependant values after api response
	componentDidUpdate = (
		prevProps: CrudProductDataFieldProps,
		prevState: CrudProductDataFieldState
	) => {
		const { formikProps: prevFormikProps, crudProps: prevCrudProps } = prevProps
		const { formikProps, crudProps, getLevelsData, changedIndex, handleIndex } =
			this.props
		const { idField, fieldConfig, index } = this.state

		if (
			idField !== 'preview' &&
			prevFormikProps.values[idField] !== formikProps.values[idField]
		) {
			crudProps.crudValidateProductCode({
				alta: 0,
				cod_catprod: crudProps.cod_catprod,
				codigo_actual: crudProps.cod_prod,
				lista: getLevelsData(formikProps.values)
			})
		}

		if (
			fieldConfig.tipo === 'combo' &&
			formikProps.values[idField] &&
			formikProps.values[idField] !== prevFormikProps.values[idField]
		) {
			const datos = getLevelsData(formikProps.values)
			const datosNiveles: any = []
			for (let i = 0; i <= datos.length; i++) {
				const newLevel = {
					nivel: i + 1,
					cod_dato_ref:
						datos[i - 1] && i - 1 < index ? datos[i - 1].cod_dato : ''
				}

				datosNiveles.push(newLevel)
			}

			crudProps.crudGetProductCodeInfo({
				cod_catprod: crudProps.cod_catprod,
				datosNiveles: datosNiveles
			})
			handleIndex(index)
		}
		if (
			fieldConfig.tipo === 'combo' &&
			index > changedIndex &&
			crudProps.productCodeLevelInfo !== prevCrudProps.productCodeLevelInfo
		) {
			formikProps.setFieldValue(idField, '')
		}

		if (
			idField === 'preview' &&
			crudProps.productCodeValidated !== prevCrudProps.productCodeValidated
		) {
			crudProps.productCodeValidated &&
				formikProps.setFieldValue(
					idField,
					crudProps.productCodeValidated.codigo_completo
				)
		}
	}

	// Setup combo field if it's empty
	comboDataField = (data: any) => {
		const values: { cod_valor: string; desc_valor: string }[] = []
		data &&
			data.datoNivel &&
			_.each(data.datoNivel, (opt: IGetTableGenericRes) => {
				values.push({ cod_valor: opt.codigo, desc_valor: opt.descrip })
			})
		return values
	}

	handleBlur = () => {
		const { crudProps, formikProps, getLevelsData } = this.props
		crudProps.crudValidateProductCode({
			alta: 0,
			cod_catprod: crudProps.cod_catprod,
			codigo_actual: crudProps.cod_prod,
			lista: getLevelsData(formikProps.values)
		})
	}

	renderInputs = (formikProps: any, disableForm: boolean) => {
		const { crudProps } = this.props
		const { setFieldValue } = formikProps
		const { fieldConfig, idField, index } = this.state
		const field = fieldConfig

		if (field.tipo === 'combo') {
			field.valores =
				crudProps.productCodeLevelInfo &&
				crudProps.productCodeLevelInfo.datosNiveles
					? this.comboDataField(
							crudProps.productCodeLevelInfo.datosNiveles[index - 1]
					  )
					: []
			!formikProps.values[idField] &&
				(field.onChange = (event: any) => {
					event.target.value && setFieldValue(field.idCampo, event.target.value)
				})
		}

		!field.editable && (field.disabled = true)
		!field.editable && (field.editable = true)

		field.onBlur = this.handleBlur

		if (field.visible) {
			return renderInput(field, formikProps, disableForm)
		}
	}

	render() {
		const { formikProps, crudProps } = this.props
		return (
			<Fragment>
				{this.renderInputs(formikProps, crudProps.disableForm)}
			</Fragment>
		)
	}
}

export default CrudProductCodeField
