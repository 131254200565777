import {
	CANCEL_SEL_DOCUMENTS,
	CANCEL_SEL_DOCUMENTS_SUCCESS,
	CLEAR_IMP_DOCUMENTS_ERROR,
	COLLECTION_IMPORT_DOC,
	COLLECTION_IMPORT_DOC_SUCCESS,
	CONFIRM_IMP_DOCUMENTS,
	CONFIRM_IMP_DOCUMENTS_SUCCESS,
	CONFIRM_PAYMENT,
	CONFIRM_PAYMENT_SUCCESS,
	CONFIRM_SEL_DOCUMENTS,
	CONFIRM_SEL_DOCUMENTS_SUCCESS,
	GET_COLLECTIONS,
	GET_COLLECTIONS_SUCCESS,
	GET_IMP_DOCUMENTS,
	GET_IMP_DOCUMENTS_ERROR,
	GET_IMP_DOCUMENTS_SUCCESS,
	GET_SEL_DOCUMENTS,
	GET_SEL_DOCUMENTS_SUCCESS,
	REMOVE_PAYMENT,
	REMOVE_PAYMENT_SUCCESS,
	SEARCH_BANK,
	SEARCH_BANK_SUCCESS,
	VALIDATE_PAYMENT,
	VALIDATE_PAYMENT_SUCCESS,
	VALIDATE_SEL_DOCUMENTS,
	VALIDATE_SEL_DOCUMENTS_SUCCESS
} from '../constants/ActionsTypes'

export const getCollections = (params) => {
	return {
		type: GET_COLLECTIONS,
		payload: params
	}
}

export const getCollectionsSuccess = (response) => {
	return {
		type: GET_COLLECTIONS_SUCCESS,
		payload: response
	}
}

export const validatePayment = (params) => {
	return {
		type: VALIDATE_PAYMENT,
		payload: params
	}
}

export const validatePaymentSuccess = (response) => {
	return {
		type: VALIDATE_PAYMENT_SUCCESS,
		payload: response
	}
}

export const removePayment = (params) => {
	return {
		type: REMOVE_PAYMENT,
		payload: params
	}
}

export const removePaymentSuccess = (response) => {
	return {
		type: REMOVE_PAYMENT_SUCCESS,
		payload: response
	}
}

export const confirmPayment = (params) => {
	return {
		type: CONFIRM_PAYMENT,
		payload: params
	}
}

export const confirmPaymentSuccess = (response) => {
	return {
		type: CONFIRM_PAYMENT_SUCCESS,
		payload: response
	}
}

export const getSelDocuments = (params) => {
	return {
		type: GET_SEL_DOCUMENTS,
		payload: params
	}
}

export const getSelDocumentsSuccess = (response) => {
	return {
		type: GET_SEL_DOCUMENTS_SUCCESS,
		payload: response
	}
}

export const confirmSelDocuments = (params) => {
	return {
		type: CONFIRM_SEL_DOCUMENTS,
		payload: params
	}
}

export const confirmSelDocumentsSuccess = (response) => {
	return {
		type: CONFIRM_SEL_DOCUMENTS_SUCCESS,
		payload: response
	}
}

export const validateSelDocuments = (params) => {
	return {
		type: VALIDATE_SEL_DOCUMENTS,
		payload: params
	}
}

export const validateSelDocumentsSuccess = (response) => {
	return {
		type: VALIDATE_SEL_DOCUMENTS_SUCCESS,
		payload: response
	}
}

export const cancelSelDocuments = (params) => {
	return {
		type: CANCEL_SEL_DOCUMENTS,
		payload: params
	}
}

export const cancelSelDocumentsSuccess = (response) => {
	return {
		type: CANCEL_SEL_DOCUMENTS_SUCCESS,
		payload: response
	}
}

export const searchBank = (params) => {
	return {
		type: SEARCH_BANK,
		payload: params
	}
}

export const searchBankSuccess = (response) => {
	return {
		type: SEARCH_BANK_SUCCESS,
		payload: response
	}
}

export const getImpDocuments = (params) => {
	return {
		type: GET_IMP_DOCUMENTS,
		payload: params
	}
}

export const getImpDocumentsSuccess = (response) => {
	return {
		type: GET_IMP_DOCUMENTS_SUCCESS,
		payload: response
	}
}

export const getImpDocumentsError = (error) => {
	return {
		type: GET_IMP_DOCUMENTS_ERROR,
		payload: error
	}
}

export const collectionImportDoc = (params) => {
	return {
		type: COLLECTION_IMPORT_DOC,
		payload: params
	}
}

export const collectionImportDocSuccess = (response) => {
	return {
		type: COLLECTION_IMPORT_DOC_SUCCESS,
		payload: response
	}
}

export const confirImpDocuments = (params) => {
	return {
		type: CONFIRM_IMP_DOCUMENTS,
		payload: params
	}
}

export const confirImpDocumentsSuccess = (response) => {
	return {
		type: CONFIRM_IMP_DOCUMENTS_SUCCESS,
		payload: response
	}
}

export const clearErrorImpDocs = () => {
	return {
		type: CLEAR_IMP_DOCUMENTS_ERROR
	}
}
