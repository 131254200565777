import {
	crudApplyLedgerAccountsPointers,
	crudGetLedgerAccountsPointers
} from 'api/LedgerAccountsPointersApi'
import { ICrudApplyLedgerAccountsPointersSaga } from 'models/CrudLedgerAccountsInterface'
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
	crudApplyLedgerAccountsPointersSuccess,
	crudGetLedgerAccountsPointersSuccess
} from '../actions'
import {
	CRUD_APPLY_LEDGER_ACCOUNTS_POINTERS,
	CRUD_GET_LEDGER_ACCOUNTS_POINTERS
} from '../constants/ActionsTypes'

function* crudGetLedgerAccountsPointersReq(): any {
	try {
		const ledgerAccounts = yield call(crudGetLedgerAccountsPointers)
		yield put(crudGetLedgerAccountsPointersSuccess(ledgerAccounts))
	} catch (error) {}
}

function* crudApplyLedgerAccountsPointersReq({
	payload
}: ICrudApplyLedgerAccountsPointersSaga): any {
	try {
		const ledgerAccounts = yield call(crudApplyLedgerAccountsPointers, payload)
		yield put(crudApplyLedgerAccountsPointersSuccess(ledgerAccounts))
	} catch (error) {}
}

export function* crudGetLedgerAccountsPointersSaga() {
	yield takeEvery(
		CRUD_GET_LEDGER_ACCOUNTS_POINTERS,
		crudGetLedgerAccountsPointersReq
	)
}

export function* crudApplyLedgerAccountsPointersSaga() {
	yield takeLatest(
		CRUD_APPLY_LEDGER_ACCOUNTS_POINTERS,
		crudApplyLedgerAccountsPointersReq
	)
}

export default function* rootSaga() {
	yield all([
		fork(crudGetLedgerAccountsPointersSaga),
		fork(crudApplyLedgerAccountsPointersSaga)
	])
}
