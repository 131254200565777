import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'

export default class CommonModal extends Component {
	render() {
		const {
			showModal,
			modalTitle,
			modalBody,
			handleCloseModal,
			handleSubmit,
			buttons,
			sizeModal,
			customClass,
			disableHide
		} = this.props

		return (
			<Modal
				dialogClassName={customClass}
				// contentClassName={customClass}
				show={showModal}
				onHide={!disableHide ? handleCloseModal : () => {}}
				aria-labelledby="contained-modal-title-vcenter"
				size={sizeModal}
				centered
			>
				{modalTitle && (
					<Modal.Header closeButton={!disableHide}>{modalTitle}</Modal.Header>
				)}
				<Modal.Body>{modalBody}</Modal.Body>
				{buttons && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseModal}>
							{'Cerrar'}
						</Button>
						<Button variant="primary" onClick={handleSubmit}>
							{'Aceptar'}
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		)
	}
}
