import DisplayAmount from 'components/common/displayAmount'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

class AccountSeatTotals extends Component {
	render() {
		const { data, t, formatCol, classDiv } = this.props
		const totalDebe = data && data.total_debe ? data.total_debe : 0
		const totalHaber = data && data.total_haber ? data.total_haber : 0
		const total = data && data.diferencia ? data.diferencia : 0
		const mdCol = formatCol || 12
		return (
			<Row
				className={classDiv}
				style={{ lineHeight: '1.4', fontWeight: 'bold', fontSize: '11pt' }}
			>
				<Col md={mdCol}>
					<span>
						{' '}
						{`${t('AccountingSeat.table.totalDebe')}: `}{' '}
						<DisplayAmount amount={totalDebe} />{' '}
					</span>
				</Col>
				<Col md={mdCol}>
					<span>
						{`${t('AccountingSeat.table.totalHaber')}: `}{' '}
						<DisplayAmount amount={totalHaber} />{' '}
					</span>
				</Col>
				<Col md={mdCol}>
					<span>
						{`${t('AccountingSeat.table.diferencia')}: `}{' '}
						<DisplayAmount amount={total} />{' '}
					</span>
				</Col>
			</Row>
		)
	}
}

export default withTranslation()(AccountSeatTotals)
