import { CRUD_WRONG_ABM } from 'utils/RoutePath'

export const errorConfig = {
	id: 'errorCrud',
	key: 'error',
	descrip: 'errorDescrip',
	searchMethod: 'get',
	category: 'A',
	translation: 'errorData',
	dataUrl: CRUD_WRONG_ABM,
	urlApi: 'abm_error'
}
