import { advancedProductSearch, clearProductStore } from 'actions'
import { IFieldStructure } from 'constants/valuesInterfaces/interfaces'
import { Formik } from 'formik'
import { getInitValues, getValidationSchema } from 'lib/FieldValidations'
import React, { Component, Fragment } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'underscore'
import SearchProductsInputs from './SearchProductsInputs'
interface SearchProductsFromProps extends WithTranslation {
	t?: any
	fields: IFieldStructure[]
	advancedProductSearch: Function
	authUser: any
	paramsSearch: any
	isSearchProducts: boolean
	clearProductStore: () => void
	handleSendSearch: (payload: any) => void
}

class SearchProductsForm extends Component<SearchProductsFromProps> {
	handleSubmit = (values: any) => {
		const { atributo, valor_atrib, ...data } = values
		const { authUser } = this.props
		this.props.advancedProductSearch({
			idEmpresa: authUser.userCompany,
			...data,
			page_number: 1,
			page_size: 10
		})
	}

	render() {
		const { fields, t, paramsSearch, isSearchProducts } = this.props
		const validationSchema = getValidationSchema(fields, t, [])
		const columnSize = isSearchProducts ? 'offset-md-6' : 'offset-md-8'
		const initialValues = !_.isEmpty(paramsSearch)
			? paramsSearch
			: fields
			? getInitValues(fields)
			: []

		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values: any, actions: any) => {
					this.handleSubmit(values)
				}}
				validationSchema={validationSchema}
				enableReinitialize={true}
				render={(props) => {
					const {
						values,
						handleChange,
						handleBlur,
						setFieldValue,
						setFieldTouched
					} = props
					return (
						<Fragment>
							<Col sm={12}>
								{/* <NotificationError errors={props.errors} touched={props.touched} /> */}
							</Col>
							<Form
								onSubmit={(e) => props.handleSubmit(e as any)}
								className="voucher-info-form"
							>
								<SearchProductsInputs
									fields={fields}
									{...{
										values,
										handleChange,
										handleBlur,
										setFieldValue,
										setFieldTouched,
										readOnly: false,
										t
									}}
								/>

								<Row className={'pt-1 pr-2'}>
									<Col className={`${columnSize} text-center mt-2`}>
										<Button style={{ minWidth: '105px' }} type={'submit'}>
											{t('global.search')}
										</Button>
									</Col>
									{isSearchProducts && (
										<Col className={'text-center mt-2'}>
											<Button
												style={{ minWidth: '105px' }}
												type={'button'}
												onClick={() =>
													this.props.handleSendSearch({ ...values })
												}
											>
												{t('Collection.form.buttons.select')}
											</Button>
										</Col>
									)}
									<Col className={'text-center mt-2'}>
										<Button
											style={{ minWidth: '105px' }}
											type={'button'}
											onClick={() => {
												props.resetForm()
												this.props.clearProductStore()
											}}
										>
											{t('global.clear')}
										</Button>
									</Col>
								</Row>
							</Form>
						</Fragment>
					)
				}}
			/>
		)
	}
}

const mapStateToProps = ({ auth, product }: any) => {
	const { authUser } = auth
	const { paramsSearch } = product
	return { authUser, paramsSearch }
}

const connectForm = connect(mapStateToProps, {
	advancedProductSearch,
	clearProductStore
})(SearchProductsForm)

export default withTranslation()(connectForm)
