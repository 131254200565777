import {
	ICrudCreateFundAccountsCode,
	ICrudCreateFundAccountsCodeRes,
	ICrudGetFundAccountsCode,
	ICrudGetFundAccountsCodeConfig,
	ICrudGetFundAccountsCodeConfigRes,
	ICrudGetFundAccountsCodeRes
} from 'models/CrudFundsAccountsInterface'
import {
	CRUD_CLEAR_FUND_ACCOUNTS_CODE_STORE,
	CRUD_CREATE_FUND_ACCOUNTS_CODE,
	CRUD_CREATE_FUND_ACCOUNTS_CODE_SUCCESS,
	CRUD_GET_FUND_ACCOUNTS_CODE,
	CRUD_GET_FUND_ACCOUNTS_CODE_CONFIG,
	CRUD_GET_FUND_ACCOUNTS_CODE_CONFIG_SUCCESS,
	CRUD_GET_FUND_ACCOUNTS_CODE_SUCCESS,
	CRUD_VALIDATE_FUND_ACCOUNTS_CODE,
	CRUD_VALIDATE_FUND_ACCOUNTS_CODE_SUCCESS
} from '../constants/ActionsTypes'

export const crudGetFundAccountsCode = (payload: ICrudGetFundAccountsCode) => {
	return {
		type: CRUD_GET_FUND_ACCOUNTS_CODE,
		payload
	}
}

export const crudGetFundAccountsCodeSuccess = (
	payload: ICrudGetFundAccountsCodeRes
) => {
	return {
		type: CRUD_GET_FUND_ACCOUNTS_CODE_SUCCESS,
		payload
	}
}

export const crudGetFundAccountsCodeConfig = (
	payload: ICrudGetFundAccountsCodeConfig
) => {
	return {
		type: CRUD_GET_FUND_ACCOUNTS_CODE_CONFIG,
		payload
	}
}

export const crudGetFundAccountsCodeConfigSuccess = (
	payload: ICrudGetFundAccountsCodeConfigRes
) => {
	return {
		type: CRUD_GET_FUND_ACCOUNTS_CODE_CONFIG_SUCCESS,
		payload
	}
}

export const crudCreateFundAccountsCode = (
	payload: ICrudCreateFundAccountsCode
) => {
	return {
		type: CRUD_CREATE_FUND_ACCOUNTS_CODE,
		payload
	}
}

export const crudCreateFundAccountsCodeSuccess = (
	payload: ICrudCreateFundAccountsCodeRes
) => {
	return {
		type: CRUD_CREATE_FUND_ACCOUNTS_CODE_SUCCESS,
		payload
	}
}

export const crudValidateFundAccountsCode = (
	payload: ICrudCreateFundAccountsCode
) => {
	return {
		type: CRUD_VALIDATE_FUND_ACCOUNTS_CODE,
		payload
	}
}

export const crudValidateFundAccountsCodeSuccess = (
	payload: ICrudCreateFundAccountsCodeRes
) => {
	return {
		type: CRUD_VALIDATE_FUND_ACCOUNTS_CODE_SUCCESS,
		payload
	}
}

export const crudClearFundAccountsCodeStore = () => ({
	type: CRUD_CLEAR_FUND_ACCOUNTS_CODE_STORE
})
