import bsCustomFileInput from 'bs-custom-file-input'
import InputButton from 'components/form/inputButton'
import React, { Component, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import TotalsImportDoc from './totalsImportDoc'

export default class CollectionImportDocs extends Component {
	constructor(props) {
		super(props)
		this.state = {
			fileUpload: null,
			nameFile: ''
		}

		this.rfsFile = React.createRef()
	}

	componentDidMount = () => {
		bsCustomFileInput.init()
	}

	componentDidUpdate = (prevProps) => {
		const { docsImported, impDocuments } = this.props

		if (prevProps.docsImported !== docsImported && docsImported) {
			this.setState({ totalsImport: docsImported })
		}

		if (prevProps.impDocuments !== impDocuments && impDocuments) {
			this.setState({ totalsImport: impDocuments })
		}
	}

	handleImport = () => {
		const { fileUpload } = this.state
		const { t } = this.props

		if (fileUpload) {
			this.props.handleImport({ fileUpload })
		} else {
			this.props.handleError({
				showError: true,
				errorTitle: '',
				errorMessage: t('Collection.import.form_warning'),
				typeNotifaction: 'danger'
			})
		}
	}

	handleFileChange = (evnt) => {
		const fileUpload = evnt.target.files[0]
		this.setState({ fileUpload, nameFile: fileUpload.name })
	}

	render() {
		const { t, impSuccess } = this.props
		const columns = impSuccess ? 4 : 6

		return (
			<Row>
				<Col lg={columns} md={6} className={'text-left'}>
					<div className="custom-file">
						<input
							autoFocus={true}
							id="inputGroupFile01"
							ref={this.rfsFile}
							accept=".csv"
							type="file"
							className="custom-file-input"
							lang="es"
							onChange={this.handleFileChange}
						/>
						<label
							className="custom-file-label"
							data-browse={t('Collection.form.buttons.select')}
							htmlFor="inputGroupFile01"
						>
							{t('Collection.import.choose_file')}
						</label>
					</div>
				</Col>
				<Col lg={columns} md={6} className={'text-center'}>
					<InputButton
						valueButton={t('Collection.form.buttons.import')}
						type={'primary'}
						onClick={this.handleImport}
						customStyle={{ minWidth: '145px' }}
					/>
				</Col>
				{impSuccess && (
					<Fragment>
						<Col lg={4} md={12} className={'text-center mt-2'}>
							<InputButton
								valueButton={t('Collection.form.buttons.accept')}
								type={'primary'}
								onClick={() => this.props.handleConfirmation('accept')}
								customStyle={{ minWidth: '145px', marginRight: '15px' }}
							/>

							<InputButton
								valueButton={t('Collection.form.buttons.cancel')}
								type={'primary'}
								onClick={() => this.props.changeModal()}
								customStyle={{ minWidth: '145px', marginLeft: '15px' }}
							/>
						</Col>
						<Col sm={12}>
							<TotalsImportDoc
								totals={this.state.totalsImport}
								handleChangeTable={this.props.handleChangeTable}
							/>
						</Col>
					</Fragment>
				)}
			</Row>
		)
	}
}
