import withMenu from 'components/common/withMenu'
import LandingPendingVouchers from 'components/landing/LandingPendingVouchers'
import LandingVoucherList from 'components/landing/LandingVoucherList'
import React, { Component, Fragment } from 'react'
import _ from 'underscore'
interface ILandingProps {
	match: any
}

interface ILandingState {
	companyId: string
}

class Landing extends Component<ILandingProps, ILandingState> {
	constructor(props: ILandingProps) {
		super(props)
		this.state = { companyId: '' }
	}

	componentDidMount = () => {
		this.handleCompanyId()
	}

	componentDidUpdate = (prevProps: Readonly<ILandingProps>): void => {
		const { params } = this.props.match

		if (!_.isEqual(prevProps.match.params, params) && !_.isEmpty(params))
			this.handleCompanyId()
	}

	handleCompanyId = () => {
		const { params } = this.props.match
		this.setState({ companyId: params.idCompany })
	}

	render() {
		const { companyId } = this.state

		return (
			<Fragment>
				<LandingVoucherList companyId={companyId} />
				<LandingPendingVouchers companyId={companyId} />
			</Fragment>
		)
	}
}

export default withMenu(Landing)
